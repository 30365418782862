import React from 'react'
import { View } from 'react-native'
import Select, { InputActionMeta, components } from 'react-select'
import { CustomFonts, IconProps, SelectInputProps } from 'lib/types'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { ErrorMessage } from '../ErrorMessage'
import { CustomClearIndicator, CustomDropdownIndicator, CustomOption, CustomSingleValue } from './webComponents'

export const SelectInput: React.FunctionComponent<SelectInputProps> = ({
    disabled,
    onChange,
    value,
    options,
    placeholder,
    errorMessage,
    dropdownStyles,
    defaultValue,
    isClearable,
    onMenuOpen,
    isSearchable = false,
    leftIcon,
    hideErrorMessage,
    onInputChange,
    formatOptionLabel,
    testID
}) => {
    const { theme } = useStyles()
    const { styles } = useStyles(stylesheet)
    const hasOptionIcons = options.some(option => option?.icon)
    const hasSubLabel = options.some(option => option?.subLabel)
    const fontSize = dropdownStyles
        ? 16
        : 14
    const fontFamily = dropdownStyles
        ? CustomFonts.Roboto600
        : CustomFonts.Roboto400
    const getBorderColor = () => {
        if (errorMessage) {
            return theme.components.input.error.borderColor
        }

        if (!value) {
            return theme.components.input.emptyBorderColor
        }

        return theme.components.input.filledBorderColor
    }
    const LeftIcon = !leftIcon
        ? null
        : React.createElement<IconProps>(leftIcon, {
            size: 20,
            forceColor: theme.icon.unselected
        })

    const handleInputChange = (value: string, action: InputActionMeta) => {
        if (onInputChange && action.action === 'input-change') {
            onChange({
                value,
                label: value
            })
        }
    }
    const shouldDisplayDropdownIndicator = !disabled && options.length > 0

    return (
        <View testID={testID}>
            <Select
                aria-label={testID}
                blurInputOnSelect
                backspaceRemovesValue
                value={value}
                isSearchable={isSearchable}
                defaultValue={defaultValue}
                options={options}
                isDisabled={disabled}
                placeholder={placeholder}
                isClearable={isClearable}
                onChange={newValue => {
                    if (newValue !== value) {
                        onChange(newValue)
                    }
                }}
                onMenuOpen={onMenuOpen}
                noOptionsMessage={() => null}
                onInputChange={handleInputChange}
                formatOptionLabel={formatOptionLabel}
                components={{
                    ClearIndicator: CustomClearIndicator,
                    DropdownIndicator: shouldDisplayDropdownIndicator
                        ? CustomDropdownIndicator
                        : null,
                    Option: hasOptionIcons || hasSubLabel
                        ? CustomOption
                        : components.Option,
                    SingleValue: hasOptionIcons
                        ? CustomSingleValue
                        : components.SingleValue
                }}
                styles={{
                    control: base => ({
                        ...base,
                        borderWidth: dropdownStyles
                            ? 0
                            : 1,
                        height: theme.components.input.height,
                        borderRadius: theme.components.input.borderRadius,
                        borderColor: getBorderColor(),
                        paddingLeft: theme.components.input.padding,
                        fontFamily,
                        boxShadow: 'none',
                        '&:hover': {
                            borderColor: theme.colors.night
                        }
                    }),
                    valueContainer: base => ({
                        ...base,
                        paddingLeft: leftIcon
                            ? theme.utils.gap(2)
                            : 0
                    }),
                    singleValue: base => ({
                        ...base,
                        fontSize,
                        color: disabled
                            ? theme.components.input.typography.placeholder
                            : theme.components.input.typography.text
                    }),
                    input: base => ({
                        ...base,
                        fontSize,
                        color: theme.components.input.typography.text
                    }),
                    placeholder: base => ({
                        ...base,
                        fontSize,
                        marginLeft: 0,
                        color: errorMessage
                            ? theme.components.input.typography.error
                            : theme.components.input.typography.placeholder
                    }),
                    indicatorSeparator: base => ({
                        ...base,
                        width: 0
                    }),
                    menu: base => ({
                        ...base,
                        borderRadius: theme.components.input.borderRadius,
                        boxShadow: '0px 4px 8px 2px rgba(0, 0, 0, 0.16)'
                    }),
                    option: (base, props) => ({
                        ...base,
                        fontSize: 16,
                        color: props.isFocused
                            ? theme.colors.orange
                            : theme.components.input.typography.text,
                        backgroundColor: props.isFocused
                            ? theme.colors.yellow
                            : 'transparent',
                        fontFamily: theme.components.typography.fontFamily
                    }),
                    noOptionsMessage: (base, props) => ({
                        ...base,
                        color: theme.components.input.typography.placeholder,
                        fontFamily: theme.components.typography.fontFamily
                    })
                }}
            />
            {leftIcon && (
                <View style={styles.leftIcon}>
                    {LeftIcon}
                </View>
            )}
            {!hideErrorMessage && <ErrorMessage text={errorMessage} />}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    leftIcon: {
        position: 'absolute',
        // half of input size and icon size
        top: theme.components.input.height / 2 - 10,
        left: theme.utils.gap(1)
    }
}))
