import { useEffect, useRef, useState } from 'react'
import { useAuthTimerAtom } from 'lib/atoms'
import { dateHelpers } from 'lib/utils'

export const useTimer = (limitInSeconds: number) => {
    const intervalId = useRef<NodeJS.Timeout>()
    const [timer, setTimer] = useState(0)
    const [authTimer, setAuthTimer] = useAuthTimerAtom()

    useEffect(() => {
        const currentLocalTimer = dateHelpers.differenceInSecondsToNow(authTimer)

        if (currentLocalTimer > 0) {
            setTimer(currentLocalTimer)
            intervalId.current = setInterval(() => {
                if (timer > -1) {
                    setTimer(prevState => prevState - 1)
                }
            }, 1000)
        }

        return () => {
            clearInterval(intervalId.current)
        }
    }, [])

    const startTimer = () => {
        setTimer(limitInSeconds)
        clearInterval(intervalId.current)
        setAuthTimer(dateHelpers.addSecondsToNow(limitInSeconds))

        intervalId.current = setInterval(() => setTimer(prevState => prevState - 1), 1000)
    }

    const resetTimer = () => {
        setTimer(0)
        setAuthTimer(0)
    }

    return {
        timer,
        isTimerInProgress: timer > 0,
        startTimer,
        resetTimer
    }
}
