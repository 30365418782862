export type Payment = {
    uuid: string,
    date: string,
    name: string,
    price: number,
    code: string
}

type PaymentProviderExpiration = {
    never_expires: boolean,
    expiry_month: number,
    expiry_year: number
}

export type PaymentProvider = {
    _id: string,
    auth: string,
    card_type: string,
    default: boolean,
    expiration: PaymentProviderExpiration,
    imageUrl: string,
    last_4: string,
    payment_type: string,
    permissions: [],
    provider_name: string,
    status: string,
    user_id: string
}

export enum PaymentType {
    Card = 'Card',
    Credits = 'Credits',
    GrabPay = 'Grab'
}

export enum PaymentOption {
    Card = 'Pay by card',
    SendPay = 'SendPay Credit'
}
