import React from 'react'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scrollview'
import { Modal as NativeModal, View, StyleSheet, ViewStyle, ScrollViewProps } from 'react-native'
import { Icons } from 'assets'
import { Children } from 'lib/types'
import { useStyles } from 'lib/hooks'
import { Measurements, isIOS, isNative } from 'lib/common'
import { createStyles } from 'lib/styles'
import { Touchable } from './Touchable'
import { Toast } from './Toast'

type BasicModalProps = {
    isOpen: boolean,
    children: Children,
    isFullWindowSize?: boolean,
    isLoading?: boolean,
    animationType?: 'none' | 'slide' | 'fade',
    scrollViewProps?: ScrollViewProps,
    wrapperStyles?: ViewStyle,
    scrollViewContainerStyles?: ViewStyle,
    scrollViewStyles?: ViewStyle,
    insetsPadding?: boolean,
    testID?: string,
    onClose(byButton?: boolean): void
}

export const BasicModal: React.FunctionComponent<BasicModalProps> = ({
    isOpen,
    children,
    onClose,
    animationType = 'fade',
    isLoading = false,
    wrapperStyles = {},
    scrollViewContainerStyles = {},
    scrollViewStyles,
    scrollViewProps,
    isFullWindowSize = false,
    insetsPadding = false,
    testID
}) => {
    const { styles, theme } = useStyles(stylesheet)
    const insets = useSafeAreaInsets()
    const paddingTop = insetsPadding ? insets.top : 0
    const paddingBottom = insetsPadding ? insets.bottom : 0

    const handleClose = (byButton?: boolean) => {
        if (!isLoading) {
            onClose(byButton)
        }
    }

    return (
        <NativeModal
            testID={testID}
            accessibilityLabel={testID}
            transparent
            visible={isOpen}
            statusBarTranslucent
            animationType={animationType}
            onRequestClose={() => handleClose()}
        >
            <View
                style={{
                    ...styles.contentWrapper,
                    padding: isFullWindowSize
                        ? 0
                        : theme.utils.gap(2),
                    ...scrollViewStyles,
                    ...wrapperStyles
                }}
            >
                <Touchable
                    onPress={handleClose}
                    style={styles.backdrop}
                />
                <View style={styles.spacer}/>
                <KeyboardAwareScrollView
                    style={{
                        width: isFullWindowSize || isNative
                            ? '100%'
                            : undefined,
                        ...scrollViewStyles
                    }}
                    showsVerticalScrollIndicator={false}
                    contentContainerStyle={scrollViewContainerStyles}
                    {...scrollViewProps}
                >
                    <View style={styles.content(paddingTop, paddingBottom)}>
                        <Touchable
                            onPress={() => handleClose(true)}
                            style={styles.closeButton(paddingTop)}
                            hitSlopBottom={20}
                            hitSlopRight={20}
                            hitSlopLeft={20}
                            hitSlopTop={20}
                        >
                            <Icons.Close size={14} />
                        </Touchable>
                        {children}
                    </View>
                </KeyboardAwareScrollView>
                <View style={styles.spacer}/>
            </View>
            <Toast />
        </NativeModal>
    )
}

const stylesheet = createStyles(theme => ({
    backdrop: {
        ...StyleSheet.absoluteFillObject
    },
    contentWrapper: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.components.modal.backdrop.backgroundColor,
        paddingTop: isIOS
            ? Measurements.StatusBarHeight
            : 0
    },
    spacer: {
        flex: 100 // should be high enough to suppress the scrollView flex
    },
    content: (topInset: number, bottomInset: number) => ({
        paddingBottom: theme.utils.gap(3) + bottomInset,
        paddingTop: theme.utils.gap(3) + topInset,
        paddingHorizontal: theme.utils.gap(2),
        backgroundColor: theme.components.modal.content.backgroundColor,
        borderRadius: theme.components.modal.content.borderRadius
    }),
    closeButton: (topInset: number) => ({
        zIndex: theme.zIndex[10],
        position: 'absolute',
        right: theme.utils.gap(3),
        top: theme.utils.gap(3) + topInset
    })
}))
