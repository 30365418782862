import React from 'react'
import { View } from 'react-native'
import { Breakpoint, createStyles } from 'lib/styles'
import { useIsWithinBreakpoints, useStyles, useTranslations } from 'lib/hooks'
import { NavigationParams, NavigationProps, ScreenNames } from 'lib/routing'
import { AuthLayout, SingleSignOnForm } from '../components'

type AuthSingleSignOnScreenProps = {
    navigation: NavigationProps<ScreenNames.AuthSingleSignOn>,
    route: NavigationParams<ScreenNames.AuthSingleSignOn>
}

export const AuthSingleSignOnScreen: React.FunctionComponent<AuthSingleSignOnScreenProps> = () => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const isMobile = useIsWithinBreakpoints(Breakpoint.XS, Breakpoint.MD)

    return (
        <AuthLayout
            centerVertical
            title={!isMobile
                ? T.screens.authSingleSignOn.welcome
                : undefined
            }
            message={!isMobile
                ? T.screens.authSingleSignOn.welcomeMessage
                : undefined
            }
        >
            <View
                style={isMobile
                    ? styles.container
                    : undefined
                }
            >
                <SingleSignOnForm />
            </View>
        </AuthLayout>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        flex: 1,
        marginTop: theme.utils.gap(10)
    }
}))
