import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const Chevron: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path d="M2.1 18.1c-2.8 2.8-2.8 7.4 0 10.3L35 61.9c2.8 2.8 7.3 2.8 10.1 0L78 28.4c2.8-2.8 2.8-7.4 0-10.3-2.8-2.8-7.3-2.8-10.1 0L40 46.4 12.2 18.1c-2.8-2.8-7.4-2.7-10.1 0z" />
    </Icon>
)
