import React from 'react'
import { Text, TextStyle } from 'react-native'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { Children } from 'lib/types'
import { R } from 'lib/utils'

type BaseTextProps = {
    numberOfLines?: number,
    onPress?: VoidFunction,
    style?: TextStyle,
    testID?: string,
    forceColor?: string,
    children?: Children,
    onNumberOfLines?(numberOfLines: number): void
}

export const BaseText: React.FunctionComponent<BaseTextProps> = ({
    children,
    style = {},
    numberOfLines,
    onPress,
    testID,
    forceColor,
    onNumberOfLines
}) => {
    const { styles, theme } = useStyles(stylesheet)

    return (
        <Text
            onLayout={event => {
                const numberOfLines = Math.round(event.nativeEvent.layout.height / Number(style.lineHeight))

                R.ifDefined(onNumberOfLines, onNumberOfLines => onNumberOfLines(numberOfLines))
            }}
            data-testid={testID}
            accessibilityLabel={testID}
            onPress={onPress}
            allowFontScaling={false}
            numberOfLines={numberOfLines}
            ellipsizeMode={numberOfLines ? 'tail' : undefined}
            style={{
                ...styles.text,
                color: forceColor || theme.colors.night,
                ...style
            }}
            children={children}
            testID={testID}
        />
    )
}

const stylesheet = createStyles(theme => ({
    text: {
        fontFamily: theme.components.typography.fontFamily
    }
}))
