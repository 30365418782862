import React from 'react'
import { View } from 'react-native'
import { createStyles } from 'lib/styles'
import { Typography } from 'lib/components'
import { usePrice, useStyles } from 'lib/hooks'
import { CustomFonts } from 'lib/types'
import { SessionJob } from 'features/jobs/types'

type SessionItemProps =  {
    session: SessionJob,
}

export const SessionItem: React.FunctionComponent<SessionItemProps> = ({ session }) => {
    const { styles } = useStyles(stylesheet)

    if (!session || !Object.keys(session).length) {
        return null
    }

    const formattedPrice = session?.bookingSessionCost ? usePrice(session.bookingSessionCost) : '-'

    return (
        <View key={session.bookingSessionId} style={styles.row}>
            <Typography.Regular style={styles.name}>
                {session.bookingSessionName}
            </Typography.Regular>
            <Typography.Regular style={styles.price}>
                {formattedPrice}
            </Typography.Regular>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    name: {
        fontSize: 14,
        lineHeight: 17
    },
    price: {
        fontSize: 14,
        lineHeight: 17,
        fontFamily: CustomFonts.Poppins500
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: theme.utils.gap(1.25)
    }
}))
