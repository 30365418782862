import React from 'react'
import { View } from 'react-native'
import { createStyles, theme } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { CustomModal, Button, Typography } from 'lib/components'
import { Icons } from 'assets'
import { CustomFonts } from 'lib/types'

type CancelRequestSentModalProps = {
    isOpen: boolean,
    isLoading: boolean,
    onClose: VoidFunction,
}

export const CancelRequestSentModal: React.FunctionComponent<CancelRequestSentModalProps> = ({
    isOpen,
    isLoading,
    onClose
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)

    return (
        <CustomModal
            onClose={onClose}
            isOpen={isOpen}
        >
            <View style={styles.modalWrapper}>
                <View style={styles.iconContainer}>
                    <Icons.CheckCircle
                        size={40}
                        forceColor={theme.colors.orange}
                    />
                </View>
                <Typography.SmallSubheading style={styles.title}>
                    {T.screens.jobDetails.cancelRequestSend.packageTitle}
                </Typography.SmallSubheading>
                <Typography.Regular style={styles.rescheduleNote}>
                    {T.screens.jobDetails.cancelRequestSend.note}
                </Typography.Regular>
                <View style={styles.actionContainer}>
                    <View style={styles.buttonWrapper}>
                        <Button
                            disabled={isLoading}
                            isLoading={isLoading}
                            text={
                                isLoading ?
                                    T.screens.jobDetails.cancelRequestSend.sending :
                                    T.screens.jobDetails.cancelRequestSend.done}
                            onPress={onClose}
                            textWrapperStyles={styles.buttonTextWrapper}
                            textStyles={styles.buttonText}
                        />
                    </View>
                </View>
            </View>
        </CustomModal>
    )
}

const stylesheet = createStyles(theme => ({
    modalWrapper: {
        alignItems: 'center'
    },
    iconContainer: {
        alignItems: 'center',
        paddingBottom: theme.utils.gap(1)
    },
    title: {
        textAlign: 'center',
        fontSize: 25,
        lineHeight: 31,
        fontFamily: CustomFonts.Poppins600
    },
    actionContainer: {
        paddingTop: theme.utils.gap(3),
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        zIndex: -1,
        width: {
            xs: '100%',
            md: undefined
        }
    },
    buttonWrapper: {
        flex: 1
    },
    buttonTextWrapper: {
        width: '100%',
        wordWrap: 'break-word',
        textAlign: 'center',
        marginHorizontal: theme.utils.gap(7)
    },
    rescheduleNote: {
        textAlign: 'center',
        marginTop: theme.utils.gap(1.5),
        maxWidth: {
            md: '50%',
            xs: '100%'
        }
    },
    buttonText: {
        textAlign: 'center'
    }
}))
