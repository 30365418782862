import React, { useEffect, useState } from 'react'
import Animated, { FadeIn, FadeOut } from 'react-native-reanimated'
import { View } from 'react-native'
import { useLocaleAtom } from 'lib/atoms'
import { Typography } from 'lib/components'
import { useStyles, useTranslations } from 'lib/hooks'
import { Job, RecurringJob } from 'lib/models'
import { createStyles } from 'lib/styles'
import { JobStatus, KeyValuePair } from 'lib/types'
import { dateHelpers } from 'lib/utils'
import { BookingsListViewTile } from './BookingsListViewTile'
import { PaginationPages } from './paginationPages'

type UserBookingsListViewProps = {
    jobs: KeyValuePair<Array<Job>>,
    jobStatus: JobStatus
    recurringJobs: Array<RecurringJob>,
    filters?: Array<string>
}

export const UserBookingsListView: React.FunctionComponent<UserBookingsListViewProps> = ({
    jobs = [],
    jobStatus,
    recurringJobs,
    filters = []
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const [locale] = useLocaleAtom()
    const jobsPerPage = 10
    const [currentPage, setCurrentPage] = useState(1)
    const currentPageStartIndex = (currentPage - 1) * jobsPerPage
    const allJobsKeys =  Object.keys(jobs)
    const totalJobsCount = Object.values(jobs).length
    const groupedJobKeys = allJobsKeys
        .slice(currentPageStartIndex, currentPageStartIndex + jobsPerPage)

    useEffect(() => {
        setCurrentPage(1)
    }, filters)

    return (
        <View style={styles.wrapper}>
            {groupedJobKeys.map((key, index) => {
                const jobsThatDay = jobs[key]

                return (
                    <Animated.View
                        key={`${index}-${jobStatus}`}
                        style={styles.container}
                        exiting={FadeOut.duration(100)}
                        entering={FadeIn.delay(100)}
                    >
                        <Typography.Label bold>
                            {dateHelpers.getFullBookingListViewDate(key, locale)}
                        </Typography.Label>
                        {jobsThatDay.map((job, index) => (
                            <BookingsListViewTile
                                job={job}
                                key={index}
                                jobStatus={jobStatus}
                                recurringJobs={recurringJobs}
                            />
                        ))}
                    </Animated.View>
                )
            })}
            {allJobsKeys.length > jobsPerPage && (
                <View style={styles.row}>
                    <PaginationPages
                        pagesAmount={Math.ceil(allJobsKeys.length / jobsPerPage)}
                        currentPage={currentPage}
                        onChangePage={setCurrentPage}
                    />
                    <Typography.Regular style={styles.paginationText}>
                        {`${currentPageStartIndex + 1} ${T.screens.bookings.pagination.to} ${currentPageStartIndex + groupedJobKeys.length} ${T.screens.bookings.pagination.of} ${totalJobsCount} ${T.screens.bookings.pagination.results}`}
                    </Typography.Regular>
                </View>
            )}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        flex: 1,
        backgroundColor: theme.colors.marble,
        paddingBottom: theme.utils.gap(4),
        paddingTop: theme.utils.gap(2),
        marginHorizontal: {
            lg: -theme.utils.gap(6),
            xs: -theme.utils.gap(1)
        },
        paddingHorizontal: {
            lg: theme.utils.gap(6),
            xs: theme.utils.gap(1)
        }
    },
    container: {
        marginVertical: theme.utils.gap(2)
    },
    row: {
        flexDirection: {
            xl: 'row',
            lg: 'column'
        },
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    paginationText: {
        padding: theme.utils.gap(2)
    }
}))
