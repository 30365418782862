import React from 'react'
import { View } from 'react-native'
import { Field } from '@codegateinc/react-form-builder-v2'
import { Service } from 'lib/models'
import { useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { NumberInputActions } from 'lib/types'
import { FormComponents, Touchable, Typography } from 'lib/components'
import { Segment } from 'lib/analytics'
import { Icons } from 'assets'
import { linkingHelpers } from 'lib/utils'

interface ServicesProps extends Field<Array<Service>> {
    disabled?: boolean,
    step?: number
}

export const Services: React.FunctionComponent<ServicesProps> = ({
    step = 1,
    value,
    disabled,
    errorMessage,
    onChangeValue
}) => {
    const { styles } = useStyles(stylesheet)
    const T = useTranslations()

    const onAdd = (id: number) => {
        const newValue = value.map(item => {
            if (item.id !== id) {
                return item
            }

            Segment.bookingAddonAdded({
                addon: {
                    name: `${item.title} ${item.subtitle}`,
                    value: item.selected ? String(0) : String(1)
                }
            })

            return {
                ...item,
                quantity: item.selected ? 0 : 1,
                selected: !item.selected
            }
        })

        onChangeValue(newValue)
    }

    const onQuantityChange = (action: NumberInputActions, id: number) => {
        const newValue = value.map(item => {
            if (item.id !== id) {
                return item
            }

            const quantity = action === NumberInputActions.Add
                ? item.quantity + step
                : item.quantity - step

            Segment.bookingAddonAdded({
                addon: {
                    name: `${item.title} ${item.subtitle}`,
                    value: action === NumberInputActions.Add
                        ? String(item.quantity + step)
                        : String(item.quantity - step)
                }
            })

            return {
                ...item,
                quantity,
                selected: quantity > 0
            }
        })

        onChangeValue(newValue)
    }

    return (
        <View style={styles.wrapper}>
            {value.map((item, index) => (
                <FormComponents.ServiceInput
                    key={index}
                    service={item}
                    onAdd={onAdd}
                    disabled={disabled}
                    onQuantityChange={onQuantityChange}
                />
            ))}

            <FormComponents.CustomServiceInput
                title={T.screens.deepCleaning.formFields.services.footerText.title}
                subtitle={T.screens.deepCleaning.formFields.services.footerText.subTitle}
                action={(
                    <Touchable onPress={() => {
                        linkingHelpers.openUrl(T.screens.maintenance.contactUs.whatsApp.link)
                    }}>
                        <View style={styles.actionButtonContent}>
                            <Icons.WhatsAppColoured size={20} />
                            <Typography.Regular style={styles.underline} bold>
                                {T.screens.deepCleaning.formFields.services.footerText.whatsAppText}
                            </Typography.Regular>
                        </View>
                    </Touchable>
                )}
            />

            <FormComponents.ErrorMessage text={errorMessage} />
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        marginTop: theme.utils.gap(1)
    },
    underline: {
        textDecorationLine: 'underline',
        fontSize: 14
    },
    actionButtonContent: {
        flexDirection: 'row',
        gap: theme.utils.gap(0.5)
    }
}))
