import { useAppUpdateAtom } from 'lib/atoms'
import { isAndroid } from 'lib/common'
import { AppVersionHeader } from 'lib/types'
import { shouldUpdateApp } from 'features/updates/utils'

export const useAppUpdateListener = () => {
    const [appUpdate] = useAppUpdateAtom()

    const minAppVersionIOS = appUpdate[AppVersionHeader.IOS_MIN]
    const minAppVersionAndroid = appUpdate[AppVersionHeader.ANDROID_MIN]
    const currentAppVersionIOS = appUpdate[AppVersionHeader.IOS_CURRENT]
    const currentAppVersionAndroid = appUpdate[AppVersionHeader.ANDROID_CURRENT]

    const minAppVersion = isAndroid ? minAppVersionAndroid : minAppVersionIOS
    const currentAppVersion = isAndroid ? currentAppVersionAndroid : currentAppVersionIOS

    const { shouldForceUpdate, shouldGeneralUpdate } = shouldUpdateApp(minAppVersion, currentAppVersion)

    return { shouldForceUpdate, shouldGeneralUpdate }
}
