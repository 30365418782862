import React from 'react'
import { View } from 'react-native'
import { Field } from '@codegateinc/react-form-builder-v2'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { FormComponents, Typography } from 'lib/components'
import { BookingFrequency, FrequencyOption } from 'lib/types'
import { Segment, segmentUtils } from 'lib/analytics'

interface FrequencyOptionsProps extends Field<FrequencyOption> {
    options: Array<FrequencyOption>,
    primary?: BookingFrequency,
    primaryText?: string,
    disabled?: boolean
}

export const FrequencyOptions: React.FunctionComponent<FrequencyOptionsProps> = ({
    value,
    options,
    label,
    primary,
    primaryText,
    errorMessage,
    onChangeValue
}) => {
    const { styles } = useStyles(stylesheet)

    return (
        <View>
            <Typography.Regular bold>
                {label}
            </Typography.Regular>
            <View style={styles.optionsContainer}>
                {options.map((option, index) => (
                    <FormComponents.FrequencyOptionsItem
                        key={index}
                        index={index}
                        option={option}
                        isSelected={option.value === value?.value}
                        isPrimary={option.value === primary}
                        isLast={index === options.length - 1}
                        onChangeValue={() => {
                            Segment.bookingFrequencyClicked({
                                position: index + 1,
                                frequencyType: segmentUtils.getFrequencyType(option.value)
                            })

                            onChangeValue(option)
                        }}
                        primaryText={primaryText}
                    />
                ))}
            </View>
            <FormComponents.ErrorMessage text={errorMessage} />
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    optionsContainer: {
        marginTop: theme.utils.gap(3),
        flexDirection: 'row'
    }
}))
