import React, { useEffect, useRef, useState } from 'react'
import { NativeSyntheticEvent, TextInput, TextInputKeyPressEventData, View } from 'react-native'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { CustomFonts, Nullable } from 'lib/types'
import { regexes } from 'lib/utils'

type OTPInputProps = {
    length: number,
    value: Array<string>,
    disabled: boolean,
    error?: boolean,
    onChange(value: Array<string>): void,
}

export const OTPInput: React.FunctionComponent<OTPInputProps> = ({
    length,
    disabled,
    value,
    onChange,
    error = false
}) => {
    const { styles } = useStyles(stylesheet)
    const [focusedIndex, setFocusedIndex] = useState<Nullable<number>>(null)
    const [localState, setLocalState] = useState([...new Array(length)].map((item, index) => ({ index, value: '' })))
    const inputRefs = useRef<Array<Nullable<TextInput>>>([])

    const onChangeValue = (text: string, index: number) => {
        setLocalState(prevState => prevState.map(item => {
            if (item.index === index) {
                return {
                    index,
                    value: `${item.value}${text}`
                }
            }

            return {
                index: item.index,
                value: item.value
            }
        }))
        const newValue = value.map((item, valueIndex) => {
            if (valueIndex === index) {
                return text
            }

            return item
        })

        onChange(newValue)
    }

    const clearLocalState = () => {
        setFocusedIndex(null)
        setLocalState([...new Array(length)].map((item, index) => ({ index, value: '' })))
    }

    const handleChange = (text: string, index: number) => {
        if (!regexes.isValidDigit(text)) {
            return
        }

        onChangeValue(text, index)

        if (text.length !== 0) {
            return inputRefs?.current[index + 1]?.focus()
        }

        return inputRefs?.current[index - 1]?.focus()
    }

    const handleKeyPress = (event: NativeSyntheticEvent<TextInputKeyPressEventData>, index: number) => {
        const { nativeEvent } = event

        if (nativeEvent.key === 'Backspace') {
            return handleChange('', index)
        }

        if (!regexes.isValidDigit(nativeEvent.key)) {
            return
        }

        return handleChange(nativeEvent.key, index)
    }

    useEffect(() => {
        const longValue = localState.find(item => item.value.length === length)

        if (longValue) {
            onChange(longValue.value.split(''))
        }
    }, [localState])

    useEffect(() => {
        const [firstInput] = inputRefs.current || []

        firstInput?.focus()
    }, [])

    return (
        <View style={styles.container}>
            {[...new Array(length)].map((item, index) => (
                <TextInput
                    testID={`otp-number-${index}`}
                    ref={ref => {
                        if (ref && !inputRefs.current.includes(ref)) {
                            inputRefs.current = [...inputRefs.current, ref]
                        }
                    }}
                    key={index}
                    maxLength={1}
                    value={value[index]}
                    contextMenuHidden
                    editable={!disabled}
                    style={focusedIndex === index
                        ? {
                            ...styles.input,
                            ...styles.focused
                        }
                        : error
                            ? {
                                ...styles.input,
                                ...styles.withError
                            }
                            : styles.input
                    }
                    onBlur={clearLocalState}
                    onFocus={() => setFocusedIndex(index)}
                    keyboardType="decimal-pad"
                    onKeyPress={event => handleKeyPress(event, index)}
                />
            ))}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    input: {
        width: 50,
        height: 50,
        fontSize: {
            lg: 20
        },
        textAlign: 'center',
        fontFamily: CustomFonts.Poppins400,
        color: theme.components.OTPInput.typography.text,
        backgroundColor: theme.components.OTPInput.backgroundColor,
        borderRadius: theme.components.OTPInput.borderRadius,
        borderColor: theme.components.OTPInput.borderColor,
        borderWidth: theme.components.OTPInput.borderWidth
    },
    focused: {
        borderColor: theme.components.OTPInput.focusBorderColor
    },
    withError: {
        borderColor: theme.components.OTPInput.error.borderColor,
        color: theme.components.OTPInput.typography.error
    }
}))
