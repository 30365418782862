import { CustomerCharge, JobCancelReason, JobProposal } from 'lib/models'
import { JobType, ProposalAction } from 'lib/types'
import { DateTimeSlot } from 'features/bookings'

export type CancelJobRequest = {
    cancel_reason: JobCancelReason,
    token: string,
    id: string,
    job_type: JobType | undefined
}

export type RateJobRequest = {
    token: string,
    id: string,
    rate: number
}

export type ProposalActionRequest = {
    id: string, // This is job id not proposal :(
    job_proposal: JobProposal,
    proposal_action: ProposalAction,
    token: string,
}

export type RescheduleJobRequest = {
    token: string,
    job: string,
    new_date: string
}

export type ExtendJobRequest = {
    token: string,
    is_recurring: boolean,
    job_id: string,
    num_hours: string
}

export type PauseJobRequest = {
    id: string,
    token: string,
    pause_end_date: string,
    pause_start_date: string
}

export type ResumeJobRequest = {
    token: string,
    id: string
}

export type AcceptChargesRequest = {
    id: string,
    token: string,
    current_charges: Array<CustomerCharge>
}

export type RescheduleLaundryRequest = {
    id: string,
    token: string,
    pickup_slot: DateTimeSlot,
    post_status: PostStatus.UpdateDeliveryTimeSlot
}

export enum PostStatus {
    UpdateDeliveryTimeSlot = 'update_delivery_time_slot'
}
