import { useMutation, useQuery } from '@tanstack/react-query'
import { QueryKey, useFetcher } from 'lib/api'
import { ErrorResponse, HttpMethod, Response } from 'lib/types'
import { useNotificationAtom, useUserTokenAtom } from 'lib/atoms'
import { GetNotificationsResponse, GetNotificationDetailsRequest, GetNotificationDetailsResponse } from './types'

export const useGetNotifications = (enabled = false, onSuccess: VoidFunction) => {
    const [token] = useUserTokenAtom()
    const [, setNotifications] = useNotificationAtom()
    const fetcher = useFetcher<GetNotificationsResponse>(HttpMethod.POST, '/user-get-all-messages')

    return useQuery<Response<GetNotificationsResponse>, ErrorResponse>([QueryKey.Notification], () => fetcher({ token }), {
        enabled,
        onSuccess: ({ data }) => {
            setNotifications(data.messages)
            onSuccess()
        }
    })
}

export const useGetRecurringNotifications = () => {
    const [token] = useUserTokenAtom()
    const [, setNotifications] = useNotificationAtom()
    const fetcher = useFetcher<GetNotificationsResponse>(HttpMethod.POST, '/user-get-all-messages')

    return useQuery<Response<GetNotificationsResponse>, ErrorResponse>([QueryKey.RecurringNotification], () => fetcher({ token }), {
        enabled: Boolean(token),
        refetchInterval: 1000 * 30,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchIntervalInBackground: true,
        onSuccess: ({ data }) => setNotifications(data.messages)
    })
}

export const useGetNotificationDetails = () => {
    const [token] = useUserTokenAtom()
    const fetcher = useFetcher<GetNotificationDetailsResponse>(HttpMethod.POST, '/user-get-message')

    return useMutation<Response<GetNotificationDetailsResponse>, ErrorResponse, GetNotificationDetailsRequest>(request => fetcher({
        ...request,
        token
    }))
}
