import React from 'react'
import { View } from 'react-native'
import { JobStatus } from 'lib/types'
import { createStyles } from 'lib/styles'
import { LoadingIndicator, Typography } from 'lib/components'
import { useStyles, useTranslations } from 'lib/hooks'
import { useGetAllJobsData } from '../hooks'
import { JobDetailsResponse } from '../types'
import { getJobsDetails } from '../actions'
import { SessionDetailsTile } from './SessionDetailsTile'

type SessionDetailsProps = {
    job: JobDetailsResponse
}

export const SessionDetails: React.FunctionComponent<SessionDetailsProps> = ({
    job
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const { isLoading: isLoadingAllData, allJobsData } = useGetAllJobsData()
    const sessions = allJobsData
        .filter(item => job.job._id === item.recurring_job)
        .sort((firstDate, secondDate) => firstDate.date_time.localeCompare(secondDate.date_time))
    const data = getJobsDetails(sessions.map(session => session._id))
    const isLoadingSession = data.some(item => item.isLoading)
    const sessionsData = data
        .map(item => item.data?.data as JobDetailsResponse)
        .filter(item => item?.job.status === JobStatus.Accepted)
    const isLoading = isLoadingAllData || isLoadingSession

    if (sessionsData.length === 0) {
        return null
    }

    return (
        <View style={styles.content}>
            <LoadingIndicator isLoading={isLoading} />
            <Typography.Regular bold>
                {T.screens.jobDetails.sessionsDetails}
            </Typography.Regular>
            {sessionsData.map((item, index) => (
                <SessionDetailsTile
                    key={index}
                    job={item}
                />
            ))}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    content: {
        borderRadius: 8,
        borderWidth: 1,
        minHeight: 100,
        padding: theme.utils.gap(2),
        backgroundColor: theme.colors.white,
        borderColor: theme.colors.silver,
        backGroundColor: theme.colors.white,
        marginBottom: theme.utils.gap(2),
        ...theme.utils.createShadow(2, 0, 8, 4, 4, theme.colors.webShadow(0.04))
    }
}))
