import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const Cleaner: React.FunctionComponent<IconProps> = props => (
    <Icon viewBox="0 0 52 59" {...props}>
        <Path d="M45.5941 47.1077C47.0741 43.833 47.7097 40.2397 47.4428 36.656C47.176 33.0724 46.0151 29.6128 44.0664 26.5936C42.1176 23.5743 39.4432 21.0916 36.2875 19.3725C33.1319 17.6534 29.5956 16.7526 26.0021 16.7526C22.4085 16.7526 18.8723 17.6534 15.7166 19.3725C12.561 21.0916 9.88653 23.5743 7.9378 26.5936C5.98907 29.6128 4.82821 33.0724 4.56133 36.656C4.29445 40.2397 4.93008 43.833 6.41009 47.1077H45.5941Z" fill="white"/>
        <Path d="M1 46.5701H51" stroke="#F9B130" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M36.8389 54.312C36.6551 54.312 36.4788 54.239 36.3488 54.1091C36.2188 53.9791 36.1457 53.8029 36.1455 53.6191V52.1109C36.1455 51.927 36.2186 51.7506 36.3486 51.6206C36.4786 51.4905 36.655 51.4175 36.8389 51.4175C37.0228 51.4175 37.1991 51.4905 37.3292 51.6206C37.4592 51.7506 37.5323 51.927 37.5323 52.1109V53.6191C37.5321 53.8029 37.459 53.9791 37.329 54.1091C37.199 54.239 37.0227 54.312 36.8389 54.312Z" fill="white" stroke="#F9B130" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M38.2251 54.3121C38.0413 54.3121 37.8651 54.2391 37.7352 54.1092C37.6052 53.9792 37.5322 53.803 37.5322 53.6193V52.111C37.5373 51.9305 37.6125 51.7591 37.7419 51.6333C37.8714 51.5074 38.0448 51.4369 38.2253 51.4369C38.4059 51.4369 38.5793 51.5074 38.7088 51.6333C38.8382 51.7591 38.9134 51.9305 38.9184 52.111V53.6193C38.9183 53.8031 38.8452 53.9793 38.7152 54.1092C38.5852 54.2391 38.4089 54.3121 38.2251 54.3121Z" fill="white" stroke="#F9B130" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M39.6113 54.3121C39.4275 54.3121 39.2513 54.2391 39.1212 54.1092C38.9912 53.9793 38.9181 53.8031 38.918 53.6193V52.111C38.923 51.9305 38.9982 51.7591 39.1277 51.6333C39.2571 51.5074 39.4305 51.4369 39.6111 51.4369C39.7916 51.4369 39.9651 51.5074 40.0945 51.6333C40.2239 51.7591 40.2992 51.9305 40.3042 52.111V53.6193C40.3042 53.803 40.2312 53.9792 40.1013 54.1092C39.9713 54.2391 39.7951 54.3121 39.6113 54.3121Z" fill="white" stroke="#F9B130" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M40.9971 54.3121C40.8133 54.3121 40.637 54.2391 40.507 54.1092C40.377 53.9793 40.3039 53.8031 40.3037 53.6193V52.111C40.3087 51.9305 40.384 51.7591 40.5134 51.6333C40.6428 51.5074 40.8163 51.4369 40.9968 51.4369C41.1774 51.4369 41.3508 51.5074 41.4802 51.6333C41.6097 51.7591 41.6849 51.9305 41.6899 52.111V53.6193C41.6899 53.803 41.6169 53.9792 41.487 54.1092C41.3571 54.2391 41.1808 54.3121 40.9971 54.3121Z" fill="white" stroke="#F9B130" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M42.3848 54.312C42.201 54.312 42.0247 54.239 41.8947 54.1091C41.7647 53.9791 41.6915 53.8029 41.6914 53.6191V52.1109C41.6914 51.927 41.7645 51.7506 41.8945 51.6206C42.0245 51.4905 42.2009 51.4175 42.3848 51.4175C42.5687 51.4175 42.745 51.4905 42.8751 51.6206C43.0051 51.7506 43.0782 51.927 43.0782 52.1109V53.6191C43.078 53.8029 43.0049 53.9791 42.8749 54.1091C42.7449 54.239 42.5686 54.312 42.3848 54.312Z" fill="white" stroke="#F9B130" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M43.7713 54.3121C43.5878 54.3117 43.412 54.2385 43.2824 54.1086C43.1529 53.9787 43.0801 53.8027 43.0801 53.6193V52.111C43.0851 51.9305 43.1603 51.7591 43.2898 51.6333C43.4192 51.5074 43.5926 51.4369 43.7732 51.4369C43.9537 51.4369 44.1272 51.5074 44.2566 51.6333C44.386 51.7591 44.4613 51.9305 44.4663 52.111V53.6193C44.4662 53.7104 44.4482 53.8006 44.4132 53.8848C44.3782 53.969 44.327 54.0454 44.2625 54.1098C44.1979 54.1741 44.1213 54.2251 44.037 54.2598C43.9527 54.2945 43.8625 54.3123 43.7713 54.3121Z" fill="white" stroke="#F9B130" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M38.9356 37.583C38.6183 37.3936 38.2389 37.3376 37.8804 37.4273C37.5219 37.5169 37.2135 37.7449 37.0227 38.0614C36.7776 38.6058 36.4074 39.0846 35.9423 39.4589C35.6244 39.7013 35.2324 39.8262 34.8329 39.8126C34.3337 39.8142 33.8536 39.6207 33.495 39.2734C33.1364 38.926 32.9278 38.4524 32.9135 37.9534V35.4771H30.1953L30.2093 37.9539C30.2072 38.5627 30.3252 39.166 30.5565 39.7292C30.7877 40.2924 31.1277 40.8045 31.5571 41.2362C31.9864 41.6679 32.4966 42.0108 33.0585 42.2452C33.6204 42.4795 34.223 42.6009 34.8318 42.6022C35.4058 42.6198 35.9774 42.5207 36.5119 42.3107C37.0464 42.1008 37.5327 41.7845 37.9413 41.381C38.5609 40.8648 39.0626 40.2216 39.4124 39.4949C39.6016 39.1779 39.6577 38.7989 39.5683 38.4406C39.479 38.0824 39.2515 37.7741 38.9356 37.583Z" fill="white" stroke="#F9B130" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M32.448 54.5461C32.1252 54.6281 31.7016 54.7066 31.1882 54.7766C29.8544 54.9585 27.9958 55.0727 25.9321 55.0727C23.8684 55.0727 22.0099 54.9585 20.676 54.7766C20.1626 54.7066 19.739 54.6281 19.4163 54.5461C19.739 54.464 20.1626 54.3856 20.676 54.3156C22.0099 54.1337 23.8684 54.0194 25.9321 54.0194C27.9958 54.0194 29.8544 54.1337 31.1882 54.3156C31.7016 54.3856 32.1252 54.464 32.448 54.5461ZM18.815 54.3229C18.8153 54.3229 18.8183 54.3251 18.823 54.3296C18.817 54.3252 18.8147 54.3229 18.815 54.3229ZM18.815 54.7692C18.8147 54.7692 18.817 54.767 18.823 54.7626C18.8183 54.7671 18.8153 54.7693 18.815 54.7692ZM33.0412 54.7626C33.0472 54.767 33.0496 54.7692 33.0493 54.7692C33.0489 54.7693 33.0459 54.7671 33.0412 54.7626ZM33.0412 54.3296C33.046 54.3251 33.0489 54.3229 33.0493 54.3229C33.0496 54.3229 33.0472 54.3252 33.0412 54.3296Z" fill="#FFE9B6" stroke="#F9B130"/>
        <Path d="M25.9984 27.9771L25.9989 27.9771C26.9201 27.9762 27.8321 28.1522 28.6827 28.4944C29.5333 28.8367 30.3054 29.3385 30.9552 29.9704C31.605 30.6023 32.1197 31.3519 32.4706 32.1761C32.8215 33.0002 33.002 33.883 33.002 34.7743V35.0844H30.5837C30.3075 35.0844 30.0837 35.3083 30.0837 35.5844V41.9771H21.9202V35.5844C21.9202 35.3083 21.6964 35.0844 21.4202 35.0844H19.002V34.7743V34.7742C19.0019 33.8835 19.182 33.0011 19.5325 32.1774C19.883 31.3537 20.3971 30.6043 21.0462 29.9725C21.6953 29.3406 22.4667 28.8388 23.3165 28.4961C24.1664 28.1535 25.0778 27.977 25.9984 27.9771Z" fill="white" stroke="#F9B130" strokeLinejoin="round"/>
        <Path d="M22.9365 48.1444L23.2891 54.6648H28.6588L29.0114 48.1444H22.9365Z" stroke="#F9B130" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M29.9882 54.6648C30.1229 54.6647 30.2551 54.6291 30.3716 54.5618C30.4882 54.4944 30.585 54.3976 30.6523 54.281C30.7196 54.1644 30.7551 54.0322 30.7551 53.8975C30.7552 53.7629 30.7198 53.6306 30.6526 53.514C30.4507 53.1639 30.1601 52.8733 29.8101 52.6713C29.4601 52.4692 29.0631 52.363 28.659 52.3632H28.2752C27.6648 52.3632 27.0794 52.6057 26.6478 53.0373C26.2161 53.469 25.9736 54.0544 25.9736 54.6648H29.9882Z" fill="#FFE9B6" stroke="#F9B130" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M25.9747 54.6648C25.9747 54.0545 25.7323 53.4691 25.3008 53.0375C24.8693 52.6059 24.284 52.3633 23.6737 52.3632H23.2899C22.8858 52.3631 22.4888 52.4694 22.1388 52.6714C21.7889 52.8734 21.4983 53.164 21.2963 53.514C21.2289 53.6307 21.1934 53.763 21.1934 53.8977C21.1934 54.0325 21.2288 54.1648 21.2962 54.2815C21.3636 54.3982 21.4606 54.495 21.5773 54.5623C21.694 54.6296 21.8264 54.6649 21.9612 54.6648H25.9747Z" fill="#FFE9B6" stroke="#F9B130" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M25.9756 48.1444V54.6648" stroke="#F9B130" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M29.5287 48.1818H29.5287H22.8647H22.8647C22.7657 48.1818 22.6783 48.1471 22.6191 48.0962C22.5612 48.0466 22.5374 47.9891 22.5344 47.9403L22.5344 47.9402L22.1587 41.6568H30.2347L29.859 47.9402L29.859 47.9403C29.856 47.9891 29.8322 48.0466 29.7743 48.0962C29.7151 48.1471 29.6277 48.1818 29.5287 48.1818Z" fill="#FFE9B6" stroke="#F9B130"/>
        <Path d="M25.4639 31.3893C25.3191 31.4607 25.1969 31.5708 25.1108 31.7074C25.0246 31.844 24.9779 32.0018 24.9759 32.1633C24.9759 32.3353 25.0296 32.5041 25.0205 32.6755C25.0166 32.78 24.9909 32.8824 24.9452 32.9764C24.8995 33.0704 24.8347 33.1538 24.7549 33.2214C24.6752 33.2889 24.5823 33.3391 24.482 33.3688C24.3818 33.3985 24.2765 33.4069 24.1729 33.3936" stroke="#F9B130" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M25.9754 30.7352C25.9754 30.7255 25.9694 30.4331 25.9641 30.4245C25.8055 30.1487 25.2304 30.1831 24.68 30.5013C24.1296 30.8196 23.813 31.2995 23.9715 31.5764C24.1301 31.8532 24.7052 31.8172 25.2556 31.4995C25.5768 31.3364 25.8312 31.0668 25.9754 30.7368V30.7352Z" stroke="#F9B130" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M26.4854 31.3893C26.6302 31.4606 26.7525 31.5707 26.8387 31.7073C26.9248 31.844 26.9715 32.0018 26.9734 32.1633C26.9734 32.3353 26.9197 32.5041 26.9288 32.6755C26.9327 32.78 26.9584 32.8825 27.0041 32.9766C27.0499 33.0706 27.1147 33.154 27.1945 33.2216C27.2743 33.2892 27.3673 33.3394 27.4676 33.369C27.5679 33.3986 27.6733 33.407 27.777 33.3936" stroke="#F9B130" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M25.9756 30.7352C25.9756 30.7255 25.9815 30.4331 25.9869 30.4245C26.1481 30.1487 26.7206 30.1831 27.271 30.5013C27.8214 30.8196 28.1385 31.2995 27.9794 31.5764C27.8203 31.8532 27.2457 31.8172 26.6958 31.4995C26.3746 31.3364 26.12 31.0668 25.9756 30.7368V30.7352Z" stroke="#F9B130" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M19.0723 35.4771V44.1078C19.0723 44.4642 19.2138 44.8059 19.4658 45.0579C19.7178 45.31 20.0596 45.4515 20.416 45.4515C20.7724 45.4515 21.1142 45.31 21.3662 45.0579C21.6182 44.8059 21.7598 44.4642 21.7598 44.1078V35.4771H19.0723Z" fill="white" stroke="#F9B130" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M28.3455 28.4749C28.1843 28.4163 28.0198 28.3674 27.8532 28.3196C27.7371 28.2902 27.6339 28.2232 27.5599 28.1291C27.4858 28.035 27.4449 27.919 27.4436 27.7993V25.8981H24.5094V27.7993C24.5081 27.9191 24.4672 28.0352 24.393 28.1293C24.3188 28.2235 24.2155 28.2904 24.0992 28.3196C23.9326 28.3653 23.7681 28.4163 23.6074 28.4749V28.4803C23.7926 28.9588 24.1182 29.37 24.5415 29.66C24.9647 29.9501 25.4658 30.1053 25.9789 30.1053C26.492 30.1053 26.993 29.9501 27.4163 29.66C27.8395 29.37 28.1651 28.9588 28.3503 28.4803L28.3455 28.4749Z" fill="white" stroke="#F9B130" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M26.3339 17.0648C25.8351 17.9028 24.5822 18.0764 23.5346 17.4529C22.487 16.8294 22.043 15.6453 22.5413 14.8073C23.0395 13.9693 24.2935 13.7957 25.3406 14.4192C26.3877 15.0427 26.8327 16.2268 26.3339 17.0648Z" fill="white" stroke="#F9B130" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M25.2988 16.5661C26.025 17.2164 27.2731 17.0079 28.0858 16.0995C28.8985 15.1911 28.9694 13.9285 28.2427 13.2782C27.516 12.6278 26.2685 12.8363 25.4558 13.7442C24.6431 14.652 24.5721 15.9157 25.2988 16.5661Z" fill="white" stroke="#F9B130" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M30.6247 20.9165C30.6247 23.5773 28.5302 25.7104 25.9755 25.7104C23.4207 25.7104 21.3262 23.5773 21.3262 20.9165C21.3262 18.2558 23.4207 16.1227 25.9755 16.1227C28.5302 16.1227 30.6247 18.2558 30.6247 20.9165Z" fill="white" stroke="#F9B130"/>
        <Path d="M30.2752 21.4841C30.2398 21.4495 30.1977 21.4225 30.1515 21.4047C30.1053 21.3869 30.0559 21.3786 30.0065 21.3804C27.7801 21.4465 24.9158 21.0079 23.7601 19.1508C23.6123 20.6021 22.877 21.2369 21.8853 21.3465C21.7953 21.3563 21.7122 21.3991 21.6518 21.4666C21.5915 21.5341 21.5583 21.6215 21.5585 21.712V22.314C21.344 22.3541 21.1513 22.4708 21.0163 22.6423C20.8813 22.8138 20.8131 23.0285 20.8245 23.2464C20.8358 23.4644 20.9259 23.6708 21.0781 23.8274C21.2302 23.9839 21.434 24.0799 21.6515 24.0974C21.8583 25.0904 22.4008 25.9818 23.1877 26.6217C23.9747 27.2616 24.958 27.611 25.9722 27.611C26.9865 27.611 27.9698 27.2616 28.7567 26.6217C29.5436 25.9818 30.0861 25.0904 30.293 24.0974C30.5105 24.0798 30.7142 23.9837 30.8663 23.8272C31.0183 23.6706 31.1083 23.4641 31.1196 23.2462C31.1309 23.0282 31.0627 22.8136 30.9277 22.6421C30.7926 22.4707 30.5999 22.3541 30.3854 22.314V21.7228C30.3838 21.6777 30.3732 21.6333 30.3543 21.5924C30.3354 21.5514 30.3085 21.5146 30.2752 21.4841Z" fill="white" stroke="#F9B130" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M27.0781 25.0365C26.7597 25.2751 26.3725 25.4041 25.9746 25.4041C25.5767 25.4041 25.1895 25.2751 24.8711 25.0365" stroke="#F9B130" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M24.3696 22.2779C24.3696 22.3064 24.3465 22.3294 24.3181 22.3294C24.2896 22.3294 24.2666 22.3064 24.2666 22.2779C24.2666 22.2495 24.2896 22.2265 24.3181 22.2265C24.3465 22.2265 24.3696 22.2495 24.3696 22.2779Z" fill="#F9B130" stroke="#F9B130"/>
        <Path d="M27.6791 22.278C27.6791 22.3064 27.6561 22.3295 27.6277 22.3295C27.5992 22.3295 27.5762 22.3064 27.5762 22.278C27.5762 22.2495 27.5992 22.2265 27.6277 22.2265C27.6561 22.2265 27.6791 22.2495 27.6791 22.278Z" fill="#F9B130" stroke="#F9B130"/>
        <Path d="M37.6091 25.8721C37.5651 25.6113 37.4204 25.3783 37.2062 25.2232C36.992 25.0681 36.7254 25.0035 36.4639 25.0432C36.2024 25.0829 35.9671 25.2238 35.8085 25.4355C35.65 25.6472 35.5811 25.9127 35.6165 26.1747L39.3495 50.7499L41.342 50.4473L37.6091 25.8721Z" fill="#FFE9B6" stroke="#F9B130" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M44.4713 52.0887H36.14C35.9975 52.0887 35.8608 52.0321 35.76 51.9313C35.6592 51.8305 35.6025 51.6938 35.6025 51.5512V51.1508C35.6025 50.8657 35.7158 50.5922 35.9174 50.3906C36.119 50.189 36.3924 50.0758 36.6775 50.0758H43.9338C44.2189 50.0758 44.4924 50.189 44.694 50.3906C44.8956 50.5922 45.0088 50.8657 45.0088 51.1508V51.5539C45.0081 51.696 44.9512 51.832 44.8505 51.9322C44.7497 52.0325 44.6134 52.0887 44.4713 52.0887Z" fill="#FFE9B6" stroke="#F9B130" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M21.1772 6.34273C21.1989 6.27359 21.2009 6.19978 21.1829 6.12958C21.165 6.05938 21.1278 5.99557 21.0756 5.94532C21.0234 5.89507 20.9582 5.86037 20.8874 5.84512C20.8166 5.82987 20.7429 5.83466 20.6746 5.85897L19.1761 6.39648C19.1167 6.41769 19.053 6.42418 18.9906 6.41538C18.9281 6.40658 18.8688 6.38275 18.8175 6.34595L17.6211 5.48971C17.5621 5.44721 17.4924 5.42207 17.4199 5.41714C17.3474 5.41221 17.275 5.42769 17.2108 5.46181C17.1466 5.49594 17.0933 5.54735 17.0568 5.61023C17.0204 5.67311 17.0023 5.74494 17.0046 5.81759L17.0502 7.28981C17.0519 7.35312 17.0381 7.41589 17.01 7.47265C16.9819 7.52942 16.9403 7.57846 16.889 7.61553L15.6092 8.55348C15.5506 8.59644 15.5052 8.65495 15.4782 8.72237C15.4511 8.7898 15.4435 8.86345 15.4561 8.93499C15.4688 9.00653 15.5012 9.0731 15.5497 9.12717C15.5982 9.18125 15.6609 9.22066 15.7307 9.24094L17.2588 9.686C17.3194 9.70369 17.3749 9.73582 17.4204 9.77963C17.4658 9.82344 17.5 9.87763 17.52 9.93755L18.0194 11.449C18.0423 11.5179 18.084 11.5791 18.1398 11.6256C18.1956 11.6721 18.2633 11.7021 18.3352 11.7121C18.4071 11.7222 18.4805 11.7119 18.5469 11.6825C18.6133 11.6531 18.6702 11.6058 18.7111 11.5458L19.5711 10.2789C19.6067 10.2267 19.6544 10.184 19.7101 10.1543C19.7658 10.1246 19.8278 10.1089 19.8909 10.1085L21.4228 10.1009C21.4955 10.1007 21.5667 10.08 21.6282 10.0414C21.6898 10.0027 21.7393 9.94758 21.7711 9.88223C21.803 9.81688 21.8159 9.74392 21.8084 9.67162C21.8009 9.59932 21.7732 9.53056 21.7287 9.47315L20.7542 8.21485C20.7156 8.16491 20.6896 8.10635 20.6786 8.0442C20.6675 7.98204 20.6717 7.91814 20.6907 7.85795L21.1772 6.34273Z" fill="white" stroke="#FCB40A" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M14.4716 12.773C14.4559 12.7174 14.425 12.6673 14.3823 12.6284C14.3396 12.5894 14.2868 12.5632 14.23 12.5527C14.1732 12.5422 14.1145 12.5478 14.0607 12.5689C14.0069 12.59 13.9601 12.6257 13.9255 12.672L13.1676 13.6863C13.1376 13.7265 13.0982 13.7588 13.0529 13.7805C13.0076 13.8021 12.9577 13.8124 12.9075 13.8104L11.7368 13.7674C11.6791 13.7652 11.6218 13.7791 11.5716 13.8077C11.5214 13.8362 11.4801 13.8783 11.4526 13.9291C11.425 13.9798 11.4122 14.0373 11.4156 14.095C11.419 14.1527 11.4385 14.2083 11.4718 14.2555L12.148 15.2122C12.1768 15.2533 12.1953 15.3008 12.2019 15.3506C12.2084 15.4004 12.2028 15.451 12.1856 15.4982L11.7475 16.6866C11.7276 16.7409 11.7232 16.7996 11.7349 16.8562C11.7465 16.9128 11.7738 16.9651 11.8137 17.007C11.8535 17.0488 11.9043 17.0788 11.9602 17.0933C12.0162 17.1078 12.0751 17.1064 12.1303 17.0892L13.3396 16.7129C13.3877 16.6982 13.4387 16.6954 13.4882 16.7048C13.5376 16.7141 13.584 16.7354 13.6234 16.7667L14.6178 17.5498C14.6632 17.5857 14.7177 17.6081 14.7752 17.6146C14.8327 17.621 14.8908 17.6112 14.943 17.5863C14.9952 17.5614 15.0394 17.5224 15.0706 17.4737C15.1017 17.425 15.1186 17.3685 15.1193 17.3107L15.1354 16.0921C15.1361 16.0419 15.149 15.9925 15.173 15.9483C15.1969 15.9041 15.2313 15.8664 15.273 15.8384L16.2862 15.1606C16.3342 15.1285 16.3723 15.0836 16.3961 15.031C16.4199 14.9784 16.4285 14.9201 16.4209 14.8629C16.4134 14.8056 16.39 14.7516 16.3534 14.707C16.3167 14.6623 16.2683 14.6288 16.2137 14.6102L15.0145 14.2028C14.9669 14.1868 14.9239 14.1593 14.8894 14.1229C14.8548 14.0864 14.8297 14.0421 14.8162 13.9937L14.4716 12.773Z" fill="white" stroke="#FCB40A" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M33.3081 6.34273C33.2864 6.27359 33.2844 6.19978 33.3023 6.12958C33.3203 6.05938 33.3574 5.99557 33.4096 5.94532C33.4618 5.89507 33.527 5.86037 33.5978 5.84512C33.6687 5.82987 33.7424 5.83466 33.8106 5.85897L35.3086 6.39648C35.3681 6.41769 35.4318 6.42418 35.4944 6.41538C35.5569 6.40658 35.6164 6.38276 35.6677 6.34595L36.8631 5.48971C36.9221 5.44721 36.9917 5.42207 37.0643 5.41714C37.1368 5.41221 37.2092 5.42769 37.2734 5.46181C37.3376 5.49594 37.3909 5.54735 37.4273 5.61023C37.4638 5.67311 37.4819 5.74494 37.4796 5.81759L37.4339 7.28981C37.4323 7.35312 37.4462 7.41587 37.4743 7.47262C37.5024 7.52938 37.5439 7.57843 37.5952 7.61553L38.876 8.55348C38.9346 8.59644 38.98 8.65495 39.0071 8.72237C39.0341 8.7898 39.0418 8.86345 39.0291 8.93499C39.0165 9.00653 38.9841 9.0731 38.9355 9.12717C38.887 9.18125 38.8243 9.22066 38.7546 9.24094L37.2259 9.686C37.1654 9.70373 37.11 9.73588 37.0646 9.77969C37.0192 9.8235 36.9851 9.87767 36.9652 9.93755L36.4653 11.449C36.4426 11.518 36.4009 11.5792 36.3452 11.6257C36.2894 11.6722 36.2217 11.7022 36.1498 11.7122C36.0779 11.7223 36.0046 11.7121 35.9382 11.6826C35.8718 11.6532 35.815 11.6058 35.7741 11.5458L34.9141 10.2789C34.8785 10.2267 34.8308 10.184 34.7752 10.1544C34.7195 10.1247 34.6574 10.1089 34.5943 10.1085L33.0619 10.1009C32.9892 10.1006 32.9181 10.0798 32.8567 10.0411C32.7952 10.0025 32.7458 9.94732 32.714 9.88199C32.6822 9.81666 32.6694 9.74374 32.6769 9.67149C32.6844 9.59923 32.712 9.53053 32.7566 9.47315L33.7311 8.21485C33.7697 8.16491 33.7956 8.10635 33.8067 8.0442C33.8177 7.98204 33.8135 7.91814 33.7945 7.85795L33.3081 6.34273Z" fill="white" stroke="#FCB40A" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M40.0135 12.773C40.0292 12.7174 40.0602 12.6673 40.1029 12.6284C40.1456 12.5894 40.1983 12.5632 40.2551 12.5527C40.312 12.5422 40.3706 12.5478 40.4244 12.5689C40.4782 12.59 40.5251 12.6257 40.5596 12.672L41.3175 13.6863C41.3474 13.7266 41.3868 13.759 41.4321 13.7807C41.4775 13.8023 41.5274 13.8125 41.5776 13.8104L42.7483 13.7653C42.8061 13.763 42.8633 13.7769 42.9135 13.8055C42.9638 13.8341 43.005 13.8761 43.0326 13.9269C43.0601 13.9777 43.073 14.0352 43.0696 14.0929C43.0661 14.1506 43.0467 14.2061 43.0133 14.2533L42.3371 15.2101C42.3082 15.2511 42.2896 15.2986 42.2831 15.3484C42.2765 15.3982 42.2822 15.4489 42.2995 15.496L42.737 16.6845C42.7572 16.7387 42.7617 16.7974 42.7501 16.8541C42.7386 16.9107 42.7113 16.963 42.6715 17.0049C42.6317 17.0469 42.5809 17.0768 42.5249 17.0913C42.469 17.1058 42.41 17.1043 42.3549 17.087L41.145 16.7108C41.0969 16.6961 41.046 16.6933 40.9967 16.7026C40.9473 16.712 40.901 16.7332 40.8617 16.7645L39.8668 17.5477C39.8214 17.5836 39.7669 17.6061 39.7095 17.6125C39.652 17.619 39.5939 17.6092 39.5417 17.5843C39.4895 17.5594 39.4454 17.5203 39.4143 17.4716C39.3832 17.4228 39.3664 17.3663 39.3658 17.3085L39.3497 16.09C39.349 16.0397 39.3361 15.9904 39.3121 15.9462C39.2882 15.902 39.2538 15.8643 39.2121 15.8363L38.1989 15.1585C38.1509 15.1264 38.1129 15.0815 38.0891 15.0288C38.0652 14.9762 38.0566 14.918 38.0642 14.8607C38.0717 14.8035 38.0951 14.7495 38.1318 14.7048C38.1684 14.6602 38.2168 14.6267 38.2715 14.6081L39.4706 14.2007C39.5181 14.1845 39.561 14.157 39.5956 14.1206C39.6301 14.0841 39.6553 14.0399 39.669 13.9916L40.0135 12.773Z" fill="white" stroke="#FCB40A" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M31.177 4.75332C31.2433 4.68864 31.2902 4.60668 31.3125 4.51672C31.3347 4.42676 31.3313 4.33238 31.3026 4.24425C31.274 4.15612 31.2213 4.07775 31.1504 4.01802C31.0796 3.95828 30.9935 3.91956 30.9018 3.90622L28.8926 3.61436C28.8128 3.60347 28.7368 3.57332 28.6712 3.52649C28.6057 3.47967 28.5525 3.41758 28.5163 3.3456L27.6875 1.65838C27.6465 1.5753 27.583 1.50536 27.5043 1.45645C27.4256 1.40754 27.3348 1.38162 27.2422 1.38162C27.1495 1.38162 27.0587 1.40754 26.98 1.45645C26.9013 1.50536 26.8379 1.5753 26.7969 1.65838L25.9659 3.34292C25.9297 3.4149 25.8765 3.47698 25.811 3.5238C25.7454 3.57063 25.6694 3.60079 25.5896 3.61167L23.5804 3.90353C23.4887 3.91687 23.4026 3.9556 23.3318 4.01533C23.2609 4.07507 23.2082 4.15343 23.1796 4.24156C23.1509 4.32969 23.1475 4.42407 23.1697 4.51403C23.192 4.604 23.2389 4.68595 23.3052 4.75064L24.7565 6.16749C24.8142 6.22367 24.8574 6.29306 24.8823 6.36966C24.9072 6.44627 24.9131 6.52778 24.8995 6.60717L24.5565 8.60776C24.5407 8.6991 24.5508 8.79304 24.5856 8.87894C24.6205 8.96484 24.6787 9.03926 24.7537 9.09376C24.8287 9.14826 24.9174 9.18066 25.0099 9.1873C25.1023 9.19393 25.1948 9.17453 25.2768 9.13129L27.0065 8.22183C27.0778 8.18449 27.1571 8.16498 27.2376 8.16498C27.3181 8.16498 27.3974 8.18449 27.4687 8.22183L29.1984 9.13129C29.2804 9.17453 29.3729 9.19393 29.4653 9.1873C29.5578 9.18066 29.6466 9.14826 29.7215 9.09376C29.7965 9.03926 29.8547 8.96484 29.8896 8.87894C29.9244 8.79304 29.9345 8.6991 29.9187 8.60776L29.5757 6.60717C29.5621 6.52783 29.568 6.44636 29.5928 6.36978C29.6176 6.29319 29.6606 6.22377 29.7182 6.16749L31.177 4.75332Z" fill="white" stroke="#FCB40A" strokeLinecap="round" strokeLinejoin="round"/>
    </Icon>
)

