import { useTranslations } from 'lib/hooks'
import { JobStatus } from 'lib/types'

export const useEmptyBookingsTitle = (jobStatus: JobStatus) => {
    const T = useTranslations()

    switch (jobStatus) {
        case JobStatus.Completed:
            return T.screens.bookings.empty.completed
        case JobStatus.Cancelled:
            return T.screens.bookings.empty.cancelled
        case JobStatus.Submitted:
        default:
            return T.screens.bookings.empty.upcoming
    }
}
