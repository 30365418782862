import React from 'react'
import { View } from 'react-native'
import { Icons } from 'assets'
import { FormComponents, Grid, Touchable, Typography } from 'lib/components'
import { useFormatPrice, useStyles } from 'lib/hooks'
import { Segment } from 'lib/analytics'
import { createStyles } from 'lib/styles'
import { PestControlSession } from '../../types'

type PestControlServiceTileProps = {
    service: PestControlSession,
    onChangeValue(updatedService: PestControlSession): void
}

export const PestControlServiceTile: React.FunctionComponent<PestControlServiceTileProps> = ({
    service,
    onChangeValue
}) => {
    const { styles, theme } = useStyles(stylesheet)
    const formatPrice = useFormatPrice()

    return (
        <View
            key={service.title}
            style={{
                ...styles.row,
                ...styles.container
            }}
        >
            <FormComponents.Checkbox
                isSelected={Boolean(service.isSelected)}
                style={styles.checkbox}
                onChange={() => {
                    if (!service.isSelected) {
                        Segment.bookingPestControlServiceSelected({
                            service: service.title
                        })
                    }

                    onChangeValue({
                        ...service,
                        isSelected: !service.isSelected
                    })
                }}
                renderComponent={() => (
                    <Touchable
                        style={styles.checkboxLabel}
                        onPress={() => {
                            if (!service.isSelected) {
                                Segment.bookingPestControlServiceSelected({
                                    service: service.title
                                })
                            }

                            onChangeValue({
                                ...service,
                                isSelected: !service.isSelected
                            })
                        }}
                    >
                        <Typography.Regular>
                            {service.pestCategory}
                        </Typography.Regular>
                        <Typography.Label>
                            {formatPrice(service.price_per_treatment * (service.minNumberOfTreatments || 1))}
                            <Typography.Label forceColor={theme.colors.fog}>
                                {service.note ? ` ${service.note}` : ''}
                            </Typography.Label>
                        </Typography.Label>
                    </Touchable>
                )}
            />
            <View style={styles.row}>
                <Touchable
                    onPress={() => onChangeValue({
                        ...service,
                        number_of_treatments: Math.max(service.minNumberOfTreatments || 0, service.number_of_treatments - 1),
                        isSelected: Boolean(service?.number_of_treatments - 1 > 0)
                    })}
                    style={styles.amountTile}
                >
                    <Icons.Minus size={12}/>
                </Touchable>
                <Grid.Gap gapRight={2}/>
                <Typography.Regular>
                    {service.number_of_treatments}
                </Typography.Regular>
                <Grid.Gap gapRight={2}/>
                <Touchable
                    onPress={() => onChangeValue({
                        ...service,
                        number_of_treatments: service.number_of_treatments + 1,
                        isSelected: true
                    })}
                    style={styles.amountTile}
                >
                    <Icons.Plus size={12}/>
                </Touchable>
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        paddingTop: theme.utils.gap(2),
        paddingBottom: 4,
        borderTopWidth: 1,
        borderColor: theme.colors.silver
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    checkbox: {
        marginBottom: -theme.utils.gap(2)
    },
    checkboxLabel: {
        paddingTop: theme.utils.gap(1)
    },
    amountTile: {
        width: 32,
        height: 32,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.colors.silver
    }
}))
