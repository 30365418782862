import { useViewOptionAtom } from 'lib/atoms'
import { useViewStyleOptions } from './useViewStyleOptions'

export const useBookingsViewStyle = () => {
    const viewStyleOptions = useViewStyleOptions()
    const [initialViewStyle] = viewStyleOptions
    const [viewStyle, setViewStyle] = useViewOptionAtom()

    return {
        viewStyle: viewStyle.value
            ? viewStyle
            : initialViewStyle,
        setViewStyle,
        viewStyleOptions
    }
}
