import { useField } from '@codegateinc/react-form-builder-v2'
import { dateHelpers } from 'lib/utils'
import { useTranslations } from 'lib/hooks'
import { bookingsHooks } from 'features/bookings'
import { BookingFrequency, FrequencyOption, Nullable, SupplyID } from 'lib/types'
import { getServiceStaticConfig } from 'features/bookings/utils'
import { RescheduleFields } from './forms'

export const useRescheduleForm = () => {
    const T = useTranslations()
    const frequencyOptions = bookingsHooks.useFrequencyOptions()
    const bookingDaysInitialValue = bookingsHooks.useBookingDays()
    const config = getServiceStaticConfig(SupplyID.Cleaner)

    const frequency = useField<Nullable<FrequencyOption>>({
        key: RescheduleFields.Frequency,
        initialValue: frequencyOptions.find(option => option.value === BookingFrequency.OneTime) || null,
        isRequired: false,
        validateOnBlur: false,
        label: T.screens.deepCleaning.formFields.frequency.label
    })

    const startingDate = useField<string>({
        key: RescheduleFields.StartingDate,
        initialValue: '',
        isRequired: true,
        validateOnBlur: false,
        label: T.screens.deepCleaning.formFields.startingDate.label,
        placeholder: T.screens.deepCleaning.formFields.startingDate.placeholder,
        validationRules: [
            {
                errorMessage: T.screens.deepCleaning.formFields.startingDate.validation.isRequired,
                validate: Boolean
            }
        ]
    })

    const bookingTime = useField<string>({
        key: RescheduleFields.BookingTime,
        initialValue: '',
        validateOnBlur: true,
        isRequired: frequency.value?.value === BookingFrequency.OneTime,
        placeholder: T.screens.deepCleaning.formFields.selectTime.placeHolder,
        validationRules: [
            {
                errorMessage: T.screens.deepCleaning.formFields.selectTime.validation.isRequired,
                validate: value => {
                    if (frequency.value?.value !== BookingFrequency.OneTime) {
                        return true
                    }

                    return Boolean(value)
                }
            },
            {
                errorMessage: `${config.min_notice_hours} ${T.screens.deepCleaning.formFields.selectTime.validation.minimumHourNotice}`,
                validate: value => {
                    if (frequency.value?.value !== BookingFrequency.OneTime) {
                        return true
                    }

                    const isPast = dateHelpers.isDatePast(dateHelpers.subHoursFromDate(value, config.min_notice_hours))

                    return !isPast
                }
            }
        ]
    })

    const bookingDays = useField<Record<string, Nullable<string>>>({
        key: RescheduleFields.BookingDays,
        initialValue: bookingDaysInitialValue,
        validateOnBlur: true,
        isRequired: frequency.value?.value !== BookingFrequency.OneTime,
        placeholder: T.screens.deepCleaning.formFields.selectTime.placeHolder,
        validationRules: [
            {
                errorMessage: T.screens.deepCleaning.formFields.selectTime.validation.isRequired,
                validate: value => {
                    if (frequency.value?.value === BookingFrequency.OneTime) {
                        return true
                    }

                    return Object.values(value).some(Boolean)
                }
            },
            {
                errorMessage: `${config.min_notice_hours} ${T.screens.deepCleaning.formFields.selectTime.validation.minimumHourNotice}`,
                validate: value => {
                    const times = Object.values(value).filter(item => Boolean(item)) as Array<string>
                    const isSomePast = times.some(time => dateHelpers.isDatePast(dateHelpers.subHoursFromDate(time, config.min_notice_hours)))

                    return !isSomePast
                }
            }
        ]
    })

    return {
        frequency,
        startingDate,
        bookingTime,
        bookingDays
    }
}
