import React from 'react'
import { View } from 'react-native'
import { useLocaleAtom } from 'lib/atoms'
import { Typography } from 'lib/components'
import { useFormatPrice, useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { dateHelpers } from 'lib/utils'
import { JobDetailsResponse } from '../../types'
import { DetailsExpert } from '../DetailsExpert'
import { RescheduleLaundry } from './RescheduleLaundry'

type LaundryServiceDateProps = {
    job: JobDetailsResponse,
    isSubmitted: boolean
}

export const LaundryServiceDate: React.FunctionComponent<LaundryServiceDateProps> = ({
    job,
    isSubmitted
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const [locale] = useLocaleAtom()
    const { job: { date_time, bookingSummary, promotion } } = job
    const formatPrice = useFormatPrice()
    const totalPrice = formatPrice(bookingSummary?.total?.value)
    const deliveryDate = job.job.laundry_options.delivery_slot?.date

    return (
        <View>
            {!isSubmitted && (
                <DetailsExpert job={job} />
            )}
            <View style={styles.wrapper}>
                <View style={styles.flex}>
                    <View style={styles.title}>
                        <Typography.Label forceColor={theme.colors.mouse}>
                            {T.screens.laundryDetails.collectionDate}
                        </Typography.Label>
                    </View>
                    <Typography.Label style={styles.laundryDays}>
                        {date_time
                            ? `${dateHelpers.getFullBookingListViewDate(date_time, locale)} - ${job.job.laundry_options.pickup_slot.display}`
                            : '-'
                        }
                    </Typography.Label>
                </View>
                <View style={styles.flex}>
                    <View style={styles.title}>
                        <Typography.Label forceColor={theme.colors.mouse}>
                            {T.screens.laundryDetails.deliveryDate}
                        </Typography.Label>
                    </View>
                    <Typography.Label>
                        {deliveryDate
                            ? dateHelpers.getFullBookingDate(deliveryDate, locale)
                            : '-'
                        }
                    </Typography.Label>
                </View>
            </View>
            <RescheduleLaundry job={job.job} />
            <View style={styles.wrapper}>
                <View style={styles.flex}>
                    <View style={styles.title}>
                        <Typography.Label forceColor={theme.colors.mouse}>
                            {T.screens.jobDetails.sections.promotionCode}
                        </Typography.Label>
                    </View>
                    <Typography.Label>
                        {promotion?.code ?? '-'}
                    </Typography.Label>
                </View>
                {totalPrice && (
                    <View style={styles.flex}>
                        <View style={styles.title}>
                            <Typography.Label forceColor={theme.colors.mouse}>
                                {T.screens.jobDetails.sections.totalAmount}
                            </Typography.Label>
                        </View>
                        <Typography.Label>
                            {totalPrice ?? 0}
                        </Typography.Label>
                    </View>
                )}
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        flexDirection: 'row',
        marginBottom: theme.utils.gap(3)
    },
    title: {
        marginBottom: theme.utils.gap(1)
    },
    flex: {
        flex: 1
    },
    laundryDays: {
        textTransform: 'capitalize'
    }
}))
