import React from 'react'
import { View } from 'react-native'
import Animated, { useAnimatedScrollHandler, useDerivedValue, useSharedValue } from 'react-native-reanimated'
import { useNavigation } from '@react-navigation/native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { Icons, Images } from 'assets'
import { Button } from 'lib/components'
import { createStyles } from 'lib/styles'
import { Measurements } from 'lib/common'
import { ScreenNames } from 'lib/routing'
import { useOnboardingAtom } from 'lib/atoms'
import { useStyles, useTranslations } from 'lib/hooks'
import { OnboardingBackground, OnboardingPage, OnboardingPaginationDot, OnboardingText } from '../components'

export const OnboardingNativeScreen: React.FunctionComponent = () => {
    const T = useTranslations()
    const insets = useSafeAreaInsets()
    const { styles, theme } = useStyles(stylesheet)
    const navigation = useNavigation()
    const [, setOnboarding] = useOnboardingAtom()
    const animatedX = useSharedValue(0)
    const activeIndex = useDerivedValue(() => Math.round(animatedX.value / Measurements.WindowWidth))
    const scrollHandler = useAnimatedScrollHandler(event => {
        animatedX.value = event.contentOffset.x
    })
    const pages = [
        Images.OnboardingHomeServices,
        Images.OnboardingTopQuality,
        Images.OnboardingNoCashPayment,
        Images.OnboardingRatings
    ]
    const pagesContent = Object.values(T.screens.onboarding.content)

    return (
        <View style={styles.wrapper}>
            <OnboardingBackground />
            <Animated.ScrollView
                horizontal
                pagingEnabled
                scrollEventThrottle={16}
                onScroll={scrollHandler}
                showsHorizontalScrollIndicator={false}
                style={{
                    ...styles.carousel,
                    marginTop: insets.top + theme.utils.gap(10)
                }}
            >
                {pages.map((page, index) => (
                    <OnboardingPage
                        key={index}
                        image={page}
                        index={index}
                        activeIndex={activeIndex}
                    />
                ))}
            </Animated.ScrollView>
            <View
                style={{
                    ...styles.contentWrapper,
                    paddingBottom: insets.bottom + theme.utils.gap(3)
                }}
            >
                <View style={styles.pagination}>
                    {[...new Array(pages.length)].map((item, index) => (
                        <OnboardingPaginationDot
                            key={index}
                            index={index}
                            activeIndex={activeIndex}
                        />
                    ))}
                </View>
                <View style={styles.pagesContent}>
                    {pagesContent.map((page, index) => (
                        <OnboardingText
                            key={index}
                            index={index}
                            activeIndex={activeIndex}
                            title={page.title}
                            message={page.message}
                        />
                    ))}
                </View>
                <Button
                    text={T.screens.onboarding.getStarted}
                    onPress={() => {
                        setOnboarding(false)
                        navigation.navigate(ScreenNames.SignUpNative)
                    }}
                    renderIcon={color => (
                        <Icons.Arrow
                            size={14}
                            forceColor={color}
                        />
                    )}
                />
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        flex: 1,
        backgroundColor: theme.colors.white
    },
    carousel: {
        flex: 1
    },
    pagesContent: {
        height: 120,
        position: 'relative'
    },
    pagination: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginVertical: theme.utils.gap(2)
    },
    contentWrapper: {
        paddingHorizontal: theme.utils.gap(2)
    }
}))
