import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const Bell: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="m10.062 55.999 4.72-4.729a7.946 7.946 0 0 0 2.344-5.656V26.905c0-5.428 2.36-10.617 6.48-14.225 4.153-3.64 9.441-5.24 14.95-4.516 9.308 1.236 16.329 9.652 16.329 19.581v17.869c0 2.136.832 4.144 2.34 5.652L61.949 56H10.062Zm33.942 9.364c0 3.596-3.664 6.636-8 6.636-4.337 0-8.001-3.04-8.001-6.636V64h16v1.364ZM70.089 52.83l-7.204-7.216V27.745c0-13.925-10.009-25.753-23.281-27.51a26.888 26.888 0 0 0-21.27 6.429c-5.856 5.128-9.208 12.505-9.208 20.241l-.004 18.709-7.205 7.216A6.525 6.525 0 0 0 .501 59.96a6.529 6.529 0 0 0 6.049 4.04h13.453v1.364c0 8.073 7.176 14.637 16 14.637 8.825 0 16.001-6.564 16.001-14.637V64h13.453a6.516 6.516 0 0 0 6.04-4.036 6.518 6.518 0 0 0-1.408-7.133Z"
        />
    </Icon>
)
