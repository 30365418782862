import React from 'react'
import { View } from 'react-native'
import { Field } from '@codegateinc/react-form-builder-v2'
import { useIsWithinBreakpoints, useStyles, useTranslations } from 'lib/hooks'
import { Breakpoint, createStyles, theme } from 'lib/styles'
import { ProviderDetails } from 'lib/models'
import { Adapter, Button, Typography, Grid } from 'lib/components'
import { Icons } from 'assets'
import { COUNTRIES } from 'lib/data'
import { isWeb } from 'lib/common'
import { SignUpFormShape } from '../forms'
import { CustomFonts } from 'lib/types'

type SignUpFormProps = {
    providerDetails: ProviderDetails,
    formHasError: boolean,
    isLoading: boolean,
    form: Record<keyof SignUpFormShape, Field<string & boolean>>,
    onFocus?: VoidFunction,
    emailDisabled?: boolean,
    onSubmit: VoidFunction
}

export const SignUpForm: React.FunctionComponent<SignUpFormProps> = ({
    form,
    onFocus,
    onSubmit,
    isLoading,
    providerDetails,
    formHasError,
    emailDisabled
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const isMobile = useIsWithinBreakpoints(Breakpoint.XS, Breakpoint.MD)

    const renderRadioLabel = () => (
        <View style={styles.marketingLabelContainer}>
            <Typography.Regular style={styles.marketingLabel}>
                <Typography.Regular style={{
                    ...styles.marketingLabel,
                    ...styles.orangeText
                }}>
                    {T.screens.auth.marketingConsent.getVoucher}
                </Typography.Regular>
                {` ${T.screens.auth.marketingConsent.benefit}`}
            </Typography.Regular>
        </View>
    )

    const renderRadioTrueLabel = () => (
        <View style={styles.marketingOptionContainer}>
            <Typography.Regular style={styles.marketingOptionLabel}>
                <Typography.Regular style={{
                    ...styles.marketingOptionLabel,
                    ...styles.marketingOptionValueLabel
                }}>
                    {T.screens.auth.marketingConsent.yes}
                </Typography.Regular>
                {T.screens.auth.marketingConsent.descriptionOfYes}
            </Typography.Regular>
        </View>
    )

    const renderRadioFalseLabel = () => (
        <View style={styles.marketingOptionContainer}>
            <Typography.Regular style={styles.marketingOptionLabel}>
                <Typography.Regular style={{
                    ...styles.marketingOptionLabel,
                    ...styles.marketingOptionValueLabel
                }}>
                    {T.screens.auth.marketingConsent.no}
                </Typography.Regular>
                {T.screens.auth.marketingConsent.descriptionOfNo}
            </Typography.Regular>
        </View>
    )

    const renderRadioActiveButton = () => isMobile ? (
        <View style={styles.checkBoxContainer}>
            <Icons.CheckSquare viewBox="0 0 18 18" forceColor={theme.colors.orange} size={18}/>
        </View>
    ) : null

    const renderRadioInactiveButton = () => isMobile ? (
        <View style={styles.checkBoxContainer}>
            <View style={styles.inactiveCheckBox}/>
        </View>
    ) : null

    return (
        <React.Fragment>
            <Adapter.TextInput
                {...form.name}
                disabled={isLoading}
                onFocus={onFocus}
                inputProps={{
                    autoCapitalize: 'words',
                    textContentType: 'name',
                    testID: 'name'
                }}
            />
            <View style={styles.row}>
                <Grid.Gap gapRight={1}>
                    <Adapter.SelectCountryCodeInput
                        {...form.country}
                        isClearable={false}
                        options={COUNTRIES}
                        disabled={isLoading}
                    />
                </Grid.Gap>
                <View style={styles.phoneInput}>
                    <Adapter.TextInput
                        {...form.phoneNumber}
                        inputProps={{
                            keyboardType: 'phone-pad',
                            contextMenuHidden: true,
                            textContentType: 'telephoneNumber',
                            dataDetectorTypes: 'phoneNumber',
                            testID: 'phone-number'
                        }}
                        disabled={isLoading}
                    />
                </View>
            </View>
            <Adapter.TextInput
                {...form.email}
                inputProps={{
                    keyboardType: 'email-address',
                    textContentType: 'emailAddress',
                    autoCapitalize: 'none',
                    testID: 'email-address'
                }}
                disabled={isLoading || emailDisabled}
            />
            {providerDetails === ProviderDetails.Email && (
                <React.Fragment>
                    <Adapter.TextInput
                        {...form.password}
                        inputProps={{
                            autoCorrect: false,
                            textContentType: 'password',
                            autoComplete: isWeb
                                ? undefined
                                : 'password',
                            secureTextEntry: true,
                            testID: 'sign-up-password'
                        }}
                        disabled={isLoading}
                    />
                    <Adapter.TextInput
                        {...form.confirmPassword}
                        inputProps={{
                            autoCorrect: false,
                            textContentType: 'password',
                            autoComplete: isWeb
                                ? undefined
                                : 'password',
                            secureTextEntry: true,
                            testID: 'confirm-sign-up-password'
                        }}
                        disabled={isLoading}
                    />
                </React.Fragment>
            )}

            <View style={styles.marketingContainer}>
                <Adapter.Radio
                    {...form.marketingConsent}
                    disabled={isLoading}
                    optionCustomStyles={styles.optionCustomStyles}
                    containerCustomStyles={styles.containerCustomStyles}
                    activeRadioCustomStyles={styles.activeRadioCustomStyles}
                    inactiveRadioCustomStyles={styles.inactiveRadioCustomStyles}
                    customLabel={renderRadioLabel()}
                    customTrueLabel={renderRadioTrueLabel()}
                    customFalseLabel={renderRadioFalseLabel()}
                    customActiveButton={renderRadioActiveButton()}
                    customInactiveButton={renderRadioInactiveButton()}
                />
            </View>
            <View style={styles.submit}>
                <Button
                    testID="sign-up-button"
                    onPress={onSubmit}
                    text={T.common.signUp}
                    isLoading={isLoading}
                    disabled={formHasError}
                    renderIcon={color => (
                        <Icons.Arrow
                            size={14}
                            forceColor={color}
                        />
                    )}
                />
            </View>
        </React.Fragment>
    )
}

const stylesheet = createStyles(theme => ({
    submit: {
        alignItems: 'center'
    },
    row: {
        flex: 1,
        zIndex: theme.zIndex[10],
        flexDirection: 'row'
    },
    phoneInput: {
        flex: 1
    },
    marketingContainer: {
        backgroundColor: theme.colors.peach,
        padding: theme.utils.gap(1.7),
        marginBottom: theme.utils.gap(2),
        borderRadius: 8
    },
    marketingLabelContainer: {
        paddingBottom: theme.utils.gap(1)
    },
    marketingOptionContainer: {
        width: '100%',
        paddingRight: theme.utils.gap(1.75)
    },
    marketingLabel: {
        fontFamily: CustomFonts.Poppins500,
        color:  theme.colors.night,
        fontSize: 13,
        textTransform: 'capitalize'
    },
    orangeText: {
        color: theme.colors.orange
    },
    marketingOptionLabel: {
        fontFamily: CustomFonts.Roboto400,
        color:  theme.colors.night,
        fontSize: 13,
        lineHeight: 20
    },
    marketingOptionValueLabel: {
        fontFamily: CustomFonts.Roboto700
    },
    inactiveCheckBox: {
        borderWidth: 2,
        borderColor: theme.colors.black,
        borderRadius: 3,
        width: 18,
        height: 18
    },
    checkBoxContainer: {
        marginRight: theme.utils.gap(0.75),
        marginTop: theme.utils.gap(0.1)
    },
    optionCustomStyles: {
        alignItems: 'flex-start'
    },
    containerCustomStyles: {
        marginVertical: 0
    },
    activeRadioCustomStyles: {
        borderColor: theme.colors.orange
    },
    inactiveRadioCustomStyles: {
        borderColor: theme.colors.black
    }
}))
