import { useMemo } from 'react'
import { useSharedServiceConfigAtom } from '../atoms'

export const useSupplyOptions = (onlyFavourite?: boolean) => {
    const [availableServices] = useSharedServiceConfigAtom()

    return useMemo(() => availableServices
        .sort((itemA, itemB) => itemA.sort_order - itemB.sort_order)
        .filter(item => {
            if (!onlyFavourite) {
                return true
            }

            return item.use_preferred_helper
        })
        .map(item => ({
            label: item.name,
            value: item.id?.toString()
        })), []
    )
}
