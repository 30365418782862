import React, { useState } from 'react'
import { ScrollView, TextInput, View } from 'react-native'
import Animated from 'react-native-reanimated'
import { Icons } from 'assets'
import { createStyles } from 'lib/styles'
import { IconProps, SelectInputProps } from 'lib/types'
import { useStyles, useTextInputDropdown } from 'lib/hooks'
import { Touchable } from './Touchable'
import { FormComponents } from './form'

export const SearchableInput: React.FunctionComponent<SelectInputProps> = ({
    value,
    onChange,
    errorMessage,
    options,
    leftIcon,
    placeholder,
    onInputChange
}) => {
    const [query, setQuery] = useState('')
    const { styles, theme } = useStyles(stylesheet)
    const data = options.filter(option => option.label.toLowerCase().includes(query.toLowerCase()))
    const {
        ref,
        controlledValue,
        animatedChevronStyles,
        animatedContainerStyles,
        onInputBlur,
        onInputFocus,
        setControlledValue,
        onSelectOption,
        handleChevronPress
    } = useTextInputDropdown({
        value,
        onChange,
        onMenuClosed: () => setQuery('')
    })
    const getBorderColor = () => {
        if (errorMessage) {
            return theme.components.input.error.borderColor
        }

        if (!controlledValue) {
            return theme.components.input.emptyBorderColor
        }

        return theme.components.input.filledBorderColor
    }
    const handleInputChange = (value: string) => {
        setQuery(value)
        setControlledValue(value)

        if (onInputChange) {
            onInputChange(value)
        }
    }
    const LeftIcon = leftIcon ? React.createElement<IconProps>(leftIcon, {
        size: 20
    }) : null

    return (
        <View>
            <View style={styles.container}>
                <TextInput
                    ref={ref}
                    onFocus={onInputFocus}
                    onBlur={onInputBlur}
                    style={{
                        ...styles.input,
                        paddingLeft: LeftIcon ? 50 : 20,
                        borderColor: getBorderColor()
                    }}
                    placeholder={placeholder}
                    value={controlledValue}
                    onChangeText={handleInputChange}
                />
                {leftIcon && (
                    <View style={styles.leftIconWrapper}>
                        <View style={styles.icon}>
                            {LeftIcon}
                        </View>
                    </View>
                )}
                <Touchable
                    onPress={handleChevronPress}
                    style={styles.rightIconWrapper}
                >
                    <Animated.View
                        style={[
                            animatedChevronStyles,
                            styles.icon
                        ]}
                    >
                        <Icons.Chevron size={10} />
                    </Animated.View>
                </Touchable>
                <FormComponents.ErrorMessage text={errorMessage} />
            </View>
            {data.length > 0 && (
                <Animated.View
                    style={[
                        styles.options,
                        animatedContainerStyles
                    ]}
                >
                    <ScrollView
                        style={styles.optionsContainer}
                        showsVerticalScrollIndicator={false}
                        contentContainerStyle={styles.optionsContentContainer}
                    >
                        {data.map((item, index) => (
                            <FormComponents.CustomDropdownOptionItem
                                key={index}
                                item={item}
                                onSelect={() => onSelectOption(item)}
                            />
                        ))}
                    </ScrollView>
                </Animated.View>
            )}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        width: '100%'
    },
    input: {
        paddingRight: theme.utils.gap(4),
        height: theme.components.input.height,
        width: '100%',
        borderRadius: theme.components.input.borderRadius,
        borderWidth: theme.components.input.borderWidth,
        textAlignVertical: 'center'
    },
    options: {
        top: theme.components.input.height + theme.utils.gap(1),
        left: 0,
        right: 0,
        borderRadius: 8,
        position: 'absolute',
        backgroundColor: theme.colors.white,
        ...theme.utils.createShadow(0, 0.8, 20, 70)
    },
    icon: {
        paddingHorizontal: theme.utils.gap(1),
        paddingVertical: theme.utils.gap(1)
    },
    leftIconWrapper: {
        position: 'absolute',
        left: theme.utils.gap(1),
        top: 0,
        bottom: 0,
        height: theme.components.input.height,
        justifyContent: 'center',
        elevation: 19
    },
    rightIconWrapper: {
        position: 'absolute',
        right: theme.utils.gap(1),
        top: 0,
        bottom: 0,
        height: theme.components.input.height,
        justifyContent: 'center',
        elevation: 19
    },
    optionsContainer: {
        maxHeight: 150
    },
    optionsContentContainer: {
        paddingVertical: theme.utils.gap(1)
    }
}))
