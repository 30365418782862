import React, { useState } from 'react'
import { Linking, View } from 'react-native'
import { useIsWithinBreakpoints, useStyles } from 'lib/hooks'
import { Breakpoint, createStyles } from 'lib/styles'
import { PhoneModal, Touchable, Typography } from 'lib/components'
import { Icons } from 'assets'
import { AnalyticsEvent, AnalyticsEventDescription, GoogleAnalytics } from 'lib/analytics'
import { useGetChatStatus } from '../hooks'
import { ConnectionStatus } from '../types'

type ChatHeaderProps = {
    jobId: string,
    roomConnectionStatus: ConnectionStatus,
    supplierPhone?: string,
    supplierName?: string,
    onGoBack: VoidFunction
}

export const ChatHeader: React.FunctionComponent<ChatHeaderProps> = ({
    jobId,
    onGoBack,
    supplierPhone,
    supplierName,
    roomConnectionStatus
}) => {
    const { styles, theme } = useStyles(stylesheet)
    const { getChatStatus } = useGetChatStatus()
    const { label, dotBackgroundColor } = getChatStatus(roomConnectionStatus)
    const [isPhoneModalOpen, setIsPhoneModalOpen] = useState(false)
    const isMobile = useIsWithinBreakpoints(Breakpoint.XS, Breakpoint.MD)

    const onCallPress = () => {
        GoogleAnalytics.logEvent({
            eventType: AnalyticsEvent.UserAction,
            description: AnalyticsEventDescription.PhoneClicked,
            value: jobId
        })

        if (isMobile) {
            return Linking.openURL(`tel:${supplierPhone}`)
        }

        setIsPhoneModalOpen(true)
    }

    return (
        <View style={styles.container}>
            <PhoneModal
                isOpen={isPhoneModalOpen}
                onClose={() => setIsPhoneModalOpen(false)}
                phoneNumber={supplierPhone || ''}
                expertName={supplierName || ''}
            />
            <Touchable
                onPress={onGoBack}
                style={{
                    ...styles.goBackButton,
                    transform: [
                        {
                            rotate: '90deg'
                        }
                    ]
                }}
            >
                <Icons.Chevron size={12} />
            </Touchable>
            <View style={styles.chatTitle}>
                <Typography.Regular bold>
                    {jobId}
                </Typography.Regular>
                <View style={styles.activityStatus}>
                    <View
                        style={{
                            ...styles.activityIndicator,
                            backgroundColor: dotBackgroundColor
                        }}
                    />
                    <Typography.Label style={styles.status}>
                        {label}
                    </Typography.Label>
                </View>
            </View>
            {supplierPhone && (
                <Touchable onPress={onCallPress}>
                    <Icons.Phone
                        size={20}
                        forceColor={theme.colors.orange}
                    />
                </Touchable>
            )}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        height: 64,
        flexDirection: 'row',
        backgroundColor: theme.colors.white,
        alignItems: 'center',
        borderBottomWidth: 1,
        borderBottomColor: theme.colors.silver,
        paddingHorizontal: theme.utils.gap(2)
    },
    goBackButton: {
        height: 32,
        width: 32,
        borderRadius: 4,
        borderWidth: 1,
        borderColor: theme.colors.silver,
        justifyContent: 'center',
        alignItems: 'center'
    },
    chatTitle: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center'
    },
    activityStatus: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: theme.utils.gap(1)
    },
    activityIndicator: {
        height: 12,
        width: 12,
        borderRadius: 6
    },
    status: {
        width: 80,
        color: theme.colors.softBlueGrey,
        fontSize: 10,
        lineHeight: 12,
        marginLeft: theme.utils.gap(1) / 2
    }
}))
