import { authActions, cookieUtils } from 'features/auth'
import { CreateJobRequest, CreateMoversAndPackersJobRequest } from 'features/bookings'
import { addNewAddress } from 'features/user'
import {
    useMPDropoffLocationAtom,
    useMPPickupLocationAtom,
    useResetBookingJobQuotationAtom,
    useToastAtom,
    useUserAtom,
    useUserTokenAtom
} from 'lib/atoms'
import { UploadedPhoto } from 'lib/components'
import { useTranslations } from 'lib/hooks'
import { Address, PaymentType } from 'lib/models'
import { MoversAndPackersSummaryScreenParams } from 'lib/routing'
import { NewAddressRequestShape, NotificationType, SupplyID, VoidFunction } from 'lib/types'

type UseMoversAndPackersCreateJobsParams = {
    routeParams: MoversAndPackersSummaryScreenParams,
    uploadedPhotos: Array<UploadedPhoto>,
    onSuccess: VoidFunction,
}

type CreateMoversAndPackersParams = {
    jobDetails: CreateMoversAndPackersJobRequest,
    paymentMethod: PaymentType,
    onCreateJob(supplyId: SupplyID, data: CreateJobRequest, onSuccess: VoidFunction, moversAndPackersData?: CreateMoversAndPackersJobRequest): void
}

export const useMoversAndPackersCreateJobs = ({
    routeParams,
    uploadedPhotos,
    onSuccess
}: UseMoversAndPackersCreateJobsParams) => {
    const T = useTranslations()
    const [token] = useUserTokenAtom()
    const [, setUser] = useUserAtom()
    const [, setToastMessage] = useToastAtom()
    const [ mpDropoffLocation ] = useMPDropoffLocationAtom()
    const [ mpPickupLocation ] = useMPPickupLocationAtom()
    const { mutateAsync: addAddress, isLoading: isAddingAddress } = addNewAddress()
    const { mutateAsync: getMe, isLoading: isFetchingProfile } = authActions.useGetMe()
    const [, setResetJobQuotationTypeAtom] = useResetBookingJobQuotationAtom()

    const createMoversAndPackers = async ({ jobDetails, onCreateJob, paymentMethod }: CreateMoversAndPackersParams) => {
        try {

            setResetJobQuotationTypeAtom()

            const pickupAddress = mpPickupLocation as Address
            const dropOffAddress = mpDropoffLocation as Address
            const jobData = {
                ...jobDetails,
                booking: {
                    ...jobDetails.booking,
                    pickUpLocation: pickupAddress,
                    dropOffLocation: dropOffAddress,
                    photos: (uploadedPhotos || []).map(photo => photo.downloadUrl || '')
                }
            }

            if (!pickupAddress._id) {
                await addAddress({
                    token,
                    address: mpPickupLocation as NewAddressRequestShape
                })
            }

            if (!dropOffAddress._id) {
                await addAddress({
                    token,
                    address: mpDropoffLocation as NewAddressRequestShape
                })
            }

            if (!dropOffAddress._id || !pickupAddress._id) {
                const userProfile = await getMe({ token })

                setUser(userProfile.data.profile)
                cookieUtils.setUserAddressesCookie(userProfile.data.profile.locations)

                const savedPickupAddress = userProfile.data.profile.locations.find(location => location.address === mpPickupLocation?.address)
                const savedDropOffAddress = userProfile.data.profile.locations.find(location => location.address === mpDropoffLocation?.address)

                return onCreateJob(
                    routeParams.supplyId,
                    {
                        job: {
                            paymentType: paymentMethod
                        }
                    } as CreateJobRequest,
                    onSuccess,
                    {
                        ...jobData,
                        booking: {
                            ...jobData.booking,
                            pickUpLocation: savedPickupAddress as Address,
                            dropOffLocation: savedDropOffAddress as Address
                        }
                    }
                )
            }

            return onCreateJob(
                routeParams.supplyId,
                {
                    job: {
                        paymentType: paymentMethod
                    }
                } as CreateJobRequest,
                onSuccess,
                jobData
            )
        } catch {
            setToastMessage({
                message: T.common.errorMessage,
                type: NotificationType.Error
            })
        }
    }

    return {
        createMoversAndPackers,
        isCreatingJob: isAddingAddress || isFetchingProfile
    }
}
