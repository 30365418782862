import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const CalendarReferral: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path
            fillRule="evenodd"
            d="M74.723 11.654c-1.558-1.527-3.604-2.352-5.803-2.352h-7.055V6.248a3.063 3.063 0 0 0-3.055-3.054 3.063 3.063 0 0 0-3.054 3.054v3.054H25.244V6.248a3.063 3.063 0 0 0-3.055-3.054 3.063 3.063 0 0 0-3.054 3.054v3.054H12.08a8.254 8.254 0 0 0-5.803 2.352 8.022 8.022 0 0 0-2.444 5.742v47.952c0 2.138.886 4.246 2.444 5.743 1.557 1.527 3.604 2.351 5.803 2.351h56.87c2.17 0 4.246-.824 5.773-2.351a8.022 8.022 0 0 0 2.444-5.743V17.396a8.022 8.022 0 0 0-2.444-5.742Zm-3.665 53.694c0 .52-.214 1.008-.61 1.375-.398.397-.948.61-1.528.61H12.08c-.58 0-1.13-.213-1.527-.61a1.943 1.943 0 0 1-.58-1.375V17.396c0-.52.213-1.008.58-1.374.397-.397.946-.611 1.527-.611h7.055v6.109a3.063 3.063 0 0 0 3.054 3.054 3.063 3.063 0 0 0 3.055-3.055v-6.108h30.543v6.109a3.063 3.063 0 0 0 3.054 3.054 3.063 3.063 0 0 0 3.054-3.055v-6.108h7.056c.58 0 1.13.214 1.527.61.397.367.61.856.61 1.375v47.952h-.03Z"
            clipRule="evenodd"
        />
        <Path
            d="M22.189 39.845a5.223 5.223 0 1 0 0-10.445 5.223 5.223 0 0 0 0 10.445ZM40.514 39.845a5.223 5.223 0 1 0 0-10.445 5.223 5.223 0 0 0 0 10.445ZM58.84 39.845a5.223 5.223 0 1 0 0-10.445 5.223 5.223 0 0 0 0 10.445Z"
        />
        <Path
            fillRule="evenodd"
            d="M22.189 50.321a5.22 5.22 0 0 0-5.223 5.223c0 2.87 2.352 5.223 5.223 5.223 2.87 0 5.223-2.352 5.223-5.223s-2.352-5.223-5.223-5.223ZM40.514 50.321a5.22 5.22 0 0 0-5.223 5.223c0 2.87 2.352 5.223 5.223 5.223s5.223-2.352 5.223-5.223-2.352-5.223-5.223-5.223Z"
            clipRule="evenodd"
        />
    </Icon>
)
