import { UseFormReturn } from '@codegateinc/react-form-builder-v2'
import { Nullable } from 'lib/types'
import { dateHelpers } from 'lib/utils'
import { Address, PaymentType } from 'lib/models'
import { MoversAndPackersSummaryScreenParams } from 'lib/routing'
import { useUserTokenAtom } from 'lib/atoms'
import { CreateMoversAndPackersJobRequest, GetPromotionResponse } from 'features/bookings'
import { useSegmentSession, useSessionData } from 'lib/hooks'
import { MoversAndPackersServiceDetailsShape } from '../../forms'

type UseMoversAndPackersJobDetailsParams = {
    routeParams: MoversAndPackersSummaryScreenParams,
    formReturn: UseFormReturn<MoversAndPackersServiceDetailsShape>,
    paymentMethod: PaymentType,
    promotionPackage: Nullable<GetPromotionResponse>
}

export const useMoversAndPackersJobDetails = ({ formReturn, paymentMethod, promotionPackage, routeParams }: UseMoversAndPackersJobDetailsParams): CreateMoversAndPackersJobRequest => {
    const { form } = formReturn
    const [token] = useUserTokenAtom()
    const { segmentSession } = useSegmentSession()
    const sessionData = useSessionData()

    return {
        token,
        ...sessionData,
        bookingSessionId: segmentSession.id,
        booking: {
            messageToSupplier: form.listOfItems.value,
            photos: [],
            movingDate: routeParams.form.bookingTime,
            serviceType: routeParams.form.serviceType || '',
            pickUpLocation: routeParams.form.pickUpLocation as Address,
            pickUpPropertyInfo: {
                floor: Number(routeParams.form.pickUpFloor),
                // Need to check it this way because it is from queryParams so it is string
                has_elevator: String(routeParams.form.pickUpHasElevator) === 'true',
                type: routeParams.form.pickUpPropertyType || '',
                bedrooms: Number(routeParams.form.pickUpNumberOfBedrooms) ?? undefined,
                size: routeParams.form.pickUpPropertySize
                    ? routeParams.form.pickUpPropertySize
                    : undefined,
                details: routeParams.form.pickupDetails
            },
            dropOffLocation: routeParams.form.dropOffLocation as Address,
            dropOffPropertyInfo: {
                floor: Number(routeParams.form.dropOffFloor),
                has_elevator: String(routeParams.form.dropOffHasElevator) === 'true',
                type: routeParams.form.dropOffPropertyType || '',
                bedrooms: routeParams.form.dropOffNumberOfBedrooms
                    ? Number(routeParams.form.dropOffNumberOfBedrooms)
                    : undefined,
                size: routeParams.form.dropOffPropertySize
                    ? routeParams.form.dropOffPropertySize
                    : undefined,
                details: routeParams.form.dropOffDetails
            },
            movingItemsInfo: '',
            inspectionDateAvailability: Object.entries<string>(form.inspectionBookingDays.value)
                .map(item => {
                    const [key, value] = item

                    return {
                        date: dateHelpers.selectTimeFormat(dateHelpers.addHoursToDate(key, 8)),
                        availability: value || ''
                    }
                })
                .filter(item => item.availability),
            paymentType: paymentMethod,
            additionalRequiredServices: []
        },
        promo: promotionPackage?.promotion.promo_auth ? {
            auth: promotionPackage?.promotion.promo_auth,
            code: promotionPackage?.promotion.promo_code
        } : undefined
    }
}
