import React, { useState } from 'react'
import { View } from 'react-native'
import { Icons } from 'assets'
import { createStyles } from 'lib/styles'
import { Typography } from 'lib/components'
import { useStyles } from 'lib/hooks'
import { CustomFonts } from 'lib/types'
import { EquipmentDisclaimerModal } from './EquipmentDisclaimerModal'
import { EquipmentDisclaimer } from '../types'

type CleaningSummaryColumnProps = {
    equipmentDisclaimer?: EquipmentDisclaimer,
}

export const CleaningEquipmentDisclaimer: React.FunctionComponent<CleaningSummaryColumnProps> =
    ({ equipmentDisclaimer }) => {
        const { styles } = useStyles(stylesheet)
        const [isModalOpen, setIsModalOpen] = useState(false)

        return (
            <>
                <View style={styles.container}>
                    <View style={styles.leftContainer}>
                        <View>
                            <Typography.Regular style={styles.title}>
                                {equipmentDisclaimer?.title}
                            </Typography.Regular>
                        </View>
                        <View>
                            <Typography.Regular style={styles.description}>
                                {equipmentDisclaimer?.description}
                                <Typography.Regular style={styles.link} onPress={() => setIsModalOpen(true)}>
                                    {` ${equipmentDisclaimer?.ctaButton}`}
                                </Typography.Regular>
                            </Typography.Regular>

                        </View>
                    </View>
                    <View style={styles.rightContainer}>
                        <View style={styles.iconContainer}>
                            <Icons.CleaningTool viewBox="0 0 77 127" size={127} />
                        </View>
                    </View>
                </View>
                <EquipmentDisclaimerModal
                    closeModal={() => setIsModalOpen(false)}
                    sources={equipmentDisclaimer?.popup}
                    isOpen={isModalOpen}
                />
            </>
        )
    }

const stylesheet = createStyles(theme => ({
    container: {
        flex: 1,
        flexDirection: 'row',
        borderRadius: 8,
        borderWidth: 1,
        borderColor: theme.colors.fog,
        minHeight: {
            xs: 120,
            md: 110
        },
        marginTop: 50,
        paddingBottom: theme.utils.gap(2)

    },
    leftContainer: {
        flexDirection: 'column',
        paddingTop: theme.utils.gap(1.75),
        paddingLeft: theme.utils.gap(2.3),
        flex: 1,
        paddingRight: 120
    },
    rightContainer: {
        backgroundColor: {
            md: 'red',
            sm: 'blue',
            xs: 'green'
        },
        position: 'relative',
        paddingBottom: theme.utils.gap(3)
    },
    iconContainer: {
        flex: 1,
        position: 'absolute',
        right: 0,
        top: {
            xs: -20,
            md: -40
        },
        with: 80
    },
    title: {
        color: theme.colors.orange,
        textTransform: 'uppercase',
        fontSize: 16,
        lineHeight: 24,
        fontFamily: CustomFonts.Roboto500,
        marginBottom: theme.utils.gap(0.5)
    },
    description: {
        color: theme.colors.darkSlate,
        fontSize: 16,
        lineHeight: 24,
        fontFamily: CustomFonts.Roboto400
    },
    link: {
        color: theme.colors.orange,
        fontSize: 16,
        lineHeight: 20,
        fontFamily: CustomFonts.Roboto500,
        userSelect: 'none'
    }
}))
