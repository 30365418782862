import React from 'react'
import { View } from 'react-native'
import { Job } from 'lib/models'
import { MediaQuery } from 'lib/components'
import { JobStatus, JobType } from 'lib/types'
import { Breakpoint, createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { JobName } from './JobName'
import { JobRating } from './JobRating'
import { JobDetailsResponse } from '../types'
import { CancelRequest } from './CancelRequest'
import { ServiceLocation } from './ServiceLocation'
import { SubmittedMessage } from './SubmittedMessage'
import { BookingsProposals } from './BookingsProposals'
import { ServiceDetailsAccepted } from './ServiceDetailsAccepted'
import { RescheduleSession } from './RescheduleSession'
import { ExtendSession } from './ExtendSession'
import { AdditionalInformation } from './AdditionalInformation'
import { CookingServiceDate } from './CookingServiceDate'
import { CookingServiceDetails } from './CookingServiceDetails'
import { RecurringSchedule } from './RecurringSchedule'
import { CookingRecurringDetails } from './CookingRecurringDetails'

type CookingDetailsContentProps = {
    response: JobDetailsResponse,
    jobStatus?: JobStatus,
    allJobsData: Array<Job>,
    isSession?: boolean,
}

export const CookingDetailsContent: React.FunctionComponent<CookingDetailsContentProps> = ({
    response,
    jobStatus,
    allJobsData,
    isSession
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const isSubmitted = response.job.status === JobStatus.Submitted
    const isRecurring = response.job.job_type === JobType.Recurring && isSubmitted
    const isRecurringAccepted = response.job.job_type === JobType.Recurring && (response.job.status === JobStatus.Accepted || response.job.status === JobStatus.Paused) && isSession

    return (
        <View style={styles.wrapper}>
            {isRecurringAccepted ?
                (
                    <CookingRecurringDetails
                        response={response}
                        allJobsData={allJobsData}
                    />
                ) : (
                    <View style={styles.contentWrapper}>
                        <View style={styles.content}>
                            <JobName
                                job={response}
                                isRecurring={response.job.job_type === JobType.Recurring}
                            />
                            {isRecurring && (
                                <RecurringSchedule schedule={response.job.schedule} />
                            )}
                            {isSubmitted && (
                                <SubmittedMessage />
                            )}
                            <CookingServiceDate
                                job={response}
                                isSubmitted={isSubmitted}
                            />
                            <View style={styles.row}>
                                <RescheduleSession data={response} />
                                <ExtendSession data={response} />
                            </View>
                            <CookingServiceDetails
                                job={response}
                                withHours={false}
                            />
                            <AdditionalInformation
                                job={response}
                                linesLimit={3}
                            />
                            <ServiceLocation job={response.job} />
                            <CancelRequest
                                job={response}
                                jobStatus={jobStatus}
                                text={isSubmitted
                                    ? T.screens.jobDetails.cancelRequest
                                    : undefined
                                }
                            />
                        </View>
                        {jobStatus === JobStatus.Completed && (
                            <JobRating job={response} />
                        )}
                    </View>
                )
            }
            {response.job.status === JobStatus.Submitted
                ? (
                    <BookingsProposals
                        job={response}
                        isRecurring={isRecurring}
                    />
                ) : (
                    <React.Fragment>
                        <MediaQuery.Visible from={Breakpoint.LG}>
                            <ServiceDetailsAccepted
                                job={response}
                                jobStatus={jobStatus}
                                isRecurringAccepted={Boolean(isRecurringAccepted)}
                            />
                        </MediaQuery.Visible>
                    </React.Fragment>
                )
            }
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        flexDirection: {
            lg: 'row',
            xs: 'column'
        },
        alignItems: {
            lg: 'flex-start',
            xs: undefined
        },
        marginTop: -theme.utils.gap(6),
        paddingHorizontal: {
            lg: theme.utils.gap(6),
            xs: theme.utils.gap(2)
        }
    },
    contentWrapper: {
        flex: 1
    },
    row: {
        flexDirection: {
            ':w[1250, ]': 'row',
            ':w[, 1250]': 'column'
        }
    },
    content: {
        borderRadius: 8,
        borderWidth: 1,
        paddingHorizontal: theme.utils.gap(2),
        paddingVertical: theme.utils.gap(3),
        backgroundColor: theme.colors.white,
        borderColor: theme.colors.silver,
        backGroundColor: theme.colors.white,
        ...theme.utils.createShadow(2, 0, 8, 4, 4, theme.colors.webShadow(0.04))
    }
}))
