import React from 'react'
import { View } from 'react-native'
import { createStyles } from 'lib/styles'
import { Typography } from 'lib/components'
import { usePrice, useStyles, useTranslations } from 'lib/hooks'
import { JobDetailsResponse } from '../types'

type AirConditionPriceDetailsProps = {
    job: JobDetailsResponse,
    isSubmitted: boolean
}

export const AirConditionPriceDetails: React.FunctionComponent<AirConditionPriceDetailsProps> = ({
    job,
    isSubmitted
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const { job: { bookingSummary } } = job
    const price = usePrice(bookingSummary?.total?.value)

    return (
        <View style={styles.wrapper}>
            <View style={styles.flex}>
                <View style={styles.title}>
                    <Typography.Label forceColor={theme.colors.mouse}>
                        {T.screens.jobDetails.sections.promotionCode}
                    </Typography.Label>
                </View>
                <Typography.Label>
                    {job.job.promotion?.code ?? '-'}
                </Typography.Label>
            </View>
            <View style={styles.flex}>
                <View style={styles.title}>
                    <Typography.Label forceColor={theme.colors.mouse}>
                        {T.screens.jobDetails.sections.totalAmount}
                    </Typography.Label>
                </View>
                <Typography.Label>
                    {price ?? 0}
                </Typography.Label>
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        flexDirection: 'row',
        marginBottom: theme.utils.gap(3)
    },
    title: {
        marginBottom: theme.utils.gap(1)
    },
    flex: {
        flex: 1
    },
    cleaningDays: {
        textTransform: 'capitalize'
    }
}))
