import React from 'react'
import { Path, Stop, LinearGradient, Defs, G } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const WhatsAppColoured: React.FunctionComponent<IconProps> = props => (
    <Icon {...props} viewBox="0 0 24 24">
        <G filter="url(#filter0_d_1090_50888)">
            <Path d="M2.18359 21.3522L3.526 16.4735C2.6981 15.0455 2.26253 13.4257 2.263 11.7666C2.26535 6.57549 6.50919 2.35217 11.7233 2.35217C14.254 2.35311 16.6296 3.33398 18.4151 5.1133C20.2016 6.89262 21.1845 9.25803 21.1836 11.7736C21.1812 16.9647 16.9374 21.1885 11.7233 21.1885C11.7228 21.1885 11.7238 21.1885 11.7233 21.1885H11.7191C10.1356 21.188 8.57989 20.7923 7.19848 20.0425L2.18359 21.3522Z" fill="url(#paint0_linear_1090_50888)" />
        </G>
        <Path d="M1.83203 22L3.24485 16.8647C2.37348 15.3617 1.91511 13.6567 1.91559 11.9099C1.91797 6.44566 6.38465 2 11.874 2C14.5378 2.00095 17.0378 3.03334 18.9181 4.90658C20.7978 6.77981 21.833 9.27001 21.832 11.918C21.8296 17.3818 17.362 21.8284 11.874 21.8284C11.8736 21.8284 11.8745 21.8284 11.874 21.8284H11.8698C10.2034 21.8279 8.5657 21.4116 7.11135 20.6221L1.83203 22ZM7.35581 18.8277L7.65804 19.006C8.92858 19.7565 10.3853 20.1534 11.8707 20.1543H11.874C16.4357 20.1543 20.149 16.4592 20.1509 11.9171C20.1514 9.71585 19.2914 7.64631 17.7287 6.08964C16.166 4.53297 14.088 3.67502 11.8769 3.67407C7.3114 3.67407 3.59865 7.36873 3.59674 11.9104C3.59626 13.4666 4.03362 14.9824 4.86202 16.2938L5.05921 16.6056L4.22269 19.6453L7.35581 18.8277Z" fill="url(#paint1_linear_1090_50888)" />
        <Path fillRule="evenodd" clipRule="evenodd" d="M9.42362 7.83703C9.2395 7.43936 9.04546 7.43157 8.86984 7.4247C8.72632 7.41874 8.5625 7.4192 8.39821 7.4192C8.23439 7.4192 7.96765 7.47921 7.74198 7.71791C7.51631 7.9566 6.88086 8.53432 6.88086 9.70901C6.88086 10.8837 7.76275 12.019 7.8855 12.1784C8.00872 12.3374 9.58744 14.8251 12.0882 15.7822C14.1664 16.5775 14.5894 16.4195 15.0407 16.3796C15.4921 16.3398 16.4962 15.8024 16.7016 15.2448C16.9065 14.6872 16.9065 14.2094 16.8451 14.1095C16.7837 14.0101 16.6195 13.9505 16.3735 13.831C16.1275 13.7114 14.918 13.1337 14.6923 13.0544C14.4667 12.9751 14.3028 12.9348 14.1385 13.174C13.9747 13.4127 13.5031 13.9505 13.3596 14.1095C13.216 14.2689 13.0725 14.2891 12.8266 14.1695C12.5806 14.0499 11.7879 13.798 10.848 12.9848C10.1167 12.3521 9.62285 11.5705 9.47933 11.3313C9.33581 11.0926 9.46375 10.963 9.58744 10.8443C9.69791 10.7376 9.83341 10.5657 9.95663 10.426C10.0794 10.2867 10.1204 10.1873 10.2026 10.0279C10.2847 9.86845 10.2437 9.72917 10.1823 9.60959C10.1209 9.49002 9.64268 8.30892 9.42362 7.83703Z" fill="white" />
        <Defs>
            <LinearGradient id="paint0_linear_1090_50888" x1="11.6835" y1="21.3522" x2="11.6835" y2="2.35251" gradientUnits="userSpaceOnUse">
                <Stop stopColor="#20B038" />
                <Stop offset="1" stopColor="#60D66A" />
            </LinearGradient>
            <LinearGradient id="paint1_linear_1090_50888" x1="11.8323" y1="22.0001" x2="11.8323" y2="2.00055" gradientUnits="userSpaceOnUse">
                <Stop stopColor="#F9F9F9" />
                <Stop offset="1" stopColor="white" />
            </LinearGradient>
        </Defs>
    </Icon>
)
