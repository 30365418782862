import React from 'react'
import { View } from 'react-native'
import { useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { Icons } from 'assets'
import { ServiceLocation, useJobStatus } from 'features/jobs'
import { CopyBookingId, Grid, Typography } from 'lib/components'
import { JobDetails } from 'lib/models'
import { dateHelpers } from 'lib/utils'

type ChatJobDetailsProps = {
    job: JobDetails
}

export const ChatJobDetails: React.FunctionComponent<ChatJobDetailsProps> = ({ job }) => {
    const T = useTranslations()
    const { colors, label } = useJobStatus(job.status)
    const { styles, theme } = useStyles(stylesheet)
    const date = job.date_time || job.beginning_date
    const renderHeader = () => (
        <View style={styles.header}>
            <View style={styles.column}>
                <View style={styles.row}>
                    <Typography.Label
                        style={styles.label}
                        forceColor={theme.colors.softBlueGrey}
                    >
                        {job.job_id}
                    </Typography.Label>
                    <Grid.Gap gapLeft={1}>
                        <Typography.Label
                            style={styles.label}
                            forceColor={colors.text}
                        >
                            {label}
                        </Typography.Label>
                    </Grid.Gap>
                </View>
                <View style={styles.jobName}>
                    <Typography.Regular
                        bold
                        numberOfLines={1}
                    >
                        {job.supply.name} {job.frequency ? `- ${job.frequency}` : ''}
                    </Typography.Regular>
                    {Boolean(job.recurring_job) && (
                        <Grid.Gap gapLeft={1}>
                            <Icons.Refresh size={14} />
                        </Grid.Gap>
                    )}
                </View>
            </View>
            <View style={styles.shareColumn}>
                <CopyBookingId
                    id={job.job_id}
                    size={16}
                />
            </View>
        </View>
    )

    return (
        <View style={styles.jobDetails}>
            {renderHeader()}
            <Grid.Gap gapBottom={2}>
                <View style={styles.timeRow}>
                    <Typography.Regular bold>
                        {dateHelpers.shortFullDate(date)}
                    </Typography.Regular>
                </View>
                {job.num_hours
                    ? (
                        <Typography.Regular>
                            {`${dateHelpers.selectTimeLabel(date)} - ${dateHelpers.selectTimeLabel(dateHelpers.addHoursToDate(date, job.num_hours))} (${job.num_hours}h)`}
                        </Typography.Regular>
                    ) : (
                        <Typography.Regular>
                            {dateHelpers.selectTimeLabel(date)}
                        </Typography.Regular>
                    )
                }
            </Grid.Gap>
            {job.supplier && (
                <Grid.Gap gapBottom={2}>
                    <Typography.Label
                        style={styles.label}
                        numberOfLines={1}
                        forceColor={theme.colors.softBlueGrey}
                    >
                        {T.screens.chat.sideMenu.expert}: {job.supplier.handle}
                    </Typography.Label>
                </Grid.Gap>
            )}
            {Boolean(job.num_hours) && (
                <Grid.Gap gapBottom={2}>
                    <Grid.Gap gapBottom={1}>
                        <Typography.Regular
                            bold
                            forceColor={theme.colors.softBlueGrey}
                        >
                            {T.screens.chat.sideMenu.hoursPerSession}
                        </Typography.Regular>
                    </Grid.Gap>
                    <Typography.Regular>
                        {job.num_hours} {job.num_hours > 1 ? T.common.hours : T.common.hour }
                    </Typography.Regular>
                </Grid.Gap>
            )}
            <ServiceLocation job={job} />
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    jobDetails: {
        width: '100%',
        ...theme.utils.createShadow(4, 0.04, 8, 8, 2),
        borderWidth: 1,
        borderColor: theme.colors.silver,
        borderRadius: theme.utils.gap(1),
        padding: theme.utils.gap(2)
    },
    column: {
        flex: 1,
        flexDirection: 'column'
    },
    header: {
        flexDirection: 'row',
        marginBottom: theme.utils.gap(2)
    },
    row: {
        flexDirection: 'row'
    },
    jobName: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: theme.utils.gap(1) / 2
    },
    label: {
        fontSize: 12,
        lineHeight: 12
    },
    shareColumn: {
        alignItems: 'flex-end'
    },
    timeRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: theme.utils.gap(1)
    },
    addressContainer: {
        borderWidth: 1,
        borderRadius: theme.utils.gap(1),
        borderColor: theme.colors.fog,
        padding: theme.utils.gap(2),
        marginTop: theme.utils.gap(1)
    }
}))
