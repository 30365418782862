import React from 'react'
import { ImageBackground, View } from 'react-native'
import { Images } from 'assets'
import { useStyles, useTranslations } from 'lib/hooks'
import { breakpoints, createStyles } from 'lib/styles'
import { CustomModal, Typography, Touchable } from 'lib/components'
import { CustomFonts } from 'lib/types'
import { linkingHelpers } from 'lib/utils'
import { ENV } from 'lib/config'
import { ErrorMessage } from 'lib/components/form/components/ErrorMessage'
import { ReminderProps } from './MarketingConsentReminder'

export const MarketingConsentModal: React.FunctionComponent<ReminderProps> =
    ({
        onPressGetPromotion,
        onCloseModal,
        error,
        isOpen,
        isLoading,
        user
    }) => {
        const T = useTranslations()
        const { styles } = useStyles(stylesheet)

        return (
            <CustomModal
                isOpen={isOpen}
                onClose={onCloseModal}
                contentStyles={styles.modalContent}
                closeButtonStyles={styles.closeButton}
                childrenWrapper={styles.modalChildrenWrapper}
            >
                <ImageBackground
                    style={styles.bannerContainer}
                    source={Images.MarketingConsentBanner}
                    imageStyle={styles.imageBanner}
                />

                <ImageBackground
                    style={styles.bodyBackground}
                    source={Images.MarketingConsentBackground}
                    imageStyle={styles.background}
                >
                    <View style={styles.bodyContainer}>
                        <View style={styles.body}>
                            <View style={styles.headingContainer}>
                                <Typography.Regular style={styles.headingText}>
                                    <Typography.Regular style={styles.subHeadingText}>
                                        {`${T.components.marketingConsentModal.numberOfPeople} `}
                                    </Typography.Regular>
                                    {T.components.marketingConsentModal.haveGottenTheOffer}</Typography.Regular>
                            </View>
                            <View style={styles.row}>
                                <Typography.Regular style={styles.title}>
                                    {T.components.marketingConsentModal.title}
                                </Typography.Regular>
                            </View>
                            <View style={styles.row}>
                                <Typography.Regular style={styles.description}>
                                    {T.components.marketingConsentModal.description}
                                </Typography.Regular>
                            </View>
                            <View style={styles.emailContainer}>
                                <Typography.Regular style={styles.email}>
                                    {user?.email_id}
                                </Typography.Regular>
                            </View>
                            <View style={styles.actionContainer}>
                                <Touchable
                                    style={styles.submitButton}
                                    onPress={onPressGetPromotion}
                                    disabled={isLoading}
                                >
                                    <Typography.Regular style={styles.buttonText}>
                                        {T.components.marketingConsentModal.getPromotion}
                                    </Typography.Regular>
                                </Touchable>
                            </View>
                        </View>
                        <ErrorMessage text={error}/>
                        <View style={styles.footer}>
                            <Touchable
                                onPress={() => linkingHelpers.openUrl(`${ENV.SENDHELPER_MARKETING_SITE_URL}/blog/welcome-offer-get-50-discount`)}
                                style={styles.termsAndConditionButton}>
                                <Typography.Regular style={styles.termsAndCondition}>{T.components.marketingConsentModal.termsAndCondition}</Typography.Regular>
                            </Touchable>
                        </View>
                    </View>
                </ImageBackground>
            </CustomModal>
        )

    }

const stylesheet = createStyles(theme => ({
    body: {
        alignItems: 'center'
    },
    footer: {
        alignItems: 'center',
        paddingTop: theme.utils.gap(1.5)
    },
    row: {
        alignItems: 'center',
        paddingVertical: theme.utils.gap(1)
    },
    title: {
        fontFamily: CustomFonts.Poppins500,
        fontSize: 21,
        lineHeight: 28,
        textTransform: 'capitalize',
        color: theme.colors.night,
        textAlign: 'center',
        marginTop: theme.utils.gap(1)
    },
    description: {
        fontFamily: CustomFonts.Roboto400,
        fontSize: 16,
        color: theme.colors.darkSlate,
        textAlign: 'center',
        marginBottom: theme.utils.gap(1)
    },
    termsAndConditionButton: {
        backgroundColor: theme.colors.transparent,
        userSelect: 'none'
    },
    termsAndCondition: {
        fontFamily: CustomFonts.Roboto400,
        fontSize: 13,
        color: theme.colors.grey
    },
    modalContent: {
        paddingHorizontal: 0,
        width: '100%',
        backgroundColor: theme.colors.peach,
        paddingTop: 0,
        paddingBottom: 0
    },
    modalChildrenWrapper: {
        maxWidth: breakpoints.sm * 0.8,
        marginTop: 0,
        paddingTop: 0
    },
    bannerContainer: {
        backgroundColor: theme.colors.peach,
        width: '100%',
        minHeight: breakpoints.sm * 0.4,
        borderRadius: 8
    },
    bodyBackground: {
        backgroundColor: theme.colors.peach,
        width: '100%',
        borderRadius: 8
    },
    bodyContainer: {
        padding: theme.utils.gap(2)
    },
    headingContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
    subHeadingText: {
        fontSize: 16,
        lineHeight: 20,
        fontFamily: CustomFonts.Roboto700,
        color: theme.colors.darkSlate
    },
    headingText: {
        fontSize: 13,
        lineHeight: 20,
        fontFamily: CustomFonts.Roboto400,
        color: theme.colors.darkSlate
    },
    actionContainer: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    submitButton: {
        paddingVertical: theme.utils.gap(1.5),
        backgroundColor: theme.colors.sun,
        width: '100%',
        borderRadius: 8
    },
    buttonText: {
        textAlign: 'center',
        fontSize: 18,
        lineHeight: 18,
        fontFamily: CustomFonts.Roboto500,
        color: theme.colors.night
    },
    inputContainer: {
        width: '100%'
    },
    emailContainer: {
        width: '100%',
        borderRadius: 8,
        borderWidth: 1,
        borderColor: theme.colors.fog,
        backgroundColor: theme.colors.white,
        paddingVertical: theme.utils.gap(1.5),
        paddingHorizontal: theme.utils.gap(1.5),
        marginBottom: theme.utils.gap(1)
    },
    email: {
        fontSize: 14,
        fontFamily: CustomFonts.Roboto400
    },
    imageBanner: {
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8
    },
    background: {
        borderBottomLeftRadius: 8,
        borderBottomRightRadius: 8
    },
    closeButton: {
        backgroundColor: theme.colors.white,
        padding: 10,
        borderRadius: 4,
        borderWidth: 1,
        borderColor: theme.colors.silver
    }
}))
