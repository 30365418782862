import React from 'react'
import { View } from 'react-native'
import { Icons } from 'assets'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { Touchable, Typography } from 'lib/components'

type ServiceLayoutBackButtonProps = {
    title: string,
    onPress: VoidFunction
}

export const ServiceLayoutBackButton: React.FunctionComponent<ServiceLayoutBackButtonProps> = ({
    title,
    onPress
}) => {
    const { styles } = useStyles(stylesheet)

    return (
        <View style={styles.backButtonContainer}>
            <Touchable
                style={styles.backButtonWrapper}
                onPress={onPress}
            >
                <View
                    style={{
                        ...styles.backButton,
                        transform: [
                            {
                                rotateZ: '90deg'
                            }
                        ]
                    }}
                >
                    <Icons.Chevron size={10} />
                </View>
                <Typography.Label>
                    {title}
                </Typography.Label>
            </Touchable>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    backButtonContainer: {
        height: 80,
        paddingLeft: theme.utils.gap(2),
        justifyContent: 'center'
    },
    backButtonWrapper: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    backButton: {
        width: 32,
        height: 32,
        paddingTop: 12,
        marginRight: theme.utils.gap(1),
        alignItems: 'center',
        borderRadius: 4,
        borderWidth: 1,
        borderColor: theme.colors.silver
    }
}))
