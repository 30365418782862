import React from 'react'
import { View } from 'react-native'
import { useStyles } from 'lib/hooks'
import { createStyles, theme } from 'lib/styles'
import { Typography } from '.'

type InfoStackProps = {
    title: string,
    items: Array<React.ReactElement>
}

export const InfoStack = ({ title, items }: InfoStackProps) => {
    const { styles } = useStyles(stylesheet)

    return (
        <View>
            <View style={styles.container}>
                <Typography.Regular forceColor={theme.colors.darkGrey} bold>
                    {title}
                </Typography.Regular>
            </View>
            <View>
                {items.map((row, index) => (
                    <View key={index} style={styles.row}>
                        {row}
                    </View>
                ))}
            </View>
        </View>
    )

}

const stylesheet = createStyles(theme => ({
    container: {
        padding: theme.utils.gap(1.5),
        borderTopRightRadius: 6,
        borderTopLeftRadius: 6,
        backgroundColor: theme.colors.latte
    },
    row: {
        padding: theme.utils.gap(1),
        minHeight: 40,
        borderWidth: 0.5,
        borderColor: theme.colors.silver,
        borderTop: 'none'
    }
}))
