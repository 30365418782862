import React from 'react'
import { View } from 'react-native'
import { Icons } from 'assets'
import { useLocaleAtom } from 'lib/atoms'
import { useJobFrequencyText, useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { Typography } from 'lib/components'
import { Job, RecurringJob } from 'lib/models'
import { dateHelpers } from 'lib/utils'
import { JobType, LaundryStatus } from 'lib/types'
import { RateExpert } from './RateExpert'
import { ApproveBilling } from './ApproveBilling'

type JobTypeLabelProps = {
    job: Job,
    parentJob?: RecurringJob,
    isCompletedPage?: boolean,
    recurringJobs: Array<RecurringJob>
}

export const JobTypeLabel: React.FunctionComponent<JobTypeLabelProps> = ({
    job,
    parentJob,
    recurringJobs,
    isCompletedPage
}) => {
    const T = useTranslations()
    const [locale] = useLocaleAtom()
    const { styles } = useStyles(stylesheet)
    const [recurringJob] = recurringJobs.filter(item => item._id === job.recurring_job)
    const frequencyText = useJobFrequencyText(job.schedule || parentJob?.schedule)
    const jobFrequency = recurringJob
        ? recurringJob.frequency
        : job.job_type === JobType.Recurring
            ? job.frequency
            : undefined
    const laundryDeliveryDate = job?.laundry_options?.delivery_slot?.date

    const getSubtitle = () => {
        if (job?.laundry_options?.status === LaundryStatus.Ready && !laundryDeliveryDate) {
            return T.components.jobStatus.readyForDelivery
        }

        if (laundryDeliveryDate) {
            return dateHelpers.getFullBookingDate(laundryDeliveryDate, locale)
        }

        return dateHelpers.selectTimeLabel(job.date_time)
    }

    const getTitleSuffix = () => {
        if (laundryDeliveryDate) {
            return ` - ${T.components.jobStatus.readyForDelivery}`
        }

        if (jobFrequency) {
            return ` - ${frequencyText}`
        }

        return ''
    }

    return (
        <View style={styles.container}>
            <View style={styles.label}>
                <View style={styles.row}>
                    <Typography.Regular
                        bold
                        style={styles.labelText}
                    >
                        {`${job.supply.name}${getTitleSuffix()}`}
                    </Typography.Regular>
                    {jobFrequency && (
                        <Icons.Refresh size={15} />
                    )}
                </View>
                {!isCompletedPage
                    ? (
                        <Typography.Label style={styles.time}>
                            {getSubtitle()}
                        </Typography.Label>
                    ) : (
                        <RateExpert job={job} />
                    )
                }
            </View>
            <ApproveBilling job={job} />
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    row: {
        alignItems: 'center',
        flexDirection: 'row'
    },
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'row',
        marginLeft: {
            lg: theme.utils.gap(3),
            xs: theme.utils.gap(2)
        },
        marginVertical: {
            lg: undefined,
            xs: theme.utils.gap(2)
        }
    },
    label: {
        marginRight: theme.utils.gap(1)
    },
    labelText: {
        marginRight: theme.utils.gap(1),
        textTransform: 'capitalize'
    },
    time: {
        marginTop: theme.utils.gap(1) / 2
    }
}))
