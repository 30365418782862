import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const TaskErrands: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path
            d="M49.725 79.995H8.077A8.086 8.086 0 0 1 0 71.918V25.471a8.086 8.086 0 0 1 8.077-8.076h4.058v5.013H8.077a3.067 3.067 0 0 0-3.064 3.064v46.446a3.067 3.067 0 0 0 3.064 3.065h41.648a3.067 3.067 0 0 0 3.064-3.064V25.471a3.067 3.067 0 0 0-3.064-3.063h-4.028v-5.013h4.028a8.086 8.086 0 0 1 8.077 8.077v46.446a8.086 8.086 0 0 1-8.077 8.077Z"
            fill="#fff"
        />
        <Path
            d="M42.101 26.606H15.707A7.314 7.314 0 0 1 8.401 19.3a8.515 8.515 0 0 1 8.505-8.505h.516a.577.577 0 0 0 .577-.577v-.48a2.943 2.943 0 0 1 .041-.49A10.935 10.935 0 0 1 28.991 0a10.691 10.691 0 0 1 10.787 9.311 2.945 2.945 0 0 1 .031.424v.426a.637.637 0 0 0 .636.636h.457a8.515 8.515 0 0 1 8.505 8.505 7.314 7.314 0 0 1-7.306 7.304ZM16.907 15.811a3.5 3.5 0 0 0-3.492 3.492 2.3 2.3 0 0 0 2.292 2.292h26.394a2.3 2.3 0 0 0 2.292-2.292 3.5 3.5 0 0 0-3.491-3.492h-.457a5.656 5.656 0 0 1-5.649-5.649v-.269a5.649 5.649 0 0 0-5.805-4.882 5.868 5.868 0 0 0-5.978 4.91v.3a5.6 5.6 0 0 1-5.59 5.59Z"
            fill="#fff"
        />
        <Path
            d="M43.194 41.496H14.615a2.507 2.507 0 1 1 0-5.013h28.58a2.507 2.507 0 1 1 0 5.013Z"
            fill="#fff"
        />
        <Path
            d="M43.194 51.092H14.615a2.507 2.507 0 1 1 0-5.013h28.58a2.507 2.507 0 1 1 0 5.013Z"
            fill="#fff"
        />
        <Path
            d="M43.194 60.69H14.615a2.507 2.507 0 1 1 0-5.013h28.58a2.507 2.507 0 1 1 0 5.013Z"
            fill="#fff"
        />
    </Icon>
)
