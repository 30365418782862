import React, { useState } from 'react'
import { Icons } from 'assets'
import { View } from 'react-native'
import { createStyles } from 'lib/styles'
import { useMockedHours, useStyles, useTranslations } from 'lib/hooks'
import { Touchable, Typography, WebViewInModal } from 'lib/components'
import { ENV } from 'lib/config'

export const CleaningWorkingHoursPlaceholder: React.FunctionComponent= () => {
    const T = useTranslations()
    const [isModelOpen, setIsModelOpen] = useState<boolean>(false)
    const [initialHours] = useMockedHours(2.5, 8)
    const { styles, theme } = useStyles(stylesheet)

    return (
        <View style={styles.container}>
            <View style={styles.labelContainer}>
                <Typography.Regular style={styles.label}>
                    {T.screens.cleaning.formFields.hours.placeholderPartOne}
                </Typography.Regular>
                <Typography.Regular style={{ ...styles.label, ...styles.hours }} bold>{initialHours.label}</Typography.Regular>
                <Typography.Regular style={styles.label}>
                    {T.screens.cleaning.formFields.hours.placeholderPartTwo}
                </Typography.Regular>
                <Touchable onPress={() => setIsModelOpen(true)} style={styles.button}>
                    <Typography.Regular bold style={styles.link} onPress={() => setIsModelOpen(true)}>
                        {T.screens.cleaning.formFields.hours.whatIncluded}
                    </Typography.Regular>
                    <Icons.Info forceColor={theme.colors.orange} size={16}/>
                </Touchable>
            </View>
            <WebViewInModal
                title={T.screens.cleaning.formFields.hours.whatIncluded}
                isOpen={isModelOpen}
                closeModal={() => setIsModelOpen(false)}
                uri={ENV.SENDHELPER_CLEANING_WHAT_IS_INCLUDED_URL as string}
            />
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        marginBottom: theme.utils.gap(2)
    },
    labelContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        flexWrap: 'wrap'
    },
    label: {
        color: theme.colors.grey,
        lineHeight: 22,
        whiteSpace: 'nowrap'
    },
    hours: {
        marginHorizontal: theme.utils.gap(0.25),
        color: theme.colors.black
    },
    link: {
        color: theme.colors.orange,
        cursor: 'pointer',
        lineHeight: 22,
        whiteSpace: 'nowrap',
        marginHorizontal: theme.utils.gap(0.25)
    },
    button: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    noWrap: {
        whiteSpace: 'nowrap'
    }
}))
