import React, { useState } from 'react'
import Animated, { useAnimatedStyle, withTiming } from 'react-native-reanimated'
import { Icons } from 'assets'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { Regular } from '../typography'
import { Touchable } from '../Touchable'

type AnimatedHeaderLinkProps = {
    isOpened: Animated.SharedValue<boolean>
    text: string,
    onPress: VoidFunction
}

export const AnimatedHeaderLink: React.FunctionComponent<AnimatedHeaderLinkProps> = ({
    isOpened,
    text,
    onPress
}) => {
    const { styles, theme } = useStyles(stylesheet)
    const [isHovering, setIsHovering] = useState(false)
    const animatedChevronStyles = useAnimatedStyle(() => ({
        transform: [
            {
                rotate: withTiming(`${isOpened.value ? 180 : 0}deg`, {
                    duration: 200
                })
            }
        ]
    }))

    return (
        <Touchable
            onPress={onPress}
            style={styles.link}
            onHoverIn={() => setIsHovering(true)}
            onHoverOut={() => setIsHovering(false)}
        >
            <Regular
                style={styles.linkText}
                forceColor={isHovering ? theme.colors.sun : undefined}
            >
                {text}
            </Regular>
            <Animated.View
                style={[
                    styles.iconContainer,
                    animatedChevronStyles
                ]}
            >
                <Icons.Chevron
                    size={10}
                    forceColor={theme.colors.sun}
                />
            </Animated.View>
        </Touchable>
    )
}

const stylesheet = createStyles(theme => ({
    link: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    linkText: {
        ...theme.components.header.link,
        paddingHorizontal: theme.utils.gap(1) / 2,
        paddingVertical: theme.utils.gap(1)
    },
    iconContainer: {
        marginLeft: theme.utils.gap(1) / 2
    }
}))
