import React from 'react'
import { View } from 'react-native'
import { components, ValueContainerProps } from 'react-select'
import { Icons } from 'assets'
import { SelectInputOption } from 'lib/types'
import { createStyles } from 'lib/styles'
import { useStyles } from 'lib/hooks'

export const CustomValueContainer = ({
    children,
    ...props
}: ValueContainerProps<SelectInputOption>) => {
    const { styles } = useStyles(stylesheet)

    return (
        <components.ValueContainer {...props}>
            <View style={styles.searchIcon}>
                <Icons.Search size={14} />
            </View>
            {children}
        </components.ValueContainer>
    )
}

const stylesheet = createStyles(theme => ({
    searchIcon: {
        position: 'absolute'
    }
}))
