import { KeyValuePair, VoidFunction } from './common'

type ConfigParams = {
    appId: string,
    cookie: boolean,
    xfbml: boolean,
    version: `v${string}`
}

type LoginCallbackResponse = {
    authResponse: {
        accessToken: string
    }
}

export enum LoginStatus {
    Connected = 'connected',
    NotAuthorized = 'not_authorized',
    Unknown = 'unknown'
}

interface LoginStatusCallbackResponse extends LoginCallbackResponse {
    status: LoginStatus
}

type LoginCallback = (response: LoginCallbackResponse) => void
type LoginStatusCallback = (response: LoginStatusCallbackResponse) => void

type LoginConfig = {
    scope: string
}

export type FB = {
    init: (config: ConfigParams) => void,
    login: (response: LoginCallback, config: LoginConfig) => void,
    api: <T extends KeyValuePair>(url: string, response: (response: T) => void) => void,
    getLoginStatus: (response: LoginStatusCallback) => void,
    logout: (response: VoidFunction) => void
}

export type FacebookGetMeResponse = {
    id: string,
    name: string,
    email: string
}
