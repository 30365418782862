import React from 'react'
import { View } from 'react-native'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'

type UnreadDotProps = {
    isVisible: boolean,
    top?: number,
    left?: number,
    right?: number,
    bottom?: number
}

export const UnreadDot: React.FunctionComponent<UnreadDotProps> = ({
    isVisible,
    top,
    left,
    right,
    bottom
}) => {
    const { styles } = useStyles(stylesheet)

    return isVisible ? (
        <View
            style={{
                ...styles.unreadDot,
                top,
                bottom,
                left,
                right
            }}
        />
    ) : null
}

const stylesheet = createStyles(theme => ({
    unreadDot: {
        position: 'absolute',
        top: 0,
        width: 8,
        height: 8,
        borderRadius: 4,
        backgroundColor: theme.colors.flame
    }
}))
