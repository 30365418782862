import { useNavigation } from '@react-navigation/native'
import { useTranslations } from 'lib/hooks'
import { ScreenNames } from 'lib/routing'
import { useUnreadNotificationCounterAtom } from 'lib/atoms'

export const useNotificationsTabs = () => {
    const T = useTranslations()
    const navigation = useNavigation()
    const [unreadNotificationCounter] = useUnreadNotificationCounterAtom()

    return [
        {
            text: T.screens.notifications.tabs.all,
            screenName: ScreenNames.Notifications,
            onPress: () => navigation.navigate(ScreenNames.Notifications)
        },
        {
            text: `${T.screens.notifications.tabs.unread} (${unreadNotificationCounter})`,
            screenName: ScreenNames.UnreadNotifications,
            onPress: () => navigation.navigate(ScreenNames.UnreadNotifications)
        }
    ]
}
