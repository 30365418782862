import React from 'react'
import { View } from 'react-native'
import { Children, Nullable } from 'lib/types'
import { dateHelpers } from 'lib/utils'
import { createStyles } from 'lib/styles'
import { useLocaleAtom } from 'lib/atoms'
import { useStyles, useTranslations } from 'lib/hooks'
import { BookingSummary } from './BookingSummary'
import { CookingFormShape } from '../forms'
import { GetPromotionResponse } from '../types'

type CookingSummaryColumnProps = {
    form: CookingFormShape,
    couponText?: string,
    title?: string,
    renderPriceComponent: () => Children,
    renderPriceSummaryComponent?: () => Children,
    renderSubTotalComponent?: () => Children,
    renderExtraContent?: () => Children,
    promotionPackage?: Nullable<GetPromotionResponse>,
    price?: Nullable<number>,
    totalPrice?: Nullable<number>,
    originalPrice?: Nullable<number>,
}

export const CookingSummaryColumn: React.FunctionComponent<CookingSummaryColumnProps> = ({
    form,
    couponText,
    title,
    renderPriceComponent,
    renderPriceSummaryComponent,
    renderSubTotalComponent,
    renderExtraContent,
    promotionPackage,
    totalPrice,
    originalPrice
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const [locale] = useLocaleAtom()
    const bookingDateDayName = dateHelpers.getWeekDayFullNames(form.startingDate || new Date(), locale)
    const startingDate = form.startingDate
        ? dateHelpers.startingDate(form.startingDate)
        : ''
    const bookingTime = form.bookingTime
        ? dateHelpers.selectTimeLabel(form.bookingTime)
        : form.bookingDays[bookingDateDayName]
            ? dateHelpers.selectTimeLabel(form.bookingDays[bookingDateDayName] || new Date())
            : ''

    return (
        <View style={styles.container}>
            <BookingSummary
                promotionPackage={promotionPackage}
                title={title}
                totalPrice={totalPrice}
                originalPrice={originalPrice}
                couponText={couponText}
                renderPriceComponent={renderPriceComponent}
                renderPriceSummaryComponent={renderPriceSummaryComponent}
                renderSubTotalComponent={renderSubTotalComponent}
                bookingSummary={{
                    [T.components.bookingSummary.cookingFrequency]: form.frequency
                        ? form.frequency.label
                        : '',
                    [T.components.bookingSummary.bookingDate]: startingDate,
                    [T.components.bookingSummary.bookingTime]: bookingTime,
                    [T.components.bookingSummary.cookingStyle]: form.cuisines,
                    [T.components.bookingSummary.hours]: form.hours
                }}
            />
            {renderExtraContent && renderExtraContent()}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        borderWidth: 1,
        borderRadius: 8,
        borderColor: theme.colors.silver,
        paddingTop: theme.utils.gap(2),
        paddingHorizontal: theme.utils.gap(2),
        marginBottom: theme.utils.gap(2),
        backgroundColor: theme.colors.white,
        ...theme.utils.createShadow(2, 0, 8, 4, 4, theme.colors.webShadow(0.04))
    }
}))
