import { useField } from '@codegateinc/react-form-builder-v2'
import { useTranslations } from 'lib/hooks'
import { Nullable } from 'lib/types'
import { SelectAddressFields } from './forms'

export const useSelectPostcodeForm = () => {
    const T = useTranslations()

    const address = useField<Nullable<string>>({
        key: SelectAddressFields.Address,
        initialValue: '',
        validateOnBlur: true,
        isRequired: true,
        placeholder: T.screens.address.formFields.address.placeholder,
        validationRules: [
            {
                errorMessage: T.screens.address.formFields.address.validation.isRequired,
                validate: Boolean
            }
        ]
    })

    return {
        address
    }
}
