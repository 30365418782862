import { View } from 'react-native'
import React, { useEffect, useState } from 'react'
import { Field } from '@codegateinc/react-form-builder-v2'
import { Nullable, SupplyID } from 'lib/types'
import { BasicModal } from 'lib/components'
import { getServiceStaticConfig } from 'features/bookings/utils'
import { PestControlPackageModalContent } from './PestControlPackageModalContent'
import { PestControlPackages } from './PestControlPackages'
import { PestControlServicesList } from './PestControlServicesList'
import { PestControlTabs } from './PestControlTabs'
import { PackageDetailsModalContent, PestControlPackage, PestControlSession } from '../../types'

type PestControlServicesTabsProps = {
    packages: Field<Array<PestControlPackage>>,
    services: Field<Array<PestControlSession>>,
    propertyType: number
}

export const PestControlServicesTabs: React.FunctionComponent<PestControlServicesTabsProps> = ({
    packages,
    services,
    propertyType
}) => {
    const [arePackagesVisible, setPackagesVisibility] = useState(false)
    const [modalContent, setModalContent] = useState<Nullable<PackageDetailsModalContent>>(null)
    const config = getServiceStaticConfig(SupplyID.PestControl)
    const [availablePackages, setAvailablePackages] = useState<Array<PestControlPackage>>([])
    const { pricing } = config

    useEffect(() => {
        const [selectedProperty] = pricing.propertyType.options.filter(item => item.id === propertyType)
        const defaultServices = selectedProperty.services.bySession.items

        const currentPropertyTypePackages = selectedProperty?.services.byPackage.items

        const currentPackages: Array<PestControlPackage> = currentPropertyTypePackages.map(servicePackage => ({
            id: servicePackage.id,
            propertyTypes: [selectedProperty?.area_type],
            title: servicePackage.label,
            package_type: servicePackage.label,
            package_name: servicePackage.label,
            description: servicePackage.description,
            items: servicePackage.options.map(item => ({
                id: item.id,
                title: item.label,
                value: item.id,
                sessions: item.sessions,
                price: item.price,
                isSelected: false,
                details: item.details
            }))
        }))

        const updatedServices = defaultServices.map(service => ({
            id: service.id,
            isSelected: false,
            cost: service.price,
            title: service.label,
            price_per_treatment: service.price,
            number_of_treatments: service.minQuantity,
            minNumberOfTreatments: service.minQuantity,
            pest_type: service.label,
            area_type: selectedProperty.label,
            note: service.notes,
            pestCategory: service.label
        }))

        services.onChangeValue(updatedServices)
        packages.onChangeValue(currentPackages)
        setAvailablePackages(currentPackages)

    }, [propertyType])

    const selectedServicesCount = services.value.filter(service => service.isSelected).length
    const selectedPackagesCount = packages.value.flatMap(p => p.items?.filter(i => i.isSelected)).length

    return (
        <React.Fragment>
            {availablePackages?.length > 0 && (
                <PestControlTabs
                    arePackagesVisible={arePackagesVisible}
                    setPackagesVisibility={setPackagesVisibility}
                    packagesAmount={selectedPackagesCount}
                    servicesAmount={selectedServicesCount}
                />
            )}
            {(arePackagesVisible && availablePackages?.length > 0) ? (
                <View>
                    <PestControlPackages
                        propertyType={propertyType}
                        onDetailsPress={setModalContent}
                        {...packages}
                    />
                </View>
            ) : (
                <PestControlServicesList
                    propertyType={propertyType}
                    {...services}
                />
            )}
            <BasicModal
                isOpen={Boolean(modalContent)}
                onClose={() => setModalContent(null)}
            >
                {modalContent && (
                    <PestControlPackageModalContent {...modalContent}/>
                )}
            </BasicModal>
        </React.Fragment>
    )
}
