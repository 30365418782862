import React from 'react'
import { View } from 'react-native'
import { Icons } from 'assets'
import { Typography } from 'lib/components'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { LaundryStatus } from 'lib/types'
import { useLaundryMilestones } from '../hooks'

type Milestone = {
    label: string,
    value: number
}

type LaundryMilestonesProps = {
    customMilestones?: Array<Milestone>,
    activeMilestone: LaundryStatus | number
}

export const LaundryMilestones: React.FunctionComponent<LaundryMilestonesProps> = ({
    customMilestones,
    activeMilestone = 0
}) => {
    const { styles, theme } = useStyles(stylesheet)
    const laundryMilestones = useLaundryMilestones()

    return (
        <View style={styles.container}>
            {(customMilestones || laundryMilestones).map(({ label, value }, index) => {
                const nextMilestone = activeMilestone + 1
                const labelColor = nextMilestone > value
                    ? theme.colors.sun
                    : theme.colors.fog

                return (
                    <View
                        key={label}
                        style={styles.column}
                    >
                        <Icons.Chevron
                            size={10}
                            forceColor={nextMilestone === value
                                ? theme.colors.black
                                : theme.colors.silver
                            }
                        />
                        <View style={styles.dotContainer}>
                            {index !== 0 && (
                                <View
                                    style={{
                                        ...styles.lineLeft,
                                        backgroundColor: nextMilestone >= value
                                            ? theme.colors.sun
                                            : theme.colors.fog
                                    }}
                                />
                            )}
                            {index !== (customMilestones || laundryMilestones).length - 1 && (
                                <View
                                    style={{
                                        ...styles.lineRight,
                                        backgroundColor: nextMilestone >= value + 1
                                            ? theme.colors.sun
                                            : theme.colors.fog
                                    }}
                                />
                            )}
                            <View
                                style={{
                                    ...styles.dot,
                                    ...(nextMilestone > value ? styles.orangeDot : {}),
                                    ...(nextMilestone === value ? styles.blackDot : {})
                                }}
                            >
                                <Typography.Label
                                    style={styles.step}
                                    forceColor={nextMilestone < value
                                        ? theme.colors.fog
                                        : theme.colors.white
                                    }
                                >
                                    {value}
                                </Typography.Label>
                            </View>
                        </View>
                        <Typography.Label
                            forceColor={nextMilestone === value
                                ? theme.colors.black
                                : labelColor
                            }
                        >
                            {label}
                        </Typography.Label>
                    </View>
                )
            })}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        padding: theme.utils.gap(1),
        backgroundColor: theme.colors.silver,
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'center'
    },
    route: {
        position: 'absolute',
        width: '100%',
        marginHorizontal: theme.utils.gap(7),
        height: 1,
        backgroundColor: theme.colors.black
    },
    lineLeft: {
        position: 'absolute',
        width: '50%',
        left: 0,
        height: 1,
        backgroundColor: theme.colors.fog
    },
    lineRight: {
        position: 'absolute',
        width: '50%',
        right: 0,
        height: 1,
        backgroundColor: theme.colors.fog
    },
    lineBlack: {
        backgroundColor: theme.colors.black
    },
    column: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
    },
    step: {
        fontSize: {
            lg: 12,
            xs: 10
        }
    },
    dotContainer: {
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center'
    },
    dot: {
        width: 20,
        height: 20,
        borderRadius: 10,
        alignItems: 'center',
        justifyContent: 'center',
        marginVertical: theme.utils.gap(1),
        borderWidth: 1,
        borderColor: theme.colors.fog,
        backgroundColor: theme.colors.silver
    },
    blackDot: {
        backgroundColor: theme.colors.black,
        borderColor: theme.colors.black
    },
    orangeDot: {
        backgroundColor: theme.colors.sun,
        borderColor: theme.colors.sun
    }
}))
