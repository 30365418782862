import React from 'react'
import { Field } from '@codegateinc/react-form-builder-v2'
import { Segment } from 'lib/analytics'
import { HandymanService } from 'lib/models'
import { Nullable, NumberInputActions } from 'lib/types'
import { HandymanServiceFields } from 'features/bookings'
import { HandymanServiceDetailsItem } from './HandymanServiceDetailsItem'

interface HandymanServiceDetailsProps extends Field<Array<HandymanService>> {
    disabled?: boolean,
    isOpen: boolean
    commentField: Field<string>,
}

export const HandymanServiceDetails: React.FunctionComponent<HandymanServiceDetailsProps> = React.memo(({
    value,
    onChangeValue,
    commentField
}) => {
    const handleRemove = (service: HandymanService) => {
        const newValue = value.filter(selectedService =>
            selectedService.item !== service.item
            || selectedService.category !== service.category
        )

        onChangeValue(newValue)
    }

    const handleQuantityChange = (action: NumberInputActions, service: HandymanService) => {
        const newValue = value.map(selectedService => {
            if (selectedService.item !== service.item || selectedService.category !== service.category) {
                return selectedService
            }

            return {
                ...selectedService,
                amount: action === NumberInputActions.Add
                    ? selectedService.amount + 1
                    : selectedService.amount - 1
            }
        })

        onChangeValue(newValue)
    }

    const updateServiceField = (fieldValue: Nullable<string>, service: HandymanService, fieldKey: HandymanServiceFields) => {
        const newValue = value.map(selectedService => {
            if (selectedService.item !== service.item || selectedService.category !== service.category) {
                return selectedService
            }

            Segment.bookingServiceTypeSelected({
                category: service.category,
                item: service.item,
                type: fieldValue || ''
            })

            return {
                ...selectedService,
                [fieldKey]: fieldValue
            }
        })

        onChangeValue(newValue)
    }

    const onPartsChange = (part: string, isSelected: boolean, service: HandymanService) => {
        const newValue = value.map(selectedService => {
            if (selectedService.item !== service.item || selectedService.category !== service.category) {
                return selectedService
            }

            return {
                ...selectedService,
                [HandymanServiceFields.Part]: isSelected
                    ? selectedService.part.filter(item => item !== part)
                    : [...selectedService.part, part]
            }
        })

        onChangeValue(newValue)
    }

    const onRemoveDetails = (service: HandymanService) => {
        const newValue = value.map(selectedService => {
            if (selectedService.item !== service.item || selectedService.category !== service.category) {
                return selectedService
            }

            return {
                ...selectedService,
                [HandymanServiceFields.Comment]: null,
                [HandymanServiceFields.OthersComment]: null,
                [HandymanServiceFields.Part]: []
            }
        })

        onChangeValue(newValue)
    }

    return (
        <React.Fragment>
            {value.map((service, index) => (
                <HandymanServiceDetailsItem
                    index={index}
                    onRemoveDetails={onRemoveDetails}
                    key={`${service.category}-${service.item}`}
                    service={service}
                    commentField={commentField}
                    onPartsChange={onPartsChange}
                    onUpdateServiceField={updateServiceField}
                    onQuantityChange={handleQuantityChange}
                    onRemove={() => handleRemove(service)}
                />
            ))}
        </React.Fragment>
    )
}, (prevProps, nextProps) => {
    if (!nextProps.isOpen) {
        return false
    }

    return prevProps.isOpen === nextProps.isOpen
})
