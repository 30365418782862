import { datadogRum } from '@datadog/browser-rum'
import { Segment } from 'lib/analytics'
import { isNative } from 'lib/common'
import { authUtils, cookieUtils } from 'features/auth'
import { GoogleSignin } from '@react-native-google-signin/google-signin'
import { mmkvStorage } from '../mmkv'
import { useResetAtoms } from '../atoms'

export const useCleanApp = () => {
    const { resetAtoms } = useResetAtoms()

    return {
        cleanPersonalData: () => {
            Segment.logout()
            datadogRum.clearUser()

            authUtils.clearAuthTokens()
            cookieUtils.removeAuthCookies()
            cookieUtils.removeMarketingConsentCookie()

            if (isNative) {
                GoogleSignin.signOut()
            }
            // todo sign out from social providers
            // todo how to check auth provider?
            // if (authProvider === AuthProvider.Facebook) {
            //     socialActions.logoutFromFacebook()
            //         .catch(R.T)
            // }

            resetAtoms()
            mmkvStorage.deleteAll()
        }
    }
}
