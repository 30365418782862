import { FrequencyOption, Nullable } from 'lib/types'

export enum BookingsFiltersFields {
    SearchValue = 'searchValue',
    Service = 'service',
    Date = 'date'
}

export type BookingsFiltersShape = {
    [BookingsFiltersFields.SearchValue]: string
    [BookingsFiltersFields.Service]: string,
    [BookingsFiltersFields.Date]: string
}

export enum CancelationFields {
    Reason = 'reason',
    CustomReason = 'customReason'
}

export enum CancelationPackageFields {
    CancellationOption = 'cancellationOption',
    Reason = 'reason',
    CustomReason = 'customReason'
}

export type CancelationShape = {
    [CancelationFields.Reason]: string
    [CancelationFields.CustomReason]: string
}

export enum RescheduleFields {
    Frequency = 'frequency',
    StartingDate = 'startingDate',
    BookingTime = 'bookingTime',
    BookingDays = 'bookingDays'
}

export type RescheduleFormShape = {
    [RescheduleFields.Frequency]: FrequencyOption,
    [RescheduleFields.StartingDate]: string,
    [RescheduleFields.BookingTime]: string,
    [RescheduleFields.BookingDays]: Record<string, Nullable<string>>,
}

export enum ExtendSessionFields {
    ExtraHours = 'extraHours'
}

export type ExtendSessionFormShape = {
    [ExtendSessionFields.ExtraHours]: string
}

export enum PauseBookingsFields {
    StartDate = 'startDate',
    EndDate = 'endDate'
}

export type PauseBookingsFormShape = {
    [PauseBookingsFields.StartDate]: string
    [PauseBookingsFields.EndDate]: string
}

export enum CancelBookingFields {
    Reason = 'reason'
}

export type CancelBookingShape = {
    [CancelationFields.Reason]: string
}

export type CancelationPackageShape = {
    [CancelationPackageFields.Reason]: string
    [CancelationPackageFields.CancellationOption]: string
    [CancelationPackageFields.CustomReason]: string
}
