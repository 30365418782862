import React from 'react'
import { View } from 'react-native'
import { MediaQuery } from 'lib/components'
import { Breakpoint, createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { JobStatus } from 'lib/types'
import { JobName } from './JobName'
import { JobRating } from './JobRating'
import { JobDetailsResponse } from '../types'
import { CancelRequest } from './CancelRequest'
import { ServiceLocation } from './ServiceLocation'
import { SubmittedMessage } from './SubmittedMessage'
import { BookingsProposals } from './BookingsProposals'
import { AdditionalInformation } from './AdditionalInformation'
import { ServiceDetailsAccepted } from './ServiceDetailsAccepted'
import { DeepCleaningServiceDate } from './DeepCleaningServiceDate'
import { DeepCleaningServices } from './DeepCleaningServices'
import { RescheduleSession } from './RescheduleSession'
import { DeepCleanPropertyInfo } from './DeepCleanPropertyInfo'

type DeepCleaningDetailsContentProps = {
    data: JobDetailsResponse,
    jobStatus?: JobStatus
}

export const DeepCleaningDetailsContent: React.FunctionComponent<DeepCleaningDetailsContentProps> = ({
    data,
    jobStatus
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const isSubmitted = data.job.status === JobStatus.Submitted

    return (
        <View style={styles.wrapper}>
            <View style={styles.contentWrapper}>
                <View style={styles.content}>
                    <JobName
                        job={data}
                        isRecurring={false}
                    />
                    {isSubmitted && (
                        <SubmittedMessage />
                    )}
                    <DeepCleaningServiceDate
                        job={data}
                        isSubmitted={isSubmitted}
                    />
                    <RescheduleSession data={data} />
                    <DeepCleanPropertyInfo job={data} />
                    <DeepCleaningServices
                        services={data.job.service_type_deepcleaning}
                    />
                    <AdditionalInformation job={data} />
                    <ServiceLocation job={data.job} />
                    <CancelRequest
                        job={data}
                        text={isSubmitted
                            ? T.screens.jobDetails.cancelRequest
                            : undefined
                        }
                        jobStatus={jobStatus}
                    />
                </View>
                {jobStatus === JobStatus.Completed && (
                    <JobRating job={data} />
                )}
            </View>
            {isSubmitted
                ? (
                    <BookingsProposals
                        job={data}
                        isRecurring={false}
                    />
                ) : (
                    <React.Fragment>
                        <MediaQuery.Visible from={Breakpoint.LG}>
                            <ServiceDetailsAccepted
                                job={data}
                                jobStatus={jobStatus}
                                isRecurringAccepted={false}
                            />
                        </MediaQuery.Visible>
                    </React.Fragment>
                )
            }
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        flexDirection: {
            lg: 'row',
            xs: 'column'
        },
        alignItems: {
            lg: 'flex-start',
            xs: undefined
        },
        marginTop: -theme.utils.gap(6),
        paddingHorizontal: {
            lg: theme.utils.gap(6),
            xs: theme.utils.gap(2)
        }
    },
    contentWrapper: {
        flex: 1
    },
    content: {
        borderRadius: 8,
        borderWidth: 1,
        paddingHorizontal: theme.utils.gap(2),
        paddingVertical: theme.utils.gap(3),
        backgroundColor: theme.colors.white,
        borderColor: theme.colors.silver,
        backGroundColor: theme.colors.white,
        ...theme.utils.createShadow(2, 0, 8, 4, 4, theme.colors.webShadow(0.04))
    }
}))
