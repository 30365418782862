import { useTranslations } from 'lib/hooks'

export const useHandymanServicesTypes = () => {
    const T = useTranslations()

    return {
        [T.components.handymanServices.general.items.door]: [
            T.components.handymanServiceType.repair,
            T.components.handymanServiceType.replace,
            T.components.handymanServiceType.installation,
            T.components.handymanServiceType.others
        ],
        [T.components.handymanServices.general.items.curtain]: [
            T.components.handymanServiceType.repair,
            T.components.handymanServiceType.replace,
            T.components.handymanServiceType.installation,
            T.components.handymanServiceType.others
        ],
        [T.components.handymanServices.general.items.tiles]: [
            T.components.handymanServiceType.repair,
            T.components.handymanServiceType.replace,
            T.components.handymanServiceType.installation,
            T.components.handymanServiceType.others
        ],
        [T.components.handymanServices.general.items.furniture]: [
            T.components.handymanServiceType.assembling,
            T.components.handymanServiceType.disassembling,
            T.components.handymanServiceType.disposal,
            T.components.handymanServiceType.repair,
            T.components.handymanServiceType.others
        ],
        [T.components.handymanServices.general.items.tvBracket]: [
            T.components.handymanServiceType.repair,
            T.components.handymanServiceType.replace,
            T.components.handymanServiceType.installation,
            T.components.handymanServiceType.others
        ],
        [T.components.handymanServices.general.items.others]: [],
        [T.components.handymanServices.electrical.items.powerPoint]: [
            T.components.handymanServiceType.repair,
            T.components.handymanServiceType.replace,
            T.components.handymanServiceType.installation,
            T.components.handymanServiceType.others
        ],
        [T.components.handymanServices.electrical.items.ceilingFan]: [
            T.components.handymanServiceType.repair,
            T.components.handymanServiceType.installation,
            T.components.handymanServiceType.troubleshoot,
            T.components.handymanServiceType.others
        ],
        [T.components.handymanServices.electrical.items.light]: [
            T.components.handymanServiceType.repair,
            T.components.handymanServiceType.replace,
            T.components.handymanServiceType.installation,
            T.components.handymanServiceType.others
        ],
        [T.components.handymanServices.electrical.items.writingExtension]: [
            T.components.handymanServiceType.extension,
            T.components.handymanServiceType.rewiring,
            T.components.handymanServiceType.troubleshoot,
            T.components.handymanServiceType.others
        ],
        [T.components.handymanServices.general.items.others]: [],
        [T.components.handymanServices.painting.items.wholeHouse]: [
            T.components.handymanServiceType.touchUp,
            T.components.handymanServiceType.fullPainting,
            T.components.handymanServiceType.others
        ],
        [T.components.handymanServices.painting.items.wholeShop]: [
            T.components.handymanServiceType.touchUp,
            T.components.handymanServiceType.fullPainting,
            T.components.handymanServiceType.others
        ],
        [T.components.handymanServices.painting.items.room]: [
            T.components.handymanServiceType.touchUp,
            T.components.handymanServiceType.fullPainting,
            T.components.handymanServiceType.others
        ],
        [T.components.handymanServices.painting.items.ceiling]: [
            T.components.handymanServiceType.touchUp,
            T.components.handymanServiceType.fullPainting,
            T.components.handymanServiceType.others
        ],
        [T.components.handymanServices.painting.items.roof]: [
            T.components.handymanServiceType.touchUp,
            T.components.handymanServiceType.fullPainting,
            T.components.handymanServiceType.others
        ],
        [T.components.handymanServices.painting.items.wall]: [
            T.components.handymanServiceType.touchUp,
            T.components.handymanServiceType.fullPainting,
            T.components.handymanServiceType.others
        ],
        [T.components.handymanServices.painting.items.others]: [],
        [T.components.handymanServices.plumbing.items.waterTap]: [
            T.components.handymanServiceType.repair,
            T.components.handymanServiceType.replace,
            T.components.handymanServiceType.installation,
            T.components.handymanServiceType.others
        ],
        [T.components.handymanServices.plumbing.items.toiletCover]: [
            T.components.handymanServiceType.repair,
            T.components.handymanServiceType.replace,
            T.components.handymanServiceType.installation,
            T.components.handymanServiceType.others
        ],
        [T.components.handymanServices.plumbing.items.toiletBowl]: [
            T.components.handymanServiceType.repair,
            T.components.handymanServiceType.replace,
            T.components.handymanServiceType.installation,
            T.components.handymanServiceType.unclog,
            T.components.handymanServiceType.others
        ],
        [T.components.handymanServices.plumbing.items.sink]: [
            T.components.handymanServiceType.repair,
            T.components.handymanServiceType.replace,
            T.components.handymanServiceType.installation,
            T.components.handymanServiceType.unclog,
            T.components.handymanServiceType.others
        ],
        [T.components.handymanServices.plumbing.items.bidet]: [
            T.components.handymanServiceType.repair,
            T.components.handymanServiceType.replace,
            T.components.handymanServiceType.installation,
            T.components.handymanServiceType.others
        ],
        [T.components.handymanServices.plumbing.items.waterHeater]: [
            T.components.handymanServiceType.repair,
            T.components.handymanServiceType.replace,
            T.components.handymanServiceType.installation,
            T.components.handymanServiceType.others
        ],
        [T.components.handymanServices.plumbing.items.shower]: [
            T.components.handymanServiceType.repair,
            T.components.handymanServiceType.replace,
            T.components.handymanServiceType.installation,
            T.components.handymanServiceType.others
        ],
        [T.components.handymanServices.plumbing.items.others]: []
    }
}
