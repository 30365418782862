import React from 'react'
import { View } from 'react-native'
import { Children, Nullable } from 'lib/types'
import { dateHelpers } from 'lib/utils'
import { createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { BookingSummary } from './BookingSummary'
import { AirConditionFormShape } from '../forms'
import { GetPromotionResponse, JobQuotationResponse } from '../types'

type AirConditionSummaryColumnProps = {
    form: AirConditionFormShape,
    couponText?: string,
    title?: string,
    priceMessage?: string,
    renderPriceSummaryComponent?: () => Children,
    renderPriceComponent: () => Children,
    renderSubTotalComponent?: () => Children,
    renderExtraContent?: () => Children,
    promotionPackage?: Nullable<GetPromotionResponse>
    totalPrice?: Nullable<number>,
    originalPrice?: Nullable<number>,
    jobQuotation?: JobQuotationResponse
}

export const AirConditionSummaryColumn: React.FunctionComponent<AirConditionSummaryColumnProps> = ({
    form,
    totalPrice,
    originalPrice,
    couponText,
    title,
    priceMessage,
    renderPriceComponent,
    renderPriceSummaryComponent,
    renderExtraContent,
    promotionPackage,
    jobQuotation,
    renderSubTotalComponent
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const startingDate = form.startingDate
        ? dateHelpers.startingDate(form.startingDate)
        : ''
    const bookingTime = form.bookingTime
        ? ` ${dateHelpers.selectTimeLabel(form.bookingTime)}`
        : ''

    return (
        <View style={styles.container}>
            <BookingSummary
                totalPrice={totalPrice}
                originalPrice={originalPrice}
                title={title}
                numberOfLines={2}
                couponText={couponText}
                pricingMessage={priceMessage}
                promotionPackage={promotionPackage}
                renderPriceComponent={renderPriceComponent}
                renderPriceSummaryComponent={renderPriceSummaryComponent}
                renderSubTotalComponent={renderSubTotalComponent}
                bookingSummary={{
                    [T.components.bookingSummary.servicePlan]: form.serviceOption.title,
                    [T.components.bookingSummary.serviceDateAndTime]: `${startingDate}\n${bookingTime}`,
                    [T.components.bookingSummary.numberOfAC]: String(form.numberOfAirConditioners),
                    ...(jobQuotation?.platformFee
                        ? {
                            [T.components.bookingSummary.platformFee]: jobQuotation.platformFee.value
                        }
                        : {}
                    )
                }}
                platformFeeDescription={jobQuotation?.platformFee?.description}
            />
            {renderExtraContent && renderExtraContent()}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        borderWidth: 1,
        borderRadius: 8,
        borderColor: theme.colors.silver,
        paddingTop: theme.utils.gap(2),
        paddingHorizontal: theme.utils.gap(2),
        marginBottom: theme.utils.gap(2),
        backgroundColor: theme.colors.white,
        ...theme.utils.createShadow(2, 0, 8, 4, 4, theme.colors.webShadow(0.04))
    }
}))
