import React from 'react'
import { View } from 'react-native'
import { Children } from 'lib/types'
import { createStyles } from 'lib/styles'
import { useStyles } from 'lib/hooks'
import { SmallSubheading } from './typography'
import { LoadingIndicator } from './LoadingIndicator'

type SectionBoxProps = {
    title?: string,
    children: Children
    renderLeftColumnContent?:() => Children,
    isLoading?: boolean,
    bordered?: boolean,
}

export const SectionBox: React.FunctionComponent<SectionBoxProps> = ({
    title,
    renderLeftColumnContent,
    isLoading = false,
    children,
    bordered
}) => {
    const { styles } = useStyles(stylesheet)
    const leftColumnContent = renderLeftColumnContent
        ? renderLeftColumnContent()
        : null

    return (
        <View style={bordered ? styles.borderedContainer : styles.container}>
            <LoadingIndicator isLoading={isLoading} />
            {(leftColumnContent || title) && (
                <View style={styles.leftColumn}>
                    <SmallSubheading>
                        {title}
                    </SmallSubheading>
                    {leftColumnContent}
                </View>
            )}
            <View style={styles.rightColumn}>
                {children}
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        overflow: 'hidden',
        flexDirection: {
            lg: 'row',
            ':w[0, 1200]': 'column'
        },
        marginTop: {
            lg: theme.utils.gap(3),
            xs: theme.utils.gap(1)
        },
        marginHorizontal: {
            lg: 0,
            xs: theme.utils.gap(2)
        },
        paddingHorizontal: {
            lg: theme.utils.gap(2),
            xs: 0
        },
        borderColor: theme.colors.silver,
        borderWidth: {
            lg: 1,
            xs: 0
        },
        borderRadius: {
            lg: 8,
            xs: 0
        },
        borderBottomWidth: {
            lg: 1,
            xs: 2
        }
    },
    borderedContainer: {
        overflow: 'hidden',
        flexDirection: {
            lg: 'row',
            ':w[0, 1200]': 'column'
        },
        marginTop: {
            lg: theme.utils.gap(3),
            xs: theme.utils.gap(1)
        },
        marginHorizontal: {
            lg: 0,
            xs: theme.utils.gap(2)
        },
        paddingHorizontal: theme.utils.gap(2),
        borderColor: theme.colors.silver,
        borderWidth: 1,
        borderRadius: 8,
        borderBottomWidth: 1
    },
    leftColumn: {
        width: {
            lg: 300,
            xs: '100%'
        },
        paddingVertical: {
            lg: theme.utils.gap(2),
            xs: theme.utils.gap(1)
        }
    },
    rightColumn: {
        flex: 1
    }
}))
