import React from 'react'
import { Field } from '@codegateinc/react-form-builder-v2'
import { View, ViewStyle } from 'react-native'
import { useStyles } from 'lib/hooks'
import { IconProps, SelectInputOption } from 'lib/types'
import { Breakpoint, createStyles } from 'lib/styles'
import { MediaQuery, Typography } from 'lib/components'
import { FormComponents } from '../components'

interface SelectInputProps extends Field<string> {
    options: Array<SelectInputOption>,
    disabled?: boolean,
    testID?: string,
    isClearable?: boolean,
    isSearchable?: boolean,
    defaultValue?: SelectInputOption,
    dropdownStyles?: boolean,
    onInputChange?: (value: string) => void,
    inputCustomStyles?: ViewStyle,
    wrapperCustomStyles?: ViewStyle,
    customPlaceholder?: boolean,
    leftIcon?: React.FunctionComponent<IconProps>
}

export const SelectInput: React.FunctionComponent<SelectInputProps> = ({
    label,
    options,
    isRequired,
    onChangeValue,
    onInputChange,
    value,
    placeholder,
    errorMessage,
    leftIcon,
    testID,
    isSearchable,
    inputCustomStyles,
    customPlaceholder = false,
    wrapperCustomStyles = {},
    dropdownStyles = false,
    isClearable = true,
    disabled = false
}) => {
    const { styles, theme } = useStyles(stylesheet)

    const getSelectValue = () => {
        if (value && onInputChange) {
            return options.find(item => item.value === value) || { value, label: value }
        }

        return options.find(item => item.value === value) || null
    }

    return (
        <View
            style={{
                ...styles.row,
                ...wrapperCustomStyles
            }}
        >
            {label && (
                <View style={styles.labelColumn}>
                    <Typography.Regular>
                        <Typography.Regular>
                            {label}
                        </Typography.Regular>
                        <Typography.Label forceColor={theme.components.input.typography.error}>
                            {isRequired ? '*' : ''}
                        </Typography.Label>
                    </Typography.Regular>
                    <MediaQuery.Visible from={Breakpoint.LG}>
                        <FormComponents.ErrorMessage />
                    </MediaQuery.Visible>
                </View>
            )}
            <View style={styles.input}>
                <FormComponents.SelectInput
                    testID={testID}
                    options={options}
                    disabled={disabled}
                    leftIcon={leftIcon}
                    isClearable={isClearable}
                    inputValue={value}
                    isSearchable={isSearchable}
                    errorMessage={errorMessage}
                    placeholder={placeholder}
                    dropdownStyles={dropdownStyles}
                    onInputChange={onInputChange}
                    value={getSelectValue()}
                    customPlaceholder={customPlaceholder}
                    inputCustomStyles={inputCustomStyles}
                    onChange={option => onChangeValue(option?.value || '')}
                />
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    input: {
        flex: 1
    },
    row: {
        zIndex: theme.zIndex[10],
        alignItems: {
            lg: 'center',
            xs: 'stretch'
        },
        flexDirection: {
            lg: 'row',
            xs: 'column'
        }
    },
    labelColumn: {
        width: {
            lg: 120,
            xs: '100%'
        },
        marginRight: {
            lg: theme.utils.gap(1),
            xs: 0
        },
        marginBottom: {
            lg: 0,
            xs: theme.utils.gap(1)
        }
    },
    textRow: {
        flexDirection: 'row',
        flexWrap: 'wrap'
    }
}))
