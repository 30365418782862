import React from 'react'
import { View } from 'react-native'
import { UseFormReturn } from '@codegateinc/react-form-builder-v2'
import { Children, Nullable } from 'lib/types'
import { dateHelpers } from 'lib/utils'
import { createStyles } from 'lib/styles'
import { GetPromotionResponse, JobQuotationResponse } from 'features/bookings'
import { useStyles, useTranslations } from 'lib/hooks'
import { BookingSummary } from '../BookingSummary'
import { MoversAndPackersAddonsShape, MoversAndPackersFormShape } from '../../forms'
import { moversAndPackersHooks } from '../../hooks'

type MoversAndPackersSummaryColumnProps = {
    form: MoversAndPackersFormShape,
    summaryForm?: UseFormReturn<MoversAndPackersAddonsShape>,
    inspectionDays?: string,
    couponText?: string,
    title?: string,
    priceMessage?: string,
    renderPriceComponent: () => Children,
    renderExtraContent?: () => Children,
    promotionPackage?: Nullable<GetPromotionResponse>,
    jobQuotation?: JobQuotationResponse
    price?: Nullable<number>,
    totalPrice?: Nullable<number>,
    originalPrice?: Nullable<number>
}

export const MoversAndPackersSummaryColumn: React.FunctionComponent<MoversAndPackersSummaryColumnProps> = ({
    form,
    summaryForm,
    inspectionDays,
    couponText,
    title,
    priceMessage,
    renderPriceComponent,
    renderExtraContent,
    promotionPackage,
    jobQuotation,
    totalPrice,
    originalPrice
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const { serviceTypeOptions } = moversAndPackersHooks.useMoversAndPackersOptions()
    const matchingService = serviceTypeOptions.find(serviceType => serviceType.value === form.serviceType)
    const startingDate = form.startingDate
        ? dateHelpers.startingDate(form.startingDate)
        : ''
    const bookingTime = form.bookingTime
        ? ` ${dateHelpers.selectTimeLabel(form.bookingTime)}`
        : ''
    const pickUpFloors = form.pickUpHasElevator
        ? 0
        : Number(form.pickUpFloor)
    const dropOffFloors = form.dropOffHasElevator
        ? 0
        : Number(form.dropOffFloor)
    const totalFloors = pickUpFloors + dropOffFloors
    const staircaseFloors = `${totalFloors} ${totalFloors === 1
        ? T.screens.moversAndPackers.floor
        : T.screens.moversAndPackers.floors
    }`
    const showFloors = form.pickUpPropertyType || form.dropOffPropertyType

    return (
        <View style={styles.container}>
            <BookingSummary
                title={title}
                totalPrice={totalPrice}
                originalPrice={originalPrice}
                promotionPackage={promotionPackage}
                couponText={couponText}
                pricingMessage={priceMessage}
                renderPriceComponent={renderPriceComponent}
                bookingSummary={{
                    [T.components.bookingSummary.serviceDate]: `${startingDate}\n${bookingTime}`.trim(),
                    [T.components.bookingSummary.expertiseType]: matchingService?.label || '-',
                    ...(matchingService?.label
                        ? {
                            [T.components.bookingSummary.staircase]: showFloors ? staircaseFloors : '-'
                        }
                        : {}),
                    ...(inspectionDays
                        ? {
                            [T.components.bookingSummary.inspectionDate]: inspectionDays
                        }
                        : {}),
                    ...(summaryForm?.form.movers.value
                        ? {
                            [T.screens.moversAndPackersSummary.formFields.movers.label]: summaryForm.form.movers.value
                        }
                        : {}),
                    ...(summaryForm?.form.tailgating.value
                        ? {
                            [T.screens.moversAndPackersSummary.formFields.tailgating.label]: T.common.yes
                        }
                        : {}
                    ),
                    ...(summaryForm?.form.wrapping.value
                        ? {
                            [T.screens.moversAndPackersSummary.formFields.wrapping.label]: summaryForm.form.wrapping.value
                        }
                        : {}
                    ),
                    ...(jobQuotation?.platformFee
                        ? {
                            [T.components.bookingSummary.platformFee]: jobQuotation.platformFee.value
                        }
                        : {}
                    )
                }}
                platformFeeDescription={jobQuotation?.platformFee?.description}
            />
            {renderExtraContent && renderExtraContent()}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        borderWidth: 1,
        borderRadius: 8,
        borderColor: theme.colors.silver,
        paddingVertical: theme.utils.gap(2),
        paddingHorizontal: theme.utils.gap(2),
        marginBottom: theme.utils.gap(2),
        backgroundColor: theme.colors.white,
        ...theme.utils.createShadow(2, 0, 8, 4, 4, theme.colors.webShadow(0.04))
    }
}))
