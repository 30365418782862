import { useField } from '@codegateinc/react-form-builder-v2'
import { useSupplyOptions, useTranslations } from 'lib/hooks'
import { Nullable } from 'lib/types'
import { FavouritesFiltersFields } from './forms'

export const useServiceFiltersForm = () => {
    const T = useTranslations()
    const [initialValue] = useSupplyOptions()

    const service = useField<Nullable<string>>({
        key: FavouritesFiltersFields.Service,
        initialValue: initialValue.value.toString(),
        isRequired: false,
        validateOnBlur: false,
        placeholder: T.common.service
    })

    return {
        service
    }
}
