import { DropzoneOptions, useDropzone } from 'react-dropzone'

export const useDropZone = (options: DropzoneOptions) => {
    const { getRootProps, isDragActive } = useDropzone(options)

    return {
        isDragActive,
        rootProps: getRootProps()
    }
}
