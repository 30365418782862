import { AppleID, FB, Google } from '../types'

type SendhelperEnvs = {
    DD_ENABLED?: string,
    DD_CLIENT_TOKEN?: string,
    DD_RUM_APP_ID?: string,
    DD_SITE?: string,
    DD_SERVICE?: string,
    DD_VERSION?: string,
    FIREBASE_API_KEY?: string,
    FIREBASE_AUTH_DOMAIN?: string,
    FIREBASE_PROJECT_ID?: string,
    FIREBASE_STORAGE_BUCKET?: string,
    FIREBASE_MESSAGING_SENDER_ID?: string,
    FIREBASE_APP_ID?: string,
    API_URL?: string,
    DEPRECATED_APP_VERSION?: string,
    APP_ENV?: string,
    COOKIE_SHARED_DOMAIN?: string,
    COOKIE_SHARED_SUFFIX?: string
    NODE_ENV?: string,
    FACEBOOK_APP_ID?: string,
    FACEBOOK_CLIENT_TOKEN?: string,
    FACEBOOK_DISPLAY_NAME?: string,
    FACEBOOK_SCHEME?: string,
    WEBSOCKET_URL?: string,
    TIMESTAMP_DEFAULT?: string,
    MAX_RECEIVED_MESSAGE?: string,
    MAX_IMAGE_UPLOAD_SIZE_MB?: string,
    SUFFIX_SERVER_CHAT_NAME?: string,
    SUFFIX_ROOM_NAME?: string,
    APPLE_BUNDLE_IDENTIFIER?: string,
    GOOGLE_PLAY_STORE_URL?: string
    APP_STORE_URL?: string,
    SENDHELPER_MARKETING_SITE_URL?: string
    SENDHELPER_CLEANING_INFO_URL?: string,
    SENDHELPER_CLEANING_WHAT_IS_INCLUDED_URL?: string,
    SENDHELPER_CANCELLATION_CHARGES?: string,
    ONEMAP_API?: string,
    GOOGLE_TRACKING_ID?: string,
    GOOGLE_TAG_MANAGER_ID?: string,
    SEGMENT_WRITE_KEY?: string,
    ENABLE_HANDYMAN?: string,
    ENABLE_LAUNDRY?: string,
    ENABLE_PEST_CONTROL?: string,
    ENABLE_COOKING?: string,
    ENABLE_TASK_ERRANDS?: string,
    ENABLE_MOVERS_AND_PACKERS?: string,
    SEGMENT_APPS_FLYER_ID?: string,
    SEGMENT_WRITE_KEY_IOS?: string,
    SEGMENT_WRITE_KEY_ANDROID?: string,
    SEGMENT_COOKIE_DOMAIN?: string,
    QUOTATION_URL?: string,
    BRAZE_SDK_ENDPOINT?: string,
    BRAZE_SDK_KEY_ANDROID?: string,
    BRAZE_SDK_KEY_IOS?: string,
    DEEPLINK_CUSTOM_DOMAIN?: string,
    GOOGLE_WEB_CLIENT_ID?: string,
    GOOGLE_IOS_CLIENT_ID?: string,
    GOOGLE_IOS_URL_SCHEME?: string,
    GOOGLE_PROFILE_URL?: string,
    GOOGLE_SDK_URL?: string
}

declare global {
    export interface Window {
        env: SendhelperEnvs,
        FB: FB,
        AppleID: AppleID,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        gtag: (...args: Array<any>) => void
        google: Google,
        // todo only temporary
        ENABLE_API_URL_V2: boolean
    }
}

// todo fix it
export const ENV: SendhelperEnvs = {
    DD_ENABLED: window.env?.DD_ENABLED || process.env.DD_ENABLED,
    DD_CLIENT_TOKEN: window.env?.DD_CLIENT_TOKEN || process.env.DD_CLIENT_TOKEN,
    DD_RUM_APP_ID: window.env?.DD_RUM_APP_ID || process.env.DD_RUM_APP_ID,
    DD_SITE: window.env?.DD_SITE || process.env.DD_SITE,
    DD_SERVICE: window.env?.DD_SERVICE || process.env.DD_SERVICE,
    DD_VERSION: window.env?.DD_VERSION || process.env.DD_VERSION,
    FIREBASE_API_KEY: window.env?.FIREBASE_API_KEY || process.env.FIREBASE_API_KEY,
    FIREBASE_AUTH_DOMAIN: window.env?.FIREBASE_AUTH_DOMAIN || process.env.FIREBASE_AUTH_DOMAIN,
    FIREBASE_PROJECT_ID: window.env?.FIREBASE_PROJECT_ID || process.env.FIREBASE_PROJECT_ID,
    FIREBASE_STORAGE_BUCKET: window.env?.FIREBASE_STORAGE_BUCKET || process.env.FIREBASE_STORAGE_BUCKET,
    FIREBASE_MESSAGING_SENDER_ID: window.env?.FIREBASE_MESSAGING_SENDER_ID || process.env.FIREBASE_MESSAGING_SENDER_ID,
    FIREBASE_APP_ID: window.env?.FIREBASE_APP_ID || process.env.FIREBASE_APP_ID,
    API_URL: window.env?.API_URL || process.env.API_URL,
    DEPRECATED_APP_VERSION: window.env?.DEPRECATED_APP_VERSION || process.env.DEPRECATED_APP_VERSION,
    APP_ENV: window.env?.APP_ENV || process.env.APP_ENV,
    COOKIE_SHARED_DOMAIN: window.env?.COOKIE_SHARED_DOMAIN || process.env.COOKIE_SHARED_DOMAIN,
    COOKIE_SHARED_SUFFIX: window.env?.COOKIE_SHARED_SUFFIX || process.env.COOKIE_SHARED_SUFFIX,
    NODE_ENV: window.env?.NODE_ENV || process.env.NODE_ENV,
    FACEBOOK_APP_ID: window.env?.FACEBOOK_APP_ID || process.env.FACEBOOK_APP_ID,
    FACEBOOK_CLIENT_TOKEN: window.env?.FACEBOOK_CLIENT_TOKEN || process.env.FACEBOOK_CLIENT_TOKEN,
    FACEBOOK_DISPLAY_NAME: window.env?.FACEBOOK_DISPLAY_NAME || process.env.FACEBOOK_DISPLAY_NAME,
    FACEBOOK_SCHEME: window.env?.FACEBOOK_SCHEME || process.env.FACEBOOK_SCHEME,
    WEBSOCKET_URL: window.env?.WEBSOCKET_URL || process.env.WEBSOCKET_URL,
    TIMESTAMP_DEFAULT: window.env?.TIMESTAMP_DEFAULT || process.env.TIMESTAMP_DEFAULT,
    MAX_RECEIVED_MESSAGE: window.env?.MAX_RECEIVED_MESSAGE || process.env.MAX_RECEIVED_MESSAGE,
    SUFFIX_SERVER_CHAT_NAME: window.env?.SUFFIX_SERVER_CHAT_NAME || process.env.SUFFIX_SERVER_CHAT_NAME,
    SUFFIX_ROOM_NAME: window.env?.SUFFIX_ROOM_NAME || process.env.SUFFIX_ROOM_NAME,
    MAX_IMAGE_UPLOAD_SIZE_MB: window.env?.MAX_IMAGE_UPLOAD_SIZE_MB || process.env.MAX_IMAGE_UPLOAD_SIZE_MB,
    APPLE_BUNDLE_IDENTIFIER: window.env?.APPLE_BUNDLE_IDENTIFIER || process.env.APPLE_BUNDLE_IDENTIFIER,
    GOOGLE_PLAY_STORE_URL: window.env?.GOOGLE_PLAY_STORE_URL || process.env.GOOGLE_PLAY_STORE_URL,
    APP_STORE_URL: window.env?.APP_STORE_URL || process.env.APP_STORE_URL,
    SENDHELPER_MARKETING_SITE_URL: window.env?.SENDHELPER_MARKETING_SITE_URL || process.env.SENDHELPER_MARKETING_SITE_URL,
    SENDHELPER_CLEANING_INFO_URL: window.env?.SENDHELPER_CLEANING_INFO_URL || process.env.SENDHELPER_CLEANING_INFO_URL,
    SENDHELPER_CLEANING_WHAT_IS_INCLUDED_URL: window.env?.SENDHELPER_CLEANING_WHAT_IS_INCLUDED_URL || process.env.SENDHELPER_CLEANING_WHAT_IS_INCLUDED_URL,
    SENDHELPER_CANCELLATION_CHARGES: window.env?.SENDHELPER_CANCELLATION_CHARGES || process.env.SENDHELPER_CANCELLATION_CHARGES,
    ONEMAP_API: window.env?.ONEMAP_API || process.env.ONEMAP_API,
    GOOGLE_TRACKING_ID: window.env?.GOOGLE_TRACKING_ID || process.env.GOOGLE_TRACKING_ID,
    GOOGLE_TAG_MANAGER_ID: window.env?.GOOGLE_TAG_MANAGER_ID || process.env.GOOGLE_TAG_MANAGER_ID,
    SEGMENT_WRITE_KEY: window.env?.SEGMENT_WRITE_KEY || process.env.SEGMENT_WRITE_KEY,
    ENABLE_HANDYMAN: window.env?.ENABLE_HANDYMAN || process.env.ENABLE_HANDYMAN,
    ENABLE_LAUNDRY: window.env?.ENABLE_LAUNDRY || process.env.ENABLE_LAUNDRY,
    ENABLE_PEST_CONTROL: window.env?.ENABLE_PEST_CONTROL || process.env.ENABLE_PEST_CONTROL,
    ENABLE_COOKING: window.env?.ENABLE_COOKING || process.env.ENABLE_COOKING,
    ENABLE_TASK_ERRANDS: window.env?.ENABLE_TASK_ERRANDS || process.env.ENABLE_TASK_ERRANDS,
    ENABLE_MOVERS_AND_PACKERS: window.env?.ENABLE_MOVERS_AND_PACKERS || process.env.ENABLE_MOVERS_AND_PACKERS,
    SEGMENT_APPS_FLYER_ID: window.env?.SEGMENT_APPS_FLYER_ID || process.env.SEGMENT_APPS_FLYER_ID,
    SEGMENT_WRITE_KEY_IOS: window.env?.SEGMENT_WRITE_KEY_IOS || process.env.SEGMENT_WRITE_KEY_IOS,
    SEGMENT_WRITE_KEY_ANDROID: window.env?.SEGMENT_WRITE_KEY_ANDROID || process.env.SEGMENT_WRITE_KEY_ANDROID,
    SEGMENT_COOKIE_DOMAIN: window.env?.SEGMENT_COOKIE_DOMAIN || process.env.SEGMENT_COOKIE_DOMAIN,
    QUOTATION_URL: window.env?.QUOTATION_URL || process.env.QUOTATION_URL,
    BRAZE_SDK_ENDPOINT: window.env?.BRAZE_SDK_ENDPOINT || process.env.BRAZE_SDK_ENDPOINT,
    BRAZE_SDK_KEY_ANDROID: window.env?.BRAZE_SDK_KEY_ANDROID || process.env.BRAZE_SDK_KEY_ANDROID,
    BRAZE_SDK_KEY_IOS: window.env?.BRAZE_SDK_KEY_IOS || process.env.BRAZE_SDK_KEY_IOS,
    DEEPLINK_CUSTOM_DOMAIN: window.env?.DEEPLINK_CUSTOM_DOMAIN || process.env.DEEPLINK_CUSTOM_DOMAIN,
    GOOGLE_WEB_CLIENT_ID: window.env?.GOOGLE_WEB_CLIENT_ID || process.env.GOOGLE_WEB_CLIENT_ID,
    GOOGLE_IOS_CLIENT_ID: window.env?.GOOGLE_IOS_CLIENT_ID || process.env.GOOGLE_IOS_CLIENT_ID,
    GOOGLE_IOS_URL_SCHEME: window.env?.GOOGLE_IOS_URL_SCHEME || process.env.GOOGLE_IOS_URL_SCHEME,
    GOOGLE_PROFILE_URL: window.env?.GOOGLE_PROFILE_URL || process.env.GOOGLE_PROFILE_URL,
    GOOGLE_SDK_URL: window.env?.GOOGLE_SDK_URL || process.env.GOOGLE_SDK_URL
}
