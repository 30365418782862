import React from 'react'
import { View } from 'react-native'
import { createStyles } from 'lib/styles'
import { dateHelpers } from 'lib/utils'
import { useLocaleAtom } from 'lib/atoms'
import { Typography } from 'lib/components'
import { usePrice, useStyles, useTranslations } from 'lib/hooks'
import { JobDetailsResponse } from '../types'
import { DetailsExpert } from './DetailsExpert'

type DeepCleaningServiceDateProps = {
    job: JobDetailsResponse,
    isSubmitted: boolean
}

export const DeepCleaningServiceDate: React.FunctionComponent<DeepCleaningServiceDateProps> = ({
    job,
    isSubmitted
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const [locale] = useLocaleAtom()
    const { job: { date_time, beginning_date, bookingSummary } } = job
    const price = usePrice(bookingSummary?.total?.value || 0)

    return (
        <View>
            {!isSubmitted && (
                <DetailsExpert job={job} />
            )}
            {isSubmitted && (
                <View style={styles.wrapper}>
                    <View style={styles.flex}>
                        <View style={styles.title}>
                            <Typography.Label forceColor={theme.colors.mouse}>
                                {T.screens.jobDetails.sections.serviceDate}
                            </Typography.Label>
                        </View>
                        <Typography.Label style={styles.cleaningDays}>
                            {dateHelpers.getFullBookingDate(date_time || beginning_date, locale)}
                        </Typography.Label>
                    </View>
                </View>
            )}
            <View style={styles.wrapper}>
                <View style={styles.flex}>
                    <View style={styles.title}>
                        <Typography.Label forceColor={theme.colors.mouse}>
                            {T.screens.jobDetails.sections.promotionCode}
                        </Typography.Label>
                    </View>
                    <Typography.Label>
                        {job.job.promotion?.code ?? '-'}
                    </Typography.Label>
                </View>
                {price && (
                    <View style={styles.flex}>
                        <View style={styles.title}>
                            <Typography.Label forceColor={theme.colors.mouse}>
                                {T.screens.jobDetails.sections.price}
                            </Typography.Label>
                        </View>
                        <Typography.Label>
                            {price}
                        </Typography.Label>
                    </View>
                )}
                {/* Just to match width of services column below */}
                <View style={styles.placeHolder} />
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        flexDirection: 'row',
        marginBottom: theme.utils.gap(3)
    },
    title: {
        marginBottom: theme.utils.gap(1)
    },
    flex: {
        flex: 1
    },
    cleaningDays: {
        textTransform: 'capitalize'
    },
    placeHolder: {
        width: 50
    }
}))
