import { datadogRum } from '@datadog/browser-rum'
import axios, { AxiosResponse } from 'axios'
import { from, lastValueFrom, of } from 'rxjs'
import { map, catchError, delay } from 'rxjs/operators'
import { KeyValuePair, HttpMethod, AppVersionHeader } from 'lib/types'
import { APP_CONFIG } from '../config'
import { getAppVersionsFromHeader } from './utils'
import { useAppUpdateAtom } from 'lib/atoms'

const API = axios.create({
    timeout: APP_CONFIG.API.REQUEST_TIMEOUT
})

type FetcherConfig = {
    responseDelay?: number,
    timeout?: number
}

export const useExternalFetcher = <ResponseType>(
    method: HttpMethod,
    url: string,
    headers?: KeyValuePair
) => {
    const [, setAppUpdate] = useAppUpdateAtom()

    return (
        params: KeyValuePair = {},
        config?: FetcherConfig
    ) => lastValueFrom(from(API<ResponseType>({
        url,
        method,
        ...(method === HttpMethod.GET ? { params } : { data: params }),
        headers
    })).pipe(
        delay(config?.responseDelay || 0),
        map((res: AxiosResponse) => {
            const headers = { ...res.headers }

            if(headers[AppVersionHeader.IOS_MIN] &&
                   headers[AppVersionHeader.ANDROID_MIN] &&
                   headers[AppVersionHeader.IOS_CURRENT] &&
                   headers[AppVersionHeader.ANDROID_CURRENT]){
                setAppUpdate(getAppVersionsFromHeader(headers))
            }

            return res.data
        }),
        catchError(error => {
            datadogRum.addError({
                url,
                method,
                params,
                error
            })

            return of({ error })
        })
    ))

}

