import React, { useState } from 'react'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import {  Modal as NativeModal, ScrollView, View } from 'react-native'
import { useAnimatedStyle, useDerivedValue, withTiming } from 'react-native-reanimated'
import { Icons } from 'assets'
import { Children, JobStatus } from 'lib/types'
import { createStyles } from 'lib/styles'
import { Toast, Touchable, Typography } from 'lib/components'
import { useStyles, useTranslations } from 'lib/hooks'
import { MobilePaymentButton } from './MobilePaymentButton'
import { JobDetailsResponse } from '../types'

type MobilePaymentProps = {
    job: JobDetailsResponse
    content(closeModal: VoidFunction): Children,
}

export const MobilePayment: React.FunctionComponent<MobilePaymentProps> = ({
    content,
    job
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const [isOpen, setIsOpen] = useState(false)
    const insets = useSafeAreaInsets()
    const rotateZ = useDerivedValue(() => withTiming(isOpen ? 0 : -180))
    const buttonHeight = job.job.status === JobStatus.Accepted ? 150 : 60
    const animatedRotateStyle = useAnimatedStyle(() => ({
        transform: [{ rotateZ: `${rotateZ.value}deg` }]
    }))

    return (
        <React.Fragment>
            <NativeModal
                transparent
                visible={isOpen}
                animationType="fade"
                statusBarTranslucent
                onRequestClose={() => setIsOpen(false)}
            >
                <View
                    style={{
                        ...styles.shadow,
                        bottom: buttonHeight
                    }}
                />
                <Touchable
                    style={styles.backdrop}
                    onPress={() => setIsOpen(false)}
                >
                    <View />
                </Touchable>
                <ScrollView showsVerticalScrollIndicator={false}>
                    <View
                        style={{
                            ...styles.content,
                            paddingTop: theme.utils.gap(2) + insets.top
                        }}
                    >
                        <Touchable
                            style={styles.close}
                            onPress={() => setIsOpen(false)}
                            hitSlopBottom={20}
                            hitSlopRight={20}
                            hitSlopLeft={20}
                            hitSlopTop={20}
                        >
                            <Icons.Close size={14} />
                        </Touchable>
                        <Typography.Title style={styles.title}>
                            {T.common.paymentDetails}
                        </Typography.Title>
                        {content(() => setIsOpen(prevState => !prevState))}
                    </View>
                </ScrollView>
                <MobilePaymentButton
                    job={job}
                    setIsOpen={() => setIsOpen(prevState => !prevState)}
                    animatedStyles={animatedRotateStyle}
                />
                <Toast />
            </NativeModal>
            <MobilePaymentButton
                job={job}
                setIsOpen={() => setIsOpen(prevState => !prevState)}
                animatedStyles={animatedRotateStyle}
            />
        </React.Fragment>
    )
}

const stylesheet = createStyles(theme => ({
    shadow: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        backgroundColor: theme.colors.white
    },
    backdrop: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 90
    },
    close: {
        alignItems: 'flex-end'
    },
    title: {
        marginBottom: theme.utils.gap(2)
    },
    content: {
        paddingHorizontal: theme.utils.gap(2),
        borderTopLeftRadius: 12,
        borderTopRightRadius: 12,
        backgroundColor: theme.colors.white
    }
}))
