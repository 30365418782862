import React from 'react'
import { Field } from '@codegateinc/react-form-builder-v2'
import { View } from 'react-native'
import { useStyles } from 'lib/hooks'
import { SelectInputOption } from 'lib/types'
import { createStyles } from 'lib/styles'
import { FormComponents } from '../components'

interface SearchInputProps extends Field<string> {
    options: Array<SelectInputOption>,
    disabled?: boolean
}

export const SearchInput: React.FunctionComponent<SearchInputProps> = ({
    options,
    onChangeValue,
    value,
    placeholder,
    errorMessage,
    validate,
    disabled = false
}) => {
    const { styles } = useStyles(stylesheet)

    return (
        <View style={styles.row}>
            <View style={styles.input}>
                <FormComponents.SearchInput
                    onBlur={validate}
                    options={options}
                    disabled={disabled}
                    errorMessage={errorMessage}
                    placeholder={placeholder}
                    value={options.find(item => item.value === value) || null}
                    onChange={option => onChangeValue(option?.value || '')}
                />
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    input: {
        flex: 1
    },
    row: {
        zIndex: theme.zIndex[10],
        alignItems: {
            lg: 'center',
            xs: 'stretch'
        },
        flexDirection: {
            lg: 'row',
            xs: 'column'
        }
    },
    textRow: {
        flexDirection: 'row',
        flexWrap: 'wrap'
    }
}))
