import React from 'react'
import { Linking, View } from 'react-native'
import { Icons } from 'assets'
import { createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { BasicModal } from './BasicModal'
import { Label, Regular } from './typography'
import { Touchable } from './Touchable'

type PhoneModalProps = {
    isOpen: boolean,
    expertName: string,
    phoneNumber: string,
    onClose: VoidFunction
}

export const PhoneModal: React.FunctionComponent<PhoneModalProps> = ({
    isOpen,
    onClose,
    expertName,
    phoneNumber
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)

    return (
        <BasicModal
            isOpen={isOpen}
            onClose={onClose}
        >
            <Touchable onPress={() => Linking.openURL(`tel:${phoneNumber}`)}>
                <View style={styles.container}>
                    <View style={styles.supplierName}>
                        <Label forceColor={theme.colors.mouse}>
                            {`${T.common.yourExpert} ${expertName}`}
                        </Label>
                    </View>
                    <View style={styles.phoneContainer}>
                        <Icons.Phone size={20} />
                        <Regular>
                            {` ${T.common.call} ${phoneNumber}`}
                        </Regular>
                    </View>
                </View>
            </Touchable>
        </BasicModal>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        zIndex: theme.zIndex[10],
        maxWidth: 400,
        paddingTop: theme.utils.gap(4)
    },
    supplierName: {
        alignItems: 'center'
    },
    phoneContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        borderTopWidth: 1,
        borderColor: theme.colors.mouse,
        marginTop: theme.utils.gap(2),
        paddingTop: theme.utils.gap(2),
        paddingHorizontal: theme.utils.gap(5)
    }
}))
