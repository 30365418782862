import React from 'react'
import { View } from 'react-native'
import { Icons } from 'assets'
import { createStyles } from 'lib/styles'
import { Typography } from 'lib/components'
import { useStyles, useTranslations } from 'lib/hooks'

export const RescheduledMessage: React.FunctionComponent = () => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)

    return (
        <View style={styles.wrapper}>
            <View style={styles.icon}>
                <Icons.Info size={18} forceColor={theme.colors.purple} />
            </View>
            <Typography.Regular forceColor={theme.colors.purple} style={styles.text}>
                {T.screens.jobDetails.rescheduleBooking.planRescheduledMessage}
            </Typography.Regular>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        marginTop: theme.utils.gap(2),
        borderWidth: 1,
        borderColor: theme.colors.magenta,
        flexDirection: 'row',
        padding: theme.utils.gap(1),
        borderRadius: 4,
        backgroundColor: theme.colors.lightPink,
        justifyContent: 'center'
    },
    text: {
        marginLeft: theme.utils.gap(1)
    },
    link: {
        marginLeft: theme.utils.gap(1),
        textDecoration: 'underline',
        cursor: 'pointer'
    },
    icon: {
        minWidth: 18,
        minHeight: 18
    }
}))
