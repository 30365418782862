import React, { useState } from 'react'
import { Modal as NativeModal, View } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scrollview'
import { useAnimatedStyle, useDerivedValue, withTiming } from 'react-native-reanimated'
import { Field } from '@codegateinc/react-form-builder-v2'
import { Measurements, isIOS } from 'lib/common'
import { Children, Nullable } from 'lib/types'
import { createStyles } from 'lib/styles'
import { Toast, Touchable, Typography } from 'lib/components'
import { useStyles, useTranslations } from 'lib/hooks'
import { Icons } from 'assets'
import { MobileSummaryButton } from './MobileSummaryButton'
import { NativeCouponModal } from './NativeCouponModal'
import { GetPromotionResponse, JobQuotationResponse } from '../types'

type MobileSummaryProps = {
    disabled: boolean,
    submit: VoidFunction,
    guestSubmit?: VoidFunction,
    content: Children,
    price: Nullable<number>,
    priceBeforeDiscount?: Nullable<number>,
    submitText?: string,
    priceLabel?: string,
    perHour?: boolean,
    isLoading?: boolean,
    isGuestBookingEnabled?: boolean
    areGuestDetailsValid?: boolean
    authenticationView?: boolean,
    promotionPackage?: Nullable<GetPromotionResponse>,
    promoCode?: Field<string>,
    jobQuotation?: JobQuotationResponse,
    requestPromotion?: (code: string) => void,
    onRemovePromoCode?: VoidFunction,
    deepCleanMinAmount?: boolean
}

export const MobileSummary: React.FunctionComponent<MobileSummaryProps> = ({
    disabled,
    submit,
    content,
    price,
    submitText,
    priceLabel,
    perHour,
    isLoading,
    authenticationView = false,
    promotionPackage,
    priceBeforeDiscount,
    onRemovePromoCode,
    promoCode,
    requestPromotion,
    isGuestBookingEnabled = false,
    guestSubmit,
    areGuestDetailsValid,
    jobQuotation,
    deepCleanMinAmount
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const [isOpen, setIsOpen] = useState(false)
    const [isNativeCouponModalOpen, setNativeCouponModal] = useState(false)
    const rotateZ = useDerivedValue(() => withTiming(isOpen ? 0 : -180))
    const animatedRotateStyle = useAnimatedStyle(() => ({
        transform: [{ rotateZ: `${rotateZ.value}deg` }]
    }))

    const onSubmit = () => {
        setIsOpen(false)

        if (isOpen && isGuestBookingEnabled) {
            guestSubmit && guestSubmit()

            return
        }

        if (submitText && isOpen) {
            submit()

            return
        }

        if (submitText && !isOpen) {
            setIsOpen(true)

            return
        }

        submit()
    }

    const onCouponMobileClick = () => {
        setNativeCouponModal(true)
    }

    const renderNativeCouponModal = () => (
        <NativeCouponModal isOpen={isNativeCouponModalOpen} onClose={() => setNativeCouponModal(false)}
            promoCode={promoCode} onApply={requestPromotion} onRemove={onRemovePromoCode} promotionPackage={promotionPackage} isLoading={isLoading} />
    )

    return (
        <React.Fragment>
            {!isOpen && renderNativeCouponModal()}
            <NativeModal
                transparent
                visible={isOpen}
                animationType="fade"
                statusBarTranslucent
                onRequestClose={() => setIsOpen(false)}
            >
                {renderNativeCouponModal()}
                <View style={styles.shadow} />
                <Touchable
                    style={styles.backdrop}
                    onPress={() => setIsOpen(false)}
                >
                    <View />
                </Touchable>
                <KeyboardAwareScrollView
                    keyboardShouldPersistTaps="always"
                    keyboardDismissMode={isIOS ? 'interactive' : 'on-drag'}
                    showsVerticalScrollIndicator={false}
                >
                    <View style={styles.content}>
                        <Touchable
                            style={styles.close}
                            onPress={() => setIsOpen(false)}
                            hitSlopBottom={20}
                            hitSlopRight={20}
                            hitSlopLeft={20}
                            hitSlopTop={20}
                        >
                            <Icons.Close size={14} />
                        </Touchable>
                        <Typography.Title style={styles.title}>
                            {T.common.bookingSummary}
                        </Typography.Title>
                        {content}
                    </View>
                </KeyboardAwareScrollView>
                <MobileSummaryButton
                    isLoading={isLoading}
                    authenticationView={authenticationView}
                    perHour={perHour}
                    priceLabel={priceLabel}
                    submitText={submitText}
                    price={price}
                    priceBeforeDiscount={priceBeforeDiscount}
                    disabled={disabled}
                    setIsOpen={() => setIsOpen(prevState => !prevState)}
                    submit={onSubmit}
                    animatedStyles={animatedRotateStyle}
                    isGuestBookingEnabled={isGuestBookingEnabled}
                    areGuestDetailsValid={areGuestDetailsValid}
                    onCouponMobileClick={onCouponMobileClick}
                    promotionPackage={promotionPackage}
                    jobQuotation={jobQuotation}
                    deepCleanMinAmount={deepCleanMinAmount}
                />
                <Toast />
            </NativeModal>
            <MobileSummaryButton
                isLoading={isLoading}
                perHour={perHour}
                authenticationView={authenticationView}
                priceLabel={priceLabel}
                submitText={submitText}
                price={price}
                priceBeforeDiscount={priceBeforeDiscount}
                disabled={disabled}
                setIsOpen={() => setIsOpen(prevState => !prevState)}
                submit={onSubmit}
                animatedStyles={animatedRotateStyle}
                isGuestBookingEnabled={isGuestBookingEnabled}
                areGuestDetailsValid={areGuestDetailsValid}
                onCouponMobileClick={onCouponMobileClick}
                promotionPackage={promotionPackage}
                jobQuotation={jobQuotation}
                deepCleanMinAmount={deepCleanMinAmount}
            />
        </React.Fragment>
    )
}

const stylesheet = createStyles(theme => ({
    shadow: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 150,
        backgroundColor: theme.colors.white
    },
    backdrop: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 90
    },
    close: {
        alignItems: 'flex-end',
        paddingTop: theme.utils.gap(2)
    },
    title: {
        marginBottom: theme.utils.gap(2)
    },
    content: {
        paddingTop: Measurements.StatusBarHeight,
        paddingHorizontal: theme.utils.gap(2),
        borderTopLeftRadius: 12,
        borderTopRightRadius: 12,
        backgroundColor: theme.colors.white
    }
}))
