import React from 'react'
import { View } from 'react-native'
import { Field } from '@codegateinc/react-form-builder-v2'
import { PestControlSession } from '../../types'
import { PestControlServiceTile } from './PestControlServiceTile'

interface PestControlServicesListProps extends Field<Array<PestControlSession>>{
    propertyType: number,
    disabled?: boolean,
}

export const PestControlServicesList: React.FunctionComponent<PestControlServicesListProps> = ({
    propertyType,
    disabled,
    ...formProps
}) => (
    <View>
        {formProps.value.map((service, index) => (
            <PestControlServiceTile
                key={index}
                service={service}
                onChangeValue={updatedService => formProps.onChangeValue(
                    formProps.value.map(service => service.id === updatedService.id
                        ? updatedService
                        : service
                    ))
                }
            />
        ))}
    </View>
)
