import React, { useState } from 'react'
import { View } from 'react-native'
import { Icons } from 'assets'
import { Button } from 'lib/components'
import { JobProposal } from 'lib/models'
import { createStyles } from 'lib/styles'
import { ProposalAction } from 'lib/types'
import { useUserTokenAtom } from 'lib/atoms'
import { useQueryHelpers, useStyles, useTranslations } from 'lib/hooks'
import { getJobDetails, proposalAction } from '../actions'
import { ProposalActionModal } from './proposalActionModal'

type AcceptProposalProps = {
    proposal: JobProposal,
    jobId: string
}

export const AcceptProposal: React.FunctionComponent<AcceptProposalProps> = ({
    jobId,
    proposal
}) => {
    const T = useTranslations()
    const [isModalOpen, setIsModalOpen] = useState(false)
    const { styles, theme } = useStyles(stylesheet)
    const [token] = useUserTokenAtom()
    const { refetch, isRefetching } = getJobDetails(jobId)
    const { onRequestError } = useQueryHelpers()
    const { mutate, isLoading: isMutating } = proposalAction()

    const onAccept = () => {
        mutate(
            {
                token,
                id: jobId,
                job_proposal: proposal,
                proposal_action: ProposalAction.Accept
            },
            {
                onSuccess: () => refetch(),
                onError: error => {
                    setIsModalOpen(false)
                    onRequestError(error)
                }
            }
        )
    }
    const isLoading = isMutating || isRefetching

    return (
        <React.Fragment>
            <ProposalActionModal
                isLoading={isLoading}
                renderIcon={() => (
                    <View style={styles.icon}>
                        <Icons.CheckCircle
                            size={30}
                            forceColor={theme.colors.orange}
                        />
                    </View>
                )}
                isOpen={isModalOpen}
                onConfirm={onAccept}
                onCancel={() => setIsModalOpen(false)}
                onClose={() => setIsModalOpen(false)}
                confirmText={T.common.accept}
                cancelText={T.common.cancel}
                title={T.components.proposals.accept.title}
                message={T.components.proposals.accept.message}
            />
            <Button
                text={T.common.accept}
                onPress={() => setIsModalOpen(true)}
            />
        </React.Fragment>
    )
}

const stylesheet = createStyles(theme => ({
    icon: {
        marginBottom: theme.utils.gap(2)
    }
}))
