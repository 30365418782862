import React from 'react'
import { useIsWithinBreakpoints, useTranslations } from 'lib/hooks'
import { Breakpoint } from 'lib/styles'
import { NavigationProps, ScreenNames } from 'lib/routing'
import { Grid } from 'lib/components'
import { AuthLayout, ForgotPasswordForm } from '../components'

type AuthForgotPasswordScreenProps = {
    navigation: NavigationProps<ScreenNames.AuthForgotPassword>
}

export const AuthForgotPasswordScreen: React.FunctionComponent<AuthForgotPasswordScreenProps> = () => {
    const T = useTranslations()
    const isMobile = useIsWithinBreakpoints(Breakpoint.XS, Breakpoint.MD)

    return (
        <AuthLayout
            title={!isMobile
                ? T.screens.authForgotPassword.welcome
                : undefined
            }
            message={!isMobile
                ? T.screens.authForgotPassword.welcomeMessage
                : undefined
            }
        >
            <Grid.Gap gapTop={10}>
                <ForgotPasswordForm />
            </Grid.Gap>
        </AuthLayout>
    )
}
