import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const FacebookIcon: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path d="M62.083 5.504c-.333-1.86-1.997-3.008-3.77-2.997-4.545.028-8.658.165-12.257.776-3.604.612-6.749 1.708-9.325 3.689-5.076 3.902-7.668 11.007-7.776 23.565h-9.029c-1.716 0-3.35 1.087-3.687 2.909-.383 2.074-.687 5.406.022 9.557.332 1.94 2.063 3.218 3.945 3.218h8.745v27.757c0 1.384.774 2.788 2.355 3.09 1.197.228 3.079.433 5.986.433 2.903 0 4.954-.205 6.328-.428 1.799-.292 2.844-1.874 2.844-3.542v-27.31H58.22c1.823 0 3.541-1.206 3.882-3.11.688-3.841.356-7.319-.028-9.517-.33-1.88-2-3.058-3.798-3.058h-11.81c.02-2.684.145-4.775.442-6.414.328-1.802.85-2.976 1.6-3.78.743-.798 1.795-1.324 3.373-1.655 1.592-.333 3.64-.452 6.307-.488 1.834-.025 3.564-1.246 3.903-3.17.696-3.959.375-7.375-.008-9.525Z" />
    </Icon>
)
