import React from 'react'
import { View } from 'react-native'
import { Field } from '@codegateinc/react-form-builder-v2'
import { Job } from 'lib/models'
import { createStyles } from 'lib/styles'
import { useStyles, useSupplyOptions, useTranslations } from 'lib/hooks'
import { Adapter, FormComponents } from 'lib/components'
import { isNative } from 'lib/common'
import { Icons } from 'assets'

type BookingsSearchProps = {
    jobs?: Array<Job>,
    searchField: Field<string>,
    serviceField: Field<string>
}

export const BookingsSearch: React.FunctionComponent<BookingsSearchProps> = ({
    jobs = [],
    searchField,
    serviceField
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const supplyOptions = useSupplyOptions()

    return (
        <View style={styles.container}>
            <View style={styles.filterInput}>
                <Adapter.SelectInput
                    {...serviceField}
                    customPlaceholder
                    options={supplyOptions}
                    testID={T.accessibility.bookingsScreen.filters.service}
                />
            </View>
            <View style={styles.inputContainer}>
                {isNative ? (
                    <FormComponents.SearchTextInput
                        value={searchField.value}
                        onQuery={searchField.onChangeValue}
                        placeholder={searchField.placeholder}
                        leftIcon={() => (
                            <Icons.Search size={14}/>
                        )}

                    />
                ) : (
                    <Adapter.SearchInput
                        {...searchField}
                        options={jobs.map(item => ({
                            label: `${item.job_id} - ${item.supply.name}`,
                            value: JSON.stringify(item)
                        }))}
                    />
                )}
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        flex: 1,
        paddingTop: theme.utils.gap(2),
        zIndex: theme.zIndex[20],
        flexDirection: {
            lg: 'row',
            ':w[, 1150]': 'column'
        },
        paddingHorizontal: {
            lg: 0,
            ':w[, 1150]': theme.utils.gap(1)
        },
        justifyContent: 'flex-end'
    },
    inputContainer: {
        flex: 1,
        zIndex: theme.zIndex[10],
        maxWidth: {
            lg: 350,
            ':w[, 1150]': undefined
        },
        marginLeft: {
            lg: theme.utils.gap(2),
            ':w[, 1150]': 0
        }
    },
    filterInput: {
        zIndex: theme.zIndex[10],
        width: {
            lg: 200,
            ':w[, 1150]': undefined
        }
    }
}))
