import React from 'react'
import { View } from 'react-native'
import { createStyles, theme } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { CustomModal, Button, Typography } from 'lib/components'
import { JobDetailsResponse } from 'features/jobs/types'
import { Icons } from 'assets'
import { dateHelpers } from 'lib/utils'
import { UseFormReturn } from '@codegateinc/react-form-builder-v2'
import { RescheduleFormShape } from 'features/jobs/forms'

type RescheduledSuccessModalProps = {
    isOpen: boolean,
    job: JobDetailsResponse,
    onClose: VoidFunction,
    formReturn: UseFormReturn<RescheduleFormShape>
}

export const RescheduledSuccessModal: React.FunctionComponent<RescheduledSuccessModalProps> = ({
    isOpen,
    onClose,
    job,
    formReturn
}) => {
    const { form } = formReturn
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)

    return (
        <CustomModal
            onClose={onClose}
            isOpen={isOpen}
        >
            <View style={styles.modalWrapper}>
                <View style={styles.iconContainer}>
                    <Icons.CheckCircle
                        size={40}
                        forceColor={theme.colors.orange}
                    />
                </View>
                <Typography.SmallSubheading style={styles.title}>
                    {T.screens.jobDetails.rescheduleBookingSuccess.packageTitle}
                </Typography.SmallSubheading>
                <View style={styles.datetimeWrapper}>
                    <View style={styles.datetimeContainer}>
                        <Icons.DateAndTime
                            size={24}
                            forceColor={theme.colors.orange}
                        />
                        <Typography.Regular style={styles.datetimeText}>
                            {form.startingDate.value ? dateHelpers.bookingFullDate(form.startingDate.value) : null}
                        </Typography.Regular>
                    </View>
                    <View style={styles.spacer}/>
                    <View style={styles.datetimeContainer}>
                        <Icons.Clock
                            size={24}
                            forceColor={theme.colors.orange}
                            viewBox="0 0 25 25"
                        />
                        <Typography.Regular style={styles.datetimeText}>
                            {form.bookingTime.value ? dateHelpers.selectTimeLabel(form.bookingTime.value) : null}
                        </Typography.Regular>
                    </View>
                </View>
                <Typography.Regular style={styles.messageContainer}>
                    {T.screens.jobDetails.rescheduleBookingSuccess.theBookingRequest}
                    <Typography.Regular style={styles.jobIdText}>
                        {` ${job.job.job_id} `}
                    </Typography.Regular>
                    {T.screens.jobDetails.rescheduleBookingSuccess.hasBeenRescheduledSuccessfully}
                </Typography.Regular>
                <View style={styles.actionContainer}>
                    <View style={styles.buttonWrapper}>
                        <Button
                            text={T.screens.jobDetails.rescheduleBookingSuccess.done}
                            onPress={onClose}
                            textWrapperStyles={styles.buttonTextWrapper}
                            textStyles={styles.buttonText}
                        />
                    </View>
                </View>
            </View>
        </CustomModal>
    )
}

const stylesheet = createStyles(theme => ({
    modalWrapper: {
        alignItems: 'center',
        paddingHorizontal: {
            lg: theme.utils.gap(16),
            md: theme.utils.gap(6),
            sm: theme.utils.gap(4),
            xs: theme.utils.gap(0)
        }
    },
    iconContainer: {
        alignItems: 'center',
        paddingBottom: theme.utils.gap(1)
    },
    messageContainer: {
        textAlign: 'center'
    },
    actionContainer: {
        paddingTop: theme.utils.gap(3.5),
        flexDirection: 'row',
        justifyContent: 'center',
        zIndex: -1,
        width: {
            xs: '100%',
            md: undefined
        }
    },
    buttonWrapper: {
        flex: 1
    },
    buttonTextWrapper: {
        width: '100%',
        wordWrap: 'break-word',
        textAlign: 'center',
        marginHorizontal: theme.utils.gap(7)
    },
    jobIdText: {
        fontWeight: 'bold'
    },
    datetimeWrapper: {
        borderRadius: 8,
        backgroundColor: theme.colors.latte,
        paddingVertical: theme.utils.gap(1.5),
        paddingHorizontal: {
            xs: theme.utils.gap(2),
            md: theme.utils.gap(3)
        },
        marginVertical: theme.utils.gap(1.5),
        flex: 1,
        flexDirection: {
            md: 'row',
            xs: 'column'
        },
        justifyContent: 'center',
        width: {
            xs: '100%',
            md: undefined
        }
    },
    datetimeContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: theme.utils.gap(1)
    },
    datetimeText: {
        marginLeft: theme.utils.gap(1),
        fontWeight: '600'
    },
    title: {
        fontSize: 25,
        textAlign: 'center',
        lineHeight: 31
    },
    spacer: {
        height: {
            md: 0,
            xs: theme.utils.gap(1)
        }
    },
    buttonText: {
        textAlign: 'center'
    }
}))
