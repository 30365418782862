import React from 'react'
import { View } from 'react-native'
import { createStyles } from 'lib/styles'
import { dateHelpers } from 'lib/utils'
import { useLocaleAtom } from 'lib/atoms'
import { Typography } from 'lib/components'
import { useStyles, useTranslations } from 'lib/hooks'
import { JobDetailsResponse } from '../../types'
import { DetailsExpert } from '../DetailsExpert'

type HandymanServiceDateProps = {
    job: JobDetailsResponse,
    isSubmitted: boolean
}

export const HandymanServiceDate: React.FunctionComponent<HandymanServiceDateProps> = ({
    job,
    isSubmitted
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const [locale] = useLocaleAtom()
    const { job: { date_time, beginning_date } } = job

    return (
        <View>
            {!isSubmitted && (
                <DetailsExpert job={job} />
            )}
            <View style={styles.wrapper}>
                {isSubmitted && (
                    <View style={styles.flex}>
                        <View style={styles.title}>
                            <Typography.Label forceColor={theme.colors.mouse}>
                                {T.screens.jobDetails.sections.serviceDate}
                            </Typography.Label>
                        </View>
                        <Typography.Label style={styles.cleaningDays}>
                            {dateHelpers.getFullBookingDate(date_time || beginning_date, locale)}
                        </Typography.Label>
                    </View>
                )}
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        flexDirection: 'row',
        marginBottom: theme.utils.gap(3)
    },
    title: {
        marginBottom: theme.utils.gap(1)
    },
    flex: {
        flex: 1
    },
    cleaningDays: {
        textTransform: 'capitalize'
    }
}))
