import React from 'react'
import { Icons } from 'assets'
import { createStyles } from 'lib/styles'
import { useStyles } from 'lib/hooks'
import { Touchable } from './Touchable'
import { Regular } from './typography'

type LinkButtonProps = {
    text: string,
    onPress: VoidFunction
}

export const LinkButton: React.FunctionComponent<LinkButtonProps> = ({
    text,
    onPress
}) => {
    const { styles, theme } = useStyles(stylesheet)

    return (
        <Touchable
            onPress={onPress}
            style={styles.container}
        >
            <Regular
                bold
                style={styles.text}
                forceColor={theme.colors.orange}
            >
                {text}
            </Regular>
            <Icons.Arrow
                size={12}
                forceColor={theme.colors.orange}
            />
        </Touchable>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    text: {
        borderBottomWidth: 1,
        borderBottomColor: theme.colors.orange,
        marginRight: theme.utils.gap(1)
    }
}))
