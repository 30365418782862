import React from 'react'
import { View } from 'react-native'
import { Grid } from 'lib/components'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { Job } from 'lib/models'
import { getJobDetails } from '../../actions'
import { NextSessionActions } from './NextSessionActions'
import { NextSessionInfo } from './NextSessionInfo'
import { RescheduledMessage } from '../RescheduledMessage'

type NextSessionTileProps = {
    nextSession: Job,
    sessionIndex: number,
    allSessionsCount: number
}

export const NextSessionTile: React.FunctionComponent<NextSessionTileProps> = ({
    nextSession,
    sessionIndex,
    allSessionsCount
}) => {
    const { styles } = useStyles(stylesheet)
    const { data: response } = getJobDetails(nextSession?._id || '')
    const nextResponseData = response?.data
    const nextJobDetails = nextResponseData?.job

    return (
        <View style={styles.content}>
            <NextSessionInfo
                job={nextSession}
                jobDetails={nextJobDetails}
                sessionIndex={sessionIndex}
                allSessionsCount={allSessionsCount}
            />
            <Grid.Gap gapBottom={2}/>
            {
                nextResponseData && <RescheduledMessage response={nextResponseData}/>
            }
            <NextSessionActions jobDetails={nextJobDetails} jobDetailsResponse={nextResponseData}/>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    content: {
        overflow: 'hidden',
        borderRadius: 8,
        borderWidth: 1,
        paddingHorizontal: theme.utils.gap(2),
        paddingVertical: theme.utils.gap(3),
        backgroundColor: theme.colors.white,
        borderColor: theme.colors.silver,
        backGroundColor: theme.colors.white,
        marginBottom: theme.utils.gap(4),
        ...theme.utils.createShadow(2, 0, 8, 4, 4, theme.colors.webShadow(0.04))
    }
}))
