import React from 'react'
import { View } from 'react-native'
import { createStyles } from 'lib/styles'
import { linkingHelpers } from 'lib/utils'
import { Touchable, Typography } from 'lib/components'
import { useStyles, useTranslations } from 'lib/hooks'
import { useShareFaq } from '../hooks'
import { ShareFaqItem } from './ShareFaqItem'

export const ShareFaqSection: React.FunctionComponent = () => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const shareFaq = useShareFaq()

    return (
        <View style={styles.wrapper}>
            <Typography.SmallSubheading style={styles.title}>
                {T.screens.referFriend.faq.title}
            </Typography.SmallSubheading>
            <View>
                {shareFaq.map((item, index) => (
                    <ShareFaqItem
                        key={index}
                        {...item}
                    />
                ))}
            </View>
            <View style={styles.footer}>
                <Typography.Regular>
                    {T.screens.referFriend.footer}
                </Typography.Regular>
                <Touchable onPress={() => linkingHelpers.openReferralTermsAndConditions()}>
                    <Typography.Regular
                        bold
                        forceColor={theme.colors.orange}
                    >
                        {T.screens.referFriend.footerPartTwo}
                    </Typography.Regular>
                </Touchable>
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        paddingHorizontal: {
            xl: theme.utils.gap(6),
            md: theme.utils.gap(3),
            xs: theme.utils.gap(2)
        }
    },
    title: {
        marginTop: {
            md: theme.utils.gap(5),
            xs: theme.utils.gap(3)
        },
        marginBottom: theme.utils.gap(2)
    },
    footer: {
        flexDirection: 'row',
        marginTop: theme.utils.gap(4),
        justifyContent: 'center'
    }
}))
