import { JobStatus } from 'lib/types'
import { useBookingsTabs } from './useBookingsTabs'
import { useBookingsFilters } from './useBookingsFilters'
import { useBookingsViewStyle } from './useBookingsViewStyle'
import { ConsumerJobsResponse } from '../types'
import { useEmptyBookingsTitle } from './useEmptyBookingsTitle'

export const useBookings = (jobStatus: JobStatus, jobs?: ConsumerJobsResponse) => {
    const bookingsTabs = useBookingsTabs()
    const { viewStyle, viewStyleOptions, setViewStyle } = useBookingsViewStyle()
    const emptyTitle = useEmptyBookingsTitle(jobStatus)
    const mappedRecurringJobs = jobs?.recurring_job_list
        .map(job => ({
            ...job,
            date_time: job.beginning_date
        })) || []
    const submittedJobs = jobStatus === JobStatus.Submitted
        ? (jobs?.job_list || [])
            .concat(mappedRecurringJobs.filter(job => job.status === JobStatus.Submitted))
            .filter(job => job.status !== JobStatus.Completed)
        : (jobs?.job_list || []).concat(mappedRecurringJobs)
    const allJobs = jobStatus !== JobStatus.Submitted
        ? (jobs?.job_list || []).concat(mappedRecurringJobs)
        : submittedJobs
    const { searchField, serviceField, dateField, filteredJobs, resetSearchForm } = useBookingsFilters(allJobs, viewStyle.value)

    return {
        allJobs,
        jobList: submittedJobs,
        recurringJobs: jobs?.recurring_job_list,
        bookingsTabs,
        filteredJobs,
        fields: {
            serviceField,
            dateField,
            searchField
        },
        resetSearchForm,
        bookingViewStyle: {
            viewStyle,
            setViewStyle,
            viewStyleOptions
        },
        emptyTitle
    }
}
