import React, { useState } from 'react'
import { Field } from '@codegateinc/react-form-builder-v2'
import { isNative } from 'lib/common'
import { useIsAuthorizedAtom, useUserAtom } from 'lib/atoms'
import { PaymentType } from 'lib/models'
import { useTranslations } from 'lib/hooks'
import { NewAddressShape } from 'features/user'
import { Nullable, SelectInputOption, SupplyID } from 'lib/types'
import { taskAndErrandsHooks } from '../../hooks'
import { GetPromotionResponse, JobQuotationResponse } from '../../types'
import { ServiceAddonsFormShape, TaskAndErrandsFormShape } from '../../forms'
import { ServiceLayout } from '../ServiceLayout'
import { TaskAndErrandsSummaryColumnView } from './TaskAndErrandsSummaryColumnView'
import { TaskAndErrandsSummaryContent } from './TaskAndErrandsSummaryContent'
import { TaskAndErrandsSummaryFooter } from './TaskAndErrandsSummaryFooter'
import { TaskAndErrandsHelpModalContent } from './TaskAndErrandsHelpModalContent'
import { ServiceDescription } from '../ServiceDescription'
import { BookingAuth } from '../unauthorized'

type TaskAndErrandsSummaryViewProps = {
    disabled: boolean,
    selectedPaymentMethod: PaymentType,
    setPaymentView: (value: boolean) => void,
    setPaymentMethod: (value: PaymentType) => void,
    form: Record<keyof ServiceAddonsFormShape, Field<boolean & string>>
    addressForm: Record<keyof NewAddressShape, Field<SelectInputOption & string>>,
    formShape: TaskAndErrandsFormShape,
    additionalInformation: string,
    isLoading: boolean,
    totalPrice: number,
    jobQuotation?: JobQuotationResponse,
    submit: VoidFunction,
    promotionPackage: Nullable<GetPromotionResponse>,
    originalPrice?: Nullable<number>,
    requestPromotion: (code: string) => void,
    onRemovePromoCode: VoidFunction,
    fetchPostalCodes(value: string): Promise<Array<SelectInputOption>>
}

export const TaskAndErrandsSummaryView: React.FunctionComponent<TaskAndErrandsSummaryViewProps> = props => {
    const T = useTranslations()
    const [isHelpModalOpen, setHelpModalOpen] = useState(false)
    const [user] = useUserAtom()
    const {
        submit,
        disabled,
        selectedPaymentMethod,
        totalPrice,
        setPaymentMethod,
        jobQuotation
    } = props
    const breadcrumbs = taskAndErrandsHooks.useTaskAndErrandsBreadcrumbs()
    const [isAuthorized] = useIsAuthorizedAtom()
    const [authenticationView, setAuthenticationView] = useState(false)
    const notEnoughCredits = selectedPaymentMethod === PaymentType.Credits && user.consumer.balance_credit < totalPrice
    const confirmDisabled = isAuthorized
        ? disabled
        : false
    const handleConfirm = () => {
        if (!isAuthorized) {
            return setAuthenticationView(true)
        }

        submit()
    }

    return (
        <ServiceLayout
            onInfoPress={() => setHelpModalOpen(true)}
            title={T.screens.taskAndErrands.title}
            withBackground
            authenticationView={authenticationView}
            onBackFromDetails={() => {
                setAuthenticationView(false)
            }}
            breadcrumbs={breadcrumbs}
            contentColumn={authenticationView
                ? (
                    <BookingAuth
                        supplyId={SupplyID.TasksErrands}
                        notEnoughCredits={notEnoughCredits}
                        selectedPaymentMethod={selectedPaymentMethod}
                        setPaymentMethod={setPaymentMethod}
                    />
                ) : (
                    <React.Fragment>
                        {isNative && (
                            <ServiceDescription
                                isHelpModalOpen={isHelpModalOpen}
                                setHelpModalOpen={setHelpModalOpen}
                                title={T.screens.taskAndErrands.title}
                                modalContent={(
                                    <TaskAndErrandsHelpModalContent />
                                )}
                                description={T.screens.taskAndErrands.serviceDescription}
                            />
                        )}
                        <TaskAndErrandsSummaryContent {...props} />
                    </React.Fragment>
                )
            }
            summaryColumn={(
                <TaskAndErrandsSummaryColumnView
                    {...props}
                    authenticationView={authenticationView}
                    disabled={confirmDisabled}
                    submit={handleConfirm}
                    jobQuotation={jobQuotation}
                />
            )}
            footer={(
                <TaskAndErrandsSummaryFooter
                    {...props}
                    authenticationView={authenticationView}
                    disabled={confirmDisabled}
                    submit={handleConfirm}
                    jobQuotation={jobQuotation}
                />
            )}
        />
    )
}
