import { atom as jotaiAtom, useAtom } from 'jotai'
import { isWeb } from 'lib/common'
import { createAtom } from './atomFactory'
import { MMKVKey } from '../mmkv'
import { Optional, PushNotificationAuthStatus } from '../types'

type PushNotificationConfig = {
    fcmToken: Optional<string>,
    authStatus: PushNotificationAuthStatus
}

const initialState = {
    fcmToken: undefined,
    // todo change it in order to support web push notifications
    authStatus: isWeb
        ? PushNotificationAuthStatus.Unsupported
        : PushNotificationAuthStatus.NotDetermined
}

const { atom, resetAtom } = createAtom<PushNotificationConfig>(initialState, MMKVKey.PushNotification)
const { atom: shouldAskForPermissionAtom, resetAtom: resetShouldAskForPermissionAtom  } = createAtom<boolean>(true)
const fcmAtom = jotaiAtom(get => get(atom).fcmToken, (get, set, fcmToken: Optional<string>) => set(atom, {
    ...get(atom),
    fcmToken
}))
const authStatusAtom = jotaiAtom(get => get(atom).authStatus, (get, set, authStatus: PushNotificationAuthStatus) => set(atom, {
    ...get(atom),
    authStatus
}))
const { atom: requestEnableNotificationModalShowAtom, resetAtom: resetRequestEnableNotificationModalShowAtom } = createAtom<boolean>(true)

export const usePushNotificationFCMAtom = () => useAtom(fcmAtom)
export const usePushNotificationAuthStatusAtom = () => useAtom(authStatusAtom)
export const useShouldAskForPushNotificationPermissionAtom = () => useAtom(shouldAskForPermissionAtom)
export const useRequestEnableNotificationModalShowAtom = () => useAtom(requestEnableNotificationModalShowAtom)
export const useResetPushNotificationAtom = () => useAtom(resetAtom)
export const useResetShouldAskForPermissionAtom = () => useAtom(resetShouldAskForPermissionAtom)
export const useResetRequestEnableNotificationModalShowAtom = () => useAtom(resetRequestEnableNotificationModalShowAtom)
