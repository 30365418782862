import React from 'react'
import { View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { Field } from '@codegateinc/react-form-builder-v2'
import { Adapter, Button, FormComponents } from 'lib/components'
import { ScreenNames } from 'lib/routing'
import { createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { useUserAtom } from 'lib/atoms'
import { Segment } from 'lib/analytics'
import { isNative } from 'lib/common'
import { Icons } from 'assets'

type AddressSearchProps = {
    field: Field<string>,
    setAddAddress?: VoidFunction
}

export const AddressSearch: React.FunctionComponent<AddressSearchProps> = ({
    field,
    setAddAddress
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const navigation = useNavigation()
    const [user] = useUserAtom()

    return (
        <View style={styles.container}>
            <View style={styles.inputContainer}>
                {isNative ? (
                    <FormComponents.SearchTextInput
                        onQuery={field.onChangeValue}
                        placeholder={field.placeholder}
                        leftIcon={() => (
                            <Icons.Search size={14}/>
                        )}
                    />
                ) : (
                    <Adapter.SearchInput
                        {...field}
                        options={user.locations.map(item => ({
                            label: item.address,
                            value: JSON.stringify(item)
                        }))}
                    />
                )}
            </View>
            <View style={styles.buttonContainer}>
                <Button
                    noBackground
                    onPress={() => {
                        Segment.addAddressClicked({})

                        if (setAddAddress) {
                            return setAddAddress()
                        }

                        navigation.navigate(ScreenNames.UserNewAddress)
                    }}
                    text={T.screens.user.addAddress}
                />
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        flex: 1,
        zIndex: theme.zIndex[10],
        flexDirection: {
            lg: 'row',
            ':w[, 1150]': 'column'
        },
        marginTop: theme.utils.gap(2),
        paddingHorizontal: {
            lg: 0,
            ':w[, 1150]': theme.utils.gap(1)
        },
        justifyContent: 'flex-end'
    },
    inputContainer: {
        flex: 1,
        zIndex: theme.zIndex[10],
        maxWidth: {
            lg: 300,
            ':w[, 1150]': undefined
        },
        marginRight: {
            lg: theme.utils.gap(2),
            ':w[, 1150]': 0
        }
    },
    buttonContainer: {
        flex: 1,
        maxWidth: {
            lg: 180,
            ':w[, 1150]': '100%'
        }
    }
}))
