import { useField } from '@codegateinc/react-form-builder-v2'
import { useTranslations } from 'lib/hooks'
import { isNative } from 'lib/common'
import { CancelationFields } from './forms'

export const useCancelationForm = () => {
    const T = useTranslations()

    const reason = useField<string>({
        key: CancelationFields.Reason,
        initialValue: '',
        isRequired: false,
        validateOnBlur: false,
        label: T.screens.jobDetails.cancelation.formFields.reason.label,
        placeholder: T.screens.jobDetails.cancelation.formFields.reason.placeHolder
    })

    const customReason = useField<string>({
        key: CancelationFields.CustomReason,
        initialValue: '',
        isRequired: false,
        validateOnBlur: false,
        label: T.screens.jobDetails.cancelation.formFields.customReason.label,
        placeholder: isNative ?
            T.screens.jobDetails.cancelation.formFields.customReason.placeHolderForNative :
            T.screens.jobDetails.cancelation.formFields.customReason.placeHolder
    })

    return {
        reason,
        customReason
    }
}
