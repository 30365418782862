import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import { useForm } from '@codegateinc/react-form-builder-v2'
import { SupplyID } from 'lib/types'
import { isNative } from 'lib/common'
import { Breakpoint, createStyles } from 'lib/styles'
import { useBookingTimeOptions, useInitializeAddress, useIsWithinBreakpoints, useSegmentSession, useStyles, useTranslations } from 'lib/hooks'
import { NavigationParams, NavigationProps, ScreenNames } from 'lib/routing'
import { Adapter, Address, Button, FormComponents, Grid, MediaQuery, Sticky, Typography } from 'lib/components'
import { dateHelpers } from 'lib/utils'
import { Segment, ServiceType } from 'lib/analytics'
import { getServiceStaticConfig } from 'features/bookings/utils'
import {
    BookingCancellationFees,
    BookingDateModal,
    MobileSummary,
    PestControlComponents,
    PriceSummaryComponent,
    Rated,
    ServiceDescription,
    ServiceLayout,
    TotalPriceComponent
} from '../components'
import { PestControlFields, PestControlFormShape, usePestControlForm } from '../forms'
import { useJobQuotation, usePestControlBreadcrumbs, useResetFormCallback } from '../hooks'
type PestControlScreenProps = {
    route: NavigationParams<ScreenNames.PestControl>
    navigation: NavigationProps<ScreenNames.PestControl>
}

export const PestControlScreen: React.FunctionComponent<PestControlScreenProps> = ({ navigation, route }) => {
    const T = useTranslations()
    const { segmentSession } = useSegmentSession()
    const { styles, theme } = useStyles(stylesheet)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const breadcrumbs = usePestControlBreadcrumbs()
    const [isHelpModalOpen, setHelpModalOpen] = useState(false)
    const isMobile = useIsWithinBreakpoints(Breakpoint.XS, Breakpoint.MD)
    const { jobQuotation, requestJobQuotation } = useJobQuotation(SupplyID.PestControl)
    const [totalPrice, setTotalPrice] = useState(0)
    const { form, isFilled, hasError, submit, resetForm, setFieldValue } = useForm<PestControlFormShape>(usePestControlForm(), {
        onSuccess: formValues => {
            navigation.navigate(ScreenNames.PestControlSummary, {
                postalcode: route.params?.postalcode,
                supplyId: SupplyID.PestControl,
                price: totalPrice,
                form: {
                    ...formValues,
                    services: formValues.services
                        .filter(service => service?.isSelected)
                        .map(service => ({
                            ...service
                        }))
                }
            })
        }
    })
    const config = getServiceStaticConfig(SupplyID.PestControl)
    const { pricing } = config
    const columnWidth = isNative || isMobile
        ? undefined
        : 'calc(55% - 10px)'
    const hasSelectedService = form.packages.value.length || form.services.value.some(service => service?.isSelected)
    const continueDisabled = !hasSelectedService || !form.bookingTime.value || !isFilled || hasError
    const formSummaryValue = Object.keys(form).reduce((acc, nextValue) => ({
        ...acc,
        [nextValue]: form[nextValue].value
    }), {} as PestControlFormShape)
    const propertyOptions = pricing.propertyType.options.map(item => ({
        ...item,
        value: item.id
    }))
    const selectedTimeOption = useBookingTimeOptions(form.startingDate.value).find(item => item.value === form.bookingTime.value)

    useEffect(() => {
        setFieldValue(PestControlFields.BookingTime, '')
    }, [form.startingDate.value])

    useResetFormCallback(resetForm)

    useEffect(() => {
        if (segmentSession.id) {
            Segment.bookingStarted({
                bookingSessionId: segmentSession.id,
                postcode: route.params?.postalcode as string,
                serviceType: ServiceType.PestControl
            })
        }
    }, [segmentSession])

    useInitializeAddress(true)

    useEffect(() => {
        const selectedServices = getSelectedServices()
        const selectedPackages = getSelectedPackages()

        if((selectedServices.length === 0 && selectedPackages.length === 0) || !form.propertyType.value){
            setTotalPrice(0)

            return
        }

        requestJobQuotation({
            propertyType: form.propertyType.value,
            bySession: selectedServices,
            byPackage: selectedPackages
        })
    }, [form.propertyType.value, form.services.value, form.packages.value])

    useEffect(() => {
        if(jobQuotation) {
            setTotalPrice(jobQuotation?.total?.value)
        }
    }, [jobQuotation])

    const getSelectedServices = () => {
        const selectedServices = form.services.value
            .filter(service => service?.isSelected)
            .map(service => ({ id: service.id, quantity: service.number_of_treatments }))

        return selectedServices
    }

    const getSelectedPackages = () => {
        const selectedPackages = form.packages.value.flatMap(p =>
            p.items
                .filter(i => i.isSelected)
                .map(i => ({
                    packageId: p.id,
                    optionId: i.id
                }))
        )

        return selectedPackages
    }

    return (
        <React.Fragment>
            <ServiceLayout
                onInfoPress={() => setHelpModalOpen(true)}
                title={T.screens.pestControl.title}
                withBackground
                breadcrumbs={breadcrumbs}
                bottomSpacerHeight={
                    isMobile
                        ? 0
                        : 50
                }
                contentColumn={(
                    <View>
                        <MediaQuery.Hidden from={Breakpoint.LG}>
                            <View style={styles.mobileDescriptionContainer}>
                                <ServiceDescription
                                    isHelpModalOpen={isHelpModalOpen}
                                    setHelpModalOpen={setHelpModalOpen}
                                    title={T.screens.pestControl.title}
                                    modalContent={(
                                        <PestControlComponents.PestControlHelpModalContent />
                                    )}
                                    description={T.screens.pestControl.serviceDescription}
                                />
                            </View>
                        </MediaQuery.Hidden>
                        <BookingDateModal
                            title={T.screens.pestControl.title}
                            description={T.screens.pestControl.calendarModal.oneTime.description}
                            minHoursNotice={config.min_notice_hours}
                            onContinue={() => setIsModalOpen(false)}
                            isModalOpen={isModalOpen}
                            frequency={form.frequency.value}
                            startingDateField={form.startingDate}
                            bookingTimeField={form.bookingTime}
                            bookingDaysField={form.bookingDays}
                            timeTitle={T.screens.pestControl.formFields.selectTime.label}
                            onClose={() => setIsModalOpen(false)}
                        />
                        <FormComponents.FormRow>
                            <View
                                style={{
                                    width: columnWidth
                                }}
                            >
                                <Adapter.DateAndTime
                                    {...form.startingDate}
                                    value={(form.startingDate.value && selectedTimeOption)
                                        ? `${dateHelpers.shortFullDate(form.startingDate.value)} - ${selectedTimeOption.label}`
                                        : form.startingDate.value
                                    }
                                    onPress={() => {
                                        Segment.bookingDateAndTimeClicked({})

                                        setIsModalOpen(true)
                                    }}
                                />
                                <Grid.Gap gapBottom={1} />
                                {Boolean(config.min_notice_hours) && (
                                    <Typography.Label forceColor={theme.colors.fog}>
                                        {`${T.screens.pestControl.minimumNotice.prefix} ${config.min_notice_hours}${T.screens.pestControl.minimumNotice.suffix}.`}
                                    </Typography.Label>
                                )}
                            </View>
                        </FormComponents.FormRow>
                        <FormComponents.FormRow style={styles.zIndex}>
                            <View
                                style={{
                                    width: columnWidth
                                }}
                            >
                                <Adapter.SelectInputColumn
                                    {...form.propertyType}
                                    onMenuOpen={() => {
                                        Segment.bookingPropertyTypeClicked({})
                                    }}
                                    options={propertyOptions}
                                    value={form.propertyType.value}
                                    onChangeValue={value => {
                                        setTotalPrice(0)
                                        form.propertyType.onChangeValue(value)
                                        form.services.onChangeValue([])
                                        form.packages.onChangeValue([])
                                    }}
                                    isClearable={false}
                                    onOptionSelect={({ value }) => {
                                        Segment.bookingPropertyTypeSelected({
                                            propertyType: value
                                        })
                                    }}
                                />
                            </View>
                        </FormComponents.FormRow>
                        <Typography.Regular bold>
                            {T.screens.pestControl.selectServices}
                        </Typography.Regular>
                        <PestControlComponents.PestControlServicesTabs
                            packages={form.packages}
                            services={form.services}
                            propertyType={form.propertyType.value}
                        />
                        <Grid.Gap gapBottom={4} />
                        <Typography.Label forceColor={theme.colors.fog}>
                            {T.screens.pestControl.minimumSessions}
                        </Typography.Label>
                        <Grid.Gap gapBottom={8} />
                    </View>
                )}
                summaryColumn={(
                    <MediaQuery.Visible from={Breakpoint.LG}>
                        <ServiceDescription
                            isHelpModalOpen={isHelpModalOpen}
                            setHelpModalOpen={setHelpModalOpen}
                            title={T.screens.pestControl.title}
                            modalContent={(
                                <PestControlComponents.PestControlHelpModalContent />
                            )}
                            description={T.screens.pestControl.serviceDescription}
                        />
                        <Sticky>
                            <Address />
                            <PestControlComponents.PestControlSummaryColumn
                                priceMessage={T.screens.pestControl.estimatedMessage}
                                renderPriceComponent={() => (
                                    <TotalPriceComponent
                                        totalPrice={totalPrice}
                                    />
                                )}
                                renderPriceSummaryComponent={((getSelectedServices().length !== 0 || getSelectedPackages().length !== 0) && jobQuotation?.priceSummary) ?
                                    () => <PriceSummaryComponent priceSummary={jobQuotation.priceSummary!} promotion={jobQuotation.discount} />
                                    : undefined
                                }
                                form={formSummaryValue}
                            />
                            <Button
                                onPress={submit}
                                disabled={continueDisabled}
                                text={T.common.continue}
                            />
                            <BookingCancellationFees />
                        </Sticky>
                    </MediaQuery.Visible>
                )}
                footer={(
                    <MediaQuery.Hidden from={Breakpoint.LG}>
                        <MobileSummary
                            submit={submit}
                            price={totalPrice}
                            disabled={continueDisabled}
                            isLoading={false}
                            content={(
                                <React.Fragment>
                                    <Address />
                                    <PestControlComponents.PestControlSummaryColumn
                                        priceMessage={T.screens.pestControl.estimatedMessage}
                                        renderPriceComponent={() => (
                                            <TotalPriceComponent
                                                totalPrice={jobQuotation?.total?.value || 0}
                                            />
                                        )}
                                        renderPriceSummaryComponent={() => jobQuotation?.priceSummary ? (
                                            <PriceSummaryComponent priceSummary={jobQuotation?.priceSummary} promotion={jobQuotation.discount} />
                                        ) : undefined}
                                        form={formSummaryValue}
                                    />
                                    <Rated />
                                    <BookingCancellationFees />
                                </React.Fragment>
                            )}
                        />
                    </MediaQuery.Hidden>
                )}
            />
        </React.Fragment>
    )
}

const stylesheet = createStyles(theme => ({
    row: {
        marginTop: theme.utils.gap(1),
        flexDirection: {
            lg: 'row',
            xs: 'column'
        }
    },
    zIndex: {
        zIndex: theme.zIndex[10]
    },
    spacer: {
        width: theme.utils.gap(1)
    },
    mobileDescriptionContainer: {
        backgroundColor: theme.colors.marble,
        marginHorizontal: -theme.utils.gap(2),
        marginBottom: theme.utils.gap(2),
        paddingHorizontal: theme.utils.gap(2),
        paddingTop: theme.utils.gap(4)
    }
}))
