import { View } from 'react-native'
import React, { useState } from 'react'
import { useStyles } from 'lib/hooks'
import { useResetFormAtom } from 'lib/atoms'
import { createStyles } from 'lib/styles'
import { Touchable } from '../Touchable'
import { Regular } from '../typography'
import { NewBadge } from '../NewBadge'

type MainMenuLinkProps = {
    withUnderline?: boolean,
    label: string,
    onPress: VoidFunction,
    isNew?: boolean
}

export const MainMenuLink: React.FunctionComponent<MainMenuLinkProps> = ({
    onPress,
    label,
    withUnderline = false,
    isNew
}) => {
    const { styles, theme } = useStyles(stylesheet)
    const [isHovering, setIsHovering] = useState(false)
    const [, setResetForm] = useResetFormAtom()

    return (
        <Touchable
            onPress={() => {
                onPress()
                setResetForm(true)
            }}
            style={{
                ...styles.link,
                backgroundColor: isHovering
                    ? theme.colors.yellow
                    : undefined
            }}
            onHoverIn={() => setIsHovering(true)}
            onHoverOut={() => setIsHovering(false)}
        >
            <View
                style={{
                    ...styles.container,
                    ...withUnderline
                        ? styles.underline
                        : {},
                    borderColor: !isHovering
                        ? theme.colors.shadow
                        : theme.colors.white
                }}
            >
                <Regular forceColor={isHovering ? theme.colors.orange : undefined}>
                    {label}
                </Regular>
                {isNew && (
                    <NewBadge />
                )}
            </View>
        </Touchable>
    )
}

const stylesheet = createStyles(theme => ({
    link: {
        paddingLeft: theme.utils.gap(2)
    },
    underline: {
        borderBottomWidth: 1
    },
    container: {
        flexDirection: 'row',
        paddingVertical: theme.utils.gap(2)
    }
}))
