import { useAtom } from 'jotai'
import { NotificationType, ToastMessage } from 'lib/types'
import { createAtom } from './atomFactory'

export const initToastState = {
    message: '',
    type: NotificationType.Success
}

const { atom } = createAtom<ToastMessage>(initToastState)

export const useToastAtom = () => useAtom(atom)
