import { View } from 'react-native'
import React from 'react'
import { Typography } from 'lib/components'
import { usePrice, useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'

type LaundryItemPriceTileProps = {
    title: string,
    price: number
}

export const LaundryItemPriceTile: React.FunctionComponent<LaundryItemPriceTileProps> = ({
    title,
    price
}) => {
    const { styles } = useStyles(stylesheet)
    const formattedPrice = usePrice(price || 0)

    return (
        <View style={styles.item}>
            <Typography.Regular>
                {title}
            </Typography.Regular>
            <Typography.Regular
                bold
                style={styles.priceLabel}
            >
                {formattedPrice}
            </Typography.Regular>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    item: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        borderBottomWidth: 1,
        borderBottomColor: theme.colors.silver,
        paddingTop: {
            lg: theme.utils.gap(1.5),
            xs: theme.utils.gap(1)
        },
        paddingBottom: {
            lg: theme.utils.gap(1),
            xs: theme.utils.gap(0.5)
        }
    },
    priceLabel: {
        width: {
            lg: undefined,
            xs: 70
        },
        textAlign: 'right'
    }
}))
