import 'react-native-gesture-handler'
import 'react-native-get-random-values'
import 'core-js/actual/array/at'
import 'core-js/actual/object/entries'
import 'core-js/actual/object/from-entries'
import ReactGA from 'react-ga4'
import 'setimmediate'
import Analytics from 'analytics'
import googleTagManager from '@analytics/google-tag-manager'
import '../node_modules/flag-icons/css/flag-icons.min.css'
import { registerRootComponent } from 'expo'
import { App } from 'app'
import { ENV } from 'lib/config'

require('./reset.css')

// FIXME need reanimated update, see https://github.com/software-mansion/react-native-reanimated/issues/3355
if (window) {
    // @ts-ignore
    window._frameTimestamp = null
}

ReactGA.initialize(ENV.GOOGLE_TRACKING_ID as string, {
    gaOptions: {
        cookieFlags: 'SameSite=None;Secure'
    }
})

Analytics({
    plugins: [
        googleTagManager({
            containerId: ENV.GOOGLE_TAG_MANAGER_ID
        })
    ]
})

registerRootComponent(App)
