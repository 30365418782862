import React from 'react'
import { View } from 'react-native'
import { createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { Touchable, Typography } from 'lib/components'
import { linkingHelpers } from 'lib/utils'
import { ENV } from 'lib/config'

type DetailsContactLinkProps = {
    text: string,
    onPress?: VoidFunction
}

export const DetailsContactLink: React.FunctionComponent<DetailsContactLinkProps> = ({
    text,
    onPress
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)

    return (
        <Touchable
            onPress={() => {
                linkingHelpers.openUrl(`${ENV.SENDHELPER_MARKETING_SITE_URL}/contact`)

                if (onPress) {
                    onPress()
                }
            }}
        >
            <View
                style={styles.contact}
                hitSlop={{
                    top: 20,
                    left: 20,
                    bottom: 20,
                    right: 20
                }}
            >
                <Typography.Label  forceColor={theme.colors.mouse}>
                    {`${text} `}
                </Typography.Label>
                <Typography.Label
                    bold
                    forceColor={theme.colors.orange}
                >
                    {T.headerLinks.contactUs}
                </Typography.Label>
            </View>
        </Touchable>
    )
}

const stylesheet = createStyles(theme => ({
    contact: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: {
            lg: theme.utils.gap(2),
            xs: undefined
        }
    }
}))
