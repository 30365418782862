import React from 'react'
import { View, Image } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { Field, useForm } from '@codegateinc/react-form-builder-v2'
import { AvoidSoftInputView } from 'react-native-avoid-softinput'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import Animated, { Extrapolate, interpolate, useAnimatedScrollHandler, useAnimatedStyle, useSharedValue } from 'react-native-reanimated'
import { Images } from 'assets'
import { createStyles } from 'lib/styles'
import { ScreenNames } from 'lib/routing'
import { ProviderDetails } from 'lib/models'
import { useAvoidInput, useBackHandler, useStyles, useTranslations } from 'lib/hooks'
import { Separator, Touchable, Typography } from 'lib/components'
import { SignUpForm, SocialButtons, TermsAndPrivacy } from '../components'
import { useSignUp, useSignIn } from '../hooks'
import { SignUpFormShape, useSignUpForm } from '../forms'

export const SignUpNativeScreen: React.FunctionComponent = () => {
    const T = useTranslations()
    const insets = useSafeAreaInsets()
    const { styles, theme } = useStyles(stylesheet)
    const navigation = useNavigation()
    const { isLoading, verifyUserEmail, signUpWithApple } = useSignUp()
    const { signInWithFacebook, signInWithGoogle, isLoading: isSignInLoading } = useSignIn()
    const { form, submit, isFilled, setError, hasError } = useForm<SignUpFormShape>(useSignUpForm(ProviderDetails.Email), {
        onSuccess: form => verifyUserEmail(
            form,
            (authUser, verifyCode) => navigation.navigate(ScreenNames.AuthVerificationCodeNative, {
                authUser,
                verifyCode
            }),
            setError
        )
    })
    const translationY = useSharedValue(0)
    const scrollHandler = useAnimatedScrollHandler(event => {
        translationY.value = event.contentOffset.y
    })
    const animatedScale = useAnimatedStyle(() => ({
        transform: [
            {
                scale: interpolate(translationY.value, [-100, 0, 50], [1.2, 1, 0.95], Extrapolate.CLAMP)
            }
        ]
    }))

    useAvoidInput()
    useBackHandler(() => isLoading || isSignInLoading)

    return (
        <View style={styles.wrapper}>
            <Animated.View
                style={[
                    styles.imageWrapper,
                    animatedScale
                ]}
            >
                <Image
                    source={Images.SignUpHero}
                    style={styles.image}
                />
            </Animated.View>
            <Animated.ScrollView
                onScroll={scrollHandler}
                scrollEventThrottle={16}
                showsVerticalScrollIndicator={false}
            >
                <AvoidSoftInputView>
                    <View
                        style={{
                            ...styles.contentContainer,
                            paddingBottom: insets.bottom + theme.utils.gap(2)
                        }}
                    >
                        <View style={styles.headerText}>
                            <Typography.Subheading>
                                {T.common.signUp}
                            </Typography.Subheading>
                            <Touchable
                                testID={T.accessibility.signUp.hasAccountLogin}
                                style={styles.subHeaderText}
                                onPress={() => navigation.navigate(ScreenNames.LogInNative)}
                            >
                                <Typography.Label forceColor={theme.colors.darkGrey}>
                                    {T.screens.auth.hasAccountText}
                                </Typography.Label>
                                <Typography.Label
                                    bold
                                    forceColor={theme.colors.orange}
                                >
                                    {` ${T.common.logIn}`}
                                </Typography.Label>
                            </Touchable>
                        </View>
                        <SignUpForm
                            providerDetails={ProviderDetails.Email}
                            form={form as Record<keyof SignUpFormShape, Field<string & boolean>>}
                            isLoading={isLoading || isSignInLoading}
                            onSubmit={submit}
                            formHasError={!isFilled || hasError}
                        />
                        <Separator
                            height={20}
                            text={T.screens.auth.signUpSeparatorNative}
                        />
                        <SocialButtons
                            disabled={isLoading || isSignInLoading}
                            onContinueWithFacebook={signInWithFacebook}
                            onContinueWithApple={signUpWithApple}
                            onContinueWithGoogle={signInWithGoogle}
                        />
                        <TermsAndPrivacy />
                    </View>
                </AvoidSoftInputView>
            </Animated.ScrollView>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        flex: 1,
        backgroundColor: theme.colors.white
    },
    imageWrapper: {
        left: -20,
        right: -20,
        top: -20,
        bottom: -20,
        flex: 1,
        height: 200,
        position: 'absolute'
    },
    image: {
        width: '100%',
        height: '100%'
    },
    contentContainer: {
        marginTop: 170,
        borderTopLeftRadius: 15,
        borderTopRightRadius: 15,
        backgroundColor: theme.colors.white,
        paddingHorizontal: theme.utils.gap(2),
        paddingTop: theme.utils.gap(2)
    },
    forgotPasswordContainer: {
        alignItems: 'flex-end',
        marginBottom: {
            lg: theme.utils.gap(3),
            xs: theme.utils.gap(2)
        }
    },
    forgotButton: {
        borderBottomWidth: 1
    },
    submit: {
        alignItems: 'center'
    },
    headerText: {
        alignItems: 'center',
        marginBottom: theme.utils.gap(2)
    },
    subHeaderText: {
        flexDirection: 'row',
        marginTop: theme.utils.gap(1)
    },
    resetPasswordMessage: {
        marginVertical: theme.utils.gap(3)
    },
    resetPasswordMessageText: {
        textAlign: 'center'
    }
}))
