import { useField } from '@codegateinc/react-form-builder-v2'
import { useUserAtom } from 'lib/atoms'
import { regexes } from 'lib/utils'
import { useTranslations } from 'lib/hooks'
import { EditProfileFields } from './forms'

export const useEditProfileForm = () => {
    const T = useTranslations()
    const [user] = useUserAtom()

    const name = useField<string>({
        key: EditProfileFields.Name,
        initialValue: user.handle,
        isRequired: true,
        validateOnBlur: true,
        placeholder: T.screens.user.formFields.name.placeholder,
        label: T.screens.user.formFields.name.label,
        validationRules: [
            {
                errorMessage: T.screens.user.formFields.name.validation.isRequired,
                validate: Boolean
            },
            {
                errorMessage: T.screens.auth.formFields.name.validation.minValue,
                validate: value => value.length >= 3
            },
            {
                errorMessage: T.screens.auth.formFields.name.validation.maxValue,
                validate: value => value.length <= 30
            }
        ],
        submitParser: value => value
            .split(' ')
            .filter(Boolean)
            .join(' ')
    })

    const phoneNumber = useField<string>({
        key: EditProfileFields.PhoneNumber,
        initialValue: user.phone,
        isRequired: true,
        validateOnBlur: true,
        placeholder: T.screens.user.formFields.phoneNumber.placeholder,
        label: T.screens.user.formFields.phoneNumber.label,
        validationRules: [
            {
                errorMessage: T.screens.user.formFields.phoneNumber.validation.isRequired,
                validate: Boolean
            },
            {
                errorMessage: T.screens.user.formFields.phoneNumber.validation.tooShort,
                validate: ({ length }) => length >= 5
            },
            {
                errorMessage: T.screens.user.formFields.phoneNumber.validation.tooLong,
                validate: ({ length }) => length <= 16
            }
        ],
        liveParser: value => {
            if (!value) {
                return value
            }

            if (!value.slice(-1).match(/\d/) || value.length > 16) {
                return value.slice(0, -1)
            }

            return value
        }
    })

    const email = useField<string>({
        key: EditProfileFields.Email,
        initialValue: user.email_id,
        isRequired: true,
        validateOnBlur: true,
        placeholder: T.screens.user.formFields.email.placeholder,
        label: T.screens.user.formFields.email.label,
        validationRules: [
            {
                errorMessage: T.screens.user.formFields.email.validation.isRequired,
                validate: Boolean
            },
            {
                errorMessage: T.screens.user.formFields.email.validation.isInvalid,
                validate: regexes.isValidEmail
            }
        ],
        submitParser: value => value.trim()
    })

    return {
        name,
        phoneNumber,
        email
    }
}
