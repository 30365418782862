import React from 'react'
import { View } from 'react-native'
import { Icons } from 'assets'
import { createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { CopyListener, StickyMobile, Touchable, Typography } from 'lib/components'
import { Segment } from 'lib/analytics'

type ReferralCodeMobileProps = {
    code: string,
    onPress: VoidFunction
}

export const ReferralCodeMobile: React.FunctionComponent<ReferralCodeMobileProps> = ({
    code,
    onPress
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)

    return (
        <StickyMobile>
            <View style={styles.codeContainer}>
                <Typography.Regular bold>
                    {T.screens.referFriend.yourCode}
                </Typography.Regular>
                <CopyListener
                    onCopy={() => {
                        Segment.referralCodeCopied({
                            referralCode: code
                        })
                    }}
                >

                    <View style={styles.codeWrapper}>
                        <Typography.Subheading>
                            {code}
                        </Typography.Subheading>
                    </View>
                </CopyListener>
                <Touchable
                    style={styles.shareButton}
                    onPress={() => {
                        Segment.shareReferralCodeClicked({})

                        onPress()
                    }}
                >
                    <Icons.Share size={30} />
                    <Typography.Regular
                        bold
                        style={styles.shareIcon}
                    >
                        {T.screens.referFriend.shareCode}
                    </Typography.Regular>
                </Touchable>
            </View>
        </StickyMobile>
    )
}

const stylesheet = createStyles(theme => ({
    codeContainer: {
        alignItems: 'center',
        backgroundColor: theme.colors.yellow,
        borderColor: theme.colors.fog,
        paddingVertical: theme.utils.gap(2),
        paddingHorizontal: theme.utils.gap(2)
    },
    codeWrapper: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginVertical: theme.utils.gap(1)
    },
    shareButton: {
        width: '100%',
        borderRadius: theme.utils.gap(1) / 2,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: theme.utils.gap(1),
        backgroundColor: theme.colors.sun
    },
    shareIcon: {
        marginLeft: theme.utils.gap(1)
    }
}))
