import React from 'react'
import { View } from 'react-native'
import { openURL } from 'expo-linking'
import { createStyles } from 'lib/styles'
import { Typography } from 'lib/components'
import { useStyles, useTranslations } from 'lib/hooks'
import { APP_CONFIG } from 'lib/config'

export const MoversAndPackersHelpModalContent: React.FunctionComponent = () => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const includedList = Object.values(T.components.moversAndPackers.includedList)

    return (
        <View style={styles.container}>
            <View style={styles.title}>
                <Typography.Title bold>
                    {T.components.moversAndPackers.title}
                </Typography.Title>
            </View>
            <Typography.Regular forceColor={theme.colors.darkGrey}>
                {T.components.moversAndPackers.description}
            </Typography.Regular>
            <View style={styles.row}>
                <Typography.Regular bold>
                    {T.components.moversAndPackers.includedTitle}
                </Typography.Regular>
                <View style={styles.listContainer}>
                    {includedList.map((item, index) => (
                        <View
                            key={index}
                            style={styles.listItem}
                        >
                            <View style={styles.dot} />
                            <Typography.Regular forceColor={theme.colors.darkGrey}>
                                {item}
                            </Typography.Regular>
                        </View>
                    ))}
                </View>
            </View>
            <View style={styles.row}>
                <Typography.Regular forceColor={theme.colors.darkGrey}>
                    {T.components.moversAndPackers.includedMessage}
                    <Typography.Regular
                        bold
                        forceColor={theme.colors.orange}
                        onPress={() => openURL(`mailto:${APP_CONFIG.CONTACT.MAIL}`)}
                    >
                        {T.components.moversAndPackers.helpEmail}
                    </Typography.Regular>
                    {T.components.moversAndPackers.includedMessagePartTwo}
                </Typography.Regular>
            </View>
            <View style={styles.row}>
                <Typography.Regular bold>
                    {T.components.moversAndPackers.howDoesItWorkTitle}
                </Typography.Regular>
                <Typography.Regular forceColor={theme.colors.darkGrey}>
                    {T.components.moversAndPackers.howDoesItWorkMessagePartOne}
                </Typography.Regular>
            </View>
            <View style={styles.row}>
                <Typography.Regular forceColor={theme.colors.darkGrey}>
                    {T.components.moversAndPackers.howDoesItWorkMessagePartTwo}
                </Typography.Regular>
            </View>
            <View style={styles.row}>
                <Typography.Regular forceColor={theme.colors.darkGrey}>
                    {T.components.moversAndPackers.howDoesItWorkMessagePartThree}
                </Typography.Regular>
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        maxWidth: 800,
        padding: {
            lg: theme.utils.gap(2),
            xs: undefined
        }
    },
    listContainer: {
        marginTop: theme.utils.gap(1 / 2),
        paddingLeft: theme.utils.gap(1)
    },
    title: {
        marginBottom: theme.utils.gap(2)
    },
    row: {
        marginTop: theme.utils.gap(2)
    },
    dot: {
        width: 4,
        height: 4,
        borderRadius: 2,
        backgroundColor: theme.colors.night,
        marginRight: theme.utils.gap(1)
    },
    listItem: {
        flexDirection: 'row',
        alignItems: 'center'
    }
}))
