import React from 'react'
import { View } from 'react-native'
import { ENV } from 'lib/config'
import { JobStatus, JobType } from 'lib/types'
import { Segment, segmentUtils } from 'lib/analytics'
import { createStyles } from 'lib/styles'
import { linkingHelpers } from 'lib/utils'
import { Job, RecurringJob } from 'lib/models'
import { BookingTileButtonSmall, JobContactSmall, JobStatusLabelSmall, JobTypeLabelSmall, useJobDetailsRoute } from 'features/jobs'
import { useStyles, useTranslations } from 'lib/hooks'
import { Label } from '../typography'
import { Touchable } from '../Touchable'
import { CopyBookingId } from '../CopyBookingId'

type BookingCalendarModalTileProps = {
    job: Job,
    onClose: VoidFunction,
    jobStatus: JobStatus,
    recurringJobs: Array<RecurringJob>
}

export const BookingCalendarModalTile: React.FunctionComponent<BookingCalendarModalTileProps> = ({
    job,
    onClose,
    jobStatus,
    recurringJobs
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const navigate = useJobDetailsRoute(job, jobStatus, recurringJobs)
    const isAccepted = job.supplier && job.status === JobStatus.Accepted
    const isRecurring = job.job_type === JobType.Recurring

    const onNavigate = () => {
        onClose()
        navigate()
    }

    return (
        <Touchable onPress={onNavigate} testID={T.accessibility.bookingsScreen.views.calendarView.item}>
            <View style={styles.container}>
                <View style={styles.icon}>
                    <CopyBookingId id={job.job_id} />
                </View>
                <View style={styles.jobIdWrapper}>
                    <View style={styles.jobContainer}>
                        <Label forceColor={theme.colors.mouse}>
                            {job.job_id}
                        </Label>
                        <JobStatusLabelSmall job={job} />
                    </View>
                </View>
                <JobTypeLabelSmall
                    job={job}
                    recurringJobs={recurringJobs}
                    isCompletedPage={jobStatus === JobStatus.Completed}
                />
                {isAccepted && (
                    <JobContactSmall
                        job={job}
                        onChatPress={onClose}
                    />
                )}
                {job.job_proposals?.length !== 0 && jobStatus === JobStatus.Submitted && !isAccepted && !isRecurring && (
                    <BookingTileButtonSmall
                        text={T.components.jobStatus.proposalReceived}
                        onPress={onNavigate}
                    />
                )}
                {jobStatus === JobStatus.Completed && (
                    <BookingTileButtonSmall
                        text={T.components.jobStatus.bookAgain}
                        onPress={() => {
                            Segment.bookAgainClicked({
                                jobId: job.job_id,
                                serviceType: segmentUtils.supplyIdToServiceType(job.supply.id),
                                jobStatus: segmentUtils.getReadableJobStatus(job.status)
                            })

                            linkingHelpers.openUrl(ENV.SENDHELPER_MARKETING_SITE_URL, false)
                        }}
                    />
                )}
            </View>
        </Touchable>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        borderRadius: 8,
        flexDirection: 'column',
        backgroundColor: theme.colors.white,
        paddingVertical: theme.utils.gap(1),
        paddingHorizontal: theme.utils.gap(1),
        marginVertical: theme.utils.gap(2),
        ...theme.utils.createShadow(2, 0, 8, 4, 4, theme.colors.webShadow(0.04))
    },
    jobIdWrapper: {
        marginTop: theme.utils.gap(1),
        marginLeft: theme.utils.gap(2),
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    jobContainer: {
        flex: 1,
        flexDirection: 'row'
    },
    icon: {
        position: 'absolute',
        zIndex: theme.zIndex[10],
        top: theme.utils.gap(2),
        right: theme.utils.gap(2)
    }
}))
