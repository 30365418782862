import React from 'react'
import { View } from 'react-native'
import { createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { CustomModal, Button, Typography, Adapter } from 'lib/components'
import { JobDetailsResponse } from 'features/jobs/types'
import { PauseBookingsFormShape } from 'features/jobs/forms'
import { UseFormReturn } from '@codegateinc/react-form-builder-v2'
import { usePauseDates } from 'features/jobs/hooks'
import { dateHelpers, jobHelpers } from 'lib/utils'
import { useLocaleAtom } from 'lib/atoms'
import { PauseSubscriptionModal } from '../pauseSubscriptionModal'

type PausePackageModalProps = {
    isOpen: boolean,
    isLoading?: boolean,
    job: JobDetailsResponse,
    onClose: VoidFunction,
    onSubmit: VoidFunction,
    formReturn: UseFormReturn<PauseBookingsFormShape>,
    onRescheduleBooking: VoidFunction,
}

export const PausePackageModal: React.FunctionComponent<PausePackageModalProps> = ({
    isOpen,
    isLoading,
    formReturn,
    job,
    onClose,
    onSubmit,
    onRescheduleBooking
}) => {
    const T = useTranslations()
    const [locale] = useLocaleAtom()
    const { styles } = useStyles(stylesheet)
    const { form } = formReturn
    const { startOptions, endOptions } = usePauseDates(job.job.schedule, 60, form.startDate.value)

    if (jobHelpers.shouldShowSubscriptionPauseModal(job)) {
        return (
            <PauseSubscriptionModal
                job={job}
                onSubmit={onSubmit}
                formReturn={formReturn}
                isLoading={isLoading}
                isOpen={isOpen}
                onClose={onClose}
                onRescheduleBooking={onRescheduleBooking}
            />
        )
    }

    return (
        <CustomModal
            onClose={onClose}
            isOpen={isOpen}
        >
            <View style={styles.modalWrapper}>
                <View style={styles.title}>
                    <Typography.SmallSubheading>
                        {T.components.resumeJob.pauseBooking}
                    </Typography.SmallSubheading>
                </View>

                <View style={styles.form}>
                    <View>
                        <Typography.Regular bold>
                            {T.components.resumeJob.pauseFrom}
                        </Typography.Regular>
                        <Adapter.DateScrollSelect
                            {...form.startDate}
                            disabled={isLoading}
                            options={startOptions.slice(0, 30).map(option => ({
                                label: dateHelpers.getPauseDates(option, locale),
                                value: option
                            }))}
                        />
                    </View>
                    <View>
                        <Typography.Regular bold>
                            {T.components.resumeJob.endOn}
                        </Typography.Regular>
                        <Adapter.DateScrollSelect
                            {...form.endDate}
                            disabled={isLoading}
                            options={endOptions.slice(0, 30).map(option => ({
                                label: dateHelpers.getPauseDates(option, locale),
                                value: option
                            }))}
                        />
                    </View>
                </View>

                <View style={styles.actionContainer}>
                    <View style={styles.buttonWrapper}>
                        <Button
                            disabled={isLoading || formReturn.hasError || !formReturn.isFilled}
                            text={T.screens.jobDetails.cancelBookingSuccess.done}
                            onPress={onSubmit}
                            textWrapperStyles={styles.buttonTextWrapper}
                            textStyles={styles.buttonText}
                        />
                    </View>
                </View>
            </View>
        </CustomModal>
    )
}

const stylesheet = createStyles(theme => ({
    modalWrapper: {
        alignItems: 'center',
        paddingHorizontal: {
            lg: theme.utils.gap(16),
            md: theme.utils.gap(8),
            sm: theme.utils.gap(6),
            xs: theme.utils.gap(2)
        }
    },
    title: {
        textAlign: 'left',
        width: '100%'
    },
    actionContainer: {
        paddingTop: theme.utils.gap(3),
        flexDirection: 'row',
        justifyContent: 'center',
        zIndex: -1,
        width: '100%'
    },
    buttonWrapper: {
        marginHorizontal: theme.utils.gap(1),
        flex: 1
    },
    buttonTextWrapper: {
        width: '100%'
    },
    form: {
        marginVertical: theme.utils.gap(2),
        flexDirection: {
            lg: 'row',
            xs: 'column'
        }
    },
    buttonText: {
        textAlign: 'center'
    }
}))
