import React from 'react'
import { View } from 'react-native'
import { createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { Typography } from 'lib/components'
import { CustomFonts } from 'lib/types'
import { linkingHelpers } from 'lib/utils'
import { ENV } from 'lib/config'

export const TermsAndPrivacy: React.FunctionComponent = () => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)

    return (
        <View style={styles.container}>
            <Typography.Regular style={styles.text}>
                {`${T.screens.auth.termsAndPrivacy.prefixContent} `}
                <Typography.Regular
                    style={{
                        ...styles.text,
                        ...styles.link
                    }}
                    onPress={() => linkingHelpers.openUrl(`${ENV.SENDHELPER_MARKETING_SITE_URL}/terms`)}
                >
                    {T.screens.auth.termsAndPrivacy.termsOfService}
                </Typography.Regular>
                {` ${T.common.and} `}
                <Typography.Regular
                    style={{
                        ...styles.text,
                        ...styles.link
                    }}
                    onPress={() => linkingHelpers.openUrl(`${ENV.SENDHELPER_MARKETING_SITE_URL}/privacy`)}
                >
                    {T.screens.auth.termsAndPrivacy.privacyPolicy}
                </Typography.Regular>
                {` ${T.screens.auth.termsAndPrivacy.suffixContent}`}
            </Typography.Regular>
            <Typography.Regular style={styles.text}>
                {T.screens.auth.marketingConsent.promoCodeDescription}
            </Typography.Regular>
            <Typography.Regular style={styles.text}>
                {T.screens.auth.marketingConsent.promoCodeCondition}
            </Typography.Regular>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: theme.utils.gap(2)
    },
    text: {
        textAlign: 'center',
        marginBottom: theme.utils.gap(1),
        fontFamily: CustomFonts.Roboto400,
        fontSize: 12,
        color: theme.colors.grey
    },
    link: {
        color: theme.colors.orange,
        fontFamily: CustomFonts.Roboto700
    }
}))
