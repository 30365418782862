import { atom as jotaiAtom, useAtom } from 'jotai'
import { createAtom } from './atomFactory'
import { MMKVKey } from '../mmkv'
import { Notification } from '../models'

const { atom, resetAtom } = createAtom<Array<Notification>>([], MMKVKey.Notification)
const unreadNotificationCounterAtom = jotaiAtom(get => get(atom).filter(notification => !notification.read).length)

export const useNotificationAtom = () => useAtom(atom)
export const useUnreadNotificationCounterAtom = () => useAtom(unreadNotificationCounterAtom)
export const useResetNotificationAtom = () => useAtom(resetAtom)
