import React, { useEffect, useState } from 'react'
import { View, ViewStyle } from 'react-native'
import { useNavigation, useRoute } from '@react-navigation/native'
import { Field } from '@codegateinc/react-form-builder-v2'
import { Address } from 'lib/models'
import { createStyles } from 'lib/styles'
import { NotificationType, Nullable, SelectInputOption } from 'lib/types'
import { NewAddressShape } from 'features/user'
import { useStyles, useTranslations } from 'lib/hooks'
import { Adapter, ConfirmationModal, Typography } from 'lib/components'
import { isNative } from 'lib/common'
import { useAddressAtom, useToastAtom, useUserAtom } from 'lib/atoms'
import { BookingsScreens, NavigationParams, ScreenNames, StackProps } from 'lib/routing'
import { linkingHelpers } from 'lib/utils'

type SummaryNewAddressFormProps = {
    isLoading: boolean,
    customStyle?: ViewStyle,
    title?: string,
    form: Record<keyof NewAddressShape, Field<SelectInputOption & string>>,
    fetchPostalCodes(value: string): Promise<Array<SelectInputOption>>
}

export const SummaryNewAddressForm: React.FunctionComponent<SummaryNewAddressFormProps> = ({
    form,
    isLoading,
    customStyle = {},
    title,
    fetchPostalCodes
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const route = useRoute<NavigationParams<ScreenNames.Summary>>()
    const [user] = useUserAtom()
    const navigation = useNavigation<StackProps<ScreenNames>>()
    const [, setAddress] = useAddressAtom()
    const [, setToastMessage] = useToastAtom()
    const [newAddress, setNewAddress] = useState<Nullable<Address>>(null)
    const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false)

    const redirect = () => {
        if (route.name.toLowerCase().includes(ScreenNames.Summary.toLowerCase())) {
            const canGoBack = navigation.canGoBack()
            const { screenName } = linkingHelpers.getInternalPathBySupplyId(route.params.supplyId)

            canGoBack
                ? navigation.goBack()
                : navigation.replace(screenName as BookingsScreens, {
                    postalcode: route.params.postalcode,
                    service: String(route.params.supplyId)
                })
        }

        setToastMessage({
            message: T.components.addressChangeConfirmation.successMessage,
            type: NotificationType.Success
        })
    }

    const onConfirm = () => {
        if (newAddress) {
            setAddress(newAddress)
            setConfirmationModalOpen(false)

            return redirect()
        }
    }

    const onCancel = () => {
        form.postcode.resetState()
        setConfirmationModalOpen(false)
    }

    useEffect(() => {
        const getAddressValue = () => {
            try {
                return JSON.parse(form.postcode.value.value) as Address
            } catch {
                return null
            }
        }

        const address = getAddressValue()

        if (!address?._id) {
            return
        }

        const selectedSavedAddress = user.locations.find(location => location._id === address._id)

        if (selectedSavedAddress) {
            setNewAddress(selectedSavedAddress)
        }

        setConfirmationModalOpen(true)
    }, [form.postcode.value])

    return (
        <View
            style={!isNative
                ? {
                    ...styles.container,
                    ...customStyle
                }
                : undefined
            }
        >
            <ConfirmationModal
                onCancel={onCancel}
                onConfirm={onConfirm}
                isLoading={isLoading}
                isOpen={isConfirmationModalOpen}
            />
            <View style={styles.title}>
                <Typography.Regular bold>
                    {title || T.screens.cleaningSummary.serviceLocation}
                </Typography.Regular>
            </View>
            <View>
                <Adapter.AsyncSelectInput
                    {...form.postcode}
                    defaultOptions={user.locations
                        ? user.locations.map(location => ({
                            value: JSON.stringify({
                                _id: location._id,
                                postcode: location.postcode,
                                formatted_address: location.address,
                                district: location.district,
                                location: location.geo,
                                unit: location.unit
                            }),
                            label: `${location.unit}, ${location.address}, ${location.postcode}`
                        }))
                        : []
                    }
                    loadingOptions={fetchPostalCodes}
                    disabled={isLoading}
                />
                <Adapter.TextInputWithLabel
                    {...form.addressLine}
                    disabled={isLoading}
                    testID="address-line"
                />
                <View style={styles.row}>
                    <View style={styles.flexLeft}>
                        <Adapter.TextInputWithLabel
                            {...form.block}
                            label={T.common.block}
                            disabled={isLoading}
                        />
                    </View>
                    <View style={styles.flex}>
                        <Adapter.TextInputWithLabel
                            {...form.unitFloor}
                            smallLabel
                            disabled={isLoading}
                            testID="unit-or-floor"
                        />
                    </View>
                </View>
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        borderWidth: 1,
        borderRadius: 8,
        marginBottom: theme.utils.gap(2),
        paddingHorizontal: theme.utils.gap(2),
        borderColor: theme.components.input.emptyBorderColor
    },
    title: {
        paddingVertical: theme.utils.gap(2)
    },
    row: {
        flexDirection: {
            lg: 'row',
            xs: 'column'
        }
    },
    flexLeft: {
        flex: {
            lg: 1,
            xs: undefined
        },
        paddingRight: {
            lg: theme.utils.gap(1),
            xs: 0
        }
    },
    flex: {
        flex: {
            lg: 1,
            xs: undefined
        }
    }
}))
