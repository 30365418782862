import React, { useState } from 'react'
import { SectionList, View } from 'react-native'
import { Children, Nullable } from 'lib/types'
import { Notification } from 'lib/models'
import { Typography } from 'lib/components'
import { createStyles } from 'lib/styles'
import { useNotificationAtom } from 'lib/atoms'
import { dateHelpers, R } from 'lib/utils'
import { useStyles } from 'lib/hooks'
import { isNative } from 'lib/common'
import { EmptyNotifications, NotificationModal, NotificationTile } from '../components'

type Config = {
    emptyTitle: string,
    emptyMessage: string,
    filterNotifications?(notification: Notification): boolean
}

export const useNotifications = (config: Config) => {
    const { styles } = useStyles(stylesheet)
    const [activeNotification, setActiveNotification] = useState<Nullable<Notification>>(null)
    const { emptyTitle, emptyMessage, filterNotifications } = config
    const [notifications, setNotifications] = useNotificationAtom()
    const hasMessages = filterNotifications
        ? notifications.filter(filterNotifications).length
        : notifications.length

    const renderNotifications = (): Children => {
        switch (true) {
            case !hasMessages:
                return (
                    <EmptyNotifications
                        emptyTitle={emptyTitle}
                        emptyMessage={emptyMessage}
                    />
                )
            default: {
                const messages = filterNotifications
                    ? notifications.filter(filterNotifications)
                    : notifications
                const sections = Object
                    .entries(R.groupBy<Notification>(notification => dateHelpers.notificationDate(notification.date))(messages))
                    .map(([key, value]) => ({
                        title: key,
                        data: value as Array<Notification>
                    }))

                return (
                    <React.Fragment>
                        <SectionList
                            sections={sections}
                            renderItem={({ item }) => (
                                <NotificationTile
                                    {...item}
                                    onPress={() => setActiveNotification(item)}
                                />
                            )}
                            keyExtractor={notification => notification.id}
                            SectionSeparatorComponent={() => (
                                <View style={styles.sectionSeparator} />
                            )}
                            showsVerticalScrollIndicator={false}
                            contentContainerStyle={styles.list}
                            renderSectionHeader={({ section: { title } }) => (
                                <Typography.Regular
                                    bold
                                    style={styles.heading}
                                >
                                    {title}
                                </Typography.Regular>
                            )}
                            ListFooterComponent={() => isNative
                                ? (
                                    <View style={styles.bottomSpacer} />
                                )
                                : null
                            }
                        />
                        { activeNotification ? (
                            <NotificationModal
                                notification={activeNotification}
                                onReadNotification={() => {
                                    if (!activeNotification?.read) {
                                        setNotifications(prevState => prevState
                                            .map(notification => notification.id !== activeNotification?.id
                                                ? notification
                                                : ({
                                                    ...notification,
                                                    read: true
                                                })
                                            )
                                        )
                                    }
                                }}
                                onClose={() => setActiveNotification(null)}
                            />
                        ) : null }
                    </React.Fragment>
                )
            }
        }
    }

    return {
        renderNotifications
    }
}

const stylesheet = createStyles(theme => ({
    list: {
        marginTop: isNative
            ? 0
            : theme.utils.gap(4),
        paddingHorizontal: theme.utils.gap(isNative ? 1 : 3)
    },
    heading: {
        paddingVertical: theme.utils.gap(1),
        backgroundColor: theme.colors.white
    },
    bottomSpacer: {
        height: 300
    },
    sectionSeparator: {
        height: 10
    }
}))
