import { View } from 'react-native'
import React, { useState } from 'react'
import { useStyles } from 'lib/hooks'
import { JobStatus, KeyValuePair } from 'lib/types'
import { createStyles } from 'lib/styles'
import { Job, RecurringJob } from 'lib/models'
import { CalendarHeader } from './CalendarHeader'
import { BookingsCalendarDaysName } from './BookingsCalendarDaysName'
import { BookingsCalendarMonth } from './BookingsCalendarMonth'
import { BookingCalendarModal } from './BookingCalendarModal'

type BookingsCalendarProps = {
    data: KeyValuePair<Array<Job>>
    recurringJobs: Array<RecurringJob>
    jobStatus: JobStatus
}

export const BookingsCalendar: React.FunctionComponent<BookingsCalendarProps> = ({
    data,
    recurringJobs,
    jobStatus
}) => {
    const { styles } = useStyles(stylesheet)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [date, setDate] = useState(new Date().toISOString())
    const [selectedDay, setSelectedDay] = useState<string>('')

    const handleDayPress = (date: string) => {
        setIsModalOpen(true)
        setSelectedDay(date)
    }

    return (
        <View style={styles.container}>
            <BookingCalendarModal
                day={selectedDay}
                isOpen={isModalOpen}
                data={data[selectedDay]}
                recurringJobs={recurringJobs}
                jobStatus={jobStatus}
                onClose={() => setIsModalOpen(false)}
            />
            <View style={styles.monthNameContainer}>
                <CalendarHeader
                    date={date}
                    onChange={setDate}
                    customContainerStyles={styles.monthHeader}
                />
            </View>
            <View style={styles.monthContainer}>
                <BookingsCalendarDaysName mobilePadding={22} />
                <BookingsCalendarMonth
                    date={date}
                    data={data}
                    mobilePadding={22}
                    selectedDay={selectedDay}
                    recurringJobs={recurringJobs}
                    onDayPress={handleDayPress}
                    selectedDate={new Date().toISOString()}
                />
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        paddingTop: theme.utils.gap(1)
    },
    monthHeader: {
        borderBottomWidth: 0
    },
    monthContainer: {
        borderWidth: 1,
        borderColor: theme.colors.silver,
        marginBottom: theme.utils.gap(5)
    },
    monthNameContainer: {
        alignItems: {
            lg: 'center',
            xs: undefined
        }
    }
}))
