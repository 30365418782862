import { TypeOfCancelJob } from '../types'

export const CANCEL_PACKAGE_OPTIONS: Array<TypeOfCancelJob> = [
    {
        value: 'finish_sessions_but_do_not_renew_the_package',
        label: 'Finish sessions but don’t renew the package'
    },{
        value: 'cancel_all_the_remaining_sessions',
        label: 'Cancel all the remaining sessions'
    }
]

/**
 * Init cancel subscription option from response
 * because the response is not have key value, so we need to use same value and label
 *
 * [
 * "Finish sessions but don’t renew the package",
 * "Cancel all the remaining sessions"
 * ]
 */
export const INIT_CANCEL_SUBSCRIPTION_OPTION: TypeOfCancelJob = {
    value: 'Finish sessions but don’t renew the package',
    label: 'Finish sessions but don’t renew the package'
}
