import { useTranslations } from 'lib/hooks'
import { ScreenNames } from 'lib/routing'

export const useCleaningBreadcrumbs = () => {
    const T = useTranslations()

    return [
        {
            text: T.screens.cleaning.breadCrumbs.requirements,
            screenName: ScreenNames.Cleaning
        },
        {
            text: T.screens.cleaning.breadCrumbs.confirmation,
            screenName: ScreenNames.CleaningSummary
        }
    ]
}
