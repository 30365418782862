import React from 'react'
import { View } from 'react-native'
import { Field } from '@codegateinc/react-form-builder-v2'
import { Icons } from 'assets'
import { useStyles } from 'lib/hooks'
import { createStyles, theme } from 'lib/styles'
import { FormComponents, Touchable, Typography } from 'lib/components'
import { NumberInputActions } from 'lib/types'
import { isNative } from 'lib/common'

interface NumberInputProps extends Field<string> {
    disabled?: boolean,
    maxValue?: number,
    minValue?: number,
    step?: number,
    plusStep?: number,
    minusStep?: number,
    maxReached?: boolean,
    onFormatLabel?(value: string): string
}

export const NumberInput: React.FunctionComponent<NumberInputProps> = ({
    step = 1,
    plusStep,
    minusStep,
    label,
    value,
    disabled,
    maxValue,
    minValue = 0,
    errorMessage,
    hasError,
    maxReached,
    onChangeValue,
    onFormatLabel
}) => {
    const { styles } = useStyles(stylesheet)

    const handlePress = (action: NumberInputActions) => {
        const numberValue = Number(value)

        if (maxValue && maxValue <= numberValue) {
            return
        }

        if (action === NumberInputActions.Add) {
            return onChangeValue(String(numberValue + (plusStep ?? step)))
        }

        if (minValue && minValue >= numberValue) {
            return
        }

        if (numberValue - step < 0) {
            return
        }

        onChangeValue(String(numberValue - (minusStep ?? step)))
    }
    const getBorderColor = () => {
        if (errorMessage) {
            return theme.components.input.error.borderColor
        }

        if (Number(value) <= 0) {
            return theme.components.input.emptyBorderColor
        }

        return theme.components.input.filledBorderColor
    }
    const getLabel = () => onFormatLabel
        ? onFormatLabel(value)
        : value || '0'

    return (
        <View style={styles.wrapper}>
            <Typography.Label forceColor={theme.colors.darkGrey}>
                {label}
            </Typography.Label>
            <View
                style={{
                    ...styles.container,
                    borderColor: getBorderColor()
                }}
            >
                <Touchable
                    style={styles.button}
                    onPress={() => handlePress(NumberInputActions.Remove)}
                    disabled={disabled}
                >
                    <Icons.Minus size={8} />
                </Touchable>
                <View style={styles.label}>
                    <Typography.Regular
                        bold
                        forceColor={
                            hasError
                                ? theme.components.input.typography.error
                                : theme.components.input.typography.text
                        }
                    >
                        {getLabel()}
                    </Typography.Regular>
                </View>
                <Touchable
                    style={styles.button}
                    onPress={() => handlePress(NumberInputActions.Add)}
                    disabled={disabled || maxReached}
                >
                    <Icons.Plus size={12} />
                </Touchable>
            </View>
            <FormComponents.ErrorMessage text={errorMessage} />
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        flex: 1,
        userSelect: isNative
            ? undefined
            : 'none'
    },
    container: {
        borderWidth: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: theme.utils.gap(1),
        borderRadius: theme.components.input.borderRadius,
        height: theme.components.input.height
    },
    label: {
        alignItems: 'center',
        justifyContent: 'center'
    },
    button: {
        width: 50,
        alignItems: 'center',
        justifyContent: 'center'
    }
}))
