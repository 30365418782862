import React from 'react'
import { View } from 'react-native'
import { Children, Nullable } from 'lib/types'
import { dateHelpers } from 'lib/utils'
import { createStyles } from 'lib/styles'
import { GetPromotionResponse } from 'features/bookings'
import { usePrice, useStyles, useTranslations } from 'lib/hooks'
import { BookingSummary } from '../BookingSummary'
import { TaskAndErrandsFormShape } from '../../forms'

type TaskAndErrandsSummaryColumnProps = {
    form: TaskAndErrandsFormShape,
    couponText?: string,
    title?: string,
    renderPriceComponent: () => Children,
    renderPriceSummaryComponent?: () => Children,
    renderExtraContent?: () => Children,
    promotionPackage?: Nullable<GetPromotionResponse>,
    price?: Nullable<number>,
    totalPrice?: Nullable<number>,
    originalPrice?: Nullable<number>
}

export const TaskAndErrandsSummaryColumn: React.FunctionComponent<TaskAndErrandsSummaryColumnProps> = ({
    form,
    couponText,
    title,
    renderPriceComponent,
    renderPriceSummaryComponent,
    renderExtraContent,
    promotionPackage,
    totalPrice,
    originalPrice
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const purchasingPrice = usePrice(10)
    const startingDate = form.startingDate
        ? dateHelpers.startingDate(form.startingDate)
        : ''
    const bookingTime = form.bookingTime
        ? ` ${dateHelpers.selectTimeLabel(form.bookingTime)}`
        : ''

    const summaryData = form.purchasing
        ? {
            [T.components.bookingSummary.serviceDateAndTime]: `${startingDate}\n${bookingTime}`,
            [T.components.bookingSummary.hours]: form.hours,
            [T.components.bookingSummary.task]: form.taskType.label,
            [T.components.bookingSummary.purchasing]: purchasingPrice
            // TODO Hide for now
            // [T.components.bookingSummary.vehicle]: form.vehicle
        } : {
            [T.components.bookingSummary.serviceDateAndTime]: `${startingDate}\n${bookingTime}`,
            [T.components.bookingSummary.hours]: form.hours,
            [T.components.bookingSummary.task]: form.taskType.label
            // TODO Hide for now
            // [T.components.bookingSummary.vehicle]: form.vehicle
        }

    return (
        <View style={styles.container}>
            <BookingSummary
                totalPrice={totalPrice}
                originalPrice={originalPrice}
                promotionPackage={promotionPackage}
                title={title}
                numberOfLines={2}
                couponText={couponText}
                renderPriceComponent={renderPriceComponent}
                bookingSummary={summaryData}
                renderPriceSummaryComponent={renderPriceSummaryComponent}
            />
            {renderExtraContent && renderExtraContent()}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        borderWidth: 1,
        borderRadius: 8,
        borderColor: theme.colors.silver,
        paddingTop: theme.utils.gap(2),
        paddingHorizontal: theme.utils.gap(2),
        marginBottom: theme.utils.gap(2),
        backgroundColor: theme.colors.white,
        ...theme.utils.createShadow(2, 0, 8, 4, 4, theme.colors.webShadow(0.04))
    }
}))
