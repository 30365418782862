import React from 'react'
import { View } from 'react-native'
import { useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { Error } from './typography'

type NewBadgeProps = {
    large?: boolean
}

export const NewBadge: React.FunctionComponent<NewBadgeProps> = ({ large }) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const height = large ? 20 : 18

    return (
        <View
            style={{
                ...styles.wrapper,
                height
            }}
        >
            <Error
                bold
                forceColor={theme.colors.white}
                style={{
                    lineHeight: height
                }}
            >
                {T.common.new}
            </Error>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        borderRadius: 4,
        justifyContent: 'center',
        paddingHorizontal: theme.utils.gap(1 / 2),
        marginHorizontal: theme.utils.gap(1),
        backgroundColor: theme.colors.flame
    }
}))
