import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const Select: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path d="m40.002 19.434 8.2 8.2c1.3 1.3 3.4 1.3 4.7 0 1.3-1.3 1.3-3.4 0-4.7l-10.567-10.6a3.32 3.32 0 0 0-4.7 0l-10.566 10.6a3.32 3.32 0 0 0 0 4.7c1.3 1.3 3.4 1.3 4.7 0l8.233-8.2Zm0 41.133-8.2-8.2a3.32 3.32 0 0 0-4.7 0 3.32 3.32 0 0 0 0 4.7l10.567 10.6c1.3 1.3 3.4 1.3 4.7 0l10.566-10.566c1.3-1.3 1.3-3.4 0-4.7a3.32 3.32 0 0 0-4.7 0l-8.233 8.166Z" />
    </Icon>
)
