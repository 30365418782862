import { addDays, startOfWeek } from 'date-fns'
import { useLocaleAtom } from 'lib/atoms'
import { dateHelpers } from 'lib/utils'

export const useLocalizedDates = () => {
    const [locale] = useLocaleAtom()

    const monthName = (date: dateHelpers.AnyDate) => dateHelpers.getMonthName(date, locale)
    const getWeekDayNames = () => {
        const firstDayOfWeek = startOfWeek(new Date(), { weekStartsOn: dateHelpers.WEEK_STARTS_ON })

        return Array.from(Array(7)).map((element, index) => dateHelpers.getWeekDayNames(addDays(firstDayOfWeek, index).toISOString(), locale))
    }
    const getWeekDayShortNames = () => {
        const firstDayOfWeek = startOfWeek(new Date(), { weekStartsOn: dateHelpers.WEEK_STARTS_ON })

        return Array.from(Array(7)).map((element, index) => dateHelpers.getWeekDayShortNames(addDays(firstDayOfWeek, index).toISOString(), locale))
    }
    const getWeekDayFullNames = () => {
        const firstDayOfWeek = startOfWeek(new Date(), { weekStartsOn: dateHelpers.WEEK_STARTS_ON })

        return Array.from(Array(7)).map((element, index) => dateHelpers.getWeekDayFullNames(addDays(firstDayOfWeek, index).toISOString(), locale))
    }
    const getWeekDayLongNames = () => {
        const firstDayOfWeek = startOfWeek(new Date(), { weekStartsOn: dateHelpers.WEEK_STARTS_ON })

        return Array.from(Array(7)).map((element, index) => dateHelpers.getWeekDayLongNames(addDays(firstDayOfWeek, index).toISOString(), locale))
    }

    return {
        monthName,
        getWeekDayFullNames,
        getWeekDayLongNames,
        getWeekDayNames,
        getWeekDayShortNames
    }
}
