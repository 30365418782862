import { View } from 'react-native'
import React from 'react'
import { Icons } from 'assets'
import { useStyles, useTranslations } from 'lib/hooks'
import { useIsAuthorizedAtom } from 'lib/atoms'
import { createStyles, theme } from 'lib/styles'
import { DockedModal, Typography, Touchable, Button } from 'lib/components'

type UserDeleteAccountModalProps = {
    isModalOpen: boolean,
    closeModal: VoidFunction
}

export const UserDeleteAccountModal: React.FunctionComponent<UserDeleteAccountModalProps> = ({
    isModalOpen,
    closeModal
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const [, setIsAuthorized] = useIsAuthorizedAtom()
    const handleDonePress = () => setIsAuthorized(false)

    return (
        <DockedModal
            contentStyles={styles.modalContent}
            childrenWrapper={styles.modalChildrenWrapper}
            onClose={closeModal}
            isOpen={isModalOpen}
            closeButtonStyles={styles.closeButton}
        >
            <View style={styles.modalHeader}>
                <Touchable
                    onPress={closeModal}
                    hitSlop={{ top: 20, right: 20, bottom: 20, left: 20 }}
                >
                    <Icons.Close size={22} />
                </Touchable>
            </View>
            <View style={styles.modalContentWrapper}>
                <Icons.CheckCircle
                    size={48}
                    forceColor={theme.colors.green}
                />
                <Typography.Heading style={styles.modalTitle}>
                    {T.screens.user.deleteConfirmationTitle}
                </Typography.Heading>
                <View style={styles.alertContainer}>
                    <Typography.Heading  style={styles.alertContainerText}>
                        {T.screens.user.deleteConfirmationAlert}
                    </Typography.Heading>
                </View>
                <Typography.Regular style={styles.modalDescription}>
                    {T.screens.user.deleteConfirmationMessage1}
                </Typography.Regular>
                <Typography.Regular style={styles.modalDescription}>
                    {T.screens.user.deleteConfirmationMessage2}
                </Typography.Regular>
                <View style={styles.buttonContainer}>
                    <Button
                        onPress={handleDonePress}
                        text={T.common.done}
                    />
                </View>
            </View>
        </DockedModal>
    )
}

const stylesheet = createStyles(theme => ({
    buttonContainer: {
        display: 'flex',
        width: '100%',
        marginVertical: theme.utils.gap(2)
    },
    alertContainer: {
        marginTop: 20,
        padding: theme.utils.gap(2),
        backgroundColor: theme.colors.linen,
        flexDirection: 'row',
        minHeight: theme.components.alert.minHeight,
        borderRadius: 8
    },
    alertContainerText: {
        color: theme.colors.strawberry,
        fontSize: 16,
        fontWeight: '800',
        lineHeight: 24,
        textAlign: 'center'
    },
    closeButton: {
        display: 'none'
    },
    modalHeader: {
        alignItems: 'flex-end',
        marginRight: theme.utils.gap(2),
        marginTop: theme.utils.gap(3)
    },
    modalContent: {
        position: 'relative',
        backgroundColor: theme.colors.white,
        paddingTop: 0,
        paddingBottom: theme.utils.gap(5)
    },
    modalChildrenWrapper: {
        marginTop: 0,
        paddingTop: 0
    },
    modalTitle: {
        marginTop: 15,
        fontSize: 20,
        lineHeight: 22,
        textAlign: 'center',
        color: theme.colors.night
    },
    modalDescription: {
        marginTop: theme.utils.gap(3),
        color: theme.colors.darkGrey,
        fontSize: 16,
        lineHeight: 24,
        textAlign: 'center'
    },
    modalContentWrapper: {
        paddingTop: 15,
        alignItems: 'center'
    },
    iconContainer: {
        marginBottom: theme.utils.gap(1)
    }
}))
