import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const Users: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M40 7.273a7.273 7.273 0 1 0 0 14.545 7.273 7.273 0 0 0 0-14.545Zm-14.546 7.273C25.454 6.511 31.968 0 40 0s14.545 6.512 14.545 14.546c0 8.033-6.512 14.545-14.545 14.545-8.033 0-14.546-6.512-14.546-14.546Zm-10.909 7.272a3.636 3.636 0 1 0 0 7.273 3.636 3.636 0 0 0 0-7.273ZM3.636 25.454c0-6.024 4.885-10.909 10.91-10.909 6.024 0 10.909 4.885 10.909 10.91s-4.885 10.909-10.91 10.909c-6.024 0-10.909-4.884-10.909-10.91Zm61.819-3.636a3.636 3.636 0 1 0 0 7.273 3.636 3.636 0 0 0 0-7.273Zm-10.91 3.636c0-6.024 4.885-10.909 10.91-10.909s10.909 4.885 10.909 10.91-4.885 10.909-10.91 10.909c-6.024 0-10.908-4.884-10.908-10.91ZM40 40c-6.118 0-11.363 3.779-13.51 9.144a14.495 14.495 0 0 0-1.035 5.401v3.637h29.09v-3.637c0-1.915-.368-3.736-1.035-5.401C51.363 43.779 46.118 40 40 40Zm21.818 18.182h10.91v-3.637a7.273 7.273 0 0 0-7.273-7.272 7.233 7.233 0 0 0-4.412 1.49c.506 1.844.775 3.784.775 5.782v3.637ZM57.922 42.1C53.984 36.438 47.428 32.727 40 32.727c-7.428 0-13.983 3.711-17.922 9.374A14.482 14.482 0 0 0 14.546 40C6.511 40 0 46.512 0 54.545v7.273a3.636 3.636 0 0 0 3.636 3.637h72.728A3.636 3.636 0 0 0 80 61.818v-7.273C80 46.512 73.488 40 65.454 40c-2.757 0-5.335.769-7.532 2.1Zm-38.965 6.662a7.233 7.233 0 0 0-4.412-1.49 7.273 7.273 0 0 0-7.272 7.272v3.637h10.909v-3.637c0-1.998.27-3.938.775-5.782Z"
        />
    </Icon>
)
