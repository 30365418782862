import React from 'react'
import { View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { ScreenNames } from 'lib/routing'
import { createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { Button, SectionBox, Typography } from 'lib/components'
import { Segment } from 'lib/analytics'

type UserAddressEmptyProps = {
    setAddAddress?: VoidFunction
}

export const UserAddressEmpty: React.FunctionComponent<UserAddressEmptyProps> = ({ setAddAddress }) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const navigation = useNavigation()

    return (
        <SectionBox>
            <View style={styles.container}>
                <View style={styles.text}>
                    <Typography.Title bold>
                        {T.screens.user.noAddresses}
                    </Typography.Title>
                </View>
                <Button
                    testID="add-address-button"
                    width={180}
                    noBackground
                    onPress={() => {
                        Segment.addAddressClicked({})

                        if (!setAddAddress) {
                            return navigation.navigate(ScreenNames.UserNewAddress)
                        }

                        setAddAddress()
                    }}
                    text={T.screens.user.addAddress}
                />
            </View>
        </SectionBox>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        alignItems: 'center',
        marginVertical: theme.utils.gap(6)
    },
    text: {
        marginBottom: theme.utils.gap(3)
    }
}))
