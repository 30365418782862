import React, { useState } from 'react'
import { View, Image } from 'react-native'
import { Icons } from 'assets'
import { Nullable } from 'lib/types'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { LoadingIndicator, PhotoGallery, Touchable } from 'lib/components'
import { ChatImage } from '../types'

type ChatImagePreviewProps = {
    images: Array<ChatImage>,
    isVisible: boolean,
    disabled: boolean,
    isLoading: boolean,
    onRemoveImage: (uuid: string) => void
}

export const ChatImagePreview: React.FunctionComponent<ChatImagePreviewProps> = ({
    images,
    disabled,
    isLoading,
    isVisible,
    onRemoveImage
}) => {
    const [openPhotoIndex, setOpenPhotoIndex] = useState<Nullable<number>>(null)
    const { styles } = useStyles(stylesheet)

    return (
        <View>
            {isVisible && (
                <View style={styles.filesContainer}>
                    <LoadingIndicator isLoading={isLoading} />
                    {images.map((image, index) => (
                        <View
                            key={index}
                            style={styles.imageContainer}
                        >
                            <Touchable
                                disabled={disabled}
                                onPress={() => setOpenPhotoIndex(index)}
                            >
                                <View style={styles.file}>
                                    <Touchable
                                        disabled={disabled}
                                        style={styles.removeIcon}
                                        onPress={() => onRemoveImage(image.uuid)}
                                    >
                                        <Icons.RemovePhoto size={20} />
                                    </Touchable>
                                    <Image
                                        source={{
                                            uri: typeof image.file === 'string'
                                                ? image.file
                                                : URL.createObjectURL(image.file)
                                        }}
                                        resizeMode="cover"
                                        style={styles.imagePreview}
                                    />
                                </View>
                            </Touchable>
                        </View>
                    ))}
                </View>
            )}
            <PhotoGallery
                isPreview
                photos={images.map(image => typeof image.file === 'string'
                    ? image.file
                    : URL.createObjectURL(image.file)
                )}
                initialIndex={openPhotoIndex ?? undefined}
                onClose={() => setOpenPhotoIndex(null)}
                isVisible={openPhotoIndex !== null}
            />
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    filesContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        paddingLeft: theme.utils.gap(1),
        paddingBottom: theme.utils.gap(1)
    },
    file: {
        paddingTop: theme.utils.gap(1),
        paddingRight: theme.utils.gap(1)
    },
    removeIcon: {
        position: 'absolute',
        zIndex: theme.zIndex[10],
        top: theme.utils.gap(2),
        right: theme.utils.gap(2)
    },
    imageContainer: {
        flexBasis: {
            xs: '25%',
            md: '20%'
        }
    },
    imagePreview: {
        width: '100%',
        height: {
            xs: 75,
            md: 100
        }
    }
}))
