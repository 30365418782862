import React from 'react'
import { useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { Touchable, Typography } from 'lib/components'

type LaundryPriceDetailsProps = {
    onPress: VoidFunction
}

export const LaundryPriceDetails: React.FunctionComponent<LaundryPriceDetailsProps> = ({
    onPress
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)

    return (
        <Touchable
            style={styles.link}
            onPress={onPress}
            hitSlopBottom={20}
            hitSlopRight={20}
            hitSlopTop={20}
        >
            <Typography.Label forceColor={theme.colors.orange}>
                {T.screens.laundry.viewPrices}
            </Typography.Label>
        </Touchable>
    )
}

const stylesheet = createStyles(theme => ({
    link: {
        borderBottomWidth: 1,
        alignSelf: 'flex-start',
        borderColor: theme.colors.orange
    }
}))
