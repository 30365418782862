import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const Logout: React.FunctionComponent<IconProps> = props => {
    const { forceColor, ...rest } = props

    return (
        <Icon {...rest}>
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.823 4.706a4.72 4.72 0 0 1-4.706 4.706H9.412v56.47h4.705a4.72 4.72 0 0 1 4.706 4.705 4.72 4.72 0 0 1-4.706 4.706H4.706A4.72 4.72 0 0 1 0 70.587V4.706A4.72 4.72 0 0 1 4.706 0h9.411a4.72 4.72 0 0 1 4.706 4.706ZM65.9 16.116l13.242 18.823a4.697 4.697 0 0 1-.085 5.53L64.94 59.291a4.697 4.697 0 0 1-3.77 1.882 4.701 4.701 0 0 1-3.76-7.525l8.475-11.298H28.234a4.705 4.705 0 0 1-4.705-4.706 4.705 4.705 0 0 1 4.705-4.706h37.647c.078 0 .15.022.222.043.06.018.12.035.183.042L58.2 21.527a4.703 4.703 0 0 1 1.143-6.555c2.123-1.5 5.06-.983 6.556 1.144Z"
                fill={forceColor}
            />
        </Icon>
    )
}

