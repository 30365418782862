import { Nullable } from 'lib/types'

export enum ChatMessageType {
    Text = 'text',
    Photo = 'photo',
    GroupChat = 'groupchat',
    System = 'system_text'
}

export type GroupChatMessage = {
    type: ChatMessageType,
    body: string,
    metadata?: Nullable<ChatImageMetadata>
}

export type ChatArchiveRSM = {
    max: number,
    before?: boolean | number
}

export type ChatMessageBody = {
    type: ChatMessageType,
    payload: GroupChatMessage
}

export type ChatArchiveMessage = {
    timestamp: number,
    txt: string,
    kind: ChatMessageType,
    created_at: string,
    room_jid: string,
    msg_sender: string
}

export type ChatArchiveMAM = {
    items: Array<ChatArchiveMessage>,
    rsm: {
        first: string,
        last: string
    }
}

export type ChatImageSize = {
    width: number,
    height: number
}

export type ChatImageMetadata = {
    downloadKey: string,
    imageUrl: string,
    size: ChatImageSize
}
