import React, { useState } from 'react'
import { View } from 'react-native'
import { useNavigation, useRoute } from '@react-navigation/native'
import { AvoidSoftInputView } from 'react-native-avoid-softinput'
import { createStyles } from 'lib/styles'
import { useAddressAtom, useToastAtom, useUserAtom } from 'lib/atoms'
import { useUserNewAddress } from 'features/user'
import { Segment } from 'lib/analytics'
import { isIOS } from 'lib/common'
import { NewAddressRequestShape, NotificationType } from 'lib/types'
import { linkingHelpers } from 'lib/utils'
import { useStyles, useTranslations } from 'lib/hooks'
import { Address } from 'lib/models'
import { BookingsScreens, NavigationParams, ScreenNames, StackProps } from 'lib/routing'
import { Button } from '../Button'
import { BasicModal } from '../BasicModal'
import { Regular, SmallSubheading } from '../typography'
import { NewAddressModalForm } from './NewAddressModalForm'
import { ConfirmationModal } from './ConfirmationModal'

type NewAddressModalProps = {
    isOpen: boolean,
    onClose: VoidFunction,
    onEdit?: VoidFunction,
    modalTitle?: string,
    skipConfirmation?: boolean,
    onSuccess?(address: Address): void,
    onSubmit?(newAddress: NewAddressRequestShape): void
}

export const NewAddressModal: React.FunctionComponent<NewAddressModalProps> = ({
    isOpen,
    onEdit,
    onClose,
    modalTitle,
    skipConfirmation = false,
    onSuccess,
    onSubmit
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const navigation = useNavigation<StackProps<ScreenNames>>()
    const [user] = useUserAtom()
    const [selectedAddress, setSelectedAddress] = useAddressAtom()
    const [, setToastMessage] = useToastAtom()
    const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false)
    const route = useRoute<NavigationParams<ScreenNames.Summary>>()
    const {
        submit,
        isLoading,
        isFilled,
        form: addressForm,
        hasError,
        resetForm,
        isFetchingPostcode,
        fetchPostalCodesWithDebounce
    } = useUserNewAddress({
        postcode: selectedAddress.postcode
            ? undefined
            : route.params?.postalcode,
        onSubmit,
        onSuccess: address => {
            onClose()
            redirect()
            setConfirmationModalOpen(false)

            onSuccess
                ? onSuccess(address)
                : setSelectedAddress(address)
        }
    })

    const onCancel = () => {
        onClose()
        resetForm()
        setConfirmationModalOpen(false)
    }

    const redirect = () => {
        if (onSuccess) {
            return
        }

        setToastMessage({
            message: T.components.addressChangeConfirmation.successMessage,
            type: NotificationType.Success
        })

        if (route.name.toLowerCase().includes(ScreenNames.Summary.toLowerCase())) {
            const canGoBack = navigation.canGoBack()
            const { screenName } = linkingHelpers.getInternalPathBySupplyId(route.params.supplyId)

            canGoBack
                ? navigation.goBack()
                : navigation.replace(screenName as BookingsScreens, {
                    postalcode: route.params.postalcode,
                    service: String(route.params.supplyId)
                })
        }
    }

    return (
        <React.Fragment>
            <ConfirmationModal
                onCancel={onCancel}
                onConfirm={submit}
                isLoading={isLoading}
                isOpen={isConfirmationModalOpen}
            />
            <BasicModal
                isOpen={isOpen}
                onClose={() => {
                    resetForm()
                    onClose()
                }}
                scrollViewProps={{
                    keyboardShouldPersistTaps: 'always',
                    keyboardDismissMode: isIOS ? 'interactive' : 'on-drag'
                }}
            >
                <AvoidSoftInputView style={styles.container}>
                    <SmallSubheading>
                        {modalTitle || T.screens.address.addNew}
                    </SmallSubheading>
                    <View style={styles.formContainer}>
                        <NewAddressModalForm
                            form={addressForm}
                            isLoading={isLoading}
                            fetchPostalCodes={fetchPostalCodesWithDebounce}
                        />
                    </View>
                    <View style={styles.buttons}>
                        <View style={styles.button}>
                            <Button
                                noBackground
                                disabled={isLoading}
                                onPress={() => {
                                    Segment.addressCancelClicked({})

                                    resetForm()
                                    onClose()
                                }}
                                text={T.common.cancel}
                            />
                        </View>
                        <View style={styles.buttonSeparator} />
                        <View style={styles.button}>
                            <Button
                                text={T.common.save}
                                onPress={() => {
                                    Segment.addressSaveClicked({})

                                    if (skipConfirmation) {
                                        return submit()
                                    }

                                    onClose()
                                    setConfirmationModalOpen(true)
                                }}
                                isLoading={isLoading || isFetchingPostcode}
                                disabled={!isFilled || hasError}
                            />
                        </View>
                    </View>
                    {user.locations && user.locations?.length !== 0 && (
                        <View style={styles.createNew}>
                            <Regular>
                                <Regular>
                                    {`${T.common.or.toLowerCase()} `}
                                </Regular>
                                <Regular
                                    onPress={() => {
                                        Segment.bookingSelectSavedAddressClicked({})

                                        resetForm()
                                        onEdit && onEdit()
                                    }}
                                    style={styles.underLine}
                                    forceColor={theme.colors.orange}
                                >
                                    {T.screens.address.selectSavedAddress}
                                </Regular>
                            </Regular>
                        </View>
                    )}
                </AvoidSoftInputView>
            </BasicModal>
        </React.Fragment>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        width: {
            lg: 800,
            xs: undefined
        },
        paddingHorizontal: {
            lg: theme.utils.gap(3),
            xs: 0
        },
        paddingTop: theme.utils.gap(2)
    },
    formContainer: {
        zIndex: theme.zIndex[10]
    },
    buttons: {
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginTop: theme.utils.gap(1),
        marginBottom: theme.utils.gap(3)
    },
    buttonSeparator: {
        width: theme.utils.gap(2)
    },
    button: {
        flex: {
            lg: undefined,
            xs: 1
        },
        width: 120
    },
    underLine: {
        borderBottomWidth: 1,
        borderBottomColor: theme.colors.orange
    },
    createNew: {
        marginVertical: theme.utils.gap(2),
        alignItems: 'center'
    }
}))
