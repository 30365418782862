import React, { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { KeyboardAvoidingView, View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { useInternetConnection, useStyles, useTranslations } from 'lib/hooks'
import { NotificationType } from 'lib/types'
import { createStyles } from 'lib/styles'
import { isIOS, isNative } from 'lib/common'
import { ENV } from 'lib/config'
import { DropZone, useDropZone } from 'lib/components'
import { ScreenNames, StackProps } from 'lib/routing'
import { useToastAtom } from 'lib/atoms'
import { ChatHeader } from './ChatHeader'
import { ChatMessages } from './ChatMessages'
import { ChatFooter } from './ChatFooter'
import { ChatImagePreview } from './ChatImagePreview'
import { GroupedChatMessages, ConnectionStatus, ChatImage } from '../types'
import { useUploadChatPhoto } from '../hooks'

type ChatProps = {
    jobId: string,
    canLoadMore: boolean,
    canReconnect: boolean,
    isLoadingHistory: boolean,
    messages: GroupedChatMessages,
    roomConnectionStatus: ConnectionStatus,
    supplierPhone?: string,
    supplierName?: string,
    onLoadMore: VoidFunction,
    onReconnect: VoidFunction,
    sendTextMessage(message: string): void,
    sendImageMessage(imageUrl: string, message?: string): void
}

export const Chat: React.FunctionComponent<ChatProps> = ({
    jobId,
    messages,
    onLoadMore,
    canLoadMore,
    supplierPhone,
    canReconnect,
    supplierName,
    onReconnect,
    sendImageMessage,
    sendTextMessage,
    isLoadingHistory,
    roomConnectionStatus
}) => {
    const T = useTranslations()
    const navigation = useNavigation<StackProps<ScreenNames>>()
    const { styles } = useStyles(stylesheet)
    const [, setToastMessage] = useToastAtom()
    const { isUploadingImage, onUpload } = useUploadChatPhoto()
    const [imagesPreview, setImagesPreview] = useState<Array<ChatImage>>([])
    const { isOnline } = useInternetConnection()
    const { rootProps, isDragActive } = useDropZone({
        noClick: true,
        disabled: isNative,
        multiple: false,
        maxSize: Number(ENV.MAX_IMAGE_UPLOAD_SIZE_MB) * 1024 * 1024,
        accept: {
            'image/*': []
        },
        onDropRejected: () => {
            setToastMessage({
                type: NotificationType.Error,
                message: T.screens.chat.imageUploadSizeError
            })
        },
        onDropAccepted: files => setImagesPreview(prevState => [
            ...prevState,
            {
                uuid: uuidv4(),
                file: files.at(0) as File
            }
        ])
    })

    const handleSubmit = async (message: string) => {
        try {
            if (imagesPreview.length === 0) {
                return sendTextMessage(message)
            }

            const uploadedImages = await Promise.all(imagesPreview.map(image => onUpload(image.file)))

            uploadedImages.forEach((uploadedImage, index) => {
                const isLast = index === uploadedImages.length - 1

                sendImageMessage(uploadedImage, isLast ? message : undefined)
            })
            setImagesPreview([])
        } catch {
            setToastMessage({
                type: NotificationType.Error,
                message: T.screens.chat.imageUploadError
            })
        }
    }

    const chatContent = (
        <KeyboardAvoidingView
            style={styles.container}
            keyboardVerticalOffset={160}
            behavior={isIOS
                ? 'padding'
                : 'height'
            }
        >
            <ChatMessages
                isOnline={isOnline}
                messages={messages}
                canReconnect={canReconnect}
                onLoadMore={onLoadMore}
                canLoadMore={canLoadMore}
                isDragActive={isDragActive}
                onReconnect={onReconnect}
                isLoadingHistory={isLoadingHistory}
                roomConnectionStatus={roomConnectionStatus}
            />
            <View style={styles.chatMessageWrapper}>
                <ChatImagePreview
                    isLoading={isUploadingImage}
                    disabled={isUploadingImage}
                    images={imagesPreview}
                    onRemoveImage={uuid => setImagesPreview(prevState => prevState.filter(image => image.uuid !== uuid))}
                    isVisible={imagesPreview.length !== 0}
                />
                <ChatFooter
                    maxPhotosReached={imagesPreview.length > 9}
                    isLoading={isUploadingImage}
                    isOnline={isOnline}
                    isDragActive={isDragActive}
                    sendMessage={handleSubmit}
                    havePhotoAdded={imagesPreview.length !== 0}
                    onSelectImage={file => setImagesPreview(prevState => [
                        ...prevState,
                        {
                            uuid: uuidv4(),
                            file
                        }
                    ])}
                    isLoadingHistory={isLoadingHistory}
                    roomConnectionStatus={roomConnectionStatus}
                />
            </View>
        </KeyboardAvoidingView>
    )

    const handleBackClick = () => {
        navigation.canGoBack()
            ? navigation.goBack()
            : navigation.navigate(ScreenNames.Bookings)
    }

    return (
        <View style={styles.container}>
            <ChatHeader
                jobId={jobId}
                supplierName={supplierName}
                supplierPhone={supplierPhone}
                onGoBack={handleBackClick}
                roomConnectionStatus={roomConnectionStatus}
            />
            <View style={styles.container}>
                {isNative
                    ? chatContent
                    : (
                        <DropZone
                            rootProps={rootProps}
                            style={styles.dropZone}
                        >
                            {chatContent}
                        </DropZone>
                    )}
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        flex: 1,
        backgroundColor: theme.colors.marble
    },
    dropZone: {
        display: 'contents'
    },
    chatMessageWrapper: {
        backgroundColor: theme.colors.white,
        borderTopLeftRadius: 12,
        borderTopRightRadius: 12
    }
}))
