import { useNavigation } from '@react-navigation/native'
import { AnalyticsEvent, AnalyticsEventDescription, GoogleAnalytics, Segment, segmentUtils } from 'lib/analytics'
import { ScreenNames } from 'lib/routing'
import { Job, RecurringJob } from 'lib/models'
import { JobStatus, NotificationType, SupplyID } from 'lib/types'
import { linkingHelpers } from 'lib/utils'
import { useTranslations } from 'lib/hooks'
import { APP_CONFIG } from 'lib/config'
import { useIsAuthorizedAtom, useToastAtom } from 'lib/atoms'

export const useJobDetailsRoute = (job: Job, jobStatus: JobStatus, recurringJobs: Array<RecurringJob>, isSession = true) => {
    const T = useTranslations()
    const navigation = useNavigation()
    const [, setToastMessage] = useToastAtom()
    const [isAuthorized] = useIsAuthorizedAtom()
    const recurringJob = recurringJobs.find(item => item._id === job.recurring_job)
    const jobId = jobStatus === JobStatus.Submitted
        ? job.recurring_job || job._id
        : job._id

    return () => {
        GoogleAnalytics.logEvent({
            eventType: AnalyticsEvent.UserAction,
            description: AnalyticsEventDescription.JobClicked,
            value: job.supply.id.toString()
        })
        Segment.jobClicked({
            jobId: job.job_id,
            jobStatus: segmentUtils.getReadableJobStatus(jobStatus),
            serviceType: segmentUtils.supplyIdToServiceType(job.supply.id)
        })

        switch (job.supply.id) {
            case SupplyID.Cleaner:
                return navigation.navigate(ScreenNames.CleaningDetails, {
                    jobId,
                    recurringJob,
                    jobStatus,
                    isSession
                })
            case SupplyID.DeepCleaning:
                return navigation.navigate(ScreenNames.DeepCleaningDetails, {
                    jobId,
                    jobStatus
                })
            case SupplyID.ACService:
                return navigation.navigate(ScreenNames.AirConditionDetails, {
                    jobId,
                    jobStatus
                })
            // eslint-disable-next-line no-fallthrough
            case SupplyID.Laundry:
                if (APP_CONFIG.SERVICES.IS_LAUNDRY_ENABLED) {
                    return navigation.navigate(ScreenNames.LaundryDetails, {
                        jobId,
                        jobStatus
                    })
                }
            // eslint-disable-next-line no-fallthrough
            case SupplyID.Handyman:
                if (APP_CONFIG.SERVICES.IS_HANDYMAN_ENABLED) {
                    return navigation.navigate(ScreenNames.HandymanDetails, {
                        jobId,
                        jobStatus
                    })
                }
            // eslint-disable-next-line no-fallthrough
            case SupplyID.Cooking:
                if (APP_CONFIG.SERVICES.IS_COOKING_ENABLED) {
                    return navigation.navigate(ScreenNames.CookingDetails, {
                        jobId,
                        recurringJob,
                        jobStatus,
                        isSession
                    })
                }
            // eslint-disable-next-line no-fallthrough
            case SupplyID.PestControl:
                if (APP_CONFIG.SERVICES.IS_PEST_CONTROL_ENABLED) {
                    return navigation.navigate(ScreenNames.PestControlDetails, {
                        jobId,
                        jobStatus
                    })
                }
            // eslint-disable-next-line no-fallthrough
            case SupplyID.TasksErrands:
                if (APP_CONFIG.SERVICES.IS_TASK_ERRANDS_ENABLED) {
                    return navigation.navigate(ScreenNames.TaskAndErrandsDetails, {
                        jobId,
                        jobStatus
                    })
                }
            // eslint-disable-next-line no-fallthrough
            case SupplyID.MoversPackers:
                if (APP_CONFIG.SERVICES.IS_MOVERS_AND_PACKERS_ENABLED) {
                    return navigation.navigate(ScreenNames.MoversAndPackersDetails, {
                        jobId,
                        jobStatus
                    })
                }
            // eslint-disable-next-line no-fallthrough
            default:
                return linkingHelpers.getRedirectUrlFromBookingLink(
                    isAuthorized,
                    () => {
                        setToastMessage({
                            message: T.linking.unsupportedServiceError,
                            type: NotificationType.Info
                        })
                    },
                    `/bookings?service=${job.supply.id}`
                )
        }
    }
}
