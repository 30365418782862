import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const Plus: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path d="M75 35H45V5c0-2.765-2.24-5-5-5s-5 2.235-5 5v30H5c-2.76 0-5 2.235-5 5s2.24 5 5 5h30v30c0 2.765 2.24 5 5 5s5-2.235 5-5V45h30c2.76 0 5-2.235 5-5s-2.24-5-5-5Z" />
    </Icon>
)
