import { useEffect, useState } from 'react'
import { Keyboard, KeyboardEvent } from 'react-native'

export const useKeyboardHeight = () => {
    const [keyboardHeight, setKeyboardHeight] = useState(0)

    useEffect(() => {
        const onKeyboardDidShow = (e: KeyboardEvent) => {
            setKeyboardHeight(e.endCoordinates.height)
        }

        const onKeyboardDidHide = () => {
            setKeyboardHeight(0)
        }

        const showSubscription = Keyboard.addListener('keyboardDidShow', onKeyboardDidShow)
        const hideSubscription = Keyboard.addListener('keyboardDidHide', onKeyboardDidHide)

        return () => {
            showSubscription.remove()
            hideSubscription.remove()
        }
    }, [])

    return keyboardHeight
}
