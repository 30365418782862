import { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { MutateOptions } from '@tanstack/query-core'
import { useNavigation } from '@react-navigation/native'
import { R } from 'lib/utils'
import { Address, PaymentType } from 'lib/models'
import { DetailsScreensNames, ScreenNames, StackProps } from 'lib/routing'
import { isNative } from 'lib/common'
import { useQueryHelpers, useSegmentSession } from 'lib/hooks'
import { authActions } from 'features/auth'
import { useUserNewAddress } from 'features/user'
import { useAddNewCreditCard } from 'features/payments'
import { NotificationType, Nullable, SupplyID, Response, ErrorResponse } from 'lib/types'
import {
    useAddressAtom,
    useCrossSaleBookingRecommendationsAtom,
    useResetCrossSaleBookingRecommendationsAtom,
    useResetFormAtom,
    useToastAtom,
    useUserAtom,
    useUserTokenAtom
} from 'lib/atoms'
import { createPestControlJob } from '../actions'
import { CreatePestControlJobRequest, CreateJobResponse } from '../types'

export const usePestControlCreateJob = (submit: VoidFunction, detailsScreenName: DetailsScreensNames, postalCode?: string, totalCost?: number) => {
    const [token] = useUserTokenAtom()
    const [user, setUser] = useUserAtom()
    const [, setCrossSaleBookingRecommendations] = useCrossSaleBookingRecommendationsAtom()
    const [, setResetCrossSaleBookingRecommendations] = useResetCrossSaleBookingRecommendationsAtom()
    const { clearSession } = useSegmentSession()
    const navigation = useNavigation<StackProps<ScreenNames>>()
    const [, setToastMessage] = useToastAtom()
    const [, setResetForm] = useResetFormAtom()
    const { onRequestError } = useQueryHelpers()
    const [address, setSavedAddress] = useAddressAtom()
    const [paymentMethod, setPaymentMethod] = useState<PaymentType>(PaymentType.Card)
    const [defaultCard] = user.payment_providers
        ? user.payment_providers.filter(item => item.default)
        : []
    const jobUniqueId = `${uuidv4()}-${new Date().getTime()}`
    const { isLoading: isCreatingJob, mutate } = createPestControlJob()
    const { mobileSubmit, isLoading: isLoadingNewCard } = useAddNewCreditCard(R.T)
    const [isNewCardModalOpen, setIsNewCardModalOpen] = useState(false)
    const [addressCreated, setAddressCreated] = useState<Nullable<Address>>(null)
    const { mutate: getMe, isLoading: isLoadingUserProfile } = authActions.useGetMe()
    const {
        submit: submitAddressForm,
        isLoading: isLoadingAddressForm,
        isFilled,
        form: addressForm,
        fetchPostalCodesWithDebounce,
        hasError
    } = useUserNewAddress({
        postcode: address.postcode || postalCode,
        onSuccess: setAddressCreated
    })
    const onCreateJob = (supplyId: SupplyID, data: CreatePestControlJobRequest, onSuccess: VoidFunction) => {

        const mutateOptions: MutateOptions<Response<CreateJobResponse>, ErrorResponse, CreatePestControlJobRequest> = {
            onSuccess: response => {
                getMe(
                    {
                        token
                    },
                    {
                        onSuccess: ({ data: { profile } }) => {
                            onSuccess()
                            clearSession()

                            setUser(profile)
                            setResetForm(true)
                            setSavedAddress({} as Address)

                            if (isNative) {
                                setToastMessage({
                                    isModalOnly: true,
                                    message: response.data.popup.success.message,
                                    type: NotificationType.Success
                                })

                                return navigation.navigate(ScreenNames.Bookings, {
                                    screenName: detailsScreenName,
                                    bookingId: response.data.jobIdName,
                                    jobId: response.data.jobId
                                })
                            }

                            navigation.replace(ScreenNames.BookingSuccess, {
                                bookingId: response.data.jobIdName
                            })
                            setToastMessage({
                                message: response.data.popup.success.message,
                                type: NotificationType.Success
                            })
                        },
                        onError: onRequestError
                    }
                )

                const crossSaleBookingRequestRecommendations = response?.data?.crossaleBookingRequestRecommendations || []
                setCrossSaleBookingRecommendations(crossSaleBookingRequestRecommendations)
            },
            onError: onRequestError
        }
        setResetCrossSaleBookingRecommendations()
        mutate(data, mutateOptions)
    }
    const handleSubmit = () => {
        if (paymentMethod === PaymentType.Card && !defaultCard) {
            return isNative
                ? mobileSubmit()
                : setIsNewCardModalOpen(true)
        }

        if (!address.postcode) {
            return submitAddressForm()
        }

        submit()
    }

    // This is so we need first create new address on API and then submit new job with that address
    useEffect(() => {
        if (addressCreated) {
            submit()
        }
    }, [addressCreated])

    return {
        jobUniqueId,
        onCreateJob,
        handleSubmit,
        setPaymentMethod,
        addressForm,
        fetchPostalCodes: fetchPostalCodesWithDebounce,
        isNewCardModalOpen,
        setIsNewCardModalOpen,
        addressCreated,
        paymentMethod,
        isLoadingAddressForm,
        isDisabled: (!address.postcode) && (!isFilled || hasError),
        isLoading: isCreatingJob || isLoadingUserProfile || isLoadingNewCard
    }
}
