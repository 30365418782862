import React, { useState } from 'react'
import { Breakpoint } from 'lib/styles'
import { Segment } from 'lib/analytics'
import { BasicModal, TextButton, Grid } from 'lib/components'
import { useIsWithinBreakpoints, useTranslations } from 'lib/hooks'
import { MoversAndPackersPackagePricingContent } from './MoversAndPackersPackagePricingContent'

export const MoverAndPackersPackagePricing: React.FunctionComponent = () => {
    const T = useTranslations()
    const [isModalOpen, setIsModalOpen] = useState(false)
    const isMobile = useIsWithinBreakpoints(Breakpoint.XS, Breakpoint.MD)

    return (
        <Grid.Gap gapBottom={2}>
            <BasicModal
                insetsPadding
                isFullWindowSize={isMobile}
                isOpen={isModalOpen}
                onClose={() => {
                    Segment.bookingPriceReferenceClosed({})

                    setIsModalOpen(false)
                }}
            >
                <MoversAndPackersPackagePricingContent />
            </BasicModal>
            <TextButton
                onPress={() => {
                    Segment.bookingPriceReferenceClicked({})

                    setIsModalOpen(true)
                }}
                text={T.components.moversAndPackersPackagePrice.viewButton}
            />
        </Grid.Gap>
    )
}
