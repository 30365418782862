import React, { useState } from 'react'
import { View } from 'react-native'
import { useForm } from '@codegateinc/react-form-builder-v2'
import { LinearGradient } from 'expo-linear-gradient'
import { Icons } from 'assets'
import { Nullable } from 'lib/types'
import { useUserAtom } from 'lib/atoms'
import { CreditPackage } from 'lib/models'
import { Breakpoint, createStyles } from 'lib/styles'
import { useIsWithinBreakpoints, usePrice, useStyles, useTranslations } from 'lib/hooks'
import { SendPayPaymentErrorModal, SendPayPaymentModal } from 'features/sendPay'
import { Adapter, Button, ModalWithMobileOverlay, SeparatorWithBorder, Touchable, Typography } from 'lib/components'
import { SendPayCreditsFormShape, useSendPayCredits } from '../forms'

type SendPayModalProps = {
    isOpen: boolean,
    onClose: VoidFunction
}

export const SendPayModal: React.FunctionComponent<SendPayModalProps> = ({
    isOpen,
    onClose
}) => {
    const T = useTranslations()
    const [user] = useUserAtom()
    const isMobile = useIsWithinBreakpoints(Breakpoint.XS, Breakpoint.MD)
    const { styles, theme } = useStyles(stylesheet)
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false)
    const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false)
    const [selectedPackage, setSelectedPackage] = useState<Nullable<CreditPackage>>(null)
    const creditsBalance = usePrice(user.consumer ? user.consumer.balance_credit : '')
    const { form, submit, hasError, isFilled } = useForm<SendPayCreditsFormShape>(useSendPayCredits(), {
        onSuccess: () => {
            onClose()
            setIsPaymentModalOpen(true)
        }
    })

    const onPaymentError = () => {
        setIsErrorModalOpen(true)
        setIsPaymentModalOpen(false)
    }

    return (
        <React.Fragment>
            <SendPayPaymentErrorModal
                isOpen={isErrorModalOpen}
                onClose={() => setIsErrorModalOpen(false)}
                onConfirm={() => setIsErrorModalOpen(false)}
            />
            <SendPayPaymentModal
                onSuccess={() => setIsPaymentModalOpen(false)}
                onError={onPaymentError}
                creditPackage={selectedPackage}
                amount={selectedPackage?.price || form.amount.value}
                isOpen={isPaymentModalOpen || Boolean(selectedPackage)}
                onClose={() => {
                    setSelectedPackage(null)
                    setIsPaymentModalOpen(false)
                }}
            />
            <ModalWithMobileOverlay
                isOpen={isOpen}
                onClose={onClose}
            >
                <View style={styles.container}>
                    <View style={styles.content}>
                        {isMobile
                            ? (
                                <Touchable
                                    style={styles.backButtonWrapper}
                                    onPress={onClose}
                                >
                                    <View
                                        style={{
                                            ...styles.backButton,
                                            transform: [
                                                {
                                                    rotateZ: '90deg'
                                                }
                                            ]
                                        }}
                                    >
                                        <Icons.Chevron size={10} />
                                    </View>
                                    <Typography.Subheading>
                                        {T.components.sendPayModal.title}
                                    </Typography.Subheading>
                                </Touchable>
                            ) : (
                                <Typography.Subheading>
                                    {T.components.sendPayModal.title}
                                </Typography.Subheading>
                            )
                        }
                        <View style={styles.messageContainer}>
                            <Typography.Regular forceColor={theme.colors.darkGrey}>
                                {T.components.sendPayModal.formFields.amount.label}
                            </Typography.Regular>
                        </View>
                        <View style={styles.headerContainer}>
                            <View style={styles.flex}>
                                <View style={styles.amountInputContainer}>
                                    <View style={styles.flex}>
                                        <Adapter.TextInput {...form.amount} />
                                    </View>
                                    <View style={styles.button}>
                                        <Button
                                            width={isMobile
                                                ? 100
                                                : 200
                                            }
                                            onPress={submit}
                                            text={T.common.purchase}
                                            disabled={hasError || !isFilled}
                                        />
                                    </View>
                                </View>
                            </View>
                            <LinearGradient
                                style={styles.balanceContainer}
                                end={{ x: 0.5, y: 0.5 }}
                                colors={[theme.colors.orange, theme.colors.sun]}
                            >
                                <Typography.Label forceColor={theme.colors.white}>
                                    {`${T.screens.sendPay.balance} `}
                                </Typography.Label>
                                <Typography.Title
                                    bold
                                    forceColor={theme.colors.white}
                                >
                                    {creditsBalance}
                                </Typography.Title>
                            </LinearGradient>
                        </View>
                        <SeparatorWithBorder text={T.common.or} />
                        <Typography.SmallSubheading>
                            {T.components.sendPayModal.purchaseCredits}
                        </Typography.SmallSubheading>
                        <View style={styles.messageContainer}>
                            <Typography.Regular forceColor={theme.colors.darkGrey}>
                                {T.components.sendPayModal.message}
                            </Typography.Regular>
                        </View>
                        <Adapter.CreditsPackage
                            {...form.amount}
                            onPurchase={value => {
                                onClose()
                                setSelectedPackage(value)
                            }}
                        />
                    </View>
                </View>
            </ModalWithMobileOverlay>
        </React.Fragment>
    )
}

const stylesheet = createStyles(theme => ({
    flex: {
        flex: 1
    },
    container: {
        flex: 1,
        paddingHorizontal: {
            lg: theme.utils.gap(3),
            xs: 0
        },
        paddingVertical: {
            lg: theme.utils.gap(2),
            xs: 0
        }
    },
    messageContainer: {
        marginVertical: theme.utils.gap(1)
    },
    buttonsContainer: {
        flexDirection: 'row',
        paddingVertical: {
            lg: 0,
            xs: theme.utils.gap(2)
        },
        paddingHorizontal: {
            lg: 0,
            xs: theme.utils.gap(2)
        },
        backgroundColor: theme.colors.white
    },
    spacer: {
        width: theme.utils.gap(2)
    },
    amountInput: {
        marginVertical: theme.utils.gap(1)
    },
    buttonsContainerShadow: {
        ...theme.utils.createShadow(0, 0.8, 20, 70)
    },
    content: {
        flex: 1,
        paddingVertical: {
            lg: 0,
            xs: theme.utils.gap(3)
        },
        paddingHorizontal: {
            lg: 0,
            xs: theme.utils.gap(2)
        }
    },
    backButtonWrapper: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: theme.utils.gap(2)
    },
    backButton: {
        width: 32,
        height: 32,
        paddingTop: 12,
        marginRight: theme.utils.gap(1),
        alignItems: 'center',
        borderRadius: 4,
        borderWidth: 1,
        borderColor: theme.colors.silver
    },
    amountInputContainer: {
        flexDirection: 'row'
    },
    balanceContainer: {
        borderRadius: 8,
        minWidth: 250,
        paddingVertical: theme.utils.gap(2),
        paddingHorizontal: theme.utils.gap(2),
        marginTop: {
            lg: -theme.utils.gap(4),
            xs: 0
        }
    },
    button: {
        marginHorizontal: theme.utils.gap(1)
    },
    headerContainer: {
        flexDirection: {
            lg: 'row',
            xs: 'column'
        },
        alignItems: {
            lg: 'center',
            xs: undefined
        }
    }
}))
