import React from 'react'
import { useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { OTPVerifyCode } from 'lib/firebase'
import { AnimatedTabs } from 'lib/components'
import { SignInTab } from './SignInTab'
import { SignUpTab } from './SignUpTab'
import { AuthUser } from '../types'

type AuthTabsProps = {
    initialIndex?: number,
    resetPasswordSuccessMessage?: boolean,
    showGuestBooking?: boolean,
    setShowGuestBookingForm?: (value: boolean) => void,
    onAccountConfirmed(authUser: AuthUser, verifyCode: OTPVerifyCode): void
}

export const AuthTabs: React.FunctionComponent<AuthTabsProps> = ({
    initialIndex = 0,
    onAccountConfirmed,
    resetPasswordSuccessMessage,
    showGuestBooking,
    setShowGuestBookingForm
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)

    const tabs = [
        { text: T.common.logIn },
        { text: T.common.signUp }
    ]

    return (
        <AnimatedTabs
            tabs={tabs}
            initialIndex={initialIndex}
            activeLabelStyles={styles.activeLabel}
            inactiveLabelStyles={styles.inactiveLabel}
        >
            {(windowWidth, activeTabIndex, onGoTo) => (
                <React.Fragment>
                    <SignInTab
                        index={0}
                        tabWidth={windowWidth}
                        activeIndex={activeTabIndex}
                        onFocus={() => onGoTo(0)}
                        resetPasswordSuccessMessage={resetPasswordSuccessMessage}
                        showGuestBooking={showGuestBooking}
                        setShowGuestBookingForm={setShowGuestBookingForm}
                    />
                    <SignUpTab
                        index={1}
                        tabWidth={windowWidth}
                        activeIndex={activeTabIndex}
                        onAccountConfirmed={onAccountConfirmed}
                        onFocus={() => onGoTo(1)}
                        showGuestBooking={showGuestBooking}
                        setShowGuestBookingForm={setShowGuestBookingForm}
                    />
                </React.Fragment>
            )}
        </AnimatedTabs>
    )
}

const stylesheet = createStyles(theme => ({
    activeLabel: {
        fontSize: 20,
        lineHeight: 30,
        fontWeight: '700',
        color: theme.colors.orange
    },
    inactiveLabel: {
        fontSize: 20,
        lineHeight: 30,
        fontWeight: '500',
        color: theme.colors.fog
    }
}))
