import { useTranslations } from 'lib/hooks'
import { JobStatus, LaundryStatus } from 'lib/types'

export const useLaundryMilestones = () => {
    const T = useTranslations()

    return [
        {
            label: T.components.laundryMilestones.accepted,
            value: LaundryStatus.Created + JobStatus.Accepted
        },
        {
            label: T.components.laundryMilestones.collected,
            value: LaundryStatus.PickedUp + JobStatus.Accepted
        },
        {
            label: T.components.laundryMilestones.ready,
            // updated due to the gap in values between picked up and ready statuses
            value: LaundryStatus.Ready + JobStatus.Accepted - 1
        },
        {
            label: T.components.laundryMilestones.delivered,
            // updated due to the gap in values between picked up and ready statuses
            value: LaundryStatus.Delivered + JobStatus.Accepted - 1
        }
    ]
}
