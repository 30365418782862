import { useTranslations } from 'lib/hooks'

export const usePetsOptions = () => {
    const T = useTranslations()

    return [
        // TODO Currently not supported
        // {
        //     value: T.components.petsOptions.dog,
        //     label: T.components.petsOptions.dog
        // },
        // {
        //     value: T.components.petsOptions.cat,
        //     label: T.components.petsOptions.cat
        // },
        {
            value: T.components.petsOptions.yes,
            label: T.components.petsOptions.yes
        },
        {
            value: '',
            label: T.components.petsOptions.no
        }
    ]
}
