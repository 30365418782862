import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const Edit: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path d="M70.346 10.154a4.21 4.21 0 0 0-5.955 0L29.474 45.072v5.954h5.954L70.346 16.11a4.21 4.21 0 0 0 0-5.955ZM58.436 4.2C63.37-.733 71.368-.733 76.3 4.2c4.933 4.933 4.933 12.93 0 17.864l-36.15 36.15a4.21 4.21 0 0 1-2.978 1.233H25.263a4.21 4.21 0 0 1-4.21-4.21v-11.91a4.21 4.21 0 0 1 1.233-2.977L58.436 4.2ZM12.633 17.342a4.21 4.21 0 0 0-4.211 4.21v46.316a4.21 4.21 0 0 0 4.21 4.211h46.316a4.21 4.21 0 0 0 4.21-4.21V46.815a4.21 4.21 0 1 1 8.422 0v21.052c0 6.977-5.655 12.632-12.632 12.632H12.632C5.655 80.5 0 74.845 0 67.868V21.553C0 14.576 5.655 8.92 12.632 8.92h21.052a4.21 4.21 0 0 1 0 8.421H12.632Z" />
    </Icon>
)
