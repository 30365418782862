import { RescheduleFormShape } from 'features/jobs/forms'
import { JobDetailsResponse } from 'features/jobs/types'
import { Languages } from 'lib/locale'
import { JobCancelReason } from 'lib/models'
import { dateHelpers, jobHelpers } from 'lib/utils'

export const getFinishSessionAndDontRenewPackageMessage = (jobDetail: JobDetailsResponse, cancelReason: JobCancelReason, customReason?: string): string => `
- Job id: Package: ${jobDetail.job.job_id}
- Request: Want to finish sessions but don’t renew the package with reason: ${cancelReason?.reason ?? customReason}\n`

export const getCancelRequestMessage = (jobDetail: JobDetailsResponse, cancelReason: JobCancelReason, customReason?: string): string => `
- Job id: ${jobHelpers.isAccepted(jobDetail) ? 'Accept' : 'Session'} job: ${jobDetail?.job?.job_id}
- Request: Want to request cancel with reason: ${cancelReason?.reason ?? customReason}\n`

export const getScheduleRequestMessage = (jobDetail: JobDetailsResponse, rescheduleForm: RescheduleFormShape, locale: Languages): string => {
    if (jobHelpers.isSessionJob(jobDetail) || (jobHelpers.isAccepted(jobDetail) && !jobHelpers.isJobRecurring(jobDetail))) {
        return getScheduleRequestMessageOfAdHocJob(jobDetail, rescheduleForm, locale)
    }

    return getScheduleRequestMessageOfRecurringJob(jobDetail, rescheduleForm, locale)
}

const getScheduleRequestMessageOfAdHocJob = (jobDetail: JobDetailsResponse, rescheduleForm: RescheduleFormShape, locale: Languages): string => `
        - Job id: Session job: ${jobDetail.job.job_id}
        - Request: Want to reschedule to:
            - Original Date: ${dateHelpers.getFullBookingDateWithTimeZone(jobDetail.job.date_time, locale)}
            - New Date: ${dateHelpers.getFullBookingDateWithTimeZone(rescheduleForm.bookingTime, locale)}\n`

const getScheduleRequestMessageOfRecurringJob = (jobDetail: JobDetailsResponse, rescheduleForm: RescheduleFormShape, locale: Languages): string => {
    const adjustedSelectBookingDays =
        dateHelpers.adjustBookingDates(rescheduleForm.bookingDays, rescheduleForm.startingDate, jobDetail.job.frequency, locale)
    const startBookingDate =
                jobHelpers.getRecurringStartDate(rescheduleForm.startingDate, rescheduleForm.bookingDays, locale)
    const selectBookingDays = Object.keys(adjustedSelectBookingDays)

    return `
    - Job id: Recurring job: ${jobDetail.job.job_id}
    - Request: Want to reschedule to:
        - Original Date: ${dateHelpers.getFullBookingDateWithTimeZone(jobDetail.job.beginning_date, locale)}
        - Original Booking Days:${getOriginalBookingDays(jobDetail, locale)}
        - New Date: ${startBookingDate} (${dateHelpers.getTimeZone()})
        - Booking Days:${selectBookingDays.map(keyDay => `
            - ${dateHelpers.getFullBookingDateWithTimeZone(adjustedSelectBookingDays[keyDay] as string, locale)}`).join('')}\n`
}

const getOriginalBookingDays = (jobDetail: JobDetailsResponse, locale: Languages): string =>
    jobDetail?.job?.schedule?.filter(el => el.activated)
        .map(scheduleItem => `
            - ${dateHelpers.getFullBookingDateWithTimeZone(scheduleItem.start_time, locale)}`).join('') || ''

