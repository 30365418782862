import { useTranslations } from 'lib/hooks'
import { discountHelpers } from 'lib/utils'
import { BookingFrequency } from 'lib/types'

type FrequencyOptions = {
    pricePerHour: number,
    recurringPricePerHour: number
}

const frequencyOptionsWithoutPrice: FrequencyOptions = {
    pricePerHour: 0,
    recurringPricePerHour: 0
}

export const useFrequencyOptions = (config: FrequencyOptions = frequencyOptionsWithoutPrice) => {
    const T = useTranslations()
    const { pricePerHour, recurringPricePerHour } = config

    return [
        {
            price: pricePerHour,
            value: BookingFrequency.OneTime,
            label: T.components.frequency.oneTime.title,
            title: T.components.frequency.oneTime.title,
            description: T.components.frequency.oneTime.description,
            list: [
                T.components.frequency.options.basicCleaning
            ]
        },
        {
            price: recurringPricePerHour,
            discount: discountHelpers.discountPercentage(recurringPricePerHour, pricePerHour),
            value: BookingFrequency.Weekly,
            label: T.components.frequency.weekly.title,
            title: T.components.frequency.weekly.title,
            description: T.components.frequency.weekly.description,
            list: [
                T.components.frequency.options.saveTime,
                T.components.frequency.options.sameCleaner
            ]
        },
        {
            price: recurringPricePerHour,
            discount: discountHelpers.discountPercentage(recurringPricePerHour, pricePerHour),
            value: BookingFrequency.Fortnightly,
            label: T.components.frequency.fortnightly.title,
            title: T.components.frequency.fortnightly.title,
            description: T.components.frequency.fortnightly.description,
            list: [
                T.components.frequency.options.saveTime,
                T.components.frequency.options.sameCleaner
            ]
        }
    ]
}
