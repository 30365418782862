import React from 'react'
import { createStyles } from 'lib/styles'
import { useStyles } from 'lib/hooks'
import { Typography } from 'lib/components'
import { CustomFonts } from 'lib/types'
import { priceHelper } from 'lib/utils'

type FinalPriceProps = {
    price: string,
}

export const FinalPrice: React.FunctionComponent<FinalPriceProps> = ({ price }) => {
    const { styles } = useStyles(stylesheet)

    if (!price) {
        return null
    }

    const { prefix, price: formattedPrice, suffix } = priceHelper.extractPriceParts(price)

    return (
        <Typography.Regular style={styles.finalPrice}>
            {`${prefix} `}
            <Typography.Regular style={styles.finalPriceNumber}>{formattedPrice}</Typography.Regular>
            {suffix}
        </Typography.Regular>
    )
}

const stylesheet = createStyles(theme => ({
    finalPrice: {
        color: theme.colors.orange,
        fontSize: 16,
        fontFamily: CustomFonts.Roboto400,
        marginTop: theme.utils.gap(0.3),
        lineHeight: 40
    },
    finalPriceNumber: {
        color: theme.colors.orange,
        fontSize: 31,
        fontFamily: CustomFonts.Poppins600,
        lineHeight: 35
    }
}))
