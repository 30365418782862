import React from 'react'
import { View } from 'react-native'
import { Icons } from 'assets'
import { useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { Typography, Touchable } from 'lib/components'
import { CustomFonts } from 'lib/types'
import { ErrorMessage } from 'lib/components/form/components/ErrorMessage'
import { ReminderProps } from './MarketingConsentReminder'

export const MarketingConsentPopup: React.FunctionComponent<ReminderProps> =
({
    onPressGetPromotion,
    onCloseModal,
    error,
    isOpen,
    isLoading
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)

    return isOpen ? (
        <View style={styles.mainContainer}>
            <View style={styles.row}>
                <Typography.Title style={styles.title}>{T.components.marketingConsentPopup.title}</Typography.Title>
                <Touchable
                    onPress={onCloseModal}
                    style={styles.closeButton}
                    hitSlopBottom={20}
                    hitSlopRight={20}
                    hitSlopLeft={20}
                    hitSlopTop={20}
                >
                    <Icons.Close size={16} />
                </Touchable>
            </View>
            <View style={styles.row}>
                <Typography.Title style={styles.description}>{T.components.marketingConsentPopup.description}</Typography.Title>
                <Touchable
                    style={styles.submitButton}
                    onPress={onPressGetPromotion}
                    disabled={isLoading}
                >
                    <Typography.Regular style={styles.buttonText}>
                        {T.common.ok}
                    </Typography.Regular>
                </Touchable>
            </View>
            <ErrorMessage text={error} />
        </View>
    ) : null
}

const stylesheet = createStyles(theme => ({
    mainContainer: {
        paddingTop: theme.utils.gap(2),
        paddingHorizontal: theme.utils.gap(2),
        backgroundColor: theme.colors.yellow,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        boxShadow: '0px -2px 8px 0px rgba(44, 44, 44, 0.50)'
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start'
    },
    closeButton: {
        alignItems: 'flex-end',
        flex: 1
    },
    title: {
        fontFamily: CustomFonts.Poppins600,
        fontSize: 16,
        lineHeight: 20,
        textTransform: 'capitalize',
        color: theme.colors.orange,
        flex: 4,
        marginBottom: theme.utils.gap(1.5),
        marginRight: theme.utils.gap(0.25)
    },
    description: {
        fontFamily: CustomFonts.Roboto400,
        fontSize: 13,
        lineHeight: 20,
        color: theme.colors.darkSlate,
        flex: 4,
        marginRight: theme.utils.gap(0.25)
    },
    submitButton: {
        paddingVertical: theme.utils.gap(1),
        paddingHorizontal: theme.utils.gap(0),
        backgroundColor: theme.colors.sun,
        borderRadius: 4,
        flex: 1
    },
    buttonText: {
        textAlign: 'center',
        fontSize: 16,
        lineHeight: 18,
        fontFamily: CustomFonts.Roboto500,
        color: theme.colors.night,
        textTransform: 'uppercase'
    }
}))
