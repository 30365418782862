import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const MoversAndPackers: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path d="m76.547 37.784-5.354-14.429c-.788-2.114-2.793-3.529-4.995-3.529H52.285v-5.57c0-2.903-2.292-5.256-5.121-5.256H5.12C2.291 9 0 11.353 0 14.257v43.985c0 2.904 2.292 5.257 5.121 5.257h4.28c.913 4.025 4.422 7.04 8.612 7.04s7.7-3.015 8.613-7.04h26.34c.912 4.025 4.422 7.04 8.612 7.04s7.7-3.015 8.612-7.04h1.845c2.74-.258 4.888-2.684 4.888-5.496V39.86c0-.717-.125-1.415-.376-2.095v.018ZM18.013 66.881c-2.9 0-5.282-2.427-5.282-5.423s2.364-5.422 5.282-5.422c2.919 0 5.282 2.426 5.282 5.422 0 2.996-2.363 5.423-5.282 5.423Zm30.69-7.04H26.698c-.752-4.246-4.35-7.481-8.702-7.481-4.35 0-7.95 3.235-8.702 7.48h-4.19c-.86 0-1.54-.716-1.54-1.58V14.257c0-.882.699-1.58 1.54-1.58h42.043c.86 0 1.54.716 1.54 1.58v45.602l.018-.018ZM72.68 37.71l-12.337.055-.053-5.9h10.206l2.166 5.845h.018ZM61.578 66.88c-2.9 0-5.282-2.427-5.282-5.423s2.363-5.422 5.282-5.422c2.918 0 5.282 2.426 5.282 5.422 0 2.996-2.364 5.423-5.282 5.423Zm10.296-7.04H70.28c-.752-4.246-4.351-7.481-8.702-7.481s-7.95 3.235-8.702 7.48h-.43l-.143-36.338h13.895c.716 0 1.378.46 1.647 1.158l1.307 3.53h-8.845c-1.952 0-3.528 1.617-3.528 3.62v5.937c0 2.004 1.576 3.621 3.528 3.621h13.035v16.635c0 1.01-.842 1.764-1.468 1.82v.018Z" />
        <Path d="M30.11 20.566a4.51 4.51 0 0 0-3.537-1.936 4.452 4.452 0 0 0-3.675 1.76L11.84 32.57c-.738.951-.755 2.06-.721 2.465v9.998c0 2.41 1.906 4.365 4.258 4.365h4.225c2.37 0 4.31-1.972 4.31-4.418V37.92c0-.475.274-.844.532-.844h3.983c.258 0 .533.37.533.844v6.97c0 2.483 1.974 4.507 4.396 4.507h4.24c2.353 0 4.26-1.954 4.26-4.366v-9.927c.085-.651-.052-1.584-.653-2.464L30.127 20.549l-.017.017Zm8.294 24.484a.835.835 0 0 1-.824.845h-4.242a.972.972 0 0 1-.961-.986v-6.97c0-2.411-1.786-4.365-3.967-4.365h-3.983c-2.181 0-3.967 1.954-3.967 4.365v7.058a.89.89 0 0 1-.876.898H15.36a.835.835 0 0 1-.824-.845V34.736L25.44 22.748l.12-.141c.309-.405.738-.458.893-.44.172 0 .584.07.875.493l11.076 12.127V45.05Z" />
    </Icon>
)
