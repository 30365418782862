import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const Check: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path d="M30.67 61.093 10.061 40.484a3.623 3.623 0 1 1 5.123-5.123L30.8 50.977 64.464 19a3.623 3.623 0 0 1 4.99 5.253Z" />
    </Icon>
)
