import React from 'react'
import { View } from 'react-native'
import { ClearIndicatorProps } from 'react-select'
import { Icons } from 'assets'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { SelectInputOption } from 'lib/types'

export const CustomClearIndicator = (props: ClearIndicatorProps<SelectInputOption, false>) => {
    const { styles } = useStyles(stylesheet)
    const {
        innerProps: {
            ref,
            ...restInnerProps
        }
    } = props

    return (
        <div
            {...restInnerProps}
            ref={ref}
        >
            <View style={styles.container}>
                <Icons.Close size={7} />
            </View>
        </div>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        width: 15,
        height: 15,
        cursor: 'pointer',
        marginRight: theme.utils.gap(1),
        borderRadius: theme.utils.gap(1),
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.colors.silver
    }
}))
