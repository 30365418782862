import React, { useEffect } from 'react'
import { View } from 'react-native'
import { useForm } from '@codegateinc/react-form-builder-v2'
import { useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { Icons } from 'assets'
import { useToastAtom, useUserAtom } from 'lib/atoms'
import { NotificationType } from 'lib/types'
import { linkingHelpers, R, stringHelpers } from 'lib/utils'
import { Measurements, isNative } from 'lib/common'
import { NavigationParams, ScreenNames, StackProps } from 'lib/routing'
import { Adapter, AppLayout, Button, Grid, Touchable, Typography } from 'lib/components'
import { SelectPostcodeFormShape, useSelectPostcodeForm } from '../forms'

type AllServicesPostcodeScreenProps = {
    navigation: StackProps<ScreenNames>,
    route: NavigationParams<ScreenNames.AllServicesPostcode>
}

export const AllServicesPostcodeScreen: React.FunctionComponent<AllServicesPostcodeScreenProps> = ({
    route,
    navigation
}) => {
    const T = useTranslations()
    const [user] = useUserAtom()
    const supplyId = route.params?.supplyId
    const [, setToastMessage] = useToastAtom()
    const userAddresses = user.locations
    const { styles, theme } = useStyles(stylesheet)
    const { form, hasError, submit, isFilled } = useForm<SelectPostcodeFormShape>(useSelectPostcodeForm(userAddresses), {
        onSuccess: ({ postcode }) => {
            const { screenName } = linkingHelpers.getInternalPathBySupplyId(Number(supplyId))
            const userPostcode = postcode.split('/').at(0)

            if (screenName && userPostcode) {
                return navigation.replace(screenName, {
                    postalcode: userPostcode,
                    service: supplyId.toString()
                })
            }

            setToastMessage({
                message: T.linking.unsupportedServiceError,
                type: NotificationType.Info
            })
        }
    })

    useEffect(() => {
        if (R.isNil(supplyId)) {
            setToastMessage({
                message: T.linking.missingSupplierIdError,
                type: NotificationType.Error
            })

            navigation.navigate(ScreenNames.AllServices)
        }
    }, [])

    return (
        <AppLayout
            renderSideMenu={false}
            disableUserMenu={false}
            customContainerStyles={styles.layout}
        >
            <View style={styles.navigationContainer}>
                <Touchable onPress={() => navigation.goBack()}>
                    <View
                        style={{
                            transform: [
                                {
                                    rotate: '90deg'
                                }
                            ],
                            ...styles.iconContainer
                        }}
                    >
                        <Icons.Chevron size={14} />
                    </View>
                </Touchable>
                <Typography.Regular bold>
                    {T.screens.allServicesPostcode.goBack}
                </Typography.Regular>
            </View>
            <Grid.Gap gapTop={10}>
                <Typography.SmallSubheading>
                    {T.screens.allServicesPostcode.title}
                </Typography.SmallSubheading>
            </Grid.Gap>
            <View style={styles.formContainer}>
                <Adapter.SelectInput
                    {...form.postcode}
                    isClearable={false}
                    isSearchable
                    leftIcon={Icons.Navigation}
                    onInputChange={form.postcode.onChangeValue}
                    options={userAddresses.map(location => {
                        const addressUnit = stringHelpers.getFormattedAddressUnit(location.unit)

                        return {
                            label: `${addressUnit}${location.address}, ${location.postcode}`,
                            value: `${location.postcode}/${location._id}`
                        }
                    })}
                />
            </View>
            <Button
                width={
                    isNative
                        ? Measurements.WindowWidth - theme.utils.gap(6)
                        : 220
                }
                onPress={submit}
                text={T.common.confirm}
                disabled={hasError || !isFilled}
            />
        </AppLayout>
    )
}

const stylesheet = createStyles(theme => ({
    layout: {
        alignItems: 'center',
        paddingTop: theme.utils.gap(2)
    },
    formContainer: {
        width: {
            sm: 420,
            xs: 360
        },
        zIndex: theme.zIndex[10],
        marginTop: theme.utils.gap(4),
        marginBottom: theme.utils.gap(2)
    },
    navigationContainer: {
        alignSelf: 'flex-start',
        flexDirection: 'row',
        alignItems: 'center',
        marginLeft: {
            lg: undefined,
            xs: theme.utils.gap(2)
        }
    },
    iconContainer: {
        borderRadius: 4,
        borderColor: theme.colors.silver,
        borderWidth: 1,
        padding: theme.utils.gap(1),
        marginRight: theme.utils.gap(2)
    }
}))
