import { Nullable } from 'lib/types'
import { useIsSingaporeTimeZone } from 'lib/hooks'
import {
    addDays,
    formatISO,
    getDayOfWeek,
    isDatePast,
    selectTimeLabel,
    selectTimeValue,
    subHoursFromDate,
    toDateFnsDate,
    WEEK_STARTS_ON,
    selectTimeLabelWithTimeZone
} from './date'

export const bookingTimeWithOffsetOptions = (date: string, index: number) => {
    const isSingaporeTimeZone = useIsSingaporeTimeZone()

    if (!date) {
        return []
    }

    const dayIndex = (index + WEEK_STARTS_ON) % 7
    const nextWeek = Array.from(Array(7))
        .map((element, index) => formatISO(addDays(toDateFnsDate(date), index)))
        .sort((dateA, dateB) => (getDayOfWeek(dateA)) - (getDayOfWeek(dateB)))

    const dates = new Array(48).fill(0).map((item, index) => ({
        value: selectTimeValue(nextWeek[dayIndex], index * 30),
        label: selectTimeLabel(selectTimeValue(nextWeek[dayIndex], index * 30)),
        subLabel: !isSingaporeTimeZone
            ? selectTimeLabelWithTimeZone(selectTimeValue(date, index * 30))
            : undefined
    }))

    return [...dates.slice(16), ...dates.slice(0, 16)]
}

export const shiftBookingDays = (bookingDays: Record<string, Nullable<string>>, minHoursNotice: number, startingDate: string) =>
    Object.keys(bookingDays).reduce((acc, key, index) => {
        const bookingTimeOptions = bookingTimeWithOffsetOptions(startingDate || new Date().toISOString(), index)
            .filter(({ value }) => !isDatePast(subHoursFromDate(value, minHoursNotice)))

        const [selectedValue] = bookingTimeOptions.filter(option => {
            if (!bookingDays[key]) {
                return false
            }

            return option.label === selectTimeLabel(bookingDays[key] as string)
        })

        return {
            ...acc,
            [key]: selectedValue?.value || null
        }
    }, {})
