import { useField } from '@codegateinc/react-form-builder-v2'
import { dateHelpers } from 'lib/utils'
import { useMockedHours, useTranslations } from 'lib/hooks'
import { BookingFrequency, FrequencyOption, Nullable, SupplyID } from 'lib/types'
import { getServiceStaticConfig } from 'features/bookings/utils'
import { TaskAndErrandsFields, TaskInput } from './forms'
import { useBookingDays, useFrequencyOptions } from '../hooks'
import { TaskAndErrandServices } from '../types'

export const useTaskAndErrandsForm = () => {
    const T = useTranslations()
    const [initialValue] = useMockedHours(2, 8)
    const frequencyOptions = useFrequencyOptions()
    const bookingDaysInitialValue = useBookingDays()
    const config = getServiceStaticConfig(SupplyID.TasksErrands)

    const frequency = useField<Nullable<FrequencyOption>>({
        key: TaskAndErrandsFields.Frequency,
        initialValue: frequencyOptions.find(option => option.value === BookingFrequency.OneTime) || null,
        isRequired: false,
        validateOnBlur: false,
        label: T.screens.taskAndErrands.formFields.frequency.label
    })

    const startingDate = useField<string>({
        key: TaskAndErrandsFields.StartingDate,
        initialValue: '',
        isRequired: true,
        validateOnBlur: false,
        label: T.screens.taskAndErrands.formFields.startingDate.label,
        placeholder: T.screens.taskAndErrands.formFields.startingDate.placeholder,
        validationRules: [
            {
                errorMessage: T.screens.taskAndErrands.formFields.startingDate.validation.isRequired,
                validate: Boolean
            }
        ]
    })

    const bookingTime = useField<string>({
        key: TaskAndErrandsFields.BookingTime,
        initialValue: '',
        validateOnBlur: true,
        isRequired: frequency.value?.value === BookingFrequency.OneTime,
        placeholder: T.screens.taskAndErrands.formFields.selectTime.placeHolder,
        validationRules: [
            {
                errorMessage: T.screens.taskAndErrands.formFields.selectTime.validation.isRequired,
                validate: value => {
                    if (frequency.value?.value !== BookingFrequency.OneTime) {
                        return true
                    }

                    return Boolean(value)
                }
            },
            {
                errorMessage: `${config.min_notice_hours} ${T.screens.taskAndErrands.formFields.selectTime.validation.minimumHourNotice}`,
                validate: value => {
                    if (frequency.value?.value !== BookingFrequency.OneTime) {
                        return true
                    }

                    const isPast = dateHelpers.isDatePast(dateHelpers.subHoursFromDate(value, config.min_notice_hours))

                    return !isPast
                }
            }
        ]
    })

    const bookingDays = useField<Record<string, Nullable<string>>>({
        key: TaskAndErrandsFields.BookingDays,
        initialValue: bookingDaysInitialValue,
        validateOnBlur: true,
        isRequired: frequency.value?.value !== BookingFrequency.OneTime,
        placeholder: T.screens.taskAndErrands.formFields.selectTime.placeHolder,
        validationRules: [
            {
                errorMessage: T.screens.taskAndErrands.formFields.selectTime.validation.isRequired,
                validate: value => {
                    if (frequency.value?.value === BookingFrequency.OneTime) {
                        return true
                    }

                    return Object.values(value).some(Boolean)
                }
            },
            {
                errorMessage: `${config.min_notice_hours} ${T.screens.taskAndErrands.formFields.selectTime.validation.minimumHourNotice}`,
                validate: value => {
                    const times = Object.values(value).filter(item => Boolean(item)) as Array<string>
                    const isSomePast = times.some(time => dateHelpers.isDatePast(dateHelpers.subHoursFromDate(time, config.min_notice_hours)))

                    return !isSomePast
                }
            }
        ]
    })

    const hours = useField<string>({
        key: TaskAndErrandsFields.Hours,
        initialValue: initialValue.value,
        isRequired: true,
        validateOnBlur: true,
        label: T.screens.taskAndErrands.formFields.hours.label,
        validationRules: [
            {
                errorMessage: T.screens.taskAndErrands.formFields.hours.validation.isRequired,
                validate: Boolean
            }
        ]
    })

    const taskType = useField<Nullable<TaskInput>>({
        key: TaskAndErrandsFields.TaskType,
        initialValue: null,
        isRequired: true,
        validateOnBlur: true,
        placeholder: T.screens.taskAndErrands.formFields.taskType.placeholder
    })

    const otherTaskComment = useField<string>({
        key: TaskAndErrandsFields.OtherTaskComment,
        initialValue: '',
        isRequired: false,
        validateOnBlur: true,
        label: T.screens.taskAndErrands.formFields.otherTaskComment.label,
        placeholder: T.screens.taskAndErrands.formFields.otherTaskComment.placeholder,
        validationRules: [
            {
                errorMessage: T.screens.taskAndErrands.formFields.otherTaskComment.validation.isRequired,
                validate: value => taskType?.value?.value !== TaskAndErrandServices.Other || Boolean(value)
            }
        ]
    })

    const purchasing = useField<boolean>({
        key: TaskAndErrandsFields.Purchasing,
        initialValue: false,
        isRequired: false,
        validateOnBlur: true,
        label: T.screens.taskAndErrands.formFields.purchasing.label
    })

    // TODO Hide for now
    // const vehicle = useField<string>({
    //     key: TaskAndErrandsFields.Vehicle,
    //     initialValue: T.screens.taskAndErrands.vehicle.no,
    //     isRequired: true,
    //     validateOnBlur: true,
    //     placeholder: T.screens.taskAndErrands.formFields.vehicle.placeholder,
    //     validationRules: [
    //         {
    //             errorMessage: T.screens.taskAndErrands.formFields.vehicle.validation.isRequired,
    //             validate: Boolean
    //         }
    //     ]
    // })

    return {
        frequency,
        startingDate,
        bookingTime,
        bookingDays,
        hours,
        taskType,
        otherTaskComment,
        purchasing
    }
}
