import React from 'react'
import { ActivityIndicator, View } from 'react-native'
import { useStyles, useTranslations } from 'lib/hooks'
import { createStyles, theme } from 'lib/styles'
import { Touchable, Typography } from 'lib/components'
import { Icons } from 'assets'
import { GetPromotionResponse } from '../types'
import { Nullable } from 'lib/types'

type CouponMobileButtonProps = {
    isLoading: boolean,
    onClick?: VoidFunction
    promotionPackage?: Nullable<GetPromotionResponse>
}

export const CouponMobileButton: React.FunctionComponent<CouponMobileButtonProps> = ({ onClick, promotionPackage, isLoading }) => {
    const { styles } = useStyles(stylesheet)
    const T = useTranslations()

    const isPromoApplied = promotionPackage && promotionPackage.promotion

    const getButtonStyle = () => isPromoApplied ? styles.buttonApplied : styles.button

    return (
        <View style={styles.couponButtonContainer} >
            <Touchable
                testID={T.accessibility.couponButton}
                style={getButtonStyle()}
                onPress={onClick}
                disabled={isLoading}
                pointerEvents={isLoading ? 'none' : 'auto'}
            >
                {isLoading ? <ActivityIndicator color={isPromoApplied ? theme.colors.green : theme.colors.orange} /> : (
                    <View>
                        <View style={styles.textContainer}>
                            {!isPromoApplied && <Icons.Coupon forceColor={theme.colors.orange} size={24} />}
                            <Typography.Regular forceColor={isPromoApplied ? theme.colors.green : theme.colors.orange} bold>
                                {isPromoApplied ? promotionPackage?.promotion?.promo_code.toUpperCase() : T.common.applyDiscount}
                            </Typography.Regular>
                            {isPromoApplied && <Icons.Edit forceColor={theme.colors.green} size={16} />}
                        </View>
                        <View>
                            {isPromoApplied && (
                                <Typography.Regular forceColor={theme.colors.green} style={styles.applied}>
                                    {T.common.applied}
                                </Typography.Regular>
                            )
                            }
                        </View>
                    </View>
                )}
            </Touchable>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    couponButtonContainer: {
        alignItems: 'center'
    },
    button: {
        backgroundColor: theme.colors.yellow,
        width: '100%',
        height: 52,
        borderRadius: 4,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        gap: theme.utils.gap(1)
    },
    buttonApplied: {
        backgroundColor: theme.colors.mint,
        height: 52,
        width: '100%',
        borderRadius: 4,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        gap: theme.utils.gap(1)
    },
    textContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: theme.utils.gap(0.5)
    },
    applied: {
        textAlign: 'center'
    }
}))
