import React from 'react'
import { Icons } from 'assets'
import { useCopyToClipboard, useTranslations } from 'lib/hooks'
import { Touchable } from './Touchable'

type CopyBookingIdProps = {
    id: string,
    size?: number
}

export const CopyBookingId: React.FunctionComponent<CopyBookingIdProps> = ({
    id,
    size = 20
}) => {
    const T = useTranslations()
    const { onCopyPress } = useCopyToClipboard(T.common.copiedBookingIdMessage)

    return (
        <Touchable onPress={() => onCopyPress(id)}>
            <Icons.Booking size={size} />
        </Touchable>
    )
}

