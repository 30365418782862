import React, { useState, useEffect }  from 'react'
import { View, ScrollView, Image, StyleSheet } from 'react-native'
import { Icons, Images } from 'assets'
import { ENV } from 'lib/config'
import { isWeb, isNative, Measurements } from 'lib/common'
import { createStyles } from 'lib/styles'
import { CustomFonts } from 'lib/types'
import { linkingHelpers, R } from 'lib/utils'
import { Button, Typography, Logo } from 'lib/components'
import { useStyles, useTranslations, useAppState } from 'lib/hooks'
import { firebase } from 'lib/firebase'

export const MaintenanceScreen: React.FunctionComponent = () => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const { isInBackground, willBeActive } = useAppState()
    const [maintenanceActive, setMaintenanceActive] = useState(false)

    const checkMaintenanceStatus = async () => {
        const config = await firebase
            .remoteConfig
            .fetchRemoteConfig()
            .catch(R.always({
                isUnderMaintenance: undefined
            }))

        if (config.isUnderMaintenance === 'true') {
            return setMaintenanceActive(true)
        }

        return setMaintenanceActive(false)
    }

    useEffect(() => {
        checkMaintenanceStatus()
    }, [])

    useEffect(() => {
        if (isInBackground && willBeActive) {
            checkMaintenanceStatus()
        }
    }, [isInBackground, willBeActive])

    if (!maintenanceActive) {
        return
    }

    return (
        <View style={styles.container}>
            <View style={[
                styles.header,
                isNative && styles.headerNative
            ]}>
                <View style={[styles.logoContainer]}>
                    <Logo onPress={() => linkingHelpers.openUrl(ENV.SENDHELPER_MARKETING_SITE_URL, false)} />
                </View>
            </View>
            <ScrollView contentContainerStyle={styles.wrapper}>
                <Image
                    resizeMode="contain"
                    source={Images.Maintenance}
                    style={styles.image}
                />
                <View style={styles.textContainer}>
                    <Typography.Heading
                        numberOfLines={1}
                        style={styles.headingText}
                        forceColor={theme.colors.night}
                    >
                        {T.screens.maintenance.heading}
                    </Typography.Heading>
                    <Typography.Subheading
                        numberOfLines={1}
                        style={styles.headingText}
                        forceColor={theme.colors.orange}
                    >
                        {T.screens.maintenance.subHeading}
                    </Typography.Subheading>
                    <Typography.Regular
                        style={styles.text}
                        forceColor={theme.colors.night}
                    >
                        {T.screens.maintenance.supportingText}
                    </Typography.Regular>
                </View>
                <View style={styles.ctaContainer}>
                    <Button
                        width={316}
                        height={50}
                        textStyles={styles.cta}
                        text={isWeb
                            ? T.screens.maintenance.ctaText.web
                            : T.screens.maintenance.ctaText.mobile
                        }
                        onPress={() => {
                            linkingHelpers.openUrl(`${ENV.SENDHELPER_MARKETING_SITE_URL}`, false)
                        }}
                    />
                </View>
                <View style={styles.contactUs}>
                    <View style={styles.leftColumn}>
                        <Typography.Regular
                            bold
                            style={styles.text}
                            forceColor={theme.colors.night}
                        >
                            {T.screens.maintenance.contactUs.title}
                        </Typography.Regular>
                    </View>
                    <View style={styles.rightColumn}>
                        <View style={styles.rightSpacing}>
                            <Button
                                noBackground
                                width={155}
                                height={48}
                                textStyles={styles.contactUsButtons}
                                text={T.screens.maintenance.contactUs.whatsApp.text}
                                onPress={() => {
                                    linkingHelpers.openUrl(T.screens.maintenance.contactUs.whatsApp.link)
                                }}
                                renderIcon={() => (
                                    <Icons.WhatsApp
                                        size={16}
                                        forceColor={theme.colors.green}
                                    />
                                )}
                            />
                        </View>
                        <Button
                            noBackground
                            width={155}
                            height={48}
                            textStyles={styles.contactUsButtons}
                            text={T.screens.maintenance.contactUs.email.text}
                            onPress={() => {
                                linkingHelpers.openUrl(`mailto:${T.screens.maintenance.contactUs.email.link}`)
                            }}
                            renderIcon={color => (
                                <Icons.Mail
                                    size={16}
                                    forceColor={color}
                                />
                            )}
                        />
                    </View>
                </View>
            </ScrollView>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        backgroundColor: theme.colors.white,
        ...StyleSheet.absoluteFillObject
    },
    header: {
        zIndex: theme.zIndex[10],
        flexDirection: 'row',
        width: '100%',
        height: {
            ':w[1440, ]': Measurements.WideHeaderHeight,
            ':w[, 1440]': Measurements.HeaderHeight
        },
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingLeft: {
            ':w[1280, ]': theme.utils.gap(8),
            xs: 0
        },
        borderBottomWidth: 1,
        borderColor: theme.colors.silver
    },
    headerNative: {
        paddingTop: Math.max(Measurements.StatusBarHeight, theme.utils.gap(3)),
        minHeight: Measurements.HeaderWithStatusBarHeight
    },
    logoContainer: {
        flex: {
            ':w[1280, ]': undefined,
            ':w[, 1280]': 1
        },
        alignItems: 'flex-start',
        marginLeft: {
            ':w[,1280]': theme.utils.gap(4)
        }
    },
    wrapper: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        paddingHorizontal: {
            lg: theme.utils.gap(6),
            xs: 0
        },
        paddingTop: {
            lg: theme.utils.gap(5),
            xs: theme.utils.gap(2)
        },
        paddingBottom: theme.utils.gap(5)
    },
    image: {
        width: 198,
        height: 198
    },
    textContainer: {
        width: {
            md: 548,
            xs: 331
        },
        alignItems: 'center'
    },
    headingText: {
        fontSize: {
            lg: 32,
            xs: 28
        },
        lineHeight: {
            lg: 40,
            xs: 35
        }
    },
    text: {
        fontSize: 16,
        lineHeight: 24,
        marginTop: theme.utils.gap(1.4),
        textAlign: 'center'
    },
    ctaContainer: {
        paddingTop: theme.utils.gap(1.4)
    },
    cta: {
        fontSize: 18,
        lineHeight: 18,
        fontFamily: CustomFonts.Roboto500
    },
    contactUs: {
        flex: 1,
        width: '100%',
        height: 68,
        marginTop: theme.utils.gap(3),
        paddingTop: theme.utils.gap(2),
        borderTopWidth: 1,
        borderTopColor: theme.colors.silver,
        flexDirection: {
            lg: 'row',
            xs: 'column'
        },
        justifyContent: 'center'
    },
    leftColumn: {
        marginRight: {
            lg: theme.utils.gap(1),
            xs: undefined
        },
        justifyContent: 'center'
    },
    rightColumn: {
        flexDirection: 'row',
        maxWidth: {
            lg: 360,
            xs: undefined
        },
        justifyContent: 'center',
        marginTop: theme.utils.gap(1.4)
    },
    contactUsButtons: {
        fontSize: 16,
        lineHeight: 24,
        color: theme.colors.night,
        marginRight: theme.utils.gap(1.5),
        fontFamily: CustomFonts.Roboto400
    },
    rightSpacing: {
        marginRight: theme.utils.gap(1)
    }
}))
