import { getS3ImageUploadURL, getS3ImageDownloadURL } from './actions'
import type { GetImageDownloadURLRequest, GetImageDownloadURLResponse } from './types'

export const imageActions = {
    getS3ImageUploadURL,
    getS3ImageDownloadURL
}

export {
    GetImageDownloadURLRequest,
    GetImageDownloadURLResponse
}
