import React from 'react'
import { Modal as NativeModal, View, StyleSheet } from 'react-native'
import { Icons } from 'assets'
import { Children } from 'lib/types'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { Regular, SmallSubheading } from './typography'
import { Button } from './Button'
import { Touchable } from './Touchable'
import { FormComponents } from './form'
import { Toast } from './Toast'

type ModalProps = {
    isOpen: boolean,
    title: string,
    message: string,
    confirmText: string,
    onConfirm: VoidFunction,
    cancelText?: string,
    disabled?: boolean,
    isLoading?: boolean,
    onCancel?: VoidFunction,
    error?: string,
    renderIcon?: () => Children,
    onClose: VoidFunction,
    buttonWidth?: number
}

export const Modal: React.FunctionComponent<ModalProps> = ({
    isOpen,
    title,
    message,
    onConfirm,
    confirmText,
    onCancel,
    cancelText,
    renderIcon,
    error = '',
    disabled = false,
    isLoading = false,
    onClose,
    buttonWidth
}) => {
    const { styles } = useStyles(stylesheet)
    const withCancel = onCancel && cancelText
    const icon = renderIcon
        ? renderIcon()
        : null

    return (
        <NativeModal
            transparent
            visible={isOpen}
            statusBarTranslucent
            animationType="fade"
            // Called when the user is attempting to close the modal like when they hit Escape.
            onRequestClose={() => {
                if (!disabled && !isLoading) {
                    onClose()
                }
            }}
        >
            <View style={styles.contentWrapper}>
                <Touchable
                    onPress={onClose}
                    disabled={disabled || isLoading}
                    style={styles.backdrop}
                />
                <View style={styles.content}>
                    {icon}
                    <Touchable
                        onPress={onClose}
                        disabled={disabled || isLoading}
                        style={styles.closeButton}
                        hitSlopBottom={20}
                        hitSlopRight={20}
                        hitSlopLeft={20}
                        hitSlopTop={20}
                    >
                        <Icons.Close size={18} />
                    </Touchable>
                    <SmallSubheading style={styles.alignText}>
                        {title}
                    </SmallSubheading>
                    <View style={styles.messageContainer}>
                        <View style={styles.errorMessageContainer}>
                            <FormComponents.ErrorMessage text={error} />
                        </View>
                        <Regular style={styles.alignText}>
                            {message}
                        </Regular>
                    </View>
                    <View style={styles.actionButtons}>
                        {withCancel && (
                            <View style={styles.buttonContainer}>
                                <Button
                                    noBackground
                                    onPress={onCancel}
                                    text={cancelText}
                                    width={buttonWidth}
                                    disabled={disabled || isLoading}
                                />
                            </View>
                        )}
                        {withCancel && (
                            <View style={styles.spacer} />
                        )}
                        <View style={styles.buttonContainer}>
                            <Button
                                onPress={onConfirm}
                                text={confirmText}
                                isLoading={isLoading}
                                disabled={disabled}
                                width={buttonWidth}
                            />
                        </View>
                    </View>
                </View>
            </View>
            <Toast />
        </NativeModal>
    )
}

const stylesheet = createStyles(theme => ({
    backdrop: {
        ...StyleSheet.absoluteFillObject
    },
    contentWrapper: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.utils.gap(3),
        backgroundColor: theme.components.modal.backdrop.backgroundColor
    },
    content: {
        alignItems: 'center',
        paddingVertical: {
            lg: theme.utils.gap(5),
            xs: theme.utils.gap(3)
        },
        paddingHorizontal: {
            lg: theme.utils.gap(8),
            xs: theme.utils.gap(3)
        },
        backgroundColor: theme.components.modal.content.backgroundColor,
        borderRadius: theme.components.modal.content.borderRadius
    },
    messageContainer: {
        alignItems: 'center',
        marginVertical: theme.utils.gap(1)
    },
    alignText: {
        textAlign: 'center'
    },
    actionButtons: {
        width: '100%',
        marginTop: theme.utils.gap(2),
        flexDirection: 'row'
    },
    buttonContainer: {
        flex: 1,
        alignItems: 'center'
    },
    spacer: {
        width: theme.utils.gap(1)
    },
    closeButton: {
        position: 'absolute',
        right: theme.utils.gap(2),
        top: theme.utils.gap(2)
    },
    errorMessageContainer: {
        flexDirection: 'row'
    }
}))
