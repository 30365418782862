import appsFlyer from 'react-native-appsflyer'
import { isNative } from 'lib/common'
import { AirConServices, BookingFrequency, JobStatus, SupplyID } from 'lib/types'
import { PaymentType } from 'lib/models'
import { ServiceType } from './types'
import { BookingFrequencySegmentType, JobSegmentStatus, PaymentSegmentMethod, SelectedPaymentSegmentMethod, ServiceSegmentPlan } from './typewriter'
import { Segment } from './segment'

export const supplyIdToServiceType = (supplyId: SupplyID) => {
    switch (supplyId) {
        case SupplyID.Cleaner:
            return ServiceType.Cleaning
        case SupplyID.DeepCleaning:
            return  ServiceType.DeepCleaning
        case SupplyID.ACService:
            return ServiceType.AirCondition
        case SupplyID.Cooking:
            return ServiceType.Cooking
        case SupplyID.Handyman:
            return ServiceType.Handyman
        case SupplyID.Laundry:
            return ServiceType.Laundry
        case SupplyID.PestControl:
            return ServiceType.PestControl
        case SupplyID.TasksErrands:
            return ServiceType.TaskErrands
        default:
            return undefined
    }
}

export const getReadableJobStatus = (jobStatus: JobStatus) => {
    switch (jobStatus) {
        case JobStatus.Submitted:
            return JobSegmentStatus.Submitted
        case JobStatus.Accepted:
            return JobSegmentStatus.Accepted
        case JobStatus.Cancelled:
            return JobSegmentStatus.Cancelled
        case JobStatus.Completed:
            return JobSegmentStatus.Completed
        case JobStatus.Rated:
            return JobSegmentStatus.Rated
        case JobStatus.Paused:
            return JobSegmentStatus.Paused
        case JobStatus.Hold:
            return JobSegmentStatus.Hold
        default:
            return undefined
    }
}

export const getPaymentMethod = (paymentMethod: PaymentType) => {
    switch (paymentMethod) {
        case PaymentType.Credits:
            return PaymentSegmentMethod.Credits
        case PaymentType.GrabPay:
            return PaymentSegmentMethod.Grab
        case PaymentType.Card:
            return PaymentSegmentMethod.Card
        default:
            return undefined
    }
}

export const getFrequencyType = (bookingFrequency: BookingFrequency) => {
    switch (bookingFrequency) {
        case BookingFrequency.OneTime:
            return BookingFrequencySegmentType.OneTime
        case BookingFrequency.Weekly:
            return BookingFrequencySegmentType.Weekly
        case BookingFrequency.Fortnightly:
            return BookingFrequencySegmentType.Fortnightly
        default:
            return undefined
    }
}

export const getSelectedPaymentMethod = (paymentMethod: PaymentType) => {
    switch (paymentMethod) {
        case PaymentType.Credits:
            return SelectedPaymentSegmentMethod.SendPay
        case PaymentType.Card:
            return SelectedPaymentSegmentMethod.CreditCard
        default:
            return undefined
    }
}

export const getServicePlan = (airConService: AirConServices) => {
    switch (airConService) {
        case AirConServices.OneTime:
            return ServiceSegmentPlan.OneTime
        case AirConServices.Contract:
            return ServiceSegmentPlan.Contract
        case AirConServices.Custom:
            return ServiceSegmentPlan.Custom
        default:
            return undefined
    }
}

export const segmentIntegrations = async () => {
    try {
        const uuid = await getAppsFlyerId

        return isNative
            ? {
                integrations: {
                    AppsFlyer: {
                        appsFlyerId: uuid
                    }
                }
            }
            : {}
    } catch {
        return {}
    }

}

export const getAppsFlyerId = new Promise<string>((resolve, reject) => {
    if (!isNative) {
        return resolve('')
    }

    appsFlyer.getAppsFlyerUID((uidError, appsFlyerUID) => {
        if (uidError) {
            return reject(uidError)
        }

        return resolve(appsFlyerUID)
    })
})

export const getAnonymousID = async () => Segment.getAnonymousID()
