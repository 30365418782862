import React from 'react'
import { Typography } from 'lib/components'
import { usePrice, useStyles, useTranslations } from 'lib/hooks'
import { CustomFonts, Nullable } from 'lib/types'
import { View } from 'react-native'
import { createStyles } from 'lib/styles'

type PriceComponentProps = {
    price: Nullable<number>,
    originalPrice?: Nullable<number>,
    text?: string,
    perHour?: boolean,
    priceString?: string,
    quotationText?: string
}

export const PriceComponent: React.FunctionComponent<PriceComponentProps> = ({
    price,
    originalPrice,
    text,
    perHour,
    priceString,
    quotationText
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const formattedPrice = usePrice(price || 0)
    const defaultPriceToDisplay = perHour
        ? `${formattedPrice} / ${T.common.hour}`
        : formattedPrice

    const formattedOriginalPrice = usePrice(originalPrice || 0, true)
    const shouldShowOriginalPrice = originalPrice && price && price !== originalPrice

    if (!price && !priceString) {
        return (
            <React.Fragment>
                <Typography.Regular bold>
                    {quotationText || T.common.chargesOnQuotation}
                </Typography.Regular>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <Typography.Regular bold>
                {text ?? T.common.startingFrom}
            </Typography.Regular>
            {
                shouldShowOriginalPrice && (
                    <View style={styles.row}>
                        <Typography.Regular style={styles.originalPrice}>
                            {perHour
                                ? ` ${formattedOriginalPrice} / ${T.common.hour}`
                                : formattedOriginalPrice
                            }
                        </Typography.Regular>
                    </View>
                )
            }
            <Typography.Regular bold>
                {priceString || defaultPriceToDisplay}
            </Typography.Regular>
        </React.Fragment>
    )
}

const stylesheet = createStyles(theme => ({
    row: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    originalPrice: {
        color: theme.colors.night,
        fontSize: 10,
        lineHeight: 20,
        textDecorationLine: 'line-through',
        fontFamily: CustomFonts.Poppins500
    }
}))
