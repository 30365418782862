import React from 'react'
import { View } from 'react-native'
import { PaymentType } from 'lib/models'
import { createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { SmallSubheading } from '../typography'
import { Touchable } from '../Touchable'

type GrabPayProps = {
    selectedPaymentMethod: PaymentType,
    onSelect: (value: PaymentType) => void
}

export const GrabPay: React.FunctionComponent<GrabPayProps> = ({
    selectedPaymentMethod,
    onSelect
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)

    return (
        <Touchable
            style={styles.container}
            onPress={() => onSelect(PaymentType.GrabPay)}
        >
            <View style={styles.row}>
                <View style={styles.radio}>
                    {selectedPaymentMethod === PaymentType.GrabPay && (
                        <View style={styles.dot} />
                    )}
                </View>
                <SmallSubheading>
                    {T.components.paymentMethod.grabPay.label}
                </SmallSubheading>
            </View>
        </Touchable>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        borderBottomWidth: 1,
        paddingVertical: theme.utils.gap(2),
        borderBottomColor: theme.colors.silver
    },
    row: {
        flexDirection: 'row'
    },
    radio: {
        width: 24,
        height: 24,
        borderRadius: 12,
        alignItems: 'center',
        justifyContent: 'center',
        borderWidth: 2,
        marginRight: theme.utils.gap(2),
        borderColor: theme.colors.night
    },
    dot: {
        width: 12,
        height: 12,
        borderRadius: 6,
        backgroundColor: theme.colors.sun
    }
}))
