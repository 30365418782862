import React from 'react'
import { Path, G } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'
import { useIconColor } from './useIconColor'

export const House: React.FunctionComponent<IconProps> = props => {
    const color = useIconColor(props.isActive, props.forceColor)

    return (
        <Icon
            {...props}
            forceColor="transparent"
        >
            <G
                stroke={color}
                strokeWidth={8}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
            >
                <Path d="m 10.8 32.4 l 32.4 -25.2 l 32.4 25.2 v 39.6 a 7.2 7.2 90 0 1 -7.2 7.2 H 18 a 7.2 7.2 90 0 1 -7.2 -7.2 V 32.4 Z"/>
                <Path d="M 32.4 79.2 V 43.2 h 21.6 v 36"/>
            </G>
        </Icon>
    )
}
