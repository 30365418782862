import React from 'react'
import { View } from 'react-native'
import { useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { PropertyInfo } from 'lib/models'
import { Typography } from 'lib/components'
import { HomePropertyType, MoversAndPackersServiceType } from 'features/bookings'

type PropertyInfoContentProps = {
    title: string,
    location: string
    serviceType: string,
    propertyInfo: PropertyInfo
}

export const PropertyInfoContent: React.FunctionComponent<PropertyInfoContentProps> = ({
    title,
    location,
    serviceType,
    propertyInfo
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const isOtherPropertyType = propertyInfo.type === HomePropertyType.Other

    return (
        <View style={styles.wrapper}>
            <Typography.Label
                style={styles.title}
                forceColor={theme.colors.mouse}
            >
                {title}
            </Typography.Label>
            <Typography.Label>
                {location}
            </Typography.Label>
            <View style={styles.content}>
                <View style={styles.flex}>
                    <Typography.Label
                        style={styles.title}
                        forceColor={theme.colors.mouse}
                    >
                        {T.screens.moversAndPackersDetails.propertyType}
                    </Typography.Label>
                    <Typography.Label>
                        {propertyInfo.type}
                    </Typography.Label>
                </View>
                {!isOtherPropertyType && (
                    <View style={styles.flex}>
                        <Typography.Label
                            style={styles.title}
                            forceColor={theme.colors.mouse}
                        >
                            {serviceType === MoversAndPackersServiceType.HomeMoving
                                ? T.screens.moversAndPackersDetails.bedrooms
                                : T.screens.moversAndPackersDetails.propertySize
                            }
                        </Typography.Label>
                        <Typography.Label>
                            {serviceType === MoversAndPackersServiceType.HomeMoving
                                ? propertyInfo.bedrooms
                                : propertyInfo.size
                            }
                        </Typography.Label>
                    </View>
                )}
            </View>
            {!isOtherPropertyType  && (
                <View style={styles.content}>
                    <View style={styles.flex}>
                        <Typography.Label
                            style={styles.title}
                            forceColor={theme.colors.mouse}
                        >
                            {T.screens.moversAndPackersDetails.propertyFloor}
                        </Typography.Label>
                        <Typography.Label>
                            {propertyInfo.floor}
                        </Typography.Label>
                    </View>
                    <View style={styles.flex}>
                        <Typography.Label
                            style={styles.title}
                            forceColor={theme.colors.mouse}
                        >
                            {T.screens.moversAndPackersDetails.hasElevator}
                        </Typography.Label>
                        <Typography.Label>
                            {propertyInfo.has_elevator
                                ? T.common.yes
                                : T.common.no
                            }
                        </Typography.Label>
                    </View>
                </View>
            )}
            {Boolean(propertyInfo.details) && (
                <View style={styles.details}>
                    <Typography.Label
                        style={styles.title}
                        forceColor={theme.colors.mouse}
                    >
                        {T.screens.moversAndPackersDetails.propertyDetails}
                    </Typography.Label>
                    <Typography.Label>
                        {propertyInfo.details}
                    </Typography.Label>
                </View>
            )}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    title: {
        marginBottom: theme.utils.gap(1)
    },
    wrapper: {
        marginBottom: theme.utils.gap(3)
    },
    content: {
        flexDirection: 'row',
        marginTop: theme.utils.gap(2)
    },
    flex: {
        flex: 1
    },
    details: {
        marginTop: theme.utils.gap(2)
    }
}))
