import React from 'react'
import { View } from 'react-native'
import { Icons } from 'assets'
import { MOCKED_TIME_SLOTS } from 'mocks'
import { useStyles } from 'lib/hooks'
import { dateHelpers } from 'lib/utils'
import { useLocaleAtom } from 'lib/atoms'
import { createStyles } from 'lib/styles'
import { Nullable, SelectInputOption } from 'lib/types'
import { FormComponents, Touchable, Typography } from 'lib/components'

type BookingMultiselectDaysInputProps = {
    index: number,
    date: string,
    dayValue: Nullable<string>,
    onRemoveDate: (date: string) => void,
    onBlur: VoidFunction,
    disabledInput: boolean,
    placeholder?: string,
    minHoursNotice?: number,
    onInputChange: (value: Nullable<SelectInputOption>) => void
}

export const BookingMultiselectDaysInput: React.FunctionComponent<BookingMultiselectDaysInputProps> = ({
    date,
    dayValue,
    disabledInput,
    placeholder,
    onRemoveDate,
    onInputChange
}) => {
    const [locale] = useLocaleAtom()
    const { styles } = useStyles(stylesheet)
    // TODO Need this from API
    const data = MOCKED_TIME_SLOTS

    const [selectedValue] = data.filter(option => {
        if (!dayValue) {
            return false
        }

        return option.value === dayValue
    })

    return (
        <View style={styles.container}>
            <Typography.Regular>
                {dateHelpers.multiSelectDateLabel(date, locale)}
            </Typography.Regular>
            <View style={styles.selectContainer}>
                <View style={styles.inputContainer}>
                    <FormComponents.SelectInput
                        options={data}
                        isClearable={false}
                        disabled={disabledInput}
                        placeholder={placeholder}
                        value={selectedValue || null}
                        onChange={onInputChange}
                    />
                </View>
                <Touchable
                    onPress={() => onRemoveDate(date)}
                    style={styles.deleteIcon}
                >
                    <Icons.Delete size={20} />
                </Touchable>
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: theme.utils.gap(1)
    },
    selectContainer: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    inputContainer: {
        width: 160,
        marginBottom: -theme.utils.gap(2),
        marginLeft: theme.utils.gap(1)
    },
    deleteIcon: {
        padding: theme.utils.gap(1)
    }
}))
