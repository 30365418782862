import React from 'react'
import { View } from 'react-native'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { Typography } from 'lib/components'
import { stringHelpers } from 'lib/utils'

type UserPhotoProps = {
    name: string,
    size?: number
}

export const UserPhoto: React.FunctionComponent<UserPhotoProps> = ({
    name,
    size = 40
}) => {
    const { styles, theme } = useStyles(stylesheet)

    return (
        <View style={styles.container}>
            <View
                style={{
                    ...styles.image,
                    height: size,
                    width: size,
                    borderRadius: size / 2
                }}
            >
                <Typography.Title
                    style={{
                        fontSize: size / 3,
                        textTransform: 'uppercase'
                    }}
                    numberOfLines={1}
                    forceColor={theme.colors.white}
                >
                    {stringHelpers.getFirstLetters(name)}
                </Typography.Title>
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    image: {
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.colors.night
    }
}))
