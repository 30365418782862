import React from 'react'
import { View } from 'react-native'
import { DropdownIndicatorProps } from 'react-select'
import { Icons } from 'assets'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'

export const CustomDropdownIndicator: React.FunctionComponent = props => {
    const { styles } = useStyles(stylesheet)
    const { selectProps: { menuIsOpen } } = props as DropdownIndicatorProps

    return (
        <View
            style={{
                ...styles.container,
                transform: menuIsOpen
                    ? [{ rotate: '180deg' }]
                    : []
            }}
        >
            <Icons.Chevron size={10} />
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        paddingTop: 5,
        paddingHorizontal: theme.utils.gap(2)
    }
}))
