export enum AppVersionHeader {
    IOS_CURRENT = 'x-latest-app-version-ios',
    ANDROID_CURRENT = 'x-latest-app-version-android',
    IOS_MIN = 'x-min-app-version-ios',
    ANDROID_MIN = 'x-min-app-version-android'
}

export type AppVersionAtom = {
    [AppVersionHeader.IOS_CURRENT]: string,
    [AppVersionHeader.ANDROID_CURRENT]: string,
    [AppVersionHeader.IOS_MIN]: string,
    [AppVersionHeader.ANDROID_MIN]: string
}
