import React from 'react'
import { useForm } from '@codegateinc/react-form-builder-v2'
import { View } from 'react-native'
import { useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { Nullable } from 'lib/types'
import { Adapter, Button } from 'lib/components'
import { ChatShape, useChatForm } from '../forms'
import { ConnectionStatus } from '../types'
import { ChatImagePickerButton } from './chatImagePickerButton'

type ChatFooterProps = {
    isOnline: Nullable<boolean>,
    isDragActive: boolean,
    isLoading: boolean,
    maxPhotosReached: boolean,
    isLoadingHistory: boolean,
    havePhotoAdded: boolean,
    roomConnectionStatus: ConnectionStatus,
    sendMessage(message?: string): void,
    onSelectImage(image: File | string): void
}

export const ChatFooter: React.FunctionComponent<ChatFooterProps> = ({
    isDragActive,
    isOnline,
    isLoading,
    sendMessage,
    onSelectImage,
    maxPhotosReached,
    havePhotoAdded,
    isLoadingHistory,
    roomConnectionStatus
}) => {
    const { styles } = useStyles(stylesheet)
    const T = useTranslations()
    const isConnected = roomConnectionStatus === ConnectionStatus.Online && !isLoadingHistory
    const { form, submit, resetForm } = useForm<ChatShape>(useChatForm(), {
        onSuccess: ({ message }) => {
            resetForm()
            sendMessage(message)
        }
    })
    const isDisabled = isDragActive || !isOnline || !isConnected || isLoading

    const handleSubmit = () => {
        if (form.message.value) {
            return submit()
        }

        if (havePhotoAdded) {
            return sendMessage()
        }
    }

    return (
        <View
            style={{
                ...styles.footer,
                opacity: (isConnected && isOnline && !isDragActive) ? 1 : 0.2
            }}
            pointerEvents={isConnected ? undefined : 'none'}
        >
            <View style={styles.chatInput}>
                <ChatImagePickerButton
                    disabled={maxPhotosReached}
                    onSelectedImage={onSelectImage}
                />
                <View style={styles.inputContainer}>
                    <Adapter.ChatInput
                        {...form.message}
                        disabled={isDisabled}
                        inputProps={{
                            onSubmitEditing: handleSubmit
                        }}
                    />
                </View>
            </View>
            <Button
                height={42}
                width={124}
                onPress={handleSubmit}
                disabled={isDisabled}
                text={T.common.send}
            />
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    footer: {
        height: 84,
        borderTopLeftRadius: 12,
        borderTopRightRadius: 12,
        paddingHorizontal: theme.utils.gap(2),
        backgroundColor: theme.colors.white,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        ...theme.utils.createShadow()
    },
    chatInput: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center'
    },
    inputContainer: {
        flex: 1,
        marginRight: theme.utils.gap(2)
    }
}))
