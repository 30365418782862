import React from 'react'
import { View } from 'react-native'
import { createStyles, theme } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { CustomModal, Button, Typography } from 'lib/components'
import { JobDetailsResponse } from 'features/jobs/types'
import { Icons } from 'assets'
import { CustomFonts } from 'lib/types'
import { jobHelpers } from 'lib/utils'

type CancelBookingSuccessModalProps = {
    isOpen: boolean,
    cancelationMessage?: string,
    job: JobDetailsResponse,
    onClose: VoidFunction
}

export const CancelBookingSuccessModal: React.FunctionComponent<CancelBookingSuccessModalProps> = ({
    isOpen,
    cancelationMessage,
    onClose,
    job
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const isAccepted = jobHelpers.isAccepted(job)

    return (
        <CustomModal
            onClose={onClose}
            isOpen={isOpen}
        >
            <View style={styles.modalWrapper}>
                <View style={styles.iconContainer}>
                    <Icons.CheckCircle
                        size={40}
                        forceColor={theme.colors.orange}
                    />
                </View>
                <Typography.SmallSubheading style={styles.title}>
                    {
                        isAccepted ?
                            T.screens.jobDetails.cancelBookingSuccess.bookingCancelled :
                            T.screens.jobDetails.cancelBookingSuccess.bookingRequestCancelled
                    }
                </Typography.SmallSubheading>
                <View style={styles.messageContainer}>
                    <Typography.Regular style={styles.message}>
                        {
                            isAccepted ?
                                T.screens.jobDetails.cancelBookingSuccess.theBooking :
                                T.screens.jobDetails.cancelBookingSuccess.theBookingRequest
                        }
                        <Typography.Regular style={styles.jobIdText}>
                            {` ${job.job.job_id} `}
                        </Typography.Regular>
                        {T.screens.jobDetails.cancelBookingSuccess.hasBeenCancelledSuccessfully}
                    </Typography.Regular>
                </View>
                {cancelationMessage && (
                    <View style={styles.cancelationMessageWrapper}>
                        <Icons.Info
                            size={24}
                            forceColor={theme.colors.orange}
                        />
                        <Typography.Regular style={styles.cancelationMessage}>
                            {cancelationMessage}
                        </Typography.Regular>
                    </View>
                )}
                <View style={styles.actionContainer}>
                    <View style={styles.buttonWrapper}>
                        <Button
                            text={T.screens.jobDetails.cancelBookingSuccess.done}
                            onPress={onClose}
                            textWrapperStyles={styles.buttonTextWrapper}
                            textStyles={styles.buttonText}
                        />
                    </View>
                </View>
            </View>
        </CustomModal>
    )
}

const stylesheet = createStyles(theme => ({
    modalWrapper: {
        alignItems: 'center',
        paddingHorizontal: {
            lg: theme.utils.gap(16),
            md: theme.utils.gap(8),
            sm: theme.utils.gap(6),
            xs: theme.utils.gap(2)
        }
    },
    iconContainer: {
        alignItems: 'center',
        paddingBottom: theme.utils.gap(1)
    },
    messageContainer: {
        marginTop: theme.utils.gap(1.5)
    },
    actionContainer: {
        paddingTop: theme.utils.gap(3),
        flexDirection: 'row',
        justifyContent: 'center',
        zIndex: -1,
        width: {
            md: undefined,
            xs: '100%'
        }
    },
    buttonWrapper: {
        backgroundColor: theme.colors.latte,
        width: '100%'
    },
    buttonTextWrapper: {
        width: '100%',
        wordWrap: 'break-word',
        textAlign: 'center',
        marginHorizontal: theme.utils.gap(7)
    },
    firstButtonWrapper: {
        marginLeft: 0
    },
    lastButtonWrapper: {
        marginRight: 0
    },
    jobIdText: {
        fontWeight: 'bold'
    },
    cancelationMessageWrapper: {
        borderRadius: 8,
        backgroundColor: theme.colors.latte,
        padding: theme.utils.gap(1.5),
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: theme.utils.gap(1.5),
        marginBottom: theme.utils.gap(0)
    },
    cancelationMessage: {
        textAlign: 'center',
        marginLeft: theme.utils.gap(1)
    },
    title: {
        fontSize: 25,
        textAlign: 'center',
        lineHeight: 31,
        fontFamily: CustomFonts.Poppins600
    },
    message: {
        lineHeight: 24,
        textAlign: 'center'
    },
    buttonText: {
        textAlign: 'center'
    }
}))
