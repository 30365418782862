import { useField } from '@codegateinc/react-form-builder-v2'
import { useTranslations } from 'lib/hooks'
import { Country } from 'lib/types'
import { DEFAULT_COUNTRY } from 'lib/data'
import { GuestBookingFields } from './types'

export const useGuestBookingForm = () => {
    const T = useTranslations()

    const name = useField<string>({
        key: GuestBookingFields.Name,
        initialValue: '',
        isRequired: true,
        validateOnBlur: true,
        placeholder: T.screens.bookings.guestBooking.formFields.name.placeholder,
        label: T.screens.bookings.guestBooking.formFields.name.label,
        validationRules: [
            {
                errorMessage: T.screens.bookings.guestBooking.formFields.name.validation.isRequired,
                validate: Boolean
            },
            {
                errorMessage: T.screens.bookings.guestBooking.formFields.name.validation.minValue,
                validate: value => value.length >= 3
            },
            {
                errorMessage: T.screens.bookings.guestBooking.formFields.name.validation.maxValue,
                validate: value => value.length <= 30
            }
        ],
        submitParser: value => value
            .split(' ')
            .filter(Boolean)
            .join(' ')
    })

    const country = useField<Country>({
        key: GuestBookingFields.Country,
        initialValue: DEFAULT_COUNTRY,
        isRequired: true
    })

    const phoneNumber = useField<string>({
        key: GuestBookingFields.PhoneNumber,
        initialValue: '',
        isRequired: true,
        validateOnBlur: true,
        placeholder: T.screens.bookings.guestBooking.formFields.phoneNumber.placeholder,
        label: T.screens.bookings.guestBooking.formFields.phoneNumber.label,
        validationRules: [
            {
                errorMessage: T.screens.bookings.guestBooking.formFields.phoneNumber.validation.isRequired,
                validate: Boolean
            },
            {
                errorMessage: T.screens.bookings.guestBooking.formFields.phoneNumber.validation.minValue,
                validate: ({ length }) => length >= 5
            },
            {
                errorMessage: T.screens.bookings.guestBooking.formFields.phoneNumber.validation.maxValue,
                validate: ({ length }) => length <= 16
            }
        ],
        liveParser: value => {
            if (!value) {
                return value
            }

            if (!value.slice(-1).match(/\d/) || value.length > 16) {
                return value.slice(0, -1)
            }

            return value
        }
    })

    const address = useField<string>({
        key: GuestBookingFields.Address,
        initialValue: '',
        isRequired: true,
        validateOnBlur: true,
        placeholder: T.screens.bookings.guestBooking.formFields.address.placeholder,
        label: T.screens.bookings.guestBooking.formFields.address.label,
        validationRules: [
            {
                errorMessage: T.screens.bookings.guestBooking.formFields.address.validation.isRequired,
                validate: Boolean
            },
            {
                errorMessage: T.screens.bookings.guestBooking.formFields.address.validation.minValue,
                validate: value => value.length >= 3
            }
        ],
        submitParser: value => value
            .split(' ')
            .filter(Boolean)
            .join(' ')
    })

    return {
        name,
        country,
        phoneNumber,
        address
    }
}
