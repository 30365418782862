import React, { RefObject } from 'react'
import { Field } from '@codegateinc/react-form-builder-v2'
import { TextInput as NativeTextInput, TextInputProps as NativeTextInputProps, View } from 'react-native'
import { IconProps, VoidFunction } from 'lib/types'
import { Breakpoint, createStyles } from 'lib/styles'
import { MediaQuery, Typography } from 'lib/components'
import { useStyles } from 'lib/hooks'
import { KeyboardAccessoryFieldProps } from '../../reKeyboardAccessory'
import { TextInput } from './TextInput'
import { FormComponents } from '../components'

interface TextInputWithLabelProps extends KeyboardAccessoryFieldProps, Field<string> {
    ref?: RefObject<NativeTextInput>,
    disabled?: boolean,
    inputProps?: NativeTextInputProps,
    customStyles?: boolean,
    smallLabel?: boolean,
    testID?: string,
    rightIcon?: React.FunctionComponent<IconProps>,
    leftIcon?: React.FunctionComponent<IconProps>,
    onFocus?: VoidFunction
}

export const TextInputWithLabel = React.forwardRef<NativeTextInput, TextInputWithLabelProps>((props, ref) => {
    const { styles, theme } = useStyles(stylesheet)

    return (
        <View style={styles.row}>
            <View
                style={{
                    ...styles.labelColumn,
                    width: props.smallLabel
                        ? undefined
                        : styles.labelColumn.width
                }}
            >
                <Typography.Regular>
                    <Typography.Regular>
                        {props.label}
                    </Typography.Regular>
                    <Typography.Label forceColor={theme.components.input.typography.error}>
                        {props.isRequired ? '*' : ''}
                    </Typography.Label>
                </Typography.Regular>
                <MediaQuery.Visible from={Breakpoint.LG}>
                    <FormComponents.ErrorMessage />
                </MediaQuery.Visible>
            </View>
            <View style={styles.input}>
                <TextInput
                    ref={ref}
                    {...props}
                />
            </View>
        </View>
    )
})

const stylesheet = createStyles(theme => ({
    input: {
        flex: 1
    },
    row: {
        alignItems: {
            lg: 'center',
            xs: 'stretch'
        },
        flexDirection: {
            lg: 'row',
            xs: 'column'
        }
    },
    labelColumn: {
        width: {
            lg: 120,
            xs: '100%'
        },
        marginRight: {
            lg: theme.utils.gap(1),
            xs: 0
        },
        marginBottom: {
            lg: 0,
            xs: theme.utils.gap(1)
        }
    },
    textRow: {
        flexDirection: 'row',
        flexWrap: 'wrap'
    }
}))
