import React from 'react'
import { View } from 'react-native'
import { createStyles } from 'lib/styles'
import { BasicModal, Typography } from 'lib/components'
import { useStyles, useTranslations } from 'lib/hooks'
import { AirConditionPricingModalItem } from './AirConditionPricingModalItem'
import { SupplyID } from 'lib/types'
import { getServiceStaticConfig } from '../utils'
import { DetailsContactLink } from '../../jobs/components/DetailsContactLink'

type AirConditionPricingModalProps = {
    isOpen: boolean,
    onClose: VoidFunction
}

export const AirConditionPricingModal: React.FunctionComponent<AirConditionPricingModalProps> = ({
    isOpen,
    onClose
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)

    const config = getServiceStaticConfig(SupplyID.ACService)

    const {
        oneTime,
        contract,
        custom,
        disclaimers
    } = config.pricing

    return (
        <BasicModal
            insetsPadding
            isOpen={isOpen}
            onClose={onClose}
        >
            <View style={styles.priceChartModal}>
                <Typography.SmallSubheading>
                    {T.screens.airCondition.pageTitle}
                </Typography.SmallSubheading>
                <View style={styles.table}>
                    <AirConditionPricingModalItem item={oneTime} />
                    <AirConditionPricingModalItem item={contract} />
                    <AirConditionPricingModalItem
                        isLast
                        item={custom}
                    />
                </View>
                <Typography.Regular style={styles.disclaimer}>
                    {disclaimers || T.screens.airCondition.disclaimer}
                </Typography.Regular>
                <DetailsContactLink text={T.common.gotQuestion} />
            </View>
        </BasicModal>
    )
}

const stylesheet = createStyles(theme => ({
    priceChartModal: {
        paddingHorizontal: {
            lg: theme.utils.gap(2),
            xs: 0
        },
        paddingVertical: theme.utils.gap(1)
    },
    table: {
        flexDirection: {
            lg: 'row',
            xs: 'column'
        },
        marginTop: theme.utils.gap(2)
    },
    disclaimer: {
        marginTop: theme.utils.gap(2),
        marginBottom: {
            lg: 0,
            xs: theme.utils.gap(1)
        },
        fontSize: 12,
        lineHeight: {
            lg: 26,
            xs: 19
        },
        color: theme.colors.grey
    }
}))
