import React from 'react'
import { View } from 'react-native'
import { Children, Nullable } from 'lib/types'
import { dateHelpers } from 'lib/utils'
import { createStyles } from 'lib/styles'
import { GetPromotionResponse } from 'features/bookings'
import { useStyles, useTranslations } from 'lib/hooks'
import { PestControlFormShape } from '../../forms'
import { PestControlBookingSummary } from './PestControlBookingSummary'

type PestControlSummaryColumnProps = {
    form: PestControlFormShape,
    couponText?: string,
    priceMessage?: string,
    showPrices?: boolean,
    title?: string,
    renderPriceSummaryComponent?: () => Children,
    renderPriceComponent: () => Children,
    renderExtraContent?: () => Children,
    promotionPackage?: Nullable<GetPromotionResponse>,
    totalPrice?: Nullable<number>,
    originalPrice?: Nullable<number>
}

export const PestControlSummaryColumn: React.FunctionComponent<PestControlSummaryColumnProps> = ({
    form,
    couponText,
    priceMessage,
    title,
    showPrices,
    renderPriceSummaryComponent,
    renderPriceComponent,
    renderExtraContent,
    promotionPackage,
    totalPrice,
    originalPrice
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const startingDate = form.startingDate
        ? dateHelpers.startingDate(form.startingDate)
        : ''
    const bookingTime = form.bookingTime
        ? ` ${dateHelpers.selectTimeLabel(form.bookingTime)}`
        : ''
    // const { firstService, otherServices, packagesEntries } = pestControlHooks.useGetSummaryEntries({
    //     services: form.services,
    //     packages: form.packages,
    //     showPrices
    // })

    return (
        <View style={styles.container}>
            <PestControlBookingSummary
                promotionPackage={promotionPackage}
                totalPrice={totalPrice}
                originalPrice={originalPrice}
                title={title}
                couponText={couponText}
                pricingMessage={priceMessage}
                renderPriceComponent={renderPriceComponent}
                renderPriceSummaryComponent={renderPriceSummaryComponent}
                bookingSummary={[
                    [T.components.bookingSummary.serviceDate, `${startingDate}${bookingTime}`]
                    // [T.components.bookingSummary.propertyType, form.propertyType.label],
                    // [T.components.bookingSummary.services, firstService?.at(1) || ''],
                ]}
                valueStyle={showPrices ? {} : styles.flexed}
                labelStyle={showPrices ? styles.flexed : {}}
            />
            {renderExtraContent && renderExtraContent()}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        borderWidth: 1,
        borderRadius: 8,
        borderColor: theme.colors.silver,
        paddingTop: theme.utils.gap(2),
        paddingHorizontal: theme.utils.gap(2),
        marginBottom: theme.utils.gap(2),
        backgroundColor: theme.colors.white,
        ...theme.utils.createShadow(2, 0, 8, 4, 4, theme.colors.webShadow(0.04))
    },
    flexed: {
        flex: 1
    }
}))
