import React from 'react'
import { View } from 'react-native'
import { Service } from 'lib/models'
import { createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { Typography } from 'lib/components'

type DeepCleaningServicesProps = {
    services?: Array<Service>
}

export const DeepCleaningServices: React.FunctionComponent<DeepCleaningServicesProps> = ({
    services
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const columns = Object.values(T.screens.jobDetails.servicesColumns)

    if (services?.length === 0) {
        return null
    }

    return (
        <View style={styles.wrapper}>
            <View style={styles.row}>
                {columns.map((item, index) => (
                    <View
                        key={index}
                        style={
                            index === columns.length - 1
                                ? styles.quantity
                                : styles.column
                        }
                    >
                        <Typography.Regular
                            bold
                            forceColor={theme.colors.mouse}
                        >
                            {item}
                        </Typography.Regular>
                    </View>
                ))}
            </View>
            {services?.map(item => (
                <View
                    key={item.id}
                    style={styles.row}
                >
                    <View style={styles.column}>
                        <Typography.Regular>
                            {item.title}
                        </Typography.Regular>
                    </View>
                    <View style={styles.column}>
                        <Typography.Regular>
                            {item.subtitle ? item.subtitle : '-'}
                        </Typography.Regular>
                    </View>
                    <View style={styles.quantity}>
                        <Typography.Regular>
                            {item.quantity}
                        </Typography.Regular>
                    </View>
                </View>
            ))}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        marginBottom: theme.utils.gap(2)
    },
    row: {
        flexDirection: 'row'
    },
    column: {
        flex: 1,
        marginBottom: theme.utils.gap(1)
    },
    quantity: {
        width: 50
    }
}))
