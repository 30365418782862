import React, { MutableRefObject, useEffect, useRef, useState } from 'react'
import { View } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scrollview'
import Animated, { SharedValue, useAnimatedStyle, withTiming } from 'react-native-reanimated'
import { Icons } from 'assets'
import { createStyles } from 'lib/styles'
import { Touchable, Typography } from 'lib/components'
import { useClickAway, useStyles } from 'lib/hooks'
import { ACServiceDropdownOption, Nullable } from 'lib/types'
import { isNative } from 'lib/common'

type AirConditionServiceDropdownProps = {
    options: Array<ACServiceDropdownOption>,
    isSelected: boolean,
    onSelectOption: (option: ACServiceDropdownOption) => void,
    isDropdownVisible: SharedValue<boolean>,
    scrollRef: MutableRefObject<KeyboardAwareScrollView>
}

export const AirConditionServiceDropdown: React.FunctionComponent<AirConditionServiceDropdownProps> = ({
    isSelected,
    options,
    onSelectOption,
    isDropdownVisible,
    scrollRef
}) => {
    const optionsDropdownRef = useRef<View>(null)
    const { styles, theme } = useStyles(stylesheet)
    const [selectedOptionIndex, setSelectedOptionIndex] = useState(0)
    const [hoveredIndex, setHoveredIndex] = useState<Nullable<number>>(null)
    const animatedContainerStyles = useAnimatedStyle(() => ({
        opacity: withTiming(isDropdownVisible.value ? 1 : 0),
        display: isDropdownVisible.value ? 'flex' : 'none'
    }))
    const defaultLabel = options[selectedOptionIndex].value
    const animatedChevronStyles = useAnimatedStyle(() => ({
        transform: [
            {
                rotate: withTiming(`${isDropdownVisible.value ? 180 : 0}deg`, {
                    duration: 200
                })
            }
        ]
    }))

    useClickAway({
        elementRef: optionsDropdownRef,
        onClickAway: () => {
            isDropdownVisible.value = false
        }
    })

    useEffect(() => {
        if (!isSelected) {
            setSelectedOptionIndex(0)
        }
    }, [isSelected])

    return (
        <View>
            <Touchable
                onPress={() => {
                    if (!isDropdownVisible.value && isNative) {
                        scrollRef?.current?.scrollTo({
                            x: 0,
                            y: 130,
                            animated: true
                        })
                    }

                    isDropdownVisible.value = !isDropdownVisible.value
                }}
            >
                <View
                    ref={optionsDropdownRef}
                    style={styles.selectContainer}
                >
                    <Typography.Label forceColor={theme.colors.orange}>
                        {defaultLabel}
                    </Typography.Label>
                    <Animated.View
                        style={[
                            styles.chevron,
                            animatedChevronStyles
                        ]}
                    >
                        <Icons.Chevron
                            size={isNative
                                ? 8
                                : 10
                            }
                        />
                    </Animated.View>
                </View>
            </Touchable>
            <Animated.View
                style={[
                    styles.options,
                    animatedContainerStyles
                ]}
            >
                {options.map((item, index) => {
                    const isHovered = hoveredIndex === index

                    return (
                        <Touchable
                            onHoverIn={() => setHoveredIndex(index)}
                            onHoverOut={() => setHoveredIndex(null)}
                            key={index}
                            onPress={() => {
                                isDropdownVisible.value = false
                                setSelectedOptionIndex(index)
                                onSelectOption(item)
                            }}
                        >
                            <View
                                style={isHovered
                                    ? {
                                        ...styles.option,
                                        ...styles.hooveredBackground
                                    }
                                    : styles.option
                                }
                            >
                                <Typography.Label
                                    forceColor={isHovered
                                        ? theme.colors.orange
                                        : theme.colors.night
                                    }
                                >
                                    {item.value}
                                </Typography.Label>
                            </View>
                        </Touchable>
                    )
                })}
            </Animated.View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    selectContainer: {
        borderWidth: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        borderColor: theme.colors.sun,
        marginTop: theme.utils.gap(2),
        paddingVertical: theme.utils.gap(1),
        paddingHorizontal: theme.utils.gap(1) / 2,
        borderRadius: theme.components.button.borderRadius
    },
    chevron: {
        marginLeft: theme.utils.gap(1) / 4
    },
    options: {
        top: 70,
        left: -20,
        right: -20,
        borderRadius: 8,
        position: 'absolute',
        backgroundColor: theme.colors.white,
        ...theme.utils.createShadow(2, 0, 8, 4, 4, theme.colors.webShadow(0.04))
    },
    hooveredBackground: {
        backgroundColor: theme.colors.yellow
    },
    option: {
        paddingHorizontal: theme.utils.gap(2),
        paddingVertical: theme.utils.gap(3) / 2
    }
}))
