import React, { useEffect, useMemo } from 'react'
import { View } from 'react-native'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { bookingHelpers, dateHelpers } from 'lib/utils'
import { Typography } from 'lib/components'
import { Nullable, SelectInputOption } from 'lib/types'
import { FormComponents } from '../components'

type BookingDaysInputProps = {
    index: number,
    dayValue: Nullable<string>,
    startDate: string,
    dayKey: string,
    onBlur: VoidFunction,
    disabledCheckbox: boolean,
    disabledInput: boolean,
    isSelected: boolean,
    placeholder?: string,
    onCheckboxChange: VoidFunction,
    minHoursNotice?: number,
    onInputChange: (value: Nullable<SelectInputOption>) => void
}

export const BookingDaysInput: React.FunctionComponent<BookingDaysInputProps> = ({
    index,
    startDate,
    dayKey,
    dayValue,
    onBlur,
    disabledCheckbox,
    disabledInput,
    placeholder,
    isSelected,
    onCheckboxChange,
    minHoursNotice,
    onInputChange
}) => {
    const { styles } = useStyles(stylesheet)
    const data = useMemo(() => {
        const bookingTimeOptions = bookingHelpers.bookingTimeWithOffsetOptions(startDate || new Date().toISOString(), index)
        const filteredBookingTimeOptions = bookingTimeOptions
            .filter(({ value }) => {
                if (!minHoursNotice) {
                    return true
                }

                return !dateHelpers.isDatePast(dateHelpers.subHoursFromDate(value, minHoursNotice))
            })

        return filteredBookingTimeOptions.length === 0
            ? bookingHelpers.bookingTimeWithOffsetOptions(dateHelpers.addDaysToDate(startDate || new Date().toISOString(), 7), index)
            : filteredBookingTimeOptions
    }, [startDate])

    const [selectedValue] = data.filter(option => {
        if (!dayValue) {
            return false
        }

        return option.label === dateHelpers.selectTimeLabel(dayValue)
    })

    useEffect(() => {
        if (!selectedValue) {
            onInputChange(null)
        }
    }, [selectedValue])

    return (
        <View style={styles.container}>
            <View style={styles.checkBox}>
                <FormComponents.Checkbox
                    onChange={onCheckboxChange}
                    renderComponent={() => (
                        <Typography.Regular>
                            {dayKey}
                        </Typography.Regular>
                    )}
                    disabled={disabledCheckbox}
                    isSelected={isSelected}
                />
            </View>
            <View style={styles.inputContainer}>
                <FormComponents.SelectInput
                    options={data}
                    isClearable={false}
                    disabled={disabledInput}
                    placeholder={placeholder}
                    value={selectedValue || null}
                    onChange={onInputChange}
                />
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        flexDirection: 'row'
    },
    checkBox: {
        flex: 1,
        paddingTop: 10
    },
    inputContainer: {
        width: 160,
        marginLeft: theme.utils.gap(1)
    }
}))
