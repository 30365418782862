import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const Gift: React.FunctionComponent<IconProps> = props => (
    <Icon
        {...props}
        forceColor="transparent"
    >
        <Path
            d="M68.75 74.001v-42H9.25v42h59.5ZM39 74.001v-42M68.75 74H9.25"
            stroke={props.forceColor}
            strokeWidth={7}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <Path
            d="M74 17.998H4v14h70v-14Z"
            stroke={props.forceColor}
            strokeWidth={7}
            strokeLinejoin="round"
        />
        <Path
            d="m25 4 14 14L53 4"
            stroke={props.forceColor}
            strokeWidth={7}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Icon>
)
