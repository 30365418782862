import React, { useState } from 'react'
import { View } from 'react-native'
import { useForm } from '@codegateinc/react-form-builder-v2'
import { CallToActionButton, Typography } from 'lib/components'
import { dateHelpers, jobHelpers, emitter } from 'lib/utils'
import { Segment, segmentUtils } from 'lib/analytics'
import { useDynamicServiceAtom, useJobScheduleLogsAtom, useLocaleAtom, useToastAtom, useUserTokenAtom } from 'lib/atoms'
import { createStyles } from 'lib/styles'
import { useQueryHelpers, useStyles, useTranslations } from 'lib/hooks'
import { NotificationType } from 'lib/types'
import { BookingDateModal } from 'features/bookings'
import { JobDetailsResponse } from '../types'
import { RescheduleFormShape, useRescheduleForm } from '../forms'
import { getConsumerActiveJobs, getJobDetails, rescheduleJob } from '../actions'

type RescheduleSessionProps = {
    data: JobDetailsResponse,
    label?: string,
    fullWidth?: boolean,
    description?: string,
    buttonWidth?: number,
    disabled?: boolean
}

export const RescheduleSession: React.FunctionComponent<RescheduleSessionProps> = ({
    data,
    label,
    fullWidth,
    description,
    buttonWidth,
    disabled
}) => {

    const T = useTranslations()
    const [locale] = useLocaleAtom()
    const [token] = useUserTokenAtom()
    const { styles, theme } = useStyles(stylesheet)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const { onRequestError } = useQueryHelpers()
    const [, setToastMessage] = useToastAtom()
    const [jobScheduleLogs] = useJobScheduleLogsAtom()
    const [config] = useDynamicServiceAtom(data.job.supply.id)
    const { mutate: reschedule, isLoading: isMutating } = rescheduleJob()
    const { refetch: refetchJobDetails, isRefetching: isRefetchingJobDetails } = getJobDetails(data.job._id)
    const { refetch: refetchActiveJobs, isRefetching: isRefetchingActiveJobs } = getConsumerActiveJobs()
    const { form, submit, resetForm } = useForm<RescheduleFormShape>(useRescheduleForm(), {
        onSuccess: ({ bookingTime }) => {
            reschedule(
                {
                    token,
                    job: data.job._id,
                    new_date: bookingTime
                },
                {
                    onSuccess: () => {
                        resetForm()
                        refetchJobDetails()
                        refetchActiveJobs()
                        setIsModalOpen(false)
                        setToastMessage({
                            type: NotificationType.Success,
                            message: T.screens.jobDetails.rescheduleSuccessMessage
                        })
                        Segment.rescheduleBookingClicked({
                            jobId: data.job.job_id,
                            serviceType: segmentUtils.supplyIdToServiceType(data.job.supply.id)
                        })
                    },
                    onError: onRequestError
                }
            )
        }
    })

    if(!jobHelpers.shouldShowRescheduleButton(data)) {
        return null
    }

    const getRescheduleButtonLabel = (jobDetail: JobDetailsResponse, defaultLabel: string): string => {
        const jobId = jobDetail?.job?._id
        const isSessionJob = jobHelpers.isSessionJob(jobDetail)
        const isJobOneTime = jobHelpers.isJobOneTime(jobDetail)
        const isRecurringAccepted = jobHelpers.isRecurringAccepted(jobDetail)
        const isSubmittedSubscription = jobHelpers.isSubmittedSubscription(jobDetail)
        const isAcceptedSubscription = jobHelpers.isAcceptedSubscription(jobDetail)
        const isAcceptedSessionJobForSubscription = jobHelpers.isAcceptedSessionJobForSubscription(jobDetail)
        const isSubmittedRecurringJob = jobHelpers.isRecurringSubmitted(jobDetail) && !isSessionJob
        const jobScheduleLog = jobScheduleLogs[jobId as string]

        if (isAcceptedSessionJobForSubscription) {
            return jobScheduleLog ?
                T.screens.jobDetails.updateRescheduleRequest :
                T.screens.jobDetails.rescheduleSession
        }

        if (isSubmittedSubscription || isAcceptedSubscription) {
            return jobScheduleLog ?
                T.screens.jobDetails.updateRescheduleRequest :
                T.screens.jobDetails.reschedulePlan
        }

        if (isSessionJob || isJobOneTime) {
            return T.screens.jobDetails.rescheduleSession
        }

        if (isRecurringAccepted && !isSessionJob) {
            return T.screens.jobDetails.reschedulePackage
        }

        if (isSubmittedRecurringJob) {
            return T.screens.jobDetails.rescheduleTheBooking
        }

        return defaultLabel
    }

    const isLoading = isMutating || isRefetchingJobDetails || isRefetchingActiveJobs

    const defaultLabel = label || T.screens.jobDetails.rescheduleSession

    return (
        <View style={fullWidth ? styles.containerFullWidth : styles.container}>
            <BookingDateModal
                testID={T.accessibility.bookingDetailsScreen.reschedule.modal}
                onContinue={submit}
                disabled={isLoading}
                isLoading={isLoading}
                isModalOpen={isModalOpen}
                description={description}
                frequency={form.frequency.value}
                startingDateField={form.startingDate}
                bookingTimeField={form.bookingTime}
                bookingDaysField={form.bookingDays}
                minHoursNotice={config?.min_notice_hours}
                onClose={() => setIsModalOpen(false)}
                title={T.screens.jobDetails.reschedule}
                header={(
                    <View style={styles.currentSessionWrapper}>
                        <Typography.Label forceColor={theme.colors.mouse}>
                            {T.screens.jobDetails.currentServiceDate}
                        </Typography.Label>
                        <Typography.Label>
                            {dateHelpers.getFullBookingDate(data.job.date_time || data.job.beginning_date, locale)}
                        </Typography.Label>
                    </View>
                )}
            />
            <View style={fullWidth ? styles.buttonWrapperFullWidth : styles.buttonWrapper}>
                <CallToActionButton
                    onPress={() => {
                        emitter.emit('openRescheduleModal', data)

                        Segment.rescheduleBookingClicked({})
                    }}
                    text={getRescheduleButtonLabel(data, defaultLabel)}
                    width={buttonWidth}
                    disabled={disabled}
                />
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        flex: 1
    },
    containerFullWidth:{
        flex: 1,
        width: '100%'
    },
    buttonWrapper: {
        width: {
            ':w[1250, ]': 240,
            ':w[, 1250]': undefined
        },
        marginBottom: theme.utils.gap(2)
    },
    buttonWrapperFullWidth: {
        marginBottom: theme.utils.gap(2),
        width: '100%'
    },
    currentSessionWrapper: {
        marginLeft: {
            lg: theme.utils.gap(4),
            xs: 0
        }
    }
}))
