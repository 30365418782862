import React from 'react'
import { View } from 'react-native'
import { createStyles } from 'lib/styles'
import { Typography } from 'lib/components'
import { useStyles, useTranslations } from 'lib/hooks'
import { JobDetailsResponse } from '../types'

type TaskAndErrandsServiceDetailsProps = {
    job: JobDetailsResponse,
    withHours: boolean
}

export const TaskAndErrandsServiceDetails: React.FunctionComponent<TaskAndErrandsServiceDetailsProps> = ({
    job,
    withHours
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)

    return (
        <View>
            <View style={styles.wrapper}>
                {withHours && (
                    <View style={styles.flex}>
                        <View style={styles.title}>
                            <Typography.Label forceColor={theme.colors.mouse}>
                                {T.common.hours}
                            </Typography.Label>
                        </View>
                        <Typography.Label>
                            {job.job.num_hours}
                        </Typography.Label>
                    </View>
                )}
                <View style={styles.flex}>
                    <View style={styles.title}>
                        <Typography.Label forceColor={theme.colors.mouse}>
                            {T.screens.jobDetails.sections.purchasing}
                        </Typography.Label>
                    </View>
                    <Typography.Label>
                        {job.job.shopping ? T.common.yes : T.common.no}
                    </Typography.Label>
                </View>
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        flexDirection: 'row',
        marginBottom: theme.utils.gap(3)
    },
    title: {
        marginBottom: theme.utils.gap(1)
    },
    flex: {
        flex: 1
    },
    cleaningDays: {
        textTransform: 'capitalize'
    }
}))
