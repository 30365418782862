import React from 'react'
import { View } from 'react-native'
import { Children } from 'lib/types'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { Typography } from 'lib/components'

type ShareStepItemProps = {
    index: number,
    title: string,
    description: string,
    renderIcon: () => Children
}

export const ShareStepItem: React.FunctionComponent<ShareStepItemProps> = ({
    index,
    title,
    description,
    renderIcon
}) => {
    const { styles, theme } = useStyles(stylesheet)

    return (
        <View style={styles.wrapper}>
            <View style={styles.columnWrapper}>
                <View style={styles.iconWrapper}>
                    <View style={styles.indexWrapper}>
                        <Typography.Label>
                            {index + 1}
                        </Typography.Label>
                    </View>
                    {renderIcon()}
                </View>
                <View style={styles.titleWrapper}>
                    <Typography.Title
                        bold
                        style={styles.title}
                    >
                        {title}
                    </Typography.Title>
                    <Typography.Label forceColor={theme.colors.darkGrey}>
                        {description}
                    </Typography.Label>
                </View>
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        flex: 1,
        borderWidth: 1,
        borderRadius: 8,
        borderColor: theme.colors.silver,
        paddingVertical: theme.utils.gap(3) / 2,
        paddingHorizontal: theme.utils.gap(3) / 2,
        backgroundColor: theme.colors.white,
        ...theme.utils.createShadow(2, 0, 8, 4, 4, theme.colors.webShadow(0.04))
    },
    indexWrapper: {
        top: 0,
        left: 0,
        width: 18,
        height: 18,
        borderRadius: 18 / 2,
        alignItems: 'center',
        position: 'absolute',
        justifyContent: 'center',
        zIndex: theme.zIndex[10],
        backgroundColor: theme.colors.sun
    },
    columnWrapper: {
        alignItems: 'center',
        flexDirection: {
            md: 'column',
            xs: 'row'
        }
    },
    titleWrapper: {
        flex: 1,
        marginLeft: {
            md: 0,
            xs: theme.utils.gap(1)
        }
    },
    title: {
        textAlign: {
            md: 'center',
            xs: undefined
        },
        marginTop: {
            md: theme.utils.gap(1),
            xs: 0
        },
        marginBottom: {
            md: theme.utils.gap(2),
            xs: theme.utils.gap(1)
        }
    },
    iconWrapper: {
        width: {
            md: 64,
            xs: 48
        },
        height: {
            md: 64,
            xs: 48
        },
        borderRadius: 64 / 2,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.colors.yellow
    }
}))
