import { useTranslations } from 'lib/hooks'
import { ScreenNames } from 'lib/routing'

export const useLaundryBreadcrumbs = () => {
    const T = useTranslations()

    return [
        {
            text: T.screens.laundry.breadCrumbs.requirements,
            screenName: ScreenNames.Laundry
        },
        {
            text: T.screens.laundry.breadCrumbs.confirmation,
            screenName: ScreenNames.LaundrySummary
        }
    ]
}
