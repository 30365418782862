import React from 'react'
import { View, ViewStyle } from 'react-native'
import { Icons } from 'assets'
import { useStyles } from 'lib/hooks'
import { VoidFunction } from 'lib/types'
import { createStyles } from 'lib/styles'
import { isWeb } from 'lib/common'
import { Touchable } from 'lib/components'
import { ErrorMessage } from './ErrorMessage'

type CheckboxProps = {
    isSelected: boolean,
    disabled?: boolean,
    errorMessage?: string,
    testID?: string,
    onChange: VoidFunction,
    style?: ViewStyle,
    iconSize?: number,
    unselectCheckboxStyle?: ViewStyle,
    selectedCheckboxStyle?: ViewStyle,
    hideErrorMessage?: boolean,
    checkBoxContainerStyle?: ViewStyle,
    renderComponent(): JSX.Element
    renderExtraComponent?(): JSX.Element | null
}

export const Checkbox: React.FunctionComponent<CheckboxProps> = ({
    isSelected,
    onChange,
    disabled,
    renderComponent,
    renderExtraComponent,
    errorMessage,
    style,
    unselectCheckboxStyle = {},
    selectedCheckboxStyle = {},
    testID,
    hideErrorMessage,
    iconSize,
    checkBoxContainerStyle
}) => {
    const { styles, theme } = useStyles(stylesheet)

    return (
        <View
            style={{
                ...styles.container,
                ...style
            }}
        >
            <Touchable
                testID={testID}
                focusable={false}
                onPress={onChange}
                disabled={disabled}
                style={{
                    ...styles.checkboxContainer,
                    ...checkBoxContainerStyle
                }}
            >
                {
                    !isSelected ? (
                        <View
                            style={{
                                ...styles.checkbox,
                                ...unselectCheckboxStyle
                            }}
                            focusable
                        />
                    ) : (
                        <View
                            style={{
                                ...styles.checkbox,
                                ...selectedCheckboxStyle
                            }}
                            focusable
                        >
                            <View style={styles.selectedCheckbox}>
                                <Icons.Check
                                    size={iconSize ? iconSize : 16}
                                    forceColor={theme.components.checkBox.iconColor}
                                />
                            </View>
                        </View>
                    )
                }
                {renderComponent()}
            </Touchable>
            {renderExtraComponent && renderExtraComponent()}
            {
                !hideErrorMessage && <ErrorMessage text={errorMessage}/>
            }
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        flex: 1,
        marginBottom: theme.utils.gap(2)
    },
    checkboxContainer: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    checkbox: {
        width: theme.components.checkBox.width,
        height: theme.components.checkBox.height,
        padding: isWeb
            ? theme.utils.gap(1)
            : undefined,
        borderRadius: theme.components.checkBox.borderRadius,
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: theme.utils.gap(1),
        borderWidth: theme.components.checkBox.borderWidth,
        borderColor: theme.components.checkBox.borderColor
    },
    selectedCheckbox: {
        borderRadius: theme.components.checkBox.borderRadius,
        backgroundColor: theme.components.checkBox.backgroundColor
    }
}))
