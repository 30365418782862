import React from 'react'
import { View } from 'react-native'
import { Icons } from 'assets'
import { useUserAtom } from 'lib/atoms'
import { JobDetails } from 'lib/models'
import { linkingHelpers } from 'lib/utils'
import { createStyles } from 'lib/styles'
import { Grid, Touchable, Typography } from 'lib/components'
import { CustomerChargeStatus, SupplyID } from 'lib/types'
import { useFormatPrice, useStyles, useTranslations } from 'lib/hooks'
import { AcceptBilling } from './AcceptBilling'
import { CancelBilling } from './CancelBilling'
import { PriceSummaryComponent } from 'features/bookings/components'

type JobBillingsProps = {
    job: JobDetails
}

export const JobBillings: React.FunctionComponent<JobBillingsProps> = ({ job }) => {
    const T = useTranslations()
    const [user] = useUserAtom()
    const { styles, theme } = useStyles(stylesheet)
    const formatPrice = useFormatPrice()
    const isTaskAndErrands = job.supply.id === SupplyID.TasksErrands
    const isFavourite = user.consumer.per_service_account.some(account => account.preferred_helper?._id === job.supplier._id)
    const paidBills = job?.customer_charges?.filter(item => item.auth_status === CustomerChargeStatus.Approved) || []
    const totalPaidAmount = paidBills?.reduce((acc, nextValue) => acc + nextValue.amount, 0)
    const pendingBills = job?.customer_charges?.filter(item => item.auth_status === CustomerChargeStatus.Pending) || []
    const price = formatPrice(job?.bookingSummary?.total?.value || 0)
    const formattedTip = formatPrice(job.tip || 0)

    return (
        <View style={styles.content}>
            <View style={styles.billingsTitle}>
                <View style={styles.billingsNumber}>
                    <Typography.Regular forceColor={theme.colors.white}>
                        {job?.customer_charges?.length}
                    </Typography.Regular>
                </View>
                <Typography.Regular bold>
                    {T.components.noBilling.title}
                </Typography.Regular>
            </View>
            {isTaskAndErrands && Number(job?.base_cost) > 0 && (
                <Grid.Gap
                    gapTop={2}
                    style={styles.row}
                >
                    <Typography.Regular bold>
                        {T.components.billing.tasksAndErrands}
                    </Typography.Regular>
                    <Typography.Label>
                        {formatPrice(job?.base_cost || 0)}
                    </Typography.Label>
                </Grid.Gap>
            )}
            {paidBills.length > 0 && (
                <View>
                    <Typography.Label style={styles.paidLabel} bold>
                        {T.components.billing.paidBilling}
                    </Typography.Label>
                    <View style={styles.title}>
                        <View style={styles.expertName}>
                            <Typography.Label forceColor={theme.colors.mouse}>
                                {`${T.components.proposals.expert}: ${job.supplier.handle} `}
                            </Typography.Label>
                            {isFavourite && (
                                <Icons.Heart
                                    size={14}
                                    fill={theme.colors.orange}
                                    forceColor={theme.colors.orange}
                                />
                            )}
                        </View>
                        <Icons.CheckCircle
                            size={14}
                            forceColor={theme.colors.green}
                        />
                    </View>
                    <View style={styles.charges}>
                        {paidBills.map((item, index) => (
                            <View key={index}>
                                <View style={styles.row}>
                                    <Typography.Label forceColor={theme.colors.mouse}>
                                        {item.payment_type}
                                    </Typography.Label>
                                    <Typography.Label>
                                        {formatPrice(item.amount)}
                                    </Typography.Label>
                                </View>
                                {item.description && (
                                    <View style={styles.row}>
                                        <Typography.Label>
                                            {item.description}
                                        </Typography.Label>
                                    </View>
                                )}
                            </View>
                        ))}
                    </View>
                    <View style={styles.totalPrice}>
                        <Typography.Label bold>
                            {T.common.totalPaid}
                        </Typography.Label>
                        <Typography.Label>
                            {formatPrice(totalPaidAmount)}
                        </Typography.Label>
                    </View>
                    <View style={styles.lineSeparator} />
                </View>
            )}
            {pendingBills.length > 0 && (
                <View>
                    <Typography.Label style={styles.paidLabel} bold>
                        {T.components.billing.unPaidBilling}
                    </Typography.Label>
                    <View style={styles.title}>
                        <View style={styles.expertName}>
                            <Typography.Label forceColor={theme.colors.mouse}>
                                {`${T.components.proposals.expert}: ${job.supplier.handle} `}
                            </Typography.Label>
                            {isFavourite && (
                                <Icons.Heart
                                    size={14}
                                    fill={theme.colors.orange}
                                    forceColor={theme.colors.orange}
                                />
                            )}
                        </View>
                    </View>
                    <View style={styles.charges}>
                        {pendingBills.map((item, index) => (
                            <View key={index}>
                                <View style={styles.row}>
                                    <Typography.Label>
                                        {item.payment_type}
                                    </Typography.Label>
                                    <Typography.Label>
                                        {formatPrice(item.amount)}
                                    </Typography.Label>
                                </View>
                                {item.description && (
                                    <View style={styles.row}>
                                        <Typography.Label>
                                            {item.description}
                                        </Typography.Label>
                                    </View>
                                )}
                            </View>
                        ))}
                        <View style={styles.row}>
                            <PriceSummaryComponent priceSummary={job?.bookingSummary?.priceSummary} promotion={job?.bookingSummary?.discount} />
                        </View>

                    </View>
                    {!isTaskAndErrands && (
                        <View style={styles.lineSeparator} />
                    )}
                </View>
            )}
            {Boolean(job.tip) && !isTaskAndErrands && (
                <View style={styles.row}>
                    <Typography.Label>
                        {T.common.tip}
                    </Typography.Label>
                    <Typography.Label>
                        {formattedTip}
                    </Typography.Label>
                </View>
            )}

            {job.promotion?.warning_message && (
                <View style={styles.termsAndConditions}>
                    <Typography.Label
                        bold
                        forceColor={theme.colors.flame}
                    >
                        {`${T.screens.referFriend.jobDetailsMessage} `}
                        <Touchable onPress={() => linkingHelpers.openReferralTermsAndConditions()}>
                            <Typography.Label
                                bold
                                style={styles.viewTerms}
                                forceColor={theme.colors.flame}
                            >
                                {T.screens.referFriend.footerPartTwo}
                            </Typography.Label>
                        </Touchable>
                    </Typography.Label>
                </View>
            )}
            {!isTaskAndErrands && (
                <View style={styles.row}>
                    <Typography.Label bold>
                        {T.common.totalAmount}
                    </Typography.Label>
                    <Typography.Error style={styles.lineThrough}>
                        {formatPrice(job.bookingSummary?.total?.valueBeforeDiscount || 0)}
                    </Typography.Error>
                    <Typography.Label>
                        {price}
                    </Typography.Label>
                </View>
            )}
            <View style={styles.buttons}>
                <View style={styles.flex}>
                    <CancelBilling job={job} />
                </View>
                <View style={styles.separator} />
                <View style={styles.flex}>
                    <AcceptBilling job={job} />
                </View>
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    flex: {
        flex: 1
    },
    buttons: {
        flexDirection: 'row',
        marginTop: theme.utils.gap(1)
    },
    separator: {
        width: theme.utils.gap(1) / 2
    },
    content: {
        borderRadius: 8,
        borderWidth: 1,
        padding: theme.utils.gap(2),
        backgroundColor: theme.colors.white,
        borderColor: theme.colors.silver,
        backGroundColor: theme.colors.white,
        marginBottom: theme.utils.gap(2),
        ...theme.utils.createShadow(2, 0, 8, 4, 4, theme.colors.webShadow(0.04))
    },
    title: {
        borderRadius: 8,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: theme.colors.marble,
        paddingVertical: theme.utils.gap(1),
        paddingHorizontal: theme.utils.gap(2),
        marginTop: theme.utils.gap(2),
        marginBottom: theme.utils.gap(2)
    },
    expertName: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    row: {
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: theme.utils.gap(2)
    },
    charges: {
        paddingHorizontal: theme.utils.gap(1)
    },
    description: {
        marginBottom: theme.utils.gap(1)
    },
    billingsTitle: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    billingsNumber: {
        width: 24,
        height: 24,
        borderRadius: 12,
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: theme.utils.gap(1),
        backgroundColor: theme.colors.night
    },
    totalPrice: {
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingHorizontal: theme.utils.gap(1)
    },
    lineThrough: {
        textDecorationLine: 'line-through'
    },
    lineSeparator: {
        height: 1,
        backgroundColor: theme.colors.marble,
        marginBottom: theme.utils.gap(1)
    },
    paidLabel: {
        marginTop: theme.utils.gap(1)
    },
    termsAndConditions: {
        justifyContent: 'center',
        textAlign: 'center',
        marginBottom: theme.utils.gap(1)
    },
    viewTerms: {
        textDecorationLine: 'underline'
    }
}))
