import { APP_CONFIG, ENV } from 'lib/config'
import { Languages } from 'lib/locale'

import { AuthFlowError } from '../types'
import { GoogleProfile } from 'lib/types'

export const getGoogleInstance = () => {
    if (!window.google) {
        throw AuthFlowError.ServerError
    }

    return window.google
}

const getGoogleProfile = async (accessToken: string) => {
    const url = `${ENV.GOOGLE_PROFILE_URL}?alt=json&access_token=${accessToken}`
    try {
        const response = await fetch(url)

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`)
        }

        const responseData = await response.json()

        return responseData
    } catch (error) {

        console.error('Failed to fetch Google profile:', error)
        throw error
    }
}

export const initGoogleSDK = (id: string, language: string) => new Promise<void>((resolve, reject) => {
    const doesScriptExist = Boolean(document.getElementById(id))

    if (doesScriptExist) {
        return resolve()
    }

    if (!language.includes('_')) {
        return reject()
    }

    const scriptTag = document.createElement('script')

    scriptTag.id = id
    scriptTag.src = ENV.GOOGLE_SDK_URL as string
    scriptTag.async = true
    scriptTag.defer = true
    scriptTag.onload = () => resolve()

    scriptTag.onerror = () => reject()

    const body = document.querySelector('body')

    if (!body) {
        return reject()
    }

    body.append(scriptTag)
})

export const authorizeWithGoogle = (): Promise<GoogleProfile> =>
    new Promise((resolve, reject) => {
        const handleGoogleLoginError = error => {
            reject(error)
        }

        const handleGoogleLogin = res => {
            (async () => {
                try {
                    if (!res.access_token) {
                        return reject(res.error)
                    }

                    const profile = await getGoogleProfile(res.access_token)
                    resolve({ access_token: res.access_token, email: profile.email, accountId: profile.id, name: profile.name })
                } catch (error) {
                    reject(error)
                }
            })()
        }
        initGoogleSDK(APP_CONFIG.KEYS.GOOGLE_SCRIPT_ID, Languages.en_US)
            .then(() => getGoogleInstance())
            .then(google => google.accounts.oauth2.initTokenClient({
                client_id: ENV.GOOGLE_WEB_CLIENT_ID as string,
                prompt: 'select_account',
                scope: 'email profile',
                callback: handleGoogleLogin,
                error_callback: handleGoogleLoginError
            })
            ).then(client => client.requestAccessToken())
    })

