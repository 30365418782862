import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const Chat: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path d="M71.591 45.175c-2.028 13.015-12.52 23.81-25.515 26.246-6.275 1.184-12.667.508-18.467-1.947-1.644-.696-3.344-1.048-5.012-1.048-.76 0-1.512.072-2.252.22L9.099 70.894l2.252-11.268c.472-2.34.184-4.844-.828-7.24-2.456-5.8-3.128-12.187-1.948-18.467C11.01 20.924 21.801 10.432 34.821 8.404c10.36-1.612 20.491 1.652 27.803 8.964 7.315 7.316 10.583 17.451 8.967 27.807Zm-3.308-33.463C59.143 2.577 46.504-1.499 33.59.497 17.282 3.04 3.762 16.176.71 32.443c-1.472 7.832-.624 15.808 2.444 23.06.388.923.508 1.783.356 2.555L.078 75.21a3.987 3.987 0 0 0 1.096 3.612 3.983 3.983 0 0 0 2.828 1.172c.26 0 .52-.024.784-.076l17.131-3.428c.984-.188 1.936.088 2.572.352 7.26 3.068 15.236 3.912 23.06 2.444 16.27-3.052 29.406-16.571 31.95-32.878 2.008-12.908-2.076-25.555-11.216-34.695Z" />
    </Icon>
)
