import React, { useState, useEffect } from 'react'
import { View } from 'react-native'
import { Field } from '@codegateinc/react-form-builder-v2'
import { useLocaleAtom } from 'lib/atoms'
import { useStyles, useTranslations } from 'lib/hooks'
import { createStyles, theme } from 'lib/styles'
import { BookingFrequency, CustomFonts, FrequencyOption, Nullable } from 'lib/types'
import { dateHelpers } from 'lib/utils'
import { Icons } from 'assets'
import { BookingDateCalendarPickerLite, BookingDateWeeklyService } from 'features/bookings/components'
import { TimeSlot } from '../../../../bookings/types'

type RescheduleBookingDateTimeProps = {
    startingDateField: Field<string>,
    bookingTimeField: Field<string>,
    bookingDaysField: Field<Record<string, Nullable<string>>>,
    maxDate?: string,
    frequency: FrequencyOption,
    onContinue: VoidFunction,
    disabled?: boolean,
    isLoading?: boolean,
    timeDescription?: string,
    minHoursNotice: number,
    timeSlots?: Array<TimeSlot>,
    isMultiSelect?: boolean,
    allowToggle?: boolean
}

export const RescheduleBookingDateTime: React.FunctionComponent<RescheduleBookingDateTimeProps> = ({
    frequency,
    startingDateField,
    bookingTimeField,
    bookingDaysField,
    disabled,
    isLoading,
    onContinue,
    minHoursNotice,
    timeSlots,
    timeDescription,
    isMultiSelect,
    allowToggle,
    maxDate
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const [locale] = useLocaleAtom()
    const [bookingDate, setBookingDate] = useState('')
    const [isShowBookingCalendar, setIsShowBookingCalendar] = useState(false)
    const startingDate = isMultiSelect
        ? startingDateField.value.split(', ').at(0) || ''
        : startingDateField.value
    const translationKey = frequency.value || BookingFrequency.OneTime
    const isMultiSelectValueSelected =
        Object.values(bookingDaysField.value).length === 0 ||
        Object.values(bookingDaysField.value).some(value => value === null)
    const isValueSelected = !bookingTimeField.value
    const continueDisabled = isMultiSelect ? isMultiSelectValueSelected : isValueSelected
    const bookingDateDayName = dateHelpers.getWeekDayFullNames(startingDate || new Date(), locale)
    const selectedTime = dateHelpers.getDateTime(bookingDaysField.value[bookingDateDayName] || new Date())
    const selectedTimeDate = startingDate ? `${startingDate}T${selectedTime}` : new Date()
    const isPastMinimumNotice =
        dateHelpers.isDatePast(dateHelpers.subHoursFromDate(selectedTimeDate || new Date(), minHoursNotice))

    useEffect(() => {
        const currentDate = new Date().toISOString().split('T')[0]
        const selectedDate = startingDate || currentDate
        setBookingDate(dateHelpers.startingDate(selectedDate))

        if (isShowBookingCalendar) {
            setIsShowBookingCalendar(false)
        }
    }, [startingDate])

    useEffect(() => {
        if (startingDateField.hasChange) {
            bookingTimeField.onChangeValue('')
        }
    }, [startingDateField.value])

    return (
        <View style={styles.calendarContainer}>
            <BookingDateCalendarPickerLite
                displayDate={bookingDate}
                frequency={frequency}
                isLoading={isLoading}
                onContinue={onContinue}
                allowToggle={allowToggle}
                maxDate={maxDate}
                isMultiSelect={isMultiSelect}
                minHoursNotice={minHoursNotice}
                continueLabel={T.common.continue}
                continueDisabled={continueDisabled}
                startingDateField={startingDateField}
                selectedDays={bookingDaysField.value}
                onChangeSelectedDays={bookingDaysField.onChangeValue}
                description=""
                disabled={
                    disabled ||
                    !startingDate ||
                    continueDisabled ||
                    !bookingDaysField.value[bookingDateDayName] ||
                    isPastMinimumNotice
                }
            />

            <View style={styles.spacer} />
            <View style={styles.bookingTimeContainer}>
                <BookingDateWeeklyService
                    testIDOneTimeSelect={T.accessibility.bookingDetailsScreen.reschedule.time}
                    liteMode
                    leftIcon={() =>  (
                        <Icons.Clock
                            viewBox="0 0 25 25"
                            size={20}
                            forceColor={continueDisabled ? theme.colors.fog : theme.colors.black}
                        />
                    )}
                    disabled={disabled}
                    timeTitle=""
                    timeSlots={timeSlots}
                    isLoading={isLoading}
                    isOneTime
                    onContinue={onContinue}
                    startingDate={startingDate}
                    isMultiSelect={isMultiSelect}
                    translationKey={translationKey}
                    minHoursNotice={minHoursNotice}
                    timeDescription={timeDescription}
                    bookingDaysField={bookingDaysField}
                    continueDisabled={continueDisabled}
                    bookingTimeField={bookingTimeField}
                    onChangeSelectedDate={startingDateField.onChangeValue}
                    selectedDates={startingDateField.value}
                    hideContinueButton
                />
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    calendarContainer: {
        width: '100%',
        flexDirection: 'column'
    },
    reverseOrder: {
        flexDirection: {
            lg: 'row-reverse',
            xs: 'column-reverse'
        }
    },
    spacer: {
        width: {
            lg: theme.utils.gap(4),
            xs: 0
        }
    },
    bookingTimeContainer: {
        paddingTop: theme.utils.gap(0),
        zIndex: -1,
        textAlign: 'left'
    },
    datePickerLabel: {
        fontFamily: CustomFonts.Roboto500
    }
}))

export default RescheduleBookingDateTime
