import React from 'react'
import { View } from 'react-native'
import { createStyles } from 'lib/styles'
import { Job, RecurringJob } from 'lib/models'
import { useStyles } from 'lib/hooks'
import { BookingsCalendarEventTile } from './BookingsCalendarEventTile'

type BookingsCalendarEventsProps = {
    jobs: Array<Job>,
    recurringJobs: Array<RecurringJob>
}

export const BookingsCalendarEvents: React.FunctionComponent<BookingsCalendarEventsProps> = ({
    jobs,
    recurringJobs
}) => {
    const { styles } = useStyles(stylesheet)

    return (
        <View style={styles.container}>
            {jobs.slice(0, 2).map((item, index) => (
                <BookingsCalendarEventTile
                    job={item}
                    key={index}
                    recurringJobs={recurringJobs}
                />
            ))}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        flexDirection: {
            lg: 'column',
            xs: 'row'
        },
        maxWidth: '100%',
        alignItems: 'center',
        paddingHorizontal: {
            lg: theme.utils.gap(2),
            xs: undefined
        }
    }
}))
