import qs from 'qs'
import { useNavigation, useRoute } from '@react-navigation/native'
import { NavigationParams, ScreenNames } from 'lib/routing'
import { isNative } from 'lib/common'
import {
    useCancelReasonAtom,
    useCreditsPackageAtom,
    useIsAuthorizedAtom,
    useToastAtom,
    useUserAtom,
    useUserTokenAtom
} from 'lib/atoms'
import { linkingHelpers, cancelReasonHelpers } from 'lib/utils'
import { NotificationType } from 'lib/types'
import { GoogleAnalytics } from 'lib/analytics'
import { useGetErrorMessage, useTranslations } from 'lib/hooks'
import { useGetCancelationReasons, useGetCreditPackages, useGetMe } from '../actions'
import { cookieUtils } from '../cookies'

export const useGetProfileData = (onError: (error: string) => void) => {
    const T = useTranslations()
    const [, setUser] = useUserAtom()
    const { getErrorMessage } = useGetErrorMessage()
    const [, setIsAuthorized] = useIsAuthorizedAtom()
    const [, setUserToken] = useUserTokenAtom()
    const [, setCreditsPackage] = useCreditsPackageAtom()
    const [, setToastMessage] = useToastAtom()
    const [, setCancelReason] = useCancelReasonAtom()
    const navigation = useNavigation()
    const route = useRoute<NavigationParams<ScreenNames.Auth>>()
    const { mutate: getMe, isLoading: isFetchingProfile } = useGetMe()
    const { mutateAsync: getCancellationReasons, isLoading: isFetchingCancellationReasons } = useGetCancelationReasons()
    const { mutateAsync: getCreditPackages, isLoading: isFetchingCreditPackages } = useGetCreditPackages()

    return {
        isFetchingProfile: isFetchingProfile || isFetchingCancellationReasons || isFetchingCreditPackages,
        getMe: (token: string) => getMe({
            token
        }, {
            onSuccess: async ({ data }) => {
                try {
                    const cancellationReasonsResponse = await getCancellationReasons({ token })
                    const creditPackagesResponse = await getCreditPackages({ token })
                    const cancelReason = cancelReasonHelpers.appendTheReasonOther(cancellationReasonsResponse.data.job_cancel_reasons)
                    setUser(data.profile)
                    setUserToken(data.token)
                    setIsAuthorized(true)
                    setCancelReason(cancelReason)
                    setCreditsPackage(creditPackagesResponse.data.package_list)
                    cookieUtils.setMarketingConsentCookie(data.profile)

                    if (isNative && data.profile?.locations?.length === 0) {
                        navigation.navigate(ScreenNames.AddressSetup)
                    }

                    GoogleAnalytics.setUserId(data.token)
                    cookieUtils.setAuthCookies({
                        userId: data.token,
                        userEmail: data.profile.email_id,
                        userAddresses: data.profile.locations,
                        userName: data.profile.handle
                    })

                    const shouldBeRedirectedToBooking = Boolean(route.params?.service && route.params?.postalcode)

                    if (!shouldBeRedirectedToBooking) {
                        return
                    }

                    const { url } = linkingHelpers.getInternalPathForBooking(
                        `/${linkingHelpers.BOOK_URL}?service=${route.params?.service}&postalcode=${route.params?.postalcode}`
                    )

                    if (!url)  {
                        return
                    }

                    linkingHelpers.openUrl(`${url}?${qs.stringify(route.params)}`, false)
                } catch {
                    setToastMessage({
                        message: T.common.errorMessage,
                        type: NotificationType.Error
                    })
                }
            },
            onError: response => onError(getErrorMessage(response))
        })
    }
}
