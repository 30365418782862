import React from 'react'
import { View } from 'react-native'
import { useNavigation, useRoute } from '@react-navigation/native'
import { Breadcrumb } from 'lib/types'
import { useIsWithinBreakpoints, useStyles, useTranslations } from 'lib/hooks'
import { Touchable, Typography } from 'lib/components'
import { Breakpoint, createStyles, theme } from 'lib/styles'
import { useIsAuthorizedAtom } from 'lib/atoms'
import { BookingsScreens, NavigationParams, ScreenNames, StackProps } from 'lib/routing'

type ServiceLayoutBreadCrumbsProps = {
    breadcrumbs: Array<Breadcrumb>,
    authenticationView?: boolean,
    onBackFromDetails?: VoidFunction
}

export const ServiceLayoutBreadCrumbs: React.FunctionComponent<ServiceLayoutBreadCrumbsProps> = ({
    breadcrumbs,
    authenticationView,
    onBackFromDetails
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const route = useRoute<NavigationParams<ScreenNames.Summary>>()
    const [isAuthorized] = useIsAuthorizedAtom()
    const isMobile = useIsWithinBreakpoints(Breakpoint.XS, Breakpoint.MD)
    const navigation = useNavigation<StackProps<ScreenNames>>()
    const breadcrumbsLinks = isAuthorized && !authenticationView
        ? breadcrumbs
        : [
            ...breadcrumbs,
            {
                text: T.components.bookingAuth.breadcrumbText,
                screenName: ScreenNames.Auth
            }
        ]
    const activeIndex = breadcrumbsLinks.findIndex(item => {
        if (authenticationView) {
            return item.screenName === ScreenNames.Auth
        }

        return route.name === item.screenName
    })
    const separatorSize = breadcrumbsLinks.length > 2 ? 3 : 10

    return (
        <View style={styles.container}>
            {breadcrumbsLinks.map((item, index) => {
                const isLast = index === breadcrumbsLinks.length - 1
                const isActive = index === activeIndex
                const isDisabled = isActive || index > activeIndex
                const fontColor = isActive
                    ? theme.colors.orange
                    : isDisabled
                        ? theme.colors.darkSilver
                        : theme.colors.night

                return (
                    <Touchable
                        key={index}
                        disabled={isDisabled}
                        onPress={() => {
                            if (onBackFromDetails && index === breadcrumbsLinks.length - 2 && authenticationView) {
                                return onBackFromDetails()
                            }

                            if (navigation.canGoBack()) {
                                return navigation.goBack()
                            }

                            navigation.replace(item.screenName as BookingsScreens, {
                                postalcode: route.params.postalcode,
                                service: String(route.params.supplyId)
                            })
                        }}
                    >
                        <View style={styles.item}>
                            <Typography.Error
                                style={styles.index}
                                forceColor={fontColor}
                            >
                                {String(index + 1).padStart(2, '0')}
                            </Typography.Error>
                            <Typography.Regular
                                bold
                                forceColor={fontColor}
                                style={!isDisabled || isActive
                                    ? {
                                        ...styles.underline,
                                        borderColor: fontColor,
                                        ...(breadcrumbsLinks.length > 3 ? styles.capitalizeLetter : {})
                                    }
                                    : {
                                        ...(breadcrumbsLinks.length > 3 ? styles.capitalizeLetter : {})
                                    }
                                }
                            >
                                {breadcrumbsLinks.length > 3
                                    ? item.text.split(' ').join('\n')
                                    : item.text
                                }
                            </Typography.Regular>
                            {!isLast && !isMobile && (
                                <View style={styles.separator}>
                                    {[...new Array(separatorSize)].map((item, index) => (
                                        <View
                                            key={index}
                                            style={styles.dot}
                                        />
                                    ))}
                                </View>
                            )}
                        </View>
                    </Touchable>
                )
            })}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        flexDirection: {
            lg: 'row',
            xs: 'column'
        },
        marginTop: theme.utils.gap(2),
        marginBottom: {
            lg: theme.utils.gap(9),
            xs: theme.utils.gap(1)
        }
    },
    item: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: {
            lg: 0,
            xs: theme.utils.gap(1)
        }
    },
    index: {
        marginRight: theme.utils.gap(1)
    },
    underline: {
        borderBottomWidth: {
            lg: 1,
            xs: 0
        }
    },
    separator: {
        flexDirection: 'row',
        marginHorizontal: {
            lg: theme.utils.gap(2),
            xs: theme.utils.gap(1)
        }
    },
    dot: {
        width: 2,
        height: 2,
        borderRadius: 1,
        marginHorizontal: 2,
        backgroundColor: theme.colors.darkSilver
    },
    capitalizeLetter: {
        textTransform: 'capitalize'
    }
}))
