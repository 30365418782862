export enum AnalyticsEventDescription {
    EmailSignIn = 'Email login called',
    EmailSignUp = 'Email register called',
    FacebookSignIn = 'Facebook login called',
    FacebookSignUp = 'Facebook register called',
    AppleSignIn = 'Apple login called',
    AppleSignUp = 'Apple register called',
    GoogleSignIn = 'Google login called',
    GoogleSignUp = 'Google register called',
    ForgotPassword = 'User clicked on forgot password',
    OTPSent = 'OTP sent to the user',
    ResendOTP = 'OTP was resend',
    ConfirmOTP = 'OTP confirmation called',
    OTPError = 'An error occurred while sending OTP to the user',
    ServiceClicked = 'User clicked a service',
    CreditCardAdded = 'User added new credit card',
    CreditCardNotAdded = 'An error occurred while creating new credit card',
    ChatOpened = 'User opened chat',
    PhoneClicked = 'User clicked on supplier phone',
    JobClicked = 'User clicked on a job',
    AddressAdded = 'User added new address',
    Geocoding = 'User fetched coordinates of address',
    SendPayCredits = 'User clicked on buy purchase credits',
    SendPayCreditsFailed = 'An error occured while purchasing send pay credits'
}
