import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { useForm } from '@codegateinc/react-form-builder-v2'
import { SupplyID } from 'lib/types'
import { isNative } from 'lib/common'
import { bookingHelpers } from 'lib/utils'
import { Breakpoint, createStyles } from 'lib/styles'
import { Segment, ServiceType } from 'lib/analytics'
import { useBookingDateCustomValueFormat, useInitializeAddress, useIsWithinBreakpoints, useMockedHours, useSegmentSession, useStyles, useTranslations } from 'lib/hooks'
import { NavigationParams, NavigationProps, ScreenNames } from 'lib/routing'
import { Adapter, Address, Button, FormComponents, Grid, MediaQuery, Sticky, Typography } from 'lib/components'
import { getServiceStaticConfig } from 'features/bookings/utils'
import { BookingCancellationFees, BookingDateModal, TaskAndErrandsComponents, MobileSummary, Rated, ServiceDescription, ServiceLayout, TotalPriceComponent, PriceSummaryComponent } from '../components'
import { TaskAndErrandsFields, TaskAndErrandsFormShape, useTaskAndErrandsForm } from '../forms'
import { taskAndErrandsHooks, useJobQuotation, useResetFormCallback } from '../hooks'
import { TaskAndErrandServices, TaskAndErrandsConfig } from '../types'

type TaskAndErrandsScreenProps = {
    route: NavigationParams<ScreenNames.TaskAndErrands>
    navigation: NavigationProps<ScreenNames.TaskAndErrands>
}

export const TaskAndErrandsScreen: React.FunctionComponent<TaskAndErrandsScreenProps> = ({
    route
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const navigation = useNavigation()
    const { segmentSession } = useSegmentSession()
    const mockedHours = useMockedHours(0.5, 8)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isHelpModalOpen, setHelpModalOpen] = useState(false)
    const breadcrumbs = taskAndErrandsHooks.useTaskAndErrandsBreadcrumbs()
    const isMobile = useIsWithinBreakpoints(Breakpoint.XS, Breakpoint.MD)
    const config: TaskAndErrandsConfig = getServiceStaticConfig(SupplyID.TasksErrands)
    const [selectedOption, setSelectedOption] = useState<string>('')
    const { pricing } = config
    const taskOptions = pricing?.taskOptions?.map(item => ({
        label: item.label,
        value: item.id.toString()
    }))
    const { form, isFilled, hasError, submit, resetForm, setFieldValue } = useForm<TaskAndErrandsFormShape>(useTaskAndErrandsForm(), {
        onSuccess: form => {
            const shiftedDays = bookingHelpers.shiftBookingDays(form.bookingDays, config.min_notice_hours, form.startingDate)

            navigation.navigate(ScreenNames.TaskAndErrandsSummary, {
                form: {
                    ...form,
                    bookingDays: shiftedDays
                },
                price: totalPrice,
                postalcode: route.params?.postalcode,
                supplyId: SupplyID.TasksErrands
            })
        }
    })
    const dateCustomValueFormat = useBookingDateCustomValueFormat(form.startingDate.value, form.bookingTime.value, form.bookingDays.value)

    const [totalPrice, setTotalPrice] = useState(0)
    const { jobQuotation, requestJobQuotation } = useJobQuotation(SupplyID.TasksErrands)

    const columnWidth = isNative || isMobile
        ? undefined
        : 'calc(55% - 10px)'
    const continueDisabled = !form.bookingTime.value || !isFilled || hasError
    const formSummaryValue = Object.keys(form).reduce((acc, nextValue) => ({
        ...acc,
        [nextValue]: form[nextValue].value
    }), {} as TaskAndErrandsFormShape)

    useResetFormCallback(resetForm)

    useEffect(() => {
        setFieldValue(TaskAndErrandsFields.BookingTime, '')
    }, [form.startingDate.value])

    useInitializeAddress(true)

    useEffect(() => {
        const [currentTaskType] = pricing.taskOptions.filter(item => +item.id === +form.taskType.value.id)

        if(currentTaskType?.includeShoppingFee) {
            return setFieldValue(TaskAndErrandsFields.Purchasing, true)
        }

        setFieldValue(TaskAndErrandsFields.Purchasing, false)
        setFieldValue(TaskAndErrandsFields.OtherTaskComment, '')
    }, [form.taskType.value])

    useEffect(() => {
        if (segmentSession.id) {
            Segment.bookingStarted({
                bookingSessionId: segmentSession.id,
                postcode: route.params?.postalcode as string,
                serviceType: ServiceType.TaskErrands
            })
        }
    }, [segmentSession])

    useEffect(() => {
        const { hours, taskType } = form

        if(!hours.value || !taskType.value) {
            return
        }

        requestJobQuotation({
            numberOfHours: +hours.value,
            taskId: taskType.value.value,
            includeShoppingFee: form.purchasing.value
        })

    }, [form.hours.value, form.taskType.value, form.purchasing.value])

    useEffect(() => {
        if (!jobQuotation) {
            return
        }

        const { total } = jobQuotation

        if (!total) {
            return
        }

        setTotalPrice(total.value)

    }, [jobQuotation])

    const onChangeTaskType = value => {
        const [currentTaskType] = pricing.taskOptions.filter(item => +item.id === +value)
        const currentTaskValue = currentTaskType?.id.toString()

        if(currentTaskType) {
            currentTaskType.value = currentTaskValue
            setSelectedOption(currentTaskValue)

            return setFieldValue(TaskAndErrandsFields.TaskType, currentTaskType)
        }
    }

    return (
        <React.Fragment>
            {/* <LoadingIndicator isLoading={isLoading} /> */}
            <ServiceLayout
                onInfoPress={() => setHelpModalOpen(true)}
                title={T.screens.taskAndErrands.title}
                withBackground
                breadcrumbs={breadcrumbs}
                bottomSpacerHeight={
                    isMobile
                        ? 0
                        : 50
                }
                contentColumn={(
                    <View>
                        <MediaQuery.Hidden from={Breakpoint.LG}>
                            <View style={styles.mobileDescriptionContainer}>
                                <ServiceDescription
                                    isHelpModalOpen={isHelpModalOpen}
                                    setHelpModalOpen={setHelpModalOpen}
                                    title={T.screens.taskAndErrands.title}
                                    modalContent={(
                                        <TaskAndErrandsComponents.TaskAndErrandsHelpModalContent />
                                    )}
                                    description={T.screens.taskAndErrands.serviceDescription}
                                />
                            </View>
                        </MediaQuery.Hidden>
                        <BookingDateModal
                            title={T.screens.taskAndErrands.calendarModal.oneTime.title}
                            description={T.screens.taskAndErrands.calendarModal.oneTime.description}
                            timeTitle={T.screens.taskAndErrands.calendarModal.oneTime.select.title}
                            timeDescription={T.screens.taskAndErrands.calendarModal.oneTime.select.description}
                            minHoursNotice={config.min_notice_hours}
                            onContinue={() => setIsModalOpen(false)}
                            isModalOpen={isModalOpen}
                            frequency={form.frequency.value}
                            startingDateField={form.startingDate}
                            bookingTimeField={form.bookingTime}
                            bookingDaysField={form.bookingDays}
                            onClose={() => setIsModalOpen(false)}
                        />
                        <FormComponents.FormRow>
                            <View
                                style={{
                                    width: columnWidth
                                }}
                            >
                                <Adapter.DateAndTime
                                    {...form.startingDate}
                                    customValueFormat={dateCustomValueFormat}
                                    onPress={() => setIsModalOpen(true)}
                                />
                            </View>
                        </FormComponents.FormRow>
                        <FormComponents.FormRow>
                            <Adapter.OptionsRadio
                                {...form.hours}
                                options={mockedHours}
                                onChangeValue={value => {
                                    Segment.bookingHoursSelected({
                                        hours: Number(value)
                                    })

                                    form.hours.onChangeValue(value)
                                }}
                                maxOptionsPerRow={isMobile ? 3 : undefined}
                                initialAmountToShow={isMobile ? 5 : undefined}
                            />
                            <Typography.Error forceColor={theme.colors.fog}>
                                {T.screens.taskAndErrands.formFields.hours.description}
                            </Typography.Error>
                        </FormComponents.FormRow>
                        <View style={styles.zIndex}>
                            <FormComponents.FormRow title={T.screens.taskAndErrands.formFields.taskType.label}>
                                <View style={styles.propertySelectWrapper}>
                                    <View style={styles.row}>
                                        <View style={styles.propertySelectWrapper}>
                                            <Adapter.SelectInputColumn
                                                {...form.taskType}
                                                placeholderContainerStyles={styles.resetMargin}
                                                value={selectedOption}
                                                options={taskOptions}
                                                onChangeValue={value => {
                                                    onChangeTaskType(value)
                                                }}
                                                onOptionSelect={value => {
                                                    Segment.bookingTaskOrErrandTypeSelected({
                                                        type: value.label
                                                    })
                                                }}
                                            />
                                        </View>
                                        <View style={styles.spacer} />
                                        <View style={styles.checkBoxWrapper}>
                                            <Adapter.Checkbox
                                                {...form.purchasing}
                                                checkBoxContainerStyle={styles.checkBoxContainerStyle}
                                                renderComponent={() => (
                                                    <Typography.Regular>
                                                        {pricing.shoppingFee.label}
                                                        <Typography.Regular forceColor={theme.colors.mouse}>
                                                            {` + ${pricing.shoppingFee.value}`}
                                                        </Typography.Regular>
                                                    </Typography.Regular>
                                                )}
                                                onChangeValue={value => {
                                                    if (value) {
                                                        Segment.bookingPurchasingServicesSelected({})
                                                    }

                                                    form.purchasing.onChangeValue(value)
                                                }}
                                            />
                                        </View>
                                    </View>
                                </View>
                                <Grid.Gap gapBottom={isNative ? 1 : 0} />
                                <Typography.Error forceColor={theme.colors.fog}>
                                    {T.screens.taskAndErrands.formFields.taskType.description}
                                </Typography.Error>
                                <Grid.Gap gapBottom={2} />
                                {form.taskType?.value?.value === TaskAndErrandServices.Other && (
                                    <React.Fragment>
                                        <Typography.Regular bold>
                                            {form.otherTaskComment?.label}
                                        </Typography.Regular>
                                        <Grid.Gap gapBottom={1} />
                                        <Adapter.TextInput
                                            inputProps={{
                                                multiline: true
                                            }}
                                            {...form.otherTaskComment}
                                        />
                                    </React.Fragment>
                                )}
                                {/* TODO Hide for now */}
                                {/* <View
                                    style={{
                                        width: columnWidth
                                    }}
                                >
                                    <View style={styles.selectWrapper}>
                                        <Adapter.SelectInputColumn
                                            {...form.vehicle}
                                            isClearable={false}
                                            options={Object.values(T.screens.taskAndErrands.vehicle).map(item => ({
                                                value: item,
                                                label: item
                                            }))}
                                        />
                                    </View>
                                </View> */}
                            </FormComponents.FormRow>
                        </View>
                    </View>
                )}
                summaryColumn={(
                    <MediaQuery.Visible from={Breakpoint.LG}>
                        <ServiceDescription
                            isHelpModalOpen={isHelpModalOpen}
                            setHelpModalOpen={setHelpModalOpen}
                            title={T.screens.taskAndErrands.title}
                            modalContent={(
                                <TaskAndErrandsComponents.TaskAndErrandsHelpModalContent />
                            )}
                            description={T.screens.taskAndErrands.serviceDescription}
                        />
                        <Sticky>
                            <Address />
                            <TaskAndErrandsComponents.TaskAndErrandsSummaryColumn
                                renderPriceComponent={() => (
                                    <TotalPriceComponent
                                        totalPrice={jobQuotation?.total?.value || 0}
                                    />
                                )}
                                renderPriceSummaryComponent={() => jobQuotation?.priceSummary ? (
                                    <PriceSummaryComponent priceSummary={jobQuotation?.priceSummary} promotion={jobQuotation.discount} />
                                ) : undefined}
                                form={formSummaryValue}
                            />
                            <Button
                                testID="confirm-booking-requirement-button"
                                onPress={submit}
                                disabled={continueDisabled}
                                text={T.common.continue}
                            />
                            <BookingCancellationFees />
                        </Sticky>
                    </MediaQuery.Visible>
                )}
                footer={(
                    <MediaQuery.Hidden from={Breakpoint.LG}>
                        <MobileSummary
                            submit={submit}
                            price={totalPrice}
                            priceLabel={T.common.total}
                            disabled={continueDisabled}
                            // isLoading={isLoading}
                            content={(
                                <React.Fragment>
                                    <Address />
                                    <TaskAndErrandsComponents.TaskAndErrandsSummaryColumn
                                        renderPriceComponent={() => (
                                            <TotalPriceComponent
                                                totalPrice={jobQuotation?.total?.value || 0}
                                            />
                                        )}
                                        renderPriceSummaryComponent={() => jobQuotation?.priceSummary ? (
                                            <PriceSummaryComponent priceSummary={jobQuotation?.priceSummary} promotion={jobQuotation.discount} />
                                        ) : undefined}
                                        form={formSummaryValue}
                                    />
                                    <Rated />
                                    <BookingCancellationFees />
                                </React.Fragment>
                            )}
                        />
                    </MediaQuery.Hidden>
                )}
            />
        </React.Fragment>
    )
}

const stylesheet = createStyles(theme => ({
    row: {
        alignItems: {
            lg: 'center',
            xs: undefined
        },
        flexDirection: {
            lg: 'row',
            xs: 'column'
        }
    },
    zIndex: {
        zIndex: theme.zIndex[10]
    },
    spacer: {
        width: theme.utils.gap(1)
    },
    selectWrapper: {
        flex: 1
    },
    checkBoxWrapper: {
        flex: 1,
        marginBottom: -theme.utils.gap(4),
        marginVertical: {
            lg: undefined,
            xs: theme.utils.gap(1)
        }
    },
    propertySelectWrapper: {
        flex: 1,
        zIndex: theme.zIndex[10]
    },
    mobileDescriptionContainer: {
        backgroundColor: theme.colors.marble,
        marginHorizontal: -theme.utils.gap(2),
        marginBottom: theme.utils.gap(2),
        paddingHorizontal: theme.utils.gap(2),
        paddingTop: theme.utils.gap(4)
    },
    resetMargin: {
        marginTop: 0
    },
    checkBoxContainerStyle: {
        flexDirection: 'row-reverse',
        justifyContent: 'space-between'
    }
}))
