import React from 'react'
import { View } from 'react-native'
import { Icons } from 'assets'
import { Modal } from 'lib/components'
import { useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'

type SendPayPaymentErrorModalProps = {
    isOpen: boolean,
    onClose: VoidFunction,
    onConfirm: VoidFunction,
}

export const SendPayPaymentErrorModal: React.FunctionComponent<SendPayPaymentErrorModalProps> = ({
    isOpen,
    onConfirm,
    onClose
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)

    return (
        <Modal
            renderIcon={() => (
                <View
                    style={{
                        ...styles.icon,
                        transform: [
                            {
                                rotateZ: '45deg'
                            }
                        ]
                    }}
                >
                    <Icons.PlusCircle
                        size={30}
                        forceColor={theme.colors.orange}
                    />
                </View>
            )}
            isOpen={isOpen}
            buttonWidth={180}
            onClose={onClose}
            onConfirm={onConfirm}
            title={T.components.sendPayModal.error.title}
            message={T.components.sendPayModal.error.message}
            confirmText={T.common.tryAgain}
        />
    )
}

const stylesheet = createStyles(theme => ({
    icon: {
        marginBottom: theme.utils.gap(2)
    }
}))
