import React from 'react'
import { View } from 'react-native'
import { useStyles, useTranslations } from 'lib/hooks'
import { JobDetails } from 'lib/models'
import { Button, LoadingIndicator, Typography } from 'lib/components'
import { createStyles } from 'lib/styles'
import { ChatJobDetails } from './ChatJobDetails'

type ChatSideMenuProps = {
    job?: JobDetails,
    isLoading: boolean,
    hasError: boolean,
    onRefetch: VoidFunction
}

export const ChatSideMenu: React.FunctionComponent<ChatSideMenuProps> = ({
    job,
    isLoading,
    hasError,
    onRefetch
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)

    return (
        <View style={styles.container}>
            {job && (
                <ChatJobDetails job={job} />
            )}
            {(isLoading || hasError && !job) && (
                <View style={styles.loader}>
                    <LoadingIndicator isLoading={isLoading} />
                    {hasError && (
                        <React.Fragment>
                            <Typography.Label
                                style={styles.error}
                                forceColor={theme.colors.softBlueGrey}
                            >
                                {T.screens.chat.sideMenu.fetchError}
                            </Typography.Label>
                            <Button
                                width={140}
                                onPress={onRefetch}
                                text={T.common.tryAgain}
                            />
                        </React.Fragment>
                    )}
                </View>
            )}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        flex: 1,
        marginTop: theme.utils.gap(6),
        paddingLeft: theme.utils.gap(2)
    },
    loader: {
        height: 400,
        width: '100%',
        borderWidth: 1,
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.utils.gap(2),
        borderColor: theme.colors.silver,
        borderRadius: theme.utils.gap(1),
        ...theme.utils.createShadow(4, 0.04, 8, 8, 2)
    },
    error: {
        textAlign: 'center',
        marginBottom: theme.utils.gap(2)
    }
}))
