import React from 'react'
import Animated, { FadeOut } from 'react-native-reanimated'
import { ActivityIndicator, View } from 'react-native'
import { createStyles } from 'lib/styles'
import { useStyles } from 'lib/hooks'

type LoadingIndicatorProps = {
    isLoading: boolean,
    withBackground?: boolean,
}

export const LoadingIndicator: React.FunctionComponent<LoadingIndicatorProps> = ({
    isLoading,
    withBackground = true
}) => {
    const { styles, theme } = useStyles(stylesheet)

    if (!isLoading) {
        return null
    }

    return (
        <Animated.View
            style={styles.activityIndicatorContainer}
            exiting={FadeOut.duration(250)}
        >
            <View
                style={{
                    ...styles.activityIndicatorWrapper,
                    backgroundColor: withBackground
                        ? theme.colors.white
                        : undefined
                }}
            >
                <ActivityIndicator color={theme.colors.orange} />
            </View>
        </Animated.View>
    )
}

const stylesheet = createStyles(theme => ({
    activityIndicatorContainer: {
        zIndex: theme.zIndex[10],
        position: 'absolute',
        alignItems: 'center',
        justifyContent: 'center',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0
    },
    activityIndicatorWrapper: {
        borderRadius: 8,
        paddingVertical: theme.utils.gap(2),
        paddingHorizontal: theme.utils.gap(2),
        ...theme.utils.createShadow(0, 0.8, 8, 8)
    }
}))
