const enum DISTRICT {
    D01 = 'D1. City - Marina Area',
    D02 = 'D2. City - CBD',
    D03 = 'D3. Central South',
    D04 = 'D4. South - Keppel',
    D05 = 'D5. South West',
    D06 = 'D6. City - City Hall',
    D07 = 'D7. City- Beach Road',
    D08 = 'D8. Central - Little India',
    D09 = 'D9. Central - Orchard',
    D10 = 'D10. Central: Tanglin',
    D11 = 'D11. Central - Newton',
    D12 = 'D12. Central - Toa Payoh',
    D13 = 'D13. Central East',
    D14 = 'D14. Central East - Eunos',
    D15 = 'D15. East Coast - Katong',
    D16 = 'D16. Upper East Coast',
    D17 = 'D17. Far East - Changi',
    D18 = 'D18. Far East - Tampines',
    D19 = 'D19. North East - Hougang',
    D20 = 'D20. Central North - AMK',
    D21 = 'D21. Central West',
    D22 = 'D22. Far West - Jurong',
    D23 = 'D23. North West',
    D24 = 'D24. Far North West',
    D25 = 'D25. Far North',
    D26 = 'D26. North',
    D27 = 'D27. Far North - Yishun',
    D28 = 'D28. North East - Seletar',
}

const DISTRICTS_MAPPING: Map<string, string> = new Map([
    ['01', DISTRICT.D01],
    ['02', DISTRICT.D01],
    ['03', DISTRICT.D01],
    ['04', DISTRICT.D01],
    ['05', DISTRICT.D01],
    ['06', DISTRICT.D01],
    ['07', DISTRICT.D02],
    ['08', DISTRICT.D02],
    ['09', DISTRICT.D04],
    ['10', DISTRICT.D04],
    ['11', DISTRICT.D05],
    ['12', DISTRICT.D05],
    ['13', DISTRICT.D05],
    ['14', DISTRICT.D03],
    ['15', DISTRICT.D03],
    ['16', DISTRICT.D03],
    ['17', DISTRICT.D06],
    ['18', DISTRICT.D07],
    ['19', DISTRICT.D07],
    ['20', DISTRICT.D08],
    ['21', DISTRICT.D08],
    ['22', DISTRICT.D09],
    ['23', DISTRICT.D09],
    ['24', DISTRICT.D10],
    ['25', DISTRICT.D10],
    ['26', DISTRICT.D10],
    ['27', DISTRICT.D10],
    ['28', DISTRICT.D11],
    ['29', DISTRICT.D11],
    ['30', DISTRICT.D11],
    ['31', DISTRICT.D12],
    ['32', DISTRICT.D12],
    ['33', DISTRICT.D12],
    ['34', DISTRICT.D13],
    ['35', DISTRICT.D13],
    ['36', DISTRICT.D13],
    ['37', DISTRICT.D13],
    ['38', DISTRICT.D14],
    ['39', DISTRICT.D14],
    ['40', DISTRICT.D14],
    ['41', DISTRICT.D14],
    ['42', DISTRICT.D15],
    ['43', DISTRICT.D15],
    ['44', DISTRICT.D15],
    ['45', DISTRICT.D15],
    ['46', DISTRICT.D16],
    ['47', DISTRICT.D16],
    ['48', DISTRICT.D16],
    ['49', DISTRICT.D17],
    ['50', DISTRICT.D17],
    ['51', DISTRICT.D18],
    ['52', DISTRICT.D18],
    ['53', DISTRICT.D19],
    ['54', DISTRICT.D19],
    ['55', DISTRICT.D19],
    ['56', DISTRICT.D20],
    ['57', DISTRICT.D20],
    ['58', DISTRICT.D21],
    ['59', DISTRICT.D21],
    ['60', DISTRICT.D22],
    ['61', DISTRICT.D22],
    ['62', DISTRICT.D22],
    ['63', DISTRICT.D22],
    ['64', DISTRICT.D22],
    ['65', DISTRICT.D23],
    ['66', DISTRICT.D23],
    ['67', DISTRICT.D23],
    ['68', DISTRICT.D23],
    ['69', DISTRICT.D24],
    ['70', DISTRICT.D24],
    ['71', DISTRICT.D24],
    ['72', DISTRICT.D25],
    ['73', DISTRICT.D25],
    ['74', DISTRICT.D25],
    ['75', DISTRICT.D27],
    ['76', DISTRICT.D27],
    ['77', DISTRICT.D26],
    ['78', DISTRICT.D26],
    ['79', DISTRICT.D28],
    ['80', DISTRICT.D28],
    ['81', DISTRICT.D17],
    ['82', DISTRICT.D19]
])

export const getDistrictFromPostcode = (postcode: string) => {
    const postCodePrefix = postcode.toString().substring(0, 2)
    const district = DISTRICTS_MAPPING.get(postCodePrefix)

    if (district) {
        return district
    }

    return ''
}
