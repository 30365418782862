export enum BraintreeErrorFields {
    Name = 'name',
    Number = 'number',
    CVV = 'cvv'
}

export type BraintreeError = {
    name: string,
    code: string,
    message: string,
    type: string,
    details: BraintreeErrorDetails
}

export type BraintreeErrorDetails = {
    originalError: BraintreeOriginalError,
    httpStatus: number
}

export type BraintreeOriginalError = {
    error: Error,
    fieldErrors: Array<BraintreeOriginalFieldErrors>
}

export type BraintreeOriginalFieldErrors = {
    field: string,
    fieldErrors: Array<BraintreeOriginalFieldError>
}

export type BraintreeOriginalFieldError = {
    code: string,
    field: BraintreeErrorFields,
    message: string
}

type Error = {
    message: string
}
