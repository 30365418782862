import React from 'react'
import { View, ViewStyle } from 'react-native'
import { useRoute } from '@react-navigation/native'
import { CommonTab } from 'lib/types'
import { createStyles } from 'lib/styles'
import { useStyles } from 'lib/hooks'
import { Touchable } from './Touchable'
import { Regular } from './typography'

type TabsProps = {
    customTabStyles?: ViewStyle
    tabs: Array<CommonTab>
}

export const Tabs: React.FunctionComponent<TabsProps> = ({
    tabs,
    customTabStyles = {}
}) => {
    const { styles, theme } = useStyles(stylesheet)
    const route = useRoute()

    return (
        <View style={styles.container}>
            {tabs.map((tab, index) => {
                const isActive = tab.screenName === route.name

                return (
                    <Touchable
                        key={index}
                        style={{
                            ...isActive
                                ? {
                                    ...styles.tab,
                                    ...styles.isActive
                                }
                                : styles.tab,
                            ...customTabStyles
                        }}
                        onPress={tab.onPress}
                        testID={tab.testID}
                    >
                        <Regular
                            bold={isActive}
                            forceColor={isActive
                                ? theme.colors.orange
                                : undefined
                            }
                        >
                            {tab.text}
                        </Regular>
                    </Touchable>
                )
            })}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        flexDirection: 'row',
        marginTop: {
            lg: theme.utils.gap(2),
            xs: theme.utils.gap(1)
        },
        marginBottom: theme.utils.gap(1)
    },
    tab: {
        flex: {
            lg: undefined,
            xs: 1
        },
        alignItems: 'center',
        borderBottomWidth: 1,
        borderBottomColor: theme.colors.silver,
        paddingVertical: theme.utils.gap(1),
        paddingHorizontal: {
            lg: theme.utils.gap(3),
            xs: theme.utils.gap(1)
        }
    },
    isActive: {
        borderBottomWidth: 3,
        borderBottomColor: theme.colors.orange
    }
}))
