import { useAtom } from 'jotai'
import { PromotionPopupForConsumer } from 'features/promotionPopup/types'
import { createAtom } from './atomFactory'
import { MMKVKey } from '../mmkv'

const { atom: promotionPopup, resetAtom: resetPromotionPopup } = createAtom<PromotionPopupForConsumer>({} as PromotionPopupForConsumer, MMKVKey.PromotionPopup)
const { atom: promotionPopupShownAt, resetAtom: resetPromotionPopupShownAt } = createAtom<number>(0, MMKVKey.PromotionPopupShownAt)
const { atom: shouldClearPromotionPopupShownAt, resetAtom: resetShouldClearPromotionPopupShownAt } = createAtom<boolean>(true, MMKVKey.ShouldClearPromotionPopupShownAt)

export const usePromotionPopupAtom = () => useAtom(promotionPopup)
export const useResetPromotionPopupAtom = () => useAtom(resetPromotionPopup)
export const usePromotionPopupShownAtAtom = () => useAtom(promotionPopupShownAt)
export const useResetPromotionPopupShownAtAtom = () => useAtom(resetPromotionPopupShownAt)
export const useShouldClearPromotionPopupShownAtAtom = () => useAtom(shouldClearPromotionPopupShownAt)
export const useResetShouldClearPromotionPopupShownAtAtom = () => useAtom(resetShouldClearPromotionPopupShownAt)
