import React from 'react'
import { Linking, ScrollView, View } from 'react-native'
import { dateHelpers } from 'lib/utils'
import { createStyles } from 'lib/styles'
import { useHeaderLinks, useSocialIcons, useStyles, useTranslations } from 'lib/hooks'
import { Regular } from '../../typography'
import { Touchable } from '../../Touchable'

export const MenuContent: React.FunctionComponent = () => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const links = useHeaderLinks()
    const socialLinks = useSocialIcons()

    return (
        <ScrollView
            showsVerticalScrollIndicator={false}
            contentContainerStyle={styles.container}
        >
            {links.map((link, index) => (
                <Touchable
                    key={index}
                    style={styles.link}
                    onPress={link.onPress}
                >
                    <Regular>
                        {link.text}
                    </Regular>
                </Touchable>
            ))}
            <View style={styles.socials}>
                <View style={styles.socialIcons}>
                    {socialLinks.map((icon, index) => (
                        <Touchable
                            key={index}
                            style={styles.icon}
                            onPress={() => Linking.openURL(icon.url)}
                        >
                            {icon.renderIcon()}
                        </Touchable>
                    ))}
                </View>
                <Regular>
                    {`© ${dateHelpers.currentYear()} ${T.common.sendHelper}`}
                </Regular>
            </View>
        </ScrollView>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        marginVertical: theme.utils.gap(2),
        paddingHorizontal: theme.utils.gap(2)
    },
    link: {
        borderBottomWidth: 1,
        paddingVertical: theme.utils.gap(1),
        borderColor: theme.colors.silver
    },
    socials: {
        alignItems: 'center',
        marginVertical: theme.utils.gap(3)
    },
    socialIcons: {
        flexDirection: 'row',
        marginBottom: theme.utils.gap(1)
    },
    icon: {
        marginHorizontal: theme.utils.gap(1) / 2
    }
}))
