import React from 'react'
import { View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { useForm } from '@codegateinc/react-form-builder-v2'
import { Icons } from 'assets'
import { createStyles } from 'lib/styles'
import { Adapter, Button, ListBottomSpacer, Typography, Grid } from 'lib/components'
import { useToastAtom } from 'lib/atoms'
import { ScreenNames } from 'lib/routing'
import { NotificationType } from 'lib/types'
import { useBackHandler, useGetErrorMessage, useStyles, useTranslations } from 'lib/hooks'
import { NewPasswordFields, NewPasswordFormShape, useNewPasswordForm } from '../forms'
import { CancelButton } from './CancelButton'
import { useForgotPassword } from '../actions'
import { ChangePasswordAction } from '../types'
import { isNative } from 'lib/common'

type NewPasswordFormProps = {
    token: string,
    tempPassword: string
}

export const NewPasswordForm: React.FunctionComponent<NewPasswordFormProps> = ({
    token,
    tempPassword
}) => {
    const T = useTranslations()
    const navigation = useNavigation()
    const { styles } = useStyles(stylesheet)
    const [, setToastMessage] = useToastAtom()
    const { getErrorMessage } = useGetErrorMessage()
    const { mutate: changePassword, isLoading } = useForgotPassword()
    const { form, submit, isFilled, hasError, setError } = useForm<NewPasswordFormShape>(useNewPasswordForm(), {
        onSuccess: ({ newPassword }) => changePassword({
            token,
            action: ChangePasswordAction.SetNewPassword,
            new_password: newPassword,
            temp_password: tempPassword
        }, {
            onSuccess: () => {
                setToastMessage({
                    message: T.screens.authNewPassword.successMessage,
                    type: NotificationType.Success
                })
                navigation.reset({
                    index: 0,
                    routes: [
                        {
                            name: isNative ? ScreenNames.LogInNative : ScreenNames.Auth
                        }
                    ]
                })
            },
            onError: response => setError(NewPasswordFields.ConfirmNewPassword, getErrorMessage(response))
        })
    })

    useBackHandler(() => isLoading)

    return (
        <View style={styles.container}>
            <View style={styles.row}>
                <Typography.SmallSubheading style={styles.textCenter}>
                    {T.screens.authNewPassword.verifyTitle}
                </Typography.SmallSubheading>
            </View>
            <View style={styles.row}>
                <Typography.Regular style={styles.textCenter}>
                    {T.screens.authNewPassword.verifyMessage}
                </Typography.Regular>
            </View>
            <View style={styles.formInput}>
                <Adapter.TextInput
                    {...form.newPassword}
                    disabled={isLoading}
                    inputProps={{
                        autoCorrect: false,
                        textContentType: 'password',
                        autoComplete: 'password',
                        secureTextEntry: true
                    }}
                />
                <Adapter.TextInput
                    {...form.confirmNewPassword}
                    disabled={isLoading}
                    inputProps={{
                        autoCorrect: false,
                        textContentType: 'password',
                        autoComplete: 'password',
                        secureTextEntry: true
                    }}
                />
            </View>
            <View style={styles.submit}>
                <Button
                    onPress={submit}
                    isLoading={isLoading}
                    disabled={!isFilled || hasError}
                    text={T.screens.authNewPassword.submit}
                    renderIcon={color => (
                        <Icons.Arrow
                            size={14}
                            forceColor={color}
                        />
                    )}
                />
            </View>
            <Grid.Gap gapTop={2}>
                <CancelButton isDisabled={isLoading} />
            </Grid.Gap>
            <ListBottomSpacer />
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        marginTop: {
            lg: 0,
            xs: theme.utils.gap(3)
        }
    },
    textCenter: {
        textAlign: 'center'
    },
    row: {
        marginBottom: theme.utils.gap(3)
    },
    formInput: {
        maxWidth: {
            lg: 400,
            xs: '100%'
        },
        width: '100%',
        alignSelf: 'center',
        marginBottom: theme.utils.gap(2)
    },
    submit: {
        maxWidth: {
            lg: 400,
            xs: '100%'
        },
        width: '100%',
        alignSelf: 'center',
        marginBottom: theme.utils.gap(2)
    }
}))
