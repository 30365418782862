import { TextInputProps, View } from 'react-native'
import { Subject } from 'rxjs'
import { debounceTime } from 'rxjs/operators'
import React, { useEffect, useMemo, useState } from 'react'
import { createStyles } from 'lib/styles'
import { useStyles } from 'lib/hooks'
import { IconProps, KeyValuePair } from 'lib/types'
import { R } from 'lib/utils'
import { Icons } from 'assets'
import { Touchable } from 'lib/components'
import { isNative, isWeb } from 'lib/common'

type InputProps = {
    label?: string,
    errorMessage?: string,
    inputProps: TextInputProps,
    leftIcon?: React.FunctionComponent<IconProps>,
    rightIcon?: React.FunctionComponent<IconProps>,
    onDebounceEnd?(inputValue: string): void
}

export const useTextInput = ({
    inputProps,
    errorMessage,
    onDebounceEnd,
    leftIcon,
    rightIcon,
    label
}: InputProps) => {
    const { styles, theme } = useStyles(stylesheet)
    const [previewPassword, setPreviewPassword] = useState(false)
    const [shouldRenderCloseButton, setRenderCloseButton] = useState(false)
    const iconColor = errorMessage
        ? theme.components.input.icon.error
        : theme.components.input.icon.regular
    const LeftIcon = leftIcon ? React.createElement<IconProps>(leftIcon, {
        forceColor: iconColor,
        size: 20
    }) : null
    const RightIcon = rightIcon ? React.createElement<IconProps>(rightIcon, {
        forceColor: iconColor,
        size: 20
    }) : null

    const getBorderColor = () => {
        if (errorMessage) {
            return theme.components.input.error.borderColor
        }

        if (inputProps.value?.length === 0) {
            return theme.components.input.emptyBorderColor
        }

        return theme.components.input.filledBorderColor
    }
    const getTextColor = () => {
        if (errorMessage) {
            return theme.components.input.typography.error
        }

        if (!inputProps.editable) {
            return theme.components.input.typography.placeholder
        }

        return theme.components.input.typography.text
    }
    const inputStream$ = useMemo(() => onDebounceEnd && new Subject(), [onDebounceEnd])
    const multilineStyles = inputProps.multiline
        ? styles.multiline
        : {}

    useEffect(() => {
        const subscribe = inputStream$?.pipe(
            debounceTime(500)
        ).subscribe(value => {
            R.ifDefined(onDebounceEnd, fn => fn(value))
        })

        return () => {
            subscribe?.unsubscribe()
        }
    }, [onDebounceEnd, inputStream$])

    return {
        styles,
        label,
        errorMessage,
        inputProps: {
            underlineColorAndroid: theme.ui.transparent,
            placeholderTextColor: errorMessage
                ? theme.components.input.typography.error
                : theme.components.input.typography.placeholder,
            ...inputProps,
            secureTextEntry: previewPassword
                ? false
                : inputProps.secureTextEntry,
            style: {
                ...styles.input,
                ...multilineStyles,
                borderColor: getBorderColor(),
                color: getTextColor(),
                backgroundColor: inputProps.editable
                    ? theme.components.input.backgroundColor
                    : theme.components.input.disabled.backgroundColor,
                paddingRight: (shouldRenderCloseButton ? 40 : 20) + (isNative && inputProps.secureTextEntry ? 40 : 0),
                paddingLeft: LeftIcon ? 50 : 20,
                ...(inputProps.style as KeyValuePair) || {}
            },
            clearButtonMode: 'never',
            allowFontScaling: false,
            onChangeText: text => {
                inputStream$?.next(text)
                setRenderCloseButton(R.all(
                    !R.isDefined(inputProps.editable) || Boolean(inputProps.editable),
                    text.length > 0, !rightIcon
                ))
                R.ifDefined(inputProps.onChangeText, onChangeText => onChangeText(text))
            },
            onSubmitEditing: () => {
                R.ifDefined(inputProps.onSubmitEditing, fn => fn(inputProps.value))
            },
            onFocus: event => {
                if (inputProps.value && inputProps.value.length > 0 && !rightIcon) {
                    setRenderCloseButton(true)
                }

                R.ifDefined(inputProps.onFocus, onFocus => onFocus(event))
            },
            onBlur: event => {
                if (!isWeb && !inputProps.value) {
                    setRenderCloseButton(false)
                }

                R.ifDefined(inputProps.onBlur, onBlur => onBlur(event))
            }
        },
        renderLeftIcon: () => {
            if (leftIcon) {
                return (
                    <View style={styles.leftIconWrapper}>
                        <View style={styles.leftIcon}>
                            {LeftIcon}
                        </View>
                    </View>
                )
            }

            return null
        },
        renderRightIcon: () => {
            if (rightIcon) {
                return (
                    <View style={styles.rightIconWrapper}>
                        <View style={styles.leftIcon}>
                            {RightIcon}
                        </View>
                    </View>
                )
            }

            return null
        },
        renderClearButton: () => {
            if (isWeb) {
                return null
            }

            if (shouldRenderCloseButton && !(inputProps.editable === false)) {
                return (
                    <Touchable
                        focusable={false}
                        style={
                            inputProps.multiline
                                ? {
                                    ...styles.clearIcon,
                                    ...styles.multiLineHeight
                                }
                                : {
                                    ...styles.clearIcon,
                                    ...(inputProps.secureTextEntry
                                        ? {
                                            right: 40
                                        }
                                        : {}
                                    )
                                }
                        }
                        onPress={() => {
                            setRenderCloseButton(false)
                            inputStream$?.next('')
                            R.ifDefined(inputProps.onChangeText, onChangeText => onChangeText(''))
                        }}
                    >
                        <View style={styles.clearIconInnerView}>
                            <Icons.Close size={7} />
                        </View>
                    </Touchable>
                )
            }

            return null
        },
        renderPreviewPasswordButton: () => {
            if (inputProps.secureTextEntry) {
                const hasValue = inputProps.value
                    ? inputProps.value.length > 0
                    : false

                return (
                    <Touchable
                        focusable={false}
                        style={{
                            ...styles.viewPasswordIcon,
                            right: rightIcon ? 35 : 18
                        }}
                        onPress={() => setPreviewPassword(prevState => !prevState)}
                    >
                        <View>
                            {!previewPassword
                                ? (
                                    <Icons.Eye
                                        size={20}
                                        forceColor={hasValue ? theme.colors.night : iconColor}
                                    />
                                ) : (
                                    <Icons.EyeDisabled
                                        size={20}
                                        forceColor={hasValue ? theme.colors.night : iconColor}
                                    />
                                )
                            }
                        </View>
                    </Touchable>
                )
            }

            return null
        }
    }
}

const stylesheet = createStyles(theme => ({
    container: {
        width: '100%'
    },
    leftIconWrapper: {
        position: 'absolute',
        left: theme.utils.gap(1),
        top: 0,
        bottom: 0,
        height: theme.components.input.height,
        justifyContent: 'center',
        elevation: 19
    },
    rightIconWrapper: {
        position: 'absolute',
        right: theme.utils.gap(1),
        top: 0,
        bottom: 0,
        height: theme.components.input.height,
        justifyContent: 'center',
        elevation: 19
    },
    leftIcon: {
        paddingHorizontal: theme.utils.gap(1),
        paddingVertical: theme.utils.gap(1)
    },
    input: {
        paddingLeft: theme.utils.gap(2),
        height: theme.components.input.height,
        width: '100%',
        borderRadius: theme.components.input.borderRadius,
        borderWidth: theme.components.input.borderWidth,
        paddingHorizontal: theme.components.input.padding,
        textAlignVertical: 'center'
    },
    clearIcon: {
        position: 'absolute',
        right: 5,
        paddingHorizontal: theme.utils.gap(1),
        height: theme.components.input.height,
        alignItems: 'center',
        justifyContent: 'center',
        elevation: 19
    },
    multiLineHeight: {
        height: 100
    },
    multiline: {
        height: 100,
        textAlignVertical: 'top',
        borderRadius: theme.components.input.borderRadius,
        paddingTop: theme.utils.gap(2),
        paddingBottom: theme.utils.gap(2)
    },
    clearIconInnerView: {
        width: 15,
        height: 15,
        borderRadius: theme.utils.gap(1),
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.colors.silver
    },
    viewPasswordIcon: {
        position: 'absolute',
        top: 3,
        bottom: 0,
        height: theme.components.input.height,
        justifyContent: 'center',
        elevation: 19
    },
    extraContent: {
        zIndex: theme.zIndex[10],
        height: undefined,
        borderBottomWidth: 0,
        borderBottomLeftRadius: 1,
        borderBottomRightRadius: 1,
        marginBottom: -theme.components.input.borderRadius,
        backgroundColor: theme.colors.white
    }
}))
