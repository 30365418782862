import React from 'react'
import { useTranslations } from 'lib/hooks'
import { AppLayout, Tabs } from 'lib/components'
import { NavigationProps, ScreenNames } from 'lib/routing'
import { UserNotificationPreferencesSection } from '../components'
import { useUserProfileTabs } from '../hooks'

type UserPreferencesScreenProps = {
    navigation: NavigationProps<ScreenNames.UserPreferences>
}

export const UserPreferencesScreen: React.FunctionComponent<UserPreferencesScreenProps> = () => {
    const T = useTranslations()
    const userProfileTabs = useUserProfileTabs()

    return (
        <AppLayout title={T.screens.user.title}>
            <Tabs tabs={userProfileTabs} />
            <UserNotificationPreferencesSection />
        </AppLayout>
    )
}

