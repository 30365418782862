import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const DateAndTime: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M77.334 9.233c-1.7-1.666-3.933-2.566-6.333-2.566h-7.7V3.333C63.3 1.5 61.8 0 59.967 0a3.343 3.343 0 0 0-3.333 3.333v3.334h-33.3V3.333C23.334 1.5 21.834 0 20.001 0a3.343 3.343 0 0 0-3.334 3.333v3.334h-7.7c-2.366 0-4.633.9-6.333 2.566A8.755 8.755 0 0 0-.033 15.5v52.333c0 2.334.967 4.634 2.667 6.267 1.7 1.667 3.933 2.567 6.333 2.567h62.067c2.367 0 4.633-.9 6.3-2.567a8.755 8.755 0 0 0 2.667-6.267V15.5a8.755 8.755 0 0 0-2.667-6.267Zm-4 58.6c0 .567-.233 1.1-.667 1.5a2.342 2.342 0 0 1-1.666.667H8.967a2.342 2.342 0 0 1-1.666-.667 2.12 2.12 0 0 1-.634-1.5V15.5c0-.567.234-1.1.634-1.5a2.343 2.343 0 0 1 1.666-.667h7.7V20c0 1.833 1.5 3.333 3.334 3.333 1.833 0 3.333-1.5 3.333-3.333v-6.667h33.334V20c0 1.833 1.5 3.333 3.333 3.333 1.833 0 3.333-1.5 3.333-3.333v-6.667h7.7c.633 0 1.233.234 1.667.667.433.4.667.933.667 1.5v52.333h-.034Z"
        />
        <Path d="M20 40a5.7 5.7 0 1 0 0-11.4A5.7 5.7 0 0 0 20 40ZM40 40a5.7 5.7 0 1 0 0-11.4A5.7 5.7 0 0 0 40 40ZM60 40a5.7 5.7 0 1 0 0-11.4A5.7 5.7 0 0 0 60 40Z" />
        <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20 51.433a5.698 5.698 0 0 0-5.7 5.7c0 3.133 2.567 5.7 5.7 5.7 3.134 0 5.7-2.567 5.7-5.7s-2.566-5.7-5.7-5.7ZM40 51.433a5.698 5.698 0 0 0-5.7 5.7c0 3.133 2.567 5.7 5.7 5.7s5.7-2.567 5.7-5.7-2.567-5.7-5.7-5.7Z"
        />
    </Icon>
)
