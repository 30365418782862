import React, { useState } from 'react'
import { View } from 'react-native'
import { Grid, MediaQuery, Typography } from 'lib/components'
import { useStyles, useTranslations } from 'lib/hooks'
import { Breakpoint, createStyles } from 'lib/styles'
import { JobStatus, LaundryStatus } from 'lib/types'
import { JobDetailsResponse } from '../../types'
import { AdditionalInformation } from '../AdditionalInformation'
import { LaundryMilestones } from '../LaundryMilestones'
import { CancelRequest } from '../CancelRequest'
import { JobName } from '../JobName'
import { JobRating } from '../JobRating'
import { ServiceLocation } from '../ServiceLocation'
import { SubmittedMessage } from '../SubmittedMessage'
import { JobRatingModal } from '../JobRatingModal'
import { LaundryDetailsAccepted } from './LaundryDetailsAccepted'
import { LaundryServiceDate } from './LaundryServiceDate'

type LaundryDetailsContentProps = {
    data: JobDetailsResponse,
    jobStatus?: JobStatus
}

export const LaundryDetailsContent: React.FunctionComponent<LaundryDetailsContentProps> = ({
    data,
    jobStatus
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const [isRatingDismissed, setRatingDismiss] = useState(false)
    const isSubmitted = data.job.status === JobStatus.Submitted
    // updated due to the gap in values between picked up and ready statuses
    const updatedLaundryStatus = Number(data.job.laundry_options.status) >= LaundryStatus.Ready
        ? Number(data.job.laundry_options.status) - 1
        : data.job.laundry_options.status || 0

    return (
        <View style={styles.wrapper}>
            <View style={styles.contentWrapper}>
                <View style={styles.content}>
                    <JobName
                        job={data}
                        isRecurring={false}
                    />
                    {isSubmitted && (
                        <SubmittedMessage />
                    )}
                    <LaundryMilestones activeMilestone={updatedLaundryStatus + data.job.status}/>
                    <Grid.Gap gapBottom={3}/>
                    <LaundryServiceDate
                        job={data}
                        isSubmitted={isSubmitted}
                    />
                    <AdditionalInformation
                        job={data}
                        linesLimit={6} // minimum comment lines for laundry due to putting job details into comment
                    />
                    <View style={styles.packagingReminder}>
                        <Typography.Label>
                            {T.screens.laundrySummary.packingReminder}
                        </Typography.Label>
                    </View>
                    <ServiceLocation job={data.job} />
                    <CancelRequest
                        job={data}
                        text={isSubmitted
                            ? T.screens.jobDetails.cancelRequest
                            : undefined
                        }
                        jobStatus={data.job.status || jobStatus}
                    />
                </View>
                {jobStatus === JobStatus.Completed && (
                    <JobRating job={data} />
                )}
            </View>
            <MediaQuery.Visible from={Breakpoint.LG}>
                <LaundryDetailsAccepted
                    job={data}
                    jobStatus={jobStatus}
                />
            </MediaQuery.Visible>
            <JobRatingModal
                isVisible={data.job.status === JobStatus.Completed && !isRatingDismissed}
                onClose={() => setRatingDismiss(true)}
                job={data}
            />
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        flexDirection: {
            lg: 'row',
            xs: 'column'
        },
        alignItems: {
            lg: 'flex-start',
            xs: undefined
        },
        marginTop: -theme.utils.gap(6),
        paddingHorizontal: {
            lg: theme.utils.gap(6),
            xs: theme.utils.gap(2)
        }
    },
    contentWrapper: {
        flex: 1
    },
    content: {
        borderRadius: 8,
        borderWidth: 1,
        paddingHorizontal: theme.utils.gap(2),
        paddingVertical: theme.utils.gap(3),
        backgroundColor: theme.colors.white,
        borderColor: theme.colors.silver,
        backGroundColor: theme.colors.white,
        ...theme.utils.createShadow(2, 0, 8, 4, 4, theme.colors.webShadow(0.04))
    },
    packagingReminder: {
        width: '100%',
        padding: theme.utils.gap(2),
        borderRadius: theme.utils.gap(1),
        backgroundColor: theme.colors.silver,
        marginBottom: theme.utils.gap(3)
    }
}))
