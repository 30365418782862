import React from 'react'
import { View } from 'react-native'
import { createStyles } from 'lib/styles'
import { LoadingIndicator, Typography } from 'lib/components'
import { useStyles, useTranslations } from 'lib/hooks'
import { JobDetailsResponse } from 'features/jobs/types'
import { SessionList } from 'features/jobs/components/SessionList'

type SessionOverviewProps = {
    job: JobDetailsResponse
}

export const SessionOverview: React.FunctionComponent<SessionOverviewProps> = ({ job }) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)

    const sessionDetails = job?.sessionDetails ?? {}
    const completedSessions = sessionDetails?.completedSessions ?? []
    const upcomingSessions = sessionDetails?.upcomingSessions ?? []

    return (
        <View style={styles.content}>
            <LoadingIndicator isLoading={!job?.job} />
            <Typography.Regular bold>
                {T.screens.jobDetails.sessionsDetails}
            </Typography.Regular>
            <SessionList title={T.screens.jobDetails.completedSessions} sessions={completedSessions} />
            <SessionList title={T.screens.jobDetails.upcomingSessions} sessions={upcomingSessions} />
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    content: {
        borderRadius: 8,
        borderWidth: 1,
        minHeight: 100,
        padding: theme.utils.gap(2),
        backgroundColor: theme.colors.white,
        borderColor: theme.colors.silver,
        backGroundColor: theme.colors.white,
        marginBottom: theme.utils.gap(2),
        ...theme.utils.createShadow(2, 0, 8, 4, 4, theme.colors.webShadow(0.04))
    }
}))
