import React from 'react'
import { Image, View } from 'react-native'
import { useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { dateHelpers } from 'lib/utils'
import { Touchable, Typography } from 'lib/components'
import { ChatImageSize, ChatMessage, GroupChatMessage } from '../types'

interface ImageMessageProps extends ChatMessage {
    onPress(imageUrl: string): void
}

export const ImageMessage: React.FunctionComponent<ImageMessageProps> = ({
    payload,
    createdAt,
    onPress
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const { metadata: imageMetadata } = payload as GroupChatMessage
    const getImageHeight = (size: ChatImageSize) => {
        const { height, width } = size

        if (width === height) {
            return 230
        }

        if (width > height) {
            return 180
        }

        return 340
    }

    const imageHeight = imageMetadata
        ? getImageHeight(imageMetadata.size)
        : 250

    return (
        <Touchable
            disabled={!imageMetadata}
            onPress={() => {
                if (imageMetadata) {
                    onPress(imageMetadata.imageUrl)
                }
            }}
        >
            {imageMetadata?.imageUrl && (
                <Image
                    source={{ uri: imageMetadata.imageUrl }}
                    style={{
                        ...styles.image,
                        height: imageHeight
                    }}
                />
            )}
            {!imageMetadata && (
                <View style={styles.container}>
                    <Typography.Label forceColor={theme.colors.night}>
                        {T.screens.chat.imageLoadFailed}
                    </Typography.Label>
                </View>
            )}
            <Typography.Label style={styles.date}>
                {dateHelpers.selectTimeLabel(createdAt)}
            </Typography.Label>
        </Touchable>
    )
}

const stylesheet = createStyles(theme => ({
    image: {
        width: 230,
        borderRadius: 10,
        resizeMode: 'contain'
    },
    date: {
        color: theme.colors.darkGrey,
        lineHeight: 18,
        marginLeft: theme.utils.gap(1),
        alignSelf: 'flex-end',
        textAlign: 'right'
    },
    container: {
        height: 250,
        width: 250,
        justifyContent: 'center',
        alignItems: 'center'
    }
}))
