import React from 'react'
import { View } from 'react-native'
import { Job } from 'lib/models'
import { createStyles } from 'lib/styles'
import { Typography } from 'lib/components'
import { useStyles, useTranslations } from 'lib/hooks'
import { useJobStatus } from '../hooks'

type JobStatusLabelSmallProps = {
    job: Job
}

export const JobStatusLabelSmall: React.FunctionComponent<JobStatusLabelSmallProps> = ({
    job
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const jobStatusData = useJobStatus(job.status)

    return (
        <View style={styles.label}>
            <Typography.Label
                bold
                forceColor={jobStatusData.colors.text}
            >
                {jobStatusData.label}
            </Typography.Label>
            {jobStatusData.label === T.components.jobStatus.completed && (
                <View style={styles.jobSupplier}>
                    <Typography.Label
                        numberOfLines={1}
                        forceColor={theme.colors.mouse}
                    >
                        {`${T.common.by} ${job.supplier?.handle}`}
                    </Typography.Label>
                </View>
            )}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    label: {
        flexDirection: 'row',
        marginLeft: theme.utils.gap(1)
    },
    jobSupplier: {
        flex: 1,
        marginLeft: theme.utils.gap(1) / 2
    }
}))
