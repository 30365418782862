import React, { useRef } from 'react'
import { View } from 'react-native'
import { useSharedValue } from 'react-native-reanimated'
import { useClickAway, useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { Measurements } from 'lib/common'
import { linkingHelpers } from 'lib/utils'
import { ENV } from 'lib/config'
import { Logo } from '../Logo'
import { Foreground } from '../grid'
import { HeaderMenu } from './HeaderMenu'
import { MenuSidebar, UserSidebar } from '../sidebar'
import { MainDropdownMenu } from './MainDropdownMenu'

type HeaderProps = {
    disableUserMenu?: boolean
}

export const Header: React.FunctionComponent<HeaderProps> = ({
    disableUserMenu = false
}) => {
    const { styles } = useStyles(stylesheet)
    const mainDropdownMenuRef = useRef<View>(null)
    const isMainMenuVisible = useSharedValue<boolean>(false)

    useClickAway({
        elementRef: mainDropdownMenuRef,
        onClickAway: () => {
            isMainMenuVisible.value = false
        }
    })

    return (
        <Foreground style={styles.container}>
            <MenuSidebar />
            <View style={styles.logoContainer}>
                <Logo onPress={() => linkingHelpers.openUrl(ENV.SENDHELPER_MARKETING_SITE_URL, false)} />
            </View>
            <HeaderMenu
                disableUserMenu={disableUserMenu}
                isMainMenuVisible={isMainMenuVisible}
                onShowMainMenu={() => {
                    isMainMenuVisible.value = !isMainMenuVisible.value
                }}
            />
            <UserSidebar />
            <MainDropdownMenu
                ref={mainDropdownMenuRef}
                isVisible={isMainMenuVisible}
            />
        </Foreground>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        // To hide shadow from sidebar
        zIndex: theme.zIndex[10],
        flexDirection: 'row',
        width: '100%',
        height: {
            ':w[1440, ]': Measurements.WideHeaderHeight,
            ':w[, 1440]': Measurements.HeaderHeight
        },
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingLeft: {
            ':w[1280, ]': theme.utils.gap(8),
            xs: 0
        },
        borderBottomWidth: 1,
        borderColor: theme.colors.silver
    },
    logoContainer: {
        flex: {
            ':w[1280, ]': undefined,
            ':w[, 1280]': 1
        },
        alignItems: 'flex-start',
        marginLeft: {
            xs: theme.utils.gap(1),
            md: undefined
        }
    }
}))
