import React, { useState } from 'react'
import { View } from 'react-native'
import { useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { BasicModal, Touchable, Typography } from 'lib/components'
import { IroningServiceDetailsModalContent } from './IroningServiceDetailsModalContent'

export const IroningServiceDetails: React.FunctionComponent = () => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const [isDetailsModalOpen, setDetailsModalOpen] = useState(false)

    return (
        <View>
            <BasicModal
                isOpen={isDetailsModalOpen}
                onClose={() => setDetailsModalOpen(false)}
            >
                <IroningServiceDetailsModalContent />
            </BasicModal>
            <Touchable
                style={styles.link}
                testID={T.accessibility.cleaningAddonsIroningServiceDetailsButton}
                onPress={() => setDetailsModalOpen(true)}
            >
                <Typography.Label forceColor={theme.colors.orange}>
                    {T.common.viewDetails}
                </Typography.Label>
            </Touchable>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    link: {
        borderBottomWidth: 1,
        alignSelf: 'flex-start',
        borderColor: theme.colors.orange
    }
}))
