import { useField } from '@codegateinc/react-form-builder-v2'
import { Service } from 'lib/models'
import { dateHelpers } from 'lib/utils'
import { useTranslations } from 'lib/hooks'
import { BookingFrequency, FrequencyOption, Nullable, SupplyID } from 'lib/types'
import { getServiceStaticConfig } from 'features/bookings/utils'
import { DeepCleaningFields } from './forms'
import { useBookingDays, useFrequencyOptions } from '../hooks'

export const useDeepCleaningForm = () => {
    const T = useTranslations()
    const frequencyOptions = useFrequencyOptions()
    const bookingDaysInitialValue = useBookingDays()
    const config = getServiceStaticConfig(SupplyID.DeepCleaning)
    const { pricing, building_type } = config
    const servicesInitValue = pricing?.additionalServices?.items.map(item => ({
        ...item,
        quantity: 0,
        selected: false
    }))

    const buildingType = building_type ? building_type[0] : ''

    const propertyCleaning = useField<string>({
        key: DeepCleaningFields.PropertyCleaning,
        initialValue: 'true',
        isRequired: true,
        validateOnBlur: true,
        validationRules: [
            {
                errorMessage: T.screens.deepCleaning.formFields.propertyCleaning.validation.isRequired,
                validate: Boolean
            }
        ]
    })

    const propertyType = useField<string>({
        key: DeepCleaningFields.PropertyType,
        initialValue: buildingType,
        isRequired: true,
        validateOnBlur: true,
        label: T.screens.deepCleaning.formFields.propertyType.label,
        validationRules: [
            {
                errorMessage: T.screens.deepCleaning.formFields.propertyType.validation.isRequired,
                validate: Boolean
            }
        ]
    })

    const propertySize = useField<string>({
        key: DeepCleaningFields.PropertySize,
        initialValue: pricing?.propertySize?.options[0]?.id || '',
        isRequired: false,
        placeholder: pricing.propertySize.label
    })

    const services = useField<Array<Service>>({
        key: DeepCleaningFields.Services,
        initialValue: servicesInitValue,
        isRequired: false,
        validateOnBlur: false,
        label: pricing.additionalServices.label,
        placeholder: pricing.additionalServices.description
    })

    const frequency = useField<Nullable<FrequencyOption>>({
        key: DeepCleaningFields.Frequency,
        initialValue: frequencyOptions.find(option => option.value === BookingFrequency.OneTime) || null,
        isRequired: false,
        validateOnBlur: false,
        label: T.screens.deepCleaning.formFields.frequency.label
    })

    const startingDate = useField<string>({
        key: DeepCleaningFields.StartingDate,
        initialValue: '',
        isRequired: true,
        validateOnBlur: false,
        label: T.screens.deepCleaning.formFields.startingDate.label,
        placeholder: T.screens.deepCleaning.formFields.startingDate.placeholder,
        validationRules: [
            {
                errorMessage: T.screens.deepCleaning.formFields.startingDate.validation.isRequired,
                validate: Boolean
            }
        ]
    })

    const bookingTime = useField<string>({
        key: DeepCleaningFields.BookingTime,
        initialValue: '',
        validateOnBlur: true,
        isRequired: frequency.value?.value === BookingFrequency.OneTime,
        placeholder: T.screens.deepCleaning.formFields.selectTime.placeHolder,
        validationRules: [
            {
                errorMessage: T.screens.deepCleaning.formFields.selectTime.validation.isRequired,
                validate: value => {
                    if (frequency.value?.value !== BookingFrequency.OneTime) {
                        return true
                    }

                    return Boolean(value)
                }
            },
            {
                errorMessage: `${config.min_notice_hours} ${T.screens.deepCleaning.formFields.selectTime.validation.minimumHourNotice}`,
                validate: value => {
                    if (frequency.value?.value !== BookingFrequency.OneTime) {
                        return true
                    }

                    const isPast = dateHelpers.isDatePast(dateHelpers.subHoursFromDate(value, config.min_notice_hours))

                    return !isPast
                }
            }
        ]
    })

    const bookingDays = useField<Record<string, Nullable<string>>>({
        key: DeepCleaningFields.BookingDays,
        initialValue: bookingDaysInitialValue,
        validateOnBlur: true,
        isRequired: frequency.value?.value !== BookingFrequency.OneTime,
        placeholder: T.screens.deepCleaning.formFields.selectTime.placeHolder,
        validationRules: [
            {
                errorMessage: T.screens.deepCleaning.formFields.selectTime.validation.isRequired,
                validate: value => {
                    if (frequency.value?.value === BookingFrequency.OneTime) {
                        return true
                    }

                    return Object.values(value).some(Boolean)
                }
            },
            {
                errorMessage: `${config.min_notice_hours} ${T.screens.deepCleaning.formFields.selectTime.validation.minimumHourNotice}`,
                validate: value => {
                    const times = Object.values(value).filter(item => Boolean(item)) as Array<string>
                    const isSomePast = times.some(time => dateHelpers.isDatePast(dateHelpers.subHoursFromDate(time, config.min_notice_hours)))

                    return !isSomePast
                }
            }
        ]
    })

    return {
        propertyCleaning,
        propertyType,
        propertySize,
        services,
        frequency,
        startingDate,
        bookingTime,
        bookingDays
    }
}
