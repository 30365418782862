export enum CustomFonts {
    Poppins400 = 'Poppins-400',
    Poppins500 = 'Poppins-500',
    Poppins600 = 'Poppins-600',
    Poppins700 = 'Poppins-700',
    Roboto400 = 'Roboto-400',
    Roboto500 = 'Roboto-500',
    Roboto600 = 'Roboto-600',
    Roboto700 = 'Roboto-700',
    Lato700 = 'Lato-700'
}
