import { useNavigation, useRoute } from '@react-navigation/native'
import { SupplyID } from 'lib/types'
import { Segment, ServiceType } from 'lib/analytics'
import { useTranslations } from './useTranslations'
import { linkingHelpers } from '../utils'
import { NavigationParams, ScreenNames } from '../routing'
import { useAddressAtom, useIsAuthorizedAtom, useUserAtom } from '../atoms'
import { APP_CONFIG, ENV } from '../config'

type MainDropdownLink = {
    label: string,
    onPress: VoidFunction,
    isNew?: boolean
}

export const useMainDropdownLinks = () => {
    const T = useTranslations()
    const [isAuthorized] = useIsAuthorizedAtom()
    const navigation = useNavigation()
    const [user] = useUserAtom()
    const [selectedAddress] = useAddressAtom()
    const route = useRoute<NavigationParams<ScreenNames.Summary>>()
    const routeWithPostalcode = route.params?.postalcode
    const canAccessBookingFlow = isAuthorized || routeWithPostalcode
    const [primaryAddress] = user?.locations || []
    const postalcode = selectedAddress.postcode || route.params?.postalcode || primaryAddress?.postcode
    const mostPopular: Array<MainDropdownLink> = [
        {
            label: T.components.mainDropdownMenu.mostPopular.cleaning,
            onPress: () => {
                Segment.selectedServiceFromDropdown({
                    serviceType: ServiceType.Cleaning
                })

                canAccessBookingFlow
                    ? navigation.navigate(ScreenNames.Cleaning, {
                        postalcode,
                        service: String(SupplyID.Cleaner)
                    })
                    : linkingHelpers.openUrl(`${ENV.SENDHELPER_MARKETING_SITE_URL}/service/cleaning`, false)
            }
        },
        {
            label: T.components.mainDropdownMenu.otherServices.deepCleaning,
            onPress: () => {
                Segment.selectedServiceFromDropdown({
                    serviceType: ServiceType.DeepCleaning
                })

                canAccessBookingFlow
                    ? navigation.navigate(ScreenNames.DeepCleaning, {
                        postalcode,
                        service: String(SupplyID.DeepCleaning)
                    })
                    : linkingHelpers.openUrl(`${ENV.SENDHELPER_MARKETING_SITE_URL}/service/deep-cleaning`, false)
            }
        },
        {
            label: T.components.mainDropdownMenu.mostPopular.airCon,
            onPress: () => {
                Segment.selectedServiceFromDropdown({
                    serviceType: ServiceType.AirCondition
                })

                canAccessBookingFlow
                    ? navigation.navigate(ScreenNames.AirCondition, {
                        postalcode,
                        service: String(SupplyID.ACService)
                    })
                    : linkingHelpers.openUrl(`${ENV.SENDHELPER_MARKETING_SITE_URL}/service/air-condition`, false)
            }
        },
        {
            label: T.components.mainDropdownMenu.mostPopular.handyman,
            onPress: () => {
                Segment.selectedServiceFromDropdown({
                    serviceType: ServiceType.Handyman
                })

                canAccessBookingFlow && APP_CONFIG.SERVICES.IS_HANDYMAN_ENABLED
                    ? navigation.navigate(ScreenNames.Handyman, {
                        postalcode,
                        service: String(SupplyID.Handyman)
                    })
                    : linkingHelpers.openUrl(`${ENV.SENDHELPER_MARKETING_SITE_URL}/service/handyman`, false)
            }
        }
    ]
    const otherServices: Array<MainDropdownLink> = [
        {
            label: T.components.mainDropdownMenu.mostPopular.laundry,
            onPress: () => {
                Segment.selectedServiceFromDropdown({
                    serviceType: ServiceType.Laundry
                })

                canAccessBookingFlow && APP_CONFIG.SERVICES.IS_LAUNDRY_ENABLED
                    ? navigation.navigate(ScreenNames.Laundry, {
                        postalcode,
                        service: String(SupplyID.Laundry)
                    })
                    : linkingHelpers.openUrl(`${ENV.SENDHELPER_MARKETING_SITE_URL}/service/laundry-singapore`, false)
            }
        },
        {
            label: T.components.mainDropdownMenu.otherServices.pestControl,
            onPress: () => {
                Segment.selectedServiceFromDropdown({
                    serviceType: ServiceType.PestControl
                })

                canAccessBookingFlow && APP_CONFIG.SERVICES.IS_PEST_CONTROL_ENABLED
                    ? navigation.navigate(ScreenNames.PestControl, {
                        postalcode,
                        service: String(SupplyID.PestControl)
                    })
                    : linkingHelpers.openUrl(`${ENV.SENDHELPER_MARKETING_SITE_URL}/service/pest-control`, false)
            }
        },
        {
            isNew: true,
            label: T.components.mainDropdownMenu.otherServices.moversAndPackers,
            onPress: () => {
                canAccessBookingFlow && APP_CONFIG.SERVICES.IS_MOVERS_AND_PACKERS_ENABLED
                    ? navigation.navigate(ScreenNames.MoversAndPackers, {
                        postalcode,
                        service: String(SupplyID.MoversPackers)
                    })
                    : linkingHelpers.openUrl(`${ENV.SENDHELPER_MARKETING_SITE_URL}/service/movers-packers`, false)
            }
        },
        {
            label: T.components.mainDropdownMenu.otherServices.cooking,
            onPress: () => {
                Segment.selectedServiceFromDropdown({
                    serviceType: ServiceType.Cooking
                })

                canAccessBookingFlow && APP_CONFIG.SERVICES.IS_COOKING_ENABLED
                    ? navigation.navigate(ScreenNames.Cooking, {
                        postalcode,
                        service: String(SupplyID.Cooking)
                    })
                    : linkingHelpers.openUrl(`${ENV.SENDHELPER_MARKETING_SITE_URL}/service/cooking`, false)
            }
        },
        {
            label: T.components.mainDropdownMenu.otherServices.tasksErrands,
            onPress: () => {
                Segment.selectedServiceFromDropdown({
                    serviceType: ServiceType.TaskErrands
                })

                canAccessBookingFlow && APP_CONFIG.SERVICES.IS_TASK_ERRANDS_ENABLED
                    ? navigation.navigate(ScreenNames.TaskAndErrands, {
                        postalcode,
                        service: String(SupplyID.TasksErrands)
                    })
                    : linkingHelpers.openUrl(`${ENV.SENDHELPER_MARKETING_SITE_URL}/service/task-errands`, false)
            }
        }
    ]

    return {
        mostPopular,
        otherServices
    }
}
