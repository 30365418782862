import React from 'react'
import { TextStyle, View } from 'react-native'
import { Grid, ReferralWarning, Typography } from 'lib/components'
import { useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { Children, Nullable } from 'lib/types'
import { GetPromotionResponse } from 'features/bookings'
import { PromotionSummary } from '../PromotionSummary'

type PestControlBookingSummaryProps = {
    couponText?: string,
    pricingMessage?: string,
    title?: string,
    renderPriceSummaryComponent?: () => Children,
    renderPriceComponent: () => Children,
    bookingSummary: Array<[string, string]>,
    valueStyle?: TextStyle,
    labelStyle?: TextStyle,
    promotionPackage?: Nullable<GetPromotionResponse>,
    totalPrice?: Nullable<number>,
    originalPrice?: Nullable<number>,
}

export const PestControlBookingSummary: React.FunctionComponent<PestControlBookingSummaryProps> = ({
    renderPriceComponent,
    renderPriceSummaryComponent,
    pricingMessage,
    couponText,
    title,
    bookingSummary,
    valueStyle,
    labelStyle,
    promotionPackage,
    totalPrice,
    originalPrice
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)

    return (
        <View>
            <Typography.Title bold>
                {title ?? T.common.bookingSummary}
            </Typography.Title>
            <View style={styles.summaryContainer}>
                {bookingSummary.map(([key, value]) => (
                    <View
                        key={`${key}-${value}`}
                        style={{
                            ...styles.summaryRow,
                            ...(!key ? styles.summaryRowNewLine : {})
                        }}
                    >
                        <Typography.Label style={labelStyle}>
                            {key}
                        </Typography.Label>
                        <Grid.Gap gapRight={2}/>
                        <Typography.Label
                            bold
                            style={{
                                ...styles.value,
                                ...valueStyle
                            }}
                        >
                            {value || '-'}
                        </Typography.Label>
                    </View>
                ))}
            </View>
            <PromotionSummary
                promotionPackage={promotionPackage}
                totalPrice={totalPrice}
                originalPrice={originalPrice}
            />
            {renderPriceSummaryComponent ? renderPriceSummaryComponent() : null}
            <View style={styles.priceContainer}>
                {renderPriceComponent()}
            </View>
            {pricingMessage && (
                <Typography.Error
                    style={styles.pricingMessage}
                    forceColor={theme.colors.mouse}
                >
                    {pricingMessage}
                </Typography.Error>
            )}
            {couponText && (
                <View style={styles.couponContainer}>
                    <Typography.Label
                        bold
                        forceColor={theme.colors.green}
                    >
                        {couponText}
                    </Typography.Label>
                    {promotionPackage?.promotion.warning_message && (
                        <ReferralWarning />
                    )}
                </View>
            )}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    priceContainer: {
        borderTopWidth: 1,
        borderColor: theme.colors.silver,
        marginBottom: theme.utils.gap(2),
        paddingTop: theme.utils.gap(2),
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    summaryContainer: {
        borderTopWidth: 1,
        borderColor: theme.colors.silver,
        paddingTop: theme.utils.gap(2),
        marginTop: theme.utils.gap(2)
    },
    summaryRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: theme.utils.gap(2)
    },
    summaryRowNewLine: {
        marginTop: -theme.utils.gap(1)
    },
    labelWrapper: {
        flex: 1,
        minWidth: 50,
        marginRight: theme.utils.gap(2)
    },
    value: {
        textAlign: 'right'
    },
    couponContainer: {
        borderRadius: 8,
        justifyContent: 'center',
        textAlign: 'center',
        paddingHorizontal: theme.utils.gap(1),
        paddingVertical: theme.utils.gap(1),
        backgroundColor: theme.colors.mint
    },
    couponIcon: {
        marginRight: theme.utils.gap(1)
    },
    pricingMessage: {
        marginBottom: theme.utils.gap(2)
    },
    warningMessage: {
        marginTop: theme.utils.gap(1)
    },
    viewTerms: {
        textDecorationLine: 'underline'
    },
    pricingSummaryContainer: {
        borderTopWidth: 1,
        borderColor: theme.colors.silver,
        paddingTop: theme.utils.gap(2)
    },
    priceSummaryContainer: {
        justifyContent: 'space-between',
        width: '100%'
    }
}))
