import React from 'react'
import { ScrollView } from 'react-native'
import { DrawerContentComponentProps } from '@react-navigation/drawer'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { MenuContent } from '../sidebar'

export const CustomLeftDrawer: React.FunctionComponent<DrawerContentComponentProps> = () => {
    const { styles } = useStyles(stylesheet)

    return (
        <ScrollView
            style={styles.drawerWrapper}
            showsVerticalScrollIndicator={false}
        >
            <MenuContent />
        </ScrollView>
    )
}

const stylesheet = createStyles(() => ({
    drawerWrapper: {
        flex: 1,
        width: '100%'
    }
}))
