import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const Navigation: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M69.357 10.643c.656.656.829 1.653.432 2.492l-26.342 55.61a2.195 2.195 0 0 1-4.113-.408L33.8 46.2l-22.137-5.534a2.195 2.195 0 0 1-.408-4.113l55.61-26.342a2.195 2.195 0 0 1 2.492.432ZM18.678 37.895l17.464 4.366c.787.196 1.4.81 1.597 1.597l4.366 17.464L63.19 16.81 18.678 37.895Z"
            stroke={props.forceColor}
            strokeWidth={3}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Icon>
)
