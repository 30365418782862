import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const DedicatedSupport: React.FunctionComponent<IconProps> = props => (
    <Icon viewBox="0 0 57 58" {...props}>
        <Path d="M28.5002 53.639C41.0923 53.639 51.3002 43.4311 51.3002 30.839C51.3002 18.2469 41.0923 8.039 28.5002 8.039C15.9081 8.039 5.7002 18.2469 5.7002 30.839C5.7002 43.4311 15.9081 53.639 28.5002 53.639Z" fill="white"/>
        <Path opacity="0.15" d="M28.7459 47.7925C33.1824 47.7925 36.7789 47.3523 36.7789 46.8093C36.7789 46.2662 33.1824 45.826 28.7459 45.826C24.3094 45.826 20.7129 46.2662 20.7129 46.8093C20.7129 47.3523 24.3094 47.7925 28.7459 47.7925Z" fill="#F9B130"/>
        <Path d="M44.7918 18.9727C44.7918 18.6299 44.7242 18.2905 44.593 17.9737C44.4617 17.657 44.2694 17.3693 44.0269 17.127C43.7844 16.8846 43.4965 16.6925 43.1797 16.5614C42.8629 16.4304 42.5234 16.3631 42.1806 16.3633H15.3091C14.9664 16.3633 14.6271 16.4308 14.3106 16.5619C13.994 16.6931 13.7064 16.8853 13.4641 17.1276C13.2219 17.3699 13.0297 17.6576 12.8986 17.9742C12.7675 18.2908 12.7001 18.6301 12.7002 18.9727V36.7852H44.7912L44.7918 18.9727Z" fill="#FCF8EA"/>
        <Path d="M32.6358 44.5652H24.8564L25.3427 40.6749H32.1502L32.6358 44.5652Z" fill="white"/>
        <Path d="M32.6358 44.5652H24.8564L25.3427 40.6749H32.1502L32.6358 44.5652Z" stroke="#F9B130" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M34.315 46.51H23.1761C23.0756 46.5112 22.9765 46.4873 22.8877 46.4404C22.7988 46.3935 22.7231 46.3251 22.6674 46.2415C22.6118 46.1579 22.5779 46.0617 22.5688 45.9617C22.5598 45.8616 22.576 45.7609 22.6158 45.6687C22.7746 45.3382 23.0235 45.0592 23.3339 44.864C23.6443 44.6688 24.0036 44.5652 24.3702 44.5652H33.1226C33.4892 44.5652 33.8484 44.6687 34.1587 44.864C34.469 45.0592 34.7178 45.3382 34.8765 45.6687C34.9164 45.761 34.9325 45.8618 34.9234 45.962C34.9143 46.0621 34.8803 46.1584 34.8245 46.242C34.7687 46.3257 34.6928 46.394 34.6039 46.4408C34.5149 46.4876 34.4156 46.5114 34.315 46.51Z" fill="white" stroke="#F9B130" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M42.1806 40.6749C42.8726 40.6749 43.5362 40.4001 44.0256 39.9108C44.5149 39.4216 44.7899 38.758 44.7901 38.066V36.7852H12.7002V38.066C12.7002 38.7579 12.9751 39.4215 13.4643 39.9108C13.9536 40.4001 14.6172 40.6749 15.3091 40.6749H42.1806Z" fill="white" stroke="#F9B130" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M44.7918 18.9727C44.7918 18.6299 44.7242 18.2905 44.593 17.9737C44.4617 17.657 44.2694 17.3693 44.0269 17.127C43.7844 16.8846 43.4965 16.6925 43.1797 16.5614C42.8629 16.4304 42.5234 16.3631 42.1806 16.3633H15.3091C14.9664 16.3633 14.6271 16.4308 14.3106 16.5619C13.994 16.6931 13.7064 16.8853 13.4641 17.1276C13.2219 17.3699 13.0297 17.6576 12.8986 17.9742C12.7675 18.2908 12.7001 18.6301 12.7002 18.9727V36.7852H44.7912L44.7918 18.9727Z" stroke="#F9B130" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M28.7454 39.3622C29.0945 39.3622 29.3775 39.0792 29.3775 38.7301C29.3775 38.381 29.0945 38.098 28.7454 38.098C28.3963 38.098 28.1133 38.381 28.1133 38.7301C28.1133 39.0792 28.3963 39.3622 28.7454 39.3622Z" fill="#F9B130"/>
        <Path d="M37.4555 17.6737C36.6832 17.4816 36.2848 16.5525 35.9918 15.5436V13.1644C35.9918 12.2662 35.8149 11.3768 35.4711 10.5469C35.1274 9.71702 34.6235 8.96298 33.9884 8.32783C33.3532 7.69268 32.5992 7.18885 31.7693 6.8451C30.9395 6.50136 30.05 6.32444 29.1518 6.32444C28.2535 6.32444 27.3641 6.50136 26.5342 6.8451C25.7044 7.18885 24.9503 7.69268 24.3152 8.32783C23.68 8.96298 23.1762 9.71702 22.8324 10.5469C22.4887 11.3768 22.3118 12.2662 22.3118 13.1644V15.5436C22.0194 16.5554 21.6209 17.4816 20.8486 17.6766C19.4048 18.0408 20.1429 19.9794 21.0196 20.9256C21.4401 21.3585 21.9454 21.7001 22.5038 21.929C23.0622 22.158 23.6618 22.2695 24.2652 22.2565C25.7655 23.0962 27.4379 23.5816 29.1546 23.6758C30.8714 23.5818 32.5438 23.0963 34.0441 22.2565C34.648 22.2699 35.2483 22.1586 35.8073 21.9296C36.3663 21.7006 36.8721 21.3588 37.2931 20.9256C38.1612 19.9765 38.8993 18.0379 37.4555 17.6737Z" fill="white" stroke="#F9B130" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M29.1516 22.4047C31.5298 22.4274 33.8028 23.3878 35.4768 25.0772C37.1507 26.7666 38.0901 29.0484 38.0909 31.4267V36.7847H20.2129V31.4267C20.2135 29.0485 21.1529 26.7667 22.8267 25.0772C24.5005 23.3878 26.7735 22.4274 29.1516 22.4047Z" fill="#FFF6D2"/>
        <Path d="M23.7126 36.7852L23.6967 34.0686C23.2943 33.1942 23.3667 31.4871 23.6836 30.4041" stroke="#F9B130" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M34.6064 36.7852L34.6218 34.0686C35.0243 33.1942 34.9513 31.4871 34.6349 30.4041" stroke="#F9B130" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M29.1516 22.4047C31.5298 22.4274 33.8028 23.3878 35.4768 25.0772C37.1507 26.7666 38.0901 29.0484 38.0909 31.4267V36.7847H20.2129V31.4267C20.2135 29.0485 21.1529 26.7667 22.8267 25.0772C24.5005 23.3878 26.7735 22.4274 29.1516 22.4047Z" stroke="#F9B130" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M29.1724 25.4468C29.7994 27.9867 33.6709 27.582 33.5911 24.9674C33.5876 24.8606 33.5782 24.754 33.5631 24.6482C33.1009 21.4123 25.2599 21.0088 24.7401 24.6482C24.725 24.754 24.7157 24.8606 24.7122 24.9674C24.6318 27.582 28.5061 27.9884 29.1308 25.4468L29.1513 25.3613L29.1724 25.4468Z" fill="white" stroke="#F9B130" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M27.1318 20.9016V21.6135C27.1319 22.3566 27.3163 23.0881 27.6686 23.7423C28.0209 24.3966 28.5299 24.9533 29.1502 25.3624C29.77 24.954 30.2787 24.3981 30.6307 23.7446C30.9826 23.091 31.1669 22.3604 31.1669 21.6181V20.9016H27.1318Z" fill="white" stroke="#F9B130" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M27.1318 20.9016V21.6135C27.1319 22.3566 27.3163 23.0881 27.6686 23.7423C28.0209 24.3966 28.5299 24.9533 29.1502 25.3624C29.77 24.954 30.2787 24.3981 30.6307 23.7446C30.9826 23.091 31.1669 22.3604 31.1669 21.6181V20.9016H27.1318Z" stroke="#F9B130" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M34.8515 14.8556V13.4306C34.8515 11.9189 34.251 10.4691 33.182 9.40012C32.1131 8.33116 30.6633 7.73063 29.1515 7.73063C27.6398 7.73063 26.19 8.33116 25.121 9.40012C24.0521 10.4691 23.4515 11.9189 23.4515 13.4306V14.8556C23.1492 14.8556 22.8592 14.9757 22.6454 15.1895C22.4316 15.4033 22.3115 15.6933 22.3115 15.9956C22.3115 16.298 22.4316 16.5879 22.6454 16.8017C22.8592 17.0155 23.1492 17.1356 23.4515 17.1356C23.4755 17.1356 23.4977 17.1299 23.5216 17.1282C23.7231 18.4774 24.4023 19.7093 25.4356 20.5998C26.469 21.4903 27.7877 21.9801 29.1518 21.9801C30.5159 21.9801 31.8347 21.4903 32.868 20.5998C33.9013 19.7093 34.5805 18.4774 34.782 17.1282C34.8054 17.1282 34.8276 17.1356 34.8515 17.1356C35.1539 17.1356 35.4438 17.0155 35.6576 16.8017C35.8714 16.5879 35.9915 16.298 35.9915 15.9956C35.9915 15.6933 35.8714 15.4033 35.6576 15.1895C35.4438 14.9757 35.1539 14.8556 34.8515 14.8556Z" fill="white" stroke="#F9B130" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M25.8736 17.9906C26.2671 17.9906 26.5861 17.6716 26.5861 17.2781C26.5861 16.8846 26.2671 16.5656 25.8736 16.5656C25.4801 16.5656 25.1611 16.8846 25.1611 17.2781C25.1611 17.6716 25.4801 17.9906 25.8736 17.9906Z" fill="#FFF6D2"/>
        <Path d="M32.4293 17.9906C32.8228 17.9906 33.1418 17.6716 33.1418 17.2781C33.1418 16.8846 32.8228 16.5656 32.4293 16.5656C32.0358 16.5656 31.7168 16.8846 31.7168 17.2781C31.7168 17.6716 32.0358 17.9906 32.4293 17.9906Z" fill="#FFF6D2"/>
        <Path d="M30.8614 18.7254C30.4023 19.1682 29.7893 19.4157 29.1514 19.4157C28.5135 19.4157 27.9005 19.1682 27.4414 18.7254" stroke="#F9B130" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M27.7266 15.4256C27.6563 15.2568 27.5375 15.1126 27.3854 15.0112C27.2332 14.9098 27.0545 14.8556 26.8716 14.8556C26.6887 14.8556 26.51 14.9098 26.3578 15.0112C26.2057 15.1126 26.0869 15.2568 26.0166 15.4256" stroke="#F9B130" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M32.2862 15.4256C32.2158 15.2568 32.0971 15.1126 31.945 15.0112C31.7928 14.9098 31.614 14.8556 31.4312 14.8556C31.2483 14.8556 31.0695 14.9098 30.9174 15.0112C30.7652 15.1126 30.6465 15.2568 30.5762 15.4256" stroke="#F9B130" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M29.1515 6.30563C27.3358 6.31076 25.5961 7.03563 24.3139 8.32133C23.0318 9.60704 22.3117 11.3487 22.3115 13.1644V15.9842C22.3115 15.8492 22.3382 15.7155 22.3899 15.5908C22.4417 15.4661 22.5176 15.3528 22.6132 15.2575C22.7089 15.1622 22.8224 15.0867 22.9473 15.0353C23.0721 14.984 23.2059 14.9578 23.3409 14.9582C25.0509 15.2484 26.122 13.4004 26.5865 10.5692C28.7046 12.5483 34.3887 15.0528 34.9655 14.9582C35.2376 14.9582 35.4986 15.0663 35.691 15.2587C35.8834 15.4512 35.9915 15.7121 35.9915 15.9842V13.1644C35.9915 11.3486 35.2715 9.6069 33.9893 8.32117C32.7071 7.03543 30.9673 6.31061 29.1515 6.30563Z" fill="white"/>
        <Path d="M29.1515 6.30563C27.3358 6.31076 25.5961 7.03563 24.3139 8.32133C23.0318 9.60704 22.3117 11.3487 22.3115 13.1644V15.9842C22.3115 15.8492 22.3382 15.7155 22.3899 15.5908C22.4417 15.4661 22.5176 15.3528 22.6132 15.2575C22.7089 15.1622 22.8224 15.0867 22.9473 15.0353C23.0721 14.984 23.2059 14.9578 23.3409 14.9582C25.0509 15.2484 26.122 13.4004 26.5865 10.5692C28.7046 12.5483 34.3887 15.0528 34.9655 14.9582C35.2376 14.9582 35.4986 15.0663 35.691 15.2587C35.8834 15.4512 35.9915 15.7121 35.9915 15.9842V13.1644C35.9915 11.3486 35.2715 9.6069 33.9893 8.32117C32.7071 7.03543 30.9673 6.31061 29.1515 6.30563Z" stroke="#F9B130" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M23.6731 16.8119C23.6731 16.9649 23.6123 17.1116 23.5041 17.2198C23.3959 17.3279 23.2492 17.3887 23.0962 17.3887H22.2298C22.1534 17.3886 22.0801 17.3581 22.026 17.3041C21.972 17.25 21.9416 17.1767 21.9414 17.1003V13.0636C21.9414 12.9871 21.9718 12.9137 22.0259 12.8596C22.08 12.8055 22.1533 12.7751 22.2298 12.7751H23.0962C23.2492 12.7751 23.3959 12.8359 23.5041 12.9441C23.6123 13.0523 23.6731 13.199 23.6731 13.352V16.8119Z" fill="#FFF6D2" stroke="#F9B130" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M20.7897 16.8119H21.9451V13.352H20.7897C20.714 13.3519 20.639 13.3667 20.569 13.3957C20.499 13.4246 20.4355 13.467 20.3819 13.5206C20.3283 13.5741 20.2858 13.6376 20.2568 13.7076C20.2278 13.7775 20.2129 13.8525 20.2129 13.9282V16.235C20.2129 16.388 20.2737 16.5347 20.3818 16.6429C20.49 16.7511 20.6367 16.8119 20.7897 16.8119Z" fill="white" stroke="#F9B130" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M35.2077 17.3887H36.073C36.1494 17.3886 36.2226 17.3581 36.2765 17.304C36.3305 17.25 36.3608 17.1767 36.3608 17.1003V13.0636C36.3609 13.0257 36.3535 12.9882 36.3391 12.9532C36.3246 12.9182 36.3034 12.8864 36.2767 12.8596C36.25 12.8329 36.2182 12.8116 36.1832 12.7971C36.1483 12.7826 36.1108 12.7751 36.073 12.7751H35.2077C35.0547 12.7751 34.908 12.8359 34.7998 12.9441C34.6916 13.0523 34.6309 13.199 34.6309 13.352V16.8119C34.6309 16.9649 34.6916 17.1116 34.7998 17.2198C34.908 17.3279 35.0547 17.3887 35.2077 17.3887Z" fill="#FFF6D2" stroke="#F9B130" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M38.0909 16.235C38.0909 16.388 38.0301 16.5347 37.9219 16.6429C37.8137 16.7511 37.667 16.8119 37.514 16.8119H36.3604V13.352H37.514C37.5898 13.3519 37.6648 13.3667 37.7347 13.3957C37.8047 13.4246 37.8683 13.467 37.9219 13.5206C37.9755 13.5741 38.018 13.6376 38.047 13.7076C38.0759 13.7775 38.0909 13.8525 38.0909 13.9282V16.235Z" fill="white" stroke="#F9B130" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M36.9362 13.352C36.945 12.324 36.7501 11.3044 36.3628 10.3521C35.9755 9.39986 35.4034 8.53372 34.6796 7.80369C33.9558 7.07366 33.0946 6.4942 32.1457 6.09876C31.1967 5.70332 30.1789 5.49972 29.1509 5.49972C28.1228 5.49972 27.105 5.70332 26.1561 6.09876C25.2071 6.4942 24.3459 7.07366 23.6221 7.80369C22.8983 8.53372 22.3262 9.39986 21.9389 10.3521C21.5516 11.3044 21.3567 12.324 21.3655 13.352V11.9099C21.3567 10.8819 21.5516 9.86232 21.9389 8.91004C22.3262 7.95776 22.8983 7.09162 23.6221 6.36159C24.3459 5.63156 25.2071 5.0521 26.1561 4.65666C27.105 4.26122 28.1228 4.05762 29.1509 4.05762C30.1789 4.05762 31.1967 4.26122 32.1457 4.65666C33.0946 5.0521 33.9558 5.63156 34.6796 6.36159C35.4034 7.09162 35.9755 7.95776 36.3628 8.91004C36.7501 9.86232 36.945 10.8819 36.9362 11.9099V13.352Z" fill="white" stroke="#F9B130" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M26.5567 20.8486H25.403C25.2505 20.8486 25.1042 20.7882 24.9961 20.6807C24.888 20.5731 24.8269 20.4271 24.8262 20.2746C24.8262 20.1216 24.8869 19.9749 24.9951 19.8667C25.1033 19.7586 25.25 19.6978 25.403 19.6978H26.5567C26.7094 19.6982 26.8557 19.7592 26.9635 19.8673C27.0713 19.9755 27.1318 20.1219 27.1318 20.2746C27.1312 20.4269 27.0704 20.5727 26.9626 20.6802C26.8549 20.7878 26.7089 20.8483 26.5567 20.8486Z" fill="#FFF6D2" stroke="#F9B130" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M24.8271 20.2746H23.674C23.0622 20.2746 22.4754 20.0316 22.0428 19.599C21.6102 19.1664 21.3672 18.5796 21.3672 17.9678V16.8119" stroke="#F9B130" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M30.6123 30.7877H27.6911C27.3763 30.7877 27.1211 31.0429 27.1211 31.3577V34.8786C27.1211 35.1934 27.3763 35.4486 27.6911 35.4486H30.6123C30.9271 35.4486 31.1823 35.1934 31.1823 34.8786V31.3577C31.1823 31.0429 30.9271 30.7877 30.6123 30.7877Z" fill="white" stroke="#F9B130" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M30.043 31.9277H28.2617V34.3086H30.043V31.9277Z" fill="#FFF6D2" stroke="#F9B130" strokeLinejoin="round"/>
        <Path d="M27.4512 27.2976L29.1521 30.7877L30.9914 27.1215" stroke="#F9B130" strokeLinecap="round" strokeLinejoin="round"/>
    </Icon>
)
