import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const Notification: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path d="M23.5 10.167a23.333 23.333 0 0 1 39.833 16.5c0 11.175 2.39 18.115 4.593 22.153 1.106 2.028 2.183 3.358 2.931 4.148.375.396.67.658.847.806a4.07 4.07 0 0 0 .183.145A3.333 3.333 0 0 1 70 60H10a3.333 3.333 0 0 1-1.888-6.08l.01-.007c.025-.019.085-.064.173-.138.178-.148.472-.41.847-.806.748-.79 1.825-2.12 2.931-4.148 2.203-4.038 4.593-10.978 4.593-22.153a23.333 23.333 0 0 1 6.834-16.5ZM8.146 53.897v-.001Zm9.018-.564h45.671a31.482 31.482 0 0 1-.762-1.32c-2.797-5.128-5.407-13.189-5.407-25.346a16.667 16.667 0 0 0-33.333 0c0 12.157-2.61 20.218-5.407 25.346-.254.465-.508.905-.762 1.32ZM32.56 67.117a3.333 3.333 0 0 1 4.557 1.21 3.333 3.333 0 0 0 5.766 0 3.333 3.333 0 1 1 5.767 3.346 10 10 0 0 1-17.3 0 3.333 3.333 0 0 1 1.21-4.556Z" />
    </Icon>
)
