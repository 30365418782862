import React from 'react'
import { View } from 'react-native'
import { useNavigation, useRoute } from '@react-navigation/native'
import { Button, Typography, Grid } from 'lib/components'
import { useStyles, useTranslations } from 'lib/hooks'
import { Address } from 'lib/models'
import { NavigationParams, ScreenNames } from 'lib/routing'
import { createStyles } from 'lib/styles'
import { NewAddressRequestShape, Nullable } from 'lib/types'
import { stringHelpers } from 'lib/utils'

type DoubleAddressProps = {
    disabled?: boolean,
    pickUpAddress: Nullable<Address | NewAddressRequestShape>,
    dropOffAddress: Nullable<Address | NewAddressRequestShape>
}

export const DoubleAddress: React.FunctionComponent<DoubleAddressProps> = ({
    pickUpAddress,
    dropOffAddress
}) => {
    const T = useTranslations()
    const navigation = useNavigation()
    const { styles } = useStyles(stylesheet)
    const route = useRoute<NavigationParams<ScreenNames.Cleaning>>()

    if (!pickUpAddress && !dropOffAddress) {
        return (
            <View style={styles.buttonContainer}>
                <Button
                    text={T.screens.user.addAddress}
                    onPress={() => navigation.navigate(ScreenNames.UserNewAddress)}
                />
            </View>
        )
    }

    const pickUpAddressUnit = stringHelpers.getFormattedAddressUnit(pickUpAddress?.unit)
    const dropOffAddressUnit = stringHelpers.getFormattedAddressUnit(dropOffAddress?.unit)

    return (
        <React.Fragment>
            <View style={styles.container}>
                <Typography.Regular bold>
                    {T.screens.moversAndPackers.formFields.pickUpLocation.label}
                </Typography.Regular>
                <View style={styles.row}>
                    <View style={styles.flex}>
                        <View>
                            <Typography.Label>
                                {pickUpAddress?.postcode
                                    ? `${pickUpAddressUnit}${pickUpAddress?.address}, ${pickUpAddress?.postcode}`
                                    : `${route.params?.postalcode}, ${T.common.singapore}`
                                }
                            </Typography.Label>
                        </View>
                    </View>
                </View>
                <Grid.Gap gapBottom={2}/>
                <Typography.Regular bold>
                    {T.screens.moversAndPackers.formFields.dropOffLocation.label}
                </Typography.Regular>
                <View style={styles.row}>
                    <View style={styles.flex}>
                        <View>
                            <Typography.Label>
                                {dropOffAddress?.postcode
                                    ? `${dropOffAddressUnit}${dropOffAddress?.address}, ${dropOffAddress?.postcode}`
                                    : `${route.params?.postalcode}, ${T.common.singapore}`
                                }
                            </Typography.Label>
                        </View>
                    </View>
                </View>
            </View>
        </React.Fragment>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        borderRadius: 8,
        borderWidth: 1,
        borderColor: theme.colors.silver,
        paddingVertical: theme.utils.gap(5) / 2,
        paddingHorizontal: {
            lg: theme.utils.gap(3),
            xs: theme.utils.gap(2)
        },
        marginBottom: theme.utils.gap(4),
        backgroundColor: theme.colors.white,
        ...theme.utils.createShadow(2, 0, 8, 4, 4, theme.colors.webShadow(0.04))
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: theme.utils.gap(1)
    },
    buttonContainer: {
        marginBottom: theme.utils.gap(2)
    },
    flex: {
        flex: 1
    },
    changeButton: {
        borderBottomWidth: 1,
        marginLeft: theme.utils.gap(1),
        borderColor: theme.colors.night
    }
}))
