import React, { useState } from 'react'
import { View, Image } from 'react-native'
import { useSharedValue } from 'react-native-reanimated'
import { Images } from 'assets'
import { Segment } from 'lib/analytics'
import { createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { FadeTabButton, FadeTabContent, Typography } from 'lib/components'
import { MoversAndPackersPricingAccordion } from './moversAndPackers'

export const MoversAndPackersPackagePricingContent: React.FunctionComponent = () => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const activeTabIndex = useSharedValue(0)
    const openAccordionIndex = useSharedValue(0)
    const [selectedTab, setSelectedTab] = useState(0)
    const pricingOptions = Object.values(T.components.moversAndPackersPackagePrice.options)
    const lorryImages = [Images.TenLorry, Images.FourteenLorry, Images.TwentyFourLorry]

    return (
        <View style={styles.container}>
            <Typography.SmallSubheading style={styles.title}>
                {T.components.moversAndPackersPackagePrice.modalTitle}
            </Typography.SmallSubheading>
            <Typography.Regular
                style={styles.description}
                forceColor={theme.colors.darkGrey}
            >
                {T.components.moversAndPackersPackagePrice.description}
            </Typography.Regular>
            <View style={styles.tabs}>
                {pricingOptions.map((option, index) => (
                    <FadeTabButton
                        key={index}
                        text={option.title}
                        index={index}
                        activeIndex={activeTabIndex}
                        onPress={() => {
                            Segment.bookingPriceReferenceTabClicked({
                                tabName: option.title
                            })

                            setSelectedTab(index)
                            activeTabIndex.value = index
                        }}
                    >
                        <Typography.Regular
                            forceColor={index === selectedTab
                                ? theme.colors.orange
                                : theme.colors.night
                            }
                        >
                            {option.title}
                        </Typography.Regular>
                    </FadeTabButton>
                ))}
            </View>
            <View>
                {pricingOptions.map((option, index) => (
                    <FadeTabContent
                        key={index}
                        selectedTab={selectedTab}
                        activeIndex={activeTabIndex}
                        index={index}
                    >
                        <View style={styles.tabContainer}>
                            <View style={styles.tabContent}>
                                <Image
                                    source={lorryImages[selectedTab]}
                                    resizeMode="contain"
                                    style={styles.lorryImage}
                                />
                                <View style={styles.table}>
                                    <Typography.Regular
                                        style={styles.optionDescription}
                                        forceColor={theme.colors.darkGrey}
                                    >
                                        {option.description}
                                        <Typography.Regular
                                            bold
                                            forceColor={theme.colors.night}
                                        >
                                            {option.boldDescription}
                                        </Typography.Regular>
                                    </Typography.Regular>
                                    {Object.values(option.details).map((item, index) => (
                                        <View
                                            key={index}
                                            style={styles.tableRow}
                                        >
                                            <View style={styles.flex}>
                                                <Typography.Regular>
                                                    {item.title}
                                                </Typography.Regular>
                                            </View>
                                            <Typography.Regular bold>
                                                {item.description}
                                            </Typography.Regular>
                                        </View>
                                    ))}
                                    <View style={styles.serviceAddons}>
                                        <Typography.Regular bold>
                                            {option.addOnTitle}
                                        </Typography.Regular>
                                    </View>
                                    <View>
                                        {Object.values(option.addOns).map((item, index) => (
                                            <MoversAndPackersPricingAccordion
                                                key={index}
                                                index={index}
                                                openedIndex={openAccordionIndex}
                                                title={item.title}
                                                price={item.price}
                                                description={item.description}
                                                options={Object.values(item.details)}
                                            />
                                        ))}
                                    </View>
                                </View>
                            </View>
                        </View>
                    </FadeTabContent>
                ))}
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    flex: {
        flex: 1
    },
    title: {
        marginRight: {
            lg: undefined,
            xs: theme.utils.gap(4)
        }
    },
    description: {
        marginTop: theme.utils.gap(1)
    },
    container: {
        width: {
            xl: 1100,
            xs: '100%'
        },
        padding: {
            lg: theme.utils.gap(3 / 2),
            xs: 0
        }
    },
    tabs: {
        flex: 1,
        gap: {
            lg: theme.utils.gap(2),
            xs: theme.utils.gap(1)
        },
        marginTop: theme.utils.gap(2),
        flexDirection: 'row'
    },
    alignText: {
        textAlign: 'center'
    },
    tabContainer: {
        paddingHorizontal: {
            xl: theme.utils.gap(8),
            xs: 0
        }
    },
    tabContent: {
        flexDirection: {
            lg: 'row',
            xs: 'column'
        }
    },
    lorryImage: {
        marginTop: theme.utils.gap(2),
        width: {
            lg: 320,
            xs: undefined
        },
        height: {
            lg: 250,
            xs: 220
        }
    },
    table: {
        flex: 1,
        marginVertical: theme.utils.gap(2),
        marginHorizontal: {
            lg: theme.utils.gap(4),
            xs: 0
        }
    },
    tableRow: {
        flexDirection: 'row',
        alignItems: 'center',
        borderBottomWidth: 1,
        paddingTop: theme.utils.gap(2),
        paddingBottom: theme.utils.gap(1),
        borderBottomColor: theme.colors.silver
    },
    optionDescription: {
        marginBottom: theme.utils.gap(1)
    },
    serviceAddons: {
        marginTop: theme.utils.gap(4)
    }
}))
