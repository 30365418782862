import React from 'react'
import { View } from 'react-native'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { Regular } from './typography'

type SeparatorProps = {
    text: string,
    height?: number
}

export const Separator: React.FunctionComponent<SeparatorProps> = ({
    text,
    height = 45
}) => {
    const { styles, theme  } = useStyles(stylesheet)

    return (
        <View
            style={{
                ...styles.container,
                height
            }}
        >
            <View style={styles.textContainer}>
                <Regular
                    forceColor={theme.colors.darkGrey}
                    style={styles.text}
                >
                    {text}
                </Regular>
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        marginVertical: theme.utils.gap(1),
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    border: {
        flex: 1,
        height: 1,
        backgroundColor: theme.colors.fog
    },
    textContainer: {
        marginHorizontal: theme.utils.gap(2)
    },
    text: {
        textTransform: 'lowercase'
    }
}))
