import React from 'react'
import { View } from 'react-native'
import { Field } from '@codegateinc/react-form-builder-v2'
import { createStyles } from 'lib/styles'
import { Nullable } from 'lib/types'
import { Typography } from 'lib/components'
import { useStyles, useTranslations } from 'lib/hooks'
import { BookingDaysInput } from './BookingDaysInput'
import { FormComponents } from '../components'
import { BookingMultiselectDaysInput } from './BookingMultiselectDaysInput'

interface BookingDaysProps extends Field<Record<string, Nullable<string>>> {
    startDate: string,
    disabled: boolean,
    onChangeSelectedDateValue: (value: string) => void,
    minHoursNotice?: number,
    isMultiSelect?: boolean
}

export const BookingDays: React.FunctionComponent<BookingDaysProps> = ({
    startDate,
    value,
    validate,
    onChangeValue,
    onChangeSelectedDateValue,
    placeholder,
    disabled,
    errorMessage,
    minHoursNotice,
    isMultiSelect
}) => {
    const T = useTranslations()
    const isEmpty = startDate.length === 0
    const { styles, theme } = useStyles(stylesheet)
    const onRemoveDate = (date: string) => {
        const newValue = startDate
            .split(', ')
            .filter(selectedDate => selectedDate !== date)
            .join(', ')

        onChangeSelectedDateValue(newValue)
        onChangeValue({
            ...value,
            [date]: ''
        })
    }

    if (isMultiSelect && isEmpty) {
        return (
            <Typography.Regular style={styles.label}>
                {T.common.noDatesSelected}
            </Typography.Regular>
        )
    }

    if (isMultiSelect) {
        return (
            <View>
                {startDate.split(', ')
                    .sort((firstDate, secondDate) => firstDate.localeCompare(secondDate))
                    .map((date, index) => (
                        <View
                            key={index}
                            style={{
                                // Need this to make dropdown visible on every select input
                                zIndex: 999 - index
                            }}
                        >
                            <BookingMultiselectDaysInput
                                index={index}
                                date={date}
                                onBlur={validate}
                                disabledInput={false}
                                dayValue={value[date]}
                                placeholder={placeholder}
                                minHoursNotice={minHoursNotice}
                                onRemoveDate={onRemoveDate}
                                onInputChange={option => onChangeValue({
                                    ...value,
                                    [date]: option === null
                                        ? null
                                        : option.value
                                })}
                            />
                        </View>
                    ))}
            </View>
        )
    }

    return (
        <View>
            {Object.keys(value).map((dayKey, index) => (
                <View
                    key={index}
                    style={{
                        // Need this to make dropdown visible on every select input
                        zIndex: theme.zIndex[10] - index
                    }}
                >
                    <BookingDaysInput
                        minHoursNotice={minHoursNotice}
                        index={index}
                        dayKey={dayKey}
                        dayValue={value[dayKey]}
                        startDate={startDate}
                        placeholder={placeholder}
                        disabledCheckbox={disabled}
                        disabledInput={value[dayKey] === null}
                        isSelected={value[dayKey] !== null}
                        onBlur={validate}
                        onCheckboxChange={() => onChangeValue({
                            ...value,
                            [dayKey]: value[dayKey] === null
                                ? ''
                                : null
                        })}
                        onInputChange={option => onChangeValue({
                            ...value,
                            [dayKey]: option === null
                                ? null
                                : option.value
                        })}
                    />
                </View>
            ))}
            <FormComponents.ErrorMessage text={errorMessage} />
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    label: {
        marginBottom: theme.utils.gap(1)
    }
}))
