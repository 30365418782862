import { Icons } from 'assets'
import { NotificationType } from 'lib/types'
import { useStyles } from './useStyles'

export const useToastIcon = (type: NotificationType) => {
    const { theme } = useStyles()

    switch (type) {
        case NotificationType.Success:
            return (
                <Icons.CheckCircle
                    size={24}
                    forceColor={theme.colors.green}
                />
            )
        case NotificationType.Error:
            return (
                <Icons.Alert
                    size={24}
                    forceColor={theme.colors.strawberry}
                />
            )
        case NotificationType.Info:
            return (
                <Icons.Info
                    size={24}
                    forceColor={theme.colors.sun}
                />
            )
        default:
            return (
                <Icons.CheckCircle
                    size={24}
                    forceColor={theme.icon.success}
                />
            )
    }
}
