import React from 'react'
import { View } from 'react-native'
import { Icons } from 'assets'
import { createStyles } from 'lib/styles'
import { useStyles } from 'lib/hooks'
import { Touchable } from './Touchable'
import { Regular } from './typography'

type TextButtonProps = {
    onPress: VoidFunction,
    text: string,
    disabled?: boolean
}

export const TextButton: React.FunctionComponent<TextButtonProps> = ({
    onPress,
    text,
    disabled = false
}) => {
    const { styles, theme } = useStyles(stylesheet)

    return (
        <Touchable
            style={styles.buttonRow}
            disabled={disabled}
            onPress={onPress}
        >
            <Regular
                forceColor={theme.colors.orange}
                style={styles.priceReferenceButton}
            >
                {text}
            </Regular>
            <View
                style={{
                    transform: [
                        {
                            rotate: '-90deg'
                        }
                    ]
                }}
            >
                <Icons.Chevron
                    size={10}
                    forceColor={theme.colors.orange}
                />
            </View>
        </Touchable>
    )
}

const stylesheet = createStyles(theme => ({
    buttonRow: {
        marginTop: theme.utils.gap(3),
        alignItems: 'center',
        flexDirection: 'row'
    },
    priceReferenceButton: {
        textDecorationLine: 'underline',
        marginRight: theme.utils.gap(1)
    }
}))
