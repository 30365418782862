import React from 'react'
import { View } from 'react-native'
import { useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { R } from 'lib/utils'
import { PaginationPageTile } from './PaginationPageTile'

type PaginationPagesProps = {
    pagesAmount: number,
    currentPage: number,
    onChangePage(pageNumber: number): void
}

export const PaginationPages: React.FunctionComponent<PaginationPagesProps> = ({
    pagesAmount,
    currentPage,
    onChangePage
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const pagesSpan = 3 // max number of text tiles on each side of current page tile
    const prevPagesLength = currentPage - 1
    const prevPages = currentPage > pagesSpan + 1
        ? [1, null, currentPage - 1]
        : new Array(prevPagesLength > 0 ? prevPagesLength : 0)
            .fill(1)
            .map((item, index) => index + 1)
    const nextPagesLength = pagesAmount - currentPage
    const nextPages = nextPagesLength > pagesSpan
        ? [currentPage + 1, null, pagesAmount]
        : new Array(nextPagesLength > 0 ? nextPagesLength : 0)
            .fill(1)
            .map((item, index) => currentPage + index + 1)
    const pagesToView = prevPages
        .concat(currentPage)
        .concat(nextPages)

    return (
        <View style={styles.row}>
            <PaginationPageTile
                onPress={() => onChangePage(1)}
                text="<<"
                disabled={currentPage === 1}
                style={styles.mostLeftTile}
                testID={T.accessibility.pagination.mostLeftTile}
            />
            <PaginationPageTile
                onPress={() => onChangePage(currentPage - 1)}
                text="<"
                disabled={currentPage === 1}
                testID={T.accessibility.pagination.leftTile}
            />
            {pagesToView.map((page, index) => (
                <PaginationPageTile
                    key={`pagination page - ${page}-${index}`}
                    text={page
                        ? `${page}`
                        : '...'
                    }
                    testID={T.accessibility.pagination.pageNoTile}
                    onPress={page
                        ? () => onChangePage(page)
                        : R.T
                    }
                    disabled={page === currentPage || !page}
                    isSelected={page === currentPage}
                    style={!page ? styles.disabled : undefined}
                />
            ))}
            <PaginationPageTile
                onPress={() => onChangePage(currentPage + 1)}
                text=">"
                disabled={currentPage === pagesAmount}
                testID={T.accessibility.pagination.rightTile}
            />
            <PaginationPageTile
                onPress={() => onChangePage(pagesAmount)}
                text=">>"
                disabled={currentPage === pagesAmount}
                style={styles.mostRightTile}
                testID={T.accessibility.pagination.mostRightTile}
            />
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    row: {
        flexDirection: 'row'
    },
    disabled: {
        backgroundColor: theme.colors.silver
    },
    mostLeftTile: {
        borderTopLeftRadius: theme.utils.gap(0.5),
        borderBottomLeftRadius: theme.utils.gap(0.5)
    },
    mostRightTile: {
        borderRightWidth: 1,
        borderTopRightRadius: theme.utils.gap(0.5),
        borderBottomRightRadius: theme.utils.gap(0.5)
    }
}))
