import React from 'react'
import { ViewStyle } from 'react-native'
import Animated, { useAnimatedStyle, useSharedValue, withRepeat, withTiming } from 'react-native-reanimated'
import { Icons } from 'assets'
import { useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { Touchable } from '../Touchable'

type RatingStarProps = {
    index: number,
    rated: boolean,
    value: number,
    starColor?: string,
    isFilled: boolean,
    onRate: (value: number) => void,
    hoveredValue: number,
    setHoveredValue: (value: number) => void
}

export const RatingStar: React.FunctionComponent<RatingStarProps> = ({
    index,
    rated,
    onRate,
    starColor,
    value,
    isFilled,
    hoveredValue,
    setHoveredValue
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const isClicked = useSharedValue(false)
    const animatedScaleStyle = useAnimatedStyle<ViewStyle>(() => ({
        transform: [
            {
                scale: withRepeat(withTiming(isClicked.value ? 1.25 : 1, {
                    duration: 200
                }), 2, true)
            },
            {
                rotate: withRepeat(withTiming(`${isClicked.value ? -25 : 0}deg`, {
                    duration: 200
                }), 2, true)
            }
        ]
    }))

    return (
        <Touchable
            testID={T.accessibility.rateButton.replace('[index]', index.toString())}
            key={index}
            disabled={rated}
            onHoverIn={() => setHoveredValue(index + 1)}
            onHoverOut={() => setHoveredValue(0)}
            onPress={() => {
                onRate(index + 1)
                isClicked.value = true
            }}
            style={styles.starContainer}
        >
            <Animated.View style={[animatedScaleStyle]}>
                {isFilled || (value === 0 && hoveredValue === 0)
                    ? (
                        <Icons.StarFilled
                            size={24}
                            forceColor={rated
                                ? theme.colors.sun
                                : value === 0
                                    ? starColor
                                    : undefined
                            }
                        />
                    ) : (
                        <Icons.Star
                            size={24}
                            forceColor={rated
                                ? theme.colors.sun
                                : value === 0
                                    ? starColor
                                    : undefined
                            }
                        />
                    )
                }
            </Animated.View>
        </Touchable>
    )
}

const stylesheet = createStyles(theme => ({
    starContainer: {
        paddingHorizontal: theme.utils.gap(1) / 2
    }
}))
