import {
    getAirconJobQuotation,
    getCleaningJobQuotation,
    getAuthorizedCleaningJobQuotation,
    getCookingJobQuotation,
    getAuthorizedCookingJobQuotation,
    getDeepCleaningJobQuotation,
    getAuthorizedDeepCleaningJobQuotation,
    getPestControlJobQuotation,
    getAuthorizedPestControlJobQuotation,
    getHandymanJobQuotation,
    getAuthorizedHandymanJobQuotation,
    getTaskErrandsJobQuotation,
    getAuthorizedTaskErrandsJobQuotation,
    getLaundryJobQuotation,
    getAuthorizedLaundryJobQuotation,
    getMoversAndPackersJobQuotation,
    getAuthorizedMoversAndPackersJobQuotation,
    getAuthorizedAirconJobQuotation
} from '../actions'
import { useState } from 'react'
import { useUserTokenAtom } from 'lib/atoms'
import { SupplyID } from 'lib/types'
import { JobQuotationResponse } from 'features/bookings/types'

export const useJobQuotation = supplyID => {
    const [token] = useUserTokenAtom()
    const [isLoading, setIsLoading] = useState(false)
    const [jobQuotation, setJobQuotation] = useState<JobQuotationResponse>()
    const { mutate: getCleaningJobQuotationData } = getCleaningJobQuotation()
    const { mutate: getAuthorizedCleaningJobQuotationData } = getAuthorizedCleaningJobQuotation()
    const { mutate: getCookingJobQuotationData } = getCookingJobQuotation()
    const { mutate: getAuthorizedCookingJobQuotationData } = getAuthorizedCookingJobQuotation()
    const { mutate: getDeepCleaningJobQuotationData } = getDeepCleaningJobQuotation()
    const { mutate: getAuthorizedDeepCleaningJobQuotationData } = getAuthorizedDeepCleaningJobQuotation()
    const { mutate: getAirconJobQuotationData } = getAirconJobQuotation()
    const { mutate: getAuthorizedAirconJobQuotationData } = getAuthorizedAirconJobQuotation()
    const { mutate: getPestControlJobQuotationData } = getPestControlJobQuotation()
    const { mutate: getAuthorizedPestControlJobQuotationData } = getAuthorizedPestControlJobQuotation()
    const { mutate: getHandymanJobQuotationData } = getHandymanJobQuotation()
    const { mutate: getAuthorizedHandymanJobQuotationData } = getAuthorizedHandymanJobQuotation()
    const { mutate: getTaskErrandsJobQuotationData } = getTaskErrandsJobQuotation()
    const { mutate: getAuthorizedTaskErrandsJobQuotationData } = getAuthorizedTaskErrandsJobQuotation()
    const { mutate: getLaundryJobQuotationData } = getLaundryJobQuotation()
    const { mutate: getAuthorizedLaundryJobQuotationData } = getAuthorizedLaundryJobQuotation()
    const { mutate: getMoversAndPackersJobQuotationData } = getMoversAndPackersJobQuotation()
    const { mutate: getAuthorizedMoversAndPackersJobQuotationData } = getAuthorizedMoversAndPackersJobQuotation()

    const getFunctionFetchJobQuotationData = supplyID => {
        switch(supplyID) {
            case SupplyID.Cleaner:
                return token ? getAuthorizedCleaningJobQuotationData : getCleaningJobQuotationData
            case SupplyID.Cooking:
                return token ? getAuthorizedCookingJobQuotationData : getCookingJobQuotationData
            case SupplyID.DeepCleaning:
                return token ? getAuthorizedDeepCleaningJobQuotationData : getDeepCleaningJobQuotationData
            case SupplyID.ACService:
                return token ? getAuthorizedAirconJobQuotationData : getAirconJobQuotationData
            case SupplyID.PestControl:
                return token ? getAuthorizedPestControlJobQuotationData : getPestControlJobQuotationData
            case SupplyID.Handyman:
                return token ? getAuthorizedHandymanJobQuotationData : getHandymanJobQuotationData
            case SupplyID.TasksErrands:
                return token ? getAuthorizedTaskErrandsJobQuotationData : getTaskErrandsJobQuotationData
            case SupplyID.Laundry:
                return token ? getAuthorizedLaundryJobQuotationData : getLaundryJobQuotationData
            case SupplyID.MoversPackers:
                return token ? getAuthorizedMoversAndPackersJobQuotationData : getMoversAndPackersJobQuotationData
            default:
                return undefined
        }
    }

    const requestJobQuotation = jobData => {
        const data = { ...jobData }

        if(token) {
            data.token = token
        }

        const functionFetchJobQuotationData = getFunctionFetchJobQuotationData(supplyID)
        setIsLoading(true)

        if (functionFetchJobQuotationData) {

            functionFetchJobQuotationData(data, {
                onSuccess: response => {
                    setJobQuotation(token ? response.data : response)
                },
                onError: error => {
                    console.log(error)
                },
                onSettled: () => {
                    setIsLoading(false)
                }
            })
        }
    }

    return {
        jobQuotation,
        requestJobQuotation,
        isLoading
    }
}
