import React from 'react'
import { Image, ImageBackground, View, StyleProp, ViewStyle  } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { Touchable, Typography } from 'lib/components'
import { createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { Icons } from 'assets'
import {
    useAutoSelectOneTimeJobTypeAtom,
    useCrossSaleBookingRecommendationsAtom,
    useUserAtom
} from 'lib/atoms'
import { SupplyID } from 'lib/types'
import { redirectToSupply } from 'lib/utils/navigation'
import { logCrossaleEvent } from 'lib/utils/analytic'
import { SupplyIcon } from 'features/bookings/components/SupplyIcon'

type BookingSuccessConfirmationModalProps = {
    closeModal?: () => void
}

export const BookingSuccessCrossSale: React.FunctionComponent<BookingSuccessConfirmationModalProps> = () => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const navigation = useNavigation()
    const [user] = useUserAtom()
    const [, setAutoSelectOneTimeJobTypeAtom] = useAutoSelectOneTimeJobTypeAtom()
    const [crossSaleBookingRecommendations] = useCrossSaleBookingRecommendationsAtom()
    const bookingRecommendations = crossSaleBookingRecommendations.slice()
    const firstRecommendation = bookingRecommendations.shift()

    const redirectToSupplyPageAndCloseModal = (supplyId: number) => {
        logCrossaleEvent(supplyId)
        setAutoSelectOneTimeJobTypeAtom(supplyId !== SupplyID.Cleaner)
        redirectToSupply(supplyId, user, navigation)
    }

    if (!firstRecommendation) {
        return null
    }

    return (
        <View>
            <View style={styles.title}>
                <Typography.Subheading>
                    {T.screens.bookingSuccess.crossSale.title}
                </Typography.Subheading>
            </View>
            <View style={styles.ctaContainer}>
                <View style={styles.firstColumn as StyleProp<ViewStyle>}>
                    <Touchable onPress={() => redirectToSupplyPageAndCloseModal(firstRecommendation?.supplyId as number)}>
                        <View style={styles.ctaCard}>
                            <ImageBackground
                                source={{ uri: firstRecommendation?.imageUrl }}
                                style={styles.bigImage}
                                imageStyle={styles.image}
                            >
                                <Typography.Label style={styles.cardSupplyName}>
                                    <View style={styles.iconContainer}>
                                        <SupplyIcon supplyId={firstRecommendation?.supplyId as number}/>
                                    </View>
                                    {firstRecommendation?.supplyName}
                                </Typography.Label>
                                <View style={styles.cardSupplyInfo}>
                                    <Typography.Regular style={styles.cardSupplyDescription}>
                                        {firstRecommendation?.supplyDescription}
                                        <Typography.Regular style={styles.cardCTAButton}>
                                            {firstRecommendation?.ctaButtonText}
                                        </Typography.Regular>
                                    </Typography.Regular>
                                </View>
                            </ImageBackground>
                        </View>
                    </Touchable>
                </View>
                <View style={styles.secondColumn}>
                    {
                        bookingRecommendations.map((recommendation, index) => (
                            <Touchable key={index} style={styles.smallCardContainer} onPress={() => redirectToSupplyPageAndCloseModal(recommendation?.supplyId as number)}>
                                <View style={styles.smallCardFirstColumn}>
                                    <Typography.Label style={styles.cardSupplyName}>
                                        <View style={styles.iconContainer}>
                                            <SupplyIcon supplyId={recommendation?.supplyId as number}/>
                                        </View>
                                        {recommendation?.supplyName}
                                    </Typography.Label>
                                    <Image
                                        source={{ uri: recommendation?.imageUrl }}
                                        style={styles.smallImage}
                                    />
                                </View>
                                <View style={styles.smallCardSecondColumn}>
                                    <View>
                                        <Typography.Regular style={styles.smallCardSupplyDescription}>
                                            {recommendation?.supplyDescription}
                                        </Typography.Regular>
                                        <Typography.Regular style={styles.smallCardCTAButton}>
                                            {recommendation?.ctaButtonText}
                                            <View style={styles.bookNowIcon}>
                                                <Icons.Chevron
                                                    size={10}
                                                    forceColor={theme.colors.orange}
                                                />
                                            </View>
                                        </Typography.Regular>
                                    </View>
                                </View>
                            </Touchable>
                        ))
                    }
                </View>
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    title: {
        marginTop: theme.utils.gap(4),
        paddingTop: theme.utils.gap(2),
        paddingBottom: theme.utils.gap(1),
        paddingHorizontal: {
            lg: theme.utils.gap(0),
            md: theme.utils.gap(2),
            xs: theme.utils.gap(2)
        }
    },
    image: {
        borderRadius: 8
    },
    ctaContainer: {
        flex: 1,
        flexDirection: {
            md: 'row',
            xs: 'column'
        },
        paddingVertical: 14,
        gap: theme.utils.gap(2),
        paddingHorizontal: {
            lg: theme.utils.gap(0),
            md: theme.utils.gap(2),
            xs: theme.utils.gap(2)
        }
    },
    smallCardContainer: {
        flexDirection: 'row',
        gap: theme.utils.gap(1),
        boxShadow: `0px 4px 10px 0px ${theme.colors.webShadow(0.05)}`,
        elevation: 4,
        shadowColor: theme.colors.webShadow(0.05),
        shadowOffset: { width: 0, height: 4 },
        shadowOpacity: 1,
        borderRadius: 8
    },
    firstColumn: {
        flexGrow: 0,
        flexShrink: 0,
        flex: {
            md: 1,
            xs: 'none'
        }
    },
    secondColumn: {
        flex: 1,
        gap: theme.utils.gap(2)
    },
    smallCardFirstColumn: {
        flexGrow: 0,
        flexShrink: 0
    },
    smallCardSecondColumn: {
        flex: 1,
        padding: 10,
        justifyContent: 'center'
    },
    ctaCard: {
        flex: 1,
        borderRadius: 8,
        boxShadow: `0px 4px 10px 0px ${theme.colors.webShadow(0.05)}`,
        elevation: 4,
        shadowColor: theme.colors.webShadow(0.05),
        shadowOffset: { width: 0, height: 4 },
        shadowOpacity: 1
    },
    bigImage: {
        height: {
            md: 260,
            xs: 216
        },
        width: '100%',
        borderRadius: 8
    },
    smallImage: {
        height: {
            md: 120,
            xs: 153
        },
        width: {
            md: 153,
            xs: 148
        },
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8
    },
    cardSupplyName: {
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundColor: theme.colors.orange,
        zIndex: 1,
        paddingHorizontal: theme.utils.gap(1),
        paddingVertical: theme.utils.gap(0.5),
        borderTopLeftRadius: 8,
        borderBottomRightRadius: 8,
        fontSize: 14,
        color: theme.colors.white
    },
    cardSupplyInfo:{
        position: 'absolute',
        bottom: 10,
        left: 0,
        zIndex: 1,
        padding: 4,
        width: '100%',
        paddingHorizontal: 24
    },
    cardSupplyDescription: {
        color: 'white',
        fontSize: 16
    },
    cardCTAButton: {
        fontSize: 16,
        color: theme.colors.sun,
        textDecorationLine: 'underline',
        marginLeft: theme.utils.gap(0.75)
    },
    smallCardCTAButton: {
        fontSize: 16,
        color: theme.colors.orange,
        marginTop: theme.utils.gap(3)
    },
    iconContainer: {
        marginRight: 6
    },
    smallCardSupplyDescription: {
        color: theme.colors.black,
        fontSize: 16
    },
    bookNowIcon: {
        transform: [{ rotate: '270deg' }],
        marginLeft: theme.utils.gap(0.75)
    }
}))
