import React, { useEffect } from 'react'
import { datadogRum } from '@datadog/browser-rum'
import { notificationActions } from 'features/notifications'
import { jobActions } from 'features/jobs'
import { Children } from '../types'
import { useUserTokenAtom } from '../atoms'

type SendhelperListenerProps = {
    children: Children
}

export const SendhelperListener: React.FunctionComponent<SendhelperListenerProps> = ({ children }) => {
    const [token] = useUserTokenAtom()

    // fetch it silently in loop
    notificationActions.useGetRecurringNotifications()
    jobActions.useGetConsumerActiveJobs()

    useEffect(() => {
        const datadogUser = datadogRum.getUser()

        if (!datadogUser.id && token) {
            datadogRum.setUser({
                id: token
            })
            datadogRum.startSessionReplayRecording()
        }

        if (!token) {
            datadogRum.stopSessionReplayRecording()
        }
    }, [token])

    return (
        <React.Fragment>
            {children}
        </React.Fragment>
    )
}
