import React from 'react'
import { Image, View } from 'react-native'
import { Images } from 'assets'
import { useUserAtom } from 'lib/atoms'
import { Breakpoint, createStyles } from 'lib/styles'
import { useIsWithinBreakpoints, useStyles, useTranslations } from 'lib/hooks'
import { Typography } from 'lib/components'
import { ReferralCode } from './ReferralCode'
import { ReferFriendHeader } from './ReferFriendHeader'
import { ReferFriendHeaderMobile } from './ReferFriendHeaderMobile'
import { ShareModal } from './ShareModal'

type ReferFriendSectionProps = {
    isShareModalOpen: boolean,
    setShareModalOpen(value: boolean): void
}

export const ReferFriendSection: React.FunctionComponent<ReferFriendSectionProps> = ({
    isShareModalOpen,
    setShareModalOpen
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const [user] = useUserAtom()
    const isMobile = useIsWithinBreakpoints(Breakpoint.XS, Breakpoint.SM)

    return (
        <React.Fragment>
            <ShareModal
                isOpen={isShareModalOpen}
                onClose={() => setShareModalOpen(false)}
            />
            <View style={styles.wrapper}>
                <View style={styles.container}>
                    {isMobile
                        ? (
                            <ReferFriendHeaderMobile />
                        )
                        : (
                            <ReferFriendHeader />
                        )
                    }
                    <View style={styles.row}>
                        <Typography.Regular
                            style={styles.textCenter}
                            forceColor={theme.colors.darkGrey}
                        >
                            {T.screens.referFriend.description}
                            <Typography.Regular
                                bold
                                forceColor={theme.colors.darkGrey}
                            >
                                {T.screens.referFriend.descriptionBold}
                            </Typography.Regular>
                        </Typography.Regular>
                    </View>
                    {!isMobile && (
                        <ReferralCode
                            code={user.referral.promo_code}
                            onPress={() => setShareModalOpen(true)}
                        />
                    )}
                </View>
                <View style={styles.imageWrapper}>
                    <Image
                        resizeMode="contain"
                        style={styles.image}
                        source={Images.ShareReferral}
                    />
                </View>
            </View>
        </React.Fragment>
    )
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        alignItems: {
            md: 'center',
            xs: undefined
        },
        flexDirection: {
            md: 'row',
            xs: 'column'
        },
        paddingHorizontal: {
            xl: theme.utils.gap(6),
            md: theme.utils.gap(3),
            xs: theme.utils.gap(2)
        }
    },
    container: {
        flex: {
            md: 1,
            xs: undefined
        },
        alignItems: {
            md: undefined,
            xs: 'center'
        }
    },
    imageWrapper: {
        flex: {
            md: 1,
            xs: undefined
        }
    },
    image: {
        height: {
            md: 360,
            xs: 250
        },
        width: '100%'
    },
    row: {
        marginBottom: theme.utils.gap(2),
        maxWidth: {
            lg: 600,
            xs: '100%'
        }
    },
    textCenter: {
        textAlign: {
            md: undefined,
            xs: 'center'
        }
    }
}))
