import React from 'react'
import { View } from 'react-native'
import { Touchable } from './Touchable'
import { BasicModal } from './BasicModal'

type ModalWithIconProps = {
    icon: React.ReactElement
    children: React.ReactElement
}

export const ModalWithIcon: React.FunctionComponent<ModalWithIconProps> = ({ icon, children }) => {
    const [ isModalOpen, setIsModalOpen ] = React.useState(false)

    return (
        <View>
            <Touchable
                onPress={() => setIsModalOpen(true)}
            >
                {icon}
            </Touchable>
            <BasicModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
            >
                {children}
            </BasicModal>
        </View>
    )
}
