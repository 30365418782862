import React from 'react'
import { View } from 'react-native'
import { OptionProps, components as SelectComponents } from 'react-select'
import { Grid, Typography } from 'lib/components'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { IconProps, SelectInputOption } from 'lib/types'

export const CustomOption = (props: OptionProps<SelectInputOption>) => {
    const { styles, theme } = useStyles(stylesheet)
    const color = props.isFocused
        ? theme.colors.orange
        : theme.colors.black
    const Icon = props.data.icon
        ? React.createElement<IconProps>(props.data.icon, {
            forceColor: color,
            size: 20
        })
        : null

    return (
        <SelectComponents.Option {...props}>
            <View style={styles.container}>
                {Icon && (
                    <Grid.Gap gapRight={1.5}>
                        {Icon}
                    </Grid.Gap>
                )}
                <Typography.Regular
                    forceColor={color}
                    bold={props.isFocused}
                >
                    {props.data.label}
                    {props.data.subLabel && (
                        <Typography.Label style={styles.subLabel}>
                            {props.data.subLabel}
                        </Typography.Label>
                    )}
                </Typography.Regular>
            </View>
        </SelectComponents.Option>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    selectedContainer: {
        backgroundColor: theme.colors.yellow
    },
    subLabel: {
        marginLeft: theme.utils.gap(1 / 2)
    }
}))
