import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import { useForm } from '@codegateinc/react-form-builder-v2'
import { BookingDateModal, bookingsHooks, bookingsActions, TimeSlot } from 'features/bookings'
import { useDynamicServiceAtom, useLocaleAtom, useToastAtom, useUserTokenAtom } from 'lib/atoms'
import { Button, Typography } from 'lib/components'
import { useQueryHelpers, useStyles, useTranslations } from 'lib/hooks'
import { Job } from 'lib/models'
import { createStyles } from 'lib/styles'
import { LaundryStatus, NotificationType } from 'lib/types'
import { dateHelpers } from 'lib/utils'
import { getConsumerActiveJobs, getJobDetails, updateJob } from '../../actions'
import { RescheduleFormShape, useRescheduleLaundryForm } from '../../forms'
import { PostStatus } from '../../types'

type RescheduleLaundryProps = {
    job: Job,
    customButton?(onPress: VoidFunction): JSX.Element
}

export const RescheduleLaundry: React.FunctionComponent<RescheduleLaundryProps> = ({
    job,
    customButton
}) => {
    const T = useTranslations()
    const [locale] = useLocaleAtom()
    const [token] = useUserTokenAtom()
    const { styles, theme } = useStyles(stylesheet)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const { onRequestError } = useQueryHelpers()
    const [, setToastMessage] = useToastAtom()
    const [timeSlots, setTimeSlots] = useState<Array<TimeSlot>>()
    const { mutate: getTimeSlots, isLoading: isGettingTimeSlots } = bookingsActions.getLaundryTimeSlots()
    const [config] = useDynamicServiceAtom(job.supply.id)
    const canBeRescheduled = job?.laundry_options?.status === LaundryStatus.Ready
    const { mutate: reschedule, isLoading: isMutating } = updateJob()
    const { refetch: refetchJobDetails, isRefetching: isRefetchingJobDetails } = getJobDetails(job._id)
    const { refetch: refetchActiveJobs, isRefetching: isRefetchingActiveJobs } = getConsumerActiveJobs()
    const isLoading = isMutating || isRefetchingJobDetails || isRefetchingActiveJobs || isGettingTimeSlots
    const { form, submit } = useForm<RescheduleFormShape>(useRescheduleLaundryForm(timeSlots || [], job?.laundry_options?.delivery_slot?.date), {
        onSuccess: ({ bookingTime, startingDate }) => {
            const matchingTimeSlot = timeSlots?.find(timeSlot => timeSlot.display === bookingTime)

            if (!matchingTimeSlot) {
                return form.bookingTime.validate()
            }

            const startDateTime = dateHelpers.getStartDateOfTimeSlot(matchingTimeSlot, startingDate)

            reschedule(
                {
                    token,
                    id: job._id,
                    post_status: PostStatus.UpdateDeliveryTimeSlot,
                    pickup_slot: {
                        ...(matchingTimeSlot as TimeSlot),
                        date: startDateTime
                    }
                },
                {
                    onSuccess: () => {
                        refetchJobDetails()
                        refetchActiveJobs()
                        setIsModalOpen(false)
                        setToastMessage({
                            type: NotificationType.Success,
                            message: T.screens.jobDetails.rescheduleSuccessMessage
                        })
                    },
                    onError: onRequestError
                }
            )
        }
    })
    const availableTimeSlotsOptions = bookingsHooks.useLaundryAvailableTimeSlots(form.startingDate.value, timeSlots)

    useEffect(() => {
        getTimeSlots(
            {
                token
            },
            {
                onSuccess: ({ data }) => setTimeSlots(data.time_slot_list),
                onError: onRequestError
            }
        )
    }, [])

    return canBeRescheduled ? (
        <View style={styles.container}>
            <BookingDateModal
                onContinue={submit}
                disabled={isLoading}
                isLoading={isLoading}
                isModalOpen={isModalOpen}
                frequency={form.frequency.value}
                startingDateField={form.startingDate}
                bookingTimeField={form.bookingTime}
                bookingDaysField={form.bookingDays}
                minHoursNotice={config.min_notice_hours}
                onClose={() => setIsModalOpen(false)}
                title={T.screens.laundryDetails.setDeliveryTitle}
                description={T.screens.laundryDetails.setDeliveryDateTitle}
                timeTitle={T.screens.laundryDetails.setDeliveryTimeTitle}
                timeSlots={availableTimeSlotsOptions}
                header={job?.laundry_options?.delivery_slot?.date && (
                    <View style={styles.currentSessionWrapper}>
                        <Typography.Label forceColor={theme.colors.mouse}>
                            {T.screens.jobDetails.currentServiceDate}
                        </Typography.Label>
                        <Typography.Label>
                            {`${dateHelpers.getFullBookingListViewDate(job.laundry_options.delivery_slot.date, locale)} - ${job?.laundry_options.pickup_slot.display}`}
                        </Typography.Label>
                    </View>
                )}
            />
            {customButton
                ? customButton(() => setIsModalOpen(true))
                : (
                    <View style={styles.buttonWrapper}>
                        <Button
                            noBackground
                            text={T.screens.laundryDetails.setDeliveryTime}
                            onPress={() => setIsModalOpen(true)}
                        />
                    </View>
                )
            }
        </View>
    ) : null
}

const stylesheet = createStyles(theme => ({
    container: {
        flex: 1
    },
    buttonWrapper: {
        width: {
            ':w[1250, ]': 240,
            ':w[, 1250]': undefined
        },
        marginBottom: theme.utils.gap(2)
    },
    currentSessionWrapper: {
        marginLeft: {
            lg: theme.utils.gap(4),
            xs: 0
        }
    }
}))
