import React from 'react'
import { useRoute } from '@react-navigation/native'
import { ScrollView, View } from 'react-native'
import { useStyles } from 'lib/hooks'
import { Breakpoint, createStyles } from 'lib/styles'
import { useUnreadJobChatsAtom } from 'lib/atoms'
import { Children } from 'lib/types'
import { AppDrawerContent, Grid, Header, ListBottomSpacer, MediaQuery } from 'lib/components'
import { ChatNotification } from './ChatNotification'

type ChatLayoutProps = {
    children: Children,
    renderHeader?(): Children,
    renderRightColumn(): Children
}

export const ChatLayout: React.FunctionComponent<ChatLayoutProps> = ({
    children,
    renderHeader,
    renderRightColumn
}) => {
    const route = useRoute()
    const { styles } = useStyles(stylesheet)
    const [unreadChatMessages] = useUnreadJobChatsAtom()

    return (
        <Grid.Background>
            <Header disableUserMenu />
            <View style={styles.pageContent}>
                <MediaQuery.Visible from={Breakpoint.LG}>
                    <ScrollView
                        style={styles.sidebar}
                        showsVerticalScrollIndicator={false}
                    >
                        <AppDrawerContent routeName={route.name} />
                        <ListBottomSpacer height={50} />
                    </ScrollView>
                </MediaQuery.Visible>
                <View style={styles.body}>
                    <ChatNotification
                        unreadMessages={unreadChatMessages}
                        isVisible={unreadChatMessages.length > 0}
                    />
                    {renderHeader && renderHeader()}
                    <View style={styles.leftColumn}>
                        <View style={styles.leftColumnBody}>
                            {children}
                        </View>
                        <MediaQuery.Visible from={Breakpoint.MD}>
                            <View style={styles.rightColumn}>
                                {renderRightColumn()}
                            </View>
                        </MediaQuery.Visible>
                    </View>
                </View>
            </View>
        </Grid.Background>
    )
}

const stylesheet = createStyles(theme => ({
    pageContent: {
        flex: 1,
        flexDirection: 'row'
    },
    body: {
        flex: 1
    },
    leftColumn: {
        flex: 1,
        justifyContent: {
            md: 'center',
            sm: undefined
        },
        marginHorizontal: {
            xl: theme.utils.gap(9),
            sm: theme.utils.gap(1)
        },
        flexDirection: {
            md: 'row',
            sm: 'column'
        }
    },
    leftColumnBody: {
        flex: {
            lg: 2,
            xs: 1
        },
        maxWidth: {
            xl: 676,
            md: 550,
            sm: undefined
        }
    },
    rightColumn: {
        width: 308
    },
    sidebar: {
        flex: 1,
        maxWidth: 260,
        height: '100%',
        paddingHorizontal: theme.utils.gap(3),
        paddingTop: {
            lg: theme.utils.gap(5),
            xs: 0
        },
        backgroundColor: theme.colors.white,
        ...theme.utils.createShadow(0, 0.8, 8, 8)
    }
}))
