import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'
import { useIconColor } from './useIconColor'

interface DeleteIconProps extends IconProps {
    fill?: string
}

export const Delete: React.FunctionComponent<DeleteIconProps> = props => {
    const color = useIconColor(props.isActive, props.forceColor)

    return (
        <Icon
            {...props}
            forceColor="transparent"
        >
            <Path
                fill={color}
                d="M32.334 56.833c0 2.2-1.8 4-4 4s-4-1.8-4-4v-16c0-2.2 1.8-4 4-4s4 1.8 4 4v16Zm24 0c0 2.2-1.8 4-4 4s-4-1.8-4-4v-16c0-2.2 1.8-4 4-4s4 1.8 4 4v16Zm8 12c0 2.204-1.792 4-4 4h-40c-2.208 0-4-1.796-4-4v-44h48v44Zm-32-58.688c0-.62.856-1.312 2-1.312h12c1.144 0 2 .692 2 1.312v6.688h-16v-6.688Zm44 6.688h-20v-6.688c0-5.136-4.484-9.312-10-9.312h-12c-5.516 0-10 4.176-10 9.312v6.688h-20c-2.2 0-4 1.8-4 4s1.8 4 4 4h4v44c0 6.616 5.384 12 12 12h40c6.616 0 12-5.384 12-12v-44h4c2.2 0 4-1.8 4-4s-1.8-4-4-4Z"
            />
        </Icon>
    )
}

