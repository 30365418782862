import React from 'react'
import { View } from 'react-native'
import { Typography } from 'lib/components'
import { createStyles } from 'lib/styles'
import { useStyles } from 'lib/hooks'

type AddressTileTitleProps = {
    name: string,
    location: string
}

export const AddressTileTitle: React.FunctionComponent<AddressTileTitleProps> = ({
    name,
    location
}) => {
    const { styles } = useStyles(stylesheet)

    return (
        <View style={styles.container}>
            <Typography.Label numberOfLines={1}>
                {location}
            </Typography.Label>
            <Typography.Title
                bold
                numberOfLines={1}
            >
                {name}
            </Typography.Title>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        flex: 1,
        marginRight: theme.utils.gap(1)
    }
}))
