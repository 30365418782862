import React from 'react'
import { View } from 'react-native'
import { createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { Grid, Touchable, Typography } from 'lib/components'

type PestControlTabsProps = {
    arePackagesVisible: boolean,
    servicesAmount: number,
    packagesAmount: number,
    setPackagesVisibility(value: boolean): void
}

export const PestControlTabs: React.FunctionComponent<PestControlTabsProps> = ({
    arePackagesVisible,
    servicesAmount,
    packagesAmount,
    setPackagesVisibility
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)

    return (
        <View style={styles.tabsContainer}>
            <Touchable
                style={{
                    ...styles.tab,
                    ...(arePackagesVisible ? {} : styles.activeTab)
                }}
                onPress={() => setPackagesVisibility(false)}
            >
                <Typography.Regular
                    bold={!arePackagesVisible}
                    forceColor={!arePackagesVisible
                        ? theme.colors.orange
                        : undefined
                    }
                >
                    {T.screens.pestControl.tabs.bySession}
                </Typography.Regular>
                <Grid.Gap gapRight={1}/>
                {servicesAmount > 0 && (
                    <View
                        style={{
                            ...styles.dot,
                            backgroundColor: arePackagesVisible
                                ? theme.colors.night
                                : theme.colors.orange
                        }}
                    >
                        <Typography.Label forceColor={theme.colors.white}>
                            {servicesAmount}
                        </Typography.Label>
                    </View>
                )}
            </Touchable>
            <Touchable
                style={{
                    ...styles.tab,
                    ...(arePackagesVisible ? styles.activeTab : {})
                }}
                onPress={() => setPackagesVisibility(true)}
            >
                <Typography.Regular
                    bold={arePackagesVisible}
                    forceColor={arePackagesVisible
                        ? theme.colors.orange
                        : undefined
                    }
                >
                    {T.screens.pestControl.tabs.byPackage}
                </Typography.Regular>
                <Grid.Gap gapRight={1}/>
                {packagesAmount > 0 && (
                    <View
                        style={{
                            ...styles.dot,
                            backgroundColor: !arePackagesVisible
                                ? theme.colors.night
                                : theme.colors.orange
                        }}
                    >
                        <Typography.Label forceColor={theme.colors.white}>
                            {packagesAmount}
                        </Typography.Label>
                    </View>
                )}
            </Touchable>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    tab: {
        flex: 1,
        justifyContent: 'center',
        flexDirection: 'row',
        height: 50,
        borderBottomWidth: 1,
        borderBottomColor: theme.colors.silver,
        paddingVertical: theme.utils.gap(1),
        paddingHorizontal: {
            lg: theme.utils.gap(3),
            xs: theme.utils.gap(1)
        }
    },
    activeTab: {
        borderBottomWidth: 2,
        borderBottomColor: theme.colors.orange
    },
    tabsContainer: {
        flex: 1,
        flexDirection: 'row',
        height: 70,
        minHeight: 70
    },
    dot: {
        width: 20,
        height: 20,
        borderRadius: 10,
        alignItems: 'center',
        justifyContent: 'center'
    }
}))
