import React, { useMemo, useState } from 'react'
import { ActivityIndicator, Linking, View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { Icons } from 'assets'
import { Job } from 'lib/models'
import { Breakpoint, createStyles } from 'lib/styles'
import { useIsWithinBreakpoints, useStyles, useTranslations } from 'lib/hooks'
import { PhoneModal, Touchable, Typography, UnreadDot } from 'lib/components'
import { ScreenNames } from 'lib/routing'
import { AnalyticsEvent, AnalyticsEventDescription, GoogleAnalytics, Segment, segmentUtils } from 'lib/analytics'
import { useUnreadJobChatsAtom } from 'lib/atoms'
import { useGetAllJobsData } from '../hooks'

type JobContactProps = {
    job: Job,
    onChatPress?: VoidFunction
}

export const JobContact: React.FunctionComponent<JobContactProps> = ({
    job,
    onChatPress
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const navigation = useNavigation()
    const [unreadChatMessages] = useUnreadJobChatsAtom()
    const hasUnreadChatMessage = unreadChatMessages.some(unreadChat => unreadChat.jobFetchId === job._id || unreadChat.jobFetchId === job?.recurring_job)
    const [isPhoneModalOpen, setIsPhoneModalOpen] = useState(false)
    const isMobile = useIsWithinBreakpoints(Breakpoint.XS, Breakpoint.MD)
    const isChildJob = Boolean(job?.recurring_job)
    const { isLoading: isLoadingAllData, recurringJobsData } = useGetAllJobsData()
    const parentChatProps = useMemo(() => {
        const matchingParent = job?.recurring_job && recurringJobsData.find(item => item._id === job.recurring_job)

        return matchingParent
            ? {
                jobId: matchingParent.job_id,
                jobFetchId: matchingParent._id,
                supplierId: matchingParent.supplier._id
            }
            : null
    }, [job, recurringJobsData])

    if (!job.supplier) {
        return null
    }

    const onCallPress = () => {
        GoogleAnalytics.logEvent({
            eventType: AnalyticsEvent.UserAction,
            description: AnalyticsEventDescription.PhoneClicked,
            value: job.job_id
        })
        Segment.callSupplierClicked({
            jobId: job.job_id,
            supplierId: job.supplier._id,
            serviceType: segmentUtils.supplyIdToServiceType(job.supply.id)
        })

        if (isMobile) {
            return Linking.openURL(`tel:${job.supplier.phone}`)
        }

        setIsPhoneModalOpen(true)
    }

    return (
        <View
            style={{
                ...styles.wrapper,
                ...(hasUnreadChatMessage ? styles.highlight : {})
            }}
        >
            <PhoneModal
                isOpen={isPhoneModalOpen}
                onClose={() => setIsPhoneModalOpen(false)}
                phoneNumber={job.supplier.phone}
                expertName={job.supplier.handle}
            />
            <Touchable
                style={styles.container}
                disabled={isChildJob && isLoadingAllData}
                onPress={() => {
                    GoogleAnalytics.logEvent({
                        eventType: AnalyticsEvent.UserAction,
                        description: AnalyticsEventDescription.ChatOpened,
                        value: job.job_id
                    })
                    Segment.chatWithSupplierClicked({
                        jobId: job.job_id,
                        supplierId: job.supplier._id,
                        serviceType: segmentUtils.supplyIdToServiceType(job.supply.id)
                    })

                    onChatPress && onChatPress()
                    navigation.navigate(ScreenNames.Chat, parentChatProps || {
                        jobId: job.job_id,
                        jobFetchId: job._id,
                        supplierId: job.supplier._id
                    })
                }}
            >
                <View style={styles.icon}>
                    {(isChildJob && isLoadingAllData) ? (
                        <ActivityIndicator
                            size="small"
                            color={theme.colors.orange}
                        />
                    ) : (
                        <React.Fragment>
                            <Icons.Chat
                                size={13}
                                forceColor={theme.icon.selected}
                            />
                            <UnreadDot
                                right={-2}
                                isVisible={hasUnreadChatMessage}
                            />
                        </React.Fragment>
                    )}
                </View>
                <Typography.Label
                    bold
                    forceColor={theme.colors.orange}
                >
                    {T.common.chat}
                </Typography.Label>
            </Touchable>
            <View style={styles.spacer} />
            <Touchable
                style={styles.container}
                onPress={onCallPress}
            >
                <View style={styles.icon}>
                    <Icons.Phone
                        size={16}
                        forceColor={theme.icon.selected}
                    />
                </View>
                <Typography.Label
                    bold
                    forceColor={theme.colors.orange}
                >
                    {T.common.call}
                </Typography.Label>
            </Touchable>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 8,
        marginHorizontal: {
            lg: 0,
            xs: -theme.utils.gap(1)
        },
        marginBottom: {
            lg: 0,
            xs: -theme.utils.gap(1)
        },
        borderTopLeftRadius: {
            lg: 8,
            xs: 0
        },
        borderTopRightRadius: {
            lg: 8,
            xs: 0
        }
    },
    highlight: {
        backgroundColor: theme.colors.yellow
    },
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        paddingHorizontal: theme.utils.gap(2),
        paddingVertical: {
            lg: theme.utils.gap(1),
            xs: theme.utils.gap(3) / 2
        }
    },
    icon: {
        marginRight: theme.utils.gap(1) / 2
    },
    spacer: {
        width: 1,
        height: theme.utils.gap(2),
        backgroundColor: theme.colors.orange
    }
}))
