import React from 'react'
import { View } from 'react-native'
import { Field } from '@codegateinc/react-form-builder-v2'
import { createStyles } from 'lib/styles'
import { Nullable } from 'lib/types'
import { useStyles } from 'lib/hooks'
import { BookingDayButton } from './BookingDayButton'
import { FormComponents } from '../components'

interface HorizontalBookingDaysProps extends Field<Record<string, Nullable<string>>> {
    startDate: string,
    disabled: boolean
}

export const HorizontalBookingDays: React.FunctionComponent<HorizontalBookingDaysProps> = ({
    value,
    onChangeValue,
    disabled,
    errorMessage
}) => {
    const { styles } = useStyles(stylesheet)

    return (
        <View>
            <View style={styles.weekContainer}>
                {Object.keys(value).map((dayKey, index) => {
                    const dayValue = value[dayKey]

                    return (
                        <View key={index}>
                            <BookingDayButton
                                index={index}
                                dayKey={dayKey}
                                disabledCheckbox={disabled}
                                isActive={dayValue !== null}
                                onClick={() => {
                                    onChangeValue({
                                        ...value,
                                        [dayKey]: dayValue === null
                                            ? ''
                                            : null
                                    })
                                }}
                            />
                        </View>
                    )
                })}
            </View>
            <FormComponents.ErrorMessage text={errorMessage} />
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    weekContainer: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: {
            xs: 0,
            md: theme.utils.gap(1)
        },
        flexWrap: 'wrap',
        justifyContent: {
            md: 'space-between',
            xs: 'center'
        }
    }
}))
