import React from 'react'
import { Modal } from 'lib/components'
import { PaymentProvider } from 'lib/models'
import { NotificationType } from 'lib/types'
import { useGetErrorMessage, useTranslations } from 'lib/hooks'
import { useToastAtom, useUserAtom, useUserTokenAtom } from 'lib/atoms'
import { removeCard } from '../actions'

type CardDeleteModalProps = {
    isOpen: boolean,
    card: PaymentProvider,
    onClose: VoidFunction
}

export const CardDeleteModal: React.FunctionComponent<CardDeleteModalProps> = ({
    card,
    isOpen,
    onClose
}) => {
    const T = useTranslations()
    const [, setUser] = useUserAtom()
    const [token] = useUserTokenAtom()
    const [, setToastMessage] = useToastAtom()
    const { getErrorMessage } = useGetErrorMessage()
    const { mutate: onRemoveCard, isLoading } = removeCard()

    const onDelete = () => {
        onRemoveCard(
            {
                token,
                payment_method_id: card._id
            },
            {
                onSuccess: ({ data }) => {
                    onClose()
                    setUser(data.profile)
                    setToastMessage({
                        message: T.screens.paymentAndCards.deletedToastMessage,
                        type: NotificationType.Success
                    })
                },
                onError: response => {
                    onClose()
                    setToastMessage({
                        message: getErrorMessage(response),
                        type: NotificationType.Error
                    })
                }
            }
        )
    }

    return (
        <Modal
            disabled={isLoading}
            isLoading={isLoading}
            isOpen={isOpen}
            title={T.screens.paymentAndCards.deleteTitle}
            message={T.screens.paymentAndCards.deleteMessage}
            onClose={onClose}
            onConfirm={onDelete}
            confirmText={T.common.delete}
            cancelText={T.common.cancel}
            onCancel={onClose}
        />
    )
}
