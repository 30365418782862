import { useState } from 'react'
import { PhotoFile, SupplyID } from 'lib/types'
import { useUserTokenAtom } from 'lib/atoms'
import { imageActions } from 'features/image'
import { UploadedPhoto } from 'lib/components'
import { bookingsHooks } from 'features/bookings'
import { axiosWithoutInterceptors } from 'lib/api'

type UseUploadPhotoCallbacks = {
    onSuccess:(photos: Array<UploadedPhoto>) => void,
    onError: VoidFunction
}

export const useUploadPhoto = (supplyId: SupplyID, { onSuccess, onError }: UseUploadPhotoCallbacks) => {
    const [token] = useUserTokenAtom()
    const [isLoading, setIsLoading] = useState(false)
    const getUploadKey = bookingsHooks.useGetUploadKey(supplyId)
    const { mutateAsync: getUploadURL } = imageActions.getS3ImageUploadURL()
    const { mutateAsync: getDownloadURL } = imageActions.getS3ImageDownloadURL()

    const uploadPhotos = (files: Array<PhotoFile>) => {
        setIsLoading(true)

        const requests = files.map((file, index) => new Promise<UploadedPhoto>((resolve, reject) => {
            const uploadKey = getUploadKey(index)

            getUploadURL({
                token,
                key: uploadKey,
                content_type: file.file.type
            })
                .then(data => {
                    axiosWithoutInterceptors.put(data.data.upload_url, file.file, {
                        headers: {
                            'Content-Type': file.file.type
                        }
                    })
                        .then(() => {
                            getDownloadURL({
                                token,
                                key: uploadKey
                            })
                                .then(data => {
                                    const [downloadURL] = data.data.download_url.split('?')

                                    return resolve({
                                        ...file,
                                        downloadUrl: downloadURL
                                    })
                                })
                                .catch(reject)
                        })
                        .catch(reject)

                })
                .catch(reject)
        }))

        Promise.all(requests)
            .then(response => {
                setIsLoading(false)
                onSuccess(response)
            })
            .catch(() => {
                setIsLoading(false)
                onError()
            })
    }

    return {
        isLoading,
        uploadPhotos
    }
}
