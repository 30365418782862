import React from 'react'
import { View } from 'react-native'
import { createStyles } from 'lib/styles'
import { Typography } from 'lib/components'
import { useStyles, useTranslations } from 'lib/hooks'

export const CustomDropdownNoOptions: React.FunctionComponent = () => {
    const T = useTranslations()
    const { theme, styles } = useStyles(stylesheet)

    return (
        <View style={styles.container}>
            <Typography.Regular forceColor={theme.components.input.typography.placeholder}>
                {T.common.noOptions}
            </Typography.Regular>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        paddingVertical: theme.utils.gap(2),
        color: theme.components.input.typography.placeholder,
        alignItems: 'center'
    }
}))
