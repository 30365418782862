import { useStyles, useTranslations } from 'lib/hooks'
import { JobStatus } from 'lib/types'

export const useJobStatus = (jobStatus: JobStatus) => {
    const T = useTranslations()
    const { theme } = useStyles()

    switch (jobStatus) {
        case JobStatus.Cancelled:
            return {
                label: T.components.jobStatus.cancelled,
                colors: {
                    background: theme.components.notificationBox.background.error,
                    text: theme.components.notificationBox.typography.error
                }
            }
        case JobStatus.Submitted:
            return {
                label: T.components.jobStatus.submitted,
                colors: {
                    background: theme.components.notificationBox.background.info,
                    text: theme.components.notificationBox.typography.info
                }
            }
        case JobStatus.Paused:
            return {
                label: T.components.jobStatus.paused,
                colors: {
                    background: theme.components.notificationBox.background.error,
                    text: theme.components.notificationBox.typography.error
                }
            }
        case JobStatus.Accepted:
            return {
                label: T.components.jobStatus.accepted,
                colors: {
                    background: theme.components.notificationBox.background.success,
                    text: theme.components.notificationBox.typography.success
                }
            }
        case JobStatus.Completed:
        default:
            return {
                label: T.components.jobStatus.completed,
                colors: {
                    background: theme.components.notificationBox.background.completed,
                    text: theme.components.notificationBox.typography.completed
                }
            }
    }
}
