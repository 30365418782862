import React from 'react'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { HomeScreen } from 'features/home'
import {
    AddressSetupScreen,
    UserAddressScreen,
    UserEditAddressScreen,
    UserDeleteAccountScreen,
    UserNewAddressScreen,
    UserPreferencesScreen
} from 'features/user'
import { AllServicesPostcodeScreen } from 'features/bookings'
import { UnreadNotificationsScreen } from 'features/notifications'
import { ChatScreen } from 'features/chat'
import { ReferScreen } from 'features/refer'
import { useTranslations } from 'lib/hooks'
import { APP_CONFIG } from 'lib/config'
import { UserCardsNewScreen } from 'features/payments'
import {
    AirConditionDetailsScreen,
    BookingsCancelledScreen,
    BookingsCompletedScreen,
    CleaningDetailsScreen,
    CookingDetailsScreen,
    DeepCleaningDetailsScreen,
    HandymanDetailsScreen,
    LaundryDetailsScreen,
    PestControlDetailsScreen,
    TaskAndErrandsDetailsScreen,
    MoversAndPackersDetailsScreen,
    CancelJobScreen
} from 'features/jobs'
import { ScreenNames } from '../screens'
import { AppStackParams } from '../stackParams'

const Stack = createNativeStackNavigator<AppStackParams>()

export const SharedStack = () => {
    const T = useTranslations()

    return (
        <React.Fragment>
            <Stack.Screen
                name={ScreenNames.Home}
                component={HomeScreen}
                options={{
                    title: T.screens.home.pageTitle
                }}
            />
            <Stack.Screen
                name={ScreenNames.UserDeleteAccount}
                component={UserDeleteAccountScreen}
                options={{
                    title: T.screens.userDeleteAccount.pageTitle
                }}
            />
            <Stack.Screen
                name={ScreenNames.UserAddress}
                component={UserAddressScreen}
                options={{
                    title: T.screens.address.pageTitle
                }}
            />
            <Stack.Screen
                name={ScreenNames.AddressSetup}
                component={AddressSetupScreen}
            />
            <Stack.Screen
                name={ScreenNames.UserNewAddress}
                component={UserNewAddressScreen}
                options={{
                    title: T.screens.addNewAddress.pageTitle
                }}
            />
            <Stack.Screen
                name={ScreenNames.UserEditAddress}
                component={UserEditAddressScreen}
                options={{
                    title: T.screens.editAddress.pageTitle
                }}
            />
            <Stack.Screen
                name={ScreenNames.UserPreferences}
                component={UserPreferencesScreen}
                options={{
                    title: T.screens.preferences.pageTitle
                }}
            />
            <Stack.Screen
                name={ScreenNames.Refer}
                component={ReferScreen}
                options={{
                    title: T.screens.referFriend.pageTitle
                }}
            />
            <Stack.Screen
                name={ScreenNames.UserCardsNew}
                component={UserCardsNewScreen}
                options={{
                    title: T.screens.paymentAndCards.pageTitle
                }}
            />
            {/* todo for now not available */}
            {/* <Stack.Screen */}
            {/*     name={ScreenNames.UserCardsHistory} */}
            {/*     component={PaymentHistory} */}
            {/*     options={{ */}
            {/*         title: T.screens.paymentAndCards.pageTitle */}
            {/*     }} */}
            {/* /> */}
            {/* <Stack.Screen */}
            {/*     name={ScreenNames.OffersAndDeals} */}
            {/*     component={OffersAndDeals} */}
            {/*     options={{ */}
            {/*         title: T.screens.offersAndDeals.pageTitle */}
            {/*     }} */}
            {/* /> */}
            <Stack.Screen
                name={ScreenNames.AllServicesPostcode}
                component={AllServicesPostcodeScreen}
                options={{
                    title: T.screens.allServicesPostcode.pageTitle
                }}
            />
            <Stack.Screen
                name={ScreenNames.BookingsCompleted}
                component={BookingsCompletedScreen}
                options={{
                    title: T.screens.completedBookings.pageTitle
                }}
            />
            <Stack.Screen
                name={ScreenNames.BookingsCancelled}
                component={BookingsCancelledScreen}
                options={{
                    title: T.screens.cancelledBookings.pageTitle
                }}
            />
            <Stack.Screen
                name={ScreenNames.Chat}
                component={ChatScreen}
                options={{
                    title: T.screens.chat.pageTitle
                }}
            />
            <Stack.Screen
                name={ScreenNames.UnreadNotifications}
                component={UnreadNotificationsScreen}
                options={{
                    title: T.screens.notifications.pageTitle
                }}
            />
            <Stack.Screen
                name={ScreenNames.CleaningDetails}
                component={CleaningDetailsScreen}
                options={{
                    title: T.screens.jobDetails.pageTitle
                }}
            />
            <Stack.Screen
                name={ScreenNames.DeepCleaningDetails}
                component={DeepCleaningDetailsScreen}
                options={{
                    title: T.screens.jobDetails.pageTitle
                }}
            />
            <Stack.Screen
                name={ScreenNames.AirConditionDetails}
                component={AirConditionDetailsScreen}
                options={{
                    title: T.screens.jobDetails.pageTitle
                }}
            />
            <Stack.Screen
                name={ScreenNames.CancelJob}
                component={CancelJobScreen}
                options={{
                    title: T.screens.jobDetails.pageTitle
                }}
            />
            {!APP_CONFIG.SERVICES.IS_COOKING_ENABLED
                ? <React.Fragment />
                : (
                    <React.Fragment>
                        <Stack.Screen
                            name={ScreenNames.CookingDetails}
                            component={CookingDetailsScreen}
                            options={{
                                title: T.screens.jobDetails.pageTitle
                            }}
                        />
                    </React.Fragment>
                )
            }
            {!APP_CONFIG.SERVICES.IS_PEST_CONTROL_ENABLED
                ? <React.Fragment />
                : (
                    <React.Fragment>
                        <Stack.Screen
                            name={ScreenNames.PestControlDetails}
                            component={PestControlDetailsScreen}
                            options={{
                                title: T.screens.jobDetails.pageTitle
                            }}
                        />
                    </React.Fragment>
                )
            }
            {!APP_CONFIG.SERVICES.IS_HANDYMAN_ENABLED
                ? <React.Fragment />
                : (
                    <React.Fragment>
                        <Stack.Screen
                            name={ScreenNames.HandymanDetails}
                            component={HandymanDetailsScreen}
                            options={{
                                title: T.screens.jobDetails.pageTitle
                            }}
                        />
                    </React.Fragment>
                )
            }
            {!APP_CONFIG.SERVICES.IS_LAUNDRY_ENABLED
                ? <React.Fragment />
                : (
                    <React.Fragment>
                        <Stack.Screen
                            name={ScreenNames.LaundryDetails}
                            component={LaundryDetailsScreen}
                            options={{
                                title: T.screens.jobDetails.pageTitle
                            }}
                        />
                    </React.Fragment>
                )
            }
            {!APP_CONFIG.SERVICES.IS_TASK_ERRANDS_ENABLED
                ? <React.Fragment />
                : (
                    <React.Fragment>
                        <Stack.Screen
                            name={ScreenNames.TaskAndErrandsDetails}
                            component={TaskAndErrandsDetailsScreen}
                            options={{
                                title: T.screens.jobDetails.pageTitle
                            }}
                        />
                    </React.Fragment>
                )
            }
            {!APP_CONFIG.SERVICES.IS_MOVERS_AND_PACKERS_ENABLED
                ? <React.Fragment />
                : (
                    <React.Fragment>
                        <Stack.Screen
                            name={ScreenNames.MoversAndPackersDetails}
                            component={MoversAndPackersDetailsScreen}
                            options={{
                                title: T.screens.jobDetails.pageTitle
                            }}
                        />
                    </React.Fragment>
                )
            }
        </React.Fragment>
    )
}
