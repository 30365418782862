import { Nullable } from './common'

export enum JobType {
    AdHoc = 'ad hoc',
    Recurring = 'recurring'
}

export type JobSupplier = {
    _id: string,
    phone: string,
    handle: string
}

export type JobSupply = {
    _id: string,
    id: number,
    paid_per_hour: boolean,
    name: string,
    add_extra_charges: boolean
}

export enum JobStatus {
    Submitted = 0,
    Accepted = 1,
    Cancelled = 2,
    Completed = 3,
    Rated = 4,
    Paused = 5,
    Hold = 6
}

export enum LaundryStatus {
    Created = 0,
    PickedUp = 1,
    Ready = 3, // todo update when backend fixed
    Delivered = 4,
    Completed = 5
}

export type JobSchedule = {
    activated: boolean,
    day: string,
    start_time: string
    fortnightly: boolean
}

export enum JobFrequency {
    Weekly = 'weekly'
}

export enum CustomerChargeStatus {
    Pending = 'pending',
    Approved = 'approved'
}

export enum BookingRequestType {
    Session = 'session',
    Plan = 'plan'
}

export type RescheduledBookingRequest = {
    requestType: BookingRequestType,
    jobId: string,
    newStartDate: string,
    newJobSchedule: Record<string, Nullable<string>> | Array<JobSchedule>
}
