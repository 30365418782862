import React  from 'react'
import { View } from 'react-native'
import { Breakpoint, createStyles } from 'lib/styles'
import { AppLayout, Typography } from 'lib/components'
import { useIsWithinBreakpoints, useStyles, useTranslations } from 'lib/hooks'
import { ContactUsForm, ContactUsSections } from '../components'

export const ContactUsScreen: React.FunctionComponent = () => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const isMobile = useIsWithinBreakpoints(Breakpoint.XS, Breakpoint.MD)

    return (
        <AppLayout title={T.screens.contactUs.title}>
            <View style={styles.wrapper}>
                {isMobile && (
                    <Typography.SmallSubheading style={styles.title}>
                        {T.screens.contactUs.title}
                    </Typography.SmallSubheading>
                )}
                <ContactUsForm />
                <ContactUsSections />
            </View>
        </AppLayout>
    )
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        paddingHorizontal: {
            xs: theme.utils.gap(1),
            lg: undefined
        }
    },
    title: {
        marginTop: theme.utils.gap(2)
    }
}))
