import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const Refresh: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path d="M76.507 33.909c-.028.06-.056.121-.082.183-.026.063-.046.13-.067.196a1.395 1.395 0 0 1-.123.31 3.04 3.04 0 0 1-.408.501l-.125.136c-.046.047-.087.1-.128.152a1.333 1.333 0 0 1-.185.206c-.213.174-.46.295-.705.416l-.053.026c-.052.025-.101.056-.15.088-.07.045-.141.09-.22.117a3.741 3.741 0 0 1-1.93.183l-16.192-2.686a3.809 3.809 0 0 1-3.135-4.38c.343-2.073 2.301-3.494 4.381-3.132l6.263 1.036C58.905 18.933 49.85 13.62 39.884 13.62c-11.272 0-21.204 6.545-25.307 16.667a3.803 3.803 0 0 1-4.96 2.103 3.805 3.805 0 0 1-2.099-4.96C12.791 14.412 25.495 6 39.884 6c12.998 0 24.812 7.124 30.774 18.24l1.405-7.516c.389-2.065 2.385-3.433 4.446-3.036a3.807 3.807 0 0 1 3.044 4.445L76.7 33.372a2.33 2.33 0 0 1-.194.537ZM64.66 50.287a3.808 3.808 0 1 1 7.06 2.857C66.447 66.16 53.742 74.573 39.353 74.573c-12.998 0-24.811-7.124-30.773-18.24l-1.41 7.512a3.803 3.803 0 0 1-4.446 3.04 3.805 3.805 0 0 1-3.04-4.446L2.54 47.201c.029-.15.093-.282.157-.416.038-.077.075-.154.106-.235.03-.076.056-.155.082-.234.057-.17.115-.34.21-.486.057-.087.13-.157.205-.226.046-.044.092-.088.135-.136l.1-.117c.181-.214.36-.426.59-.58.022-.017.049-.026.075-.036.023-.009.046-.017.065-.03.37-.24.766-.426 1.204-.521a.29.29 0 0 1 .082-.003c.024.001.048.003.07 0 .42-.07.843-.111 1.288-.035l16.19 2.69a3.807 3.807 0 0 1 3.136 4.38 3.81 3.81 0 0 1-4.38 3.132l-6.264-1.036c4.743 8.327 13.799 13.642 23.764 13.642 11.273 0 21.204-6.545 25.307-16.667Z" />
    </Icon>
)
