import React, { useState } from 'react'
import { View } from 'react-native'
import { Icons } from 'assets'
import { createStyles } from 'lib/styles'
import { Typography, Touchable } from 'lib/components'
import { useStyles, useTranslations } from 'lib/hooks'
import { RescheduleRequestDetailModal } from './rescheduleRequestDetailModal'
import { JobDetailsResponse } from '../types'

type RescheduledMessageProps = {
    response: JobDetailsResponse,
}

export const RescheduledMessage: React.FunctionComponent<RescheduledMessageProps> = ({
    response
}) => {
    const T = useTranslations()
    const [isModalOpen, setIsModalOpen] = useState(false)
    const { styles, theme } = useStyles(stylesheet)
    const planRescheduleRequest = response?.job?.planRescheduleRequest || null
    const sessionRescheduleRequest = response?.job?.sessionRescheduleRequest || null
    const rescheduleRequestData = planRescheduleRequest || sessionRescheduleRequest || null

    const message = rescheduleRequestData?.message
    const button = rescheduleRequestData?.button

    return message && button? (
        <React.Fragment>
            <RescheduleRequestDetailModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} jobDetail={response} />
            <View style={styles.wrapper}>
                <View style={styles.icon}>
                    <Icons.Info size={18} forceColor={theme.colors.orange} />
                </View>
                <Typography.Regular testID={T.accessibility.jobDetailsScreen.rescheduledMessage.notice} forceColor={theme.colors.orange} style={styles.text}>
                    {message}
                    <Touchable testID={T.accessibility.jobDetailsScreen.rescheduledMessage.viewDetails} onPress={() => setIsModalOpen(true)} style={styles.link}>
                        <Typography.Regular forceColor={theme.colors.orange}>
                            {button}
                        </Typography.Regular>
                    </Touchable>

                </Typography.Regular>
            </View>
        </React.Fragment>
    ) : null
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        marginBottom: theme.utils.gap(2),
        borderWidth: 1,
        borderColor: theme.colors.peachPuff,
        flexDirection: 'row',
        padding: theme.utils.gap(1),
        borderRadius: 4,
        backgroundColor: theme.colors.latte,
        justifyContent: 'center'
    },
    text: {
        marginLeft: theme.utils.gap(1)
    },
    link: {
        marginLeft: theme.utils.gap(1),
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: theme.colors.orange
    },
    icon: {
        minWidth: 18,
        minHeight: 18
    }
}))
