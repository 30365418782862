import React from 'react'
import { useNavigation } from '@react-navigation/native'
import Animated, { useAnimatedStyle, withTiming } from 'react-native-reanimated'
import { useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { UnreadChatMessage } from 'lib/models'
import { Touchable, Typography } from 'lib/components'
import { ScreenNames } from 'lib/routing'

type ChatNotificationProps = {
    isVisible: boolean,
    unreadMessages: Array<UnreadChatMessage>
}

export const ChatNotification: React.FunctionComponent<ChatNotificationProps> = ({
    isVisible,
    unreadMessages
}) => {
    const navigation = useNavigation()
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const animatedNotificationStyles = useAnimatedStyle(() => ({
        height: withTiming(isVisible ? 46 : 0),
        opacity: withTiming(isVisible ? 1 : 0, {
            duration: 200
        })
    }))

    return (
        <Animated.View
            style={[
                styles.container,
                animatedNotificationStyles
            ]}
        >
            <Typography.Regular
                bold
                forceColor={theme.colors.orange}
                style={styles.message}
            >
                {T.screens.chat.chatNotification.message} {`(${unreadMessages.length})`} {T.screens.chat.chatNotification.from}
            </Typography.Regular>
            <Touchable
                onPress={() => {
                    if (unreadMessages.length === 1) {
                        const { jobId, jobFetchId, supplierId } = unreadMessages.at(0) as UnreadChatMessage

                        return navigation.navigate(ScreenNames.Chat, {
                            jobId,
                            jobFetchId,
                            supplierId
                        })
                    }

                    navigation.navigate(ScreenNames.Bookings)
                }}
                style={styles.chatNowContainer}
            >
                <Typography.Regular
                    bold
                    forceColor={theme.colors.orange}
                    style={styles.chatNow}
                >
                    {T.screens.chat.chatNotification.cta}
                </Typography.Regular>
            </Touchable>
        </Animated.View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        height: 46,
        flexDirection: 'row',
        backgroundColor: theme.colors.latte,
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        paddingHorizontal: theme.utils.gap(2)
    },
    chatNowContainer: {
        marginLeft: theme.utils.gap(2)
    },
    message: {
        flex: {
            xs: 1,
            md: undefined
        }
    },
    chatNow: {
        textDecorationLine: 'underline'
    }
}))
