import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'
import { useIconColor } from './useIconColor'

export const RemovePhoto: React.FunctionComponent<IconProps> = props => {
    const { forceColor, isActive, ...rest } = props
    const color = useIconColor(isActive, forceColor)

    return (
        <Icon {...rest}>
            <Path
                fill={color}
                fillRule="evenodd"
                d="M50.828 45.172a3.996 3.996 0 0 1 0 5.656A3.989 3.989 0 0 1 48 52a3.989 3.989 0 0 1-2.828-1.172L40 45.656l-5.172 5.172A3.989 3.989 0 0 1 32 52a3.989 3.989 0 0 1-2.828-1.172 3.996 3.996 0 0 1 0-5.656L34.344 40l-5.172-5.172a3.996 3.996 0 0 1 0-5.656 3.996 3.996 0 0 1 5.656 0L40 34.344l5.172-5.172a3.996 3.996 0 0 1 5.656 0 3.996 3.996 0 0 1 0 5.656L45.656 40l5.172 5.172ZM40 0C17.944 0 0 17.944 0 40s17.944 40 40 40 40-17.944 40-40S62.056 0 40 0Z"
                clipRule="evenodd"
            />
        </Icon>
    )
}
