import React, { useEffect } from 'react'
import { Icons } from 'assets'
import { View } from 'react-native'
import { createStyles } from 'lib/styles'
import { useFormatPrice, useStyles, useTranslations } from 'lib/hooks'
import { jobHelpers } from 'lib/utils'
import { Touchable, Typography } from 'lib/components'
import { JobDetailsResponse } from '../types'

type JobReceiptWithAdditionalFeeProps = {
    response: JobDetailsResponse
}

export const JobReceiptWithAdditionalFee: React.FunctionComponent<JobReceiptWithAdditionalFeeProps> = ({
    response
}) => {
    const T = useTranslations()
    const formatPrice = useFormatPrice()
    const [selectedSessionId, setSelectedSessionId] = React.useState<string | null>(null)
    const { styles, theme } = useStyles(stylesheet)
    const upcomingSessions = jobHelpers.getUpcomingSessions(response)
    const cancellationFee = jobHelpers.getCancellationFee(response)
    const formattedCancellationFee = formatPrice(cancellationFee)

    useEffect(() => {
        setSelectedSessionId(upcomingSessions?.[0]?.bookingSessionId ?? null)
    }, [upcomingSessions])

    return (
        <React.Fragment>
            <View style={styles.content}>
                {
                    upcomingSessions.map((session, index) => {
                        const isLastElement = index === upcomingSessions.length - 1
                        const isSelected = selectedSessionId === session?.bookingSessionId

                        return (
                            <Touchable
                                testID={T.accessibility.jobDetailsScreen.jobReceiptWithAdditionFee.receipt?.replace('[index]', index.toString())}
                                key={session?.bookingSessionId ?? index}
                                style={{
                                    ...styles.receiptButton,
                                    ...(isLastElement ? styles.noBorderBottom : {})
                                }}
                                onPress={() => setSelectedSessionId(session.bookingSessionId || null)}
                            >
                                <View style={{
                                    ...styles.titleContainer,
                                    ...(!isSelected && { ...styles.noBorderBottom, ...styles.noPaddingBottom })
                                }}>
                                    <View>
                                        <Typography.Regular bold>
                                            {T.common.receiptSummary}
                                        </Typography.Regular>
                                        <Typography.Regular bold>
                                            {response?.job?.job_id}
                                        </Typography.Regular>
                                    </View>

                                    <View style={{
                                        ...styles.iconContainer,
                                        ...(isSelected ? styles.arrowUp : {})
                                    }}>
                                        <Icons.Chevron size={14} forceColor={theme.colors.orange}/>
                                    </View>
                                </View>
                                {
                                    isSelected && (
                                        <View style={styles.information}>
                                            <Typography.Regular>
                                                {T.common.additionalFee}
                                            </Typography.Regular>

                                            <Typography.Regular bold>
                                                {formattedCancellationFee}
                                            </Typography.Regular>
                                        </View>
                                    )
                                }

                            </Touchable>
                        )
                    })
                }

            </View>
        </React.Fragment>
    )
}

const stylesheet = createStyles(theme => ({
    content: {
        borderRadius: 8,
        borderWidth: 1,
        backgroundColor: theme.colors.white,
        borderColor: theme.colors.silver,
        backGroundColor: theme.colors.white,
        marginBottom: theme.utils.gap(2),
        ...theme.utils.createShadow(2, 0, 8, 4, 4, theme.colors.webShadow(0.04))
    },
    titleContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderColor: theme.colors.silver,
        borderBottomWidth: 1,
        paddingBottom: theme.utils.gap(1.5)
    },
    information: {
        paddingTop: theme.utils.gap(1.5),
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    receiptButton: {
        padding: theme.utils.gap(2),
        borderBottomWidth: 1,
        borderBottomColor: theme.colors.silver
    },
    noBorderBottom: {
        borderBottom: 'none'
    },
    noPaddingBottom: {
        paddingBottom: 0
    },
    iconContainer: {
        width: 24,
        height: 24,
        alignItems: 'center',
        justifyContent: 'center'
    },
    arrowUp: {
        transform: [{ rotate: '180deg' }]
    }
}))
