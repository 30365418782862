import React, { useState } from 'react'
import { View } from 'react-native'
import { Field } from '@codegateinc/react-form-builder-v2'
import { useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { HandymanService as HandymanServiceModel } from 'lib/models'
import { Nullable, NumberInputActions } from 'lib/types'
import { HandymanService, HandymanServiceFields } from 'features/bookings'
import { Adapter, FormComponents, Typography } from 'lib/components'
import { HandymanServiceDetailsFooter } from './HandymanServiceDetailsFooter'
import { handymanHooks } from '../../hooks'
import { HandymanServiceItem } from './HandymanServiceItem'

type HandymanServiceDetailsItemProps = {
    index: number,
    commentField: Field<string>,
    service: HandymanServiceModel
    onRemove: VoidFunction,
    onRemoveDetails(service: HandymanServiceModel): void,
    onQuantityChange(action: NumberInputActions, service: HandymanServiceModel): void,
    onPartsChange(part: string, isSelected: boolean, service: HandymanServiceModel): void,
    onUpdateServiceField(value: Nullable<string>, service: HandymanServiceModel, field: HandymanServiceFields): void
}

export const HandymanServiceDetailsItem: React.FunctionComponent<HandymanServiceDetailsItemProps> = ({
    index,
    commentField,
    service,
    onRemove,
    onRemoveDetails,
    onQuantityChange,
    onPartsChange,
    onUpdateServiceField
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const [isAddingComment, setIsAddingComment] = useState(service.item === HandymanService.Others)
    const serviceTypes = handymanHooks.useHandymanServicesTypes()
    const serviceParts = handymanHooks.useHandymanServicesParts()
    const serviceTypesArray = serviceTypes[service.item] || []
    const servicePartsArray = serviceParts[service.item] || []

    return (
        <View
            style={{
                ...styles.wrapper,
                zIndex: 999 - index
            }}
        >
            <Typography.Error bold>
                {service.category.toUpperCase()}
            </Typography.Error>
            <View style={styles.title}>
                <Typography.Regular bold>
                    {service.item}
                </Typography.Regular>
                <View style={styles.numberWrapper}>
                    <FormComponents.Number
                        minValue={1}
                        quantity={service.amount}
                        onRemove={() => onQuantityChange(NumberInputActions.Remove, service)}
                        onAdd={() => onQuantityChange(NumberInputActions.Add, service)}
                    />
                </View>
            </View>
            {serviceTypesArray.length !== 0 && (
                <View style={styles.selectWrapper}>
                    <Adapter.MultiRadioSelect
                        {...commentField}
                        value={service.type || ''}
                        options={serviceTypesArray.map(option => ({
                            label: option,
                            value: option
                        }))}
                        onChangeValue={value => onUpdateServiceField(value, service, HandymanServiceFields.Type)}
                    />
                </View>
            )}
            {isAddingComment && (
                <View>
                    {servicePartsArray.length !== 0 && (
                        <Typography.Regular bold>
                            {T.components.handymanServiceSelect.selectParts}
                        </Typography.Regular>
                    )}
                    <View style={styles.itemsWrapper}>
                        {servicePartsArray.length !== 0 && servicePartsArray.map((part, index) => {
                            const isSelected = service.part.includes(part)

                            return (
                                <HandymanServiceItem
                                    key={index}
                                    label={part}
                                    isSelected={isSelected}
                                    onPress={() => onPartsChange(part, isSelected, service)}
                                />
                            )
                        })}
                    </View>
                    {service.part.includes(HandymanService.Others) && (
                        <Adapter.TextInput
                            {...commentField}
                            value={service.othersComment || ''}
                            onChangeValue={value => onUpdateServiceField(value, service, HandymanServiceFields.OthersComment)}
                            placeholder={T.components.handymanServiceSelect.describeParts}
                            inputProps={{
                                multiline: true,
                                maxLength: 300
                            }}
                        />
                    )}
                    <FormComponents.FormRow
                        style={styles.commentInput}
                        title={T.screens.handyman.formFields.serviceComment.label}
                    >
                        <Adapter.TextInput
                            {...commentField}
                            value={service.comment || ''}
                            onChangeValue={value => onUpdateServiceField(value, service, HandymanServiceFields.Comment)}
                            label={T.screens.handyman.formFields.serviceComment.label}
                            placeholder={T.screens.handyman.formFields.serviceComment.placeholder}
                            inputProps={{
                                multiline: true,
                                maxLength: 300
                            }}
                        />
                    </FormComponents.FormRow>
                </View>
            )}
            <HandymanServiceDetailsFooter
                isAdding={isAddingComment}
                onRemove={onRemove}
                onAddDetails={() => setIsAddingComment(true)}
                onRemoveDetails={() => {
                    setIsAddingComment(false)
                    onRemoveDetails(service)
                }}
            />
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        borderRadius: theme.components.input.borderRadius,
        borderWidth: theme.components.input.borderWidth,
        borderColor: theme.components.input.emptyBorderColor,
        paddingVertical: theme.utils.gap(1),
        paddingHorizontal: theme.utils.gap(3 / 2),
        marginBottom: theme.utils.gap(2)
    },
    itemsWrapper: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: theme.utils.gap(1),
        marginBottom: theme.utils.gap(1),
        marginHorizontal: -theme.utils.gap(1) / 2
    },
    selectWrapper: {
        zIndex: theme.zIndex[10]
    },
    title: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginVertical: theme.utils.gap(1)
    },
    numberWrapper: {
        width: 100
    },
    commentInput: {
        marginBottom: 0
    }
}))
