import React from 'react'
import { NavigationContainerRefWithCurrent } from '@react-navigation/native'
import { NavigationScreenParams } from 'lib/routing'
import { usePushNotificationAuthStateListener, usePushNotificationListener } from './hooks'

type PushNotificationProviderProps = {
    navigationRef: NavigationContainerRefWithCurrent<NavigationScreenParams>
}

export const PushNotificationProvider: React.FunctionComponent<PushNotificationProviderProps> = ({ navigationRef }) => {
    usePushNotificationAuthStateListener()
    usePushNotificationListener(navigationRef)

    return null
}
