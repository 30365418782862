import React from 'react'
import { View, ViewStyle } from 'react-native'
import { Icons } from 'assets'
import { JobType } from 'lib/types'
import { useJobFrequencyText, useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { RecurringJob } from 'lib/models'
import { CopyBookingId, Typography } from 'lib/components'
import { JobDetailsResponse } from '../types'
import { JobStatusLabelSmall } from './JobStatusLabelSmall'

type JobNameProps = {
    job: JobDetailsResponse,
    isRecurring: boolean,
    recurringJob?: RecurringJob,
    customStyles?: ViewStyle
}

export const JobName: React.FunctionComponent<JobNameProps> = ({
    job,
    recurringJob,
    customStyles = {}
}) => {
    const { styles, theme } = useStyles(stylesheet)
    const { job: { job_id, supply: { name }, schedule } } = job
    const frequencyText = useJobFrequencyText(schedule)
    const jobFrequency = recurringJob?._id
        ? recurringJob.frequency
        : job.job.job_type === JobType.Recurring
            ? job.job.frequency
            : undefined

    return (
        <View
            style={{
                ...styles.wrapper,
                ...customStyles
            }}
        >
            <View style={styles.nameContainer}>
                <View style={styles.jobStatus}>
                    <Typography.Label
                        bold
                        forceColor={theme.colors.darkGrey}
                    >
                        {job_id}
                    </Typography.Label>
                    <JobStatusLabelSmall job={job.job} />
                </View>
                <View style={styles.nameWrapper}>
                    <Typography.Regular
                        bold
                        style={styles.labelText}
                    >
                        {`${name} ${jobFrequency ? `- ${frequencyText}` : ''}`}
                    </Typography.Regular>
                    {jobFrequency && (
                        <View style={styles.frequencyIcon}>
                            <Icons.Refresh size={16} />
                        </View>
                    )}
                </View>
            </View>
            <CopyBookingId id={job_id} />
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        flexDirection: 'row',
        marginBottom: theme.utils.gap(2)
    },
    jobStatus: {
        alignItems: 'center',
        flexDirection: 'row'
    },
    nameWrapper: {
        alignItems: 'center',
        flexDirection: 'row'
    },
    labelText: {
        marginRight: theme.utils.gap(1),
        textTransform: 'capitalize'
    },
    nameContainer: {
        flex: 1
    },
    frequencyIcon: {
        marginLeft: theme.utils.gap(1) / 2
    }
}))
