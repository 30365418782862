import { useMemo } from 'react'
import { dateHelpers } from 'lib/utils'
import { TimeSlot } from '../types'

export const useLaundryAvailableTimeSlots = (date: string, timeSlots?: Array<TimeSlot>) => useMemo(() => {
    if (!date) {
        return timeSlots
    }

    if (dateHelpers.isDateDaySaturday(date)) {
        return timeSlots?.slice(0, 3)
    }

    if (dateHelpers.isDateDaySunday(date)) {
        return timeSlots?.slice(0, 2)
    }

    return timeSlots

}, [timeSlots, date])
