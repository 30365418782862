import { useField } from '@codegateinc/react-form-builder-v2'
import { useTranslations } from 'lib/hooks'
import { Nullable } from 'lib/types'
import { Address } from 'lib/models'
import { SelectPostcodeFields } from './forms'

export const useSelectPostcodeForm = (userAddresses: Array<Address> = []) => {
    const T = useTranslations()
    const [firstAddress] = userAddresses

    const postcode = useField<Nullable<string>>({
        key: SelectPostcodeFields.Postcode,
        initialValue: firstAddress
            ? `${firstAddress.postcode}/${firstAddress._id}`
            : null,
        isRequired: true,
        placeholder: T.screens.allServicesPostcode.formFields.postcode.placeholder,
        validationRules: [
            {
                errorMessage: T.screens.allServicesPostcode.formFields.postcode.validation.isRequired,
                validate: Boolean
            },
            {
                errorMessage: T.screens.allServicesPostcode.formFields.postcode.validation.invalid,
                validate: value => value?.split('/')?.at(0)?.length === 6
            }
        ]
    })

    return {
        postcode
    }
}
