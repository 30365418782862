import { Segment } from 'lib/analytics'
import { SupplyID } from 'lib/types'

export const logCrossaleEvent = (supplyId: number) => {
    switch(supplyId) {
        case SupplyID.Cleaner:
            Segment.crossaleCleaningBookNowClicked({
                serviceType: supplyId
            })
            break
        case SupplyID.DeepCleaning:
            Segment.crossaleDeepCleaningBookNowClicked({
                serviceType: supplyId
            })
            break
        case SupplyID.Cooking:
            Segment.crossaleCookingBookNowClicked({
                serviceType: supplyId
            })
            break
        case SupplyID.Laundry:
            Segment.crossaleLaundryBookNowClicked({
                serviceType: supplyId
            })
            break
        case SupplyID.Handyman:
            Segment.crossaleHandymanBookNowClicked({
                serviceType: supplyId
            })
            break
        case SupplyID.ACService:
            Segment.crossaleAirconBookNowClicked({
                serviceType: supplyId
            })
            break
        case SupplyID.PestControl:
            Segment.crossalePestControlBookNowClicked({
                serviceType: supplyId
            })
            break
        case SupplyID.TasksErrands:
            Segment.crossaleTasksAndErrandsBookNowClicked({
                serviceType: supplyId
            })
            break
        case SupplyID.MoversPackers:
            Segment.crossaleMoversAndPackersBookNowClicked({
                serviceType: supplyId
            })
            break
        default:
            Segment.crossSaleBookNowClicked({
                serviceType: supplyId
            })
    }
}
