export enum PriceSummaryItemNames {
    CleaningPrice = 'Cleaning price',
    PlatformFee = 'Platform fee',
    TotalPrice = 'Total price',
    ReceiptSummary = 'Receipt Summary',
}

export enum JobQuotationSavedItems {
    SavedSubscriptionPlan = 'Saved Subscription Plan',
    SavedOneOffPlan = 'Saved',
}
