export const MOCKED_OFFERS = [
    {
        uuid: '1',
        date: new Date().toISOString(),
        name: 'Christmas $20OFF one-time cleaning services',
        service: 'Handy man service',
        description: 'A sum of $20 will be applied over 1 booking of any home service with successful input of the promo code “OCT20” on the Sendhelper app.'
    },
    {
        uuid: '2',
        date: new Date().toISOString(),
        name: 'Deepavali promo: $10OFF on any service',
        service: 'Deep cleaning',
        description: 'A sum of $20 will be applied over 1 booking of any home service with successful input of the promo code “OCT20” on the Sendhelper app.'
    },
    {
        uuid: '3',
        date: new Date().toISOString(),
        name: 'Christmas $20OFF one-time cleaning services',
        service: 'Handy man service',
        description: 'A sum of $20 will be applied over 1 booking of any home service with successful input of the promo code “OCT20” on the Sendhelper app.'
    },
    {
        uuid: '4',
        date: new Date().toISOString(),
        name: 'Deepavali promo: $10OFF on any service',
        service: 'Deep cleaning',
        description: 'A sum of $20 will be applied over 1 booking of any home service with successful input of the promo code “OCT20” on the Sendhelper app.'
    },
    {
        uuid: '5',
        date: new Date().toISOString(),
        name: 'Deepavali promo: $10OFF on any service',
        service: 'Deep cleaning',
        description: 'A sum of $20 will be applied over 1 booking of any home service with successful input of the promo code “OCT20” on the Sendhelper app.'
    },
    {
        uuid: '6',
        date: new Date().toISOString(),
        name: 'Deepavali promo: $10OFF on any service',
        service: 'Deep cleaning',
        description: 'A sum of $20 will be applied over 1 booking of any home service with successful input of the promo code “OCT20” on the Sendhelper app.'
    },
    {
        uuid: '7',
        date: new Date().toISOString(),
        name: 'Deepavali promo: $10OFF on any service',
        service: 'Deep cleaning',
        description: 'A sum of $20 will be applied over 1 booking of any home service with successful input of the promo code “OCT20” on the Sendhelper app.'
    },
    {
        uuid: '8',
        date: new Date().toISOString(),
        name: 'Deepavali promo: $10OFF on any service',
        service: 'Deep cleaning',
        description: 'A sum of $20 will be applied over 1 booking of any home service with successful input of the promo code “OCT20” on the Sendhelper app.'
    }
]
