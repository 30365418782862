import { useField } from '@codegateinc/react-form-builder-v2'
import { useTranslations } from 'lib/hooks'
import { PestControlAddonsFields } from './forms'

export const usePestControlAddonsForm = () => {
    const T = useTranslations()

    const pets = useField<string>({
        key: PestControlAddonsFields.Pets,
        initialValue: '',
        isRequired: false,
        placeholder: T.screens.cleaningSummary.formFields.pets.placeholder
    })

    const comment = useField<string>({
        key: PestControlAddonsFields.Comment,
        initialValue: '',
        isRequired: false,
        placeholder: T.screens.cleaningSummary.formFields.comment.placeholder
    })

    const promoCode = useField<string>({
        key: PestControlAddonsFields.PromoCode,
        initialValue: '',
        isRequired: false,
        placeholder: T.screens.cleaningSummary.formFields.promoCode.placeholder
    })

    return {
        pets,
        comment,
        promoCode
    }
}
