import { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import * as FileSystem from 'expo-file-system'
import { imageActions } from 'features/image'
import { useUserTokenAtom } from 'lib/atoms'
import { axiosWithoutInterceptors } from 'lib/api'

export const useUploadChatPhoto = () => {
    const [token] = useUserTokenAtom()
    const { mutateAsync: getUploadURL } = imageActions.getS3ImageUploadURL()
    const [isUploadingImage, setIsUploadingImage] = useState(false)

    const uploadNativeImage = async (imageUri: string) => {
        const uploadKey = `chat/${uuidv4()}`

        try {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            const { data: { upload_url } } = await getUploadURL({
                token,
                key: uploadKey,
                content_type: 'image/jpeg'
            })

            const uploadUrlWithoutQueryParams = upload_url
                .split('?')
                .at(0)

            await FileSystem.uploadAsync(upload_url, imageUri, {
                httpMethod: 'PUT',
                headers: {
                    'Content-Type': 'image/jpeg'
                }
            })

            setIsUploadingImage(false)

            return uploadUrlWithoutQueryParams as string
        } catch (error) {
            setIsUploadingImage(false)

            throw error
        }
    }

    const uploadWebImage = async (image: File) => {
        const uploadKey = `chat/${uuidv4()}`

        try {
            const { data: { upload_url } } = await getUploadURL({
                token,
                key: uploadKey,
                content_type: image.type
            })

            const uploadUrlWithoutQueryParams = upload_url
                .split('?')
                .at(0)

            await axiosWithoutInterceptors.put(
                upload_url,
                image,
                {
                    headers: {
                        'Content-Type': image.type
                    }
                }
            )

            setIsUploadingImage(false)

            return uploadUrlWithoutQueryParams as string
        } catch (error) {
            setIsUploadingImage(false)

            throw error
        }
    }

    return {
        isUploadingImage,
        onUpload: (image: File | string): Promise<string> => {
            setIsUploadingImage(true)

            return typeof image === 'string'
                ? uploadNativeImage(image as string)
                : uploadWebImage(image as File)
        }
    }
}
