import React, { useState } from 'react'
import { Address } from 'lib/models'
import { Modal } from 'lib/components'
import { Segment } from 'lib/analytics'
import { authActions, cookieUtils } from 'features/auth'
import { NotificationType } from 'lib/types'
import { useToastAtom, useUserAtom, useUserTokenAtom } from 'lib/atoms'
import { useGetErrorMessage, useQueryHelpers, useTranslations } from 'lib/hooks'
import { removeAddress } from '../actions'

type AddressDeleteModalProps = {
    address: Address,
    isOpen: boolean,
    onClose: VoidFunction
}

export const AddressDeleteModal: React.FunctionComponent<AddressDeleteModalProps> = ({
    address,
    isOpen,
    onClose
}) => {
    const T = useTranslations()
    const [, setToastMessage] = useToastAtom()
    const { getErrorMessage } = useGetErrorMessage()
    const [token] = useUserTokenAtom()
    const [, setUser] = useUserAtom()
    const [error, setError] = useState('')
    const { onRequestError } = useQueryHelpers()
    const { mutate: deleteAddress, isLoading } = removeAddress()
    const { mutate: getMe, isLoading: isLoadingUserProfile } = authActions.useGetMe()

    const handleOnClose = () => {
        setError('')
        onClose()
    }

    const onDelete = () => {
        deleteAddress(
            {
                token,
                address: address._id
            },
            {
                onSuccess: () => {
                    Segment.addressDeleted({})

                    getMe(
                        {
                            token
                        },
                        {
                            onSuccess: ({ data }) => {
                                setUser(data.profile)
                                handleOnClose()
                                setToastMessage({
                                    message: T.screens.address.deletedToastMessage,
                                    type: NotificationType.Success
                                })

                                cookieUtils.setUserAddressesCookie(data.profile.locations)
                            },
                            onError: onRequestError
                        }
                    )
                },
                onError: error => setError(getErrorMessage(error))
            }
        )
    }

    return (
        <Modal
            disabled={isLoading || isLoadingUserProfile}
            isLoading={isLoading || isLoadingUserProfile}
            isOpen={isOpen}
            title={T.screens.address.deleteModalTitle}
            message={T.screens.address.deleteModalMessage}
            onClose={handleOnClose}
            onConfirm={onDelete}
            confirmText={T.common.delete}
            cancelText={T.common.cancel}
            onCancel={handleOnClose}
            error={error}
        />
    )
}
