import React, { useState } from 'react'
import { useTranslations } from 'lib/hooks'
import { OTPVerifyCode } from 'lib/firebase'
import { NavigationParams, ScreenNames } from 'lib/routing'
import { Nullable } from 'lib/types'
import { AuthLayout, OTPForm, AuthTabs } from '../components'
import { AuthUser } from '../types'

type AuthScreenProps = {
    route: NavigationParams<ScreenNames.Auth>
}

export const AuthScreen: React.FunctionComponent<AuthScreenProps> = ({ route }) => {
    const T = useTranslations()
    const [verifyCode, setVerifyCode] = useState<Nullable<OTPVerifyCode>>(null)
    const [initialActiveTabIndex, setInitialActiveTabIndex] = useState(1)
    const [authUser, setAuthUser] = useState<Nullable<AuthUser>>(null)

    return (
        <AuthLayout
            title={T.screens.auth.welcome}
            message={T.screens.auth.welcomeMessage}
        >
            {authUser ? (
                <OTPForm
                    authUser={authUser}
                    onCancel={() => {
                        setAuthUser(null)
                        setInitialActiveTabIndex(1)
                        setVerifyCode(null)
                    }}
                    setVerifyCode={setVerifyCode}
                    onVerifyCode={verifyCode as OTPVerifyCode}
                />
            ) : (
                <AuthTabs
                    onAccountConfirmed={(user, verifyCode) => {
                        setAuthUser(user)
                        setVerifyCode(verifyCode)
                    }}
                    initialIndex={initialActiveTabIndex}
                    resetPasswordSuccessMessage={route.params?.fromResetPassword}
                />
            )}
        </AuthLayout>
    )
}
