import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const CheckSquare: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path fillRule="evenodd" clipRule="evenodd" d="M3 0C1.34315 0 0 1.34315 0 3V15C0 16.6569 1.34315 18 3 18H15C16.6569 18 18 16.6569 18 15V3C18 1.34315 16.6569 0 15 0H3ZM13.3658 5.22871L8.17743 11.459L5.28314 8.56468C4.96362 8.24516 4.44557 8.24516 4.12605 8.56468C3.80653 8.8842 3.80653 9.40224 4.12605 9.72176L7.72605 13.3218C8.09114 13.6868 8.69897 13.6267 8.98536 13.1971L14.7273 6.1364C14.978 5.76042 14.8764 5.25244 14.5004 5.00179C14.1244 4.75114 13.6164 4.85273 13.3658 5.22871Z"/>
    </Icon>
)
