import { useStyles } from 'lib/hooks'

export const useIconColor = (isActive?: boolean, forceColor?: string) => {
    const { theme } = useStyles()

    if (forceColor) {
        return forceColor
    }

    if (isActive) {
        return theme.icon.selected
    }

    return theme.icon.unselected
}
