import React from 'react'
import { View } from 'react-native'
import { Field } from '@codegateinc/react-form-builder-v2'
import { createStyles } from 'lib/styles'
import { Segment } from 'lib/analytics'
import { Typography } from 'lib/components'
import { HandymanService } from 'lib/models'
import { useStyles, useTranslations } from 'lib/hooks'
import { handymanHooks } from '../../hooks'
import { HandymanServiceItem } from './HandymanServiceItem'

type HandymanFrequentlyServiceItemsProps = Field<Array<HandymanService>>

export const HandymanFrequentlyServiceItems: React.FunctionComponent<HandymanFrequentlyServiceItemsProps> = ({
    value,
    onChangeValue
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const frequentlyServiceItems = handymanHooks.useHandymanFrequentlyServiceItems()

    const handlePress = (item: HandymanService, isSelected: boolean) => {
        const newValue = isSelected
            ? value.filter(fieldItems => fieldItems.item !== item.item)
            : [ ...value, item]

        onChangeValue(newValue)
    }

    return (
        <View style={styles.wrapper}>
            <Typography.Error forceColor={theme.colors.darkGrey}>
                {T.components.handymanServiceSelect.frequentlyServiceItems}
            </Typography.Error>
            <View style={styles.itemsWrapper}>
                {frequentlyServiceItems.map((item, index) => {
                    const isSelected = value.some(fieldItems => fieldItems.item === item.item && fieldItems.category === item.category)

                    return (
                        <HandymanServiceItem
                            key={index}
                            label={item.item}
                            isSelected={false}
                            onPress={() => {
                                Segment.bookingServiceTypeSelected({
                                    category: item.category,
                                    item: item.item,
                                    type: item.type || ''
                                })

                                handlePress(item, isSelected)
                            }}
                        />
                    )
                })}
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        marginTop: theme.utils.gap(1)
    },
    itemsWrapper: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: theme.utils.gap(1),
        marginBottom: theme.utils.gap(1),
        marginHorizontal: -theme.utils.gap(1) / 2
    }
}))
