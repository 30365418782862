import { useTranslations } from './useTranslations'
import { ErrorResponse } from '../types'

export const useGetErrorMessage = () => {
    const T = useTranslations()

    return {
        getErrorMessage: (error?: ErrorResponse) => error?.errors?.error_msg ?? T.common.errorMessage,
        getErrorTitle: (error?: ErrorResponse) => error?.errors?.error_title ?? T.errorBoundary.title
    }
}
