import React, { useState } from 'react'
import { View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { Icons } from 'assets'
import { Measurements, isAndroid, isNative } from 'lib/common'
import { ScreenNames } from 'lib/routing'
import { NotificationType, Nullable } from 'lib/types'
import { useToastAtom, useUserAtom, useUserTokenAtom } from 'lib/atoms'
import { Breakpoint, createStyles } from 'lib/styles'
import { CreditPackage, PaymentType } from 'lib/models'
import { AnalyticsEvent, AnalyticsEventDescription, GoogleAnalytics, Segment, segmentUtils } from 'lib/analytics'
import { useBackHandler, useIsWithinBreakpoints, usePrice, useQueryHelpers, useStyles, useTranslations } from 'lib/hooks'
import { Button, CreditCard, GrabPay, ModalWithMobileOverlay, Touchable, Typography } from 'lib/components'
import { addSendPayCredits } from '../actions'

type SendPayPaymentModalProps = {
    amount: number,
    isOpen: boolean,
    onClose: VoidFunction,
    onError: VoidFunction,
    onSuccess: VoidFunction,
    creditPackage: Nullable<CreditPackage>
}

export const SendPayPaymentModal: React.FunctionComponent<SendPayPaymentModalProps> = ({
    amount,
    isOpen,
    onClose,
    onSuccess,
    creditPackage,
    onError
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const [token] = useUserTokenAtom()
    const [user, setUser] = useUserAtom()
    const navigation = useNavigation()
    const [, setToastMessage] = useToastAtom()
    const { onRequestError } = useQueryHelpers()
    const [isNewCardModalOpen, setIsNewCardModalOpen] = useState(false)
    const [isConfirmLoading, setIsConfirmLoading] = useState(false)
    const [defaultCard] = user.payment_providers.filter(item => item.default)
    const isMobile = useIsWithinBreakpoints(Breakpoint.XS, Breakpoint.MD)
    const [paymentMethod, setPaymentMethod] = useState<PaymentType>(PaymentType.Card)
    const { isLoading, mutate: purchaseSendPayCredits } = addSendPayCredits()
    const price = usePrice(amount)

    const onPurchase = () => {
        if (paymentMethod === PaymentType.Card && !defaultCard) {
            return setIsNewCardModalOpen(true)
        }

        const payment = {
            token,
            custom: creditPackage ? undefined : true,
            pkg: creditPackage ?? undefined,
            credit: Number(amount),
            ...paymentMethod === PaymentType.GrabPay
                ? { via: PaymentType.GrabPay.toLowerCase() as Lowercase<PaymentType.GrabPay> }
                : {}
        }

        purchaseSendPayCredits(
            payment,
            {
                onSuccess: ({ data }) => {
                    onClose()

                    if (creditPackage) {
                        Segment.purchaseSendPayCreditPackClicked({
                            creditPack: {
                                name: creditPackage.package_name,
                                value: creditPackage.credit
                            }
                        })
                    }

                    if (!creditPackage) {
                        Segment.purchaseTopUpSendPayCreditsClicked({
                            topUpAmount: Number(amount)
                        })
                    }

                    GoogleAnalytics.logEvent({
                        eventType: AnalyticsEvent.UserPurchase,
                        description: paymentMethod
                    })
                    Segment.purchasedSendPayCredits({
                        purchaseType: creditPackage
                            ? Segment.PurchaseType.Package
                            : Segment.PurchaseType.Manual,
                        paymentMethodType: segmentUtils.getPaymentMethod(paymentMethod),
                        amount
                    })

                    if (paymentMethod === PaymentType.GrabPay && data.grabAuthWebUrl && isNative) {
                        return navigation.navigate(ScreenNames.GrabPayWebView, { url:  data.grabAuthWebUrl })
                    }

                    if (paymentMethod === PaymentType.GrabPay && data.grabAuthWebUrl) {
                        // todo when redirect url will be changed, handle onSuccess/onError with Toast
                        // https://developer.grab.com/docs/payment-otc/api/v2/#tag/otc-api
                        return window.open(data.grabAuthWebUrl, '_blank')?.focus()
                    }

                    onSuccess()
                    setUser(prevState => ({
                        ...prevState,
                        consumer: {
                            ...prevState.consumer,
                            balance_credit: data.credits.available
                        }
                    }))
                    setToastMessage({
                        type: NotificationType.Success,
                        message: T.components.sendPayModal.success.message
                    })
                },
                onError: error => {
                    onClose()
                    onError()
                    onRequestError(error)
                    GoogleAnalytics.logEvent({
                        eventType: AnalyticsEvent.UserAction,
                        description: AnalyticsEventDescription.SendPayCreditsFailed
                    })
                }
            }
        )
    }

    const handleOnClose = () => {
        if (!isLoading) {
            onClose()
        }
    }

    useBackHandler(() => {
        handleOnClose()

        return isOpen
    })

    return (
        <ModalWithMobileOverlay
            isOpen={isOpen}
            onClose={handleOnClose}
        >
            <View style={styles.container}>
                <View style={styles.content}>
                    {isMobile
                        ? (
                            <Touchable
                                style={styles.backButtonWrapper}
                                onPress={handleOnClose}
                            >
                                <View
                                    style={{
                                        ...styles.backButton,
                                        transform: [
                                            {
                                                rotateZ: '90deg'
                                            }
                                        ]
                                    }}
                                >
                                    <Icons.Chevron size={10} />
                                </View>
                                <Typography.Title bold>
                                    {T.screens.payment.selectPayment}
                                </Typography.Title>
                            </Touchable>
                        ) : (
                            <Typography.Title bold>
                                {T.screens.payment.selectPayment}
                            </Typography.Title>
                        )
                    }
                    <CreditCard
                        onSelect={setPaymentMethod}
                        isModalOpen={isNewCardModalOpen}
                        setIsModalOpen={setIsNewCardModalOpen}
                        setIsLoading={setIsConfirmLoading}
                        selectedPaymentMethod={paymentMethod}
                    />
                    <GrabPay
                        onSelect={setPaymentMethod}
                        selectedPaymentMethod={paymentMethod}
                    />
                </View>
                <View
                    style={isMobile
                        ? {
                            ...styles.buttonsContainer,
                            ...styles.buttonsContainerShadow
                        } : {
                            ...styles.buttonsContainer
                        }
                    }
                >
                    {isMobile && (
                        <View style={styles.amount}>
                            <Typography.Error>
                                {T.components.sendPayModal.topUpAmount}
                            </Typography.Error>
                            <Typography.Regular bold>
                                {price}
                            </Typography.Regular>
                        </View>
                    )}
                    <View style={styles.row}>
                        <View style={styles.flex}>
                            <Button
                                noBackground
                                disabled={isLoading}
                                onPress={handleOnClose}
                                text={T.common.cancel}
                            />
                        </View>
                        <View style={styles.spacer} />
                        <View style={styles.flex}>
                            <Button
                                onPress={onPurchase}
                                isLoading={isLoading || isConfirmLoading}
                                text={T.common.confirm}
                            />
                        </View>
                    </View>
                </View>
            </View>
        </ModalWithMobileOverlay>
    )
}

const stylesheet = createStyles(theme => ({
    flex: {
        flex: 1
    },
    row: {
        flexDirection: 'row'
    },
    container: {
        flex: 1,
        width: {
            lg: 700,
            xs: undefined
        },
        paddingHorizontal: {
            lg: theme.utils.gap(3),
            xs: 0
        },
        paddingTop: Measurements.StatusBarHeight
    },
    buttonsContainer: {
        marginTop: theme.utils.gap(4),
        paddingTop: {
            lg: 0,
            xs: theme.utils.gap(4)
        },
        paddingHorizontal: {
            lg: 0,
            xs: theme.utils.gap(2)
        },
        backgroundColor: theme.colors.white
    },
    buttonsContainerShadow: {
        paddingBottom: isAndroid
            ? theme.utils.gap(6)
            : theme.utils.gap(4),
        ...theme.utils.createShadow(0, 0.8, 20, 70)
    },
    spacer: {
        width: theme.utils.gap(2)
    },
    backButtonWrapper: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: theme.utils.gap(2)
    },
    backButton: {
        width: 32,
        height: 32,
        paddingTop: 12,
        marginRight: theme.utils.gap(1),
        alignItems: 'center',
        borderRadius: 4,
        borderWidth: 1,
        borderColor: theme.colors.silver
    },
    content: {
        flex: 1,
        paddingVertical: {
            lg: 0,
            xs: theme.utils.gap(3)
        },
        paddingHorizontal: {
            lg: 0,
            xs: theme.utils.gap(2)
        }
    },
    amount: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: theme.utils.gap(1)
    }
}))
