import React from 'react'
import { Breakpoint } from 'lib/styles'
import { JobStatus, JobType } from 'lib/types'
import { LoadingIndicator, MediaQuery } from 'lib/components'
import { useIsWithinBreakpoints, useTranslations } from 'lib/hooks'
import { NavigationParams, NavigationProps, ScreenNames } from 'lib/routing'
import { getJobDetails } from '../actions'
import { BookingDetailsLayout, ServiceDetailsAccepted, CleaningDetailsContent, MobilePayment } from '../components'
import { useGetAllJobsData } from '../hooks'

type CleaningDetailsScreenProps = {
    navigation: NavigationProps<ScreenNames.CleaningDetails>,
    route: NavigationParams<ScreenNames.CleaningDetails>
}

export const CleaningDetailsScreen: React.FunctionComponent<CleaningDetailsScreenProps> = ({
    route
}) => {
    const T = useTranslations()
    const { isLoading, data: response } = getJobDetails(route.params.jobId)
    const { isLoading: isLoadingAllData, allJobsData } = useGetAllJobsData()
    const isMobile = useIsWithinBreakpoints(Breakpoint.XS, Breakpoint.MD)
    const isRecurringAccepted =
        response?.data.job.job_type === JobType.Recurring &&
        response?.data.job.status === JobStatus.Accepted &&
        route.params.isSession

    if (isLoading || isLoadingAllData || !response) {
        return (
            <BookingDetailsLayout
                isLoading={isLoading}
                title={T.screens.jobDetails.title}
            >
                <LoadingIndicator isLoading />
            </BookingDetailsLayout>
        )
    }

    return (
        <BookingDetailsLayout
            bottomSpacerHeight={isMobile ? 0 : 50}
            title={T.screens.jobDetails.title}
            footer={(
                <MediaQuery.Hidden from={Breakpoint.LG}>
                    <MobilePayment
                        job={response.data}
                        content={closeModal => (
                            <ServiceDetailsAccepted
                                job={response.data}
                                closeModal={closeModal}
                                jobStatus={route.params.jobStatus}
                                isRecurringAccepted={Boolean(isRecurringAccepted)}
                            />
                        )}
                    />
                </MediaQuery.Hidden>
            )}
        >
            <CleaningDetailsContent
                response={response.data}
                allJobsData={allJobsData}
                recurringJob={route.params.recurringJob}
                jobStatus={route.params.jobStatus}
                isSession={route.params.isSession}
            />
        </BookingDetailsLayout>
    )
}
