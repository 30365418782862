import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'
import { useIconColor } from './useIconColor'

export const Wallet: React.FunctionComponent<IconProps> = props => {
    const color = useIconColor(props.isActive, props.forceColor)

    return (
        <Icon
            {...props}
            forceColor="transparent"
        >
            <Path
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={6}
                d="M29.97 19.948 52.975 6.667l7.687 13.315-30.692-.034Z"
                clipRule="evenodd"
            />
            <Path
                stroke={color}
                strokeLinejoin="round"
                strokeWidth={6}
                d="M6.667 23.333A3.333 3.333 0 0 1 10 20h60a3.333 3.333 0 0 1 3.333 3.333V70A3.333 3.333 0 0 1 70 73.333H10A3.333 3.333 0 0 1 6.667 70V23.333Z"
            />
            <Path
                stroke={color}
                strokeLinejoin="round"
                strokeWidth={6}
                d="M58.75 55h14.584V38.333H58.751c-4.833 0-8.75 3.731-8.75 8.334C50 51.269 53.918 55 58.75 55Z"
            />
            <Path
                stroke={color}
                strokeLinecap="round"
                strokeWidth={6}
                d="M73.335 27.5v40"
            />
        </Icon>
    )
}
