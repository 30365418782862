import React, { useState } from 'react'
import { View } from 'react-native'
import { SupplyID } from 'lib/types'
import { createStyles } from 'lib/styles'
import { Typography } from 'lib/components'
import { useStyles, useTranslations } from 'lib/hooks'
import { JobDetailsResponse } from '../types'

type AdditionalInformationProps = {
    job: JobDetailsResponse,
    linesLimit?: number
}

export const AdditionalInformation: React.FunctionComponent<AdditionalInformationProps> = ({
    job,
    linesLimit
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const { job: { petOnPremises, message_to_supplier } } = job
    const [showFullMessage, setShowFullMessage] = useState(true)
    const [maxLines, setMaxLines] = useState(0)
    const canShowMore = maxLines > Number(linesLimit)
    const additionalMessage = job.job.supply.id === SupplyID.Handyman
        ? message_to_supplier.split(T.components.handymanServiceSelect.serviceItems).at(0)
        : message_to_supplier

    return (
        <View style={styles.wrapper}>
            <View style={styles.title}>
                <Typography.Label forceColor={theme.colors.mouse}>
                    {T.screens.jobDetails.sections.additionalInformation}
                </Typography.Label>
            </View>
            <Typography.Label style={styles.content}>
                {petOnPremises // todo consider moving to one text with message_to_supplier to keep numberOfLines
                    ? T.components.petsOptions.yes
                    : T.components.petsOptions.no
                }
            </Typography.Label>
            <Typography.Label
                onNumberOfLines={numberOfLines => {
                    if (!maxLines) {
                        setShowFullMessage(false)
                    }

                    if (showFullMessage) {
                        setMaxLines(numberOfLines)
                    }
                }}
                numberOfLines={showFullMessage ? undefined : linesLimit}>
                {additionalMessage}
            </Typography.Label>
            {canShowMore && (
                <Typography.Regular
                    bold
                    style={styles.viewMore}
                    onPress={() => setShowFullMessage(prevState => !prevState)}
                >
                    {showFullMessage
                        ? T.screens.laundryDetails.showLess
                        : T.screens.laundryDetails.showMore
                    }
                </Typography.Regular>
            )}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        marginBottom: theme.utils.gap(3),
        paddingBottom: theme.utils.gap(2)
    },
    title: {
        marginBottom: theme.utils.gap(1)
    },
    content: {
        marginBottom: theme.utils.gap(1)
    },
    viewMore: {
        position: 'absolute',
        right: 0,
        bottom: 0,
        textDecorationLine: 'underline'
    }
}))
