import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const Mail: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path d="M5.774 16.762 40 40.309l34.226-23.547c-.574-1.886-2.364-3.276-4.459-3.276H10.233c-2.095 0-3.885 1.39-4.459 3.276Zm68.645 6.563L41.6 45.905a2.83 2.83 0 0 1-3.2 0L5.58 23.324v38.618c0 2.508 2.1 4.571 4.652 4.571h59.534c2.552 0 4.652-2.063 4.652-4.571V23.325ZM0 18.057C0 12.52 4.598 8 10.233 8h59.534C75.402 8 80 12.52 80 18.057v43.886C80 67.48 75.402 72 69.767 72H10.233C4.598 72 0 67.48 0 61.943V18.057Z" />
    </Icon>
)
