import React from 'react'
import { View } from 'react-native'
import { useIsWithinBreakpoints, useStyles, useTranslations } from 'lib/hooks'
import { Breakpoint, createStyles } from 'lib/styles'
import { Typography, Touchable } from 'lib/components'
import { CustomFonts } from 'lib/types'

type VoucherInformationProps = {
    onPressBookingNow: VoidFunction
}

export const VoucherInformation: React.FunctionComponent<VoucherInformationProps> = ({ onPressBookingNow }) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const isMobile = useIsWithinBreakpoints(Breakpoint.XS, Breakpoint.MD)

    return isMobile ? (
        <View style={styles.bookNowMobileContainer}>
            <Typography.Regular style={styles.mobileTitle}>{T.components.promotionalModal.yourDiscountCode}</Typography.Regular>
            <Typography.Regular style={styles.voucher}>{T.components.promotionalModal.discountCode}</Typography.Regular>
            <Touchable style={styles.bookNowMobileButton} onPress={onPressBookingNow}>
                <Typography.Regular style={styles.bookNowLabel}>{T.components.promotionalModal.bookNow}</Typography.Regular>
            </Touchable>
        </View>
    ) : (
        <View style={styles.bookNowContainer}>
            <View style={styles.voucherContainer}>
                <Typography.Regular style={styles.voucher}>{T.components.promotionalModal.discountCode}</Typography.Regular>
            </View>
            <Touchable style={styles.bookNowButton} onPress={onPressBookingNow}>
                <Typography.Regular style={styles.bookNowLabel}>{T.components.promotionalModal.bookNow}</Typography.Regular>
            </Touchable>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    bookNowContainer: {
        borderRadius: 8,
        borderWidth: 1,
        borderColor: theme.colors.fog,
        height: 48,
        minWidth: 380,
        paddingLeft: theme.utils.gap(1.5),
        marginBottom: theme.utils.gap(2),
        paddingRight: 140,
        backgroundColor: theme.colors.white,
        position: 'relative'
    },
    voucherContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'flex-start'
    },
    voucher: {
        fontFamily: CustomFonts.Roboto700,
        fontSize: 17,
        color: theme.colors.orange,
        textTransform: 'uppercase'
    },
    bookNowButton: {
        position: 'absolute',
        right: -1,
        top: -1,
        width: 140,
        height: 48,
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
        backgroundColor: theme.colors.sun,
        justifyContent: 'center',
        alignItems: 'center',
        userSelect: 'none'
    },
    bookNowMobileButton: {
        borderRadius: 8,
        backgroundColor: theme.colors.sun,
        paddingHorizontal: theme.utils.gap(2),
        paddingVertical: theme.utils.gap(1.5),
        justifyContent: 'center',
        alignItems: 'center',
        width: 260,
        marginTop: theme.utils.gap(1)
    },
    bookNowLabel: {
        fontFamily: CustomFonts.Roboto500,
        fontSize: 16,
        color: theme.colors.night,
        textTransform: 'capitalize'
    },
    bookNowMobileContainer: {
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(255, 217, 102, 0.32)',
        paddingHorizontal: theme.utils.gap(2.5),
        paddingVertical: theme.utils.gap(1.5),
        marginBottom: theme.utils.gap(2)
    },
    mobileTitle: {
        fontFamily: CustomFonts.Poppins500,
        fontSize: 14,
        color: theme.colors.night,
        marginBottom: theme.utils.gap(1)
    }
}))
