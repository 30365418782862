import { useState } from 'react'
import { useNavigation, useRoute } from '@react-navigation/native'
import { useGetErrorMessage, useTranslations } from 'lib/hooks'
import { firebase, FirebaseAuthError, OTPVerifyCode } from 'lib/firebase'
import { AuthorizeWithAppleResponse, NotificationType } from 'lib/types'
import { ProviderDetails } from 'lib/models'
import { AnalyticsEvent, AnalyticsEventDescription, GoogleAnalytics } from 'lib/analytics'
import { useSocialSignInTokenAtom, useToastAtom } from 'lib/atoms'
import { AuthFinishSocialSignUpScreenParams, NavigationParams, ScreenNames } from 'lib/routing'
import { useSignIn } from './useSignIn'
import { verifyEmail } from '../actions'
import { SignUpFields, SignUpFormShape } from '../forms'
import { AuthFlowError, AuthUser } from '../types'
import { authorizeWithApple, authorizeWithFacebook, getFacebookUserDetails } from '../social'
import { authParams } from 'features/auth'

export const useSignUp = () => {
    const T = useTranslations()
    const navigation = useNavigation()
    const [, setToastMessage] = useToastAtom()
    const [, setSocialSignInToken] = useSocialSignInTokenAtom()
    const route = useRoute<NavigationParams<ScreenNames.Auth>>()
    const { getErrorMessage } = useGetErrorMessage()
    const [isLoading, setIsLoading] = useState(false)
    const { mutate: verifyUserEmail } = verifyEmail()
    const { signInWithApple } = useSignIn()

    const isUserSignedUp = (response: AuthorizeWithAppleResponse) => response.authorization && response.authorization.code && !response.user?.email

    return {
        isLoading,
        verifyUserEmail: (
            form: SignUpFormShape,
            onAccountConfirmed: (authUser: AuthUser, verifyCode: OTPVerifyCode) => void,
            onError: (field: SignUpFields, error: string) => void
        ) => {
            setIsLoading(true)

            const { country, ...otherFields } = form
            const phoneNumber = `${country.code}${form.phoneNumber}`

            verifyUserEmail({
                email_id: form.email
            }, {
                onSuccess: () => {
                    GoogleAnalytics.logEvent({
                        eventType: AnalyticsEvent.UserAction,
                        description: AnalyticsEventDescription.OTPSent
                    })

                    // email is not used, send SMS to verify phone number
                    firebase
                        .auth
                        .signInWithPhoneNumber(phoneNumber)
                        .then(result => onAccountConfirmed({
                            ...otherFields,
                            phoneNumber
                        }, result as OTPVerifyCode))
                        .catch(error => {
                            GoogleAnalytics.logEvent({
                                eventType: AnalyticsEvent.UserAction,
                                description: AnalyticsEventDescription.OTPError
                            })

                            switch (error.code) {
                                case FirebaseAuthError.InvalidNumber:
                                    return onError(SignUpFields.PhoneNumber, T.screens.auth.errors.invalidPhoneNumber)
                                case FirebaseAuthError.UserDisabled:
                                    return onError(SignUpFields.Email, T.screens.auth.errors.userDisabled)
                                case FirebaseAuthError.TooManyRequests:
                                    return onError(SignUpFields.PhoneNumber, T.screens.auth.errors.tooManyRequests)
                                default:
                                    return onError(SignUpFields.ConfirmPassword, T.screens.auth.errors.genericError)
                            }
                        })
                        .finally(() => setIsLoading(false))
                },
                onError: response => {
                    onError(SignUpFields.Email, getErrorMessage(response))
                    setIsLoading(false)
                }
            })
        },
        signUpWithFacebook: (onSuccess?: (params: AuthFinishSocialSignUpScreenParams) => void) => {
            setIsLoading(true)

            authorizeWithFacebook((accessToken, error) => {
                if (!error && accessToken) {
                    return getFacebookUserDetails(accessToken)
                        .then(response => {
                            setIsLoading(false)

                            if (onSuccess) {
                                return onSuccess({
                                    token: response.accessToken,
                                    providerDetails: ProviderDetails.Facebook,
                                    email: response.email,
                                    name: response.name,
                                    service: route.params?.service,
                                    postalcode: route.params?.postalcode
                                })
                            }

                            setSocialSignInToken(response.accessToken)
                            navigation.navigate(ScreenNames.AuthFinishSocialSignUp, {
                                providerDetails: ProviderDetails.Facebook,
                                email: response.email,
                                name: response.name,
                                service: route.params?.service,
                                postalcode: route.params?.postalcode
                            })
                        })
                        .catch(() => {
                            setIsLoading(false)

                            setToastMessage({
                                message: T.screens.auth.errors.facebookError,
                                type: NotificationType.Error
                            })
                        })
                }

                setIsLoading(false)

                if (error === AuthFlowError.ServerError) {
                    setToastMessage({
                        message: T.screens.auth.errors.facebookError,
                        type: NotificationType.Error
                    })
                }
            })
        },
        signUpWithApple: () => {
            setIsLoading(true)

            authorizeWithApple()
                .then(response => {
                    setSocialSignInToken(response.authorization?.id_token || '')
                    isUserSignedUp(response) ? signInWithApple(response) :
                        navigation.navigate(ScreenNames.AuthFinishSocialSignUp, authParams.getAuthFinishAppleSignUpParams(response))
                })
                .catch((error: AuthFlowError) => {
                    if (error === AuthFlowError.ServerError) {
                        setToastMessage({
                            message: T.screens.auth.errors.appleError,
                            type: NotificationType.Error
                        })
                    }

                    setIsLoading(false)
                })
                .finally(() => setIsLoading(false))
        }
    }
}
