import React from 'react'
import { View } from 'react-native'
import { dateHelpers } from 'lib/utils'
import { createStyles } from 'lib/styles'
import { Typography } from 'lib/components'
import { useStyles, useTranslations } from 'lib/hooks'
import { JobDetailsResponse } from '../types'

type DetailsExpertProps = {
    job: JobDetailsResponse
}

export const DetailsExpert: React.FunctionComponent<DetailsExpertProps> = ({
    job
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const date = job.job.date_time || job.job.beginning_date
    const dateTimeText = job.job.num_hours
        ? `${dateHelpers.selectTimeLabel(date)} - ${dateHelpers.selectTimeLabel(dateHelpers.addHoursToDate(date, job.job.num_hours))} (${job.job.num_hours}h)`
        : dateHelpers.selectTimeLabel(date)

    return (
        <View>
            <Typography.Regular bold>
                {dateHelpers.shortFullDate(date)}
            </Typography.Regular>
            <View style={styles.timeWrapper}>
                <Typography.Label>
                    {dateTimeText}
                </Typography.Label>
            </View>
            <View style={styles.expertName}>
                {job.job.supplier && (
                    <Typography.Label>
                        {`${T.components.proposals.expert}: ${job.job.supplier.handle}`}
                    </Typography.Label>
                )}
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    timeWrapper: {
        marginVertical: theme.utils.gap(1)
    },
    expertName: {
        marginBottom: theme.utils.gap(2)
    }
}))
