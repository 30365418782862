import React, { useState } from 'react'
import { View } from 'react-native'
import { Icons } from 'assets'
import { createStyles } from 'lib/styles'
import { BasicModal, Touchable, Typography } from 'lib/components'
import { useStyles, useTranslations } from 'lib/hooks'

type VisitChargesProps = {
    isHandyman?: boolean,
    labelText?: string,
    messageText?: string,
    modalTitle?: string,
    chargesMessage?: string
}

export const VisitCharges: React.FunctionComponent<VisitChargesProps> = ({
    isHandyman,
    labelText,
    messageText,
    modalTitle,
    chargesMessage
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const message = isHandyman
        ? T.screens.handyman.visitChargesDescription
        : T.screens.airConditionSummary.visitChargesDescription
    const label = isHandyman
        ? T.screens.handyman.visitCharges
        : T.screens.airConditionSummary.visitCharges

    return (
        <React.Fragment>
            <BasicModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
            >
                <View style={styles.container}>
                    <View style={styles.title}>
                        <Typography.Regular>
                            {modalTitle || T.screens.airConditionSummary.visitChargesTitle}
                        </Typography.Regular>
                    </View>
                    <Typography.Regular forceColor={theme.colors.darkGrey}>
                        {messageText || message}
                    </Typography.Regular>
                </View>
            </BasicModal>
            <View style={styles.wrapper}>
                <Touchable
                    onPress={() => setIsModalOpen(true)}
                    style={styles.message}
                >
                    <Typography.Regular bold>
                        {labelText || label}
                    </Typography.Regular>
                    <View style={styles.icon}>
                        <Icons.Info size={16} />
                    </View>
                </Touchable>
                <Typography.Label forceColor={theme.colors.darkGrey}>
                    {chargesMessage || T.screens.airConditionSummary.visitChargesMessage}
                </Typography.Label>
            </View>
        </React.Fragment>
    )
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        alignItems: 'center'
    },
    message: {
        alignItems: 'center',
        flexDirection: 'row',
        marginVertical: theme.utils.gap(1)
    },
    icon: {
        marginLeft: theme.utils.gap(1)
    },
    container: {
        maxWidth: 500
    },
    title: {
        borderBottomWidth: 1,
        borderColor: theme.colors.silver,
        paddingBottom: theme.utils.gap(2),
        marginBottom: theme.utils.gap(2)
    }
}))
