import React from 'react'
import { View } from 'react-native'
import { createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { Typography } from 'lib/components'

export const PaymentFeeMessage: React.FunctionComponent = () => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)

    return (
        <View style={styles.container}>
            <Typography.Regular>
                {T.screens.paymentAndCards.verificationFeeMessage}
            </Typography.Regular>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        borderTopWidth: 1,
        marginTop: theme.utils.gap(1),
        paddingVertical: theme.utils.gap(2),
        borderColor: theme.colors.darkSilver
    }
}))
