import React from 'react'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scrollview'
import { Modal as NativeModal, View, StyleSheet, ViewStyle, ScrollViewProps } from 'react-native'
import { Icons } from 'assets'
import { Children } from 'lib/types'
import { useStyles } from 'lib/hooks'
import { Measurements, isIOS, isNative } from 'lib/common'
import { createStyles } from 'lib/styles'
import { Touchable } from './Touchable'
import { Toast } from './Toast'

type CustomModalProps = {
    isOpen: boolean,
    children: Children,
    isFullWindowSize?: boolean,
    isLoading?: boolean,
    animationType?: 'none' | 'slide' | 'fade',
    scrollViewProps?: ScrollViewProps,
    wrapperStyles?: ViewStyle,
    scrollViewContainerStyles?: ViewStyle,
    scrollViewStyles?: ViewStyle,
    customScrollViewStyles?: ViewStyle,
    childrenWrapper?: ViewStyle,
    closeButtonStyles?: ViewStyle,
    contentStyles?: ViewStyle,
    insetsPadding?: boolean,
    testID?: string,
    onClose(byButton?: boolean): void
}

export const CustomModal: React.FunctionComponent<CustomModalProps> = ({
    isOpen,
    children,
    onClose,
    animationType = 'fade',
    isLoading = false,
    wrapperStyles = {},
    scrollViewContainerStyles = {},
    contentStyles = {},
    closeButtonStyles = {},
    childrenWrapper = {},
    scrollViewStyles,
    customScrollViewStyles,
    scrollViewProps,
    testID,
    isFullWindowSize = false,
    insetsPadding = false
}) => {
    const { styles, theme } = useStyles(stylesheet)
    const insets = useSafeAreaInsets()
    const paddingTop = insetsPadding ? insets.top : 0
    const paddingBottom = insetsPadding ? insets.bottom : 0

    const handleClose = (byButton?: boolean) => {
        if (!isLoading) {
            onClose(byButton)
        }
    }

    return (
        <NativeModal
            testID={testID}
            accessibilityLabel={testID}
            transparent
            visible={isOpen}
            statusBarTranslucent
            animationType={animationType}
            onRequestClose={() => handleClose()}
        >
            <View
                style={{
                    ...styles.contentWrapper,
                    padding: theme.utils.gap(isFullWindowSize ? 0 : 2),
                    ...scrollViewStyles,
                    ...wrapperStyles
                }}
            >
                <Touchable
                    onPress={handleClose}
                    style={styles.backdrop}
                />
                <KeyboardAwareScrollView
                    style={{
                        width: isFullWindowSize || isNative
                            ? '100%'
                            : undefined,
                        minHeight: '100%',
                        backgroundColor: theme.colors.transparent,
                        ...scrollViewStyles,
                        ...customScrollViewStyles
                    }}
                    showsVerticalScrollIndicator={false}
                    contentContainerStyle={{
                        minHeight: '100%',
                        backgroundColor: theme.colors.transparent,
                        justifyContent: 'center',
                        alignItems: 'center',
                        ...scrollViewContainerStyles
                    }}
                    {...scrollViewProps}
                >
                    <View style={{
                        ...(isFullWindowSize ? styles.fullWindowSize : {}),
                        ...styles.content(paddingTop, paddingBottom),
                        ...contentStyles
                    }}>
                        <Touchable
                            onPress={() => handleClose(true)}
                            style={{
                                ...styles.closeButton(paddingTop),
                                ...closeButtonStyles
                            }}
                            hitSlopBottom={20}
                            hitSlopRight={20}
                            hitSlopLeft={20}
                            hitSlopTop={20}
                        >
                            <Icons.Close size={16} />
                        </Touchable>
                        <View style={{
                            ...styles.childrenWrapper,
                            ...childrenWrapper
                        }}>
                            {children}
                        </View>
                    </View>
                </KeyboardAwareScrollView>
            </View>
            <Toast />
        </NativeModal>
    )
}

const stylesheet = createStyles(theme => ({
    backdrop: {
        ...StyleSheet.absoluteFillObject
    },
    contentWrapper: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.components.modal.backdrop.backgroundColor,
        paddingTop: isIOS
            ? Measurements.StatusBarHeight
            : 0
    },
    content: (topInset: number, bottomInset: number) => ({
        paddingBottom: theme.utils.gap(5) + bottomInset,
        paddingTop: theme.utils.gap(1.5) + topInset,
        paddingHorizontal: theme.utils.gap(2.3),
        backgroundColor: theme.components.modal.content.backgroundColor,
        borderRadius: theme.components.modal.content.borderRadius
    }),
    closeButton: (topInset: number) => ({
        zIndex: theme.zIndex[10],
        position: 'absolute',
        right: theme.utils.gap(2.5),
        top: theme.utils.gap(2) + topInset
    }),
    childrenWrapper: {
        marginTop: theme.utils.gap(2.5),
        paddingTop: theme.utils.gap(1.5),
        width: {
            lg: 800,
            md: 650,
            sm: 450,
            xs: '100%'
        }
    },
    fullWindowSize: {
        height: '100%',
        width: '100%',
        backgroundColor: theme.colors.orange,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0
    }
}))
