import React from 'react'
import { View, ViewStyle } from 'react-native'
import Animated, { useAnimatedStyle, withTiming } from 'react-native-reanimated'
import { Icons } from 'assets'
import { createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { Label } from './typography'
import { Touchable } from './Touchable'

type SwitchProps = {
    disabled?: boolean,
    hideLabel?: boolean,
    value: boolean,
    onChangeValue: (value: boolean) => void
}

export const Switch: React.FunctionComponent<SwitchProps> = ({
    value,
    onChangeValue,
    disabled = false,
    hideLabel
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const animatedThumbStyles = useAnimatedStyle<ViewStyle>(() => ({
        backgroundColor: value
            ? theme.colors.orange
            : theme.colors.darkSilver,
        transform: [{
            translateX: withTiming(value ? 20 : 0)
        }]
    }))
    const animatedRailsStyles = useAnimatedStyle<ViewStyle>(() => ({
        borderColor: value
            ? theme.colors.orange
            : theme.colors.darkSilver
    }))

    return (
        <View style={styles.container}>
            <Touchable
                disabled={disabled}
                onPress={() => {
                    onChangeValue(!value)
                }}
            >
                <Animated.View
                    style={[
                        animatedRailsStyles,
                        styles.rails
                    ]}
                >
                    <Animated.View
                        style={[
                            animatedThumbStyles,
                            styles.thumb
                        ]}
                    >
                        <Icons.Check
                            size={18}
                            forceColor={
                                value
                                    ? theme.colors.white
                                    : theme.colors.darkSilver
                            }
                        />
                    </Animated.View>
                </Animated.View>
            </Touchable>
            {
                !hideLabel && (
                    <Label>
                        {value
                            ? T.common.on
                            : T.common.off
                        }
                    </Label>
                )
            }

        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    rails: {
        padding: 2,
        width: 56,
        borderRadius: 20,
        marginRight: theme.utils.gap(1),
        borderWidth: 2
    },
    thumb: {
        width: 27,
        height: 27,
        borderRadius: 13.5,
        alignItems: 'center',
        justifyContent: 'center'
    }
}))
