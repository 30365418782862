import { useField } from '@codegateinc/react-form-builder-v2'
import { useTranslations } from 'lib/hooks'
import { ContactUsFields } from './forms'

export const useContactUsForm = () => {
    const T = useTranslations()

    const message = useField<string>({
        key: ContactUsFields.Message,
        initialValue: '',
        isRequired: true,
        validateOnBlur: true,
        placeholder: T.screens.contactUs.formFields.message.placeholder,
        label: T.screens.contactUs.formFields.message.label,
        validationRules: [
            {
                errorMessage: T.screens.contactUs.formFields.message.validation.isRequired,
                validate: Boolean
            },
            {
                errorMessage: T.screens.contactUs.formFields.message.validation.minValue,
                validate: value => value.length > 15
            },
            {
                errorMessage: T.screens.contactUs.formFields.message.validation.maxValue,
                validate: value => value.length < 299
            }
        ]
    })

    return {
        message
    }
}
