import React from 'react'
import { View } from 'react-native'
import { Field } from '@codegateinc/react-form-builder-v2'
import { createStyles } from 'lib/styles'
import { FormComponents, Grid, Touchable, Typography } from 'lib/components'
import { useStyles, useTranslations } from 'lib/hooks'

interface RadioCheckboxProps extends Field<boolean> {
    disabled?: boolean
}

export const RadioButtons: React.FunctionComponent<RadioCheckboxProps> = ({
    onChangeValue,
    errorMessage,
    value,
    label,
    disabled
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)

    return (
        <View>
            <Typography.Label
                style={styles.smallLabel}
                forceColor={theme.colors.darkGrey}
            >
                {label}
            </Typography.Label>
            <View style={styles.row}>
                <Touchable
                    disabled={disabled}
                    style={styles.buttonWrapper}
                    onPress={() => onChangeValue(true)}
                >
                    <View
                        style={{
                            ...styles.button,
                            ...(value ? styles.buttonSelected : {})
                        }}
                    >
                        <Typography.Regular
                            forceColor={value
                                ? theme.colors.orange
                                : theme.colors.night
                            }
                        >
                            {T.common.yes}
                        </Typography.Regular>
                    </View>
                </Touchable>
                <Grid.Gap gapLeft={1} />
                <Touchable
                    disabled={disabled}
                    style={styles.buttonWrapper}
                    onPress={() => onChangeValue(false)}
                >
                    <View
                        style={{
                            ...styles.button,
                            ...(!value ? styles.buttonSelected : {})
                        }}
                    >
                        <Typography.Regular
                            forceColor={!value
                                ? theme.colors.orange
                                : theme.colors.night
                            }
                        >
                            {T.common.no}
                        </Typography.Regular>
                    </View>
                </Touchable>
            </View>
            <FormComponents.ErrorMessage text={errorMessage} />
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    row: {
        flexDirection: 'row'
    },
    smallLabel: {
        marginBottom: theme.utils.gap(1)
    },
    buttonWrapper: {
        flex: 1,
        height: theme.components.input.height
    },
    button: {
        flex: 1,
        borderRadius: 8,
        borderWidth: 1,
        alignItems: 'center',
        justifyContent: 'center',
        borderColor: theme.colors.darkSilver,
        paddingHorizontal: {
            lg: theme.utils.gap(3 / 2),
            xs: theme.utils.gap(1)
        }
    },
    buttonSelected: {
        backgroundColor: theme.colors.yellow,
        borderColor: theme.colors.orange
    }
}))
