import { useField } from '@codegateinc/react-form-builder-v2'
import { useTranslations } from 'lib/hooks'
import { DeleteAccountFields } from './forms'

export const useDeleteAccountForm = () => {
    const T = useTranslations()

    const historyTerms = useField<boolean>({
        key: DeleteAccountFields.HistoryTerms,
        initialValue: false,
        isRequired: true,
        label: T.screens.user.formFields.historyTerms.label,
        validationRules: [
            {
                errorMessage: T.screens.user.formFields.historyTerms.validation.isRequired,
                validate: Boolean
            }
        ]
    })

    const informationTerms = useField<boolean>({
        key: DeleteAccountFields.InformationTerms,
        initialValue: false,
        isRequired: true,
        label: T.screens.user.formFields.informationTerms.label,
        validationRules: [
            {
                errorMessage: T.screens.user.formFields.informationTerms.validation.isRequired,
                validate: Boolean
            }
        ]
    })

    const marketingTerms = useField<boolean>({
        key: DeleteAccountFields.MarketingTerms,
        initialValue: false,
        isRequired: true,
        label: T.screens.user.formFields.marketingTerms.label,
        validationRules: [
            {
                errorMessage: T.screens.user.formFields.marketingTerms.validation.isRequired,
                validate: Boolean
            }
        ]
    })

    const newAccountTerms = useField<boolean>({
        key: DeleteAccountFields.NewAccountTerms,
        initialValue: false,
        isRequired: true,
        label: T.screens.user.formFields.newAccountTerms.label,
        validationRules: [
            {
                errorMessage: T.screens.user.formFields.newAccountTerms.validation.isRequired,
                validate: Boolean
            }
        ]
    })

    return {
        historyTerms,
        informationTerms,
        marketingTerms,
        newAccountTerms
    }
}
