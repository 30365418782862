import { useEffect } from 'react'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { useNavigation } from '@react-navigation/native'
import { useIsAuthorizedAtom, useOnboardingAtom } from 'lib/atoms'
import { GrabPayWebViewScreen } from 'features/sendPay'
import { SharedStack } from './SharedStack'
import { AppStackParams } from '../stackParams'
import { ScreenNames } from '../screens'
import { BottomTabsStack } from './BottomTabsStack'
import { UnauthorizedNativeStack } from './UnauthorizedNativeStack'
import { ServicesStack } from './ServicesStack'

const Stack = createNativeStackNavigator<AppStackParams>()

export const MobileStack = () => {
    const [isAuthorized] = useIsAuthorizedAtom()
    const [isOnboarding] = useOnboardingAtom()
    const navigation = useNavigation()

    useEffect(() => {
        if (!isAuthorized) {
            // Remove history from navigation stack
            navigation.reset({
                index: 0,
                routes: [{ name: ScreenNames.SignUpNative }]
            })
        }
    }, [isAuthorized])

    return (
        <Stack.Navigator
            initialRouteName={ScreenNames.RootBottomTabs}
            screenOptions={{
                headerShown: false
            }}
        >
            {isAuthorized
                ? SharedStack()
                : UnauthorizedNativeStack(isOnboarding)
            }
            {isAuthorized && (
                <Stack.Screen
                    name={ScreenNames.RootBottomTabs}
                    component={BottomTabsStack}
                />
            )}
            {ServicesStack()}
            <Stack.Screen
                name={ScreenNames.GrabPayWebView}
                component={GrabPayWebViewScreen}
            />
        </Stack.Navigator>
    )
}
