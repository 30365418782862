import { View } from 'react-native'
import React from 'react'
import { Grid, Typography } from 'lib/components'
import { useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'

export const LaundryWeightExample: React.FunctionComponent = () => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const examples = [
        T.components.laundryPrices.weightInfo.examples.shirts,
        T.components.laundryPrices.weightInfo.examples.trousers,
        T.components.laundryPrices.weightInfo.examples.underwear,
        T.components.laundryPrices.weightInfo.examples.socks
    ]

    return (
        <View style={styles.container}>
            <View style={styles.infoText}>
                <Typography.Label bold>
                    {T.components.laundryPrices.weightInfo.title}
                </Typography.Label>
                <Typography.Label>
                    {T.components.laundryPrices.weightInfo.message}
                </Typography.Label>
                <Grid.Gap gapBottom={1}/>
                <View style={styles.row}>
                    {examples.map((item, index) => (
                        <React.Fragment key={item}>
                            <Grid.Gap style={styles.clothingTile}>
                                <Typography.Label>
                                    {item}
                                </Typography.Label>
                            </Grid.Gap>
                            {index < examples.length - 1 && (
                                <Typography.Label
                                    bold
                                    style={styles.plus}
                                >
                                    +
                                </Typography.Label>
                            )}
                        </React.Fragment>
                    ))}
                </View>
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        flex: 1
    },
    infoText: {
        padding: {
            lg: theme.utils.gap(2),
            xs: theme.utils.gap(1)
        },
        justifyContent: 'center',
        backgroundColor: theme.colors.silver
    },
    row: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        flexWrap: 'wrap'
    },
    clothingTile: {
        paddingHorizontal: theme.utils.gap(1),
        paddingVertical: theme.utils.gap(0.5),
        borderWidth: 1,
        borderRadius: theme.utils.gap(1)
    },
    plus: {
        marginVertical: theme.utils.gap(2),
        textAlign: 'center',
        width: 24
    }
}))
