import React from 'react'
import { View } from 'react-native'
import { createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { Typography } from 'lib/components'

export const UserDeleteAccountMessage: React.FunctionComponent = () => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)

    return (
        <View style={styles.container}>
            <View style={styles.textRow}>
                <Typography.Regular>
                    {T.screens.user.deleteFormWelcomeMessage}
                </Typography.Regular>
            </View>
            <View style={styles.textRow}>
                <Typography.Regular>
                    {T.screens.user.deleteFormWelcomeMessageSecondParagraph}
                </Typography.Regular>
            </View>
            <View style={styles.textRow}>
                <Typography.Regular>
                    {T.screens.user.deleteFormWelcomeMessageThirdParagraph}
                </Typography.Regular>
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        marginBottom: theme.utils.gap(2)
    },
    textRow: {
        marginTop: theme.utils.gap(1)
    }
}))
