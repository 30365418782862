import { xml } from '@xmpp/client'

export type XMPPClient = {
    on(event: ChatClientEvents.Stanza, stanza: typeof xml),
    send(...args: Array<typeof xml>): Promise<void>,
    leaveRoom(roomName: string): Promise<void>,
    stop(): Promise<void>
}

export enum ChatClientEvents {
    Status = 'status',
    Online = 'online',
    Error = 'error',
    Stanza = 'stanza',
    Offline = 'offline'
}

export enum ConnectionStatus {
    Connecting = 'connecting',
    Connect = 'connect',
    Opening = 'opening',
    Open = 'open',
    Online = 'online',
    Closing = 'closing',
    Disconnect = 'disconnect',
    Offline = 'offline'
}

export enum XMPPErrorCode {
    ConnectionError = 'ECONNERROR'
}
