import { View } from 'react-native'
import React, { useRef, useState } from 'react'
import Animated, { runOnJS, useAnimatedReaction, useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated'
import { Field } from '@codegateinc/react-form-builder-v2'
import { Icons } from 'assets'
import { createStyles } from 'lib/styles'
import { HandymanService } from 'lib/models'
import { Segment } from 'lib/analytics'
import { FormComponents, Touchable, Typography } from 'lib/components'
import { useClickAway, useStyles, useTranslations } from 'lib/hooks'
import { HandymanServicesDropdown } from './HandymanServicesDropdown'
import { HandymanServiceDetails } from './HandymanServiceDetails'
import { HandymanFrequentlyServiceItems } from './HandymanFrequentlyServiceItems'

interface HandymanServicesProps extends Field<Array<HandymanService>> {
    disabled?: boolean,
    isDropdownOpen: boolean,
    commentField: Field<string>,
    setIsDropdownOpen(value: boolean): void
}

export const HandymanServices: React.FunctionComponent<HandymanServicesProps> = ({
    disabled,
    isDropdownOpen,
    setIsDropdownOpen,
    commentField,
    ...fieldProps
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const [query, setQuery] = useState('')
    const optionsDropdownRef = useRef<View>(null)
    const isDropDownVisible = useSharedValue<boolean>(isDropdownOpen)
    const isEmpty = fieldProps.value.length === 0
    const animatedChevronStyles = useAnimatedStyle(() => ({
        transform: [
            {
                rotate: withTiming(`${isDropDownVisible.value ? 180 : 0}deg`, {
                    duration: 200
                })
            }
        ]
    }))
    const onClose = () => {
        setQuery('')
        isDropDownVisible.value = !isDropDownVisible.value
    }

    useAnimatedReaction(() => isDropDownVisible.value, value => {
        runOnJS(setIsDropdownOpen)(value)
    })
    useClickAway({
        elementRef: optionsDropdownRef,
        onClickAway: () => {
            if (isDropDownVisible.value) {
                onClose()
            }
        }
    })

    return (
        <View style={styles.zIndex}>
            <FormComponents.FormRow title={T.screens.handyman.formFields.serviceType.label}>
                <View style={styles.zIndex}>
                    <HandymanServiceDetails
                        {...fieldProps}
                        isOpen={isDropdownOpen}
                        commentField={commentField}
                    />
                </View>
                <View ref={optionsDropdownRef}>
                    <Touchable
                        disabled={disabled}
                        onPress={() => {
                            if (isEmpty) {
                                Segment.bookingSelectServiceTypeClicked({})
                            }

                            onClose()
                        }}
                    >
                        <View style={styles.wrapper}>
                            <View style={styles.row}>
                                {!isEmpty && (
                                    <View style={styles.plusIcon}>
                                        <Icons.Plus size={12} />
                                    </View>
                                )}
                                <Typography.Regular>
                                    {isEmpty
                                        ? T.components.handymanServiceSelect.selectItems
                                        : T.components.handymanServiceSelect.addAnotherItem
                                    }
                                </Typography.Regular>
                            </View>
                            <Animated.View style={[animatedChevronStyles]}>
                                <Icons.Chevron size={10} />
                            </Animated.View>
                        </View>
                    </Touchable>
                    <HandymanServicesDropdown
                        {...fieldProps}
                        query={query}
                        setQuery={setQuery}
                        onClose={onClose}
                        isDropdownOpen={isDropdownOpen}
                        isDropDownVisible={isDropDownVisible}
                    />
                    {isEmpty && (
                        <HandymanFrequentlyServiceItems {...fieldProps} />
                    )}
                </View>
            </FormComponents.FormRow>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    zIndex: {
        zIndex: theme.zIndex[10]
    },
    row: {
        alignItems: 'center',
        flexDirection: 'row'
    },
    plusIcon: {
        marginRight: theme.utils.gap(1)
    },
    wrapper: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: theme.components.input.borderRadius,
        borderWidth: theme.components.input.borderWidth,
        borderColor: theme.components.input.emptyBorderColor,
        padding: theme.components.input.padding
    }
}))
