import React from 'react'
import { View } from 'react-native'
import { Icons } from 'assets'
import { Expert } from 'lib/models'
import { dateHelpers } from 'lib/utils'
import { createStyles } from 'lib/styles'
import { UserPhoto } from 'features/user'
import { Segment, segmentUtils } from 'lib/analytics'
import { NotificationType, ResetFavouriteExpert } from 'lib/types'
import { useToastAtom, useUserAtom, useUserTokenAtom } from 'lib/atoms'
import { LoadingIndicator, Touchable, Typography } from 'lib/components'
import { useQueryHelpers, useStyles, useTranslations } from 'lib/hooks'
import { setPreferredHelper } from '../actions'

type ExpertTileProps = {
    expert: Expert,
    isFavourite?: boolean
}

export const ExpertTile: React.FunctionComponent<ExpertTileProps> = ({
    expert,
    isFavourite
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const [, setToast] = useToastAtom()
    const [token] = useUserTokenAtom()
    const [, setUser] = useUserAtom()
    const { onRequestError } = useQueryHelpers()
    const { mutate: onSetPreferredHelper, isLoading } = setPreferredHelper()

    const onFavouritePress = () => {
        onSetPreferredHelper(
            {
                token,
                supplier: isFavourite
                    ? ResetFavouriteExpert.None
                    : expert.supplier,
                supply: expert.supply.id
            },
            {
                onSuccess: ({ data }) => {
                    if (!isFavourite) {
                        Segment.favouritePartnerSet({
                            partner: {
                                partnerId: expert.supplier._id,
                                partnerHandle: expert.supplier.handle
                            },
                            serviceType: segmentUtils.supplyIdToServiceType(expert.supply.id)
                        })
                    }

                    setUser(data.user_profile)
                    setToast({
                        message: isFavourite
                            ? T.screens.favourite.removedFromFavourites
                            : T.screens.favourite.setsAsFavourite,
                        type: NotificationType.Success
                    })
                },
                onError: onRequestError
            }
        )
    }

    return (
        <View style={styles.container}>
            <LoadingIndicator isLoading={isLoading} />
            <UserPhoto
                size={64}
                name={expert.supplier.handle}
            />
            <View style={styles.row}>
                <Typography.Regular numberOfLines={1}>
                    {expert.supplier.handle}
                </Typography.Regular>
            </View>
            {expert.date_time && (
                <View style={styles.row}>
                    <Typography.Label forceColor={theme.colors.darkGrey}>
                        {`${T.screens.favourite.lastBooking}: ${dateHelpers.lastBookingDate(expert.date_time)}`}
                    </Typography.Label>
                </View>
            )}
            <Touchable
                onPress={onFavouritePress}
                style={styles.favouriteButton}
            >
                <Icons.Heart
                    size={16}
                    fill={
                        isFavourite
                            ? theme.colors.orange
                            : undefined
                    }
                    forceColor={theme.colors.orange}
                />
                <Typography.Label
                    style={styles.favouriteText}
                    forceColor={theme.colors.orange}
                >
                    {
                        isFavourite
                            ? T.screens.favourite.favourite
                            : T.screens.favourite.setAsFavourite
                    }
                </Typography.Label>
            </Touchable>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        width: {
            lg: '20%',
            ':w[1100, 1300]': '25%',
            ':w[, 1100]': '50%'
        },
        alignItems: 'center',
        marginBottom: theme.utils.gap(4)
    },
    row: {
        marginTop: theme.utils.gap(1)
    },
    favouriteButton: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: theme.utils.gap(1)
    },
    favouriteText: {
        borderBottomWidth: 1,
        marginLeft: theme.utils.gap(1),
        borderColor: theme.colors.orange
    }
}))
