import React from 'react'
import { View } from 'react-native'
import { Field } from '@codegateinc/react-form-builder-v2'
import { Address } from 'lib/models'
import { createStyles } from 'lib/styles'
import { stringHelpers } from 'lib/utils'
import { useStyles, useTranslations } from 'lib/hooks'
import { Touchable, Typography } from 'lib/components'

type NativeAddressSelectItemProps = {
    item: Address,
    isPrimary: boolean,
    isSelected: boolean,
    postCodeField: Field<string>
}

export const NativeAddressSelectItem: React.FunctionComponent<NativeAddressSelectItemProps> = ({
    item,
    isPrimary,
    isSelected,
    postCodeField
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const backgroundColor = isSelected
        ? theme.colors.latte
        : theme.colors.white
    const addressUnit = stringHelpers.getFormattedAddressUnit(item.unit)

    return (
        <Touchable onPress={() => postCodeField.onChangeValue(`${item.postcode}/${item._id}`)}>
            <View
                style={{
                    ...styles.container,
                    backgroundColor
                }}
            >
                <View style={styles.radio}>
                    <View
                        style={{
                            ...styles.radioIndicator,
                            ...(isSelected
                                ? styles.radioIndicatorSelected
                                : {}
                            )
                        }}
                    />
                </View>
                <View style={styles.addressText}>
                    <Typography.Label numberOfLines={1}>
                        {item.district}
                    </Typography.Label>
                    <Typography.SmallSubheading numberOfLines={1}>
                        {`${addressUnit}${item.address}, ${item.postcode}`}
                    </Typography.SmallSubheading>
                </View>
                {isPrimary && (
                    <View style={styles.primaryBadge}>
                        <Typography.Label forceColor={theme.colors.orange}>
                            {T.common.primary}
                        </Typography.Label>
                    </View>
                )}
            </View>
        </Touchable>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        borderWidth: 1,
        borderRadius: 8,
        alignItems: 'center',
        flexDirection: 'row',
        height: theme.utils.gap(8),
        marginBottom: theme.utils.gap(2),
        paddingHorizontal: theme.utils.gap(2),
        borderColor: theme.colors.silver
    },
    addressText: {
        flex: 1
    },
    primaryBadge: {
        marginLeft: theme.utils.gap(1),
        backgroundColor: theme.colors.navajo,
        paddingVertical: theme.utils.gap(1) / 2,
        paddingHorizontal: theme.utils.gap(1),
        borderRadius: 4
    },
    radio: {
        width: 24,
        height: 24,
        borderWidth: 2,
        borderRadius: 12,
        justifyContent: 'center',
        alignItems: 'center',
        borderColor: theme.colors.night,
        marginRight: theme.utils.gap(2)
    },
    radioIndicator: {
        width: 12,
        height: 12,
        borderRadius: 6,
        backgroundColor: theme.colors.white
    },
    radioIndicatorSelected: {
        backgroundColor: theme.colors.sun
    }
}))
