import { useMutation } from '@tanstack/react-query'
import { GetPromotionPackagesResponse } from 'features/sendPay'
import { MutationKey, useFetcher } from 'lib/api'
import { ErrorResponse, HttpMethod, Response } from 'lib/types'
import {
    GetMeRequest,
    GetMeResponse,
    SignUpResponse,
    SignUpWithEmailRequest,
    VerifyEmailRequest,
    VerifyEmailResponse,
    SignInResponse,
    SignInWithEmailRequest,
    ForgotPasswordRequest,
    ForgotPasswordResponse,
    SignInWithFacebookRequest,
    SignUpWithFacebookRequest,
    SignInWithAppleRequest,
    SignUpWithAppleRequest,
    GetCancellationReasonsResponse,
    GetProfileDataRequest,
    SignInWithGoogleRequest,
    SignUpWithGoogleRequest
} from './types'

export const verifyEmail = () => {
    const fetcher = useFetcher<VerifyEmailResponse>(HttpMethod.POST, '/user/check-email')

    return useMutation<Response<VerifyEmailResponse>, ErrorResponse, VerifyEmailRequest>(fetcher)
}

export const signUpWithEmail = () => {
    const fetcher = useFetcher<SignUpResponse>(HttpMethod.POST, '/consumer-signup')

    return useMutation<Response<SignUpResponse>, ErrorResponse, SignUpWithEmailRequest>(fetcher)
}

export const useGetMe = () => {
    const fetcher = useFetcher<GetMeResponse>(HttpMethod.POST, '/consumer-get-profile')

    return useMutation<Response<GetMeResponse>, ErrorResponse, GetMeRequest>(fetcher, {
        mutationKey: [MutationKey.GetMe]
    })
}

export const useSignInWithEmail = () => {
    const fetcher = useFetcher<SignInResponse>(HttpMethod.POST, '/consumer-login')

    return useMutation<Response<SignInResponse>, ErrorResponse & { token: string }, SignInWithEmailRequest>(fetcher)
}

export const useSignInWithFacebook = () => {
    const fetcher = useFetcher<SignInResponse>(HttpMethod.POST, '/consumer-login')

    return useMutation<Response<SignInResponse>, ErrorResponse & { token: string }, SignInWithFacebookRequest>(fetcher)
}

export const useSignUpWithFacebook = () => {
    const fetcher = useFetcher<SignUpResponse>(HttpMethod.POST, '/consumer-signup')

    return useMutation<Response<SignUpResponse>, ErrorResponse, SignUpWithFacebookRequest>(fetcher)
}

export const useSignInWithApple = () => {
    const fetcher = useFetcher<SignInResponse>(HttpMethod.POST, '/consumer-login')

    return useMutation<Response<SignInResponse>, ErrorResponse & { token: string }, SignInWithAppleRequest>(fetcher)
}

export const useSignUpWithApple = () => {
    const fetcher = useFetcher<SignUpResponse>(HttpMethod.POST, '/consumer-signup')

    return useMutation<Response<SignUpResponse>, ErrorResponse, SignUpWithAppleRequest>(fetcher)
}

export const useSignInWithGoogle = () => {
    const fetcher = useFetcher<SignInResponse>(HttpMethod.POST, '/consumer-login')

    return useMutation<Response<SignInResponse>, ErrorResponse & { token: string }, SignInWithGoogleRequest>(fetcher)
}

export const useSignUpWithGoogle = () => {
    const fetcher = useFetcher<SignUpResponse>(HttpMethod.POST, '/consumer-signup')

    return useMutation<Response<SignUpResponse>, ErrorResponse, SignUpWithGoogleRequest>(fetcher)
}

export const useForgotPassword = () => {
    const fetcher = useFetcher<ForgotPasswordResponse>(HttpMethod.POST, '/user/password')

    return useMutation<Response<ForgotPasswordResponse>, ErrorResponse, ForgotPasswordRequest>(fetcher)
}

export const useGetCancelationReasons = () => {
    const fetcher = useFetcher<GetCancellationReasonsResponse>(HttpMethod.POST, '/consumer-get-job-cancellation-reasons')

    return useMutation<Response<GetCancellationReasonsResponse>, ErrorResponse, GetProfileDataRequest>(fetcher)
}

export const useGetCreditPackages = () => {
    const fetcher = useFetcher<GetPromotionPackagesResponse>(HttpMethod.POST, '/consumer-get-promotion-packages')

    return useMutation<Response<GetPromotionPackagesResponse>, ErrorResponse, GetProfileDataRequest>(fetcher)
}
