import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import { Field, UseFormReturn } from '@codegateinc/react-form-builder-v2'
import { BookingFrequency, Nullable, SelectInputOption, SupplyID } from 'lib/types'
import { isNative } from 'lib/common'
import { useAddressAtom, useIsAuthorizedAtom, useUserAtom } from 'lib/atoms'
import { PaymentType } from 'lib/models'
import { Segment } from 'lib/analytics'
import { NewAddressShape } from 'features/user'
import { Breakpoint, createStyles } from 'lib/styles'
import { useIsWithinBreakpoints, useStyles, useTranslations } from 'lib/hooks'
import { Adapter, Address, Button, FormComponents, MediaQuery, PolicyLinks, Typography } from 'lib/components'
import { GetPromotionResponse, JobQuotationResponse } from '../types'
import { ServiceLayout } from './ServiceLayout'
import { PaymentMethodTile } from './PaymentMethodTile'
import { IroningServiceDetails } from './IroningServiceDetails'
import { CleaningSummaryColumn } from './CleaningSummaryColumn'
import { BookingCancellationFees } from './BookingCancellationFees'
import { ServiceAddonsFormShape, CleaningFormShape, ServiceAddonsFields } from '../forms'
import { useCleaningBreadcrumbs, usePetsOptions } from '../hooks'
import { PromotionCodeInput } from './PromotionCodeInput'
import { Rated } from './Rated'
import { MobileSummary } from './MobileSummary'
import { SummaryNewAddressForm } from './SummaryNewAddressForm'
import { RecurringBookingSchedule } from './RecurringBookingSchedule'
import { ServiceDescription } from './ServiceDescription'
import { CleaningHelpModalContent } from './CleaningHelpModalContent'
import { BookingAuth } from './unauthorized'
import { PriceSummaryComponent } from './PriceSummaryComponent'
import { SubTotalComponent } from './SubTotalComponent'
import { BookingTotalPrice } from './BookingTotalPrice'
import { getServiceStaticConfig } from '../utils'

type CleaningSummaryViewProps = {
    disabled: boolean,
    selectedPaymentMethod: PaymentType,
    setPaymentView: (value: boolean) => void,
    addonsForm: UseFormReturn<ServiceAddonsFormShape>
    addressForm: Record<keyof NewAddressShape, Field<SelectInputOption & string>>,
    formShape: CleaningFormShape,
    isLoading: boolean,
    totalPrice: number,
    extraHours: number,
    isRecurring: boolean,
    submit: VoidFunction,
    onSubmitGuestBooking: (value) => void,
    setPaymentMethod: (value: PaymentType) => void,
    promotionPackage: Nullable<GetPromotionResponse>,
    originalPrice?: Nullable<number>,
    isMainPageLoading: boolean,
    requestPromotion: (code: string) => void,
    onRemovePromoCode: VoidFunction,
    jobQuotation?: JobQuotationResponse,
    fetchPostalCodes(value: string): Promise<Array<SelectInputOption>>,
}

export const CleaningSummaryView: React.FunctionComponent<CleaningSummaryViewProps> = ({
    addonsForm,
    disabled,
    formShape,
    totalPrice,
    originalPrice,
    requestPromotion,
    setPaymentView,
    promotionPackage,
    isLoading,
    extraHours,
    isRecurring,
    addressForm,
    submit,
    setPaymentMethod,
    selectedPaymentMethod,
    onRemovePromoCode,
    fetchPostalCodes,
    jobQuotation,
    onSubmitGuestBooking,
    isMainPageLoading = false
}) => {
    const T = useTranslations()
    const [user] = useUserAtom()
    const [address] = useAddressAtom()
    const { styles, theme } = useStyles(stylesheet)
    const petsOptions = usePetsOptions()
    const breadcrumbs = useCleaningBreadcrumbs()
    const [isHelpModalOpen, setHelpModalOpen] = useState(false)
    const [isAuthorized] = useIsAuthorizedAtom()

    const [isGuestBookingEnabled, setIsGuestBookingEnabled] = useState(true)
    const [areGuestDetailsValid, setAreGuestDetailsValid] = useState(false)
    const [guestDetails, setGuestDetails] = useState()
    const [isGuestBookingPromoApplied, setIsGuestBookingPromoApplied] = useState(false)
    const [showGuestBookingForm, setShowGuestBookingForm] = useState(false)

    const config = getServiceStaticConfig(SupplyID.Cleaner)
    const [authenticationView, setAuthenticationView] = useState(false)
    const notEnoughCredits = selectedPaymentMethod === PaymentType.Credits && user.consumer.balance_credit < totalPrice
    const isMobile = useIsWithinBreakpoints(Breakpoint.XS, Breakpoint.MD)
    const { form } = addonsForm
    const columnWidth = isNative || isMobile
        ? undefined
        : 'calc(50% - 10px)'
    const confirmDisabled = isAuthorized
        ? disabled
        : false
    const handleConfirm = () => {
        if (!isAuthorized) {

            return setAuthenticationView(true)
        }

        submit()
    }

    const handleGuestConfirm = () => {
        if (isGuestBookingEnabled && areGuestDetailsValid) {

            return onSubmitGuestBooking(guestDetails)
        }

        if (!isAuthorized) {
            return setAuthenticationView(true)
        }
    }

    const handleSubmitGuestBooking = () => {
        if (!areGuestDetailsValid) {
            return
        }

        onSubmitGuestBooking(guestDetails)
    }

    useEffect(() => {
        const { guestBooking } = config
        const { disabled } = guestBooking
        setIsGuestBookingEnabled(!disabled && !isAuthorized && !isRecurring)
    }, [isRecurring, isAuthorized])

    const isContinueButtonDisabled = () => !areGuestDetailsValid

    const renderCTAButton = () => {
        if(authenticationView && !showGuestBookingForm && !isAuthorized) {
            return null
        }

        const buttonProps = {
            testID: T.accessibility.cleaningConfirmButton,
            disabled: (!authenticationView || isAuthorized) ? (notEnoughCredits || confirmDisabled) : isContinueButtonDisabled(),
            isLoading,
            onPress: (!authenticationView || isAuthorized) ? handleConfirm : handleSubmitGuestBooking,
            text: T.common.confirm
        }

        return <Button {...buttonProps} />
    }

    return (
        <React.Fragment>
            <ServiceLayout
                onInfoPress={() => setHelpModalOpen(true)}
                title={T.screens.cleaning.title}
                withBackground
                authenticationView={authenticationView}
                onBackFromDetails={() => {
                    setAuthenticationView(false)
                }}
                breadcrumbs={breadcrumbs}
                contentColumn={authenticationView
                    ? (
                        <BookingAuth
                            isRecurring={isRecurring}
                            supplyId={SupplyID.Cleaner}
                            notEnoughCredits={notEnoughCredits}
                            selectedPaymentMethod={selectedPaymentMethod}
                            setPaymentMethod={setPaymentMethod}
                            isGuestBookingEnabled={isGuestBookingEnabled}
                            setAreGuestDetailsValid={setAreGuestDetailsValid}
                            setGuestDetails={setGuestDetails}
                            setShowGuestBookingForm={setShowGuestBookingForm}
                            showGuestBookingForm={showGuestBookingForm}
                        />
                    ) : (
                        <View>
                            {isNative && (
                                <ServiceDescription
                                    isHelpModalOpen={isHelpModalOpen}
                                    setHelpModalOpen={setHelpModalOpen}
                                    title={T.screens.cleaning.title}
                                    modalContent={(
                                        <CleaningHelpModalContent />
                                    )}
                                    description={T.screens.cleaning.serviceDescription}
                                />
                            )}
                            <View style={styles.wrapper}>
                                <FormComponents.FormRow
                                    isOptional
                                    title={T.screens.cleaningSummary.sections.addOns}
                                >
                                    <View
                                        style={{
                                            ...styles.addonsRow,
                                            width: columnWidth
                                        }}
                                    >
                                        <Adapter.AddonCheckBox
                                            {...form.ironing}
                                            disabled={isLoading}
                                            testID={T.accessibility.cleaningAddonsIroningService}
                                            onChangeValue={value => {
                                                if (!value) {
                                                    form.extraHours.onChangeValue(0)
                                                }

                                                form.ironing.onChangeValue(value)
                                            }}
                                            renderContent={() => (
                                                <IroningServiceDetails />
                                            )}
                                        />
                                    </View>
                                    <View>
                                        {Boolean(form.ironing.value) && (
                                            <Adapter.ExtraHours
                                                {...form.extraHours}
                                                step={0.5}
                                                disabled={isLoading}
                                                description={T.screens.cleaningSummary.formFields.extraHours.description}
                                            />
                                        )}
                                    </View>
                                </FormComponents.FormRow>
                                <FormComponents.FormRow
                                    isOptional
                                    title={T.screens.cleaningSummary.sections.additionalInformation}
                                >
                                    <View
                                        style={{
                                            ...styles.selectWrapper,
                                            width: columnWidth
                                        }}
                                    >
                                        <Adapter.SelectInputColumn
                                            {...form.pets}
                                            disabled={isLoading}
                                            options={petsOptions}
                                            testID={T.accessibility.cleaningPetsSelection}
                                            onOptionSelect={({ label }) => {
                                                Segment.bookingAdditionalInformationAdded({
                                                    information: {
                                                        type: ServiceAddonsFields.Pets,
                                                        value: label
                                                    }
                                                })
                                            }}
                                        />
                                    </View>
                                    <Adapter.TextInput
                                        {...form.comment}
                                        disabled={isLoading}
                                        inputProps={{
                                            multiline: true
                                        }}
                                        testID={T.accessibility.cleaningAdditionalComments}
                                        onBlur={() => {
                                            if (form.comment.value) {
                                                Segment.bookingAdditionalInformationAdded({
                                                    information: {
                                                        type: ServiceAddonsFields.Comment,
                                                        value: form.comment.value
                                                    }
                                                })
                                            }
                                        }}
                                    />
                                    <View>
                                        {!address.postcode && isAuthorized && (
                                            <SummaryNewAddressForm
                                                form={addressForm}
                                                isLoading={isLoading}
                                                fetchPostalCodes={fetchPostalCodes}
                                            />
                                        )}
                                    </View>
                                </FormComponents.FormRow>
                            </View>
                            {formShape.frequency.value !== BookingFrequency.OneTime && (
                                <FormComponents.FormRow>
                                    <RecurringBookingSchedule
                                        title={T.screens.cleaningSummary.schedule}
                                        frequency={formShape.frequency}
                                        oneTimeDate={formShape.bookingTime}
                                        weeklyDays={formShape.bookingDays}
                                    />
                                </FormComponents.FormRow>
                            )}
                            {isMobile && (
                                <PolicyLinks />
                            )}
                        </View>
                    )}
                summaryColumn={(
                    <MediaQuery.Visible from={Breakpoint.LG}>
                        {!(isAuthorized && authenticationView) && (
                            <View>
                                <Address disabled={isLoading} isHidden={showGuestBookingForm} />
                            </View>
                        )}
                        <CleaningSummaryColumn
                            title={T.common.bookingSummary}
                            extraHours={extraHours}
                            renderPriceComponent={() => (
                                <BookingTotalPrice jobQuotation={jobQuotation}/>
                            )}
                            renderPriceSummaryComponent={() => jobQuotation?.priceSummary ? (
                                <PriceSummaryComponent
                                    priceSummary={jobQuotation.priceSummary}
                                    promotion={jobQuotation.discount}
                                    total={jobQuotation?.total}
                                />
                            ) : undefined}
                            renderSubTotalComponent={isRecurring && jobQuotation?.subTotal ? () => (
                                <SubTotalComponent subTotal={jobQuotation?.subTotal} />
                            ) : undefined}
                            form={formShape}
                            jobQuotation={jobQuotation}
                            couponText={
                                promotionPackage ?
                                    promotionPackage.promotion.message_to_client : undefined
                            }
                            totalPrice={totalPrice}
                            originalPrice={originalPrice}
                            promotionPackage={promotionPackage}
                            renderExtraContent={() => (
                                <React.Fragment>
                                    {/* TODO I think we will need to hide this for MVP */}
                                    {/* <AvailableDiscountsTile /> */}
                                    <PromotionCodeInput
                                        promoCode={form.promoCode}
                                        isLoading={isLoading}
                                        onRemovePromoCode={onRemovePromoCode}
                                        promotionPackage={promotionPackage}
                                        requestPromotion={requestPromotion}
                                        setIsGuestBookingPromoCodeApplied={setIsGuestBookingPromoApplied}
                                        isGuestBookingPromoApplied={isGuestBookingPromoApplied}
                                        isPromoCodeEnabled={authenticationView && showGuestBookingForm && isGuestBookingEnabled}
                                    />
                                    {!isGuestBookingEnabled && (
                                        <Typography.Label
                                            forceColor={theme.colors.mouse}
                                            style={styles.cleaningEquipmentCopy}
                                        >
                                            {T.screens.cleaningSummary.cleaningEquipmentCopy}
                                        </Typography.Label>
                                    )}
                                </React.Fragment>
                            )}
                        />
                        {!authenticationView && (
                            <PaymentMethodTile
                                notEnoughCredits={notEnoughCredits}
                                onPress={() => setPaymentView(true)}
                                selectedPaymentMethod={selectedPaymentMethod}
                            />
                        )}
                        <PolicyLinks />
                        {renderCTAButton()}
                        <BookingCancellationFees />
                    </MediaQuery.Visible>
                )}
                footer={(
                    <MediaQuery.Hidden from={Breakpoint.LG}>
                        <MobileSummary
                            authenticationView={authenticationView && !isAuthorized}
                            isGuestBookingEnabled={isGuestBookingEnabled}
                            submit={handleConfirm}
                            guestSubmit={handleGuestConfirm}
                            areGuestDetailsValid={areGuestDetailsValid}
                            submitText={T.common.confirm}
                            priceLabel={T.common.totalPrice}
                            price={totalPrice}
                            onRemovePromoCode={onRemovePromoCode}
                            priceBeforeDiscount={originalPrice}
                            promoCode={form.promoCode}
                            promotionPackage={promotionPackage}
                            requestPromotion={requestPromotion}
                            isLoading={isLoading || isMainPageLoading}
                            disabled={notEnoughCredits || confirmDisabled}
                            jobQuotation={jobQuotation}
                            content={(
                                <React.Fragment>
                                    <View>
                                        <Address disabled={isLoading} />
                                    </View>
                                    <CleaningSummaryColumn
                                        extraHours={extraHours}
                                        renderPriceComponent={() => (
                                            <BookingTotalPrice jobQuotation={jobQuotation}/>
                                        )}
                                        renderPriceSummaryComponent={() => jobQuotation?.priceSummary ? (
                                            <PriceSummaryComponent priceSummary={jobQuotation.priceSummary} promotion={jobQuotation.discount} />
                                        ) : undefined}
                                        renderSubTotalComponent={isRecurring && jobQuotation?.subTotal ? () => (
                                            <SubTotalComponent subTotal={jobQuotation?.subTotal} />
                                        ) : undefined}
                                        form={formShape}
                                        jobQuotation={jobQuotation}
                                        couponText={
                                            promotionPackage ?
                                                promotionPackage.promotion.message_to_client : undefined
                                        }
                                        totalPrice={totalPrice}
                                        originalPrice={originalPrice}
                                        promotionPackage={promotionPackage}
                                        renderExtraContent={() => (
                                            <React.Fragment>
                                                {/* TODO I think we will need to hide this for MVP */}
                                                {/* <AvailableDiscountsTile /> */}
                                                {/* remove this check after guest booking experiment */}
                                                {!isAuthorized && (
                                                    <PromotionCodeInput
                                                        promoCode={form.promoCode}
                                                        isLoading={isLoading}
                                                        onRemovePromoCode={onRemovePromoCode}
                                                        promotionPackage={promotionPackage}
                                                        requestPromotion={requestPromotion}
                                                        setIsGuestBookingPromoCodeApplied={setIsGuestBookingPromoApplied}
                                                        isGuestBookingPromoApplied={isGuestBookingPromoApplied}
                                                        isPromoCodeEnabled={authenticationView && showGuestBookingForm && isGuestBookingEnabled}
                                                    />
                                                )}
                                                {!isGuestBookingEnabled && (
                                                    <Typography.Label
                                                        forceColor={theme.colors.mouse}
                                                        style={styles.cleaningEquipmentCopy}
                                                    >
                                                        {T.screens.cleaningSummary.cleaningEquipmentCopy}
                                                    </Typography.Label>
                                                )}
                                            </React.Fragment>
                                        )}
                                    />
                                    {!authenticationView && (
                                        <PaymentMethodTile
                                            notEnoughCredits={notEnoughCredits}
                                            onPress={() => setPaymentView(true)}
                                            selectedPaymentMethod={selectedPaymentMethod}
                                        />
                                    )}
                                    <PolicyLinks />
                                    <Rated />
                                    <BookingCancellationFees />
                                </React.Fragment>
                            )}
                        />
                    </MediaQuery.Hidden>
                )}
            />
        </React.Fragment>
    )
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        marginTop: {
            lg: 0,
            xs: theme.utils.gap(1)
        },
        zIndex: theme.zIndex[10]
    },
    selectWrapper: {
        zIndex: theme.zIndex[10],
        marginTop: -theme.utils.gap(2)
    },
    addonsRow: {
        flexDirection: {
            lg: 'row',
            xs: 'column'
        }
    },
    spacer: {
        width: theme.utils.gap(2)
    },
    cleaningEquipmentCopy: {
        marginVertical: theme.utils.gap(2)
    }
}))
