import React from 'react'
import { View } from 'react-native'
import { createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { Touchable, Typography } from 'lib/components'

type PrimaryCardBadgeProps = {
    isPrimary: boolean,
    onSetAsPrimary: VoidFunction
}

export const PrimaryCardBadge: React.FunctionComponent<PrimaryCardBadgeProps> = ({
    isPrimary,
    onSetAsPrimary
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)

    return (
        <View style={styles.row}>
            {isPrimary
                ? (
                    <View style={styles.primaryBadge}>
                        <Typography.Label forceColor={theme.colors.orange}>
                            {T.common.primary}
                        </Typography.Label>
                    </View>
                ) : (
                    <Touchable
                        onPress={onSetAsPrimary}
                        style={styles.primaryButton}
                    >
                        <Typography.Label forceColor={theme.colors.orange}>
                            {T.common.setAsPrimary}
                        </Typography.Label>
                    </Touchable>
                )
            }
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    row: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    primaryButton: {
        borderBottomWidth: 1,
        borderBottomColor: theme.colors.orange
    },
    primaryBadge: {
        backgroundColor: theme.colors.navajo,
        paddingVertical: theme.utils.gap(1) / 2,
        paddingHorizontal: theme.utils.gap(4),
        borderRadius: 4
    }
}))
