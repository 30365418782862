import React from 'react'
import { ViewStyle } from 'react-native'
import Animated, { useAnimatedStyle, useSharedValue } from 'react-native-reanimated'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { Touchable, Typography } from 'lib/components'
import { SelectInputOption } from 'lib/types'

type DateScrollSelectTileProps = {
    selectedValue: string,
    value: SelectInputOption,
    onChangeValue: (value: string) => void,
    disabled?: boolean
}

export const DateScrollSelectTile: React.FunctionComponent<DateScrollSelectTileProps> = ({
    value,
    disabled,
    onChangeValue,
    selectedValue
}) => {
    const { styles, theme } = useStyles(stylesheet)
    const isSelected = value.value === selectedValue
    const animatedHover = useSharedValue(false)
    const animatedHoverStyles = useAnimatedStyle<ViewStyle>(() => ({
        backgroundColor: (animatedHover.value || isSelected) ?
            theme.colors.silver :
            theme.colors.transparent
    }))

    return (
        <Touchable
            disabled={disabled}
            onPress={() =>onChangeValue(value.value)}
            onHoverIn={() => animatedHover.value = true}
            onHoverOut={() => animatedHover.value = false}
        >
            <Animated.View
                style={[
                    styles.listItem,
                    animatedHoverStyles
                ]}
            >
                <Typography.Regular
                    forceColor={!isSelected
                        ? theme.colors.mouse
                        : undefined
                    }
                >
                    {value.label}
                </Typography.Regular>
            </Animated.View>
        </Touchable>
    )
}

const stylesheet = createStyles(theme => ({
    listItem: {
        width: 220,
        borderRadius: 8,
        alignItems: 'center',
        marginHorizontal: theme.utils.gap(1),
        paddingVertical: theme.utils.gap(1)
    }
}))
