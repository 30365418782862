import { useField } from '@codegateinc/react-form-builder-v2'
import { PauseBookingsFields } from './forms'

export const usePauseBookingForm = () => {
    const startDate = useField<string>({
        key: PauseBookingsFields.StartDate,
        initialValue: '',
        isRequired: true,
        validateOnBlur: false
    })

    const endDate = useField<string>({
        key: PauseBookingsFields.EndDate,
        initialValue: '',
        isRequired: true,
        validateOnBlur: false
    })

    return {
        startDate,
        endDate
    }
}
