import React, { useState } from 'react'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { Modal as NativeModal, View, StyleSheet, ScrollView } from 'react-native'
import { Icons } from 'assets'
import { JobStatus } from 'lib/types'
import { Job, RecurringJob } from 'lib/models'
import { dateHelpers } from 'lib/utils'
import { useLocaleAtom } from 'lib/atoms'
import { Measurements, isNative } from 'lib/common'
import { Breakpoint, createStyles } from 'lib/styles'
import { useIsWithinBreakpoints, useStyles, useTranslations } from 'lib/hooks'
import { Touchable } from '../Touchable'
import { Regular } from '../typography'
import { BookingCalendarModalTile } from './BookingCalendarModalTile'
import { BookingCalendarModalNavigation } from './BookingCalendarModalNavigation'
import { ITEMS_PER_PAGE } from './consts'
import { Toast } from '../Toast'

type BookingCalendarModalProps = {
    day: string,
    data?: Array<Job>,
    recurringJobs: Array<RecurringJob>,
    isOpen: boolean,
    onClose: VoidFunction,
    jobStatus: JobStatus
}

export const BookingCalendarModal: React.FunctionComponent<BookingCalendarModalProps> = ({
    day,
    data,
    recurringJobs,
    isOpen,
    onClose,
    jobStatus
}) => {
    const T = useTranslations()
    const [language] = useLocaleAtom()
    const insets = useSafeAreaInsets()
    const [page, setPage] = useState(0)
    const { styles, theme } = useStyles(stylesheet)
    const isMobile = useIsWithinBreakpoints(Breakpoint.XS, Breakpoint.MD)
    const withNavigation = data ? data.length > 2 : false
    const dataLength = data?.length || 0
    const pagedData = data?.slice(page * ITEMS_PER_PAGE, (page * ITEMS_PER_PAGE) + ITEMS_PER_PAGE)
    const isLastPage = pagedData?.length === page

    const handleClose = () => {
        onClose()
        setPage(0)
    }

    return (
        <NativeModal
            transparent
            visible={isOpen}
            animationType="fade"
            statusBarTranslucent
            onRequestClose={handleClose}
            testID={T.accessibility.bookingsScreen.views.calendarView.modal}
        >
            <View
                style={{
                    ...styles.contentWrapper,
                    padding: isMobile
                        ? 0
                        : theme.utils.gap(5)
                }}
            >
                <Touchable
                    onPress={handleClose}
                    style={styles.backdrop}
                />
                <View
                    style={{
                        ...styles.content,
                        height: !isMobile || isNative
                            ? undefined
                            : Measurements.WindowHeight
                    }}
                >
                    <ScrollView showsVerticalScrollIndicator={false}>
                        <View
                            style={{
                                paddingTop: theme.utils.gap(2) + insets.top
                            }}
                        >
                            {Boolean(day) && (
                                <View style={styles.title}>
                                    {isMobile && (
                                        <Touchable onPress={handleClose}>
                                            <View
                                                style={{
                                                    ...styles.backButton,
                                                    transform: [
                                                        {
                                                            rotateZ: '90deg'
                                                        }
                                                    ]
                                                }}
                                            >
                                                <Icons.Chevron size={10} />
                                            </View>
                                        </Touchable>
                                    )}
                                    <View style={styles.titleText}>
                                        <Regular bold>
                                            {dateHelpers.getFullBookingListViewDate(day, language)}
                                        </Regular>
                                    </View>
                                    {!isMobile && (
                                        <Touchable
                                            onPress={handleClose}
                                            hitSlopBottom={20}
                                            hitSlopRight={20}
                                            hitSlopLeft={20}
                                            hitSlopTop={20}
                                        >
                                            <Icons.Close size={14} />
                                        </Touchable>
                                    )}
                                </View>
                            )}
                            <View style={styles.cards}>
                                {pagedData?.map((job, index) => (
                                    <React.Fragment key={index}>
                                        <BookingCalendarModalTile
                                            job={job}
                                            onClose={handleClose}
                                            recurringJobs={recurringJobs}
                                            jobStatus={jobStatus}
                                        />
                                        {index % 2 === 0 && !isLastPage && (
                                            <View style={styles.separator} />
                                        )}
                                    </React.Fragment>
                                ))}
                            </View>
                            {withNavigation && (
                                <BookingCalendarModalNavigation
                                    currentPage={page}
                                    dataLength={dataLength}
                                    onNextPage={() => setPage(prevState => prevState + 1)}
                                    onPrevPage={() => setPage(prevState => prevState - 1)}
                                />
                            )}
                        </View>
                    </ScrollView>
                </View>
            </View>
            <Toast />
        </NativeModal>
    )
}

const stylesheet = createStyles(theme => ({
    backdrop: {
        ...StyleSheet.absoluteFillObject
    },
    contentWrapper: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
    content: {
        width: {
            lg: 400,
            xs: '100%'
        },
        paddingVertical: {
            lg: theme.utils.gap(3),
            xs: 0
        },
        paddingHorizontal: {
            lg: theme.utils.gap(3),
            xs: 0
        },
        backgroundColor: theme.colors.marble,
        borderRadius: {
            lg: 8,
            xs: 0
        },
        marginHorizontal: {
            lg: theme.utils.gap(3),
            xs: 0
        },
        marginVertical: {
            lg: theme.utils.gap(2),
            xs: 0
        },
        ...theme.utils.createShadow(2, 0, 8, 4, 4, theme.colors.webShadow(0.2))
    },
    title: {
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: {
            lg: 'space-between',
            xs: 'center'
        },
        borderBottomWidth: {
            lg: 1,
            xs: 0
        },
        borderColor: theme.colors.silver,
        paddingBottom: theme.utils.gap(2),
        paddingTop: {
            lg: undefined,
            xs: theme.utils.gap(2)
        },
        backgroundColor: {
            lg: undefined,
            xs: theme.colors.white
        },
        paddingHorizontal: {
            lg: undefined,
            xs: theme.utils.gap(1)
        }
    },
    cards: {
        marginTop: theme.utils.gap(1),
        marginHorizontal: {
            lg: undefined,
            xs: theme.utils.gap(1)
        }
    },
    separator: {
        height: 1,
        backgroundColor: theme.colors.silver
    },
    backButton: {
        width: 32,
        height: 32,
        paddingTop: 12,
        marginRight: theme.utils.gap(1),
        alignItems: 'center',
        borderRadius: 4,
        borderWidth: 1,
        borderColor: theme.colors.silver
    },
    titleText: {
        flex: 1,
        alignItems: {
            lg: undefined,
            xs: 'center'
        },
        marginRight: 16
    }
}))
