import { AppState } from 'react-native'
import { useEffect, useState } from 'react'
import { AppStateStatus } from '../types'

export const useAppState = () => {
    const [appState, setAppState] = useState<AppStateStatus>(AppStateStatus.Active)
    const [willBeActive, setWillBeActive] = useState<boolean>(false)
    const [isInBackground, setIsInBackground] = useState<boolean>(false)

    useEffect(() => {
        const listener = AppState.addEventListener('change', (nextState: string) => {
            setIsInBackground(Boolean(appState.match(/inactive|background/)))
            setAppState(nextState as AppStateStatus)
            setWillBeActive(nextState === AppStateStatus.Active)
        })

        return () => listener.remove()
    }, [appState])

    return {
        appState,
        willBeActive,
        isInBackground
    }
}
