import { ScreenNames } from 'lib/routing'
import { useTranslations } from 'lib/hooks'

export const useTaskAndErrandsBreadcrumbs = () => {
    const T = useTranslations()

    return [
        {
            text: T.screens.taskAndErrands.breadCrumbs.requirements,
            screenName: ScreenNames.TaskAndErrands
        },
        {
            text: T.screens.taskAndErrands.breadCrumbs.confirmation,
            screenName: ScreenNames.TaskAndErrandsSummary
        }
    ]
}
