import React from 'react'
import Animated, { useAnimatedStyle } from 'react-native-reanimated'
import { View } from 'react-native'
import { useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { Grid, Typography } from 'lib/components'

type BookNowProps = {
    onPress: VoidFunction,
    onLearnMore: VoidFunction,
    animatedScale: Animated.SharedValue<number>,
    animatedOpacity: Animated.SharedValue<number>,
    testID?: string
}

export const BookNow: React.FunctionComponent<BookNowProps> = ({
    onPress,
    onLearnMore,
    animatedScale,
    animatedOpacity,
    testID
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const animatedOverlayStyles = useAnimatedStyle(() => ({
        opacity: animatedOpacity.value,
        transform: [
            {
                scale: animatedScale.value
            }
        ]
    }))

    return (
        <Animated.View
            style={[
                styles.overlay,
                animatedOverlayStyles
            ]}
        >
            <View style={styles.bookNow} testID={testID} accessibilityLabel={testID}>
                <Typography.Regular
                    bold
                    style={styles.bookNowText}
                    onPress={onPress}
                    forceColor={theme.colors.white}
                >
                    {T.screens.allServices.bookNow}
                </Typography.Regular>
            </View>
            <Grid.Gap
                gapTop={2}
                gapBottom={2}
            >
                <Typography.Regular forceColor={theme.colors.white}>
                    {T.common.or.toLowerCase()}
                </Typography.Regular>
            </Grid.Gap>
            <Typography.Regular
                bold
                onPress={onLearnMore}
                forceColor={theme.colors.white}
                style={styles.learnMore}
            >
                {T.screens.allServices.learnMore}
            </Typography.Regular>
        </Animated.View>
    )
}

const stylesheet = createStyles(theme => ({
    bookNow: {
        borderWidth: 2,
        borderColor: theme.colors.white,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 4
    },
    bookNowText: {
        paddingHorizontal: theme.utils.gap(4),
        paddingVertical: theme.utils.gap(3) / 2
    },
    learnMore: {
        textDecorationLine: 'underline'
    },
    overlay: {
        borderRadius: 8,
        backgroundColor: theme.colors.sun,
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        justifyContent: 'center',
        alignItems: 'center'
    }
}))
