import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const Alert: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path d="m40 12.442 31.6 54.98H8.4L40 12.443ZM1.139 63.197c-3.232 5.62.797 12.678 7.26 12.678h63.202c6.463 0 10.492-7.058 7.26-12.678l-31.6-54.981c-3.232-5.621-11.29-5.621-14.521 0L1.139 63.196Zm34.664-29.583v8.452c0 2.325 1.889 4.226 4.197 4.226s4.197-1.901 4.197-4.226v-8.452c0-2.324-1.889-4.226-4.197-4.226s-4.197 1.902-4.197 4.226Zm0 21.13h8.394v8.453h-8.394v-8.452Z" />
    </Icon>
)
