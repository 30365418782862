import { getConsumerActiveJobs, getConsumerCancelledJobs, getConsumerCompletedJobs } from '../actions'

export const useGetAllJobsData = () => {
    const { isLoading: isLoadingActive, data: activeResponse } = getConsumerActiveJobs()
    const { isLoading: isLoadingCancelled, data: cancelledResponse } = getConsumerCancelledJobs()
    const { isLoading: isLoadingCompleted, data: completeResponse } = getConsumerCompletedJobs()

    return {
        isLoading: isLoadingActive || isLoadingCancelled || isLoadingCompleted,
        allJobsData: [
            ...activeResponse?.data.job_list || [],
            ...cancelledResponse?.data.job_list || [],
            ...completeResponse?.data.job_list || []
        ],
        recurringJobsData: [
            ...activeResponse?.data.recurring_job_list || [],
            ...cancelledResponse?.data.recurring_job_list || [],
            ...completeResponse?.data.recurring_job_list || []
        ]
    }
}
