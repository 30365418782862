import { ScreenNames } from 'lib/routing'
import { useTranslations } from 'lib/hooks'

export const usePestControlBreadcrumbs = () => {
    const T = useTranslations()

    return [
        {
            text: T.screens.pestControl.breadCrumbs.requirements,
            screenName: ScreenNames.PestControl
        },
        {
            text: T.screens.pestControl.breadCrumbs.confirmation,
            screenName: ScreenNames.PestControlSummary
        }
    ]
}
