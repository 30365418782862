import React from 'react'
import { View } from 'react-native'
import { createStyles } from 'lib/styles'
import { Typography } from 'lib/components'
import { useStyles, useTranslations } from 'lib/hooks'

export const PestControlHelpModalContent: React.FunctionComponent = () => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)

    return (
        <View style={styles.container}>
            <View style={styles.title}>
                <Typography.Regular>
                    {T.components.pestControlHelp.title}
                </Typography.Regular>
            </View>
            <Typography.Regular forceColor={theme.colors.darkGrey}>
                {T.components.pestControlHelp.description}
            </Typography.Regular>
            <View style={styles.row}>
                <Typography.Regular bold>
                    {T.components.pestControlHelp.includedTitle}
                </Typography.Regular>
                <Typography.Regular forceColor={theme.colors.darkGrey}>
                    {T.components.pestControlHelp.includedMessage}
                </Typography.Regular>
            </View>
            <View style={styles.row}>
                <Typography.Regular bold>
                    {T.components.pestControlHelp.howDoesItWorkTitle}
                </Typography.Regular>
                <Typography.Regular forceColor={theme.colors.darkGrey}>
                    {T.components.pestControlHelp.howDoesItWorkMessage}
                </Typography.Regular>
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        maxWidth: 800
    },
    title: {
        borderBottomWidth: 1,
        borderColor: theme.colors.silver,
        paddingBottom: theme.utils.gap(2),
        marginBottom: theme.utils.gap(2)
    },
    row: {
        marginTop: theme.utils.gap(2)
    }
}))
