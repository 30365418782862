import { useField } from '@codegateinc/react-form-builder-v2'
import { formatWithMask } from 'react-native-mask-input'
import { dateHelpers, masks, regexes } from 'lib/utils'
import { useTranslations } from 'lib/hooks'
import { CreditCardFields } from './forms'

export const useNewCreditCardForm = () => {
    const T = useTranslations()

    const nameOnCard = useField<string>({
        key: CreditCardFields.NameOnCard,
        initialValue: '',
        isRequired: true,
        validateOnBlur: true,
        placeholder: T.screens.paymentAndCards.formFields.nameOnCard.placeholder,
        label: T.screens.paymentAndCards.formFields.nameOnCard.label,
        validationRules: [
            {
                errorMessage: T.screens.paymentAndCards.formFields.nameOnCard.validation.isRequired,
                validate: Boolean
            },
            {
                errorMessage: T.screens.paymentAndCards.formFields.nameOnCard.validation.minValue,
                validate: value => value.length >= 3
            },
            {
                errorMessage: T.screens.paymentAndCards.formFields.nameOnCard.validation.maxValue,
                validate: value => value.length <= 50
            }
        ]
    })

    const cardNumber = useField<string>({
        key: CreditCardFields.CardNumber,
        initialValue: '',
        isRequired: true,
        validateOnBlur: true,
        placeholder: T.screens.paymentAndCards.formFields.cardNumber.placeholder,
        label: T.screens.paymentAndCards.formFields.cardNumber.label,
        liveParser: value => {
            const { masked: maskedValue } = formatWithMask({
                text: value,
                mask: masks.creditCardMask
            })

            return maskedValue
        },
        validationRules: [
            {
                errorMessage: T.screens.paymentAndCards.formFields.cardNumber.validation.isRequired,
                validate: Boolean
            },
            {
                errorMessage: T.screens.paymentAndCards.formFields.cardNumber.validation.isInvalid,
                validate: value => value.split(' ').join('').length > 12
            }
        ]
    })

    const month = useField<string>({
        key: CreditCardFields.Month,
        initialValue: '',
        isRequired: true,
        validateOnBlur: true,
        placeholder: T.screens.paymentAndCards.formFields.month.placeholder,
        label: T.screens.paymentAndCards.formFields.month.label,
        liveParser: value => {
            const { masked: maskedValue } = formatWithMask({
                text: value,
                mask: masks.monthMask
            })

            return maskedValue
        },
        validationRules: [
            {
                errorMessage: T.screens.paymentAndCards.formFields.month.validation.isRequired,
                validate: Boolean
            },
            {
                errorMessage: T.screens.paymentAndCards.formFields.month.validation.isInvalid,
                validate: regexes.isValidMonthNumber
            }
        ]
    })

    const year = useField<string>({
        key: CreditCardFields.Year,
        initialValue: '',
        isRequired: true,
        validateOnBlur: true,
        placeholder: T.screens.paymentAndCards.formFields.year.placeholder,
        liveParser: value => {
            const { masked: maskedValue } = formatWithMask({
                text: value,
                mask: masks.yearMask
            })

            return maskedValue
        },
        validationRules: [
            {
                errorMessage: T.screens.paymentAndCards.formFields.year.validation.isRequired,
                validate: Boolean
            },
            {
                errorMessage: T.screens.paymentAndCards.formFields.year.validation.isInvalid,
                validate: ({ length }) => length === 4
            },
            {
                errorMessage: T.screens.paymentAndCards.formFields.year.validation.isExpired,
                validate: value => {
                    const date = `${value}-${month.value}-01`

                    return !dateHelpers.isDatePast(`${value}-${month.value}-01`) && !dateHelpers.getIsSameDay(date, new Date().toISOString())
                }
            }
        ]
    })

    const cvv = useField<string>({
        key: CreditCardFields.CVV,
        initialValue: '',
        isRequired: true,
        validateOnBlur: true,
        placeholder: T.screens.paymentAndCards.formFields.cvv.placeholder,
        label: T.screens.paymentAndCards.formFields.cvv.label,
        liveParser: value => {
            const { masked: maskedValue } = formatWithMask({
                text: value,
                mask: masks.cvvMask
            })

            return maskedValue
        },
        validationRules: [
            {
                errorMessage: T.screens.paymentAndCards.formFields.cvv.validation.isRequired,
                validate: Boolean
            },
            {
                errorMessage: T.screens.paymentAndCards.formFields.cvv.validation.isInvalid,
                validate: ({ length }) => length > 2 && length < 5
            }
        ]
    })

    return {
        nameOnCard,
        cardNumber,
        month,
        year,
        cvv
    }
}
