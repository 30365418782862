import React, { MutableRefObject, useState } from 'react'
import { View } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scrollview'
import { useSharedValue } from 'react-native-reanimated'
import { Field } from '@codegateinc/react-form-builder-v2'
import { Segment, segmentUtils } from 'lib/analytics'
import { createStyles } from 'lib/styles'
import { ACServiceDropdownOption, AirConServices, AirConditionService, AirConditionServiceOption, Nullable } from 'lib/types'
import { useStyles, useTranslations } from 'lib/hooks'
import { FormComponents, TextButton, Typography } from 'lib/components'
import { AirConditionPricingModal } from 'features/bookings'
import { AirConditionServiceDropdown } from './AirConditionServiceDropdown'

interface AirConditionServiceOptionsProps extends Field<Nullable<AirConditionServiceOption>> {
    options: Array<AirConditionServiceOption>,
    disabled?: boolean,
    scrollRef: MutableRefObject<KeyboardAwareScrollView>
}

export const AirConditionServiceOptions: React.FunctionComponent<AirConditionServiceOptionsProps> = ({
    value,
    options,
    label,
    errorMessage,
    onChangeValue,
    scrollRef
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const [priceModalOpen, steIsPriceModalOpen] = useState(false)
    const isDropdownVisible = useSharedValue<boolean>(false)

    const handleValueChange = (option: AirConditionServiceOption) => {
        isDropdownVisible.value = false

        if (option.options) {
            const [defaultOption] = option.options

            return onChangeValue({
                ...option,
                title: defaultOption.value.trim(),
                value: defaultOption.value,
                contractType: defaultOption.contractType
            })
        }

        return onChangeValue(option)
    }

    const handleSelectDropdownOption = (value: AirConditionServiceOption, option: ACServiceDropdownOption) => onChangeValue({
        ...value,
        title: option.value.trim(),
        value: option.value,
        contractType: option.contractType
    })

    return (
        <View>
            <AirConditionPricingModal
                isOpen={priceModalOpen}
                onClose={() => steIsPriceModalOpen(false)}
            />
            <Typography.Regular bold>
                {label}
            </Typography.Regular>
            <View style={styles.optionsContainer}>
                {options.map((option, index) => {
                    const isSelected = option.options
                        ? option.options.findIndex(item => item.value === value?.value) > -1
                        : option.value === value?.value

                    return (
                        <FormComponents.FrequencyOptionsItem
                            key={index}
                            index={index}
                            option={option}
                            isPrimary={false}
                            customSelect={option.options
                                ? (
                                    <AirConditionServiceDropdown
                                        scrollRef={scrollRef}
                                        isDropdownVisible={isDropdownVisible}
                                        onSelectOption={selectedOption => handleSelectDropdownOption(option, selectedOption)}
                                        isSelected={isSelected}
                                        options={option.options}
                                    />
                                )
                                : undefined
                            }
                            isSelected={isSelected}
                            isLast={index === options.length - 1}
                            onChangeValue={() => {
                                Segment.bookingServicePlanClicked({
                                    position: index + 1,
                                    servicePlan: segmentUtils.getServicePlan(option.title as AirConServices)
                                })

                                handleValueChange(option)
                            }}
                        />
                    )
                })}
            </View>
            {value?.title?.toLowerCase().includes(AirConditionService.Custom) && (
                <Typography.Error
                    style={styles.customMessage}
                    forceColor={theme.colors.mouse}
                >
                    {T.screens.airCondition.customMessage}
                </Typography.Error>
            )}
            <TextButton
                onPress={() => {
                    Segment.bookingPriceReferenceClicked({})

                    isDropdownVisible.value = false
                    steIsPriceModalOpen(true)
                }}
                text={T.common.priceReference}
            />
            <FormComponents.ErrorMessage text={errorMessage} />
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    optionsContainer: {
        zIndex: theme.zIndex[20],
        marginTop: theme.utils.gap(3),
        flexDirection: 'row'
    },
    description: {
        flex: 1,
        marginTop: theme.utils.gap(2),
        marginBottom: theme.utils.gap(3)
    },
    title: {
        alignItems: 'center'
    },
    option: {
        flex: 1,
        borderWidth: 1,
        paddingTop: theme.utils.gap(3),
        paddingBottom: theme.utils.gap(2),
        paddingHorizontal: {
            lg: theme.utils.gap(3) / 2,
            xs: theme.utils.gap(1) / 2
        },
        borderRadius: theme.components.input.borderRadius,
        marginBottom: {
            lg: undefined,
            xs: theme.utils.gap(1)
        },
        ...theme.utils.createShadow(2, 0, 8, 4, 4, theme.colors.webShadow(0.04))
    },
    selectContainer: {
        borderWidth: 1,
        alignItems: 'center',
        marginTop: theme.utils.gap(2),
        paddingVertical: theme.utils.gap(1),
        borderRadius: theme.components.button.borderRadius
    },
    listItem: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: theme.utils.gap(1) / 2
    },
    listText: {
        marginLeft: {
            lg: theme.utils.gap(1),
            xs: theme.utils.gap(1) / 2
        },
        paddingRight: theme.utils.gap(1)
    },
    customMessage: {
        marginTop: theme.utils.gap(3)
    }
}))
