import React from 'react'
import { View } from 'react-native'
import Animated, { runOnUI } from 'react-native-reanimated'
import { Job } from 'lib/models'
import { JobStatus } from 'lib/types'
import { createStyles } from 'lib/styles'
import { isNative } from 'lib/common'
import { Typography } from 'lib/components'
import { R, dateHelpers } from 'lib/utils'
import { useAccordion, useStyles, useTranslations } from 'lib/hooks'
import { JobName } from './JobName'
import { JobDetailsResponse } from '../types'
import { AdditionalInformation } from './AdditionalInformation'
import { ServiceLocation } from './ServiceLocation'
import { CancelRequest } from './CancelRequest'
import { DetailsSession } from './DetailsSession'
import { ViewDetails } from './ViewDetails'
import { RecurringSchedule } from './RecurringSchedule'
import { PausedJobStatus } from './PausedJobStatus'
import { CookingServiceDetails } from './CookingServiceDetails'
import { CookingServiceDate } from './CookingServiceDate'
import { PauseJob } from './PauseJob'
import { NextSessionTile } from './nextSessionTile'

type CookingRecurringDetailsProps = {
    response: JobDetailsResponse,
    allJobsData: Array<Job>
}

export const CookingRecurringDetails: React.FunctionComponent<CookingRecurringDetailsProps> = ({
    response,
    allJobsData
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const { setHeight, animatedHeightStyle, aref, isOpened } = useAccordion(theme.utils.gap(3))
    const relatedJobs = allJobsData
        .filter(item => response.job._id === item.recurring_job)
        .sort((firstDate, secondDate) => firstDate.date_time.localeCompare(secondDate.date_time))
    const allSessions = R.uniqBy((job: Job) => job.job_id, relatedJobs)
    const unfinishedSessions = allSessions.filter(item => item.status === JobStatus.Accepted)
    const futureSessions = unfinishedSessions.filter(session => !dateHelpers.isDatePast(session.date_time))
    const [nextSession] = futureSessions || []

    return (
        <View style={styles.contentWrapper}>
            <View style={styles.content}>
                <JobName
                    job={response}
                    isRecurring
                    recurringJob={response.job}
                    customStyles={styles.resetMargin}
                />
                <Typography.Label>
                    {`${T.components.proposals.expert}: ${response.job.supplier.handle}`}
                </Typography.Label>
                <PausedJobStatus job={response} />
                <View style={styles.viewDetails}>
                    <ViewDetails
                        isOpen={isOpened}
                        onPress={() => runOnUI(setHeight)()}
                    />
                </View>
                <Animated.View style={animatedHeightStyle}>
                    <View
                        ref={aref}
                        collapsable={false}
                        style={styles.accordionWrapper}
                    >
                        <RecurringSchedule schedule={response.job.schedule} />
                        <CookingServiceDate
                            isSubmitted
                            job={response}
                            withHours={false}
                        />
                        <View style={styles.pauseWrapper}>
                            <PauseJob job={response} />
                        </View>
                        <CookingServiceDetails job={response} />
                        <AdditionalInformation job={response} />
                        <ServiceLocation job={response.job} />
                        <CancelRequest
                            job={response}
                            text={T.screens.jobDetails.cancelPackage}
                            jobStatus={JobStatus.Accepted}
                        />
                    </View>
                </Animated.View>
            </View>
            {nextSession && (
                <NextSessionTile
                    nextSession={nextSession}
                    sessionIndex={allSessions.findIndex(item => item?._id === nextSession?._id) || 0}
                    allSessionsCount={allSessions.length}
                />
            )}
            {unfinishedSessions.length !== 0 && (
                <View style={styles.content}>
                    <Typography.Regular bold>
                        {T.common.upcoming}
                    </Typography.Regular>
                    {unfinishedSessions.map(session => (
                        <DetailsSession
                            key={session._id}
                            session={session}
                        />
                    ))}
                </View>
            )}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    accordionWrapper: {
        left: 0,
        right: 0,
        position: isNative
            ? 'absolute'
            : undefined
    },
    contentWrapper: {
        flex: 1
    },
    resetMargin: {
        marginBottom: theme.utils.gap(2)
    },
    viewDetails: {
        marginBottom: theme.utils.gap(3)
    },
    content: {
        overflow: 'hidden',
        borderRadius: 8,
        borderWidth: 1,
        paddingHorizontal: theme.utils.gap(2),
        paddingTop: theme.utils.gap(3),
        backgroundColor: theme.colors.white,
        borderColor: theme.colors.silver,
        backGroundColor: theme.colors.white,
        marginBottom: theme.utils.gap(4),
        ...theme.utils.createShadow(2, 0, 8, 4, 4, theme.colors.webShadow(0.04))
    },
    pauseWrapper: {
        marginTop: -theme.utils.gap(3),
        marginBottom: theme.utils.gap(2)
    }
}))
