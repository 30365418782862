import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const DeepCleaning: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path d="M74.419 77.61H52.861v-5.445h21.558a.136.136 0 0 0 .136-.136v-9.8a27.537 27.537 0 0 0-27.506-27.504 1.6 1.6 0 0 0-1.6 1.6v24.267h-5.441V36.32a7.049 7.049 0 0 1 7.041-7.041A32.989 32.989 0 0 1 80.001 62.23v9.8a5.588 5.588 0 0 1-5.582 5.58Z" />
        <Path d="M77.277 59.36a23 23 0 0 1-22.974-22.975v-4.169a2.723 2.723 0 1 1 5.446 0v4.169a17.548 17.548 0 0 0 17.528 17.529 2.723 2.723 0 0 1 0 5.446Z" />
        <Path d="M45.905 57.869a11.657 11.657 0 1 1-11.657 11.657 11.671 11.671 0 0 1 11.657-11.657Zm0 17.869a6.212 6.212 0 1 0-6.212-6.212 6.219 6.219 0 0 0 6.212 6.212Z" />
        <Path d="M59.897 31.163h-5.446v-4.425h-1.7v4.425h-5.448v-4.969a4.907 4.907 0 0 1 4.9-4.9h2.791a4.907 4.907 0 0 1 4.9 4.9Z" />
        <Path d="M24.507 67.84h-5.446v-9.189h-2.893v9.19h-5.446v-9.735a4.907 4.907 0 0 1 4.9-4.9h3.982a4.907 4.907 0 0 1 4.9 4.9Z" />
        <Path d="M4.9 65.016h25.426a4.907 4.907 0 0 1 4.9 4.9V73.9a4.907 4.907 0 0 1-4.9 4.9H4.9A4.907 4.907 0 0 1 0 73.9v-3.984a4.907 4.907 0 0 1 4.9-4.9Zm24.882 5.446H5.446v2.893h24.336Z" />
        <Path d="M17.424 59.053a2.723 2.723 0 0 1-2.723-2.723V20.89a20.89 20.89 0 1 1 41.78 0v3.276a2.723 2.723 0 0 1-5.446 0V20.89a15.444 15.444 0 1 0-30.888 0v35.441a2.723 2.723 0 0 1-2.723 2.722Z" />
    </Icon>
)
