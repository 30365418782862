import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import { v4 as uuidv4 } from 'uuid'
import { useDropzone } from 'react-dropzone'
import { Icons } from 'assets'
import { ENV } from 'lib/config'
import { Segment } from 'lib/analytics'
import { createStyles } from 'lib/styles'
import { NotificationType, PhotoFile } from 'lib/types'
import { useFilesAtom, useIsAuthorizedAtom, useToastAtom, useUserTokenAtom } from 'lib/atoms'
import { axiosWithoutInterceptors } from 'lib/api'
import { bookingsHooks } from 'features/bookings'
import { imageActions } from 'features/image'
import { useQueryHelpers, useStyles, useTranslations } from 'lib/hooks'
import { PhotoUploadProps, UploadedPhoto } from './types'
import { Label, Regular } from '../typography'
import { PhotoUploadUploadedFiles } from './PhotoUploadUploadedFiles'

export const PhotoUpload: React.FunctionComponent<PhotoUploadProps> = ({
    supplyId,
    onUploadStarts,
    onPhotoRemove,
    isLoading,
    onError,
    withLabel = true,
    onSuccess,
    highlightOnDrag,
    buttonStyle,
    disabled,
    shouldInitialize = true,
    renderButtonLabel
}) => {
    const T = useTranslations()
    const [addedFiles, setAddedFiles] = useState<Array<UploadedPhoto>>([])
    const [files, setFiles] = useFilesAtom()
    const [token] = useUserTokenAtom()
    const { onRequestError } = useQueryHelpers()
    const [, setToastMessage] = useToastAtom()
    const [isAuthorized] = useIsAuthorizedAtom()
    const getUploadKey = bookingsHooks.useGetUploadKey(supplyId)
    const { mutateAsync: getUploadURL } = imageActions.getS3ImageUploadURL()
    const { mutateAsync: getDownloadURL } = imageActions.getS3ImageDownloadURL()
    const { styles, theme } = useStyles(stylesheet)
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        disabled,
        multiple: true,
        maxSize: Number(ENV.MAX_IMAGE_UPLOAD_SIZE_MB) * 1024 * 1024,
        accept: {
            'image/*': []
        },
        onDropRejected: () => {
            setToastMessage({
                type: NotificationType.Error,
                message: T.components.photoUpload.maxFileSize
            })
        },
        onDropAccepted: files => {
            const filesWithUUID = files.map(file => ({
                file,
                fileName: file.name,
                fileUUID: uuidv4(),
                preview: URL.createObjectURL(file)
            }))

            setAddedFiles(prevState => [...prevState, ...filesWithUUID])

            if (!isAuthorized) {
                setFiles(prevState => [...prevState, ...filesWithUUID])

                return
            }

            uploadFiles(filesWithUUID)
        }
    })
    const uploadFiles = (files: Array<PhotoFile>) => {
        onUploadStarts()

        const requests = files.map((file, index) => new Promise<UploadedPhoto>((resolve, reject) => {
            const uploadKey = getUploadKey(index)

            getUploadURL({
                token,
                key: uploadKey,
                content_type: file.file.type
            })
                .then(data => {
                    axiosWithoutInterceptors.put(data.data.upload_url, file.file, {
                        headers: {
                            'Content-Type': file.file.type
                        }
                    })
                        .then(() => {
                            getDownloadURL({
                                token,
                                key: uploadKey
                            })
                                .then(data => {
                                    const [downloadURL] = data.data.download_url.split('?')

                                    return resolve({
                                        ...file,
                                        downloadUrl: downloadURL
                                    })
                                })
                                .catch(reject)
                        })
                        .catch(reject)

                })
                .catch(reject)
        }))

        Promise.all(requests)
            .then(response => {
                Segment.bookingImageUploaded({})

                onSuccess(response)
                setFiles([])
            })
            .catch(error => {
                onRequestError(error)
                onError()
            })
    }
    const onRemoveFile = (uuid: string) => {
        Segment.bookingUploadedImageRemoveClicked({})

        onPhotoRemove(uuid)
        setFiles(prevState => prevState.filter(prevFile => uuid !== prevFile.fileUUID))
        setAddedFiles(prevState => prevState.filter(prevFile => uuid !== prevFile.fileUUID))
    }

    useEffect(() => () => addedFiles.forEach(file => URL.revokeObjectURL(file.preview)), [])
    useEffect(() => {
        if (isAuthorized && files.length !== 0) {
            uploadFiles(files)
        }

        if (!isAuthorized && addedFiles.length !== 0) {
            setAddedFiles([])
        }
    }, [isAuthorized])

    useEffect(() => {
        if (files.length !== 0 && shouldInitialize) {
            setAddedFiles(files)
        }
    }, [])

    return (
        <View style={styles.wrapper}>
            {withLabel && (
                <Label
                    style={styles.label}
                    forceColor={theme.colors.darkGrey}
                >
                    {T.components.photoUpload.label}
                </Label>
            )}
            {buttonStyle
                ? (
                    <div {...getRootProps()}>
                        <View style={styles.row}>
                            {renderButtonLabel && (
                                <View style={styles.flex}>
                                    {renderButtonLabel()}
                                </View>
                            )}
                            <View>
                                <View style={styles.button}>
                                    <input {...getInputProps()} />
                                    <View
                                    // TODO Refactor when ready
                                        style={{
                                            ...styles.uploadIcon,
                                            transform: [
                                                {
                                                    rotateZ: '-90deg'
                                                }
                                            ]
                                        }}
                                    >
                                        <Icons.Logout
                                            size={12}
                                            forceColor={theme.colors.orange}
                                        />
                                    </View>
                                    <Regular forceColor={theme.colors.orange}>
                                        {T.common.upload}
                                    </Regular>
                                </View>
                            </View>
                        </View>
                    </div>
                )
                : (
                    <div {...getRootProps()}>
                        <View
                            style={{
                                ...styles.content,
                                ...((highlightOnDrag && isDragActive) ? styles.activeContent : {})
                            }}
                        >
                            <input {...getInputProps()} />
                            <Icons.Clip
                                size={38}
                                forceColor={theme.colors.orange}
                            />
                            <Regular style={styles.drag}>
                                {T.components.photoUpload.dragAndDrop}
                            </Regular>
                            <Label style={styles.browse}>
                                {T.components.photoUpload.browseFile}
                            </Label>
                        </View>
                    </div>
                )
            }
            {addedFiles.length > 0 && (
                <PhotoUploadUploadedFiles
                    isLoading={isLoading}
                    disabled={disabled}
                    addedFiles={addedFiles}
                    onRemoveFile={onRemoveFile}
                />
            )}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    wrapper:{
        marginBottom: theme.utils.gap(2)
    },
    content: {
        borderWidth: 1,
        borderRadius: 8,
        alignItems: 'center',
        borderStyle: 'dashed',
        borderColor: theme.colors.darkSilver,
        paddingVertical: theme.utils.gap(6)
    },
    activeContent: {
        borderWidth: 2,
        borderColor: theme.colors.orange,
        margin: -1 // compensate for extra borderWidth
    },
    label: {
        marginBottom: theme.utils.gap(1)
    },
    drag: {
        marginBottom: theme.utils.gap(1) / 2,
        marginTop: theme.utils.gap(2)
    },
    browse: {
        textDecorationLine: 'underline'
    },
    uploaded: {
        marginTop: theme.utils.gap(2)
    },
    filesContainer: {
        borderWidth: 1,
        borderRadius: 8,
        borderColor: theme.colors.darkSilver,
        paddingHorizontal: theme.utils.gap(2)
    },
    fileName: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center'
    },
    fileIcon: {
        marginRight: theme.utils.gap(1)
    },
    file: {
        paddingVertical: theme.utils.gap(2),
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    button: {
        flexDirection: 'row',
        alignItems: 'center',
        borderWidth: 2,
        borderRadius: 4,
        marginLeft: theme.utils.gap(2),
        paddingVertical: theme.utils.gap(1) / 2,
        paddingHorizontal: theme.utils.gap(1),
        borderColor: theme.colors.orange
    },
    uploadIcon: {
        marginRight: theme.utils.gap(1)
    },
    flex: {
        flex: 1
    },
    row: {
        flexDirection: 'row'
    }
}))
