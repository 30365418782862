export enum ProposalAction {
    Accept = 'accept_proposal',
    Decline = 'decline_proposal'
}

export enum ProposalStatus {
    Submitted = 0,
    Accepted = 1,
    Withdrawn = 2,
    Declined = 3,
}
