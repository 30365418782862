import React from 'react'
import { TextInput, TextInputProps, View } from 'react-native'
import { createStyles } from 'lib/styles'
import { useStyles } from 'lib/hooks'
import { useTextInput } from './hooks'

type ChatInputProps = {
    inputProps: TextInputProps,
    onDebounceEnd?(inputValue: string): void
}

export const ChatInput = React.forwardRef<TextInput, ChatInputProps>((props, ref) => {
    const { styles: extraStyles, theme } = useStyles(stylesheet)
    const {
        styles,
        inputProps
    } = useTextInput({
        ...props,
        inputProps: {
            ...props.inputProps,
            style: extraStyles.input
        }
    })

    return (
        <View style={styles.container}>
            <View style={styles.container}>
                <TextInput
                    ref={ref}
                    {...inputProps as TextInputProps}
                    placeholderTextColor={theme.colors.darkGrey}
                />
            </View>
        </View>
    )
})

const stylesheet = createStyles(theme => ({
    input: {
        height: 42,
        borderRadius: 40
    }
}))
