import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const Clock: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path fillRule="evenodd" clipRule="evenodd" d="M12.5 3.5C7.52944 3.5 3.5 7.52944 3.5 12.5C3.5 17.4706 7.52944 21.5 12.5 21.5C17.4706 21.5 21.5 17.4706 21.5 12.5C21.5 7.52944 17.4706 3.5 12.5 3.5ZM1.5 12.5C1.5 6.42487 6.42487 1.5 12.5 1.5C18.5751 1.5 23.5 6.42487 23.5 12.5C23.5 18.5751 18.5751 23.5 12.5 23.5C6.42487 23.5 1.5 18.5751 1.5 12.5Z"/>
        <Path fillRule="evenodd" clipRule="evenodd" d="M12.5 5.5C13.0523 5.5 13.5 5.94772 13.5 6.5V11.882L16.9472 13.6056C17.4412 13.8526 17.6414 14.4532 17.3944 14.9472C17.1474 15.4412 16.5468 15.6414 16.0528 15.3944L12.0528 13.3944C11.714 13.225 11.5 12.8788 11.5 12.5V6.5C11.5 5.94772 11.9477 5.5 12.5 5.5Z"/>
    </Icon>
)
