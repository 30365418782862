import React, { useEffect, useState } from 'react'
import { useForm } from '@codegateinc/react-form-builder-v2'
import { PaymentNewCardModal } from 'features/payments'
import { PaymentOption } from 'lib/models'
import { useSegmentSession, useTranslations, useSessionData } from 'lib/hooks'
import { BookingFrequency, SupplyID } from 'lib/types'
import { useAddressAtom, useDynamicServiceAtom, useResetBookingJobQuotationAtom, useUserTokenAtom } from 'lib/atoms'
import { NavigationParams, NavigationProps, ScreenNames } from 'lib/routing'
import { Segment, ServiceType } from 'lib/analytics'
import { BookingPaymentView, CookingSummaryView } from '../components'
import { ServiceAddonsFormShape, useServiceAddonsForm } from '../forms'
import { useBookingPromoCode, useBookingScheduleDates, useCookingAdditionalInformation, useCookingCreateJob, useJobQuotation } from '../hooks'
import { CreateCookingJobRequest } from '../types'

type CookingSummaryScreenProps = {
    navigation: NavigationProps<ScreenNames.CookingSummary>,
    route: NavigationParams<ScreenNames.CookingSummary>
}

export const CookingSummaryScreen: React.FunctionComponent<CookingSummaryScreenProps> = ({ route }) => {
    const T = useTranslations()
    const [address] = useAddressAtom()
    const [token] = useUserTokenAtom()
    const  { segmentSession } = useSegmentSession()
    const [dynamicConfig] = useDynamicServiceAtom(route.params.supplyId)
    const [, setResetJobQuotationTypeAtom] = useResetBookingJobQuotationAtom()
    const supplyId = dynamicConfig._id
    const [paymentView, setPaymentView] = useState(false)
    const oneTimePricing = route.params.form.frequency.value === BookingFrequency.OneTime
    const recurringDates = useBookingScheduleDates(route.params.form.frequency.value, Object.values(route.params.form.bookingDays))
    const isRecurring = route.params.form.frequency.value !== BookingFrequency.OneTime
    const { jobQuotation, requestJobQuotation } = useJobQuotation(SupplyID.Cooking)
    const [originalPrice, setOriginalPrice] = useState(0)
    const [totalPrice, setTotalPrice] = useState(0)
    const [recurringStartDate] = recurringDates
    const sessionData = useSessionData()
    const formReturn = useForm<ServiceAddonsFormShape>(useServiceAddonsForm(), {
        onSuccess: ({ pets, comment }) => {
            setResetJobQuotationTypeAtom()
            const recurringJobData = isRecurring
                ? Object.keys(route.params.form.bookingDays)
                    .map(dayKey => ({
                        activated: Boolean(route.params.form.bookingDays[dayKey]),
                        // They use day name in format like 'mon', 'tue' etc. They have it hardcoded in mobile app. I do not know if this support localization
                        day: dayKey.slice(0, 3).toLocaleLowerCase(),
                        fortnightly: route.params.form.frequency.value === BookingFrequency.Fortnightly,
                        start_time: route.params.form.bookingDays[dayKey] ?? recurringStartDate
                    }))
                : undefined

            const createJobPayload: CreateCookingJobRequest = {
                token,
                ...sessionData,
                bookingSessionId: segmentSession.id,
                booking: {
                    messageToSupplier: `${additionalInformation}\n${comment}`,
                    date: isRecurring
                        ? recurringStartDate
                        : route.params.form.bookingTime,
                    location: addressCreated ?? address,
                    numberOfHours: parseFloat(route.params.form.hours),
                    frequency: isRecurring ? 'recurring' : BookingFrequency.OneTime.toLowerCase(),
                    paymentType: paymentMethod,
                    buildingType: route.params.form.propertyType,
                    petOnPresmises:  Boolean(pets),
                    cuisines: route.params.form.cuisines
                }
            }

            if (isRecurring) {
                createJobPayload.booking.schedule = recurringJobData
            }

            if (promotionPackage?.promotion.promo_auth) {
                createJobPayload.promo = {
                    auth: promotionPackage?.promotion.promo_auth,
                    code: promotionPackage?.promotion.promo_code
                }
            }

            onCreateJob(
                route.params.supplyId,
                createJobPayload,
                () => {
                    resetForm()
                    removePromoCode()
                }
            )
        }
    })
    const { form, submit, resetForm } = formReturn
    const additionalInformation = useCookingAdditionalInformation(route.params.form, formReturn)
    const { isLoading: isLoadingPromotion, promotionPackage, requestPromotion, removePromoCode } = useBookingPromoCode()
    const {
        jobUniqueId,
        onCreateJob,
        isLoading: isCreatingJob,
        addressCreated,
        paymentMethod,
        isDisabled,
        handleSubmit,
        isLoadingAddressForm,
        setPaymentMethod,
        fetchPostalCodes,
        addressForm,
        isNewCardModalOpen,
        setIsNewCardModalOpen
    } = useCookingCreateJob(
        submit,
        ScreenNames.CookingDetails,
        route.params.postalcode,
        totalPrice)

    const isLoading = isLoadingPromotion || isCreatingJob
    const onRequestPromotion = (code: string) => {
        requestPromotion(code, {
            jobUniqueId,
            photos: [],
            petOnPresmises: Boolean(form.pets.value),
            ironing: Boolean(form.ironing.value),
            isAllImageUploaded: true,
            message_to_supplier: `${additionalInformation}\n${form.comment.value}`,
            linkUploads: {},
            targetPromoCode: promotionPackage?.promotion.promo_code || null,
            checkingPayment: false,
            date_time: isRecurring
                ? recurringStartDate
                : route.params.form.bookingTime,
            location: addressCreated ?? address,
            is_purchasing_services: false,
            supply: supplyId,
            is_recurring: isRecurring,
            paymentType: paymentMethod,
            paymentOptions: [PaymentOption.Card, PaymentOption.SendPay],
            num_hours: parseFloat(route.params.form.hours),
            building_type: route.params.form.propertyType,
            cuisines: route.params.form.cuisines
        })
    }

    useEffect(() => {
        const { form: { hours } } = route.params
        const numberOfHours = parseFloat(hours) + Number(form.extraHours.value)

        const promoPayload = {
            code: promotionPackage?.promotion?.promo_code,
            auth: promotionPackage?.promotion?.promo_auth
        }

        const data = {
            frequency: isRecurring ? 'recurring' : 'onetime',
            numberOfHours,
            ...(promoPayload.code && { promo: promoPayload })
        }

        requestJobQuotation(data)

    }, [route.params.form.frequency, route.params.form.hours, form.extraHours.value, promotionPackage?.promotion.promo_code])

    useEffect(() => {
        if (segmentSession.id) {
            Segment.bookingSummary({
                bookingSessionId: segmentSession.id,
                postcode: route.params?.postalcode as string,
                amount: route.params?.price as number,
                serviceType: ServiceType.Cooking
            })
        }
    }, [segmentSession])

    useEffect(() => {
        if(jobQuotation) {
            setTotalPrice(jobQuotation?.total?.value)
            setOriginalPrice(jobQuotation?.total?.valueBeforeDiscount ?? 0)
        }
    }, [jobQuotation])

    if (paymentView) {
        return (
            <BookingPaymentView
                disabled={isDisabled}
                submit={handleSubmit}
                onRemovePromoCode={removePromoCode}
                isRecurring={isRecurring}
                isLoading={isLoading || isLoadingAddressForm}
                totalPrice={totalPrice}
                promotionPackage={promotionPackage}
                setPaymentView={setPaymentView}
                service={T.screens.cooking.title}
                setPaymentMethod={setPaymentMethod}
                selectedPaymentMethod={paymentMethod}
                discount={promotionPackage?.promotion.percent || promotionPackage?.promotion.package.credit}
                promotionType={promotionPackage?.promotion.promo_type}
                requestPromotion={onRequestPromotion}
                promoCode={form.promoCode}
            />
        )
    }

    return (
        <React.Fragment>
            <PaymentNewCardModal
                isOpen={isNewCardModalOpen}
                onClose={() => setIsNewCardModalOpen(false)}
            />
            <CookingSummaryView
                submit={handleSubmit}
                disabled={isDisabled}
                isRecurring={isRecurring}
                setPaymentMethod={setPaymentMethod}
                isOneTimePricing={oneTimePricing}
                additionalInformation={additionalInformation}
                onRemovePromoCode={removePromoCode}
                fetchPostalCodes={fetchPostalCodes}
                isLoading={isLoading || isLoadingAddressForm}
                totalPrice={totalPrice}
                setPaymentView={setPaymentView}
                originalPrice={originalPrice}
                promotionPackage={promotionPackage}
                requestPromotion={onRequestPromotion}
                selectedPaymentMethod={paymentMethod}
                formShape={route.params.form}
                addressForm={addressForm}
                formReturn={formReturn}
                jobQuotation={jobQuotation}
            />
        </React.Fragment>
    )
}
