import { View } from 'react-native'
import React, { useState } from 'react'
import { Field } from '@codegateinc/react-form-builder-v2'
import { R } from 'lib/utils'
import { Nullable } from 'lib/types'
import { useUserAtom } from 'lib/atoms'
import { Segment, segmentUtils } from 'lib/analytics'
import { isNative, isWeb } from 'lib/common'
import { Breakpoint, createStyles } from 'lib/styles'
import { PaymentType, PromotionType } from 'lib/models'
import { useAddNewCreditCard } from 'features/payments'
import { useBackHandler, useIsWithinBreakpoints, useStyles, useTranslations } from 'lib/hooks'
import { Button, CreditCard, PolicyLinks, SendPay, Typography } from 'lib/components'
import { ServiceLayout } from './ServiceLayout'
import { PaymentSummary } from './PaymentSummary'
import { BookingCancellationFees } from './BookingCancellationFees'
import { PromotionCodeInput } from './PromotionCodeInput'
import { GetPromotionResponse } from '../types'

type BookingPaymentViewProps = {
    isRecurring?: boolean,
    isLoading: boolean,
    disabled: boolean,
    totalPrice: number,
    submit: VoidFunction,
    discount?: number,
    service: string,
    promotionType?: PromotionType,
    promotionPackage: Nullable<GetPromotionResponse>
    selectedPaymentMethod: PaymentType,
    setPaymentMethod: (value: PaymentType) => void,
    setPaymentView: (value: boolean) => void,
    requestPromotion: (code: string) => void,
    onRemovePromoCode: VoidFunction,
    showBillingMessage?: boolean,
    perHour?: boolean,
    promoCode: Field<string>
}

export const BookingPaymentView: React.FunctionComponent<BookingPaymentViewProps> = ({
    submit,
    promoCode,
    disabled,
    requestPromotion,
    isRecurring = false,
    isLoading,
    totalPrice,
    service,
    discount,
    promotionPackage,
    promotionType,
    setPaymentView,
    setPaymentMethod,
    selectedPaymentMethod,
    onRemovePromoCode,
    showBillingMessage,
    perHour
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const [user] = useUserAtom()
    const [defaultCard] = user.payment_providers.filter(item => item.default)
    const [isNewCardModalOpen, setIsNewCardModalOpen] = useState(false)
    const isMobile = useIsWithinBreakpoints(Breakpoint.XS, Breakpoint.MD)
    const notEnoughCredits = selectedPaymentMethod === PaymentType.Credits && user.consumer.balance_credit < totalPrice
    const { mobileSubmit, isLoading: isLoadingNewCard } = useAddNewCreditCard(R.T)
    const onSubmit = () => {
        if (selectedPaymentMethod === PaymentType.Card && !defaultCard) {
            return setIsNewCardModalOpen(true)
        }

        submit()
    }
    const handleSelectPaymentMethod = (value: PaymentType) => {
        Segment.bookingPaymentSwitched({
            selectedPaymentMethod: segmentUtils.getSelectedPaymentMethod(value)
        })

        setPaymentMethod(value)
    }

    useBackHandler(() => {
        setPaymentView(false)

        return true
    })

    return (
        <ServiceLayout
            backButtonTitle={T.screens.payment.title}
            onBackButtonPress={() => setPaymentView(false)}
            bottomSpacerHeight={
                isMobile
                    ? 0
                    : 50
            }
            contentColumn={(
                <View style={styles.content}>
                    <Typography.Subheading>
                        {T.screens.payment.selectPayment}
                    </Typography.Subheading>
                    <View style={styles.description}>
                        <Typography.Regular>
                            {T.screens.payment.paymentDescription}
                        </Typography.Regular>
                    </View>
                    <CreditCard
                        onSelect={handleSelectPaymentMethod}
                        isModalOpen={isNewCardModalOpen}
                        setIsModalOpen={value => {
                            if (!isNative) {
                                return setIsNewCardModalOpen(value)
                            }

                            mobileSubmit()
                        }}
                        selectedPaymentMethod={selectedPaymentMethod}
                    />
                    {!isRecurring && (
                        <SendPay
                            onSelect={handleSelectPaymentMethod}
                            notEnoughCredits={notEnoughCredits}
                            selectedPaymentMethod={selectedPaymentMethod}
                        />
                    )}
                </View>
            )}
            summaryColumn={(
                <View>
                    <PaymentSummary
                        promotionPackage={promotionPackage}
                        perHour={perHour}
                        promotionType={promotionType}
                        totalPrice={totalPrice}
                        service={service}
                        discount={discount}
                        couponText={promotionPackage
                            ? promotionPackage.promotion.message_to_client
                            : undefined
                        }
                        renderExtraContent={() => isWeb && !isMobile ? (
                            <PromotionCodeInput
                                showBillingMessage={showBillingMessage}
                                promoCode={promoCode}
                                isLoading={isLoading || isLoadingNewCard}
                                onRemovePromoCode={onRemovePromoCode}
                                promotionPackage={promotionPackage}
                                requestPromotion={requestPromotion}
                            />
                        ) : null}
                    />
                    <PolicyLinks />
                    <Button
                        onPress={onSubmit}
                        disabled={notEnoughCredits || disabled}
                        isLoading={isLoading || isLoadingNewCard}
                        text={T.common.confirm}
                    />
                    <BookingCancellationFees />
                </View>
            )}
        />
    )
}

const stylesheet = createStyles(theme => ({
    content: {
        marginBottom: theme.utils.gap(3)
    },
    addonsRow: {
        flexDirection: {
            lg: 'row',
            xs: 'column'
        }
    },
    spacer: {
        width: theme.utils.gap(2)
    },
    selectWrapper: {
        zIndex: theme.zIndex[10]
    },
    description: {
        marginVertical: theme.utils.gap(2)
    }
}))
