import React, { useEffect, useState } from 'react'
import { View, Modal as NativeModal, StyleSheet } from 'react-native'
import { Icons } from 'assets'
import { createStyles } from 'lib/styles'
import { ScrollDirection } from 'lib/types'
import { useStyles } from 'lib/hooks'
import { Toast, Touchable, Typography } from 'lib/components'
import { Photo } from './Photo'

type PhotoGalleryProps = {
    isVisible: boolean,
    photos: Array<string>,
    onClose: VoidFunction,
    initialIndex?: number,
    isPreview?: boolean
}

export const PhotoGallery: React.FunctionComponent<PhotoGalleryProps> = ({
    photos,
    isVisible,
    onClose,
    isPreview,
    initialIndex = 0
}) => {
    const { styles, theme } = useStyles(stylesheet)
    const [photoIndex, setPhotoIndex] = useState(0)
    const withPagination = photos?.length > 1

    useEffect(() => {
        setPhotoIndex(initialIndex)
    }, [initialIndex])

    const handleArrowPress = (direction: ScrollDirection) => {
        if (direction === ScrollDirection.Prev && photoIndex === 0 ||
            direction === ScrollDirection.Next && photoIndex === photos?.length - 1) {
            return
        }

        if (direction === ScrollDirection.Prev) {
            return setPhotoIndex(prevState => prevState - 1)
        }

        setPhotoIndex(prevState => prevState + 1)
    }

    if (photos?.length === 0 || !isVisible) {
        return null
    }

    return (
        <View>
            <NativeModal
                transparent
                animationType="fade"
                visible={isVisible}
                statusBarTranslucent
                onRequestClose={() => onClose()}
            >
                <View style={styles.contentWrapper}>
                    <Touchable
                        onPress={() => onClose()}
                        style={styles.backdrop}
                    />
                    <Touchable
                        style={styles.close}
                        onPress={() => onClose()}
                    >
                        <Icons.Close
                            size={18}
                            forceColor={theme.colors.white}
                        />
                    </Touchable>
                    <View style={styles.flex}>
                        {withPagination && (
                            <View
                                style={{
                                    ...styles.leftArrow,
                                    transform: [
                                        {
                                            rotate: '90deg'
                                        }
                                    ]
                                }}
                            >
                                <Touchable onPress={() => handleArrowPress(ScrollDirection.Prev)}>
                                    <Icons.Chevron
                                        size={18}
                                        forceColor={theme.colors.white}
                                    />
                                </Touchable>
                            </View>
                        )}
                        <Photo
                            isPreview={isPreview}
                            downloadKey={photos[photoIndex]}
                        />
                        {withPagination && (
                            <View
                                style={{
                                    ...styles.rightArrow,
                                    transform: [
                                        {
                                            rotate: '-90deg'
                                        }
                                    ]
                                }}
                            >
                                <Touchable onPress={() => handleArrowPress(ScrollDirection.Next)}>
                                    <Icons.Chevron
                                        size={18}
                                        forceColor={theme.colors.white}
                                    />
                                </Touchable>
                            </View>
                        )}
                        <View style={styles.paginationNumber}>
                            <Typography.Label forceColor={theme.colors.white}>
                                {`${photoIndex + 1} / ${photos?.length}`}
                            </Typography.Label>
                        </View>
                    </View>
                </View>
                <Toast />
            </NativeModal>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    flex: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
    },
    backdrop: {
        ...StyleSheet.absoluteFillObject
    },
    close: {
        zIndex: theme.zIndex[10],
        right: {
            lg: theme.utils.gap(5),
            xs: theme.utils.gap(2)
        },
        top: theme.utils.gap(5),
        position: 'absolute'
    },
    photoContainer: {
        flexDirection: 'row'
    },
    paginationNumber: {
        position: 'absolute',
        bottom: theme.utils.gap(5),
        alignItems: 'center'
    },
    leftArrow: {
        position: 'absolute',
        zIndex: theme.zIndex[10],
        left: {
            lg: -theme.utils.gap(5),
            xs: -theme.utils.gap(2)
        }
    },
    rightArrow: {
        position: 'absolute',
        zIndex: theme.zIndex[10],
        right: {
            lg: -theme.utils.gap(5),
            xs: -theme.utils.gap(2)
        }
    },
    contentWrapper: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.components.modal.backdrop.backgroundColor
    }
}))
