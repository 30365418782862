import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const Handyman: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path d="M12.414 78.109A10.061 10.061 0 0 1 5.3 60.934l9.761-9.761a10.062 10.062 0 1 1 14.228 14.231l-9.761 9.761a9.995 9.995 0 0 1-7.114 2.944Zm9.761-24.945a5.09 5.09 0 0 0-3.623 1.5l-9.76 9.761a5.124 5.124 0 1 0 7.248 7.246l9.761-9.761a5.123 5.123 0 0 0-3.622-8.745Z" />
        <Path d="M63.127 25.797a7.991 7.991 0 0 1-5.65-13.638l6.832-6.832a7.991 7.991 0 1 1 11.3 11.3l-6.832 6.832a7.938 7.938 0 0 1-5.65 2.338ZM69.96 7.922a3.033 3.033 0 0 0-2.159.894l-6.832 6.832a3.053 3.053 0 1 0 4.317 4.317l6.832-6.833a3.052 3.052 0 0 0-2.158-5.211Z" />
        <Path d="M53.442 48.211 32.108 26.878l3.492-3.492 21.333 21.335Z" />
        <Path d="M45.052 37.985a2.469 2.469 0 0 1-1.75-4.215l13.8-13.8a2.47 2.47 0 1 1 3.5 3.488l-13.8 13.8a2.462 2.462 0 0 1-1.75.727Z" />
        <Path d="M37.582 65.473a2.461 2.461 0 0 1-1.746-.723L15.821 44.734a2.47 2.47 0 1 1 3.492-3.492l20.015 20.016a2.469 2.469 0 0 1-1.746 4.215Z" />
        <Path d="M61.478 42.957a18.417 18.417 0 0 1 13.107 5.43 18.63 18.63 0 0 1 3.15 22.026l-1.584 2.882-8.766-8.762-2.87 2.871 8.762 8.762-2.881 1.586a18.54 18.54 0 1 1-8.918-34.8Zm13.185 21.869a13.686 13.686 0 0 0-3.569-12.947 13.6 13.6 0 1 0-9.616 23.216 13.666 13.666 0 0 0 3.332-.413l-7.278-7.278 9.853-9.855Z" />
        <Path d="M18.521 0A18.538 18.538 0 0 1 31.63 31.647a18.538 18.538 0 0 1-26.217 0A18.63 18.63 0 0 1 2.265 9.619l1.583-2.883 8.768 8.765 2.871-2.871-8.764-8.762 2.881-1.587A18.575 18.575 0 0 1 18.521 0Zm0 32.138a13.6 13.6 0 1 0 0-27.2 13.651 13.651 0 0 0-3.332.413l7.278 7.278-9.851 9.856-7.28-7.277a13.686 13.686 0 0 0 3.569 12.947 13.511 13.511 0 0 0 9.617 3.983Z" />
        <Path d="M45.645 55.953 24.311 34.62l3.492-3.492 21.333 21.335Z" />
        <Path d="m24.918 52.085 8.1-8.3a2.47 2.47 0 1 1 3.492 3.492l-8.1 8.3c-.482.479-4.456-2.528-3.492-3.492Z" />
    </Icon>
)
