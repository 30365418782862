import React from 'react'
import { View } from 'react-native'
import { createStyles } from 'lib/styles'
import { Typography } from 'lib/components'
import { useStyles, useTranslations } from 'lib/hooks'

export const ReferFriendHeaderMobile: React.FunctionComponent = () => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)

    return (
        <View style={styles.row}>
            <Typography.Heading style={styles.textCenter}>
                {T.screens.referFriend.messagePartOne}
                <Typography.Heading forceColor={theme.colors.orange}>
                    {T.screens.referFriend.messagePartTwo}
                </Typography.Heading>
            </Typography.Heading>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    row: {
        width: 250,
        marginTop: theme.utils.gap(2),
        marginBottom: theme.utils.gap(1)
    },
    textCenter: {
        textAlign: 'center',
        lineHeight: theme.utils.gap(3)
    }
}))
