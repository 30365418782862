import { useTranslations } from 'lib/hooks'
import { useHandymanServicesTypes } from './useHandymanServicesTypes'

export const useHandymanFrequentlyServiceItems = () => {
    const T = useTranslations()
    const serviceTypes = useHandymanServicesTypes()

    return [
        {
            category: T.components.handymanServices.general.title,
            item: T.components.handymanServices.general.items.door,
            amount: 1,
            comment: null,
            othersComment: null,
            part: [],
            type: serviceTypes[T.components.handymanServices.general.items.door].at(0) || null
        },
        {
            category: T.components.handymanServices.plumbing.title,
            item: T.components.handymanServices.plumbing.items.sink,
            amount: 1,
            comment: null,
            othersComment: null,
            part: [],
            type: serviceTypes[T.components.handymanServices.plumbing.items.sink].at(0) || null
        },
        {
            category: T.components.handymanServices.plumbing.title,
            item: T.components.handymanServices.plumbing.items.waterTap,
            amount: 1,
            comment: null,
            othersComment: null,
            part: [],
            type: serviceTypes[T.components.handymanServices.plumbing.items.waterTap].at(0) || null
        },
        {
            category: T.components.handymanServices.electrical.title,
            item: T.components.handymanServices.electrical.items.light,
            amount: 1,
            comment: null,
            othersComment: null,
            part: [],
            type: serviceTypes[T.components.handymanServices.electrical.items.light].at(0) || null
        },
        {
            category: T.components.handymanServices.general.title,
            item: T.components.handymanServices.general.items.tiles,
            amount: 1,
            comment: null,
            othersComment: null,
            part: [],
            type: serviceTypes[T.components.handymanServices.general.items.tiles].at(0) || null
        },
        {
            category: T.components.handymanServices.electrical.title,
            item: T.components.handymanServices.electrical.items.powerPoint,
            amount: 1,
            comment: null,
            othersComment: null,
            part: [],
            type: serviceTypes[T.components.handymanServices.electrical.items.powerPoint].at(0) || null
        }
    ]
}
