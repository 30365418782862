import React from 'react'
import { View } from 'react-native'
import Animated, { useAnimatedStyle, withTiming } from 'react-native-reanimated'
import { Field, useForm } from '@codegateinc/react-form-builder-v2'
import { useBackHandler, useStyles, useTranslations } from 'lib/hooks'
import { ListBottomSpacer, Separator, Button } from 'lib/components'

import { VoidFunction } from 'lib/types'
import { isWeb } from 'lib/common'
import { OTPVerifyCode } from 'lib/firebase'
import { ProviderDetails } from 'lib/models'
import { Segment } from 'lib/analytics'
import { SignUpFormShape, useSignUpForm } from '../forms'
import { AuthUser } from '../types'
import { Recaptcha } from './Recaptcha'
import { SocialButtons } from './SocialButtons'
import { useSignUp, useSignIn } from '../hooks'
import { SignUpForm } from './SignUpForm'
import { TermsAndPrivacy } from './TermsAndPrivacy'

import { Icons } from 'assets'
import { createStyles } from 'lib/styles'

type SignUpTabProps = {
    index: number,
    activeIndex: Animated.SharedValue<number>
    tabWidth: Animated.SharedValue<number>,
    showGuestBooking?: boolean,
    onFocus: VoidFunction,
    setShowGuestBookingForm?: (value: boolean) => void,
    onAccountConfirmed(authUser: AuthUser, verifyCode: OTPVerifyCode): void
}

export const SignUpTab: React.FunctionComponent<SignUpTabProps> = ({
    index,
    tabWidth,
    onFocus,
    activeIndex,
    onAccountConfirmed,
    showGuestBooking,
    setShowGuestBookingForm
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const { isLoading, verifyUserEmail, signUpWithApple } = useSignUp()
    const { signInWithFacebook, signInWithGoogle, isLoading: isSignInLoading } = useSignIn()
    const animatedContainerStyles = useAnimatedStyle(() => ({
        display: index === activeIndex.value ? 'flex' : 'none',
        width: tabWidth.value,
        padding: 1,
        opacity: withTiming(index === activeIndex.value ? 1 : 0)
    }))
    const { form, submit, isFilled, setError, hasError } = useForm<SignUpFormShape>(useSignUpForm(ProviderDetails.Email), {
        onSuccess: form => verifyUserEmail(
            form,
            onAccountConfirmed,
            setError
        )
    })

    useBackHandler(() => isLoading || isSignInLoading)

    const handleGuestBooking = () => {
        Segment.continueAsGuestClicked({})
        setShowGuestBookingForm && setShowGuestBookingForm(true)
    }

    return (
        <Animated.View style={animatedContainerStyles}>
            <SocialButtons
                disabled={isLoading || isSignInLoading}
                onContinueWithFacebook={signInWithFacebook}
                onContinueWithApple={signUpWithApple}
                onContinueWithGoogle={signInWithGoogle}
            />
            {showGuestBooking && (
                <View style={styles.submit}>
                    <Button
                        onPress={handleGuestBooking}
                        text={T.screens.auth.continueAsGuest}
                        renderIcon={color => (
                            <Icons.Arrow
                                size={14}
                                forceColor={color}
                            />
                        )}
                        testID="guest-booking-button"
                    />
                </View>
            )}
            <Separator text={T.screens.auth.loginSeparator} />
            <SignUpForm
                providerDetails={ProviderDetails.Email}
                form={form as Record<keyof SignUpFormShape, Field<string & boolean>>}
                isLoading={isLoading || isSignInLoading}
                onSubmit={submit}
                formHasError={!isFilled || hasError}
                onFocus={() => {
                    if (isWeb && index !== activeIndex.value) {
                        onFocus()
                    }
                }}
            />
            <ListBottomSpacer />
            <Recaptcha />
            <TermsAndPrivacy />
        </Animated.View>
    )
}

const stylesheet = createStyles(theme => ({
    submit: {
        alignItems: 'center'
    },
    forgotPasswordContainer: {
        alignItems: 'flex-end',
        marginBottom: theme.utils.gap(3)
    },
    forgotButton: {
        borderBottomWidth: 1
    },
    resetPasswordMessage: {
        marginVertical: theme.utils.gap(3)
    },
    resetPasswordMessageText: {
        textAlign: 'center'
    }
}))
