import React, { useState } from 'react'
import { View } from 'react-native'
import { Field } from '@codegateinc/react-form-builder-v2'
import { Icons } from 'assets'
import { Touchable, Typography } from 'lib/components'
import { useStyles, useTranslations } from 'lib/hooks'
import { createStyles, theme } from 'lib/styles'
import { isNative } from 'lib/common'
import { FrequencyOption, Nullable } from 'lib/types'
import { BookingDateCalendarPicker } from './BookingDateCalendarPicker'

type BookingDateCalendarPickerLiteProps = {
    displayDate: string,
    minHoursNotice: number,
    disabled: boolean,
    description: string,
    continueDisabled: boolean,
    frequency: FrequencyOption,
    continueLabel: string,
    selectedDays: Record<string, Nullable<string>>,
    startingDateField: Field<string>,
    allowToggle?: boolean,
    maxDate?: string,
    isLoading?: boolean,
    isMultiSelect?: boolean,
    onContinue: VoidFunction,
    onChangeSelectedDays(days: Record<string, Nullable<string>>): void
}

export const BookingDateCalendarPickerLite = ({ displayDate, ...rest }: BookingDateCalendarPickerLiteProps) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const [isShowBookingCalendar, setIsShowBookingCalendar] = useState(false)

    const onClickDateSelected = () => {
        setIsShowBookingCalendar(false)
    }

    return (
        <View style={styles.datePickerContainer}>
            <Touchable
                style={{
                    ...styles.datePickerToggleButton,
                    ...(isShowBookingCalendar ? styles.toggleButtonBorderWithCalendar : styles.toggleButtonBorder)
                }}
                onPress={() => setIsShowBookingCalendar(!isShowBookingCalendar)}
                testID={T.accessibility.bookingDetailsScreen.reschedule.date}
            >
                <View style={styles.datePickerToggleButtonContainer}>
                    <Icons.DateAndTime
                        size={20}
                        forceColor={theme.colors.black}
                    />
                    <Typography.Regular style={styles.datetimeText}>
                        {displayDate}
                    </Typography.Regular>
                </View>
                <View style={isShowBookingCalendar ? styles.chevronUp : styles.chevronDown}>
                    <Icons.Chevron
                        size={10}
                        forceColor={theme.colors.black}
                    />
                </View>
            </Touchable>
            {
                isShowBookingCalendar && (
                    <View style={styles.bookingCalendarContainer}>
                        <BookingDateCalendarPicker
                            liteMode
                            fullScreen={false}
                            isOneTime
                            hideContinueButton
                            onClickDateSelected={onClickDateSelected}
                            {...rest}
                        />
                    </View>
                )
            }
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    bookingCalendarContainer: {
        position: 'absolute',
        top: '100%',
        left: 0,
        width: '100%',
        backgroundColor: theme.colors.white,
        borderRadius: 8,
        zIndex: theme.zIndex[10]
    },
    datePickerToggleButton: {
        marginTop: theme.utils.gap(1),
        backgroundColor: theme.colors.white,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingLeft: theme.utils.gap(1),
        paddingRight: theme.utils.gap(1.75),
        paddingVertical: theme.utils.gap(1.25),
        userSelect: 'none',
        minWidth: {
            xs: isNative ? undefined : '100%'
        }
    },
    toggleButtonBorder: {
        borderWidth: 1,
        borderRadius: 8,
        borderColor: theme.colors.black
    },
    toggleButtonBorderWithCalendar: {
        borderRadius: 8,
        borderColor: theme.colors.fog,
        borderWidth: 1,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        borderBottomColor: theme.colors.transparent
    },
    datePickerToggleButtonContainer: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center'
    },
    datetimeText: {
        marginLeft: theme.utils.gap(0.5),
        fontSize: 14
    },
    chevronDown: {
        transform: [
            {
                rotate: '0deg'
            }
        ]
    },
    chevronUp: {
        transform: [
            {
                rotate: '180deg'
            }
        ]
    },
    datePickerContainer: {
        alignItems: 'center',
        flexDirection: 'column',
        position: 'relative'
    }
}))
