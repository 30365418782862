import { View } from 'react-native'
import React, { useState } from 'react'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { SidebarPosition } from 'lib/types'
import { HeaderMenuIcon } from '../Header'
import { AnimatedSidebar } from '../AnimatedSidebar'
import { MenuContent } from './menuContent'

export const MenuSidebar: React.FunctionComponent = () => {
    const { styles } = useStyles(stylesheet)
    const [isMenuDrawerOpen, setMenuDrawerOpen] = useState(false)

    return (
        <View style={styles.container}>
            <HeaderMenuIcon
                isOpen={isMenuDrawerOpen}
                onPress={() => setMenuDrawerOpen(true)}
            />
            <AnimatedSidebar
                position={SidebarPosition.Left}
                onClose={() => setMenuDrawerOpen(false)}
                isOpen={isMenuDrawerOpen}
            >
                <MenuContent />
            </AnimatedSidebar>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        display: {
            ':w[1280, ]': 'none',
            ':w[, 1280]': 'flex'
        },
        marginRight: theme.utils.gap(1)
    }
}))
