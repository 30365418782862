import { useEffect } from 'react'

export const useAutoSelectSubscriptionPlan = (
    autoSelectFrequencyJobType: boolean,
    selectSubscriptionPlan: () => void,
    setResetAutoSelectFrequencyJobType: () => void
) => {
    useEffect(() => {
        if (!autoSelectFrequencyJobType) {
            return
        }

        selectSubscriptionPlan()
        setResetAutoSelectFrequencyJobType()
    }, [autoSelectFrequencyJobType, setResetAutoSelectFrequencyJobType])
}

