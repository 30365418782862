import React from 'react'
import { TextStyle } from 'react-native'
import { Children, VoidFunction } from 'lib/types'
import { createStyles } from 'lib/styles'
import { useStyles } from 'lib/hooks'
import { BaseText } from './BaseText'

type SubheadingProps = {
    numberOfLines?: number,
    onPress?: VoidFunction,
    style?: TextStyle,
    forceColor?: string,
    testID?: string,
    children?: Children
}

export const Subheading: React.FunctionComponent<SubheadingProps> = ({
    children,
    numberOfLines,
    onPress,
    style = {},
    forceColor,
    testID
}) => {
    const { styles } = useStyles(stylesheet)

    return (
        <BaseText
            testID={testID}
            onPress={onPress}
            style={{
                ...styles.text,
                ...style
            }}
            numberOfLines={numberOfLines}
            forceColor={forceColor}
            children={children}
        />
    )
}

const stylesheet = createStyles(theme => ({
    text: {
        ...theme.components.typography.subheading,
        fontSize: {
            lg: 30,
            xs: 18
        },
        lineHeight: {
            lg: 36,
            xs: 22
        }
    }
}))
