import { useEffect } from 'react'
import { useRoute } from '@react-navigation/native'
import { Address } from 'lib/models'
import { useAddressAtom, useIsAuthorizedAtom, useUserAtom } from 'lib/atoms'
import { NavigationParams, ScreenNames } from 'lib/routing'

export const useInitializeAddress = (initialize: boolean) => {
    const [user] = useUserAtom()
    const [isAuthorized] = useIsAuthorizedAtom()
    const [, setSavedAddress] = useAddressAtom()
    const route = useRoute<NavigationParams<ScreenNames.Cleaning>>()

    useEffect(() => {
        if (!initialize || !isAuthorized) {
            return
        }

        const newAddress = user.locations.find(address => address.postcode === route.params?.postalcode)
        const [primaryAddress] = user.locations

        if (!newAddress && route.params?.postalcode) {
            return setSavedAddress({} as Address)
        }

        setSavedAddress(newAddress || primaryAddress || {})
    }, [route.params?.postalcode, initialize, isAuthorized])
}
