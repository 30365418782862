import React from 'react'
import { View, Modal as NativeModal } from 'react-native'
import { createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { JobDetailsResponse } from 'features/jobs/types'
import { PaymentSummary } from './PaymentSummary'
import { isNative } from 'lib/common'
import { DockedModal } from 'lib/components'

type PaymentSummaryModalProps = {
    jobDetail: JobDetailsResponse,
    upcomingSession?: JobDetailsResponse,
    isAdditionFeeModalShow: boolean,
    setIsAdditionFeeModalShow: (boolean) => void
}

export const PaymentSummaryModal: React.FunctionComponent<PaymentSummaryModalProps> = ({
    jobDetail,
    upcomingSession,
    isAdditionFeeModalShow,
    setIsAdditionFeeModalShow
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const onCloseModal = () => setIsAdditionFeeModalShow(false)

    return isNative ? (
        <DockedModal
            testID={T.accessibility.cancelJobScreen.paymentSummary.modal}
            contentStyles={{
                backgroundColor: theme.colors.linen,
                paddingTop: 0,
                paddingBottom: theme.utils.gap(1)
            }}
            childrenWrapper={{
                marginTop: 0,
                paddingTop: 0
            }}
            onClose={onCloseModal}
            isOpen={isAdditionFeeModalShow}
            closeButtonStyles={styles.closeButtonStyles}
        >
            <PaymentSummary
                jobDetail={jobDetail}
                onCloseModal={onCloseModal}
                showAsModal
                fullWidth
                upcomingSession={upcomingSession}
            />
        </DockedModal>
    ) :(
        <NativeModal
            transparent
            visible={isAdditionFeeModalShow}
            animationType="fade"
            statusBarTranslucent
            onRequestClose={onCloseModal}
        >
            <View style={styles.nativeModalContainer}>
                <PaymentSummary
                    jobDetail={jobDetail}
                    onCloseModal={onCloseModal}
                    showAsModal
                    upcomingSession={upcomingSession}
                />
            </View>
        </NativeModal>
    )
}

const stylesheet = createStyles(theme => ({
    nativeModalContainer: {
        backgroundColor: theme.colors.webShadow(0.6),
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1
    },
    closeButtonStyles: {
        display: 'none'
    }
}))
