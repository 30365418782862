import React from 'react'
import { View } from 'react-native'
import Animated, { useAnimatedStyle, withTiming } from 'react-native-reanimated'
import { useForm } from '@codegateinc/react-form-builder-v2'
import { useNavigation } from '@react-navigation/native'
import { Icons } from 'assets'
import { createStyles } from 'lib/styles'
import { ScreenNames } from 'lib/routing'
import { useBackHandler, useStyles, useTranslations } from 'lib/hooks'
import { Adapter, Button, ListBottomSpacer, Separator, Touchable, Typography } from 'lib/components'
import { isWeb } from 'lib/common'
import { VoidFunction } from 'lib/types'
import { Segment } from 'lib/analytics'
import { LogInFields, LogInFormShape, useLogInForm } from '../forms'
import { SocialButtons } from './SocialButtons'
import { useSignIn } from '../hooks'

type SignInTabProps = {
    index: number,
    onFocus: VoidFunction,
    activeIndex: Animated.SharedValue<number>,
    tabWidth: Animated.SharedValue<number>,
    resetPasswordSuccessMessage?: boolean,
    showGuestBooking?: boolean,
    setShowGuestBookingForm?: (value: boolean) => void
}

export const SignInTab: React.FunctionComponent<SignInTabProps> = ({
    index,
    tabWidth,
    onFocus,
    activeIndex,
    resetPasswordSuccessMessage,
    showGuestBooking,
    setShowGuestBookingForm
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const navigation = useNavigation()
    const { signInWithEmail, signInWithFacebook, isLoading, signInWithApple, signInWithGoogle } = useSignIn(error => setError(LogInFields.Password, error))
    const { form, submit, isFilled, setError, hasError } = useForm<LogInFormShape>(useLogInForm(), {
        onSuccess: ({ email, password }) => signInWithEmail(email, password)
    })
    const animatedContainerStyles = useAnimatedStyle(() => ({
        display: index === activeIndex.value ? 'flex' : 'none',
        width: tabWidth.value,
        padding: 1,
        opacity: withTiming(index === activeIndex.value ? 1 : 0)
    }))

    useBackHandler(() => isLoading)

    const handleGuestBooking = () => {
        Segment.continueAsGuestClicked({})
        setShowGuestBookingForm && setShowGuestBookingForm(true)
    }

    return (
        <Animated.View style={animatedContainerStyles}>
            <SocialButtons
                disabled={isLoading}
                onContinueWithFacebook={signInWithFacebook}
                onContinueWithApple={signInWithApple}
                onContinueWithGoogle={signInWithGoogle}
            />
            {showGuestBooking && (
                <View style={styles.submit}>
                    <Button
                        onPress={handleGuestBooking}
                        text={T.screens.auth.continueAsGuest}
                        renderIcon={color => (
                            <Icons.Arrow
                                size={14}
                                forceColor={color}
                            />
                        )}
                        testID="guest-booking-button"
                    />
                </View>
            )}
            <Separator text={T.screens.auth.loginSeparator} />
            <Adapter.TextInput
                {...form.email}
                disabled={isLoading}
                inputProps={{
                    autoComplete: 'email',
                    autoCapitalize: 'none',
                    textContentType: 'emailAddress',
                    keyboardType: 'email-address',
                    autoCorrect: false,
                    onSubmitEditing: submit,
                    testID: 'email'
                }}
                onFocus={() => {
                    if (isWeb && index !== activeIndex.value) {
                        onFocus()
                    }
                }}
            />
            <Adapter.TextInput
                {...form.password}
                disabled={isLoading}
                inputProps={{
                    autoCorrect: false,
                    textContentType: 'password',
                    autoComplete: 'password',
                    secureTextEntry: true,
                    onSubmitEditing: submit,
                    testID: 'password'
                }}
            />
            <View style={styles.forgotPasswordContainer}>
                <Touchable
                    disabled={isLoading}
                    onPress={() => navigation.navigate(ScreenNames.AuthForgotPassword)}
                    style={styles.forgotButton}
                >
                    <Typography.Regular bold>
                        {T.screens.auth.forgotPassword}
                    </Typography.Regular>
                </Touchable>
            </View>
            <View style={styles.submit}>
                <Button
                    onPress={submit}
                    text={T.common.logIn}
                    isLoading={isLoading}
                    disabled={!isFilled || hasError}
                    renderIcon={color => (
                        <Icons.Arrow
                            size={14}
                            forceColor={color}
                        />
                    )}
                    testID="login-submit-button"
                />
            </View>
            {resetPasswordSuccessMessage && (
                <View style={styles.resetPasswordMessage}>
                    <Typography.Label style={styles.resetPasswordMessageText}>
                        {T.screens.auth.resetPasswordSuccessMessage}
                    </Typography.Label>
                </View>
            )}
            <ListBottomSpacer />
        </Animated.View>
    )
}

const stylesheet = createStyles(theme => ({
    submit: {
        alignItems: 'center'
    },
    forgotPasswordContainer: {
        alignItems: 'flex-end',
        marginBottom: theme.utils.gap(3)
    },
    forgotButton: {
        borderBottomWidth: 1
    },
    resetPasswordMessage: {
        marginVertical: theme.utils.gap(3)
    },
    resetPasswordMessageText: {
        textAlign: 'center'
    }
}))
