// eslint-disable-next-line no-restricted-imports
import { Pressable, ViewStyle } from 'react-native'
import React, { useState } from 'react'
import { R } from 'lib/utils'
import { Children, PointerEvents, VoidFunction } from '../types'

type TouchableProps = {
    focusable?: boolean,
    onPress?: VoidFunction,
    onHoverIn?: VoidFunction,
    onHoverOut?: VoidFunction,
    onLongPress?: VoidFunction,
    onPressOut?: VoidFunction,
    disabled?: boolean,
    hitSlop?: {
        top: number,
        right: number,
        bottom: number,
        left: number
    },
    hitSlopLeft?: number,
    hitSlopRight?: number,
    hitSlopTop?: number,
    hitSlopBottom?: number,
    style?: ViewStyle,
    activeOpacity?: number,
    children?: Children
    testID?: string
    pointerEvents?: PointerEvents
}

export const Touchable: React.FunctionComponent<TouchableProps> = ({
    onPress,
    children,
    disabled,
    style,
    onLongPress,
    onPressOut,
    onHoverIn,
    onHoverOut,
    focusable = true,
    hitSlop = { top: 0, right: 0, bottom: 0, left: 0 },
    hitSlopLeft = 0,
    hitSlopRight = 0,
    hitSlopTop = 0,
    hitSlopBottom = 0,
    activeOpacity = 0.6,
    testID,
    pointerEvents
}) => {
    const [pressedIn, setPressedIn] = useState(false)

    return (
        <Pressable
            onHoverIn={onHoverIn}
            onHoverOut={onHoverOut}
            focusable={focusable}
            children={children}
            android_disableSound
            disabled={disabled}
            onPress={onPress}
            onLongPress={onLongPress}
            style={({ pressed }) => ({
                ...style,
                opacity: pressed || pressedIn ? activeOpacity : 1
            })}
            hitSlop={hitSlop ? hitSlop : {
                bottom: hitSlopBottom,
                top: hitSlopTop,
                left: hitSlopLeft,
                right: hitSlopRight
            }}
            onPressIn={() => setPressedIn(true)}
            onPressOut={() => {
                setPressedIn(false)
                R.ifDefined(onPressOut, R.call)
            }}
            testID={testID}
            accessibilityLabel={testID}
            accessible
            pointerEvents={pointerEvents}
        />
    )
}
