import React from 'react'
import { View, Image } from 'react-native'
import { Icons } from 'assets'
import { useIsAuthorizedAtom, useUserAtom } from 'lib/atoms'
import { createStyles } from 'lib/styles'
import { usePrice, userCreditCardNumber, useStyles, useTranslations } from 'lib/hooks'
import { FormComponents, Touchable, Typography } from 'lib/components'
import { PaymentType } from 'lib/models'
import { Segment } from 'lib/analytics'

type PaymentMethodTileProps = {
    notEnoughCredits: boolean,
    selectedPaymentMethod: PaymentType,
    onPress?: VoidFunction,
    creditCardToken?: string
}

export const PaymentMethodTile: React.FunctionComponent<PaymentMethodTileProps> = ({
    notEnoughCredits,
    selectedPaymentMethod,
    creditCardToken,
    onPress
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const [user] = useUserAtom()
    const [isAuthorized] = useIsAuthorizedAtom()
    const [defaultCard] = user.payment_providers
        ? user.payment_providers.filter(item => {
            if (creditCardToken) {
                return item.auth === creditCardToken
            }

            return item.default
        })
        : []
    const cardNumber = userCreditCardNumber(defaultCard?.last_4)
    const userBalance = usePrice(user.consumer?.balance_credit)

    if (!defaultCard || !isAuthorized) {
        return null
    }

    if (selectedPaymentMethod === PaymentType.Credits) {
        return (
            <Touchable
                disabled={!onPress}
                style={styles.container}
                testID={T.accessibility.summaryColumnPaymentTileButton}
                onPress={() => {
                    Segment.bookingEditPaymentClicked({})

                    onPress && onPress()
                }}
            >
                <View style={styles.row}>
                    <Typography.Title bold>
                        {T.common.paymentMethod}
                    </Typography.Title>
                    {onPress && (
                        <Icons.Edit
                            size={20}
                            forceColor={theme.colors.orange}
                        />
                    )}
                </View>
                <View>
                    <Typography.Regular bold>
                        {T.screens.sendPay.title}
                    </Typography.Regular>
                    {onPress && (
                        <Typography.Regular forceColor={theme.colors.darkGrey}>
                            {userBalance}
                        </Typography.Regular>
                    )}
                </View>
                <View>
                    {notEnoughCredits && (
                        <FormComponents.ErrorMessage text={T.screens.sendPay.notEnoughCredits} />
                    )}
                </View>
            </Touchable>
        )
    }

    return (
        <Touchable
            disabled={!onPress}
            style={styles.container}
            onPress={() => {
                Segment.bookingEditPaymentClicked({})

                onPress && onPress()
            }}
        >
            <View style={styles.row}>
                <Typography.Title bold>
                    {T.common.paymentMethod}
                </Typography.Title>
                {onPress && (
                    <Icons.Edit
                        size={20}
                        forceColor={theme.colors.orange}
                    />
                )}
            </View>
            <View style={styles.cardDetails}>
                <Image
                    style={styles.cardImage}
                    source={{
                        uri: defaultCard.imageUrl
                    }}
                />
                <View>
                    <Typography.Regular bold>
                        {defaultCard.card_type}
                    </Typography.Regular>
                    <Typography.Regular forceColor={theme.colors.darkGrey}>
                        {cardNumber}
                    </Typography.Regular>
                </View>
            </View>
        </Touchable>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        borderWidth: 1,
        borderRadius: 12,
        padding: theme.utils.gap(2),
        borderColor: theme.colors.silver,
        marginBottom: theme.utils.gap(2),
        backgroundColor: theme.colors.white,
        ...theme.utils.createShadow(2, 0, 8, 4, 4, theme.colors.webShadow(0.04))
    },
    row: {
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'row'
    },
    cardDetails: {
        alignItems: 'center',
        flexDirection: 'row',
        marginTop: theme.utils.gap(1)
    },
    cardImage: {
        width: 45,
        height: 25,
        resizeMode: 'contain',
        marginRight: theme.utils.gap(1)
    }
}))
