import React from 'react'
import { View } from 'react-native'
import { createStyles } from 'lib/styles'
import { Typography } from 'lib/components'
import { useStyles, useTranslations } from 'lib/hooks'

type SubmittedMessageProps = {
    text?: string
}

export const SubmittedMessage: React.FunctionComponent<SubmittedMessageProps> = ({
    text
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)

    return (
        <View style={styles.wrapper}>
            <Typography.Regular forceColor={theme.colors.mouse}>
                {text ?? T.screens.jobDetails.submittedMessage}
            </Typography.Regular>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        marginBottom: theme.utils.gap(2)
    }
}))
