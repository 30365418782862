import { from, lastValueFrom } from 'rxjs'
import qs from 'qs'
import { datadogRum } from '@datadog/browser-rum'
import axios, { CustomParamsSerializer } from 'axios'
import { catchError, delay, switchMap, map } from 'rxjs/operators'
import { authTypes, authUtils } from 'features/auth'
import { useCleanApp } from 'lib/hooks'
import { useIsAuthorizedAtom, usePushNotificationFCMAtom, useUserTokenAtom, useAppsflyerIdAtom, useAppUpdateAtom } from 'lib/atoms'
import { AppVersionHeader, ErrorCodes, ErrorResponse, HttpMethod, KeyValuePair, Response } from 'lib/types'
import { setAxiosDefaults } from './config'
import { getAppVersionsFromHeader, getRequestMetadataWithDeviceInfo, maskPassword } from './utils'

setAxiosDefaults()

type FetcherConfig = {
    responseDelay?: number,
    timeout?: number,
    urlParams?: string
}

const CustomError = (message: string, code: string) => ({
    status: false,
    errors: {
        error_code: code,
        errorMessage: message
    }
})

export const useFetcher = <ResponseType>(method: HttpMethod, url: string) => {
    const [token] = useUserTokenAtom()
    const [fcmToken] = usePushNotificationFCMAtom()
    const [isAuthorized] = useIsAuthorizedAtom()
    const { cleanPersonalData } = useCleanApp()
    const [ appsFlyerId ] = useAppsflyerIdAtom()
    const [, setAppUpdate] = useAppUpdateAtom()

    return (params: KeyValuePair = {}, config?: FetcherConfig) => {
        const requestConfig = {
            url: config?.urlParams
                ? `${url}${config.urlParams}`
                : url,
            method,
            data: method !== HttpMethod.GET
                ? {
                    ...params,
                    ...getRequestMetadataWithDeviceInfo(fcmToken, appsFlyerId)
                }
                : undefined,
            params: method === HttpMethod.GET
                ? params
                : undefined,
            paramsSerializer: {
                serialize: qs.stringify as CustomParamsSerializer
            }
        }

        return lastValueFrom(from(axios.request<ResponseType>(requestConfig))
            .pipe(
                delay(config?.responseDelay || 0),
                map(response => {

                    const body = response.data as ErrorResponse

                    const headers = { ...response.headers }

                    if(headers[AppVersionHeader.IOS_MIN] &&
                   headers[AppVersionHeader.ANDROID_MIN] &&
                   headers[AppVersionHeader.IOS_CURRENT] &&
                   headers[AppVersionHeader.ANDROID_CURRENT]){
                        setAppUpdate(getAppVersionsFromHeader(headers))
                    }

                    if (body && !body.status) {
                        throw response
                    }

                    return response
                }),
                catchError(response => {
                    const error = typeof response.data === 'string'
                        ? JSON.parse(response.data)
                        : response.data as ErrorResponse
                    const errorCode = error?.errors?.error_code
                    const params = error?.errors?.params

                    if (errorCode === ErrorCodes.Unauthorized || errorCode === ErrorCodes.RefreshTokenExpired) {
                        cleanPersonalData()

                        throw error
                    }

                    if (errorCode === ErrorCodes.SessionExpired && isAuthorized) {
                        const refreshToken = authUtils.getRefreshToken()

                        return lastValueFrom(from(axios({
                            url: '/user-extend-session',
                            method: HttpMethod.POST,
                            data: {
                                refreshToken,
                                token,
                                ...getRequestMetadataWithDeviceInfo(fcmToken, appsFlyerId)
                            },
                            headers: {
                                Authorization: `Bearer ${refreshToken}`
                            }
                        })
                            .then((response: Response<authTypes.ExtendSessionResponse>) => {
                                const { status, tokens } = response.data || {}

                                if (!status || !tokens || !tokens?.accessToken || !tokens?.refreshToken) {
                                    throw response
                                }

                                authUtils.saveAccessToken(tokens.accessToken)
                                authUtils.saveRefreshToken(tokens.refreshToken)

                                return response
                            })
                            .catch(response => {
                                cleanPersonalData()

                                throw response
                            }))
                            .pipe(switchMap(() => axios(requestConfig)))
                        )
                    }

                    if (!error && response.code === ErrorCodes.ConnectionAborted) {
                        throw CustomError(response.message, response.code)
                    }

                    const blacklistedErrorCodes = [
                        ErrorCodes.SessionExpired,
                        ErrorCodes.RefreshTokenExpired,
                        ErrorCodes.ForceResetPassword,
                        ErrorCodes.InvalidPassword,
                        ErrorCodes.Unauthorized,
                        ErrorCodes.DuplicatedEmail
                    ]
                    const shouldLogDDError = error?.errors?.error_code
                        ? !blacklistedErrorCodes.some(errorCode => error?.errors?.error_code === errorCode)
                        : true

                    if (shouldLogDDError) {
                        datadogRum.addError({
                            url,
                            method,
                            params: params ? maskPassword(params) : {},
                            error
                        })
                    }

                    throw error
                })
            ))
    }
}
