import React from 'react'
import { ImageRequireSource, SectionListData } from 'react-native'
import { IconProps, VoidFunction } from 'lib/types'

type SharedSendhelperServiceProps = {
    isNew?: boolean,
    serviceType?: string,
    icon: React.FunctionComponent<IconProps>,
    name: string,
    description: string,
    image: ImageRequireSource,
    onLearnMore: VoidFunction
}

export type SendhelperService = SharedSendhelperServiceProps & ({
    isAvailable: true,
    onPress: VoidFunction,
    onPressNative: (postalcode: string) => void
} | {
    isAvailable: false
})

export enum DriverInstructions {
    Outside = 'outside',
    Personal = 'personal',
    Reception = 'reception'
}

export enum TimeOfDay {
    AM = 'am',
    PM = 'pm'
}

export type TimeSlot = {
    _id: string,
    display: string,
    active: boolean,
    format: string,
    end: {
        time_of_day: TimeOfDay,
        minute: number,
        hour: number
    },
    start: {
        time_of_day: TimeOfDay,
        minute: number,
        hour: number
    }
}

export interface DateTimeSlot extends TimeSlot {
    date: string
}

export type BasePrice = {
    _id: string,
    title: LaundryService,
    amount: number
}

export type ItemPrices = {
    _id: string,
    sub_title: string,
    sub_content: Array<BasePrice>
}

export type CategoryPrices = {
    _id: string,
    title: string,
    content: Array<ItemPrices>
}

export enum LaundryService { // same names as on price list
    WashAndIron = 'Wash & Iron',
    DryCleanAndIron = 'Dry clean & Iron',
    IronOnly = 'Iron only',
    LoadWash = 'Load Wash ($/Kg)',
    CurtainsWash = 'Curtains'
}

export enum HandymanService {
    General = 'General',
    Plumbing = 'Plumbing',
    Electrical = 'Electrical',
    Painting = 'Painting',
    Others = 'Others'
}

export enum HandymanPriceCategory {
    MinorFix = 'minorFix',
    LargerFix = 'largerFix',
    Customized = 'customized'
}

export interface HandymanDetailsSection extends SectionListData<string> {
    title,
    subtitle?: string,
    minPrice?: string,
    maxPrice?: string
}

export type SelectableItem = {
    isSelected: boolean,
    value: string
}

export interface HandymanSubservice extends SelectableItem {
    service: HandymanService,
    priceCategory: HandymanPriceCategory
}

export enum PropertyType { // values accepted by backend
    SmallHDB = 'HDB 2/3 Room Apartment',
    LargeHDB = 'HDB 4/5 Room Apartment',
    SmallCondo = 'Condo 1/2 Room Apartment',
    MediumCondo = 'Condo 3/4 Room Apartment',
    LargeCondo = 'Condo 5/> Room Apartment',
    SmallLanded = '2/3 Bedroom Landed',
    LargeLanded = '4 / > Bedroom Landed'
}

export enum PestControlServices { // values accepted by backend
    Bedbugs =  'Bedbugs (All bedrooms)',
    BeehivesSmall = 'Beehives (Tennis Ball Size)',
    BeehivesMedium = 'Beehives (Soccer Ball Size)',
    BeehivesLarge = 'Beehives (Bigger than a soccer ball size)',
    Booklice = 'Booklice',
    Cobra = 'Cobra (any length)',
    Cockroach = 'Cockroach',
    CommensalRodents = 'Commensal Rodents',
    CommonAnts = 'Common Ants',
    CommonFlies = 'Common Flies',
    CommonHouseSnakes = 'Common House Snakes',
    DrywoodTermites = 'Drywood Termites (Per location/site)',
    HornetsSmall = 'Hornets (Soccer Ball Size)',
    HornetsLarge = 'Hornets (Bigger than a soccer ball size)',
    Mosquitoes = 'Mosquitoes',
    Python = 'Phyton (Est 2-3 meters)',
    SubterraneanTermites = 'SubterraneanTermites (Per location/site)'
}

export type PestControlSession = {
    id: string,
    title: PestControlServices,
    pest_type: PestControlServices,
    area_type: PropertyType,
    number_of_treatments: number,
    price_per_treatment: number,
    cost: number,
    isSelected?: boolean,
    minNumberOfTreatments?: number,
    note?: string,
    pestCategory?: string
}

export enum PackageFrequency {
    Monthly = 'Monthly',
    Bimonthly = 'Bi-Monthly',
    Quarterly = 'Quarterly'
}

export type PackageIdentifier = {
    id: string,
    per_annum: PackageFrequency,
    package_name: string,
    package_type: number,
    cost: number,
    area_type?: string
}

export interface PestControlPackage extends PackageIdentifier {
    sessions: number,
    title: string,
    items?: Array<FormattedPestControlPackageItems>,
    description?: string
}

export type Property = {
    label: string,
    value: number
}

export type FormattedPestControlPackageItems = {
    id: string,
    title: string,
    value: PackageFrequency,
    sessions: number,
    price: number,
    isSelected?: boolean,
    details: {
        description: string,
        includedTitle: string,
        message: string,
        title: string
    }
}

export type FormattedPestControlPackage = {
    propertyTypes: Array<string>,
    title: string,
    package_type: number,
    packageName: string,
    description: string,
    items: Array<FormattedPestControlPackageItems>
}

export type PackageDetailsModalContent = {
    title: string,
    description: string,
    includedTitle: string,
    message: string
}

export enum TaskAndErrandServices {
    Grocery =  'Grocery shopping',
    Moving = 'Moving & rearranging furnitures',
    Lift = 'Lift & carry heavy loads',
    MoveIn = 'Move-in / move-out organisation',
    Office = 'Office / home parcel delivery',
    Other = 'Other'
}

export enum HomePropertyType {
    Condominium = 'Condominium',
    HDB = 'HDB',
    Landed = 'Landed Property',
    Other = 'Others'
}

export enum CommercialPropertyType {
    Shophouse = 'Shophouse',
    Office = 'Office Building',
    Other = 'Others'
}

export enum MoversAndPackersServiceType {
    HomeMoving = 'Home Moving',
    CommercialMoving = 'Commercial Moving'
}

export enum HandymanServiceFields {
    Comment = 'comment',
    OthersComment = 'othersComment',
    Part = 'part',
    Type = 'type'
}

export enum MoversAndPackersAddonType {
    Movers = 'Movers',
    Wrapping = 'Wrapping Service',
    Tailgating = 'Tailgating Service'
}

export type QuotationDemand = {
    frequency: string,
    numberOfHours: number,
    promoCode: string
}

export type SubTotal = {
    description?: string
    items: Array<{
        name: string
        value: string
    }>
}

export type PriceSummary = {
    description?: string
    items: Array<{
        name: string
        value: string
        description?: string
    }>
}

export type Discount = {
    name: string
    value: number
    code?: string
    description?: string
}

export type JobQuotationTotalSubscription = {
    name: string
    value: number
    valueBeforeDiscount?: number
    valueSubjectToDiscount?: number
    valueSavings?: number
    valueWithoutSavings?: number
    description?: string
}

export type JobQuotationTotal = {
    name: string
    value: number
    valueBeforeDiscount?: number
    valueWithoutSavings?: number
    valueSavings?: number
    description?: string
}

export type JobQuotationResponse = {
    total: JobQuotationTotal,
    totalSubscription?: JobQuotationTotalSubscription
    discount?: Discount
    priceSummary?: PriceSummary
    services?: {
        description?: string
        items: Array<{
            name: string
            value: string
            quantity?: number
            description?: string
        }>
    }
    subTotal: SubTotal
    success?: boolean
    token?: string
    estimation?: {
        name: string,
        value: number
    },
    visitCharges: {
        title: string,
        subtitle: string
    },
    platformFee?: {
        description: string;
        name: string;
        value: string;
    },
    minimumCharges?: {
        name: string;
        value: string;
    },
    saved?: {
        description?: string;
        items?: Array<{
            name?: string
            value?: string
        }>
    },
    paymentBySessionFlow?: boolean
}

export enum ExtraCancellationReasonId {
    NonSelect = -1,
    Other = 0
}
