import React from 'react'
import { View } from 'react-native'
import { Children, Nullable } from 'lib/types'
import { dateHelpers } from 'lib/utils'
import { createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { BookingSummary } from './BookingSummary'
import { HandymanFormShape } from '../forms'
import { GetPromotionResponse, JobQuotationResponse } from '../types'

type HandymanSummaryColumnProps = {
    form: HandymanFormShape,
    couponText?: string,
    title?: string,
    jobQuotation?: JobQuotationResponse,
    renderPriceComponent?: () => Children,
    renderExtraContent?: () => Children,
    promotionPackage?: Nullable<GetPromotionResponse>,
    price?: Nullable<number>,
    totalPrice?: Nullable<number>,
    originalPrice?: Nullable<number>
}

export const HandymanSummaryColumn: React.FunctionComponent<HandymanSummaryColumnProps> = ({
    form,
    couponText,
    title,
    renderPriceComponent,
    renderExtraContent,
    promotionPackage,
    jobQuotation,
    totalPrice,
    originalPrice
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const startingDate = form.startingDate
        ? dateHelpers.startingDate(form.startingDate)
        : ''
    const bookingTime = form.bookingTime
        ? ` ${dateHelpers.selectTimeLabel(form.bookingTime)}`
        : ''
    const selectedServices = form.serviceType.reduce((acc, nextValue) => ({
        ...acc,
        [nextValue.category]: acc[nextValue.category]
            ? [
                ...acc[nextValue.category],
                `${nextValue.item}${nextValue.amount > 1 ? ` x ${nextValue.amount}` : ''}`
            ]
            : [`${nextValue.item}${nextValue.amount > 1 ? ` x ${nextValue.amount}` : ''}`]
    }), {})

    const serviceDateEntry = {
        [T.components.bookingSummary.serviceDate]: `${startingDate}\n${bookingTime}`.trim()
    }

    const platformFeeEntry = jobQuotation?.platformFee ? {
        [T.components.bookingSummary.platformFee]: jobQuotation.platformFee.value
    } : {}

    const expertiseTypeEntry = form.serviceType.length === 0 ? {
        [T.components.bookingSummary.expertiseType]: ''
    } : null

    const bookingSummary = {
        ...serviceDateEntry,
        ...platformFeeEntry,
        ...(expertiseTypeEntry || selectedServices)
    }

    return (
        <View style={styles.container}>
            <BookingSummary
                totalPrice={totalPrice}
                originalPrice={originalPrice}
                promotionPackage={promotionPackage}
                title={title}
                couponText={couponText}
                handymanService={form.serviceType}
                renderPriceComponent={renderPriceComponent}
                bookingSummary={bookingSummary}
                platformFeeDescription={jobQuotation?.platformFee?.description}
            />
            {renderExtraContent && renderExtraContent()}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        borderWidth: 1,
        borderRadius: 8,
        borderColor: theme.colors.silver,
        paddingTop: theme.utils.gap(2),
        paddingHorizontal: theme.utils.gap(2),
        marginBottom: theme.utils.gap(2),
        backgroundColor: theme.colors.white,
        ...theme.utils.createShadow(2, 0, 8, 4, 4, theme.colors.webShadow(0.04))
    }
}))
