import React from 'react'
import { View } from 'react-native'
import { createStyles } from 'lib/styles'
import { Typography } from 'lib/components'
import { useStyles } from 'lib/hooks'
import { CustomFonts } from 'lib/types'
import { SessionJob } from 'features/jobs/types'
import { SessionItem } from 'features/jobs/components/SessionItem'

type SessionListProps =  {
    title: string,
    sessions: Array<SessionJob>
}

export const SessionList: React.FunctionComponent<SessionListProps> = ({ title, sessions }) => {
    const { styles } = useStyles(stylesheet)

    if (!sessions.length) {
        return null
    }

    return (
        <View>
            <Typography.Regular style={styles.subTitle}>
                {title}
            </Typography.Regular>
            <View>
                {sessions.map((item: SessionJob, index) => <SessionItem key={index} session={item} />)}
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    subTitle: {
        fontSize: 14,
        lineHeight: 17,
        fontFamily: CustomFonts.Poppins500,
        color: theme.colors.grey,
        marginTop: theme.utils.gap(1.5)
    }
}))
