import React from 'react'
import { View } from 'react-native'
import { useForm } from '@codegateinc/react-form-builder-v2'
import { createStyles } from 'lib/styles'
import { Adapter, Button } from 'lib/components'
import { useStyles, useTranslations } from 'lib/hooks'
import { ChangePasswordFormShape, useChangePasswordForm } from '../forms'

type UserPasswordFormProps = {
    onCancel: VoidFunction
}

export const UserPasswordForm: React.FunctionComponent<UserPasswordFormProps> = ({ onCancel }) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const { form, submit, isFilled, hasError } = useForm<ChangePasswordFormShape>(useChangePasswordForm(), {
        // TODO Submit request to API to change user profile
        onSuccess: () => {}
    })

    // TODO Get this from request
    const isLoading = false

    return (
        <View style={styles.container}>
            <View style={styles.row}>
                <View style={styles.input}>
                    <Adapter.TextInput
                        {...form.currentPassword}
                        disabled={isLoading}
                        inputProps={{
                            autoCorrect: false,
                            textContentType: 'password',
                            autoComplete: 'password',
                            secureTextEntry: true
                        }}
                    />
                </View>
            </View>
            <View style={styles.row}>
                <View style={styles.input}>
                    <Adapter.TextInput
                        {...form.newPassword}
                        disabled={isLoading}
                        inputProps={{
                            autoCorrect: false,
                            textContentType: 'newPassword',
                            autoComplete: 'password',
                            secureTextEntry: true
                        }}
                    />
                </View>
            </View>
            <View style={styles.row}>
                <View style={styles.input}>
                    <Adapter.TextInput
                        {...form.confirmNewPassword}
                        disabled={isLoading}
                        inputProps={{
                            autoCorrect: false,
                            textContentType: 'newPassword',
                            autoComplete: 'password',
                            secureTextEntry: true
                        }}
                    />
                </View>
            </View>
            <View style={styles.buttons}>
                <View style={styles.button}>
                    <Button
                        noBackground
                        onPress={onCancel}
                        text={T.common.cancel}
                    />
                </View>
                <View style={styles.buttonSeparator} />
                <View style={styles.button}>
                    <Button
                        onPress={submit}
                        text={T.common.save}
                        isLoading={isLoading}
                        disabled={!isFilled || hasError}
                    />
                </View>
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        marginTop: theme.utils.gap(2)
    },
    row: {
        flexDirection: 'row'
    },
    labelColumn: {
        width: 100,
        paddingTop: theme.components.input.height / 4
    },
    input: {
        flex: 1
    },
    buttons: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginBottom: theme.utils.gap(3)
    },
    buttonSeparator: {
        width: theme.utils.gap(2)
    },
    button: {
        flex: {
            lg: undefined,
            xs: 1
        },
        width: 120
    }
}))
