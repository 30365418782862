import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const CreditCard: React.FunctionComponent<IconProps> = props => (
    <Icon
        {...props}
        forceColor="transparent"
    >
        <Path
            d="M21.502 14.5v-7a3.5 3.5 0 0 1 3.5-3.5H70.5A3.5 3.5 0 0 1 74 7.5v31.499a3.5 3.5 0 0 1-3.5 3.5H67"
            stroke={props.forceColor}
            strokeWidth={8}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <Path
            d="M52.999 24.998h-45.5A3.5 3.5 0 0 0 4 28.498v31.5a3.5 3.5 0 0 0 3.5 3.499h45.499a3.5 3.5 0 0 0 3.5-3.5v-31.5a3.5 3.5 0 0 0-3.5-3.499ZM4 40.751h52.498M56.498 32.001v21M4 32.001v21M16.252 51.248h14M40.749 51.248h3.5"
            stroke={props.forceColor}
            strokeWidth={8}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Icon>
)
