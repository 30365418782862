import { Icons } from 'assets'
import { useTranslations } from 'lib/hooks'
import { DriverInstructions } from '../types'

export const useDriverInstructionsOptions = () => {
    const T = useTranslations()

    return [
        {
            label: T.screens.laundry.driverInstructions.outside.label,
            key: T.screens.laundry.driverInstructions.outside.key,
            value: DriverInstructions.Outside,
            icon: Icons.House
        },
        {
            label: T.screens.laundry.driverInstructions.personal.label,
            key: T.screens.laundry.driverInstructions.personal.key,
            value: DriverInstructions.Personal,
            icon: Icons.UserOpen
        },
        {
            label: T.screens.laundry.driverInstructions.reception.label,
            key: T.screens.laundry.driverInstructions.reception.key,
            value: DriverInstructions.Reception,
            icon: Icons.Key
        }
    ]
}
