import { useMutation } from '@tanstack/react-query'
import { useFetcher } from 'lib/api'
import { ErrorResponse, HttpMethod, KeyValuePair, Response } from 'lib/types'
import { BraintreeTokenResponse, CreatePaymentMethodRequest, CreatePaymentMethodResponse, DeleteCardRequest, DeleteCardResponse, SetDefaultCardRequest, SetDefaultCardResponse } from './types'

export const setDefaultCard = () => {
    const fetcher = useFetcher<SetDefaultCardResponse>(HttpMethod.POST, '/user/set_default_card')

    return useMutation<Response<SetDefaultCardResponse>, ErrorResponse, SetDefaultCardRequest>(fetcher)
}

export const removeCard = () => {
    const fetcher = useFetcher<DeleteCardResponse>(HttpMethod.POST, '/payment/remove_payment_method')

    return useMutation<Response<DeleteCardResponse>, ErrorResponse, DeleteCardRequest>(fetcher)
}

export const createBraintreeToken = () => {
    const fetcher = useFetcher<BraintreeTokenResponse>(HttpMethod.POST, '/payment/get_client_token')

    return useMutation<Response<BraintreeTokenResponse>, ErrorResponse, KeyValuePair>(fetcher)
}

export const createPaymentMethod = () => {
    const fetcher = useFetcher<CreatePaymentMethodResponse>(HttpMethod.POST, '/payment/register_braintree_payment')

    return useMutation<Response<CreatePaymentMethodResponse>, ErrorResponse, CreatePaymentMethodRequest>(fetcher)
}
