import { useField } from '@codegateinc/react-form-builder-v2'
import { useTranslations } from 'lib/hooks'
import { SearchAddressFields } from './forms'

export const useSearchAddressForm = () => {
    const T = useTranslations()

    const searchValue = useField<string>({
        key: SearchAddressFields.SearchValue,
        initialValue: '',
        isRequired: false,
        validateOnBlur: false,
        placeholder: T.common.search,
        label: T.common.search
    })

    return {
        searchValue
    }
}
