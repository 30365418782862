import React from 'react'
import { View, ViewStyle } from 'react-native'
import { useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { Measurements } from 'lib/common'
import { ChatMessage, ChatMessageType } from '../types'
import { TextMessage } from './TextMessage'
import { ImageMessage } from './ImageMessage'

interface MessageTileProps extends ChatMessage {
    isMe: boolean,
    onImagePress(imageUrl: string): void
}

export const MessageTile: React.FunctionComponent<MessageTileProps> = ({
    isMe,
    onImagePress,
    ...message
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const getMessageBody = () => {
        switch (message.payload?.type) {
            case ChatMessageType.System:
            case ChatMessageType.Text:
                return (
                    <TextMessage {...message} />
                )
            case ChatMessageType.Photo:
                return (
                    <ImageMessage
                        {...message}
                        onPress={onImagePress}
                    />
                )
            default:
                return (
                    <TextMessage
                        {...message}
                        payload={{
                            type: ChatMessageType.Text,
                            body: T.screens.chat.unsupportedMessage
                        }}
                    />
                )
        }
    }
    const messageStyle = message.payload?.type === ChatMessageType.System
        ? styles.systemMessage
        : {
            alignSelf: isMe
                ? 'flex-end'
                : 'flex-start',
            backgroundColor: isMe
                ? theme.colors.navajo
                : theme.colors.latte,
            borderTopLeftRadius: isMe ? 20 : 0,
            borderTopRightRadius: isMe ? 0 : 20
        }

    return (
        <View
            style={{
                ...styles.container,
                ...messageStyle as ViewStyle
            }}
        >
            {getMessageBody()}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        minWidth: 120,
        maxWidth: Math.max(Measurements.WindowWidth * 0.45, 250),
        borderRadius: 20,
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginVertical: theme.utils.gap(1),
        marginHorizontal: theme.utils.gap(2),
        padding: theme.utils.gap(2)
    },
    systemMessage: {
        backgroundColor: theme.colors.silver,
        minWidth: undefined,
        maxWidth: undefined
    }
}))
