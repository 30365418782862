import React from 'react'
import { View } from 'react-native'
import { Field } from '@codegateinc/react-form-builder-v2'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { useCreditsPackageAtom } from 'lib/atoms'
import { PackageTile } from 'lib/components'
import { CreditPackage } from 'lib/models'
import { Nullable } from 'lib/types'

interface CreditsPackageProps extends Field<string> {
    onPurchase?: (value: CreditPackage) => void
    onSelect?: (value: Nullable<CreditPackage>) => void
}

export const CreditsPackage: React.FunctionComponent<CreditsPackageProps> = ({
    onPurchase,
    onChangeValue,
    onSelect,
    value
}) => {
    const { styles } = useStyles(stylesheet)
    const [creditsPackage] = useCreditsPackageAtom()

    const onChange = (newValue: CreditPackage) => {
        const stringValue = String(newValue.price)

        if (stringValue === value) {
            return onChangeValue('')
        }

        if (onSelect) {
            onSelect(newValue)
        }

        onChangeValue(stringValue)
    }

    return (
        <View style={styles.container}>
            {creditsPackage.map((creditPackage, index) => (
                <PackageTile
                    index={index}
                    key={creditPackage._id}
                    onPurchase={onPurchase}
                    isSelected={creditPackage.price === Number(value)}
                    onPress={() => onChange(creditPackage)}
                    creditPackage={creditPackage}
                    isLast={index === creditsPackage.length - 1}
                />
            ))}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        flexDirection: 'row',
        marginTop: theme.utils.gap(4)
    }
}))
