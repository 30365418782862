import React from 'react'
import { View } from 'react-native'
import { Field } from '@codegateinc/react-form-builder-v2'
import { createStyles } from 'lib/styles'
import { SelectInputOption } from 'lib/types'
import { NewAddressShape } from 'features/user'
import { useStyles, useTranslations } from 'lib/hooks'
import { Adapter } from 'lib/components'
import { Segment } from 'lib/analytics'

type NewAddressModalFormProps = {
    isLoading: boolean,
    form: Record<keyof NewAddressShape, Field<SelectInputOption & string>>,
    fetchPostalCodes(value: string): Promise<Array<SelectInputOption>>
}

export const NewAddressModalForm: React.FunctionComponent<NewAddressModalFormProps> = ({
    form,
    isLoading,
    fetchPostalCodes
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)

    return (
        <View style={styles.container}>
            <View>
                <Adapter.AsyncSelectInput
                    {...form.postcode}
                    loadingOptions={fetchPostalCodes}
                    disabled={isLoading}
                />
                <Adapter.TextInputWithLabel
                    {...form.addressLine}
                    disabled={isLoading}
                    onBlur={() => {
                        if (form.addressLine.value) {
                            Segment.addressLineEntered({
                                entryType: Segment.EntryType.Manual
                            })
                        }
                    }}
                />
                <View style={styles.row}>
                    <View style={styles.flexLeft}>
                        <Adapter.TextInputWithLabel
                            {...form.block}
                            label={T.common.block}
                            disabled={isLoading}
                            onBlur={() => {
                                if (form.block.value) {
                                    Segment.blockLineEntered({
                                        entryType: Segment.EntryType.Manual
                                    })
                                }
                            }}
                        />
                    </View>
                    <View style={styles.flex}>
                        <Adapter.TextInputWithLabel
                            {...form.unitFloor}
                            smallLabel
                            disabled={isLoading}
                            onBlur={() => {
                                if (form.unitFloor.value) {
                                    Segment.unitAndFloorEntered({
                                        entryType: Segment.EntryType.Manual
                                    })
                                }
                            }}
                        />
                    </View>
                </View>
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        marginTop: theme.utils.gap(3)
    },
    title: {
        paddingVertical: theme.utils.gap(2)
    },
    row: {
        flexDirection: {
            lg: 'row',
            xs: 'column'
        }
    },
    flexLeft: {
        flex: {
            lg: 1,
            xs: undefined
        },
        paddingRight: {
            lg: theme.utils.gap(1),
            xs: 0
        }
    },
    flex: {
        flex: {
            lg: 1,
            xs: undefined
        }
    }
}))
