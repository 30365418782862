import React from 'react'
import { View } from 'react-native'
import { Icons } from 'assets'
import { createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { BasicModal } from '../BasicModal'
import { Regular, SmallSubheading } from '../typography'
import { Button } from '../Button'

type ConfirmationModalProps = {
    isOpen: boolean,
    isLoading: boolean,
    onCancel: VoidFunction,
    onConfirm: VoidFunction
}

export const ConfirmationModal: React.FunctionComponent<ConfirmationModalProps> = ({
    isOpen,
    isLoading,
    onCancel,
    onConfirm
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)

    return (
        <BasicModal
            isLoading={isLoading}
            isOpen={isOpen}
            onClose={onCancel}
        >
            <View style={styles.content}>
                <View style={styles.iconContainer}>
                    <Icons.CheckCircle
                        size={32}
                        forceColor={theme.icon.selected}
                    />
                </View>
                <SmallSubheading style={styles.alignText}>
                    {T.components.addressChangeConfirmation.title}
                </SmallSubheading>
                <View style={styles.messageContainer}>
                    <Regular style={styles.alignText}>
                        {T.components.addressChangeConfirmation.message}
                    </Regular>
                </View>
                <View style={styles.actionButtons}>
                    <View style={styles.buttonContainer}>
                        <Button
                            noBackground
                            onPress={onCancel}
                            text={T.common.cancel}
                            width={160}
                            disabled={isLoading}
                        />
                    </View>
                    <View style={styles.spacer} />
                    <View style={styles.buttonContainer}>
                        <Button
                            onPress={onConfirm}
                            text={T.common.confirm}
                            isLoading={isLoading}
                            width={160}
                        />
                    </View>
                </View>
            </View>
        </BasicModal>
    )
}

const stylesheet = createStyles(theme => ({
    content: {
        alignItems: 'center',
        paddingVertical: {
            lg: theme.utils.gap(5),
            xs: 0
        },
        paddingHorizontal: {
            lg: theme.utils.gap(8),
            xs: 0
        },
        paddingTop: {
            lg: theme.utils.gap(5),
            xs: theme.utils.gap(3)
        },
        backgroundColor: theme.components.modal.content.backgroundColor,
        borderRadius: theme.components.modal.content.borderRadius
    },
    iconContainer: {
        marginBottom: theme.utils.gap(1)
    },
    messageContainer: {
        flex: 1,
        alignItems: 'center',
        marginVertical: theme.utils.gap(1)
    },
    alignText: {
        textAlign: 'center'
    },
    actionButtons: {
        marginTop: theme.utils.gap(2),
        flexDirection: 'row'
    },
    buttonContainer: {
        alignItems: 'center',
        justifyContent: 'center'
    },
    spacer: {
        width: theme.utils.gap(1)
    }
}))
