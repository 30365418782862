import axios from 'axios'
import { authUtils } from 'features/auth'
import { APP_CONFIG } from '../config'
import { HttpMethod, KeyValuePair } from '../types'

// Need this for file upload, need to remove all request headers
export const axiosWithoutInterceptors = axios.create()

export const setAxiosDefaults = () => {
    axios.defaults.baseURL = APP_CONFIG.API.URL
    axios.defaults.timeout = APP_CONFIG.API.REQUEST_TIMEOUT
}

axios.interceptors.request.use(request => {
    request.headers = request.headers || {} as KeyValuePair

    if (request.method !== HttpMethod.GET) {
        request.headers.Accept = 'application/json'
        request.headers['Content-Type'] = 'application/json'
    }

    if (!request.headers.Authorization) {
        const accessToken = authUtils.getAccessToken()

        request.headers.Authorization = `Bearer ${accessToken}`
    }

    request.baseURL = APP_CONFIG.API.URL

    return request
})
