import * as Clipboard from 'expo-clipboard'
import { useToastAtom } from 'lib/atoms'
import { NotificationType } from 'lib/types'
import { useTranslations } from './useTranslations'

export const useCopyToClipboard = (message?: string) => {
    const T = useTranslations()
    const [, setToastMessage] = useToastAtom()

    const onCopyPress = async (value: string) => {
        await Clipboard.setStringAsync(value)
            .then(() => {
                setToastMessage({
                    message: message ?? T.screens.referFriend.copiedMessage,
                    type: NotificationType.Success
                })
            })
            .catch(() => {
                setToastMessage({
                    message: T.common.errorMessage,
                    type: NotificationType.Error
                })
            })
    }

    return {
        onCopyPress
    }
}
