import React from 'react'
import { Field } from '@codegateinc/react-form-builder-v2'
import { View } from 'react-native'
import { useStyles } from 'lib/hooks'
import { Nullable, SelectInputOption } from 'lib/types'
import { Breakpoint, createStyles } from 'lib/styles'
import { MediaQuery, Typography } from 'lib/components'
import { FormComponents } from '../components'

interface AsyncSelectInputProps extends Field<Nullable<SelectInputOption>> {
    loadingOptions: (value: string) => Promise<Array<SelectInputOption>>,
    defaultOptions?: Array<SelectInputOption>,
    disabled?: boolean,
    testID?: string,
}

export const AsyncSelectInput: React.FunctionComponent<AsyncSelectInputProps> = ({
    label,
    isRequired,
    onChangeValue,
    value,
    loadingOptions,
    placeholder,
    errorMessage,
    defaultOptions,
    testID,
    disabled = false
}) => {
    const { styles, theme } = useStyles(stylesheet)

    return (
        <View style={styles.row}>
            {label && (
                <View style={styles.labelColumn}>
                    <Typography.Regular>
                        <Typography.Regular>
                            {label}
                        </Typography.Regular>
                        <Typography.Label forceColor={theme.components.input.typography.error}>
                            {isRequired ? '*' : ''}
                        </Typography.Label>
                    </Typography.Regular>
                    <MediaQuery.Visible from={Breakpoint.LG}>
                        <FormComponents.ErrorMessage />
                    </MediaQuery.Visible>
                </View>
            )}
            <View style={styles.input}>
                <FormComponents.AsyncSelectInput
                    disabled={disabled}
                    errorMessage={errorMessage}
                    placeholder={placeholder}
                    defaultOptions={defaultOptions}
                    loadingOptions={loadingOptions}
                    value={value}
                    onChange={onChangeValue}
                    testID={testID}
                />
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    input: {
        flex: 1
    },
    row: {
        zIndex: theme.zIndex[10],
        alignItems: {
            lg: 'center',
            xs: 'stretch'
        },
        flexDirection: {
            lg: 'row',
            xs: 'column'
        }
    },
    labelColumn: {
        width: {
            lg: 120,
            xs: '100%'
        },
        marginRight: {
            lg: theme.utils.gap(1),
            xs: 0
        },
        marginBottom: {
            lg: 0,
            xs: theme.utils.gap(1)
        }
    },
    textRow: {
        flexDirection: 'row',
        flexWrap: 'wrap'
    }
}))
