import React, { useEffect, useRef } from 'react'
import { View, ViewStyle } from 'react-native'
import Animated, { runOnJS, useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated'
import { Icons } from 'assets'
import { isWeb } from 'lib/common'
import { initToastState, useToastAtom } from 'lib/atoms'
import { createStyles } from 'lib/styles'
import { useNotificationColor, useStyles, useToastIcon, useTranslations } from 'lib/hooks'
import { Touchable } from './Touchable'
import { HTMLContent } from './htmlContent'

const TOAST_HEIGHT = 100

type ToastProps = {
    isModal?: boolean
}

export const Toast: React.FunctionComponent<ToastProps> = ({ isModal }) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const [toast, setToastMessage] = useToastAtom()
    const timeoutRef = useRef<NodeJS.Timeout>()
    const icon = useToastIcon(toast.type)
    const color = useNotificationColor(toast.type)
    const isOpened = useSharedValue(Boolean(toast.message))
    const animatedSlideInStyle = useAnimatedStyle<ViewStyle>(() => ({
        transform: [{
            translateY: withTiming(
                isOpened.value
                    ? -TOAST_HEIGHT
                    : TOAST_HEIGHT,
                {},
                () => {
                    if (!isOpened.value) {
                        runOnJS(setToastMessage)(initToastState)
                    }
                }
            )
        }]
    }))

    useEffect(() => {
        if (toast.isModalOnly && !isModal) {
            return
        }

        if (!toast.message) {
            isOpened.value = false

            return clearTimeout(timeoutRef.current)
        }

        isOpened.value = true
        timeoutRef.current = setTimeout(() => {
            isOpened.value = false
        }, 5000)

        return () => clearTimeout(timeoutRef.current)
    }, [toast.message])

    if (toast.isModalOnly && !isModal) {
        return null
    }

    return (
        <Animated.View
            style={[
                styles.wrapper as ViewStyle,
                animatedSlideInStyle
            ]}
        >
            <View style={styles.container}>
                <View style={styles.message}>
                    <View>
                        {icon}
                    </View>
                    <View style={styles.messageText} testID={T.accessibility.toast.container}>
                        <HTMLContent
                            html={toast.message}
                            messageColor={color.text}
                        />
                    </View>
                </View>
                <Touchable
                    onPress={() => isOpened.value = false}
                    hitSlopBottom={20}
                    hitSlopRight={20}
                    hitSlopLeft={20}
                    hitSlopTop={20}
                >
                    <Icons.Close size={15} />
                </Touchable>
            </View>
        </Animated.View>
    )
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        left: 0,
        right: 0,
        bottom: 0,
        position: isWeb
            ? 'fixed'
            : 'absolute',
        display: 'flex',
        alignItems: 'center'
    },
    container: {
        width: {
            lg: '60%',
            xs: '90%'
        },
        borderRadius: 8,
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: theme.utils.gap(3),
        backgroundColor: theme.colors.white,
        ...theme.utils.createShadow(0, 0.8, 20, 70)
    },
    message: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center'
    },
    messageText: {
        flex: 1,
        marginHorizontal: theme.utils.gap(1),
        marginLeft: theme.utils.gap(1),
        marginRight: theme.utils.gap(4)
    }
}))
