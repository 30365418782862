import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const AirCondition: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path d="M8.071 0h63.864a8.086 8.086 0 0 1 8.07 8.083V38.2a8.086 8.086 0 0 1-8.07 8.083H8.071A8.086 8.086 0 0 1 0 38.2V8.083A8.086 8.086 0 0 1 8.071 0Zm63.864 40.9a2.7 2.7 0 0 0 2.69-2.694V8.083a2.7 2.7 0 0 0-2.69-2.694H8.071a2.7 2.7 0 0 0-2.69 2.694V38.2a2.7 2.7 0 0 0 2.69 2.7Z" />
        <Path d="M77.305 25.801H2.694v-5.389h74.611Z" />
        <Path d="M68.145 35.657h-56.29a2.695 2.695 0 0 1 0-5.389h56.29a2.695 2.695 0 0 1 0 5.389Z" />
        <Path d="M18.912 14.556h-7.051a2.695 2.695 0 0 1 0-5.389h7.051a2.695 2.695 0 1 1 0 5.389Z" />
        <Path d="M17.472 80.707a2.7 2.7 0 0 1-2.408-3.9l2.392-4.784a4.771 4.771 0 0 0-.177-4.611 10.137 10.137 0 0 1-.376-9.794l2.392-4.784a2.694 2.694 0 0 1 4.82 2.41l-2.392 4.784a4.773 4.773 0 0 0 .177 4.612 10.135 10.135 0 0 1 .376 9.793l-2.392 4.784a2.7 2.7 0 0 1-2.412 1.49Z" />
        <Path d="M37.182 80.707a2.7 2.7 0 0 1-2.408-3.9l2.392-4.784a4.772 4.772 0 0 0-.177-4.611 10.137 10.137 0 0 1-.377-9.795l2.392-4.784a2.694 2.694 0 0 1 4.82 2.41l-2.392 4.784a4.773 4.773 0 0 0 .177 4.612 10.135 10.135 0 0 1 .376 9.793l-2.392 4.784a2.7 2.7 0 0 1-2.411 1.491Z" />
        <Path d="M56.884 80.707a2.7 2.7 0 0 1-2.408-3.9l2.392-4.784a4.77 4.77 0 0 0-.177-4.611 10.136 10.136 0 0 1-.376-9.794l2.392-4.784a2.694 2.694 0 1 1 4.82 2.41l-2.392 4.784a4.773 4.773 0 0 0 .177 4.612 10.133 10.133 0 0 1 .376 9.794l-2.391 4.784a2.694 2.694 0 0 1-2.413 1.489Z" />
    </Icon>
)
