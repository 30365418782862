import React from 'react'
import { View } from 'react-native'
import { createStyles } from 'lib/styles'
import { Typography } from 'lib/components'
import { useStyles, useTranslations } from 'lib/hooks'
import { getPricingOptionsForAircon } from '../utils'

type AirConditionPricingModalItemProps = {
    item,
    isLast?: boolean
}

export const AirConditionPricingModalItem: React.FunctionComponent<AirConditionPricingModalItemProps> = ({
    item,
    isLast = false
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const plansWithFrequency = Object.keys(item).map(key => item[key]).filter(item => !!item.frequency)
    const pricingOptions = getPricingOptionsForAircon(item)

    return (
        <View
            style={!isLast
                ? styles.withBorder
                : undefined
            }
        >
            <Typography.Regular
                bold
                style={styles.header}
            >
                {`${item.label} ${T.screens.airCondition.servicing}`}
            </Typography.Regular>
            {plansWithFrequency.length > 1 && (
                <View
                    style={{
                        ...styles.row,
                        ...styles.columns
                    }}
                >
                    {plansWithFrequency.map((column, index) => (
                        <Typography.Regular
                            bold
                            key={index}
                            style={styles.column}
                            forceColor={theme.colors.darkGrey}
                        >
                            {column.frequency}
                        </Typography.Regular>
                    ))}
                </View>
            )}
            {Object.keys(pricingOptions).map((item, index) => (
                <View
                    key={index}
                    style={styles.row}
                >
                    <Typography.Regular>
                        {item}
                    </Typography.Regular>
                    <View style={styles.values}>
                        {pricingOptions[item].price.map((price, index) => (
                            <Typography.Regular
                                bold
                                key={index}
                                style={styles.columnItem}
                            >
                                {price}
                            </Typography.Regular>
                        ))}
                    </View>
                </View>
            ))}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    row: {
        width: '100%',
        flexDirection: 'row',
        borderBottomWidth: 1,
        justifyContent: 'space-between',
        paddingVertical: theme.utils.gap(1),
        borderBottomColor: theme.colors.silver
    },
    values: {
        flexDirection: 'row'
    },
    columnItem: {
        width: 100,
        textAlign: 'right'
    },
    header: {
        marginBottom: theme.utils.gap(1)
    },
    column: {
        marginLeft: theme.utils.gap(1)
    },
    columns: {
        justifyContent: 'flex-end'
    },
    withBorder: {
        borderRightWidth: {
            lg: 1,
            xs: 0
        },
        borderRightColor: theme.colors.silver,
        paddingRight: {
            lg: theme.utils.gap(2),
            xs: undefined
        },
        marginRight: {
            lg: theme.utils.gap(2),
            xs: undefined
        },
        marginBottom: {
            lg: 0,
            xs: theme.utils.gap(3)
        }
    }
}))
