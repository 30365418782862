import { dateHelpers } from 'lib/utils'
import { useIsSingaporeTimeZone } from 'lib/hooks'
import { supportedTimeZones, timeZones } from 'lib/locale'

export const useBookingTimeOptions = (date: string) => {
    const isSingaporeTimeZone = useIsSingaporeTimeZone()

    if (!date) {
        return []
    }

    const timeZoneFormat = supportedTimeZones[timeZones.ASIA_SINGAPORE].timeZoneName

    const generateTimeOption = (index: number) => {
        const selectedTime = dateHelpers.selectTimeValue(date, index * 30)
        const label = `${timeZoneFormat} ${dateHelpers.selectTimeLabel(selectedTime)}`
        const subLabel = !isSingaporeTimeZone
            ? dateHelpers.selectTimeLabelWithTimeZone(selectedTime)
            : undefined

        return { value: selectedTime, label, subLabel }
    }

    const dates = new Array(48).fill(0).map((item, index) => generateTimeOption(index))

    const eightAM = `${timeZoneFormat} ${dateHelpers.selectTimeLabelWithoutTimezone(dateHelpers.selectTimeValue(date, 8 * 60))}`

    const firstSlotIndex = dates.findIndex(item => item.label === eightAM)

    return [...dates.slice(firstSlotIndex), ...dates.slice(0, firstSlotIndex)]
}

