import React from 'react'
import { View } from 'react-native'
import { createStyles } from 'lib/styles'
import { Typography } from 'lib/components'
import { useStyles, useTranslations } from 'lib/hooks'

export const ReferFriendHeader: React.FunctionComponent = () => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)

    return (
        <View style={styles.row}>
            <Typography.SmallSubheading>
                {T.screens.referFriend.messagePartOne}
                <Typography.SmallSubheading forceColor={theme.colors.orange}>
                    {T.screens.referFriend.messagePartTwo}
                </Typography.SmallSubheading>
            </Typography.SmallSubheading>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    row: {
        marginBottom: theme.utils.gap(2),
        maxWidth: {
            lg: 600,
            xs: '100%'
        }
    }
}))
