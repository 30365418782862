import React from 'react'
import { Path, Ellipse } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const CleaningTool: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path d="M42.3437 125.607C43.2757 125.607 44.1695 125.18 44.8287 124.42C45.4879 123.66 45.8587 122.629 45.8594 121.554V112.73C45.8594 111.654 45.489 110.622 44.8297 109.861C44.1703 109.101 43.2761 108.673 42.3437 108.673C41.4113 108.673 40.5171 109.101 39.8578 109.861C39.1985 110.622 38.8281 111.654 38.8281 112.73V121.554C38.8288 122.629 39.1995 123.66 39.8588 124.42C40.518 125.18 41.4118 125.607 42.3437 125.607Z" fill="white" stroke="#E03C31" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M35.3156 125.609C36.2473 125.609 37.1408 125.182 37.7996 124.422C38.4584 123.661 38.8285 122.63 38.8285 121.555V112.732C38.803 111.676 38.4216 110.673 37.7653 109.937C37.109 109.2 36.2297 108.788 35.3142 108.788C34.3988 108.788 33.5194 109.2 32.8631 109.937C32.2068 110.673 31.8254 111.676 31.7999 112.732V121.555C31.8007 122.631 32.1714 123.662 32.8306 124.422C33.4898 125.182 34.3836 125.609 35.3156 125.609Z" fill="white" stroke="#E03C31" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M28.2842 125.609C29.2161 125.609 30.1099 125.182 30.7691 124.422C31.4284 123.662 31.7991 122.631 31.7998 121.555V112.732C31.7743 111.676 31.3929 110.673 30.7366 109.937C30.0803 109.2 29.201 108.788 28.2855 108.788C27.3701 108.788 26.4907 109.2 25.8344 109.937C25.1782 110.673 24.7967 111.676 24.7712 112.732V121.555C24.7712 122.63 25.1413 123.661 25.8001 124.422C26.4589 125.182 27.3525 125.609 28.2842 125.609Z" fill="white" stroke="#E03C31" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M21.2557 125.609C22.1877 125.609 23.0814 125.182 23.7407 124.422C24.3999 123.662 24.7706 122.631 24.7714 121.555V112.732C24.7459 111.676 24.3644 110.673 23.7082 109.937C23.0519 109.2 22.1725 108.788 21.2571 108.788C20.3416 108.788 19.4623 109.2 18.806 109.937C18.1497 110.673 17.7683 111.676 17.7428 112.732V121.555C17.7428 122.63 18.1129 123.661 18.7717 124.422C19.4305 125.182 20.324 125.609 21.2557 125.609Z" fill="white" stroke="#E03C31" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M14.2272 125.607C15.1591 125.607 16.0529 125.18 16.7121 124.42C17.3714 123.66 17.7421 122.629 17.7428 121.554V112.73C17.7428 111.654 17.3724 110.622 16.7131 109.861C16.0538 109.101 15.1596 108.673 14.2272 108.673C13.2947 108.673 12.4005 109.101 11.7412 109.861C11.0819 110.622 10.7115 111.654 10.7115 112.73V121.554C10.7122 122.629 11.083 123.66 11.7422 124.42C12.4014 125.18 13.2952 125.607 14.2272 125.607Z" fill="white" stroke="#E03C31" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M7.1985 125.609C8.12876 125.606 9.02019 125.178 9.67722 124.418C10.3343 123.658 10.7032 122.629 10.7032 121.555V112.732C10.6778 111.676 10.2963 110.673 9.64005 109.937C8.98377 109.2 8.10441 108.788 7.18895 108.788C6.2735 108.788 5.39416 109.2 4.73788 109.937C4.0816 110.673 3.70017 111.676 3.67468 112.732V121.555C3.67504 122.089 3.76651 122.617 3.94386 123.109C4.12121 123.601 4.38094 124.049 4.70823 124.425C5.03552 124.802 5.42393 125.1 5.85123 125.303C6.27854 125.506 6.73636 125.61 7.1985 125.609Z" fill="white" stroke="#E03C31" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M31.6704 7.78671C31.8327 6.73847 32.5194 5.76839 33.5825 5.08569C34.6456 4.403 35.9998 4.06242 37.3531 4.13741C38.7064 4.2124 39.9502 4.69694 40.8164 5.48654C41.6826 6.27614 42.1016 7.30751 41.9832 8.35818L28.7866 107.009L18.4738 106.438L31.6704 7.78671Z" fill="#AA322A" stroke="#E03C31" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M3.65271 113.519H45.8949C46.6177 113.519 47.3109 113.188 47.822 112.598C48.3331 112.008 48.6202 111.209 48.6202 110.375V108.032C48.6202 106.364 48.046 104.764 47.0238 103.585C46.0016 102.405 44.6152 101.743 43.1696 101.743H6.37801C4.93242 101.743 3.54605 102.405 2.52386 103.585C1.50168 104.764 0.927402 106.364 0.927402 108.032V110.39C0.931004 111.222 1.21972 112.017 1.73043 112.604C2.24113 113.19 2.93227 113.519 3.65271 113.519Z" fill="#AA322A" stroke="#E03C31" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M64.5802 74.8327C64.2185 74.8305 63.8722 74.6633 63.617 74.3675C63.3618 74.0718 63.2184 73.6716 63.2181 73.2542V65.3007H48.071V73.2515C48.071 73.6701 47.9269 74.0716 47.6704 74.3676C47.4138 74.6636 47.0659 74.8299 46.7031 74.8299C43.5983 74.8299 40.6206 76.2529 38.4249 78.7858C36.2293 81.3187 34.9955 84.7542 34.9949 88.3367V117.715C34.9795 118.791 35.1502 119.86 35.4971 120.859C35.844 121.857 36.3601 122.766 37.0152 123.532C37.6703 124.298 38.4512 124.906 39.3122 125.319C40.1733 125.732 41.0971 125.943 42.0297 125.94H69.1626C71.0514 125.934 72.8612 125.065 74.1967 123.524C75.5322 121.983 76.2846 119.894 76.2896 117.715V88.3435C76.2896 84.7602 75.0559 81.3237 72.86 78.7899C70.6641 76.2561 67.6857 74.8327 64.5802 74.8327Z" fill="#7FC6A6" stroke="#1F87A7" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M65.9658 67.3913C65.964 67.7535 65.8379 68.1002 65.6153 68.3556C65.3926 68.611 65.0914 68.7544 64.7774 68.7543H46.5057C46.3501 68.7552 46.1958 68.7206 46.0518 68.6525C45.9078 68.5844 45.7769 68.4842 45.6666 68.3575C45.5562 68.2309 45.4687 68.0803 45.409 67.9145C45.3492 67.7487 45.3185 67.5708 45.3185 67.3913V60.167C45.3182 59.627 45.4101 59.0922 45.5891 58.5933C45.768 58.0944 46.0304 57.641 46.3613 57.2592C46.6922 56.8774 47.0851 56.5746 47.5176 56.3681C47.95 56.1616 48.4134 56.0556 48.8814 56.0559H62.4018C63.3468 56.0559 64.2532 56.489 64.9215 57.2599C65.5899 58.0309 65.9655 59.0765 65.9658 60.167V67.3913Z" fill="#FD414C" stroke="#1F87A7" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M52.2014 68.7612V56.0559" stroke="#1F87A7" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M59.0836 68.7612V56.0559" stroke="#1F87A7" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M34.9938 89.5886V114.296H54.4055C54.8072 114.296 55.205 114.204 55.5762 114.027C55.9473 113.85 56.2845 113.59 56.5686 113.262C56.8526 112.934 57.0778 112.545 57.2315 112.116C57.3851 111.688 57.4641 111.229 57.4639 110.765V93.1176C57.4639 92.1817 57.1417 91.2841 56.5681 90.6222C55.9946 89.9604 55.2166 89.5886 54.4055 89.5886H34.9938Z" fill="#4FB7D6" stroke="#1F87A7" strokeLinecap="round" strokeLinejoin="round"/>
        <Ellipse cx="54.9996" cy="47.8853" rx="6.13922" ry="6.13921" fill="#4FB7D6" fillOpacity="0.19"/>
        <Ellipse cx="66.0504" cy="31.9243" rx="9.82275" ry="9.82274" fill="#4FB7D6" fillOpacity="0.19"/>
        <Ellipse cx="46.4051" cy="14.7341" rx="14.7341" ry="14.7341" fill="#4FB7D6" fillOpacity="0.19"/>
    </Icon>
)
