import { useField } from '@codegateinc/react-form-builder-v2'
import { useTranslations } from 'lib/hooks'
import { NewPasswordFields } from './forms'

export const useNewPasswordForm = () => {
    const T = useTranslations()

    const newPassword = useField<string>({
        key: NewPasswordFields.NewPassword,
        initialValue: '',
        isRequired: true,
        validateOnBlur: true,
        placeholder: T.screens.authNewPassword.formFields.newPassword.placeholder,
        label: T.screens.authNewPassword.formFields.newPassword.label,
        validationRules: [
            {
                errorMessage: T.screens.authNewPassword.formFields.newPassword.validation.isRequired,
                validate: Boolean
            },
            {
                errorMessage: T.screens.auth.formFields.password.validation.isTooShort,
                validate: password => !password || password.length >= 10
            },
            {
                errorMessage: T.screens.auth.formFields.password.validation.missingUpperCase,
                validate: password => /[A-Z]/.test(password)
            },
            {
                errorMessage: T.screens.auth.formFields.password.validation.missingLowerCase,
                validate: password => /[a-z]/.test(password)
            },
            {
                errorMessage: T.screens.auth.formFields.password.validation.missingNumber,
                validate: password => /\d/.test(password)
            },
            {
                errorMessage: T.screens.auth.formFields.password.validation.missingSpecialChar,
                validate: password => /[^A-Za-z0-9]/.test(password) // This regex matches any character that is NOT alphanumeric
            }
        ]
    })

    const confirmNewPassword = useField<string>({
        key: NewPasswordFields.ConfirmNewPassword,
        initialValue: '',
        isRequired: true,
        validateOnBlur: true,
        placeholder: T.screens.authNewPassword.formFields.confirmPassword.placeholder,
        label: T.screens.authNewPassword.formFields.confirmPassword.label,
        validationRules: [
            {
                errorMessage: T.screens.authNewPassword.formFields.confirmPassword.validation.isRequired,
                validate: Boolean
            },
            {
                errorMessage: T.screens.authNewPassword.formFields.confirmPassword.validation.doesntMatch,
                validate: confirmPassword => confirmPassword === newPassword.value
            }
        ]
    })

    return {
        newPassword,
        confirmNewPassword
    }
}
