import React from 'react'
import { View } from 'react-native'
import { useIsWithinBreakpoints, useStyles } from 'lib/hooks'
import { dateHelpers } from 'lib/utils'
import { useJobStatus } from 'features/jobs'
import { Breakpoint, createStyles } from 'lib/styles'
import { Job, RecurringJob } from 'lib/models'
import { Label } from '../typography'

type BookingsCalendarEventTileProps = {
    job: Job,
    recurringJobs: Array<RecurringJob>
}

export const BookingsCalendarEventTile: React.FunctionComponent<BookingsCalendarEventTileProps> = ({
    job
}) => {
    const { styles } = useStyles(stylesheet)
    const { colors } = useJobStatus(job.status)
    const isMobile = useIsWithinBreakpoints(Breakpoint.XS, Breakpoint.MD)

    if (isMobile) {
        return (
            <View style={styles.container}>
                <View
                    style={{
                        ...styles.dot,
                        backgroundColor: colors.text
                    }}
                />
            </View>
        )
    }

    return (
        <View style={styles.container}>
            <View
                style={{
                    ...styles.dot,
                    backgroundColor: colors.text
                }}
            />
            <Label numberOfLines={1}>
                {dateHelpers.selectTimeLabel(job.date_time)}
            </Label>
            <View style={styles.flex}>
                <Label
                    bold
                    numberOfLines={1}
                >
                    {job.supply.name}
                </Label>
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    flex: {
        marginLeft: theme.utils.gap(1) / 2,
        flex: 1
    },
    dot: {
        width: {
            lg: 8,
            xs: 6
        },
        height: {
            lg: 8,
            xs: 6
        },
        borderRadius: 4,
        marginRight: {
            lg: theme.utils.gap(1) / 2,
            xs: undefined
        },
        marginHorizontal: {
            lg: undefined,
            xs: 1
        }
    },
    container: {
        maxWidth: '100%',
        alignItems: 'center',
        flexDirection: 'row',
        marginTop: {
            lg: theme.utils.gap(1),
            xs: 0
        }
    }
}))
