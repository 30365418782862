import React, { RefObject } from 'react'
import { Field } from '@codegateinc/react-form-builder-v2'
import { TextInput as NativeTextInput, TextInputProps as NativeTextInputProps, View } from 'react-native'
import { VoidFunction } from 'lib/types'
import { R } from 'lib/utils'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { FormComponents } from '../components'
import { KeyboardAccessoryFieldProps } from '../../reKeyboardAccessory'

interface TextInputProps extends KeyboardAccessoryFieldProps, Field<string> {
    ref?: RefObject<NativeTextInput>,
    disabled?: boolean,
    inputProps?: NativeTextInputProps,
    customStyles?: boolean,
    onFocus?: VoidFunction
}

export const ChatInput = React.forwardRef<NativeTextInput, TextInputProps>((
    {
        value,
        label,
        onBlur,
        disabled,
        placeholder,
        onChangeValue,
        errorMessage,
        inputProps = {},
        onFocus,
        customStyles = false,
        keyboardAccessory
    },
    ref
) => {
    const { styles, theme } = useStyles(stylesheet)

    return (
        <View
            style={styles.container}
            ref={keyboardAccessory?.fieldRef}
        >
            <FormComponents.ChatInput
                ref={ref || undefined}
                inputProps={{
                    value,
                    placeholder,
                    placeholderTextColor: theme.components.input.typography.placeholder,
                    editable: !disabled,
                    onChangeText: onChangeValue,
                    ...inputProps,
                    onFocus: event => {
                        const textInputProps = inputProps as NativeTextInputProps

                        R.ifDefined(onFocus, R.call)
                        R.ifDefined(textInputProps.onFocus, onFocus => onFocus(event))
                        R.ifDefined(keyboardAccessory?.events?.onFocus, R.call)
                    },
                    onBlur: event => {
                        const textInputProps = inputProps as NativeTextInputProps

                        R.ifDefined(textInputProps.onBlur, onBlur => onBlur(event))
                        R.ifDefined(onBlur, onBlur => onBlur(event))
                        R.ifDefined(keyboardAccessory?.events?.onBlur(), R.call)
                    }
                }}
            />
        </View>
    )
})

const stylesheet = createStyles(() => ({
    container: {
        width: '100%'
    }
}))
