import React from 'react'
import { Field } from '@codegateinc/react-form-builder-v2'
import { View } from 'react-native'
import { useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { CustomFonts, Nullable } from 'lib/types'
import { Adapter, Typography } from 'lib/components'

type BookingDateWeeklyServiceProps = {
    liteMode?: boolean,
    isLoading?: boolean,
    startingDate: string,
    timeDescription?: string,
    showSubscriptionLabel?: boolean,
    bookingDaysField: Field<Record<string, Nullable<string>>>
}

export const BookingDateWeeklyService: React.FunctionComponent<BookingDateWeeklyServiceProps> = ({
    isLoading,
    startingDate,
    timeDescription,
    bookingDaysField,
    showSubscriptionLabel,
    liteMode
}) => {
    const { styles } = useStyles(stylesheet)
    const T = useTranslations()

    return (
        <View style={liteMode ? styles.timeWithLiteMode : styles.time}>
            <View style={styles.selectInputWrapper}>
                {
                    showSubscriptionLabel ? (
                        <React.Fragment>
                            <Typography.Label bold style={styles.planHeading}>
                                {T.screens.jobDetails.rescheduleBooking.planHeading}
                            </Typography.Label>
                            <Typography.Label style={styles.planDescription}>
                                {T.screens.jobDetails.rescheduleBooking.planDescription}
                            </Typography.Label>
                        </React.Fragment>
                    ) : (
                        <Typography.Label style={styles.subTitle}>
                            {T.screens.jobDetails.rescheduleBooking.formSubTitle}
                        </Typography.Label>
                    )}

                <Adapter.HorizontalBookingDays
                    {...bookingDaysField}
                    startDate={startingDate}
                    disabled={Boolean(isLoading)}
                />

                <Adapter.BookingTimeSlots
                    {...bookingDaysField}
                    testID={T.accessibility.bookingDetailsScreen.reschedule.time}
                    key="bookingDaysTimeSlots"
                    startDate={startingDate}
                    disabled={Boolean(isLoading)}
                />

                {timeDescription && (
                    <Typography.Label style={liteMode ? styles.descriptionWithLiteMode : styles.description}>
                        {timeDescription}
                    </Typography.Label>
                )}
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    subTitle: {
        fontWeight: '500',
        fontFamily: CustomFonts.Roboto500,
        marginBottom: {
            lg: undefined,
            xs: theme.utils.gap(1)
        }
    },
    time: {
        flex: 1,
        minWidth: {
            lg: 300,
            xs: undefined
        },
        paddingTop: {
            lg: theme.utils.gap(2),
            xs: 0
        },
        justifyContent: 'space-between'
    },
    timeWithLiteMode: {
        flex: 1,
        minWidth: {
            lg: 300,
            xs: undefined
        },
        justifyContent: 'space-between'
    },
    selectInputWrapper: {
        paddingTop: theme.utils.gap(2.5)
    },
    description: {
        marginBottom: theme.utils.gap(2)
    },
    descriptionWithLiteMode: {
        marginBottom: theme.utils.gap(1)
    },
    planHeading: {
        color: theme.colors.night
    },
    planDescription: {
        color: theme.colors.mouse,
        marginTop: theme.utils.gap(0.5)
    }
}))
