import { useTranslations } from 'lib/hooks'

export const useShareFaq = () => {
    const T = useTranslations()

    return [
        {
            title: T.screens.referFriend.faq.tiles.whoCanRefer.title,
            description: T.screens.referFriend.faq.tiles.whoCanRefer.description
        },
        {
            title: T.screens.referFriend.faq.tiles.whatWillIEarn.title,
            description: T.screens.referFriend.faq.tiles.whatWillIEarn.description
        },
        {
            title: T.screens.referFriend.faq.tiles.expire.title,
            description: T.screens.referFriend.faq.tiles.expire.description
        },
        {
            title: T.screens.referFriend.faq.tiles.howCanIRedeem.title,
            description: T.screens.referFriend.faq.tiles.howCanIRedeem.description
        }
    ]
}
