import React from 'react'
import { View } from 'react-native'
import { Icons } from 'assets'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { Touchable, Typography } from 'lib/components'

type HandymanServiceItemProps = {
    label: string,
    isSelected: boolean,
    onPress: VoidFunction
}

export const HandymanServiceItem: React.FunctionComponent<HandymanServiceItemProps> = ({
    label,
    isSelected,
    onPress
}) => {
    const { styles, theme } = useStyles(stylesheet)

    return (
        <Touchable onPress={onPress}>
            <View
                style={isSelected
                    ? {
                        ...styles.item,
                        ...styles.selected
                    } : styles.item
                }
            >
                {isSelected && (
                    <View style={styles.checkIcon}>
                        <Icons.Check
                            size={15}
                            forceColor={theme.colors.orange}
                        />
                    </View>
                )}
                <Typography.Regular>
                    {label}
                </Typography.Regular>
            </View>
        </Touchable>
    )
}

const stylesheet = createStyles(theme => ({
    item: {
        flexDirection: 'row',
        alignItems: 'center',
        borderRadius: theme.components.input.borderRadius,
        borderWidth: theme.components.input.borderWidth,
        borderColor: theme.components.input.emptyBorderColor,
        paddingVertical: theme.utils.gap(1),
        paddingHorizontal: theme.utils.gap(3),
        marginBottom: theme.utils.gap(1),
        marginHorizontal: theme.utils.gap(1 / 2)
    },
    checkIcon: {
        left: theme.utils.gap(1),
        position: 'absolute'
    },
    selected: {
        backgroundColor: theme.colors.yellow,
        borderColor: theme.colors.orange
    }
}))
