import React from 'react'
import { View, ViewStyle } from 'react-native'
import { Typography } from 'lib/components'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'

type JobDetailsRowProps = {
    leftTitle?: string,
    leftMessage: string,
    rightTitle?: string,
    rightMessage?: string,
    style?: ViewStyle
}
export const JobDetailsRow: React.FunctionComponent<JobDetailsRowProps> = ({
    leftTitle,
    leftMessage,
    rightTitle,
    rightMessage,
    style
}) => {
    const { styles, theme } = useStyles(stylesheet)

    return (
        <View
            style={{
                ...styles.wrapper,
                ...style
            }}
        >
            <View style={styles.flex}>
                <View style={styles.title}>
                    <Typography.Label forceColor={theme.colors.mouse}>
                        {leftTitle}
                    </Typography.Label>
                </View>
                <Typography.Label>
                    {leftMessage}
                </Typography.Label>
            </View>
            {rightTitle && (
                <View style={styles.flex}>
                    <View style={styles.title}>
                        <Typography.Label forceColor={theme.colors.mouse}>
                            {rightTitle}
                        </Typography.Label>
                    </View>
                    <Typography.Label>
                        {rightMessage}
                    </Typography.Label>
                </View>
            )}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        flexDirection: 'row',
        marginBottom: {
            xl: theme.utils.gap(3),
            xs: 0
        }
    },
    title: {
        marginBottom: theme.utils.gap(1)
    },
    flex: {
        flex: 1,
        marginBottom: {
            xl: 0,
            xs: theme.utils.gap(3)
        }
    }
}))
