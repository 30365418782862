import React, { useState, useEffect } from 'react'
import { View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { CustomModal, Typography, Touchable } from 'lib/components'
import { Icons } from 'assets'
import { CustomFonts } from 'lib/types'
import { SubscribePromotionAction } from 'lib/types/promotion'
import { linkingHelpers } from 'lib/utils'
import { ENV } from 'lib/config'
import { ScreenNames } from 'lib/routing'
import {
    useAutoApplyPromoAtom,
    usePromotionAtom,
    useResetPromotionAtom,
    useResetPromotionPopupShownAtAtom,
    useShouldClearPromotionPopupShownAtAtom,
    useRequestEnableNotificationModalShowAtom
} from 'lib/atoms'
import { isNative } from 'lib/common'
import { VoucherInformation } from './VoucherInformation'

export const PromotionModal: React.FunctionComponent = () => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const navigation = useNavigation()

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
    const [promotionAction] = usePromotionAtom()
    const [, resetPromotionAction] = useResetPromotionAtom()
    const [, setAutoApplyPromo] = useAutoApplyPromoAtom()
    const [, resetPromotionPopupShownAtAtom] = useResetPromotionPopupShownAtAtom()
    const [shouldClearPromotionPopupShownAtAtom, setShouldClearPromotionPopupShownAtAtom] = useShouldClearPromotionPopupShownAtAtom()
    const [requestEnableNotificationModalShow] = useRequestEnableNotificationModalShowAtom()

    useEffect(() => {
        const shouldShowModal =
            promotionAction === SubscribePromotionAction.ShouldShowPromotionModal &&
            !requestEnableNotificationModalShow

        if (shouldShowModal) {
            setIsModalOpen(true)
            resetPromotionAction()
        }
    }, [promotionAction, requestEnableNotificationModalShow])

    const onPressBookingNow = () => {
        setAutoApplyPromo(T.components.promotionalModal.discountCode)
        setIsModalOpen(false)
        navigation.navigate(ScreenNames.AllServices)
    }

    return (
        <CustomModal
            isOpen={isModalOpen}
            onClose={() => {
                setIsModalOpen(false)

                if (shouldClearPromotionPopupShownAtAtom) {
                    setShouldClearPromotionPopupShownAtAtom(false)
                    resetPromotionPopupShownAtAtom()
                }

            }}
            contentStyles={styles.modalContent}
            childrenWrapper={styles.modalChildrenWrapper}
        >
            <View style={styles.header}>
                <View style={styles.gifContainer}>
                    <Icons.GiftBox size={120} viewBox="0 0 120 120"/>
                </View>
            </View>
            <View style={styles.body}>
                <View style={styles.row}>
                    <Typography.Title style={styles.title}>{T.components.promotionalModal.title}</Typography.Title>
                    <Typography.Regular style={styles.description}>{T.components.promotionalModal.description}</Typography.Regular>
                </View>
                <View style={styles.row}>
                    <VoucherInformation onPressBookingNow={onPressBookingNow}/>
                </View>
            </View>
            <View style={styles.footer}>
                <Touchable
                    onPress={() => linkingHelpers.openUrl(`${ENV.SENDHELPER_MARKETING_SITE_URL}/blog/welcome-offer-get-50-discount`)}
                    style={styles.termsAndConditionButton}>
                    <Typography.Regular style={styles.termsAndCondition}>{T.components.promotionalModal.termsAndCondition}</Typography.Regular>
                </Touchable>
            </View>
        </CustomModal>
    )
}

const stylesheet = createStyles(theme => ({
    header: {
        paddingBottom: {
            lg: theme.utils.gap(1),
            xs: theme.utils.gap(0)
        },
        position: 'relative'
    },
    body: {
        alignItems: 'center'
    },
    footer: {
        alignItems: 'center'
    },
    row: {
        alignItems: 'center'
    },
    title: {
        fontFamily: CustomFonts.Poppins600,
        fontSize: 21,
        textTransform: 'capitalize',
        color: theme.colors.night,
        textAlign: 'center',
        paddingTop: theme.utils.gap(0.25),
        marginBottom: theme.utils.gap(1.5)
    },
    description: {
        fontFamily: CustomFonts.Roboto400,
        fontSize: 16,
        color: theme.colors.darkSlate,
        textAlign: 'center',
        marginBottom: theme.utils.gap(2.75)
    },
    termsAndConditionButton: {
        backgroundColor: theme.colors.transparent,
        userSelect: 'none'
    },
    termsAndCondition: {
        fontFamily: CustomFonts.Roboto400,
        fontSize: 13,
        color: theme.colors.grey
    },
    gifContainer: {
        width: {
            lg: 120,
            xs: 100
        },
        height: {
            lg: 120,
            xs: 100
        },
        backgroundColor: theme.colors.white,
        borderRadius:{
            lg: 60,
            xs: 50
        },
        position: 'absolute',
        left: '50%',
        top: {
            lg: -50,
            xs: isNative ? -55 : -45
        },
        marginTop: {
            lg: -55,
            xs: -50
        },
        marginLeft: {
            lg: -55,
            xs: -50
        }
    },
    modalContent: {
        paddingBottom: theme.utils.gap(3),
        width: '100%'
    },
    modalChildrenWrapper: {
        maxWidth: 700
    }
}))
