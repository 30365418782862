import React from 'react'
import { Icons } from 'assets'
import { View } from 'react-native'
import { createStyles, theme } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { Typography } from 'lib/components'
import { dataTypeHelper, dateHelpers } from 'lib/utils'
import { BookingFrequency, DayOfWeekMapping, JobSchedule } from 'lib/types'
import { PlanRescheduleRequest } from 'lib/models/Job'

type SubscriptionBookingTimeProps = {
    frequency: BookingFrequency,
    rescheduleRequest: PlanRescheduleRequest
    isCurrentTime?: boolean
}

export const SubscriptionBookingTime: React.FunctionComponent<SubscriptionBookingTimeProps> = ({
    frequency,
    isCurrentTime,
    rescheduleRequest
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const forceColor = isCurrentTime ? theme.colors.mouse : theme.colors.orange

    const frequencyText = frequency === BookingFrequency.Weekly ?
        T.screens.jobDetails.rescheduleRequestSend.weeklyRepeat :
        T.screens.jobDetails.rescheduleRequestSend.fortnightlyRepeat

    const beginningDate = isCurrentTime ?
        rescheduleRequest.popup.currentBeginingDate :
        rescheduleRequest.popup.requestedBeginingDate

    const generateCurrentRepeatDays = () => {
        const activatedSchedule = rescheduleRequest.popup.currentSchedule?.filter(item => item.activated) || []
        const lengthOfDays = activatedSchedule.length

        return (
            <Typography.Regular>
                {activatedSchedule.map((day, i) => {
                    const isLastElement = i + 1 === lengthOfDays
                    const isPrevLastElement = i + 2 === lengthOfDays
                    const separatorChar = isPrevLastElement || isLastElement ? ' ' : ', '
                    const prefixChar = isLastElement ? lengthOfDays > 1 ? 'and ' : '' : ''

                    return (
                        <React.Fragment key={day.day}>
                            <Typography.Regular>
                                <Typography.Regular style={styles.frequencyNote}>{prefixChar}</Typography.Regular>
                                <Typography.Regular style={styles.repeatDay}>{DayOfWeekMapping[day.day]}</Typography.Regular>{separatorChar}
                            </Typography.Regular>
                        </React.Fragment>
                    )
                })}
            </Typography.Regular>
        )
    }

    const generateRescheduledRepeatDaysByObjectFormat = () => {
        const requestedSchedule = rescheduleRequest.popup.requestedSchedule || {}
        const filteredKeys = Object.keys(requestedSchedule)
            .filter(key => requestedSchedule[key] !== null && requestedSchedule[key] !== undefined)
        const lengthOfDays = filteredKeys.length

        return (
            <Typography.Regular>
                {filteredKeys.map((day, i) => {
                    const isLastElement = i + 1 === lengthOfDays
                    const isPrevLastElement = i + 2 === lengthOfDays
                    const separatorChar = isPrevLastElement || isLastElement ? ' ' : ', '
                    const prefixChar = isLastElement ? lengthOfDays > 1 ? 'and ' : '' : ''

                    return (
                        <React.Fragment key={day}>
                            <Typography.Regular>
                                <Typography.Regular style={styles.frequencyNote}>{prefixChar}</Typography.Regular>
                                <Typography.Regular style={styles.repeatDay}>{day}</Typography.Regular>{separatorChar}
                            </Typography.Regular>
                        </React.Fragment>
                    )
                })}
            </Typography.Regular>
        )
    }

    const generateRescheduledRepeatDaysByArrayFormat = () => {
        // eslint-disable-next-line @typescript-eslint/ban-types
        const requestedSchedule = (rescheduleRequest.popup.requestedSchedule || []) as unknown as Array<JobSchedule>
        const activatedSchedule = requestedSchedule?.filter(item => item.activated) || []
        const lengthOfDays = activatedSchedule.length

        return (
            <Typography.Regular>
                {activatedSchedule.map((day, i) => {
                    const isLastElement = i + 1 === lengthOfDays
                    const isPrevLastElement = i + 2 === lengthOfDays
                    const separatorChar = isPrevLastElement || isLastElement ? ' ' : ', '
                    const prefixChar = isLastElement ? lengthOfDays > 1 ? 'and ' : '' : ''

                    return (
                        <React.Fragment key={day.day}>
                            <Typography.Regular>
                                <Typography.Regular style={styles.frequencyNote}>{prefixChar}</Typography.Regular>
                                <Typography.Regular style={styles.repeatDay}>{DayOfWeekMapping[day.day]}</Typography.Regular>{separatorChar}
                            </Typography.Regular>
                        </React.Fragment>
                    )
                })}
            </Typography.Regular>
        )
    }

    const generateRescheduledRepeatDays = () => {
        const requestedSchedule = rescheduleRequest.popup.requestedSchedule || {}

        if (dataTypeHelper.isObject(requestedSchedule)) {
            return generateRescheduledRepeatDaysByObjectFormat()
        }

        if (dataTypeHelper.isArray(requestedSchedule)) {
            return generateRescheduledRepeatDaysByArrayFormat()
        }

        return null
    }

    return (
        <View style={styles.datetimeWrapper}>
            <View style={styles.rescheduleTimeContainer}>
                <View style={styles.datetimeContainer}>
                    <Icons.DateAndTime
                        size={24}
                        forceColor={forceColor}
                    />
                    <Typography.Regular forceColor={forceColor}>{` ${T.common.start}: `}
                        <Typography.Regular style={styles.datetimeText} testID={
                            isCurrentTime ?
                                T.accessibility.jobDetailsScreen.rescheduleRequestDetail.currentSubscriptionDate :
                                T.accessibility.jobDetailsScreen.rescheduleRequestDetail.requestedSubscriptionDate
                        }>
                            {beginningDate && dateHelpers.bookingFullDate(beginningDate)}
                        </Typography.Regular>
                    </Typography.Regular>
                </View>
                <View style={styles.spacer}/>
                <View style={styles.datetimeContainer}>
                    <Icons.Clock
                        size={24}
                        forceColor={forceColor}
                        viewBox="0 0 25 25"
                    />
                    <Typography.Regular style={styles.datetimeText} testID={
                        isCurrentTime ?
                            T.accessibility.jobDetailsScreen.rescheduleRequestDetail.currentSubscriptionTime :
                            T.accessibility.jobDetailsScreen.rescheduleRequestDetail.requestedSubscriptionTime
                    }>
                        {beginningDate && dateHelpers.selectTimeLabel(beginningDate)}
                    </Typography.Regular>
                </View>
            </View>
            <View>
                <Typography.Regular style={styles.frequencyNote}>
                    {`${frequencyText} `}
                    {
                        isCurrentTime ?
                            generateCurrentRepeatDays() :
                            generateRescheduledRepeatDays()
                    }
                </Typography.Regular>
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    frequencyNote: {
        color: theme.colors.mouse,
        marginTop: theme.utils.gap(1),
        fontSize: 13
    },
    repeatDay: {
        fontWeight: 'bold',
        fontSize: 13,
        color: theme.colors.darkGrey
    },
    datetimeWrapper: {
        borderRadius: 8,
        backgroundColor: theme.colors.latte,
        padding: theme.utils.gap(1.5),
        marginTop: theme.utils.gap(1.5),
        marginBottom: theme.utils.gap(2),
        alignItems: 'center',
        width: {
            xs: '100%',
            md: undefined
        }
    },
    rescheduleTimeContainer: {
        flex: 1,
        flexDirection: {
            md: 'row',
            xs: 'column'
        },
        justifyContent: 'center'
    },
    datetimeContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: theme.utils.gap(1)
    },
    datetimeText: {
        marginLeft: theme.utils.gap(1),
        fontWeight: '600'
    },
    startText: {
        marginRight: theme.utils.gap(0.5),
        color: theme.colors.orange
    },
    spacer: {
        height: {
            md: 0,
            xs: theme.utils.gap(1)
        }
    }
}))
