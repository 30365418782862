import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const Calendar: React.FunctionComponent<IconProps> = props => (
    <Icon
        {...props}
        forceColor="transparent"
    >
        <Path
            d="M4 31.63h70v38.685A3.684 3.684 0 0 1 70.316 74H7.684A3.684 3.684 0 0 1 4 70.315V31.631Z"
            stroke={props.forceColor}
            strokeWidth={9}
            strokeLinejoin="round"
        />
        <Path
            d="M4 13.216A3.684 3.684 0 0 1 7.684 9.53h62.632A3.684 3.684 0 0 1 74 13.215v18.422H4V13.214Z"
            stroke={props.forceColor}
            strokeWidth={9}
            strokeLinejoin="round"
        />
        <Path
            d="M24.262 4v14.737M53.736 4v14.737M46.366 59.266h11.052M20.579 59.266H31.63M46.366 44.525h11.052M20.579 44.525H31.63"
            stroke={props.forceColor}
            strokeWidth={9}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Icon>
)
