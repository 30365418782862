import React from 'react'
import { View } from 'react-native'
import { createStyles } from 'lib/styles'
import { Typography } from 'lib/components'
import { usePrice, useStyles } from 'lib/hooks'
import { JobDetailsResponse } from '../types'

type SessionDetailsTileProps = {
    job: JobDetailsResponse
}

export const SessionDetailsTile: React.FunctionComponent<SessionDetailsTileProps> = ({
    job
}) => {
    const { styles } = useStyles(stylesheet)
    const price = usePrice(job ? job.job.bookingSummary.total.value : 0)

    if (!job) {
        return null
    }

    return (
        <View style={styles.row}>
            <Typography.Regular>
                {job.job.job_id}
            </Typography.Regular>
            <Typography.Regular bold>
                {price}
            </Typography.Regular>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: theme.utils.gap(2)
    }
}))
