import React from 'react'
import { TextInput, TextInputProps, View, ViewStyle } from 'react-native'
import { IconProps } from 'lib/types'
import { Typography } from 'lib/components'
import { ErrorMessage } from './ErrorMessage'
import { useTextInput } from './hooks'

type InputProps = {
    label?: string,
    testID?: string,
    controlledValue?: string,
    errorMessage?: string,
    customStyles: boolean,
    hideErrorMessage?: boolean,
    extraContent?: string,
    inputProps: TextInputProps,
    errorContentStyles?: ViewStyle,
    leftIcon?: React.FunctionComponent<IconProps>,
    rightIcon?: React.FunctionComponent<IconProps>,
    onDebounceEnd?(inputValue: string): void
}

export const Input = React.forwardRef<TextInput, InputProps>((props, ref) => {
    const {
        styles,
        inputProps,
        errorMessage,
        renderLeftIcon,
        renderRightIcon,
        renderClearButton,
        renderPreviewPasswordButton
    } = useTextInput(props)

    const { hideErrorMessage, errorContentStyles = {} } = props

    return (
        <View style={styles.container}>
            {props.extraContent && (
                <View
                    focusable={false}
                    style={{
                        ...inputProps.style,
                        ...styles.extraContent
                    }}
                >
                    <Typography.Regular>
                        {props.extraContent}
                    </Typography.Regular>
                </View>
            )}
            <View style={styles.container}>
                <TextInput
                    ref={ref}
                    data-testid={props.testID}
                    accessibilityLabel={props.testID}
                    {...inputProps as TextInputProps}
                />
                {renderLeftIcon()}
                {renderRightIcon()}
                {renderClearButton()}
                {renderPreviewPasswordButton()}
                <ErrorMessage
                    renderHTMLContent
                    text={hideErrorMessage ? undefined : errorMessage}
                    contentStyles={errorContentStyles}
                />
            </View>
        </View>
    )
})
