import { useTranslations } from './useTranslations'
import { linkingHelpers } from '../utils'
import { ENV } from '../config'

export const useHeaderLinks = () => {
    const T = useTranslations()

    return [
        {
            text: T.headerLinks.ourServices,
            withChevron: true,
            onPress: () => linkingHelpers.openUrl(`${ENV.SENDHELPER_MARKETING_SITE_URL}`, false)
        },
        {
            text: T.headerLinks.blog,
            onPress: () => linkingHelpers.openUrl(`${ENV.SENDHELPER_MARKETING_SITE_URL}/blog`, false)
        },
        {
            text: T.headerLinks.becomePartner,
            onPress: () => linkingHelpers.openUrl(`${ENV.SENDHELPER_MARKETING_SITE_URL}/partner`, false)
        },
        {
            text: T.headerLinks.contactUs,
            onPress: () => linkingHelpers.openUrl(`${ENV.SENDHELPER_MARKETING_SITE_URL}/contacts`, false)
        }
    ]
}
