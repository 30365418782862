import React from 'react'
import { View } from 'react-native'
import { Icons } from 'assets'
import { createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { Typography } from 'lib/components'

type PrimaryAddressBadgeProps = {
    isPrimary: boolean
}

export const PrimaryAddressBadge: React.FunctionComponent<PrimaryAddressBadgeProps> = ({
    isPrimary
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)

    return (
        <View style={styles.row}>
            {isPrimary && (
                <View style={styles.primaryBadge}>
                    <Typography.Label forceColor={theme.colors.orange}>
                        {T.common.primary}
                    </Typography.Label>
                </View>
            )}
            {/* todo: for now not supported */}
            {/* {!isPrimary && ( */}
            {/*     <Touchable */}
            {/*         onPress={onSetAsPrimary} */}
            {/*         style={styles.primaryButton} */}
            {/*     > */}
            {/*         <Typography.Label forceColor={theme.colors.orange}> */}
            {/*             {T.common.setAsPrimary} */}
            {/*         </Typography.Label> */}
            {/*     </Touchable> */}
            {/* )} */}
            <Icons.Triangle size={10} />
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    row: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    primaryButton: {
        borderBottomWidth: 1,
        marginRight: {
            lg: theme.utils.gap(6),
            xs: theme.utils.gap(1)
        },
        borderBottomColor: theme.colors.orange
    },
    primaryBadge: {
        backgroundColor: theme.colors.navajo,
        marginRight: {
            lg: theme.utils.gap(3),
            xs: theme.utils.gap(1)
        },
        paddingVertical: theme.utils.gap(1) / 2,
        paddingHorizontal: theme.utils.gap(4),
        borderRadius: 4
    }
}))
