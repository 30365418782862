import { SectionList, SectionListData, View } from 'react-native'
import React, { LegacyRef, useRef } from 'react'
import { Typography } from 'lib/components'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { ItemPrices, LaundryService } from '../types'
import { LaundryItemPriceTile } from './LaundryItemPriceTile'

type LaundryPriceListProps = {
    sections: Array<SectionListData<ItemPrices>>,
    activeService: LaundryService
}

export const LaundryPriceList: React.FunctionComponent<LaundryPriceListProps> = ({
    sections,
    activeService
}) => {
    const { styles } = useStyles(stylesheet)
    const listRef = useRef<SectionList>()

    return (
        <SectionList
            ref={listRef as LegacyRef<SectionList>}
            style={styles.list}
            sections={sections}
            showsVerticalScrollIndicator={false}
            renderItem={({ item }) => {
                const [matchingService] = item.sub_content

                return matchingService
                    ? (
                        <LaundryItemPriceTile
                            price={matchingService?.amount}
                            title={item.sub_title}
                        />
                    )
                    : null
            }}
            renderSectionHeader={({ section }) => (activeService as LaundryService) !== LaundryService.LoadWash
                ? (
                    <View style={styles.sectionHeader}>
                        <Typography.Regular bold>
                            {section?.title}
                        </Typography.Regular>
                    </View>
                )
                : null
            }
            renderSectionFooter={() => (
                <View style={styles.sectionFooter}/>
            )}
        />
    )
}

const stylesheet = createStyles(theme => ({
    listContainer: {
        flex: 1
    },
    list: {
        flex: 1,
        height: 340,
        paddingHorizontal: {
            lg: theme.utils.gap(3),
            xs: undefined
        }
    },
    sectionHeader: {
        borderBottomWidth: 1,
        backgroundColor: theme.colors.white,
        borderBottomColor: theme.colors.silver,
        paddingBottom: {
            lg: theme.utils.gap(1),
            xs: theme.utils.gap(0.5)
        }
    },
    sectionFooter: {
        paddingBottom: {
            lg: theme.utils.gap(4),
            xs: theme.utils.gap(2)
        }
    }
}))
