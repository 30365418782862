import React from 'react'
import { Icons } from 'assets'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { Touchable } from '../Touchable'

type HeaderUserIconProps = {
    onPress?: VoidFunction
}

export const HeaderUserIcon: React.FunctionComponent<HeaderUserIconProps> = ({ onPress }) => {
    const { styles } = useStyles(stylesheet)

    return (
        <Touchable
            style={styles.container}
            onPress={onPress}
        >
            <Icons.User size={18} />
        </Touchable>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        height: '100%',
        justifyContent: 'center',
        borderLeftWidth: 1,
        borderColor: theme.colors.silver,
        paddingHorizontal: theme.utils.gap(3)
    }
}))
