export enum SupplyID {
    Cleaner = 0,
    Cooking = 1,
    Laundry = 2,
    Handyman = 3,
    ACService = 4,
    DeepCleaning = 5,
    ElderlyCare = 6,
    LeatherCleaning = 7,
    PestControl = 8,
    TasksErrands = 9,
    MoversPackers = 10,
    Disinfection = 14
}
