import React, { useRef } from 'react'
import { useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { Icons } from 'assets'
import { Touchable } from 'lib/components'
import { useToastAtom } from 'lib/atoms'
import { NotificationType } from 'lib/types'
import { ENV } from 'lib/config'

type ChatImagePickerButtonProps = {
    disabled: boolean,
    onSelectedImage(image: File): void
}

export const ChatImagePickerButton: React.FunctionComponent<ChatImagePickerButtonProps> = ({
    disabled,
    onSelectedImage
}) => {
    const T = useTranslations()
    const [, setToastMessage] = useToastAtom()
    const { styles } = useStyles(stylesheet)
    const inputRef = useRef<HTMLInputElement>()

    return (
        <Touchable
            disabled={disabled}
            onPress={() => inputRef.current?.click()}
            style={styles.selectImageButton}
        >
            <input
                // @ts-ignore
                ref={inputRef}
                type="file"
                accept="image/*"
                onChange={event => {
                    if (!event.target.files?.item(0)) {
                        return
                    }

                    // can't be destructured
                    const file = event.target.files[0]

                    if (file.size > Number(ENV.MAX_IMAGE_UPLOAD_SIZE_MB) * 1024 * 1024) {
                        return setToastMessage({
                            type: NotificationType.Error,
                            message: `${T.screens.chat.imageUploadSizeError} ${Number(ENV.MAX_IMAGE_UPLOAD_SIZE_MB)} ${T.common.megabytes}`
                        })
                    }

                    onSelectedImage(event.target.files[0])
                    inputRef.current!.value = ''
                }}
                style={styles.filePicker}
            />
            <Icons.Clip size={20} />
        </Touchable>
    )
}

const stylesheet = createStyles(theme => ({
    selectImageButton: {
        alignItems: 'flex-start',
        paddingVertical: theme.utils.gap(1),
        marginRight: theme.utils.gap(2)
    },
    filePicker: {
        display: 'none'
    }
}))
