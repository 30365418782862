import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const Booking: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path
            fillRule="evenodd"
            d="M26.667 44.444V40c0-7.351 5.982-13.333 13.333-13.333h4.444V11.853A2.967 2.967 0 0 0 41.48 8.89H11.853a2.967 2.967 0 0 0-2.964 2.964V41.48a2.967 2.967 0 0 0 2.964 2.964h14.814Zm0 8.89H11.853C5.316 53.333 0 48.017 0 41.48V11.853C0 5.316 5.316 0 11.853 0H41.48c6.538 0 11.853 5.316 11.853 11.853v14.814h13.334C74.017 26.667 80 32.649 80 40v26.667C80 74.017 74.018 80 66.667 80H40c-7.351 0-13.333-5.982-13.333-13.333V53.333ZM35.556 40A4.452 4.452 0 0 1 40 35.556h26.667A4.449 4.449 0 0 1 71.11 40v26.667a4.449 4.449 0 0 1-4.444 4.444H40a4.452 4.452 0 0 1-4.444-4.444V40Z"
        />
    </Icon>
)
