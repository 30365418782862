import { ScreenNames } from 'lib/routing'
import { useTranslations } from 'lib/hooks'

export const useAirConditionBreadCrumbs = () => {
    const T = useTranslations()

    return [
        {
            text: T.screens.airCondition.breadCrumbs.requirements,
            screenName: ScreenNames.AirCondition
        },
        {
            text: T.screens.airCondition.breadCrumbs.confirmation,
            screenName: ScreenNames.AirConditionSummary
        }
    ]
}
