import React, { useState } from 'react'
import { Icons } from 'assets'
import { View, Platform } from 'react-native'
import Animated, { useAnimatedStyle, useDerivedValue, withTiming } from 'react-native-reanimated'
import { createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { Touchable, Typography } from 'lib/components'
import { CustomFonts, ErrorResponse, NotificationType } from 'lib/types'
import { ExtraCancellationReasonId } from 'features/bookings'
import { useCancelReasonAtom, useToastAtom, useUserAtom, useUserTokenAtom } from 'lib/atoms'
import { cancelReasonHelpers, emailTemplateHelper, getAppVersionForPlatform, jobHelpers } from 'lib/utils'
import { cancelJob, getJobDetails } from 'features/jobs/actions'
import { contactUs } from 'features/contact/actions'
import { RequestType } from 'features/contact/types'
import { JobDetailsResponse, TypeOfCancelJob } from 'features/jobs/types'
import { SubscriptionCancelSuccessModal } from 'features/jobs/components/subscriptionCancelSuccessModal'
import { PaymentSummaryModal } from './PaymentSummaryModal'
import { CancelRequestSendModal } from '../cancelRequestSendModal'

type CancelJobActionsProps = {
    jobDetail: JobDetailsResponse,
    upcomingSession?: JobDetailsResponse,
    cancellationReasonId: number,
    otherReason: string,
    handleGoBack: VoidFunction,
    resetState: VoidFunction,
    showAsFloating?: boolean,
    cancelSubscriptionType?: TypeOfCancelJob
}

export const CancelJobActions: React.FunctionComponent<CancelJobActionsProps> = ({
    jobDetail,
    upcomingSession,
    handleGoBack,
    showAsFloating,
    cancellationReasonId,
    otherReason,
    resetState,
    cancelSubscriptionType
}) => {
    const T = useTranslations()
    const [isSubscriptionCancelSuccessModalOpen, setIsSubscriptionCancelSuccessModalOpen] = useState<boolean>(false)
    const [isAdditionFeeModalShow, setIsAdditionFeeModalShow] = useState<boolean>(false)
    const [token] = useUserTokenAtom()
    const [user] = useUserAtom()
    const [cancellationOptions] = useCancelReasonAtom()
    const [, setToastMessage] = useToastAtom()
    const { styles, theme } = useStyles(stylesheet)
    const { mutate: onJobCancel, isLoading: isMutating } = cancelJob()
    const { refetch: refetchJobDetails, isLoading: isRefetchingJobDetails } = getJobDetails(jobDetail?.job?._id)
    const { mutate: sendContact, isLoading: isContactLoading } = contactUs()
    const additionalFee = jobHelpers.getPolicyCancellationFee(jobDetail)
    const rotateZ = useDerivedValue(() => withTiming(isAdditionFeeModalShow ? 0 : -180))
    const animatedRotateStyle = useAnimatedStyle(() => ({
        transform: [{ rotateZ: `${rotateZ.value}deg` }]
    }))
    const cancellationOption = cancellationOptions.find(option => option.id === cancellationReasonId) ||  ''
    const cancelReason = cancelReasonHelpers.getCancelReason(JSON.stringify(cancellationOption), otherReason)
    const cancelSubscriptionTypes = cancelReasonHelpers.getCancelSubscriptionTypes(jobDetail)
    const shouldShowTypeOfCancelSchedule = jobHelpers.isAccepted(jobDetail) || jobHelpers.isPaused(jobDetail)
    const shouldSendRequestCancelJob =
        (shouldShowTypeOfCancelSchedule && cancelSubscriptionType?.value === cancelSubscriptionTypes?.[0]?.value) ||
        (jobHelpers.isRecurringAccepted(jobDetail) && jobHelpers.isPackage(jobDetail))

    const generateCancelPayload = () => ({
        id: jobDetail.job._id,
        token,
        job_type: jobDetail.job.job_type,
        cancel_reason: cancelReason
    })

    const onSendRequestSuccess = () => {
        resetState()
        refetchJobDetails()
        setIsSubscriptionCancelSuccessModalOpen(true)
    }

    const onSendRequestFail = (error: ErrorResponse) => {
        setToastMessage({
            message: error?.errors?.error_msg || T.screens.cancelJob.cancelJobFailed,
            type: NotificationType.Error
        })
    }

    const sendRequestCancelJob = () => {
        sendContact(
            {
                token,
                profile: user,
                contents: `
${emailTemplateHelper.getFinishSessionAndDontRenewPackageMessage(jobDetail, cancelReason, otherReason)}
${Platform.OS} - ${getAppVersionForPlatform()} ${token}`,
                requestType: RequestType.Cancel,
                jobId: jobDetail.job._id
            },
            {
                onSuccess: () => {
                    onSendRequestSuccess()
                },
                onError: error => {
                    onSendRequestFail(error)
                }
            }
        )
    }

    const handleCancelJob = () => {
        if (shouldSendRequestCancelJob) {
            sendRequestCancelJob()

            return
        }

        onJobCancel(
            generateCancelPayload(),
            {
                onSuccess: () => {
                    onSendRequestSuccess()
                },
                onError: error => {
                    onSendRequestFail(error)
                }
            }
        )
    }

    const shouldDisableCancelButton =
        isMutating ||
        isContactLoading ||
        (cancellationReasonId === ExtraCancellationReasonId.NonSelect) ||
        (cancellationReasonId === ExtraCancellationReasonId.Other && !otherReason.trim())

    const shouldShowAdditionFee =
        showAsFloating &&
        additionalFee &&
        cancelSubscriptionType?.value === cancelSubscriptionTypes?.[1]?.value

    return (
        <>
            <PaymentSummaryModal
                jobDetail={jobDetail}
                upcomingSession={upcomingSession}
                isAdditionFeeModalShow={isAdditionFeeModalShow}
                setIsAdditionFeeModalShow={setIsAdditionFeeModalShow}
            />

            <View style={showAsFloating ? styles.floatingMainContainer : styles.mainContainer}>
                {
                    shouldShowAdditionFee && (
                        <View style={styles.additionalFeeContainer}>
                            <View style={styles.cancellationFeeContainer}>
                                <Typography.Regular forceColor={theme.colors.strawberry}>
                                    {T.screens.cancelJob.cancellationFee}
                                </Typography.Regular>
                                <Typography.Subheading testID={T.accessibility.cancelJobScreen.fee}>
                                    {additionalFee}
                                </Typography.Subheading>
                            </View>
                            <Touchable onPress={() => setIsAdditionFeeModalShow(true)} style={styles.viewDetailButton}>
                                <Typography.Regular style={styles.viewDetailButtonText}>
                                    {T.screens.cancelJob.viewDetails}
                                </Typography.Regular>
                                <Animated.View style={animatedRotateStyle}>
                                    <Icons.Chevron size={10} />
                                </Animated.View>
                            </Touchable>
                        </View>
                    )
                }
                <View style={styles.actionsContainer}>
                    <Touchable
                        testID={T.accessibility.cancelJobScreen.keepPlan}
                        disabled={isMutating || isContactLoading}
                        onPress={handleGoBack}
                        style={{
                            ...styles.button,
                            ...styles.keepPlanButton
                        }}>
                        <Typography.Regular style={styles.keepPlanButtonText}>
                            {T.screens.cancelJob.keepMyPlan}
                        </Typography.Regular>
                    </Touchable>
                    <View style={styles.spacer} />
                    <Touchable
                        testID={T.accessibility.cancelJobScreen.cancel}
                        disabled={shouldDisableCancelButton}
                        onPress={handleCancelJob}
                        style={{
                            ...styles.button,
                            ...(shouldDisableCancelButton ? styles.disableButton : styles.cancelPlanButton)
                        }}>
                        <Typography.Regular style={shouldDisableCancelButton ? styles.disableButtonText : styles.cancelPlanButtonText}>
                            {T.screens.cancelJob.cancelPlan}
                        </Typography.Regular>
                    </Touchable>
                </View>
            </View>

            {
                shouldSendRequestCancelJob ?
                    (
                        <CancelRequestSendModal
                            isLoading={isRefetchingJobDetails}
                            isOpen={isSubscriptionCancelSuccessModalOpen}
                            onClose={() => {
                                setIsSubscriptionCancelSuccessModalOpen(false)
                                handleGoBack()
                            }}
                        />
                    ) : (
                        <SubscriptionCancelSuccessModal
                            jobDetail={jobDetail}
                            isLoading={isRefetchingJobDetails}
                            isOpen={isSubscriptionCancelSuccessModalOpen}
                            onClose={() => {
                                setIsSubscriptionCancelSuccessModalOpen(false)
                                handleGoBack()
                            }}
                        />
                    )
            }
        </>
    )
}

const stylesheet = createStyles(theme => ({
    actionsContainer: {
        flexDirection: 'row'
    },
    mainContainer: {
        flexDirection: 'column'
    },
    floatingMainContainer: {
        flexDirection: 'column',
        paddingHorizontal: theme.utils.gap(2),
        paddingVertical: theme.utils.gap(2),
        backgroundColor: theme.colors.white,
        borderTopLeftRadius: 12,
        borderTopRightRadius: 12,
        ...theme.utils.createShadow(0, 1, 20, 70)
    },
    button: {
        borderWidth: 2,
        borderColor: theme.colors.orange,
        borderRadius: 4,
        flex: 1,
        paddingHorizontal: theme.utils.gap(2),
        paddingVertical: theme.utils.gap(1.25),
        justifyContent: 'center',
        alignItems: 'center'
    },
    keepPlanButton: {
        backgroundColor: theme.colors.white
    },
    cancelPlanButton: {
        backgroundColor: theme.colors.sun,
        borderColor: theme.colors.sun
    },
    spacer: {
        width: theme.utils.gap(2),
        height: theme.utils.gap(2)
    },
    keepPlanButtonText: {
        fontFamily: CustomFonts.Poppins400,
        color: theme.colors.orange,
        fontSize: 14
    },
    cancelPlanButtonText: {
        fontFamily: CustomFonts.Poppins400,
        color: theme.colors.darkSlate,
        fontSize: 14
    },
    disableButton: {
        backgroundColor: theme.colors.silver,
        borderColor: theme.colors.silver
    },
    disableButtonText: {
        color: theme.colors.fog
    },
    additionalFeeContainer: {
        marginBottom: theme.utils.gap(2),
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    cancellationFeeContainer: {
        flexDirection: 'column'
    },
    viewDetailButton: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    viewDetailButtonText: {
        marginRight: theme.utils.gap(0.5)
    }
}))
