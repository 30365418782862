import { useTranslations } from './useTranslations'

export const useMockedHours = (from: number, to: number) => {
    const T = useTranslations()

    return [...new Array((to - from) * 2)].map((item, index) => {
        const number = from + ((index + 1) / 2)

        return {
            value: `${number}`,
            label: `${number} ${number === 1 ? T.common.hour : T.common.hours.toLowerCase()}`
        }
    })
}
