import { useMemo } from 'react'
import { useLocalizedDates } from 'lib/hooks'

export const useBookingDays = () => {
    const { getWeekDayFullNames } = useLocalizedDates()

    return useMemo((() => getWeekDayFullNames().reduce((acc, nextValue) => ({
        ...acc,
        [nextValue]: null
    }), {})), [])
}
