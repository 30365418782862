import React from 'react'
import { View } from 'react-native'
import { createStyles, theme } from 'lib/styles'
import { useBookingCalendarPickerValueFormat, useStyles, useTranslations } from 'lib/hooks'
import { CustomModal, Button, Typography } from 'lib/components'
import { Icons } from 'assets'
import { RescheduleFormShape } from 'features/jobs/forms'
import { BookingFrequency, CustomFonts } from 'lib/types'
import { UseFormReturn } from '@codegateinc/react-form-builder-v2'
import { dateHelpers, jobHelpers } from 'lib/utils'
import { JobDetailsResponse } from 'features/jobs/types'

type RescheduleConfirmationModalProps = {
    isOpen: boolean,
    isLoading: boolean,
    onClose: VoidFunction,
    formReturn: UseFormReturn<RescheduleFormShape>,
    job: JobDetailsResponse,
}

export const RescheduleConfirmationModal: React.FunctionComponent<RescheduleConfirmationModalProps> = ({
    isOpen,
    isLoading,
    onClose,
    formReturn,
    job
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const { bookingDays, startingDate, frequency, bookingTime } = formReturn.form
    const dateCustomValueFormat = useBookingCalendarPickerValueFormat(startingDate.value, '', bookingDays.value, true)
    const dayBooking = dateCustomValueFormat ? dateCustomValueFormat.split(' - ')[0] : null
    const timeBooking = (jobHelpers.isJobOneTime(job) || jobHelpers.isSessionJob(job)) && bookingTime.value ? dateHelpers.selectTimeLabel(bookingTime.value) : (dateCustomValueFormat ? dateCustomValueFormat.split(' - ')[1] : null)
    const shouldShowFrequencyText = jobHelpers.isJobRecurring(job) && !jobHelpers.isSessionJob(job)
    const isAccepted = jobHelpers.isAccepted(job)
    const shouldShowNoteForSubscriptionJob = jobHelpers.isCleaner(job) && jobHelpers.isJobRecurring(job) && !jobHelpers.isSessionJob(job)

    const generateRepeatDays = bookingDays => {
        const selectBookingDays = Object.keys(bookingDays).filter(keyDay => bookingDays[keyDay])
        const lengthOfDays = selectBookingDays.length

        return (
            <Typography.Regular>
                {selectBookingDays.map((day, i) => {
                    const isLastElement = i + 1 === lengthOfDays
                    const isPrevLastElement = i + 2 === lengthOfDays
                    const separatorChar = isPrevLastElement || isLastElement ? ' ' : ', '
                    const prefixChar = isLastElement ? lengthOfDays > 1 ? 'and ' : '' : ''

                    return (
                        <React.Fragment key={day}>
                            <Typography.Regular>
                                <Typography.Regular style={styles.frequencyNote}>{prefixChar}</Typography.Regular>
                                <Typography.Regular style={styles.repeatDay}>{day}</Typography.Regular>{separatorChar}
                            </Typography.Regular>
                        </React.Fragment>
                    )
                })}
            </Typography.Regular>
        )
    }

    return (
        <CustomModal
            onClose={onClose}
            isOpen={isOpen}
        >
            <View style={styles.modalWrapper}>
                <View style={styles.iconContainer}>
                    <Icons.CheckCircle
                        size={40}
                        forceColor={theme.colors.orange}
                    />
                </View>
                <Typography.SmallSubheading style={styles.title}>
                    {T.screens.jobDetails.rescheduleRequestSend.packageTitle}
                </Typography.SmallSubheading>

                {
                    shouldShowNoteForSubscriptionJob && (
                        <View style={styles.noteContainer}>
                            <Typography.Regular style={isAccepted ? styles.rescheduleNoteForAcceptedJob: styles.rescheduleNote}>
                                {T.screens.jobDetails.rescheduleRequestSend.note}
                            </Typography.Regular>
                        </View>
                    )
                }

                <View style={styles.datetimeWrapper}>
                    <View style={styles.rescheduleTimeContainer}>
                        <View style={styles.datetimeContainer}>
                            <Icons.DateAndTime
                                size={24}
                                forceColor={theme.colors.orange}
                            />
                            <Typography.Regular style={styles.datetimeText}>
                                {
                                    shouldShowFrequencyText &&
                                    <Typography.Regular style={styles.startText}>{`${T.common.start}: `}</Typography.Regular>
                                }{dayBooking}
                            </Typography.Regular>
                        </View>
                        <View style={styles.spacer}/>
                        <View style={styles.datetimeContainer}>
                            <Icons.Clock
                                size={24}
                                forceColor={theme.colors.orange}
                                viewBox="0 0 25 25"
                            />
                            <Typography.Regular style={styles.datetimeText}>
                                {timeBooking}
                            </Typography.Regular>
                        </View>
                    </View>

                    {shouldShowFrequencyText &&  (
                        <Typography.Regular style={styles.frequencyNote}>
                            {
                                frequency.value?.value === BookingFrequency.Weekly ?
                                    T.screens.jobDetails.rescheduleRequestSend.weeklyRepeat :
                                    T.screens.jobDetails.rescheduleRequestSend.fortnightlyRepeat
                            } {generateRepeatDays(bookingDays.value)}
                        </Typography.Regular>
                    )}

                </View>

                {
                    shouldShowNoteForSubscriptionJob ? (
                        <View style={styles.subscriptionNote}>
                            <View style={styles.iconInformationContainer}>
                                <Icons.Info size={16} forceColor={theme.colors.darkGrey}/>
                            </View>
                            <Typography.Regular style={styles.subscriptionNoteLabel}>
                                {T.screens.jobDetails.rescheduleRequestSend.subscriptionNote}
                            </Typography.Regular>
                        </View>
                    ) : (
                        <Typography.Regular style={isAccepted ? styles.rescheduleNoteForAcceptedJob: styles.rescheduleNote}>
                            {T.screens.jobDetails.rescheduleRequestSend.note}
                        </Typography.Regular>
                    )
                }

                <View style={styles.actionContainer}>
                    <View style={styles.buttonWrapper}>
                        <Button
                            disabled={isLoading}
                            isLoading={isLoading}
                            text={
                                isLoading ?
                                    T.screens.jobDetails.rescheduleRequestSend.sending :
                                    T.screens.jobDetails.rescheduleRequestSend.done}
                            onPress={onClose}
                            textWrapperStyles={styles.buttonTextWrapper}
                            textStyles={styles.buttonText}
                        />
                    </View>
                </View>
            </View>
        </CustomModal>
    )
}

const stylesheet = createStyles(theme => ({
    modalWrapper: {
        alignItems: 'center'
    },
    iconContainer: {
        alignItems: 'center',
        paddingBottom: theme.utils.gap(1)
    },
    title: {
        textAlign: 'center',
        fontSize: 25,
        lineHeight: 31
    },
    actionContainer: {
        paddingTop: theme.utils.gap(3.5),
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        zIndex: -1,
        width: {
            xs: '100%',
            md: undefined
        }
    },
    buttonWrapper: {
        flex: 1
    },
    buttonTextWrapper: {
        width: '100%',
        wordWrap: 'break-word',
        textAlign: 'center',
        marginHorizontal: theme.utils.gap(7)
    },
    repeatDay: {
        fontWeight: 'bold',
        fontSize: 13,
        color: theme.colors.darkGrey
    },
    datetimeWrapper: {
        borderRadius: 8,
        backgroundColor: theme.colors.latte,
        padding: theme.utils.gap(1.5),
        marginTop: theme.utils.gap(1.5),
        marginBottom: theme.utils.gap(2),
        alignItems: 'center',
        width: {
            xs: '100%',
            md: undefined
        }
    },
    rescheduleTimeContainer: {
        flex: 1,
        flexDirection: {
            md: 'row',
            xs: 'column'
        },
        justifyContent: 'center'
    },
    frequencyNote: {
        color: theme.colors.darkGrey,
        marginTop: theme.utils.gap(1),
        fontSize: 13
    },
    datetimeContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: theme.utils.gap(1)
    },
    datetimeText: {
        marginLeft: theme.utils.gap(1),
        fontWeight: '600'
    },
    rescheduleNote: {
        textAlign: 'center',
        maxWidth: {
            md: '50%',
            xs: '100%'
        }
    },
    rescheduleNoteForAcceptedJob: {
        textAlign: 'center'
    },
    startText: {
        marginRight: theme.utils.gap(0.5),
        color: theme.colors.orange
    },
    spacer: {
        height: {
            md: 0,
            xs: theme.utils.gap(1)
        }
    },
    buttonText: {
        textAlign: 'center'
    },
    noteContainer: {
        marginTop: theme.utils.gap(2),
        alignItems: 'center',
        width: '100%'
    },
    subscriptionNoteLabel: {
        fontFamily: CustomFonts.Poppins500,
        color: theme.colors.darkGrey,
        fontSize: 14,
        marginLeft: theme.utils.gap(0.5)
    },
    subscriptionNote: {
        flexDirection: 'row'
    },
    iconInformationContainer: {
        marginTop: theme.utils.gap(1) / 5
    }
}))
