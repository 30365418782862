import React from 'react'
import { View, ViewStyle } from 'react-native'
import Animated, {
    runOnUI,
    useAnimatedStyle,
    useDerivedValue,
    withTiming
} from 'react-native-reanimated'
import { Icons } from 'assets'
import { isNative } from 'lib/common'
import { Children } from 'lib/types'
import { createStyles } from 'lib/styles'
import { useAccordion, useStyles } from 'lib/hooks'
import { SmallSubheading } from './typography'
import { Touchable } from './Touchable'

type SectionAccordionProps = {
    title?: string,
    children: Children,
    renderCustomIcon?: () => Children,
    containerStyles?: ViewStyle,
    titleStyles?: ViewStyle
}

export const SectionAccordion: React.FunctionComponent<SectionAccordionProps> = ({
    title,
    children,
    renderCustomIcon,
    containerStyles = {},
    titleStyles = {}
}) => {
    const { setHeight, animatedHeightStyle, aref, isOpened } = useAccordion()
    const { styles } = useStyles(stylesheet)
    const rotateZ = useDerivedValue(() => withTiming(isOpened.value ? 0 : -90))
    const animatedRotateStyle = useAnimatedStyle(() => ({
        transform: [{ rotateZ: `${rotateZ.value}deg` }]
    }))

    return (
        <View
            style={{
                ...styles.container,
                ...containerStyles
            }}
        >
            <Touchable
                style={{
                    ...styles.title,
                    ...titleStyles
                }}
                onPress={() => runOnUI(setHeight)()}
            >
                <SmallSubheading>
                    {title}
                </SmallSubheading>
                <Animated.View style={animatedRotateStyle}>
                    {renderCustomIcon
                        ? renderCustomIcon()
                        : (
                            <Icons.Chevron size={10} />
                        )
                    }
                </Animated.View>
            </Touchable>
            <Animated.View style={animatedHeightStyle}>
                <View
                    ref={aref}
                    collapsable={false}
                    style={styles.accordionWrapper}
                >
                    {children}
                </View>
            </Animated.View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    accordionWrapper: {
        left: 0,
        right: 0,
        position: isNative
            ? 'absolute'
            : undefined
    },
    container: {
        marginTop: {
            lg: theme.utils.gap(3),
            xs: theme.utils.gap(1)
        },
        marginHorizontal: {
            lg: 0,
            xs: theme.utils.gap(2)
        },
        paddingHorizontal: {
            lg: theme.utils.gap(2),
            xs: 0
        },
        borderColor: theme.colors.silver,
        overflow: 'hidden',
        borderWidth: {
            lg: 1,
            xs: 0
        },
        borderRadius: {
            lg: 8,
            xs: 0
        },
        borderBottomWidth: {
            lg: 1,
            xs: 2
        }
    },
    title: {
        flex: 1,
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingVertical: theme.utils.gap(2)
    }
}))
