import React, { useState } from 'react'
import { View } from 'react-native'
import { CallToActionButton, MediaQuery, PhotoGallery } from 'lib/components'
import { Breakpoint, createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { CustomerChargeStatus, JobStatus } from 'lib/types'
import { JobDetailsResponse } from '../../types'
import { AdditionalInformation } from '../AdditionalInformation'
import { BookingsProposals } from '../BookingsProposals'
import { ServiceDetailsAccepted } from '../ServiceDetailsAccepted'
import { JobBillings } from '../JobBillings'
import { JobName } from '../JobName'
import { JobRating } from '../JobRating'
import { JobRatingModal } from '../JobRatingModal'
import { ServiceLocation } from '../ServiceLocation'
import { SubmittedMessage } from '../SubmittedMessage'
import { CancelRequest } from '../CancelRequest'
import { HandymanPriceDetails } from './HandymanPriceDetails'
import { HandymanServiceDate } from './HandymanServiceDate'
import { HandymanServiceDetails } from './HandymanServiceDetails'

type HandymanDetailsContentProps = {
    data: JobDetailsResponse,
    jobStatus?: JobStatus
}

export const HandymanDetailsContent: React.FunctionComponent<HandymanDetailsContentProps> = ({
    data,
    jobStatus
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const [isRatingDismissed, setRatingDismiss] = useState(false)
    const isSubmitted = data.job.status === JobStatus.Submitted
    const [isGalleryOpen, setIsGalleryOpen] = useState(false)
    const photos = data.job.photos

    return (
        <View style={styles.wrapper}>
            <View style={styles.contentWrapper}>
                <View style={styles.content}>
                    <JobName
                        job={data}
                        isRecurring={false}
                    />
                    {isSubmitted && (
                        <SubmittedMessage text={T.screens.handymanDetails.submittedMessage} />
                    )}
                    <HandymanServiceDate
                        job={data}
                        isSubmitted={isSubmitted}
                    />
                    <HandymanServiceDetails job={data} />
                    <HandymanPriceDetails job={data}/>
                    {/* todo enable when supported
                        <RescheduleSession data={data} />
                    */}
                    <AdditionalInformation
                        job={data}
                        linesLimit={3}
                    />
                    {photos.length > 0 && (
                        <View style={styles.buttonWrapper}>
                            <CallToActionButton
                                onPress={() => setIsGalleryOpen(true)}
                                text={T.common.viewImages}
                            />
                        </View>
                    )}
                    <PhotoGallery
                        photos={photos}
                        isVisible={isGalleryOpen}
                        onClose={() => setIsGalleryOpen(false)}
                    />
                    <ServiceLocation job={data.job} />
                    {data.job.customer_charges?.length === 0 && (
                        <CancelRequest
                            job={data}
                            text={isSubmitted
                                ? T.screens.jobDetails.cancelRequest
                                : undefined
                            }
                            jobStatus={jobStatus}
                        />
                    )}
                </View>
                {jobStatus === JobStatus.Completed && (
                    <JobRating job={data} />
                )}
            </View>
            {isSubmitted
                ? (
                    <BookingsProposals
                        job={data}
                        isRecurring={false}
                    />
                ) : (
                    <React.Fragment>
                        <MediaQuery.Visible from={Breakpoint.LG}>
                            <ServiceDetailsAccepted
                                job={data}
                                jobStatus={jobStatus}
                                isRecurringAccepted={false}
                            />
                        </MediaQuery.Visible>
                        {data.job.customer_charges?.some(item => item.auth_status === CustomerChargeStatus.Pending) && data.job.status === JobStatus.Accepted && (
                            <MediaQuery.Hidden from={Breakpoint.LG}>
                                <View style={styles.billingWrapper}>
                                    <JobBillings job={data.job} />
                                </View>
                            </MediaQuery.Hidden>
                        )}
                    </React.Fragment>
                )
            }
            <JobRatingModal
                isVisible={data.job.status === JobStatus.Completed && !isRatingDismissed}
                onClose={() => setRatingDismiss(true)}
                job={data}
            />
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        flexDirection: {
            lg: 'row',
            xs: 'column'
        },
        alignItems: {
            lg: 'flex-start',
            xs: undefined
        },
        marginTop: -theme.utils.gap(6),
        paddingHorizontal: {
            lg: theme.utils.gap(6),
            xs: theme.utils.gap(2)
        }
    },
    contentWrapper: {
        flex: 1
    },
    content: {
        borderRadius: 8,
        borderWidth: 1,
        paddingHorizontal: theme.utils.gap(2),
        paddingVertical: theme.utils.gap(3),
        backgroundColor: theme.colors.white,
        borderColor: theme.colors.silver,
        backGroundColor: theme.colors.white,
        ...theme.utils.createShadow(2, 0, 8, 4, 4, theme.colors.webShadow(0.04))
    },
    buttonWrapper: {
        width: {
            lg: 240,
            xs: undefined
        },
        marginBottom: theme.utils.gap(2)
    },
    billingWrapper: {
        marginTop: theme.utils.gap(2)
    }
}))
