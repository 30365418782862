import { useTranslations } from 'lib/hooks'

export const useAppliances = () => {
    const T = useTranslations()

    // TODO Might need some value to parse it later to comment data, but not sure yet
    return [
        {
            label: T.components.appliances.yesOvenNoDishwasher,
            value: T.components.appliances.yesOvenNoDishwasher
        },
        {
            label: T.components.appliances.ovenAndDishwasher,
            value: T.components.appliances.ovenAndDishwasher
        },
        {
            label: T.components.appliances.noOvenYesDishwasher,
            value: T.components.appliances.noOvenYesDishwasher
        },
        {
            label: T.components.appliances.noOvenNoDishwasher,
            value: T.components.appliances.noOvenNoDishwasher
        }
    ]
}
