import React from 'react'
import Select from 'react-select'
import { CustomFonts } from 'lib/types'
import { useStyles, useTranslations } from 'lib/hooks'
import { SelectProps } from './types'
import { CustomClearIndicator, CustomDropdownIndicator } from './webComponents'

export const SelectDropdown: React.FunctionComponent<SelectProps> = ({
    onChange,
    options,
    defaultValue
}) => {
    const { theme } = useStyles()
    const T = useTranslations()
    const fontSize = 16
    const fontFamily = CustomFonts.Roboto600

    return (
        <React.Fragment>
            <Select
                backspaceRemovesValue
                defaultValue={defaultValue}
                options={options}
                isClearable={false}
                onChange={onChange}
                noOptionsMessage={() => T.common.noOptions}
                components={{
                    ClearIndicator: CustomClearIndicator,
                    DropdownIndicator: CustomDropdownIndicator
                }}
                styles={{
                    control: base => ({
                        ...base,
                        borderWidth: 0,
                        borderBottomWidth: 1,
                        borderRadius: 0,
                        paddingBottom: 0,
                        borderColor: theme.colors.orange,
                        fontFamily,
                        boxShadow: 'none',
                        '&:hover': {
                            borderColor: theme.colors.night
                        }
                    }),
                    valueContainer: base => ({
                        ...base,
                        paddingLeft: 0
                    }),
                    singleValue: base => ({
                        ...base,
                        fontSize,
                        color: theme.components.input.typography.text
                    }),
                    input: base => ({
                        ...base,
                        fontSize,
                        color: theme.components.input.typography.text
                    }),
                    placeholder: base => ({
                        ...base,
                        fontSize,
                        marginLeft: 0,
                        color: theme.components.input.typography.placeholder
                    }),
                    indicatorSeparator: base => ({
                        ...base,
                        width: 0
                    }),
                    menu: base => ({
                        ...base,
                        zIndex: 9999,
                        borderRadius: theme.components.input.borderRadius,
                        boxShadow: '0px 4px 8px 2px rgba(0, 0, 0, 0.16)'
                    }),
                    option: (base, props) => ({
                        ...base,
                        fontSize: 16,
                        color: props.isFocused
                            ? theme.colors.orange
                            : theme.components.input.typography.text,
                        backgroundColor: props.isFocused
                            ? theme.colors.yellow
                            : 'transparent',
                        fontFamily: theme.components.typography.fontFamily
                    }),
                    noOptionsMessage: (base, props) => ({
                        ...base,
                        color: theme.components.input.typography.placeholder,
                        fontFamily: theme.components.typography.fontFamily
                    })
                }}
            />
        </React.Fragment>
    )
}
