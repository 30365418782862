export const MOCKED_PAYMENTS = [
    {
        uuid: '1',
        date: new Date().toISOString(),
        name: 'Cleaning Service',
        price: 120,
        code: 'SG-45678'
    },
    {
        uuid: '2',
        date: new Date().toISOString(),
        name: 'Laundry Service',
        price: 220,
        code: 'SG-45638'
    },
    {
        uuid: '3',
        date: new Date().toISOString(),
        name: 'Cleaning Service',
        price: 120,
        code: 'SG-45678'
    },
    {
        uuid: '4',
        date: new Date().toISOString(),
        name: 'Laundry Service',
        price: 220,
        code: 'SG-45638'
    }
]
