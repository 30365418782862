import { useField } from '@codegateinc/react-form-builder-v2'
import { dateHelpers } from 'lib/utils'
import { useTranslations } from 'lib/hooks'
import { BookingFrequency, FrequencyOption, Nullable, SupplyID } from 'lib/types'
import { getServiceStaticConfig } from 'features/bookings/utils'
import { PestControlFields } from './forms'
import { useBookingDays, useFrequencyOptions } from '../hooks'
import { PestControlPackage, PestControlSession } from '../types'

export const usePestControlForm = () => {
    const T = useTranslations()
    // const [defaultProperty] = pestControlHooks.useGetPropertyType()
    const frequencyOptions = useFrequencyOptions()
    const bookingDaysInitialValue = useBookingDays()
    const config = getServiceStaticConfig(SupplyID.PestControl)
    const { pricing, min_notice_hours } = config
    const defaultProperty = pricing.propertyType.options[0]
    const defaultServices = defaultProperty.services.bySession.items

    const frequency = useField<Nullable<FrequencyOption>>({
        key: PestControlFields.Frequency,
        initialValue: frequencyOptions.find(option => option.value === BookingFrequency.OneTime) || null,
        isRequired: false,
        validateOnBlur: false,
        label: T.screens.pestControl.formFields.frequency.label
    })

    const startingDate = useField<string>({
        key: PestControlFields.StartingDate,
        initialValue: '',
        isRequired: true,
        validateOnBlur: false,
        label: T.screens.pestControl.formFields.startingDate.label,
        placeholder: T.screens.pestControl.formFields.startingDate.placeholder,
        validationRules: [
            {
                errorMessage: T.screens.pestControl.formFields.startingDate.validation.isRequired,
                validate: Boolean
            }
        ]
    })

    const bookingTime = useField<string>({
        key: PestControlFields.BookingTime,
        initialValue: '',
        validateOnBlur: true,
        isRequired: frequency.value?.value === BookingFrequency.OneTime,
        placeholder: T.screens.pestControl.formFields.selectTime.placeHolder,
        validationRules: [
            {
                errorMessage: T.screens.pestControl.formFields.selectTime.validation.isRequired,
                validate: value => {
                    if (frequency.value?.value !== BookingFrequency.OneTime) {
                        return true
                    }

                    return Boolean(value)
                }
            },
            {
                errorMessage: `${min_notice_hours} ${T.screens.pestControl.formFields.selectTime.validation.minimumHourNotice}`,
                validate: value => {
                    if (frequency.value?.value !== BookingFrequency.OneTime) {
                        return true
                    }

                    const isPast = dateHelpers.isDatePast(dateHelpers.subHoursFromDate(value, min_notice_hours))

                    return !isPast
                }
            }
        ]
    })

    const bookingDays = useField<Record<string, Nullable<string>>>({
        key: PestControlFields.BookingDays,
        initialValue: bookingDaysInitialValue,
        validateOnBlur: true,
        isRequired: frequency.value?.value !== BookingFrequency.OneTime,
        placeholder: T.screens.pestControl.formFields.selectTime.placeHolder,
        validationRules: [
            {
                errorMessage: T.screens.pestControl.formFields.selectTime.validation.isRequired,
                validate: value => {
                    if (frequency.value?.value === BookingFrequency.OneTime) {
                        return true
                    }

                    return Object.values(value).some(Boolean)
                }
            },
            {
                errorMessage: `${min_notice_hours} ${T.screens.pestControl.formFields.selectTime.validation.minimumHourNotice}`,
                validate: value => {
                    const times = Object.values(value).filter(item => Boolean(item)) as Array<string>
                    const isSomePast = times.some(time => dateHelpers.isDatePast(dateHelpers.subHoursFromDate(time, min_notice_hours)))

                    return !isSomePast
                }
            }
        ]
    })

    const propertyType = useField<number>({
        key: PestControlFields.PropertyType,
        initialValue: defaultProperty.id,
        label: T.screens.pestControl.formFields.propertyType.label,
        isRequired: true
    })

    const services = useField<Array<PestControlSession>>({
        key: PestControlFields.Services,
        initialValue: defaultServices.map(service => ({
            id: service.id,
            isSelected: false,
            cost: service.price,
            title: service.label,
            price_per_treatment: service.price,
            number_of_treatments: service.minQuantity,
            minNumberOfTreatments: service.minQuantity,
            pest_type: service.label,
            area_type: defaultProperty.label,
            note: service.notes,
            pestCategory: service.label
        })),
        label: '',
        isRequired: false,
        validationRules: [
            {
                errorMessage: T.screens.pestControl.formFields.services.validation.isRequired,
                validate: value => value.some(service => service.number_of_treatments > 0) || packages.value.length > 0
            }
        ]
    })

    const packages = useField<Array<PestControlPackage>>({
        key: PestControlFields.Packages,
        initialValue: [],
        label: '',
        isRequired: false,
        validationRules: [
            {
                errorMessage: T.screens.pestControl.formFields.services.validation.isRequired,
                validate: value => value.length > 0 || services.value.some(service => service.number_of_treatments > 0)
            }
        ]
    })

    return {
        frequency,
        startingDate,
        bookingTime,
        bookingDays,
        propertyType,
        services,
        packages
    }
}
