import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const Coupon: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path d="M80 32V16c0-4.44-3.6-8-8-8H8C3.6 8 .04 11.56.04 16v16C4.44 32 8 35.6 8 40s-3.56 8-8 8v16c0 4.4 3.6 8 8 8h64c4.4 0 8-3.6 8-8V48c-4.4 0-8-3.6-8-8s3.6-8 8-8Zm-8-5.84c-4.76 2.76-8 7.96-8 13.84s3.24 11.08 8 13.84V64H8V53.84c4.76-2.76 8-7.96 8-13.84 0-5.92-3.2-11.08-7.96-13.84L8 16h64v10.16ZM36 52h8v8h-8v-8Zm0-16h8v8h-8v-8Zm0-16h8v8h-8v-8Z" />
    </Icon>
)
