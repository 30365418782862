import React from 'react'
import { View } from 'react-native'
import { Icons } from 'assets'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { Touchable, Typography } from 'lib/components'

type NumberProps = {
    quantity: number,
    onRemove: VoidFunction,
    disabled?: boolean,
    onAdd: VoidFunction,
    minValue?: number
}

export const Number: React.FunctionComponent<NumberProps> = ({
    quantity,
    disabled,
    onRemove,
    minValue = 0,
    onAdd
}) => {
    const { styles, theme } = useStyles(stylesheet)

    return (
        <View style={styles.numberWrapper}>
            <Touchable
                disabled={disabled || quantity === minValue}
                onPress={onRemove}
            >
                <View style={styles.button}>
                    {quantity > 1 ? (
                        <Icons.Minus
                            size={8}
                            forceColor={quantity === minValue
                                ? theme.colors.mouse
                                : undefined
                            }
                        />
                    ) : (
                        <Icons.Delete
                            size={12}
                            forceColor={quantity === minValue
                                ? theme.colors.mouse
                                : undefined
                            }
                        />
                    )}
                </View>
            </Touchable>
            <View style={styles.quantityWrapper}>
                <Typography.Regular
                    bold
                    forceColor={quantity !== minValue
                        ? theme.colors.black
                        : undefined
                    }
                >
                    {quantity}
                </Typography.Regular>
            </View>
            <Touchable
                disabled={disabled}
                onPress={onAdd}
            >
                <View style={styles.button}>
                    <Icons.Plus size={10} />
                </View>
            </Touchable>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    numberWrapper: {
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'row'
    },
    button: {
        width: 32,
        height: 32,
        backgroundColor: theme.colors.silver,
        alignItems: 'center',
        justifyContent: 'center'
    },
    quantityWrapper: {
        width: 32,
        height: 32,
        backgroundColor: theme.colors.white,
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row'
    }
}))
