import React from 'react'
import { Children } from 'lib/types'
import { useStyles } from 'lib/hooks'
import { isNative } from 'lib/common'
import { createStyles } from 'lib/styles'

type StickyProps = {
    children: Children
}

export const Sticky: React.FunctionComponent<StickyProps> = ({
    children
}) => {
    const { styles } = useStyles(stylesheet)

    if (isNative) {
        return (
            <React.Fragment>
                {children}
            </React.Fragment>
        )
    }

    return (
        <div style={styles.sticky}>
            {children}
        </div>
    )
}

const stylesheet = createStyles(theme => ({
    sticky: {
        top: {
            lg: theme.utils.gap(4),
            xs: undefined
        },
        position: {
            lg: 'sticky',
            xs: undefined
        }
    }
}))
