import React from 'react'
import { View } from 'react-native'
import { CustomFonts, JobStatus, NotificationType } from 'lib/types'
import { dateHelpers, jobHelpers } from 'lib/utils'
import { createStyles } from 'lib/styles'
import { useToastAtom, useUserTokenAtom } from 'lib/atoms'
import { Button, CallToActionButton, Typography } from 'lib/components'
import { useQueryHelpers, useStyles, useTranslations } from 'lib/hooks'
import { JobDetailsResponse } from '../types'
import { getConsumerActiveJobs, getJobDetails, resumeJob } from '../actions'

type PausedJobStatusProps = {
    job: JobDetailsResponse,
    onlyButton?: boolean
    onlyText?: boolean
}

export const PausedJobStatus: React.FunctionComponent<PausedJobStatusProps> = ({ job, onlyButton, onlyText }) => {
    const T = useTranslations()
    const [token] = useUserTokenAtom()
    const [, setToastMessage] = useToastAtom()
    const { styles } = useStyles(stylesheet)
    const { onRequestError } = useQueryHelpers()
    const { mutate: onResume, isLoading: isMutating } = resumeJob()
    const { refetch: refetchJobDetails, isRefetching: isRefetchingJobDetails } = getJobDetails(job.job._id)
    const { refetch: refetchActiveJobs, isRefetching: isRefetchingActiveJobs } = getConsumerActiveJobs()

    if (job.job.status !== JobStatus.Paused) {
        return null
    }

    const handleResumeJob = () => {
        onResume(
            {
                token,
                id: job.job._id
            },
            {
                onSuccess: () => {
                    refetchJobDetails()
                    refetchActiveJobs()
                    setToastMessage({
                        type: NotificationType.Success,
                        message: T.components.resumeJob.resumeSuccessMessage
                    })
                },
                onError: onRequestError
            }
        )
    }

    const isLoading = isMutating || isRefetchingJobDetails || isRefetchingActiveJobs
    const isAcceptedSubscription = jobHelpers.isAcceptedSubscription(job)
    const ctaText = isAcceptedSubscription ? T.components.resumeJob.resumePlan : T.components.resumeJob.resume

    return onlyButton ? (
        <View style={styles.containerWithOnlyButton}>
            <CallToActionButton testID={T.accessibility.jobDetailsScreen.components.resumeJob} text={ctaText} onPress={handleResumeJob}/>
        </View>
    ) :(
        <View style={styles.container}>
            <View style={styles.wrapper}>
                <View style={styles.flex}>
                    <View style={styles.title}>
                        <Typography.Label style={styles.label}>
                            {T.components.resumeJob.pausedFrom}
                        </Typography.Label>
                    </View>
                    <Typography.Label>
                        {dateHelpers.pauseDate(job.job.pause_start_date)}
                    </Typography.Label>
                </View>
                <View style={styles.flex}>
                    <View style={styles.title}>
                        <Typography.Label style={styles.label}>
                            {T.components.resumeJob.to}
                        </Typography.Label>
                    </View>
                    <Typography.Label>
                        {dateHelpers.pauseDate(job.job.pause_end_date)}
                    </Typography.Label>
                </View>
            </View>
            {!onlyText &&(
                <Button
                    isLoading={isLoading}
                    onPress={handleResumeJob}
                    text={ctaText}
                />
            )}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        marginTop: theme.utils.gap(2)
    },
    containerWithOnlyButton: {
        marginBottom: theme.utils.gap(2)
    },
    wrapper: {
        flexDirection: 'row',
        marginBottom: theme.utils.gap(3)
    },
    title: {
        marginBottom: theme.utils.gap(1) / 2
    },
    label: {
        fontFamily: CustomFonts.Roboto500,
        color: theme.colors.strawberry
    },
    flex: {
        flex: 1
    }
}))
