import { useStyles, useTranslations } from 'lib/hooks'
import { ConnectionStatus } from '../types'

export const useGetChatStatus = () => {
    const T = useTranslations()
    const { theme } = useStyles()

    return {
        getChatStatus: (roomConnectionStatus: ConnectionStatus) => {
            switch (roomConnectionStatus) {
                case ConnectionStatus.Online:
                    return {
                        label: T.screens.chat.header.connectionStatus.online,
                        dotBackgroundColor: theme.colors.green
                    }
                case ConnectionStatus.Closing:
                case ConnectionStatus.Offline:
                case ConnectionStatus.Disconnect:
                    return {
                        label: T.screens.chat.header.connectionStatus.offline,
                        dotBackgroundColor: theme.colors.flame
                    }
                default:
                    return {
                        label: `${T.screens.chat.header.connectionStatus.connecting}...`,
                        dotBackgroundColor: theme.colors.sun
                    }
            }
        }
    }
}
