import { ACServiceContractType, AirConditionService, SupplyID } from 'lib/types'
import { getServiceStaticConfig } from '../utils'

export const useAirConditionServiceOptions = () => {
    const config = getServiceStaticConfig(SupplyID.ACService)
    const { pricing } = config

    if (!pricing) {
        return []
    }

    const { oneTime, contract, custom } = pricing
    const serviceTypes = Object.keys(pricing)

    return [
        {
            value: serviceTypes.find(item => item.toLowerCase().includes(AirConditionService.OneTime)) || '',
            title: oneTime.label,
            list: oneTime.description,
            frequency: AirConditionService.OneTime

        },
        {
            value: serviceTypes.find(item => item.toLowerCase().includes(AirConditionService.Contract)) || '',
            options: Object.keys(contract).map(item => ({ value: contract[item].frequency, contractType: item as ACServiceContractType })).filter(item => !!item.value),
            title: contract.label,
            list: contract.description,
            frequency: AirConditionService.Contract
        },
        {
            value: serviceTypes.find(item => item.toLowerCase().includes(AirConditionService.Custom)) || '',
            title: custom.label,
            list: custom.description,
            frequency: AirConditionService.Custom
        }
    ]
}
