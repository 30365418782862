import React, { useState } from 'react'
import { View, Image } from 'react-native'
import { Icons } from 'assets'
import { Nullable } from 'lib/types'
import { createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { Label } from '../typography'
import { UploadedPhoto } from './types'
import { Touchable } from '../Touchable'
import { LoadingIndicator } from '../LoadingIndicator'
import { PhotoGallery } from '../photoGallery'

type PhotoUploadUploadedFilesProps = {
    disabled?: boolean,
    isLoading: boolean,
    addedFiles: Array<UploadedPhoto>,
    onRemoveFile: (uuid: string) => void
}

export const PhotoUploadUploadedFiles: React.FunctionComponent<PhotoUploadUploadedFilesProps> = ({
    isLoading,
    addedFiles,
    disabled,
    onRemoveFile
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const [openPhotoIndex, setOpenPhotoIndex] = useState<Nullable<number>>(null)

    return (
        <View style={styles.uploaded}>
            <Label
                style={styles.label}
                forceColor={theme.colors.darkGrey}
            >
                {T.components.photoUpload.uploaded}
            </Label>
            <View style={styles.filesContainer}>
                <LoadingIndicator isLoading={isLoading} />
                {addedFiles.map((file, index) => (
                    <View
                        key={index}
                        style={styles.imageContainer}
                    >
                        <Touchable
                            disabled={disabled}
                            onPress={() => setOpenPhotoIndex(index)}
                        >
                            <View style={styles.file}>
                                <Touchable
                                    disabled={disabled}
                                    style={styles.removeIcon}
                                    onPress={() => onRemoveFile(file.fileUUID)}
                                >
                                    <Icons.RemovePhoto size={20} />
                                </Touchable>
                                <Image
                                    source={{
                                        uri: file.preview
                                    }}
                                    resizeMode="cover"
                                    style={styles.imagePreview}
                                />
                            </View>
                        </Touchable>
                    </View>
                ))}
            </View>
            <PhotoGallery
                isPreview
                photos={addedFiles.map(file => file.preview)}
                initialIndex={openPhotoIndex ?? undefined}
                onClose={() => setOpenPhotoIndex(null)}
                isVisible={openPhotoIndex !== null}
            />
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    uploaded: {
        marginTop: theme.utils.gap(2)
    },
    filesContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        borderWidth: 1,
        borderRadius: 8,
        borderColor: theme.colors.darkSilver,
        paddingLeft: theme.utils.gap(1),
        paddingBottom: theme.utils.gap(1)
    },
    label: {
        marginBottom: theme.utils.gap(1)
    },
    file: {
        paddingTop: theme.utils.gap(1),
        paddingRight: theme.utils.gap(1)
    },
    removeIcon: {
        position: 'absolute',
        zIndex: theme.zIndex[10],
        top: theme.utils.gap(2),
        right: theme.utils.gap(2)
    },
    imageContainer: {
        flexBasis: {
            xs: '25%',
            md: '20%'
        }
    },
    imagePreview: {
        width: '100%',
        height: {
            xs: 75,
            md: 100
        }
    }
}))
