import React from 'react'
import { View } from 'react-native'
import { createStyles } from 'lib/styles'
import { Typography } from 'lib/components'
import { usePrice, useStyles, useTranslations } from 'lib/hooks'
import { NavigationParams, NavigationProps, ScreenNames } from 'lib/routing'
import { BookingSuccessButtons, BookingSuccessCrossSale, BookingSuccessPromoSection, BookingSuccessSection, PageLayout } from '../components'
import { useBookingJobQuotationAtom, useFeatureFlagAtom } from 'lib/atoms'
import { CustomFonts } from 'lib/types'
import { JobQuotationResponse } from 'features/bookings/types'

type BookingSuccessScreenProps = {
    navigation: NavigationProps<ScreenNames.BookingSuccess>,
    route: NavigationParams<ScreenNames.BookingSuccess>
}

export const BookingSuccessScreen: React.FunctionComponent<BookingSuccessScreenProps> = ({
    route
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const [bookingJobQuotationAtom] = useBookingJobQuotationAtom()
    const [featureFlagAtom] = useFeatureFlagAtom()
    const usePaymentBySessionFlow = featureFlagAtom?.paymentBySessionFlow || false
    const bookingJobQuotation = bookingJobQuotationAtom as JobQuotationResponse
    const jobQuotationTotal = usePaymentBySessionFlow ?
        bookingJobQuotation?.totalSubscription :
        bookingJobQuotation?.total
    const valueSavings = jobQuotationTotal?.valueSavings || 0
    const formattedValueSavings = usePrice(valueSavings as number, true)

    return (
        <PageLayout>
            <View>
                <View style={styles.title}>
                    <Typography.Subheading>
                        {T.screens.bookingSuccess.title}
                    </Typography.Subheading>

                    {
                        valueSavings > 0.0 && (
                            <Typography.SmallSubheading style={styles.savedText}>
                                {`${T.screens.bookingSuccess.youHaveJustSaved} ${formattedValueSavings}!`}
                            </Typography.SmallSubheading>
                        )
                    }

                </View>
                <BookingSuccessSection bookingId={route.params.bookingId} />
                {route.params.withPromo && (
                    <BookingSuccessPromoSection />
                )}
                {/* TODO Need correct content do show here */}
                {/* <BookingSuccessFaq /> */}
                <BookingSuccessButtons hideBackToHomeButton/>
                <BookingSuccessCrossSale />
                <BookingSuccessButtons hideMyBookingButton/>
            </View>
        </PageLayout>
    )
}

const stylesheet = createStyles(theme => ({
    title: {
        marginTop: theme.utils.gap(4),
        alignItems: 'center'
    },
    savedText: {
        color: theme.colors.green,
        fontFamily: CustomFonts.Roboto500,
        marginTop: theme.utils.gap(1)
    }
}))
