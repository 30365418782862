import { useCallback } from 'react'
import { useFonts } from 'expo-font'
import * as SplashScreen from 'expo-splash-screen'
import { CustomFonts } from 'lib/types'

export const useCustomFonts = () => {
    const [fontsLoaded, error] = useFonts({
        [CustomFonts.Poppins400]: require('fonts/Poppins-Regular.ttf'),
        [CustomFonts.Poppins500]: require('fonts/Poppins-Medium.ttf'),
        [CustomFonts.Poppins600]: require('fonts/Poppins-SemiBold.ttf'),
        [CustomFonts.Poppins700]: require('fonts/Poppins-Bold.ttf'),
        [CustomFonts.Roboto400]: require('fonts/Roboto-Regular.ttf'),
        [CustomFonts.Roboto500]: require('fonts/Roboto-Medium.ttf'),
        [CustomFonts.Roboto600]: require('fonts/Roboto-Bold.ttf'),
        [CustomFonts.Roboto700]: require('fonts/Roboto-Black.ttf'),
        [CustomFonts.Lato700]: require('fonts/Lato-Bold.ttf')
    })

    const onLayoutRootView = useCallback(async () => {
        if (fontsLoaded) {
            await SplashScreen.hideAsync()
        }
    }, [fontsLoaded])

    return {
        fontsLoaded: fontsLoaded || error,
        onLayoutRootView
    }
}
