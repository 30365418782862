import React, { useState } from 'react'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { Regular } from '../typography'
import { Touchable } from '../Touchable'

type HeaderLinkProps = {
    text: string,
    onPress: VoidFunction
}

export const HeaderLink: React.FunctionComponent<HeaderLinkProps> = ({
    onPress,
    text
}) => {
    const { styles, theme } = useStyles(stylesheet)
    const [isHovering, setIsHovering] = useState(false)

    return (
        <Touchable
            onPress={onPress}
            style={styles.link}
            onHoverIn={() => setIsHovering(true)}
            onHoverOut={() => setIsHovering(false)}
        >
            <Regular
                style={styles.linkText}
                forceColor={isHovering ? theme.colors.sun : undefined}
            >
                {text}
            </Regular>
        </Touchable>
    )
}

const stylesheet = createStyles(theme => ({
    link: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    linkText: {
        ...theme.components.header.link,
        paddingHorizontal: theme.utils.gap(1) / 2,
        paddingVertical: theme.utils.gap(1)
    }
}))
