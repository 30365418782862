import React from 'react'
import { View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { isNative } from 'lib/common'
import { AppLayout, Header, BackButton } from 'lib/components'
import { useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'

import { UserDeleteAccountForm } from '../components/UserDeleteAccountForm'

export const UserDeleteAccountScreen: React.FunctionComponent = () => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const navigation = useNavigation()
    const handleBack = () => navigation.goBack()

    return (
        <AppLayout title={T.screens.user.title}>
            {isNative ? (
                <View
                    style={{
                        ...styles.backButtonContainer,
                        marginTop: theme.utils.gap(1)
                    }}
                >
                    <BackButton onPress={handleBack} />
                </View>
            ) : (
                <Header disableUserMenu />
            )}
            <UserDeleteAccountForm cancelDeleting={handleBack} />
        </AppLayout>
    )
}

const stylesheet = createStyles(theme => ({
    backButtonContainer: {
        paddingHorizontal: theme.utils.gap(2)
    }
}))
