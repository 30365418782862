import React, { useState } from 'react'
import { View } from 'react-native'
import { useForm } from '@codegateinc/react-form-builder-v2'

import { Adapter, Button, SectionBox, Typography } from 'lib/components'
import { createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { useIsAuthorizedAtom, useUserTokenAtom } from 'lib/atoms'
import { ErrorResponse } from 'lib/types'

import { UserDeleteAccountMessage } from './UserDeleteAccountMessage'
import { UserDeleteAccountModal } from './UserDeleteAccountModal'

import { deleteAccount } from '../actions'
import { useDeleteAccountForm, DeleteAccountShape } from '../forms'

type UserDeleteAccountFormProps = {
    cancelDeleting: VoidFunction
}

export const UserDeleteAccountForm: React.FunctionComponent<UserDeleteAccountFormProps> = ({
    cancelDeleting
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const [isDeleted, setIsDeleted] = useState(false)
    const [historyTermsChecked, setHistoryTermsChecked] = useState(false)
    const [informationTermsChecked, setInformationTermsChecked] = useState(false)
    const [marketingTermsChecked, setMarketingTermsChecked] = useState(false)
    const [newAccountTermsChecked, setNewAccountTermsChecked] = useState(false)
    const iAllChecked = historyTermsChecked
                        && informationTermsChecked
                        && marketingTermsChecked
                        && newAccountTermsChecked
    const [, setIsAuthorized] = useIsAuthorizedAtom()
    const [, setError] = useState<ErrorResponse>()
    const [userToken] = useUserTokenAtom()
    const { form } = useForm<DeleteAccountShape>(useDeleteAccountForm(), {
        onSuccess: () => {}
    })
    const { mutate: onDeleteAccount, isLoading } = deleteAccount()

    const handleConfirm = () => {
        onDeleteAccount(
            {
                token: userToken
            },
            {
                onSuccess: () => setIsDeleted(true),
                onError: response => {
                    setError(response)
                }
            }
        )
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const renderCheckbox = (fields: any, setCheckBox: any) => (
        <Adapter.Checkbox
            {...fields}
            renderComponent={() => <Typography.Regular>{fields.label}</Typography.Regular>}
            onChangeValue={value => {
                fields.onChangeValue(value)
                setCheckBox(value)
            }}
        />
    )

    return (
        <SectionBox>
            <UserDeleteAccountModal
                isModalOpen={isDeleted}
                closeModal={() => setIsAuthorized(false)}
            />
            <View style={styles.textRow}>
                <Typography.Heading style={styles.deleteFormTitle}>
                    {T.screens.user.deleteFormTitle}
                </Typography.Heading>
            </View>
            <UserDeleteAccountMessage />
            <View style={styles.separator} />
            <View style={styles.checkboxWrapper}>
                {renderCheckbox(form.historyTerms, setHistoryTermsChecked)}
                {renderCheckbox(form.informationTerms, setInformationTermsChecked)}
                {renderCheckbox(form.marketingTerms, setMarketingTermsChecked)}
                {renderCheckbox(form.newAccountTerms, setNewAccountTermsChecked)}
            </View>
            <View style={styles.separator} />
            <View style={styles.buttons}>
                <View style={styles.button}>
                    <Button
                        noBackground
                        onPress={cancelDeleting}
                        text={T.screens.user.cancelDelete}
                    />
                </View>
                <View style={styles.buttonSeparator} />
                <View style={styles.button}>
                    <Button
                        isLoading={isLoading}
                        onPress={handleConfirm}
                        text={T.common.confirm}
                        disabled={!iAllChecked}
                    />
                </View>
            </View>
        </SectionBox>
    )
}

const stylesheet = createStyles(theme => ({
    buttons: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginVertical: theme.utils.gap(3)
    },
    textRow: {
        marginVertical: theme.utils.gap(2)
    },
    deleteFormTitle: {
        fontSize: 20
    },
    checkboxWrapper: {
        marginTop: theme.utils.gap(2),
        paddingRight: theme.utils.gap(3)
    },
    separator: {
        height: 2,
        width: '100%',
        backgroundColor: theme.colors.darkSilver
    },
    buttonSeparator: {
        width: theme.utils.gap(2)
    },
    button: {
        flex: {
            lg: undefined,
            xs: 1
        },
        width: 120
    }
}))
