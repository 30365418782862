import React from 'react'
import { View } from 'react-native'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { Typography } from 'lib/components'
import { Children } from 'lib/types'
import { AlertSeverity } from './types'
import { useAlertColors } from './hooks'

type AlertProps = {
    message: string,
    actions?: Children,
    testID?: string,
    type?: AlertSeverity
}

export const Alert: React.FunctionComponent<AlertProps> = ({
    message,
    testID,
    actions,
    type = AlertSeverity.Warning
}) => {
    const { styles } = useStyles(stylesheet)
    const color = useAlertColors(AlertSeverity[type])

    return (
        <View
            testID={testID}
            accessibilityLabel={testID}
            style={styles.alertContainer(color.backgroundColor)}
        >
            <View
                style={{
                    ...styles.textContainer,
                    ...actions
                        ? styles.textContainerAndActions
                        : {}
                }}
            >
                <Typography.Regular
                    bold
                    numberOfLines={1}
                    style={styles.text}
                    forceColor={color.text}
                >
                    {message}
                </Typography.Regular>
            </View>
            {
                actions ? (
                    <View style={styles.alertActionsContainer}>
                        {actions}
                    </View>
                ) : null
            }
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    alertContainer: (backgroundColor: string) => ({
        backgroundColor,
        flexDirection: 'row',
        minHeight: theme.components.alert.minHeight
    }),
    textContainer: {
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center'
    },
    textContainerAndActions: {
        width: '70%',
        alignItems: 'flex-start',
        paddingLeft: theme.utils.gap(1)
    },
    text: {
        lineHeight: theme.components.alert.text.lineHeight
    },
    alertActionsContainer: {
        width: '30%',
        alignItems: 'flex-end',
        justifyContent: 'center'
    }
}))
