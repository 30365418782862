import React from 'react'
import { Typography } from 'lib/components'

type ChargesComponentProps = {
    name: string | undefined,
    value: string | undefined
}

export const ChargesComponent: React.FunctionComponent<ChargesComponentProps> = ({
    name,
    value
}) => (
    <React.Fragment>
        <Typography.Regular bold>
            {name}
        </Typography.Regular>
        <Typography.Regular bold>
            {value}
        </Typography.Regular>
    </React.Fragment>
)
