import { ENV } from 'lib/config'
import { linkingHelpers } from 'lib/utils'
import { useTranslations } from './useTranslations'

export const useServicePoliciesLinks = () => {
    const T = useTranslations()

    return [
        {
            title: T.components.policies.cancellation,
            onPress: () => linkingHelpers.openUrl(`${ENV.SENDHELPER_MARKETING_SITE_URL}/cancellation-policy`)
        },
        {
            title: T.components.policies.terms,
            onPress: () => linkingHelpers.openUrl(`${ENV.SENDHELPER_MARKETING_SITE_URL}/terms`)
        },
        {
            title: T.components.policies.privacy,
            onPress: () => linkingHelpers.openUrl(`${ENV.SENDHELPER_MARKETING_SITE_URL}/privacy`)
        },
        {
            title: T.components.policies.helpCentre,
            onPress: () => linkingHelpers.openUrl(`${ENV.SENDHELPER_MARKETING_SITE_URL}/help-center/general`)
        }
    ]
}
