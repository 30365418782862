import React from 'react'
import { View } from 'react-native'
import { useForm } from '@codegateinc/react-form-builder-v2'
import { R } from 'lib/utils'
import { AppLayout, Tabs } from 'lib/components'
import { useUserAtom } from 'lib/atoms'
import { createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { useUserProfileTabs } from '../hooks'
import { AddressSearch, UserAddresses } from '../components'
import { SearchAddressShape, useSearchAddressForm } from '../forms'

export const UserAddressScreen: React.FunctionComponent = () => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const userProfileTabs = useUserProfileTabs()
    const [user] = useUserAtom()
    const { form } = useForm<SearchAddressShape>(useSearchAddressForm(), {
        onSuccess: R.T
    })
    const userHaveLocations = user.locations.length > 0

    return (
        <AppLayout title={T.screens.user.title}>
            <View style={styles.tabsContainer}>
                <Tabs tabs={userProfileTabs} />
                {userHaveLocations && (
                    <AddressSearch field={form.searchValue} />
                )}
            </View>
            <UserAddresses
                query={form.searchValue.value}
                userHaveLocations={userHaveLocations}
            />
        </AppLayout>
    )
}

const stylesheet = createStyles(theme => ({
    tabsContainer: {
        zIndex: theme.zIndex[10],
        flexDirection: {
            lg: 'row',
            ':w[, 1150]': 'column'
        },
        alignItems: {
            lg: 'center',
            ':w[, 1150]': undefined
        },
        justifyContent: 'space-between'
    }
}))

