import React from 'react'
import { View } from 'react-native'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { Typography } from 'lib/components'
import { dateHelpers } from 'lib/utils'
import { JobSchedule } from 'lib/types'

type RecurringScheduleProps = {
    schedule: Array<JobSchedule>
}

export const RecurringSchedule: React.FunctionComponent<RecurringScheduleProps> = ({
    schedule
}) => {
    const { styles, theme } = useStyles(stylesheet)
    const activeSchedule = schedule?.filter(item => item.activated)

    if (!schedule) {
        return null
    }

    return (
        <View>
            <View style={styles.wrapper}>
                {activeSchedule.map((item, index) => (
                    <View
                        key={index}
                        style={{
                            ...styles.dateItem,
                            borderRightWidth: index === activeSchedule.length - 1
                                ? undefined
                                : 1
                        }}
                    >
                        <Typography.Regular
                            style={styles.capitalize}
                            forceColor={theme.colors.darkGrey}
                        >
                            {item.day}
                        </Typography.Regular>
                        <Typography.Label>
                            {dateHelpers.selectTimeLabel(item.start_time)}
                        </Typography.Label>
                    </View>
                ))}
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        borderRadius: 8,
        flexDirection: 'row',
        backgroundColor: theme.colors.marble,
        paddingVertical: theme.utils.gap(1),
        marginBottom: theme.utils.gap(2)
    },
    capitalize: {
        textTransform: 'capitalize'
    },
    dateItem: {
        flex: 1,
        alignItems: 'center',
        borderRightWidth: 1,
        borderRightColor: theme.colors.darkSilver
    }
}))
