import { NotificationType } from 'lib/types'
import { useStyles } from './useStyles'

export const useNotificationColor = (type: NotificationType) => {
    const { theme } = useStyles()

    switch (type) {
        case NotificationType.Error:
            return {
                background: theme.components.notificationBox.background.error,
                text: theme.components.notificationBox.typography.error
            }
        case NotificationType.Info:
            return {
                background: theme.components.notificationBox.background.info,
                text: theme.components.notificationBox.typography.info
            }
        case NotificationType.Success:
            return {
                background: theme.components.notificationBox.background.success,
                text: theme.components.notificationBox.typography.success
            }

        default:
            return {
                background: theme.components.notificationBox.background.success,
                text: theme.components.notificationBox.typography.success
            }
    }
}
