import React from 'react'
import { Icons } from 'assets'
import { View, ImageBackground } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { Touchable, Typography } from 'lib/components'
import { CustomFonts, JobType } from 'lib/types'
import { Segment } from 'lib/analytics'
import { R } from 'lib/utils'
import {
    useAutoSelectFrequencyJobTypeAtom,
    useAutoSelectOneTimeJobTypeAtom,
    useSubscriptionRecommendationListAtom,
    useUserAtom
} from 'lib/atoms'
import { redirectToSupply } from 'lib/utils/navigation'
import { FinalPrice } from 'features/bookings/components/FinalPrice'
import { SupplyIcon } from 'features/bookings/components/SupplyIcon'

type SubscriptionRecommendationProps = object

export const SubscriptionRecommendation: React.FunctionComponent<SubscriptionRecommendationProps> = () => {
    const { styles, theme } = useStyles(stylesheet)
    const T = useTranslations()
    const [user] = useUserAtom()
    const navigation = useNavigation()
    const [subscriptionRecommendationList] = useSubscriptionRecommendationListAtom()
    const [, setAutoSelectFrequencyJobTypeAtom] = useAutoSelectFrequencyJobTypeAtom()
    const [, setAutoSelectOneTimeJobTypeAtom] = useAutoSelectOneTimeJobTypeAtom()
    const subscriptionRecommendation = subscriptionRecommendationList?.[0]

    const bookJobBySupplyId = (supplyId: number, jobType: string) => {
        Segment.crossaleMyBookingsClicked({
            serviceType: supplyId
        })

        jobType === JobType.Recurring ?
            setAutoSelectFrequencyJobTypeAtom(true) :
            setAutoSelectOneTimeJobTypeAtom(true)

        redirectToSupply(supplyId, user, navigation)
    }

    const imageUrl = subscriptionRecommendation?.imageUrl
    const supplyDescription = subscriptionRecommendation?.supplyDescription
    const ctaButtonText = subscriptionRecommendation?.ctaButtonText
    const supplyId = subscriptionRecommendation?.supplyId
    const supplyName = subscriptionRecommendation?.supplyName
    const finalPrice = subscriptionRecommendation?.finalPrice
    const jobType = subscriptionRecommendation?.jobType

    return R.isDefined(supplyId) ? (
        <View style={styles.wrapper}>
            <View style={styles.wrapperContainer}>
                <View style={styles.imageContainer}>
                    <ImageBackground
                        source={{ uri: imageUrl }}
                        style={styles.imageBackground}
                        imageStyle={styles.image}
                    >
                        <Typography.Label style={styles.badgeContainer}>
                            <View style={styles.iconContainer}>
                                <SupplyIcon supplyId={supplyId as number}/>
                            </View>
                            <Typography.Label style={styles.supplyName}>{supplyName}</Typography.Label>
                        </Typography.Label>
                    </ImageBackground>
                </View>
                <View style={styles.informationContainer}>
                    <View style={styles.descriptionContainer}>
                        <Typography.Regular style={styles.title}>
                            {supplyDescription}
                        </Typography.Regular>
                        <FinalPrice price={finalPrice as string} />
                    </View>
                    <View style={styles.actionContainer}>
                        <Touchable testID={T.accessibility.bookingsScreen.subscriptionRecommendations.normal} style={styles.bookNowButton} onPress={() => bookJobBySupplyId(supplyId as number, jobType as string)}>
                            <View style={styles.spacer}/>
                            <Typography.Regular style={styles.bookNowText}>{ctaButtonText}</Typography.Regular>
                            <View style={styles.bookNowIcon}>
                                <Icons.Chevron size={12} forceColor={theme.colors.sun}/>
                            </View>
                        </Touchable>
                    </View>
                </View>
            </View>
        </View>
    ) : null
}

const stylesheet = createStyles(theme => ({
    wrapperContainer: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    descriptionContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        paddingBottom: theme.utils.gap(2)
    },
    title: {
        fontSize: 20,
        fontFamily: CustomFonts.Poppins500,
        lineHeight: 24,
        wordBreak: 'break-word',
        whiteSpace: 'pre-line',
        flex: 1
    },
    priceContainer: {
        alignItems: 'flex-end',
        flex: {
            xl: 0.5,
            md: 0.4,
            xs: 0.4
        }
    },
    informationContainer: {
        padding: theme.utils.gap(2),
        justifyContent: 'space-between'
    },
    supplyName: {
        color: theme.colors.white,
        fontSize: 14,
        fontFamily: CustomFonts.Roboto400
    },
    badgeContainer: {
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundColor: theme.colors.orange,
        paddingHorizontal: theme.utils.gap(1),
        paddingVertical: theme.utils.gap(0.5),
        borderTopLeftRadius: 8,
        borderBottomRightRadius: 8
    },
    iconContainer: {
        marginRight: 6
    },
    imageBackground: {
        minHeight: {
            xl: undefined,
            lg: 200,
            md: 220,
            sm: 220,
            xs: 220
        },
        flex: 1
    },
    image: {
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        flex: 1
    },
    imageContainer: {
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        flex: 1,
        minHeight: {
            xl: '50%'
        },
        maxHeight: {
            xl: '50%'
        }
    },
    actionContainer: {
        userSelect: 'none',
        alignItems: 'flex-end'
    },
    wrapper: {
        flexDirection: 'column',
        justifyContent: 'space-between',
        position: 'relative',
        flex: 1,
        height: '100%',
        minHeight: {
            xl: 310,
            lg: 'max-content',
            md: 'max-content',
            sm: 'max-content',
            xs: 'max-content'
        },
        width: {
            xl: 270,
            lg: '100%',
            xs: '100%'
        },
        borderRadius: 8,
        ...theme.utils.createShadow(2, 0, 8, 4, 4, theme.colors.webShadow(0.1))
    },
    bookNowButton: {
        borderRadius: 4,
        borderColor: theme.colors.sun,
        borderWidth: 2,
        borderStyle: 'solid',
        paddingVertical: theme.utils.gap(1.25),
        paddingHorizontal: theme.utils.gap(2.25),
        flexDirection: 'row',
        justifyContent: {
            xl: 'center',
            lg: 'space-between',
            xs: 'space-between'
        },
        alignItems: 'center',
        width: {
            xl: 'max-content',
            lg: '100%',
            xs: '100%'
        }

    },
    bookNowIcon: {
        transform: [{ rotate: '270deg' }],
        marginLeft: theme.utils.gap(0.5)
    },
    spacer: {
        width: theme.utils.gap(1)
    },
    bookNowText: {
        color: theme.colors.sun
    }
}))
