import React from 'react'
import { View } from 'react-native'
import { Icons } from 'assets'
import { createStyles } from 'lib/styles'
import { Typography } from 'lib/components'
import { useStyles, useTranslations } from 'lib/hooks'

export const NoticeOfReschedulingMessage: React.FunctionComponent = () => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)

    return (
        <View style={styles.wrapper}>
            <View style={styles.icon}>
                <Icons.Info size={18} forceColor={theme.colors.night} />
            </View>
            <Typography.Regular forceColor={theme.colors.night} style={styles.text}>
                {T.components.currentJobSession.noticeOfReschedulingMessagePart1}
                <Typography.Regular forceColor={theme.colors.night} bold>
                    {` ${T.components.currentJobSession.noticeOfReschedulingMessagePart2} `}
                </Typography.Regular>
                {T.components.currentJobSession.noticeOfReschedulingMessagePart3}
            </Typography.Regular>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        flex: 1,
        marginTop: theme.utils.gap(2),
        flexDirection: 'row',
        paddingVertical: theme.utils.gap(1),
        justifyContent: 'flex-start'
    },
    text: {
        marginLeft: theme.utils.gap(0.75)
    },
    icon: {
        minWidth: 18,
        minHeight: 18
    }
}))
