import React, { useEffect, useState } from 'react'
import { Dimensions, Image, View } from 'react-native'
import { Measurements, isAndroid } from 'lib/common'
import { AnimatedModal, Button, Touchable, Typography } from 'lib/components'
import { useAppUpdateListener, useStyles, useTranslations } from 'lib/hooks'
import { createStyles, theme } from 'lib/styles'
import { linkingHelpers } from 'lib/utils'
import { ENV } from 'lib/config'
import { Icons, Images } from 'assets'

export const GeneralUpdateListener: React.FunctionComponent = () => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const { shouldGeneralUpdate } = useAppUpdateListener()
    const [isVisible, setIsVisible] = useState(false)

    useEffect(() => {
        setIsVisible(shouldGeneralUpdate)
    }, [shouldGeneralUpdate])

    const modalHeight = Measurements.WindowHeight / 2 + (Dimensions.get('screen').height - Dimensions.get('window').height)

    const onClose = () => setIsVisible(false)

    return (
        <AnimatedModal
            isVisible={isVisible}
            backdropHeight={Measurements.WindowHeight - modalHeight}
            onClose={onClose}
        >

            {() => (
                <View
                    style={{
                        ...styles.container,
                        height: modalHeight
                    }}
                >
                    <Touchable
                        style={styles.close}
                        onPress={() => setIsVisible(false)}
                    >
                        <Icons.Close size={14} />
                    </Touchable>
                    <View style={styles.backgroundContainer}>
                        <Image source={Images.GeneralUpdateBackground} style={styles.backgroundImage} />
                    </View>
                    <View style={styles.content}>
                        <Typography.Heading style={styles.heading}>
                            {'New update is here'}
                        </Typography.Heading>
                        <Typography.Regular style={styles.message} forceColor={theme.colors.darkGrey}>
                            {'We made improvements to give you a smoother experience with our latest app version'}
                        </Typography.Regular>
                        <View style={styles.separator} />
                        <Typography.Regular style={styles.whatsNewHeading} forceColor={theme.colors.grey} bold>
                            {'What’s new?'}
                        </Typography.Regular>
                        <Typography.Regular forceColor={theme.colors.darkGrey}>
                            {'New Features, Bug Fixes, Better Performance and Improved security'}
                        </Typography.Regular>
                    </View>
                    <View style={styles.ctaContainer}>
                        <Button
                            height={48}
                            textStyles={styles.ctaText}
                            text={T.components.forceUpdateListener.cta}
                            onPress={() => {
                                linkingHelpers.openUrl(isAndroid
                                    ? ENV.GOOGLE_PLAY_STORE_URL
                                    : ENV.APP_STORE_URL
                                )
                            }}
                        />
                    </View>
                </View>
            )
            }
        </AnimatedModal >
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        backgroundColor: theme.colors.white,
        paddingHorizontal: theme.utils.gap(2),
        justifyContent: 'flex-end'
    },
    backgroundImage: {
        width: 120,
        height: 120
    },
    backgroundContainer: {
        alignItems: 'center'
    },
    heading: {
        marginTop: theme.utils.gap(3),
        marginBottom: theme.utils.gap(2),
        textAlign: 'center'
    },
    message: {
        marginTop: theme.utils.gap(1),
        textAlign: 'center'
    },
    whatsNewHeading: {
        fontSize: theme.fontSize[16],
        marginBottom: theme.utils.gap(1)
    },
    separator: {
        borderBottomWidth: 1,
        borderColor: theme.colors.silver,
        marginVertical: theme.utils.gap(2)
    },
    close: {
        alignItems: 'flex-end',
        marginTop: - theme.utils.gap(2)
    },
    ctaContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        paddingTop: theme.utils.gap(0.5)
    },
    ctaText: {
        fontWeight: 'bold',
        fontSize: theme.fontSize[18]
    },
    content: {
        paddingHorizontal: theme.utils.gap(1.5)
    }
}))
