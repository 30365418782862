import React from 'react'
import { View } from 'react-native'
import { useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { Typography, Touchable, FormComponents } from 'lib/components'
import { BookingFrequency, FrequencyOption } from 'lib/types'

interface FrequencySelectProps {
    frequency?: FrequencyOption,
    selectWeeklyPlan?(): void,
    selectBiWeeklyPlan?(): void,
}

export const FrequencySelect: React.FunctionComponent<FrequencySelectProps> = ({
    frequency,
    selectWeeklyPlan,
    selectBiWeeklyPlan
}) => {
    const T = useTranslations()
    const isWeeklySelected = frequency?.value === BookingFrequency.Weekly
    const isBiWeeklySelected = frequency?.value === BookingFrequency.Fortnightly
    const shouldShowFrequencySelect = isWeeklySelected || isBiWeeklySelected
    const { styles } = useStyles(stylesheet)

    return (
        shouldShowFrequencySelect ? (
            <FormComponents.FormRow>
                <Typography.Regular bold>
                    {T.servicesPlan.frequencyTitle}
                </Typography.Regular>
                <View style={styles.subscriptionFrequencyContainer}>
                    <Touchable
                        onPress={selectWeeklyPlan}
                        testID={T.accessibility.cleaningSubscriptionFrequencyWeekly}
                        style={{
                            ...styles.subscriptionFrequencyButton,
                            ...isWeeklySelected ? styles.subscriptionFrequencySelectedButton : {}
                        }}
                    >
                        <Typography.Regular style={isWeeklySelected ? styles.subscriptionFrequencySelectedLabel : {}}>
                            {T.servicesPlan.weekly}
                        </Typography.Regular>
                    </Touchable>
                    <View style={styles.spacer}/>
                    <Touchable
                        onPress={selectBiWeeklyPlan}
                        testID={T.accessibility.cleaningSubscriptionFrequencyBiWeekly}
                        style={{
                            ...styles.subscriptionFrequencyButton,
                            ...isBiWeeklySelected ? styles.subscriptionFrequencySelectedButton : {}
                        }}
                    >
                        <Typography.Regular style={isBiWeeklySelected ? styles.subscriptionFrequencySelectedLabel : {}}>
                            {T.servicesPlan.biWeekly}
                        </Typography.Regular>
                    </Touchable>
                </View>
            </FormComponents.FormRow>
        ) : null
    )
}

const stylesheet = createStyles(theme => ({
    subscriptionFrequencyContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingVertical: theme.utils.gap(1)
    },
    subscriptionFrequencyButton: {
        flex: 1,
        borderWidth: 1,
        alignItems: 'center',
        paddingVertical: theme.utils.gap(1),
        paddingHorizontal: theme.utils.gap(2),
        borderRadius: theme.components.button.borderRadius
    },
    subscriptionFrequencySelectedButton: {
        borderColor: theme.colors.sun,
        backgroundColor: theme.colors.yellow
    },
    subscriptionFrequencySelectedLabel: {
        color: theme.colors.sun
    },
    spacer: {
        width: theme.utils.gap(1)
    }
}))
