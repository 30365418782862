import { SelectInputOption } from 'lib/types'

export enum EditProfileFields {
    Name = 'name',
    PhoneNumber = 'phoneNumber',
    Email = 'email'
}

export type EditProfileFormShape = {
    [EditProfileFields.Name]: string,
    [EditProfileFields.PhoneNumber]: string,
    [EditProfileFields.Email]: string
}

export enum ChangePasswordFields {
    CurrentPassword = 'currentPassword',
    NewPassword = 'newPassword',
    ConfirmNewPassword = 'confirmNewPassword'
}

export type ChangePasswordFormShape = {
    [ChangePasswordFields.CurrentPassword]: string,
    [ChangePasswordFields.NewPassword]: string,
    [ChangePasswordFields.ConfirmNewPassword]: string
}

export enum DeleteAccountFields {
    HistoryTerms = 'historyTerms',
    InformationTerms = 'informationTerms',
    MarketingTerms = 'marketingTerms',
    NewAccountTerms = 'newAccountTerms'
}

export type DeleteAccountShape = {
    [DeleteAccountFields.HistoryTerms]: boolean,
    [DeleteAccountFields.InformationTerms]: boolean,
    [DeleteAccountFields.MarketingTerms]: boolean,
    [DeleteAccountFields.NewAccountTerms]: boolean
}

export enum NewAddressFields {
    Postcode = 'postcode',
    AddressLine = 'addressLine',
    UnitFloor = 'unitFloor',
    Block = 'block'
}

export type NewAddressShape = {
    [NewAddressFields.Postcode]: SelectInputOption,
    [NewAddressFields.AddressLine]: string,
    [NewAddressFields.UnitFloor]: string,
    [NewAddressFields.Block]: string
}

export enum SearchAddressFields {
    SearchValue = 'searchValue',
}

export type SearchAddressShape = {
    [SearchAddressFields.SearchValue]: string
}
