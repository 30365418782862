import React from 'react'
import { View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { Icons } from 'assets'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { Measurements } from 'lib/common'
import { DrawerProps, ScreenNames } from 'lib/routing'
import { Touchable } from '../Touchable'
import { SmallSubheading } from '../typography'
import { BackButton } from '../BackButton'

type ServiceHeaderNativeProps = {
    title: string,
    onInfoPress: VoidFunction
}

export const ServiceHeaderNative: React.FunctionComponent<ServiceHeaderNativeProps> = ({
    title,
    onInfoPress
}) => {
    const { styles, theme } = useStyles(stylesheet)
    const navigation = useNavigation<DrawerProps<ScreenNames>>()

    return (
        <View style={styles.container}>
            <View style={styles.title}>
                <BackButton
                    customStyles={styles.backButton}
                    onPress={() => navigation.goBack()}
                />
                <SmallSubheading>
                    {title}
                </SmallSubheading>
            </View>
            <Touchable onPress={onInfoPress}>
                <View>
                    <Icons.Info
                        size={20}
                        forceColor={theme.colors.orange}
                    />
                </View>
            </Touchable>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        zIndex: theme.zIndex[10],
        flexDirection: 'row',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: theme.colors.white,
        paddingHorizontal: theme.utils.gap(2),
        paddingTop: Measurements.StatusBarHeight,
        minHeight: Measurements.HeaderWithStatusBarHeight
    },
    title: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    backButton: {
        backgroundColor: theme.colors.white
    }
}))
