import { useField } from '@codegateinc/react-form-builder-v2'
import { bookingsHooks, TimeSlot } from 'features/bookings'
import { useTranslations } from 'lib/hooks'
import { dateHelpers } from 'lib/utils'
import { BookingFrequency, FrequencyOption, Nullable, SupplyID } from 'lib/types'
import { getServiceStaticConfig } from 'features/bookings/utils'
import { RescheduleFields } from './forms'

export const useRescheduleLaundryForm = (timeSlots: Array<TimeSlot>, initialDate?: string) => {
    const T = useTranslations()
    const config = getServiceStaticConfig(SupplyID.Laundry)
    const frequencyOptions = bookingsHooks.useFrequencyOptions()
    const bookingDaysInitialValue = bookingsHooks.useBookingDays()

    const frequency = useField<Nullable<FrequencyOption>>({
        key: RescheduleFields.Frequency,
        initialValue: frequencyOptions.find(option => option.value === BookingFrequency.OneTime) || null,
        isRequired: false,
        validateOnBlur: false,
        label: T.screens.laundry.formFields.frequency.label
    })

    const startingDate = useField<string>({
        key: RescheduleFields.StartingDate,
        initialValue: initialDate
            ? dateHelpers.calendarDate(initialDate)
            : '',
        isRequired: true,
        validateOnBlur: false,
        label: T.screens.laundry.formFields.startingDate.label,
        placeholder: T.screens.laundry.formFields.startingDate.placeholder,
        validationRules: [
            {
                errorMessage: T.screens.laundry.formFields.startingDate.validation.isRequired,
                validate: Boolean
            }
        ]
    })

    const bookingTime = useField<string>({
        key: RescheduleFields.BookingTime,
        initialValue: initialDate
            ? dateHelpers.selectTimeFormat(initialDate)
            : '',
        validateOnBlur: true,
        isRequired: frequency.value?.value === BookingFrequency.OneTime,
        placeholder: T.screens.laundry.formFields.selectTime.placeHolder,
        validationRules: [
            {
                errorMessage: T.screens.laundry.formFields.selectTime.validation.isRequired,
                validate: Boolean
            },
            {
                errorMessage: `${config.min_notice_hours} ${T.screens.laundry.formFields.selectTime.validation.minimumHourNotice}`,
                validate: value => {
                    // todo add generic value type to select inputs
                    const matchingTimeSlot = timeSlots.find(timeSlot => timeSlot.display === value)

                    if (!matchingTimeSlot) {
                        return false
                    }

                    const startDateTime = dateHelpers.getStartDateOfTimeSlot(matchingTimeSlot, startingDate.value)

                    return dateHelpers.validateNoticeHours(startDateTime, config.min_notice_hours)
                }
            }
        ]
    })

    const bookingDays = useField<Record<string, Nullable<string>>>({
        key: RescheduleFields.BookingDays,
        initialValue: bookingDaysInitialValue,
        validateOnBlur: true,
        isRequired: false,
        placeholder: T.screens.laundry.formFields.selectTime.placeHolder
    })

    return {
        frequency,
        startingDate,
        bookingTime,
        bookingDays
    }
}
