import { SectionListData } from 'react-native'
import { BasePrice, ItemPrices, LaundryService } from '../types'

type FilterProps<T> = {
    array: Array<T>,
    query?: string,
    activeService?: LaundryService
}
const checkQuery = (query: string, text: string) => text?.toLowerCase().includes(query?.toLowerCase())

const filterItemServices = ({ array, activeService, query }: FilterProps<BasePrice>) => array
    .filter(item => {
        const isMatchingService = !activeService || item?.title === activeService
        const isMatchingQuery = !query || checkQuery(query, item?.title)

        return isMatchingService && isMatchingQuery
    }) as Array<BasePrice>

const filterItems = ({ array, activeService, query }: FilterProps<ItemPrices>) => array.reduce((acc, item) => {
    // load wash have other services underneath
    if (item?.sub_title === LaundryService.LoadWash && query !== LaundryService.LoadWash) {
        return []
    }

    const filteredServices = filterItemServices({
        array: item?.sub_content,
        activeService,
        query: (query && checkQuery(query, item?.sub_title))
            ? undefined
            : query
    })

    return filteredServices?.length > 0
        ? acc.concat({
            ...item,
            sub_content: filteredServices
        })
        : acc
}, [] as Array<ItemPrices>)

export const filterPriceSections = ({ array, activeService, query }: FilterProps<SectionListData<ItemPrices>>) => {
    // load and curtains wash services are recognizable by their parent name
    // they still can have other services underneath
    const queryServices = [LaundryService.LoadWash, LaundryService.CurtainsWash]

    return array.reduce((acc, itemCategory) => {
        const isQueryService = queryServices.includes(activeService as LaundryService)
        const childrenQuery = (query && checkQuery(query, itemCategory?.title))
            ? undefined
            : query

        const filteredCategories = filterItems({
            array: itemCategory?.data as Array<ItemPrices>,
            activeService: isQueryService
                ? undefined
                : activeService,
            query: isQueryService
                ? activeService
                : childrenQuery
        })

        return filteredCategories?.length > 0
            ? acc.concat({
                ...itemCategory,
                data: filteredCategories
            })
            : acc
    }, [] as Array<SectionListData<ItemPrices>>)
}
