import { Platform } from 'react-native'
import * as Updates from 'expo-updates'
import Constants from 'expo-constants'

const pkg = require('../../../package.json')

export const getAppVersionForPlatform = () => {
    switch (Platform.OS) {
        case 'android':
            return `${Constants.manifest?.version || Updates.runtimeVersion} (${Constants.manifest?.android?.versionCode || Updates.updateId?.slice(0, 4) || '-'})`
        case 'ios':
            return `${Constants.manifest?.version || Updates.runtimeVersion} (${Constants.manifest?.ios?.buildNumber || Updates.updateId?.slice(0, 4) || '-'})`
        case 'web':
            return `${pkg.version}`
        default:
            return ''
    }
}
