import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const Arrow: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path d="M5.134 44.114H62.48L37.427 69.167c-2.003 2.003-2.003 5.288 0 7.29a5.113 5.113 0 0 0 7.239 0l33.832-33.832a5.113 5.113 0 0 0 0-7.24L44.717 1.503a5.113 5.113 0 0 0-7.24 0 5.113 5.113 0 0 0 0 7.239L62.48 33.846H5.134C2.31 33.846 0 36.156 0 38.98c0 2.823 2.31 5.134 5.134 5.134Z" />
    </Icon>
)
