import { datadogRum } from '@datadog/browser-rum'
import { APP_CONFIG, ENV } from '../config'

// we need to match native config: Promise<void>
export const initDataDog = (): Promise<void> => {
    if (!APP_CONFIG.DATADOG.ENABLED) {
        return Promise.resolve()
    }

    datadogRum.init({
        applicationId: APP_CONFIG.DATADOG.APP_ID as string,
        clientToken: APP_CONFIG.DATADOG.CLIENT_TOKEN as string,
        site: APP_CONFIG.DATADOG.SITE as string,
        env: ENV.APP_ENV as string,
        service: APP_CONFIG.DATADOG.SERVICE,
        defaultPrivacyLevel: 'mask-user-input',
        trackResources: true,
        trackLongTasks: true,
        trackUserInteractions: true,
        version: APP_CONFIG.APP.WEB_VERSION,
        trackSessionAcrossSubdomains: true
    })

    return Promise.resolve()
}
