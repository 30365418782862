import React from 'react'
import { Icons } from 'assets'
import { View } from 'react-native'
import { createStyles, theme } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { Typography } from 'lib/components'
import { dateHelpers } from 'lib/utils'
import { SessionRescheduleRequest } from 'lib/models/Job'

type OneOffBookingTimeProps = {
    rescheduleRequest: SessionRescheduleRequest,
    isCurrentTime?: boolean
}

export const OneOffBookingTime: React.FunctionComponent<OneOffBookingTimeProps> = ({
    rescheduleRequest,
    isCurrentTime
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const dateTime = isCurrentTime ? rescheduleRequest.popup.currentDate : rescheduleRequest.popup.requestedDate
    const forceColor = isCurrentTime ? theme.colors.mouse : theme.colors.orange

    return (
        <View style={styles.datetimeWrapper}>
            <View style={styles.rescheduleTimeContainer}>
                <View style={styles.datetimeContainer}>
                    <Icons.DateAndTime
                        size={24}
                        forceColor={forceColor}
                    />
                    <Typography.Regular style={styles.datetimeText} testID={
                        isCurrentTime ?
                            T.accessibility.jobDetailsScreen.rescheduleRequestDetail.currentOneOffDate :
                            T.accessibility.jobDetailsScreen.rescheduleRequestDetail.requestedOneOffDate
                    }>
                        {dateHelpers.bookingFullDate(dateTime)}
                    </Typography.Regular>
                </View>
                <View style={styles.spacer}/>
                <View style={styles.datetimeContainer}>
                    <Icons.Clock
                        size={24}
                        forceColor={forceColor}
                        viewBox="0 0 25 25"
                    />
                    <Typography.Regular style={styles.datetimeText} testID={
                        isCurrentTime ?
                            T.accessibility.jobDetailsScreen.rescheduleRequestDetail.currentOneOffTime :
                            T.accessibility.jobDetailsScreen.rescheduleRequestDetail.requestedOneOffTime
                    }>
                        {dateHelpers.selectTimeLabel(dateTime)}
                    </Typography.Regular>
                </View>
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    datetimeWrapper: {
        borderRadius: 8,
        backgroundColor: theme.colors.latte,
        padding: theme.utils.gap(1.5),
        marginTop: theme.utils.gap(1.5),
        marginBottom: theme.utils.gap(2),
        alignItems: 'center',
        width: {
            xs: '100%',
            md: undefined
        }
    },
    rescheduleTimeContainer: {
        flex: 1,
        flexDirection: {
            md: 'row',
            xs: 'column'
        },
        justifyContent: 'center'
    },
    datetimeContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: theme.utils.gap(1)
    },
    datetimeText: {
        marginLeft: theme.utils.gap(1),
        fontWeight: '600'
    },
    startText: {
        marginRight: theme.utils.gap(0.5),
        color: theme.colors.orange
    },
    spacer: {
        height: {
            md: 0,
            xs: theme.utils.gap(1)
        }
    }
}))
