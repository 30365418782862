import qs from 'qs'
import * as Clipboard from 'expo-clipboard'
import { Image, Linking } from 'react-native'
import { R } from 'lib/utils'
import { Icons, Images } from 'assets'
import { Segment, SharePlatform } from 'lib/analytics'
import { useUserAtom } from 'lib/atoms'
import { createStyles } from 'lib/styles'
import { useCopyToClipboard, useStyles, useTranslations } from 'lib/hooks'
import { APP_CONFIG } from 'lib/config'
import { useShareReferral } from '../shareReferral'

export const useShareCodeIcons = () => {
    const T = useTranslations()
    const [user] = useUserAtom()
    const promoMessage = `${T.screens.referFriend.shareMessagePartOne} ${user.referral.promo_code} ${T.screens.referFriend.shareMessagePartTwo}`
    const shareUrlParams = qs.stringify({
        text: promoMessage
    })
    const shareTelegramParams = qs.stringify({
        url: APP_CONFIG.SOCIAL.SHARE_URL,
        text: promoMessage
    })
    const mailToUrlParams = qs.stringify({
        body: promoMessage
    })
    const { styles, theme } = useStyles(stylesheet)
    const { onCopyPress } = useCopyToClipboard()
    const { shareSupported, onShare } = useShareReferral({
        title: T.common.sendHelper,
        message: promoMessage
    })

    const shareOptions = [
        {
            label: T.screens.referFriend.shareModal.clipboard,
            render: () => (
                <Icons.Copy
                    size={20}
                    forceColor={theme.colors.orange}
                />
            ),
            onPress: () => {
                Segment.referFriendClicked({
                    sharePlatform: SharePlatform.Clipboard,
                    referralCode: user.referral.promo_code
                })

                onCopyPress(user.referral.promo_code)
                    .catch(R.T)
            }
        },
        {
            label: T.screens.referFriend.shareModal.whatsApp,
            render: () => (
                <Image
                    source={Images.WhatsApp}
                    style={styles.iconImage}
                />
            ),
            onPress: () => {
                Segment.referFriendClicked({
                    sharePlatform: SharePlatform.WhatsApp,
                    referralCode: user.referral.promo_code
                })

                Linking.openURL(`https://api.whatsapp.com/send?${shareUrlParams}`)
            }
        },
        {
            label: T.screens.referFriend.shareModal.telegram,
            render: () => (
                <Image
                    source={Images.Telegram}
                    style={styles.iconImage}
                />
            ),
            onPress: async () => {
                Segment.referFriendClicked({
                    sharePlatform: SharePlatform.Telegram,
                    referralCode: user.referral.promo_code
                })

                await Clipboard.setStringAsync(promoMessage)
                    .then(() => {
                        Linking.openURL(`https://t.me/share/url?${shareTelegramParams}`)
                    })
                    .catch(R.T)
            }
        },
        {
            label: T.screens.referFriend.shareModal.email,
            render: () => (
                <Icons.Mail
                    size={20}
                    forceColor={theme.colors.orange}
                />
            ),
            onPress: async () => {
                Segment.referFriendClicked({
                    sharePlatform: SharePlatform.Email,
                    referralCode: user.referral.promo_code
                })

                Linking.openURL(`mailto:?${mailToUrlParams}`)
            }
        }
    ]

    return shareSupported
        ? [
            ...shareOptions,
            {
                label: T.screens.referFriend.shareModal.other,
                render: () => (
                    <Icons.Share
                        size={20}
                        forceColor={theme.colors.orange}
                    />
                ),
                onPress: async () => {
                    Segment.referFriendClicked({
                        sharePlatform: SharePlatform.Other,
                        referralCode: user.referral.promo_code
                    })

                    onShare()
                        .catch(R.T)
                }
            }
        ]
        : shareOptions
}

const stylesheet = createStyles(() => ({
    iconImage: {
        width: 24,
        height: 24
    }
}))
