import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const CheckCircleFilled: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path fillRule="evenodd" clipRule="evenodd" d="M9.80372 5.59601L7.28105 8.90867L6.19238 7.51801C5.96572 7.22734 5.54572 7.17667 5.25638 7.40401C4.96638 7.63067 4.91505 8.05001 5.14238 8.33934L6.76305 10.4107C6.88972 10.572 7.08305 10.666 7.28838 10.666H7.29305C7.49905 10.6653 7.69372 10.568 7.81838 10.4033L10.8644 6.40334C11.0877 6.11001 11.0311 5.69267 10.7377 5.46934C10.4437 5.24601 10.0264 5.30334 9.80372 5.59601ZM8.00065 1.33301C4.31865 1.33301 1.33398 4.31834 1.33398 7.99967C1.33398 11.681 4.31865 14.6663 8.00065 14.6663C11.6827 14.6663 14.6673 11.681 14.6673 7.99967C14.6673 4.31834 11.6827 1.33301 8.00065 1.33301Z" fill="#F9B130"/>
    </Icon>
)
