import { formatWithMask, Mask } from 'react-native-mask-input'
import { masks } from 'lib/utils'

export const userCreditCardNumber = (number: string) => {
    const { obfuscated: maskedCreditCardNumber } = formatWithMask({
        text: `000000000000${number}`,
        mask: masks.creditCardObfuscatedMask as Mask
    })

    return maskedCreditCardNumber
}
