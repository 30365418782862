import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const StarFilled: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path
            d="M79.79 26.449c-.526-1.48-1.963-2.526-3.656-2.666L53.04 21.869l-9.126-19.51C43.24.924 41.707 0 40 0c-1.706 0-3.24.925-3.91 2.358l-9.127 19.51-23.097 1.915C2.173 23.926.74 24.973.21 26.449c-.526 1.48-.04 3.101 1.24 4.127l17.458 13.976-5.148 20.7c-.376 1.522.27 3.096 1.654 4.008a4.51 4.51 0 0 0 2.493.74 4.55 4.55 0 0 0 2.18-.55L40 58.576 59.91 69.45c1.46.797 3.297.724 4.677-.189 1.383-.912 2.03-2.486 1.654-4.008l-5.148-20.7L78.55 30.576c1.28-1.026 1.767-2.645 1.24-4.127Z"
        />
    </Icon>
)
