export const extractPriceParts = (finalPrice: string) => {
    const regex: RegExp = /([A-Za-z$]+)?\s?(\d+(\.\d+)?)\s?([A-Za-z/]+)?/
    const match: RegExpMatchArray | null = finalPrice?.match(regex)
    const priceParts = {
        prefix: '',
        price: '',
        suffix: ''
    }

    if (match) {
        const [, prefix,
            price, , suffix] = match
        priceParts.prefix = prefix || ''
        priceParts.price = price || ''
        priceParts.suffix = suffix || ''
    }

    return priceParts
}
