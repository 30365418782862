import React, { useState } from 'react'
import { Grid } from 'lib/components'
import { Nullable } from 'lib/types'
import { Breakpoint } from 'lib/styles'
import { OTPVerifyCode } from 'lib/firebase'
import { NavigationParams, ScreenNames } from 'lib/routing'
import { useIsWithinBreakpoints, useTranslations } from 'lib/hooks'
import { AuthLayout, OTPForm } from '../components'

type AuthVerificationCodeNativeScreeProps = {
    route: NavigationParams<ScreenNames.AuthVerificationCodeNative>
}

export const AuthVerificationCodeNativeScreen: React.FunctionComponent<AuthVerificationCodeNativeScreeProps> = ({ route }) => {
    const T = useTranslations()
    const isMobile = useIsWithinBreakpoints(Breakpoint.XS, Breakpoint.MD)
    const [verifyCode, setVerifyCode] = useState<Nullable<OTPVerifyCode>>(route.params.verifyCode)

    return (
        <AuthLayout
            title={!isMobile
                ? T.screens.authForgotPassword.welcome
                : undefined
            }
            message={!isMobile
                ? T.screens.authForgotPassword.welcomeMessage
                : undefined
            }
        >
            <Grid.Gap gapTop={10}>
                <OTPForm
                    authUser={route.params.authUser}
                    setVerifyCode={setVerifyCode}
                    onVerifyCode={verifyCode as OTPVerifyCode}
                />
            </Grid.Gap>
        </AuthLayout>
    )
}
