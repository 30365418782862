import { useTranslations } from 'lib/hooks'
import { BookingFrequency } from 'lib/types'

export const useCookingFrequencyOptions = config => {
    const T = useTranslations()
    const pricePerHour = config.pricing.frequency.options.filter(item => item.frequency === 'onetime')[0].price
    const recurringPrice = config.pricing.frequency.options.filter(item => item.frequency === 'recurring')[0].price

    return [
        {
            value: BookingFrequency.OneTime,
            label: T.components.frequency.oneTime.title,
            title: T.components.frequency.oneTime.title,
            description: T.components.frequency.oneTime.description,
            list: [
                `${T.components.frequency.options.startingFrom} ${pricePerHour.currency} ${pricePerHour.amount} ${pricePerHour.perUnit}`
            ]
        },
        {
            value: BookingFrequency.Weekly,
            label: T.components.frequency.weekly.title,
            title: T.components.frequency.weekly.title,
            description: T.components.frequency.weekly.description,
            list: [
                `${recurringPrice.currency} ${recurringPrice.amount} ${recurringPrice.perUnit}`,
                T.components.frequency.options.cancelAnyTime
            ]
        },
        {
            value: BookingFrequency.Fortnightly,
            label: T.components.frequency.fortnightly.title,
            title: T.components.frequency.fortnightly.title,
            description: T.components.frequency.fortnightly.description,
            list: [
                `${recurringPrice.currency} ${recurringPrice.amount} ${recurringPrice.perUnit}`,
                T.components.frequency.options.cancelAnyTime
            ]
        }
    ]
}
