import { useField } from '@codegateinc/react-form-builder-v2'
import currency from 'currency.js'
import { R } from 'lib/utils'
import { useTranslations } from 'lib/hooks'
import { SendPayCreditsFields } from './forms'

export const useSendPayCredits = () => {
    const T = useTranslations()

    const amount = useField<string>({
        key: SendPayCreditsFields.Amount,
        initialValue: '',
        isRequired: true,
        validateOnBlur: true,
        placeholder: T.components.sendPayModal.formFields.amount.placeHolder,
        validationRules: [
            {
                errorMessage: T.components.sendPayModal.formFields.amount.validation.isRequired,
                validate: Boolean
            },
            {
                errorMessage: T.components.sendPayModal.formFields.amount.validation.isInvalid,
                validate: value => R.isValidNumber(Number(value)) && !value.endsWith('.')
            },
            {
                errorMessage: T.components.sendPayModal.formFields.amount.validation.isTooSmall,
                validate: value => parseInt(value, 10) >= 20
            }
        ],
        liveParser: value => {
            if (!value || value === '.' || value === ',') {
                return ''
            }

            const [intValue, floatingValue] = value.split('.')

            // value is invalid
            if (isNaN(Number(intValue)) || (floatingValue && isNaN(Number(floatingValue)))) {
                return value.slice(0, -1)
            }

            if (!floatingValue || floatingValue.length === 1) {
                return value
            }

            return currency(value).toString()
        }
    })

    return {
        amount
    }
}
