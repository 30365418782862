import React from 'react'
import { View } from 'react-native'
import { useLocaleAtom } from 'lib/atoms'
import { BasicModal, Grid, Touchable, Typography } from 'lib/components'
import { useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { VoidFunction } from 'lib/types'
import { dateHelpers } from 'lib/utils'
import { JobDetailsResponse } from '../types'
import { JobRating } from './JobRating'

type JobRatingModalProps = {
    isVisible: boolean,
    onClose: VoidFunction,
    job: JobDetailsResponse
}

export const JobRatingModal: React.FunctionComponent<JobRatingModalProps> = ({
    isVisible,
    onClose,
    job
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const [locale] = useLocaleAtom()
    const serviceDate = job?.job?.date_time
        ? `${dateHelpers.getFullBookingListViewDate(job?.job?.date_time, locale)}`
        : ''

    return (
        <BasicModal
            isOpen={isVisible}
            onClose={onClose}
        >
            <View style={styles.container}>
                <View style={styles.row}>
                    <Typography.Regular bold>
                        {job?.job?.supply?.name}
                    </Typography.Regular>
                    <Typography.Label forceColor={theme.colors.fog}>
                        {` ${T.common.by} ${job?.job?.supplier?.handle} - ${serviceDate}`}
                    </Typography.Label>
                </View>
                <Grid.Gap gapBottom={2}/>
                <View style={styles.separator}/>
                <JobRating
                    job={job}
                    disableBorders
                    style={styles.content}
                />
                <Touchable onPress={onClose}>
                    <Typography.Label forceColor={theme.colors.mouse}>
                        {T.components.rating.skip}
                    </Typography.Label>
                </Touchable>
            </View>
        </BasicModal>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        flex: 1,
        alignItems: 'center',
        paddingTop: theme.utils.gap(3)
    },
    row: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        paddingHorizontal: {
            lg: theme.utils.gap(6),
            xs: 0
        }
    },
    separator: {
        width: '100%',
        height: 1,
        backgroundColor: theme.colors.fog
    },
    content: {
        marginTop: 0 // override
    }
}))
