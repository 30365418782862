import { useEffect } from 'react'
import { useResetFormAtom } from 'lib/atoms'

export const useResetFormCallback = (callback: VoidFunction) => {
    const [resetForm, setResetForm] = useResetFormAtom()

    useEffect(() => {
        if (resetForm) {
            callback()
            setResetForm(false)
        }
    }, [resetForm, callback])
}
