import React from 'react'
import { Children } from 'lib/types'
import { Modal } from 'lib/components'

type ProposalActionModalProps = {
    isLoading: boolean,
    renderIcon?: () => Children,
    isOpen: boolean,
    onConfirm: VoidFunction,
    onClose: VoidFunction,
    onCancel: VoidFunction,
    confirmText: string,
    cancelText: string,
    title: string,
    message: string
}

export const ProposalActionModal: React.FunctionComponent<ProposalActionModalProps> = ({
    isLoading,
    renderIcon,
    isOpen,
    onConfirm,
    onCancel,
    onClose,
    cancelText,
    confirmText,
    title,
    message
}) => (
    <Modal
        disabled={isLoading}
        isLoading={isLoading}
        renderIcon={renderIcon}
        isOpen={isOpen}
        confirmText={confirmText}
        cancelText={cancelText}
        onConfirm={onConfirm}
        onCancel={onCancel}
        onClose={onClose}
        title={title}
        message={message}
    />
)

