import React, { useState } from 'react'
import { View } from 'react-native'
import { Field, UseFormReturn } from '@codegateinc/react-form-builder-v2'
import { BookingFrequency, Nullable, SelectInputOption, SupplyID } from 'lib/types'
import { useAddressAtom, useIsAuthorizedAtom, useUserAtom } from 'lib/atoms'
import { isNative } from 'lib/common'
import { PaymentType } from 'lib/models'
import { Segment } from 'lib/analytics'
import { NewAddressShape } from 'features/user'
import { Breakpoint, createStyles } from 'lib/styles'
import { useIsWithinBreakpoints, useStyles, useTranslations } from 'lib/hooks'
import { Adapter, Address, Button, FormComponents, MediaQuery, PolicyLinks } from 'lib/components'
import { GetPromotionResponse, JobQuotationResponse } from '../types'
import { ServiceLayout } from './ServiceLayout'
import { PaymentMethodTile } from './PaymentMethodTile'
import { BookingCancellationFees } from './BookingCancellationFees'
import { ServiceAddonsFormShape, CookingFormShape, ServiceAddonsFields } from '../forms'
import { useCookingBreadcrumbs, usePetsOptions } from '../hooks'
import { PromotionCodeInput } from './PromotionCodeInput'
import { Rated } from './Rated'
import { MobileSummary } from './MobileSummary'
import { SummaryNewAddressForm } from './SummaryNewAddressForm'
import { CookingSummaryColumn } from './CookingSummaryColumn'
import { RecurringBookingSchedule } from './RecurringBookingSchedule'
import { ServiceDescription } from './ServiceDescription'
import { CookingHelpModalContent } from './CookingHelpModalContent'
import { TotalPriceComponent } from './TotalPriceComponent'
import { BookingAuth } from './unauthorized'
import { PriceSummaryComponent } from './PriceSummaryComponent'
import { SubTotalComponent } from './SubTotalComponent'

type CookingSummaryViewProps = {
    disabled: boolean,
    selectedPaymentMethod: PaymentType,
    setPaymentView: (value: boolean) => void,
    formReturn: UseFormReturn<ServiceAddonsFormShape>,
    addressForm: Record<keyof NewAddressShape, Field<SelectInputOption & string>>,
    formShape: CookingFormShape,
    isLoading: boolean,
    totalPrice: number,
    additionalInformation: string,
    submit: VoidFunction,
    setPaymentMethod: (value: PaymentType) => void,
    promotionPackage: Nullable<GetPromotionResponse>,
    originalPrice?: Nullable<number>,
    requestPromotion: (code: string) => void,
    onRemovePromoCode: VoidFunction,
    isOneTimePricing: boolean,
    isRecurring: boolean,
    jobQuotation?: JobQuotationResponse,
    fetchPostalCodes(value: string): Promise<Array<SelectInputOption>>
}

export const CookingSummaryView: React.FunctionComponent<CookingSummaryViewProps> = ({
    formReturn,
    disabled,
    formShape,
    totalPrice,
    originalPrice,
    requestPromotion,
    setPaymentView,
    promotionPackage,
    isLoading,
    addressForm,
    submit,
    isRecurring,
    isOneTimePricing,
    onRemovePromoCode,
    setPaymentMethod,
    selectedPaymentMethod,
    additionalInformation,
    fetchPostalCodes,
    jobQuotation
}) => {
    const T = useTranslations()
    const [user] = useUserAtom()
    const [address] = useAddressAtom()
    const { styles } = useStyles(stylesheet)
    const petsOptions = usePetsOptions()
    const breadcrumbs = useCookingBreadcrumbs()
    const [isHelpModalOpen, setHelpModalOpen] = useState(false)
    const [isAuthorized] = useIsAuthorizedAtom()
    const { form } = formReturn
    const [authenticationView, setAuthenticationView] = useState(false)
    const notEnoughCredits = selectedPaymentMethod === PaymentType.Credits && user.consumer.balance_credit < totalPrice
    const isMobile = useIsWithinBreakpoints(Breakpoint.XS, Breakpoint.MD)
    const columnWidth = isNative || isMobile
        ? undefined
        : 'calc(50% - 10px)'
    const confirmDisabled = isAuthorized
        ? disabled
        : false
    const handleConfirm = () => {
        if (!isAuthorized) {
            return setAuthenticationView(true)
        }

        submit()
    }

    return (
        <ServiceLayout
            onInfoPress={() => setHelpModalOpen(true)}
            title={T.screens.cooking.title}
            withBackground
            authenticationView={authenticationView}
            onBackFromDetails={() => {
                setAuthenticationView(false)
            }}
            breadcrumbs={breadcrumbs}
            contentColumn={authenticationView
                ? (
                    <BookingAuth
                        isRecurring={isRecurring}
                        supplyId={SupplyID.Cooking}
                        notEnoughCredits={notEnoughCredits}
                        selectedPaymentMethod={selectedPaymentMethod}
                        setPaymentMethod={setPaymentMethod}
                    />
                ) : (
                    <View>
                        {isNative && (
                            <ServiceDescription
                                isHelpModalOpen={isHelpModalOpen}
                                setHelpModalOpen={setHelpModalOpen}
                                title={T.screens.cooking.title}
                                modalContent={(
                                    <CookingHelpModalContent />
                                )}
                                description={T.screens.cooking.serviceDescription}
                            />
                        )}
                        <View style={styles.wrapper}>
                            <FormComponents.FormRow title={T.screens.cleaningSummary.formFields.allergies.label}>
                                <Adapter.RadioCheckbox
                                    {...form.allergies}
                                    disabled={isLoading}
                                    onChangeValue={value => {
                                        Segment.bookingFoodAllergiesSelected({
                                            allergies: value
                                        })

                                        form.allergies.onChangeValue(value)
                                    }}
                                />
                                {form.allergies.value && (
                                    <Adapter.TextInput
                                        {...form.allergiesComment}
                                        disabled={isLoading}
                                        inputProps={{
                                            multiline: true
                                        }}
                                    />
                                )}
                            </FormComponents.FormRow>
                            <FormComponents.FormRow
                                isOptional
                                title={T.screens.cookingSummary.sections.additionalInformation}
                            >
                                <View
                                    style={{
                                        ...styles.selectWrapper,
                                        width: columnWidth
                                    }}
                                >
                                    <Adapter.SelectInputColumn
                                        {...form.pets}
                                        disabled={isLoading}
                                        options={petsOptions}
                                        onOptionSelect={({ label }) => {
                                            Segment.bookingAdditionalInformationAdded({
                                                information: {
                                                    type: ServiceAddonsFields.Pets,
                                                    value: label
                                                }
                                            })
                                        }}
                                    />
                                </View>
                                <Adapter.TextInput
                                    {...form.comment}
                                    extraContent={additionalInformation}
                                    inputProps={{
                                        multiline: true
                                    }}
                                    onBlur={() => {
                                        if (form.comment.value) {
                                            Segment.bookingAdditionalInformationAdded({
                                                information: {
                                                    type: ServiceAddonsFields.Comment,
                                                    value: form.comment.value
                                                }
                                            })
                                        }
                                    }}
                                />
                                <View>
                                    {!address.postcode && isAuthorized && (
                                        <SummaryNewAddressForm
                                            form={addressForm}
                                            isLoading={isLoading}
                                            fetchPostalCodes={fetchPostalCodes}
                                        />
                                    )}
                                </View>
                            </FormComponents.FormRow>
                        </View>
                        {formShape.frequency.value !== BookingFrequency.OneTime && (
                            <FormComponents.FormRow>
                                <RecurringBookingSchedule
                                    title={T.screens.cookingSummary.schedule}
                                    frequency={formShape.frequency}
                                    oneTimeDate={formShape.bookingTime}
                                    weeklyDays={formShape.bookingDays}
                                />
                            </FormComponents.FormRow>
                        )}
                        {isMobile && (
                            <PolicyLinks />
                        )}
                    </View>
                )
            }
            summaryColumn={(
                <MediaQuery.Visible from={Breakpoint.LG}>
                    {!(isAuthorized && authenticationView) && (
                        <View>
                            <Address disabled={isLoading} />
                        </View>
                    )}
                    <CookingSummaryColumn
                        totalPrice={totalPrice}
                        originalPrice={originalPrice}
                        promotionPackage={promotionPackage}
                        title={T.common.bookingSummary}
                        renderPriceComponent={() => (
                            <TotalPriceComponent
                                totalPrice={totalPrice}
                                originalPrice={originalPrice}
                                promotion={promotionPackage?.promotion}
                                customText={T.screens.jobDetails.sections.totalAmount}
                            />
                        )}
                        renderPriceSummaryComponent={() => jobQuotation?.priceSummary ? (
                            <PriceSummaryComponent priceSummary={jobQuotation.priceSummary} promotion={jobQuotation.discount} />
                        ) : undefined}
                        renderSubTotalComponent={isRecurring && jobQuotation?.subTotal ? () => (
                            <SubTotalComponent subTotal={jobQuotation?.subTotal} />
                        ) : undefined}
                        form={formShape}
                        couponText={promotionPackage
                            ? promotionPackage.promotion.message_to_client
                            : undefined
                        }
                        renderExtraContent={() => (
                            <View>
                                <PromotionCodeInput
                                    promoCode={form.promoCode}
                                    isLoading={isLoading}
                                    onRemovePromoCode={onRemovePromoCode}
                                    promotionPackage={promotionPackage}
                                    requestPromotion={requestPromotion}
                                />
                            </View>
                        )}
                    />
                    {!authenticationView && (
                        <PaymentMethodTile
                            notEnoughCredits={notEnoughCredits}
                            onPress={() => setPaymentView(true)}
                            selectedPaymentMethod={selectedPaymentMethod}
                        />
                    )}
                    <PolicyLinks />
                    {(!authenticationView || isAuthorized) && (
                        <Button
                            disabled={notEnoughCredits || confirmDisabled}
                            isLoading={isLoading}
                            onPress={handleConfirm}
                            text={T.common.confirm}
                        />
                    )}
                    <BookingCancellationFees />
                </MediaQuery.Visible>
            )}
            footer={(
                <MediaQuery.Hidden from={Breakpoint.LG}>
                    <MobileSummary
                        authenticationView={authenticationView && !isAuthorized}
                        submit={handleConfirm}
                        perHour={!isOneTimePricing}
                        submitText={T.common.confirm}
                        priceLabel={T.common.totalPrice}
                        price={totalPrice}
                        priceBeforeDiscount={originalPrice}
                        isLoading={isLoading}
                        disabled={notEnoughCredits || confirmDisabled}
                        promotionPackage={promotionPackage}
                        onRemovePromoCode={onRemovePromoCode}
                        promoCode={form.promoCode}
                        requestPromotion={requestPromotion}
                        content={(
                            <React.Fragment>
                                <View>
                                    <Address disabled={isLoading} />
                                </View>
                                <CookingSummaryColumn
                                    totalPrice={totalPrice}
                                    originalPrice={originalPrice}
                                    promotionPackage={promotionPackage}
                                    renderPriceComponent={() => (
                                        <TotalPriceComponent
                                            totalPrice={totalPrice}
                                            originalPrice={originalPrice}
                                            promotion={promotionPackage?.promotion}
                                            customText={T.screens.jobDetails.sections.totalAmount}
                                        />
                                    )}
                                    renderPriceSummaryComponent={() => jobQuotation?.priceSummary ? (
                                        <PriceSummaryComponent priceSummary={jobQuotation.priceSummary} promotion={jobQuotation.discount} />
                                    ) : undefined}
                                    renderSubTotalComponent={isRecurring && jobQuotation?.subTotal ? () => (
                                        <SubTotalComponent subTotal={jobQuotation?.subTotal} />
                                    ) : undefined}
                                    form={formShape}
                                    couponText={promotionPackage
                                        ? promotionPackage.promotion.message_to_client
                                        : undefined
                                    }
                                    // remove this check after guest booking experiment
                                    renderExtraContent={() => !isAuthorized ? (
                                        <View>
                                            <PromotionCodeInput
                                                promoCode={form.promoCode}
                                                isLoading={isLoading}
                                                onRemovePromoCode={onRemovePromoCode}
                                                promotionPackage={promotionPackage}
                                                requestPromotion={requestPromotion}
                                            />
                                        </View>
                                    ) : null}
                                />
                                {!authenticationView && (
                                    <PaymentMethodTile
                                        notEnoughCredits={notEnoughCredits}
                                        onPress={() => setPaymentView(true)}
                                        selectedPaymentMethod={selectedPaymentMethod}
                                    />
                                )}
                                <PolicyLinks />
                                <Rated />
                                <BookingCancellationFees />
                            </React.Fragment>
                        )}
                    />
                </MediaQuery.Hidden>
            )}

        />
    )
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        marginTop: {
            lg: 0,
            xs: theme.utils.gap(1)
        },
        zIndex: theme.zIndex[10]
    },
    selectWrapper: {
        zIndex: theme.zIndex[10],
        marginTop: -theme.utils.gap(2)
    },
    addonsRow: {
        flexDirection: {
            lg: 'row',
            xs: 'column'
        }
    },
    spacer: {
        width: theme.utils.gap(2)
    }
}))
