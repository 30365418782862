import React, { useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { useRoute } from '@react-navigation/native'
import { NotificationType } from 'lib/types'
import { PaymentType } from 'lib/models'
import { Segment, segmentUtils } from 'lib/analytics'
import { useAddressAtom, useToastAtom } from 'lib/atoms'
import { NavigationParams, ScreenNames } from 'lib/routing'
import { useUserNewAddress } from 'features/user'
import { Breakpoint, createStyles } from 'lib/styles'
import { useIsWithinBreakpoints, useStyles, useTranslations } from 'lib/hooks'
import { Address, Button, CreditCard, SendPay, Typography } from 'lib/components'
import { BookingAuthConfirmationProps } from './types'
import { SummaryNewAddressForm } from '../SummaryNewAddressForm'

export const BookingAuthConfirmation: React.FunctionComponent<BookingAuthConfirmationProps> = ({
    setPaymentMethod,
    selectedPaymentMethod,
    notEnoughCredits,
    isRecurring,
    isMoversAndPackers,
    renderExtraContent
}) => {
    const T = useTranslations()
    const route = useRoute<NavigationParams<ScreenNames.Auth>>()
    const [address, setAddress] = useAddressAtom()
    const [, setToastMessage] = useToastAtom()
    const { styles } = useStyles(stylesheet)
    const [isNewCardModalOpen, setIsNewCardModalOpen] = useState(false)
    const isMobile = useIsWithinBreakpoints(Breakpoint.XS, Breakpoint.MD)
    const {
        submit,
        isLoading,
        isFilled,
        form: addressForm,
        hasError,
        isFetchingPostcode,
        fetchPostalCodesWithDebounce
    } = useUserNewAddress({
        postcode: route.params?.postalcode,
        onSuccess: address => {
            setToastMessage({
                message: T.components.bookingAuth.addAddressSuccessMessage,
                type: NotificationType.Success
            })
            setAddress(address)
        }
    })

    const handleSelectPaymentMethod = (value: PaymentType) => {
        Segment.bookingPaymentSwitched({
            selectedPaymentMethod: segmentUtils.getSelectedPaymentMethod(value)
        })

        setPaymentMethod(value)
    }

    return (
        <View style={styles.wrapper}>
            {!isMoversAndPackers && (
                <React.Fragment>
                    {address.postcode && (
                        <Address
                            disabled={isLoading}
                            title={T.screens.handymanSummary.serviceLocation}
                        />
                    )}
                    {!address.postcode && (
                        <View>
                            <View style={styles.zIndexWrapper}>
                                <SummaryNewAddressForm
                                    form={addressForm}
                                    isLoading={isLoading}
                                    customStyle={styles.addressFormWrapper}
                                    title={T.components.bookingAuth.enterAddressTitle}
                                    fetchPostalCodes={fetchPostalCodesWithDebounce}
                                />
                            </View>
                            <View style={styles.buttonWrapper}>
                                <Button
                                    width={!isMobile ? 125 : undefined}
                                    isLoading={isLoading || isFetchingPostcode}
                                    text={T.common.save}
                                    onPress={submit}
                                    disabled={!isFilled || hasError}
                                />
                            </View>
                        </View>
                    )}
                </React.Fragment>
            )}
            {renderExtraContent && renderExtraContent()}
            <View style={styles.separator} />
            <View
                style={{
                    ...styles.paymentsContainer,
                    opacity: !address.postcode && !isMoversAndPackers
                        ? 0.35
                        : 1
                }}
            >
                {!address.postcode && !isMoversAndPackers && (
                    <View style={styles.overlay} />
                )}
                <Typography.Title bold>
                    {T.screens.payment.paymentMethod}
                </Typography.Title>
                <View style={styles.description}>
                    <Typography.Regular>
                        {T.screens.payment.paymentMethodDescription}
                    </Typography.Regular>
                </View>
                <CreditCard
                    onSelect={handleSelectPaymentMethod}
                    isModalOpen={isNewCardModalOpen}
                    setIsModalOpen={setIsNewCardModalOpen}
                    selectedPaymentMethod={selectedPaymentMethod}
                    customStyles={styles.noBorder}
                />
                {!isRecurring && (
                    <SendPay
                        customStyles={styles.noBorder}
                        onSelect={handleSelectPaymentMethod}
                        notEnoughCredits={notEnoughCredits}
                        selectedPaymentMethod={selectedPaymentMethod}
                    />
                )}
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        marginTop: {
            xs: 0,
            lg: -theme.utils.gap(4)
        },
        zIndex: theme.zIndex[10]
    },
    zIndexWrapper: {
        zIndex: theme.zIndex[10]
    },
    paymentsContainer: {
        borderRadius: 8,
        borderWidth: 1,
        borderColor: theme.colors.silver,
        paddingVertical: theme.utils.gap(5) / 2,
        paddingHorizontal: {
            lg: theme.utils.gap(3),
            xs: theme.utils.gap(2)
        },
        marginBottom: theme.utils.gap(4),
        backgroundColor: theme.colors.white,
        ...theme.utils.createShadow(2, 0, 8, 4, 4, theme.colors.webShadow(0.04))
    },
    description: {
        marginTop: theme.utils.gap(1),
        marginBottom: theme.utils.gap(2)
    },
    noBorder: {
        borderBottomWidth: 0
    },
    separator: {
        height: 1,
        backgroundColor: theme.colors.silver,
        marginBottom: theme.utils.gap(4)
    },
    addressFormWrapper: {
        borderWidth: 0,
        paddingHorizontal: 0
    },
    buttonWrapper: {
        alignItems: 'flex-end',
        marginBottom: theme.utils.gap(4)
    },
    overlay: {
        zIndex: theme.zIndex[10],
        ...StyleSheet.absoluteFillObject
    }
}))
