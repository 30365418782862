import React from 'react'
import { Field } from '@codegateinc/react-form-builder-v2'
import { Calendar } from 'lib/components'
import { FrequencyOption, Nullable } from 'lib/types'

interface DatePickerProps extends Field<string> {
    frequency: FrequencyOption,
    selectedDays: Record<string, Nullable<string>>,
    minHoursNotice: number,
    disabled?: boolean,
    isMultiSelect?: boolean,
    liteMode?: boolean,
    maxDate?: string,
    allowToggle?: boolean,
    fullScreen?: boolean,
    selectedDaysChangeValue: (value: Record<string, Nullable<string>>) => void,
}

export const DatePicker: React.FunctionComponent<DatePickerProps> = ({
    value,
    frequency,
    disabled,
    selectedDays,
    minHoursNotice,
    onChangeValue,
    maxDate,
    isMultiSelect,
    allowToggle,
    liteMode,
    fullScreen,
    selectedDaysChangeValue
}) => (
    <Calendar
        liteMode={liteMode}
        fullScreen={fullScreen}
        maxDate={maxDate}
        disabled={disabled}
        selectedDate={value}
        frequency={frequency}
        selectedDays={selectedDays}
        minHoursNotice={minHoursNotice}
        isMultiSelect={isMultiSelect}
        allowToggle={allowToggle}
        onDayPress={onChangeValue}
        selectedDaysChangeValue={selectedDaysChangeValue}
    />
)
