import React from 'react'
import { View } from 'react-native'
import { BookingFrequency, Nullable } from 'lib/types'
import { createStyles } from 'lib/styles'
import { ListBullet, Typography } from 'lib/components'
import { useStyles, useTranslations } from 'lib/hooks'
import { dateHelpers } from 'lib/utils'

type CookingSchedulePreviewScreenProps = {
    frequency: BookingFrequency,
    bookingDays: Record<string, Nullable<string>>
}

export const CookingSchedulePreviewScreen: React.FunctionComponent<CookingSchedulePreviewScreenProps> = ({
    frequency,
    bookingDays
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const selectedDays = Object.keys(bookingDays).filter(key => Boolean(bookingDays[key]))

    if (selectedDays.length === 0 || frequency === BookingFrequency.OneTime) {
        return null
    }

    return (
        <View style={styles.wrapper}>
            <Typography.Label forceColor={theme.colors.darkGrey}>
                {frequency === BookingFrequency.Weekly
                    ? T.screens.cooking.cookingWeeklyScheduleInfo
                    : T.screens.cooking.cookingFortnightlyScheduleInfo
                }
            </Typography.Label>
            {selectedDays.map((dayKey, index) => (
                <ListBullet key={index}>
                    <Typography.Label bold>
                        {`${dayKey} ${T.common.at.toLowerCase()} ${dateHelpers.selectTimeLabel(bookingDays[dayKey] || new Date())}`}
                    </Typography.Label>
                </ListBullet>
            ))}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        marginTop: theme.utils.gap(2)
    }
}))
