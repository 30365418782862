import React from 'react'
import { View } from 'react-native'
import { createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { Typography } from 'lib/components'

export const PaymentMessage: React.FunctionComponent = () => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)

    return (
        <View style={styles.container}>
            <Typography.Regular>
                {T.screens.paymentAndCards.message}
            </Typography.Regular>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        marginVertical: theme.utils.gap(2),
        paddingHorizontal: {
            lg: 0,
            xs: theme.utils.gap(2)
        }
    }
}))
