import React from 'react'
import { View } from 'react-native'
import { Icons } from 'assets'
import { createStyles } from 'lib/styles'
import { Touchable, Typography } from 'lib/components'
import { useStyles, useTranslations } from 'lib/hooks'

type HandymanServiceDetailsFooterProps = {
    isAdding: boolean,
    onRemove: VoidFunction,
    onAddDetails: VoidFunction,
    onRemoveDetails: VoidFunction
}

export const HandymanServiceDetailsFooter: React.FunctionComponent<HandymanServiceDetailsFooterProps> = ({
    isAdding,
    onRemove,
    onAddDetails,
    onRemoveDetails
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)

    return (
        <View style={styles.row}>
            <Touchable onPress={onRemove}>
                <View style={styles.row}>
                    <Icons.Delete size={11} />
                    <Typography.Error style={styles.label}>
                        {T.common.remove}
                    </Typography.Error>
                </View>
            </Touchable>
            <Touchable
                onPress={isAdding
                    ? onRemoveDetails
                    : onAddDetails
                }
            >
                <View style={styles.row}>
                    {isAdding
                        ? (
                            <Icons.Minus size={11} />
                        )
                        : (
                            <Icons.Plus size={11} />
                        )
                    }
                    <Typography.Error style={styles.label}>
                        {isAdding
                            ? T.components.handymanServiceSelect.lessDetails
                            : T.components.handymanServiceSelect.addDetails
                        }
                    </Typography.Error>
                </View>
            </Touchable>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    label: {
        marginTop: theme.utils.gap(1) / 4,
        marginLeft: theme.utils.gap(1) / 4
    }
}))
