import React from 'react'
import { View } from 'react-native'
import { Icons } from 'assets'
import { Adapter, Button, Grid, Touchable, Typography } from 'lib/components'
import { useBackHandler, useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { useUserNewAddress } from '../hooks'

type AddressSetupFormProps = {
    postcode?: string,
    onCancel: VoidFunction,
    onSuccess: VoidFunction
}

export const AddressSetupForm: React.FunctionComponent<AddressSetupFormProps> = ({
    postcode,
    onCancel,
    onSuccess
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const {
        submit,
        isLoading,
        isFilled,
        form,
        hasError,
        fetchPostalCodesWithDebounce,
        isFetchingPostcode
    } = useUserNewAddress({
        postcode,
        isInitialAddressSetup: true,
        onSuccess
    })

    useBackHandler(() => {
        if (!isLoading) {
            onCancel()
        }

        return true
    })

    return (
        <View style={styles.container}>
            <View style={styles.inputWrapper}>
                <Adapter.AsyncSelectInput
                    {...form.postcode}
                    loadingOptions={fetchPostalCodesWithDebounce}
                    disabled={isLoading}
                />
            </View>
            <Grid.Gap gapBottom={2}/>
            <Adapter.TextInput
                {...form.addressLine}
                disabled={isLoading}
            />
            <View style={styles.row}>
                <View style={styles.flex}>
                    <Adapter.TextInput
                        {...form.block}
                        disabled={isLoading}
                    />
                </View>
                <Grid.Gap gapRight={2}/>
                <View style={styles.flex}>
                    <Adapter.TextInput
                        {...form.unitFloor}
                        disabled={isLoading}
                    />
                </View>
            </View>
            <Button
                onPress={submit}
                text={T.common.save}
                isLoading={isLoading || isFetchingPostcode}
                disabled={!isFilled || hasError}
                renderIcon={() => (
                    <Icons.Arrow
                        size={14}
                        forceColor={!isFilled || hasError
                            ? theme.colors.fog
                            : undefined
                        }
                    />
                )}
            />
            <Grid.Gap gapBottom={2}/>
            <Touchable
                disabled={isLoading}
                onPress={() => onCancel()}
            >
                <Typography.Label
                    bold
                    forceColor={theme.colors?.orange}
                    style={styles.text}
                >
                    {T.common.skip}
                </Typography.Label>
            </Touchable>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        marginTop: theme.utils.gap(2)
    },
    inputWrapper: {
        minHeight: theme.components.input.height,
        zIndex: theme.zIndex[10]
    },
    row: {
        flexDirection: 'row'
    },
    flex: {
        flex: 1
    },
    text: {
        textAlign: 'center'
    }
}))
