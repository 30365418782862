import { useRef, useState } from 'react'
import { datadogRum } from '@datadog/browser-rum'
import { client as xmpp, jid, xml } from '@xmpp/client'
import { imageActions } from 'features/image'
import { Nullable } from 'lib/types'
import { APP_CONFIG } from 'lib/config'
import { R } from 'lib/utils'
import { fetchImageMetadata, fromStanzaToChatMessage } from '../utils'
import {
    ChatClientEvents,
    ChatMessage,
    ChatMessageType,
    ChatProvider,
    ConnectionStatus,
    XMPPClient,
    XMPPErrorCode
} from '../types'

export const useXMPPClient = (token: string) => {
    const chatClient = useRef<Nullable<XMPPClient>>(null)
    const { mutateAsync: getImage } = imageActions.getS3ImageDownloadURL()
    const [chatConnectionStatus, setChatConnectionStatus] = useState<ConnectionStatus>(ConnectionStatus.Connect)
    const initClient = (onInitialized: VoidFunction) => {
        const jidVal = jid(`${token}${APP_CONFIG.CHAT.SUFFIX_SERVER_CHAT_NAME}`)
        const client = xmpp({
            service: APP_CONFIG.CHAT.WEBSOCKET_URL,
            domain: jidVal.getDomain(),
            username: jidVal.getLocal(),
            password: token
        })

        client.on(ChatClientEvents.Status, status => {
            setChatConnectionStatus(status)

            if (status === ConnectionStatus.Online) {
                chatClient.current = client
                onInitialized()
            }
        })

        // listener MUST be attached to avoid uncaught exceptions
        client.on(ChatClientEvents.Error, error => {
            if (error?.code !== XMPPErrorCode.ConnectionError) {
                datadogRum.addError(new Error(error))
            }
        })

        client
            .start()
            .catch(R.T)
    }
    const createTextMessage = (roomName: string, text: string): typeof xml => {
        const payload = {
            type: ChatMessageType.Text,
            payload: {
                body: text
            }
        }

        return xml(
            'message',
            {
                type: ChatMessageType.GroupChat,
                to: roomName
            },
            xml('body', {}, JSON.stringify(payload))
        )
    }
    const logError = (error: typeof xml) => {
        const errorXML = error.getChild('text')

        if (!errorXML || !errorXML.children) {
            return
        }

        const [errorMessage] = errorXML.children

        datadogRum.addError(new Error(errorMessage))
    }

    return {
        chatConnectionStatus,
        chat: {
            join: (roomName: string, messageHandler: (message: ChatMessage) => void): Promise<void> => {
                if (!chatClient.current) {
                    return Promise.reject()
                }

                return chatClient
                    .current
                    .send(xml('presence'))
                    .then(() => chatClient.current!.send(
                        xml('presence', { to: `${roomName}/${token}` }),
                        xml('x', { xmlns: 'http://jabber.org/protocol/muc' }),
                        xml('history', { maxstanzas: '0' }) // send no history
                    ))
                    .then(() => {
                        chatClient.current!.on(ChatClientEvents.Stanza, async stanza => {
                            if (!stanza.is('message')) {
                                return
                            }

                            const error = stanza.getChild(ChatClientEvents.Error)

                            if (error) {
                                return logError(error)
                            }

                            const parsedMessage = fromStanzaToChatMessage(stanza)

                            if (!parsedMessage) {
                                return
                            }

                            if (parsedMessage.payload?.type === ChatMessageType.Photo) {
                                const imageMetadata = await fetchImageMetadata(parsedMessage.payload.body, token, getImage)

                                return messageHandler({
                                    ...parsedMessage,
                                    payload: {
                                        ...parsedMessage.payload,
                                        metadata: imageMetadata
                                    }
                                })
                            }

                            messageHandler(parsedMessage)
                        })
                    })
            },
            sendTextMessage: (roomName: string, text: string): Promise<void> => {
                if (!chatClient.current) {
                    return Promise.reject()
                }

                const message = createTextMessage(roomName, text)

                return chatClient.current.send(message)
            },
            sendImageMessage: (roomName: string, imageUrl: string, text?: string): Promise<void> => {
                if (!chatClient.current) {
                    return Promise.reject()
                }

                const payload = {
                    type: ChatMessageType.Photo,
                    payload: {
                        body: imageUrl
                    }
                }

                const imageMessage = xml(
                    'message',
                    {
                        type: ChatMessageType.GroupChat,
                        to: roomName
                    },
                    xml('body', {}, JSON.stringify(payload))
                )

                return chatClient
                    .current
                    .send(imageMessage)
                    .then(() => {
                        if (text && chatClient.current) {
                            return chatClient.current.send(createTextMessage(roomName, text))
                        }
                    })
            },
            leave: (roomName: string): Promise<void> => {
                if (!chatClient.current) {
                    return Promise.reject()
                }

                return chatClient.current
                    .send(xml('presence', { type: 'unavailable', to: `${roomName}/${token}` }))
                    .then(() => chatClient.current!.send(xml('unavailable')))
            }
        } as ChatProvider,
        initClient: (onInitialized: VoidFunction) => {
            if (!chatClient.current) {
                initClient(onInitialized)
            }
        },
        destroyClient: () => {
            chatClient.current?.stop()
            chatClient.current = null
        }
    }
}
