import React from 'react'
import { View } from 'react-native'
import { createStyles } from 'lib/styles'
import { useNotificationColor, useStyles } from 'lib/hooks'
import { Children, NotificationType } from 'lib/types'

type NotificationBarProps = {
    type: NotificationType,
    renderContent: (color: string) => Children
}

export const NotificationBar: React.FunctionComponent<NotificationBarProps> = ({
    type,
    renderContent
}) => {
    const { styles } = useStyles(stylesheet)
    const { background, text } = useNotificationColor(type)

    return (
        <View
            style={{
                ...styles.container,
                backgroundColor: background
            }}
        >
            {renderContent(text)}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        alignItems: 'center',
        padding: theme.utils.gap(1)
    }
}))
