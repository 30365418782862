import React from 'react'
import { View } from 'react-native'
import { Icons } from 'assets'
import { useStyles } from 'lib/hooks'
import { ScrollDirection } from 'lib/types'
import { createStyles, theme } from 'lib/styles'
import { Touchable } from '../Touchable'
import { ITEMS_PER_PAGE } from './consts'

type BookingCalendarModalNavigationProps = {
    currentPage: number,
    dataLength: number,
    onNextPage: VoidFunction,
    onPrevPage: VoidFunction
}

export const BookingCalendarModalNavigation: React.FunctionComponent<BookingCalendarModalNavigationProps> = ({
    currentPage,
    onPrevPage,
    onNextPage,
    dataLength
}) => {
    const { styles } = useStyles(stylesheet)

    const onPress = (direction: ScrollDirection) => {
        if (direction === ScrollDirection.Prev) {
            return onPrevPage()
        }

        return onNextPage()
    }

    return (
        <View style={styles.container}>
            <Touchable
                disabled={currentPage === 0}
                onPress={() => onPress(ScrollDirection.Prev)}
            >
                <View
                    style={currentPage === 0
                        ? {
                            ...styles.button,
                            ...styles.disabled
                        }
                        : styles.button
                    }
                >
                    <View
                        style={{
                            transform: [
                                {
                                    rotateZ: '90deg'
                                }
                            ]
                        }}
                    >
                        <Icons.Chevron
                            size={8}
                            forceColor={theme.colors.mouse}
                        />
                    </View>
                </View>
            </Touchable>
            <Touchable
                disabled={Math.ceil(dataLength / ITEMS_PER_PAGE) - 1 === currentPage}
                onPress={() => onPress(ScrollDirection.Next)}
            >
                <View
                    style={Math.ceil(dataLength / ITEMS_PER_PAGE) - 1 === currentPage
                        ? {
                            ...styles.button,
                            ...styles.disabled
                        }
                        : styles.button
                    }
                >
                    <View
                        style={{
                            transform: [
                                {
                                    rotateZ: '-90deg'
                                }
                            ]
                        }}
                    >
                        <Icons.Chevron
                            size={8}
                            forceColor={theme.colors.mouse}
                        />
                    </View>
                </View>
            </Touchable>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        flexDirection: 'row',
        justifyContent: 'center'
    },
    button: {
        justifyContent: 'center',
        alignItems: 'center',
        width: 50,
        height: 40,
        borderRadius: 4,
        borderWidth: 1,
        borderColor: theme.colors.silver,
        backgroundColor: theme.colors.white
    },
    disabled: {
        opacity: 0.5
    }
}))
