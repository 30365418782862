export const MOCKED_TIME_SLOTS = [
    {
        label: 'Anytime',
        value: 'anytime'
    },
    {
        label: '8am - 1pm',
        value: '8am - 1pm'
    },
    {
        label: '1pm - 8pm',
        value: '1pm - 8pm'
    }
]
