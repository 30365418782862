import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const ArrowDownInCircle: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path d="m47.699 41.382-4.367 4.166v-18.88a3.332 3.332 0 1 0-6.667 0v18.62l-4.31-4.31a3.33 3.33 0 0 0-4.713 0 3.336 3.336 0 0 0 0 4.714l10 9.996c.307.31.677.554 1.083.724.407.17.84.256 1.274.256.43 0 .856-.09 1.256-.253.204-.083.364-.227.54-.347.17-.11.357-.176.507-.32l10-9.543a3.334 3.334 0 1 0-4.603-4.823ZM40 66.668c-14.703 0-26.667-11.963-26.667-26.667 0-14.703 11.964-26.666 26.667-26.666 14.703 0 26.667 11.963 26.667 26.666 0 14.704-11.964 26.667-26.667 26.667Zm0-60c-18.38 0-33.333 14.953-33.333 33.333S21.62 73.335 40 73.335 73.333 58.38 73.333 40C73.333 21.621 58.38 6.668 40 6.668Z" />
    </Icon>
)
