import { useField } from '@codegateinc/react-form-builder-v2'
import { regexes } from 'lib/utils'
import { useTranslations } from 'lib/hooks'
import { SingleSignOnFields } from './forms'

export const useSingleSignOnForm = () => {
    const T = useTranslations()

    const email = useField<string>({
        key: SingleSignOnFields.Email,
        initialValue: '',
        isRequired: true,
        validateOnBlur: true,
        placeholder: T.screens.authSingleSignOn.formFields.email.placeholder,
        label: T.screens.authSingleSignOn.formFields.email.label,
        validationRules: [
            {
                errorMessage: T.screens.authSingleSignOn.formFields.email.validation.isRequired,
                validate: Boolean
            },
            {
                errorMessage: T.screens.authSingleSignOn.formFields.email.validation.isInvalid,
                validate: regexes.isValidEmail
            }
        ]
    })

    return {
        email
    }
}
