import { ScreenNames } from 'lib/routing'
import { useTranslations } from 'lib/hooks'

export const useMoversAndPackersBreadcrumbs = () => {
    const T = useTranslations()

    return [
        {
            text: T.screens.moversAndPackers.breadCrumbs.requirements,
            screenName: ScreenNames.MoversAndPackers
        },
        {
            text: T.screens.moversAndPackers.breadCrumbs.confirmation,
            screenName: ScreenNames.MoversAndPackersSummary
        }
    ]
}
