import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import { useForm } from '@codegateinc/react-form-builder-v2'
import { CustomFonts, Nullable } from 'lib/types'
import { Breakpoint, createStyles, theme } from 'lib/styles'
import { Adapter, FormComponents, Grid, MediaQuery, Typography } from 'lib/components'
import { OTPVerifyCode } from 'lib/firebase'
import { AuthFinishSocialSignUpScreenParams } from 'lib/routing'
import { COUNTRIES } from 'lib/data'
import { AuthTabs, OTPForm, authTypes } from 'features/auth'
import { useStyles, useTranslations } from 'lib/hooks'
import { BookingAuthSignUpForm } from './BookingAuthSignUpForm'

import { GuestBookingFormShape } from './types'
import { useGuestBookingForm } from './GuestBookingDetailsForm'

type BookingAuthFormsProps = {
    authUser: Nullable<authTypes.AuthUser>,
    onCancel: VoidFunction,
    verifyCode: Nullable<OTPVerifyCode>,
    initialActiveTabIndex: number,
    isGuestBookingEnabled?: boolean,
    showGuestBookingForm?: boolean,
    setAreGuestDetailsValid?: (value) => void,
    setShowGuestBookingForm?: (value) => void,
    setGuestDetails?: (value) => void,
    onSuccessfulSignUp: VoidFunction,
    setVerifyCode: (code: Nullable<OTPVerifyCode>) => void,
    onAccountConfirmed: (user: authTypes.AuthUser, verifyCode: OTPVerifyCode) => void
}

export const BookingAuthForms: React.FunctionComponent<BookingAuthFormsProps> = ({
    authUser,
    onCancel,
    setVerifyCode,
    onSuccessfulSignUp,
    verifyCode,
    initialActiveTabIndex,
    onAccountConfirmed,
    isGuestBookingEnabled,
    setAreGuestDetailsValid,
    setGuestDetails,
    setShowGuestBookingForm,
    showGuestBookingForm
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const [socialSignUpParams] = useState<Nullable<AuthFinishSocialSignUpScreenParams>>(null)

    const { form, isFilled, hasError } = useForm<GuestBookingFormShape>(useGuestBookingForm(), {
        onSuccess: () =>  {
            setAreGuestDetailsValid && setAreGuestDetailsValid(isFilled && !hasError)
            setGuestDetails && setGuestDetails(form)
        }
    })

    useEffect(() => {
        setAreGuestDetailsValid && setAreGuestDetailsValid(isFilled && !hasError)
    }, [isFilled, hasError])

    useEffect(() => {
        setGuestDetails && setGuestDetails(form)
    }, [form.address.value, form.country.value, form.name.value, form.phoneNumber.value])

    const renderBookingAuthForms = () => {
        if (showGuestBookingForm) {
            return (
                <View style={styles.guestBookingFormWrapper}>
                    <Typography.Label style={styles.formHeading}>
                        Your personal details
                    </Typography.Label>
                    <View style={styles.formRow}>
                        <View style={styles.labelColumn}>
                            <Typography.Regular>
                                <Typography.Regular>
                                    Full name
                                </Typography.Regular>
                                <Typography.Label forceColor={theme.components.input.typography.error}>
                                    {'*'}
                                </Typography.Label>
                            </Typography.Regular>
                            <MediaQuery.Visible from={Breakpoint.LG}>
                                <FormComponents.ErrorMessage />
                            </MediaQuery.Visible>
                        </View>
                        <View style={styles.fieldValue}>
                            <Adapter.TextInput
                                {...form.name}
                                label="Full Name"
                                inputProps={{
                                    autoCapitalize: 'words',
                                    textContentType: 'name',
                                    testID: 'name'
                                }}
                            />
                        </View>
                    </View>
                    <View style={styles.formRow}>
                        <View style={styles.labelColumn}>
                            <Typography.Regular>
                                <Typography.Regular>
                                   Service Address
                                </Typography.Regular>
                                <Typography.Label forceColor={theme.components.input.typography.error}>
                                    {'*'}
                                </Typography.Label>
                            </Typography.Regular>
                            <MediaQuery.Visible from={Breakpoint.LG}>
                                <FormComponents.ErrorMessage />
                            </MediaQuery.Visible>
                        </View>
                        <View style={styles.fieldValue}>
                            <Adapter.TextInput
                                {...form.address}
                                inputProps={{
                                    autoCapitalize: 'words',
                                    textContentType: 'fullStreetAddress',
                                    testID: 'address'
                                }}
                            />
                        </View>
                    </View>
                    <View style={styles.formRow}>
                        <View style={styles.labelColumn}>
                            <Typography.Regular>
                                <Typography.Regular>
                                    Mobile number
                                </Typography.Regular>
                                <Typography.Label forceColor={theme.components.input.typography.error}>
                                    {'*'}
                                </Typography.Label>
                            </Typography.Regular>
                            <MediaQuery.Visible from={Breakpoint.LG}>
                                <FormComponents.ErrorMessage />
                            </MediaQuery.Visible>
                        </View>
                        <View style={styles.row}>
                            <Grid.Gap gapRight={1}>
                                <Adapter.SelectCountryCodeInput
                                    {...form.country}
                                    isClearable={false}
                                    options={COUNTRIES}
                                />
                            </Grid.Gap>
                            <View style={styles.phoneInput}>
                                <Adapter.TextInput
                                    {...form.phoneNumber}
                                    inputProps={{
                                        keyboardType: 'phone-pad',
                                        contextMenuHidden: true,
                                        textContentType: 'telephoneNumber',
                                        dataDetectorTypes: 'phoneNumber',
                                        testID: 'phone-number'
                                    }}
                                />
                            </View>
                        </View>
                    </View>
                    <Typography.Label style={styles.informationContext}>
                        {T.components.bookingAuth.guestBookingRequiredText}
                    </Typography.Label>
                </View>
            )
        }

        return (
            <View>
                <Typography.SmallSubheading>
                    {T.components.bookingAuth.title}
                </Typography.SmallSubheading>
                <Typography.Regular style={styles.description}>
                    {T.components.bookingAuth.description}
                </Typography.Regular>

                {socialSignUpParams ? (
                    <BookingAuthSignUpForm {...socialSignUpParams} />
                ) : authUser ? (
                    <OTPForm
                        authUser={authUser}
                        onCancel={onCancel}
                        setVerifyCode={setVerifyCode}
                        onSuccessfulSignUp={onSuccessfulSignUp}
                        onVerifyCode={verifyCode as OTPVerifyCode}
                    />
                ) : (
                    <AuthTabs
                        onAccountConfirmed={onAccountConfirmed}
                        initialIndex={initialActiveTabIndex}
                        showGuestBooking={isGuestBookingEnabled}
                        setShowGuestBookingForm={setShowGuestBookingForm}
                    />
                )}
            </View>
        )
    }

    return (
        <View style={styles.wrapper}>
            {renderBookingAuthForms()}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        marginTop: -theme.utils.gap(2),
        marginBottom: theme.utils.gap(4),
        paddingTop: {
            xs: theme.utils.gap(2),
            lg: 0
        }
    },
    description: {
        marginTop: theme.utils.gap(1),
        marginBottom: theme.utils.gap(2)
    },
    row: {
        flex: 1,
        zIndex: theme.zIndex[10],
        flexDirection: 'row'
    },
    phoneInput: {
        flex: 1
    },
    informationContext: {
        fontSize: 13,
        color: theme.colors.grey
    },
    guestBookingFormWrapper: {
        borderRadius: 8,
        borderColor: theme.colors.silver,
        borderWidth: 1,
        padding: theme.utils.gap(3)
    },
    formHeading: {
        fontFamily: CustomFonts.Poppins500,
        fontSize: 16,
        marginBottom: theme.utils.gap(2)
    },
    labelColumn: {
        width: {
            lg: 130,
            xs: '100%'
        },
        marginRight: {
            lg: theme.utils.gap(1),
            xs: 0
        },
        marginBottom: {
            lg: 0,
            xs: theme.utils.gap(1)
        }
    },
    formRow: {
        alignItems: {
            lg: 'baseline',
            xs: 'stretch'
        },
        flexDirection: {
            lg: 'row',
            xs: 'column'
        }
    },
    fieldValue: {
        flexGrow: 1
    }
}))
