import { JobSchedule } from 'lib/types'
import { dateHelpers, R } from 'lib/utils'

export const usePauseDates = (schedule: Array<JobSchedule>, numberOfOptions: number, selectedValue: string) => {
    const getStartOptions = () => {
        if (!schedule || schedule.length === 0) {
            return []
        }

        const activeJobs = schedule
            .filter(job => job.activated)
            .sort((firstDate, secondDate) => firstDate.start_time.localeCompare(secondDate.start_time))
        const [startDate] = activeJobs
        const isMinimumOfSevenDays = !dateHelpers.isDatePast(dateHelpers.subDaysFromDate(startDate.start_time, 7))
        const daysDifference = dateHelpers.getDaysDifferenceFromNow(startDate.start_time)
        const weeksOffset = isMinimumOfSevenDays ? 0 : Math.ceil(daysDifference / 7) + 1

        return R.splitEvery(activeJobs.length)([...new Array(numberOfOptions)]).flatMap((item, index) =>
            item.map((item, currentIndex) => dateHelpers.addDaysToDate(activeJobs[currentIndex].start_time, 7 * (index + weeksOffset)))
        )
    }

    const getEndDates = () => {
        const allDates = getStartOptions()
        const selectedIndex = allDates.findIndex(date => date === selectedValue)

        return allDates.slice(selectedIndex < 0 ? 0 : selectedIndex)
    }

    return {
        startOptions: getStartOptions(),
        endOptions: getEndDates()
    }
}
