import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'
import { useIconColor } from './useIconColor'

export const Share: React.FunctionComponent<IconProps> = props => {
    const color = useIconColor(props.isActive, props.forceColor)

    return  (
        <Icon
            {...props}
            forceColor="transparent"
        >
            <Path
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={5}
                d="M28.947 44.473A9.978 9.978 0 0 0 30 40c0-1.607-.38-3.127-1.053-4.473m0 8.946a10 10 0 1 1 0-8.946m0 8.946 22.106 11.054m-22.106-20 22.106-11.054m0 31.054a10.003 10.003 0 1 0 17.894 8.946 10.003 10.003 0 0 0-17.894-8.946Zm0-31.054a10 10 0 1 0 17.889-8.945 10 10 0 0 0-17.889 8.945Z"
            />
        </Icon>
    )
}
