import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const Phone: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path d="M14.003 9.435a7.756 7.756 0 0 1 10.962 0l.003.002 8.69 8.7c.001.001 0 0 0 0a7.757 7.757 0 0 1 2.273 5.482 7.747 7.747 0 0 1-2.27 5.48 2.088 2.088 0 0 0 0 2.952l13.914 13.914a2.088 2.088 0 0 0 1.474.612 2.081 2.081 0 0 0 1.473-.61l.002-.003a7.756 7.756 0 0 1 10.962 0l8.697 8.69a7.755 7.755 0 0 1 .002 10.963l-2.066 2.065a17.589 17.589 0 0 1-21.98 2.339A135.987 135.987 0 0 1 9.6 33.477l-.01-.015-.021-.032-.033-.05a17.59 17.59 0 0 1 2.403-21.88l2.065-2.065Zm4.005 4.01-2.063 2.062A11.925 11.925 0 0 0 14.3 30.313a130.323 130.323 0 0 0 34.946 34.968l.034.02a11.923 11.923 0 0 0 14.832-1.626l2.063-2.062a2.089 2.089 0 0 0 0-2.952l-8.693-8.687a2.092 2.092 0 0 0-2.953-.002 7.748 7.748 0 0 1-10.961 0L29.653 36.056a7.758 7.758 0 0 1-.001-10.962l.002-.002a2.08 2.08 0 0 0 0-2.945l-8.693-8.703a2.094 2.094 0 0 0-2.953 0Z" />
    </Icon>
)
