import { useTranslations } from 'lib/hooks'
import { LaundryFormShape } from '../forms'
import { useDriverInstructionsOptions } from './useDriverInstructionsOptions'

export const useLaundryAdditionalInfo = () => {
    const T = useTranslations()
    const driverInstructions = useDriverInstructionsOptions()

    const getAdditionalInfoSummary = (laundryForm: LaundryFormShape) => {
        const pickUpInfo = driverInstructions.find(instruction => instruction.value === laundryForm.driverInstructions)
        const services = [
            {
                label: T.screens.laundry.formFields.washAndIron,
                value: laundryForm.washAndIron,
                items: laundryForm.washAndIronItems
            },
            {
                label: T.screens.laundry.formFields.dryCleanAndIron,
                value: laundryForm.dryCleanAndIron,
                items: laundryForm.dryCleanAndIronItems
            },
            {
                label: T.screens.laundry.formFields.ironOnly,
                value: laundryForm.ironOnly,
                items: laundryForm.ironOnlyItems
            },
            {
                label: T.screens.laundry.formFields.loadWash,
                value: laundryForm.loadWash,
                items: laundryForm.loadWashItems
            },
            {
                label: T.screens.laundry.formFields.curtainsWash,
                value: laundryForm.curtainsWash,
                items: laundryForm.curtainsWashItems
            }
        ].filter(service => service?.value && service?.items > 0)

        // parsing pick up and services info into a comment
        const pickUpComment = `${T.screens.laundry.additionalInfo.pickUp}:\n · ${pickUpInfo?.label}\n`
        const servicesList = `${services.map(service => ` · ${service.label.label} - ${service.items} ${T.screens.laundry.additionalInfo.items}\n`)}`.replaceAll(',', '')
        const servicesComment = `${T.screens.laundry.additionalInfo.services}:\n${servicesList}`

        return `${pickUpComment}\n${servicesComment}`
    }

    return {
        getAdditionalInfoSummary
    }
}
