import React, { useEffect } from 'react'
import { View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { Field } from '@codegateinc/react-form-builder-v2'
import { Measurements } from 'lib/common'
import { Adapter, AnimatedModal, Button, Touchable, Typography } from 'lib/components'
import { createStyles } from 'lib/styles'
import { GetPromotionResponse } from '../types'
import { CustomFonts, Nullable } from 'lib/types'
import { linkingHelpers } from 'lib/utils'
import { ENV } from 'lib/config'
import { useKeyboardHeight, useStyles, useTranslations } from 'lib/hooks'
import { useAutoApplyPromoAtom, usePromotionErrorAtom, useResetPromotionErrorAtom } from 'lib/atoms'
import { Icons } from 'assets'

type NativeCouponModalProps = {
    isOpen: boolean,
    isLoading?: boolean,
    showBillingMessage?: boolean,
    promoCode?: Field<string>,
    promotionPackage?: Nullable<GetPromotionResponse>,
    onClose: VoidFunction,
    onRemove?: VoidFunction,
    onApply?: (code: string) => void
}

export const NativeCouponModal: React.FunctionComponent<NativeCouponModalProps> = ({ isOpen, onClose, promoCode = {} as Field<string>, onApply, onRemove, promotionPackage, isLoading, showBillingMessage }) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const [promotionErrorAtom] = usePromotionErrorAtom()
    const [, setResetPromotionError] = useResetPromotionErrorAtom()
    const [autoApplyPromoCode] = useAutoApplyPromoAtom()
    const insets = useSafeAreaInsets()
    const keyboardHeight = useKeyboardHeight()
    const modalHeight = keyboardHeight > 0 ? keyboardHeight : (Measurements.WindowHeight / 3.5)

    const onApplyPromo = () => {
        onApply && promoCode && onApply(promoCode.value)
    }

    const onRemovePromo = () => {
        onRemove && onRemove()
        promoCode?.onChangeValue('')
    }

    useEffect(() => {
        if (autoApplyPromoCode) {
            promoCode?.onChangeValue && promoCode.onChangeValue(autoApplyPromoCode)
            onApply && onApply(autoApplyPromoCode)
        }
    }, [])

    useEffect(() => {
        if (promoCode && promoCode.setError && promotionErrorAtom) {
            promoCode.setError(promotionErrorAtom)
        }
    }, [promotionErrorAtom])

    useEffect(() => () => setResetPromotionError(), [])

    useEffect(() => {
        if (promotionPackage?.promotion?.active && !promotionErrorAtom) {
            setResetPromotionError()
            onClose()
        }
    }, [promotionPackage])

    return (
        <AnimatedModal
            isVisible={isOpen}
            backdropHeight={Measurements.WindowHeight - modalHeight}
            onClose={onClose}
        >

            {({ onModalClose }) => (
                <View
                    style={{
                        ...styles.container,
                        height: modalHeight,
                        paddingBottom: insets.bottom + theme.utils.gap(1)
                    }}
                >
                    <View style={styles.title}>
                        <Typography.Subheading>
                            {T.components.nativeCouponModal.title}
                        </Typography.Subheading>
                        <Touchable
                            onPress={() => {
                                onModalClose()
                            }}
                            hitSlopBottom={20}
                            hitSlopRight={20}
                            hitSlopLeft={20}
                            hitSlopTop={20}
                        >
                            <Icons.Close size={18} />
                        </Touchable>
                    </View>
                    <View style={styles.inputContainer}>
                        {
                            <Adapter.TextInput {...promoCode} rightIcon={() => (promoCode.value ? (
                                <Touchable onPress={onRemovePromo}>
                                    <Icons.Cancel size={16} />
                                </Touchable>
                            ) : null)
                            }
                            testID={T.accessibility.bookingSummaryScreen.discountCodeInput}
                            />
                        }
                        <View style={styles.messageContainer}>
                            {showBillingMessage && (
                                <Typography.Error
                                    forceColor={theme.colors.mouse}
                                >
                                    {T.components.discountCodes.infoMessage}
                                </Typography.Error>
                            )}
                            {promotionPackage && promotionPackage.promotion && (
                                <Touchable
                                    onPress={() => linkingHelpers.openUrl(`${ENV.SENDHELPER_MARKETING_SITE_URL}/terms`)}
                                    style={styles.termsAndConditionButton}>
                                    <Typography.Regular style={styles.termsAndConditionsLink}>
                                        {T.common.viewTermsAndConditions}
                                    </Typography.Regular>
                                </Touchable>
                            )}
                        </View>

                    </View>

                    <View style={styles.button}>
                        <Button
                            testID={T.accessibility.bookingSummaryScreen.applyDiscountCodeButton}
                            isLoading={isLoading}
                            text={T.common.apply}
                            disabled={!promoCode?.value || !!promoCode?.errorMessage}
                            onPress={onApplyPromo}
                        />
                    </View>
                </View>
            )
            }
        </AnimatedModal >
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        backgroundColor: theme.colors.white,
        paddingHorizontal: theme.utils.gap(2)
    },
    title: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    inputContainer: {
        marginTop: theme.utils.gap(2)
    },
    button: {
        flex: 1,
        marginTop: theme.utils.gap(2)
    },
    promoInput: {
        paddingRight: 0
    },
    termsAndConditionsLink: {
        color: theme.colors.darkGrey,
        fontFamily: CustomFonts.Roboto500,
        textDecorationLine: 'underline',
        textTransform: 'capitalize',
        fontSize: 12
    },
    termsAndConditionButton: {
        backgroundColor: theme.colors.transparent,
        userSelect: 'none'
    },
    errorContent: {
        marginTop: 0,
        alignItems: 'flex-start'
    },
    messageContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: theme.utils.gap(0.5)
    }
}))
