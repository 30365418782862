import React, { useEffect, useState } from 'react'
import { Children, Nullable } from 'lib/types'
import { useUserTokenAtom } from 'lib/atoms'
import { useXMPPClient } from './hooks'
import { ChatProvider } from './types'

export const XMPPChatContext = React.createContext<Nullable<ChatProvider>>(null)

type XMPPChatProviderProps = {
    children: Children
}

export const XMPPChatProvider: React.FunctionComponent<XMPPChatProviderProps> = ({ children }) => {
    const [token] = useUserTokenAtom()
    const [chatHandler, setChatHandler] = useState<Nullable<ChatProvider>>(null)
    const { initClient, destroyClient, chat } = useXMPPClient(token)
    const closeConnection = () => {
        destroyClient()
        setChatHandler(null)
    }

    useEffect(() => {
        if (token && !chatHandler) {
            initClient(() => setChatHandler(chat))

            return
        }

        if (!token && chatHandler) {
            return closeConnection()
        }

        return () => {
            if (chatHandler) {
                closeConnection()
            }
        }
    }, [token])

    return (
        <XMPPChatContext.Provider value={chatHandler}>
            {children}
        </XMPPChatContext.Provider>
    )
}
