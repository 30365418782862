import currency from 'currency.js'
import { useTranslations } from './useTranslations'

export const usePrice = (value: number | string, currencyShortCode = false) => {
    const T = useTranslations()

    const formatPrice = (price: number | string) => currency(price, {
        pattern: '! #',
        symbol: currencyShortCode ? T.common.currencyShortCode : T.common.currency
    }).format()

    return formatPrice(value)
}
