import React from 'react'
import { Job, RecurringJob } from 'lib/models'
import { JobStatus, KeyValuePair } from 'lib/types'
import { BookingsCalendar } from 'lib/components'

type UserBookingsCalendarViewProps = {
    data: KeyValuePair<Array<Job>>,
    recurringJobs: Array<RecurringJob>,
    jobStatus: JobStatus
}

export const UserBookingsCalendarView: React.FunctionComponent<UserBookingsCalendarViewProps> = ({
    data,
    recurringJobs,
    jobStatus
}) => (
    <BookingsCalendar
        data={data}
        jobStatus={jobStatus}
        recurringJobs={recurringJobs}
    />
)
