import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const ArrowInCircle: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path d="M40 6.331c-19.009 0-34.419 15.41-34.419 34.419 0 19.009 15.41 34.419 34.419 34.419 19.009 0 34.419-15.41 34.419-34.419 0-19.009-15.41-34.419-34.419-34.419ZM0 40.75c0-22.091 17.909-40 40-40s40 17.909 40 40-17.909 40-40 40-40-17.909-40-40Zm38.027-16.857a2.79 2.79 0 0 1 3.946 0l14.884 14.884a2.79 2.79 0 0 1 0 3.946L41.973 57.607a2.79 2.79 0 1 1-3.946-3.947l10.12-10.12h-23.03a2.79 2.79 0 1 1 0-5.58h23.03l-10.12-10.12a2.79 2.79 0 0 1 0-3.947Z" />
    </Icon>
)
