import { getRemoteConfig, fetchAndActivate, getAll } from '@firebase/remote-config'
import { initializeApp } from 'firebase/app'
import {
    getAuth,
    RecaptchaVerifier,
    signOut as firebaseSignOut,
    signInWithEmailAndPassword as firebaseSignInWithEmailAndPassword,
    signInWithPhoneNumber as firebaseSignInWithPhoneNumber
} from 'firebase/auth'
import { APP_CONFIG, ENV } from 'lib/config'
import { FirebaseAuth, FirebaseConfig } from './types'
import { VERIFY_CODE_RECAPTCHA_CONTAINER } from './common'

const firebaseConfig = {
    apiKey: APP_CONFIG.FIREBASE.API_KEY,
    authDomain: APP_CONFIG.FIREBASE.AUTH_DOMAIN,
    projectId: APP_CONFIG.FIREBASE.PROJECT_ID,
    storageBucket: APP_CONFIG.FIREBASE.STORAGE_BUCKET,
    messagingSenderId: APP_CONFIG.FIREBASE.MESSAGING_SENDER_ID,
    appId: APP_CONFIG.FIREBASE.APP_ID
}

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)

auth.settings.appVerificationDisabledForTesting = ENV.APP_ENV === 'testing'
auth.useDeviceLanguage()

const signInWithEmailAndPassword = (email: string, password: string) => firebaseSignInWithEmailAndPassword(auth, email, password)
const signInWithPhoneNumber = (phoneNumber: string) => {
    const applicationVerifier = new RecaptchaVerifier(VERIFY_CODE_RECAPTCHA_CONTAINER, { size: 'invisible' }, auth)

    return firebaseSignInWithPhoneNumber(auth, phoneNumber, applicationVerifier)
}
const resendPhoneNumberAuthCode = (phoneNumber: string) => {
    const applicationVerifier = new RecaptchaVerifier(VERIFY_CODE_RECAPTCHA_CONTAINER, { size: 'invisible' }, auth)

    return firebaseSignInWithPhoneNumber(auth, phoneNumber, applicationVerifier)
}

const signOut = () => firebaseSignOut(auth)

export const firebaseAuth: FirebaseAuth = {
    signInWithEmailAndPassword,
    signInWithPhoneNumber,
    resendPhoneNumberAuthCode,
    signOut
}

export const firebaseRemoteConfig: FirebaseConfig = {
    fetchRemoteConfig: async () => {
        const remoteConfig = getRemoteConfig(app)
        remoteConfig.settings.minimumFetchIntervalMillis = 60000

        await fetchAndActivate(remoteConfig)
        const config = getAll(remoteConfig)

        return Object.fromEntries(Object
            .entries(config ?? {})
            .map(([key, value]) => [key, value.asString()])
        )
    }
}
