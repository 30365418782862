import React from 'react'
import { View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { ScreenNames } from 'lib/routing'
import { createStyles } from 'lib/styles'
import { Adapter, Button } from 'lib/components'
import { useStyles, useTranslations } from 'lib/hooks'
import { PaymentFeeMessage } from './PaymentFeeMessage'
import { useAddNewCreditCard } from '../hooks'

export const PaymentNewForm: React.FunctionComponent = () => {
    const T = useTranslations()
    const navigation = useNavigation()
    const { styles } = useStyles(stylesheet)
    const { submit, isFilled, isLoading, form, hasError } = useAddNewCreditCard(() => navigation.navigate(ScreenNames.UserCards))

    return (
        <View style={styles.container}>
            <Adapter.TextInputWithLabel
                {...form.nameOnCard}
                disabled={isLoading}
            />
            <Adapter.TextInputWithLabel
                {...form.cardNumber}
                disabled={isLoading}
                inputProps={{
                    keyboardType: 'number-pad',
                    contextMenuHidden: true
                }}
            />
            <View style={styles.expiryDate}>
                <View style={styles.row}>
                    <View style={styles.flex}>
                        <Adapter.TextInputWithLabel
                            {...form.month}
                            disabled={isLoading}
                            inputProps={{
                                keyboardType: 'number-pad',
                                contextMenuHidden: true
                            }}
                        />
                    </View>
                    <View style={styles.yearFlex}>
                        <Adapter.TextInput
                            {...form.year}
                            disabled={isLoading}
                            inputProps={{
                                keyboardType: 'number-pad',
                                contextMenuHidden: true
                            }}
                        />
                    </View>
                </View>
                <View style={styles.cvv}>
                    <Adapter.TextInputWithLabel
                        {...form.cvv}
                        disabled={isLoading}
                        smallLabel
                        inputProps={{
                            keyboardType: 'number-pad',
                            contextMenuHidden: true,
                            secureTextEntry: true
                        }}
                    />
                </View>
            </View>
            <PaymentFeeMessage />
            <View style={styles.buttons}>
                <View style={styles.button}>
                    <Button
                        noBackground
                        disabled={isLoading}
                        onPress={() => navigation.navigate(ScreenNames.UserCards)}
                        text={T.common.cancel}
                    />
                </View>
                <View style={styles.buttonSeparator} />
                <View style={styles.button}>
                    <Button
                        onPress={submit}
                        text={T.common.save}
                        isLoading={isLoading}
                        disabled={!isFilled || hasError}
                    />
                </View>
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        marginTop: theme.utils.gap(2)
    },
    row: {
        flexDirection: {
            lg: 'row',
            xs: 'column'
        }
    },
    expiryDate: {
        flexDirection: {
            lg: 'row',
            ':w[, 1400]': 'column'
        }
    },
    flexLeft: {
        flex: {
            lg: 3,
            ':w[, 1400]': undefined
        },
        paddingRight: {
            lg: theme.utils.gap(2),
            ':w[, 1400]': 0
        }
    },
    flex: {
        flex: {
            lg: 2,
            xs: undefined
        },
        marginRight: {
            lg: theme.utils.gap(2),
            xs: undefined
        }
    },
    yearFlex: {
        flex: {
            lg: 2,
            xs: undefined
        },
        marginRight: {
            lg: theme.utils.gap(2),
            ':w[, 1400]': undefined
        }
    },
    buttons: {
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginBottom: theme.utils.gap(3)
    },
    buttonSeparator: {
        width: theme.utils.gap(2)
    },
    button: {
        flex: {
            lg: undefined,
            xs: 1
        },
        width: 120
    },
    primaryButton: {
        borderBottomWidth: 1,
        borderColor: theme.colors.fog,
        marginRight: theme.utils.gap(2)
    },
    cvv: {
        flex: {
            lg: 2,
            xs: undefined
        }
    }
}))
