import React from 'react'
import { View } from 'react-native'
import Animated, { runOnUI } from 'react-native-reanimated'
import { Job } from 'lib/models'
import { JobStatus } from 'lib/types'
import { isNative } from 'lib/common'
import { dateHelpers } from 'lib/utils'
import { createStyles } from 'lib/styles'
import { useAccordion, useStyles, useTranslations } from 'lib/hooks'
import { CopyBookingId, LoadingIndicator, Touchable, Typography } from 'lib/components'
import { ViewDetails } from './ViewDetails'
import { getJobDetails } from '../actions'
import { useJobDetailsRoute, useJobStatus } from '../hooks'

type DetailsSessionProps = {
    session: Job
}

export const DetailsSession: React.FunctionComponent<DetailsSessionProps> = ({
    session
}) => {
    const T = useTranslations()
    const sessionStatus = session.status > 3 ? 3 : session.status
    const navigate = useJobDetailsRoute(session, sessionStatus, [], false)
    const { styles, theme } = useStyles(stylesheet)
    const { setHeight, animatedHeightStyle, aref, isOpened } = useAccordion(theme.utils.gap(2))
    const { data: response, isLoading } = getJobDetails(session._id)
    const jobStatus = useJobStatus(session.status)

    return (
        <View style={styles.sessionTile}>
            <LoadingIndicator isLoading={isLoading} />
            <View style={styles.icon}>
                <CopyBookingId id={session.job_id} />
            </View>
            <View style={styles.row}>
                <Typography.Label
                    bold
                    forceColor={theme.colors.darkGrey}
                >
                    {session.job_id}
                </Typography.Label>
                {session.status !== JobStatus.Accepted && (
                    <Typography.Error
                        bold
                        style={styles.status}
                        forceColor={jobStatus.colors.text}
                    >
                        {jobStatus.label}
                    </Typography.Error>
                )}
            </View>
            <Typography.Regular bold>
                {dateHelpers.shortFullDate(session.date_time)}
            </Typography.Regular>
            <ViewDetails
                isOpen={isOpened}
                onPress={() => runOnUI(setHeight)()}
            />
            <Animated.View style={animatedHeightStyle}>
                {response && (
                    <View
                        ref={aref}
                        collapsable={false}
                        style={styles.accordionWrapper}
                    >
                        <View style={styles.dateTime}>
                            <Typography.Label>
                                {`${dateHelpers.selectTimeLabel(response.data.job.date_time)} - ${dateHelpers.selectTimeLabel(dateHelpers.addHoursToDate(response.data.job.date_time, response.data.job.num_hours))} (${response.data.job.num_hours}h)`}
                            </Typography.Label>
                        </View>
                        <Typography.Label>
                            {`${T.components.proposals.expert}: ${response?.data?.job?.supplier?.handle}`}
                        </Typography.Label>
                        <View style={styles.viewSession}>
                            <Touchable
                                onPress={navigate}
                                style={styles.viewSessionButton}
                            >
                                <Typography.Regular
                                    bold
                                    forceColor={theme.colors.orange}
                                >
                                    {T.screens.jobDetails.viewSession}
                                </Typography.Regular>
                            </Touchable>
                        </View>
                    </View>
                )}
            </Animated.View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    accordionWrapper: {
        left: 0,
        right: 0,
        position: isNative
            ? 'absolute'
            : undefined
    },
    sessionTile: {
        overflow: 'hidden',
        borderBottomWidth: 1,
        borderBottomColor: theme.colors.silver,
        paddingVertical: theme.utils.gap(2)
    },
    dateTime: {
        marginTop: theme.utils.gap(3),
        marginBottom: theme.utils.gap(1)
    },
    icon: {
        right: 0,
        top: theme.utils.gap(2),
        position: 'absolute',
        zIndex: theme.zIndex[10]
    },
    viewSession: {
        marginTop: theme.utils.gap(1),
        alignItems: 'center'
    },
    viewSessionButton: {
        borderBottomWidth: 1,
        borderColor: theme.colors.orange
    },
    row: {
        alignItems: 'center',
        flexDirection: 'row',
        marginBottom: theme.utils.gap(1) / 2
    },
    status: {
        marginLeft: theme.utils.gap(1)
    }
}))
