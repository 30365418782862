import React from 'react'
import { View, Image } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { Images } from 'assets'
import { createStyles } from 'lib/styles'
import { useStyles } from 'lib/hooks'

export const OnboardingBackground: React.FunctionComponent = () => {
    const { styles } = useStyles(stylesheet)
    const insets = useSafeAreaInsets()

    return (
        <View
            style={{
                ...styles.backgroundImageWrapper,
                paddingTop: insets.top
            }}
        >
            <Image
                resizeMode="cover"
                style={styles.backgroundImage}
                source={Images.OnboardingBackground}
            />
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    backgroundImageWrapper: {
        top: 0,
        left: 0,
        right: 0,
        width: '100%',
        position: 'absolute',
        backgroundColor: theme.colors.sun
    },
    backgroundImage: {
        width: '100%',
        height: 430,
        backgroundColor: theme.colors.white
    }
}))
