import React from 'react'
import { View } from 'react-native'
import { createStyles } from 'lib/styles'
import { Typography } from 'lib/components'
import { useStyles, useTranslations } from 'lib/hooks'
import { JobDetailsResponse } from '../../types'

type HandymanServiceDetailsProps = {
    job: JobDetailsResponse
}

export const HandymanServiceDetails: React.FunctionComponent<HandymanServiceDetailsProps> = ({ job }) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const { job: { message_to_supplier } } = job

    return (
        <View>
            <View style={styles.wrapper}>
                <View style={styles.flex}>
                    <View style={styles.title}>
                        <Typography.Label forceColor={theme.colors.mouse}>
                            {T.components.handymanServiceSelect.serviceItems}
                        </Typography.Label>
                    </View>
                    <Typography.Label>
                        {message_to_supplier.split(`${T.components.handymanServiceSelect.serviceItems}\n`).at(1)}
                    </Typography.Label>
                </View>
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        flexDirection: 'row',
        marginBottom: theme.utils.gap(3)
    },
    title: {
        marginBottom: theme.utils.gap(1)
    },
    flex: {
        flex: 1
    },
    cleaningDays: {
        textTransform: 'capitalize'
    }
}))
