import { useAtom } from 'jotai'
import { createAtom } from './atomFactory'
import { MMKVKey } from '../mmkv'

const { atom } = createAtom<string>('', MMKVKey.FirstSignUp)
const { atom: firstSessionAtom, resetAtom: resetFirstSessionAtom } = createAtom<boolean>(false)

export const useFirstSignUpAtom = () => useAtom(atom)
export const useFirstSessionAtom = () => useAtom(firstSessionAtom)
export const useResetFirstSessionAtom = () => useAtom(resetFirstSessionAtom)
