import React from 'react'
import { View } from 'react-native'
import { createStyles } from 'lib/styles'
import { useStyles, useSupplyOptions, useTranslations } from 'lib/hooks'
import { NavigationProps, ScreenNames } from 'lib/routing'
import { Adapter, AppLayout, Grid, LoadingIndicator, Typography } from 'lib/components'
import { FavouriteEmpty, FavouriteExperts, FavouriteTitle, MostRecentExperts } from '../components'
import { useConsumerFavourites } from '../hooks'

type FavouriteScreenProps = {
    navigation: NavigationProps<ScreenNames.Favourite>
}

export const FavouriteScreen: React.FunctionComponent<FavouriteScreenProps> = () => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const supplyOptions = useSupplyOptions(true)
    const { isLoading, hasExperts, hasFavourites, form, recent, favourites } = useConsumerFavourites()

    if (isLoading) {
        return (
            <AppLayout
                isLoading={isLoading}
                title={T.screens.favourite.title}
            >
                <LoadingIndicator isLoading />
            </AppLayout>
        )
    }

    return (
        <AppLayout title={T.screens.favourite.title}>
            <View style={styles.headerContainer}>
                <FavouriteTitle />
                <Grid.Gap
                    gapTop={2}
                    style={styles.filterInput}
                >
                    <Adapter.SelectInput
                        {...form.service}
                        options={supplyOptions}
                    />
                </Grid.Gap>
            </View>
            {hasExperts
                ? (
                    <React.Fragment>
                        {hasFavourites && (
                            <FavouriteExperts experts={favourites} />
                        )}
                        {!hasFavourites && (
                            <View style={styles.textContainer}>
                                <Typography.Regular forceColor={theme.colors.darkGrey}>
                                    {T.screens.favourite.emptyMessage}
                                </Typography.Regular>
                            </View>
                        )}
                        <MostRecentExperts experts={recent} />
                    </React.Fragment>
                )
                : (
                    <FavouriteEmpty />
                )
            }
        </AppLayout>
    )
}

const stylesheet = createStyles(theme => ({
    headerContainer: {
        zIndex: theme.zIndex[10],
        paddingHorizontal: {
            lg: 0,
            xs: theme.utils.gap(2)
        }
    },
    filterInput: {
        width: {
            lg: 250,
            xs: '100%'
        }
    },
    textContainer: {
        paddingHorizontal: {
            lg: 0,
            xs: theme.utils.gap(2)
        }
    }
}))
