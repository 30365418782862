import React from 'react'
import { View } from 'react-native'
import { createStyles } from 'lib/styles'
import { JobStatus, NotificationType } from 'lib/types'
import { useFocusRefetch, useStyles, useTranslations } from 'lib/hooks'
import { AppLayout, LoadingIndicator, NotificationBar, Tabs, Typography } from 'lib/components'
import { NavigationProps, ScreenNames } from 'lib/routing'
import { R } from 'lib/utils'
import { SubscriptionsRecommendation } from 'features/bookings/components'
import { BookingsSearch, UserBookings } from '../components'
import { useBookings } from '../hooks'
import { getConsumerCancelledJobs } from '../actions'

type BookingsCancelledScreenProps = {
    navigation: NavigationProps<ScreenNames.BookingsCancelled>
}

export const BookingsCancelledScreen: React.FunctionComponent<BookingsCancelledScreenProps> = () => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const {
        isLoading,
        data: canceledJobs,
        isError,
        refetch
    } = getConsumerCancelledJobs()
    const {
        jobList,
        recurringJobs,
        filteredJobs,
        bookingsTabs,
        fields,
        bookingViewStyle
    } = useBookings(JobStatus.Cancelled, canceledJobs?.data)

    useFocusRefetch(() => {
        refetch()
            .catch(R.T)
    })

    return (
        <AppLayout
            bottomSpacerHeight={0}
            isLoading={isLoading}
            title={T.screens.bookings.title}
            renderNotification={isError
                ? () => (
                    <NotificationBar
                        type={NotificationType.Error}
                        renderContent={color => (
                            <Typography.Regular forceColor={color}>
                                {T.common.errorMessage}
                            </Typography.Regular>
                        )}
                    />
                )
                : undefined
            }
        >
            <View style={styles.tabsContainer}>
                <Tabs tabs={bookingsTabs} />
                <BookingsSearch
                    jobs={jobList}
                    searchField={fields.searchField}
                    serviceField={fields.serviceField}
                />
            </View>
            {isLoading
                ? (
                    <LoadingIndicator isLoading />
                ) : (
                    <UserBookings
                        jobs={jobList}
                        date={fields.dateField}
                        filteredData={filteredJobs}
                        recurringJobs={recurringJobs}
                        jobStatus={JobStatus.Cancelled}
                        viewStyle={bookingViewStyle.viewStyle}
                        setViewStyle={bookingViewStyle.setViewStyle}
                        viewStyleOptions={bookingViewStyle.viewStyleOptions}
                        emptyTitle={T.screens.bookings.empty.cancelled}
                        serviceFilter={fields.serviceField.value}
                        emptyBookingListDescription={T.screens.bookings.empty.description}
                    />
                )
            }
            <SubscriptionsRecommendation/>
        </AppLayout>
    )
}

const stylesheet = createStyles(theme => ({
    tabsContainer: {
        zIndex: theme.zIndex[10],
        flexDirection: {
            lg: 'row',
            xs: 'column'
        },
        alignItems: {
            lg: 'center',
            xs: undefined
        },
        justifyContent: 'space-between'
    }
}))
