import React from 'react'
import Animated from 'react-native-reanimated'
import { View } from 'react-native'
import { createStyles } from 'lib/styles'
import { useHeaderLinks, useStyles } from 'lib/hooks'
import { AnimatedHeaderLink } from './AnimatedHeaderLink'
import { HeaderLink } from './HeaderLink'

type HeaderLinksProps = {
    isMainMenuVisible: Animated.SharedValue<boolean>,
    onShowMainMenu: VoidFunction
}

export const HeaderLinks: React.FunctionComponent<HeaderLinksProps> = ({
    onShowMainMenu,
    isMainMenuVisible
}) => {
    const { styles } = useStyles(stylesheet)
    const [ourServiceLink, ...links] = useHeaderLinks()

    return (
        <View style={styles.container}>
            <View>
                <AnimatedHeaderLink
                    isOpened={isMainMenuVisible}
                    text={ourServiceLink.text}
                    onPress={onShowMainMenu}
                />
            </View>
            {links.map((link, index) => (
                <HeaderLink
                    key={index}
                    {...link}
                />
            ))}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        display: {
            ':w[1280, ]': 'flex',
            ':w[, 1280]': 'none'
        },
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-around',
        maxWidth: 700,
        paddingHorizontal: theme.utils.gap(1)
    }
}))
