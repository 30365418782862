import { useState } from 'react'
import { useGetErrorMessage, useTranslations } from 'lib/hooks'
import { useUserAtom, useUserTokenAtom } from 'lib/atoms'
import { cookieUtils } from 'features/auth'
import { updatePromotionalNotifications } from '../actions'

export const useUserNotificationsOptions = () => {
    const T = useTranslations()
    const [error, setError] = useState('')
    const { getErrorMessage } = useGetErrorMessage()
    const [user, setUser] = useUserAtom()
    const [token] = useUserTokenAtom()
    const { mutate: onUpdatePromotionalNotifications } = updatePromotionalNotifications()

    return {
        error,
        options: [
            {
                label: T.screens.preferences.notifications.promotional.label,
                description: T.screens.preferences.notifications.promotional.description,
                value: user.consumer?.marketing_consent?.accepted ?? false,
                onChange: (value: boolean) => onUpdatePromotionalNotifications(
                    {
                        token,
                        accepted: value
                    },
                    {
                        onSuccess: () => {
                            setUser(prevValue => ({
                                ...prevValue,
                                consumer: {
                                    ...prevValue?.consumer,
                                    marketing_consent: {
                                        ...prevValue?.consumer?.marketing_consent,
                                        accepted: value
                                    }
                                }
                            }))

                            cookieUtils.setMarketingConsentAcceptedCookie(value)
                        },
                        onError: response => setError(getErrorMessage(response))
                    }
                )
            }
        ]
    }
}
