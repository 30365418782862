import React from 'react'
import { ActivityIndicator, Image, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { Icons, Images } from 'assets'
import { useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { Touchable, Typography } from 'lib/components'
import { CustomFonts } from 'lib/types'
import { useUserAtom } from 'lib/atoms'
import { jobHelpers, linkingHelpers } from 'lib/utils'
import { ENV } from 'lib/config'
import { JobDetailsResponse } from '../types'

type CancelJobHeaderProps = {
    jobDetail: JobDetailsResponse | undefined,
    upcomingSession: JobDetailsResponse | undefined,
    isLoading: boolean,
    handleGoBack: VoidFunction
}

export const CancelJobHeader: React.FunctionComponent<CancelJobHeaderProps> = ({
    jobDetail,
    upcomingSession,
    isLoading,
    handleGoBack
}) => {
    const T = useTranslations()
    const [user] = useUserAtom()
    const { styles, theme } = useStyles(stylesheet)
    const insets = useSafeAreaInsets()
    const isSessionStartingWithinHours = upcomingSession ?
        jobHelpers.isSessionJob(upcomingSession) && jobHelpers.isSessionStartingWithinHours(upcomingSession, 2) : false

    const BackButton = () => (
        <View style={{ marginTop: insets.top }}>
            <Touchable style={styles.backButton} onPress={handleGoBack}>
                <View style={{ ...styles.backIcon, transform: [{ rotateZ: '90deg' }] }}>
                    <Icons.Chevron size={10} forceColor={theme.colors.darkSlate} />
                </View>
                <Typography.Regular style={styles.promptText}>{T.common.back}</Typography.Regular>
            </Touchable>
        </View>
    )

    return jobDetail && !isLoading ? (
        <View style={styles.container}>
            <View style={{
                ...styles.infoContainer,
                ...styles.navigationContainer
            }}>
                <View style={styles.desktopNavigationContainer}>
                    <BackButton/>
                </View>
                <View>
                    <Typography.Regular style={styles.farewellMessage}>
                        <Typography.Regular style={{
                            ...styles.farewellMessage,
                            ...styles.highlightedName
                        }}>{`${T.screens.cancelJob.hi} `}{user?.handle?.split(' ')?.shift() || ''}</Typography.Regular>
                        <Typography.Regular style={styles.farewellMessage}>, we are sad to see you go</Typography.Regular>
                    </Typography.Regular>
                    <Typography.Regular style={styles.promptText}>
                        {T.screens.cancelJob.areYouSureToWantTo}
                        <Typography.Regular style={{
                            ...styles.promptText,
                            ...styles.boldText,
                            ...styles.cancelText
                        }}>{` ${T.screens.cancelJob.cancelPlan} `}</Typography.Regular>
                        <Typography.Regular style={{
                            ...styles.promptText,
                            ...styles.boldText
                        }}>{jobDetail?.job?.job_id}</Typography.Regular>?
                    </Typography.Regular>
                    <Typography.Regular style={styles.promptText}>
                        {`${T.screens.cancelJob.unresolvedIssues}. `}
                        <Touchable onPress={() => linkingHelpers.openUrl(`${ENV.SENDHELPER_MARKETING_SITE_URL}/contacts`)}>
                            <Typography.Regular bold style={{
                                ...styles.promptText,
                                ...styles.link
                            }}>
                                {T.screens.cancelJob.contactUs}.
                            </Typography.Regular>
                        </Touchable>
                    </Typography.Regular>
                </View>

                {
                    isSessionStartingWithinHours && (
                        <View style={styles.warningMessageContainer}>
                            <Icons.Alert size={20} forceColor={theme.colors.orange} />
                            <Typography.Regular style={styles.warningMessage} testID={T.accessibility.cancelJobScreen.cancelAfterSession}>
                                {T.screens.cancelJob.planCancelAfterSession}
                            </Typography.Regular>
                        </View>
                    )
                }

            </View>
            <View style={styles.imageContainer}>
                <Image
                    resizeMode="contain"
                    source={Images.SadToGo}
                    style={styles.image}
                />
            </View>
            <View style={{
                ...styles.navigationContainer,
                ...styles.mobileNavigationContainer
            }}>
                <BackButton/>
            </View>
        </View>
    ) : (
        <View style={styles.loadingContainer}>
            <ActivityIndicator size="large" color={theme.colors.orange} />
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        flexDirection: {
            lg: 'row',
            xs: 'column-reverse'
        },
        paddingTop: {
            lg: theme.utils.gap(2),
            xs: 0
        }
    },
    desktopNavigationContainer: {
        display: {
            lg: undefined,
            xs: 'none'
        }
    },
    mobileNavigationContainer: {
        display: {
            lg: 'none',
            xs: undefined
        }
    },
    navigationContainer: {
        paddingTop: theme.utils.gap(3),
        paddingLeft: {
            lg: theme.utils.gap(6),
            xs: theme.utils.gap(2)
        },
        paddingRight: theme.utils.gap(2)
    },
    infoContainer: {
        flex: {
            xl: 0.5,
            lg: 0.6,
            md: 1
        },
        minHeight: {
            xl: 230,
            lg: 215,
            xs: undefined
        }
    },
    imageContainer: {
        backgroundColor: theme.colors.white,
        flex: {
            xl: 0.5,
            lg: 0.4,
            md: 1
        },
        minHeight: 215
    },
    backIcon: {
        width: 24,
        height: 24,
        paddingTop: 6,
        marginRight: theme.utils.gap(1),
        alignItems: 'center',
        borderRadius: 4,
        borderWidth: 1,
        borderColor: theme.colors.darkSlate
    },
    backButton: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    promptText: {
        marginLeft: theme.utils.gap(1) / 5,
        fontSize: 16,
        lineHeight: 22,
        fontFamily: CustomFonts.Poppins400,
        textAlign: {
            lg: 'left',
            xs: 'center'
        }
    },
    boldText: {
        fontFamily: CustomFonts.Poppins500
    },
    image: {
        width: '100%',
        height: 250
    },
    farewellMessage: {
        fontSize: 24,
        lineHeight: 30,
        marginTop: theme.utils.gap(2.5),
        marginBottom: theme.utils.gap(1.5),
        fontFamily: CustomFonts.Poppins600,
        textAlign: {
            lg: 'left',
            xs: 'center'
        }
    },
    highlightedName: {
        fontSize: 24,
        color: theme.colors.orange,
        fontFamily: CustomFonts.Poppins600
    },
    link: {
        color: theme.colors.orange,
        cursor: 'pointer'
    },
    loadingContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },
    cancelText: {
        textTransform: 'lowercase'
    },
    warningMessageContainer: {
        marginTop: theme.utils.gap(2),
        borderWidth: 1,
        borderColor: theme.colors.orange,
        borderRadius: 8,
        backgroundColor: theme.colors.latte,
        paddingVertical: theme.utils.gap(1),
        paddingHorizontal: theme.utils.gap(2),
        width: '100%',
        flexDirection: 'row'
    },
    warningMessage: {
        color: theme.colors.orange,
        marginLeft: theme.utils.gap(1),
        fontFamily: CustomFonts.Poppins500,
        fontSize: 14,
        lineHeight: 20
    }
}))
