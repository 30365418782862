/* eslint-disable max-lines */
import { Dictionary } from 'lib/types'

// eslint-disable-next-line
export const en_US: Dictionary = {
    common: {
        start: 'Start',
        space: ' ',
        done: 'Done',
        applyDiscount: 'Apply promo',
        additionalFee: 'Additional fee',
        youSaved:'You saved',
        youGet: `You'll get`,
        percent: '%',
        onFinalBilling: 'on final billing',
        apply: 'Apply',
        applied: 'applied',
        viewTermsAndConditions: 'view T&C',
        guestBookingPromoCodeSubtext: 'Discount code will be verified and applied when a cleaning expert is assigned.',
        goBack: 'Go back',
        back: 'Back',
        hello: 'Hello',
        sendHelper: 'Sendhelper',
        byPropertyGuru: 'by PropertyGuru',
        user: 'User',
        logIn: 'Log in',
        continue: 'Continue',
        signUp: 'Sign up',
        close: 'Close',
        seconds: 's',
        today: 'Today',
        bookService: 'Book a service',
        error: 'Error',
        save: 'Save',
        megabytes: 'MB',
        cancel: 'Cancel',
        with: 'with',
        delete: 'Delete',
        hour: 'hour',
        errorMessage: 'An error has occurred. Please try again or check your internet connection',
        ok: 'Ok',
        and: 'and',
        home: 'Home',
        block: 'Block',
        unit: 'Unit',
        noOptions: 'No options',
        services: 'Our services',
        primary: 'Primary',
        search: 'Search',
        setAsPrimary: 'Set as primary',
        on: 'On',
        off: 'Off',
        service: 'Service',
        seeMore: 'See More',
        seeLess: 'See Less',
        expiryDate: 'Expiry date',
        bookServices: 'Browse services',
        currency: 'S$',
        availableTill: 'Available till',
        currencyShortCode: 'S$',
        useCode: 'Use code',
        howItWorks: 'What’s included & how it works?',
        startingFrom: 'Starting from',
        rooms: 'Rooms',
        change: 'Change',
        hours: 'Hours',
        bestValue: 'Best value',
        mostPopular: 'Most Popular',
        optional: '(Optional)',
        viewDetails: 'View details',
        bookingSummary: 'Booking summary',
        loading: 'Loading',
        call: 'Call',
        more: 'More',
        paymentMethod: 'Payment method',
        totalPrice: 'Total price',
        totalPaid: 'Total paid',
        totalAmount: 'Total amount',
        paymentSummary: 'Payment summary',
        discount: 'Discount',
        copy: 'Copy',
        chat: 'Chat',
        send: 'Send',
        or: 'Or',
        purchase: 'Purchase',
        confirm: 'Confirm',
        tryAgain: 'Try again',
        discountCode: 'Discount code',
        select: 'Select',
        selected: 'Selected',
        edit: 'Edit',
        hr: '/hr',
        question: 'Got a question?',
        by: 'by',
        singapore: 'Singapore',
        add: 'Add',
        added: 'Added',
        gotQuestion: 'Got a question?',
        accept: 'Accept',
        decline: 'Decline',
        date: 'Date',
        receiptSummary: 'Receipt summary',
        platformFee: 'Platform fee',
        tip: 'Tip',
        upcoming: 'Upcoming',
        paymentDetails: 'Payment details',
        submit: 'Submit',
        set: 'Set',
        asFavourite: 'as Favourite',
        voucher: 'Voucher',
        copiedBookingIdMessage: 'Job ID was copied to clipboard',
        estimatedPrice: 'Estimated price',
        yes: 'Yes',
        no: 'No',
        viewImages: 'View images',
        priceReference: 'Price Reference',
        chargesOnQuotation: 'Charges on quotation',
        price: 'Price',
        at: 'At',
        noDescription: 'No description',
        yourExpert: 'Your service partner:',
        total: 'Total',
        skip: 'Skip',
        welcome: 'Welcome',
        backToHome: 'Back to home',
        allServices: 'All services',
        lessDetails: 'Less details',
        noDatesSelected: 'You have not selected any date yet',
        items: 'item(s)',
        remove: 'Remove',
        upload: 'Upload',
        contactUs: 'Contact Us',
        priceOnQuotation: 'Price on Quotation',
        new: 'New',
        dropDownSelect: 'Select...',
        muchMore: 'much more',
        perSession: 'per session',
        cleaningPrice: 'Cleaning price'
    },
    headerLinks: {
        ourServices: 'Our Services',
        blog: 'Blog',
        becomePartner: 'Become a Partner',
        contactUs: 'Contact us',
        signIn: 'Login / Sign Up'
    },
    linking: {
        unsupportedServiceError: 'This service is not supported',
        missingSupplierIdError: 'You need to select the service first'
    },
    errorBoundary: {
        title: 'Oops - something went wrong!',
        message: 'Whatever happened, it was probably our fault.\nDetailed information about this error has automatically been recorder and we have been notified.',
        submit: 'Reload the app'
    },
    screens: {
        home: {
            pageTitle: 'Home',
            title: 'Home screen',
            authorizeMe: 'Authorize me!',
            gotToAuthScreen: 'Go to auth screen'
        },
        Page404: {
            pageTitle: 'Not found',
            title: 'Sorry, we couldn’t find the page you were looking for.',
            message: 'Here are some helpful links',
            myBookings: 'My bookings',
            bookAService: 'Book a service'
        },
        auth: {
            pageTitle: 'Auth',
            title: 'Auth screen',
            welcomeBack: 'Welcome back!',
            welcome: 'Welcome!',
            singleSignOn: 'Use single sign-on',
            welcomeMessage: 'Ready to give us new tasks?',
            forgotPassword: 'Forgot Password?',
            loginSeparator: 'or',
            loginSeparatorNative: 'or continue with',
            signUpSeparatorNative: 'or sign up with',
            noAccountText: 'Don’t have an account?',
            hasAccountText: 'Already a member?',
            resetPasswordSuccessMessage: 'Your password has been successfully reset. Now login with your new password.',
            continueAsGuest: 'Continue as Guest',
            playServicesNotAvailable: 'Play services are not available.',
            googleSignInError: 'Unable to sign in with Google. Please try again.',
            formFields: {
                name: {
                    label: 'Name',
                    placeholder: 'Name',
                    validation: {
                        isRequired: 'This field is required.',
                        minValue: 'Min length 3 characters',
                        maxValue: 'Max length 30 characters'
                    }
                },
                phoneNumber: {
                    label: 'Phone number',
                    placeholder: 'Phone number',
                    validation: {
                        isRequired: 'This field is required.',
                        tooShort: 'Phone number is too short.',
                        tooLong: 'Phone number is too long.'
                    }
                },
                email: {
                    label: 'Email',
                    placeholder: 'Email',
                    validation: {
                        isRequired: 'This field is required.',
                        isInvalid: 'Email is invalid.',
                        minValue: 'Min length 5 characters',
                        maxValue: 'Max length 40 characters'
                    }
                },
                password: {
                    label: 'Password',
                    placeholder: 'Password',
                    validation: {
                        isRequired: 'This field is required.',
                        isTooShort: 'Password must be at least 10 characters long.',
                        missingUpperCase: 'Password must include a uppercase character.',
                        missingLowerCase: 'Password must include a smallcase character.',
                        missingNumber: 'Password must include at least a number.',
                        missingSpecialChar: 'Password must include a symbol like (!@#$%)',
                        isTooLong: 'Password must not be longer than 16 characters.'
                    }
                },
                confirmPassword: {
                    label: 'Confirm Password',
                    placeholder: 'Confirm Password',
                    validation: {
                        isRequired: 'This field is required.',
                        doesntMatch: 'Password confirmation doesn\'t match password.'
                    }
                },
                terms: {
                    label: 'I agree to Sendhelper’s',
                    link: 'Terms & Conditions',
                    validation: {
                        isRequired: 'You have to agree to Sendhelper\'s Terms of service.'
                    }
                }
            },
            errors: {
                invalidPhoneNumber: 'You have entered an invalid phone number, or this phone number can\'t be used for verification. Please try with another phone number.',
                userDisabled: 'Your account has been disabled or removed. Please try with another phone number.',
                genericError: 'Something went wrong during the authorization process. Please try again or use another credentials.',
                invalidVerificationCode: 'Verification code is invalid. Resend the code and try again.',
                tooManyRequests: 'We\'ve sent too many verification codes. Please try again later.',
                facebookError: 'Something went wrong while authorizing with Facebook. Please try again later.',
                appleError: 'Something went wrong while authorizing with Apple. Please try again later.',
                googleError: 'Something went wrong while authorizing with Google. Please try again later.',
                facebookEmailError: 'Something went wrong while fetching email address. Please try again later.',
                tooManyLoginAttempts: 'Too many login attempts. Please try again in 120 seconds.'
            },
            marketingConsent: {
                getVoucher: 'Get S$50* Off Your Upcoming Bookings',
                benefit: 'Access Exclusive Deals And Promotions',
                yes: 'Yes',
                no: 'No',
                descriptionOfYes: ', I want to receive exclusive deals, discounts and promotions',
                descriptionOfNo: ', I do not want to receive S$50 off my first order or exclusive deals, discounts and promotions**',
                promoCodeDescription: '*The promo code "WELCOME50" can only be used ten times. Use "WELCOME50" for $5 OFF X 10 bookings.',
                promoCodeCondition: '**We encourage user to opt in for promotional notifications to be eligible for future promotions.'
            },
            termsAndPrivacy: {
                prefixContent: 'By creating an account and/or using Sendhelper\'s platform and services, you are agreeing to Sendhelper\'s',
                termsOfService: 'Terms of Service',
                and: 'and',
                privacyPolicy: 'Privacy Policy',
                suffixContent: 'including the collection, use, disclosure, processing, storage and handling of your personal information in connection with your use of our websites, apps and services'
            }
        },
        authOTP: {
            pageTitle: 'Verify your email',
            title: 'Verify your email screen',
            verifyTitle: 'Verification code',
            verifyMessage: 'Please enter the 6 digit code sent to',
            submit: 'Verify & Continue',
            resend: 'Didn\'t receive the code?',
            wait: 'Resend code',
            reCaptcha: {
                protectedByNote: 'This site is protected by reCAPTCHA and the Google',
                privacyPolicy: 'Privacy Policy',
                termsOfService: 'Terms of Service',
                apply: 'apply'
            }
        },
        authForgotPassword: {
            pageTitle: 'Verify your email',
            title: 'Verify your email screen',
            welcome: 'Forgot your password?',
            welcomeMessage: 'No worries, let’s reset your password.',
            verifyTitle: 'Reset Password',
            verifyMessage: 'Confirm your email and we’ll send the\ninstructions to reset your password.',
            submit: 'Reset Password',
            wait: 'Resend Email',
            checkEmail: 'Check your email',
            checkEmailMessage: 'The instructions to reset your password have\nbeen sent to your address',
            didntReceiveEmail: 'Didn\'t receive an email?',
            resendEmail: 'Resend Email',
            formFields: {
                email: {
                    label: 'Email',
                    placeholder: 'Email',
                    validation: {
                        isRequired: 'This field is required.',
                        isInvalid: 'Email is invalid'
                    }
                }
            }
        },
        authNewPassword: {
            pageTitle: 'Enter new password',
            title: 'Enter new password screen',
            welcome: 'Forgot your password?',
            welcomeMessage: 'No worries, let’s reset your password.',
            verifyTitle: 'Enter new password',
            verifyMessage: 'Please set a new password to access your account.',
            submit: 'Save New Password',
            successMessage: 'Your password has been changed. You can sign in with new credentials.',
            formFields: {
                newPassword: {
                    label: 'New Password',
                    placeholder: 'New Password',
                    validation: {
                        isRequired: 'This field is required.',
                        isInvalid: 'Password must be at least 6 characters long'
                    }
                },
                confirmPassword: {
                    label: 'Confirm New Password',
                    placeholder: 'Confirm New Password',
                    validation: {
                        isRequired: 'This field is required.',
                        doesntMatch: 'Password confirmation doesn\'t match password'
                    }
                }
            }
        },
        authSuccess: {
            pageTitle: 'Auth success',
            title: 'Auth success screen',
            welcome: 'Welcome!',
            welcomeMessage: 'Create an account and let us take care of your daily chores',
            verifyTitle: 'Successfully verified!',
            verifyMessage: 'Your email is successfully verified.'
        },
        authSingleSignOn: {
            pageTitle: 'Single sign on',
            title: 'Single sign on screen',
            welcome: 'Sign on using your email',
            welcomeMessage: 'Simple like that!',
            verifyTitle: 'Log in to Sendhelper',
            verifyMessage: 'with SAML SSO',
            submit: 'Continue',
            formFields: {
                email: {
                    label: 'Email',
                    placeholder: 'Email',
                    validation: {
                        isRequired: 'This field is required.',
                        isInvalid: 'Email is invalid'
                    }
                }
            }
        },
        authFinishSocialSignUp: {
            pageTitle: 'Finish social sign up'
        },
        addressSetup: {
            title: 'Set up your address',
            message: 'Embark on a journey to a cleaner and more blissful home'
        },
        user: {
            pageTitle: 'User',
            title: 'My Profile',
            editProfile: 'Edit profile',
            changePassword: 'Change password',
            sections: {
                basics: 'Basics',
                password: 'Password',
                deleteAccount: 'Manage Account'
            },
            formFields: {
                name: {
                    label: 'Name',
                    placeholder: 'Name',
                    validation: {
                        isRequired: 'This field is required.'
                    }
                },
                phoneNumber: {
                    label: 'Phone',
                    placeholder: 'Phone',
                    validation: {
                        isRequired: 'This field is required.',
                        tooShort: 'Phone number is too short.',
                        tooLong: 'Phone number is too long.'
                    }
                },
                email: {
                    label: 'Email',
                    placeholder: 'Email',
                    validation: {
                        isRequired: 'This field is required.',
                        isInvalid: 'Email is invalid.'
                    }
                },
                currentPassword: {
                    label: 'Current Password',
                    placeholder: 'Current Password',
                    validation: {
                        isRequired: 'This field is required.',
                        isTooShort: 'Password must be at least 10 characters long.',
                        isTooLong: 'Password must not be longer than 16 characters.'
                    }
                },
                newPassword: {
                    label: 'New Password',
                    placeholder: 'New Password',
                    validation: {
                        isRequired: 'This field is required.',
                        isTooShort: 'Password must be at least 10 characters long.',
                        isTooLong: 'Password must not be longer than 16 characters.'
                    }
                },
                confirmNewPassword: {
                    label: 'Confirm New Password',
                    placeholder: 'Confirm New Password',
                    validation: {
                        isRequired: 'This field is required.',
                        isTooShort: 'Password must be at least 10 characters long.',
                        isTooLong: 'Password must not be longer than 16 characters.',
                        doesntMatch: 'Password confirmation doesn\'t match password.'
                    }
                },
                historyTerms: {
                    label: 'I understand & I accept that Sendhelper, as a company based in Singapore might need to retain my transaction history for legal & regulatory obligations for a period of 3 years.',
                    validation: {
                        isRequired: 'This field is required'
                    }
                },
                informationTerms: {
                    label: 'I understand & I accept that my information will be retained anonymously for legal & regulatory obligations for a period of 3 years.',
                    validation: {
                        isRequired: 'This field is required'
                    }
                },
                marketingTerms: {
                    label: 'I understand & I accept that Sendhelper, for marketing purposes will keep track anonymously of the services I have used during my time on the platform.',
                    validation: {
                        isRequired: 'This field is required'
                    }
                },
                newAccountTerms: {
                    label: 'I understand that after deleting my account, if I want to join the platform again I will need to create a new account and enter my personal informations.',
                    validation: {
                        isRequired: 'This field is required'
                    }
                }
            },
            newPasswordRequirementsTitle: 'Password must:',
            newPasswordRequirements: {
                beBetween: 'be between 4-16 characters',
                includeNumber: 'include at least a number',
                includeSymbol: 'include a symbol like (!@#$%)'
            },
            delete: 'Delete Account',
            deleteAccountConfirmation: 'Are you sure you want to delete account?',
            deleteAccountWarning: 'Your account will be permanently removed and',
            deleteAccountIrreversible: 'it cannot be undone.',
            cancelDelete: 'Keep my account',
            confirmDelete: 'Yes, I want to delete account',
            deleteFormWelcome: 'Hi',
            deleteFormWelcomeMessage: 'When deleting your account, we may retain certain information for legitimate business purposes or to comply with legal or regulatory obligations.',
            deleteFormWelcomeMessageSecondParagraph: 'Your information will stay confidential and your name will be hidden from transaction records.',
            deleteFormWelcomeMessageThirdParagraph: 'All irrelevant information such as phone number, email address, home address(es) will be deleted from our servers.',
            deleteFormTitle: 'Please read & accept all the terms to delete account',
            confirmDeleteTitle: 'Delete Account?',
            accountDeletionErrorTitle: 'Unable to Delete Your Account',
            accountDeletionErrorMessage: 'You may have active bookings or credits remaining in your account.',
            contactSupportForAccountDeletion: 'Please contact us to complete your account deletion request.',
            confirmDeleteMessage: 'Are you sure, you want to delete your account? You won’t be able to revert back, \n you will have to create a new account',
            deleteConfirmationTitle: 'Account deleted',
            deleteConfirmationAlert: 'Your account has been deactivated and will be permanently deleted after 30 days',
            deleteConfirmationMessage1: 'Thanks for your past support, we hope for another chance to serve you again soon!',
            deleteConfirmationMessage2: 'If you change your mind you can always create a new account or reach out to info@sendhelper.com to reactivate your account.',
            noAddresses: 'You have not added any addresses.',
            addAddress: 'Add address',
            contactUsButtonText: 'Contact us'
        },
        addNewAddress: {
            pageTitle: 'New Address',
            title: 'My Profile',
            section: {
                addNewAddress: 'Add New Address'
            },
            setAsPrimary: 'Set as Primary',
            formFields: {
                postCode: {
                    label: 'Postcode',
                    placeholder: 'Enter Postcode',
                    validation: {
                        isRequired: 'This field is required.',
                        isTooShort: 'Postcode must be 6 characters long.'
                    }
                },
                addressLine: {
                    label: 'Address Line',
                    placeholder: 'Enter Address Line',
                    validation: {
                        isRequired: 'This field is required.',
                        minValue: 'Min length 10 characters',
                        maxValue: 'Max length 150 characters'
                    }
                },
                block: {
                    placeholder: 'Block',
                    validation: {
                        maxValue: 'Max length 20 characters'
                    }
                },
                unitFloor: {
                    label: 'Floor / Unit',
                    placeholder: 'Floor / Unit',
                    validation: {
                        isRequired: 'This field is required.',
                        maxValue: 'Max length 10 characters.'
                    }
                },
                propertySize: {
                    label: 'Property Size',
                    placeholder: 'Select Property Size',
                    validation: {
                        isRequired: 'This field is required.'
                    }
                },
                nameYourAddress: {
                    label: 'Name your address',
                    placeholder: 'Address name to easily find it',
                    validation: {
                        isRequired: 'This field is required.'
                    }
                }
            },
            successMessage: 'Address added successfully'
        },
        userDeleteAccount: {
            pageTitle: 'Account Deletion'
        },
        address: {
            pageTitle: 'Address',
            edit: 'Edit Address',
            select: 'Select your address',
            addNew: 'Add a new address',
            selectSavedAddress: 'Select a saved address',
            createNewOne: 'Create new address',
            delete: 'Delete Address',
            postalCode: 'Postal code',
            addressLine: 'Address line',
            unit: 'Unit, Block,\nApartment line',
            deleteModalTitle: 'Delete Address?',
            deleteModalMessage: 'Are you sure, you want to delete this address?',
            deletedToastMessage: 'Address deleted successfully',
            formFields: {
                address: {
                    placeholder: 'Select your address',
                    validation: {
                        isRequired: 'This field is required.'
                    }
                }
            }
        },
        editAddress: {
            pageTitle: 'Edit Address',
            title: 'My Profile',
            section: {
                editAddress: 'Edit Address'
            }
        },
        preferences: {
            pageTitle: 'Preferences',
            title: 'My Profile',
            section: {
                notifications: 'Notifications'
            },
            notifications: {
                promotional: {
                    label: 'Promotional Notifications',
                    description: 'You will receive exclusive deals, discounts and promotions'
                }
            }
        },
        favourite: {
            pageTitle: 'Favourite',
            title: 'My Favourite Service Partners',
            subtitle: 'Did you like a Service Partner? Add them to your Favourite list!',
            description: 'Your Favourite Service Partners will be requested first for all future bookings.',
            noFavourites: 'You have not booked this service yet.',
            bookNow: 'Book now',
            favourite: 'Favourite',
            setAsFavourite: 'Set as Favourite',
            lastBooking: 'Last booking',
            setsAsFavourite: 'Successfully set as favourite',
            removedFromFavourites: 'Successfully removed from favourites',
            emptyMessage: 'You don’t have any Favourite Service Partners yet. Click “Set as Favourite” to add your most recent Service Partners to your list.',
            section: {
                myFavouriteExperts: 'My Favourite Service Partners',
                mostRecentExperts: 'Most Recent Service Partners'
            }
        },
        referFriend: {
            pageTitle: 'Refer',
            title: 'Refer a Friend',
            shareCode: 'Share code',
            messagePartOne: 'Refer a friend and ',
            messagePartTwo: 'earn S$50 each!',
            description: 'Share your referral code with your friend. They get S$50 off on their first booking, and you ',
            descriptionBold: 'earn S$50 as SendPay Credits!',
            yourCode: 'Share your invite code and start earning!',
            share: 'Share your code via',
            copiedMessage: 'Invite link copied to your clipboard. Now you can share your code with your friends',
            shareMessagePartOne: 'Hi! I want you to enjoy S$50 off on your first booking with Sendhelper by PropertyGuru. They provide quality home services like Cleaning, Aircon Servicing, Handyman, Laundry, and more. Sign up & complete your booking today with my referral code',
            shareMessagePartTwo: 'at Sendhelper to enjoy $S50 off.',
            footer: 'Limited Time Offer - ',
            footerPartTwo: 'View T&C',
            bookingMessage: 'Referral code is only applicable for minimum booking amount of SG $75.',
            jobDetailsMessage: 'We’re sorry. Referral code is only applicable for minimum booking amount of SG $75.',
            steps: {
                title: 'Start earning in 3 simple steps:',
                tiles: {
                    share: {
                        title: 'Share code with friend',
                        description: 'Share your referral code with any number of friends you like.'
                    },
                    book: {
                        title: 'Friend books a job',
                        description: 'Your friend completes their job with your code and gets S$50 off.'
                    },
                    earn: {
                        title: 'You earn S$50',
                        description: 'And now, your S$50 will be credited as SendPay Credits.'
                    }
                }
            },
            faq: {
                title: 'Frequently asked questions',
                tiles: {
                    whoCanRefer: {
                        title: 'How many people can I refer?',
                        description: 'You can refer to as many friends as possible. But the code will apply to anyone who has never tried Sendhelper services.'
                    },
                    whatWillIEarn: {
                        title: 'What will I earn from Referral Program?',
                        description: 'Upon your friend\'s successful job completion, you will earn S$50 in SendPay credits.'
                    },
                    expire: {
                        title: 'Is there a spending limit to applying the referral rewards?',
                        description: 'You can only apply referral rewards for a minimum booking of S$75 for any service type.'
                    },
                    howCanIRedeem: {
                        title: 'How can I redeem the referral rewards?',
                        description: 'Choose the payment method as "SendPay Credit" instead of "Pay by card" while you book a service.'
                    }
                }
            },
            shareModal: {
                title: 'Share invite code',
                clipboard: 'Copy to clipboard',
                whatsApp: 'Share via WhatsApp',
                messenger: 'Share via Messenger',
                instagram: 'Share via Instagram',
                telegram: 'Share via Telegram',
                email: 'Share via E-mail',
                other: 'More Share Options '
            }
        },
        paymentAndCards: {
            pageTitle: 'Payment and cards',
            title: 'Payment and Cards',
            message: 'All your payment details and past transaction history is safe and secured with BrainTree. At least one card is required to make payment on Sendhelper.',
            noCards: 'You have no cards yet.',
            addCard: 'Add Card',
            deleteCard: 'Delete Card',
            successMessage: 'Card added successfully',
            verificationFeeMessage: 'No verification fee will be deducted in the process of adding your card',
            formFields: {
                nameOnCard: {
                    label: 'Name on Card',
                    placeholder: 'Enter name on the card',
                    validation: {
                        isRequired: 'This field is required.',
                        minValue: 'Min length 3 characters',
                        maxValue: 'Max length 50 characters'
                    }
                },
                cardNumber: {
                    label: 'Card Number',
                    placeholder: 'Enter card number',
                    validation: {
                        isRequired: 'This field is required.',
                        isInvalid: 'Card number is invalid'
                    }
                },
                month: {
                    label: 'Expiry Date',
                    placeholder: 'MM',
                    validation: {
                        isRequired: 'This field is required.',
                        isInvalid: 'Month is invalid'
                    }
                },
                year: {
                    placeholder: 'YYYY',
                    validation: {
                        isRequired: 'This field is required.',
                        isInvalid: 'Year is invalid',
                        isExpired: 'Card is expired'
                    }
                },
                cvv: {
                    label: 'CVV',
                    placeholder: 'CVV',
                    validation: {
                        isRequired: 'This field is required.',
                        isInvalid: 'CVV is invalid'
                    }
                }
            },
            deleteTitle: 'Delete card?',
            deleteMessage: 'Are you sure you want to delete card?',
            deletedToastMessage: 'Card successfully deleted',
            setAsPrimaryMessage: 'Card successfully set as primary'
        },
        paymentHistory: {
            noPaymentsHistory: 'You don’t have any payment history'
        },
        offersAndDeals: {
            pageTitle: 'Offers & Deals',
            title: 'Offers & Deals',
            message: 'Check out our special offers and discounts for you!',
            limitedTitle: 'Limited offers for you ',
            seeLatest: 'See latest deal',
            inviteOnlyTitle: 'Invite Only',
            monthlyPromoTitle: 'Monthly promos',
            useInformation: 'By clicking use code, the code will be applied to your next booking for cleaning service.'
        },
        cleaning: {
            pageTitle: 'Book Cleaning Service',
            title: 'Cleaning service',
            planModalTitle: 'Our cleaning plan',
            serviceDescription: 'Subscription or one-off cleaning for your home done by professionals.',
            roomsSectionTitle: 'How many rooms do you have?',
            roomsSectionDescription: 'Kitchen, Living room & other common areas are already included in our service.',
            footerText: '*For the Weekly and Fortnightly cleaning service, a total of 4 sessions are automatically generated.',
            breadCrumbs: {
                requirements: 'Cleaning requirements',
                confirmation: 'Booking confirmation'
            },
            formFields: {
                address: {
                    label: 'Your address',
                    validation: {
                        isRequired: 'This field is required.'
                    }
                },
                propertyType: {
                    label: 'About your property',
                    placeholder: 'Type of property',
                    validation: {
                        isRequired: 'This field is required.'
                    }
                },
                startingDate: {
                    label: 'Starting date & time',
                    placeholder: 'Select date & time',
                    validation: {
                        isRequired: 'This field is required.'
                    }
                },
                propertySize: {
                    label: 'Property Size',
                    placeholder: 'Select Property Size',
                    validation: {
                        isRequired: 'This field is required.'
                    }
                },
                bedrooms: {
                    label: 'Number of Bedrooms',
                    validation: {
                        isRequired: 'This field is required.'
                    }
                },
                bathrooms: {
                    label: 'Number of Bathrooms',
                    validation: {
                        isRequired: 'This field is required.'
                    }
                },
                hours: {
                    label: 'How many cleaning hours do you need?',
                    placeholderPartOne: 'We suggest dedicating',
                    placeholderPartTwo: 'of cleaning for your property type.',
                    whatIncluded: 'What’s included',
                    validation: {
                        isRequired: 'This field is required.'
                    }
                },
                frequency: {
                    label: 'Cleaning Frequency',
                    validation: {
                        isRequired: 'This field is required.'
                    }
                },
                selectTime: {
                    placeHolder: 'Select time',
                    validation: {
                        isRequired: 'This field is required.',
                        minimumHourNotice: 'hours notice is required to make a booking.'
                    }
                }
            },
            calendarModal: {
                oneTime: {
                    title: 'Service',
                    description: 'Select your cleaning day',
                    select: {
                        title: 'Select the time for your service partner to come',
                        description: 'We recommend selecting 3 hours based on your bedrooms and bathrooms.'
                    }
                },
                weekly: {
                    title: 'Service',
                    description: 'Select the starting date',
                    select: {
                        title: 'Select the days & time for this service',
                        description: 'Select up to 7 days per week. This schedule will be repeated weekly.'
                    }
                },
                fortnightly: {
                    title: 'Service',
                    description: 'Select the starting date',
                    select: {
                        title: 'Select the days & time for this service',
                        description: 'Select up to 7 days per week. This schedule will be repeated every 2 weeks.'
                    }
                }
            }
        },
        cleaningSummary: {
            pageTitle: 'Cleaning Summary',
            title: 'Summary',
            cancellationFees: 'What are the cancellation charges?',
            location: 'Service location',
            schedule: 'Cleaning Schedule',
            cycleMessage: 'Cycle auto-renews after the 4th session.',
            serviceLocation: 'Service location',
            guestBookingPromoDisclaimer: 'Discount will be applied when a cleaning expert is assigned',
            cleaningEquipmentCopy: 'Please provide cleaning equipment and agents to your cleaning expert.',
            sections: {
                addOns: 'Add-ons',
                additionalInformation: 'Additional Information'
            },
            formFields: {
                extraHours: {
                    label: 'We recommend you to allocate more time when selecting the ironing service.',
                    placeholder: 'Adjust the time of each session',
                    description: '*5 pieces of clothing will take approximately 30 minutes'
                },
                ironing: {
                    label: 'Ironing Service'
                },
                cleaningSupplies: {
                    label: 'Bring Cleaning Supplies'
                },
                pets: {
                    placeholder: 'Do you have any pets at home?'
                },
                comment: {
                    placeholder: 'Any additional instructions or language preferences?'
                },
                promoCode: {
                    placeholder: 'Apply discount code'
                },
                allergies: {
                    label: 'Does you or your guests have any food allergies?'
                },
                allergiesComment: {
                    placeholder: 'Please specify your food allergies here'
                }
            }
        },
        deepCleaning: {
            pageTitle: 'Book Deep Cleaning Service',
            title: 'Deep Cleaning service',
            serviceDescription: 'Specialised home cleaning done prior to moving in, after the end of a tenancy or for special occasions.',
            breadCrumbs: {
                requirements: 'Deep Cleaning requirements',
                confirmation: 'Booking confirmation'
            },
            formFields: {
                propertyCleaning:{
                    label: 'Would you like to deep clean your entire property?',
                    subLabel: 'A thorough cleaning of all rooms, bathrooms and ',
                    validation: {
                        isRequired: 'This field is required.'
                    }
                },
                propertyType: {
                    label: 'About your property',
                    placeholder: 'Type of property',
                    validation: {
                        isRequired: 'This field is required.'
                    }
                },
                startingDate: {
                    label: 'When do you want to deep clean',
                    placeholder: 'Select service date',
                    validation: {
                        isRequired: 'This field is required.'
                    }
                },
                startingTime: {
                    placeholder: 'Select time'
                },
                propertySize: {
                    label: 'Property Size',
                    placeholder: 'Select Property Size',
                    validation: {
                        isRequired: 'This field is required.'
                    }
                },
                services: {
                    label: 'Add-on services',
                    message: {
                        minValueOf: 'A minimum value of ',
                        required: ' is required to make a booking',
                        value: 'S$ 150'
                    },
                    summaryMessage: {
                        youAre: 'You are ',
                        awayFromBooking: ' away from continuing with your booking'
                    },
                    mobileMessage:{
                        add: 'Add ',
                        more: 'or more ',
                        continue: 'to continue'
                    },
                    footerText: {
                        title: 'Parquet Floor Varnishing:',
                        subTitle: 'Based on total floor area',
                        whatsAppText: 'Request custom quote'
                    },
                    placeholder: 'On top of general cleaning, we also offer the following services as add-ons:',
                    validation: {
                        isRequired: 'This field is required.'
                    }
                },
                frequency: {
                    label: 'Cleaning Frequency',
                    validation: {
                        isRequired: 'This field is required.'
                    }
                },
                selectTime: {
                    placeHolder: 'Select time',
                    validation: {
                        isRequired: 'This field is required.',
                        minimumHourNotice: 'hours notice is required to make a booking.'
                    }
                }
            },
            calendarModal: {
                oneTime: {
                    title: 'Service',
                    description: 'Select your cleaning day',
                    select: {
                        title: 'Select the time for your service partner to come',
                        description: 'We recommend selecting 3 hours based on your bedrooms and bathrooms.'
                    }
                },
                weekly: {
                    title: 'Service',
                    description: 'Select the starting date',
                    select: {
                        title: 'Select the days & time for this service',
                        description: 'Select up to 7 days per week. This schedule will be repeated weekly.'
                    }
                },
                fortnightly: {
                    title: 'Service',
                    description: 'Select the starting date',
                    select: {
                        title: 'Select the days & time for this service',
                        description: 'Select up to 7 days per week. This schedule will be repeated every 2 weeks.'
                    }
                }
            }
        },
        deepCleaningSummary: {
            pageTitle: 'Deep Cleaning Summary',
            title: 'Summary',
            cancellationFees: 'What are the cancellation charges?',
            serviceLocation: 'Service location',
            sections: {
                additionalInformation: 'Additional Information'
            }
        },
        handyman: {
            pageTitle: 'Book Handyman Service',
            title: 'Handyman service',
            serviceDescription: 'Fix, repair and renovate with our expert handyman services.',
            averagePrice: 'Average price',
            startingFrom: 'Starting from',
            priceInfo: '*Prices mentioned above are only an estimate based on the basic job scope. Final quotation may vary depending on the nature, complexity of the project and the cost of materials',
            visitCharges: 'Visit charges S$ 50',
            visitChargesDescription: 'Please note that a $50 visitation charge will apply if you choose not to proceed with our handyman services after the Service Partner has either conducted a diagnosis or arrived at your location.',
            breadCrumbs: {
                requirements: 'Handyman requirements',
                confirmation: 'Booking confirmation'
            },
            otherServiceSummaryColumnLabel: {
                title: 'Other service',
                value: 'Customized'
            },
            formFields: {
                startingDate: {
                    label: 'Service date & time',
                    placeholder: 'Select date & time',
                    validation: {
                        isRequired: 'This field is required.'
                    }
                },
                frequency: {
                    label: 'Handyman Frequency',
                    validation: {
                        isRequired: 'This field is required.'
                    }
                },
                selectTime: {
                    placeHolder: 'Select time',
                    validation: {
                        isRequired: 'This field is required.',
                        minimumHourNotice: 'hours notice is required to make a booking.'
                    }
                },
                serviceType: {
                    label: 'What would you like to service?',
                    validation: {
                        isRequired: 'This field is required.'
                    }
                },
                subserviceType: {
                    validation: {
                        isRequired: 'This field is required'
                    }
                },
                serviceComment: {
                    label: 'What would you like us to take note?',
                    placeholder: 'Describe the condition of your item and tell us more about your concern'
                },
                photos: {
                    label: 'Upload image references of your item(s)',
                    placeholder: 'Your photos help us to provide an accurate quotation'
                },
                comment: {
                    label: 'Tell our Service Partners what you need done',
                    placeholder: 'Any additional instructions or language preferences?',
                    validation: {
                        isRequired: 'This field is required'
                    }
                },
                materials: {
                    title: 'Materials',
                    trueText: 'Yes I have the materials/parts available',
                    falseText: 'No, service partner can provide the materials/parts',
                    label: 'Do you already have the materials or parts for the service?',
                    uploadLabel: 'Please upload your materials images to help us understand better before the service.'
                }
            },
            calendarModal: {
                oneTime: {
                    title: 'Handyman',
                    description: 'Select your service day',
                    select: {
                        title: 'Select the time for your service partner to come',
                        description: 'We recommend selecting 3 hours based on your bedrooms and bathrooms.'
                    }
                }
            }
        },
        handymanSummary: {
            pageTitle: 'Handyman Summary',
            title: 'Handyman',
            cancellationFees: 'What are the cancellation charges?',
            serviceLocation: 'Service address',
            sections: {
                additionalInformation: 'Additional Information'
            }
        },
        handymanDetails: {
            expertiseType: 'Expertise type',
            serviceLevel: 'Service level',
            submittedMessage: 'When a Service Partner is assigned, you will be notified and your Service Partner will provide a quotation for you to review.'
        },
        laundry: {
            pageTitle: 'Book Laundry Service',
            title: 'Laundry service',
            serviceDescription: 'Premium cleaning and care for your garments with personalised attention and special treatments for delicate fabrics.',
            breadCrumbs: {
                requirements: 'Laundry requirements',
                confirmation: 'Booking confirmation'
            },
            collectionTime: 'Collection date & time',
            collectionPoint: 'Collection point',
            selectedServices: 'Selected services',
            services: {
                title: 'What services do you need?',
                description: 'Please note that a minimum charge of S$40 is required to book our laundry service.'
            },
            packingDescription: {
                singleService: 'Pack your items in one bag, we will collect it and count the items',
                multipleServices: 'For multiple services, pack your items in bags, one bag per service, we will collect it and count the items for each service'
            },
            viewPrices: 'View details & prices',
            minimumCharges: 'Minimum charges',
            minimumNotice: {
                prefix: '*Minimum job notice',
                suffix: 'hours'
            },
            formFields: {
                startingDate: {
                    label: 'Service date & time',
                    placeholder: 'Select date & time',
                    validation: {
                        isRequired: 'This field is required.'
                    }
                },
                driverInstructions: {
                    label: 'Instructions for the Driver:',
                    validation: {
                        isRequired: 'This field is required'
                    }
                },
                washAndIron: {
                    label: 'Wash & iron'
                },
                washAndIronItems: {
                    label: 'Number of items',
                    validation: {
                        isRequired: 'This field is required'
                    }
                },
                dryCleanAndIron: {
                    label: 'Dry clean & iron'
                },
                dryCleanAndIronItems: {
                    label: 'Number of items',
                    validation: {
                        isRequired: 'This field is required'
                    }
                },
                ironOnly: {
                    label: 'Iron only'
                },
                ironOnlyItems: {
                    label: 'Number of items',
                    validation: {
                        isRequired: 'This field is required'
                    }
                },
                loadWash: {
                    label: 'Load wash / no ironing'
                },
                loadWashItems: {
                    label: 'Number of items',
                    validation: {
                        isRequired: 'This field is required'
                    }
                },
                curtainsWash: {
                    label: 'Curtains wash'
                },
                curtainsWashItems: {
                    label: 'Number of items',
                    validation: {
                        isRequired: 'This field is required'
                    }
                },
                frequency: {
                    label: 'Laundry Frequency',
                    validation: {
                        isRequired: 'This field is required.'
                    }
                },
                selectTime: {
                    label: 'Select the collection time',
                    placeHolder: 'Select time',
                    validation: {
                        isRequired: 'This field is required.',
                        minimumHourNotice: 'hours notice is required to make a booking.'
                    }
                }
            },
            calendarModal: {
                oneTime: {
                    title: 'Laundry',
                    description: 'Select the collection day',
                    select: {
                        title: 'Select the collection time',
                        description: 'We recommend selecting 3 hours based on your bedrooms and bathrooms.'
                    }
                }
            },
            driverInstructions: {
                outside: {
                    label: 'Collect items placed outside the door',
                    key: 'Outside'
                },
                personal: {
                    label: 'Ring the door bell and collect items directly from me',
                    key: 'Personal'
                },
                reception: {
                    label: 'Collect items from the reception',
                    key: 'Reception'
                }
            },
            additionalInfo: {
                pickUp: 'Pick up instructions',
                services: 'Services required',
                items: 'items'
            }
        },
        laundrySummary: {
            pageTitle: 'Laundry Summary',
            title: 'Laundry',
            cancellationFees: 'What are the cancellation charges?',
            serviceLocation: 'Service location',
            packingReminder: 'Reminder: Sort your laundry items into separate bags by the type of service required. Alternatively, you may use reusable bags from our representative.',
            sections: {
                additionalInformation: 'Additional Information'
            }
        },
        cooking: {
            pageTitle: 'Book Cooking Service',
            title: 'Cooking service',
            serviceDescription: 'One-off or regular cooking service done by professionals.',
            footerText: '*Groceries are not included, please provide the requested groceries along with the cooking equipment.',
            dishInfo: '*Our Service Partners might not be able to cook specific dishes. Please provide a recipe when discussing with our Service Partner.',
            cleaningInfo: '*The kitchen will be returned as is. Our service partner will only clean what has been used & the area they have worked on.',
            cookingWeeklyScheduleInfo: 'The cooking service will take place every week on:',
            cookingFortnightlyScheduleInfo: 'The cooking service will take place every second week on:',
            breadCrumbs: {
                requirements: 'Cooking requirements',
                confirmation: 'Booking confirmation'
            },
            formFields: {
                startingDate: {
                    label: 'Cooking date & time',
                    placeholder: 'Select date & time',
                    validation: {
                        isRequired: 'This field is required.'
                    }
                },
                frequency: {
                    label: 'Cooking Frequency',
                    validation: {
                        isRequired: 'This field is required.'
                    }
                },
                selectTime: {
                    placeHolder: 'Select time',
                    validation: {
                        isRequired: 'This field is required.',
                        minimumHourNotice: 'hours notice is required to make a booking.'
                    }
                },
                hours: {
                    label: 'How many hours do you need?',
                    placeholderPartOne: 'We recommend selecting',
                    placeholderPartTwo: 'based on your property.',
                    validation: {
                        isRequired: 'This field is required.'
                    }
                },
                propertyType: {
                    label: 'Tell us more about your property',
                    placeholder: 'Type of property',
                    validation: {
                        isRequired: 'This field is required.'
                    }
                },
                appliances: {
                    placeholder: 'Do you have an oven and/or dishwasher?',
                    validation: {
                        isRequired: 'This field is required.'
                    }
                },
                cuisines: {
                    label: 'Choose the cooking style',
                    placeholder: 'Select...',
                    validation: {
                        isRequired: 'This field is required.'
                    }
                },
                comment: {
                    placeholder: 'Can\'t find a dish or have a special request? Write it here...'
                }
            },
            calendarModal: {
                oneTime: {
                    title: 'Cooking',
                    description: 'Select the first cooking date',
                    select: {
                        title: 'Select the first cooking time',
                        description: 'We recommend selecting 3 hours based on your bedrooms and bathrooms.'
                    }
                }
            }
        },
        cookingSummary: {
            pageTitle: 'Cooking Summary',
            title: 'Cooking',
            schedule: 'Cooking Schedule',
            cancellationFees: 'What are the cancellation charges?',
            serviceLocation: 'Service location',
            sections: {
                additionalInformation: 'Additional Information'
            },
            additionalInformation: {
                propertyInformation: {
                    title: 'Property information:',
                    oven: 'Oven',
                    dishwasher: 'Dishwasher'
                },
                specialRequest: 'Special request:',
                allergies: 'Allergies:'
            }
        },
        airCondition: {
            pageTitle: 'Air Condition Service',
            title: 'Air Condition service',
            servicing: 'servicing',
            customService: 'Custom servicing',
            serviceDescription: 'General repair and periodic maintenance of air conditioners by certified technicians.',
            customMessage: '*For Custom bookings, a quotation will be sent to you after our Service Partner accept the job.',
            estimatedMessage: '*Please note our prices are only an indication of the price. The final price will be estimated by our service partners after the visit.',
            disclaimer: '•  Any ducted and long wide wall mount unit will incur an additional cost of $10. \n•  Prices mentioned above are only an estimate based on the basic job scope. Final quotation may vary depending on the nature and complexity of the project.',
            breadCrumbs: {
                requirements: 'Air Condition requirements',
                confirmation: 'Booking confirmation'
            },
            formFields: {
                serviceOption: {
                    label: 'Service plan',
                    validation: {
                        isRequired: 'This field is required.'
                    }
                },
                frequency: {
                    label: 'Cleaning Frequency',
                    validation: {
                        isRequired: 'This field is required.'
                    }
                },
                startingDate: {
                    label: 'Service date & time',
                    placeholder: 'Select date & time',
                    validation: {
                        isRequired: 'This field is required.'
                    }
                },
                selectTime: {
                    placeHolder: 'Select time',
                    validation: {
                        isRequired: 'This field is required.',
                        minimumHourNotice: 'hours notice is required to make a booking.'
                    }
                },
                numberOfAirConditioners: {
                    label: 'Number of Air Conditioners',
                    validation: {
                        isRequired: 'This field is required.'
                    }
                }
            },
            calendarModal: {
                oneTime: {
                    title: 'Service',
                    description: 'Select your service day',
                    select: {
                        title: 'Select the time for your service partner to come',
                        description: 'We recommend selecting 3 hours based on your bedrooms and bathrooms.'
                    }
                },
                weekly: {
                    title: 'Service',
                    description: 'Select the starting date',
                    select: {
                        title: 'Select the days & time for this service',
                        description: 'Select up to 7 days per week. This schedule will be repeated weekly.'
                    }
                },
                fortnightly: {
                    title: 'Service',
                    description: 'Select the starting date',
                    select: {
                        title: 'Select the days & time for this service',
                        description: 'Select up to 7 days per week. This schedule will be repeated every 2 weeks.'
                    }
                }
            }
        },
        airConditionSummary: {
            pageTitle: 'Air Condition Service Summary',
            title: 'Summary',
            cancellationFees: 'What are the cancellation charges?',
            serviceLocation: 'Service location',
            visitCharges: 'Visit charges S$ 35',
            visitChargesTitle: 'Visit charges',
            visitChargesMessage: 'Waived upon job completion',
            visitChargesDescription: 'Please note that a $35 visitation charge will apply if you choose not to proceed with our air conditioning services after the Service Partner has either conducted a diagnosis or arrived at your location.',
            sections: {
                additionalInformation: 'Additional Information'
            }
        },
        pestControl: {
            pageTitle: 'Pest Control Service',
            title: 'Pest Control service',
            serviceDescription: 'Management and elimination of unwanted insects, rodents, and other pests in homes and commercial properties.',
            estimatedMessage: '*Please note our prices are only an indication of the price. The final price will be estimated by our service partners after the visit.',
            selectServices: 'Select the services',
            serviceSelection: 'Your selection:',
            startingFrom: 'Starting from',
            tabs: {
                bySession: 'By session',
                byPackage: 'By package'
            },
            breadCrumbs: {
                requirements: 'Pest Control requirements',
                confirmation: 'Booking confirmation'
            },
            services: {
                bedbugs: 'Bedbugs',
                beehives: 'Beehives',
                booklice: 'Booklice',
                cobra: 'Cobra',
                cockroach: 'Cockroach',
                commensalRodents: 'Commensal Rodents',
                commonAnts: 'Common Ants',
                commonFlies: 'Common Flies',
                commonHouseSnakes: 'Common House Snakes',
                drywoodTermites: 'Drywood Termites',
                hornets: 'Hornets',
                mosquitoes: 'Mosquitoes',
                python: 'Python',
                subterraneanTermites: 'Subterranean Termites'
            },
            servicesNotes: {
                allBedrooms: 'All bedrooms',
                anyLength: 'Any length',
                bigger: 'Bigger',
                estLength: 'Est. 2-3 meters',
                perLocation: 'Per location/site',
                soccerBallSize: 'Soccer Ball Size',
                tennisBallSize: 'Tennis Ball Size'
            },
            propertyType: {
                smallHDB: 'HDB 2/3 Room Apartment',
                largeHDB: 'HDB 4/5 Room Apartment',
                smallCondo: 'Condo 1/2 Room Apartment',
                mediumCondo: 'Condo 3/4 Room Apartment',
                largeCondo: 'Condo 5+ Room Apartment',
                smallLanded: '2/3 Bedroom Landed',
                largeLanded: '4+ Bedroom Landed'
            },
            packageDescription: 'Package covers only:',
            packages: {
                package1: {
                    title: 'Package 1',
                    description: 'Package covers only: Common Ants, Cockroaches, Commensal Rodents, Mosquitoes and Flies.'
                },
                package2: {
                    title: 'Package 2',
                    description: 'Package covers only: Sub-Terranean Termites'
                },
                frequency: {
                    monthly: 'Monthly',
                    bimonthly: 'Every 2 months',
                    quarterly: 'Quarterly'
                },
                session: 'session',
                sessions: 'sessions',
                priceSuffix: 'per session',
                viewDetails: 'View Details'
            },
            minimumNotice: {
                prefix: 'Minimum job notice',
                suffix: 'h'
            },
            minimumSessions: 'The number of sessions are the minimum recommended from our Service Partners.',
            formFields: {
                frequency: {
                    label: 'Cleaning Frequency',
                    validation: {
                        isRequired: 'This field is required.'
                    }
                },
                startingDate: {
                    label: 'Service date & time',
                    placeholder: 'Select date & time',
                    validation: {
                        isRequired: 'This field is required.'
                    }
                },
                selectTime: {
                    label: 'Select the starting time',
                    placeHolder: 'Select time',
                    validation: {
                        isRequired: 'This field is required.',
                        minimumHourNotice: 'hours notice is required to make a booking.'
                    }
                },
                propertyType: {
                    label: 'Your property type'
                },
                services: {
                    validation: {
                        isRequired: 'This field is required'
                    }
                },
                packages: {
                    validation: {
                        isRequired: 'This field is required'
                    }
                }
            },
            calendarModal: {
                oneTime: {
                    title: 'Service',
                    description: 'Select your service day',
                    select: {
                        title: 'Select the time for your service partner to come',
                        description: 'We recommend selecting 3 hours based on your bedrooms and bathrooms.'
                    }
                },
                weekly: {
                    title: 'Service',
                    description: 'Select the starting date',
                    select: {
                        title: 'Select the days & time for this service',
                        description: 'Select up to 7 days per week. This schedule will be repeated weekly.'
                    }
                },
                fortnightly: {
                    title: 'Service',
                    description: 'Select the starting date',
                    select: {
                        title: 'Select the days & time for this service',
                        description: 'Select up to 7 days per week. This schedule will be repeated every 2 weeks.'
                    }
                }
            }
        },
        pestControlSummary: {
            additionalInformation: 'Additional information'
        },
        taskAndErrands: {
            pageTitle: 'Book Task & Errands Service',
            title: 'Task & Errands service',
            serviceDescription: 'Professional help for everyday tasks.',
            breadCrumbs: {
                requirements: 'Task & Errands requirements',
                confirmation: 'Booking confirmation'
            },
            formFields: {
                frequency: {
                    label: 'Service Frequency',
                    validation: {
                        isRequired: 'This field is required.'
                    }
                },
                startingDate: {
                    label: 'Service date & time',
                    placeholder: 'Select date & time',
                    validation: {
                        isRequired: 'This field is required.'
                    }
                },
                selectTime: {
                    placeHolder: 'Select time',
                    validation: {
                        isRequired: 'This field is required.',
                        minimumHourNotice: 'hours notice is required to make a booking.'
                    }
                },
                hours: {
                    label: 'How many hours would this require?',
                    description: '*If the tasks takes longer for our Service Partner to complete, you will be charged for extra hours.',
                    validation: {
                        isRequired: 'This field is required.'
                    }
                },
                purchasing: {
                    label: 'Purchasing services'
                },
                taskType: {
                    label: 'Tell us more about the task',
                    placeholder: 'Type task or errands',
                    description: '*The tasks requiring buying items for you will be charged upon delivery with proof of payment from our Service Partner.',
                    validation: {
                        isRequired: 'This field is required.'
                    }
                },
                otherTaskComment: {
                    label: 'Tell us what you need done',
                    placeholder: 'Hi, I\'m looking for someone to...',
                    validation: {
                        isRequired: 'This field is required'
                    }
                },
                vehicle: {
                    placeholder: 'Does this task requires a motorised vehicle?',
                    validation: {
                        isRequired: 'This field is required.'
                    }
                }
            },
            taskType: {
                grocery: 'Grocery shopping',
                moving: 'Moving & rearranging furnitures',
                lift: 'Lift & carry heavy loads',
                moveIn: 'Move-in / move-out organisation',
                office: 'Office / home parcel delivery',
                other: 'Other'
            },
            vehicle: {
                no: 'No',
                car: 'Yes, a car',
                van: 'Yes, a van',
                truck: 'Yes, a truck'
            },
            calendarModal: {
                oneTime: {
                    title: 'Task & Errands',
                    description: 'Select the service day',
                    select: {
                        title: 'Select the starting time',
                        description: 'This is the time our Service Partner will start the task.'
                    }
                }
            }
        },
        moversAndPackersDetails: {
            rescheduleService: 'Reschedule service',
            rescheduleInspection: 'Reschedule inspection date',
            rescheduleDescription: 'Select your moving day',
            expertiseType: 'Expertise Type',
            inspectionDate: 'Inspection Date',
            pickUpLocation: 'Pick up location',
            dropOffLocation: 'Drop off location',
            serviceInformation: 'Service information',
            addonServices: 'Add-on services',
            propertyDetails: 'Property Details:',
            propertyType: 'Type Of Property',
            bedrooms: 'No of Bedrooms',
            propertyFloor: 'Property Floor',
            hasElevator: 'Is there an elevator?',
            propertySize: 'Size of Property'
        },
        bookings: {
            pageTitle: 'Bookings',
            title: 'My Bookings',
            empty: {
                upcoming: 'No upcoming jobs yet',
                completed: 'No completed jobs yet',
                cancelled: 'No cancelled jobs yet',
                description: 'Find the service you’re looking for to get started.',
                descriptionNative: 'Go to home to get start and book a service',
                findExpert: 'Find a Service Partner',
                pullToRefresh: 'Checking for updates...'
            },
            guestBooking: {
                formFields: {
                    name: {
                        validation: {
                            isRequired: 'Name is required',
                            minValue: 'Name is too short',
                            maxValue: 'Name is too long'
                        },
                        placeholder: 'Enter your name',
                        label: 'Name'
                    },
                    phoneNumber: {
                        validation: {
                            isRequired: 'Phone number is required',
                            minValue: 'Phone number is too short',
                            maxValue: 'Phone number is too long'
                        },
                        placeholder: 'Enter your phone number',
                        label: 'Phone number'
                    },
                    address: {
                        validation: {
                            isRequired: 'Address is required',
                            minValue: 'Name is too short'
                        },
                        placeholder: 'Enter your address',
                        label: 'Address'
                    }
                }
            },
            viewAllMonths: 'View All',
            pagination: {
                to: 'to',
                of: 'of',
                results: 'results'
            },
            toastMessage: {
                success: 'Your dashboard is up to date'
            }
        },
        completedBookings: {
            pageTitle: 'Completed Bookings',
            title: 'My Bookings'
        },
        cancelledBookings: {
            pageTitle: 'Cancelled Bookings',
            title: 'My Bookings'
        },
        payment: {
            pageTitle: 'Payment',
            title: 'Payment',
            selectPayment: 'Select your Payment Method',
            paymentDescription: 'The amount will be deducted as soon as one of our service partners accepts the job, until then, you will not be charged.',
            paymentSummary: 'Payment summary',
            paymentMethod: 'Payment Method',
            paymentMethodDescription: 'You will not be charged until you accept the bill proposed by your service provider.'
        },
        bookingSuccess: {
            pageTitle: 'Booking Success',
            title: 'Thanks for your booking!',
            pending: 'Pending booking confirmation',
            bookingId: 'Your booking ID',
            bookingMessage: 'Your booking request has been sent to our Service Partners. You won’t be charged until your request has been accepted.\nA confirmation will be sent to you when the Service Partner responds to your request.',
            bookingMessageGuest: 'Your booking request has been sent to our Service Provider. Our customer service will contact you and send you a payment link once the Service Provider responds to your request.',
            faqTitle: 'Useful information while you wait...',
            bookingAcceptedFaq: 'Once the booking is accepted, do I need to do anything?',
            whatToProvideFaq: 'What to provide to our Service Partner?',
            youHaveJustSaved: 'You have just saved',
            buttons: {
                home: 'Back to home',
                myBookings: 'My bookings',
                myBooking: 'My booking'
            },
            crossSale: {
                title: 'You may also be interested'
            }
        },
        chat: {
            pageTitle: 'Job Chat',
            title: 'Chat',
            formFields: {
                message: {
                    placeholder: 'Type your message',
                    validation: {
                        isRequired: 'This field is required.'
                    }
                }
            },
            chatNotification: {
                message: 'You have unread messages',
                from: 'from your service partners',
                cta: 'View now'
            },
            header: {
                connectionStatus: {
                    online: 'Online',
                    offline: 'Offline',
                    connecting: 'Connecting'
                }
            },
            sideMenu: {
                fetchError: 'There was an error while fetching job details',
                expert: 'Service Partner',
                hoursPerSession: 'Hours per session'
            },
            joinRoomError: 'Couldn\'t connect to the chat. Please try again',
            leaveRoomError: 'Something went wrong while leaving the chat',
            unsupportedMessage: 'This message is not supported by your app.',
            loadEarlierMessages: 'Load earlier messages',
            imageUploadSizeError: 'Selected image is too big. Maximum image size is',
            imageUploadError: 'Something went wrong while uploading your image. Please try again',
            dragZone: 'Drag file here',
            offlineMessage: 'You\'re offline. Check your internet connection status',
            unableToConnect: 'Unable to connect',
            reconnect: 'Reconnect',
            imageLoadFailed: 'Image failed to load'
        },
        sendPay: {
            pageTitle: 'SendPay',
            title: 'SendPay Credits',
            balance: 'Current Balance',
            notEnoughCredits: 'Balance insufficient. Please top up your account.'
        },
        notifications: {
            pageTitle: 'Notifications',
            tabs: {
                all: 'All',
                unread: 'Unread'
            },
            noNotifications: {
                title: 'No new notifications yet',
                message: 'Pull down to refresh'
            },
            noUnreadNotifications: {
                title: 'No unread notifications',
                message: 'Pull down to refresh'
            },
            notificationDetails: {
                title: 'Notification',
                ok: 'ok'
            },
            statusMessages: {
                inProgress: 'Checking for notifications...',
                success: 'Your notifcations are up to date'
            }
        },
        unreadNotifications: {
            pageTitle: 'Unread notifications'
        },
        cancelJob: {
            pageTitle: 'Cancel job',
            hi: 'Hi',
            sadToSeeYouGo: 'we are sad to see you go',
            areYouSureToWantTo: 'Are you sure you want to',
            cancelPlan: 'Cancel plan',
            unresolvedIssues: 'If you have any unresolved issues, we are here to help',
            contactUs: 'Contact us',
            iWouldLikeTo: 'I would like to',
            cancellationReasonPrompt: 'Before you go, tell us why you want to cancel the plan?',
            planCancelAfterSession: 'Your plan will be cancelled after your current session is completed.',
            keepMyPlan: 'Keep my plan',
            cancelJobFailed: 'Failed to cancel the job',
            paymentSummary: 'Payment Summary',
            sessionRemaining: 'Session remaining',
            additionalFee: 'Additional fee',
            whyAmIBeingCharged: 'Why am I being charged',
            cancellationFee: 'Cancellation Fee',
            viewDetails: 'View details'
        },
        jobDetails: {
            pageTitle: 'Job Booking Details',
            title: 'Booking Details',
            submittedMessage: 'When a Service Partner is assigned, you will be notified and your preferred payment method will be charged.',
            rescheduledMessage: 'Our support team will confirm your rescheduling request within 48 hours',
            sessionsDetails: 'Sessions details',
            completedSessions: 'Completed sessions',
            upcomingSessions: 'Upcoming sessions',
            cancel: 'Cancel',
            cancelRequest: 'Cancel Request',
            cancelPackage: 'Cancel package',
            cancelPlan: 'Cancel plan',
            needReschedule: 'Need to reschedule?',
            cancelled: 'This package has been cancelled',
            viewDetails: 'View Details',
            viewSession: 'View this session',
            rescheduleSession: 'Reschedule session',
            reschedulePackage: 'Reschedule package',
            // rescheduleTheBooking is duplicated
            rescheduleTheBooking: 'Reschedule booking',
            reschedulePlan: 'Reschedule plan',
            updateRescheduleRequest: 'Update reschedule request',
            reschedule: 'Reschedule',
            addHoursToThisSession: 'Add hours to the session',
            currentServiceDate: 'Current service date',
            rescheduleSuccessMessage: 'Booking time updated',
            rescheduleRequestDetailModal: {
                title: 'Rescheduling request details',
                requestDate: 'Requested date',
                currentDate: 'Current date'
            },
            servicesColumns: {
                items: 'Items',
                category: 'Category',
                quantity: 'Q'
            },
            sections: {
                cuisine: 'Cuisine',
                cleaningDays: 'Cleaning days',
                cleaningDate: 'Cleaning date',
                serviceDate: 'Service Date',
                additionalInformation: 'Additional information',
                location: 'Service Location',
                hoursPerSession: 'Hours per session',
                deepCleaningDate: 'Deep Cleaning date',
                price: 'Price',
                ironing: 'Ironing',
                totalAmount: 'Total amount',
                serviceType: 'Service type',
                numberOfAirConditioners: 'No. of air conditioners',
                promotionCode: 'Promotion code',
                propertyType: 'Property Type',
                propertyDeepClean: 'Property Deep Clean',
                services: 'Services',
                sessions: 'Sessions',
                packageName: 'Package name',
                frequency: 'Frequency',
                vehicle: 'Motorised vehicle',
                purchasing: 'Purchasing services',
                pricePerSession: 'Price per session',
                additionalFee: 'Additional fee'
            },
            cancelation: {
                packageTitle: 'Cancel Booking Request',
                message: 'We are sorry to see you go ',
                formFields: {
                    reason: {
                        label: 'Please tell us why you want to cancel the booking',
                        placeHolder: 'Select cancellation reason',
                        validation: {
                            isRequired: 'This field is required.'
                        }
                    },
                    customReason: {
                        label: 'Other reason',
                        placeHolder: 'Please describe your reason for cancellation',
                        placeHolderForNative: 'Please describe your reason\nfor cancellation',
                        validation: {
                            isRequired: 'This field is required.'
                        }
                    }
                },
                rescheduleBooking: 'Rescheduled booking'
            },
            cancelationPackage: {
                packageTitle: 'Cancel package',
                message: 'We are sorry to see you go ',
                back: 'Back',
                formFields: {
                    cancelationOption: {
                        label: 'Select a cancellation option',
                        placeHolder: 'Select cancellation option',
                        validation: {
                            isRequired: 'This field is required.'
                        }
                    },
                    reason: {
                        label: 'Please let us know which option you want to choose',
                        placeHolder: 'Select cancellation reason',
                        validation: {
                            isRequired: 'This field is required.'
                        }
                    },
                    customReason: {
                        label: 'Other reason',
                        placeHolder: 'Please describe your reason for cancellation',
                        placeHolderForNative: 'Please describe your reason\nfor cancellation',
                        validation: {
                            isRequired: 'This field is required.'
                        }
                    }
                },
                rescheduleBooking: 'Rescheduled booking'
            },
            subscriptionRescheduleRequest: {
                packageTitle: 'Would you like to reschedule instead?',
                packageTitleWithAdditionFee: 'You’ll be charged by cancelling',
                message: 'You can request to schedule a new date and time that works for you.',
                keepMyPlan: 'Keep my plan',
                submitReschedulingRequest: 'Submit rescheduling request',
                noIWantToCancelPlan: 'No, I want to cancel plan',
                youHave: 'You have',
                sessions: 'sessions',
                remaining: 'remaining',
                youWillBeChargedAnAdditional: 'You will be charged an additional',
                toCancelPlan: 'to cancel plan.',
                whyAmIBeingCharged: 'Why am I being charged',
                sessionChargeWarning: 'Complete the remaining sessions in the cycle to avoid getting charged.',
                requestNewSchedule: 'or you can request to schedule a new date and time that works for you.'
            },
            suggestPauseSubscriptionModal: {
                packageTitle: 'Take a break by pausing the plan',
                message: 'You won’t be charged while the plan is paused.',
                highlightOfYourPlan: 'Highlight of Your Plan',
                pauseAndUnpauseAnytime: 'Pause and unpause your plan anytime',
                bestValueSaving: 'Your plan has the best value for savings.',
                thePlanHasHelpedYouSaved: 'The plan has helped you saved a total of',
                youGetTheSameCleaner: 'You get the same cleaner every time',
                dedicatedSupport: 'Dedicated support',
                resolveYourIssues: 'to resolve your issues',
                reschedulePlan: 'Reschedule plan',
                pausePlan: 'Pause plan',
                noIWantToCancelPlan: 'No, I want to cancel plan'
            },
            pauseSubscriptionModal: {
                title: 'Pause plan',
                subTitle: 'You can pause this plan up to one month',
                pauseFrom: 'Pause from',
                resumeAfter: 'Resume after',
                selectDate: 'Select date',
                notice: 'We need at least 72 hours of notice before pausing this plan',
                reschedulePlan: 'Reschedule plan',
                continue: 'Continue'
            },
            rescheduleRequest: {
                packageTitle: 'Do you want to reschedule booking request instead?',
                pausePackageTitle: 'Do you want to pause package instead?',
                message: 'You can reschedule this booking to a different date and time',
                proceedToCancel: 'Proceed to cancel',
                rescheduleBooking: 'Reschedule booking',
                haveAnIssue: 'Have an issue?',
                contactUs: 'Contact us',
                pausePackage: 'Pause package',
                pausePlan: 'Pause plan',
                viewFAQs: 'View FAQs',
                customPackageTitle: 'Submit a request to reschedule booking instead?',
                customMessage: 'You can request to reschedule this booking to a different date and time',
                submitRequest: 'Submit a request'
            },
            rescheduleBooking: {
                packageTitle: 'Request Reschedule',
                planTitle: 'Submit Rescheduling Request',
                planHeading: 'On which day(s) do you need cleaning service?',
                planDescription: 'Your rescheduling request is subject to availability of service partner',
                message: 'Please select new booking details to request rescheduling',
                formTitle: 'Updated Date and Time',
                formSubTitle: 'When do you want the service to repeat?',
                planRescheduledMessage: 'Your request will be effective after your current session is completed',
                startingDate: 'Starting date',
                prefixOfNote: '*A total of',
                unitOfNote: 'sessions',
                suffixOfNote: 'are automatically generated',
                back: 'Back',
                send: 'Send',
                updateDateAndTime: 'Update Date and Time',
                haveAnIssue: 'Have an issue?',
                contactUs: 'Contact us',
                orViewOur: 'or view our',
                termsOfService: 'terms of service',
                and: '&',
                cancellationPolicy: 'cancelation policy'
            },
            rescheduleRequestSend: {
                packageTitle: 'Reschedule Request Sent',
                weeklyRepeat: '*Repeating weekly on',
                fortnightlyRepeat: '*Repeat every two weeks on',
                and: 'and',
                sending: 'Sending',
                done: 'Done',
                note: 'Stay assured, our team is reviewing your request and will reach out to you to confirm the details',
                subscriptionNote: 'Your upcoming sessions will be updated once your request is confirmed.'
            },
            cancelRequestSend: {
                packageTitle: 'Cancellation Request Sent',
                sending: 'Sending',
                done: 'Done',
                note: 'Stay assured, our team is reviewing your request and will reach out to you to confirm the details',
                thankForSupport: 'Rest assured, our team is looking into your request and will contact you soon to confirm the cancellation details'
            },
            cancelBookingSuccess: {
                bookingRequestCancelled: 'Booking Request Cancelled',
                bookingCancelled: 'Booking cancelled',
                theBooking: 'The booking',
                theBookingRequest: 'The booking request',
                hasBeenCancelledSuccessfully: 'has been cancelled successfully',
                done: 'Done'
            },
            cancelPackageRequestSend: {
                packageTitle: 'Cancel Request Sent',
                sending: 'Sending',
                continue: 'Continue',
                note: 'Stay assured, our team is reviewing your request and will reach out to you to confirm the details'
            },
            cancelPackageSuccess: {
                packageTitle: 'Package cancelled',
                sending: 'Sending',
                continue: 'Continue',
                thePackageBooking: 'The package',
                hasBeenCancelledSuccessfully : 'has been cancelled successfully',
                note : 'This package won’t renew after your last session on'
            },
            rescheduleBookingSuccess: {
                packageTitle: 'Booking Rescheduled',
                theBookingRequest: 'The booking request',
                hasBeenRescheduledSuccessfully: 'has been rescheduled successfully',
                done: 'Done'
            },
            pausePackageSuccess: {
                packageTitle: 'Package Paused',
                description: 'The package request has been paused successfully',
                done: 'Done'
            },
            subscriptionPauseSuccessModal: {
                packageTitle: 'Your plan has been paused',
                description: 'You can resume your plan anytime from your booking details page',
                done: 'Done',
                resumeOn: 'Resume on'
            },
            cancelModal: {
                title: 'Do you want to cancel this booking?',
                formFields: {
                    reason: {
                        placeholder: 'What\'s the reason for cancelling?',
                        validation: {
                            isRequired: 'This field is required'
                        }
                    }
                },
                success: {
                    title: 'Booking request cancelled',
                    message: 'The booking has been cancelled successfully'
                }
            },
            subscriptionCancelSuccessModal: {
                packageTitle: 'Subscription plan cancelled',
                yourPlan: 'Your plan',
                hasBeenCancelledSuccessfully: 'has been cancelled successfully.',
                thankForSupport: 'Thanks for your past support, we hope for another chance to serve you again soon!',
                backToHome: 'Back to home',
                makeANewBooking: 'Make a new booking'
            },
            recurringMessage: 'When a Service Partner is assigned, you will be notified and your preferred payment method will be charged.'
        },
        allServices: {
            pageTitle: 'Book our services',
            bookNow: 'Book Now',
            bookInMobileApp: 'Book now on our mobile application',
            learnMore: 'Learn more',
            services: {
                cleaning: {
                    title: 'Cleaning',
                    description: 'Experience 5-stars cleaning service'
                },
                deepCleaning: {
                    title: 'Deep Cleaning',
                    description: 'Refresh your home with exceptional deep cleaning services'
                },
                airCondition: {
                    title: 'Air Condition',
                    description: 'Stay cool: Expert AC maintenance & cleaning services'
                },
                handyman: {
                    title: 'Handyman',
                    description: 'Fixup your home without lifting a finger'
                },
                laundry: {
                    title: 'Laundry',
                    description: 'Premium laundry & dry cleaning services'
                },
                cooking: {
                    title: 'Cooking',
                    description: 'Hire a personal chef for customised culinary experience'
                },
                pestControl: {
                    title: 'Pest Control',
                    description: 'Keep your home & properties safe form damaging pests'
                },
                taskAndErrands: {
                    title: 'Task & Errands',
                    description: 'Hire a personal assistant to fulfill everyday tasks\n'
                },
                moversAndPackers: {
                    title: 'Movers & Packers',
                    description: 'Seamless transition for your household or business relocations\n'
                }
            },
            note: {
                question: 'Not sure what to book?',
                contactUs: 'Contact us'
            }
        },
        allServicesPostcode: {
            pageTitle: 'Book our services',
            goBack: 'Back to all our services',
            title: 'Please enter your postcode',
            formFields: {
                postcode: {
                    placeholder: 'Postcode',
                    validation: {
                        isRequired: 'This field is required.',
                        invalid: 'Postcode must be 6 digits long.'
                    }
                }
            }
        },
        laundryDetails: {
            collectionDate: 'Collection date',
            deliveryDate: 'Delivery date',
            showMore: 'Show more',
            showLess: 'Show less',
            setDeliveryTime: 'Set delivery time',
            setDeliveryTitle: 'Select delivery date & time',
            setDeliveryDateTitle: 'Select your delivery day',
            setDeliveryTimeTitle: 'Select the delivery time',
            viewReceipt: 'View receipt'
        },
        moversAndPackers: {
            pageTitle: 'Mover & Packer Service',
            title: 'Mover & Packer service',
            serviceDescription: 'Expertise in home & office moves, ensuring a seamless transition for your household or business relocations.',
            inspectionCharges: 'Cancellation charges S$50',
            inspectionChargesModalTitle: 'Cancellation Charges',
            inspectionChargesDescription: 'A S$50 cancellation fee applies to cancellation of a job after accepting the quotation from a Service Provider.',
            inspectionChargesMessage: 'Waives upon job completion',
            breadCrumbs: {
                requirements: 'Service requirements',
                details: 'Service details',
                confirmation: 'Booking confirmation'
            },
            serviceType: {
                homeMoving: 'Home Moving',
                commercialMoving: 'Commercial Moving'
            },
            propertyType: {
                condominium: 'Condominium',
                HDB: 'HDB',
                landed: 'Landed Property',
                others: 'Others',
                shophouse: 'Shophouse',
                office: 'Office Building'
            },
            propertySize: { // TODO to update when designs ready
                small: '< 1000 sq. ft',
                medium: '1000 - 2000 sq. ft',
                large: '> 2000 sq. ft'
            },
            priceNote: 'The final price will be provided by our experts after site inspection.',
            stairsInfo: 'There are additional charges for every flight of stairs.',
            floor: 'floor',
            floors: 'floors',
            formFields: {
                frequency: {
                    label: 'Service Frequency',
                    validation: {
                        isRequired: 'This field is required.'
                    }
                },
                startingDate: {
                    label: 'Moving date & time',
                    placeholder: 'Select date & time',
                    validation: {
                        isRequired: 'This field is required.'
                    }
                },
                bookingTime: {
                    placeHolder: 'Select time',
                    validation: {
                        isRequired: 'This field is required.',
                        minimumHourNotice: 'hours notice is required to make a booking.'
                    }
                },
                bookingDays: {
                    placeHolder: 'Select time',
                    validation: {
                        isRequired: 'This field is required.',
                        minimumHourNotice: 'hours notice is required to make a booking.'
                    }
                },
                serviceType: {
                    label: 'Which service do you require?'
                },
                pickUpLocation: {
                    label: 'Pick up location',
                    placeholder: 'Enter address',
                    modalTitle: 'Add pickup location'
                },
                dropOffLocation: {
                    label: 'Drop off location',
                    placeholder: 'Enter address',
                    modalTitle: 'Add drop off location'
                },
                propertyType: {
                    label: 'Type of property'
                },
                numberOfBedrooms: {
                    label: 'No. of bedrooms'
                },
                propertySize: {
                    label: 'Size of property'
                },
                floor: {
                    label: 'Property floor'
                },
                hasElevator: {
                    label: 'Is there an elevator?'
                },
                details: {
                    title: 'Tell us your property details:',
                    label: 'Your accurate descriptions help to understand and provide to your needs better',
                    placeholder: 'Provide describe your property type, floors & number of rooms',
                    validation: {
                        maxLength: 'Max length 500 characters'
                    }
                }
            },
            calendarModal: {
                oneTime: {
                    title: 'Moving Date & Time',
                    description: 'Select your moving day',
                    select: {
                        title: 'Select moving time'
                    }
                }
            }
        },
        moversAndPackersServiceDetails: {
            sections: {
                movingInformation: 'Moving Information'
            },
            photoUpload: {
                title: 'Upload image reference of all your moving items',
                description: 'Your photos will help us to understand how we can handle your items with the utmost care'
            },
            formFields: {
                listOfItems: {
                    label: 'List down all the items you would like to move',
                    placeholder: 'Enter all the items',
                    description: 'Your accurate descriptions help us protect your items during the move',
                    validation: {
                        maxLength: 'Max length 3000 characters'
                    }
                },
                startingDate: {
                    label: 'Inspection date & time',
                    placeholder: 'Select date & time',
                    description: 'Our service partner will inspect your pick up location & inventory items to provide a quotation',
                    validation: {
                        isRequired: 'This field is required.'
                    }
                },
                bookingTime: {
                    placeHolder: 'Select time',
                    validation: {
                        isRequired: 'This field is required.',
                        minimumHourNotice: 'hours notice is required to make a booking.'
                    }
                },
                bookingDays: {
                    placeHolder: 'Select time',
                    validation: {
                        isRequired: 'This field is required.',
                        minimumHourNotice: 'hours notice is required to make a booking.'
                    }
                },
                promoCode: {
                    placeholder: 'Apply discount code'
                }
            },
            calendarModal: {
                title: 'Inspection Date & Time',
                description: 'You can select multiple preferred dates.',
                select: {
                    title: 'Selected date(s)'
                }
            }
        },
        moversAndPackersSummary: {
            services: 'What services do you need?',
            additionalInfo: 'Additional information',
            viewDetails: 'View details',
            viewDetailsAndPrices: 'View details & prices',
            formFields: {
                movers: {
                    label: 'Movers',
                    numberLabel: 'Number of movers'
                },
                tailgating: {
                    label: 'Tailgating Service'
                },
                wrapping: {
                    label: 'Wrapping Service',
                    numberLabel: 'Number of furnitures'
                },
                pets: {
                    label: 'Do you have any pets at home?'
                },
                comment: {
                    placeholder: 'What else your service provider should know about the move?'
                },
                promoCode: {
                    placeholder: 'Apply discount code'
                }
            }
        },
        onboarding: {
            getStarted: 'Get Started',
            content: {
                homeServices: {
                    title: 'Trusted home services',
                    message: 'Explore up to 9 home services & we are ready to assist you with any task.'
                },
                topQuality: {
                    title: 'Quality workmanship',
                    message: 'We assign only vetted and experienced partners to you.'
                },
                noCashPayment: {
                    title: 'Reliable Customer Support',
                    message: 'Our customer support team is readily available to address your inquiries.'
                },
                ratings: {
                    title: 'Rate & Review',
                    message: 'Your rating helps us maintain high standards to serve you better.'
                }
            }
        },
        contactUs: {
            pageTitle: 'Contact Us',
            title: 'Contact Us',
            contactSections: {
                whatsapp: {
                    title: 'Whatsapp Us',
                    description: 'Send us a message on Whatsapp and we’ll get back to you as soon as possible.',
                    link: 'Click to Whatsapp Us'
                },
                email: {
                    title: 'Send us an email',
                    description: 'Send us an email and we’ll respond within 2-3 business days.',
                    link: 'Info@Sendhelper.com'
                },
                call: {
                    title: 'Give us a call',
                    description: 'Our hotline is available Monday-Friday 8:00 am to 8:00 pm SGT time.',
                    link: '+65 9387-5891'
                }
            },
            formFields: {
                message: {
                    label: 'Tell us how can we help?',
                    placeholder: 'Let us know how we can improve our service for you',
                    validation: {
                        isRequired: 'This field is required.',
                        minValue: 'Min length 16 characters',
                        maxValue: 'Max length 300 characters'
                    }
                }
            },
            successMessage: 'Your feedback has been sent. Thank you for your feedback!'
        },
        maintenance: {
            pageTitle: 'Under Maintenance',
            heading: 'Booking Site',
            subHeading: 'Upgrade in Progress',
            supportingText: 'Booking through the website is temporarily unavailable. We’ll be back soon to provide you with an even better experience.\n\nMeanwhile, if you’re interested, learn more about our award-winning services.',
            ctaText: {
                web: 'Take Me to Home Page',
                mobile: 'Visit Our website'
            },
            contactUs: {
                title: 'For any urgent queries, please reach out to us.',
                whatsApp: {
                    text: 'WhatsApp',
                    link: 'https://wa.me/6593875891'
                },
                email: {
                    text: 'Email Us',
                    link: 'info@sendhelper.com'
                }
            }
        }
    },
    components: {
        formsCommon: {
            isRequired: 'This field is required',
            placeholderSelect: 'Select'
        },
        subHeader: {
            completeProfile: 'Complete your profile',
            completeProfileMessage: 'One time settings & book within seconds'
        },
        socialButtons: {
            google: 'Continue with Google',
            facebook: 'Continue with Facebook',
            apple: 'Continue with Apple',
            nativeGoogle: 'Continue with Google',
            nativeFacebook: 'Continue with Facebook',
            nativeApple: 'Continue with Apple',
            appleDisclaimer: 'Continuing with Apple will redirect you to\nthe Sendhelper app.'
        },
        userDrawer: {
            links: {
                myProfile: 'My Profile',
                myBookings: 'My Bookings',
                myFavourites: 'My Favourite Service Partners',
                paymentAndCards: 'Payment & Cards',
                referAFriend: 'Refer a Friend',
                offersAndDeals: 'Offers & Deals',
                sendPayCredits: 'SendPay Credits',
                contactUs: 'Contact Us',
                logOut: 'Logout'
            },
            mobileFooter: {
                helpCentre: 'Help Centre',
                privacyPolicy: 'Privacy Policy',
                termsAndConditions: 'Terms & Conditions',
                appVersion: 'Version',
                companyName: '©2024 Sendhelper. Sendtech Pte. Ltd. (201434475Z). \n All rights reserved.'
            }
        },
        bottomBarTabs: {
            home: 'Home',
            bookings: 'Bookings',
            refer: 'Referral',
            notification: 'Notifications',
            profile: 'Profile'
        },
        myProfileTabs: {
            settings: 'Settings',
            address: 'Address',
            preferences: 'Preferences'
        },
        paymentTabs: {
            cards: 'Cards',
            paymentHistory: 'Transaction history'
        },
        bookingsTabs: {
            upcoming: 'Upcoming',
            completed: 'Completed',
            cancelled: 'Cancelled'
        },
        bookingSummary: {
            date: 'Date',
            time: 'Time',
            propertyType: 'Property type',
            noOfRooms: 'No. of rooms',
            hoursBooked: 'Hours per session',
            hours: 'No. of hours',
            cleaningPlan: 'Cleaning Plan',
            oneOff: 'One-off',
            subscription: 'Subscription',
            cleaningFrequency: 'Cleaning frequency',
            firstSession: 'First session',
            startingDate: 'Starting date',
            bookingTime: 'Booking time',
            serviceDate: 'Service date',
            serviceTime: 'Service time',
            serviceDateAndTime: 'Service date & time',
            propertySize: 'Property size',
            propertyDeepClean: 'Property deep clean',
            serviceFee: 'Service fee',
            servicePlan: 'Service plan',
            numberOfAC: 'Number of AC',
            expertiseType: 'Expertise type',
            serviceLevel: 'Service level',
            cookingFrequency: 'Cooking frequency',
            cookingStyle: 'Cooking style',
            bookingDate: 'Booking date',
            serviceType: 'Service type',
            services: 'Services',
            package: 'Package',
            frequency: 'Frequency',
            task: 'Task',
            purchasing: 'Purchasing services',
            vehicle: 'Vehicle needed',
            staircase: 'Staircase',
            movingDate: 'Moving date',
            inspectionDate: 'Inspection date',
            cleaningCharge: 'Cleaning charge',
            discountCode: 'Discount code',
            platformFee: 'Platform fee',
            priceSummary: 'Price summary',
            discountAmount: 'Discount amount',
            discount: 'Discount',
            subTotal: 'Sub total',
            sessionsGenerated: 'Sessions generated',
            bookingSuccessful: 'Booking successful!',
            sessionsGeneratedDescription: 'This ensures that the cleaning tasks are organized and carried out efficiently, one after the other, following a logical sequence to optimize time and resources. This automated process helps streamline the cleaning workflow and ensures that tasks are completed in a systematic and effective manner.'
        },
        cleaningHelpModal: {
            title: 'Cleaning service',
            description: 'One-off or periodic cleaning for your home done by professionals.',
            includedTitle: 'What’s included?',
            includedMessage: 'Basic cleaning tasks include sweeping, mopping, dusting, vacuuming, cleaning kitchen, cabinets, interior windows, bathrooms, toilets, bedrooms, washing utensils, ironing clothes, and garbage disposal.',
            howDoesItWorkTitle: 'How does it work?',
            howDoesItWorkMessage: 'You may book either a one-off or recurring cleaning service. Recurring bookings are for the same day(s) and time on a weekly or fortnightly basis with the same Service Partner.'
        },
        deepCleaningHelp: {
            title: 'Deep cleaning service',
            description: 'Specialised home cleaning done prior to moving in, after the end of a tenancy or for special occasions.',
            howDoesItWork: {
                title: 'How does it work?',
                cleaningInstruction: 'You may book a deep cleaning service by selecting the size (sq ft) of the cleaning location, and your preferred date and time at least 48 hours in advance.',
                partnerInfo: 'The Service Partner will attend at the cleaning location with their own equipment.'
            },
            included: {
                title: 'What’s included?',
                inclusionsBold: 'Wiping, Washing and Disinfection',
                inclusionsRegular: 'Living rooms, Balcony, Kitchen, Toilets, Bathrooms etc.'
            }
        },
        airConditionHelp: {
            title: 'Air Condition service',
            description: 'General repair and periodic maintenance of air conditioners by certified technicians.',
            includedTitle: 'What’s included?',
            includedMessage: 'Air conditioning services include installation, troubleshooting, repair, maintenance, chemical washing/overhaul, refrigerant refill etc.',
            howDoesItWorkTitle: 'How does it work?',
            howDoesItWorkMessage: 'You may book an air conditioning service by selecting the number of air conditioning units that require servicing, and your preferred date and time.'
        },
        pestControlHelp: {
            title: 'Pest Control service',
            description: 'Management and elimination of unwanted insects, rodents, and other pests in homes and commercial properties.',
            includedTitle: 'What’s included?',
            includedMessage: 'Pest control service typically includes identifying the type and extent of the infestation, developing a treatment plan, applying pesticides or other control measures, and ongoing monitoring and maintenance to prevent future infestations.',
            howDoesItWorkTitle: 'How does it work?',
            howDoesItWorkMessage: 'You can select a pest control service tailored to the type of pest infestation you\'re experiencing. Also you may choose from monthly, bimonthly, or quarterly packages to maintain a pest-free environment and prevent future infestations.'
        },
        handymanHelp: {
            title: 'Handyman service',
            description: 'For small fixes or large repair jobs, there is a handyman available! Putting up wall decors and pictures, mounting a TV or shelves, installing doorknobs, assembling furniture, packing and arranging, you name it, you will find a handyman to help you out.',
            includedTitle: 'What’s included?',
            includedMessagePrefix: '· Plumbing services\n· Electrical services\n· Furniture assembly services\n· Decorating services\n\nShould you have anything specific, please contact us at',
            helpEmail: 'info@sendhelper.com',
            includedMessageSuffix: 'and we will advise you on whether that can be done.',
            howDoesItWorkTitle: 'How does it work?',
            howDoesItWorkMessage: 'When booking a job via our app or website, please include a brief description of the work to be done and include some photos, if you can. A Service Partner will accept the job if they are available at your preferred time, and will send you an estimated cost for the job via the messaging function on our app or website before they attend your place.'
        },
        laundryHelp: {
            title: 'Laundry service',
            description: 'Premium cleaning and care for your garments with personalised attention and special treatments for delicate fabrics.',
            includedTitle: 'What’s included?',
            includedMessage: 'A laundry service typically includes washing/dry cleaning, drying, and folding clothes, curtains, bedsheets, pillow and cushion covers etc. Additionally services like stain removal and ironing are also available.',
            howDoesItWorkTitle: 'How does it work?',
            howDoesItWorkMessage: 'To book laundry services, select the location, date and time for item collection.\n\nOur representative will collect the items on the day and from the location chosen by you. Your bill will be uploaded onto your account which can be accessed via our app or website, and your card will be charged accordingly.\n\nOnce the laundry items are ready for delivery, you will receive a notification to choose your preferred delivery date and time.'
        },
        cookingHelp: {
            title: 'Cooking service',
            description: 'One-off or regular cooking service done by professionals.',
            includedTitle: 'What’s included?',
            includedMessage: 'Basic cooking service includes grocery shopping, meal preparation and presentation, packing and labeling food, washing utensils, cleaning cooking equipment, wiping kitchen surfaces, and disposing waste.',
            howDoesItWorkTitle: 'How does it work?',
            howDoesItWorkMessage: 'You may book either a one-off or recurring cooking service. Recurring bookings are for the same day(s) and time on a weekly or fortnightly basis with the same Service Partner.'
        },
        taskAndErrandsHelp: {
            title: 'Task & Errands',
            description: 'Professional help for everyday tasks.',
            includedTitle: 'What’s included?',
            includedMessage: 'Tasks and Errands Service includes grocery shopping, home and office parcel delivery, packing, organising homes, airport pick-up services, moving, rearranging furniture, and lifting heavy items. Please note we do not accept babysitting and pet care requests.',
            howDoesItWorkTitle: 'How does it work?',
            howDoesItWorkMessage: 'The task description box is where you will put in the scope and details for the requested task. You need to add all relevant information so that the Service Partner who accepts the task request knows what is expected of them upfront. It will help them to understand your requirements better and prepare themselves to complete the task efficiently.'
        },
        moversAndPackers: {
            title: 'Movers & Packers Service Details',
            description: 'Providing seamless home & office moving services - whether you are looking to move a single furniture or an entire house, we have all your needs covered.',
            includedList: {
                driver: 'Driver for transportation from pick up to drop off location',
                mover: '2 - 5 movers to load and unload items (depends on lorry size)',
                fuel: 'Cost of fuel'
            },
            helpEmail: 'info@sendhelper.com',
            includedTitle: 'What’s included?',
            includedMessage: 'Should you have anything specific, please contact us at ',
            includedMessagePartTwo: ' and we will advise you on whether that can be done.',
            howDoesItWorkTitle: 'How does it work?',
            howDoesItWorkMessagePartOne: 'Simply provide us with the required details (Date, location and list of moving items) and we will match you with one of our trusted service providers.',
            howDoesItWorkMessagePartTwo: 'Following which, our service provider will reach out for an inspection (virtual or in-person) to better understand your needs and provide an accurate quotation.',
            howDoesItWorkMessagePartThree: 'A final bill will be sent to your after the job is completed to your account, which can be accessed via our app or website. Once accepted, your card will be charged accordingly.'
        },
        moversAndPackersPackagePrice: {
            viewButton: 'Price Reference',
            modalTitle: 'Movers & Packers Price Package',
            description: 'Our service provider will inspect your property and moving items to provide you a quotation based on this price reference.',
            options: {
                tenLorry: {
                    title: '10ft Lorry',
                    description: 'A 10ft lorry can accommodate small furniture items and boxes, making it suitable for',
                    boldDescription: ' room moving or small apartments.',
                    addOnTitle: 'Add On Specialised Services',
                    details: {
                        baseFare: {
                            title: 'Base fare',
                            description: 'S$ 115 + $ 0.75/km'
                        },
                        sizeLimit: {
                            title: 'Size Limit (L x W x H)',
                            description: '290 x 140 x 170cm'
                        },
                        weightLimit: {
                            title: 'Weight Limit',
                            description: 'Up to 1,000 kg'
                        },
                        timeLimit: {
                            title: 'Time Limit',
                            description: '2 hours'
                        },
                        overtimeCharges: {
                            title: 'Overtime Charges',
                            description: '+ $ 5/helper for every 10min\n(capped at $250)'
                        },
                        feePerFloor: {
                            title: 'Staircase Fee',
                            description: '+ S$ 20/floor'
                        },
                        movers: {
                            title: 'Movers',
                            description: '2'
                        },
                        additionalMovers: {
                            title: 'Additional Movers',
                            description: '+ S$ 40/mover'
                        },
                        additionalStop: {
                            title: 'Additional Stop',
                            description: '+ S$ 15/stop'
                        }
                    },
                    addOns: {
                        tailgating: {
                            title: 'Tailgating Services',
                            price: '+ S$ 20',
                            description: 'Service available when bulky or commercial items are involved in the move.',
                            details: {
                                suitable: 'Suitable to load bulky items that might pose challenges for manual handling',
                                example: 'Example of bulky items include grand piano, fitness equipment, safe box and etc.'
                            }
                        },
                        wrapping: {
                            title: 'Wrapping Services',
                            price: '+ S$ 20/furniture',
                            description: 'Service available to protect your items and prevent damage during transportation.',
                            details: {
                                provider: 'Service provider will assess and determine the appropriate wrapping materials and techniques required',
                                materials: 'To utilise materials such as cling wrap, moving blankets, padding, foam cushioning, and bubble wrap'
                            }
                        },
                        assembly: {
                            title: 'Disassembly & Reassembly',
                            price: 'Price on quotation',
                            description: 'Service available to transport large or complex items.',
                            details: {
                                provider: 'Service provider will assess items to provide an accurate quotation',
                                disassemble: 'To disassemble the items with care and proper labelling if necessary',
                                reassemble: 'To reassemble the items according to its’ original state which typically includes reattaching legs, securing hardware, and making sure all parts fit correctly.'
                            }
                        },
                        packing: {
                            title: 'Packing & Unpacking',
                            price: 'Price on quotation',
                            description: 'Service available to ease your stress and mess of packing.',
                            details: {
                                provider: 'Service provider will assess items to provide an accurate quotation',
                                boxes: 'Include boxes and specialized containers for fragile or valuable items.',
                                movers: 'Movers will pack your items at the pick up location and unpack the boxes, remove items and placing them on surfaces or in appropriate cabinets and drawers at the drop off location.'
                            }
                        }
                    }
                },
                fourteenLorry: {
                    title: '14ft Lorry',
                    description: 'A 14ft lorry can accommodate larger furniture items and more boxes, making it suitable for',
                    boldDescription: ' large apartments or small houses.',
                    addOnTitle: 'Add On Specialised Services',
                    details: {
                        baseFare: {
                            title: 'Base fare',
                            description: 'S$ 148 + $0.75/km'
                        },
                        sizeLimit: {
                            title: 'Size Limit (L x W x H)',
                            description: '420 x 170 x 190 cm'
                        },
                        weightLimit: {
                            title: 'Weight Limit',
                            description: 'Up to 2,000 kg'
                        },
                        timeLimit: {
                            title: 'Time Limit',
                            description: '2 hours'
                        },
                        overtimeCharges: {
                            title: 'Overtime Charges',
                            description: '+ $ 5/helper for every 10min\n(capped at $250)'
                        },
                        feePerFloor: {
                            title: 'Staircase Fee',
                            description: '+ S$ 20/floor'
                        },
                        movers: {
                            title: 'Movers',
                            description: '2'
                        },
                        additionalMovers: {
                            title: 'Additional Movers',
                            description: '+ S$ 40/mover'
                        },
                        additionalStop: {
                            title: 'Additional Stop',
                            description: '+ S$ 19/stop'
                        }
                    },
                    addOns: {
                        tailgating: {
                            title: 'Tailgating Services',
                            price: '+ S$ 20',
                            description: 'Service available when bulky or commercial items are involved in the move.',
                            details: {
                                suitable: 'Suitable to load bulky items that might pose challenges for manual handling',
                                example: 'Example of bulky items include grand piano, fitness equipment, safe box and etc.'
                            }
                        },
                        wrapping: {
                            title: 'Wrapping Services',
                            price: '+ S$ 20/furniture',
                            description: 'Service available to protect your items and prevent damage during transportation.',
                            details: {
                                provider: 'Service provider will assess and determine the appropriate wrapping materials and techniques required',
                                materials: 'To utilise materials such as cling wrap, moving blankets, padding, foam cushioning, and bubble wrap'
                            }
                        },
                        assembly: {
                            title: 'Disassembly & Reassembly',
                            price: 'Price on quotation',
                            description: 'Service available to transport large or complex items.',
                            details: {
                                provider: 'Service provider will assess items to provide an accurate quotation',
                                disassemble: 'To disassemble the items with care and proper labelling if necessary',
                                reassemble: 'To reassemble the items according to its’ original state which typically includes reattaching legs, securing hardware, and making sure all parts fit correctly.'
                            }
                        },
                        packing: {
                            title: 'Packing & Unpacking',
                            price: 'Price on quotation',
                            description: 'Service available to ease your stress and mess of packing.',
                            details: {
                                provider: 'Service provider will assess items to provide an accurate quotation',
                                boxes: 'Include boxes and specialized containers for fragile or valuable items.',
                                movers: 'Movers will pack your items at the pick up location and unpack the boxes, remove items and placing them on surfaces or in appropriate cabinets and drawers at the drop off location.'
                            }
                        }
                    }
                },
                twentyFourLorry: {
                    title: '24ft Lorry',
                    description: 'A 24ft lorry can accommodate large furniture items and boxes, making it suitable for',
                    boldDescription: ' large apartments, landed houses or commercial moving.',
                    addOnTitle: 'Add On Specialised Services',
                    details: {
                        baseFare: {
                            title: 'Base fare',
                            description: 'S$530 + $ 1/km'
                        },
                        sizeLimit: {
                            title: 'Size Limit (L x W x H)',
                            description: '750 x 230 x 230 cm'
                        },
                        weightLimit: {
                            title: 'Weight Limit',
                            description: 'Up to 5,000 kg'
                        },
                        timeLimit: {
                            title: 'Time Limit',
                            description: '4 hours'
                        },
                        overtimeCharges: {
                            title: 'Overtime Charges',
                            description: '+ $ 5/helper for every 10min\n(capped at $250)'
                        },
                        feePerFloor: {
                            title: 'Staircase Fee',
                            description: '+ S$ 40/floor'
                        },
                        movers: {
                            title: 'Movers',
                            description: '4'
                        },
                        additionalMovers: {
                            title: 'Additional Movers',
                            description: '+ S$ 40/mover'
                        },
                        additionalStop: {
                            title: 'Additional Stop',
                            description: '+ S$ 20/stop'
                        }
                    },
                    addOns: {
                        tailgating: {
                            title: 'Tailgating Services',
                            price: '+ S$ 20',
                            description: 'Service available when bulky or commercial items are involved in the move.',
                            details: {
                                suitable: 'Suitable to load bulky items that might pose challenges for manual handling',
                                example: 'Example of bulky items include grand piano, fitness equipment, safe box and etc.'
                            }
                        },
                        wrapping: {
                            title: 'Wrapping Services',
                            price: '+ S$ 20/furniture',
                            description: 'Service available to protect your items and prevent damage during transportation.',
                            details: {
                                provider: 'Service provider will assess and determine the appropriate wrapping materials and techniques required',
                                materials: 'To utilise materials such as cling wrap, moving blankets, padding, foam cushioning, and bubble wrap'
                            }
                        },
                        assembly: {
                            title: 'Disassembly & Reassembly',
                            price: 'Price on quotation',
                            description: 'Service available to transport large or complex items.',
                            details: {
                                provider: 'Service provider will assess items to provide an accurate quotation',
                                disassemble: 'To disassemble the items with care and proper labelling if necessary',
                                reassemble: 'To reassemble the items according to its’ original state which typically includes reattaching legs, securing hardware, and making sure all parts fit correctly.'
                            }
                        },
                        packing: {
                            title: 'Packing & Unpacking',
                            price: 'Price on quotation',
                            description: 'Service available to ease your stress and mess of packing.',
                            details: {
                                provider: 'Service provider will assess items to provide an accurate quotation',
                                boxes: 'Include boxes and specialized containers for fragile or valuable items.',
                                movers: 'Movers will pack your items at the pick up location and unpack the boxes, remove items and placing them on surfaces or in appropriate cabinets and drawers at the drop off location.'
                            }
                        }
                    }
                }
            }
        },
        moversAndPackersAddOnPricing: {
            modalTitle: 'Add On Service Price list',
            options: {
                movers: {
                    title: 'Movers',
                    columns: {
                        number: 'No. of Movers',
                        description: 'Description',
                        price: 'Price'
                    },
                    rows: {
                        lightRoomMoving: {
                            description: 'Suitable for room moving with light weight & small items',
                            price: 'S$ 30'
                        },
                        heavyRoomMoving: {
                            description: 'Suitable for room moving with heavy weight & big items',
                            price: 'S$ 70'
                        },
                        lightApartmentRoomMoving: {
                            description: 'Suitable for apartment moving with furnitures',
                            price: 'S$ 110'
                        },
                        heavyApartmentRoomMoving: {
                            description: 'Suitable for apartment or landing house moving with furnitures & heavy electronic items',
                            price: 'S$ 150'
                        },
                        landingHouseMoving: {
                            description: 'Suitable for landing house moving with furnitures & heavy electronic items',
                            price: 'S$ 190'
                        }
                    }
                },
                tailgatingServices: {
                    title: 'Tailgating Services'
                },
                wrappingService: {
                    title: 'Wrapping Service'
                }
            }
        },
        pestControlPackageHelp: {
            monthly: {
                description: 'Our monthly pest control package is designed to keep your home free from pests year-round.',
                message: 'Our trained professionals will visit your property once a month to inspect, treat and monitor for pests. This comprehensive approach ensures that your property remains pest-free and any potential issues are caught and addressed early.'
            },
            bimonthly: {
                description: 'Our bimonthly pest control package is designed to keep your home free from pests.',
                message: 'Our trained professionals will visit your property every two months to inspect, treat and monitor for pests. This comprehensive approach ensures that your property remains pest-free and any potential issues are caught and addressed early.'
            },
            quarterly: {
                description: 'Our quarterly pest control package is designed to keep your home free from pests.',
                message: 'Our trained professionals will visit your property every three months to inspect, treat and monitor for pests. This comprehensive approach ensures that your property remains pest-free and any potential issues are caught and addressed early.'
            },
            includedTitle: 'What’s included?'
        },
        frequency: {
            weekly: {
                title: 'Weekly',
                description: 'Save SGD 10.00 on every service'
            },
            fortnightly: {
                title: 'Bi-weekly',
                description: 'Save SGD 5.00 on every service'
            },
            oneTime: {
                title: 'One-time',
                description: 'One off service when you need it'
            },
            options: {
                basicCleaning: 'Basic cleaning',
                dedicatedCustomer: 'Dedicated customer service',
                saveTime: 'Save time to book',
                sameCleaner: 'Same cleaner',
                cancelAnyTime: 'Cancel at anytime',
                startingFrom: 'Starting from'
            }
        },
        cancellationModal: {
            title: 'Cancellation Charges',
            description: 'We appreciate that there could be times when you need to cancel a scheduled Household Service or Recurring Order.',
            oneTimeMessage: 'In the event that a Service Partner is unable to fulfil your order for scheduled Household Services due to your unavailability at the time and place specified in your order, you shall be deemed to have cancelled such order within 24 hours’ notice prior the booking, and there will be a cancellation charge of S$ 25.00.',
            recurringMessage: 'In the event that a Service Partner is unable to fulfil an individual session of your Recurring Order due to your unavailability at the time and place specified in your Recurring Order, such individual session shall be deemed to have been fulfilled under the Recurring Order.',
            household: {
                title: 'Order for Household Services',
                description: 'You may cancel an order for scheduled Household Services through the Platform.'
            },
            recurring: {
                title: 'Recurring Order',
                description: 'You may cancel a Recurring Order through the Platform prior to collection of payment for each renewed Cycle.'
            },
            within: {
                title: 'Cancellation within 24h prior to the booking:',
                bullets: {
                    charges: 'Cancellation charges of S$ 25.00.'
                }
            },
            more: {
                title: 'Cancellation more than 24h prior to the booking:',
                bullets: {
                    charges: 'Cancellation charges of S$ 5.00.'
                }
            },
            notApply: {
                title: 'Cancellation charges do not apply to:',
                bullets: {
                    noPartner: 'Any order for which no Service Partner has been engaged.',
                    byPartner: 'Order cancellation by a Service Partner.'
                }
            },
            notApplyRecurring: {
                title: 'Cancellation charges do not apply to:',
                bullets: {
                    charges: 'Recurring Orders, provided that cancellation is made prior to payment being collected for a renewed Cycle.'
                }
            }
        },
        coupon: {
            save: 'Save',
            withCode: 'with code',
            nextStep: 'during next step'
        },
        ironingServiceModal: {
            modalTitle: 'Ironing service',
            description: 'For your reference, if you would like to select Ironing as a part of your service, ironing 5 pieces of garments take about 0.5 hours whilst 10 pieces of garments or more takes 1 hour or more.\n\nPlease add extra hours to your total booking hours if you have more than 10 pieces of garments to be ironed. You can also prioritize your other cleaning tasks with ironing required.'
        },
        jobStatus: {
            submitted: 'Not assigned yet',
            accepted: 'Accepted',
            completed: 'Completed',
            cancelled: 'Cancelled',
            bookAgain: 'Book again',
            paused: 'Paused',
            proposalReceived: 'Proposal Received',
            readyForDelivery: 'Ready for delivery'
        },
        viewOptions: {
            calendar: 'Calendar view',
            listView: 'List view'
        },
        paymentMethod: {
            creditCard: {
                label: 'Credit card',
                addNew: 'Add new card'
            },
            sendPay: {
                label: 'Send pay',
                topCredits: 'Top up SendPay credits',
                balance: 'Current balance'
            },
            grabPay: {
                label: 'Pay with GrabPay'
            }
        },
        sendPayModal: {
            title: 'Top up your SendPay credits',
            purchaseCredits: 'Purchase a credit pack',
            message: 'Save more with our super value credit packs!',
            youGet: 'Get',
            youPay: 'Pay',
            select: 'Select',
            selected: 'Selected',
            mostPopular: 'Most Popular',
            bestValue: 'Best Value',
            topUpAmount: 'Top up amount',
            expire: '*Credits will expire after 12 months from date of purchase',
            formFields: {
                amount: {
                    label: 'Enter your top up amount',
                    placeHolder: 'S$',
                    validation: {
                        isRequired: 'This field is required.',
                        isInvalid: 'Amount is invalid.',
                        isTooSmall: 'Minimum amount is S$ 20.00.'
                    }
                }
            },
            success: {
                message: 'SendPay credits successfully added to your account.'
            },
            error: {
                title: 'Your top-up was unsuccessful',
                message: 'An error has occurred which led to payment being unsuccessful. Please try again'
            }
        },
        discountCodes: {
            title: 'Discount codes & offers',
            tipMessage: 'Tip: Don\'t forget to check out your available',
            notAvailable: 'No coupons are available for this service',
            checkLater: 'Please check at a later time',
            infoMessage: '*Discount code will be applied on billing approval.'
        },
        rated: {
            message: 'Rated Excellent - 4.5/5 on Google Business'
        },
        petsOptions: {
            dog: 'Yes, I have a dog',
            cat: 'Yes, I have a cat',
            other: 'Yes, I have another type of pet',
            no: 'No, I don’t have a pet at home',
            yes: 'Yes, I do have a pet at home'
        },
        rateJob: {
            rateExpert: 'Rate your Service Partner',
            rated: 'Rated'
        },
        addressChangeConfirmation: {
            title: 'You are about to change your booking address',
            message: 'You will need to confirm again the information about your property.',
            successMessage: 'Successfully updated your service address! Please check your booking details to continue.'
        },
        notificationDrawer: {
            title: 'Notifications',
            tabs: {
                all: 'All',
                unread: 'Unread'
            }
        },
        proposals: {
            title: 'Proposals',
            empty: 'No proposals received so far.',
            expert: 'Service Partner',
            submittedAt: 'Submitted at',
            proposedTime: 'Proposed time',
            chatNow: 'Chat now',
            withExpert: 'with the Expert',
            tip: 'Tip',
            decline: {
                title: 'Are you sure to decline this proposal?',
                message: 'Your Booking will still be open for other Service Partners to accept or make proposals.'
            },
            accept: {
                title: 'Confirm proposal acceptance',
                message: 'This service provider will be assigned with the proposed booking details and your\n preferred payment method will be charged.'
            },
            estimatedPrice: 'Estimate cost'
        },
        rating: {
            title: 'Are you happy with the service provided by our Service Partner?',
            titleLowRating: 'Sorry to hear this!',
            label: 'Tap to rate our service',
            speakToUsMessage: 'Would you like to speak to our customer service about your experience?',
            speakToUs: 'or Speak to us',
            setAsFavouriteMessage: 'Your Favorite Service Partner will receive the job in priority.',
            skip: 'Skip for now'
        },
        mainDropdownMenu: {
            leftColumn: {
                sectionName: 'Our Services',
                message: 'Choose From Our Award-winning Services.'
            },
            mostPopular: {
                sectionName: 'Most popular',
                cleaning: 'Cleaning',
                airCon: 'Air Condition',
                handyman: 'Handyman',
                laundry: 'Laundry'
            },
            otherServices: {
                sectionName: 'Other services',
                deepCleaning: 'Deep Cleaning',
                pestControl: 'Pest Control',
                cooking: 'Cooking',
                tasksErrands: 'Tasks & Errands',
                moversAndPackers: 'Movers & Packers'
            }
        },
        photoUpload: {
            addImage: 'Add image',
            takePhoto: 'Take photo',
            dragAndDrop: 'Drag & drop',
            browseFile: 'or browse to choose a file',
            uploaded: 'Uploaded:',
            label: 'Give more information to our Service Partner',
            maxFileSize: 'File size to big'
        },
        extendSession: {
            title: 'Additional time request',
            message: 'Additional time will be charged extra and added to your session.',
            currentHours: 'Current hours per session',
            updatedHours: 'Updated hours per session',
            addTime: 'Add time to your session',
            extendSuccessMessage: 'Booking hours updated',
            notifyMessage: '*Notify your service partner before adding additional time to your current session to check availability.'
        },
        resumeJob: {
            pauseBooking: 'Pause booking',
            pausedFrom: 'Paused from',
            to: 'Resume on',
            pauseFrom: 'Pause from',
            endOn: 'End on',
            resume: 'Resume package',
            resumePlan: 'Resume plan',
            pauseDuration: 'Pause duration',
            pause: 'Pause package',
            pausePlan: 'Pause plan',
            resumeSuccessMessage: 'Job successfully resumed',
            jobPausedMessage: 'Job paused successfully'
        },
        billing: {
            title: 'Billing received',
            approveBilling: 'Approve billing',
            paidBilling: 'Paid Billing',
            unPaidBilling: 'Unpaid Billing',
            tasksAndErrands: 'Tasks & Errands'
        },
        cancelJobModal: {
            title: 'Cancel the job?',
            message: 'Are you sure you want to cancel? There will be a $20 cancellation charge.',
            contact: 'Contact us',
            cancelJob: 'Cancel job',
            declineJob: 'Decline'
        },
        policies: {
            readMore: 'Read more about our',
            confirm: 'By confirming your order, you agree to our ',
            our: '& our',
            cancellation: 'Cancellation Policy',
            terms: 'Terms of Service',
            privacy: 'Privacy Policy',
            helpCentre: 'Help Centre'
        },
        appliances: {
            yesOvenNoDishwasher: 'I have an oven but no dishwasher',
            ovenAndDishwasher: 'I have an oven & a dishwasher',
            noOvenYesDishwasher: 'I don’t have an oven but I have a dishwasher',
            noOvenNoDishwasher: 'I don’t have an oven nor a dishwasher'
        },
        commonDishes: {
            title: 'Common dishes our Service Partners can prepare:',
            dishes: {
                northIndianCuisine: 'Butter Chicken, Palak Paneer, Chana Masala, Aloo Gobi, Rogan Josh, Naan, Biryani, Dal Makhani, Aloo Paratha, Chicken Tikka Masala',
                chineseCuisine: 'Kung Pao Chicken, Hot and Sour Soup, Dumplings, Fried Rice, Ma Po Tofu, Peking Duck, Chow Mein, Egg Drop Soup, Spring Rolls, Sweet and Sour Pork',
                italianCuisine: 'Spaghetti Bolognese, Margherita Pizza, Lasagna, Risotto alla Milanese, Fettuccine Alfredo, Ravioli, Osso Buco, Caprese Salad, Tiramisu, Gelato',
                malayCuisine: 'Nasi Lemak, Satay, Rendang, Mee Goreng, Laksa, Roti Canai, Nasi Goreng, Ayam Percik, Asam Pedas, Kuih',
                southIndianCuisine: 'Idli, Dosa, Sambar, Rasam, Coconut chutney, Tomato chutney, Pongal, Upma, Vada, Lemon rice',
                westernCuisine: 'Hamburger, Pizza, Spaghetti with Meatballs, Grilled Cheese Sandwich, Caesar Salad, Macaroni and Cheese, Roast Chicken, Fish and Chips, Steak with Fries, Barbecue Ribs'
            }
        },
        laundryPrices: {
            title: 'Price list',
            minimumCharge: '*Please note that a minimum charge of S$40 is required to book our laundry service.',
            searchPlaceholder: 'Search for an item...',
            tabs: {
                prices: 'Prices',
                serviceDetails: 'Service details'
            },
            washAndIron: {
                name: 'Wash & iron',
                description: 'For everyday laundry that requires ironing after washing',
                overview: 'Your items are machine washed at 30°C and ironed.',
                suitableFor: 'Everyday items like shirts, t-shirts, trousers, bedsheets or bathroom towels.'
            },
            dryCleanAndIron: {
                name: 'Dry clean & iron',
                description: 'For delicate items and fabrics',
                overview: 'Your items are dry cleaned and ironed.',
                suitableFor: 'All items that can be dry-cleaned and ironed. Some of the popular items that are typically included are suits, shirts, dresses, coats or bedsheets.'
            },
            ironOnly: {
                name: 'Iron only',
                description: 'For items that are already clean',
                overview: 'Your items are only ironed.',
                suitableFor: 'Already washed items, that can be ironed. Some of the popular items that are typically included are shirts or t-shirts.'
            },
            loadWash: {
                name: 'Load wash',
                description: 'For everyday laundry, bedsheets and towels',
                overview: 'Your items are machine washed at 30°C and tumble dried. No ironing is included.',
                suitableFor: 'Everyday laundry that can be machine washed and tumble dried. Some popular items typically included are t-shirts, trousers, bedsheets or undergarments.'
            },
            curtainsWash: {
                name: 'Curtains wash',
                description: 'For your curtains at home',
                overview: 'Your items are machine washed at 45°C and tumble dried.',
                suitableFor: 'Your day & night curtains'
            },
            overviewTitleSuffix: 'overview',
            suitableForTitle: 'Suitable for',
            preparationTitle: 'How to prepare your items?',
            preparationPrefix: 'Prepare all you items for',
            preparationSuffix: 'into a single bag. Our service partners will list all your items and send you a quotation before proceeding with the service.\nIf you are booking multiple services, please prepare the items into separated bags & tag them with the service type.',
            weightInfo: {
                title: 'Not sure how much you have?',
                message: 'One load of 8kgs is about:',
                examples: {
                    shirts: '10 t-shirts',
                    trousers: '4 trousers',
                    underwear: '12 underwear',
                    socks: '12 pair of socks'
                }
            }
        },
        noBilling: {
            title: 'Billing received',
            message: 'No billing received so far'
        },
        laundryMilestones: {
            accepted: 'Accepted',
            collected: 'Collected',
            ready: 'Ready',
            delivered: 'Delivered'
        },
        handymanServices: {
            general: {
                title: 'General',
                items: {
                    door: 'Door',
                    curtain: 'Curtain/Blinds',
                    tiles: 'Tiles',
                    furniture: 'Furniture',
                    tvBracket: 'TV',
                    others: 'Others'
                }
            },
            electrical: {
                title: 'Electrical',
                items: {
                    powerPoint: 'Power point/Switch',
                    light: 'Light',
                    ceilingFan: 'Fan',
                    writingExtension: 'Wiring',
                    others: 'Others'
                }
            },
            painting: {
                title: 'Painting',
                items: {
                    wholeHouse: 'Whole house',
                    wholeShop: 'Whole shop',
                    room: 'Room',
                    ceiling: 'Ceiling',
                    roof: 'Roof',
                    wall: 'Wall',
                    others: 'Others'
                }
            },
            plumbing: {
                title: 'Plumbing',
                items: {
                    waterTap: 'Water tap/hose',
                    toiletCover: 'Toilet Cover',
                    toiletBowl: 'Toilet Bowl',
                    sink: 'Sink',
                    bidet: 'Bidet',
                    waterHeater: 'Water heater',
                    shower: 'Shower head/mixer',
                    others: 'Others'
                }
            }
        },
        handymanServiceSelect: {
            serviceItems: 'Service item(s)',
            frequentlyServiceItems: 'Frequently service items',
            selectItems: 'Select item(s)',
            commonlyServiceItems: 'Commonly service items',
            addAnotherItem: 'Add another item',
            selectParts: 'Select the part(s) that needed service',
            partsNote: 'What would you like us to take note?',
            describeParts: 'Describe the parts that needed service',
            search: 'Search item',
            addDetails: 'Add More Details',
            lessDetails: 'Less Details'
        },
        handymanServiceType: {
            repair: 'Repair',
            replace: 'Replace',
            installation: 'Install',
            troubleshoot: 'Troubleshoot',
            assembling: 'Assembling',
            disassembling: 'Disassembling',
            disposal: 'Disposal',
            extension: 'Extension',
            rewiring: 'Rewiring',
            touchUp: 'Touch up',
            fullPainting: 'Full painting',
            unclog: 'Unclog',
            others: 'Others'
        },
        handymanParts: {
            doorLock: 'Door Lock',
            doorKnob: 'Door Knob',
            doorPanel: 'Door Panel',
            doorHinge: 'Door Hinge',
            wall: 'Wall',
            ceiling: 'Ceiling',
            curtain: 'Curtain',
            curtainRailing: 'Curtain railing',
            blinds: 'Blinds',
            tv: 'TV',
            tvBracket: 'TV Bracket',
            cabinet: 'Cabinet',
            diningTable: 'Dining Table',
            sofa: 'Sofa',
            studyTable: 'Study Table',
            shelves: 'Shelves',
            interior: 'Interior',
            exterior: 'Exterior',
            sink: 'Sink',
            sinkPipe: 'Sink Pipe',
            sinkTrap: 'Sink Trap',
            others: 'Others'
        },
        currentJobSession: {
            title: 'Current Session',
            sessionIndexPrefix: 'Session',
            sessionIndexInfix: 'out of',
            expert: 'Service Partner',
            hours: 'h',
            addHours: 'Add hours to the session',
            addHoursInfo: '*Notify your service partner before adding additional hours to your current session to check availability.',
            cancel: 'Cancel this session',
            noticeOfReschedulingMessagePart1: 'You need a',
            noticeOfReschedulingMessagePart2: 'minimum of 2 hours notice',
            noticeOfReschedulingMessagePart3: 'to reschedule current session'
        },
        nativeAddressSelectModal: {
            title: 'Select your service address',
            savedAddresses: 'Saved addresses',
            addNewAddress: 'Add new address',
            addedTitle: 'New booking address added',
            message: 'This address will be used to book your service'
        },
        nativeCouponModal: {
            title: 'Enter Discount Code'
        },
        promoBar: {
            message: 'Get $50* on your upcoming bookings with code GET50.'
        },
        pushNotificationModal: {
            title: 'Push notifications',
            message: 'Enable notifications to stay updated on\nyour job bookings and offers',
            cta: 'Allow',
            dismiss: 'Not now'
        },
        bookingAuth: {
            title: 'Enter your details to confirm booking',
            description: 'If you already have an account, please log in to your registered account',
            breadcrumbText: 'Your details',
            promoMessage: 'Please proceed to booking confirmation page to apply your discount code',
            enterAddressTitle: 'Enter your service address',
            accountCreatedMessage: 'Successfully created a new account.',
            addAddressSuccessMessage: 'Successfully added service address',
            photoUploadSuccessMessage: 'Your photos were uploaded',
            photoUploadError: 'Couldn\'t upload your photos',
            guestBookingRequiredText: '* Your details are required for booking confirmation'
        },
        selectedFrequency: {
            title: 'You have selected cleaning service',
            weekly: 'Every',
            fortnightly: 'Every second'
        },
        otaUpdateListener: {
            availableTitle: 'New update is available',
            readyToInstallTitle: 'New update is ready',
            message: 'Please wait and don\'t close the app',
            cta: 'Reload the app',
            updateErrorMessage: 'Something went wrong while applying the update. Please restart the app manually.'
        },
        forceUpdateListener: {
            heading: 'Take your\n experience to\n the next level!',
            subHeading: 'What’s new?',
            message: 'We’ve made some major improvements to elevate your booking experience! Please upgrade the app to continue booking our services!',
            cta: 'Upgrade now',
            features: {
                newFeatures: 'New features',
                performance: 'Better Performance',
                security: 'Improved Security'
            }
        },
        animatedConnectivityAlert: {
            internetStatusMessage: {
                online: 'Connected to the server',
                offline: 'Unable to connect to server... Pull down to refresh'
            }
        },
        moversAndPackersSuccessBookingPromo: {
            promoCode: 'MPDC25',
            title: 'Get $25 off on Deep Cleaning service with the promo code “MPDC25”',
            message: 'Moving can be overwhelming. Let us make your transition a breeze with our Deep Cleaning service.',
            button: 'Book A Deep Cleaning Service',
            label: '$25 OFF PROMO'
        },
        timeZone: {
            detectionMessage: 'We\'ve detected that you\'re booking from a different timezone.',
            noteMessage: ' Please note that booking time shown are in Singapore time zone (GMT+8).'
        },
        promotionalModal: {
            title: 'Book services now with S$50* discount.',
            description: 'Use the discount code below to check out your upcoming bookings.',
            bookNow: 'Book now',
            termsAndCondition: '*T&C apply',
            discountCode: 'WELCOME50',
            yourDiscountCode: 'Your discount code'
        },
        marketingConsentModal: {
            title: 'get S$50 off your upcoming bookings',
            description: 'Give your home the care it deserves! Receive access to exclusive home services deals, discounts and promotions.',
            numberOfPeople: '5000+',
            haveGottenTheOffer: 'people have gotten the offer!',
            getPromotion: 'Get S$50 off',
            termsAndCondition: '*T&C apply'
        },
        marketingConsentPopup: {
            title: 'Get Early Access To The Best Deals and Discounts',
            description: 'I don’t want to miss a deal! Send me the latest discounts and special promotions.'
        }
    },
    alerts: {
        mediaPermissions: {
            title: 'Permissions needed',
            message: 'We need your permissions to access media library'
        },
        cameraPermissions: {
            title: 'Permissions needed',
            message: 'We need your permissions to access camera'
        }
    },
    welcomeHeader: {
        newUser: {
            welcome: 'Hi',
            message: 'Welcome to Sendhelper.'
        },
        userWithoutBookings: {
            welcome: 'Welcome back',
            message: 'Ready to give us new tasks?'
        },
        userWithUpComingBookings: {
            welcome: 'Welcome back',
            message: 'You have an upcoming booking.'
        }
    },
    subscriptionsRecommendation: {
        title: 'You may be interested',
        learnMore: 'Learn more'
    },
    servicesPlan: {
        session: 'session',
        sessions: 'sessions',
        frequencyTitle: 'How often do you need your cleaner?',
        weekly: 'Weekly',
        biWeekly: 'Bi-weekly',
        withSubscriptionPlan: 'with a subscription plan!',
        switchPlan: 'Switch Plan',
        autoRenewal: 'Auto-renewal',
        withTheSubscriptionPlan: 'with the subscription plan'
    },
    accessibility: {
        cleaningOneOffServicePlan: 'cleaning-plan-one-off-select-rdo',
        cleaningSubscriptionServicePlan: 'cleaning-plan-subscription-select-rdo',
        cleaningDateTimeSelection: 'cleaning-date-time-btn',
        cleaningPropertyTypeSelection: 'cleaning-property-type-ddl ',
        cleaningBookingHoursSelection: 'cleaning-booking-[workingHours]-hours-btn',
        cleaningSubscriptionFrequencyWeekly: 'cleaning-subscription-weekly-btn',
        cleaningSubscriptionFrequencyBiWeekly: 'cleaning-subscription-bi-weekly-btn',
        cleaningAddonsIroningService: 'cleaning-addons-ironing-chb',
        cleaningAddonsIroningServiceDetailsButton: 'cleaning-addons-ironing-details-btn',
        cleaningPetsSelection: 'cleaning-pets-ddl',
        cleaningAdditionalComments: 'cleaning-additional-comments-txa',
        cleaningConfirmButton: 'cleaning-confirm-btn',
        summaryColumnEditAddress: 'summary-column-edit-address-btn',
        summaryColumnPromoCodeInput: 'summary-column-promo-code-txt',
        summaryColumnPromoCodeApplyButton: 'summary-column-promo-code-apply-btn',
        summaryColumnPromoCodeRemoveButton: 'summary-column-promo-code-remove-btn',
        summaryColumnTCButton: 'summary-column-tc-btn',
        summaryColumnPaymentTileButton: 'summary-column-payment-tile-btn',
        summaryColumnCancellationChargesModalButton: 'summary-column-cancellation-charges-modal-btn',
        summaryColumnSwitchPlanButton: 'summary-column-switch-plan-btn',
        policyLinksCancelationLabel: 'policy-links-cancelation-lbl',
        policyLinksTermsPolicyLabel: 'policy-links-terms-policy-lbl',
        policyLinksPrivacyPolicyLabel: 'policy-links-privacy-policy-lbl',
        mobileSummaryButton: 'mobile-summary-btn',
        mobileSummaryButtonContinue: 'mobile-summary-btn-continue',
        couponButton: 'coupon-btn',
        rateButton: 'rate-job-btn-[index]',
        signUp: {
            hasAccountLogin: 'has-account-login',
            googleSignInButton: 'google-sign-in-button'
        },
        toast: {
            container: 'toast-container'
        },
        bookingsScreen: {
            tabs: {
                upcoming: 'bookings-tabs-upcoming-btn',
                completed: 'bookings-tabs-completed-btn',
                cancelled: 'bookings-tabs-cancelled-btn'
            },
            filters: {
                search: 'bookings-filters-search-txt',
                date: 'bookings-filters-date-ddl',
                service: 'bookings-filters-service-ddl',
                view: 'bookings-filters-view-ddl'
            },
            views: {
                listView: {
                    item: 'bookings-list-view-item-btn'
                },
                calendarView: {
                    dayOfMonth: 'bookings-calendar-view-day-of-month-btn',
                    modal: 'bookings-calendar-view-modal-dlg',
                    item: 'bookings-calendar-view-item-btn'
                }
            },
            subscriptionRecommendations: {
                normal: 'bookings-subscription-recommendations-btn',
                special: 'bookings-subscription-recommendations-special-btn'
            }
        },
        bookingSummaryScreen: {
            discountCodeInput: 'booking-summary-discount-code-txt',
            applyDiscountCodeButton: 'booking-summary-apply-discount-code-btn'
        },
        // pagination component
        pagination: {
            mostLeftTile: 'pagination-most-left-tile-btn',
            leftTile: 'pagination-left-tile-btn',
            rightTile: 'pagination-right-tile-btn',
            mostRightTile: 'pagination-most-right-tile-btn',
            pageNoTile: 'pagination-page-no-tile-btn'
        },
        // booking details
        bookingDetailsScreen: {
            reschedule: {
                modal: 'booking-details-reschedule-modal-dlg',
                date: 'booking-details-reschedule-date-ddl',
                time: 'booking-details-reschedule-time-ddl',
                submit: 'booking-details-reschedule-submit-btn',
                back: 'booking-details-reschedule-back-btn'
            }
        },
        cancelJobScreen: {
            cancelOptions: 'cancel-job-cancel-options-ddl',
            reason: 'cancel-job-reason-[reason]-chk',
            otherReason: 'cancel-job-reason-txa',
            keepPlan: 'cancel-job-keep-plan-btn',
            cancel: 'cancel-job-cancel-btn',
            fee: 'cancel-job-cancel-fee-lbl',
            cancelAfterSession: 'cancel-job-cancel-after-session-lbl',
            paymentSummary: {
                modal: 'cancel-job-payment-summary-modal-dlg',
                close: 'cancel-job-payment-summary-close-btn',
                session: 'cancel-job-payment-summary-session-lbl',
                fee: 'cancel-job-payment-summary-fee-lbl'
            },
            cancelRequestSend: {
                modal: 'cancel-job-cancel-request-send-modal-dlg',
                close: 'cancel-job-cancel-request-send-close-btn',
                title: 'cancel-job-cancel-request-send-title-lbl',
                message: 'cancel-job-cancel-request-send-message-lbl',
                backToHome: 'cancel-job-cancel-request-send-back-to-home-btn',
                bookNew: 'cancel-job-cancel-request-send-book-new-btn'
            },
            subscriptionCancelSuccess: {
                modal: 'cancel-job-subscription-cancel-success-modal-dlg',
                close: 'cancel-job-subscription-cancel-success-close-btn',
                title: 'cancel-job-subscription-cancel-success-title-lbl',
                message: 'cancel-job-subscription-cancel-success-message-lbl',
                backToHome: 'cancel-job-subscription-cancel-success-back-to-home-btn',
                bookNew: 'cancel-job-subscription-cancel-success-book-new-btn',
                jobId: 'cancel-job-subscription-cancel-success-job-id-lbl'
            }
        },
        jobDetailsScreen: {
            components: {
                viewDetails: 'job-details-common-view-details-btn',
                lessDetails: 'job-details-common-less-details-btn',
                pauseJob: 'job-details-common-pause-job-btn',
                resumeJob: 'job-details-common-resume-job-btn',
                addHours: 'job-details-common-add-hours-btn',
                minusHours: 'job-details-common-minus-hours-btn',
                pausedJobMessage: 'job-details-common-paused-job-message-lbl',
                backButton: 'job-details-common-back-btn'
            },
            pausedMessage: {
                pauseTime: 'job-details-paused-message-time-lbl',
                pauseStartTime: 'job-details-paused-message-start-time-lbl',
                pauseEndTime: 'job-details-paused-message-end-time-lbl',
                resume: 'job-details-paused-message-resume-btn'
            },
            rescheduledMessage: {
                notice: 'job-details-rescheduled-message-notice-lbl',
                viewDetails: 'job-details-rescheduled-message-view-details-btn'
            },
            rescheduleRequestDetail: {
                modal: 'job-details-reschedule-request-detail-dlg',
                title: 'job-details-reschedule-request-detail-title-lbl',
                close: 'job-details-reschedule-request-detail-close-btn',
                currentOneOffDate: 'job-details-reschedule-request-detail-current-one-off-date-lbl',
                currentOneOffTime: 'job-details-reschedule-request-detail-current-one-off-time-lbl',
                currentSubscriptionDate: 'job-details-reschedule-request-detail-current-subscription-date-lbl',
                currentSubscriptionTime: 'job-details-reschedule-request-detail-current-subscription-time-lbl',
                requestedOneOffDate: 'job-details-reschedule-request-detail-request-one-off-date-lbl',
                requestedOneOffTime: 'job-details-reschedule-request-detail-request-one-off-time-lbl',
                requestedSubscriptionDate: 'job-details-reschedule-request-detail-request-subscription-date-lbl',
                requestedSubscriptionTime: 'job-details-reschedule-request-detail-request-subscription-time-lbl'
            },
            jobReceipt: {
                cleaningPrice: 'job-details-job-receipt-cleaning-price-lbl',
                totalPrice: 'job-details-job-receipt-total-price-lbl',
                originalPrice: 'job-details-job-receipt-original-price-lbl'
            },
            jobReceiptWithAdditionFee: {
                receipt: 'job-details-job-receipt-additional-fee-[index]-btn'
            },
            cleaningServiceDate: {
                price: 'job-details-cleaning-service-date-price-lbl'
            },
            cancelRequest: {
                cancel: 'job-details-cancel-request-cancel-btn'
            },
            subscriptionRescheduleRequest: {
                modal: 'job-details-subscription-reschedule-request-dlg',
                totalSessions: 'job-details-subscription-reschedule-request-total-sessions-lbl',
                keepPlan: 'job-details-subscription-reschedule-request-keep-plan-btn',
                reschedule: 'job-details-subscription-reschedule-request-reschedule-btn',
                cancel: 'job-details-subscription-reschedule-request-cancel-btn'
            },
            suggestPauseSubscription: {
                modal: 'job-details-suggest-pause-subscription-dlg',
                reschedule: 'job-details-suggest-pause-subscription-reschedule-btn',
                pause: 'job-details-suggest-pause-subscription-pause-btn',
                cancel: 'job-details-suggest-pause-subscription-cancel-btn'
            },
            pauseSubscription: {
                modal: 'job-details-pause-subscription-dlg',
                close: 'job-details-pause-subscription-close-btn',
                reschedule: 'job-details-pause-subscription-reschedule-btn',
                continue: 'job-details-pause-subscription-continue-btn',
                next: 'job-details-pause-subscription-next-btn',
                previous: 'job-details-pause-subscription-previous-btn',
                day: 'job-details-pause-subscription-day-btn'
            }
        },
        calendar: {
            header: {
                next: 'calendar-header-next-btn',
                prev: 'calendar-header-prev-btn'
            },
            month: {
                date: 'calendar-month-date-[date]-btn'
            }
        },
        serviceTiles: {
            cleaning: 'service-tiles-cleaning-btn',
            airCondition: 'service-tiles-air-condition-btn',
            handyman: 'service-tiles-handyman-btn',
            laundry: 'service-tiles-laundry-btn',
            cooking: 'service-tiles-cooking-btn',
            pestControl: 'service-tiles-pest-control-btn',
            taskAndErrands: 'service-tiles-task-and-errands-btn',
            moversAndPackers: 'service-tiles-movers-and-packers-btn',
            deepCleaning: 'service-tiles-deep-cleaning-btn'
        },
        nativeSelectAddress: {
            confirmBtn: 'native-select-address-confirm-btn'
        },
        bookingSuccess: {
            copyBtn: 'booking-success-copy-btn',
            myBookingBtn: 'booking-success-my-booking-btn'
        },
        bookingConfirmationScreen: {
            recurringBookingScheduleLabel: 'booking-confirmation-recurring-booking-schedule-lbl',
            recurringAutoRenewLabel: 'booking-confirmation-recurring-auto-renew-lbl'
        }
    }
}
