import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const People: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path d="M13.42 14.17a17.084 17.084 0 1 1 24.16 24.16 17.084 17.084 0 0 1-24.16-24.16Zm12.08 1.663a10.417 10.417 0 1 0 0 20.834 10.417 10.417 0 0 0 0-20.834Z" />
        <Path d="M25.5 48.333A19.167 19.167 0 0 0 6.333 67.5a3.333 3.333 0 0 1-6.666 0 25.833 25.833 0 0 1 51.666 0 3.333 3.333 0 0 1-6.666 0A19.167 19.167 0 0 0 25.5 48.333ZM49.278 23.438a14.584 14.584 0 1 1 20.624 20.625 14.584 14.584 0 0 1-20.624-20.625Zm10.312 2.395a7.917 7.917 0 1 0 0 15.834 7.917 7.917 0 0 0 0-15.834Z" />
        <Path d="M54.41 53.337a15.087 15.087 0 0 1 20.257 14.165 3.333 3.333 0 1 0 6.666-.004A21.755 21.755 0 0 0 52.057 47.1a3.502 3.502 0 0 0-.093.036c-1.454.586-3.631 1.646-5.51 2.988-.936.668-1.923 1.495-2.706 2.467-.758.94-1.581 2.337-1.581 4.077a3.333 3.333 0 0 0 6.656.262c.025-.038.063-.09.116-.157.26-.323.72-.746 1.39-1.224 1.315-.94 2.965-1.757 4.08-2.21Zm-5.633 3.671.005-.01a.074.074 0 0 1-.005.01Z" />
    </Icon>
)
