import React from 'react'
import { FlatList, View } from 'react-native'
import { Field } from '@codegateinc/react-form-builder-v2'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { SelectInputOption } from 'lib/types'
import { DateScrollSelectTile } from './DateScrollSelectTile'

interface DateScrollSelectProps extends Field<string>  {
    options: Array<SelectInputOption>,
    disabled?: boolean
}

export const DateScrollSelect: React.FunctionComponent<DateScrollSelectProps> = ({
    options,
    disabled,
    onChangeValue,
    value
}) => {
    const { styles } = useStyles(stylesheet)

    return (
        <View style={styles.wrapper}>
            <FlatList
                data={options}
                showsVerticalScrollIndicator={false}
                renderItem={({ item }) => (
                    <DateScrollSelectTile
                        value={item}
                        disabled={disabled}
                        onChangeValue={onChangeValue}
                        selectedValue={value}
                    />
                )}
            />
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        height: 200,
        alignItems: 'center',
        marginTop: theme.utils.gap(2)
    }
}))
