import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const TryAgain: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path d="M67.677 44.753a3.337 3.337 0 0 0-4.186 2.167c-3.124 9.82-12.317 16.413-22.877 16.413-13.207 0-23.947-10.466-23.947-23.333 0-12.863 10.74-23.333 23.947-23.333 5.76 0 11.193 2.006 15.483 5.566l-7.22-1.19c-1.833-.333-3.533.93-3.833 2.744a3.33 3.33 0 0 0 2.747 3.83l14.153 2.34c.183.03.37.043.547.043.396 0 .783-.07 1.143-.203.123-.047.223-.134.343-.197.22-.113.45-.213.64-.373.12-.097.197-.237.3-.35.157-.164.324-.32.44-.52.084-.144.117-.314.18-.474.08-.196.187-.38.227-.596l2.513-13.334a3.332 3.332 0 0 0-2.66-3.893c-1.803-.327-3.553.85-3.893 2.657l-.907 4.803A30.768 30.768 0 0 0 40.614 10c-16.88 0-30.613 13.457-30.613 30s13.733 30 30.613 30c13.473 0 25.22-8.463 29.23-21.06a3.337 3.337 0 0 0-2.167-4.187Z" />
    </Icon>
)
