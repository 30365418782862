import { useEffect } from 'react'
import { useToastAtom } from 'lib/atoms'
import { useTranslations } from 'lib/hooks'
import { NotificationType, SelectInputOption, ValueOf } from 'lib/types'
import { NewAddressFields, NewAddressShape } from '../forms'

type EditAddressInitialDataParams = {
    postcode?: string,
    resetForm: VoidFunction,
    setFieldInitialValue(field: NewAddressFields, value: ValueOf<NewAddressShape>): void,
    setFieldValue(field: NewAddressFields, value: ValueOf<NewAddressShape>): void,
    fetchPostalCodes(value: string): Promise<Array<SelectInputOption>>
}

export const useEditAddressInitialData = ({
    postcode,
    setFieldValue,
    fetchPostalCodes
} : EditAddressInitialDataParams) => {
    const T = useTranslations()
    const [, setToastMessage] = useToastAtom()

    useEffect(() => {
        const fetchAndSetPostCode = async () => {
            try {
                const [fetchedPostcode] = await fetchPostalCodes(postcode || '')

                setFieldValue(
                    NewAddressFields.Postcode,
                    postcode ? fetchedPostcode : ''
                )
            } catch (error) {
                setToastMessage({
                    message: T.common.error,
                    type: NotificationType.Error
                })
            }
        }

        fetchAndSetPostCode()
    }, [postcode])
}
