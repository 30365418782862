import React, { Fragment } from 'react'
import { View } from 'react-native'
import { QuestionMark } from 'assets/icons'
import { createStyles } from 'lib/styles'
import { useFeatureFlagAtom } from 'lib/atoms'
import { CustomFonts, PriceSummaryItemNames } from 'lib/types'
import { Typography } from 'lib/components'
import { isNative } from 'lib/common'
import { jobHelpers } from 'lib/utils'
import { useFormatPrice, usePrice, useStyles, useTranslations } from 'lib/hooks'
import { ConsumerJobCostBreakdown } from 'lib/models/Job'
import { ReceiptSummary } from 'features/jobs/types'
import { SummaryModalContent } from './SummaryModalContent'
import {
    Discount,
    JobQuotationTotal,
    JobQuotationTotalSubscription,
    PriceSummary
} from '../types'
import { ModalWithIcon } from '../../../lib/components/ModalWithIcon'

type PriceSummaryComponentProps = {
    priceSummary?: PriceSummary,
    promotion?: Discount,
    total?: JobQuotationTotal,
    totalSubscription?: JobQuotationTotalSubscription,
    showPayBySessionForJobDetail?: boolean
    consumerJobCostBreakdown?: ConsumerJobCostBreakdown,
    receiptSummary?: Array<ReceiptSummary>
}

export const PriceSummaryComponent: React.FunctionComponent<PriceSummaryComponentProps> =
    ({
        priceSummary,
        promotion,
        total,
        showPayBySessionForJobDetail,
        consumerJobCostBreakdown,
        receiptSummary
    }) => {
        const { styles } = useStyles(stylesheet)
        const T = useTranslations()
        const formatPrice = useFormatPrice()
        const [featureFlagAtom] = useFeatureFlagAtom()
        const usePaymentBySessionFlow = featureFlagAtom?.paymentBySessionFlow || false
        const formattedDiscountAmount = showPayBySessionForJobDetail ?
            usePrice(consumerJobCostBreakdown?.discount || 0) :
            usePrice(promotion?.value || 0)
        const subscriptionOriginalValue = (usePaymentBySessionFlow ? consumerJobCostBreakdown?.totalPerSessionBeforeDiscount : total?.valueWithoutSavings) || 0
        const formattedSubscriptionOriginalValue = subscriptionOriginalValue ? formatPrice(subscriptionOriginalValue) : ''
        const containerStyle = isNative ? {} : { width: 'fit-content' }
        const platformFeeData = jobHelpers.getReceiptSummaryItemByName(receiptSummary as Array<ReceiptSummary>, PriceSummaryItemNames.PlatformFee)

        const PlatformFeeForPayBySession = () => (
            <View style={styles.summaryRow}>
                <View style={styles.questionContainer}>
                    <View style={[styles.labelWrapper, containerStyle]}>
                        <Typography.Label>
                            {platformFeeData?.name}
                        </Typography.Label>
                    </View>
                    {platformFeeData?.description && (
                        <ModalWithIcon
                            icon={<QuestionMark size={16} />}
                        >
                            <SummaryModalContent title={platformFeeData.name} description={platformFeeData.description} />
                        </ModalWithIcon>
                    )}
                </View>

                <Typography.Label
                    bold
                    numberOfLines={1}
                    style={{
                        ...styles.value
                    }}
                >
                    {formatPrice(platformFeeData?.value || 0) || '-'}
                </Typography.Label>
            </View>
        )

        const PriceSummary = () => priceSummary ? (
            <View style={styles.priceSummaryContainer}>
                {priceSummary && priceSummary.description && (
                    <Typography.Label bold style={styles.pricingSummaryLabel}>
                        {priceSummary.description}
                    </Typography.Label>
                )}
                { priceSummary && priceSummary.items.map(({ name, value, description }) => {
                    const isPlatformFee = name === PriceSummaryItemNames.PlatformFee
                    const isCleaningPrice = name === PriceSummaryItemNames.CleaningPrice
                    const itemValue = (showPayBySessionForJobDetail && isPlatformFee ? consumerJobCostBreakdown?.platformFee : value) || 0
                    const shouldShowOriginalValue =
                        isCleaningPrice &&
                        subscriptionOriginalValue > 0 &&
                        formattedSubscriptionOriginalValue !== itemValue &&
                        usePaymentBySessionFlow

                    if (isPlatformFee && showPayBySessionForJobDetail && platformFeeData) {
                        return (
                            <Fragment key={name}>
                                <PlatformFeeForPayBySession />
                            </Fragment>
                        )
                    }

                    return (
                        <View
                            key={name}
                            style={styles.summaryRow}
                        >

                            <View style={styles.questionContainer}>
                                <View style={[styles.labelWrapper, containerStyle]}>
                                    <Typography.Label>
                                        {name}
                                    </Typography.Label>
                                </View>
                                {description && (
                                    <ModalWithIcon
                                        icon={<QuestionMark size={16} />}
                                    >
                                        <SummaryModalContent title={name} description={description} />
                                    </ModalWithIcon>
                                )}
                            </View>

                            {
                                shouldShowOriginalValue &&
                                (
                                    <Typography.Label
                                        bold
                                        numberOfLines={1}
                                        style={styles.originalValue}
                                    >
                                        {formattedSubscriptionOriginalValue}
                                    </Typography.Label>
                                )
                            }

                            <Typography.Label
                                bold
                                numberOfLines={1}
                                style={{
                                    ...styles.value
                                }}
                            >
                                {formatPrice(itemValue) || '-'}
                            </Typography.Label>
                        </View>
                    )
                })}
            </View>
        ) : null

        const Discount = () => formattedDiscountAmount && promotion && (
            <View
                style={styles.summaryRow}
            >
                <View style={{
                    ...styles.labelWrapper,
                    ...styles.widthNone
                }}>
                    <Typography.Label>
                        {T.components.bookingSummary.discountCode} :
                        <Typography.Label bold style={styles.discountName}> {promotion?.code}</Typography.Label>
                    </Typography.Label>
                </View>
                <Typography.Label
                    bold
                    style={{
                        ...styles.value,
                        ...styles.discountValue
                    }}
                >
                    {showPayBySessionForJobDetail && formattedDiscountAmount ? '-': ''}{formattedDiscountAmount || '-'}
                </Typography.Label>
            </View>
        )

        return (
            <View style={showPayBySessionForJobDetail ? styles.spaceBetweenWithReverse : styles.spaceBetween}>
                <PriceSummary />
                <Discount/>
            </View>
        )
    }

const stylesheet = createStyles(theme => ({
    priceSummaryContainer: {
        justifyContent: 'space-between',
        width: '100%'
    },
    summaryRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: theme.utils.gap(2)
    },
    labelWrapper: {
        marginRight: theme.utils.gap(0.5)
    },
    discountName: {
        textTransform: 'uppercase'
    },
    discountValue: {
        color: theme.colors.flame
    },
    value: {
        textAlign: 'right',
        overflow: 'visible'
    },
    originalValue: {
        textDecorationLine: 'line-through',
        color: theme.colors.fog,
        overflow: 'visible',
        fontFamily: CustomFonts.Poppins500,
        marginRight: theme.utils.gap(0.5),
        fontSize: 12
    },
    questionContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '60%',
        marginRight: theme.utils.gap(2)
    },
    pricingSummaryLabel: {
        marginBottom: theme.utils.gap(2)
    },
    spaceBetween: {
        justifyContent: 'space-between',
        width: '100%'
    },
    spaceBetweenWithReverse: {
        justifyContent: 'space-between',
        width: '100%',
        flexDirection: 'column-reverse'
    },
    widthNone: {
        width: undefined
    }
}))
