type PromotionValidity = {
    length: number,
    never_expires: boolean,
    date_start: string,
    date_expiry: string
}

type PromotionUsageValidity = {
    date_start: string,
    date_end: string
}

type PromotionPackage = {
    _id: string,
    package_name: string,
    price: number,
    credit: number,
    active: boolean,
    created_at: string
}

export enum PromotionType {
    Fixed = 'fixed',
    Percentage = 'percent'
}

export type CreateJobPromotion = {
    validated: boolean,
    promo_code?: string,
    promo_auth?: string
}

// TODO: Remove this type once type CreateJobPromotion is removed
export type CreateJobPromotionTemp = {
    code?: string,
    auth?: string
}

export type Promotion = {
    _id: string,
    promo_code: string,
    promo_auth: string,
    promo_type: PromotionType,
    active: boolean,
    percent: number,
    total_times_used: number,
    validity: PromotionValidity,
    max_per_user: number,
    createdAt: string,
    updatedAt: string,
    warning_message: string,
    package: PromotionPackage,
    usageValidity: PromotionUsageValidity,
    limited_to_supply: Array<string>,
    limited_to_users: Array<string>,
    message_to_client: string
}
