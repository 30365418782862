import React from 'react'
import { View } from 'react-native'
import { Icons } from 'assets'
import { ENV } from 'lib/config'
import { createStyles } from 'lib/styles'
import { linkingHelpers } from 'lib/utils'
import { useStyles, useTranslations } from 'lib/hooks'
import { Touchable, Typography } from 'lib/components'

export const Rated: React.FunctionComponent = () => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)

    return (
        <View style={styles.container}>
            <View style={styles.row}>
                <Typography.Error forceColor={theme.colors.darkGrey}>
                    {T.components.rated.message}
                </Typography.Error>
                {[...new Array(5)].map((item, index) => (
                    <View
                        key={index}
                        style={styles.star}
                    >
                        <Icons.StarFilled
                            size={10}
                            forceColor={theme.colors.sun}
                        />
                    </View>
                ))}
            </View>
            <View style={styles.row}>
                <Typography.Error forceColor={theme.colors.darkGrey}>
                    {`${T.common.question} `}
                </Typography.Error>
                <Touchable onPress={() => linkingHelpers.openUrl(`${ENV.SENDHELPER_MARKETING_SITE_URL}/contacts`)}>
                    <Typography.Error
                        forceColor={theme.colors.orange}
                    >
                        {T.headerLinks.contactUs}
                    </Typography.Error>
                </Touchable>
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        flexDirection: {
            lg: 'row',
            xs: 'column'
        },
        alignItems: 'center',
        borderBottomWidth: 1,
        justifyContent: 'space-between',
        borderColor: theme.colors.silver,
        marginRight: {
            lg: theme.utils.gap(6),
            xs: theme.utils.gap(2)
        },
        marginLeft: theme.utils.gap(2),
        marginBottom: theme.utils.gap(2)
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingBottom: {
            lg: theme.utils.gap(2),
            xs: theme.utils.gap(1)
        }
    },
    star: {
        marginLeft: theme.utils.gap(1) / 2
    }
}))
