import React from 'react'
import { View } from 'react-native'
import { FormComponents, Grid, Touchable, Typography } from 'lib/components'
import { useFormatPrice, useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { isNative } from 'lib/common'
import { FormattedPestControlPackageItems } from 'features/bookings/types'

type PestControlServiceTileProps = {
    packageName: string,
    packageItem: FormattedPestControlPackageItems,
    onChangeValue(updatedService: FormattedPestControlPackageItems): void
    onDetailsPress(packageName:string, updatedService: FormattedPestControlPackageItems): void
}

export const PestControlPackageTile: React.FunctionComponent<PestControlServiceTileProps> = ({
    packageName,
    packageItem,
    onChangeValue,
    onDetailsPress
}) => {
    const { styles, theme } = useStyles(stylesheet)
    const T = useTranslations()
    const formatPrice = useFormatPrice()

    return (
        <View
            key={packageItem.title}
            style={{
                borderRadius: 8,
                borderWidth: 1,
                backgroundColor: packageItem.isSelected ? theme.colors.yellow : theme.colors.white,
                borderColor: packageItem.isSelected ? theme.colors.orange : theme.colors.darkSilver,
                margin: theme.utils.gap(isNative ? 3 : 1),
                paddingHorizontal: theme.utils.gap(2),
                paddingVertical: theme.utils.gap(2),
                ...theme.utils.createShadow(2, 0, 8, 4, 4, theme.colors.webShadow(0.04))
            }}
        >
            <FormComponents.Checkbox
                isSelected={Boolean(packageItem.isSelected)}
                onChange={() => {
                    onChangeValue({
                        ...packageItem,
                        isSelected: !packageItem.isSelected
                    })
                }}
                style={styles.checkbox}
                renderComponent={() => (
                    <View style={styles.textRow}>
                        <View>
                            <Typography.Regular>
                                {packageItem.title}
                            </Typography.Regular>
                            <Grid.Gap gapBottom={1}/>
                            <Typography.Label>
                                {formatPrice(packageItem.price)}
                            </Typography.Label>
                            <Typography.Label>
                                {T.screens.pestControl.packages.priceSuffix}
                            </Typography.Label>
                        </View>
                        <View style={styles.rightColumn}>
                            <Typography.Label>
                                {`${packageItem.sessions} ${T.screens.pestControl.packages.sessions}`}
                            </Typography.Label>
                            <Grid.Gap gapBottom={1}/>
                            <Touchable
                                onPress={() => onDetailsPress(packageName, packageItem)}
                                style={styles.priceDetails}
                            >
                                <Typography.Label forceColor={theme.colors.orange}>
                                    {T.screens.pestControl.packages.viewDetails}
                                </Typography.Label>
                            </Touchable>
                        </View>
                    </View>
                )}
            />
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    textRow: {
        flex: 1,
        flexDirection: 'row'
    },
    rightColumn: {
        flex: 1,
        alignItems: 'flex-end',
        marginLeft: theme.utils.gap(3)
    },
    priceDetails: {
        borderBottomWidth: 1,
        borderColor: theme.colors.orange
    },
    checkbox: {
        marginBottom: theme.utils.gap(0)
    }
}))
