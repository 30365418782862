import React from 'react'
import { Breakpoint } from 'lib/styles'
import { LoadingIndicator, MediaQuery } from 'lib/components'
import { useIsWithinBreakpoints, useTranslations } from 'lib/hooks'
import { NavigationParams, NavigationProps, ScreenNames } from 'lib/routing'
import { getJobDetails } from '../actions'
import { AirConditionDetailsContent, BookingDetailsLayout, ServiceDetailsAccepted, MobilePayment } from '../components'

type AirConditionDetailsScreenProps = {
    navigation: NavigationProps<ScreenNames.AirConditionDetails>,
    route: NavigationParams<ScreenNames.AirConditionDetails>
}

export const AirConditionDetailsScreen: React.FunctionComponent<AirConditionDetailsScreenProps> = ({
    route
}) => {
    const T = useTranslations()
    const { isLoading, data: response } = getJobDetails(route.params.jobId)
    const isMobile = useIsWithinBreakpoints(Breakpoint.XS, Breakpoint.MD)

    if (isLoading || !response) {
        return (
            <BookingDetailsLayout
                isLoading={isLoading}
                title={T.screens.jobDetails.title}
            >
                <LoadingIndicator isLoading />
            </BookingDetailsLayout>
        )
    }

    return (
        <BookingDetailsLayout
            bottomSpacerHeight={isMobile ? 0 : 50}
            title={T.screens.jobDetails.title}
            footer={(
                <MediaQuery.Hidden from={Breakpoint.LG}>
                    <MobilePayment
                        job={response.data}
                        content={closeModal => (
                            <ServiceDetailsAccepted
                                job={response.data}
                                closeModal={closeModal}
                                jobStatus={route.params.jobStatus}
                                isRecurringAccepted={false}
                            />
                        )}
                    />
                </MediaQuery.Hidden>
            )}
        >
            <AirConditionDetailsContent
                data={response.data}
                jobStatus={route.params.jobStatus}
            />
        </BookingDetailsLayout>
    )
}
