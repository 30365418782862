import React from 'react'
import { View } from 'react-native'
import { useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { Grid, Touchable, Typography } from 'lib/components'
import { Icons } from 'assets'

type ReconnectIndicatorProps = {
    canReconnect: boolean,
    onReconnect: VoidFunction
}

export const ReconnectIndicator: React.FunctionComponent<ReconnectIndicatorProps> = ({
    canReconnect,
    onReconnect
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)

    return canReconnect ? (
        <View style={styles.reconnectContainer}>
            <Typography.Regular
                bold
                forceColor={theme.colors.flame}
            >
                {`${T.screens.chat.unableToConnect} `}
            </Typography.Regular>
            <Touchable onPress={onReconnect}>
                <Typography.Regular
                    bold
                    style={styles.reconnect}
                    forceColor={theme.colors.flame}
                >
                    {T.screens.chat.reconnect}
                </Typography.Regular>
            </Touchable>
            <Grid.Gap gapLeft={1}>
                <Icons.TryAgain
                    size={14}
                    forceColor={theme.colors.flame}
                />
            </Grid.Gap>
        </View>
    ) : null
}

const stylesheet = createStyles(theme => ({
    reconnectContainer: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        height: 32,
        flexDirection: 'row',
        backgroundColor: theme.colors.linen,
        justifyContent: 'center',
        alignItems: 'center'
    },
    reconnect: {
        textDecorationLine: 'underline'
    }
}))
