import { useTranslations } from 'lib/hooks'
import { CommercialPropertyType, HomePropertyType, MoversAndPackersServiceType } from '../../types'

export const useMoversAndPackersOptions = () => {
    const T = useTranslations()

    const serviceTypeOptions = [
        {
            label: T.screens.moversAndPackers.serviceType.homeMoving,
            value: MoversAndPackersServiceType.HomeMoving
        },
        {
            label: T.screens.moversAndPackers.serviceType.commercialMoving,
            value: MoversAndPackersServiceType.CommercialMoving
        }
    ]

    const homePropertyTypeOptions = [
        {
            label: T.screens.moversAndPackers.propertyType.condominium,
            value: HomePropertyType.Condominium
        },
        {
            label: T.screens.moversAndPackers.propertyType.HDB,
            value: HomePropertyType.HDB
        },
        {
            label: T.screens.moversAndPackers.propertyType.landed,
            value: HomePropertyType.Landed
        },
        {
            label: T.screens.moversAndPackers.propertyType.others,
            value: HomePropertyType.Other
        }
    ]

    const commercialPropertyTypeOptions = [
        {
            label: T.screens.moversAndPackers.propertyType.shophouse,
            value: CommercialPropertyType.Shophouse
        },
        {
            label: T.screens.moversAndPackers.propertyType.office,
            value: CommercialPropertyType.Office
        },
        {
            label: T.screens.moversAndPackers.propertyType.others,
            value: CommercialPropertyType.Other
        }
    ]

    const propertySizeOptions = [ // todo to update when designs ready
        {
            label: T.screens.moversAndPackers.propertySize.small,
            value: T.screens.moversAndPackers.propertySize.small
        },
        {
            label: T.screens.moversAndPackers.propertySize.medium,
            value: T.screens.moversAndPackers.propertySize.medium
        },
        {
            label: T.screens.moversAndPackers.propertySize.large,
            value: T.screens.moversAndPackers.propertySize.large
        }
    ]

    const numberOfBedroomsOptions = new Array(12)
        .fill(0)
        .map((item, index) => ({
            label: `${index + 1}`,
            value: `${index + 1}`
        }))
    const floorOptions = new Array(31)
        .fill(0)
        .map((item, index) => ({
            label: `${index + 1}`,
            value: `${index + 1}`
        }))
    const propertiesWithFloors = [
        HomePropertyType.Condominium,
        HomePropertyType.HDB,
        HomePropertyType.Landed,
        CommercialPropertyType.Office,
        CommercialPropertyType.Shophouse
    ]

    const propertiesWithElevator = [
        HomePropertyType.Condominium,
        HomePropertyType.HDB,
        HomePropertyType.Landed,
        CommercialPropertyType.Office,
        CommercialPropertyType.Shophouse
    ]

    return {
        serviceTypeOptions,
        homePropertyTypeOptions,
        commercialPropertyTypeOptions,
        numberOfBedroomsOptions,
        propertySizeOptions,
        propertiesWithElevator,
        floorOptions,
        propertiesWithFloors
    }
}
