import React from 'react'
import { View } from 'react-native'
import { openURL } from 'expo-linking'
import { createStyles } from 'lib/styles'
import { Typography } from 'lib/components'
import { useStyles, useTranslations } from 'lib/hooks'

export const HandymanHelpModalContent: React.FunctionComponent = () => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)

    return (
        <View style={styles.container}>
            <View style={styles.title}>
                <Typography.Regular>
                    {T.components.handymanHelp.title}
                </Typography.Regular>
            </View>
            <Typography.Regular forceColor={theme.colors.darkGrey}>
                {T.components.handymanHelp.description}
            </Typography.Regular>
            <View style={styles.row}>
                <Typography.Regular bold>
                    {T.components.handymanHelp.includedTitle}
                </Typography.Regular>
                <Typography.Regular forceColor={theme.colors.darkGrey}>
                    {`${T.components.handymanHelp.includedMessagePrefix} `}
                    <Typography.Regular
                        forceColor={theme.colors.orange}
                        style={styles.email}
                        onPress={() => openURL(`mailto:${T.components.handymanHelp.helpEmail}`)}
                    >
                        {T.components.handymanHelp.helpEmail}
                    </Typography.Regular>
                    {` ${T.components.handymanHelp.includedMessageSuffix}`}
                </Typography.Regular>
            </View>
            <View style={styles.row}>
                <Typography.Regular bold>
                    {T.components.handymanHelp.howDoesItWorkTitle}
                </Typography.Regular>
                <Typography.Regular forceColor={theme.colors.darkGrey}>
                    {T.components.handymanHelp.howDoesItWorkMessage}
                </Typography.Regular>
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        maxWidth: 800
    },
    title: {
        borderBottomWidth: 1,
        borderColor: theme.colors.silver,
        paddingBottom: theme.utils.gap(2),
        marginBottom: theme.utils.gap(2)
    },
    row: {
        marginTop: theme.utils.gap(2)
    },
    email: {
        borderBottomWidth: 1,
        borderColor: theme.colors.orange
    }
}))
