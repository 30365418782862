import React, { useState } from 'react'
import { View, ViewStyle } from 'react-native'
import { useUserAtom } from 'lib/atoms'
import { Segment } from 'lib/analytics'
import { PaymentType } from 'lib/models'
import { createStyles } from 'lib/styles'
import { SendPayModal } from 'features/bookings'
import { usePrice, useStyles, useTranslations } from 'lib/hooks'
import { Label, Regular, SmallSubheading } from '../typography'
import { Touchable } from '../Touchable'
import { FormComponents } from '../form'

type SendPayProps = {
    notEnoughCredits: boolean,
    selectedPaymentMethod: PaymentType,
    onSelect: (value: PaymentType) => void,
    customStyles?: ViewStyle
}

export const SendPay: React.FunctionComponent<SendPayProps> = ({
    notEnoughCredits,
    selectedPaymentMethod,
    onSelect,
    customStyles = {}
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const [user] = useUserAtom()
    const [isModalOpen, setIsModalOpen] = useState(false)
    const userBalance = usePrice(user.consumer ? user.consumer.balance_credit : '')

    return (
        <React.Fragment>
            <SendPayModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
            />
            <Touchable
                style={{
                    ...styles.container,
                    ...customStyles
                }}
                onPress={() => onSelect(PaymentType.Credits)}
            >
                <View style={styles.header}>
                    <View style={styles.row}>
                        <View style={styles.radio}>
                            {selectedPaymentMethod === PaymentType.Credits && (
                                <View style={styles.dot} />
                            )}
                        </View>
                        <View>
                            <SmallSubheading>
                                {T.components.paymentMethod.sendPay.label}
                            </SmallSubheading>
                            <View style={styles.balance}>
                                <Label forceColor={theme.colors.darkGrey}>
                                    {`${T.components.paymentMethod.sendPay.balance}: ${userBalance}`}
                                </Label>
                            </View>
                            {notEnoughCredits && (
                                <FormComponents.ErrorMessage text={T.screens.sendPay.notEnoughCredits} />
                            )}
                        </View>
                    </View>
                    <Touchable
                        style={styles.add}
                        onPress={() => {
                            Segment.bookingPurchaseSendPayCreditsClicked({})

                            setIsModalOpen(true)
                        }}
                    >
                        <Regular
                            bold
                            numberOfLines={1}
                            forceColor={theme.colors.orange}
                        >
                            {T.components.paymentMethod.sendPay.topCredits}
                        </Regular>
                    </Touchable>
                </View>
            </Touchable>
        </React.Fragment>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        borderBottomWidth: 1,
        paddingVertical: theme.utils.gap(2),
        borderBottomColor: theme.colors.silver
    },
    row: {
        flex: 1,
        flexDirection: 'row'
    },
    header: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'space-between'
    },
    balance: {
        marginVertical: theme.utils.gap(1)
    },
    radio: {
        width: 24,
        height: 24,
        borderRadius: 12,
        alignItems: 'center',
        justifyContent: 'center',
        borderWidth: 2,
        marginRight: theme.utils.gap(2),
        borderColor: theme.colors.night
    },
    dot: {
        width: 12,
        height: 12,
        borderRadius: 6,
        backgroundColor: theme.colors.sun
    },
    add: {
        borderBottomWidth: 1,
        borderBottomColor: theme.colors.orange
    }
}))
