import React from 'react'
import { View } from 'react-native'
import { createStyles } from 'lib/styles'
import { Typography } from 'lib/components'
import { useStyles } from 'lib/hooks'

type WelcomeMessageProps = {
    title: string,
    message: string
}

export const WelcomeMessage: React.FunctionComponent<WelcomeMessageProps> = ({
    title,
    message
}) => {
    const { styles, theme } = useStyles(stylesheet)

    return (
        <View style={styles.container}>
            <Typography.Heading style={styles.messageTitle}>
                {title}
            </Typography.Heading>
            <Typography.Title
                style={styles.messageText}
                forceColor={theme.colors.darkGrey}
            >
                {message}
            </Typography.Title>
        </View>
    )
}

const stylesheet = createStyles(() => ({
    container: {
        maxWidth: {
            lg: 380,
            xs: '100%'
        },
        alignItems: {
            lg: 'flex-start',
            xs: 'center'
        }
    },
    messageTitle: {
        paddingVertical: {
            lg: 8,
            xs: 10
        }
    },
    messageText: {
        textAlign: {
            lg: undefined,
            xs: 'center'
        }
    }
}))
