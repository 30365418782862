import React, { useEffect } from 'react'
import { View, Image } from 'react-native'
import { isAndroid, isNative } from 'lib/common'
import { Breakpoint, createStyles } from 'lib/styles'
import { linkingHelpers, R } from 'lib/utils'
import { Touchable, Typography } from 'lib/components'
import { useIsWithinBreakpoints, useStyles, useTranslations } from 'lib/hooks'
import { Languages } from 'lib/locale'
import { APP_CONFIG } from 'lib/config'
import { Images } from 'assets'
import { CustomFonts } from 'lib/types'
import { initFacebookSDK, initGoogleSDK } from '../social'

type SocialAuthAction = {
    key: string,
    enabled: boolean,
    image: Images,
    text: string,
    onPress: VoidFunction,
    accessibilityLabel?: string
}

type SocialButtonsProps = {
    disabled: boolean,
    onContinueWithFacebook: VoidFunction,
    onContinueWithApple: VoidFunction,
    onContinueWithGoogle: VoidFunction
}

export const SocialButtons: React.FunctionComponent<SocialButtonsProps> = ({
    disabled,
    onContinueWithFacebook,
    onContinueWithApple,
    onContinueWithGoogle
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const isMobile = useIsWithinBreakpoints(Breakpoint.XS, Breakpoint.MD)
    const socialAuth: Array<SocialAuthAction> = [
        {
            key: 'google',
            enabled: true,
            image: Images.Google,
            text: T.components.socialButtons.nativeGoogle,
            onPress: onContinueWithGoogle,
            accessibilityLabel: T.accessibility.signUp.googleSignInButton
        },
        {
            key: 'facebook',
            enabled: true,
            image: Images.Facebook,
            text: isNative
                ? T.components.socialButtons.nativeFacebook
                : T.components.socialButtons.facebook,
            onPress: onContinueWithFacebook
        },
        {
            key: 'apple',
            enabled: !isAndroid,
            image: Images.Apple,
            text: isNative
                ? T.components.socialButtons.nativeApple
                : T.components.socialButtons.apple,
            onPress: () => {
                if (isNative) {
                    return onContinueWithApple()
                }

                linkingHelpers.openSendhelperAppInStore()
            }
        }
    ].filter(item => item.enabled)

    useEffect(() => {
        initFacebookSDK(APP_CONFIG.KEYS.FACEBOOK_SCRIPT_ID, Languages.en_US)
            .catch(R.T)
        initGoogleSDK(APP_CONFIG.KEYS.GOOGLE_SCRIPT_ID, Languages.en_US)
            .catch(R.T)
        // todo for now not supported
        // initAppleSDK(APP_CONFIG.KEYS.APPLE_SCRIPT_ID, Languages.en_US)
        //     .catch(R.T)
    }, [])

    return (
        <View
            style={{
                flexDirection: 'column',
                justifyContent: 'space-between',
                marginBottom: theme.utils.gap(1)
            }}
        >
            {socialAuth.map((item, index, array) => (
                <React.Fragment key={index}>
                    <View style={[isNative || isMobile ? styles.mobileContainer : styles.container]}>
                        <>
                            <Touchable
                                disabled={disabled}
                                style={{
                                    ...styles.button,
                                    flex: isNative ? 1 : undefined,
                                    backgroundColor: disabled ? theme.colors.silver : theme.colors.white
                                }}
                                onPress={array[index].onPress}
                                testID={array[index].accessibilityLabel}
                            >
                                <View
                                    style={{
                                        ...styles.buttonContentWrapper
                                    }}
                                >
                                    <Image
                                        source={array[index].image}
                                        style={styles.image}
                                    />
                                    <Typography.ButtonText
                                        forceColor={theme.colors.night}
                                        style={styles.btnText}
                                    >
                                        {array[index].text}
                                    </Typography.ButtonText>
                                </View>
                            </Touchable>
                        </>
                    </View>
                </React.Fragment>
            ))}

        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        marginBottom: theme.utils.gap(1)
    },
    mobileContainer: {
        width: '100%',
        marginBottom: theme.utils.gap(1)
    },
    button: {
        borderWidth: 1,
        alignItems: 'center',
        justifyContent: 'center',
        borderColor: theme.colors.grey,
        paddingVertical: theme.utils.gap(1),
        paddingHorizontal: theme.utils.gap(0.5),
        borderRadius: 4,
        width: '100%'
    },
    buttonContentWrapper: {
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    image: {
        width: 20,
        height: 20,
        marginRight: theme.utils.gap(1)
    },
    appleNote: {
        marginTop: theme.utils.gap(1),
        textAlign: 'center'
    },
    separator: {
        width: theme.utils.gap(1)
    },
    btnText: {
        fontSize: 14,
        lineHeight: 20,
        color: '#1F1F1F',
        textAlign: 'center',
        fontFamily: CustomFonts.Roboto500
    }
}))
