import React from 'react'
import { View } from 'react-native'
import { Icons } from 'assets'
import { createStyles } from 'lib/styles'
import { ScrollDirection } from 'lib/types'
import { useStyles } from 'lib/hooks'
import { Touchable } from '../Touchable'

type CalendarHeaderButtonProps = {
    onPress: VoidFunction,
    direction: ScrollDirection,
    testID?: string
}

export const CalendarHeaderButton: React.FunctionComponent<CalendarHeaderButtonProps> = ({
    onPress,
    direction,
    testID
}) => {
    const { styles } = useStyles(stylesheet)

    return (
        <Touchable
            testID={testID}
            onPress={onPress}
            style={styles.buttonWrapper}
        >
            <View
                style={{
                    ...styles.button,
                    transform: [
                        {
                            rotateZ: direction === ScrollDirection.Prev
                                ? '90deg'
                                : '-90deg'
                        }
                    ]
                }}
            >
                <Icons.Chevron size={10} />
            </View>
        </Touchable>
    )
}

const stylesheet = createStyles(theme => ({
    buttonWrapper: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    button: {
        width: 32,
        height: 32,
        paddingTop: 12,
        alignItems: 'center',
        borderRadius: 4,
        borderWidth: 1,
        borderColor: theme.colors.silver
    }
}))
