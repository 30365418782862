import { useWindowDimensions } from 'react-native'
import {
    styleUtils,
    CreateStylesFactory,
    ScreenSize,
    theme as appTheme,
    Breakpoint,
    ExtractBreakpoints,
    RemoveKeysWithPrefix,
    CustomNamedStyles
} from '../styles'

export const useStyles = <T extends CustomNamedStyles<T, Breakpoint>>(styles?: T | CreateStylesFactory<T, typeof appTheme>): {
    styles: ExtractBreakpoints<RemoveKeysWithPrefix<T>>,
    theme: typeof appTheme
} => {
    const dimensions = useWindowDimensions()
    const breakpoint = styleUtils.getBreakpointFromScreenWidth(dimensions.width)
    const screenSize: ScreenSize = {
        width: dimensions.width,
        height: dimensions.height
    }

    if (!styles) {
        return {
            theme: appTheme,
            styles: {} as ExtractBreakpoints<RemoveKeysWithPrefix<T>>
        }
    }

    const parsedStyles = typeof styles === 'function'
        ? styles(appTheme)
        : styles

    const dynamicStyleSheet = Object
        .entries(parsedStyles)
        .reduce((acc, [key, value]) => {
            const x = value as CustomNamedStyles<T, Breakpoint>

            if (typeof value === 'function') {
                return {
                    ...acc,
                    [key]: value
                }
            }

            const mappedValue = Object.fromEntries(Object
                .entries(x)
                .map(([key, value]) => {
                    const isDynamicFunction = typeof value === 'function'
                    const isValidStyle = typeof value !== 'object' || key === 'transform'

                    if (isDynamicFunction || isValidStyle) {
                        return [key, value]
                    }

                    const valueWithBreakpoint = value as Record<Breakpoint, string | number>

                    return [key, styleUtils.getValueForBreakpoint(valueWithBreakpoint, breakpoint, screenSize)]
                })
            )

            return {
                ...acc,
                [key]: mappedValue
            }
        }, {} as T)

    return {
        theme: appTheme,
        styles: dynamicStyleSheet as ExtractBreakpoints<RemoveKeysWithPrefix<T>>
    }
}
