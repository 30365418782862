import { Typography } from 'lib/components'
import { useFormatPrice, useStyles } from 'lib/hooks'
import { Service } from 'lib/models'
import { createStyles } from 'lib/styles'
import { View } from 'react-native'

type CostBreakdownItemsProps = {
    items?: Array<Service>
}

export const CostBreakdownItems = ({ items }: CostBreakdownItemsProps) => {

    if (!items?.length) {
        return null
    }

    const formatPrice = useFormatPrice()
    const { styles } = useStyles(stylesheet)

    return items?.map((item, index) => (
        <View
            key={index}
            style={styles.row}
        >
            <Typography.Label numberOfLines={1}>
                {item.title}
            </Typography.Label>
            <View style={styles.priceColumn}>
                <Typography.Label bold>
                    {formatPrice(item.price * item.quantity)}
                </Typography.Label>
            </View>
        </View>
    ))
}

const stylesheet = createStyles(theme => ({
    row: {
        flexDirection: 'row',
        marginTop: theme.utils.gap(2),
        justifyContent: 'space-between'
    },
    priceColumn: {
        marginLeft: theme.utils.gap(1) / 2
    }
}))

