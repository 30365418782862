import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const Copy: React.FunctionComponent<IconProps> = props => (
    <Icon
        {...props}
        forceColor="transparent"
    >
        <Path
            d="M18.7 17.705V9.6a4.934 4.934 0 0 1 4.933-4.934h42.764a4.934 4.934 0 0 1 4.934 4.934v42.763a4.934 4.934 0 0 1-4.934 4.935h-8.195"
            stroke={props.forceColor}
            strokeWidth={8}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <Path
            d="M52.364 18.702H9.6a4.934 4.934 0 0 0-4.934 4.934V66.4A4.934 4.934 0 0 0 9.6 71.334h42.764a4.934 4.934 0 0 0 4.934-4.934V23.636a4.934 4.934 0 0 0-4.934-4.934Z"
            stroke={props.forceColor}
            strokeWidth={8}
            strokeLinejoin="round"
        />
    </Icon>
)
