import React, { useState, useEffect } from 'react'
import { View } from 'react-native'
import { UseFormReturn } from '@codegateinc/react-form-builder-v2'
import { JobCancelReason } from 'lib/models'
import { createStyles, theme } from 'lib/styles'
import { Icons } from 'assets'
import { useStyles, useTranslations } from 'lib/hooks'
import { Adapter, CustomModal, Button, Typography, Touchable } from 'lib/components'
import { cancelReasonHelpers, linkingHelpers } from 'lib/utils'
import { CancelationShape } from 'features/jobs'
import { ENV } from 'lib/config'
import { JobDetailsResponse } from 'features/jobs/types'
import { CustomFonts } from 'lib/types'
import { isNative } from 'lib/common'

type CancelBookingRequestModalProps = {
    isLoading: boolean,
    isOpen: boolean,
    onClose: VoidFunction,
    onSubmit: VoidFunction,
    onRescheduleBooking: VoidFunction,
    formReturn: UseFormReturn<CancelationShape>
    cancelationMessage?: string,
    cancelationOptions: Array<JobCancelReason>,
    job: JobDetailsResponse,
}

export const CancelBookingRequestModal: React.FunctionComponent<CancelBookingRequestModalProps> = ({
    isLoading,
    isOpen,
    job,
    onClose,
    onSubmit,
    onRescheduleBooking,
    formReturn,
    cancelationMessage,
    cancelationOptions
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const { form } = formReturn
    const [showCustomReasonInput, setShowCustomReasonInput] = useState(false)
    const [disableSubmitButton, setDisableSubmitButton] = useState(true)

    useEffect(() => {
        const { id, reason } = cancelReasonHelpers.getCancelReason(form.reason.value, form.customReason.value)
        const shouldEnableSubmitButton = Boolean(id) || Boolean(reason.trim())
        setDisableSubmitButton(!shouldEnableSubmitButton)
    }, [form.reason.value, form.customReason.value])

    useEffect(() => {
        const { id } = cancelReasonHelpers.getCancelReason(form.reason.value, form.customReason.value)
        const shouldShowCustomReasonInput = Boolean(form.reason.value) && !id
        setShowCustomReasonInput(shouldShowCustomReasonInput)

        if (!shouldShowCustomReasonInput) {
            form.customReason.onChangeValue('')
        }

    }, [form.reason.value])

    return (
        <CustomModal
            isLoading={isLoading}
            onClose={onClose}
            isOpen={isOpen}
        >
            <View style={styles.headerModalWrapper}>
                <Typography.SmallSubheading style={styles.titleContainer}>
                    {`${T.screens.jobDetails.cancelation.packageTitle} ${job.job.job_id}`}
                </Typography.SmallSubheading>
            </View>
            <View style={styles.modalWrapper}>
                <View style={styles.messageContainer}>
                    <Typography.Regular>
                        {T.screens.jobDetails.cancelation.message}
                    </Typography.Regular>
                </View>
                {cancelationMessage && (
                    <View style={styles.cancelationMessageWrapper}>
                        <Icons.Alert
                            size={24}
                            forceColor={theme.colors.strawberry}
                        />
                        <Typography.Regular style={styles.cancelationMessage}>
                            {cancelationMessage}
                        </Typography.Regular>
                    </View>
                )}
                <View style={styles.formWrapper}>
                    <View style={styles.selectWrapper}>
                        <Adapter.SelectInputColumn
                            {...form.reason}
                            smallLabel
                            disabled={isLoading}
                            options={cancelationOptions.map(item => ({
                                label: item.reason,
                                value: JSON.stringify(item)
                            }))}
                        />
                    </View>
                    {
                        showCustomReasonInput && (
                            <Adapter.TextInput
                                {...form.customReason}
                                disabled={isLoading}
                                inputProps={{ multiline: true }}
                            />
                        )
                    }
                </View>
                <View style={styles.actionContainer}>
                    <View style={{
                        ...styles.buttonWrapper,
                        ...styles.firstButtonWrapper
                    }}>
                        <Button
                            noBackground
                            disabled={isLoading}
                            text={T.screens.jobDetails.cancelation.rescheduleBooking}
                            onPress={onRescheduleBooking}
                            textWrapperStyles={styles.buttonTextWrapper}
                            textStyles={styles.buttonText}
                        />
                    </View>
                    <View style={{
                        ...styles.buttonWrapper,
                        ...styles.lastButtonWrapper
                    }}>
                        <Button
                            disabled={disableSubmitButton}
                            isLoading={isLoading}
                            text={T.common.confirm}
                            onPress={onSubmit}
                            textWrapperStyles={styles.buttonTextWrapper}
                            textStyles={styles.buttonText}
                        />
                    </View>
                </View>
            </View>
            <View style={styles.footer}>
                <View style={styles.footerWrapper}>
                    <Typography.Regular style={styles.question}>
                        {T.screens.jobDetails.rescheduleRequest.haveAnIssue}
                    </Typography.Regular>
                    <Touchable onPress={() => linkingHelpers.openUrl(`${ENV.SENDHELPER_MARKETING_SITE_URL}/contacts`)}>
                        <Typography.Regular bold forceColor={theme.colors.sun}>
                            {` ${T.screens.jobDetails.rescheduleRequest.contactUs}`}
                        </Typography.Regular>
                    </Touchable>
                </View>
            </View>
        </CustomModal>
    )
}

const stylesheet = createStyles(theme => ({
    headerModalWrapper: {
        paddingHorizontal: {
            lg: theme.utils.gap(10),
            md: theme.utils.gap(5),
            sm: theme.utils.gap(4),
            xs: theme.utils.gap(1)
        }
    },
    modalWrapper: {
        paddingHorizontal: {
            lg: theme.utils.gap(20),
            md: theme.utils.gap(10),
            sm: theme.utils.gap(8),
            xs: theme.utils.gap(2)
        },
        flex: 1
    },
    titleContainer: {
        textAlign: 'center',
        fontSize: 25,
        lineHeight: 31,
        fontFamily: CustomFonts.Poppins600
    },
    messageContainer: {
        marginTop: theme.utils.gap(1.5),
        alignItems: 'center'
    },
    cancelationMessageWrapper: {
        borderRadius: 8,
        backgroundColor: theme.colors.linen,
        padding: theme.utils.gap(isNative ? 2 : 1.5),
        marginTop: theme.utils.gap(1.5),
        marginBottom: theme.utils.gap(2),
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    actionContainer: {
        paddingTop: theme.utils.gap(2.5),
        paddingBottom: theme.utils.gap(2.2),
        flex: 1,
        flexDirection: {
            md: 'row',
            xs: 'column'
        },
        justifyContent: 'space-between',
        zIndex: -1,
        width: '100%'
    },
    buttonWrapper: {
        maxWidth: {
            md: 200,
            xs: undefined
        },
        marginHorizontal: {
            md: theme.utils.gap(1),
            xs: undefined
        },
        marginVertical: {
            md: undefined,
            xs: theme.utils.gap(0.5)
        },
        flex: 1
    },
    buttonTextWrapper: {
        width: '100%',
        wordWrap: 'break-word',
        textAlign: 'center',
        marginHorizontal: theme.utils.gap(2.25)
    },
    firstButtonWrapper: {
        marginLeft: 0
    },
    lastButtonWrapper: {
        marginRight: 0
    },
    formWrapper: {
        minWidth: {
            lg: 400,
            sm: undefined
        }
    },
    selectWrapper: {
        zIndex: theme.zIndex[10]
    },
    alignText: {
        textAlign: 'center'
    },
    cancelationMessage: {
        textAlign: 'center',
        marginLeft: theme.utils.gap(1)
    },
    question: {
        textAlign: 'center',
        color: theme.colors.softBlueGrey
    },
    footer: {
        alignItems: 'center',
        paddingHorizontal: {
            lg: theme.utils.gap(8),
            xs: 0
        },
        zIndex: -1
    },
    footerWrapper: {
        alignItems: 'center',
        flexDirection: 'row'
    },
    buttonText: {
        textAlign: 'center'
    }
}))
