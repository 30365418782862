import React from 'react'
import { View, ViewStyle } from 'react-native'
import { createStyles } from 'lib/styles'
import { HTMLContent, Typography } from 'lib/components'
import { useStyles } from 'lib/hooks'
import { Icons } from 'assets'
import { Nullable } from 'lib/types'

type ErrorMessageProps = {
    text?: string,
    contentStyles?: ViewStyle,
    forceColor?: string,
    messageSize?: Nullable<number>,
    renderHTMLContent?: boolean
}

export const ErrorMessage: React.FunctionComponent<ErrorMessageProps> =
    ({
        text,
        forceColor,
        contentStyles={},
        renderHTMLContent = false,
        messageSize = null
    }) => {
        const { styles, theme } = useStyles(stylesheet)
        const color = forceColor ? forceColor : theme.icon.error

        if (!text) {
            return (
                <View style={{
                    ...styles.container,
                    ...contentStyles
                }} />
            )
        }

        return (
            <View style={{
                ...styles.container,
                ...contentStyles
            }}>
                <Icons.Error
                    size={20}
                    forceColor={color}
                />
                <View
                    style={styles.text}
                    testID="error-message"
                >
                    {
                        renderHTMLContent ? (
                            <HTMLContent
                                html={text}
                                messageColor={color}
                                messageSize={messageSize}
                            />
                        ): (
                            <Typography.Error forceColor={forceColor ? forceColor : theme.components.input.typography.error}>
                                {text}
                            </Typography.Error>
                        )}

                </View>
            </View>
        )
    }

const stylesheet = createStyles(theme => ({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        marginVertical: theme.utils.gap(1)
    },
    text: {
        flex: 1,
        marginLeft: theme.utils.gap(1)
    }
}))
