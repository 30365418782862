import React from 'react'
import { View, Image, ViewStyle } from 'react-native'
import { useUserAtom } from 'lib/atoms'
import { PaymentType } from 'lib/models'
import { createStyles } from 'lib/styles'
import { Touchable, Typography } from 'lib/components'
import { userCreditCardNumber, useStyles, useTranslations } from 'lib/hooks'
import { PaymentNewCardModal } from 'features/payments'
import { Segment } from 'lib/analytics'

type CreditCardProps = {
    isModalOpen: boolean,
    selectedPaymentMethod: PaymentType,
    setIsModalOpen: (value: boolean) => void,
    onSelect: (value: PaymentType) => void,
    customStyles?: ViewStyle,
    setIsLoading?: (value: boolean) => void
}

export const CreditCard: React.FunctionComponent<CreditCardProps> = ({
    isModalOpen,
    setIsModalOpen,
    setIsLoading,
    selectedPaymentMethod,
    onSelect,
    customStyles = {}
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const [user] = useUserAtom()
    const [defaultCard] = user.payment_providers
        ?  user.payment_providers.filter(item => item.default)
        : []
    const cardNumber = userCreditCardNumber(defaultCard?.last_4)

    return (
        <View
            style={{
                ...styles.wrapper,
                ...customStyles
            }}
        >
            <PaymentNewCardModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                setIsLoading={setIsLoading}
            />
            <Touchable
                style={styles.container}
                onPress={() => onSelect(PaymentType.Card)}
            >
                <View style={styles.row}>
                    <View style={styles.radio}>
                        {selectedPaymentMethod === PaymentType.Card && (
                            <View style={styles.dot} />
                        )}
                    </View>
                    <View style={styles.header}>
                        <View>
                            <Typography.SmallSubheading>
                                {T.components.paymentMethod.creditCard.label}
                            </Typography.SmallSubheading>
                            {defaultCard && (
                                <View style={styles.content}>
                                    <Image
                                        style={styles.cardImage}
                                        source={{
                                            uri: defaultCard.imageUrl
                                        }}
                                    />
                                    <View>
                                        <Typography.Regular forceColor={theme.colors.darkGrey}>
                                            {cardNumber}
                                        </Typography.Regular>
                                    </View>
                                </View>
                            )}
                        </View>
                        <Touchable
                            style={styles.add}
                            onPress={() => {
                                Segment.addNewCreditCardClicked({})

                                setIsModalOpen(true)
                            }}
                        >
                            <Typography.Regular
                                bold
                                forceColor={theme.colors.orange}
                            >
                                {T.components.paymentMethod.creditCard.addNew}
                            </Typography.Regular>
                        </Touchable>
                    </View>
                </View>
            </Touchable>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        borderBottomWidth: 1,
        borderBottomColor: theme.colors.silver
    },
    container: {
        paddingVertical: theme.utils.gap(2)
    },
    row: {
        flexDirection: 'row'
    },
    header: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'space-between'
    },
    balance: {
        marginVertical: theme.utils.gap(1)
    },
    radio: {
        width: 24,
        height: 24,
        borderRadius: 12,
        alignItems: 'center',
        justifyContent: 'center',
        borderWidth: 2,
        marginRight: theme.utils.gap(2),
        borderColor: theme.colors.night
    },
    dot: {
        width: 12,
        height: 12,
        borderRadius: 6,
        backgroundColor: theme.colors.sun
    },
    add: {
        borderBottomWidth: 1,
        borderBottomColor: theme.colors.orange
    },
    cards: {
        marginTop: theme.utils.gap(2),
        flexWrap: {
            lg: 'wrap',
            ':w[, 1050]': undefined
        },
        flexDirection: {
            lg: 'row',
            ':w[, 1050]': 'column'
        }
    },
    content: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: theme.utils.gap(1)
    },
    cardImage: {
        width: 30,
        height: 20,
        resizeMode: 'contain',
        marginRight: theme.utils.gap(1)
    }
}))
