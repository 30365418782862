import { useContext, useEffect, useState } from 'react'
import { imageActions } from 'features/image'
import { dateHelpers, R } from 'lib/utils'
import { useToastAtom, useUnreadJobChatsAtom, useUserTokenAtom } from 'lib/atoms'
import { NotificationType } from 'lib/types'
import { APP_CONFIG } from 'lib/config'
import { useInternetConnection, useQueryHelpers, useTranslations } from 'lib/hooks'
import {
    appendChatMessage,
    fetchImageMetadata,
    getRoomName,
    historyToChatMessages,
    mergeChatMessages,
    toChatMessage
} from '../utils'
import { getChatArchiveMessages } from '../actions'
import { XMPPChatContext } from '../XMPPChatProvider'
import { ChatArchiveMessage, ChatMessage, ChatMessageType, ConnectionStatus, GroupedChatMessages } from '../types'

export const useChat = (jobId: string, supplierId: string) => {
    const T = useTranslations()
    const [token] = useUserTokenAtom()
    const chatProvider = useContext(XMPPChatContext)
    const { isOnline } = useInternetConnection()
    const { mutateAsync: getImage } = imageActions.getS3ImageDownloadURL()
    const [canLoadMore, setCanLoadMore] = useState(false)
    const [canReconnect, setCanReconnect] = useState(false)
    const [isLoadingHistory, setIsLoadingHistory] = useState(true)
    const [messages, setMessages] = useState<GroupedChatMessages>({})
    const roomName = getRoomName(jobId, token, supplierId)
    const [, setToastMessage] = useToastAtom()
    const { onRequestError } = useQueryHelpers()
    const [, setUnreadJobChats] = useUnreadJobChatsAtom()
    const { mutate: onGetArchiveMessages } = getChatArchiveMessages()
    const [roomConnectionStatus, setRoomConnectionStatus] = useState(ConnectionStatus.Connect)
    const onNewMessage = (message: ChatMessage) => setMessages(prevState => appendChatMessage(message, prevState))
    const joinRoom = () => {
        if (chatProvider) {
            setRoomConnectionStatus(ConnectionStatus.Connecting)

            chatProvider
                .join(roomName, onNewMessage)
                .then(() => setRoomConnectionStatus(ConnectionStatus.Online))
                .catch(() => {
                    setRoomConnectionStatus(ConnectionStatus.Offline)
                    setToastMessage({
                        type: NotificationType.Error,
                        message: T.screens.chat.joinRoomError
                    })
                    setCanReconnect(true)
                })
        }
    }
    const mamResultToChatMessages = async (mamResult: Array<ChatArchiveMessage>) => {
        const messagesWithMetadata = await Promise.all(mamResult
            .sort((messageA, messageB) => messageB.timestamp - messageA.timestamp)
            .map(toChatMessage)
            .map(async message => {
                if (message?.payload?.type === ChatMessageType.Photo) {
                    return {
                        ...message,
                        payload: {
                            ...message.payload,
                            metadata: await fetchImageMetadata(message.payload.body, token, getImage)
                        }
                    }
                }

                return message
            })
        )

        return historyToChatMessages(messagesWithMetadata)
    }

    useEffect(() => {
        onGetArchiveMessages(
            {
                token,
                jid: roomName,
                rsm: {
                    max: Number(APP_CONFIG.CHAT.MAX_RECEIVED_MESSAGE),
                    before: true
                }
            },
            {
                onSuccess: async ({ data }) => {
                    const historyMessages = await mamResultToChatMessages(data.mamResult.items)

                    setIsLoadingHistory(false)
                    setMessages(prevState => mergeChatMessages(prevState, historyMessages))
                    setCanLoadMore(data.mamResult.items.length === Number(APP_CONFIG.CHAT.MAX_RECEIVED_MESSAGE))
                    setUnreadJobChats(prevState => prevState
                        .filter(unreadChat => unreadChat.jobFetchId !== jobId)
                    )
                },
                onError: error => {
                    onRequestError(error)
                    setIsLoadingHistory(false)
                }
            }
        )
    }, [roomName])

    useEffect(() => {
        joinRoom()

        return () => {
            chatProvider?.leave(roomName)
                .catch(R.T)
        }
    }, [chatProvider])

    useEffect(() => {
        if (!isOnline && roomConnectionStatus === ConnectionStatus.Online) {
            setRoomConnectionStatus(ConnectionStatus.Offline)
            setCanReconnect(false)
        }

        if (isOnline && roomConnectionStatus === ConnectionStatus.Offline) {
            chatProvider?.leave(roomName)
                .catch(R.T)

            setCanReconnect(true)
        }
    }, [isOnline, roomConnectionStatus, chatProvider])

    return {
        chat: {
            roomName,
            messages,
            canLoadMore,
            canReconnect,
            isLoadingHistory,
            roomConnectionStatus,
            leave: () => {
                chatProvider?.leave(roomName)
                    .then(() => setRoomConnectionStatus(ConnectionStatus.Offline))
                    .catch(() => setToastMessage({
                        type: NotificationType.Info,
                        message: T.screens.chat.leaveRoomError
                    }))
            },
            sendTextMessage: (message: string) => {
                chatProvider?.sendTextMessage(roomName, message)
                    .catch(R.T)
            },
            sendImageMessage: (imageUrl: string, message?: string) => {
                chatProvider?.sendImageMessage(roomName, imageUrl, message)
                    .catch(R.T)
            },
            loadMore: () => {
                setCanLoadMore(false)

                const lastMessageKey = Object
                    .keys(messages)
                    .sort(dateHelpers.compareDatesAsc)
                    .at(0) as string
                const earliestMessageDate = messages[lastMessageKey]
                    ?.at(messages[lastMessageKey].length - 1)
                    ?.createdAt

                if (earliestMessageDate) {
                    setIsLoadingHistory(true)

                    onGetArchiveMessages({
                        token,
                        jid: roomName,
                        rsm: {
                            max: Number(APP_CONFIG.CHAT.MAX_RECEIVED_MESSAGE),
                            before: new Date(earliestMessageDate).getTime() * 1000
                        }
                    }, {
                        onSuccess: async ({ data }) => {
                            const historyMessages = await mamResultToChatMessages(data.mamResult.items)

                            setIsLoadingHistory(false)
                            setMessages(prevState => mergeChatMessages(prevState, historyMessages))
                            setCanLoadMore(data.mamResult.items.length === Number(APP_CONFIG.CHAT.MAX_RECEIVED_MESSAGE))
                        },
                        onError: error => {
                            onRequestError(error)
                            setIsLoadingHistory(false)
                        }
                    })
                }
            },
            onReconnect: () => {
                setCanReconnect(false)
                joinRoom()
            }
        }
    }
}
