import React from 'react'
import { View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { Adapter, Button } from 'lib/components'
import { ScreenNames } from 'lib/routing'
import { createStyles } from 'lib/styles'
import { Segment } from 'lib/analytics'
import { useStyles, useTranslations } from 'lib/hooks'
import { useUserNewAddress } from '../hooks'

type UserNewAddressFormProps = {
    postcode?: string,
    onCancel?: VoidFunction
}

export const UserNewAddressForm: React.FunctionComponent<UserNewAddressFormProps> = ({
    postcode,
    onCancel
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const navigation = useNavigation()
    const {
        submit,
        isLoading,
        isFilled,
        form,
        hasError,
        fetchPostalCodesWithDebounce,
        isFetchingPostcode
    } = useUserNewAddress({
        postcode,
        onSuccess: onCancel
    })

    return (
        <View style={styles.container}>
            <Adapter.AsyncSelectInput
                {...form.postcode}
                loadingOptions={fetchPostalCodesWithDebounce}
                disabled={isLoading}
                testID="postcode"
            />
            <View style={styles.row}>
                <View style={styles.flexLeft}>
                    <Adapter.TextInputWithLabel
                        {...form.addressLine}
                        disabled={isLoading}
                        testID="address-line"
                        onBlur={() => {
                            if (form.addressLine.value) {
                                Segment.addressLineEntered({
                                    entryType: Segment.EntryType.Manual
                                })
                            }
                        }}
                    />
                </View>
                <View style={styles.flex}>
                    <Adapter.TextInputWithUnit
                        {...form.block}
                        unit={T.common.block}
                        disabled={isLoading}
                    />
                </View>
            </View>
            <Adapter.TextInputWithUnit
                {...form.unitFloor}
                unit={T.common.unit}
                disabled={isLoading}
                testID="unit-or-floor"
                onBlur={() => {
                    if (form.unitFloor.value) {
                        Segment.unitAndFloorEntered({
                            entryType: Segment.EntryType.Manual
                        })
                    }
                }}
            />
            <View style={styles.buttons}>
                <View style={styles.button}>
                    <Button
                        noBackground
                        disabled={isLoading}
                        onPress={() => {
                            if (onCancel) {
                                return onCancel()
                            }

                            navigation.navigate(ScreenNames.UserAddress)
                        }}
                        text={T.common.cancel}
                    />
                </View>
                <View style={styles.buttonSeparator} />
                <View style={styles.button}>
                    <Button
                        onPress={submit}
                        text={T.common.save}
                        isLoading={isLoading || isFetchingPostcode}
                        disabled={!isFilled || hasError}
                        testID="save-address-button"
                    />
                </View>
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        marginTop: theme.utils.gap(2)
    },
    row: {
        flexDirection: {
            lg: 'row',
            xs: 'column'
        }
    },
    flexLeft: {
        flex: {
            lg: 2,
            xs: undefined
        },
        paddingRight: {
            lg: theme.utils.gap(1),
            xs: 0
        }
    },
    flex: {
        flex: {
            lg: 1,
            xs: undefined
        }
    },
    buttons: {
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginBottom: theme.utils.gap(3)
    },
    buttonSeparator: {
        width: theme.utils.gap(2)
    },
    button: {
        flex: {
            lg: undefined,
            xs: 1
        },
        width: 120
    }
}))
