import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import { UseFormReturn } from '@codegateinc/react-form-builder-v2'
import { Nullable, SupplyID } from 'lib/types'
import { isNative } from 'lib/common'
import { dateHelpers } from 'lib/utils'
import { PaymentType } from 'lib/models'
import { Breakpoint, createStyles } from 'lib/styles'
import { useIsWithinBreakpoints, useStyles, useTranslations } from 'lib/hooks'
import { useIsAuthorizedAtom, useMPDropoffLocationAtom, useMPPickupLocationAtom, useUserAtom } from 'lib/atoms'
import { Adapter, Button, FormComponents, InputHeading, MediaQuery, PhotoUpload, PolicyLinks, UploadedPhoto } from 'lib/components'
import { GetPromotionResponse } from '../../types'
import { ServiceLayout } from '../ServiceLayout'
import { BookingCancellationFees } from '../BookingCancellationFees'
import { MoversAndPackersFormShape, MoversAndPackersServiceDetailsShape } from '../../forms'
import { moversAndPackersHooks, useJobQuotation } from '../../hooks'
import { Rated } from '../Rated'
import { MobileSummary } from '../MobileSummary'
import { VisitCharges } from '../VisitCharges'
import { MoversAndPackersSummaryColumn } from './MoversAndPackersSummaryColumn'
import { BookingDateModal } from '../BookingDateModal'
import { DoubleAddress } from './DoubleAddress'
import { MoversAndPackersPriceNote } from './MoversAndPackersPriceNote'
import { PromotionCodeInput } from '../PromotionCodeInput'
import { PaymentMethodTile } from '../PaymentMethodTile'
import { BookingAuth } from '../unauthorized'
import { ServiceDescription } from '../ServiceDescription'
import { MoversAndPackersHelpModalContent } from './MoversAndPackersHelpModalContent'
import { getServiceStaticConfig } from 'features/bookings/utils'

type MoversAndPackersServiceDetailsViewProps = {
    disabled: boolean,
    setPaymentView: (value: boolean) => void,
    selectedPaymentMethod: PaymentType,
    serviceDetailsForm: UseFormReturn<MoversAndPackersServiceDetailsShape>,
    formShape: MoversAndPackersFormShape,
    isLoading: boolean,
    setPaymentMethod: (value: PaymentType) => void,
    isUploadingPhotos: boolean,
    totalPrice: number,
    onUploadSuccess: (data: Array<UploadedPhoto>) => void,
    submit: VoidFunction,
    promotionPackage: Nullable<GetPromotionResponse>,
    onRemovePromoCode: VoidFunction,
    onPhotoRemove: (uuid: string) => void,
    setIsUploadingPhotos: (value: boolean) => void,
    requestPromotion: (code: string) => void
}

export const MoversAndPackersServiceDetailsView: React.FunctionComponent<MoversAndPackersServiceDetailsViewProps> = ({
    serviceDetailsForm,
    disabled,
    setPaymentView,
    selectedPaymentMethod,
    formShape,
    setPaymentMethod,
    totalPrice,
    onPhotoRemove,
    onUploadSuccess,
    promotionPackage,
    isLoading,
    submit,
    onRemovePromoCode,
    isUploadingPhotos,
    setIsUploadingPhotos,
    requestPromotion
}) => {
    const [user] = useUserAtom()
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const [isHelpModalOpen, setHelpModalOpen] = useState(false)
    const [isAuthorized] = useIsAuthorizedAtom()
    const [authenticationView, setAuthenticationView] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [originalPrice, setOriginalPrice] = useState(0)
    const breadcrumbs = moversAndPackersHooks.useMoversAndPackersBreadcrumbs()
    const [mpDropoffLocation] = useMPDropoffLocationAtom()
    const [mpPickupLocation] = useMPPickupLocationAtom()

    const config = getServiceStaticConfig(SupplyID.MoversPackers)
    const { pricing: { cancellationCharges: { title, subtitle, details: visitChargesDetails } } } = config
    const notEnoughCredits = selectedPaymentMethod === PaymentType.Credits && user.consumer.balance_credit < totalPrice
    const isMobile = useIsWithinBreakpoints(Breakpoint.XS, Breakpoint.MD)
    const columnWidth = isNative || isMobile
        ? undefined
        : 'calc(50% - 10px)'
    const { form } = serviceDetailsForm
    const handleConfirm = () => {
        if (!isAuthorized) {
            return setAuthenticationView(true)
        }

        submit()
    }
    const selectedDays = Object.keys(serviceDetailsForm.form.inspectionBookingDays.value)
        .filter(key => Boolean(serviceDetailsForm.form.inspectionBookingDays.value[key]))
        .sort((firstDate, secondDate) => firstDate.localeCompare(secondDate))
    const inspectionDateLabel = selectedDays.length > 0
        ? selectedDays.map(dateHelpers.inspectionDate).join(', ')
        : ''
    const { jobQuotation, requestJobQuotation } = useJobQuotation(SupplyID.MoversPackers) // TODO: TO shift the call to parent screen

    useEffect(() => {
        requestJobQuotation(null)
    }, [])

    useEffect(() => {
        if (jobQuotation) {
            setOriginalPrice(jobQuotation?.total?.valueBeforeDiscount ?? 0)
        }
    }, [jobQuotation])

    return (
        <ServiceLayout
            onInfoPress={() => setHelpModalOpen(true)}
            title={T.screens.moversAndPackers.title}
            withBackground
            breadcrumbs={breadcrumbs}
            authenticationView={authenticationView}
            onBackFromDetails={() => {
                setAuthenticationView(false)
            }}
            contentColumn={(authenticationView
                ? (
                    <BookingAuth
                        isMoversAndPackers
                        supplyId={SupplyID.MoversPackers}
                        onUploadSuccess={onUploadSuccess}
                        notEnoughCredits={notEnoughCredits}
                        selectedPaymentMethod={selectedPaymentMethod}
                        setPaymentMethod={setPaymentMethod}
                        renderExtraContent={() => (
                            <DoubleAddress
                                disabled={isLoading}
                                pickUpAddress={mpPickupLocation}
                                dropOffAddress={mpDropoffLocation}
                            />
                        )}
                    />
                ) : (
                    <View>
                        {isNative && (
                            <ServiceDescription
                                isHelpModalOpen={isHelpModalOpen}
                                setHelpModalOpen={setHelpModalOpen}
                                title={T.screens.moversAndPackers.title}
                                modalContent={(
                                    <MoversAndPackersHelpModalContent />
                                )}
                                description={T.screens.moversAndPackers.serviceDescription}
                            />
                        )}
                        <BookingDateModal
                            isMultiSelect
                            allowToggle
                            maxDate={formShape.startingDate}
                            title={T.screens.moversAndPackersServiceDetails.calendarModal.title}
                            description={T.screens.moversAndPackersServiceDetails.calendarModal.description}
                            timeTitle={T.screens.moversAndPackersServiceDetails.calendarModal.select.title}
                            minHoursNotice={24}
                            onContinue={() => setIsModalOpen(false)}
                            disabled={isLoading}
                            isLoading={isLoading}
                            isModalOpen={isModalOpen}
                            frequency={serviceDetailsForm.form.inspectionFrequency.value}
                            startingDateField={serviceDetailsForm.form.inspectionStartingDate}
                            bookingTimeField={serviceDetailsForm.form.inspectionBookingTime}
                            bookingDaysField={serviceDetailsForm.form.inspectionBookingDays}
                            onClose={() => setIsModalOpen(false)}
                        />
                        <View style={styles.wrapper}>
                            <FormComponents.FormRow title={T.screens.moversAndPackersServiceDetails.sections.movingInformation}>
                                <InputHeading
                                    title={T.screens.moversAndPackersServiceDetails.formFields.listOfItems.label}
                                    description={T.screens.moversAndPackersServiceDetails.formFields.listOfItems.description}
                                />
                                <Adapter.TextInput
                                    {...serviceDetailsForm.form.listOfItems}
                                    disabled={isLoading}
                                    inputProps={{
                                        multiline: true
                                    }}
                                />
                                <InputHeading
                                    title={T.screens.moversAndPackersServiceDetails.photoUpload.title}
                                    description={T.screens.moversAndPackersServiceDetails.photoUpload.description}
                                />
                                <PhotoUpload
                                    withLabel={false}
                                    onPhotoRemove={onPhotoRemove}
                                    isLoading={isUploadingPhotos}
                                    onSuccess={onUploadSuccess}
                                    onError={() => setIsUploadingPhotos(false)}
                                    onUploadStarts={() => setIsUploadingPhotos(true)}
                                    supplyId={SupplyID.MoversPackers}
                                />
                            </FormComponents.FormRow>
                            <FormComponents.FormRow
                                title={T.screens.moversAndPackersServiceDetails.formFields.startingDate.label}
                                description={T.screens.moversAndPackersServiceDetails.formFields.startingDate.description}
                            >
                                <View
                                    style={{
                                        width: columnWidth,
                                        ...styles.selectDateInput
                                    }}
                                >
                                    <Adapter.DateAndTime
                                        {...serviceDetailsForm.form.inspectionStartingDate}
                                        label={undefined}
                                        onPress={() => setIsModalOpen(true)}
                                        disabled={isLoading}
                                        customValueFormat={inspectionDateLabel}
                                    />
                                    <FormComponents.ErrorMessage text={serviceDetailsForm.form.inspectionBookingDays.errorMessage} />
                                </View>
                            </FormComponents.FormRow>
                        </View>
                        {isMobile && (
                            <PolicyLinks />
                        )}
                    </View>
                )
            )}
            summaryColumn={(
                <MediaQuery.Visible from={Breakpoint.LG}>
                    {!(isAuthorized && authenticationView) && (
                        <View>
                            <DoubleAddress
                                disabled={isLoading}
                                pickUpAddress={mpPickupLocation}
                                dropOffAddress={mpDropoffLocation}
                            />
                        </View>
                    )}
                    <MoversAndPackersSummaryColumn
                        totalPrice={totalPrice}
                        originalPrice={originalPrice}
                        promotionPackage={promotionPackage}
                        inspectionDays={inspectionDateLabel}
                        renderPriceComponent={() => (
                            <MoversAndPackersPriceNote
                                price={totalPrice}
                                originalPrice={originalPrice}
                            />
                        )}
                        form={formShape}
                        couponText={promotionPackage
                            ? promotionPackage.promotion.message_to_client
                            : undefined
                        }
                        renderExtraContent={() => (
                            <View>
                                <VisitCharges
                                    labelText={title}
                                    chargesMessage={subtitle}
                                    modalTitle={visitChargesDetails.title}
                                    messageText={visitChargesDetails.description}
                                />
                                <PromotionCodeInput
                                    promoCode={form.promoCode}
                                    isLoading={isLoading}
                                    promotionPackage={promotionPackage}
                                    onRemovePromoCode={onRemovePromoCode}
                                    requestPromotion={requestPromotion}
                                />
                            </View>
                        )}
                        jobQuotation={jobQuotation}
                    />
                    {!authenticationView && (
                        <PaymentMethodTile
                            notEnoughCredits={notEnoughCredits}
                            onPress={() => setPaymentView(true)}
                            selectedPaymentMethod={selectedPaymentMethod}
                        />
                    )}
                    <PolicyLinks />
                    {(!authenticationView || isAuthorized) && (
                        <Button
                            disabled={notEnoughCredits || disabled}
                            isLoading={isLoading}
                            onPress={handleConfirm}
                            text={T.common.confirm}
                        />
                    )}
                    <BookingCancellationFees />
                </MediaQuery.Visible>
            )}
            footer={(
                <MediaQuery.Hidden from={Breakpoint.LG}>
                    <MobileSummary
                        authenticationView={authenticationView && !isAuthorized}
                        isLoading={isLoading}
                        submit={handleConfirm}
                        submitText={T.common.confirm}
                        priceLabel={T.common.totalPrice}
                        price={totalPrice}
                        onRemovePromoCode={onRemovePromoCode}
                        priceBeforeDiscount={originalPrice}
                        promoCode={form.promoCode}
                        promotionPackage={promotionPackage}
                        requestPromotion={requestPromotion}
                        disabled={disabled}
                        content={(
                            <React.Fragment>
                                {!(isAuthorized && authenticationView) && (
                                    <View>
                                        <DoubleAddress
                                            disabled={isLoading}
                                            pickUpAddress={mpPickupLocation}
                                            dropOffAddress={mpDropoffLocation}
                                        />
                                    </View>
                                )}
                                <MoversAndPackersSummaryColumn
                                    totalPrice={totalPrice}
                                    originalPrice={originalPrice}
                                    promotionPackage={promotionPackage}
                                    inspectionDays={inspectionDateLabel}
                                    renderPriceComponent={() => (
                                        <MoversAndPackersPriceNote
                                            price={totalPrice}
                                            originalPrice={originalPrice}
                                        />
                                    )}
                                    form={formShape}
                                    couponText={promotionPackage
                                        ? promotionPackage.promotion.message_to_client
                                        : undefined
                                    }
                                    renderExtraContent={() => (
                                        <View>
                                            <VisitCharges
                                                labelText={title}
                                                chargesMessage={subtitle}
                                                modalTitle={visitChargesDetails.title}
                                                messageText={visitChargesDetails.description}
                                            />
                                            {/* remove this check after guest booking experiment */}
                                            {!isAuthorized && (
                                                <PromotionCodeInput
                                                    promoCode={form.promoCode}
                                                    isLoading={isLoading}
                                                    promotionPackage={promotionPackage}
                                                    onRemovePromoCode={onRemovePromoCode}
                                                    requestPromotion={requestPromotion}
                                                />
                                            )}
                                        </View>
                                    )}
                                    jobQuotation={jobQuotation}
                                />
                                {!authenticationView && (
                                    <PaymentMethodTile
                                        notEnoughCredits={notEnoughCredits}
                                        onPress={() => setPaymentView(true)}
                                        selectedPaymentMethod={selectedPaymentMethod}
                                    />
                                )}
                                <PolicyLinks />
                                <Rated />
                                <BookingCancellationFees />
                            </React.Fragment>
                        )}
                    />
                </MediaQuery.Hidden>
            )}
        />
    )
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        marginTop: {
            lg: 0,
            xs: theme.utils.gap(1)
        },
        zIndex: theme.zIndex[10]
    },
    selectDateInput: {
        marginTop: -theme.utils.gap(1)
    }
}))
