import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const GiftBox: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path
            d="M56 112C86.9279 112 112 86.9279 112 56C112 25.0721 86.9279 0 56 0C25.0721 0 0 25.0721 0 56C0 86.9279 25.0721 112 56 112Z"
            fill="white"
        />
        <Path
            d="M68.8599 88.7373C81.3393 88.7373 91.4558 87.6611 91.4558 86.3335C91.4558 85.0059 81.3393 83.9297 68.8599 83.9297C56.3805 83.9297 46.2639 85.0059 46.2639 86.3335C46.2639 87.6611 56.3805 88.7373 68.8599 88.7373Z"
            fill="#FFE9B6"
        />
        <Path
            d="M51.0714 91.6211C59.037 91.6211 65.4944 90.5449 65.4944 89.2173C65.4944 87.8897 59.037 86.8135 51.0714 86.8135C43.1058 86.8135 36.6484 87.8897 36.6484 89.2173C36.6484 90.5449 43.1058 91.6211 51.0714 91.6211Z"
            fill="#FFE9B6"
        />
        <Path
            d="M90.4945 51.2373H44.3411V82.7255C44.3411 83.0448 44.4679 83.351 44.6937 83.5768C44.9194 83.8025 45.2256 83.9293 45.5449 83.9293H89.2907C89.61 83.9293 89.9162 83.8025 90.1419 83.5768C90.3677 83.351 90.4945 83.0448 90.4945 82.7255V51.2373Z"
            fill="#FFE9B6"
            stroke="#FCB40A"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <Path
            d="M75.1098 38.7373H59.7253V83.9292H75.1098V38.7373Z"
            fill="white"
            stroke="#FCB40A"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <Path
            d="M91.2135 38.7373H43.6215C43.3023 38.7373 42.9961 38.8551 42.7703 39.0647C42.5446 39.2744 42.4177 39.5588 42.4177 39.8553V51.2372H92.4173V39.8553C92.4173 39.5588 92.2905 39.2744 92.0647 39.0647C91.839 38.8551 91.5328 38.7373 91.2135 38.7373Z"
            fill="#FFE9B6"
            stroke="#FCB40A"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <Path
            d="M75.1098 38.7373H59.7253V51.2372H75.1098V38.7373Z"
            fill="white"
            stroke="#FCB40A"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <Path
            d="M37.6104 66.6221H66.4563V86.572C66.4563 86.8912 66.3294 87.1974 66.1037 87.4232C65.8779 87.6489 65.5718 87.7757 65.2525 87.7757H38.8141C38.4949 87.7757 38.1887 87.6489 37.9629 87.4232C37.7372 87.1974 37.6104 86.8912 37.6104 86.572V66.6221Z"
            fill="#FFE9B6"
            stroke="#FCB40A"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <Path
            d="M56.8406 66.6221H47.2253V87.7757H56.8406V66.6221Z"
            fill="white"
            stroke="#FCB40A"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <Path
            d="M54.2356 20.124C52.6269 20.8004 51.2604 21.9478 50.316 23.4154C49.3716 24.883 48.8934 26.602 48.9444 28.3464C48.9953 30.0909 49.573 31.7791 50.6014 33.189C51.6298 34.599 53.0609 35.6647 54.7064 36.2461C58.4061 37.6186 63.5659 37.8828 67.7604 38.2683C53.7997 31.994 54.8348 22.3491 54.2356 20.124Z"
            fill="white"
            stroke="#FCB40A"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <Path
            d="M63.0121 29.4511C65.4377 31.8755 66.7202 35.8154 67.7601 38.2686C67.3674 34.0538 67.1129 28.7438 65.7187 25.0333C65.2105 23.6789 64.3706 22.4737 63.2759 21.528C62.1812 20.5823 60.8667 19.9265 59.4528 19.6204C58.039 19.3144 56.5709 19.3681 55.1832 19.7765C53.7954 20.1849 52.5323 20.935 51.5095 21.958C51.1393 22.3364 50.8041 22.7476 50.5081 23.1865C53.8823 22.8947 58.79 25.2278 63.0121 29.4511Z"
            fill="white"
            stroke="#FCB40A"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <Path
            d="M81.2897 20.124C82.8982 20.8013 84.2641 21.9495 85.2077 23.4177C86.1513 24.8859 86.6285 26.6053 86.5765 28.3497C86.5245 30.0942 85.9458 31.7821 84.9165 33.1915C83.8871 34.6009 82.4553 35.6658 80.8094 36.2461C77.1109 37.6186 71.951 37.8828 67.7566 38.2683C81.7208 31.994 80.6893 22.3491 81.2897 20.124Z"
            fill="white"
            stroke="#FCB40A"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <Path
            d="M72.5083 29.4508C70.0826 31.8752 68.8002 35.8151 67.7603 38.2683C68.1529 34.0535 68.4075 28.7435 69.8016 25.033C70.3451 23.5428 71.2904 22.2321 72.5327 21.246C73.7751 20.2598 75.2661 19.6367 76.8407 19.4455C78.4153 19.2543 80.0121 19.5025 81.4543 20.1627C82.8966 20.823 84.128 21.8694 85.0123 23.1861C81.6368 22.8943 76.7267 25.2275 72.5083 29.4508Z"
            fill="white"
            stroke="#FCB40A"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <Path
            d="M67.76 38.2686C56.4304 48.8777 62.1018 59.5001 62.1018 59.5001L53.3719 57.4515C51.9141 48.4094 61.6455 38.2686 67.76 38.2686Z"
            fill="white"
            stroke="#FCB40A"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <Path
            d="M67.7603 38.2686C79.0899 48.8777 73.4185 59.5001 73.4185 59.5001L82.1472 57.4515C83.6049 48.4094 73.8748 38.2686 67.7603 38.2686Z"
            fill="white"
            stroke="#FCB40A"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <Path
            d="M33.4051 56.6821L21.3789 83.5263C21.2059 83.9137 21.1937 84.354 21.345 84.7505C21.4963 85.147 21.7987 85.4672 22.1859 85.6409L27.7336 88.1254L41.0626 58.3585L35.5185 55.8776C35.3269 55.7916 35.1202 55.7441 34.9103 55.738C34.7004 55.7319 34.4913 55.7672 34.295 55.8419C34.0988 55.9166 33.9191 56.0293 33.7664 56.1734C33.6137 56.3176 33.4909 56.4905 33.4051 56.6821Z"
            fill="#FFE9B6"
            stroke="#FCB40A"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <Path
            d="M12.0029 73.4474C11.9651 74.6577 12.2941 75.8511 12.9468 76.871C13.5995 77.891 14.5453 78.6897 15.6601 79.1625C16.7749 79.6352 18.0066 79.7598 19.1935 79.5199C20.3804 79.28 21.467 78.6869 22.3107 77.8183C24.2428 75.8802 25.9023 72.7005 27.3541 70.168C19.3663 77.1555 13.5856 73.7128 12.0029 73.4474Z"
            fill="white"
            stroke="#FCB40A"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <Path
            d="M20.4324 70.6092C22.6623 69.7818 25.5167 70.1133 27.3635 70.1685C24.592 69.1959 21.1721 67.8209 18.4271 67.624C17.4265 67.5541 16.4243 67.7359 15.5121 68.153C14.5998 68.57 13.8066 69.209 13.2049 70.0115C12.6031 70.8139 12.212 71.7544 12.0672 72.747C11.9225 73.7395 12.0287 74.7525 12.3762 75.6934C12.5086 76.0364 12.6692 76.3678 12.8565 76.6841C13.6527 74.471 16.543 72.0502 20.4324 70.6092Z"
            fill="white"
            stroke="#FCB40A"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <Path
            d="M19.8369 56.3853C20.4028 55.869 21.0629 55.4666 21.781 55.2001C22.7313 54.8418 23.757 54.7307 24.7619 54.8772C25.7668 55.0236 26.7181 55.4228 27.5266 56.0374C28.3351 56.6519 28.9743 57.4617 29.3844 58.3908C29.7944 59.3199 29.9618 60.3379 29.8709 61.3494C29.662 64.08 28.3375 67.4135 27.3636 70.1682C27.4489 59.547 21.0665 57.4095 19.8369 56.3853Z"
            fill="white"
            stroke="#FCB40A"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <Path
            d="M23.1778 64.619C24.0076 66.8525 26.1174 68.8002 27.3639 70.1679C24.8206 68.6993 21.5471 67.0026 19.6078 65.0488C18.903 64.3357 18.388 63.4574 18.1098 62.4941C17.8316 61.5308 17.799 60.5132 18.0152 59.5341C18.2313 58.555 18.6892 57.6456 19.347 56.8889C20.0049 56.1322 20.8418 55.5524 21.7813 55.2022C22.1284 55.0789 22.4856 54.9861 22.8488 54.9248C21.684 56.9638 21.7381 60.7355 23.1778 64.619Z"
            fill="white"
            stroke="#FCB40A"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <Path
            d="M27.3635 70.168C30.7786 80.3845 39.1206 79.8813 39.1206 79.8813L35.2985 84.7962C29.1743 83.0983 25.5959 74.025 27.3635 70.168Z"
            fill="white"
            stroke="#FCB40A"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <Path
            d="M27.3638 70.1676C37.3401 66.0908 42.3943 72.7433 42.3943 72.7433L43.6312 66.6456C38.3476 63.1056 29.1374 66.3118 27.3638 70.1676Z"
            fill="white"
            stroke="#FCB40A"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <Path
            d="M93.7777 18.233C93.9158 17.6346 94.2193 17.0871 94.6535 16.6529C95.0878 16.2186 95.6353 15.9151 96.2337 15.777L101.16 14.639C101.251 14.6186 101.332 14.5678 101.39 14.4951C101.448 14.4224 101.48 14.3321 101.48 14.239C101.48 14.1459 101.448 14.0556 101.39 13.9829C101.332 13.9102 101.251 13.8594 101.16 13.839L96.2307 12.702C95.6323 12.5639 95.0848 12.2604 94.6505 11.8261C94.2163 11.3919 93.9128 10.8444 93.7747 10.246L92.6377 5.32C92.6172 5.22917 92.5665 5.148 92.4938 5.08984C92.4211 5.03169 92.3308 5 92.2377 5C92.1446 5 92.0543 5.03169 91.9816 5.08984C91.9089 5.148 91.8581 5.22917 91.8377 5.32L90.7057 10.249C90.5676 10.8474 90.2641 11.3949 89.8298 11.8291C89.3956 12.2634 88.8481 12.5669 88.2497 12.705L83.3237 13.839C83.2321 13.8585 83.15 13.9089 83.0911 13.9817C83.0321 14.0545 83 14.1453 83 14.239C83 14.3327 83.0321 14.4235 83.0911 14.4963C83.15 14.5691 83.2321 14.6195 83.3237 14.639L88.2497 15.776C88.8481 15.9141 89.3956 16.2176 89.8298 16.6518C90.2641 17.0861 90.5676 17.6336 90.7057 18.232L91.8417 23.161C91.8621 23.2518 91.9129 23.333 91.9855 23.3912C92.0582 23.4493 92.1486 23.481 92.2417 23.481C92.3348 23.481 92.4251 23.4493 92.4978 23.3912C92.5705 23.333 92.6212 23.2518 92.6417 23.161L93.7777 18.233Z"
            fill="white"
            stroke="#FCB40A"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <Path
            d="M26.5103 39.4493C26.6191 38.9767 26.8586 38.5443 27.2015 38.2015C27.5443 37.8586 27.9767 37.6191 28.4493 37.5103L32.3413 36.6103C32.4125 36.5936 32.4759 36.5534 32.5214 36.4961C32.5668 36.4389 32.5915 36.3679 32.5915 36.2948C32.5915 36.2217 32.5668 36.1507 32.5214 36.0934C32.4759 36.0361 32.4125 35.9959 32.3413 35.9793L28.4493 35.0793C27.9768 34.9701 27.5445 34.7303 27.2016 34.3873C26.8588 34.0443 26.6192 33.6118 26.5103 33.1393L25.6103 29.2503C25.5936 29.1791 25.5534 29.1156 25.4961 29.0702C25.4388 29.0247 25.3679 29 25.2948 29C25.2217 29 25.1507 29.0247 25.0934 29.0702C25.0361 29.1156 24.9959 29.1791 24.9793 29.2503L24.0793 33.1393C23.9701 33.6117 23.7305 34.044 23.3877 34.387C23.0449 34.73 22.6127 34.9698 22.1403 35.0793L18.2503 35.9793C18.1791 35.9959 18.1156 36.0361 18.0702 36.0934C18.0247 36.1507 18 36.2217 18 36.2948C18 36.3679 18.0247 36.4389 18.0702 36.4961C18.1156 36.5534 18.1791 36.5936 18.2503 36.6103L22.1393 37.5103C22.6118 37.6192 23.0443 37.8588 23.3873 38.2016C23.7303 38.5445 23.9701 38.9768 24.0793 39.4493L24.9793 43.3413C24.9959 43.4125 25.0361 43.4759 25.0934 43.5214C25.1507 43.5668 25.2217 43.5915 25.2948 43.5915C25.3679 43.5915 25.4388 43.5668 25.4961 43.5214C25.5534 43.4759 25.5936 43.4125 25.6103 43.3413L26.5103 39.4493Z"
            fill="white"
            stroke="#FCB40A"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <Path
            d="M35.7783 22.3263C35.865 21.949 36.0562 21.6037 36.3299 21.3299C36.6037 21.0562 36.949 20.865 37.3263 20.7783L40.4333 20.0613C40.4882 20.0464 40.5367 20.0139 40.5713 19.9687C40.6059 19.9235 40.6246 19.8682 40.6246 19.8113C40.6246 19.7544 40.6059 19.6991 40.5713 19.6539C40.5367 19.6087 40.4882 19.5762 40.4333 19.5613L37.3263 18.8453C36.9491 18.7582 36.6039 18.5669 36.3301 18.2932C36.0563 18.0195 35.8647 17.6745 35.7773 17.2973L35.0613 14.1913C35.0464 14.1364 35.0139 14.0879 34.9687 14.0533C34.9235 14.0187 34.8682 14 34.8113 14C34.7544 14 34.6991 14.0187 34.6539 14.0533C34.6087 14.0879 34.5762 14.1364 34.5613 14.1913L33.8453 17.2973C33.758 17.6743 33.5666 18.0193 33.293 18.293C33.0193 18.5666 32.6743 18.758 32.2973 18.8453L29.1913 19.5613C29.1364 19.5762 29.0879 19.6087 29.0533 19.6539C29.0187 19.6991 29 19.7544 29 19.8113C29 19.8682 29.0187 19.9235 29.0533 19.9687C29.0879 20.0139 29.1364 20.0464 29.1913 20.0613L32.2973 20.7773C32.6745 20.8647 33.0195 21.0563 33.2932 21.3301C33.5669 21.6039 33.7582 21.9491 33.8453 22.3263L34.5613 25.4333C34.5762 25.4882 34.6087 25.5367 34.6539 25.5713C34.6991 25.6059 34.7544 25.6246 34.8113 25.6246C34.8682 25.6246 34.9235 25.6059 34.9687 25.5713C35.0139 25.5367 35.0464 25.4882 35.0613 25.4333L35.7783 22.3263Z"
            fill="white"
            stroke="#FCB40A"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Icon>
)
