import React from 'react'
import { View } from 'react-native'
import { Job, JobProposal } from 'lib/models'
import { createStyles } from 'lib/styles'
import { useFormatPrice, useStyles, useTranslations } from 'lib/hooks'
import { Typography } from 'lib/components'
import { dateHelpers } from 'lib/utils'
import { Icons } from 'assets'
import { useUserAtom } from 'lib/atoms'
import { DeclineProposal } from './DeclineProposal'
import { AcceptProposal } from './AcceptProposal'
import { JobProposalTip } from './JobProposalTip'

type BookingProposalTileProps = {
    isLast: boolean,
    jobId: string,
    job: Job,
    proposal: JobProposal
}

export const BookingProposalTile: React.FunctionComponent<BookingProposalTileProps> = ({
    isLast,
    proposal,
    jobId
}) => {
    const T = useTranslations()
    const [user] = useUserAtom()
    const { styles, theme } = useStyles(stylesheet)
    const formatPrice = useFormatPrice()
    const isFavourite = user.consumer.per_service_account.some(account => account.preferred_helper?._id === proposal.supplier)

    return (
        <View
            style={!isLast
                ? styles.wrapper
                : undefined
            }
        >
            <View style={styles.title}>
                <View style={styles.expertName}>
                    <Typography.Label forceColor={theme.colors.mouse}>
                        {`${T.components.proposals.expert}: ${proposal.supplier_name} `}
                    </Typography.Label>
                    {isFavourite && (
                        <Icons.Heart
                            size={14}
                            fill={theme.colors.orange}
                            forceColor={theme.colors.orange}
                        />
                    )}
                </View>
                <Typography.Label forceColor={theme.colors.mouse}>
                    {`${T.components.proposals.submittedAt} ${dateHelpers.selectTimeLabel(proposal.date_submitted)}, ${dateHelpers.shortDate(proposal.date_submitted)}`}
                </Typography.Label>
            </View>
            <View style={styles.details}>
                <View style={styles.row}>
                    <View style={styles.flex}>
                        <Typography.Label forceColor={theme.colors.mouse}>
                            {T.common.date}
                        </Typography.Label>
                        <Typography.Label>
                            {dateHelpers.shortFullDate(proposal.schedule.date_time)}
                        </Typography.Label>
                    </View>
                    <View style={styles.separator} />
                    <View style={styles.flex}>
                        <Typography.Label forceColor={theme.colors.mouse}>
                            {T.components.proposals.proposedTime}
                        </Typography.Label>
                        <Typography.Label>
                            {dateHelpers.selectTimeLabel(proposal.schedule.date_time)}
                        </Typography.Label>
                    </View>
                </View>
                {proposal?.estimate && (
                    <View style={styles.row}>
                        <View style={styles.flex}>
                            <Typography.Label forceColor={theme.colors.mouse}>
                                {T.components.proposals.estimatedPrice}
                            </Typography.Label>
                            <Typography.Label>
                                {`${formatPrice(proposal?.estimate.min)} - ${formatPrice(proposal?.estimate.max)}`}
                            </Typography.Label>
                            {proposal.estimate.description && (
                                <Typography.Label style={styles.description}>
                                    {proposal?.estimate.description}
                                </Typography.Label>
                            )}
                        </View>
                    </View>
                )}
                {proposal.duration && (
                    <View style={styles.row}>
                        <View style={styles.flex}>
                            <Typography.Label forceColor={theme.colors.mouse}>
                                {T.common.hours}
                            </Typography.Label>
                            <Typography.Label>
                                {`${proposal.duration.num_hours} ${T.common.hours}`}
                            </Typography.Label>
                        </View>
                    </View>
                )}
                <JobProposalTip proposal={proposal} />
            </View>
            {/* TODO Not supported from supplier app, need to hide it for now
            {job.supply.id === SupplyID.Handyman && (
                <ChatWithExpert
                    job={job}
                    proposal={proposal}
                />
            )} */}
            <View style={styles.buttons}>
                <View style={styles.flex}>
                    <DeclineProposal
                        jobId={jobId}
                        proposal={proposal}
                    />
                </View>
                <View style={styles.separator} />
                <View style={styles.flex}>
                    <AcceptProposal
                        jobId={jobId}
                        proposal={proposal}
                    />
                </View>
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    flex: {
        flex: 1
    },
    row: {
        flexDirection: 'row',
        paddingBottom: theme.utils.gap(2)
    },
    wrapper: {
        paddingBottom: theme.utils.gap(3),
        marginBottom: theme.utils.gap(3),
        borderBottomColor: theme.colors.darkSilver,
        borderBottomWidth: 1
    },
    title: {
        borderRadius: 8,
        backgroundColor: theme.colors.marble,
        paddingVertical: theme.utils.gap(1),
        paddingHorizontal: theme.utils.gap(1),
        marginBottom: theme.utils.gap(2)
    },
    expertName: {
        flexDirection: 'row'
    },
    buttons: {
        flexDirection: 'row'
    },
    separator: {
        width: theme.utils.gap(1) / 2
    },
    details: {
        paddingHorizontal: theme.utils.gap(1)
    },
    description: {
        marginTop: theme.utils.gap(1) / 2
    }
}))
