import React from 'react'
import { View, Image } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { Images } from 'assets'
import { createStyles } from 'lib/styles'
import { ScreenNames } from 'lib/routing'
import { useStyles, useTranslations } from 'lib/hooks'
import { Button, SectionBox, Typography } from 'lib/components'
import { useAutoApplyPromoAtom } from 'lib/atoms'

export const BookingSuccessPromoSection: React.FunctionComponent = () => {
    const T = useTranslations()
    const navigation = useNavigation()
    const { styles, theme } = useStyles(stylesheet)
    const [, setAutoApplyPromo] = useAutoApplyPromoAtom()

    return (
        <View style={styles.wrapper}>
            <SectionBox>
                <View style={styles.container}>
                    <View style={styles.imageContainer}>
                        <View style={styles.label}>
                            <Typography.Label forceColor={theme.colors.white}>
                                {T.components.moversAndPackersSuccessBookingPromo.label.toUpperCase()}
                            </Typography.Label>
                        </View>
                        <Image
                            source={Images.OfferThumb}
                            resizeMode="cover"
                            style={styles.offerThumbImage}
                        />
                    </View>
                    <View style={styles.contentContainer}>
                        <Typography.SmallSubheading>
                            {T.components.moversAndPackersSuccessBookingPromo.title}
                        </Typography.SmallSubheading>
                        <Typography.Regular
                            style={styles.message}
                            forceColor={theme.colors.darkGrey}
                        >
                            {T.components.moversAndPackersSuccessBookingPromo.message}
                        </Typography.Regular>
                        <View style={styles.button}>
                            <Button
                                text={T.components.moversAndPackersSuccessBookingPromo.button}
                                onPress={() => {
                                    setAutoApplyPromo(T.components.moversAndPackersSuccessBookingPromo.promoCode)
                                    navigation.navigate(ScreenNames.DeepCleaning)
                                }}
                            />
                        </View>
                    </View>
                </View>
            </SectionBox>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        marginBottom: theme.utils.gap(2)
    },
    container: {
        flex: 1,
        flexDirection: {
            lg: 'row',
            xs: 'column'
        }
    },
    contentContainer: {
        flex: 2,
        paddingHorizontal: {
            lg: theme.utils.gap(4),
            xs: 0
        },
        paddingVertical: theme.utils.gap(3)
    },
    imageContainer: {
        flex: 1,
        minHeight: {
            lg: undefined,
            xs: 180
        },
        marginLeft: {
            lg: -theme.utils.gap(2),
            xs: 0
        }
    },
    offerThumbImage: {
        width: '100%',
        height: {
            lg: '100%',
            xs: 180
        }
    },
    message: {
        marginVertical: theme.utils.gap(2)
    },
    button: {
        width: {
            lg: 305,
            xs: undefined
        }
    },
    label: {
        left: 0,
        top: 0,
        zIndex: theme.zIndex[10],
        padding: theme.utils.gap(1),
        position: 'absolute',
        backgroundColor: theme.colors.orange,
        borderBottomRightRadius: theme.utils.gap(1)
    }
}))
