import React from 'react'
import { View } from 'react-native'
import { Field } from '@codegateinc/react-form-builder-v2'
import { Icons } from 'assets'
import { useStyles, useTranslations } from 'lib/hooks'
import { createStyles, theme } from 'lib/styles'
import { FormComponents, Touchable, Typography } from 'lib/components'
import { NumberInputActions } from 'lib/types'

interface ExtraHoursProps extends Field<string> {
    description: string,
    disabled?: boolean,
    step?: number
}

export const ExtraHours: React.FunctionComponent<ExtraHoursProps> = ({
    step = 1,
    label,
    value,
    disabled,
    errorMessage,
    description,
    placeholder,
    hasError,
    onChangeValue
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)

    const handlePress = (action: NumberInputActions) => {
        const numberValue = Number(value)

        if (action === NumberInputActions.Add) {
            return onChangeValue(String(numberValue + step))
        }

        if (numberValue - step < 0) {
            return
        }

        onChangeValue(String(numberValue - step))
    }
    const getBorderColor = () => {
        if (errorMessage) {
            return theme.components.input.error.borderColor
        }

        if (Number(value) <= 0) {
            return theme.components.input.emptyBorderColor
        }

        return theme.components.input.filledBorderColor
    }
    const getLabel = () => {
        switch (value) {
            case '0.5':
            case '1':
                return `${value} ${T.common.hour}`
            default:
                return `${value} ${T.common.hours}`
        }
    }

    return (
        <View style={styles.wrapper}>
            <Typography.Label forceColor={theme.colors.darkGrey}>
                {label}
            </Typography.Label>
            <View style={styles.row}>
                <View style={styles.description}>
                    <Typography.Regular>
                        {`${placeholder} ${T.common.optional}`}
                    </Typography.Regular>
                    <Typography.Error>
                        {description}
                    </Typography.Error>
                </View>
                <View
                    style={{
                        ...styles.container,
                        borderColor: getBorderColor()
                    }}
                >
                    <Touchable
                        testID={T.accessibility.jobDetailsScreen.components.minusHours}
                        onPress={() => handlePress(NumberInputActions.Remove)}
                        disabled={disabled}
                        style={styles.removeButton}
                    >
                        <Icons.Minus size={8} />
                    </Touchable>
                    <Typography.Regular
                        bold
                        forceColor={
                            hasError
                                ? theme.components.input.typography.error
                                : theme.components.input.typography.text
                        }
                    >
                        {getLabel()}
                    </Typography.Regular>
                    <Touchable
                        testID={T.accessibility.jobDetailsScreen.components.addHours}
                        onPress={() => handlePress(NumberInputActions.Add)}
                        disabled={disabled}
                        style={styles.addButton}
                    >
                        <Icons.Plus size={12} />
                    </Touchable>
                </View>
            </View>
            <FormComponents.ErrorMessage text={errorMessage} />
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    row: {
        flexDirection: {
            lg: 'row',
            xs: 'column'
        },
        alignItems: {
            lg: 'center',
            xs: undefined
        },
        marginTop: theme.utils.gap(2)
    },
    wrapper: {
        flex: 1,
        backgroundColor: theme.colors.marble,
        marginTop: theme.utils.gap(2),
        borderRadius: 8,
        paddingTop: theme.utils.gap(1),
        paddingHorizontal: theme.utils.gap(1)
    },
    description: {
        flex: {
            lg: 2,
            xs: undefined
        },
        marginRight: theme.utils.gap(1)
    },
    container: {
        flex: {
            lg: 1,
            xs: undefined
        },
        borderWidth: 1,
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingHorizontal: theme.utils.gap(2),
        borderRadius: theme.components.input.borderRadius,
        height: theme.components.input.height,
        marginTop: {
            lg: 0,
            xs: theme.utils.gap(1)
        }
    },
    removeButton: {
        height: '100%',
        width: 50,
        justifyContent: 'center'
    },
    addButton: {
        height: '100%',
        width: 50,
        justifyContent: 'center',
        alignItems: 'flex-end'
    }
}))
