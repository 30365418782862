import React, { useRef, useState } from 'react'
import { View } from 'react-native'
import { useSharedValue, withTiming } from 'react-native-reanimated'
import { FlashList } from '@shopify/flash-list'
import { useStyles, useTranslations } from 'lib/hooks'
import { Grid, LoadingIndicator, PhotoGallery, Typography } from 'lib/components'
import { useUserTokenAtom } from 'lib/atoms'
import { dateHelpers } from 'lib/utils'
import { Icons } from 'assets'
import { Nullable } from 'lib/types'
import { createStyles } from 'lib/styles'
import { ChatMessage, ConnectionStatus, GroupedChatMessages } from '../types'
import { MessageTile } from './MessageTile'
import { useChatMessages } from '../hooks'
import { OfflineIndicator } from './OfflineIndicator'
import { ReconnectIndicator } from './ReconnectIndicator'
import { ScrollToBottom } from './ScrollToBottom'
import { LoadMoreIndicator } from './LoadMoreIndicator'

const POSITION_START = -66
const POSITION_END = 0

type ChatMessagesProps = {
    isOnline: Nullable<boolean>,
    isLoadingHistory: boolean,
    canReconnect: boolean,
    canLoadMore: boolean,
    onLoadMore: VoidFunction,
    onReconnect: VoidFunction,
    messages: GroupedChatMessages,
    roomConnectionStatus: ConnectionStatus,
    isDragActive: boolean
}

export const ChatMessages: React.FunctionComponent<ChatMessagesProps> = ({
    messages,
    onLoadMore,
    isOnline,
    canReconnect,
    onReconnect,
    canLoadMore,
    isDragActive,
    isLoadingHistory,
    roomConnectionStatus
}) => {
    const T = useTranslations()
    const [token] = useUserTokenAtom()
    const flashListRef = useRef<FlashList<ChatMessage>>()
    const animatedScrollY = useSharedValue(0)
    const { styles, theme } = useStyles(stylesheet)
    const [photoGallery, showPhotoGallery] = useState<Array<string>>([])
    const animatedLoadMorePositionY = useSharedValue(POSITION_START)
    const isConnecting = roomConnectionStatus !== ConnectionStatus.Online && roomConnectionStatus !== ConnectionStatus.Offline
    const data = useChatMessages(messages)
    const isDisabled = !isOnline || isDragActive || isConnecting || canReconnect

    return (
        <View style={styles.container}>
            <View
                style={{
                    ...styles.opacityContainer,
                    opacity: isDisabled ? 0.25 : 1
                }}
            >
                <FlashList
                    inverted
                    data={data}
                    numColumns={1}
                    // @ts-ignore
                    ref={flashListRef}
                    scrollEnabled={!isDisabled}
                    scrollEventThrottle={8}
                    onEndReached={() => {
                        if (!isLoadingHistory && canLoadMore) {
                            animatedLoadMorePositionY.value = withTiming(POSITION_END)
                        }
                    }}
                    onScroll={({ nativeEvent }) => {
                        if (animatedLoadMorePositionY.value === POSITION_END && canLoadMore) {
                            animatedLoadMorePositionY.value = withTiming(POSITION_START)
                        }

                        animatedScrollY.value = nativeEvent.contentOffset.y
                    }}
                    getItemType={item => typeof item}
                    renderItem={({ item }) => {
                        if (typeof item === 'string') {
                            return (
                                <Typography.Label
                                    style={styles.time}
                                    forceColor={theme.colors.softBlueGrey}
                                >
                                    {dateHelpers.checkIfIsToday(item)
                                        ? T.common.today
                                        : dateHelpers.distanceToNow(item)
                                    }
                                </Typography.Label>
                            )
                        }

                        const message = item as ChatMessage

                        return (
                            <MessageTile
                                {...message}
                                isMe={message.userId === token}
                                onImagePress={imageUrl => showPhotoGallery([imageUrl])}
                            />
                        )
                    }}
                    estimatedItemSize={76}
                />
                <ScrollToBottom
                    scrollY={animatedScrollY}
                    onPress={() => flashListRef.current?.scrollToOffset({
                        animated: true,
                        offset: 0
                    })}
                />
            </View>
            <OfflineIndicator isOnline={isOnline} />
            <ReconnectIndicator
                canReconnect={canReconnect}
                onReconnect={onReconnect}
            />
            <LoadMoreIndicator
                positionStart={POSITION_START}
                positionEnd={POSITION_END}
                canLoadMore={canLoadMore}
                onLoadMore={() => {
                    animatedLoadMorePositionY.value = withTiming(POSITION_START)
                    onLoadMore()
                }}
                indicatorPositionY={animatedLoadMorePositionY}
            />
            <LoadingIndicator isLoading={isConnecting || isLoadingHistory} />
            {isDragActive && (
                <View style={styles.dropContainer}>
                    <Icons.Clip
                        size={40}
                        forceColor={theme.colors.orange}
                    />
                    <Grid.Gap gapTop={1}>
                        <Typography.SmallSubheading>
                            {T.screens.chat.dragZone}
                        </Typography.SmallSubheading>
                    </Grid.Gap>
                </View>
            )}
            <PhotoGallery
                photos={photoGallery}
                isVisible={photoGallery.length > 0}
                onClose={() => showPhotoGallery([])}
            />
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    opacityContainer: {
        flex: 1
    },
    container: {
        flex: 1,
        backgroundColor: theme.colors.marble,
        overflow: 'hidden'
    },
    time: {
        textAlign: 'center',
        marginVertical: theme.utils.gap(1)
    },
    dropContainer: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: theme.zIndex[100]
    }
}))
