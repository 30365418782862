import React from 'react'
import { Platform, View } from 'react-native'
import { useForm } from '@codegateinc/react-form-builder-v2'
import { NotificationType } from 'lib/types'
import { Breakpoint, createStyles } from 'lib/styles'
import { getAppVersionForPlatform } from 'lib/utils'
import { useToastAtom, useUserAtom, useUserTokenAtom } from 'lib/atoms'
import { Adapter, Button, Typography } from 'lib/components'
import { useIsWithinBreakpoints, useQueryHelpers, useStyles, useTranslations } from 'lib/hooks'
import { ContactUsFieldsShape, useContactUsForm } from '../forms'
import { contactUs } from '../actions'

export const ContactUsForm: React.FunctionComponent = () => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const [user] = useUserAtom()
    const [token] = useUserTokenAtom()
    const { onRequestError } = useQueryHelpers()
    const [, setToastMessage] = useToastAtom()
    const { mutate, isLoading } = contactUs()
    const isMobile = useIsWithinBreakpoints(Breakpoint.XS, Breakpoint.MD)
    const { form, submit, isFilled, hasError, resetForm } = useForm<ContactUsFieldsShape>(useContactUsForm(), {
        onSuccess: ({ message }) => {
            mutate(
                {
                    token,
                    profile: user,
                    contents: `${message} ${Platform.OS} - ${getAppVersionForPlatform()} ${token}`
                },
                {
                    onSuccess: () => {
                        resetForm()
                        setToastMessage({
                            type: NotificationType.Success,
                            message: T.screens.contactUs.successMessage
                        })
                    },
                    onError: onRequestError
                }
            )
        }
    })

    return (
        <View style={styles.wrapper}>
            <View>
                <Typography.Label style={styles.label}>
                    {T.screens.contactUs.formFields.message.label}
                </Typography.Label>
                <Adapter.TextInput
                    {...form.message}
                    disabled={isLoading}
                    inputProps={{
                        multiline: true
                    }}
                />
            </View>
            <View style={styles.buttonWrapper}>
                <Button
                    width={!isMobile ? 250 : undefined}
                    onPress={submit}
                    isLoading={isLoading}
                    text={T.common.submit}
                    disabled={!isFilled || hasError}
                />
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        marginVertical: theme.utils.gap(2)
    },
    label: {
        marginVertical: theme.utils.gap(1)
    },
    buttonWrapper: {
        alignItems: 'flex-end'
    }
}))
