import { KeyValuePair } from './common'

export enum HttpMethod {
    GET = 'get',
    POST = 'post',
    PUT = 'put',
    PATCH = 'patch',
    DELETE = 'delete'
}

export enum ErrorCodes {
    Unauthorized = 'ACCESS_DENIED',
    SessionExpired = 'SESSION_EXPIRED',
    RefreshTokenExpired = 'REFRESH_TOKEN_EXPIRED',
    ForceResetPassword = 'RESET_PASSWORD',
    UnhandledException = 'ERR_UNHANDLED_EXCEPTION',
    InvalidPassword = 'ERR_INVALID_PASSWORD',
    DuplicatedEmail = 'ERR_DUPLICATE_KEY',
    UpdateRequiredAndroid = 'ERR_USER_MUST_UPDATE_APP_ANDROID',
    UpdateRequiredIOS = 'ERR_USER_MUST_UPDATE_APP_IOS',
    TooManyErrorAttempts = 'MaxFailedAttemptError',
    ConnectionAborted = 'ECONNABORTED'
}

export type Response<T> = {
    data: T,
    status: number,
    statusText: string,
    headers: KeyValuePair,
    config: KeyValuePair,
    response?: Response<T>
}

export type ErrorResponse = {
    status: false,
    errors: {
        error_code: string,
        error_msg: string,
        error_title?: string,
        ok_button_title?: string,
        params?: KeyValuePair
    },
    // todo not sure yet how it's structured
    params: []
}
