import React, { useState } from 'react'
import { View } from 'react-native'
import { Icons } from 'assets'
import { createStyles } from 'lib/styles'
import { ENV } from 'lib/config'
import { Segment } from 'lib/analytics'
import { Touchable, Typography, WebViewInModal } from 'lib/components'
import { useStyles, useTranslations } from 'lib/hooks'

export const BookingCancellationFees: React.FunctionComponent = () => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const [isModalOpen, setIsModalOpen] = useState(false)

    return (
        <View style={styles.container}>
            <WebViewInModal
                title={T.components.cancellationModal.title}
                isOpen={isModalOpen}
                closeModal={() => setIsModalOpen(false)}
                uri={ENV.SENDHELPER_CANCELLATION_CHARGES as string}
            />
            <Touchable
                style={styles.row}
                testID={T.accessibility.summaryColumnCancellationChargesModalButton}
                onPress={() => {
                    Segment.bookingCancellationChargesClicked({})

                    setIsModalOpen(true)
                }}
            >
                <Typography.Label
                    bold
                    style={styles.text}
                    forceColor={theme.colors.orange}
                >
                    {T.screens.cleaningSummary.cancellationFees}
                </Typography.Label>
                <Icons.Info
                    size={16}
                    forceColor={theme.colors.orange}
                />
            </Touchable>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        alignItems: 'center',
        marginVertical: theme.utils.gap(2)
    },
    row: {
        flexDirection: 'row'
    },
    text: {
        borderBottomWidth: 1,
        borderColor: theme.colors.orange,
        marginRight: theme.utils.gap(1)
    }
}))
