import { Dimensions, StatusBar } from 'react-native'
import { DeviceInfo } from '../utils'
import { isIOS } from './platform'

const HEADER_HEIGHT = 64
const WIDE_HEADER_HEIGHT = 80
const STATUSBAR_HEIGHT = isIOS
    ? DeviceInfo.get().hasNotch ? 44 : 25
    : StatusBar.currentHeight as number

const WINDOW_WIDTH = Dimensions.get('window').width
const WINDOW_HEIGHT = Dimensions.get('window').height

export enum Measurements {
    HeaderHeight = HEADER_HEIGHT as number,
    WideHeaderHeight = WIDE_HEADER_HEIGHT as number,
    StatusBarHeight = STATUSBAR_HEIGHT as number,
    HeaderWithStatusBarHeight = HEADER_HEIGHT + STATUSBAR_HEIGHT as number,
    WindowWidth = WINDOW_WIDTH as number,
    WindowHeight = WINDOW_HEIGHT as number
}
