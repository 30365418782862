import { useField } from '@codegateinc/react-form-builder-v2'
import { UploadedPhoto } from 'lib/components'
import { useTranslations } from 'lib/hooks'
import { BookingFrequency, FrequencyOption, Nullable, SupplyID } from 'lib/types'
import { dateHelpers } from 'lib/utils'
import { getServiceStaticConfig } from 'features/bookings/utils'
import { useBookingDays, useFrequencyOptions } from '../hooks'
import { HandymanFields } from './forms'
import { HandymanService } from '../types'

export const useHandymanForm = () => {
    const T = useTranslations()
    const frequencyOptions = useFrequencyOptions()
    const bookingDaysInitialValue = useBookingDays()
    const config = getServiceStaticConfig(SupplyID.Handyman)

    const frequency = useField<Nullable<FrequencyOption>>({
        key: HandymanFields.Frequency,
        initialValue: frequencyOptions.find(option => option.value === BookingFrequency.OneTime) || null,
        isRequired: false,
        validateOnBlur: false,
        label: T.screens.handyman.formFields.frequency.label
    })

    const startingDate = useField<string>({
        key: HandymanFields.StartingDate,
        initialValue: '',
        isRequired: true,
        validateOnBlur: false,
        label: T.screens.handyman.formFields.startingDate.label,
        placeholder: T.screens.handyman.formFields.startingDate.placeholder,
        validationRules: [
            {
                errorMessage: T.screens.handyman.formFields.startingDate.validation.isRequired,
                validate: Boolean
            }
        ]
    })

    const bookingTime = useField<string>({
        key: HandymanFields.BookingTime,
        initialValue: '',
        validateOnBlur: true,
        isRequired: frequency.value?.value === BookingFrequency.OneTime,
        placeholder: T.screens.handyman.formFields.selectTime.placeHolder,
        validationRules: [
            {
                errorMessage: T.screens.handyman.formFields.selectTime.validation.isRequired,
                validate: value => {
                    if (frequency.value?.value !== BookingFrequency.OneTime) {
                        return true
                    }

                    return Boolean(value)
                }
            },
            {
                errorMessage: `${config.min_notice_hours} ${T.screens.handyman.formFields.selectTime.validation.minimumHourNotice}`,
                validate: value => {
                    if (frequency.value?.value !== BookingFrequency.OneTime) {
                        return true
                    }

                    const isPast = dateHelpers.isDatePast(dateHelpers.subHoursFromDate(value, config.min_notice_hours))

                    return !isPast
                }
            }
        ]
    })

    const bookingDays = useField<Record<string, Nullable<string>>>({
        key: HandymanFields.BookingDays,
        initialValue: bookingDaysInitialValue,
        validateOnBlur: true,
        isRequired: frequency.value?.value !== BookingFrequency.OneTime,
        placeholder: T.screens.handyman.formFields.selectTime.placeHolder,
        validationRules: [
            {
                errorMessage: T.screens.handyman.formFields.selectTime.validation.isRequired,
                validate: value => {
                    if (frequency.value?.value === BookingFrequency.OneTime) {
                        return true
                    }

                    return Object.values(value).some(Boolean)
                }
            },
            {
                errorMessage: `${config.min_notice_hours} ${T.screens.handyman.formFields.selectTime.validation.minimumHourNotice}`,
                validate: value => {
                    const times = Object.values(value).filter(item => Boolean(item)) as Array<string>
                    const isSomePast = times.some(time => dateHelpers.isDatePast(dateHelpers.subHoursFromDate(time, config.min_notice_hours)))

                    return !isSomePast
                }
            }
        ]
    })

    const serviceType = useField<Array<HandymanService>>({
        key: HandymanFields.ServiceType,
        initialValue: [],
        isRequired: true,
        validateOnBlur: true,
        label: T.screens.handyman.formFields.serviceType.label,
        validationRules: [
            {
                errorMessage: T.screens.handyman.formFields.serviceType.validation.isRequired,
                validate: Boolean
            }
        ]
    })

    const pets = useField<string>({
        key: HandymanFields.Pets,
        initialValue: '',
        isRequired: false,
        placeholder: T.screens.cleaningSummary.formFields.pets.placeholder
    })

    const comment = useField<string>({
        key: HandymanFields.Comment,
        initialValue: '',
        placeholder: T.screens.handyman.formFields.comment.placeholder,
        isRequired: false
    })

    const photos = useField<Array<UploadedPhoto>>({
        key: HandymanFields.Photos,
        initialValue: [],
        isRequired: false,
        label: T.screens.handyman.formFields.photos.label,
        placeholder: T.screens.handyman.formFields.photos.placeholder
    })

    const materials = useField<boolean>({
        key: HandymanFields.Materials,
        initialValue: false,
        isRequired: false,
        label: T.screens.handyman.formFields.materials.label
    })

    return {
        frequency,
        startingDate,
        bookingTime,
        bookingDays,
        serviceType,
        pets,
        comment,
        materials,
        photos
    }
}
