import React from 'react'
import { ViewStyle } from 'react-native'
import { Field } from '@codegateinc/react-form-builder-v2'
import { FormComponents } from '../components'

interface CheckboxProps extends Field<boolean> {
    disabled?: boolean,
    testID?: string,
    checkBoxContainerStyle?: ViewStyle,
    renderComponent(): JSX.Element
}

export const Checkbox: React.FunctionComponent<CheckboxProps> = ({
    onChangeValue,
    errorMessage,
    value,
    testID,
    disabled,
    checkBoxContainerStyle,
    renderComponent
}) => (
    <FormComponents.Checkbox
        testID={testID}
        isSelected={value}
        disabled={disabled}
        errorMessage={errorMessage}
        checkBoxContainerStyle={checkBoxContainerStyle}
        renderComponent={renderComponent}
        onChange={() => onChangeValue(!value)}
    />
)
