import { CreateStylesFactory, CustomNamedStyles, ScreenSize } from './types'
import { Breakpoint, breakpoints } from './breakpoints'
import { theme } from './theme'

export const createStyles = <T extends CustomNamedStyles<T, Breakpoint>>(styles: T | CreateStylesFactory<T, typeof theme>) => styles as T

export const getBreakpointFromScreenWidth = (width: number): Breakpoint => {
    if (width >= breakpoints.xs && width < breakpoints.sm) {
        return Breakpoint.XS
    }

    if (width >= breakpoints.sm && width < breakpoints.md) {
        return Breakpoint.SM
    }

    if (width >= breakpoints.md && width < breakpoints.lg) {
        return Breakpoint.MD
    }

    if (width >= breakpoints.lg && width < breakpoints.xl) {
        return Breakpoint.LG
    }

    return Breakpoint.XL
}

export const getValueForBreakpoint = (value: Record<Breakpoint, string | number>, breakpoint: Breakpoint, screenSize: ScreenSize) => {
    // the highest priority is for custom media queries
    const customMediaQueries = Object
        .entries(value)
        .filter(([key]) => key.includes(':'))
    const customMediaQueryKey = getKeyForCustomMediaQuery(customMediaQueries, screenSize)

    if (customMediaQueryKey && customMediaQueryKey in value) {
        return value[customMediaQueryKey]
    }

    // if no custom media query, or didn't match, proceed with defined breakpoints
    const unifiedKey = breakpoint.toLowerCase()
    const directBreakpoint = value[unifiedKey]

    // if there is a direct key like 'sm' or 'md', or value for this key exists but its undefined
    if (directBreakpoint || (unifiedKey in value)) {
        return directBreakpoint
    }

    // there is no direct hit for breakpoint, so let's simulate CSS cascading
    const allBreakpoints = Object
        .entries(breakpoints)
        .map(([key, value]) => [key.toLowerCase(), value])

    const currentBreakpoint = allBreakpoints
        .findIndex(([key]) => key === unifiedKey)

    const availableBreakpoints = allBreakpoints
        .filter(([key], index) => index < currentBreakpoint && key in value)
        .map(([key]) => key)

    return allBreakpoints.length > 0
        ? value[availableBreakpoints[availableBreakpoints.length - 1] as Breakpoint]
        : undefined
}

// this is custom media query parser it handles queries like:
// :w[200] - min 200px
// :w[200, 500] - from 200 to 500 px
// :h[400, 200] - same goes for height, min or from x to y
// :w[200, 500]:h[800, 1200] - or both with and height
export const getKeyForCustomMediaQuery = (mediaQueries: Array<[string, string | number]>, screenSize: ScreenSize) => {
    const [matchedQuery] = mediaQueries
        .flatMap(([key, value]) => {
            if (key.includes('w') && key.includes('h')) {
                return isWithinBreakpoint(key, screenSize) ? key : undefined
            }

            return key
                .split(':')
                .filter(Boolean)
                .map(query => isWithinBreakpoint(query, screenSize) ? key : undefined)
        })
        .filter(Boolean)

    return matchedQuery
}

export const extractValues = (codedValue: string) => codedValue
    .replace('w', '')
    .replace('h', '')
    .replace('[', '')
    .replace(']', '')
    .replace(' ', '')
    .split(',')
    .map(Number)
    .filter(num => !isNaN(num))

export const isWithinBreakpoint = (query: string, screenSize: ScreenSize) => {
    if (query.includes('w') && query.includes('h')) {
        return isWithinTheWidthAndHeight(query, screenSize)
    }

    if (query.charAt(0) === 'w') {
        return isWithinTheWidth(query, screenSize.width)
    }

    if (query.charAt(0) === 'h') {
        return isWithinTheHeight(query, screenSize.height)
    }
}

const isWithinTheWidth = (query: string, width: number) => {
    const [minWidth, maxWidth] = extractValues(query)

    if (maxWidth && width >= minWidth && width <= maxWidth) {
        return true
    }

    return !maxWidth && width >= minWidth
}

const isWithinTheHeight = (query: string, height: number) => {
    const [minHeight, maxHeight] = extractValues(query)

    if (maxHeight && height >= minHeight && height <= maxHeight) {
        return true
    }

    return !maxHeight && height >= minHeight
}

const isWithinTheWidthAndHeight = (query: string, screenSize: ScreenSize) => {
    const result = query
        .split(':')
        .filter(Boolean)
        .map(query => isWithinBreakpoint(query, screenSize))
        .filter(Boolean)

    return result.length === 2
}
