import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { useForm } from '@codegateinc/react-form-builder-v2'
import { MOCKED_COOKING_HOURS } from 'mocks'
import { BookingFrequency, NotificationType, SupplyID } from 'lib/types'
import { isNative } from 'lib/common'
import {
    useAutoSelectFrequencyJobTypeAtom,
    useResetAutoSelectFrequencyJobTypeAtom,
    useToastAtom
} from 'lib/atoms'
import { bookingHelpers } from 'lib/utils'
import { Breakpoint, createStyles } from 'lib/styles'
import {
    useBookingDateCustomValueFormat,
    useAutoSelectFrequency,
    useInitializeAddress,
    useIsWithinBreakpoints,
    useSegmentSession,
    useStyles,
    useTranslations
} from 'lib/hooks'
import { NavigationParams, NavigationProps, ScreenNames } from 'lib/routing'
import { Segment, ServiceType } from 'lib/analytics'
import {
    Adapter,
    Address,
    Button,
    FormComponents,
    FormFieldDescription,
    MediaQuery,
    Sticky,
    Typography
} from 'lib/components'
import { getServiceStaticConfig } from 'features/bookings/utils'
import {
    BookingCancellationFees,
    BookingDateModal,
    CookingHelpModalContent,
    CookingSummaryColumn,
    MobileSummary,
    PriceSummaryComponent,
    Rated,
    ServiceDescription,
    ServiceLayout,
    TotalPriceComponent
} from '../components'
import { CookingFields, CookingFormShape, useCookingForm } from '../forms'
import { CookingSchedulePreviewScreen } from './CookingSchedulePreviewScreen'
import {
    useCookingBreadcrumbs,
    useCookingFrequencyOptions,
    useAppliances,
    useResetFormCallback,
    useBookingDays,
    useJobQuotation
} from '../hooks'
import { SubTotalComponent } from '../components/SubTotalComponent'

type CookingScreenProps = {
    route: NavigationParams<ScreenNames.Cooking>
    navigation: NavigationProps<ScreenNames.Cooking>
}

export const CookingScreen: React.FunctionComponent<CookingScreenProps> = ({
    route
}) => {
    const T = useTranslations()
    const config = getServiceStaticConfig(SupplyID.Cooking)
    const { styles, theme } = useStyles(stylesheet)
    const navigation = useNavigation()
    const [autoSelectFrequencyJobType] = useAutoSelectFrequencyJobTypeAtom()
    const [, setResetAutoSelectFrequencyJobType] = useResetAutoSelectFrequencyJobTypeAtom()
    const [, setToastMessage] = useToastAtom()
    const [isModalOpen, setIsModalOpen] = useState(false)
    const breadcrumbs = useCookingBreadcrumbs()
    const appliances = useAppliances()
    const { segmentSession } = useSegmentSession()
    const [commonDishes, setCommonDishes] = useState()
    const bookingDaysInitialValue = useBookingDays()
    const [isHelpModalOpen, setHelpModalOpen] = useState(false)
    const frequencyOptions = useCookingFrequencyOptions(config)
    const isMobile = useIsWithinBreakpoints(Breakpoint.XS, Breakpoint.MD)
    const { min_notice_hours, pricing  } = config
    const propertyTypes = pricing.propertyInfo.type.options.map(item => ({
        label: item.label,
        value: item.label
    }))
    const cookingOptions = pricing.cookingStyle.options.map(item => ({
        label: item.label,
        value: item.label
    }))
    const { jobQuotation, requestJobQuotation } = useJobQuotation(SupplyID.Cooking)
    const [isRecurring, setIsRecurring] = useState(false)
    const { form, isFilled, hasError, submit, setFieldValue, resetForm } = useForm<CookingFormShape>(useCookingForm(), {
        onSuccess: form => {
            const shiftedDays = bookingHelpers.shiftBookingDays(form.bookingDays, min_notice_hours, form.startingDate)

            navigation.navigate(ScreenNames.CookingSummary, {
                form: {
                    ...form,
                    bookingDays: shiftedDays
                },
                price: startingFrom,
                postalcode: route.params?.postalcode,
                supplyId: SupplyID.Cooking
            })
        }
    })
    const dateCustomValueFormat = useBookingDateCustomValueFormat(form.startingDate.value, form.bookingTime.value, form.bookingDays.value)
    const columnWidth = isNative || isMobile
        ? undefined
        : 'calc(55% - 10px)'
    const continueDisabled = form.frequency.value.value === BookingFrequency.OneTime
        ? !form.bookingTime.value || !isFilled || hasError
        : Object.values(form.bookingDays.value).every(value => !value) || !isFilled || hasError
    const formSummaryValue = Object.keys(form).reduce((acc, nextValue) => ({
        ...acc,
        [nextValue]: form[nextValue].value
    }), {} as CookingFormShape)
    const oneTimePricing = form.frequency.value.value === BookingFrequency.OneTime || !form.frequency.value.value
    const startingFrom = jobQuotation?.total?.value || 0
    const clearRecurringData = () => {
        setFieldValue(CookingFields.StartingDate, '')
        setFieldValue(CookingFields.BookingTime, '')
        setFieldValue(CookingFields.BookingDays, bookingDaysInitialValue)
    }

    useResetFormCallback(resetForm)

    useEffect(() => {
        clearRecurringData()
    }, [form.frequency.value])

    useEffect(() => {
        setFieldValue(CookingFields.BookingTime, '')
    }, [form.startingDate.value])

    useEffect(() => {
        if (form.bookingDays.errorMessage) {
            setToastMessage({
                message: form.bookingDays.errorMessage,
                type: NotificationType.Error
            })
        }
    }, [form.bookingDays.errorMessage])

    useEffect(() => {
        if (segmentSession.id) {
            Segment.bookingStarted({
                bookingSessionId: segmentSession.id,
                postcode: route.params?.postalcode as string,
                serviceType: ServiceType.Cooking
            })
        }
    }, [segmentSession])

    useEffect(() => {
        const { hours, frequency } = form

        if(!frequency.value.value) {
            return
        }

        const isOneTime = frequency?.value?.value === BookingFrequency.OneTime
        const frequencyType = isOneTime ? 'onetime' : 'recurring'
        setIsRecurring(!isOneTime)
        requestJobQuotation({
            frequency: frequencyType,
            numberOfHours: +hours.value
        })

    }, [form.hours.value, form.frequency.value])

    useEffect(() => {
        const selectedCookingOptions = pricing.cookingStyle.options.filter(item => form.cuisines.value === item.label)[0]
        setCommonDishes(selectedCookingOptions?.details?.value)
    }, [form.cuisines.value])

    useAutoSelectFrequency(autoSelectFrequencyJobType, route.params?.postalcode, frequencyOptions, form, setResetAutoSelectFrequencyJobType)

    useInitializeAddress(true)

    return (
        <React.Fragment>
            <ServiceLayout
                onInfoPress={() => setHelpModalOpen(true)}
                title={T.screens.cooking.title}
                withBackground
                breadcrumbs={breadcrumbs}
                bottomSpacerHeight={
                    isMobile
                        ? 0
                        : 50
                }
                contentColumn={(
                    <View>
                        <MediaQuery.Hidden from={Breakpoint.LG}>
                            <View style={styles.mobileDescriptionContainer}>
                                <ServiceDescription
                                    isHelpModalOpen={isHelpModalOpen}
                                    setHelpModalOpen={setHelpModalOpen}
                                    title={T.screens.cooking.title}
                                    modalContent={(
                                        <CookingHelpModalContent />
                                    )}
                                    description={T.screens.cooking.serviceDescription}
                                />
                            </View>
                        </MediaQuery.Hidden>
                        <BookingDateModal
                            clearData={clearRecurringData}
                            description={T.screens.cooking.calendarModal.oneTime.description}
                            minHoursNotice={min_notice_hours}
                            onContinue={() => setIsModalOpen(false)}
                            isModalOpen={isModalOpen}
                            frequency={form.frequency.value}
                            startingDateField={form.startingDate}
                            bookingTimeField={form.bookingTime}
                            bookingDaysField={form.bookingDays}
                            onClose={() => setIsModalOpen(false)}
                        />
                        <FormComponents.FormRow>
                            <Adapter.FrequencyOptions
                                {...form.frequency}
                                options={frequencyOptions}
                                primary={BookingFrequency.Weekly}
                                primaryText={T.common.mostPopular}
                            />
                            <Typography.Label forceColor={theme.colors.fog}>
                                {T.screens.cooking.footerText}
                            </Typography.Label>
                        </FormComponents.FormRow>
                        <FormComponents.FormRow>
                            <View
                                style={{
                                    width: columnWidth
                                }}
                            >
                                <Adapter.DateAndTime
                                    {...form.startingDate}
                                    customValueFormat={dateCustomValueFormat}
                                    onPress={() => setIsModalOpen(true)}
                                />
                            </View>
                            <CookingSchedulePreviewScreen
                                frequency={form.frequency.value.value}
                                bookingDays={form.bookingDays.value}
                            />
                        </FormComponents.FormRow>
                        <View style={styles.propertySelectWrapper}>
                            <FormComponents.FormRow>
                                <View
                                    style={{
                                        ...styles.propertySelectWrapper,
                                        width: columnWidth
                                    }}
                                >
                                    <Adapter.SelectInputColumn
                                        {...form.cuisines}
                                        withPlaceholder={false}
                                        options={cookingOptions}
                                        onOptionSelect={({ label }) => {
                                            Segment.bookingCookingStyleSelected({
                                                cookingStyle: label
                                            })
                                        }}
                                    />
                                </View>
                                <FormFieldDescription
                                    title={T.components.commonDishes.title}
                                    message={commonDishes}
                                />
                                <Adapter.TextInput
                                    {...form.comment}
                                    inputProps={{
                                        multiline: true
                                    }}
                                    onBlur={() => {
                                        if (form.comment.value) {
                                            Segment.bookingCookingStyleEntered({
                                                cookingStyle: form.comment.value
                                            })
                                        }
                                    }}
                                />
                                <Typography.Label forceColor={theme.colors.fog}>
                                    {T.screens.cooking.dishInfo}
                                </Typography.Label>
                            </FormComponents.FormRow>
                        </View>
                        <FormComponents.FormRow>
                            <Adapter.OptionsRadio
                                {...form.hours}
                                options={MOCKED_COOKING_HOURS}
                                onChangeValue={value => {
                                    Segment.bookingHoursSelected({
                                        hours: Number(value)
                                    })

                                    form.hours.onChangeValue(value)
                                }}
                                maxOptionsPerRow={isMobile ? 3 : undefined}
                                initialAmountToShow={isMobile ? 5 : undefined}
                            />
                        </FormComponents.FormRow>
                        <FormComponents.FormRow title={T.screens.cooking.formFields.propertyType.label}>
                            <View style={styles.row}>
                                <View style={styles.propertySelectWrapper}>
                                    <Adapter.SelectInputColumn
                                        {...form.propertyType}
                                        options={propertyTypes}
                                        onChangeValue={value => {
                                            if (!value) {
                                                Segment.bookingClearPropertyTypeClicked({})
                                            }

                                            form.propertyType.onChangeValue(value)
                                        }}
                                        onOptionSelect={({ value }) => {
                                            Segment.bookingPropertyTypeSelected({
                                                propertyType: value
                                            })
                                        }}
                                    />
                                </View>
                                <View style={styles.spacer} />
                                <View style={styles.selectWrapper}>
                                    <Adapter.SelectInputColumn
                                        {...form.appliances}
                                        options={appliances}
                                        onOptionSelect={({ value }) => {
                                            Segment.bookingPropertyOptionSelected({
                                                propertyType: value
                                            })
                                        }}
                                    />
                                </View>
                            </View>
                            <Typography.Label forceColor={theme.colors.fog}>
                                {T.screens.cooking.cleaningInfo}
                            </Typography.Label>
                        </FormComponents.FormRow>
                    </View>
                )}
                summaryColumn={(
                    <MediaQuery.Visible from={Breakpoint.LG}>
                        <ServiceDescription
                            isHelpModalOpen={isHelpModalOpen}
                            setHelpModalOpen={setHelpModalOpen}
                            title={T.screens.cooking.title}
                            modalContent={(
                                <CookingHelpModalContent />
                            )}
                            description={T.screens.cooking.serviceDescription}
                        />
                        <Sticky>
                            <Address />
                            <CookingSummaryColumn
                                renderPriceComponent={() => (
                                    <TotalPriceComponent
                                        totalPrice={jobQuotation?.total?.value || 0}
                                    />
                                )}
                                form={formSummaryValue}
                                renderPriceSummaryComponent={() => jobQuotation?.priceSummary ? (
                                    <PriceSummaryComponent priceSummary={jobQuotation?.priceSummary} promotion={jobQuotation.discount} />
                                ) : undefined}
                                renderSubTotalComponent={isRecurring && jobQuotation?.subTotal ? () => (
                                    <SubTotalComponent subTotal={jobQuotation?.subTotal} />
                                ): undefined}
                            />
                            <Button
                                onPress={submit}
                                disabled={continueDisabled}
                                text={T.common.continue}
                            />
                            <BookingCancellationFees />
                        </Sticky>
                    </MediaQuery.Visible>
                )}
                footer={(
                    <MediaQuery.Hidden from={Breakpoint.LG}>
                        <MobileSummary
                            submit={submit}
                            price={startingFrom}
                            perHour={!oneTimePricing}
                            disabled={continueDisabled}
                            content={(
                                <React.Fragment>
                                    <Address />
                                    <CookingSummaryColumn
                                        renderPriceComponent={() => (
                                            <TotalPriceComponent
                                                totalPrice={jobQuotation?.total?.value || 0}
                                            />
                                        )}
                                        form={formSummaryValue}
                                        renderPriceSummaryComponent={() => jobQuotation?.priceSummary ? (
                                            <PriceSummaryComponent priceSummary={jobQuotation?.priceSummary} promotion={jobQuotation.discount} />
                                        ) : undefined}
                                        renderSubTotalComponent={isRecurring && jobQuotation?.subTotal ? () => (
                                            <SubTotalComponent subTotal={jobQuotation?.subTotal} />
                                        ): undefined}
                                    />
                                    <Rated />
                                    <BookingCancellationFees />
                                </React.Fragment>
                            )}
                        />
                    </MediaQuery.Hidden>
                )}
            />
        </React.Fragment>
    )
}

const stylesheet = createStyles(theme => ({
    row: {
        flexDirection: {
            lg: 'row',
            xs: 'column'
        }
    },
    spacer: {
        width: theme.utils.gap(1)
    },
    propertySelectWrapper: {
        flex: 1,
        zIndex: theme.zIndex[10]
    },
    selectWrapper: {
        flex: 1
    },
    mobileDescriptionContainer: {
        backgroundColor: theme.colors.marble,
        marginHorizontal: -theme.utils.gap(2),
        marginBottom: theme.utils.gap(2),
        paddingHorizontal: theme.utils.gap(2),
        paddingTop: theme.utils.gap(4)
    }
}))
