import { useResetIsAuthorizedAtom } from './auth'
import { useResetLocaleAtom } from './locale'
import { useResetUserAtom } from './user'
import { useResetUserTokenAtom } from './token'
import { useResetViewOptionAtom } from './viewOption'
import { useResetCreditsPackageAtom } from './creditsPackage'
import { useResetAddressAtom } from './address'
import { useResetCancelReasonAtom } from './cancelReason'
import { useResetNotificationAtom } from './notification'
import { useResetUnreadJobChatsAtom } from './unreadJobChats'
import { useResetFirstSessionAtom } from './firstSignUp'
import { useResetPushNotificationAtom, useResetRequestEnableNotificationModalShowAtom, useResetShouldAskForPermissionAtom } from './pushNotification'
import { useResetFilesAtom } from './filesAtom'
import { useResetSegmentSessionAtom } from './segmentSession'
import { useResetAutoApplyPromoAtom } from './autoApplyPromo'
import { useResetMPPickupLocationAtom } from './mpPickupLocation'
import { useResetMPDropoffLocationAtom } from './mpDropoffLocation'
import { useResetSocialSignInTokenAtom } from './SocialSignInToken'
import { useResetNewPasswordTokenAtom } from './newPasswordToken'
import {
    useResetPromotionPopupAtom,
    useResetPromotionPopupShownAtAtom,
    useResetShouldClearPromotionPopupShownAtAtom
} from './promotionPopup'
import { useResetAppUpdateAtom } from './appUpdate'

export const useResetAtoms = () => {
    const [, resetIsAuthorizedAtom] = useResetIsAuthorizedAtom()
    const [, resetLocaleAtom] = useResetLocaleAtom()
    const [, resetUserAtom] = useResetUserAtom()
    const [, resetTokenAtom] = useResetUserTokenAtom()
    const [, resetViewOptionAtom] = useResetViewOptionAtom()
    const [, resetCreditsPackageAtom] = useResetCreditsPackageAtom()
    const [, resetAddressAtom] = useResetAddressAtom()
    const [, resetCancelReasonAtom] = useResetCancelReasonAtom()
    const [, resetNotificationAtom] = useResetNotificationAtom()
    const [, resetUnreadJobChatsAtom] = useResetUnreadJobChatsAtom()
    const [, resetPushNotificationAtom] = useResetPushNotificationAtom()
    const [, resetFirstSessionAtom] = useResetFirstSessionAtom()
    const [, resetShouldAskForPermissionAtom] = useResetShouldAskForPermissionAtom()
    const [, resetUnreadJobChats] = useResetUnreadJobChatsAtom()
    const [, resetFilesAtom] = useResetFilesAtom()
    const [, resetSegmentSessionAtom] = useResetSegmentSessionAtom()
    const [, resetAutoApplyPromoAtom] = useResetAutoApplyPromoAtom()
    const [, resetMPPickupLocationAtom] = useResetMPPickupLocationAtom()
    const [, resetMPDropoffLocationAtom] = useResetMPDropoffLocationAtom()
    const [, resetProviderTokenAtom] = useResetSocialSignInTokenAtom()
    const [, resetResponseTokenAtom] = useResetNewPasswordTokenAtom()
    const [, resetPromotionPopupAtom] = useResetPromotionPopupAtom()
    const [, resetPromotionPopupShownAtAtom] = useResetPromotionPopupShownAtAtom()
    const [, resetShouldClearPromotionPopupShownAtAtom] = useResetShouldClearPromotionPopupShownAtAtom()
    const [, resetResetRequestEnableNotificationModalShowAtom] = useResetRequestEnableNotificationModalShowAtom()
    const [, resetAppUpdateAtom] = useResetAppUpdateAtom()

    return {
        resetAtoms: () => [
            resetIsAuthorizedAtom,
            resetLocaleAtom,
            resetUserAtom,
            resetTokenAtom,
            resetViewOptionAtom,
            resetCreditsPackageAtom,
            resetAddressAtom,
            resetCancelReasonAtom,
            resetNotificationAtom,
            resetUnreadJobChatsAtom,
            resetPushNotificationAtom,
            resetFirstSessionAtom,
            resetShouldAskForPermissionAtom,
            resetUnreadJobChats,
            resetFilesAtom,
            resetSegmentSessionAtom,
            resetAutoApplyPromoAtom,
            resetMPPickupLocationAtom,
            resetMPDropoffLocationAtom,
            resetProviderTokenAtom,
            resetResponseTokenAtom,
            resetPromotionPopupAtom,
            resetPromotionPopupShownAtAtom,
            resetShouldClearPromotionPopupShownAtAtom,
            resetResetRequestEnableNotificationModalShowAtom,
            resetAppUpdateAtom
        ].forEach(reset => reset())
    }
}
