import { useNavigation } from '@react-navigation/native'
import { Icons } from 'assets'
import { useCleanApp, useTranslations } from 'lib/hooks'
import { ENV } from 'lib/config'
import { ScreenNames } from 'lib/routing'
import { isNative, isWeb } from 'lib/common'
import { CustomerChargeStatus, JobStatus } from 'lib/types'
import { jobActions } from 'features/jobs'
import { linkingHelpers } from 'lib/utils'
import { useUnreadJobChatsAtom } from '../atoms'

export const useAppDrawerLinks = () => {
    const T = useTranslations()
    const navigation = useNavigation()
    const { cleanPersonalData } = useCleanApp()
    const [unreadJobChatsAtom] = useUnreadJobChatsAtom()
    const { data: response } = jobActions.useGetConsumerActiveJobs()
    const hasBillings = [...response?.data.job_list || []].some(item => item.customer_charges?.some(item => item.auth_status === CustomerChargeStatus.Pending) && item.status === JobStatus.Accepted)
    const proposals = [...response?.data.job_list || []].filter(job => job.job_proposals?.length !== 0 && job.status === JobStatus.Submitted)
    const hasUnreadChatMessages = unreadJobChatsAtom.length > 0
    const hasProposals = proposals.length > 0

    return [
        {
            text: T.components.userDrawer.links.myProfile,
            icon: Icons.User,
            screenName: ScreenNames.User,
            onPress: () => navigation.navigate(ScreenNames.User)
        },
        {
            text: T.components.userDrawer.links.myBookings,
            icon: Icons.Calendar,
            screenName: ScreenNames.Bookings,
            hasDot: hasUnreadChatMessages || hasProposals || hasBillings,
            onPress: () => navigation.navigate(ScreenNames.Bookings)
        },
        {
            text: T.components.userDrawer.links.myFavourites,
            icon: Icons.Heart,
            screenName: ScreenNames.Favourite,
            onPress: () => navigation.navigate(ScreenNames.Favourite)
        },
        {
            text: T.components.userDrawer.links.paymentAndCards,
            icon: Icons.CreditCard,
            screenName: ScreenNames.UserCards,
            onPress: () => navigation.navigate(ScreenNames.UserCards)
        },
        {
            text: T.components.userDrawer.links.referAFriend,
            icon: Icons.Users,
            screenName: ScreenNames.Refer,
            onPress: () => navigation.navigate(ScreenNames.Refer)
        },
        {
            isHidden: isWeb,
            text: T.components.userDrawer.links.offersAndDeals,
            icon: Icons.Gift,
            onPress: () => linkingHelpers.openUrl(`${ENV.SENDHELPER_MARKETING_SITE_URL}/blogs/deals`)
        },
        {
            text: T.components.userDrawer.links.sendPayCredits,
            icon: Icons.Coin,
            screenName: ScreenNames.SendPay,
            onPress: () => navigation.navigate(ScreenNames.SendPay)
        },
        {
            text: T.components.userDrawer.links.contactUs,
            icon: Icons.PhoneCall,
            screenName: ScreenNames.ContactUs,
            onPress: () => navigation.navigate(ScreenNames.ContactUs)
        },
        {
            text: T.components.userDrawer.links.logOut,
            icon: Icons.Logout,
            screenName: ScreenNames.Auth,
            onPress: () => {
                if (isNative) {
                    // fixme toggle drawer - not sure why toggleNestedDrawer doesn't work
                    navigation.navigate(ScreenNames.User)

                    return cleanPersonalData()
                }

                cleanPersonalData()
                linkingHelpers.openUrl(ENV.SENDHELPER_MARKETING_SITE_URL, false)
            }
        }
    ]
}
