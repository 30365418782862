import React, { useState } from 'react'
import { View } from 'react-native'
import { useForm } from '@codegateinc/react-form-builder-v2'
import { Icons } from 'assets'
import { createStyles } from 'lib/styles'
import { Adapter, Button, Typography } from 'lib/components'
import { useBackHandler, useGetErrorMessage, useStyles, useTranslations } from 'lib/hooks'
import { AnalyticsEvent, AnalyticsEventDescription, GoogleAnalytics } from 'lib/analytics'
import { Nullable } from 'lib/types'
import { ForgotPasswordFields, ForgotPasswordFormShape, useForgotPasswordForm } from '../forms'
import { CheckEmailMessage } from './CheckEmailMessage'
import { CancelButton } from './CancelButton'
import { useForgotPassword } from '../actions'
import { ChangePasswordAction } from '../types'

export const ForgotPasswordForm: React.FunctionComponent = () => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const { getErrorMessage } = useGetErrorMessage()
    const [successMessage, setSuccessMessage] = useState<Nullable<string>>(null)
    const { mutate: sendForgotPasswordEmail, isLoading } = useForgotPassword()
    const { form, submit, isFilled, hasError, setError } = useForm<ForgotPasswordFormShape>(useForgotPasswordForm(), {
        onSuccess: ({ email }) => {
            GoogleAnalytics.logEvent({
                eventType: AnalyticsEvent.UserAction,
                description: AnalyticsEventDescription.ForgotPassword
            })

            sendForgotPasswordEmail({
                action: ChangePasswordAction.SendEmail,
                email
            }, {
                onSuccess: ({ data }) => setSuccessMessage(data.popup.success.message),
                onError: response => setError(ForgotPasswordFields.Email, getErrorMessage(response))
            })
        }
    })

    useBackHandler(() => isLoading)

    if (successMessage) {
        return (
            <CheckEmailMessage
                onResend={() => sendForgotPasswordEmail({
                    action: ChangePasswordAction.SendEmail,
                    email: form.email.value
                })}
                email={form.email.value}
            />
        )
    }

    return (
        <View style={styles.container}>
            <View style={styles.row}>
                <Typography.SmallSubheading style={styles.textCenter}>
                    {T.screens.authForgotPassword.verifyTitle}
                </Typography.SmallSubheading>
            </View>
            <View style={styles.row}>
                <Typography.Regular style={styles.message}>
                    {T.screens.authForgotPassword.verifyMessage}
                </Typography.Regular>
            </View>
            <View style={styles.formInput}>
                <Adapter.TextInput
                    {...form.email}
                    disabled={isLoading}
                    inputProps={{
                        autoComplete: 'email',
                        keyboardType: 'email-address',
                        textContentType: 'emailAddress',
                        autoCapitalize: 'none',
                        autoCorrect: false
                    }}
                />
            </View>
            <View style={styles.submit}>
                <Button
                    onPress={submit}
                    isLoading={isLoading}
                    disabled={!isFilled || hasError}
                    text={T.screens.authForgotPassword.submit}
                    renderIcon={color => (
                        <Icons.Arrow
                            size={14}
                            forceColor={color}
                        />
                    )}
                />
            </View>
            <CancelButton isDisabled={isLoading} />
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        marginTop: {
            lg: 0,
            xs: theme.utils.gap(3)
        }
    },
    textCenter: {
        textAlign: 'center'
    },
    message: {
        textAlign: 'center',
        color: theme.colors.darkGrey
    },
    row: {
        marginBottom: theme.utils.gap(3)
    },
    formInput: {
        maxWidth: {
            lg: 400,
            xs: '100%'
        },
        width: '100%',
        alignSelf: 'center',
        marginBottom: theme.utils.gap(2)
    },
    submit: {
        maxWidth: {
            lg: 400,
            xs: '100%'
        },
        width: '100%',
        alignSelf: 'center',
        marginBottom: theme.utils.gap(2)
    }
}))
