import React from 'react'
import { View } from 'react-native'
import { UseFormReturn } from '@codegateinc/react-form-builder-v2'
import { JobCancelReason } from 'lib/models'
import { Breakpoint, createStyles } from 'lib/styles'
import { useIsWithinBreakpoints, useStyles, useTranslations } from 'lib/hooks'
import { Adapter, BasicModal, Button, Typography } from 'lib/components'
import { CancelationShape } from 'features/jobs'

type CancelRequestModalProps = {
    isLoading: boolean,
    isOpen: boolean,
    onClose: VoidFunction,
    onSubmit: VoidFunction,
    formReturn: UseFormReturn<CancelationShape>
    cancelationMessage?: string,
    cancelationOptions: Array<JobCancelReason>
}

export const CancelRequestModal: React.FunctionComponent<CancelRequestModalProps> = ({
    isLoading,
    isOpen,
    onClose,
    onSubmit,
    formReturn,
    cancelationMessage,
    cancelationOptions
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const isMobile = useIsWithinBreakpoints(Breakpoint.XS, Breakpoint.MD)
    const { form } = formReturn

    return (
        <BasicModal
            isLoading={isLoading}
            onClose={onClose}
            isOpen={isOpen}
        >
            <View style={styles.modalWrapper}>
                <Typography.SmallSubheading style={styles.alignText}>
                    {T.screens.jobDetails.cancelation.packageTitle}
                </Typography.SmallSubheading>
                <View style={styles.messageContainer}>
                    <Typography.Regular style={styles.alignText}>
                        {T.screens.jobDetails.cancelation.message}
                    </Typography.Regular>
                </View>
                {cancelationMessage && (
                    <View style={styles.messageContainer}>
                        <Typography.Regular style={styles.alignText}>
                            {cancelationMessage}
                        </Typography.Regular>
                    </View>
                )}
                <View style={styles.formWrapper}>
                    <View style={styles.selectWrapper}>
                        <Adapter.SelectInputColumn
                            {...form.reason}
                            disabled={isLoading || Boolean(form.customReason.value)}
                            options={cancelationOptions.map(item => ({
                                label: item.reason,
                                value: JSON.stringify(item)
                            }))}
                        />
                    </View>
                    <View style={styles.inputWrapper}>
                        <Adapter.TextInput
                            {...form.customReason}
                            disabled={isLoading || Boolean(form.reason.value)}
                        />
                    </View>

                </View>
                <View style={styles.buttons}>
                    <View style={styles.buttonWrapper}>
                        <Button
                            noBackground
                            width={isMobile
                                ? 120
                                : 180
                            }
                            disabled={isLoading}
                            text={T.common.cancel}
                            onPress={onClose}
                        />
                    </View>
                    <View style={styles.buttonWrapper}>
                        <Button
                            width={isMobile
                                ? 120
                                : 180
                            }
                            disabled={!form.customReason.value && !form.reason.value}
                            isLoading={isLoading}
                            text={T.common.confirm}
                            onPress={onSubmit}
                        />
                    </View>
                </View>
            </View>
        </BasicModal>
    )
}

const stylesheet = createStyles(theme => ({
    modalWrapper: {
        alignItems: 'center',
        paddingHorizontal: {
            lg: theme.utils.gap(8),
            xs: 0
        },
        paddingTop: theme.utils.gap(4),
        paddingBottom: theme.utils.gap(4)
    },
    messageContainer: {
        flex: 1,
        alignItems: 'center',
        marginTop: theme.utils.gap(1)
    },
    buttons: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        zIndex: -1
    },
    buttonWrapper: {
        marginHorizontal: theme.utils.gap(1)
    },
    formWrapper: {
        width: {
            lg: 380,
            xs: '100%'
        },
        marginBottom: theme.utils.gap(2)
    },
    selectWrapper: {
        zIndex: theme.zIndex[10]
    },
    inputWrapper: {
        zIndex: -1
    },
    alignText: {
        textAlign: 'center'
    }
}))
