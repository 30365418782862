import React from 'react'
import { View } from 'react-native'
import { createStyles } from 'lib/styles'
import { useStyles } from 'lib/hooks'
import { useUserAtom } from 'lib/atoms'
import { PaymentCardTile } from './PaymentCardTile'

export const PaymentCards: React.FunctionComponent = () => {
    const { styles } = useStyles(stylesheet)
    const [user] = useUserAtom()

    return (
        <View style={styles.container}>
            {user.payment_providers.map((card, index) => (
                <PaymentCardTile
                    index={index}
                    card={card}
                    key={card._id}
                    canDelete={user.payment_providers.length > 1}
                />
            ))}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        flexWrap: {
            lg: 'wrap',
            ':w[, 1050]': undefined
        },
        flexDirection: {
            lg: 'row',
            ':w[, 1050]': 'column'
        },
        margin: {
            lg: 0,
            xs: theme.utils.gap(1)
        },
        paddingTop: theme.utils.gap(2)
    }
}))
