import React from 'react'
import { View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { PaymentMethodTile } from 'features/bookings'
import { Button, MediaQuery } from 'lib/components'
import { useStyles, useTranslations } from 'lib/hooks'
import { ConsumerPaymentDetails } from 'lib/models'
import { ScreenNames } from 'lib/routing'
import { Breakpoint, createStyles } from 'lib/styles'
import { Segment, segmentUtils } from 'lib/analytics'
import { JobStatus } from 'lib/types'
import { JobDetailsResponse } from '../../types'
import { DetailsContactLink } from '../DetailsContactLink'
import { JobContact } from '../JobContact'
import { JobReceipt } from '../JobReceipt'

type LaundryDetailsAcceptedProps = {
    job: JobDetailsResponse,
    jobStatus?: JobStatus,
    closeModal?: VoidFunction
}

export const LaundryDetailsAccepted: React.FunctionComponent<LaundryDetailsAcceptedProps> = ({
    jobStatus,
    job,
    closeModal
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const navigation = useNavigation()
    const getConsumerCreditCardToken = () => {
        try {
            const [payment] = job.job.customer_payment

            return JSON.parse(payment.payment_details) as ConsumerPaymentDetails

        } catch {
            return null
        }
    }

    return (
        <View style={styles.wrapper}>
            <JobReceipt job={job.job} />
            <PaymentMethodTile
                notEnoughCredits={false}
                selectedPaymentMethod={job.job.paymentType}
                creditCardToken={getConsumerCreditCardToken()?.result.creditCard.token}
            />
            {jobStatus === JobStatus.Completed && (
                <Button
                    noBackground
                    text={T.components.jobStatus.bookAgain}
                    onPress={() => {
                        Segment.bookAgainClicked({
                            jobId: job.job.job_id,
                            serviceType: segmentUtils.supplyIdToServiceType(job.job.supply.id),
                            jobStatus: segmentUtils.getReadableJobStatus(job.job.status)
                        })

                        if (closeModal) {
                            closeModal()
                        }

                        navigation.navigate(ScreenNames.Laundry, {
                            postalcode: job.job.location.postcode,
                            service: `${job.job.supply.id}`
                        })
                    }}
                />
            )}
            <MediaQuery.Visible from={Breakpoint.LG}>
                {job.job.status === JobStatus.Accepted && (
                    <JobContact job={job.job} />
                )}
                <DetailsContactLink
                    text={T.screens.jobDetails.needReschedule}
                    onPress={() => Segment.rescheduleBookingClicked({
                        jobId: job.job.job_id,
                        serviceType: segmentUtils.supplyIdToServiceType(job.job.supply.id)
                    })}
                />
            </MediaQuery.Visible>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        width: {
            lg: 290,
            xs: undefined
        },
        marginLeft: {
            lg: theme.utils.gap(2),
            xs: undefined
        }
    }
}))
