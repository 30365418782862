import React from 'react'
import { View } from 'react-native'
import { createStyles } from 'lib/styles'
import { useStyles } from 'lib/hooks'
import { Label } from './typography'

type InputHeadingProps = {
    title: string,
    description: string
}

export const InputHeading: React.FunctionComponent<InputHeadingProps> = ({
    title,
    description
}) => {
    const { styles, theme } = useStyles(stylesheet)

    return (
        <View style={styles.inputHeading}>
            <Label
                bold
                forceColor={theme.colors.darkGrey}
            >
                {title}
            </Label>
            <Label forceColor={theme.colors.darkGrey}>
                {description}
            </Label>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    inputHeading: {
        marginBottom: theme.utils.gap(1)
    }
}))
