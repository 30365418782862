import React from 'react'
import { View } from 'react-native'
import { Icons } from 'assets'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { Touchable, Typography } from 'lib/components'

type BookingTileButtonProps = {
    text: string,
    onPress: VoidFunction
}

export const BookingTileButton: React.FunctionComponent<BookingTileButtonProps> = ({
    text,
    onPress
}) => {
    const { styles, theme } = useStyles(stylesheet)

    return (
        <Touchable onPress={onPress}>
            <View style={styles.container}>
                <Typography.Regular
                    bold
                    forceColor={theme.colors.orange}
                >
                    {text}
                </Typography.Regular>
                <View
                    style={{
                        ...styles.chevron,
                        transform: [
                            {
                                rotateZ: '-90deg'
                            }
                        ]
                    }}
                >
                    <Icons.Chevron
                        size={10}
                        forceColor={theme.colors.orange}
                    />
                </View>
            </View>
        </Touchable>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        width: {
            lg: 190,
            xs: undefined
        },
        borderRadius: 8,
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'center',
        backgroundColor: theme.colors.yellow,
        paddingVertical: {
            lg: theme.utils.gap(1),
            xs: theme.utils.gap(3) / 2
        },
        marginHorizontal: {
            lg: 0,
            xs: -theme.utils.gap(1)
        },
        marginBottom: {
            lg: 0,
            xs: -theme.utils.gap(1)
        },
        borderTopLeftRadius: {
            lg: 8,
            xs: 0
        },
        borderTopRightRadius: {
            lg: 8,
            xs: 0
        }
    },
    chevron: {
        marginLeft: theme.utils.gap(1)
    }
}))
