import React from 'react'
import { View } from 'react-native'

type ListBottomSpacerProps = {
    height?: number
}

export const ListBottomSpacer: React.FunctionComponent<ListBottomSpacerProps> = ({
    height = 20
}) => (
    <View
        style={{
            height
        }}
    />
)
