import { useNavigation } from '@react-navigation/native'
import { ScreenNames } from 'lib/routing'
import { APP_CONFIG, ENV } from 'lib/config'
import { isNative } from 'lib/common'
import { SupplyID } from 'lib/types'
import { Icons, Images } from 'assets'
import { linkingHelpers } from 'lib/utils'
import { Segment, ServiceType } from 'lib/analytics'
import { useTranslations } from 'lib/hooks'
import { useResetFormAtom, useUserAtom } from 'lib/atoms'
import { SendhelperService } from '../types'

const SERVICES_LIST = [
    ServiceType.Cleaning,
    ServiceType.DeepCleaning,
    ServiceType.AirCondition,
    ServiceType.Handyman,
    ServiceType.Laundry,
    ServiceType.MoversAndPackers,
    ServiceType.PestControl,
    ServiceType.TaskErrands,
    ServiceType.Cooking
]

export const useAllServices = (): Array<SendhelperService> => {
    const T = useTranslations()
    const navigation = useNavigation()
    const [user] = useUserAtom()
    const [firstAddress] = user.locations
    const hasSingleAddress = user.locations.length === 1
    const [, setResetForm] = useResetFormAtom()

    const sortByServiceTypes = services => services.sort((firstService, secondService) => {
        const indexOfFirstService = SERVICES_LIST.indexOf(firstService.serviceType)
        const indexOfSecondService = SERVICES_LIST.indexOf(secondService.serviceType)

        return indexOfFirstService - indexOfSecondService
    })

    const services = [
        {
            testID: T.accessibility.serviceTiles.cleaning,
            serviceType: ServiceType.Cleaning,
            isAvailable: true,
            name: T.screens.allServices.services.cleaning.title,
            description: T.screens.allServices.services.cleaning.description,
            image: Images.Cleaning,
            icon: Icons.Cleaning,
            onPressNative: (postalcode: string) => {
                Segment.serviceClicked({
                    serviceType: ServiceType.Cleaning
                })

                navigation.navigate(ScreenNames.Cleaning, {
                    postalcode,
                    service: SupplyID.Cleaner.toString()
                })
            },
            onPress: () => {
                setResetForm(true)
                Segment.serviceClicked({
                    serviceType: ServiceType.Cleaning
                })

                hasSingleAddress
                    ? navigation.navigate(ScreenNames.Cleaning, {
                        postalcode: firstAddress.postcode,
                        service: SupplyID.Cleaner.toString()
                    })
                    : navigation.navigate(ScreenNames.AllServicesPostcode, {
                        supplyId: SupplyID.Cleaner
                    })
            },
            onLearnMore: () => linkingHelpers.openUrl(`${ENV.SENDHELPER_MARKETING_SITE_URL}/service/cleaning`, false)
        },
        {
            testID: T.accessibility.serviceTiles.deepCleaning,
            serviceType: ServiceType.DeepCleaning,
            isAvailable: true,
            name: T.screens.allServices.services.deepCleaning.title,
            description: T.screens.allServices.services.deepCleaning.description,
            image: Images.DeepCleaning,
            icon: Icons.DeepCleaning,
            onPressNative: postalcode => {
                Segment.serviceClicked({
                    serviceType: ServiceType.DeepCleaning
                })

                navigation.navigate(ScreenNames.DeepCleaning, {
                    postalcode,
                    service: SupplyID.DeepCleaning.toString()
                })
            },
            onPress: () => {
                setResetForm(true)
                Segment.serviceClicked({
                    serviceType: ServiceType.DeepCleaning
                })

                hasSingleAddress
                    ? navigation.navigate(ScreenNames.DeepCleaning, {
                        postalcode: firstAddress.postcode,
                        service: SupplyID.DeepCleaning.toString()
                    })
                    : navigation.navigate(ScreenNames.AllServicesPostcode, {
                        supplyId: SupplyID.DeepCleaning
                    })
            },
            onLearnMore: () => linkingHelpers.openUrl(`${ENV.SENDHELPER_MARKETING_SITE_URL}/service/deep-cleaning`, false)
        },
        {
            testID: T.accessibility.serviceTiles.airCondition,
            serviceType: ServiceType.AirCondition,
            isAvailable: true,
            name: T.screens.allServices.services.airCondition.title,
            description: T.screens.allServices.services.airCondition.description,
            image: Images.AirCondition,
            icon: Icons.AirCondition,
            onPressNative: postalcode => {
                Segment.serviceClicked({
                    serviceType: ServiceType.AirCondition
                })

                navigation.navigate(ScreenNames.AirCondition, {
                    postalcode,
                    service: SupplyID.ACService.toString()
                })
            },
            onPress: () => {
                setResetForm(true)
                Segment.serviceClicked({
                    serviceType: ServiceType.AirCondition
                })

                hasSingleAddress
                    ? navigation.navigate(ScreenNames.AirCondition, {
                        postalcode: firstAddress.postcode,
                        service: SupplyID.ACService.toString()
                    })
                    : navigation.navigate(ScreenNames.AllServicesPostcode, {
                        supplyId: SupplyID.ACService
                    })
            },
            onLearnMore: () => linkingHelpers.openUrl(`${ENV.SENDHELPER_MARKETING_SITE_URL}/service/air-condition`, false)
        },
        {
            testID: T.accessibility.serviceTiles.handyman,
            serviceType: ServiceType.Handyman,
            isAvailable: APP_CONFIG.SERVICES.IS_HANDYMAN_ENABLED,
            name: T.screens.allServices.services.handyman.title,
            description: T.screens.allServices.services.handyman.description,
            image: Images.Handyman,
            icon: Icons.Handyman,
            onLearnMore: () => linkingHelpers.openUrl(`${ENV.SENDHELPER_MARKETING_SITE_URL}/service/handyman`, false),
            onPress: () => {
                setResetForm(true)
                Segment.serviceClicked({
                    serviceType: ServiceType.Handyman
                })

                hasSingleAddress
                    ? navigation.navigate(ScreenNames.Handyman, {
                        postalcode: firstAddress.postcode,
                        service: SupplyID.Handyman.toString()
                    })
                    : navigation.navigate(ScreenNames.AllServicesPostcode, {
                        supplyId: SupplyID.Handyman
                    })
            },
            onPressNative: postalcode => {
                Segment.serviceClicked({
                    serviceType: ServiceType.Handyman
                })

                navigation.navigate(ScreenNames.Handyman, {
                    postalcode,
                    service: SupplyID.Handyman.toString()
                })
            }
        },
        {
            testID: T.accessibility.serviceTiles.laundry,
            serviceType: ServiceType.Laundry,
            isAvailable: APP_CONFIG.SERVICES.IS_LAUNDRY_ENABLED,
            name: T.screens.allServices.services.laundry.title,
            description: T.screens.allServices.services.laundry.description,
            image: Images.Laundry,
            icon: Icons.Laundry,
            onLearnMore: () => linkingHelpers.openUrl(`${ENV.SENDHELPER_MARKETING_SITE_URL}/service/laundry-singapore`, false),
            onPress: () => {
                setResetForm(true)
                Segment.serviceClicked({
                    serviceType: ServiceType.Laundry
                })

                hasSingleAddress
                    ? navigation.navigate(ScreenNames.Laundry, {
                        postalcode: firstAddress.postcode,
                        service: SupplyID.Laundry.toString()
                    })
                    : navigation.navigate(ScreenNames.AllServicesPostcode, {
                        supplyId: SupplyID.Laundry
                    })
            },
            onPressNative: postalcode => {
                Segment.serviceClicked({
                    serviceType: ServiceType.Laundry
                })

                navigation.navigate(ScreenNames.Laundry, {
                    postalcode,
                    service: SupplyID.Laundry.toString()
                })
            }
        },
        {
            testID: T.accessibility.serviceTiles.cooking,
            serviceType: ServiceType.Cooking,
            isAvailable: APP_CONFIG.SERVICES.IS_COOKING_ENABLED,
            name: T.screens.allServices.services.cooking.title,
            description: T.screens.allServices.services.cooking.description,
            image: Images.Cooking,
            icon: Icons.Cooking,
            onLearnMore: () => linkingHelpers.openUrl(`${ENV.SENDHELPER_MARKETING_SITE_URL}/service/cooking`, false),
            onPress: () => {
                setResetForm(true)
                Segment.serviceClicked({
                    serviceType: ServiceType.Cooking
                })

                hasSingleAddress
                    ? navigation.navigate(ScreenNames.Cooking, {
                        postalcode: firstAddress.postcode,
                        service: SupplyID.Cooking.toString()
                    })
                    : navigation.navigate(ScreenNames.AllServicesPostcode, {
                        supplyId: SupplyID.Cooking
                    })
            },
            onPressNative: postalcode => {
                Segment.serviceClicked({
                    serviceType: ServiceType.Cooking
                })

                navigation.navigate(ScreenNames.Cooking, {
                    postalcode,
                    service: SupplyID.Cooking.toString()
                })
            }
        },
        {
            testID: T.accessibility.serviceTiles.pestControl,
            serviceType: ServiceType.PestControl,
            isAvailable: APP_CONFIG.SERVICES.IS_PEST_CONTROL_ENABLED,
            name: T.screens.allServices.services.pestControl.title,
            description: T.screens.allServices.services.pestControl.description,
            image: Images.PestControl,
            icon: Icons.PestControl,
            onLearnMore: () => linkingHelpers.openUrl(`${ENV.SENDHELPER_MARKETING_SITE_URL}/service/pest-control`, false),
            onPress: () => {
                setResetForm(true)
                Segment.serviceClicked({
                    serviceType: ServiceType.PestControl
                })

                hasSingleAddress
                    ? navigation.navigate(ScreenNames.PestControl, {
                        postalcode: firstAddress.postcode,
                        service: SupplyID.PestControl.toString()
                    })
                    : navigation.navigate(ScreenNames.AllServicesPostcode, {
                        supplyId: SupplyID.PestControl
                    })
            },
            onPressNative: postalcode => {
                Segment.serviceClicked({
                    serviceType: ServiceType.PestControl
                })

                navigation.navigate(ScreenNames.PestControl, {
                    postalcode,
                    service: SupplyID.PestControl.toString()
                })
            }
        },
        {
            testID: T.accessibility.serviceTiles.taskAndErrands,
            serviceType: ServiceType.TaskErrands,
            isAvailable: APP_CONFIG.SERVICES.IS_TASK_ERRANDS_ENABLED,
            name: T.screens.allServices.services.taskAndErrands.title,
            description: T.screens.allServices.services.taskAndErrands.description,
            image: Images.TaskErrands,
            icon: Icons.TaskErrands,
            onLearnMore: () => linkingHelpers.openUrl(`${ENV.SENDHELPER_MARKETING_SITE_URL}/service/task-errands`, false),
            onPress: () => {
                setResetForm(true)
                Segment.serviceClicked({
                    serviceType: ServiceType.TaskErrands
                })

                hasSingleAddress
                    ? navigation.navigate(ScreenNames.TaskAndErrands, {
                        postalcode: firstAddress.postcode,
                        service: SupplyID.TasksErrands.toString()
                    })
                    : navigation.navigate(ScreenNames.AllServicesPostcode, {
                        supplyId: SupplyID.TasksErrands
                    })
            },
            onPressNative: postalcode => {
                Segment.serviceClicked({
                    serviceType: ServiceType.TaskErrands
                })

                navigation.navigate(ScreenNames.TaskAndErrands, {
                    postalcode,
                    service: SupplyID.TasksErrands.toString()
                })
            }
        },
        {
            testID: T.accessibility.serviceTiles.moversAndPackers,
            serviceType: ServiceType.MoversAndPackers,
            isNew: true,
            isAvailable: APP_CONFIG.SERVICES.IS_MOVERS_AND_PACKERS_ENABLED,
            name: T.screens.allServices.services.moversAndPackers.title,
            description: T.screens.allServices.services.moversAndPackers.description,
            image: Images.MoversPackers,
            icon: Icons.MoversAndPackers,
            onLearnMore: () => linkingHelpers.openUrl(`${ENV.SENDHELPER_MARKETING_SITE_URL}/service/movers-packers`, false),
            onPress: () => {
                Segment.serviceClicked({
                    serviceType: ServiceType.MoversAndPackers
                })
                hasSingleAddress
                    ? navigation.navigate(ScreenNames.MoversAndPackers, {
                        postalcode: firstAddress.postcode,
                        service: SupplyID.MoversPackers.toString()
                    })
                    : navigation.navigate(ScreenNames.AllServicesPostcode, {
                        supplyId: SupplyID.MoversPackers
                    })
            },
            onPressNative: postalcode => {
                Segment.serviceClicked({
                    serviceType: ServiceType.MoversAndPackers
                })

                navigation.navigate(ScreenNames.MoversAndPackers, {
                    postalcode,
                    service: SupplyID.MoversPackers.toString()
                })
            }
        }
    ]

    return isNative ? sortByServiceTypes(services) : services
}
