import React from 'react'
import { View } from 'react-native'
import { createStyles } from 'lib/styles'
import { Typography } from 'lib/components'
import { useStyles, useTranslations } from 'lib/hooks'
import { JobDetailsResponse } from '../../types'

type HandymanPriceDetailsProps = {
    job: JobDetailsResponse
}

export const HandymanPriceDetails: React.FunctionComponent<HandymanPriceDetailsProps> = ({ job }) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const { job: { promotion } } = job

    return (
        <View style={styles.wrapper}>
            <View style={styles.flex}>
                <View style={styles.title}>
                    <Typography.Label forceColor={theme.colors.mouse}>
                        {T.screens.jobDetails.sections.promotionCode}
                    </Typography.Label>
                </View>
                <Typography.Label>
                    {promotion?.code ?? '-'}
                </Typography.Label>
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        flexDirection: 'row',
        marginBottom: theme.utils.gap(3)
    },
    title: {
        marginBottom: theme.utils.gap(1)
    },
    flex: {
        flex: 1
    }
}))
