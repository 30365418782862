import React, { useEffect, useState } from 'react'
import { useNavigation } from '@react-navigation/native'
import { NavigationParams, NavigationProps, ScreenNames } from 'lib/routing'
import { MediaQuery } from 'lib/components'
import { Breakpoint } from 'lib/styles'
import { ExtraCancellationReasonId } from 'features/bookings'
import { CancelJobContent, CancelJobLayout } from '../components'
import { getJobDetails } from '../actions'
import { TypeOfCancelJob } from '../types'
import { CancelJobActions } from '../components/cancelJobContent'
import { INIT_CANCEL_SUBSCRIPTION_OPTION } from '../constants/CancelPackageOptions'

type CancelJobScreenProps = {
    navigation: NavigationProps<ScreenNames.CancelJob>,
    route: NavigationParams<ScreenNames.CancelJob>
}

export const CancelJobScreen: React.FunctionComponent<CancelJobScreenProps> = ({ route }) => {
    const navigation = useNavigation()
    const [upComingSessionId, setUpComingSessionId] = useState<string>('')
    const { isLoading: isRefetchingJob, data: response } = getJobDetails(route.params.jobId)
    const { isLoading: isRefetchingUpcomingSession, data: upcomingSessionDetail } = getJobDetails(upComingSessionId || route.params.jobId)
    const [cancellationReasonId, setCancellationReasonId] = useState(ExtraCancellationReasonId.NonSelect)
    const [otherReason, setOtherReason] = useState('')
    const [cancelSubscriptionType, setCancelSubscriptionType] = useState<TypeOfCancelJob>(INIT_CANCEL_SUBSCRIPTION_OPTION)
    const jobDetail = response?.data
    const upcomingSession = upcomingSessionDetail?.data

    const resetState = () => {
        setCancellationReasonId(ExtraCancellationReasonId.NonSelect)
        setCancelSubscriptionType(INIT_CANCEL_SUBSCRIPTION_OPTION)
        setOtherReason('')
    }

    useEffect(() => {
        resetState()
    }, [])

    useEffect(() => setOtherReason(''), [cancellationReasonId])

    useEffect(() => {
        setUpComingSessionId(jobDetail?.sessionDetails?.upcomingSessions?.[0]?.bookingSessionId || '')
    }, [jobDetail])

    const handleGoBack = () => {
        resetState()

        if (navigation.canGoBack()) {
            return navigation.goBack()
        }

        navigation.navigate(ScreenNames.Bookings)
    }

    return (
        <CancelJobLayout
            handleGoBack={handleGoBack}
            isLoading={isRefetchingJob || isRefetchingUpcomingSession}
            jobDetail={jobDetail}
            upcomingSession={upcomingSession}
            footer={(
                <MediaQuery.Hidden from={Breakpoint.LG}>
                    {jobDetail && (
                        <CancelJobActions
                            showAsFloating
                            jobDetail={jobDetail}
                            upcomingSession={upcomingSession}
                            handleGoBack={handleGoBack}
                            cancellationReasonId={cancellationReasonId}
                            otherReason={otherReason}
                            resetState={resetState}
                            cancelSubscriptionType={cancelSubscriptionType}
                        />
                    )}
                </MediaQuery.Hidden>
            )}>

            { jobDetail && (
                <CancelJobContent
                    jobDetail={jobDetail}
                    upcomingSession={upcomingSession}
                    handleGoBack={handleGoBack}
                    cancellationReasonId={cancellationReasonId}
                    setCancellationReasonId={setCancellationReasonId}
                    otherReason={otherReason}
                    cancelSubscriptionType={cancelSubscriptionType}
                    setCancelSubscriptionType={setCancelSubscriptionType}
                    setOtherReason={setOtherReason}
                    resetState={resetState}
                />
            )}
        </CancelJobLayout>
    )
}
