import React from 'react'
import { View } from 'react-native'
import { Field } from '@codegateinc/react-form-builder-v2'
import { useIsAuthorizedAtom, useUserAtom } from 'lib/atoms'
import { Address, MediaQuery, PolicyLinks } from 'lib/components'
import { useTranslations } from 'lib/hooks'
import { PaymentType } from 'lib/models'
import { Breakpoint } from 'lib/styles'
import { Nullable, VoidFunction } from 'lib/types'
import { PestControlAddonsFormShape, PestControlFormShape } from '../../forms'
import { GetPromotionResponse, JobQuotationResponse } from '../../types'
import { BookingCancellationFees } from '../BookingCancellationFees'
import { MobileSummary } from '../MobileSummary'
import { PaymentMethodTile } from '../PaymentMethodTile'
import { PromotionCodeInput } from '../PromotionCodeInput'
import { Rated } from '../Rated'
import { VisitCharges } from '../VisitCharges'
import { PestControlSummaryColumn } from './PestControlSummaryColumn'
import { TotalPriceComponent } from '../TotalPriceComponent'
import { PriceSummaryComponent } from '../PriceSummaryComponent'

type PestControlSummaryFooterProps = {
    disabled: boolean,
    selectedPaymentMethod: PaymentType,
    setPaymentView: (value: boolean) => void,
    form: Record<keyof PestControlAddonsFormShape, Field<boolean & string>>
    formShape: PestControlFormShape,
    isLoading: boolean,
    totalPrice: number,
    originalPrice?: Nullable<number>,
    authenticationView: boolean,
    jobQuotation?: JobQuotationResponse,
    submit: VoidFunction,
    promotionPackage: Nullable<GetPromotionResponse>
    requestPromotion: (code: string) => void,
    onRemovePromoCode: VoidFunction
}

export const PestControlSummaryFooter: React.FunctionComponent<PestControlSummaryFooterProps> = ({
    form,
    disabled,
    formShape,
    totalPrice,
    originalPrice,
    requestPromotion,
    setPaymentView,
    promotionPackage,
    isLoading,
    authenticationView,
    submit,
    selectedPaymentMethod,
    onRemovePromoCode,
    jobQuotation
}) => {
    const T = useTranslations()
    const [user] = useUserAtom()
    const [isAuthorized] = useIsAuthorizedAtom()
    const notEnoughCredits = selectedPaymentMethod === PaymentType.Credits && user.consumer.balance_credit < totalPrice

    return (
        <MediaQuery.Hidden from={Breakpoint.LG}>
            <MobileSummary
                authenticationView={authenticationView && !isAuthorized}
                submit={submit}
                submitText={T.common.confirm}
                priceLabel={T.common.totalPrice}
                price={totalPrice}
                onRemovePromoCode={onRemovePromoCode}
                priceBeforeDiscount={originalPrice}
                promoCode={form.promoCode}
                promotionPackage={promotionPackage}
                requestPromotion={requestPromotion}
                isLoading={isLoading}
                disabled={notEnoughCredits || disabled}
                content={(
                    <React.Fragment>
                        <View>
                            <Address disabled={isLoading} />
                        </View>
                        <PestControlSummaryColumn
                            totalPrice={totalPrice}
                            originalPrice={originalPrice}
                            promotionPackage={promotionPackage}
                            renderPriceComponent={() => (
                                <TotalPriceComponent
                                    totalPrice={jobQuotation?.total?.value || 0}
                                />
                            )}
                            renderPriceSummaryComponent={() => jobQuotation?.priceSummary ? (
                                <PriceSummaryComponent priceSummary={jobQuotation?.priceSummary} promotion={jobQuotation.discount} />
                            ) : undefined}
                            form={formShape}
                            couponText={promotionPackage
                                ? promotionPackage.promotion.message_to_client
                                : undefined
                            }
                            renderExtraContent={() => (
                                <View>
                                    <VisitCharges />
                                    {/* remove this check after guest booking experiment  */}
                                    {!isAuthorized && (
                                        <PromotionCodeInput
                                            promoCode={form.promoCode}
                                            isLoading={isLoading}
                                            promotionPackage={promotionPackage}
                                            onRemovePromoCode={onRemovePromoCode}
                                            requestPromotion={requestPromotion}
                                        />
                                    )}
                                </View>
                            )}
                        />
                        {!authenticationView && (
                            <PaymentMethodTile
                                notEnoughCredits={notEnoughCredits}
                                onPress={() => setPaymentView(true)}
                                selectedPaymentMethod={selectedPaymentMethod}
                            />
                        )}
                        <PolicyLinks />
                        <Rated />
                        <BookingCancellationFees />
                    </React.Fragment>
                )}
            />
        </MediaQuery.Hidden>
    )
}
