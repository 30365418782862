import React, { useState } from 'react'
import { View } from 'react-native'
import { Icons } from 'assets'
import { Expert } from 'lib/models'
import { createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { Touchable, Typography } from 'lib/components'
import { ExpertTile } from './ExpertTile'

type ExpertsListListProps = {
    experts: Array<Expert>,
    isFavourite?: boolean
}

export const ExpertsList: React.FunctionComponent<ExpertsListListProps> = ({
    isFavourite,
    experts
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const [viewMore, setViewMore] = useState(false)

    return (
        <View style={styles.container}>
            {experts
                .slice(0, viewMore ? experts.length : 4)
                .map(expert => (
                    <ExpertTile
                        isFavourite={isFavourite}
                        expert={expert}
                        key={expert._id}
                    />
                ))
            }
            {experts.length > 4 && (
                <Touchable
                    style={styles.moreButton}
                    onPress={() => setViewMore(prevValue => !prevValue)}
                >
                    <Typography.Regular
                        bold
                        style={styles.moreText}
                        forceColor={theme.colors.orange}
                    >
                        {
                            viewMore
                                ? T.common.seeLess
                                : T.common.seeMore
                        }
                    </Typography.Regular>
                    <Icons.Arrow
                        size={12}
                        forceColor={theme.colors.orange}
                    />
                </Touchable>
            )}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    moreButton: {
        width: {
            lg: '20%',
            ':w[1100, 1300]': '25%',
            ':w[, 1100]': '50%'
        },
        height: 160,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    moreText: {
        marginRight: theme.utils.gap(1)
    }
}))
