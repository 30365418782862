import React from 'react'
import { Expert } from 'lib/models'
import { useTranslations } from 'lib/hooks'
import { SectionBox } from 'lib/components'
import { ExpertsList } from './ExpertsList'
import { ExpertsTitle } from './ExpertsTitle'

type FavouriteExpertsProps = {
    experts: Array<Expert>
}

export const FavouriteExperts: React.FunctionComponent<FavouriteExpertsProps> = ({ experts }) => {
    const T = useTranslations()

    return (
        <SectionBox>
            <ExpertsTitle title={T.screens.favourite.section.myFavouriteExperts} />
            <ExpertsList
                isFavourite
                experts={experts}
            />
        </SectionBox>
    )
}

