import { User } from 'lib/models'

export enum RequestType {
    Cancel = 'cancel'
}

export type ContactUsRequest = {
    contents: string,
    token: string,
    profile: User,
    requestType?: RequestType | string,
    jobId?: string
}
