import { R } from 'lib/utils'
import { PromotionPopupForConsumer, FrequencyTimes } from '../types'

export const calculateNextPromotionPopupDisplayTime = (frequency: number = FrequencyTimes.MinimumCachingTime, promotionPopupShownAt: number): Date => {
    const nextShowTime = new Date(promotionPopupShownAt)
    nextShowTime.setHours(nextShowTime.getHours() + frequency)

    return nextShowTime
}

export const shouldShowPromotionPopup = (promotionPopup: PromotionPopupForConsumer, promotionPopupShownAt: number | undefined) => {
    if (!R.isDefined(promotionPopupShownAt)) {
        return false
    }

    if (!promotionPopup?.active || !promotionPopup?.image) {
        return false
    }

    if (!promotionPopupShownAt) {
        return true
    }

    return calculateNextPromotionPopupDisplayTime(promotionPopup?.frequency, promotionPopupShownAt) <= new Date()
}

export const isPromotionPopupRequestNeeded = (promotionPopupShownAt: number | undefined) => {
    if (!R.isDefined(promotionPopupShownAt)) {
        return false
    }

    if(!promotionPopupShownAt) {
        return true
    }

    return calculateNextPromotionPopupDisplayTime(FrequencyTimes.MinimumCachingTime, promotionPopupShownAt) <= new Date()
}

export const calculateImageSize = (imageSize: string): [number, number] => {
    const maxWidth = 300

    const [imageWidth = maxWidth, imageHeight = maxWidth] = imageSize.split('x').map(Number)
    const aspectRatio = imageWidth / imageHeight

    return imageWidth > maxWidth
        ? [maxWidth, maxWidth / aspectRatio]
        : [imageWidth, imageHeight]
}
