import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import Animated, { runOnJS, useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated'
import { useQueryHelpers, useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { dateHelpers } from 'lib/utils'
import { BasicModal, Button, Grid, LoadingIndicator, Typography } from 'lib/components'
import { Nullable } from 'lib/types'
import { Notification } from 'lib/models'
import { isNative } from 'lib/common'
import { Icons } from 'assets'
import { useGetNotificationDetails } from '../actions'

type NotificationModalProps = {
    notification: Nullable<Notification>,
    onClose: VoidFunction,
    onReadNotification: VoidFunction
}

export const NotificationModal: React.FunctionComponent<NotificationModalProps> = ({
    onClose,
    notification,
    onReadNotification
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const [message, setMessage] = useState<string>()
    const { onRequestError } = useQueryHelpers()
    const animatedOpacity = useSharedValue(0)
    const { mutate: getNotificationDetails, isLoading } = useGetNotificationDetails()
    const cleanup = () => {
        animatedOpacity.value = withTiming(0, undefined, isFinished => {
            if (isFinished) {
                runOnJS(onClose)()
                runOnJS(setMessage)(undefined)
            }
        })
    }
    const animatedContainerStyles = useAnimatedStyle(() => ({
        opacity: animatedOpacity.value
    }))

    useEffect(() => {
        if (notification) {
            animatedOpacity.value = withTiming(1)

            getNotificationDetails({
                messageId: notification.id
            }, {
                onSuccess: ({ data }) => {
                    setMessage(data.message.body)
                    onReadNotification()
                },
                onError: error => {
                    onRequestError(error)
                    cleanup()
                }
            })
        }
    }, [notification])

    return (
        <BasicModal
            animationType="none"
            isOpen={Boolean(notification)}
            onClose={onClose}
            scrollViewContainerStyles={styles.modalContainer}
        >
            <Animated.View
                style={[
                    isNative
                        ? {}
                        : styles.container,
                    animatedContainerStyles
                ]}
            >
                <Typography.Regular
                    bold
                    style={styles.title}
                >
                    {T.screens.notifications.notificationDetails.title}
                </Typography.Regular>
                <View style={styles.notificationBody}>
                    <View style={styles.iconContainer}>
                        <Icons.Notification
                            size={24}
                            forceColor={theme.colors.orange}
                        />
                    </View>
                    <Typography.Regular bold>
                        {notification?.title}
                    </Typography.Regular>
                    <Grid.Gap
                        gapTop={1}
                        gapBottom={3}
                    >
                        <Typography.Regular forceColor={theme.colors.softBlueGrey}>
                            {notification?.date && dateHelpers.notificationFullDate(notification.date)}
                        </Typography.Regular>
                    </Grid.Gap>
                    <View style={styles.messageContainer}>
                        <LoadingIndicator isLoading={isLoading} />
                        {!isLoading && message && (
                            <Typography.Regular
                                style={styles.message}
                                forceColor={theme.colors.softBlueGrey}
                            >
                                {message}
                            </Typography.Regular>
                        )}
                    </View>
                    <Grid.Gap
                        gapTop={3}
                        gapLeft={4}
                        gapRight={4}
                        style={styles.buttonContainer}
                    >
                        <Button
                            onPress={cleanup}
                            text={T.screens.notifications.notificationDetails.ok.toUpperCase()}
                        />
                    </Grid.Gap>
                </View>
            </Animated.View>
        </BasicModal>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        width: {
            lg: 420,
            xs: 300
        },
        flexDirection: 'column',
        paddingHorizontal: {
            lg: theme.utils.gap(3),
            xs: 0
        }
    },
    title: {
        flex: 1,
        textAlign: 'center'
    },
    notificationBody: {
        alignItems: 'center',
        justifyContent: 'center'
    },
    iconContainer: {
        height: 56,
        width: 56,
        borderRadius: 28,
        backgroundColor: theme.colors.latte,
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: theme.utils.gap(5),
        marginBottom: theme.utils.gap(3)
    },
    modalContainer: {
        flex: isNative ? undefined : 1,
        justifyContent: 'center'
    },
    messageContainer: {
        minHeight: 60
    },
    message: {
        textAlign: 'center'
    },
    buttonContainer: {
        width: '100%'
    }
}))
