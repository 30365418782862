export enum CreditCardFields {
    NameOnCard = 'nameOnCard',
    CardNumber = 'cardNumber',
    Month = 'month',
    Year = 'year',
    CVV = 'cvv'
}

export type CreditCardShape = {
    [CreditCardFields.NameOnCard]: string,
    [CreditCardFields.CardNumber]: string,
    [CreditCardFields.Month]: string,
    [CreditCardFields.Year]: string,
    [CreditCardFields.CVV]: string
}
