import { useTranslations } from 'lib/hooks'

export const useHandymanServicesParts = () => {
    const T = useTranslations()

    return {
        [T.components.handymanServices.general.items.door]: [
            T.components.handymanParts.doorLock,
            T.components.handymanParts.doorKnob,
            T.components.handymanParts.doorPanel,
            T.components.handymanParts.doorHinge,
            T.components.handymanParts.others
        ],
        [T.components.handymanServices.general.items.curtain]: [
            T.components.handymanParts.curtain,
            T.components.handymanParts.curtainRailing,
            T.components.handymanParts.blinds,
            T.components.handymanParts.others
        ],
        [T.components.handymanServices.general.items.furniture]: [
            T.components.handymanParts.cabinet,
            T.components.handymanParts.diningTable,
            T.components.handymanParts.sofa,
            T.components.handymanParts.studyTable,
            T.components.handymanParts.shelves,
            T.components.handymanParts.others
        ],
        [T.components.handymanServices.general.items.tvBracket]: [
            T.components.handymanParts.tv,
            T.components.handymanParts.tvBracket,
            T.components.handymanParts.others
        ],
        [T.components.handymanServices.electrical.items.ceilingFan]: [
            T.components.handymanParts.wall,
            T.components.handymanParts.ceiling,
            T.components.handymanParts.others
        ],
        [T.components.handymanServices.painting.items.wall]: [
            T.components.handymanParts.interior,
            T.components.handymanParts.exterior,
            T.components.handymanParts.others
        ],
        [T.components.handymanServices.plumbing.items.sink]: [
            T.components.handymanParts.sink,
            T.components.handymanParts.sinkPipe,
            T.components.handymanParts.sinkTrap,
            T.components.handymanParts.others
        ]
    }
}
