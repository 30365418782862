import React  from 'react'
import { ImageBackground, ScrollView, View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { Images } from 'assets'
import { createStyles } from 'lib/styles'
import { Button, Grid, Header, Typography } from 'lib/components'
import { useStyles, useTranslations } from 'lib/hooks'
import { ScreenNames } from 'lib/routing'
import { Segment } from 'lib/analytics'

export const Page404Screen: React.FunctionComponent = () => {
    const T = useTranslations()
    const navigation = useNavigation()
    const { styles, theme } = useStyles(stylesheet)

    return (
        <Grid.Background>
            <Header />
            <ScrollView showsVerticalScrollIndicator={false}>
                <ImageBackground
                    style={styles.imageContainer}
                    source={Images.Page404}
                >
                    <View style={styles.message}>
                        <Typography.Heading forceColor={theme.colors.white}>
                            {T.screens.Page404.title}
                        </Typography.Heading>
                    </View>
                </ImageBackground>
                <View style={styles.content}>
                    <Typography.Subheading>
                        {T.screens.Page404.message}
                    </Typography.Subheading>
                    <View style={styles.buttons}>
                        <View style={styles.buttonWrapper}>
                            <Button
                                noBackground
                                onPress={() => navigation.navigate(ScreenNames.Bookings)}
                                text={T.screens.Page404.myBookings}
                            />
                        </View>
                        <View style={styles.buttonWrapper}>
                            <Button
                                onPress={() => {
                                    Segment.bookServiceClicked({})
                                    navigation.navigate(ScreenNames.AllServices)
                                }}
                                text={T.screens.Page404.bookAService}
                            />
                        </View>
                    </View>
                </View>
            </ScrollView>
        </Grid.Background>
    )
}

const stylesheet = createStyles(theme => ({
    imageContainer: {
        alignItems: 'center',
        paddingVertical: {
            lg: theme.utils.gap(20),
            xs: theme.utils.gap(10)
        },
        marginBottom: theme.utils.gap(8)
    },
    content: {
        alignItems: 'center'
    },
    buttons: {
        marginTop: theme.utils.gap(3),
        flexDirection: {
            lg: 'row',
            xs: 'column'
        }
    },
    buttonWrapper: {
        width: {
            lg: 220,
            xs: 300
        },
        marginHorizontal: {
            lg: theme.utils.gap(1),
            xs: undefined
        },
        marginBottom: theme.utils.gap(2)
    },
    message: {
        textAlign: 'center',
        paddingHorizontal: theme.utils.gap(1)
    }
}))
