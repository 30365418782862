import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const Cooking: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path d="M55.737 64.818a8.138 8.138 0 0 1-5.765-2.4l-13.477-13.47 3.539-3.539L53.51 58.88a3.146 3.146 0 0 0 3.894.453 20.266 20.266 0 0 0 6.327-6.208l.216-.335a3.617 3.617 0 0 0-.48-4.494l-6.062-6.066 3.539-3.539 6.062 6.062A8.65 8.65 0 0 1 68.152 55.5l-.216.335a25.3 25.3 0 0 1-7.9 7.75 8.16 8.16 0 0 1-4.299 1.233Z" />
        <Path d="M19.798 22.703a2.5 2.5 0 0 1-1.77-.733l-4.159-4.159a10.556 10.556 0 0 0-1.252-1.07l-3.465-2.52A7.87 7.87 0 1 1 20.443 3.65l2.143 3.367a10.18 10.18 0 0 0 .977 1.294l4.209 4.735a2.503 2.503 0 1 1-3.741 3.325l-4.209-4.735a15.2 15.2 0 0 1-1.458-1.932l-2.143-3.367a2.869 2.869 0 1 0-4.125 3.836l3.465 2.52a15.577 15.577 0 0 1 1.848 1.579l4.159 4.159a2.5 2.5 0 0 1-1.77 4.272Z" />
        <Path d="M72.128 76.348a7.852 7.852 0 0 1-6.656-3.649l-2.144-3.368a10.187 10.187 0 0 0-.977-1.294l-4.209-4.735a2.503 2.503 0 0 1 3.741-3.325l4.209 4.735a15.212 15.212 0 0 1 1.458 1.932l2.143 3.367a2.877 2.877 0 0 0 5.293-1.306 2.838 2.838 0 0 0-1.168-2.53l-3.464-2.517a15.612 15.612 0 0 1-1.846-1.577l-4.16-4.161a2.502 2.502 0 1 1 3.539-3.539l4.159 4.16a10.587 10.587 0 0 0 1.252 1.069l3.466 2.521a7.862 7.862 0 0 1-4.636 14.217Z" />
        <Path d="M38.586 40.906a8.22 8.22 0 0 1 0 11.612L17.417 73.687A8.211 8.211 0 1 1 5.805 62.075l21.169-21.169a8.22 8.22 0 0 1 11.612 0ZM9.346 70.147a3.209 3.209 0 0 0 4.533 0l21.168-21.168a3.208 3.208 0 0 0-4.538-4.537L9.341 65.61a3.209 3.209 0 0 0 .004 4.537Z" />
        <Path d="M43.004 48.254c-3.645 0-6.507-1.014-8.506-3.012-3.634-3.639-3.981-10.301-.977-18.771 2.403-6.778 6.748-13.949 11.07-18.268A25.155 25.155 0 0 1 62.047.515a17.209 17.209 0 0 1 12.316 4.862c7.43 7.43 6.162 20.787-2.826 29.774-6.327 6.328-19.303 13.103-28.533 13.103ZM62.047 5.52a20.132 20.132 0 0 0-13.92 6.221c-3.834 3.834-7.716 10.272-9.89 16.4-2.255 6.361-2.33 11.429-.2 13.559 1.026 1.026 2.7 1.546 4.967 1.546 7.872 0 19.491-6.133 24.994-11.637 7.036-7.036 8.3-17.218 2.826-22.7a12.085 12.085 0 0 0-8.777-3.389Z" />
        <Path d="M37.651 46.432a4.3 4.3 0 0 1-3.152-1.193c-2.391-2.391-1.2-6.933 3.848-14.729a105.964 105.964 0 0 1 12.7-15.852c6.645-6.645 14.065-10.939 18.9-10.939a6 6 0 0 1 4.412 1.656c1.558 1.558 2.814 4.7-.05 10.8a46.919 46.919 0 0 1-9.229 12.518c-6.367 6.367-20.979 17.739-27.429 17.739Zm32.3-37.708c-2.929 0-9.147 3.254-15.366 9.473A100.432 100.432 0 0 0 43.38 31.978c-3.392 5.026-4.613 7.974-4.957 9.353 4.357-.98 15.99-9.046 23.121-16.177a41.874 41.874 0 0 0 8.236-11.105c1.638-3.489 1.238-4.937 1.041-5.133a1.406 1.406 0 0 0-.869-.19Z" />
        <Path d="M16.527 23.346a8.14 8.14 0 0 0 2.098 5.884l12.756 14.16 3.72-3.352-12.757-14.16a3.147 3.147 0 0 1-.25-3.908 20.273 20.273 0 0 1 6.53-5.998l.346-.198a3.618 3.618 0 0 1 4.465.713l5.74 6.371 3.72-3.351-5.741-6.371a8.653 8.653 0 0 0-10.676-1.709l-.346.198a25.309 25.309 0 0 0-8.153 7.49 8.163 8.163 0 0 0-1.452 4.231Z" />
    </Icon>
)
