import React from 'react'
import { View } from 'react-native'
import { usePrice, useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { Typography } from 'lib/components'
import { CustomFonts } from 'lib/types'
import { useFeatureFlagAtom } from 'lib/atoms'
import { TotalPriceComponent } from 'features/bookings/components'
import { JobQuotationResponse } from 'features/bookings/types'

type BookingTotalPriceProps = {
    jobQuotation?: JobQuotationResponse,
}

export const BookingTotalPrice: React.FunctionComponent<BookingTotalPriceProps> = ({
    jobQuotation
}) => {
    const T = useTranslations()
    const { theme, styles } = useStyles(stylesheet)
    const [featureFlagAtom] = useFeatureFlagAtom()
    const usePaymentBySessionFlow = featureFlagAtom?.paymentBySessionFlow || false
    const jobQuotationTotal = usePaymentBySessionFlow ? jobQuotation?.totalSubscription : jobQuotation?.total
    const jobQuotationData = jobQuotationTotal || jobQuotation?.total
    const {
        value = 0,
        valueWithoutSavings = undefined,
        valueBeforeDiscount = undefined,
        valueSavings = 0
    } = jobQuotationData || {}

    const originValue = valueWithoutSavings || valueBeforeDiscount || 0

    const formattedValue = usePrice(value, true)
    const formattedOriginValue = usePrice(originValue, true)
    const formattedValueSavings = usePrice(valueSavings, true)

    if (!jobQuotation) {
        return null
    }

    return valueSavings ? (
        <View style={styles.container}>
            <View>
                <Typography.Regular style={styles.totalLabel}>
                    {T.common.total}
                </Typography.Regular>
            </View>
            <View style={styles.informationContainer}>
                <View style={styles.savedTextContainer}>
                    <Typography.Regular style={styles.description}>
                        {T.common.youSaved} <Typography.Error bold forceColor={theme.colors.green}>{formattedValueSavings}</Typography.Error>
                    </Typography.Regular>
                </View>
                <View style={styles.priceContainer}>
                    <Typography.Regular style={styles.originalPrice}>
                        {formattedOriginValue}
                    </Typography.Regular>
                    <Typography.Regular style={styles.totalPrice}>
                        {formattedValue}
                    </Typography.Regular>
                </View>
            </View>
        </View>
    ): (
        <TotalPriceComponent
            totalPrice={value || 0}
            originalPrice={originValue}
        />
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        flexDirection: 'row',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
        flex: 1
    },
    totalLabel: {
        color: theme.colors.darkSlate,
        fontSize: 18,
        lineHeight: 26,
        fontFamily: CustomFonts.Poppins600
    },
    informationContainer: {
        flexDirection: 'column',
        flex: 1,
        alignItems: 'flex-end'
    },
    originalPrice: {
        color: theme.colors.fog,
        fontSize: 13,
        lineHeight: 20,
        textDecorationLine: 'line-through',
        fontFamily: CustomFonts.Poppins600,
        marginRight: theme.utils.gap(0.5)
    },
    totalPrice: {
        color: theme.colors.darkSlate,
        fontSize: 18,
        lineHeight: 26,
        fontFamily: CustomFonts.Poppins600
    },
    priceContainer: {
        flexDirection: 'row',
        alignItems: 'flex-end'
    },
    savedTextContainer: {
        alignItems: 'flex-end'
    },
    description: {
        color: theme.colors.green,
        fontFamily: CustomFonts.Poppins400,
        fontSize: 12,
        paddingTop: 5
    }
}))
