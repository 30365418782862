import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const TwitterIcon: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path d="M25.158 72.502c30.19 0 46.701-25.011 46.701-46.7 0-.711 0-1.418-.048-2.122A33.396 33.396 0 0 0 80 15.184a32.718 32.718 0 0 1-9.427 2.582 16.467 16.467 0 0 0 7.216-9.078 32.885 32.885 0 0 1-10.423 3.984 16.43 16.43 0 0 0-27.97 14.97 46.6 46.6 0 0 1-33.828-17.15 16.426 16.426 0 0 0 5.082 21.911 16.268 16.268 0 0 1-7.45-2.054v.208a16.42 16.42 0 0 0 13.168 16.09c-2.416.658-4.952.755-7.411.28a16.435 16.435 0 0 0 15.334 11.4A32.93 32.93 0 0 1 0 65.128a46.47 46.47 0 0 0 25.158 7.36" />
    </Icon>
)
