import React from 'react'
import { Breakpoint } from 'lib/styles'
import { Grid } from 'lib/components'
import { useIsWithinBreakpoints, useTranslations } from 'lib/hooks'
import { NavigationParams, NavigationProps, ScreenNames } from 'lib/routing'
import { useNewPasswordTokenAtom } from 'lib/atoms'
import { AuthLayout, NewPasswordForm } from '../components'

type AuthNewPasswordScreenProps = {
    navigation: NavigationProps<ScreenNames.AuthNewPassword>,
    route: NavigationParams<ScreenNames.AuthNewPassword>
}

export const AuthNewPasswordScreen: React.FunctionComponent<AuthNewPasswordScreenProps> = ({ route }) => {
    const T = useTranslations()
    const [ newPasswordToken ] = useNewPasswordTokenAtom()
    const isMobile = useIsWithinBreakpoints(Breakpoint.XS, Breakpoint.MD)

    return (
        <AuthLayout
            title={!isMobile
                ? T.screens.authNewPassword.welcome
                : undefined
            }
            message={!isMobile
                ? T.screens.authNewPassword.welcomeMessage
                : undefined
            }
        >
            <Grid.Gap gapTop={10}>
                <NewPasswordForm
                    token={newPasswordToken}
                    tempPassword={route.params.tempPassword}
                />
            </Grid.Gap>
        </AuthLayout>
    )
}
