import React, { useEffect } from 'react'
import { View } from 'react-native'
import { createStyles } from 'lib/styles'
import { useCleanApp, useStyles, useTranslations } from 'lib/hooks'
import { VoidFunction } from '../types'
import { Subheading, Regular, Label } from './typography'
import { Button } from './Button'

type ErrorBoundaryHandlerProps = {
    error: Error,
    resetError: VoidFunction
}

export const ErrorBoundaryHandler: React.FunctionComponent<ErrorBoundaryHandlerProps> = ({
    resetError,
    error
}) => {
    const { styles, theme } = useStyles(stylesheet)
    const T = useTranslations()
    const { cleanPersonalData } = useCleanApp()

    useEffect(() => {
        cleanPersonalData()
    }, [])

    return (
        <View style={styles.container}>
            <Subheading style={styles.textCenter}>
                {T.errorBoundary.title}
            </Subheading>
            <View style={styles.messageContainer}>
                <Regular style={styles.textCenter}>
                    {T.errorBoundary.message}
                </Regular>
            </View>
            <View style={styles.messageContainer}>
                <Label
                    style={styles.textCenter}
                    forceColor={theme.colors.softBlueGrey}
                >
                    {error?.message}
                </Label>
            </View>
            <Button
                width={150}
                onPress={resetError}
                text={T.errorBoundary.submit}
            />
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },
    textCenter: {
        textAlign: 'center'
    },
    messageContainer: {
        marginVertical: theme.utils.gap(2)
    }
}))
