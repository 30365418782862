import React from 'react'
import { View } from 'react-native'
import { createStyles } from 'lib/styles'
import { Typography } from 'lib/components'
import { useStyles, useTranslations } from 'lib/hooks'
import { useShareSteps } from '../hooks'
import { ShareStepItem } from './ShareStepItem'

export const ShareStepsSection: React.FunctionComponent = () => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const shareSteps = useShareSteps()

    return (
        <View style={styles.wrapper}>
            <Typography.Title
                bold
                style={styles.title}
            >
                {T.screens.referFriend.steps.title}
            </Typography.Title>
            <View style={styles.stepsWrapper}>
                {shareSteps.map((step, index) => (
                    <ShareStepItem
                        key={index}
                        index={index}
                        {...step}
                    />
                ))}
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        backgroundColor: theme.colors.marble,
        paddingVertical: theme.utils.gap(3),
        paddingHorizontal: {
            xl: theme.utils.gap(6),
            md: theme.utils.gap(3),
            xs: theme.utils.gap(2)
        }
    },
    title: {
        marginBottom: {
            md: theme.utils.gap(5),
            xs: theme.utils.gap(2)
        }
    },
    stepsWrapper: {
        flexDirection: {
            md: 'row',
            xs: 'column'
        },
        gap: {
            md: theme.utils.gap(4),
            xs: theme.utils.gap(2)
        }
    }
}))
