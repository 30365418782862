import React from 'react'
import { View } from 'react-native'
import { useForm } from '@codegateinc/react-form-builder-v2'
import { Icons } from 'assets'
import { createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { Adapter, Button, Separator, Typography } from 'lib/components'
import { SingleSignOnFormShape, useSingleSignOnForm } from '../forms'
import { CancelButton } from './CancelButton'

export const SingleSignOnForm: React.FunctionComponent = () => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const { form, submit, isFilled, hasError } = useForm<SingleSignOnFormShape>(useSingleSignOnForm(), {
        // TODO Send email
        onSuccess: () => {}
    })

    return (
        <View>
            <Typography.Heading style={styles.heading}>
                {T.screens.authSingleSignOn.verifyTitle}
            </Typography.Heading>
            <Typography.Regular style={styles.heading}>
                {T.screens.authSingleSignOn.verifyMessage}
            </Typography.Regular>
            <View style={styles.formInput}>
                <Adapter.TextInput
                    {...form.email}
                    leftIcon={Icons.Mail}
                    inputProps={{
                        autoComplete: 'email',
                        keyboardType: 'email-address',
                        textContentType: 'emailAddress',
                        autoCapitalize: 'none',
                        autoCorrect: false
                    }}
                />
            </View>
            <View style={styles.submit}>
                <Button
                    onPress={submit}
                    disabled={!isFilled || hasError}
                    text={T.screens.authSingleSignOn.submit}
                    renderIcon={color => (
                        <Icons.Arrow
                            size={14}
                            forceColor={color}
                        />
                    )}
                />
            </View>
            <CancelButton isDisabled={false} />
            <Separator text={T.screens.auth.loginSeparator} />
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    heading: {
        textAlign: 'center',
        paddingTop: theme.utils.gap(2)
    },
    formInput: {
        maxWidth: {
            lg: 400,
            xs: '100%'
        },
        width: '100%',
        alignSelf: 'center',
        marginTop: theme.utils.gap(4),
        marginBottom: theme.utils.gap(2)
    },
    submit: {
        maxWidth: {
            lg: 400,
            xs: '100%'
        },
        width: '100%',
        alignSelf: 'center',
        marginBottom: theme.utils.gap(2)
    }
}))
