import React, { useState } from 'react'
import { Linking, View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { Icons } from 'assets'
import { Job } from 'lib/models'
import { Breakpoint, createStyles } from 'lib/styles'
import { useIsWithinBreakpoints, useStyles, useTranslations } from 'lib/hooks'
import { PhoneModal, Touchable, Typography } from 'lib/components'
import { ScreenNames } from 'lib/routing'
import { AnalyticsEvent, AnalyticsEventDescription, GoogleAnalytics, segmentUtils, Segment } from 'lib/analytics'

type JobContactSmallProps = {
    job: Job,
    onChatPress?: VoidFunction
}

export const JobContactSmall: React.FunctionComponent<JobContactSmallProps> = ({
    job,
    onChatPress
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const navigation = useNavigation()
    const [isPhoneModalOpen, setIsPhoneModalOpen] = useState(false)
    const isMobile = useIsWithinBreakpoints(Breakpoint.XS, Breakpoint.MD)

    if (!job.supplier) {
        return null
    }

    const onCallPress = () => {
        GoogleAnalytics.logEvent({
            eventType: AnalyticsEvent.UserAction,
            description: AnalyticsEventDescription.PhoneClicked,
            value: job.job_id
        })
        Segment.callSupplierClicked({
            jobId: job.job_id,
            supplierId: job.supplier._id,
            serviceType: segmentUtils.supplyIdToServiceType(job.supply.id)
        })

        if (isMobile) {
            return Linking.openURL(`tel:${job.supplier.phone}`)
        }

        setIsPhoneModalOpen(true)
    }

    return (
        <View style={styles.wrapper}>
            <PhoneModal
                isOpen={isPhoneModalOpen}
                onClose={() => setIsPhoneModalOpen(false)}
                phoneNumber={job.supplier.phone}
                expertName={job.supplier.handle}
            />
            <Touchable
                style={styles.container}
                onPress={() => {
                    onChatPress && onChatPress()
                    GoogleAnalytics.logEvent({
                        eventType: AnalyticsEvent.UserAction,
                        description: AnalyticsEventDescription.ChatOpened,
                        value: job.job_id
                    })
                    Segment.chatWithSupplierClicked({
                        jobId: job.job_id,
                        supplierId: job.supplier._id,
                        serviceType: segmentUtils.supplyIdToServiceType(job.supply.id)
                    })

                    navigation.navigate(ScreenNames.Chat, {
                        jobId: job.job_id,
                        jobFetchId: job._id,
                        supplierId: job.supplier._id
                    })
                }}
            >
                <View style={styles.icon}>
                    <Icons.Chat
                        size={13}
                        forceColor={theme.icon.selected}
                    />
                </View>
                <Typography.Label
                    bold
                    forceColor={theme.colors.orange}
                >
                    {T.common.chat}
                </Typography.Label>
            </Touchable>
            <View style={styles.spacer} />
            <Touchable
                style={styles.container}
                onPress={onCallPress}
            >
                <View style={styles.icon}>
                    <Icons.Phone
                        size={16}
                        forceColor={theme.icon.selected}
                    />
                </View>
                <Typography.Label
                    bold
                    forceColor={theme.colors.orange}
                >
                    {T.common.call}
                </Typography.Label>
            </Touchable>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.colors.yellow,

        borderRadius: 8,
        marginHorizontal: -theme.utils.gap(1),
        marginBottom: -theme.utils.gap(1),
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0
    },
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        paddingHorizontal: theme.utils.gap(2),
        paddingVertical: theme.utils.gap(3) / 2
    },
    icon: {
        marginRight: theme.utils.gap(1) / 2
    },
    spacer: {
        width: 1,
        height: theme.utils.gap(2),
        backgroundColor: theme.colors.orange
    }
}))
