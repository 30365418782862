import { View } from 'react-native'
import React, { useState } from 'react'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { FrequencyOption, Nullable } from 'lib/types'
import { CalendarHeader } from './CalendarHeader'
import { CalendarMonth } from './CalendarMonth'
import { CalendarDaysName } from './CalendarDaysName'

type CalendarProps = {
    selectedDate: string,
    onDayPress: (date: string) => void,
    frequency: FrequencyOption,
    selectedDays: Record<string, Nullable<string>>,
    minHoursNotice: number,
    minDate?: string,
    maxDate?: string,
    disabled?: boolean,
    liteMode?: boolean,
    fullScreen?: boolean,
    isMultiSelect?: boolean,
    allowToggle?: boolean,
    showDateRange?: boolean,
    simpleMode?: boolean,
    selectedDaysChangeValue: (value: Record<string, Nullable<string>>) => void,
    setSelectedDate?: (date: string) => void,
    setSelectedDays?: (day: Record<string, Nullable<string>>) => void,
}

export const Calendar: React.FunctionComponent<CalendarProps> = ({
    selectedDate,
    onDayPress,
    selectedDays,
    minDate,
    maxDate,
    minHoursNotice,
    frequency,
    disabled,
    isMultiSelect,
    allowToggle,
    liteMode,
    fullScreen,
    simpleMode,
    showDateRange,
    setSelectedDate,
    setSelectedDays,
    selectedDaysChangeValue
}) => {
    const { styles } = useStyles(stylesheet)
    const [date, setDate] = useState(minDate ? minDate : new Date().toISOString())

    return (
        <View style={{
            ...(liteMode ? styles.containerWithLiteMode : styles.container),
            ...(simpleMode ? styles.simpleMode : {})
        }}>
            <CalendarHeader
                liteMode={liteMode}
                date={date}
                onChange={setDate}
            />
            <View style={styles.container}>
                <CalendarDaysName
                    liteMode={liteMode}
                    simpleMode={simpleMode}
                />
                <CalendarMonth
                    liteMode={liteMode}
                    fullScreen={fullScreen}
                    date={date}
                    frequency={frequency}
                    disabled={disabled}
                    showDateRange={showDateRange}
                    mobilePadding={40}
                    minDate={minDate}
                    maxDate={maxDate}
                    selectedDate={selectedDate}
                    selectedDays={selectedDays}
                    isMultiSelect={isMultiSelect}
                    allowToggle={allowToggle}
                    minHoursNotice={minHoursNotice}
                    simpleMode={simpleMode}
                    onDayPress={onDayPress}
                    selectedDaysChangeValue={selectedDaysChangeValue}
                    setSelectedDate={setSelectedDate}
                    setSelectedDays={setSelectedDays}
                />
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        paddingTop: theme.utils.gap(1)
    },
    containerWithLiteMode: {
        paddingTop: theme.utils.gap(1),
        borderRadius: 8,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderWidth: 1,
        borderColor: theme.colors.fog
    },
    simpleMode: {
        borderRadius: 0,
        borderLeftWidth: 0,
        borderRightWidth: 0
    }
}))
