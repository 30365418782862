import React from 'react'
import { View } from 'react-native'
import { Icons } from 'assets'
import { createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { Touchable, Typography } from 'lib/components'
import { CustomFonts } from 'lib/types'
import { jobHelpers, linkingHelpers } from 'lib/utils'
import { ENV } from 'lib/config'
import { isNative } from 'lib/common'
import { JobDetailsResponse } from 'features/jobs/types'

type PaymentSummaryProps = {
    jobDetail: JobDetailsResponse,
    upcomingSession?: JobDetailsResponse,
    fullWidth?: boolean,
    showAsModal?: boolean,
    onCloseModal?: VoidFunction
}

export const PaymentSummary: React.FunctionComponent<PaymentSummaryProps> = ({
    jobDetail,
    upcomingSession,
    fullWidth,
    showAsModal,
    onCloseModal
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const additionalFee = jobHelpers.getPolicyCancellationFee(jobDetail)
    const isSessionStartingWithinHours = upcomingSession ?
        jobHelpers.isSessionJob(upcomingSession) && jobHelpers.isSessionStartingWithinHours(upcomingSession, 2) : false

    return additionalFee ? (
        <View style={{
            ...styles.paymentSummaryContainer,
            ...(showAsModal ? styles.modalContainer : {}),
            ...(fullWidth ? styles.fullWidth : {})
        }}>
            <View style={styles.paymentSummaryRow}>
                <Typography.Regular style={styles.paymentSummaryTitle}>
                    {isSessionStartingWithinHours ? T.screens.cancelJob.cancellationFee : T.screens.cancelJob.paymentSummary}
                </Typography.Regular>
                {
                    showAsModal && (
                        <Touchable onPress={() => onCloseModal && onCloseModal()} testID={T.accessibility.cancelJobScreen.paymentSummary.close}>
                            <Icons.Close size={16} forceColor={theme.colors.darkSlate}/>
                        </Touchable>
                    )
                }
            </View>
            <View style={styles.paymentSummaryRow}>
                <Typography.Regular style={styles.summaryAttribute}>
                    {T.screens.cancelJob.sessionRemaining}
                </Typography.Regular>
                <Typography.Regular testID={T.accessibility.cancelJobScreen.paymentSummary.session} style={{
                    ...styles.summaryAttribute,
                    ...styles.boldText
                }}>
                    {jobHelpers.getSessionsRemaining(jobDetail)}
                </Typography.Regular>
            </View>
            <View style={styles.paymentSummaryRow}>
                <Typography.Regular style={styles.summaryAttribute}>
                    {T.screens.cancelJob.additionalFee}
                </Typography.Regular>
                <Typography.Regular testID={T.accessibility.cancelJobScreen.paymentSummary.fee} style={{
                    ...styles.summaryAttribute,
                    ...styles.boldText
                }}>
                    {additionalFee}
                </Typography.Regular>
            </View>
            <View style={styles.paymentSummaryRow}>
                <Typography.Regular style={styles.link} onPress={() => linkingHelpers.openUrl(`${ENV.SENDHELPER_MARKETING_SITE_URL}/cancellation-policy`)}>
                    {` ${T.screens.jobDetails.subscriptionRescheduleRequest.whyAmIBeingCharged} `}
                    <Icons.QuestionMark viewBox={
                        isNative ? '0 -1 20 20' : '0 -6 20 20'
                    } size={16} forceColor={theme.colors.strawberry}/>
                </Typography.Regular>
            </View>
        </View>
    ) : null
}

const stylesheet = createStyles(theme => ({
    paymentSummaryContainer: {
        padding: theme.utils.gap(2),
        paddingBottom: theme.utils.gap(1),
        backgroundColor: theme.colors.linen,
        borderRadius: 8,
        borderWidth: 1,
        borderColor: theme.colors.strawberry,
        flexDirection: 'column',
        width: 260,
        marginTop: theme.utils.gap(7.25)
    },
    fullWidth: {
        marginTop: theme.utils.gap(2),
        padding: 0,
        width: '100%',
        borderColor: undefined,
        borderWidth: 0
    },
    modalContainer: {
        width: 320
    },
    paymentSummaryTitle: {
        fontSize: 18,
        lineHeight: 26,
        fontFamily: CustomFonts.Poppins500,
        color: theme.colors.strawberry
    },
    paymentSummaryRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: theme.utils.gap(1.5)
    },
    summaryAttribute: {
        fontSize: 14,
        lineHeight: 20,
        fontFamily: CustomFonts.Poppins400,
        color: theme.colors.darkSlate
    },
    boldText: {
        fontFamily: CustomFonts.Poppins500
    },
    link: {
        color: theme.colors.strawberry,
        fontFamily: CustomFonts.Poppins500,
        fontSize: 13,
        lineHeight: 22,
        cursor: 'pointer'
    }
}))
