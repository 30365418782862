import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const Triangle: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path d="m0 0 40 40L80 0H0Z" />
    </Icon>
)
