import React from 'react'
import { View } from 'react-native'
import { Icons } from 'assets'
import { createStyles } from 'lib/styles'
import { useIsSingaporeTimeZone, useStyles, useTranslations } from 'lib/hooks'
import { Label } from './typography'
import { isNative } from 'lib/common'

type TimeZoneMessageProps = {
    wideModel?: boolean,
}

export const TimeZoneMessage: React.FunctionComponent<TimeZoneMessageProps> = ({ wideModel }) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const isSingaporeTimeZone = useIsSingaporeTimeZone()

    if (isSingaporeTimeZone) {
        return null
    }

    return (
        <View style={{
            ...styles.wrapper,
            ...(wideModel ? styles.wide : styles.narrow)
        }}>
            <View>
                <Icons.Info
                    size={16}
                    forceColor={theme.colors.orange}
                />
            </View>
            <Label
                style={styles.textWrapper}
                forceColor={theme.colors.orange}
            >
                {T.components.timeZone.detectionMessage}
                <Label
                    bold
                    forceColor={theme.colors.orange}
                >
                    {T.components.timeZone.noteMessage}
                </Label>
            </Label>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    wide: {
        maxWidth: {
            lg: 600,
            md: '100%',
            xs: '100%'
        },
        paddingRight: isNative ? 20 : undefined
    },
    narrow: {
        maxWidth: {
            lg: 350,
            md: '100%',
            xs: '100%'
        }
    },
    wrapper: {
        borderRadius: 8,
        borderWidth: 1,
        paddingVertical: theme.utils.gap(1),
        paddingHorizontal: theme.utils.gap(1),
        borderColor: theme.colors.orange,
        backgroundColor: theme.colors.yellow,
        flexDirection: 'row',
        marginBottom: theme.utils.gap(1)
    },
    textWrapper: {
        marginLeft: theme.utils.gap(1),
        textAlign: 'left'
    }
}))
