import React from 'react'
import { Image } from 'react-native'
import Animated, { SharedValue, useAnimatedStyle, withTiming } from 'react-native-reanimated'
import { createStyles } from 'lib/styles'
import { Measurements } from 'lib/common'
import { useStyles } from 'lib/hooks'
import { Images } from 'assets'

type OnboardingPageProps = {
    image: Images,
    index: number,
    activeIndex: SharedValue<number>
}

export const OnboardingPage: React.FunctionComponent<OnboardingPageProps> = ({
    image,
    index,
    activeIndex
}) => {
    const { styles } = useStyles(stylesheet)
    const activeAnimatedStyles = useAnimatedStyle(() => {
        const isActive = index === activeIndex.value

        return {
            opacity: withTiming(isActive
                ? 1
                : 0
            )
        }
    })

    return (
        <Animated.View style={[styles.imageWrapper, activeAnimatedStyles]}>
            <Image
                style={styles.image}
                resizeMode="contain"
                source={image}
            />
        </Animated.View>
    )
}

const stylesheet = createStyles(theme => ({
    imageWrapper: {
        width: Measurements.WindowWidth,
        paddingHorizontal: theme.utils.gap(3)
    },
    image: {
        width: '100%',
        height: '100%'
    }
}))
