import jwt_decode from 'jwt-decode'
import { ENV } from 'lib/config'
import { Address, User } from 'lib/models'
import { stringHelpers } from 'lib/utils'
import { SetAuthCookieConfig, SHCookieKey, SHJWTToken } from './types'
import { getRefreshToken } from '../utils'

export const setAuthCookies = (config: SetAuthCookieConfig) => {
    const { cookieDomain, expirationDate } = getCookieConfig()

    setCookie(SHCookieKey.UserName, config.userName, cookieDomain, expirationDate)
    setCookie(SHCookieKey.UserId, config.userId, cookieDomain, expirationDate)
    setCookie(SHCookieKey.UserEmail, config.userEmail, cookieDomain, expirationDate)
    setUserAddressesCookie(config.userAddresses)
}

export const setUserAddressesCookie = (userAddresses: Array<Address>) => {
    const { cookieDomain, expirationDate } = getCookieConfig()

    const parsedUserAddresses = userAddresses
        .map(address => ({
            postcode: address.postcode,
            address: `${stringHelpers.getFormattedAddressUnit(address.unit)}${address.address}, ${address.postcode}`
        }))
        .slice(0, 20)

    setCookie(SHCookieKey.UserAddresses, encodeURIComponent(JSON.stringify(parsedUserAddresses)), cookieDomain, expirationDate)
}

export const setMarketingConsentCookie = (profile: User) => {
    const { cookieDomain, expirationDate } = getCookieConfig()

    const bookingCount = profile?.bookingCount || 0

    setCookie(SHCookieKey.BookingCount, bookingCount?.toString() as string, cookieDomain, expirationDate)
    setMarketingConsentAcceptedCookie(profile.consumer.marketing_consent?.accepted || false)
}

export const setMarketingConsentAcceptedCookie = (isAccepted: boolean) => {
    const { cookieDomain, expirationDate } = getCookieConfig()

    setCookie(SHCookieKey.MarketingConsentAccepted, isAccepted.toString() as string, cookieDomain, expirationDate)
}

export const removeAuthCookies = () => {
    const cookieDomain = ENV.COOKIE_SHARED_DOMAIN as string

    setCookie(SHCookieKey.UserName, '', cookieDomain, 'Thu, 01 Jan 1970 00:00:00 UTC')
    setCookie(SHCookieKey.UserAddresses, '', cookieDomain, 'Thu, 01 Jan 1970 00:00:00 UTC')
    setCookie(SHCookieKey.UserEmail, '', cookieDomain, 'Thu, 01 Jan 1970 00:00:00 UTC')
    setCookie(SHCookieKey.UserId, '', cookieDomain, 'Thu, 01 Jan 1970 00:00:00 UTC')
    setCookie(SHCookieKey.MarketingConsentAccepted, '', cookieDomain, 'Thu, 01 Jan 1970 00:00:00 UTC')
    setCookie(SHCookieKey.BookingCount, '', cookieDomain, 'Thu, 01 Jan 1970 00:00:00 UTC')
}

export const removeMarketingConsentCookie = () => {
    const cookieDomain = ENV.COOKIE_SHARED_DOMAIN as string

    setCookie(SHCookieKey.MarketingConsentAccepted, '', cookieDomain, 'Thu, 01 Jan 1970 00:00:00 UTC')
    setCookie(SHCookieKey.BookingCount, '', cookieDomain, 'Thu, 01 Jan 1970 00:00:00 UTC')
}

const setCookie = (key: SHCookieKey, value: string, domain: string, expirationDate: string) => {
    const suffix = ENV.COOKIE_SHARED_SUFFIX || ''
    document.cookie = `${key}${suffix}=${value}; domain=.${domain}; path=/; expires=${expirationDate}; SameSite=Strict`
}

const getCookieConfig = () => {
    const { exp } = jwt_decode<SHJWTToken>(getRefreshToken() as string)
    const expirationDate = (new Date(exp * 1000)).toUTCString()
    const cookieDomain = ENV.COOKIE_SHARED_DOMAIN as string

    return { cookieDomain, expirationDate }
}
