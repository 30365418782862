import currency from 'currency.js'
import { R } from 'lib/utils'
import { useTranslations } from './useTranslations'

export const useFormatPrice = (precision?: number) => {
    const T = useTranslations()

    const formatPrice = (price: number | string) => currency(price, {
        pattern: '! #',
        symbol: T.common.currency,
        precision: R.isDefined(precision)
            ? precision
            : 2
    }).format()

    return formatPrice
}
