import React, { useState } from 'react'
import { View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { Icons } from 'assets'
import { useUserAtom } from 'lib/atoms'
import { createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { DockedModal, Button, SectionAccordion, Typography, Touchable } from 'lib/components'
import { ScreenNames } from 'lib/routing'
import { jobActions } from 'features/jobs'

export const UserDeleteAccountSection: React.FunctionComponent = () => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const navigation = useNavigation()
    const [user] = useUserAtom()
    const credit = user?.consumer?.balance_credit
    const { data : response } = jobActions.useGetConsumerActiveJobs()
    const numberActiveJob = response?.data?.job_list?.length
    const isDeletable = credit === 0 && numberActiveJob === 0
    const closeModal = () => setIsModalVisible(false)
    const openModal = () => setIsModalVisible(true)

    const handleDeleteAccount = () => {
        setIsModalVisible(false)
        navigation.navigate(ScreenNames.UserDeleteAccount)
    }

    const handleContactUs = () => {
        setIsModalVisible(false)
        navigation.navigate(ScreenNames.ContactUs)
    }

    return (
        <SectionAccordion
            containerStyles={styles.accordion}
            title={T.screens.user.sections.deleteAccount}
        >
            <View style={styles.container}>
                <DeleteAccountButton onPress={openModal} />
            </View>
            {isDeletable ? (
                <ConfirmAccountDeletionModal
                    isModalVisible={isModalVisible}
                    onClose={closeModal}
                    onConfirm={handleDeleteAccount}
                />
            ) : (
                <AccountDeletionErrorModal
                    isModalVisible={isModalVisible}
                    onClose={closeModal}
                    handleContactUs={handleContactUs}
                />
            )}
        </SectionAccordion>
    )
}

const DeleteAccountButton = ({ onPress }) => {
    const { styles, theme } = useStyles(stylesheet)
    const T = useTranslations()

    return (
        <Touchable style={styles.deleteAccountButton} onPress={onPress}>
            <Icons.Delete size={14} forceColor={theme.colors.grey} />
            <Typography.Regular style={styles.text}>
                {T.screens.user.delete}
            </Typography.Regular>
        </Touchable>
    )
}

const AccountDeletionErrorModal = ({ isModalVisible, onClose, handleContactUs }) => {
    const { styles, theme } = useStyles(stylesheet)
    const T = useTranslations()

    return (
        <DockedModal
            contentStyles={styles.modalContent}
            childrenWrapper={styles.modalChildrenWrapper}
            onClose={onClose}
            isOpen={isModalVisible}
            closeButtonStyles={styles.closeButton}
        >
            <View style={styles.modalHeader}>
                <Touchable
                    onPress={onClose}
                    hitSlop={{ top: 20, left: 20, right: 20, bottom: 20 }}
                >
                    <Icons.Close size={22} />
                </Touchable>
            </View>
            <View style={styles.modalContentWrapper}>
                <View style={styles.closeCircle}>
                    <Icons.Close size={20} forceColor={theme.colors.orange} />
                </View>
                <Typography.Heading style={styles.modalTitle}>
                    {T.screens.user.accountDeletionErrorTitle}
                </Typography.Heading>
                <Typography.Regular style={styles.modalMessage}>
                    {T.screens.user.accountDeletionErrorMessage}
                </Typography.Regular>
                <Typography.Regular style={styles.modalMessage}>
                    {T.screens.user.contactSupportForAccountDeletion}
                </Typography.Regular>
                <View style={styles.buttons}>
                    <View style={styles.buttonWrapper}>
                        <Button noBackground text={T.common.cancel} onPress={onClose} />
                    </View>
                    <View style={styles.buttonWrapper}>
                        <Button text={T.screens.user.contactUsButtonText} onPress={handleContactUs} />
                    </View>
                </View>
            </View>
        </DockedModal>
    )
}

const ConfirmAccountDeletionModal = ({ isModalVisible, onClose, onConfirm }) => {
    const { styles, theme } = useStyles(stylesheet)
    const T = useTranslations()

    return (
        <DockedModal
            contentStyles={styles.modalContent}
            childrenWrapper={styles.modalChildrenWrapper}
            onClose={onClose}
            isOpen={isModalVisible}
            closeButtonStyles={styles.closeButton}
        >
            <View style={styles.modalHeader}>
                <Touchable
                    onPress={onClose}
                    hitSlop={{ top: 20, left: 20, right: 20, bottom: 20 }}
                >
                    <Icons.Close size={22} />
                </Touchable>
            </View>
            <View style={styles.modalContentWrapper}>
                <Icons.Alert size={48} forceColor={theme.colors.orange} />
                <Typography.Heading style={styles.modalTitle}>
                    {T.screens.user.deleteAccountConfirmation}
                </Typography.Heading>
                <Typography.Regular style={styles.modalDescription}>
                    <Typography.Regular style={styles.modalMessage}>
                        {T.screens.user.deleteAccountWarning}{' '}
                    </Typography.Regular>
                    <Typography.Regular style={{ ...styles.modalMessage, ...styles.highlightText }}>
                        {T.screens.user.deleteAccountIrreversible}
                    </Typography.Regular>
                </Typography.Regular>
                <Button text={T.screens.user.cancelDelete} onPress={onClose} />
                <Touchable style={styles.confirmDeleteButton} onPress={onConfirm}>
                    <Typography.Regular style={styles.confirmDeleteText}>
                        {T.screens.user.confirmDelete}
                    </Typography.Regular>
                </Touchable>
            </View>
        </DockedModal>
    )
}

const stylesheet = createStyles(theme => ({
    closeCircle: {
        width: 50,
        height: 50,
        borderRadius: 25,
        borderWidth: 4,
        borderColor: theme.colors.orange,
        justifyContent: 'center',
        alignItems: 'center'
    },
    buttons: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        marginVertical: theme.utils.gap(2)
    },
    buttonWrapper: {
        flex: 1,
        marginHorizontal: theme.utils.gap(1) / 2
    },
    modalContent: {
        position: 'relative',
        backgroundColor: theme.colors.white,
        paddingTop: 0,
        paddingBottom: theme.utils.gap(5)
    },
    modalChildrenWrapper: {
        marginTop: 0,
        paddingTop: 0
    },
    modalHeader: {
        alignItems: 'flex-end',
        marginRight: theme.utils.gap(2),
        marginTop: theme.utils.gap(3)
    },
    confirmDeleteButton: {
        marginTop: 15,
        alignItems: 'center'
    },
    confirmDeleteText: {
        fontSize: 14,
        lineHeight: 16,
        color: theme.colors.grey
    },
    deleteButtonWrapper: {
        alignItems: 'center',
        marginTop: 10,
        flexDirection: 'column'
    },
    modalTitle: {
        marginTop: 15,
        fontSize: 20,
        lineHeight: 22,
        textAlign: 'center',
        color: theme.colors.night
    },
    modalDescription: {
        marginVertical: 20
    },
    modalMessage: {
        marginTop: 15,
        fontSize: 16,
        lineHeight: 24,
        textAlign: 'center',
        color: theme.colors.night
    },
    highlightText: {
        color: theme.colors.orange
    },
    modalContentWrapper: {
        paddingTop: 15,
        alignItems: 'center'
    },
    accordion: {
        // for react-select
        zIndex: -1
    },
    closeButton: {
        display: 'none'
    },
    container: {
        flexDirection: {
            lg: 'row',
            xs: 'column'
        },
        alignItems: {
            lg: 'center',
            xs: 'flex-start'
        },
        paddingVertical: theme.utils.gap(2),
        justifyContent: 'space-between'
    },
    deleteAccountButton: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    text: {
        color: theme.colors.grey,
        textDecorationLine: 'underline',
        marginLeft: 6,
        fontSize: 14
    }
}))
