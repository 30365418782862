import React from 'react'
import { View } from 'react-native'
import { Typography } from 'lib/components'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { PackageDetailsModalContent } from '../../types'

export const PestControlPackageModalContent: React.FunctionComponent<PackageDetailsModalContent> = ({
    title,
    description,
    includedTitle,
    message
}) => {
    const { styles, theme } = useStyles(stylesheet)

    return (
        <View style={styles.container}>
            <View style={styles.title}>
                <Typography.Regular>
                    {title}
                </Typography.Regular>
            </View>
            <Typography.Regular forceColor={theme.colors.darkGrey}>
                {description}
            </Typography.Regular>
            <View style={styles.row}>
                <Typography.Regular bold>
                    {includedTitle}
                </Typography.Regular>
                <Typography.Regular forceColor={theme.colors.darkGrey}>
                    {message}
                </Typography.Regular>
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        maxWidth: 800
    },
    title: {
        borderBottomWidth: 1,
        borderColor: theme.colors.silver,
        paddingBottom: theme.utils.gap(2),
        marginBottom: theme.utils.gap(2)
    },
    row: {
        marginTop: theme.utils.gap(2)
    }
}))
