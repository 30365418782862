import { useField } from '@codegateinc/react-form-builder-v2'
import { useTranslations } from 'lib/hooks'
import { BookingFrequency, FrequencyOption, Nullable, SupplyID } from 'lib/types'
import { getServiceStaticConfig } from 'features/bookings/utils'
import { LaundryFields } from './forms'
import { useBookingDays, useFrequencyOptions, useDriverInstructionsOptions } from '../hooks'
import { TimeSlot } from '../types'
import { validateTimeSlots } from '../utils'

export const useLaundryForm = (timeSlots: Array<TimeSlot>) => {
    const T = useTranslations()
    const frequencyOptions = useFrequencyOptions()
    const bookingDaysInitialValue = useBookingDays()
    const [defaultDriverInstruction] = useDriverInstructionsOptions()
    const config = getServiceStaticConfig(SupplyID.Laundry)

    const frequency = useField<Nullable<FrequencyOption>>({
        key: LaundryFields.Frequency,
        initialValue: frequencyOptions.find(option => option.value === BookingFrequency.OneTime) || null,
        isRequired: false,
        validateOnBlur: false,
        label: T.screens.laundry.formFields.frequency.label
    })

    const startingDate = useField<string>({
        key: LaundryFields.StartingDate,
        initialValue: '',
        isRequired: true,
        validateOnBlur: false,
        label: T.screens.laundry.formFields.startingDate.label,
        placeholder: T.screens.laundry.formFields.startingDate.placeholder,
        validationRules: [
            {
                errorMessage: T.screens.laundry.formFields.startingDate.validation.isRequired,
                validate: Boolean
            }
        ]
    })

    const driverInstructions = useField<Nullable<string>>({
        key: LaundryFields.DriverInstructions,
        initialValue: defaultDriverInstruction.value,
        isRequired: true,
        validateOnBlur: false,
        label: T.screens.laundry.formFields.driverInstructions.label,
        validationRules: [
            {
                errorMessage: T.screens.laundry.formFields.driverInstructions.validation.isRequired,
                validate: Boolean
            }
        ]
    })

    const washAndIron = useField<boolean>({
        key: LaundryFields.WashAndIron,
        initialValue: false,
        isRequired: false,
        label: T.screens.laundry.formFields.washAndIron.label
    })

    const washAndIronItems = useField<number>({
        key: LaundryFields.WashAndIronItems,
        initialValue: 0,
        isRequired: washAndIron.value,
        validateOnBlur: false,
        label: T.screens.laundry.formFields.washAndIronItems.label,
        validationRules: [
            {
                errorMessage: T.screens.laundry.formFields.washAndIronItems.validation.isRequired,
                validate: value => !washAndIron.value || Boolean(value)
            }
        ]
    })

    const dryCleanAndIron = useField<boolean>({
        key: LaundryFields.DryCleanAndIron,
        initialValue: false,
        isRequired: false,
        label: T.screens.laundry.formFields.dryCleanAndIron.label
    })

    const dryCleanAndIronItems = useField<number>({
        key: LaundryFields.DryCleanAndIronItems,
        initialValue: 0,
        isRequired: dryCleanAndIron.value,
        validateOnBlur: false,
        label: T.screens.laundry.formFields.dryCleanAndIronItems.label,
        validationRules: [
            {
                errorMessage: T.screens.laundry.formFields.dryCleanAndIronItems.validation.isRequired,
                validate: value => !dryCleanAndIron.value || Boolean(value)
            }
        ]
    })

    const ironOnly = useField<boolean>({
        key: LaundryFields.IronOnly,
        initialValue: false,
        isRequired: false,
        label: T.screens.laundry.formFields.ironOnly.label
    })

    const ironOnlyItems = useField<number>({
        key: LaundryFields.IronOnlyItems,
        initialValue: 0,
        isRequired: ironOnly.value,
        validateOnBlur: false,
        label: T.screens.laundry.formFields.ironOnlyItems.label,
        validationRules: [
            {
                errorMessage: T.screens.laundry.formFields.ironOnlyItems.validation.isRequired,
                validate: value => !ironOnly.value || Boolean(value)
            }
        ]
    })

    const loadWash = useField<boolean>({
        key: LaundryFields.LoadWash,
        initialValue: false,
        isRequired: false,
        label: T.screens.laundry.formFields.loadWash.label
    })

    const loadWashItems = useField<number>({
        key: LaundryFields.LoadWashItems,
        initialValue: 0,
        isRequired: loadWash.value,
        validateOnBlur: false,
        label: T.screens.laundry.formFields.loadWashItems.label,
        validationRules: [
            {
                errorMessage: T.screens.laundry.formFields.loadWashItems.validation.isRequired,
                validate: value => !loadWash.value || Boolean(value)
            }
        ]
    })

    const curtainsWash = useField<boolean>({
        key: LaundryFields.CurtainsWash,
        initialValue: false,
        isRequired: false,
        label: T.screens.laundry.formFields.curtainsWash.label
    })

    const curtainsWashItems = useField<number>({
        key: LaundryFields.CurtainsWashItems,
        initialValue: 0,
        isRequired: curtainsWash.value,
        validateOnBlur: false,
        label: T.screens.laundry.formFields.curtainsWashItems.label,
        validationRules: [
            {
                errorMessage: T.screens.laundry.formFields.curtainsWashItems.validation.isRequired,
                validate: value => !curtainsWash.value || Boolean(value)
            }
        ]
    })

    const bookingTime = useField<string>({
        key: LaundryFields.BookingTime,
        initialValue: '',
        validateOnBlur: true,
        isRequired: frequency.value?.value === BookingFrequency.OneTime,
        placeholder: T.screens.laundry.formFields.selectTime.placeHolder,
        validationRules: [
            {
                errorMessage: T.screens.laundry.formFields.selectTime.validation.isRequired,
                validate: Boolean
            },
            {
                errorMessage: `${config.min_notice_hours} ${T.screens.laundry.formFields.selectTime.validation.minimumHourNotice}`,
                validate: value => validateTimeSlots({
                    bookingTime: value,
                    startingDate: startingDate.value,
                    minHoursNotice: config.min_notice_hours,
                    timeSlots
                })
            }
        ]
    })

    const bookingDays = useField<Record<string, Nullable<string>>>({
        key: LaundryFields.BookingDays,
        initialValue: bookingDaysInitialValue,
        validateOnBlur: true,
        isRequired: false,
        placeholder: T.screens.laundry.formFields.selectTime.placeHolder
    })

    return {
        frequency,
        startingDate,
        driverInstructions,
        washAndIron,
        washAndIronItems,
        dryCleanAndIron,
        dryCleanAndIronItems,
        ironOnly,
        ironOnlyItems,
        loadWash,
        loadWashItems,
        curtainsWash,
        curtainsWashItems,
        bookingTime,
        bookingDays
    }
}
