import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const QuestionMark: React.FunctionComponent<IconProps> = props => (
    <Icon viewBox="0 0 16 16" {...props}>
        <Path d="M5.66683 6.3335C5.66683 5.04683 6.7135 4.00016 8.00016 4.00016C9.28683 4.00016 10.3335 5.04683 10.3335 6.3335C10.3335 7.38683 9.62683 8.26883 8.66683 8.55816V9.3335C8.66683 9.70216 8.36883 10.0002 8.00016 10.0002C7.6315 10.0002 7.3335 9.70216 7.3335 9.3335V8.00016C7.3335 7.6315 7.6315 7.3335 8.00016 7.3335C8.5515 7.3335 9.00016 6.88483 9.00016 6.3335C9.00016 5.78216 8.5515 5.3335 8.00016 5.3335C7.44883 5.3335 7.00016 5.78216 7.00016 6.3335C7.00016 6.70216 6.70216 7.00016 6.3335 7.00016C5.96483 7.00016 5.66683 6.70216 5.66683 6.3335ZM7.3335 11.3335C7.3335 10.9648 7.6315 10.6668 8.00016 10.6668C8.36883 10.6668 8.66683 10.9648 8.66683 11.3335C8.66683 11.7022 8.36883 12.0002 8.00016 12.0002C7.6315 12.0002 7.3335 11.7022 7.3335 11.3335ZM8.00016 13.3335C5.0595 13.3335 2.66683 10.9408 2.66683 8.00016C2.66683 5.0595 5.0595 2.66683 8.00016 2.66683C10.9408 2.66683 13.3335 5.0595 13.3335 8.00016C13.3335 10.9408 10.9408 13.3335 8.00016 13.3335ZM8.00016 1.3335C4.32416 1.3335 1.3335 4.32416 1.3335 8.00016C1.3335 11.6762 4.32416 14.6668 8.00016 14.6668C11.6762 14.6668 14.6668 11.6762 14.6668 8.00016C14.6668 4.32416 11.6762 1.3335 8.00016 1.3335Z" fill={props.forceColor ? props.forceColor : '#2C2C2C'} ill-rule="evenodd" clipRule="evenodd" />
    </Icon>
)
