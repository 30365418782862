import React, { RefObject } from 'react'
import { Field } from '@codegateinc/react-form-builder-v2'
import { TextInput as NativeTextInput, TextInputProps as NativeTextInputProps, View } from 'react-native'
import { IconProps, VoidFunction } from 'lib/types'
import { Breakpoint, createStyles } from 'lib/styles'
import { MediaQuery, Typography } from 'lib/components'
import { useStyles } from 'lib/hooks'
import { KeyboardAccessoryFieldProps } from '../../reKeyboardAccessory'
import { TextInput } from './TextInput'
import { FormComponents } from '../components'

interface TextInputWithUnitProps extends KeyboardAccessoryFieldProps, Field<string> {
    unit: string,
    ref?: RefObject<NativeTextInput>,
    disabled?: boolean,
    testID?: string,
    inputProps?: NativeTextInputProps,
    customStyles?: boolean,
    rightIcon?: React.FunctionComponent<IconProps>,
    leftIcon?: React.FunctionComponent<IconProps>,
    onFocus?: VoidFunction
}

export const TextInputWithUnit = React.forwardRef<NativeTextInput, TextInputWithUnitProps>((props, ref) => {
    const { styles, theme } = useStyles(stylesheet)
    const unitBackgroundColor = props.errorMessage
        ? theme.components.input.error.unitBackground
        : theme.components.input.unitBackgroundColor
    const unitLabelText = props.disabled
        ? theme.components.input.typography.placeholder
        : theme.components.input.typography.text
    const getBorderColor = () => {
        if (props.errorMessage) {
            return theme.components.input.error.borderColor
        }

        if (!props.value) {
            return theme.components.input.emptyBorderColor
        }

        return theme.components.input.filledBorderColor
    }

    return (
        <View style={styles.row}>
            {props.label && (
                <View style={styles.labelColumn}>
                    <Typography.Regular>
                        <Typography.Regular>
                            {props.label}
                        </Typography.Regular>
                        <Typography.Label forceColor={theme.components.input.typography.error}>
                            {props.isRequired ? '*' : ''}
                        </Typography.Label>
                    </Typography.Regular>
                    <MediaQuery.Visible from={Breakpoint.LG}>
                        <FormComponents.ErrorMessage />
                    </MediaQuery.Visible>
                </View>
            )}
            <View style={styles.input}>
                <View style={styles.input}>
                    <TextInput
                        ref={ref}
                        {...props}
                        inputProps={{
                            style: styles.inputStyles
                        }}
                    />
                </View>
                <View
                    style={{
                        ...styles.unitContainer,
                        backgroundColor: unitBackgroundColor,
                        borderColor: getBorderColor()
                    }}
                >
                    <Typography.Label forceColor={unitLabelText}>
                        {props.unit}
                    </Typography.Label>
                </View>
            </View>
        </View>
    )
})

const stylesheet = createStyles(theme => ({
    input: {
        flex: 1,
        flexDirection: 'row'
    },
    row: {
        alignItems: {
            lg: 'center',
            xs: 'stretch'
        },
        flexDirection: {
            lg: 'row',
            xs: 'column'
        }
    },
    labelColumn: {
        width: {
            lg: 120,
            xs: '100%'
        },
        marginRight: {
            lg: theme.utils.gap(1),
            xs: 0
        },
        marginBottom: {
            lg: 0,
            xs: theme.utils.gap(1)
        }
    },
    textRow: {
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    unitContainer: {
        minWidth: 80,
        justifyContent: 'center',
        paddingHorizontal: theme.utils.gap(2),
        height: theme.components.input.height,
        borderTopRightRadius: theme.components.input.borderRadius,
        borderBottomRightRadius: theme.components.input.borderRadius,
        borderTopWidth: 1,
        borderBottomWidth: 1,
        borderRightWidth: 1
    },
    inputStyles: {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0
    }
}))
