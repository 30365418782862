import { useField } from '@codegateinc/react-form-builder-v2'
import { useTranslations } from 'lib/hooks'
import { Nullable } from 'lib/types'
import { BookingsFiltersFields } from './forms'

export const useBookingsFiltersForm = () => {
    const T = useTranslations()

    const searchValue = useField<Nullable<string>>({
        key: BookingsFiltersFields.SearchValue,
        initialValue: null,
        isRequired: false,
        validateOnBlur: false,
        placeholder: T.common.search,
        label: T.common.search
    })

    const service = useField<Nullable<string>>({
        key: BookingsFiltersFields.Service,
        initialValue: null,
        isRequired: false,
        validateOnBlur: false,
        placeholder: T.common.allServices
    })

    const date = useField<string>({
        key: BookingsFiltersFields.Date,
        initialValue: '',
        isRequired: false,
        validateOnBlur: false
    })

    return {
        searchValue,
        service,
        date
    }
}
