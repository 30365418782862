import React from 'react'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'
import { Path } from 'react-native-svg'

export const Cancel: React.FunctionComponent<IconProps> = props => (
    <Icon {...props} viewBox="0 0 16 16">
        <Path d="M9.80459 6.19534C9.54392 5.93467 9.12258 5.93467 8.86192 6.19534L7.99992 7.05734L7.13792 6.19534C6.87725 5.93467 6.45592 5.93467 6.19525 6.19534C5.93459 6.456 5.93459 6.87734 6.19525 7.138L7.05725 8L6.19525 8.862C5.93459 9.12267 5.93459 9.544 6.19525 9.80467C6.32525 9.93467 6.49592 10 6.66658 10C6.83725 10 7.00792 9.93467 7.13792 9.80467L7.99992 8.94267L8.86192 9.80467C8.99192 9.93467 9.16259 10 9.33325 10C9.50392 10 9.67458 9.93467 9.80459 9.80467C10.0653 9.544 10.0653 9.12267 9.80459 8.862L8.94258 8L9.80459 7.138C10.0653 6.87734 10.0653 6.456 9.80459 6.19534ZM7.99992 13.3333C5.05925 13.3333 2.66659 10.9407 2.66659 8C2.66659 5.05934 5.05925 2.66667 7.99992 2.66667C10.9406 2.66667 13.3333 5.05934 13.3333 8C13.3333 10.9407 10.9406 13.3333 7.99992 13.3333ZM7.99992 1.33334C4.32392 1.33334 1.33325 4.324 1.33325 8C1.33325 11.676 4.32392 14.6667 7.99992 14.6667C11.6759 14.6667 14.6666 11.676 14.6666 8C14.6666 4.324 11.6759 1.33334 7.99992 1.33334Z" fillRule="evenodd" clipRule="evenodd" />
    </Icon>
)
