export enum AuthFlowError {
    CanceledByUser = 'CanceledByUser',
    ServerError = 'ServerError'
}

export type FacebookAuthResponse = {
    accessToken: string
}

export type FacebookUserDetailsResponse = {
    accessToken: string,
    email: string,
    id: string,
    name: string,
    picture?: {
        data: {
            height: number,
            width: number,
            url: string,
            is_silhouette: boolean
        }
    }
}
