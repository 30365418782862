import React from 'react'
import { View } from 'react-native'
import { createStyles } from 'lib/styles'
import { Typography } from 'lib/components'
import { useStyles } from 'lib/hooks'

type ContactSectionItemProps = {
    title: string,
    description: string
    linkText: string,
    onPress: VoidFunction
}

export const ContactSectionItem: React.FunctionComponent<ContactSectionItemProps> = ({
    title,
    description,
    linkText,
    onPress
}) => {
    const { styles, theme } = useStyles(stylesheet)

    return (
        <View style={styles.wrapper}>
            <Typography.SmallSubheading>
                {title}
            </Typography.SmallSubheading>
            <Typography.Regular
                style={styles.description}
                forceColor={theme.colors.fog}
            >
                {description}
            </Typography.Regular>
            <Typography.Title
                bold
                onPress={onPress}
                style={styles.link}
                forceColor={theme.colors.orange}
            >
                {linkText}
            </Typography.Title>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        flex: 1,
        borderWidth: 1,
        borderRadius: 8,
        borderColor: theme.colors.darkSilver,
        backgroundColor: theme.colors.white,
        paddingHorizontal: theme.utils.gap(3),
        paddingVertical: theme.utils.gap(3),
        ...theme.utils.createShadow(2, 0, 8, 4, 4, theme.colors.webShadow(0.04))
    },
    description: {
        flex: 1,
        marginTop: theme.utils.gap(2),
        marginBottom: theme.utils.gap(3)
    },
    link: {
        textDecorationLine: 'underline'
    }
}))
