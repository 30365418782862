import { useTranslations } from 'lib/hooks'
import { PropertyType } from '../../types'

export const useGetPropertyType = () => {
    const T = useTranslations()

    return [
        {
            label: T.screens.pestControl.propertyType.smallHDB,
            value: PropertyType.SmallHDB
        },
        {
            label: T.screens.pestControl.propertyType.largeHDB,
            value: PropertyType.LargeHDB
        },
        {
            label: T.screens.pestControl.propertyType.smallCondo,
            value: PropertyType.SmallCondo
        },
        {
            label: T.screens.pestControl.propertyType.mediumCondo,
            value: PropertyType.MediumCondo
        },
        {
            label: T.screens.pestControl.propertyType.largeCondo,
            value: PropertyType.LargeCondo
        },
        {
            label: T.screens.pestControl.propertyType.smallLanded,
            value: PropertyType.SmallLanded
        },
        {
            label: T.screens.pestControl.propertyType.largeLanded,
            value: PropertyType.LargeLanded
        }
    ]
}
