import { getTrackingPermissionsAsync, isAvailable, requestTrackingPermissionsAsync } from 'expo-tracking-transparency'
import { useEffect } from 'react'
import { R } from '../utils'

export const useUserTracking = () => {
    const checkPermission = async () => {
        if (!isAvailable()) {
            return
        }

        const { granted, canAskAgain } = await getTrackingPermissionsAsync()

        if (granted || !canAskAgain) {
            return
        }

        const { granted: hasTrackingPermission } = await requestTrackingPermissionsAsync()

        if (!hasTrackingPermission) {
            return
        }

        // todo here user has a permission, do we need to do anything with AppFlyer?
    }

    useEffect(() => {
        checkPermission()
            .catch(R.T)
    }, [])
}
