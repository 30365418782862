import { ScrollView, View } from 'react-native'
import React, { useState } from 'react'
import { useNavigation, useRoute } from '@react-navigation/native'
import { useStyles } from 'lib/hooks'
import { ScreenNames } from 'lib/routing'
import { SidebarPosition } from 'lib/types'
import { Measurements } from 'lib/common'
import { UserPhoto } from 'features/user'
import { useIsAuthorizedAtom, useUserAtom } from 'lib/atoms'
import { createStyles } from 'lib/styles'
import { HeaderNotifications, HeaderUserIcon } from '../Header'
import { AnimatedSidebar } from '../AnimatedSidebar'
import { Touchable } from '../Touchable'
import { AppDrawerContent } from '../appLayout'

export const UserSidebar: React.FunctionComponent = () => {
    const { styles } = useStyles(stylesheet)
    const navigation = useNavigation()
    const [isUserDrawerOpen, setUserDrawerOpen] = useState(false)
    const [isAuthorized] = useIsAuthorizedAtom()
    const [user] = useUserAtom()
    const route = useRoute()

    return (
        <View style={styles.wrapper}>
            {isAuthorized
                ? (
                    <View style={styles.buttonsContainer}>
                        <Touchable
                            style={styles.userPhoto}
                            onPress={() => setUserDrawerOpen(true)}
                        >
                            <UserPhoto
                                size={36}
                                name={user.handle}
                            />
                        </Touchable>
                        <HeaderNotifications />
                    </View>
                ) : (
                    <HeaderUserIcon onPress={() => navigation.navigate(ScreenNames.Auth)} />
                )
            }
            <AnimatedSidebar
                position={SidebarPosition.Right}
                onClose={() => setUserDrawerOpen(false)}
                isOpen={isUserDrawerOpen}
            >
                <ScrollView
                    style={styles.container}
                    showsVerticalScrollIndicator={false}
                    contentContainerStyle={styles.scrollContainer}
                >
                    <AppDrawerContent
                        routeName={route.name}
                        onPress={() => setUserDrawerOpen(false)}
                    />
                </ScrollView>
            </AnimatedSidebar>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        height: Measurements.HeaderHeight,
        display: {
            ':w[992, ]': 'none',
            ':w[, 992]': 'flex'
        }
    },
    container: {
        paddingTop: theme.utils.gap(2),
        paddingHorizontal: theme.utils.gap(2)
    },
    userPhoto: {
        marginHorizontal: theme.utils.gap(1)
    },
    scrollContainer: {
        flex: 1
    },
    buttonsContainer: {
        height: '100%',
        flexDirection: 'row',
        borderLeftWidth: 1,
        borderColor: theme.components.userDropDown.borderColor,
        alignItems: 'center'
    }
}))
