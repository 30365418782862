import { Platform } from 'react-native'

export const isIOS = Platform.OS === 'ios'
export const isAndroid = Platform.OS === 'android'
export const isWeb = Platform.OS === 'web'
export const isNative = isAndroid || isIOS

export const isEdge = () => {
    if (isNative) {
        return false
    }

    const userAgent = window.navigator.userAgent
    const isEdgeLegacy = userAgent.indexOf('Edge/') > -1
    const isEdgeChromium = userAgent.indexOf('Edg/') > -1

    return isEdgeLegacy || isEdgeChromium
}
