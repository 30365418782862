import React from 'react'
import { View } from 'react-native'
import { createStyles } from 'lib/styles'
import { useStyles } from 'lib/hooks'
import { Typography } from 'lib/components'

type EmptyNotificationsProps = {
    emptyTitle: string,
    emptyMessage: string
}

export const EmptyNotifications: React.FunctionComponent<EmptyNotificationsProps> = ({
    emptyMessage,
    emptyTitle
}) => {
    const { styles } = useStyles(stylesheet)

    return (
        <View style={styles.container}>
            <View style={styles.text}>
                <Typography.Regular bold>
                    {emptyTitle}
                </Typography.Regular>
                <Typography.Regular style={styles.note}>
                    {emptyMessage}
                </Typography.Regular>
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        flexDirection: {
            lg: 'row',
            ':w[0, 1200]': 'column'
        },
        marginTop: {
            lg: theme.utils.gap(3),
            xs: theme.utils.gap(5)
        },
        marginHorizontal: {
            lg: 0,
            xs: theme.utils.gap(2)
        },
        paddingHorizontal: {
            lg: theme.utils.gap(2),
            xs: 0
        },
        justifyContent: 'center'
    },
    text: {
        height: 400,
        justifyContent: 'center',
        alignItems: 'center'
    },
    note: {
        color: theme.colors.darkGrey,
        marginTop: theme.utils.gap(2)
    }
}))
