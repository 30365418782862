import React, { useEffect } from 'react'
import { Keyboard, View } from 'react-native'
import { Field } from '@codegateinc/react-form-builder-v2'
import { Icons } from 'assets'
import { CustomFonts, Nullable } from 'lib/types'
import { Segment } from 'lib/analytics'
import { useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import {
    useAutoApplyPromoAtom,
    useIsAuthorizedAtom,
    usePromotionErrorAtom,
    useResetPromotionErrorAtom
} from 'lib/atoms'
import { Adapter, Touchable, Typography } from 'lib/components'
import { ErrorMessage } from 'lib/components/form/components/ErrorMessage'
import { linkingHelpers } from 'lib/utils'
import { ENV } from 'lib/config'
import { PromotionType } from 'lib/models'
import { GetPromotionResponse } from '../types'

type PromotionCodeInputProps = {
    isLoading: boolean,
    promoCode: Field<string>,
    promotionPackage: Nullable<GetPromotionResponse>,
    requestPromotion: (code: string) => void,
    showBillingMessage?: boolean,
    isPromoCodeEnabled?: boolean,
    isGuestBookingPromoApplied?: boolean,
    onRemovePromoCode: VoidFunction,
    setIsGuestBookingPromoCodeApplied?: (value: boolean) =>  void
}

export const PromotionCodeInput: React.FunctionComponent<PromotionCodeInputProps> = ({
    promoCode,
    isLoading,
    promotionPackage,
    requestPromotion,
    onRemovePromoCode,
    showBillingMessage,
    isPromoCodeEnabled,
    isGuestBookingPromoApplied,
    setIsGuestBookingPromoCodeApplied
}) => {
    const T = useTranslations()
    const [isAuthorized] = useIsAuthorizedAtom()
    const { styles, theme } = useStyles(stylesheet)
    const [autoApplyPromoCode] = useAutoApplyPromoAtom()
    const [promotionErrorAtom] = usePromotionErrorAtom()
    const [, setResetPromotionError] = useResetPromotionErrorAtom()

    useEffect(() => {
        setResetPromotionError()

        if (autoApplyPromoCode) {
            promoCode.onChangeValue(autoApplyPromoCode)
            requestPromotion(autoApplyPromoCode)
        }
    }, [])

    useEffect(() => {
        promoCode.setError(promotionErrorAtom)
    }, [promotionErrorAtom])

    const isFixedPromotion = promotionPackage?.promotion?.promo_type === PromotionType.Fixed
    const prefixAppliedPromotion = isFixedPromotion ? `${T.common.currencyShortCode} ` : ''
    const suffixAppliedPromotion = isFixedPromotion ? '' : '%'
    const promotionValue = isFixedPromotion ?
        promotionPackage?.promotion?.package?.price :
        promotionPackage?.promotion?.percent

    const renderPromotionSubtext = () => {
        if(isGuestBookingPromoApplied) {
            return (
                <View>
                    <Typography.Regular style={styles.guestBookingPromoCodeSubtext}>
                        {T.common.guestBookingPromoCodeSubtext}
                    </Typography.Regular>
                </View>
            )
        }

        return (
            <View style={styles.promotionRow}>
                <Touchable
                    onPress={() => linkingHelpers.openUrl(`${ENV.SENDHELPER_MARKETING_SITE_URL}/terms`)}
                    testID={T.accessibility.summaryColumnTCButton}
                    style={styles.termsAndConditionButton}>
                    <Typography.Regular style={styles.termsAndConditionsLink}>
                        {T.common.viewTermsAndConditions}
                    </Typography.Regular>
                </Touchable>
            </View>
        )
    }

    if (promotionPackage) {
        return (
            <View>
                <View style={styles.promotionWrapper}>
                    <View style={styles.promotionRow}>
                        <View style={styles.couponIconWrapper}>
                            <Icons.Coupon size={20} />
                            <Typography.Regular style={styles.discountValue}>
                                {`${prefixAppliedPromotion}${promotionValue}${suffixAppliedPromotion} ${T.common.off.toUpperCase()}`}
                            </Typography.Regular>
                        </View>
                        <Touchable
                            onPress={onRemovePromoCode}
                            hitSlopBottom={20}
                            hitSlopRight={20}
                            hitSlopLeft={20}
                            hitSlopTop={20}
                            testID={T.accessibility.summaryColumnPromoCodeRemoveButton}
                        >
                            <Icons.Close size={12} />
                        </Touchable>
                    </View>
                    <View style={styles.promotionRow}>
                        <View style={styles.promotionContainer}>
                            <Typography.Regular style={styles.promotionDescription}>
                                {`${promotionPackage.promotion.promo_code.toUpperCase()}`} {isGuestBookingPromoApplied ? T.common.added : T.common.applied}
                            </Typography.Regular>
                        </View>
                        {renderPromotionSubtext()}
                    </View>
                </View>
                {showBillingMessage && (
                    <Typography.Error
                        style={styles.infoMessage}
                        forceColor={theme.colors.mouse}
                    >
                        {T.components.discountCodes.infoMessage}
                    </Typography.Error>
                )}
            </View>
        )
    }

    const onClickApplyPromoCode = () => {
        requestPromotion(promoCode.value)
        Keyboard.dismiss()
    }

    const onClickApplyGuestPromoCode = () => {
        setIsGuestBookingPromoCodeApplied && setIsGuestBookingPromoCodeApplied(true)
    }

    const onRemoveGuestPromoCode = () => {
        setIsGuestBookingPromoCodeApplied && setIsGuestBookingPromoCodeApplied(false)
        onRemovePromoCode()
    }

    const renderGuestBookingPromoApplied = () => {
        if(!isGuestBookingPromoApplied) {
            return null
        }

        return (
            <View>
                <View style={styles.promotionWrapper}>
                    <View style={styles.promotionRow}>
                        <View style={styles.couponIconWrapper}>
                            <Icons.Coupon size={20} />
                            <Typography.Regular style={styles.discountValue}>
                                {`${promoCode.value.toUpperCase()}`} {isGuestBookingPromoApplied ? T.common.added : T.common.applied}
                            </Typography.Regular>
                        </View>
                        <Touchable
                            onPress={onRemoveGuestPromoCode}
                            hitSlopBottom={20}
                            hitSlopRight={20}
                            hitSlopLeft={20}
                            hitSlopTop={20}
                        >
                            <Icons.Close size={12} />
                        </Touchable>
                    </View>
                    {renderPromotionSubtext()}
                </View>
            </View>
        )
    }

    const renderPromoCodeInput = () => {
        if(isPromoCodeEnabled) {
            return (
                <View style={styles.discountCodeContainer}>
                    <View style={styles.codeInput}>
                        <Adapter.TextInput
                            {...promoCode}
                            hideErrorMessage
                            errorContentStyles={{ marginTop: 0 }}
                        />
                    </View>
                    <Touchable
                        onPress={onClickApplyGuestPromoCode}
                        style={{
                            ...styles.codeButton,
                            backgroundColor: theme.colors.sun
                        }}
                    >
                        <Icons.Arrow
                            size={10}
                            forceColor={theme.colors.night}
                        />
                    </Touchable>
                </View>
            )
        }

        return (
            <View style={styles.discountCodeContainer}>
                <View style={styles.codeInput}>
                    <Adapter.TextInput
                        {...promoCode}
                        disabled={!isAuthorized}
                        testID={T.accessibility.summaryColumnPromoCodeInput}
                        inputProps={{
                            onSubmitEditing: onClickApplyPromoCode
                        }}
                        onBlur={() => {
                            Segment.bookingPromoCodeEntered({})
                        }}
                        hideErrorMessage
                        errorContentStyles={{ marginTop: 0 }}
                    />
                </View>
                <Touchable
                    onPress={onClickApplyPromoCode}
                    testID={T.accessibility.summaryColumnPromoCodeApplyButton}
                    disabled={!promoCode.value || isLoading || !isAuthorized}
                    style={{
                        ...styles.codeButton,
                        backgroundColor: promoCode.value || isLoading
                            ? theme.colors.sun
                            : theme.colors.silver
                    }}
                >
                    <Icons.Arrow
                        size={10}
                        forceColor={promoCode.value
                            ? theme.colors.night
                            : theme.colors.mouse
                        }
                    />
                </Touchable>
            </View>
        )
    }

    if(isGuestBookingPromoApplied) {
        return renderGuestBookingPromoApplied()
    }

    return (
        <View>
            {!isAuthorized && !isPromoCodeEnabled && (
                <View style={styles.unauthorizedMessage}>
                    <Typography.Label forceColor={theme.colors.darkGrey}>
                        {T.components.bookingAuth.promoMessage}
                    </Typography.Label>
                </View>
            )}
            {renderPromoCodeInput()}
            <ErrorMessage
                renderHTMLContent
                text={promotionErrorAtom}
                contentStyles={styles.errorContent}
                forceColor={theme.colors.flame}
                messageSize={12}
            />
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    discountCodeContainer: {
        flexDirection: 'row',
        marginTop: theme.utils.gap(2)
    },
    codeButton: {
        width: theme.components.input.height,
        height: theme.components.input.height,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.colors.sun,
        borderRadius: theme.components.button.borderRadius
    },
    codeInput: {
        flex: 1,
        marginRight: theme.utils.gap(1)
    },
    couponIconWrapper: {
        flexDirection: 'row'
    },
    couponIcon: {
        marginLeft: theme.utils.gap(1)
    },
    promotionWrapper: {
        flexDirection: 'column',
        borderRadius: 8,
        borderWidth: 1,
        alignItems: 'center',
        justifyContent: 'space-between',
        marginVertical: theme.utils.gap(2),
        paddingVertical: theme.utils.gap(1),
        paddingHorizontal: theme.utils.gap(1.25),
        borderColor: theme.colors.sun,
        backgroundColor: theme.colors.yellow
    },
    promotionRow: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        flexWrap: 'wrap'
    },
    infoMessage: {
        marginBottom: theme.utils.gap(2)
    },
    unauthorizedMessage: {
        borderRadius: 8,
        marginTop: theme.utils.gap(1),
        backgroundColor: theme.colors.silver,
        padding: theme.utils.gap(2)
    },
    discountValue: {
        fontSize: 16,
        fontFamily: CustomFonts.Roboto600,
        lineHeight: 20,
        marginLeft: theme.utils.gap(1)
    },
    promotionDescription: {
        fontSize: 13,
        fontFamily: CustomFonts.Roboto600,
        lineHeight: 14,
        color: theme.colors.grey,
        marginTop: theme.utils.gap(1)
    },
    termsAndConditionsLink: {
        color: theme.colors.orange,
        fontSize: 13,
        fontFamily: CustomFonts.Roboto500,
        lineHeight: 16,
        textDecorationLine: 'underline',
        marginTop: theme.utils.gap(1),
        textTransform: 'capitalize'
    },
    guestBookingPromoCodeSubtext: {
        fontSize: 13,
        fontFamily: CustomFonts.Roboto400,
        lineHeight: 16,
        marginTop: theme.utils.gap(1)
    },
    termsAndConditionButton: {
        backgroundColor: theme.colors.transparent,
        userSelect: 'none'
    },
    promotionContainer: {
        maxWidth: '100%'
    },
    errorContent: {
        marginTop: 0,
        alignItems: 'flex-start'
    }
}))
