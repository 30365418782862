import React, { useEffect, useState } from 'react'
import { ActivityIndicator, View } from 'react-native'
import { Icons } from 'assets'
import { UseFormReturn } from '@codegateinc/react-form-builder-v2'
import { Breakpoint, createStyles } from 'lib/styles'
import { isNative } from 'lib/common'
import { dateHelpers, emitter } from 'lib/utils'
import { BookingFrequency, CustomFonts, DayOfWeekMapping, Nullable } from 'lib/types'
import { useIsWithinBreakpoints, useStyles, useTranslations } from 'lib/hooks'
import { Calendar, CustomModal, Touchable, Typography } from 'lib/components'
import { JobDetailsResponse } from 'features/jobs/types'
import { PauseBookingsFormShape } from 'features/jobs/forms'
import { MAX_PAUSE_DURATION_DAYS_DEFAULT } from 'lib/utils/date'

type PauseSubscriptionModalProps = {
    isOpen: boolean,
    isLoading?: boolean,
    formReturn: UseFormReturn<PauseBookingsFormShape>,
    job: JobDetailsResponse,
    onClose: VoidFunction,
    onSubmit: VoidFunction,
    onRescheduleBooking?: VoidFunction,
}

export const PauseSubscriptionModal: React.FunctionComponent<PauseSubscriptionModalProps> = ({
    isOpen,
    isLoading,
    job,
    onClose,
    onSubmit,
    formReturn,
    onRescheduleBooking
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const { form } = formReturn
    const isMobile = useIsWithinBreakpoints(Breakpoint.XS, Breakpoint.SM) || isNative

    const [selectedDate, setSelectedDate] = useState<string>('')
    const [startRangeDate, setStartRangeDate] = useState<string>('')
    const [endRangeDate, setEndRangeDate] = useState<string>('')
    const [maxDate, setMaxDate] = useState<string>('')
    const [minDate, setMinDate] = useState<string>('')
    const [selectedDays, setSelectedDays] = useState<Record<string, Nullable<string>>>({})

    useEffect(() => {
        const schedule = job?.job?.schedule || []
        const selectedDays = {}
        const availablePauseStartDate = job?.job?.availablePauseStartDate || new Date().toISOString()
        const maxPauseDurationDays = job?.job?.maxPauseDurationDays || MAX_PAUSE_DURATION_DAYS_DEFAULT
        const minDate = dateHelpers.addDaysToDate(availablePauseStartDate, 0)
        const maxDate = dateHelpers.addDaysToDate(availablePauseStartDate, maxPauseDurationDays)

        schedule?.forEach(item => {
            selectedDays[DayOfWeekMapping[item.day]] = item.activated ? item.start_time : null
        })

        setSelectedDays(selectedDays)
        setMinDate(minDate)
        setMaxDate(maxDate)
    }, [job])

    useEffect(() => {
        const dates = selectedDate.split(', ')

        switch (dates.length) {
            case 0:
                setStartRangeDate('')
                setEndRangeDate('')
                break
            case 1:
                setStartRangeDate(dates[0])
                setEndRangeDate('')
                break
            default:
                setStartRangeDate(dates[0])
                setEndRangeDate(dates[dates.length - 1])
                break
        }
    }, [selectedDate])

    useEffect(() => {
        form.startDate.onChangeValue(startRangeDate)
        form.endDate.onChangeValue(endRangeDate)
    }, [startRangeDate, endRangeDate])

    const shouldDisableContinueButton = isLoading || !endRangeDate

    const renderCloseButton = () => (
        <Touchable
            style={{
                ...styles.closeButton,
                ...(isMobile ? {} : styles.noBorder)
            }}
            onPress={onClose}
            hitSlopBottom={20}
            hitSlopRight={20}
            hitSlopLeft={20}
            hitSlopTop={20}>
            <Icons.Close size={14} forceColor={theme.colors.darkSlate} />
        </Touchable>
    )

    const renderHeader = () => (
        <>
            {
                isMobile && (
                    <View style={styles.mobileHeaderContainer}>
                        <View style={styles.actionPlaceholderContainer} />
                        <View>
                            <Typography.Subheading>
                                {T.screens.jobDetails.pauseSubscriptionModal.title}
                            </Typography.Subheading>
                        </View>
                        <View style={styles.mobileActionContainer}>
                            { renderCloseButton() }
                        </View>
                    </View>
                )
            }

            <View style={{
                ...styles.headerContainer,
                ...(isMobile ? {} : styles.withoutPaddingHorizontal)
            }}>
                <View style={styles.headerTitleContainer}>
                    {
                        !isMobile && (
                            <Typography.SmallSubheading>
                                {T.screens.jobDetails.pauseSubscriptionModal.title}
                            </Typography.SmallSubheading>
                        )
                    }
                    <Typography.Regular style={styles.subTitle}>
                        {T.screens.jobDetails.pauseSubscriptionModal.subTitle}
                    </Typography.Regular>
                </View>
                {
                    !isMobile && (
                        <View style={styles.headerActionContainer}>
                            { renderCloseButton() }
                        </View>
                    )
                }
            </View>
        </>
    )

    const handleRescheduleBooking = () => {
        if (onRescheduleBooking) {
            onRescheduleBooking()

            return
        }

        onClose()
        emitter.emit('openRescheduleModal', job)
    }

    const renderActionsContainer = () => (
        <View style={{
            ...styles.actionContainer,
            ...(isMobile ? styles.fixedContainer : styles.withoutPaddingHorizontal)
        }}>
            <Touchable
                testID={T.accessibility.jobDetailsScreen.pauseSubscription.reschedule}
                disabled={isLoading}
                style={{
                    ...styles.button,
                    ...styles.rescheduleButton
                }}
                onPress={handleRescheduleBooking}
            >
                {
                    isLoading ? <ActivityIndicator size="small" color={theme.colors.orange} /> : (
                        <Typography.Regular forceColor={isLoading ? theme.colors.fog : theme.colors.orange}>
                            {T.screens.jobDetails.pauseSubscriptionModal.reschedulePlan}
                        </Typography.Regular>
                    )
                }
            </Touchable>

            <View style={styles.spacer} />

            <Touchable
                testID={T.accessibility.jobDetailsScreen.pauseSubscription.continue}
                style={{
                    ...styles.button,
                    ...styles.continueButton,
                    ...(shouldDisableContinueButton ? styles.disabledButton : {})
                }}
                onPress={onSubmit}
                disabled={shouldDisableContinueButton}
            >
                <Typography.Regular forceColor={shouldDisableContinueButton ? theme.colors.fog : theme.colors.darkSlate}>
                    {T.screens.jobDetails.pauseSubscriptionModal.continue}
                </Typography.Regular>
            </Touchable>
        </View>
    )

    return (
        <CustomModal
            testID={T.accessibility.jobDetailsScreen.pauseSubscription.modal}
            insetsPadding={isMobile}
            isFullWindowSize={isMobile}
            onClose={onClose}
            isOpen={isOpen}
            closeButtonStyles={styles.closeButtonStyles}
            childrenWrapper={isMobile ? styles.mobileChildrenWrapper : styles.childrenWrapper}
            contentStyles={isMobile ? styles.mobileContentStyles : styles.contentStyles}
        >
            <View style={isMobile ? styles.floatingContainer : {}}>
                { renderHeader() }

                <View style={styles.calendarContainer}>
                    <View style={{
                        ...styles.headerCalendarContainer,
                        ...(isMobile ? {
                            ...styles.noBorderRadius,
                            ...styles.noBorderLeftAndRight
                        } : {})
                    }}>
                        <View style={{
                            ...styles.selectDateContainer,
                            ...styles.borderRight,
                            ...styles.startRangeDate,
                            ...(startRangeDate ? {} : styles.highlightBackground),
                            ...(isMobile ? styles.noBorderRadius : {})
                        }}>
                            <Typography.Regular style={{
                                ...styles.dateTitle,
                                ...(startRangeDate ? {}: styles.highlightText)
                            }}>
                                {T.screens.jobDetails.pauseSubscriptionModal.pauseFrom}
                            </Typography.Regular>
                            <Typography.Regular style={styles.dateSubTitle}>
                                {
                                    startRangeDate ?
                                        dateHelpers.pauseShortDate(startRangeDate)  :
                                        T.screens.jobDetails.pauseSubscriptionModal.selectDate
                                }
                            </Typography.Regular>
                        </View>
                        <View style={{
                            ...styles.selectDateContainer,
                            ...styles.endRangeDate,
                            ...(startRangeDate ? styles.highlightBackground : {})
                        }}>
                            <Typography.Regular style={{
                                ...styles.dateTitle,
                                ...(startRangeDate ? styles.highlightText : {})
                            }} >
                                {T.screens.jobDetails.pauseSubscriptionModal.resumeAfter}
                            </Typography.Regular>
                            <Typography.Regular style={styles.dateSubTitle}>
                                {
                                    endRangeDate ?
                                        dateHelpers.pauseShortDate(endRangeDate) :
                                        T.screens.jobDetails.pauseSubscriptionModal.selectDate
                                }
                            </Typography.Regular>
                        </View>
                    </View>
                    <View style={styles.calendarPickerContainer}>
                        <Calendar
                            minDate={minDate}
                            maxDate={maxDate}
                            liteMode
                            fullScreen
                            selectedDate={selectedDate}
                            setSelectedDate={setSelectedDate}
                            frequency={{
                                value:  job?.job?.frequency === BookingFrequency.Weekly ?
                                    BookingFrequency.Weekly :
                                    BookingFrequency.Fortnightly,
                                label: '',
                                title: '',
                                description: '',
                                list: []
                            }}
                            selectedDays={selectedDays}
                            setSelectedDays={setSelectedDays}
                            minHoursNotice={0}
                            isMultiSelect
                            allowToggle
                            showDateRange
                            simpleMode={isMobile}
                            onDayPress={() => null}
                            selectedDaysChangeValue={() => null}
                        />
                    </View>
                </View>

                <View style={styles.noticeContainer}>
                    <View style={styles.noticeIconContainer}>
                        <Icons.Info size={14} forceColor={theme.colors.grey} />
                    </View>
                    <Typography.Label style={styles.notice}>
                        {T.screens.jobDetails.pauseSubscriptionModal.notice}
                    </Typography.Label>
                </View>

                {renderActionsContainer()}
            </View>
        </CustomModal>
    )
}

const stylesheet = createStyles(theme => ({
    headerContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingHorizontal: theme.utils.gap(2)
    },
    headerTitleContainer: {
        flexDirection: 'column'
    },
    headerActionContainer: {
        flexDirection: 'column',
        alignItems: 'flex-end'
    },
    closeButtonStyles: {
        display: 'none'
    },
    calendarContainer: {
        marginTop: theme.utils.gap(2),
        flexDirection: 'column'
    },
    headerCalendarContainer: {
        flexDirection: 'row',
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        borderLeftWidth: 1,
        borderTopWidth: 1,
        borderRightWidth: 1,
        borderColor: theme.colors.fog
    },
    selectDateContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        paddingVertical: theme.utils.gap(1)
    },
    dateTitle: {
        marginBottom: theme.utils.gap(0.5),
        fontFamily: CustomFonts.Poppins500,
        fontSize: 13,
        color: theme.colors.grey
    },
    dateSubTitle: {
        fontFamily: CustomFonts.Poppins400,
        fontSize: 14
    },
    calendarPickerContainer: {
        paddingTop: theme.utils.gap(0)
    },
    subTitle: {
        marginTop: theme.utils.gap(1)
    },
    borderRight: {
        borderRightWidth: 1,
        borderRightColor: theme.colors.fog
    },
    childrenWrapper: {
        width: {
            lg: 550,
            md: 450,
            sm: '100%',
            xs: '100%'
        },
        marginTop: 0
    },
    highlightBackground: {
        backgroundColor: theme.colors.yellow
    },
    highlightText: {
        color: theme.colors.darkSlate,
        fontFamily: CustomFonts.Poppins600
    },
    startRangeDate: {
        borderTopLeftRadius: 8
    },
    endRangeDate: {
        borderTopRightRadius: 8
    },
    noticeContainer: {
        flexDirection: 'row',
        paddingVertical: theme.utils.gap(1.5),
        paddingHorizontal: theme.utils.gap(isNative ? 1 : 2)
    },
    noticeIconContainer: {
        marginRight: theme.utils.gap(0.5)
    },
    notice: {
        color: theme.colors.grey,
        fontFamily: CustomFonts.Poppins500
    },
    actionContainer: {
        width: isNative ? undefined : '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingVertical: theme.utils.gap(1),
        paddingHorizontal: theme.utils.gap(2)
    },
    button: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.utils.gap(1.25),
        borderWidth: 2,
        borderRadius: 4
    },
    rescheduleButton: {
        borderColor: theme.colors.orange
    },
    continueButton: {
        backgroundColor: theme.colors.sun,
        borderColor: theme.colors.sun
    },
    disabledButton: {
        backgroundColor: theme.colors.silver,
        borderColor: theme.colors.silver
    },
    spacer: {
        width: theme.utils.gap(2),
        height: theme.utils.gap(1)

    },
    contentStyles: {
        paddingBottom: theme.utils.gap(1.5)
    },
    mobileContentStyles: {
        paddingBottom: 0,
        paddingHorizontal: 0,
        height: '100%'
    },
    closeButton: {
        width: 30,
        height: 30,
        borderRadius: 4,
        borderWidth: 1,
        borderColor: theme.colors.silver,
        justifyContent: 'center',
        alignItems: 'center'
    },
    noBorder: {
        border: 'none'
    },
    noBorderRadius: {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0
    },
    noBorderLeftAndRight: {
        borderLeftWidth: 0,
        borderRightWidth: 0
    },
    mobileHeaderContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    mobileActionContainer: {
        alignItems: 'center',
        marginRight: theme.utils.gap(2)
    },
    mobileTitleContainer: {
        textAlign: 'center'
    },
    actionPlaceholderContainer: {
        width: 30,
        height: 30,
        marginLeft: theme.utils.gap(2)
    },
    floatingContainer: {
        position: 'relative',
        height: '100%'
    },
    fixedContainer: {
        position: 'absolute',
        bottom: 0,
        paddingHorizontal: theme.utils.gap(2),
        paddingTop: theme.utils.gap(2),
        paddingBottom: theme.utils.gap(3.5),
        backgroundColor: theme.colors.white,
        borderTopLeftRadius: 12,
        borderTopRightRadius: 12,
        ...theme.utils.createShadow(0, 1, 20, 70)
    },
    mobileChildrenWrapper: {
        width: '100%',
        marginTop: 0,
        height: '100%'
    },
    withoutPaddingHorizontal: {
        paddingHorizontal: 0
    }
}))
