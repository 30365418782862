import React from 'react'
import { View } from 'react-native'
import { createStyles } from 'lib/styles'
import { CustomModal, Touchable, Typography } from 'lib/components'
import { useStyles } from 'lib/hooks'
import { CustomFonts } from 'lib/types'
import { Icons } from 'assets'
import { isNative } from 'lib/common'
import { EquipmentDisclaimerPopup } from '../types'

type EquipmentDisclaimerModalProps = {
    isOpen: boolean,
    closeModal: () => void,
    sources?: EquipmentDisclaimerPopup
}

export const EquipmentDisclaimerModal: React.FunctionComponent<EquipmentDisclaimerModalProps> =
    ({ sources, isOpen, closeModal }) => {
        const { styles } = useStyles(stylesheet)

        return (
            <CustomModal
                onClose={closeModal}
                closeButtonStyles={styles.closeButton}
                contentStyles={styles.modalContent}
                childrenWrapper={styles.modalChildrenWrapper}
                customScrollViewStyles={styles.customScrollViewStyles}
                isOpen={isOpen}
            >
                <View style={styles.modalContainer}>
                    <View style={styles.headerContainer}>
                        <Typography.Title style={styles.title}>
                            {sources?.title}
                        </Typography.Title>
                        <View style={styles.modalActionContainer}>
                            <Touchable
                                onPress={closeModal}
                                hitSlopBottom={20}
                                hitSlopRight={20}
                                hitSlopLeft={20}
                                hitSlopTop={20}>
                                <Icons.Close size={isNative ? 16 : 20} />
                            </Touchable>
                        </View>
                    </View>
                    <View style={styles.contentContainer}>
                        <Typography.Regular style={styles.description}>
                            {sources?.subtitle}
                        </Typography.Regular>
                        <View style={styles.tableContainer}>
                            <View style={styles.table}>
                                {
                                    sources?.listItems?.map((item, index) => (
                                        <View key={index} style={styles.row}>
                                            <View style={styles.cell}>
                                                <Typography.Regular style={styles.cellItem}>{item?.roomType}</Typography.Regular>
                                            </View>
                                            <View style={styles.cell}>
                                                {
                                                    item?.items?.map((itemElement, itemIndex) => (
                                                        <Typography.Regular key={itemIndex} style={styles.cellDescription}>{itemElement}</Typography.Regular>
                                                    ))
                                                }
                                            </View>
                                        </View>
                                    ))
                                }
                            </View>
                        </View>
                    </View>
                </View>
            </CustomModal>

        )
    }

const stylesheet = createStyles(theme => ({
    container: {
        flex: 1
    },
    modalContainer: {
        flex: 1
    },
    headerContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingBottom: theme.utils.gap(isNative ? 1 : 2),
        borderBottomWidth: 1,
        borderBottomColor: theme.colors.fog
    },
    title: {
        fontSize: isNative ? 18 : 22,
        fontFamily: CustomFonts.Poppins500,
        lineHeight: 30
    },
    modalActionContainer: {
        paddingLeft: theme.utils.gap(1)
    },
    modalContent:{
        paddingTop: 0,
        paddingBottom: theme.utils.gap(2)
    },
    modalChildrenWrapper: {
        paddingTop: 0
    },
    contentContainer: {
        paddingVertical: theme.utils.gap(2)
    },
    description: {
        fontSize: 14,
        fontFamily: CustomFonts.Roboto400,
        lineHeight: 18,
        color: theme.colors.darkGrey
    },
    tableContainer: {
        paddingTop: theme.utils.gap(2)
    },
    table: {
        borderWidth: 1,
        borderColor: theme.colors.fog,
        borderRadius: 4
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        borderBottomWidth: 1,
        borderColor: theme.colors.fog,
        padding: theme.utils.gap(1)
    },
    cell: {
        flex: 1,
        padding: theme.utils.gap(1),
        fontSize: 14,
        color: theme.colors.darkGrey
    },
    cellItem: {
        fontFamily: CustomFonts.Roboto600
    },
    cellDescription: {
        color: theme.colors.darkGrey
    },
    closeButton: {
        display: 'none'
    },
    customScrollViewStyles: {
        marginTop: theme.utils.gap(2)
    }
}))
