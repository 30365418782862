import { useMutation } from '@tanstack/react-query'
import { useFetcher } from 'lib/api'
import { HttpMethod, ErrorResponse, Response } from 'lib/types'
import { ChatArchiveRequest, ChatArchiveResponse, ChatUnreadRequest, ChatUnreadResponse } from './types'

export const getChatArchiveMessages = () => {
    const fetcher = useFetcher<ChatArchiveResponse>(HttpMethod.POST, '/chat-get-archive')

    return useMutation<Response<ChatArchiveResponse>, ErrorResponse, ChatArchiveRequest>(fetcher)
}

export const getChatUnreadMessages = () => {
    const fetcher = useFetcher<ChatUnreadResponse>(HttpMethod.POST, '/chat/archive/unread')

    return useMutation<Response<ChatUnreadResponse>, ErrorResponse, ChatUnreadRequest>(fetcher)
}
