import { useEffect } from 'react'
import { BookingFrequency } from 'lib/types'

export const useAutoSelectFrequency = (
    autoSelectFrequencyJobType: boolean,
    postalcode: string | undefined,
    frequencyOptions,
    form,
    setResetAutoSelectFrequencyJobType: () => void
) => {
    useEffect(() => {
        if (!autoSelectFrequencyJobType || !postalcode) {
            return
        }

        const weeklyIndex = frequencyOptions.findIndex(option => option.value === BookingFrequency.Weekly)

        if (weeklyIndex !== -1) {
            form.frequency.onChangeValue(frequencyOptions[weeklyIndex])
            setResetAutoSelectFrequencyJobType()
        }
    }, [autoSelectFrequencyJobType, postalcode, frequencyOptions, form, setResetAutoSelectFrequencyJobType])
}

