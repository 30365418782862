import React from 'react'
import { View } from 'react-native'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { useContactSections } from '../hooks'
import { ContactSectionItem } from './ContactSectionItem'

export const ContactUsSections: React.FunctionComponent = () => {
    const { styles } = useStyles(stylesheet)
    const contactSections = useContactSections()

    return (
        <View style={styles.wrapper}>
            {contactSections.map((item, index) => (
                <ContactSectionItem
                    key={index}
                    {...item}
                />
            ))}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        flexDirection: {
            xs: 'column',
            lg: 'row'
        },
        gap: theme.utils.gap(2),
        marginTop: theme.utils.gap(1)
    }
}))
