import { useTranslations } from 'lib/hooks'
import { HandymanFormShape } from '../../forms'

export const useHandymanAdditionalInfo = (form: HandymanFormShape) => {
    const T = useTranslations()
    const materialsParagraph = form.materials
        ? T.screens.handyman.formFields.materials.trueText
        : T.screens.handyman.formFields.materials.falseText
    const paragraphs = form.serviceType.map((item, index: number) => {
        const parts = item.part || []

        return `
        ${index + 1}. ${item.category} - ${item.item} ${item.type || ''} ${parts.length !== 0 ? parts.join(', ') : ''}
            ${item.comment || ''} ${item.othersComment || ''}${item.comment || item.othersComment ? '\n' : ''}`
    })

    return `
    ${T.components.handymanServiceSelect.serviceItems}
    ${paragraphs.join('')}
    ${T.screens.handyman.formFields.materials.title}:
        ·   ${materialsParagraph}`
}
