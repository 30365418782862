import React, { useState } from 'react'
import { View, ViewStyle } from 'react-native'
import { ENV } from 'lib/config'
import { createStyles } from 'lib/styles'
import { linkingHelpers } from 'lib/utils'
import { useUserTokenAtom } from 'lib/atoms'
import { useQueryHelpers, useStyles, useTranslations } from 'lib/hooks'
import { Button, Rating, Touchable, Typography } from 'lib/components'
import { getJobDetails, rateJob } from '../actions'
import { JobDetailsResponse } from '../types'
import { SetAsFavourite } from './SetAsFavourite'

type JobRatingProps = {
    job: JobDetailsResponse,
    disableBorders?: boolean,
    style?: ViewStyle
}

export const JobRating: React.FunctionComponent<JobRatingProps> = ({
    job,
    disableBorders,
    style
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const [token] = useUserTokenAtom()
    const jobRating = job.job.jobRating || 0
    const isRated = Boolean(job.job.jobRating)
    const { onRequestError } = useQueryHelpers()
    const [rateValue, setRateValue] = useState(jobRating)
    const { mutate, isLoading } = rateJob()
    const { refetch } = getJobDetails(job.job._id)

    const onRate = () => {
        mutate(
            {
                id: job.job._id,
                token,
                rate: rateValue
            },
            {
                onSuccess: () => refetch(),
                onError: onRequestError
            }
        )
    }

    return (
        <View
            style={{
                ...styles.content,
                ...(!disableBorders ? styles.borders : {}),
                ...style
            }}
        >
            <Typography.Regular
                bold
                style={styles.alignCenter}
            >
                {jobRating === 1
                    ? T.components.rating.titleLowRating
                    : T.components.rating.title
                }
            </Typography.Regular>
            {!isRated && (
                <View style={styles.message}>
                    <Typography.Label forceColor={theme.colors.mouse}>
                        {T.components.rating.label}
                    </Typography.Label>
                </View>
            )}
            <View style={styles.rating}>
                <Rating
                    value={rateValue}
                    onRate={setRateValue}
                    rated={isRated}
                />
            </View>
            {jobRating > 3 && (
                <SetAsFavourite
                    supplier={job.job.supplier}
                    supplyId={job.job.supply.id}
                />
            )}
            {!isRated && (
                <Button
                    width={280}
                    onPress={onRate}
                    isLoading={isLoading}
                    text={T.common.submit}
                />
            )}
            {jobRating === 1 && (
                <React.Fragment>
                    <Typography.Label forceColor={theme.colors.mouse}>
                        {T.components.rating.speakToUsMessage}
                    </Typography.Label>
                    <Touchable
                        style={styles.contact}
                        onPress={() => linkingHelpers.openUrl(`${ENV.SENDHELPER_MARKETING_SITE_URL}/contact`)}
                    >
                        <Typography.Label
                            bold
                            forceColor={theme.colors.orange}
                        >
                            {T.components.rating.speakToUs}
                        </Typography.Label>
                    </Touchable>
                </React.Fragment>
            )}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    content: {
        alignItems: 'center',
        marginTop: theme.utils.gap(3),
        paddingHorizontal: theme.utils.gap(2),
        paddingVertical: theme.utils.gap(3),
        backgroundColor: theme.colors.white
    },
    borders: {
        borderRadius: 8,
        borderWidth: 1,
        borderColor: theme.colors.silver,
        ...theme.utils.createShadow(2, 0, 8, 4, 4, theme.colors.webShadow(0.04))
    },
    alignCenter: {
        textAlign: 'center'
    },
    message: {
        marginTop: theme.utils.gap(2)
    },
    rating: {
        marginVertical: theme.utils.gap(2)
    },
    contact: {
        marginTop: theme.utils.gap(2),
        borderBottomWidth: 1,
        borderBottomColor: theme.colors.orange
    }
}))
