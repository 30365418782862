import { useNavigation } from '@react-navigation/native'
import { useTranslations } from 'lib/hooks'
import { ScreenNames } from 'lib/routing'

export const useBookingsTabs = () => {
    const T = useTranslations()
    const navigation = useNavigation()

    return [
        {
            text: T.components.bookingsTabs.upcoming,
            screenName: ScreenNames.Bookings,
            testID: T.accessibility.bookingsScreen.tabs.upcoming,
            onPress: () => navigation.navigate(ScreenNames.Bookings)
        },
        {
            text: T.components.bookingsTabs.completed,
            screenName: ScreenNames.BookingsCompleted,
            testID: T.accessibility.bookingsScreen.tabs.completed,
            onPress: () => navigation.navigate(ScreenNames.BookingsCompleted)
        },
        {
            text: T.components.bookingsTabs.cancelled,
            screenName: ScreenNames.BookingsCancelled,
            testID: T.accessibility.bookingsScreen.tabs.cancelled,
            onPress: () => navigation.navigate(ScreenNames.BookingsCancelled)
        }
    ]
}
