import React, { useState } from 'react'
import { View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import Animated, { runOnUI } from 'react-native-reanimated'
import { isNative } from 'lib/common'
import { useAccordion, useStyles } from 'lib/hooks'
import { Address } from 'lib/models'
import { Segment } from 'lib/analytics'
import { createStyles } from 'lib/styles'
import { Touchable } from 'lib/components'
import { ScreenNames } from 'lib/routing'
import { stringHelpers } from 'lib/utils'
import { PrimaryAddressBadge } from './PrimaryAddressBadge'
import { AddressTileTitle } from './AddressTileTitle'
import { AddressTileBody } from './AddressTileBody'
import { AddressDeleteModal } from './AddressDeleteModal'

type UserAddressTileProps = Address

export const UserAddressTile: React.FunctionComponent<UserAddressTileProps> = props => {
    const { styles } = useStyles(stylesheet)
    const navigation = useNavigation()
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
    const { setHeight, animatedHeightStyle, aref, isOpened } = useAccordion()
    const addressUnit = stringHelpers.getFormattedAddressUnit(props.unit)

    return (
        <View
            style={props.isPrimary
                ? {
                    ...styles.container,
                    ...styles.isPrimary
                }
                : styles.container
            }
        >
            <AddressDeleteModal
                address={props}
                isOpen={isDeleteModalOpen}
                onClose={() => setIsDeleteModalOpen(false)}
            />
            <Touchable
                style={styles.title}
                activeOpacity={0.95}
                onPress={() => {
                    if (!isOpened.value) {
                        Segment.addressExpanded({})
                    }

                    runOnUI(setHeight)()
                }}
            >
                <AddressTileTitle
                    name={`${addressUnit}${props.address}, ${props.postcode}`}
                    location={props.district}
                />
                <PrimaryAddressBadge isPrimary={props.isPrimary} />
            </Touchable>
            <Animated.View style={animatedHeightStyle}>
                <View
                    ref={aref}
                    collapsable={false}
                    style={styles.accordionWrapper}
                >
                    <AddressTileBody
                        address={props}
                        onEdit={() => navigation.navigate(ScreenNames.UserEditAddress, {
                            address: props
                        })}
                        onDelete={() => setIsDeleteModalOpen(true)}
                    />
                </View>
            </Animated.View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    accordionWrapper: {
        left: 0,
        right: 0,
        position: isNative
            ? 'absolute'
            : undefined
    },
    container: {
        marginTop: {
            lg: theme.utils.gap(3),
            xs: theme.utils.gap(1)
        },
        marginHorizontal: {
            lg: 0,
            xs: theme.utils.gap(1)
        },
        paddingHorizontal: theme.utils.gap(2),
        borderColor: theme.colors.silver,
        overflow: 'hidden',
        borderWidth: 1,
        borderRadius: 8,
        minHeight: 87
    },
    title: {
        flex: 1,
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingVertical: theme.utils.gap(2)
    },
    isPrimary: {
        backgroundColor: theme.colors.latte,
        borderColor: theme.colors.latte
    }
}))
