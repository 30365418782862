import { JobSchedule } from 'lib/types'
import { useTranslations } from './useTranslations'

export const useJobFrequencyText = (jobSchedule?: Array<JobSchedule>) => {
    const T = useTranslations()

    if (!jobSchedule) {
        return ''
    }

    return jobSchedule.some(job => job.fortnightly)
        ? T.components.frequency.fortnightly.title
        : T.components.frequency.weekly.title
}
