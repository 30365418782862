import React from 'react'
import { View } from 'react-native'
import { useIsMutating } from '@tanstack/react-query'
import { MutationKey } from 'lib/api'
import { createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { FormComponents, SectionBox, Switch, Typography } from 'lib/components'
import { useUserNotificationsOptions } from '../hooks'
import { CustomFonts } from 'lib/types'
import { useIsFocused } from '@react-navigation/native'

export const UserNotificationPreferencesSection: React.FunctionComponent = () => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const { options, error } = useUserNotificationsOptions()
    const isFocused = useIsFocused()
    const isMutatingOptions = useIsMutating({ mutationKey: [MutationKey.UpdatePromotionalNotifications] })

    return isFocused ? (
        <SectionBox
            isLoading={Boolean(isMutatingOptions)}
            title={T.screens.preferences.section.notifications}
            bordered
        >
            <View>
                {options.map((option, index) => (
                    <View
                        key={index}
                        style={styles.optionContainer}
                    >
                        <View style={styles.textContainer}>
                            <Typography.Regular style={styles.label}>
                                {option.label}
                            </Typography.Regular>
                            <Typography.Regular style={styles.description}>
                                {option.description}
                            </Typography.Regular>
                        </View>
                        <View>
                            <Switch
                                hideLabel
                                value={option.value}
                                onChangeValue={option.onChange}
                            />
                        </View>
                    </View>
                ))}
                <FormComponents.ErrorMessage text={error} />
            </View>
        </SectionBox>
    ) : null
}

const stylesheet = createStyles(theme => ({
    optionContainer: {
        paddingVertical: theme.utils.gap(2),
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderBottomWidth: 1,
        borderBottomColor: theme.colors.darkSilver
    },
    textContainer: {
        flex: 1,
        marginRight: theme.utils.gap(1)
    },
    label: {
        marginBottom: theme.utils.gap(1.75),
        color: theme.colors.darkGrey,
        fontFamily: CustomFonts.Roboto500
    },
    description: {
        color: theme.colors.darkGrey,
        fontFamily: CustomFonts.Roboto400,
        fontSize: 13
    }
}))
