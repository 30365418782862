import { useEffect, useMemo, useRef } from 'react'
import { useForm } from '@codegateinc/react-form-builder-v2'
import { useNavigation } from '@react-navigation/native'
import { debounceTime, Subject } from 'rxjs'
import { Address } from 'lib/models'
import { ScreenNames } from 'lib/routing'
import { authActions, cookieUtils } from 'features/auth'
import { useBackHandler, useGetErrorMessage, useTranslations } from 'lib/hooks'
import { AddressResponseFromOneMap, NotificationType, SelectInputOption, NewAddressRequestShape } from 'lib/types'
import { AnalyticsEvent, AnalyticsEventDescription, GoogleAnalytics, Segment } from 'lib/analytics'
import { useIsAuthorizedAtom, useToastAtom, useUserAtom, useUserTokenAtom } from 'lib/atoms'
import { district, R, regexes, stringHelpers } from 'lib/utils'
import { addNewAddress, findAddressByOneMap } from '../actions'
import { useEditAddressInitialData } from './useEditAddressInitialData'
import { NewAddressFields, NewAddressShape, useNewAddressForm } from '../forms'

export type UseUserNewAddressParams = {
    postcode?: string,
    loadingDebounceTime?: number,
    isInitialAddressSetup?: boolean,
    onSuccess?(newAddress: Address): void,
    onSubmit?(newAddress: NewAddressRequestShape): void
}

type LoadOptionsCallback = (options: Array<SelectInputOption>) => void

export const useUserNewAddress = ({
    postcode,
    loadingDebounceTime = 500,
    isInitialAddressSetup = false,
    onSuccess,
    onSubmit
}: UseUserNewAddressParams) => {
    const T = useTranslations()
    const [, setUser] = useUserAtom()
    const [isAuthorized] = useIsAuthorizedAtom()
    const [token] = useUserTokenAtom()
    const navigation = useNavigation()
    const [, setToastMessage] = useToastAtom()
    const { getErrorMessage } = useGetErrorMessage()
    const { mutate: findAddress, isLoading: isFetchingPostcode } = findAddressByOneMap()
    const { mutate: getMe, isLoading: isLoadingProfile } = authActions.useGetMe()
    const { mutate: addAddress, isLoading: isAddingAddress } = addNewAddress()
    const inputStream$ = useMemo(() => new Subject(), [])
    const onOptionsLoaded = useRef<LoadOptionsCallback>()

    const { submit, form, isFilled, setFieldInitialValue, resetForm, hasError, setError, setFieldValue } = useForm<NewAddressShape>(useNewAddressForm(isInitialAddressSetup), {
        onSuccess: ({ postcode, addressLine, unitFloor, block }) => {
            const address = JSON.parse(postcode.value) as AddressResponseFromOneMap
            const addressShape = {
                postcode: address.POSTAL,
                address: addressLine,
                geo: {
                    lat: parseFloat(address.LATITUDE),
                    lng: parseFloat(address.LONGITUDE)
                },
                district: district.getDistrictFromPostcode(address.POSTAL),
                unit: `${unitFloor}${block ? ` ${block}` : ''}`
            }

            if (!isAuthorized) {
                onSubmit && onSubmit(addressShape)

                return
            }

            addAddress(
                {
                    token,
                    address: addressShape
                },
                {
                    onSuccess: () => {
                        GoogleAnalytics.logEvent({
                            eventType: AnalyticsEvent.UserAction,
                            description: AnalyticsEventDescription.AddressAdded
                        })
                        Segment.addressAdded({
                            postcode: address.POSTAL
                        })

                        getMe(
                            {
                                token
                            },
                            {
                                onSuccess: ({ data }) => {
                                    setUser(data.profile)
                                    cookieUtils.setUserAddressesCookie(data.profile.locations)

                                    const savedAddress = data.profile.locations.find(location => location.address === addressLine)

                                    if (!onSuccess) {
                                        navigation.navigate(ScreenNames.UserAddress)

                                        return setToastMessage({
                                            message: T.screens.addNewAddress.successMessage,
                                            type: NotificationType.Success
                                        })
                                    }

                                    resetForm()
                                    onSuccess(savedAddress || {} as Address)
                                },
                                onError: error => setError(NewAddressFields.Postcode, getErrorMessage(error))
                            }
                        )
                    },
                    onError: error => setError(NewAddressFields.Postcode, getErrorMessage(error))
                }
            )
        }
    })

    const fetchPostalCodesWithDebounce = (value: string) => new Promise<Array<SelectInputOption>>(resolve => {
        inputStream$.next(value)
        onOptionsLoaded.current = values => resolve(values)
    })

    const fetchPostalCodes = (value: string) => new Promise<Array<SelectInputOption>>(resolve => {
        const trimmedValue = value.trim().slice(0, 6)

        if (trimmedValue.length === 0) {
            return resolve([])
        }

        if (!regexes.isValidDigit(trimmedValue)) {
            return resolve([])
        }

        findAddress(
            {
                searchVal: trimmedValue,
                returnGeom: 'Y',
                getAddrDetails: 'Y',
                pageNum: 1
            },
            {
                onSuccess: response => {
                    const addresses = response?.results?.filter(item => item.POSTAL !== 'NIL')
                    const results = addresses?.map(address => ({
                        label: address.POSTAL,
                        value: JSON.stringify(address)
                    })) || []

                    Segment.postcodeEntered({
                        postcode: value,
                        success: results.length !== 0
                    })

                    if(results.length === 0 && regexes.isValidPostalCode(trimmedValue)) {
                        return resolve([{
                            label: trimmedValue,
                            value: JSON.stringify({
                                POSTAL: trimmedValue,
                                LATITUDE: -1,
                                LONGITUDE: -1
                            })
                        }])
                    }

                    GoogleAnalytics.logEvent({
                        eventType: AnalyticsEvent.UserAction,
                        description: AnalyticsEventDescription.Geocoding
                    })

                    return resolve(results)
                },
                onError: () => resolve([])
            }
        )
    })

    useBackHandler(() => isAddingAddress || isLoadingProfile)
    useEditAddressInitialData({
        postcode,
        resetForm,
        setFieldInitialValue,
        setFieldValue,
        fetchPostalCodes
    })

    useEffect(() => {
        if (form.postcode.value?.value) {
            const address = JSON.parse(form.postcode.value?.value) as AddressResponseFromOneMap

            address?.ADDRESS && setFieldValue(
                NewAddressFields.AddressLine,
                stringHelpers.formatAddress(address.ADDRESS, true)
            )

            address?.ADDRESS && Segment.addressLineEntered({
                entryType: Segment.EntryType.Prefill
            })
        }
    }, [form.postcode.value])

    useEffect(() => {
        const subscribe = inputStream$?.pipe(
            debounceTime(loadingDebounceTime)
        ).subscribe(value => {
            fetchPostalCodes(value as string)
                .then(values => R.ifDefined(onOptionsLoaded.current, fn => fn(values)))
                .catch(() => R.ifDefined(onOptionsLoaded.current, fn => fn([])))
        })

        return () => {
            subscribe?.unsubscribe()
        }
    }, [inputStream$])

    return {
        form,
        hasError,
        isFilled,
        submit,
        resetForm,
        fetchPostalCodes,
        fetchPostalCodesWithDebounce,
        isFetchingPostcode,
        isLoading: isAddingAddress || isLoadingProfile
    }
}
