import { useState } from 'react'
import { Segment } from 'lib/analytics'
import { useQueryHelpers } from 'lib/hooks'
import { NotificationType, Nullable } from 'lib/types'
import {
    useAutoApplyPromoAtom,
    useToastAtom,
    useUserTokenAtom,
    usePromotionErrorAtom ,
    useResetPromotionErrorAtom
} from 'lib/atoms'
import { CreateJobData, PromotionCodeJobData } from 'lib/models'
import { getPromotion } from '../actions'
import { CreateMoversAndPackersJobRequest, GetPromotionResponse } from '../types'

export const useBookingPromoCode = () => {
    const [token] = useUserTokenAtom()
    const [, setToastMessage] = useToastAtom()
    const [promotionPackage, setPromotionPackage] = useState<Nullable<GetPromotionResponse>>(null)
    const { onRequestError } = useQueryHelpers()
    const [, setAutoApplyPromoCode] = useAutoApplyPromoAtom()
    const [, setPromotionError] = usePromotionErrorAtom()
    const [, setResetPromotionError] = useResetPromotionErrorAtom()
    const { mutate: packagePromotionCode, isLoading } = getPromotion()

    const removePromoCode = () => {
        setPromotionPackage(null)
    }

    const requestPromotion = (code: string, jobData: PromotionCodeJobData | CreateJobData | CreateMoversAndPackersJobRequest, isAlreadyApplied?: boolean) => {
        setResetPromotionError()
        packagePromotionCode(
            {
                token,
                promo_code: code,
                job_data: jobData
            },
            {
                onSuccess: response => {
                    Segment.bookingDiscountCodeApplied({
                        promoCode: code,
                        success: true
                    })

                    setAutoApplyPromoCode('')
                    setPromotionPackage(response.data)

                    if (!isAlreadyApplied) {
                        setToastMessage({
                            message: response.data.promotion.message_to_client,
                            type: NotificationType.Success
                        })
                    }
                },
                onError: error => {
                    Segment.bookingDiscountCodeErrorShowed({
                        promoCode: code,
                        error: error.errors.error_msg
                    })
                    Segment.bookingDiscountCodeApplied({
                        promoCode: code,
                        success: false
                    })

                    setAutoApplyPromoCode('')
                    setPromotionError(error.errors.error_msg)
                    onRequestError(error)
                }
            }
        )
    }

    return {
        isLoading,
        promotionPackage,
        requestPromotion,
        removePromoCode
    }
}
