import { useEffect } from 'react'
import { emitter } from 'lib/utils'
import { JobDetailsResponse } from 'features/jobs/types'

export const useJobListeners = (
    job,
    resetFormData,
    setIsRescheduleBookingModalOpen,
    setIsPausePackageModalOpen,
    setIsTriggerByEventEmitter
) => {
    const rescheduleListener = (jobDetail: JobDetailsResponse) => {
        if (jobDetail.job._id === job.job._id) {
            resetFormData(jobDetail)
            setIsTriggerByEventEmitter(true)
            setIsRescheduleBookingModalOpen(true)
        }
    }

    const pauseListener = (jobDetail: JobDetailsResponse) => {
        if (jobDetail.job._id === job.job._id) {
            resetFormData(jobDetail)
            setIsTriggerByEventEmitter(true)
            setIsPausePackageModalOpen(true)
        }
    }

    useEffect(() => {
        emitter.on('openRescheduleModal', rescheduleListener)
        emitter.on('openPauseModal', pauseListener)

        return () => {
            emitter.off('openRescheduleModal', rescheduleListener)
            emitter.off('openPauseModal', pauseListener)
        }
    }, [job])

}
