import { useTranslations } from 'lib/hooks'

export const useHandymanServices = () => {
    const T = useTranslations()

    return [
        {
            label: T.components.handymanServices.general.title,
            items: Object.values(T.components.handymanServices.general.items)
        },
        {
            label: T.components.handymanServices.electrical.title,
            items: Object.values(T.components.handymanServices.electrical.items)
        },
        {
            label: T.components.handymanServices.painting.title,
            items: Object.values(T.components.handymanServices.painting.items)
        },
        {
            label: T.components.handymanServices.plumbing.title,
            items: Object.values(T.components.handymanServices.plumbing.items)
        }
    ]
}
