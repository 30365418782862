import Constants from 'expo-constants'
import { lt } from 'semver'
import { isIOS, isWeb } from 'lib/common'

export const shouldUpdateApp = (minRequiredAppVersion: string, currentAppVersion: string) => {
    const appVersion = isIOS ? Constants.manifest?.version : String(Constants.manifest?.runtimeVersion)

    const defaultValue = { shouldForceUpdate: false, shouldGeneralUpdate: false }

    if (isWeb || !appVersion || !minRequiredAppVersion || !currentAppVersion) {
        return defaultValue
    }

    const shouldForceUpdate =  lt(appVersion, minRequiredAppVersion, { loose: true  })

    const shouldGeneralUpdate = lt(appVersion, currentAppVersion, { loose: true })

    return { shouldForceUpdate, shouldGeneralUpdate: shouldGeneralUpdate && !shouldForceUpdate }
}

