import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const Info: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M36 24c0-2.208 1.792-4 4-4s4 1.792 4 4-1.792 4-4 4-4-1.792-4-4Zm0 12c0-2.208 1.792-4 4-4s4 1.792 4 4v20c0 2.208-1.792 4-4 4s-4-1.792-4-4V36Zm4 36C22.356 72 8 57.644 8 40S22.356 8 40 8s32 14.356 32 32-14.356 32-32 32Zm0-72C17.908 0 0 17.908 0 40s17.908 40 40 40c22.088 0 40-17.908 40-40S62.088 0 40 0Z"
        />
    </Icon>
)
