import React from 'react'
import { View } from 'react-native'
import { Icons } from 'assets'
import { useNavigation } from '@react-navigation/native'
import { createStyles, theme } from 'lib/styles'
import { CustomFonts } from 'lib/types'
import { ScreenNames } from 'lib/routing'
import { ENV } from 'lib/config'
import { linkingHelpers } from 'lib/utils'
import { isNative } from 'lib/common'
import { useStyles, useTranslations } from 'lib/hooks'
import { CustomModal, Typography, Touchable, DockedModal } from 'lib/components'
import { JobDetailsResponse } from 'features/jobs/types'

type SubscriptionCancelSuccessModalProps = {
    isOpen: boolean,
    isLoading: boolean,
    onClose: VoidFunction,
    jobDetail: JobDetailsResponse
}

export const SubscriptionCancelSuccessModal: React.FunctionComponent<SubscriptionCancelSuccessModalProps> = ({
    isOpen,
    onClose,
    isLoading,
    jobDetail
}) => {
    const T = useTranslations()
    const navigation = useNavigation()
    const { styles } = useStyles(stylesheet)
    const Modal = isNative ? DockedModal : CustomModal

    return (
        <Modal
            testID={T.accessibility.cancelJobScreen.subscriptionCancelSuccess.modal}
            onClose={onClose}
            isOpen={isOpen}
        >
            <View style={styles.modalWrapper}>
                <View style={styles.iconContainer}>
                    <Icons.CheckCircle
                        size={40}
                        forceColor={theme.colors.orange}
                    />
                </View>
                <Typography.SmallSubheading style={styles.title} testID={T.accessibility.cancelJobScreen.subscriptionCancelSuccess.title}>
                    {T.screens.jobDetails.subscriptionCancelSuccessModal.packageTitle}
                </Typography.SmallSubheading>
                <Typography.Regular style={styles.message}>
                    {T.screens.jobDetails.subscriptionCancelSuccessModal.yourPlan}
                    <Typography.Regular style={styles.jobId} testID={T.accessibility.cancelJobScreen.subscriptionCancelSuccess.jobId}>
                        {` ${jobDetail?.job?.job_id} `}
                    </Typography.Regular>
                    <Typography.Regular style={styles.message}>
                        {T.screens.jobDetails.subscriptionCancelSuccessModal.hasBeenCancelledSuccessfully}
                    </Typography.Regular>
                </Typography.Regular>
                <Typography.Regular style={styles.message}>
                    {T.screens.jobDetails.subscriptionCancelSuccessModal.thankForSupport}
                </Typography.Regular>
                <View style={styles.actionsContainer}>
                    {
                        !isNative && (
                            <Touchable
                                testID={T.accessibility.cancelJobScreen.subscriptionCancelSuccess.backToHome}
                                disabled={isLoading}
                                onPress={() => {
                                    onClose()
                                    linkingHelpers.openUrl(ENV.SENDHELPER_MARKETING_SITE_URL, false)
                                }}
                                style={{
                                    ...styles.button,
                                    ...styles.backToHomeButton
                                }}>
                                <Typography.Regular style={styles.backToHomeButtonText}>
                                    {T.screens.jobDetails.subscriptionCancelSuccessModal.backToHome}
                                </Typography.Regular>
                            </Touchable>
                        )
                    }
                    <View style={styles.spacer} />
                    <Touchable
                        testID={T.accessibility.cancelJobScreen.subscriptionCancelSuccess.bookNew}
                        disabled={isLoading}
                        onPress={() => {
                            onClose()
                            navigation.navigate(ScreenNames.AllServices)
                        }}
                        style={{
                            ...styles.button,
                            ...styles.makeANewBookingButton
                        }}>
                        <Typography.Regular style={styles.makeANewBookingButtonText}>
                            {T.screens.jobDetails.subscriptionCancelSuccessModal.makeANewBooking}
                        </Typography.Regular>
                    </Touchable>
                </View>
            </View>
        </Modal>
    )
}

const stylesheet = createStyles(theme => ({
    modalWrapper: {
        alignItems: 'center',
        paddingHorizontal: {
            lg: theme.utils.gap(16),
            md: theme.utils.gap(8),
            sm: theme.utils.gap(6),
            xs: theme.utils.gap(2)
        }
    },
    iconContainer: {
        alignItems: 'center',
        paddingBottom: theme.utils.gap(1)
    },
    message: {
        fontFamily: CustomFonts.Poppins400,
        textAlign: 'center',
        fontSize: 14,
        lineHeight: 22
    },
    jobId: {
        fontFamily: CustomFonts.Poppins500,
        fontSize: 16,
        lineHeight: 20
    },
    title: {
        textAlign: 'center',
        fontSize: 25,
        lineHeight: 32,
        marginBottom: theme.utils.gap(2)
    },
    spacer: {
        width: theme.utils.gap(2),
        height: theme.utils.gap(1)
    },
    button: {
        borderWidth: 2,
        borderColor: theme.colors.orange,
        borderRadius: 4,
        flex: 1,
        paddingHorizontal: theme.utils.gap(2),
        paddingVertical: theme.utils.gap(1.25),
        justifyContent: 'center',
        alignItems: 'center'
    },
    backToHomeButton: {
        backgroundColor: theme.colors.white,
        borderColor: theme.colors.sun,
        borderWidth: 2
    },
    backToHomeButtonText: {
        fontFamily: CustomFonts.Poppins400,
        color: theme.colors.sun,
        fontSize: 14
    },
    actionsContainer: {
        flexDirection: {
            md: 'row',
            xs: 'column-reverse'
        },
        width: '100%',
        marginTop: theme.utils.gap(3)
    },
    makeANewBookingButton: {
        backgroundColor: theme.colors.sun,
        borderColor: theme.colors.sun
    },
    makeANewBookingButtonText: {
        fontFamily: CustomFonts.Poppins400,
        color: theme.colors.darkSlate,
        fontSize: 14
    }
}))
