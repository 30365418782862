import React from 'react'
import Animated, { SharedValue, useAnimatedStyle, withTiming } from 'react-native-reanimated'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { Typography } from 'lib/components'

type OnboardingTextProps = {
    title: string,
    message: string,
    index: number,
    activeIndex: SharedValue<number>
}

export const OnboardingText: React.FunctionComponent<OnboardingTextProps> = ({
    title,
    message,
    index,
    activeIndex
}) => {
    const { styles } = useStyles(stylesheet)
    const animatedStyles = useAnimatedStyle(() => {
        const isActive = index === activeIndex.value

        return ({
            opacity: withTiming(isActive
                ? 1
                : 0
            )
        })
    })

    return (
        <Animated.View style={[styles.wrapper, animatedStyles]}>
            <Typography.Regular
                bold
                style={styles.titleText}
            >
                {title}
            </Typography.Regular>
            <Typography.Title style={styles.messageText}>
                {message}
            </Typography.Title>
        </Animated.View>
    )
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0
    },
    titleText: {
        fontSize: 25,
        lineHeight: 31,
        textAlign: 'center'
    },
    messageText: {
        textAlign: 'center',
        marginVertical: theme.utils.gap(2),
        paddingHorizontal: theme.utils.gap(4)
    }
}))
