import React from 'react'
import { View } from 'react-native'
import { Icons } from 'assets'
import { createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { Typography } from 'lib/components'
import { useFeatureFlagAtom } from 'lib/atoms'
import { BookingFrequency, CustomFonts, JobQuotationSavedItems, Nullable } from 'lib/types'
import { JobQuotationResponse } from 'features/bookings/types'
import { CleaningFormShape } from 'features/bookings/forms'

type SubscriptionBenefitProps = {
    form?: Nullable<CleaningFormShape>,
    jobQuotation?: JobQuotationResponse,
}

export const SubscriptionBenefit: React.FunctionComponent<SubscriptionBenefitProps> = ({
    form,
    jobQuotation
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const [featureFlagAtom] = useFeatureFlagAtom()
    const usePaymentBySessionFlow = featureFlagAtom?.paymentBySessionFlow || false
    const jobQuotationItems = jobQuotation?.saved?.items || []
    const jobQuotationItem = jobQuotationItems.find(
        item => item.name === (
            usePaymentBySessionFlow ?
                JobQuotationSavedItems.SavedSubscriptionPlan : JobQuotationSavedItems.SavedOneOffPlan))

    const shouldShowBenefit = form && jobQuotationItem && [
        BookingFrequency.Fortnightly,
        BookingFrequency.Weekly
    ].includes(form?.frequency?.value)

    return shouldShowBenefit ? (
        <View style={styles.container}>
            <View style={styles.iconContainer}>
                <Icons.SavedCoin viewBox="0 0 16 16" size={16} />
            </View>
            <View style={styles.informationContainer}>
                <Typography.Regular style={styles.informationText}>
                    <Typography.Regular bold style={styles.savedText}>
                        {`${jobQuotationItem?.name} ${jobQuotationItem?.value}`}
                    </Typography.Regular> {T.servicesPlan.withTheSubscriptionPlan}
                </Typography.Regular>
            </View>
        </View>
    ) : null
}

const stylesheet = createStyles(theme => ({
    container: {
        padding: theme.utils.gap(1),
        backgroundColor: theme.colors.lightGreen,
        marginBottom: theme.utils.gap(2.5),
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        borderRadius: 8
    },
    iconContainer: {
        paddingRight: theme.utils.gap(1)
    },
    informationContainer: {
        flex: 1,
        paddingRight: theme.utils.gap(1)
    },
    informationText: {
        fontSize: 14,
        color: theme.colors.green,
        fontFamily: CustomFonts.Poppins400
    },
    savedText: {
        fontSize: 14,
        color: theme.colors.green,
        fontFamily: CustomFonts.Poppins500
    }
}))
