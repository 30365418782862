import { firebaseAuth, firebaseRemoteConfig } from './firebase'
import { VERIFY_CODE_RECAPTCHA_CONTAINER } from './common'

const firebase = {
    auth: firebaseAuth,
    remoteConfig: firebaseRemoteConfig
}

export * from './types'
export {
    firebase,
    VERIFY_CODE_RECAPTCHA_CONTAINER
}
