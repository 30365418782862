import React from 'react'
import { View } from 'react-native'
import { isIOS } from 'lib/common'
import { createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { BasicModal, Touchable, Typography } from 'lib/components'
import { useShareCodeIcons } from '../hooks'

type ShareModalProps = {
    isOpen: boolean,
    onClose: VoidFunction
}

export const ShareModal: React.FunctionComponent<ShareModalProps> = ({
    isOpen,
    onClose
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const shareIcons = useShareCodeIcons()

    return (
        <BasicModal
            isOpen={isOpen}
            onClose={onClose}
        >
            <Typography.Regular bold>
                {T.screens.referFriend.shareModal.title}
            </Typography.Regular>
            <View style={styles.container}>
                {shareIcons.map((icon, index) => (
                    <Touchable
                        key={index}
                        onPress={() => {
                            if (!isIOS) {
                                onClose()
                            }

                            icon.onPress()
                        }}
                    >
                        <View style={styles.shareWrapper}>
                            <View style={styles.iconWrapper}>
                                {icon.render()}
                            </View>
                            <Typography.Regular>
                                {icon.label}
                            </Typography.Regular>
                        </View>
                    </Touchable>
                ))}
            </View>
        </BasicModal>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        width: {
            md: 420,
            xs: 260
        },
        borderTopWidth: 1,
        marginTop: theme.utils.gap(2),
        borderColor: theme.colors.silver
    },
    shareWrapper: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: theme.utils.gap(2)
    },
    iconWrapper: {
        width: 40,
        height: 40,
        borderRadius: 40 / 2,
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: theme.utils.gap(2),
        backgroundColor: theme.colors.yellow
    }
}))
