import { useMutation, useQuery } from '@tanstack/react-query'
import { MutationKey, QueryKey, useFetcher } from 'lib/api'
import { useUserTokenAtom } from 'lib/atoms'
import { ErrorResponse, HttpMethod, Response } from 'lib/types'
import { HelpersListResponse, SetPreferredHelperRequest, SetPreferredHelperResponse } from './types'

export const getHelpersList = () => {
    const [token] = useUserTokenAtom()
    const fetcher = useFetcher<HelpersListResponse>(HttpMethod.POST, '/user/get_helper_list_for_consumer')

    return useQuery<Response<HelpersListResponse>, ErrorResponse>([QueryKey.ConsumerHelpers], () => fetcher({ token }), {
        cacheTime: 0
    })
}

export const setPreferredHelper = () => {
    const fetcher = useFetcher<SetPreferredHelperResponse>(HttpMethod.POST, '/user/set_preferred_helper')

    return useMutation<Response<SetPreferredHelperResponse>, ErrorResponse, SetPreferredHelperRequest>(fetcher, {
        mutationKey: [MutationKey.SettingPreferredHelper]
    })
}
