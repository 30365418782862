export enum ResetFavouriteExpert {
    None = 'none'
}

export type ExpertSupply = {
    _id: string,
    id: number,
    name: string
}

export type ExpertSupplier = {
    _id: string,
    handle: string,
}
