import React from 'react'
import { View } from 'react-native'
import { AuthLayout } from 'features/auth'
import { Grid, Typography } from 'lib/components'
import { useStyles, useTranslations } from 'lib/hooks'
import { NavigationParams, NavigationProps, ScreenNames } from 'lib/routing'
import { createStyles, theme } from 'lib/styles'
import { AddressSetupForm } from '../components'

type AddressSetupProps = {
    navigation: NavigationProps<ScreenNames.AddressSetup>,
    route: NavigationParams<ScreenNames.AddressSetup>
}

export const AddressSetupScreen: React.FunctionComponent<AddressSetupProps> = ({ navigation }) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const onClose = () => navigation.navigate(ScreenNames.AllServices)

    return (
        <AuthLayout centerVertical>
            <View style={styles.container}>
                <Typography.Heading style={styles.textCenter}>
                    {T.screens.addressSetup.title}
                </Typography.Heading>
                <Grid.Gap gapBottom={2}/>
                <Typography.Regular
                    forceColor={theme.colors.darkGrey}
                    style={styles.message}
                >
                    {T.screens.addressSetup.message}
                </Typography.Regular>
                <Grid.Gap gapBottom={2}/>
                <AddressSetupForm
                    onCancel={onClose}
                    onSuccess={onClose}
                />
            </View>
        </AuthLayout>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        flex: 1,
        justifyContent: 'center',
        paddingBottom: theme.utils.gap(6),
        zIndex: 10
    },
    textCenter: {
        textAlign: 'center'
    },
    message: {
        textAlign: 'center',
        paddingHorizontal: theme.utils.gap(2),
        fontSize: 16,
        lineHeight: 24
    }
}))
