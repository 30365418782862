import React from 'react'
import { View } from 'react-native'
import { createStyles } from 'lib/styles'
import { Typography } from 'lib/components'
import { useStyles, useTranslations } from 'lib/hooks'
import { JobDetails } from 'lib/models'
import { stringHelpers } from 'lib/utils'

type ServiceLocationProps = {
    job: JobDetails
}

export const ServiceLocation: React.FunctionComponent<ServiceLocationProps> = ({
    job
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const { location } = job
    const userAddressUnit = stringHelpers.getFormattedAddressUnit(location.unit)

    return (
        <View>
            <Typography.Label forceColor={theme.colors.mouse}>
                {T.screens.jobDetails.sections.location}
            </Typography.Label>
            <View style={styles.content}>
                <Typography.Regular bold>
                    {`${userAddressUnit}${location.address}, ${location.postcode}`}
                </Typography.Regular>
                <Typography.Label forceColor={theme.colors.darkGrey}>
                    {location.district}
                </Typography.Label>
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    content: {
        borderRadius: 8,
        borderWidth: 1,
        marginTop: theme.utils.gap(1),
        padding: theme.utils.gap(2),
        borderColor: theme.colors.darkSilver
    }
}))
