import { useEffect } from 'react'
import { BackHandler } from 'react-native'

export const useBackHandler = (onBackPressed: () => boolean) => {
    useEffect(() => {
        const backHandler = BackHandler.addEventListener(
            'hardwareBackPress',
            onBackPressed
        )

        return () => backHandler.remove()
    }, [onBackPressed])
}
