import React from 'react'
import { View } from 'react-native'
import { useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { Nullable } from 'lib/types'
import { Typography } from 'lib/components'

type OfflineIndicatorProps = {
    isOnline: Nullable<boolean>
}

export const OfflineIndicator: React.FunctionComponent<OfflineIndicatorProps> = ({ isOnline }) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)

    return isOnline === false ? (
        <View style={styles.offlineContainer}>
            <Typography.Regular bold>
                {T.screens.chat.offlineMessage}
            </Typography.Regular>
        </View>
    ) : null
}

const stylesheet = createStyles(theme => ({
    offlineContainer: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        height: 32,
        backgroundColor: theme.colors.latte,
        justifyContent: 'center',
        alignItems: 'center'
    }
}))
