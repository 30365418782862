import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const InstagramIcon: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path d="M40 26.667c-7.364 0-13.334 5.97-13.334 13.333 0 7.364 5.97 13.334 13.333 13.334 7.364 0 13.334-5.97 13.334-13.334s-5.97-13.333-13.334-13.333Z" />
        <Path
            fillRule="evenodd"
            d="M40 77.5c-7.87 0-14.241-.189-19.238-.44-9.817-.495-17.327-8.005-17.822-17.822C2.69 54.241 2.5 47.87 2.5 40c0-7.87.189-14.241.44-19.238.495-9.817 8.005-17.327 17.822-17.822C25.759 2.69 32.13 2.5 40 2.5c7.87 0 14.241.189 19.238.44 9.817.495 17.327 8.005 17.822 17.822.251 4.997.44 11.368.44 19.238 0 7.87-.189 14.241-.44 19.238-.495 9.817-8.005 17.327-17.822 17.822-4.997.251-11.368.44-19.238.44ZM20 40c0-11.046 8.954-20 20-20s20 8.954 20 20-8.954 20-20 20-20-8.954-20-20Zm41.667-26.667a3.333 3.333 0 1 0 0 6.667h1.666a3.333 3.333 0 1 0 0-6.667h-1.666Z"
        />
    </Icon>
)
