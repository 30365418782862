import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const Key: React.FunctionComponent<IconProps> = props => (
    <Icon{...props}>
        <Path d="M 51.8 6.66 a 18.54 18.54 90 0 0 -17.748 23.9292 a 3.06 3.06 90 0 1 -0.7632 3.0492 L 11.8184 55.116 a 0.54 0.54 90 0 0 -0.1584 0.3816 V 64.8 c 0 0.2988 0.2448 0.54 0.54 0.54 h 7.74 V 61.2 c 0 -1.692 1.368 -3.06 3.06 -3.06 h 4.14 V 54 c 0 -1.692 1.368 -3.06 3.06 -3.06 h 5.9328 l 7.2288 -7.2288 a 3.06 3.06 90 0 1 3.0528 -0.7632 A 18.54 18.54 90 1 0 51.8 6.66 Z M 27.14 25.2 a 24.66 24.66 90 1 1 19.3104 24.0804 L 39.5636 56.16 a 3.06 3.06 90 0 1 -2.16 0.9 H 33.26 V 61.2 c 0 1.6884 -1.3716 3.06 -3.06 3.06 H 26.06 V 68.4 c 0 1.6884 -1.3716 3.06 -3.06 3.06 H 12.2 A 6.66 6.66 90 0 1 5.54 64.8 v -9.3096 c 0 -1.7676 0.6984 -3.4632 1.9476 -4.7088 l 20.232 -20.232 A 24.7356 24.7356 90 0 1 27.14 25.2 Z m 21.6 -7.2 c 0 -1.692 1.368 -3.06 3.06 -3.06 A 10.26 10.26 90 0 1 62.06 25.2 a 3.06 3.06 90 0 1 -6.12 0 A 4.14 4.14 90 0 0 51.8 21.06 a 3.06 3.06 90 0 1 -3.06 -3.06 Z"/>
    </Icon>
)
