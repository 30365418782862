import * as R from 'ramda'
import Deviceinfo from 'react-native-device-info'
import * as RNLocalize from 'react-native-localize'
import { DeviceInfoModel } from 'lib/types'

const [{ languageTag }]  = RNLocalize.getLocales()

class DeviceInfo {
    private static UNKNOWN: string = 'unknown'
    private asyncInfo: DeviceInfoModel = {} as DeviceInfoModel
    private readonly syncInfo: DeviceInfoModel = {
        uniqueId: Deviceinfo.getUniqueIdSync(),
        model: Deviceinfo.getModel(),
        brand: Deviceinfo.getBrand(),
        buildNumber: Deviceinfo.getBuildNumber(),
        systemName: Deviceinfo.getSystemName(),
        systemVersion: Deviceinfo.getSystemVersion(),
        version: Deviceinfo.getVersion(),
        hasNotch: Deviceinfo.hasNotch(),
        deviceId: Deviceinfo.getDeviceId(),
        bundleId: Deviceinfo.getBundleId(),
        languageTag
    } as DeviceInfoModel

    constructor() {
        this.fetchAllInfo()
            .catch(R.T)
    }

    public get(): DeviceInfoModel {
        return {
            ...this.syncInfo,
            ...this.asyncInfo
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private safelyFetchInfo(fetchFn: any, defaultValue?: any) {
        return fetchFn()
            .catch(() => defaultValue || DeviceInfo.UNKNOWN)
    }

    private async fetchAllInfo() {
        const [
            carrier,
            device,
            deviceName,
            manufacturer,
            maxMemory,
            isEmulator,
            apiLevel
        ] = await Promise.all([
            this.safelyFetchInfo(Deviceinfo.getCarrier),
            this.safelyFetchInfo(Deviceinfo.getDevice),
            this.safelyFetchInfo(Deviceinfo.getDeviceName),
            this.safelyFetchInfo(Deviceinfo.getManufacturer),
            this.safelyFetchInfo(Deviceinfo.getMaxMemory),
            this.safelyFetchInfo(Deviceinfo.isEmulator, false),
            this.safelyFetchInfo(Deviceinfo.getApiLevel)
        ])

        // eslint-disable-next-line functional/immutable-data
        this.asyncInfo = {
            carrier,
            device,
            deviceName,
            manufacturer,
            maxMemory,
            isEmulator,
            apiLevel
        } as DeviceInfoModel
    }
}

export default new DeviceInfo()
