import React from 'react'
import { View } from 'react-native'
import { Breakpoint, createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { useCancelReasonAtom } from 'lib/atoms'
import { FormComponents, MediaQuery, Typography } from 'lib/components'
import { CustomFonts } from 'lib/types'
import { cancelReasonHelpers, jobHelpers } from 'lib/utils'
import { isNative } from 'lib/common'
import { JobDetailsResponse, TypeOfCancelJob } from 'features/jobs/types'
import { ExtraCancellationReasonId } from 'features/bookings'
import { CancelJobActions } from './CancelJobActions'
import { PaymentSummary } from './PaymentSummary'

type CancelJobContentProps = {
    jobDetail: JobDetailsResponse,
    upcomingSession: JobDetailsResponse | undefined,
    cancellationReasonId: number,
    otherReason: string,
    cancelSubscriptionType: TypeOfCancelJob,
    handleGoBack: VoidFunction,
    resetState: VoidFunction,
    setCancellationReasonId: (reasonId: number) => void,
    setOtherReason: (reason: string) => void,
    setCancelSubscriptionType: (type: TypeOfCancelJob) => void,
}

export const CancelJobContent: React.FunctionComponent<CancelJobContentProps> = ({
    jobDetail,
    upcomingSession,
    cancellationReasonId,
    setCancellationReasonId,
    otherReason,
    setOtherReason,
    handleGoBack,
    resetState,
    cancelSubscriptionType,
    setCancelSubscriptionType
}) => {
    const T = useTranslations()
    const [cancellationOptions] = useCancelReasonAtom()
    const { styles } = useStyles(stylesheet)
    const shouldShowTypeOfCancelSchedule = jobHelpers.isAccepted(jobDetail) || jobHelpers.isPaused(jobDetail)
    const cancelSubscriptionTypes = cancelReasonHelpers.getCancelSubscriptionTypes(jobDetail)
    const shouldShowCancellationFee = cancelSubscriptionType?.value && cancelSubscriptionType?.value === cancelSubscriptionTypes?.[1]?.value

    return (
        <>
            <View style={styles.mainContainer}>
                <View style={styles.infoContainer}>
                    {
                        shouldShowTypeOfCancelSchedule && (
                            <>
                                <Typography.Regular style={styles.title}>
                                    {T.screens.cancelJob.iWouldLikeTo}
                                </Typography.Regular>

                                <View style={styles.listOfCancellation}>
                                    <FormComponents.SelectInput
                                        testID={T.accessibility.cancelJobScreen.cancelOptions}
                                        disabled={false}
                                        options={cancelSubscriptionTypes}
                                        isClearable={false}
                                        value={cancelSubscriptionType}
                                        onChange={value => value && setCancelSubscriptionType(value)}/>
                                </View>
                            </>
                        )}
                    <Typography.Regular style={styles.title}>
                        {T.screens.cancelJob.cancellationReasonPrompt}
                    </Typography.Regular>
                    <View style={styles.listOfCancellation}>
                        {
                            cancellationOptions.map((option, index) => {
                                const isSelected = cancellationReasonId === option.id
                                const shouldRenderExtraComponent = isSelected && option.id === ExtraCancellationReasonId.Other

                                return (
                                    <View key={index} style={{
                                        ...styles.cancellationItem,
                                        ...(isSelected ? styles.selectedItem : {})
                                    }}>
                                        <FormComponents.Checkbox
                                            testID={T.accessibility.cancelJobScreen.reason.replace('[reason]', option?.id?.toString())}
                                            hideErrorMessage
                                            style={styles.checkbox}
                                            unselectCheckboxStyle={styles.unselectCheckboxStyle}
                                            selectedCheckboxStyle={styles.selectedCheckboxStyle}
                                            onChange={() => setCancellationReasonId(
                                                option.id === cancellationReasonId ?
                                                    ExtraCancellationReasonId.NonSelect : option.id
                                            )}
                                            renderComponent={() => (
                                                <Typography.Regular style={styles.reasonText}>
                                                    {option.reason}
                                                </Typography.Regular>
                                            )}
                                            renderExtraComponent={() => (
                                                shouldRenderExtraComponent ? (
                                                    <View style={styles.otherReasonContainer}>
                                                        <FormComponents.Input
                                                            testID={T.accessibility.cancelJobScreen.otherReason}
                                                            customStyles
                                                            inputProps={{
                                                                value: otherReason,
                                                                multiline: true,
                                                                onChangeText: (value: string) => setOtherReason(value),
                                                                style: styles.inputStyle
                                                            }}
                                                            errorContentStyles={styles.errorContentStyles}
                                                        />
                                                    </View>
                                                ): null
                                            )}
                                            isSelected={isSelected}
                                            iconSize={14}
                                        />
                                    </View>
                                )
                            })
                        }
                    </View>
                </View>
                <View style={styles.noticeContainer}>
                    {
                        shouldShowCancellationFee && (
                            <PaymentSummary
                                jobDetail={jobDetail}
                                upcomingSession={upcomingSession}
                            />
                        )
                    }
                </View>
            </View>
            <View style={styles.mainContainer}>
                <View style={styles.actionContainer}>
                    <MediaQuery.Visible from={Breakpoint.LG}>
                        <CancelJobActions
                            jobDetail={jobDetail}
                            upcomingSession={upcomingSession}
                            handleGoBack={handleGoBack}
                            otherReason={otherReason}
                            resetState={resetState}
                            cancellationReasonId={cancellationReasonId}
                            cancelSubscriptionType={cancelSubscriptionType}
                        />
                    </MediaQuery.Visible>
                </View>
            </View>
        </>
    )
}

const stylesheet = createStyles(theme => ({
    mainContainer: {
        width: '100%',
        flexDirection: {
            lg: 'row',
            xs: 'column'
        }
    },
    infoContainer: {
        flex: {
            xl: 0.5,
            lg: 0.6,
            md: 1
        },
        paddingTop: theme.utils.gap(3),
        paddingLeft: {
            lg: theme.utils.gap(6),
            xs: theme.utils.gap(2)
        },
        paddingRight: theme.utils.gap(2)
    },
    actionContainer: {
        flex: {
            xl: 0.5,
            lg: 0.6,
            md: 1
        },
        paddingLeft: {
            lg: theme.utils.gap(6),
            xs: theme.utils.gap(2)
        },
        paddingRight: theme.utils.gap(2)
    },
    noticeContainer: {
        flex: {
            xl: 0.5,
            lg: 0.4,
            md: 1
        },
        display: {
            lg: undefined,
            md: 'none',
            xs: 'none'
        },
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    title: {
        fontSize: 16,
        lineHeight: 24,
        fontFamily: CustomFonts.Poppins500
    },
    listOfCancellation: {
        paddingVertical: theme.utils.gap(2)
    },
    cancellationItem: {
        borderRadius: 8,
        borderWidth: 1,
        borderColor: theme.colors.fog,
        padding: theme.utils.gap(1),
        marginBottom: theme.utils.gap(1)
    },
    selectedItem: {
        backgroundColor: theme.colors.lightCream
    },
    checkbox: {
        marginBottom: 0
    },
    unselectCheckboxStyle: {
        borderColor: theme.colors.fog,
        padding: 8
    },
    selectedCheckboxStyle: {
        padding: isNative ? 2 : 8
    },
    otherReasonContainer: {
        flex: 1,
        paddingTop: theme.utils.gap(1)
    },
    errorContentStyles: {
        display: 'none'
    },
    inputStyle: {
        backgroundColor: theme.colors.white,
        color: theme.colors.darkSlate
    },
    paymentSummaryContainer: {
        display: {
            lg: undefined,
            md: 'none',
            xs: 'none'
        },
        padding: theme.utils.gap(2),
        backgroundColor: theme.colors.linen,
        borderRadius: 8,
        borderWidth: 1,
        borderColor: theme.colors.strawberry,
        flexDirection: 'column',
        width: 260,
        marginTop: theme.utils.gap(7.25)
    },
    paymentSummaryTitle: {
        fontSize: 18,
        lineHeight: 26,
        fontFamily: CustomFonts.Poppins500,
        color: theme.colors.strawberry
    },
    paymentSummaryRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: theme.utils.gap(1.5)
    },
    summaryAttribute: {
        fontSize: 14,
        lineHeight: 20,
        fontFamily: CustomFonts.Poppins400,
        color: theme.colors.darkSlate
    },
    link: {
        color: theme.colors.strawberry,
        fontFamily: CustomFonts.Poppins500,
        fontSize: 13,
        lineHeight: 22,
        cursor: 'pointer'
    },
    reasonText: {
        fontSize: 14,
        lineHeight: 22,
        width: isNative ? '90%' : undefined
    }
}))
