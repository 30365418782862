export enum ServiceType {
    Cleaning = 'Cleaning',
    DeepCleaning = 'DeepCleaning',
    AirCondition = 'AirCondition',
    Handyman = 'Handyman',
    Laundry = 'Laundry',
    PestControl = 'PestControl',
    Cooking = 'Cooking',
    TaskErrands = 'Task&Errands',
    MoversAndPackers = 'MoversAndPackers'
}

export type IdentifyTraits = {
    email?: string,
    device: {
        platform: string,
        deviceId?: string,
        deviceName?: string
    }
}
