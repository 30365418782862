import React from 'react'
import { ActivityIndicator, View } from 'react-native'
import { Icons } from 'assets'
import { Breakpoint, createStyles } from 'lib/styles'
import { dateHelpers } from 'lib/utils'
import { Touchable, Typography } from 'lib/components'
import { useIsWithinBreakpoints, useQueryHelpers, useStyles, useTranslations } from 'lib/hooks'
import { JobStatus, NotificationType } from 'lib/types'
import { useToastAtom, useUserTokenAtom } from 'lib/atoms'
import { getConsumerActiveJobs, getJobDetails, resumeJob } from '../actions'
import { JobDetailsResponse } from '../types'

type PausedMessageProps = {
    response: JobDetailsResponse,
}

export const PausedMessage: React.FunctionComponent<PausedMessageProps> = ({
    response
}) => {
    const T = useTranslations()
    const [token] = useUserTokenAtom()
    const [, setToastMessage] = useToastAtom()
    const { styles, theme } = useStyles(stylesheet)
    const { onRequestError } = useQueryHelpers()
    const { mutate: onResume, isLoading: isMutating } = resumeJob()
    const { refetch: refetchJobDetails, isRefetching: isRefetchingJobDetails } = getJobDetails(response.job._id)
    const { refetch: refetchActiveJobs, isRefetching: isRefetchingActiveJobs } = getConsumerActiveJobs()
    const isMobile = useIsWithinBreakpoints(Breakpoint.XS, Breakpoint.SM)

    const handleResumeJob = () => {
        onResume(
            {
                token,
                id: response.job._id
            },
            {
                onSuccess: () => {
                    refetchJobDetails()
                    refetchActiveJobs()
                    setToastMessage({
                        type: NotificationType.Success,
                        message: T.components.resumeJob.resumeSuccessMessage
                    })
                },
                onError: onRequestError
            }
        )
    }

    const isLoading = isMutating || isRefetchingJobDetails || isRefetchingActiveJobs

    if (response.job.status !== JobStatus.Paused) {
        return null
    }

    return (
        <View style={styles.wrapper}>
            {
                isLoading ? <ActivityIndicator color={theme.colors.orange} /> :
                    isMobile ? (
                        <View style={styles.mobileContainer}>
                            <View style={styles.pauseInformation}>
                                <View style={styles.iconMobile}>
                                    <Icons.Info size={18} forceColor={theme.colors.orange} />
                                </View>
                                <View>
                                    <Typography.Regular forceColor={theme.colors.orange} testID={T.accessibility.jobDetailsScreen.components.pausedJobMessage}>
                                        {T.components.resumeJob.pauseDuration}
                                    </Typography.Regular>
                                    <Typography.Regular testID={T.accessibility.jobDetailsScreen.pausedMessage.pauseStartTime} bold forceColor={theme.colors.orange}>{`${dateHelpers.pauseDate(response.job.pause_start_date)} -`}</Typography.Regular>
                                    <Typography.Regular testID={T.accessibility.jobDetailsScreen.pausedMessage.pauseEndTime} bold forceColor={theme.colors.orange}>{dateHelpers.pauseDate(response.job.pause_end_date)}</Typography.Regular>
                                </View>
                            </View>

                            <View>
                                <Touchable testID={T.accessibility.jobDetailsScreen.pausedMessage.resume} onPress={handleResumeJob}>
                                    <Typography.Regular onPress={handleResumeJob} style={styles.link} forceColor={theme.colors.orange} bold>{T.components.resumeJob.resumePlan}</Typography.Regular>
                                </Touchable>
                            </View>
                        </View>
                    ) : (
                        <>
                            <View style={styles.icon}>
                                <Icons.Info size={18} forceColor={theme.colors.orange} />
                            </View>
                            <Typography.Regular forceColor={theme.colors.orange} style={styles.text} testID={T.accessibility.jobDetailsScreen.components.pausedJobMessage}>
                                {T.components.resumeJob.pauseDuration}
                                <Typography.Regular testID={T.accessibility.jobDetailsScreen.pausedMessage.pauseTime} bold forceColor={theme.colors.orange}>{` ${dateHelpers.pauseDate(response.job.pause_start_date)} - ${dateHelpers.pauseDate(response.job.pause_end_date)} `}</Typography.Regular>
                                <Touchable testID={T.accessibility.jobDetailsScreen.pausedMessage.resume} onPress={handleResumeJob}>
                                    <Typography.Regular onPress={handleResumeJob} style={styles.link} forceColor={theme.colors.orange}>{T.components.resumeJob.resumePlan}</Typography.Regular>
                                </Touchable>
                            </Typography.Regular>
                        </>
                    )
            }
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    mobileContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'row'
    },
    wrapper: {
        marginVertical: theme.utils.gap(1),
        borderWidth: 1,
        borderColor: theme.colors.peachPuff,
        flexDirection: 'row',
        padding: theme.utils.gap(1),
        borderRadius: 4,
        backgroundColor: theme.colors.latte,
        justifyContent: 'center'
    },
    text: {
        marginLeft: theme.utils.gap(1)
    },
    icon: {
        minWidth: 18,
        minHeight: 18
    },
    iconMobile: {
        minWidth: 20,
        minHeight: 20,
        width: 25
    },
    link: {
        textDecorationLine: 'underline',
        cursor: 'pointer'
    },
    pauseInformation: {
        display: 'flex',
        flexDirection: 'row'
    }
}))
