import React from 'react'
import { View } from 'react-native'
import { createStyles } from 'lib/styles'
import { JobProposal } from 'lib/models'
import { Typography } from 'lib/components'
import { usePrice, useStyles, useTranslations } from 'lib/hooks'

type JobProposalTipProps = {
    proposal: JobProposal
}

export const JobProposalTip: React.FunctionComponent<JobProposalTipProps> = ({
    proposal
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const tipPrice = usePrice(proposal.tip?.amount || 0)

    if (!proposal.tip) {
        return null
    }

    return (
        <View style={styles.flex}>
            <Typography.Label forceColor={theme.colors.mouse}>
                {T.components.proposals.tip}
            </Typography.Label>
            <Typography.Label>
                {`${tipPrice} - ${proposal.tip.reason}`}
            </Typography.Label>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    flex: {
        flex: 1,
        marginBottom: theme.utils.gap(2)
    }
}))
