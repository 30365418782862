import ReactGA from 'react-ga4'
import { Analytics, AnalyticsAction, AnalyticsEvent } from './types'

export const GoogleAnalytics: Analytics = {
    logEvent: (event: AnalyticsAction) => ReactGA.event({
        category: event.eventType,
        action: event.description,
        label: event.value
    }),
    logScreenView: (path: string) => ReactGA.send({ hitType: 'pageview', page: path }),
    setUserId: (userId: string) => ReactGA.set({ userId }),
    logPurchase: (amount: number, productName: string, jobId?: string) => ReactGA.event(AnalyticsEvent.Purchase, {
        value: amount,
        currency: 'SGD',
        items: [
            {
                item_id: jobId,
                item_name: productName,
                price: amount,
                currency: 'SGD'
            }
        ]
    })
}
