import { Image } from 'react-native'
import { xml } from '@xmpp/client'
import { APP_CONFIG } from 'lib/config'
import { dateHelpers, R } from 'lib/utils'
import { Nullable, Response } from 'lib/types'
import { GetImageDownloadURLRequest, GetImageDownloadURLResponse } from 'features/image'
import {
    ChatArchiveMessage,
    ChatMessage,
    GroupedChatMessages,
    ChatMessageType,
    GroupChatMessage,
    ChatImageMetadata,
    ChatImageSize
} from './types'

const CHAT_SUFFIX_ROOM_NAME = APP_CONFIG.CHAT.SUFFIX_ROOM_NAME

export const getRoomName = (jobId: string, token: string, supplierId: string) => jobId
    .concat(`_${token}_${supplierId}`)
    .concat(CHAT_SUFFIX_ROOM_NAME as string)

export const parseChatMessageBody = (stringifiedBody: string) => {
    try {
        const body = JSON.parse(stringifiedBody)

        switch(body.type) {
            case ChatMessageType.System:
            case ChatMessageType.Text:
            case ChatMessageType.Photo:
                return {
                    type: body.type,
                    body: body.payload.body
                } as GroupChatMessage
            default:
                return undefined
        }
    } catch {
        return undefined
    }
}

export const toChatMessage = (mamResult: ChatArchiveMessage): ChatMessage => ({
    payload: parseChatMessageBody(mamResult.txt),
    userId: mamResult.msg_sender,
    createdAt: new Date(dateHelpers.getUnixTime(mamResult.timestamp)).toISOString()
})

export const fromStanzaToChatMessage = (stanza: typeof xml): ChatMessage | undefined => {
    try {
        const createdAt = stanza
            .getChild('stanza-id')
            .getAttr('id')
        const [, userId] = stanza
            .getAttr('from')
            .split(`${CHAT_SUFFIX_ROOM_NAME}/`)
        const [body] = stanza
            .getChild('body')
            .children

        return {
            userId,
            createdAt: new Date(dateHelpers.getUnixTime(Number(createdAt))).toISOString(),
            payload: parseChatMessageBody(body)
        }
    } catch {
        return undefined
    }
}

export const historyToChatMessages = (history: Array<ChatMessage>): GroupedChatMessages => history
    .reduce((acc, message) => {
        const key = dateHelpers.calendarDate(message.createdAt)

        if (acc[key]) {
            return {
                ...acc,
                [key]: [message].concat(acc[key])
            }
        }

        return {
            ...acc,
            [key]: [message]
        }
    }, {} as GroupedChatMessages)

export const mergeChatMessages = (messagesA: GroupedChatMessages, messagesB: GroupedChatMessages): GroupedChatMessages => {
    const messagesMerger = (messagesA: Array<ChatMessage>, messagesB: Array<ChatMessage>) => messagesA.concat(messagesB)

    return R.mergeWith(messagesMerger, messagesA, messagesB)
}

export const appendChatMessage = (message: ChatMessage, messages: GroupedChatMessages): GroupedChatMessages => {
    const key = dateHelpers.calendarDate(message.createdAt)

    if (messages[key]) {
        return {
            ...messages,
            [key]: [message].concat(messages[key])
        }
    }

    return {
        ...messages,
        [key]: [message]
    }
}

// for images API returns downloadKey, not actual imageUrl, so here we make sure to fetch imageUrl before rendering any image
export const fetchImageMetadata = async (
    downloadKey: string,
    token: string,
    getImage: (request: GetImageDownloadURLRequest) => Promise<Response<GetImageDownloadURLResponse>>
): Promise<Nullable<ChatImageMetadata>> => {
    const response = await getImage(
        {
            token,
            key: downloadKey
        }
    ).catch(R.always(null))

    if (!response) {
        return null
    }

    const dimensions = await new Promise(resolve => {
        Image.getSize(response.data.download_url, (width, height) => resolve({
            width,
            height
        }))
    }).catch(R.always(null))

    if (!dimensions) {
        return null
    }

    return {
        downloadKey,
        imageUrl: response.data.download_url,
        size: dimensions as ChatImageSize
    }
}
