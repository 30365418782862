import React from 'react'
import { View } from 'react-native'
import { LinearGradient } from 'expo-linear-gradient'
import { useNavigation } from '@react-navigation/native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { Icons } from 'assets'
import { useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { Touchable, Typography } from 'lib/components'
import { ScreenNames } from 'lib/routing'

type BookingDetailsHeaderProps = {
    title: string
}

export const BookingDetailsHeader: React.FunctionComponent<BookingDetailsHeaderProps> = ({
    title
}) => {
    const T = useTranslations()
    const navigation = useNavigation()
    const { styles, theme } = useStyles(stylesheet)
    const insets = useSafeAreaInsets()

    const handleGoBack = () => {
        if (navigation.canGoBack()) {
            return navigation.goBack()
        }

        navigation.navigate(ScreenNames.Bookings)
    }

    return (
        <LinearGradient
            style={{
                ...styles.background,
                height: 140 + insets.top
            }}
            end={{ x: 1, y: 1 }}
            colors={[theme.colors.orange, theme.colors.sun]}
        >
            <View
                style={{
                    marginTop: insets.top
                }}
            >
                <Touchable
                    testID={T.accessibility.jobDetailsScreen.components.backButton}
                    onPress={handleGoBack}
                >
                    <View
                        style={{
                            ...styles.backButton,
                            transform: [
                                {
                                    rotateZ: '90deg'
                                }
                            ]
                        }}
                    >
                        <Icons.Chevron
                            size={10}
                            forceColor={theme.colors.white}
                        />
                    </View>
                </Touchable>
                <View style={styles.titleWrapper}>
                    <Typography.Regular
                        bold
                        forceColor={theme.colors.white}
                    >
                        {title}
                    </Typography.Regular>
                </View>
            </View>
        </LinearGradient>
    )
}

const stylesheet = createStyles(theme => ({
    background: {
        height: 140,
        paddingTop: theme.utils.gap(3),
        paddingHorizontal: {
            lg: theme.utils.gap(6),
            xs: theme.utils.gap(2)
        }
    },
    backButton: {
        width: 24,
        height: 24,
        paddingTop: 6,
        left: theme.utils.gap(1),
        position: 'absolute',
        marginRight: theme.utils.gap(1),
        alignItems: 'center',
        borderRadius: 4,
        borderWidth: 1,
        borderColor: theme.colors.white
    },
    titleWrapper: {
        alignItems: 'center',
        marginHorizontal: theme.utils.gap(10)
    }
}))
