import React from 'react'
import { View } from 'react-native'
import { useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { AppLayout, Tabs } from 'lib/components'
import { useNotificationsTabs, useNotifications } from '../hooks'

export const NotificationsScreen: React.FunctionComponent = () => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const notificationsTabs = useNotificationsTabs()
    const { renderNotifications } = useNotifications({
        emptyTitle: T.screens.notifications.noNotifications.title,
        emptyMessage: T.screens.notifications.noNotifications.message
    })

    return (
        <AppLayout title={T.screens.notifications.pageTitle}>
            <React.Fragment>
                <View style={styles.tabsContainer}>
                    <Tabs
                        tabs={notificationsTabs}
                        customTabStyles={styles.tabs}
                    />
                </View>
                {renderNotifications()}
            </React.Fragment>
        </AppLayout>
    )
}

const stylesheet = createStyles(theme => ({
    tabsContainer: {
        zIndex: theme.zIndex[10],
        flexDirection: {
            lg: 'row',
            ':w[, 1150]': 'column'
        },
        alignItems: {
            lg: 'center',
            ':w[, 1150]': undefined
        },
        justifyContent: 'space-between'
    },
    tabs: {
        width: 148
    }
}))
