import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const Star: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path d="M40 0a2.79 2.79 0 0 1 2.502 1.555l10.85 21.98 24.26 3.545a2.79 2.79 0 0 1 1.544 4.76L61.603 48.939l4.143 24.153a2.79 2.79 0 0 1-4.05 2.942L40 64.623l-21.696 11.41a2.79 2.79 0 0 1-4.05-2.942l4.143-24.153L.844 31.84a2.79 2.79 0 0 1 1.543-4.76l24.262-3.547L37.498 1.555A2.79 2.79 0 0 1 40 0Zm0 9.096-8.995 18.223a2.79 2.79 0 0 1-2.099 1.526l-20.12 2.94 14.557 14.179a2.79 2.79 0 0 1 .803 2.47L20.71 68.46 38.701 59a2.79 2.79 0 0 1 2.598 0l17.99 9.46-3.435-20.026a2.79 2.79 0 0 1 .803-2.47l14.557-14.178-20.12-2.941a2.79 2.79 0 0 1-2.099-1.526L40 9.096Z" />
    </Icon>
)
