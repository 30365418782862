import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { useForm } from '@codegateinc/react-form-builder-v2'
import { SupplyID } from 'lib/types'
import { isNative } from 'lib/common'
import { bookingHelpers, dateHelpers } from 'lib/utils'
import { Breakpoint, createStyles } from 'lib/styles'
import { useBookingTimeOptions, useInitializeAddress, useIsWithinBreakpoints, useSegmentSession, useStyles, useTranslations } from 'lib/hooks'
import { NavigationParams, NavigationProps, ScreenNames } from 'lib/routing'
import { Segment, ServiceType } from 'lib/analytics'
import { Adapter, Address, Button, FormComponents, MediaQuery, Sticky } from 'lib/components'
import { getServiceStaticConfig } from 'features/bookings/utils'
import {
    BookingCancellationFees,
    BookingDateModal,
    HandymanHelpModalContent,
    HandymanSummaryColumn,
    MobileSummary,
    Rated,
    ServiceDescription,
    ServiceLayout,
    HandymanComponents,
    PriceComponent
} from '../components'
import { HandymanFields, HandymanFormShape, useHandymanForm } from '../forms'
import { useHandymanBreadcrumbs, useJobQuotation, useResetFormCallback } from '../hooks'

type HandymanScreenProps = {
    route: NavigationParams<ScreenNames.Handyman>
    navigation: NavigationProps<ScreenNames.Handyman>
}

export const HandymanScreen: React.FunctionComponent<HandymanScreenProps> = ({
    route
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const navigation = useNavigation()
    const { segmentSession } = useSegmentSession()
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isUploadingPhotos, setIsUploadingPhotos] = useState(false)
    const breadcrumbs = useHandymanBreadcrumbs()
    const [isHelpModalOpen, setHelpModalOpen] = useState(false)
    const isMobile = useIsWithinBreakpoints(Breakpoint.XS, Breakpoint.MD)
    const { jobQuotation, requestJobQuotation } = useJobQuotation(SupplyID.Handyman)
    const [totalPrice, setTotalPrice] = useState(0)
    const formReturn = useForm<HandymanFormShape>(useHandymanForm(), {
        onSuccess: form => {
            const shiftedDays = bookingHelpers.shiftBookingDays(form.bookingDays, config.min_notice_hours, form.startingDate)
            const linkUploads = form.photos.reduce((acc, nextValue) => ({
                ...acc,
                // TODO check if needed, same as AirCondition.
                [nextValue.fileUUID]: nextValue.downloadUrl || ''
            }), {})

            navigation.navigate(ScreenNames.HandymanSummary, {
                form: {
                    ...form,
                    bookingDays: shiftedDays
                },
                price: totalPrice,
                postalcode: route.params?.postalcode,
                supplyId: SupplyID.Handyman,
                linkUploads
            })
        }
    })
    const { form, isFilled, hasError, submit, resetForm, setFieldValue } = formReturn
    const config = getServiceStaticConfig(SupplyID.Handyman)
    const columnWidth = isNative || isMobile
        ? undefined
        : 'calc(55% - 10px)'
    const formSummaryValue = Object.keys(form).reduce((acc, nextValue) => ({
        ...acc,
        [nextValue]: form[nextValue].value
    }), {} as HandymanFormShape)

    const continueDisabled = !form.bookingTime.value || isUploadingPhotos || !isFilled || hasError
    const selectedTimeOption = useBookingTimeOptions(form.startingDate.value).find(item => item.value === form.bookingTime.value)
    const extraHeight = isDropdownOpen ? 350 : 0

    useEffect(() => {
        setFieldValue(HandymanFields.BookingTime, '')
    }, [form.startingDate.value])

    useResetFormCallback(resetForm)

    useEffect(() => {
        if (segmentSession.id) {
            Segment.bookingStarted({
                bookingSessionId: segmentSession.id,
                postcode: route.params?.postalcode as string,
                serviceType: ServiceType.Handyman
            })
        }
    }, [segmentSession])

    useEffect(() => {
        requestJobQuotation(null)
    }, [])

    useEffect(() => {
        if (jobQuotation && jobQuotation.estimation) {
            const { value } = jobQuotation.estimation
            setTotalPrice(value)
        }
    }, [jobQuotation])

    useInitializeAddress(true)

    return (
        <React.Fragment>
            <ServiceLayout
                onInfoPress={() => setHelpModalOpen(true)}
                title={T.screens.handyman.title}
                withBackground
                breadcrumbs={breadcrumbs}
                bottomSpacerHeight={
                    isMobile
                        ? extraHeight
                        : 50
                }
                contentColumn={(
                    <View>
                        <MediaQuery.Hidden from={Breakpoint.LG}>
                            <View style={styles.mobileDescriptionContainer}>
                                <ServiceDescription
                                    isHelpModalOpen={isHelpModalOpen}
                                    setHelpModalOpen={setHelpModalOpen}
                                    title={T.screens.handyman.title}
                                    modalContent={(
                                        <HandymanHelpModalContent />
                                    )}
                                    description={T.screens.handyman.serviceDescription}
                                />
                            </View>
                        </MediaQuery.Hidden>
                        <BookingDateModal
                            title={T.screens.handyman.calendarModal.oneTime.title}
                            description={T.screens.handyman.calendarModal.oneTime.description}
                            minHoursNotice={config.min_notice_hours}
                            onContinue={() => setIsModalOpen(false)}
                            isModalOpen={isModalOpen}
                            frequency={form.frequency.value}
                            startingDateField={form.startingDate}
                            bookingTimeField={form.bookingTime}
                            bookingDaysField={form.bookingDays}
                            onClose={() => setIsModalOpen(false)}
                        />
                        <FormComponents.FormRow>
                            <View
                                style={{
                                    width: columnWidth
                                }}
                            >
                                <Adapter.DateAndTime
                                    {...form.startingDate}
                                    value={(form.startingDate.value && form.bookingTime.value)
                                        ? `${dateHelpers.shortFullDate(form.startingDate.value)} - ${selectedTimeOption?.label}`
                                        : form.startingDate.value
                                    }
                                    onPress={() => setIsModalOpen(true)}
                                />
                            </View>
                        </FormComponents.FormRow>
                        <HandymanComponents.HandymanServices
                            {...form.serviceType}
                            isDropdownOpen={isDropdownOpen}
                            setIsDropdownOpen={setIsDropdownOpen}
                            commentField={form.comment}
                        />
                        {form.serviceType.value.length !== 0 && (
                            <HandymanComponents.HandymanDetailsFields
                                formReturn={formReturn}
                                formShape={formSummaryValue}
                                isUploadingPhotos={isUploadingPhotos}
                                setIsUploadingPhotos={setIsUploadingPhotos}
                            />
                        )}
                    </View>
                )}
                summaryColumn={(
                    <MediaQuery.Visible from={Breakpoint.LG}>
                        <ServiceDescription
                            isHelpModalOpen={isHelpModalOpen}
                            setHelpModalOpen={setHelpModalOpen}
                            title={T.screens.handyman.title}
                            modalContent={(
                                <HandymanHelpModalContent />
                            )}
                            description={T.screens.handyman.serviceDescription}
                        />
                        <Sticky>
                            <Address />
                            <HandymanSummaryColumn
                                form={formSummaryValue}
                                renderPriceComponent={() => (
                                    <PriceComponent
                                        price={jobQuotation?.estimation?.value || 0}
                                        text={T.common.estimatedPrice}
                                    />
                                )}
                                jobQuotation={jobQuotation}
                            />
                            <Button
                                onPress={submit}
                                disabled={continueDisabled}
                                text={T.common.continue}
                            />
                            <BookingCancellationFees />
                        </Sticky>
                    </MediaQuery.Visible>
                )}
                footer={(
                    <MediaQuery.Hidden from={Breakpoint.LG}>
                        <MobileSummary
                            submit={submit}
                            price={totalPrice}
                            disabled={continueDisabled}
                            isLoading={false}
                            content={(
                                <React.Fragment>
                                    <Address />
                                    <HandymanSummaryColumn
                                        form={formSummaryValue}
                                        renderPriceComponent={() => (
                                            <PriceComponent
                                                price={jobQuotation?.estimation?.value || 0}
                                                text={T.common.estimatedPrice}
                                            />
                                        )}
                                        jobQuotation={jobQuotation}
                                    />
                                    <Rated />
                                    <BookingCancellationFees />
                                </React.Fragment>
                            )}
                        />
                    </MediaQuery.Hidden>
                )}
            />
        </React.Fragment>
    )
}

const stylesheet = createStyles(theme => ({
    row: {
        marginTop: theme.utils.gap(1),
        flexDirection: {
            lg: 'row',
            xs: 'column'
        }
    },
    mobileDescriptionContainer: {
        backgroundColor: theme.colors.marble,
        marginHorizontal: -theme.utils.gap(2),
        marginBottom: theme.utils.gap(2),
        paddingHorizontal: theme.utils.gap(2),
        paddingTop: theme.utils.gap(4)
    },
    resetMargin: {
        marginTop: 0
    }
}))
