import React from 'react'
import { NavigationParams, NavigationProps, ScreenNames } from 'lib/routing'
import { jobActions } from 'features/jobs'
import { Chat, ChatLayout, ChatSideMenu } from '../components'
import { useChat } from '../hooks'

type ChatScreenProps = {
    navigation: NavigationProps<ScreenNames.Chat>,
    route: NavigationParams<ScreenNames.Chat>
}

export const ChatScreen: React.FunctionComponent<ChatScreenProps> = ({ route }) => {
    const { jobId, supplierId, jobFetchId } = route.params
    const { chat } = useChat(jobFetchId, supplierId)
    const {
        data: response,
        refetch: onRefetch,
        isLoading: isLoadingJobDetails,
        isError: hasErrorLoadingJobDetails
    } = jobActions.getJobDetails(jobFetchId)
    const supplierPhoneNumber = response?.data.job.supplier?.phone
    const supplierName = response?.data.job.supplier?.handle

    return (
        <ChatLayout
            renderRightColumn={() => (
                <ChatSideMenu
                    job={response?.data.job}
                    isLoading={isLoadingJobDetails}
                    hasError={hasErrorLoadingJobDetails}
                    onRefetch={onRefetch}
                />
            )}
        >
            <Chat
                supplierName={supplierName}
                jobId={jobId}
                messages={chat.messages}
                onLoadMore={chat.loadMore}
                onReconnect={chat.onReconnect}
                canReconnect={chat.canReconnect}
                canLoadMore={chat.canLoadMore}
                supplierPhone={supplierPhoneNumber}
                sendTextMessage={chat.sendTextMessage}
                sendImageMessage={chat.sendImageMessage}
                isLoadingHistory={chat.isLoadingHistory}
                roomConnectionStatus={chat.roomConnectionStatus}
            />
        </ChatLayout>
    )
}
