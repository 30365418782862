import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const Clip: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path d="M26.56 80c-6.993 0-13.64-2.804-18.717-7.895-10.085-10.118-10.499-26.16-.925-35.764L38.111 5.057C41.36 1.797 45.743 0 50.451 0c5.039 0 9.828 2.017 13.481 5.68 7.268 7.288 7.547 18.872.617 25.819l-31.231 31.28c-2.023 2.034-4.747 3.15-7.67 3.15-3.087 0-6.014-1.23-8.235-3.457-4.447-4.468-4.586-11.588-.308-15.883l28.823-28.863a4.228 4.228 0 0 1 5.972-.017 4.2 4.2 0 0 1 .013 5.954l-28.82 28.868c-1.013 1.019-.874 2.816.313 4.004.616.619 1.436.972 2.242.972.465 0 1.132-.113 1.681-.665l31.231-31.28c3.662-3.676 3.387-9.928-.617-13.945-3.83-3.84-10.33-4.143-13.843-.623L12.907 42.278c-6.31 6.328-5.896 17.048.929 23.89 3.476 3.491 7.995 5.41 12.725 5.41 4.227 0 8.146-1.59 11.026-4.48l31.198-31.283a4.231 4.231 0 0 1 5.971-.017 4.204 4.204 0 0 1 .017 5.954L43.576 73.036C39.099 77.524 33.056 80 26.561 80Z" />
    </Icon>
)
