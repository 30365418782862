import { useEffect } from 'react'

export const useAutoSelectOneTimePlan = (
    autoSelectOneTimeJobType: boolean,
    selectOneTimePlan: () => void,
    setResetAutoSelectOneTimeJobType: () => void
) => {
    useEffect(() => {
        if (!autoSelectOneTimeJobType) {
            return
        }

        selectOneTimePlan()
        setResetAutoSelectOneTimeJobType()
    }, [autoSelectOneTimeJobType, setResetAutoSelectOneTimeJobType])
}

