import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

interface HeartIconProps extends IconProps {
    fill?: string
}

export const Heart: React.FunctionComponent<HeartIconProps> = props => (
    <Icon
        {...props}
        forceColor="transparent"
    >
        <Path
            d="M23.25 4C12.6185 4 4 12.6084 4 23.2273C4 42.4546 26.75 59.9339 39 64C51.25 59.9339 74 42.4546 74 23.2273C74 12.6084 65.3814 4 54.75 4C48.2395 4 42.4837 7.22826 39 12.1695C35.5163 7.22826 29.7605 4 23.25 4Z"
            stroke={props.forceColor}
            strokeWidth={8}
            strokeLinecap="round"
            strokeLinejoin="round"
            fill={props.fill}
        />
    </Icon>
)
