export enum QueryKey {
    ConsumerHelpers = 'ConsumerHelpers',
    ActiveJobs = 'ActiveJobs',
    ActiveJobsLoop = 'ActiveJobsLoop',
    CompletedJobs = 'CompletedJobs',
    CancelledJobs = 'CancelledJobs',
    PromotionPackages = 'PromotionPackages',
    JobDetails = 'JobDetails',
    Notification = 'Notification',
    RecurringNotification = 'RecurringNotification'
}

export enum MutationKey {
    GetMe = 'GetMe',
    UpdatingNotificationSettings = 'UpdatingNotificationSettings',
    SettingPreferredHelper = 'SettingPreferredHelper',
    UpdatePromotionalNotifications = 'UpdatePromotionalNotifications',
}
