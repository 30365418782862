import { useNavigation } from '@react-navigation/native'
import { useTranslations } from 'lib/hooks'
import { ScreenNames } from 'lib/routing'

export const useUserProfileTabs = () => {
    const T = useTranslations()
    const navigation = useNavigation()

    return [
        {
            text: T.components.myProfileTabs.settings,
            screenName: ScreenNames.User,
            onPress: () => navigation.navigate(ScreenNames.User)
        },
        {
            text: T.components.myProfileTabs.address,
            screenName: ScreenNames.UserAddress,
            onPress: () => navigation.navigate(ScreenNames.UserAddress)
        },
        {
            text: T.components.myProfileTabs.preferences,
            screenName: ScreenNames.UserPreferences,
            onPress: () => navigation.navigate(ScreenNames.UserPreferences)
        }
    ]
}
