import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const NotificationBell: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path
            fill="#fff"
            d="m14.611 55.315 2.835-5.806 2.564-7.276 1.416-8.371v-8.306l1.84-8.024 3.623-4.12 3.865-2.69 2.2-2.219 4.148-.927h4.65l3.857.927 4.834 2.219 3.656 3.4 2.519 4.436 1.774 5.609.563 5.494 2.052 12.578 2.1 6.914 2.567 6.6Z"
        />
        <Path d="M31.415 68.775a3.489 3.489 0 0 1 4.768 1.267 3.489 3.489 0 0 0 6.036 0 3.489 3.489 0 1 1 6.036 3.5 10.467 10.467 0 0 1-18.108 0 3.489 3.489 0 0 1 1.268-4.767Z" />
        <Path d="M22.62 9.153a24.423 24.423 0 0 1 41.692 17.27c0 11.7 2.5 18.961 4.807 23.188a20.717 20.717 0 0 0 3.068 4.341 10.524 10.524 0 0 0 .886.843c.093.078.155.125.182.145l.01.007a3.489 3.489 0 0 1-1.976 6.364h-62.8a3.489 3.489 0 0 1-1.976-6.364l.01-.007a3.92 3.92 0 0 0 .182-.145 10.54 10.54 0 0 0 .886-.843 20.716 20.716 0 0 0 3.068-4.341c2.308-4.227 4.808-11.491 4.808-23.188a24.423 24.423 0 0 1 7.153-17.27ZM6.548 54.924Zm9.439-.589h47.8q-.4-.653-.8-1.382c-2.928-5.368-5.659-13.8-5.659-26.53a17.445 17.445 0 0 0-34.89 0c0 12.725-2.732 21.162-5.659 26.53q-.392.729-.791 1.382Z" />
    </Icon>
)
