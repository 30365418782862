import React from 'react'
import { View } from 'react-native'
import { createStyles, theme } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { CustomModal, Button, Typography } from 'lib/components'
import { Icons } from 'assets'
import { dateHelpers, jobHelpers } from 'lib/utils'
import { UseFormReturn } from '@codegateinc/react-form-builder-v2'
import { PauseBookingsFormShape } from 'features/jobs/forms'
import { JobDetailsResponse } from 'features/jobs/types'
import { SubscriptionPauseSuccessModal } from '../subscriptionPauseSuccessModal'

type PackagePauseSuccessModalProps = {
    isOpen: boolean,
    isLoading: boolean,
    onClose: VoidFunction,
    formReturn: UseFormReturn<PauseBookingsFormShape>,
    job?: JobDetailsResponse
}

export const PackagePauseSuccessModal: React.FunctionComponent<PackagePauseSuccessModalProps> = ({
    isOpen,
    onClose,
    isLoading,
    formReturn,
    job
}) => {
    const { form } = formReturn
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)

    if (job && jobHelpers.shouldShowSubscriptionPauseSuccessModal(job)) {
        return (
            <SubscriptionPauseSuccessModal
                job={job}
                formReturn={formReturn}
                isLoading={isLoading}
                isOpen={isOpen}
                onClose={onClose}
            />
        )
    }

    return (
        <CustomModal
            onClose={onClose}
            isOpen={isOpen}
        >
            <View style={styles.modalWrapper}>
                <View style={styles.iconContainer}>
                    <Icons.CheckCircle
                        size={40}
                        forceColor={theme.colors.orange}
                    />
                </View>
                <Typography.SmallSubheading style={styles.title}>
                    {T.screens.jobDetails.pausePackageSuccess.packageTitle}
                </Typography.SmallSubheading>
                <View style={styles.datetimeWrapper}>
                    <View style={styles.datetimeContainer}>
                        <Typography.Regular style={styles.prefixPauseText}>
                            Pause from:
                        </Typography.Regular>
                        <Typography.Regular style={styles.datetimeText}>
                            {form.startDate.value ? dateHelpers.shortFullDate(form.startDate.value) : null}
                        </Typography.Regular>
                    </View>
                    <View style={styles.datetimeContainer}>
                        <Typography.Regular style={styles.prefixPauseText}>
                            End on:
                        </Typography.Regular>
                        <Typography.Regular style={styles.datetimeText}>
                            {form.endDate.value ? dateHelpers.shortFullDate(form.endDate.value) : null}
                        </Typography.Regular>
                    </View>
                </View>
                <Typography.Regular style={styles.messageContainer}>
                    {T.screens.jobDetails.pausePackageSuccess.description}
                </Typography.Regular>
                <View style={styles.actionContainer}>
                    <View style={styles.buttonWrapper}>
                        <Button
                            disabled={isLoading}
                            text={T.screens.jobDetails.pausePackageSuccess.done}
                            onPress={onClose}
                            textWrapperStyles={styles.buttonTextWrapper}
                            textStyles={styles.buttonText}
                        />
                    </View>
                </View>
            </View>
        </CustomModal>
    )
}

const stylesheet = createStyles(theme => ({
    modalWrapper: {
        alignItems: 'center',
        paddingHorizontal: {
            lg: theme.utils.gap(16),
            md: theme.utils.gap(8),
            sm: theme.utils.gap(6),
            xs: theme.utils.gap(2)
        }
    },
    iconContainer: {
        alignItems: 'center',
        paddingBottom: theme.utils.gap(1)
    },
    messageContainer: {
        textAlign: 'center'
    },
    actionContainer: {
        paddingTop: theme.utils.gap(3.5),
        flexDirection: 'row',
        justifyContent: 'center',
        zIndex: -1,
        width: {
            xs: '100%',
            md: undefined
        }
    },
    buttonWrapper: {
        flex: 1
    },
    buttonTextWrapper: {
        width: '100%',
        wordWrap: 'break-word',
        textAlign: 'center',
        marginHorizontal: theme.utils.gap(7)
    },
    jobIdText: {
        fontWeight: 'bold'
    },
    datetimeWrapper: {
        borderRadius: 8,
        backgroundColor: theme.colors.latte,
        paddingVertical: theme.utils.gap(1.5),
        paddingHorizontal: {
            xs: theme.utils.gap(1),
            md: theme.utils.gap(3)
        },
        marginVertical: theme.utils.gap(1.5),
        flex: 1,
        flexDirection: {
            xs: 'column',
            md: 'row'
        },
        justifyContent: 'center',
        alignItems: {
            xs: 'flex-start',
            md: 'center'
        },
        width: {
            xs: '100%',
            md: undefined
        }
    },
    datetimeContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: theme.utils.gap(1)
    },
    datetimeText: {
        marginLeft: theme.utils.gap(1),
        fontWeight: '600'
    },
    prefixPauseText: {
        marginLeft: theme.utils.gap(1),
        fontWeight: '600',
        color: theme.colors.orange
    },
    title: {
        fontSize: 25,
        lineHeight: 30
    },
    buttonText: {
        textAlign: 'center'
    }
}))
