import React from 'react'
import { TextStyle } from 'react-native'
import { Children, VoidFunction } from 'lib/types'
import { createStyles } from 'lib/styles'
import { useStyles } from 'lib/hooks'
import { BaseText } from './BaseText'

type ButtonTextProps = {
    numberOfLines?: number,
    onPress?: VoidFunction,
    style?: TextStyle,
    forceColor?: string,
    children?: Children
}

export const ButtonText: React.FunctionComponent<ButtonTextProps> = ({
    children,
    numberOfLines,
    onPress,
    style = {},
    forceColor
}) => {
    const { styles } = useStyles(stylesheet)

    return (
        <BaseText
            onPress={onPress}
            style={{
                ...styles.text,
                ...style
            }}
            numberOfLines={numberOfLines}
            forceColor={forceColor}
            children={children}
        />
    )
}

const stylesheet = createStyles(theme => ({
    text: {
        ...theme.components.typography.title,
        fontSize: 16,
        lineHeight: 20,
        fontWeight: '500'
    }
}))
