import React, { useMemo } from 'react'
import { Field } from '@codegateinc/react-form-builder-v2'
import { View } from 'react-native'
import { isNative } from 'lib/common'
import { useStyles } from 'lib/hooks'
import { Grid, Typography } from 'lib/components'
import { Country, SelectInputOption } from 'lib/types'
import { createStyles } from 'lib/styles'
import { FormComponents } from '../components'

interface SelectCountryOption extends SelectInputOption {
    flagCode: string
}

interface SelectCountryCodeInputProps extends Field<Country> {
    options: Array<Country>,
    disabled?: boolean,
    isClearable?: boolean,
    defaultValue?: Country,
    dropdownStyles?: boolean,
}

export const SelectCountryCodeInput: React.FunctionComponent<SelectCountryCodeInputProps> = ({
    options,
    onChangeValue,
    value,
    placeholder,
    errorMessage,
    dropdownStyles = false,
    isClearable = true,
    disabled = false
}) => {
    const { styles } = useStyles(stylesheet)
    const mappedOptions: Array<SelectCountryOption> = useMemo(() => options.map(option => ({
        label: isNative
            ? `${option.flagEmoji} ${option.code}`
            : option.code,
        value: `${option.code}/${option.label}`,
        flagCode: option.flagCode
    })), [])
    const selectedCountry = mappedOptions.find(country => {
        const [countryCode, countryName] = country.value.split('/')

        return countryCode === value.code && countryName === value.label
    }) || null

    return (
        <View style={styles.row}>
            <FormComponents.SelectInput
                defaultValue={mappedOptions.find(item => item.flagCode === 'sg')}
                isSearchable={!isNative}
                options={mappedOptions}
                disabled={disabled}
                isClearable={isClearable}
                errorMessage={errorMessage}
                placeholder={placeholder}
                dropdownStyles={dropdownStyles}
                value={selectedCountry}
                onChange={option => {
                    const [countryCode, countryName] = option?.value.split('/') || []
                    const country = options.find(country => country.code === countryCode && country.label === countryName) as Country

                    onChangeValue(country)
                }}
                formatOptionLabel={(option: SelectCountryOption) => (
                    <View style={styles.option}>
                        <Grid.Gap
                            gapRight={1}
                            style={styles.flagOutline}
                        >
                            <div className={`fi fi-${option.flagCode}`} />
                        </Grid.Gap>
                        <Typography.Label>
                            {option.label}
                        </Typography.Label>
                    </View>
                )}
            />
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    row: {
        width: 145
    },
    option: {
        flexDirection: 'row'
    },
    flagOutline: {
        borderWidth: 1,
        borderColor: theme.colors.marble,
        height: 15,
        width: 19,
        justifyContent: 'center',
        alignItems: 'center',
        ...theme.utils.createShadow(0, 0.25, 5, 5, 0)
    }
}))
