import React from 'react'
import { View } from 'react-native'
import { createStyles } from 'lib/styles'
import { useLocalizedDates, useStyles } from 'lib/hooks'
import { Regular } from '../typography'

type CalendarDaysNameProps = {
    liteMode?: boolean,
    simpleMode?: boolean
}

export const CalendarDaysName: React.FunctionComponent<CalendarDaysNameProps>  = ({ liteMode, simpleMode }) => {
    const { styles, theme } = useStyles(stylesheet)
    const { getWeekDayNames, getWeekDayShortNames } = useLocalizedDates()
    const weekDays = simpleMode ? getWeekDayShortNames() : getWeekDayNames()

    return (
        <View style={styles.container}>
            {weekDays.map((dayName, index) => (
                <View
                    key={index}
                    style={liteMode ? styles.dayNameWithLiteMode : styles.dayName}
                >
                    <Regular forceColor={theme.colors.night}>
                        {dayName}
                    </Regular>
                </View>
            ))}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        flexDirection: 'row'
    },
    dayName: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        borderBottomWidth: 1,
        borderColor: theme.colors.silver,
        paddingVertical: theme.utils.gap(2)
    },
    dayNameWithLiteMode: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        borderBottomWidth: 1,
        borderColor: theme.colors.silver,
        paddingVertical: theme.utils.gap(1)
    }
}))
