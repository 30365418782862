import { useAtom } from 'jotai'
import { SupplyID } from 'lib/types'
import { MMKVKey } from 'lib/mmkv'
import {
    AirConConfig,
    CleaningConfig,
    CookingConfig,
    DeepCleaningConfig,
    HandymanConfig,
    LaundryConfig,
    MoversAndPackersConfig,
    PestControlConfig,
    SendhelperServiceConfig,
    ServiceBaseInfo,
    TaskAndErrandsConfig
} from 'features/bookings'
import { createAtom } from './atomFactory'

export type ServicesConfig = {
    [SupplyID.Cleaner]: CleaningConfig
    [SupplyID.DeepCleaning]: DeepCleaningConfig
    [SupplyID.ACService]: AirConConfig
    [SupplyID.Handyman]: HandymanConfig
    [SupplyID.Laundry]: LaundryConfig
    [SupplyID.Cooking]: CookingConfig
    [SupplyID.PestControl]: PestControlConfig
    [SupplyID.TasksErrands]: TaskAndErrandsConfig
    [SupplyID.MoversPackers]: MoversAndPackersConfig
}

const initCleanerConfig: CleaningConfig = {
    _id: '',
    id: 0,
    name: '',
    sort_order: 0,
    price_per_hour: 0,
    recurring_price_per_hour: 0,
    use_preferred_helper: false,
    building_type: [],
    min_notice_hours: 0
}

const initDeepCleaningConfig: DeepCleaningConfig = {
    _id: '',
    id: 0,
    name: '',
    sort_order: 0,
    price_per_hour: 0,
    recurring_price_per_hour: 0,
    use_preferred_helper: false,
    building_type: [],
    min_notice_hours: 0
}

const initAirConConfig: AirConConfig = {
    _id: '',
    id: 0,
    name: '',
    sort_order: 0,
    price_per_hour: 0,
    recurring_price_per_hour: 0,
    use_preferred_helper: false,
    pricing: {
        oneTime: {
            label: 'One-Time',
            description: [],
            minUnits: 1,
            items: []
        },
        contract: {
            label: 'Contract',
            description: [],
            triyearly: {
                label: 'Tri-yearly (3x)',
                frequency: '',
                minUnits: 2,
                items: []
            },
            quarterly: {
                label: 'Quarterly (4x)',
                frequency: '',
                minUnits: 2,
                items: []
            }
        },
        custom: {
            label: 'Custom',
            description: [],
            minUnits: 1,
            items: []
        }
    },
    min_notice_hours: 0
}

const initHandymanConfig: HandymanConfig = {
    _id: '',
    id: 0,
    name: '',
    sort_order: 0,
    price_per_hour: 0,
    recurring_price_per_hour: 0,
    use_preferred_helper: false,
    min_notice_hours: 0
}

const initLaundryConfig: LaundryConfig = {
    _id: '',
    id: 0,
    name: '',
    sort_order: 0,
    price_per_hour: 0,
    recurring_price_per_hour: 0,
    use_preferred_helper: false,
    minimum_payment: 0,
    min_notice_hours: 0
}

const initCookingConfig: CookingConfig = {
    _id: '',
    id: 0,
    name: '',
    sort_order: 0,
    price_per_hour: 0,
    recurring_price_per_hour: 0,
    use_preferred_helper: false,
    cooking_options: {
        cuisines: []
    },
    building_type: [],
    min_notice_hours: 0
}

const initPestControlConfig: PestControlConfig = {
    _id: '',
    id: 0,
    name: '',
    sort_order: 0,
    price_per_hour: 0,
    recurring_price_per_hour: 0,
    use_preferred_helper: false,
    pricing_chart: [],
    min_notice_hours: 0
}

const initTaskErrandsConfig: TaskAndErrandsConfig = {
    _id: '',
    id: 0,
    name: '',
    sort_order: 0,
    price_per_hour: 0,
    recurring_price_per_hour: 0,
    use_preferred_helper: false,
    min_notice_hours: 0,
    pricing: {
        pricePerHour: 0,
        shoppingFee: {
            label: '',
            value: 0
        },
        taskOptions: []
    }
}

const initMoversPackersConfig: MoversAndPackersConfig = {
    _id: '',
    id: 0,
    name: '',
    sort_order: 0,
    price_per_hour: 0,
    recurring_price_per_hour: 0,
    use_preferred_helper: false,
    min_notice_hours: 0,
    service_type: []
}

export const initConfigState = {
    [SupplyID.Cleaner]: initCleanerConfig,
    [SupplyID.DeepCleaning]: initDeepCleaningConfig,
    [SupplyID.ACService]: initAirConConfig,
    [SupplyID.Handyman]: initHandymanConfig,
    [SupplyID.Laundry]: initLaundryConfig,
    [SupplyID.Cooking]: initCookingConfig,
    [SupplyID.PestControl]: initPestControlConfig,
    [SupplyID.TasksErrands]: initTaskErrandsConfig,
    [SupplyID.MoversPackers]: initMoversPackersConfig
}

const { atom } = createAtom<ServicesConfig>(initConfigState, MMKVKey.ServicesConfig)

export const useServiceConfigAtom = () => useAtom(atom)

const initialSharedConfigState: Array<ServiceBaseInfo> = Array(9).fill(null).map(() => ({
    id: 0,
    _id: '',
    name: '',
    sort_order: 0,
    price_per_hour: undefined,
    recurring_price_per_hour: undefined,
    use_preferred_helper: false
}))

const { atom: sharedService } = createAtom(initialSharedConfigState)

export const useSharedServiceConfigAtom = () => useAtom(sharedService)

export const useDynamicServiceAtom = (supply: SupplyID): [SendhelperServiceConfig] => {
    const [availableServices] = useSharedServiceConfigAtom()

    return [
        availableServices.find(service => service.id === supply) as SendhelperServiceConfig
    ]
}
