import React, { useState } from 'react'
import { Segment } from 'lib/analytics'
import { SectionBox } from 'lib/components'
import { useTranslations } from 'lib/hooks'
import { UserEditProfileForm } from './UserEditProfileForm'
import { UserEditProfilePreview } from './UserEditProfilePreview'

export const UserEditProfileSection: React.FunctionComponent = () => {
    const T = useTranslations()
    const [isEditing, setIsEditing] = useState(false)

    return (
        <SectionBox
            title={T.screens.user.sections.basics}
        >
            {!isEditing
                ? (
                    <UserEditProfilePreview
                        onEdit={() => {
                            Segment.editProfileClicked({})

                            setIsEditing(true)
                        }}
                    />
                )
                : (
                    <UserEditProfileForm onCloseEditMode={() => setIsEditing(false)} />
                )
            }
        </SectionBox>
    )
}
