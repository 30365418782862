import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const EyeDisabled: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path d="M40 42c-3.307 0-6-2.692-6-6 0-.053.013-.104.025-.155a.75.75 0 0 0 .023-.141l6.249 6.248c-.048.002-.095.013-.142.023a.682.682 0 0 1-.154.025ZM10.829 1.173a3.996 3.996 0 0 0-5.656 0 3.996 3.996 0 0 0 0 5.656l22.52 22.52A13.885 13.885 0 0 0 26 36.001c0 7.72 6.28 14 14 14 2.357 0 4.617-.587 6.653-1.691l22.52 22.52A3.989 3.989 0 0 0 72 72.002a3.99 3.99 0 0 0 2.828-1.172 3.996 3.996 0 0 0 0-5.656l-64-64.001Zm30.052 54.82c-17.22.4-28.46-14.332-32.189-20.008 1.828-2.856 4.892-7.04 9.132-10.845l-5.644-5.648C6.091 25.048 2.187 31.121.531 34.012a3.995 3.995 0 0 0 0 3.98c2.516 4.389 16.116 26.009 39.568 26.009.329 0 .657-.004.985-.012 4.736-.12 9.024-1.144 12.908-2.68l-6.32-6.32c-2.14.568-4.392.944-6.792 1.004ZM38.92 8.015c23.9-.748 38 21.548 40.552 25.996a4.014 4.014 0 0 1 0 3.98c-1.66 2.892-5.564 8.964-11.652 14.52l-5.644-5.648c4.24-3.804 7.308-7.988 9.132-10.844-3.728-5.676-15.02-20.44-32.185-20.008-2.4.06-4.652.436-6.796 1.004l-6.32-6.32c3.888-1.536 8.172-2.56 12.912-2.68Z" />
    </Icon>
)
