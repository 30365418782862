import { Country } from 'lib/types'

export enum SignUpFields {
    Name = 'name',
    Country = 'country',
    PhoneNumber = 'phoneNumber',
    Email = 'email',
    Password = 'password',
    ConfirmPassword = 'confirmPassword',
    MarketingConsent = 'marketingConsent',
}

export type SignUpFormShape = {
    [SignUpFields.Name]: string,
    [SignUpFields.Country]: Country,
    [SignUpFields.PhoneNumber]: string,
    [SignUpFields.Email]: string,
    [SignUpFields.Password]: string,
    [SignUpFields.ConfirmPassword]: string,
    [SignUpFields.MarketingConsent]: boolean,
}

export enum LogInFields {
    Email = 'email',
    Password = 'password'
}

export type LogInFormShape = {
    [LogInFields.Email]: string,
    [LogInFields.Password]: string
}

export enum ForgotPasswordFields {
    Email = 'email'
}

export type ForgotPasswordFormShape = {
    [ForgotPasswordFields.Email]: string
}

export enum NewPasswordFields {
    NewPassword = 'newPassword',
    ConfirmNewPassword = 'confirmNewPassword'
}

export type NewPasswordFormShape = {
    [NewPasswordFields.NewPassword]: string,
    [NewPasswordFields.ConfirmNewPassword]: string
}

export enum SingleSignOnFields {
    Email = 'email'
}

export type SingleSignOnFormShape = {
    [SingleSignOnFields.Email]: string
}
