import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import { Icons } from 'assets'
import { useNavigation } from '@react-navigation/native'
import { createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { CustomModal, Button, Typography, Touchable, DockedModal } from 'lib/components'
import { CustomFonts } from 'lib/types'
import { ScreenNames } from 'lib/routing'
import { ENV } from 'lib/config'
import { isNative } from 'lib/common'
import { jobHelpers, linkingHelpers } from 'lib/utils'
import { JobDetailsResponse } from 'features/jobs/types'
import { SuggestPauseSubscriptionModal } from 'features/jobs/components/suggestPauseSubscriptionModal'

type SubscriptionRescheduleRequestModalProps = {
    job: JobDetailsResponse,
    isOpen: boolean,
    onClose: VoidFunction,
    onRescheduleBooking: VoidFunction,
    onPausePackage: VoidFunction,
}

export const SubscriptionRescheduleRequestModal: React.FunctionComponent<SubscriptionRescheduleRequestModalProps> = ({
    isOpen,
    job,
    onClose,
    onRescheduleBooking,
    onPausePackage
}) => {
    const T = useTranslations()
    const navigation = useNavigation()
    const Modal = isNative ? DockedModal : CustomModal
    const { styles, theme } = useStyles(stylesheet)
    const [isSuggestPauseSubscriptionModalShow, setIsSuggestPauseSubscriptionModalShow] = useState<boolean>(false)
    const cancellationFee = jobHelpers.getPolicyCancellationFee(job)

    const totalSessionsRemaining = jobHelpers.getSessionsRemaining(job)

    useEffect(() => {
        !isOpen && setIsSuggestPauseSubscriptionModalShow(false)
    }, [isOpen])

    const handleCloseModal = () => {
        isSuggestPauseSubscriptionModalShow && setIsSuggestPauseSubscriptionModalShow(false)
        onClose()
    }

    const onProceedToCancel = () => {
        if (jobHelpers.isSubmitted(job) || jobHelpers.isPaused(job)) {
            handleCloseModal()

            navigation.navigate(ScreenNames.CancelJob, {
                jobId: job.job._id
            })

            return
        }

        setIsSuggestPauseSubscriptionModalShow(true)
    }

    const isFreeCancellation = job?.cancellationPolicy?.isFreeCancellation
    const shouldShowAdditionalFeeForCancelSubscription = !isFreeCancellation
                                                            && jobHelpers.shouldShowAdditionalFeeForCancelSubscription(job)

    if (isSuggestPauseSubscriptionModalShow) {
        return  (
            <SuggestPauseSubscriptionModal
                onClose={handleCloseModal}
                isOpen={isOpen}
                job={job}
                onPauseBooking={onPausePackage}
                onRescheduleBooking={onRescheduleBooking}
            />
        )
    }

    return (
        <Modal
            onClose={handleCloseModal}
            isOpen={isOpen}
            contentStyles={styles.contentStyles}
        >
            <View style={styles.mainContainer}>
                <View style={{
                    ...styles.modalWrapper,
                    ...styles.headerWrapper
                }}>
                    <Typography.SmallSubheading style={styles.title}>
                        {shouldShowAdditionalFeeForCancelSubscription ?
                            T.screens.jobDetails.subscriptionRescheduleRequest.packageTitleWithAdditionFee :
                            T.screens.jobDetails.subscriptionRescheduleRequest.packageTitle
                        }
                    </Typography.SmallSubheading>
                    {
                        shouldShowAdditionalFeeForCancelSubscription && (
                            <View>
                                <View style={styles.additionFeeWarningContainer}>
                                    <View style={styles.iconContainer}>
                                        <Icons.Alert size={32} forceColor={theme.colors.strawberry}/>
                                    </View>

                                    <View style={styles.warningContainer}>
                                        <Typography.Regular style={styles.warningText}>
                                            {`${T.screens.jobDetails.subscriptionRescheduleRequest.youHave} `}
                                            <Typography.Regular
                                                testID={T.accessibility.jobDetailsScreen.subscriptionRescheduleRequest.totalSessions}
                                                style={{
                                                    ...styles.warningText,
                                                    ...styles.boldText
                                                }} bold>
                                                { `${totalSessionsRemaining} ${T.screens.jobDetails.subscriptionRescheduleRequest.sessions}` }
                                            </Typography.Regular>
                                            <Typography.Regular style={styles.warningText}>
                                                {` ${T.screens.jobDetails.subscriptionRescheduleRequest.remaining}`}
                                            </Typography.Regular>
                                        </Typography.Regular>
                                    </View>

                                    <Typography.Regular style={styles.noticeText}>
                                        {T.screens.jobDetails.subscriptionRescheduleRequest.youWillBeChargedAnAdditional}
                                        <Typography.Regular style={{
                                            ...styles.noticeText,
                                            ...styles.boldText,
                                            ...styles.noWrap
                                        }}>
                                            {` ${cancellationFee} `}
                                        </Typography.Regular>
                                        {T.screens.jobDetails.subscriptionRescheduleRequest.toCancelPlan}
                                        <Typography.Regular style={{
                                            ...styles.link,
                                            ...styles.noWrap
                                        }} onPress={() => linkingHelpers.openUrl(`${ENV.SENDHELPER_MARKETING_SITE_URL}/cancellation-policy`)}>
                                            {` ${T.screens.jobDetails.subscriptionRescheduleRequest.whyAmIBeingCharged} `}
                                            <Icons.QuestionMark viewBox={
                                                isNative ? '0 -4 20 20' : '0 -5.5 20 20'
                                            } size={16} forceColor={theme.colors.strawberry}/>
                                        </Typography.Regular>
                                    </Typography.Regular>
                                </View>
                            </View>
                        )}
                    <View style={styles.messageContainer}>
                        <Typography.Regular style={styles.message}>
                            {isFreeCancellation ?
                                T.screens.jobDetails.subscriptionRescheduleRequest.message :
                                job?.cancellationPolicy?.reconciliation || T.screens.jobDetails.subscriptionRescheduleRequest.message}
                        </Typography.Regular>
                    </View>
                </View>
                <View style={styles.modalWrapper}>
                    <View style={styles.actionContainer}>
                        <View style={styles.buttonWrapper}>
                            <Button
                                testID={T.accessibility.jobDetailsScreen.subscriptionRescheduleRequest.keepPlan}
                                noBackground
                                text={T.screens.jobDetails.subscriptionRescheduleRequest.keepMyPlan}
                                onPress={onClose}
                                textWrapperStyles={styles.buttonTextWrapper}
                                textStyles={styles.buttonText}
                            />
                        </View>
                        <View style={styles.spacer}/>
                        <View style={styles.buttonWrapper}>
                            <Button
                                testID={T.accessibility.jobDetailsScreen.subscriptionRescheduleRequest.reschedule}
                                text={T.screens.jobDetails.subscriptionRescheduleRequest.submitReschedulingRequest}
                                onPress={onRescheduleBooking}
                                textWrapperStyles={styles.buttonTextWrapper}
                                textStyles={styles.buttonText}
                            />
                        </View>
                    </View>
                </View>
                <View style={styles.footerWrapper}>
                    <Touchable onPress={onProceedToCancel} testID={T.accessibility.jobDetailsScreen.subscriptionRescheduleRequest.cancel}>
                        <Typography.Regular style={styles.cancelPlanLink}>
                            {T.screens.jobDetails.subscriptionRescheduleRequest.noIWantToCancelPlan}
                        </Typography.Regular>
                    </Touchable>
                </View>
            </View>
        </Modal>
    )
}

const stylesheet = createStyles(theme => ({
    contentStyles: {
        paddingBottom: theme.utils.gap(3)
    },
    mainContainer: {
        flex: 1
    },
    modalWrapper: {
        paddingHorizontal: {
            lg: theme.utils.gap(12),
            md: theme.utils.gap(8),
            sm: theme.utils.gap(6),
            xs: theme.utils.gap(2)
        }
    },
    headerWrapper: {
        alignItems: 'center'
    },
    title: {
        textAlign: 'center',
        fontSize: 25,
        lineHeight: 31,
        fontFamily: CustomFonts.Poppins600
    },
    messageContainer: {
        marginTop: theme.utils.gap(1.5)
    },
    message: {
        textAlign: 'center'
    },
    actionContainer: {
        paddingTop: theme.utils.gap(3.75),
        paddingBottom: theme.utils.gap(2.5),
        flexDirection: {
            md: 'row',
            xs: 'column-reverse'
        },
        justifyContent: 'center',
        zIndex: -1,
        flex: 1
    },
    buttonWrapper: {
        flex: 1
    },
    buttonTextWrapper: {
        width: '100%',
        wordWrap: 'break-word',
        textAlign: 'center',
        marginHorizontal: theme.utils.gap(2.25)
    },
    buttonText: {
        textAlign: 'center'
    },
    footerWrapper: {
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    cancelPlanLink: {
        color: theme.colors.mouse,
        fontFamily: CustomFonts.Poppins500,
        fontSize: 14
    },
    additionFeeWarningContainer: {
        flexDirection: 'column',
        marginVertical: theme.utils.gap(2),
        padding: theme.utils.gap(2),
        backgroundColor: theme.colors.linen,
        borderRadius: 8,
        justifyContent: 'center',
        alignItems: 'center'
    },
    warningText: {
        color: theme.colors.strawberry,
        fontFamily: CustomFonts.Poppins400,
        fontSize: 16,
        lineHeight: 24,
        textAlign: 'center'
    },
    boldText: {
        fontWeight: 'bold'
    },
    iconContainer: {
        marginBottom: theme.utils.gap(1.25)
    },
    noticeText: {
        textAlign: 'center',
        fontSize: 13,
        lineHeight: 18
    },
    warningContainer: {
        marginBottom: theme.utils.gap(1.5)
    },
    link: {
        color: theme.colors.strawberry,
        fontWeight: 'bold',
        fontSize: 13,
        lineHeight: 18,
        cursor: 'pointer'
    },
    spacer: {
        width: theme.utils.gap(2),
        height: theme.utils.gap(1)
    },
    noWrap: {
        whiteSpace: 'nowrap'
    }
}))
