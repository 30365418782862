import React from 'react'
import { View } from 'react-native'
import { Address } from 'lib/models'
import { createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { Touchable, Typography } from 'lib/components'

type AddressTileBodyProps = {
    onEdit: VoidFunction,
    onDelete: VoidFunction,
    address: Address
}

export const AddressTileBody: React.FunctionComponent<AddressTileBodyProps> = ({
    onDelete,
    address
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const borderColor = address.isPrimary
        ? theme.colors.navajo
        : theme.colors.silver

    return (
        <View>
            <View
                style={{
                    ...styles.content,
                    borderColor
                }}
            >
                <View style={styles.row}>
                    <Typography.Regular style={styles.flex}>
                        {T.screens.address.postalCode}
                    </Typography.Regular>
                    <Typography.Regular
                        bold
                        style={styles.flex}
                    >
                        {address.postcode}
                    </Typography.Regular>
                </View>
                <View style={styles.row}>
                    <Typography.Regular style={styles.flex}>
                        {T.screens.address.addressLine}
                    </Typography.Regular>
                    <Typography.Regular
                        bold
                        style={styles.flex}
                    >
                        {address.address}
                    </Typography.Regular>
                </View>
                <View style={styles.row}>
                    <Typography.Regular style={styles.flex}>
                        {T.screens.address.unit}
                    </Typography.Regular>
                    <Typography.Regular
                        bold
                        style={styles.flex}
                    >
                        {address.unit}
                    </Typography.Regular>
                </View>
            </View>
            <View style={styles.buttons}>
                {/* TODO No API to edit address user can only add new one or delete old */}
                {/* <Touchable
                    onPress={onEdit}
                    style={styles.button}
                >
                    <Typography.Regular
                        bold
                        forceColor={theme.colors.orange}
                    >
                        {T.screens.address.edit}
                    </Typography.Regular>
                </Touchable> */}
                <Touchable
                    onPress={onDelete}
                    style={styles.button}
                >
                    <Typography.Regular
                        bold
                        forceColor={theme.colors.orange}
                    >
                        {T.screens.address.delete}
                    </Typography.Regular>
                </Touchable>
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    row: {
        flexDirection: 'row',
        marginBottom: theme.utils.gap(2)
    },
    flex: {
        flex: 1,
        marginRight: theme.utils.gap(1) / 2
    },
    content: {
        marginTop: theme.utils.gap(1),
        marginBottom: theme.utils.gap(3),
        borderTopWidth: 1,
        borderBottomWidth: 1,
        paddingTop: theme.utils.gap(2)
    },
    buttons: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginBottom: theme.utils.gap(3)
    },
    button: {
        borderBottomWidth: 1,
        borderColor: theme.colors.orange
    }
}))
