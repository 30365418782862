import { useTranslations } from 'lib/hooks'
import { TaskAndErrandsFormShape } from 'features/bookings'

export const useTaskAndErrandsAdditionalInformation = (taskAndErrandsFormShape: TaskAndErrandsFormShape) => {
    const T = useTranslations()
    const otherTaskCommentSpacing = taskAndErrandsFormShape.otherTaskComment
        ? '\n'
        : ''

    return `${T.screens.jobDetails.sections.serviceType}:
    · ${taskAndErrandsFormShape.taskType.label}
${otherTaskCommentSpacing}${taskAndErrandsFormShape.otherTaskComment}${otherTaskCommentSpacing}`
// TODO Hide for now
// ${T.screens.jobDetails.sections.vehicle}:
//     · ${taskAndErrandsFormShape.vehicle}`
}
