import { useState } from 'react'
import { ReferralShareData } from '../../types'

export const useShareReferral = (shareData: ReferralShareData) => {
    const [error, setError] = useState(false)
    const { title, message, url } = shareData
    const shareSupported = Boolean(navigator.share)
    const onShare = async () => {
        try {
            if (!shareSupported) {
                return setError(true)
            }

            await navigator.share({
                title,
                text: message,
                url
            })
        } catch {
            setError(true)
        }
    }

    return {
        error,
        shareSupported,
        onShare
    }
}
