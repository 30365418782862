import React from 'react'
import { Children } from 'lib/types'
import { useStyles } from 'lib/hooks'
import { isNative } from 'lib/common'
import { createStyles } from 'lib/styles'

type StickyProps = {
    children: Children
}

export const StickyMobile: React.FunctionComponent<StickyProps> = ({
    children
}) => {
    const { styles } = useStyles(stylesheet)

    if (isNative) {
        return (
            <React.Fragment>
                {children}
            </React.Fragment>
        )
    }

    return (
        <div style={styles.wrapper}>
            {children}
        </div>
    )
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        top: 0,
        zIndex: theme.zIndex[10],
        position: 'sticky'
    }
}))
