import { SupplyPricingChart, ACPricingChart, TasksErrandsPricing } from 'lib/models'

export type ServiceBaseInfo = {
    id: number,
    _id: string,
    sort_order: number,
    name: string,
    use_preferred_helper: boolean,
    price_per_hour?: number,
    recurring_price_per_hour?: number
}

export interface SendhelperServiceConfig extends ServiceBaseInfo {
    min_notice_hours: number,
}

export interface SendhelperServiceWithPricing extends SendhelperServiceConfig {
    price_per_hour: number,
    recurring_price_per_hour: number
}

export interface EquipmentDisclaimerPopupItem {
    roomType?: string,
    items?: Array<string>
}
export interface EquipmentDisclaimerPopup {
    title?: string,
    subtitle?: string,
    listItems: Array<EquipmentDisclaimerPopupItem>,
}
export interface EquipmentDisclaimer {
    title?: string,
    imageUrl?: string,
    description?: string,
    ctaButton?: string,
    popup?: EquipmentDisclaimerPopup
}

export interface ServicePlanOptionPrice {
    amount?: number,
    currency?: string,
    perUnit?: string,
}

export interface ServicePlanOption {
    numberOfSessions?: number,
    frequency?: string,
    frequencyTitle?: string,
    title?: string,
    saverText?: string,
    bestValueText?: string,
    defaultSelected?: boolean,
    isSaver?: boolean,
    isBestValue?: boolean,
    autoRenewal?: boolean,
    price?: ServicePlanOptionPrice,
    benefits?: Array<string>,
    frequencyType?: Array<string>,
}

export interface ServicePlan {
    title?: string,
    footnote?: string,
    options?: Array<ServicePlanOption>
}

export interface CleaningPricing {
    equipmentDisclaimer?: EquipmentDisclaimer,
    servicePlan?: ServicePlan
}

export interface CleaningConfig extends SendhelperServiceConfig {
    building_type: Array<string>,
    price_per_hour: number,
    recurring_price_per_hour: number,
    pricing?: CleaningPricing
}

export enum PropertyCleaningValue {
    True = 'true',
    False = 'false'
}
export interface PropertyCleaningOption {
    label: string,
    isNotPropertyCleaning?: boolean
}

export interface DeepCleaningPricing {
    options: Array<PropertyCleaningOption>
}

export interface DeepCleaningConfig extends SendhelperServiceConfig {
    building_type: Array<string>,
    pricing?: DeepCleaningPricing
}

export interface AirConConfig extends SendhelperServiceConfig {
    _id: string;
    id: number;
    sort_order: number;
    name: string;
    use_preferred_helper: boolean;
    min_notice_hours: number;
    pricing: ACPricingChart;
}

export interface PestControlConfig extends SendhelperServiceConfig {
    pricing_chart: Array<SupplyPricingChart>
}

export interface CookingConfig extends SendhelperServiceConfig {
    cooking_options: {
        cuisines: Array<string>
    },
    building_type: Array<string>,
    price_per_hour: number,
    recurring_price_per_hour: number
}

export interface LaundryConfig extends SendhelperServiceConfig {
    minimum_payment: number
}

export type HandymanConfig = SendhelperServiceConfig

export interface TaskAndErrandsConfig extends SendhelperServiceConfig {
    price_per_hour: number,
    recurring_price_per_hour: number,
    pricing: TasksErrandsPricing,
}

export interface MoversAndPackersConfig extends SendhelperServiceConfig {
    price_per_hour: number,
    recurring_price_per_hour: number,
    service_type: Array<string>,
}
