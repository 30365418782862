import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const SavedMoney: React.FunctionComponent<IconProps> = props => (
    <Icon viewBox="0 0 57 58" {...props}>
        <Path d="M28.5002 51.8515C41.0923 51.8515 51.3002 41.6436 51.3002 29.0515C51.3002 16.4594 41.0923 6.25148 28.5002 6.25148C15.9081 6.25148 5.7002 16.4594 5.7002 29.0515C5.7002 41.6436 15.9081 51.8515 28.5002 51.8515Z" fill="white"/>
        <Path d="M37.1068 46.1931C37.9048 46.0039 38.3642 45.7901 38.3642 45.5627C38.3642 44.7966 33.2393 44.1753 26.9174 44.1753C20.5956 44.1753 15.4707 44.7966 15.4707 45.5627C15.4707 46.2433 19.5137 46.8081 24.8449 46.9267C24.8449 46.9347 24.8358 46.9426 24.8358 46.9506C24.8358 47.5252 28.0973 47.9909 32.121 47.9909C36.1446 47.9909 39.405 47.5252 39.405 46.9506C39.4044 46.6519 38.5181 46.3806 37.1068 46.1931Z" fill="#FFF6D2"/>
        <Path d="M41.133 30.6794C41.133 29.8683 41.5719 29.2111 42.1122 29.2111C42.6526 29.2111 43.0915 29.8683 43.0915 30.6794C43.0915 32.301 41.7765 33.1264 40.1543 33.1264" stroke="#F9B130" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M40.154 33.1264C40.154 27.3295 36.18 25.0826 29.3867 24.4926L29.2927 24.8346C28.75 26.8045 26.3674 26.8655 25.8379 24.8033L25.7239 24.3695C23.8981 24.4221 22.0804 24.635 20.2918 25.0056L16.7407 22.0473C16.6693 21.9877 16.5824 21.9497 16.4901 21.9377C16.3979 21.9257 16.3041 21.9403 16.2199 21.9797C16.1356 22.0192 16.0644 22.0818 16.0145 22.1603C15.9646 22.2388 15.9381 22.3299 15.9381 22.4229V26.8068C15.2156 27.3167 14.6011 27.9644 14.1298 28.7126C13.6585 29.4609 13.3397 30.2949 13.1919 31.1667H12.0217C11.7621 31.1667 11.5132 31.2699 11.3296 31.4534C11.1461 31.6369 11.043 31.8859 11.043 32.1454V35.0826C11.0426 35.2114 11.0676 35.3389 11.1167 35.458C11.1657 35.577 11.2377 35.6852 11.3286 35.7763C11.4196 35.8675 11.5276 35.9398 11.6465 35.9891C11.7654 36.0384 11.8929 36.0637 12.0217 36.0636H13.4432C14.2344 38.4821 16.108 39.9829 18.8736 40.863V43.8948C18.8736 44.1546 18.9768 44.4036 19.1605 44.5873C19.3441 44.7709 19.5932 44.8741 19.8529 44.8741H22.7901C22.9187 44.8741 23.0461 44.8488 23.1649 44.7995C23.2837 44.7502 23.3917 44.6781 23.4825 44.587C23.5734 44.496 23.6455 44.388 23.6946 44.2692C23.7437 44.1503 23.7689 44.0229 23.7688 43.8943V41.7636H23.7631C24.6962 41.8878 25.637 41.9458 26.5783 41.9375C27.8514 41.9529 29.1234 41.8602 30.3808 41.6604L30.366 41.6684V43.8954C30.366 44.155 30.4691 44.404 30.6526 44.5877C30.8361 44.7713 31.085 44.8745 31.3447 44.8747H34.2819C34.4104 44.8746 34.5376 44.8492 34.6564 44.8C34.7751 44.7507 34.8829 44.6786 34.9737 44.5876C35.0646 44.4967 35.1366 44.3888 35.1857 44.27C35.2348 44.1512 35.2601 44.0239 35.26 43.8954V40.5216C38.4081 39.2448 40.154 36.9659 40.154 33.1264Z" fill="white" stroke="#F9B130" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M45.0484 31.1685C45.0484 32.2515 44.3912 33.1264 43.5801 33.1264" stroke="#F9B130" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M17.1784 31.3474C17.5287 31.3474 17.8128 31.0634 17.8128 30.713C17.8128 30.3626 17.5287 30.0786 17.1784 30.0786C16.828 30.0786 16.5439 30.3626 16.5439 30.713C16.5439 31.0634 16.828 31.3474 17.1784 31.3474Z" fill="#F9B130"/>
        <Path d="M44.9616 25.5186C45.0217 25.2588 45.1536 25.021 45.3421 24.8324C45.5306 24.6438 45.7683 24.5118 46.0281 24.4516L48.169 23.958C48.2082 23.9489 48.2432 23.9269 48.2682 23.8954C48.2932 23.8639 48.3069 23.8249 48.3069 23.7847C48.3069 23.7445 48.2932 23.7054 48.2682 23.674C48.2432 23.6425 48.2082 23.6204 48.169 23.6114L46.0281 23.1172C45.7684 23.057 45.5308 22.9252 45.3422 22.7366C45.1537 22.5481 45.0218 22.3105 44.9616 22.0507L44.4675 19.911C44.4584 19.8718 44.4364 19.8368 44.4049 19.8118C44.3734 19.7868 44.3344 19.7731 44.2942 19.7731C44.254 19.7731 44.2149 19.7868 44.1835 19.8118C44.152 19.8368 44.1299 19.8718 44.1209 19.911L43.6273 22.0507C43.5671 22.3105 43.4352 22.5481 43.2467 22.7366C43.0582 22.9252 42.8205 23.057 42.5608 23.1172L40.421 23.6114C40.3818 23.6204 40.3469 23.6425 40.3219 23.674C40.2968 23.7054 40.2832 23.7445 40.2832 23.7847C40.2832 23.8249 40.2968 23.8639 40.3219 23.8954C40.3469 23.9269 40.3818 23.9489 40.421 23.958L42.5602 24.4516C42.8201 24.5117 43.058 24.6436 43.2466 24.8323C43.4352 25.0209 43.5671 25.2587 43.6273 25.5186L44.1209 27.6595C44.1299 27.6987 44.152 27.7337 44.1835 27.7587C44.2149 27.7837 44.254 27.7974 44.2942 27.7974C44.3344 27.7974 44.3734 27.7837 44.4049 27.7587C44.4364 27.7337 44.4584 27.6987 44.4675 27.6595L44.9616 25.5186Z" fill="white" stroke="#F9B130" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M40.7549 9.52898L42.6496 8.89742C42.6849 8.88546 42.7156 8.86273 42.7374 8.83241C42.7591 8.80209 42.7708 8.76572 42.7708 8.72841C42.7708 8.6911 42.7591 8.65473 42.7374 8.62441C42.7156 8.59409 42.6849 8.57136 42.6496 8.55941L41.0924 8.04071C40.9876 8.00564 40.8923 7.94671 40.8142 7.86857C40.7361 7.79043 40.6771 7.6952 40.6421 7.59041L40.1228 6.03317C40.111 5.99775 40.0883 5.96695 40.0581 5.94512C40.0278 5.92329 39.9914 5.91154 39.9541 5.91154C39.9167 5.91154 39.8804 5.92329 39.8501 5.94512C39.8198 5.96695 39.7972 5.99775 39.7853 6.03317L39.2666 7.59041C39.2316 7.6952 39.1727 7.79043 39.0945 7.86857C39.0164 7.94671 38.9211 8.00564 38.8163 8.04071L37.258 8.55941C37.2226 8.57136 37.1919 8.59409 37.1702 8.62441C37.1484 8.65473 37.1367 8.6911 37.1367 8.72841C37.1367 8.76572 37.1484 8.80209 37.1702 8.83241C37.1919 8.86273 37.2226 8.88546 37.258 8.89742L38.8163 9.41669C38.921 9.45165 39.0162 9.51049 39.0942 9.58854C39.1723 9.66659 39.2311 9.76172 39.2661 9.86642L39.7853 11.4248C39.7972 11.4602 39.8198 11.491 39.8501 11.5128C39.8804 11.5347 39.9167 11.5464 39.9541 11.5464C39.9914 11.5464 40.0278 11.5347 40.0581 11.5128C40.0883 11.491 40.111 11.4602 40.1228 11.4248L40.7549 9.52898Z" fill="white" stroke="#F9B130" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M14.0789 17.8299L15.973 17.1966C16.0085 17.1848 16.0393 17.1622 16.0611 17.1319C16.0829 17.1016 16.0947 17.0652 16.0947 17.0279C16.0947 16.9906 16.0829 16.9542 16.0611 16.9239C16.0393 16.8936 16.0085 16.871 15.973 16.8592L14.4152 16.3416C14.3105 16.3065 14.2154 16.2476 14.1373 16.1694C14.0593 16.0913 14.0005 15.9961 13.9655 15.8913L13.4462 14.3341C13.4344 14.2987 13.4118 14.2679 13.3815 14.246C13.3512 14.2242 13.3148 14.2125 13.2775 14.2125C13.2402 14.2125 13.2038 14.2242 13.1735 14.246C13.1432 14.2679 13.1206 14.2987 13.1088 14.3341L12.5895 15.8913C12.5548 15.996 12.4962 16.0912 12.4184 16.1694C12.3405 16.2476 12.2455 16.3065 12.1409 16.3416L10.5826 16.8609C10.5471 16.8727 10.5163 16.8953 10.4945 16.9256C10.4727 16.9559 10.4609 16.9923 10.4609 17.0296C10.4609 17.0669 10.4727 17.1033 10.4945 17.1336C10.5163 17.1639 10.5471 17.1865 10.5826 17.1983L12.1404 17.7182C12.2452 17.7529 12.3405 17.8117 12.4187 17.8898C12.4969 17.9678 12.5558 18.0631 12.5907 18.1679L13.1099 19.7257C13.1217 19.7611 13.1444 19.7919 13.1747 19.8138C13.2049 19.8356 13.2413 19.8473 13.2787 19.8473C13.316 19.8473 13.3524 19.8356 13.3827 19.8138C13.4129 19.7919 13.4356 19.7611 13.4474 19.7257L14.0789 17.8299Z" fill="white" stroke="#F9B130" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M41.1695 42.7389C41.1695 42.3993 41.0346 42.0736 40.7946 41.8334C40.5545 41.5933 40.2289 41.4583 39.8893 41.4581H35.6194C35.2793 41.4581 34.9531 41.5932 34.7125 41.8337C34.472 42.0742 34.3369 42.4005 34.3369 42.7406C34.3369 43.0807 34.472 43.4069 34.7125 43.6475C34.9531 43.888 35.2793 44.0231 35.6194 44.0231H39.8893C40.0577 44.023 40.2245 43.9897 40.38 43.9251C40.5356 43.8605 40.6769 43.7658 40.7958 43.6466C40.9147 43.5273 41.0089 43.3857 41.073 43.2299C41.1372 43.0742 41.17 42.9073 41.1695 42.7389Z" fill="white" stroke="#F9B130" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M40.3161 45.3005C40.316 44.9608 40.181 44.6351 39.9408 44.395C39.7007 44.1548 39.375 44.0198 39.0354 44.0197H34.7661C34.431 44.0267 34.112 44.1648 33.8775 44.4043C33.643 44.6438 33.5117 44.9656 33.5117 45.3007C33.5117 45.6359 33.643 45.9577 33.8775 46.1972C34.112 46.4367 34.431 46.5748 34.7661 46.5818H39.0354C39.3751 46.5817 39.7009 46.4466 39.941 46.2063C40.1812 45.966 40.3161 45.6402 40.3161 45.3005Z" fill="white" stroke="#F9B130" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M31.7777 45.3005C31.7776 45.1321 31.7443 44.9655 31.6798 44.81C31.6153 44.6546 31.5208 44.5133 31.4017 44.3944C31.2826 44.2754 31.1413 44.1811 30.9857 44.1168C30.8302 44.0525 30.6635 44.0195 30.4952 44.0197H26.227C25.8919 44.0267 25.5729 44.1648 25.3385 44.4043C25.104 44.6438 24.9727 44.9656 24.9727 45.3008C24.9727 45.6359 25.104 45.9577 25.3385 46.1972C25.5729 46.4367 25.8919 46.5748 26.227 46.5818H30.4952C30.6635 46.582 30.8303 46.5489 30.9859 46.4846C31.1415 46.4203 31.2828 46.3259 31.4019 46.2069C31.5211 46.0879 31.6155 45.9466 31.68 45.7911C31.7445 45.6355 31.7777 45.4688 31.7777 45.3005Z" fill="white" stroke="#F9B130" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M33.4855 42.7389C33.4854 42.3993 33.3504 42.0737 33.1104 41.8336C32.8704 41.5935 32.5448 41.4584 32.2053 41.4581H27.9348C27.5947 41.4581 27.2685 41.5932 27.028 41.8337C26.7875 42.0742 26.6523 42.4005 26.6523 42.7406C26.6523 43.0807 26.7875 43.4069 27.028 43.6475C27.2685 43.888 27.5947 44.0231 27.9348 44.0231H32.2053C32.3737 44.0229 32.5404 43.9896 32.6959 43.925C32.8515 43.8603 32.9927 43.7657 33.1116 43.6464C33.2305 43.5271 33.3247 43.3856 33.3889 43.2299C33.453 43.0741 33.4859 42.9073 33.4855 42.7389Z" fill="white" stroke="#F9B130" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M31.7778 40.1767C31.7777 40.0084 31.7445 39.8418 31.68 39.6863C31.6155 39.5308 31.521 39.3896 31.4019 39.2706C31.2828 39.1517 31.1414 39.0574 30.9859 38.9931C30.8303 38.9288 30.6636 38.8958 30.4953 38.896H26.226C25.8909 38.903 25.572 39.0411 25.3375 39.2806C25.103 39.5201 24.9717 39.8419 24.9717 40.177C24.9717 40.5122 25.103 40.834 25.3375 41.0735C25.572 41.313 25.8909 41.451 26.226 41.4581H30.4953C30.6637 41.4583 30.8305 41.4253 30.9861 41.361C31.1417 41.2967 31.2832 41.2024 31.4023 41.0834C31.5214 40.9644 31.6159 40.823 31.6803 40.6674C31.7448 40.5119 31.7779 40.3451 31.7778 40.1767Z" fill="white" stroke="#F9B130" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M27.7557 22.2394C31.613 22.2394 34.7399 19.1125 34.7399 15.2552C34.7399 11.3979 31.613 8.27098 27.7557 8.27098C23.8984 8.27098 20.7715 11.3979 20.7715 15.2552C20.7715 19.1125 23.8984 22.2394 27.7557 22.2394Z" fill="#FFF6D2" stroke="#F9B130" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M27.7557 22.2394C31.613 22.2394 34.7399 19.1125 34.7399 15.2552C34.7399 11.3979 31.613 8.27098 27.7557 8.27098C23.8984 8.27098 20.7715 11.3979 20.7715 15.2552C20.7715 19.1125 23.8984 22.2394 27.7557 22.2394Z" fill="#FFF6D2" stroke="#F9B130" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M29.2809 13.7299C29.2809 13.3258 29.1206 12.9383 28.8351 12.6523C28.5497 12.3663 28.1624 12.2053 27.7584 12.2046H27.6444C27.3259 12.2046 27.0167 12.3123 26.767 12.51C26.5172 12.7077 26.3416 12.9839 26.2684 13.294C26.1953 13.604 26.2289 13.9296 26.3639 14.2181C26.4989 14.5066 26.7274 14.7411 27.0123 14.8836L28.4994 15.6268C28.7848 15.7689 29.0138 16.0033 29.1491 16.292C29.2845 16.5806 29.3183 16.9066 29.2451 17.2169C29.1719 17.5272 28.9959 17.8036 28.7458 18.0013C28.4957 18.199 28.1861 18.3063 27.8673 18.3058H27.7533C27.3488 18.3057 26.961 18.1449 26.6751 17.8589C26.3891 17.5728 26.2285 17.185 26.2285 16.7805" stroke="#F9B130" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M27.7559 12.2046V11.4419" stroke="#F9B130" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M27.7559 19.0679V18.3053" stroke="#F9B130" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
    </Icon>
)

