import React, { useEffect, useState } from 'react'
import { useNavigation } from '@react-navigation/native'
import { Modal, View, StyleSheet } from 'react-native'
import { Icons } from 'assets'
import { createStyles } from 'lib/styles'
import { Measurements, isIOS } from 'lib/common'
import { Button, Toast, Touchable, Typography } from 'lib/components'
import { CustomFonts } from 'lib/types'
import {
    useIsAuthorizedAtom,
    useBookingJobQuotationAtom,
    useShouldAskForPushNotificationPermissionAtom,
    useFeatureFlagAtom
} from 'lib/atoms'
import { useCopyToClipboard, usePrice, useStyles, useTranslations } from 'lib/hooks'
import { DetailsScreensNames } from 'lib/routing'
import { EnablePushNotificationModal } from 'features/pushNotifications'
import { BookingSuccessCrossSale } from 'features/bookings/components'
import { JobQuotationResponse } from 'features/bookings/types'

type BookingSuccessConfirmationModalProps = {
    jobId: string,
    bookingId: string,
    screenName: DetailsScreensNames
}

export const BookingSuccessConfirmationModal: React.FunctionComponent<BookingSuccessConfirmationModalProps> = ({
    jobId,
    screenName,
    bookingId
}) => {
    const T = useTranslations()
    const [isAuthorized] = useIsAuthorizedAtom()
    const { styles, theme } = useStyles(stylesheet)
    const [shouldRequestPermission] = useShouldAskForPushNotificationPermissionAtom()
    const [featureFlagAtom] = useFeatureFlagAtom()
    const [isOpen, setIsOpen] = useState(false)
    const [shouldShowPushNotificationModal, setShowPushNotificationPermissionModal] = useState(false)
    const navigation = useNavigation()
    const { onCopyPress } = useCopyToClipboard(T.common.copiedBookingIdMessage)
    const [bookingJobQuotationAtom] = useBookingJobQuotationAtom()
    const usePaymentBySessionFlow = featureFlagAtom?.paymentBySessionFlow || false
    const bookingJobQuotation = bookingJobQuotationAtom as JobQuotationResponse
    const jobQuotationTotal = usePaymentBySessionFlow ?
        bookingJobQuotation?.totalSubscription :
        bookingJobQuotation?.total
    const valueSavings = jobQuotationTotal?.valueSavings || 0
    const formattedValueSavings = usePrice(valueSavings as number, true)

    useEffect(() => {
        setIsOpen(true)
    }, [jobId])

    return (
        <React.Fragment>
            <Modal
                transparent
                visible={isOpen}
                statusBarTranslucent
                animationType="fade"
                onRequestClose={() => setIsOpen(false)}
            >
                <View style={styles.scrollContainer}>
                    <Touchable
                        onPress={() => setIsOpen(false)}
                        style={styles.backdrop}
                    />
                    <View style={styles.content}>
                        <Typography.Subheading>
                            {T.screens.bookingSuccess.title}
                        </Typography.Subheading>
                        {
                            valueSavings > 0.0 && (
                                <Typography.SmallSubheading style={styles.savedText}>
                                    {`${T.screens.bookingSuccess.youHaveJustSaved} ${formattedValueSavings}!`}
                                </Typography.SmallSubheading>
                            )
                        }
                        <View style={styles.icon}>
                            <Icons.SuccessBooking
                                size={42}
                                forceColor={theme.colors.orange}
                            />
                        </View>
                        <Typography.Subheading>
                            {T.screens.bookingSuccess.pending}
                        </Typography.Subheading>
                        <View style={styles.bookingIdWrapper}>
                            <Typography.Regular bold>
                                {T.screens.bookingSuccess.bookingId}
                            </Typography.Regular>
                            <View style={styles.bookingId}>
                                <Typography.Label bold>
                                    {bookingId}
                                </Typography.Label>
                            </View>
                            <Touchable
                                testID={T.accessibility.bookingSuccess.copyBtn}
                                onPress={() => onCopyPress(bookingId)}
                                style={styles.copyButton}
                            >
                                <Typography.Label
                                    style={styles.copyText}
                                    forceColor={theme.colors.orange}
                                >
                                    {T.common.copy}
                                </Typography.Label>
                                <Icons.Copy
                                    size={18}
                                    forceColor={theme.colors.orange}
                                />
                            </Touchable>
                        </View>
                        <Typography.Regular
                            style={styles.textCenter}
                            forceColor={theme.colors.darkGrey}
                        >
                            {isAuthorized ? T.screens.bookingSuccess.bookingMessage : T.screens.bookingSuccess.bookingMessageGuest}
                        </Typography.Regular>
                        <Button
                            testID={T.accessibility.bookingSuccess.myBookingBtn}
                            onPress={() => {
                                setIsOpen(false)
                                setShowPushNotificationPermissionModal(true)
                                navigation.navigate(screenName, {
                                    jobId
                                })
                            }}
                            text={T.screens.bookingSuccess.buttons.myBooking}
                        />
                        <BookingSuccessCrossSale closeModal={() => setIsOpen(false)} />
                    </View>
                </View>
                <Toast isModal />
            </Modal>
            <EnablePushNotificationModal
                // should be visible after the successful booking
                shouldBeVisible={shouldShowPushNotificationModal && shouldRequestPermission}
            />
        </React.Fragment>
    )
}

const stylesheet = createStyles(theme => ({
    backdrop: {
        ...StyleSheet.absoluteFillObject
    },
    scrollContainer: {
        flex: 1,
        justifyContent: 'center',
        backgroundColor: theme.components.modal.backdrop.backgroundColor,
        paddingTop: isIOS
            ? Measurements.StatusBarHeight
            : 0
    },
    content: {
        borderRadius: 12,
        alignItems: 'center',
        padding: theme.utils.gap(3),
        backgroundColor: theme.colors.white,
        marginHorizontal: theme.utils.gap(2)
    },
    icon: {
        marginVertical: theme.utils.gap(2)
    },
    bookingIdWrapper: {
        flexDirection: 'row',
        alignItems: 'center',
        marginVertical: theme.utils.gap(1)
    },
    bookingId: {
        backgroundColor: theme.colors.silver,
        borderRadius: 4,
        paddingVertical: theme.utils.gap(1) / 2,
        paddingHorizontal: theme.utils.gap(1) / 2,
        marginHorizontal: theme.utils.gap(1)
    },
    copyButton: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    copyText: {
        textTransform: 'lowercase',
        marginRight: theme.utils.gap(1) / 2
    },
    textCenter: {
        textAlign: 'center',
        marginBottom: theme.utils.gap(2)
    },
    savedText: {
        color: theme.colors.green,
        fontFamily: CustomFonts.Roboto500,
        marginTop: theme.utils.gap(1)
    }
}))
