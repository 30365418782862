import { APP_CONFIG } from 'lib/config'
import { GA4SessionData } from 'lib/types'
import { useEffect, useState } from 'react'

export const getGtagInstance = () => {
    if (!window.gtag) {
        return
    }

    return window.gtag
}
export const useSessionData = () => {
    const [sessionData, setSessionData] = useState<GA4SessionData | null>(null)

    useEffect(() => {
        const fetchSessionData = async () => {
            try {
                const gtag = getGtagInstance()

                if (!gtag) {
                    return {}
                }

                const gtagPromises = [
                    new Promise<string>(resolve => gtag('get', APP_CONFIG.GA4.TRACKING_ID, 'session_id', resolve)),
                    new Promise<string>(resolve => gtag('get', APP_CONFIG.GA4.TRACKING_ID, 'session_number', resolve)),
                    new Promise<string>(resolve => gtag('get', APP_CONFIG.GA4.TRACKING_ID, 'client_id', resolve))
                ]

                const [sessionId,
                       sessionNumber,
                       clientId] = await Promise.all(gtagPromises)

                setSessionData({ sessionId, sessionNumber, clientId })
            } catch (error) {
                console.error('Error fetching session data:', error)
            }
        }

        fetchSessionData()
    }, [])

    return sessionData
}
