import React from 'react'
import { LinearGradient } from 'expo-linear-gradient'
import Animated, { Extrapolation, interpolate, useAnimatedStyle, withTiming } from 'react-native-reanimated'
import { View } from 'react-native'
import { useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { Touchable, Typography } from 'lib/components'
import { Icons } from 'assets'

type LoadMoreIndicatorProps = {
    positionStart: number,
    positionEnd: number,
    canLoadMore: boolean,
    indicatorPositionY: Animated.SharedValue<number>,
    onLoadMore: VoidFunction
}

export const LoadMoreIndicator: React.FunctionComponent<LoadMoreIndicatorProps> = ({
    canLoadMore,
    onLoadMore,
    positionEnd,
    positionStart,
    indicatorPositionY
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const animatedLoadMorePosition = useAnimatedStyle(() => ({
        top: withTiming(indicatorPositionY.value),
        opacity: withTiming(interpolate(
            indicatorPositionY.value,
            [positionStart, positionStart / 2, positionEnd],
            [0, 0.9, 1],
            {
                extrapolateLeft: Extrapolation.CLAMP,
                extrapolateRight: Extrapolation.CLAMP
            }
        ))
    }))

    return canLoadMore ? (
        <Animated.View
            style={[
                animatedLoadMorePosition,
                styles.loadMoreContainer
            ]}
        >
            <LinearGradient
                locations={[0, 1]}
                end={{ x: 0, y: 1 }}
                start={{ x: 0, y: 0 }}
                style={styles.gradientContainer}
                colors={[theme.colors.white, theme.utils.hexToRGBA(theme.colors.darkSilver, 0)]}
            >
                <Touchable
                    onPress={onLoadMore}
                    style={styles.loadMore}
                >
                    <Typography.Regular
                        bold
                        forceColor={theme.colors.night}
                    >
                        {T.screens.chat.loadEarlierMessages}
                    </Typography.Regular>
                    <View style={styles.tryAgainIconContainer}>
                        <Icons.TryAgain
                            size={16}
                            forceColor={theme.colors.night}
                        />
                    </View>
                </Touchable>
            </LinearGradient>
        </Animated.View>
    ) : null
}

const stylesheet = createStyles(theme => ({
    gradientContainer: {
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingTop: theme.utils.gap(3),
        paddingBottom: theme.utils.gap(20)
    },
    loadMoreContainer: {
        position: 'absolute',
        width: '100%'
    },
    loadMore: {
        height: 36,
        backgroundColor: theme.colors.sun,
        textAlign: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        borderRadius: theme.utils.gap(10),
        paddingHorizontal: theme.utils.gap(2),
        ...theme.utils.createShadow()
    },
    tryAgainIconContainer: {
        marginLeft: 4
    }
}))
