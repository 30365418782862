import { useField } from '@codegateinc/react-form-builder-v2'
import { useTranslations } from 'lib/hooks'
import { isNative } from 'lib/common'
import { CancelationPackageFields } from './forms'
import { CANCEL_PACKAGE_OPTIONS } from '../constants/CancelPackageOptions'

export const useCancelationPackageForm = () => {
    const T = useTranslations()

    const cancellationOption = useField<string>({
        key: CancelationPackageFields.CancellationOption,
        initialValue: CANCEL_PACKAGE_OPTIONS[0].value,
        isRequired: false,
        validateOnBlur: false,
        label: T.screens.jobDetails.cancelationPackage.formFields.cancelationOption.label,
        placeholder: T.screens.jobDetails.cancelationPackage.formFields.cancelationOption.placeHolder
    })

    const reason = useField<string>({
        key: CancelationPackageFields.Reason,
        initialValue: '',
        isRequired: false,
        validateOnBlur: false,
        label: T.screens.jobDetails.cancelationPackage.formFields.reason.label,
        placeholder: T.screens.jobDetails.cancelationPackage.formFields.reason.placeHolder
    })

    const customReason = useField<string>({
        key: CancelationPackageFields.CustomReason,
        initialValue: '',
        isRequired: false,
        validateOnBlur: false,
        label: T.screens.jobDetails.cancelationPackage.formFields.customReason.label,
        placeholder: isNative ?
            T.screens.jobDetails.cancelation.formFields.customReason.placeHolderForNative :
            T.screens.jobDetails.cancelation.formFields.customReason.placeHolder
    })

    return {
        cancellationOption,
        reason,
        customReason
    }
}
