import React from 'react'
import { Icons } from 'assets'
import { createStyles } from 'lib/styles'
import { useStyles } from 'lib/hooks'
import { IconProps } from 'lib/types'

type SupplyIconProps = {
    supplyId: number,
    forceColor?: string
}

export const SupplyIcon: React.FunctionComponent<SupplyIconProps> = ({ supplyId, forceColor }) => {
    const { theme } = useStyles(stylesheet)

    const renderIconBySupplyId = (supplyId: number): React.ReactNode => {
        const iconsMap = {
            0: Icons.Cleaning,
            1: Icons.Cooking,
            2: Icons.Laundry,
            3: Icons.Handyman,
            4: Icons.AirCondition,
            5: Icons.DeepCleaning,
            8: Icons.PestControl,
            9: Icons.TaskErrands,
            10: Icons.MoversAndPackers
        }

        return React.createElement<IconProps>(iconsMap[supplyId] , {
            forceColor: forceColor || theme.colors.white,
            size: 16
        })
    }

    return renderIconBySupplyId(supplyId)
}

const stylesheet = createStyles(() => ({}))
