import React from 'react'
import { View } from 'react-native'
import Animated, { interpolateColor, useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated'
import { Icons } from 'assets'
import { stringHelpers } from 'lib/utils'
import { AirConditionServiceOption, Children, FrequencyOption } from 'lib/types'
import { Breakpoint, createStyles } from 'lib/styles'
import { Touchable, Typography } from 'lib/components'
import { useIsWithinBreakpoints, useStyles, useTranslations } from 'lib/hooks'

type FrequencyOptionsItemProps = {
    option: FrequencyOption | AirConditionServiceOption,
    index: number,
    isSelected: boolean,
    isPrimary: boolean,
    isLast: boolean,
    primaryText?: string,
    onChangeValue: VoidFunction,
    customSelect?: Children
}

export const FrequencyOptionsItem: React.FunctionComponent<FrequencyOptionsItemProps> = ({
    option,
    index,
    isSelected,
    isPrimary,
    isLast,
    primaryText,
    onChangeValue,
    customSelect
}) => {
    const T = useTranslations()
    const isHovered = useSharedValue(0)
    const { styles, theme } = useStyles(stylesheet)
    const isMobile = useIsWithinBreakpoints(Breakpoint.XS, Breakpoint.MD)
    const fontColor = option.discount
        ? theme.colors.orange
        : theme.colors.night
    const animatedBackgroundStyles = useAnimatedStyle(() => ({
        backgroundColor: isSelected
            ? theme.colors.yellow
            : interpolateColor(isHovered.value, [0, 1], [theme.colors.white, theme.colors.yellow]),
        borderColor: isSelected
            ? theme.colors.orange
            : interpolateColor(isHovered.value, [0, 1], [theme.colors.silver, theme.colors.orange])
    }))

    return (
        <Touchable
            activeOpacity={1}
            testID={stringHelpers.toKebabCase(`select-frequency-${option.value}`)}
            key={index}
            onPress={onChangeValue}
            onHoverIn={() => {
                isHovered.value = withTiming(1)
            }}
            onHoverOut={() => {
                isHovered.value = withTiming(0)
            }}
            style={styles.optionWrapper}
        >
            <Animated.View
                style={[
                    {
                        ...styles.option,
                        marginRight: isLast
                            ? undefined
                            : theme.utils.gap(1)
                    },
                    animatedBackgroundStyles
                ]}
            >
                <View style={styles.badgeWrapper}>
                    {isPrimary && (
                        <View style={styles.badge}>
                            <Typography.Error forceColor={theme.colors.white}>
                                {primaryText || T.common.bestValue}
                            </Typography.Error>
                        </View>
                    )}
                </View>
                <View style={styles.title}>
                    <Typography.Title bold>
                        {option.title}
                    </Typography.Title>
                    <Typography.Error forceColor={theme.colors.orange}>
                        {option.discount ? `${T.common.save} ${option.discount}%` : ' '}
                    </Typography.Error>
                    {option.price && (
                        <View style={styles.price}>
                            <Typography.Regular forceColor={fontColor}>
                                {T.common.currency}
                            </Typography.Regular>
                            <Typography.SmallSubheading forceColor={fontColor}>
                                {` ${option.price} `}
                            </Typography.SmallSubheading>
                            <Typography.Regular forceColor={fontColor}>
                                {T.common.hr}
                            </Typography.Regular>
                        </View>
                    )}
                </View>
                <View style={styles.description}>
                    {option.list.map((item, index) => (
                        <View
                            key={index}
                            style={styles.listItem}
                        >
                            <View>
                                <Icons.Check
                                    size={isMobile
                                        ? 10
                                        : 15
                                    }
                                    forceColor={theme.colors.orange}
                                />
                            </View>
                            <Typography.Label style={styles.listText}>
                                {item}
                            </Typography.Label>
                        </View>
                    ))}
                </View>
                {customSelect ? (
                    customSelect
                ) : (
                    <View style={styles.selectContainer}>
                        <Typography.Label forceColor={theme.colors.orange}>
                            {isSelected
                                ? T.common.selected
                                : T.common.select
                            }
                        </Typography.Label>
                    </View>
                )}
            </Animated.View>
        </Touchable>
    )
}

const stylesheet = createStyles(theme => ({
    optionWrapper: {
        flex: 1,
        minHeight: {
            lg: 250,
            xs: 200
        }
    },
    option: {
        flex: 1,
        borderWidth: 1,
        paddingTop: theme.utils.gap(3),
        paddingBottom: theme.utils.gap(2),
        minHeight: {
            lg: 250,
            xs: 200
        },
        paddingHorizontal: {
            lg: theme.utils.gap(3) / 2,
            xs: theme.utils.gap(1) / 2
        },
        borderRadius: theme.components.input.borderRadius,
        marginBottom: {
            lg: undefined,
            xs: theme.utils.gap(1)
        },
        ...theme.utils.createShadow(2, 0, 8, 4, 4, theme.colors.webShadow(0.04))
    },
    listText: {
        marginLeft: {
            lg: theme.utils.gap(1),
            xs: theme.utils.gap(1) / 2
        }
    },
    selectContainer: {
        borderWidth: 1,
        alignItems: 'center',
        marginTop: theme.utils.gap(2),
        borderColor: theme.colors.sun,
        paddingVertical: theme.utils.gap(1),
        borderRadius: theme.components.button.borderRadius
    },
    badgeWrapper: {
        top: -theme.utils.gap(3) / 2,
        left: 0,
        right: 0,
        position: 'absolute',
        alignItems: 'center'
    },
    badge: {
        height: theme.utils.gap(3),
        borderRadius: theme.utils.gap(3) / 2,
        paddingHorizontal: {
            lg: theme.utils.gap(2),
            xs: theme.utils.gap(1)
        },
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.colors.sun
    },
    title: {
        alignItems: 'center'
    },
    description: {
        flex: 1,
        marginTop: theme.utils.gap(1),
        marginBottom: theme.utils.gap(1)
    },
    listItem: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: theme.utils.gap(1) / 2
    },
    price: {
        marginTop: {
            lg: theme.utils.gap(2),
            xs: theme.utils.gap(1)
        },
        flexDirection: 'row',
        alignItems: 'baseline'
    }
}))
