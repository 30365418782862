import React from 'react'
import { View } from 'react-native'
import { createStyles } from 'lib/styles'
import { Typography } from 'lib/components'
import { useStyles } from 'lib/hooks'

type Summary = {
    title: string
    description: string
}

export const SummaryModalContent: React.FunctionComponent<Summary> = ({ title, description }) => {
    const { styles, theme } = useStyles(stylesheet)

    return (
        <View style={styles.container}>
            <View style={styles.title}>
                <Typography.Regular>
                    {title}
                </Typography.Regular>
            </View>
            <Typography.Regular forceColor={theme.colors.darkGrey}>
                {description}
            </Typography.Regular>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        maxWidth: 800
    },
    title: {
        borderBottomWidth: 1,
        borderColor: theme.colors.silver,
        paddingBottom: theme.utils.gap(2),
        marginBottom: theme.utils.gap(2)
    }
}))
