import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import { createStyles } from 'lib/styles'
import { Typography } from 'lib/components'
import { useStyles } from 'lib/hooks'
import { SubTotal } from '../types'

type SubTotalComponentProps = {
    subTotal: SubTotal
}

type Item = {
    name: string
    value: string
}

export const SubTotalComponent: React.FunctionComponent<SubTotalComponentProps> = ({ subTotal }) => {

    const { styles } = useStyles(stylesheet)
    const [items, setItems] = useState<Array<Item>>([])

    useEffect(() => {
        const { items: subTotalItems } = subTotal

        if(subTotalItems) {
            setItems(subTotalItems)
        }
    }, [subTotal])

    const renderItems = () => {
        if(!items) {
            return null
        }

        return items.map(({ name, value }) => (
            <View
                key={name}
                style={styles.summaryRow}
            >
                <View style={styles.labelWrapper}>
                    <Typography.Label>
                        {name}
                    </Typography.Label>
                </View>
                <Typography.Label
                    bold
                    style={{
                        ...styles.value
                    }}
                >
                    {value}
                </Typography.Label>
            </View>
        ))
    }

    return renderItems()
}

const stylesheet = createStyles(theme => ({
    priceSummaryContainer: {
        justifyContent: 'space-between',
        width: '100%'
    },
    summaryRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: theme.utils.gap(2)
    },
    labelWrapper: {
        flex: 1,
        minWidth: 50,
        marginRight: theme.utils.gap(2)
    },
    discountName: {
        textTransform: 'uppercase'
    },
    discountValue: {
        color: theme.colors.flame
    },
    value: {
        textAlign: 'right'
    }
}))
