import { useField } from '@codegateinc/react-form-builder-v2'
import { dateHelpers } from 'lib/utils'
import { useTranslations } from 'lib/hooks'
import { BookingFrequency, FrequencyOption, Nullable, SupplyID } from 'lib/types'
import { getServiceStaticConfig } from 'features/bookings/utils'
import { MoversAndPackersServiceDetailsFields } from './forms'
import { useFrequencyOptions } from '../hooks'

export const useMoversAndPackersServiceDetailsForm = () => {
    const T = useTranslations()
    const frequencyOptions = useFrequencyOptions()
    const config = getServiceStaticConfig(SupplyID.MoversPackers)

    const listOfItems = useField<string>({
        key: MoversAndPackersServiceDetailsFields.ListOfItems,
        initialValue: '',
        isRequired: false,
        validateOnBlur: false,
        label: T.screens.moversAndPackersServiceDetails.formFields.listOfItems.label,
        placeholder: T.screens.moversAndPackersServiceDetails.formFields.listOfItems.placeholder,
        validationRules: [
            {
                errorMessage: T.screens.moversAndPackersServiceDetails.formFields.listOfItems.validation.maxLength,
                validate: value => {
                    if (value) {
                        return value.length <= 3000
                    }

                    return true
                }
            }
        ]
    })

    const promoCode = useField<string>({
        key: MoversAndPackersServiceDetailsFields.PromoCode,
        initialValue: '',
        isRequired: false,
        placeholder: T.screens.moversAndPackersServiceDetails.formFields.promoCode.placeholder
    })

    const inspectionFrequency = useField<Nullable<FrequencyOption>>({
        key: MoversAndPackersServiceDetailsFields.InspectionFrequency,
        initialValue: frequencyOptions.find(option => option.value === BookingFrequency.OneTime) || null,
        isRequired: false,
        validateOnBlur: false
    })

    const inspectionStartingDate = useField<string>({
        key: MoversAndPackersServiceDetailsFields.InspectionStartingDate,
        initialValue: '',
        isRequired: true,
        validateOnBlur: false,
        label: T.screens.moversAndPackersServiceDetails.formFields.startingDate.label,
        placeholder: T.screens.moversAndPackersServiceDetails.formFields.startingDate.placeholder,
        validationRules: [
            {
                errorMessage: T.screens.moversAndPackersServiceDetails.formFields.startingDate.validation.isRequired,
                validate: Boolean
            }
        ]
    })

    const inspectionBookingTime = useField<string>({
        key: MoversAndPackersServiceDetailsFields.InspectionBookingTime,
        initialValue: '',
        validateOnBlur: true,
        isRequired: inspectionFrequency.value?.value !== BookingFrequency.OneTime,
        placeholder: T.screens.moversAndPackersServiceDetails.formFields.bookingTime.placeHolder,
        validationRules: [
            {
                errorMessage: T.screens.moversAndPackersServiceDetails.formFields.bookingTime.validation.isRequired,
                validate: value => {
                    if (inspectionFrequency.value?.value === BookingFrequency.OneTime) {
                        return true
                    }

                    return Boolean(value)
                }
            },
            {
                errorMessage: `${config.min_notice_hours} ${T.screens.moversAndPackersServiceDetails.formFields.bookingTime.validation.minimumHourNotice}`,
                validate: value => {
                    if (inspectionFrequency.value?.value === BookingFrequency.OneTime) {
                        return true
                    }

                    return !dateHelpers.isDatePast(dateHelpers.subHoursFromDate(value, config.min_notice_hours))
                }
            }
        ]
    })

    const inspectionBookingDays = useField<Record<string, Nullable<string>>>({
        key: MoversAndPackersServiceDetailsFields.InspectionBookingDays,
        initialValue: {},
        validateOnBlur: true,
        isRequired: inspectionFrequency.value?.value === BookingFrequency.OneTime,
        placeholder: T.screens.moversAndPackersServiceDetails.formFields.bookingDays.placeHolder,
        validationRules: [
            {
                errorMessage: T.screens.moversAndPackersServiceDetails.formFields.bookingDays.validation.isRequired,
                validate: value => {
                    if (inspectionFrequency.value?.value !== BookingFrequency.OneTime) {
                        return true
                    }

                    return Object.values(value).some(Boolean)
                }
            }
        ]
    })

    return {
        listOfItems,
        promoCode,
        inspectionFrequency,
        inspectionStartingDate,
        inspectionBookingTime,
        inspectionBookingDays
    }
}
