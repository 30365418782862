import { useToastAtom } from 'lib/atoms'
import { ErrorResponse, NotificationType } from 'lib/types'
import { useGetErrorMessage } from './useGetErrorMessage'

export const useQueryHelpers = () => {
    const [, setToastMessage] = useToastAtom()
    const { getErrorMessage } = useGetErrorMessage()

    const onRequestError = (response: ErrorResponse) => {
        setToastMessage({
            type: NotificationType.Error,
            message: getErrorMessage(response)
        })
    }

    return {
        onRequestError
    }
}
