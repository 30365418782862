import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const PhoneCall: React.FunctionComponent<IconProps> = props => {
    const { forceColor, ...rest } = props

    return (
        <Icon
            {...rest}
            forceColor="transparent"
        >
            <Path
                stroke={forceColor}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={8}
                d="M48.977 17.57a17.006 17.006 0 0 1 8.764 4.655 16.954 16.954 0 0 1 4.663 8.746M48.977 4a30.615 30.615 0 0 1 18.24 8.743A30.51 30.51 0 0 1 76 30.937M72.6 58.01v10.177a6.772 6.772 0 0 1-2.201 5.028 6.802 6.802 0 0 1-5.208 1.757 67.35 67.35 0 0 1-29.334-10.415 66.219 66.219 0 0 1-20.394-20.355A67.056 67.056 0 0 1 5.028 14.788a6.773 6.773 0 0 1 1.75-5.185 6.798 6.798 0 0 1 5.014-2.21h10.197a6.806 6.806 0 0 1 4.5 1.64 6.782 6.782 0 0 1 2.298 4.195 43.49 43.49 0 0 0 2.38 9.533 6.773 6.773 0 0 1-1.53 7.158l-4.317 4.309a54.333 54.333 0 0 0 20.394 20.355l4.317-4.308a6.802 6.802 0 0 1 7.172-1.527 43.705 43.705 0 0 0 9.552 2.375 6.802 6.802 0 0 1 4.237 2.332A6.777 6.777 0 0 1 72.6 58.01Z"
            />
        </Icon>
    )
}
