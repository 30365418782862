import React from 'react'
import { View } from 'react-native'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { Notification } from 'lib/models'
import { Grid, Touchable, Typography } from 'lib/components'
import { dateHelpers } from 'lib/utils'
import { Icons } from 'assets'

interface NotificationTileProps extends Notification {
    onPress: VoidFunction
}

export const NotificationTile: React.FunctionComponent<NotificationTileProps> = ({
    read,
    title,
    date,
    onPress
}) => {
    const { styles, theme } = useStyles(stylesheet)

    return (
        <Touchable
            onPress={onPress}
            style={{
                ...styles.container,
                backgroundColor: !read
                    ? theme.colors.latte
                    : undefined
            }}
        >
            <View style={styles.body}>
                <Grid.Gap gapRight={2}>
                    {read
                        ? (
                            <Icons.Notification
                                size={24}
                                forceColor={theme.colors.softBlueGrey}
                            />
                        )
                        : (
                            <Icons.UnreadNotification size={24} />
                        )
                    }
                </Grid.Gap>
                <Typography.Regular
                    bold={!read}
                    numberOfLines={1}
                    style={styles.title}
                    forceColor={read
                        ? theme.colors.softBlueGrey
                        : theme.colors.black
                    }
                >
                    {title}
                </Typography.Regular>
                <Typography.Label
                    style={styles.date}
                    forceColor={theme.colors.softBlueGrey}
                >
                    {dateHelpers.distanceToNow(date)}
                </Typography.Label>
            </View>
        </Touchable>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        height: 64,
        padding: theme.utils.gap(2)
    },
    body: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderBottomColor: theme.colors.latte,
        paddingBottom: theme.utils.gap(2)
    },
    title: {
        flex: 1,
        marginRight: theme.utils.gap(2)
    },
    date: {
        lineHeight: {
            lg: 19,
            xs: 17
        }
    }
}))
