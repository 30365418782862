import {
    BookingFrequency,
    BookingRequestType,
    CancelConfig,
    CustomerChargeStatus,
    DayOfWeekMapping,
    FrequencyOption,
    JobSchedule,
    JobStatus,
    JobType,
    Nullable,
    RescheduleConfig,
    RescheduledBookingRequest,
    SupplyID
} from 'lib/types'
import { dateHelpers } from 'lib/utils'
import { JobQuotationResponse } from 'features/bookings'
import { JobDetailsResponse, ReceiptSummary, SessionJob } from 'features/jobs/types'
import { RescheduleFormShape } from 'features/jobs/forms'

export const isRecurringAccepted = (jobDetail: JobDetailsResponse): boolean =>
    isJobRecurring(jobDetail) && (isAccepted(jobDetail) || isPaused(jobDetail))

export const isRecurringSubmitted = (jobDetail: JobDetailsResponse): boolean =>
    isJobRecurring(jobDetail) && isSubmitted(jobDetail)

export const isSubmittedSubscription = (jobDetail: JobDetailsResponse): boolean =>
    isRecurringSubmitted(jobDetail) &&
    isCleaner(jobDetail) &&
    !isSessionJob(jobDetail)

export const isAcceptedSubscription = (jobDetail: JobDetailsResponse): boolean =>
    isRecurringAccepted(jobDetail) &&
    isCleaner(jobDetail) &&
    !isSessionJob(jobDetail)

export const isAcceptedSessionJobForSubscription = (jobDetail: JobDetailsResponse): boolean =>
    isRecurringAccepted(jobDetail) &&
    isCleaner(jobDetail) &&
    isSessionJob(jobDetail)

export const isAdhocAccepted = (jobDetail: JobDetailsResponse): boolean =>
    isJobOneTime(jobDetail) && isAccepted(jobDetail)

export const isPaidPerHour = (jobDetail: JobDetailsResponse): boolean => jobDetail.job.supply.paid_per_hour

export const isSubmitted = (jobDetail: JobDetailsResponse): boolean => jobDetail.job.status === JobStatus.Submitted

export const isLaundry = (jobDetail: JobDetailsResponse): boolean => jobDetail.job.supply.id === SupplyID.Laundry

export const isCleaner = (jobDetail: JobDetailsResponse): boolean => jobDetail.job.supply.id === SupplyID.Cleaner

export const isHandyman = (jobDetail: JobDetailsResponse): boolean => jobDetail.job.supply.id === SupplyID.Handyman

export const isACService = (jobDetail: JobDetailsResponse): boolean => jobDetail.job.supply.id === SupplyID.ACService

export const isTasksErrands = (jobDetail: JobDetailsResponse): boolean => jobDetail.job.supply.id === SupplyID.TasksErrands

export const isMoversPackers = (jobDetail: JobDetailsResponse): boolean => jobDetail.job.supply.id === SupplyID.MoversPackers

export const isMoversAndPackers = (jobDetail: JobDetailsResponse): boolean =>
    jobDetail.job.supply.id === SupplyID.MoversPackers

export const isJobRecurring = (jobDetail: JobDetailsResponse): boolean =>
    !!jobDetail.job.schedule?.length || jobDetail.job.job_type === JobType.Recurring

export const isJobOneTime = (jobDetail: JobDetailsResponse): boolean => jobDetail.job.job_type === JobType.AdHoc

export const isAccepted = (jobDetail: JobDetailsResponse): boolean =>
    jobDetail.job.supplier && jobDetail.job.status === JobStatus.Accepted

export const isPaused = (jobDetail: JobDetailsResponse): boolean =>
    jobDetail.job.supplier && jobDetail.job.status === JobStatus.Paused

export const isCompleted = (jobDetail: JobDetailsResponse): boolean =>
    jobDetail.job.status === JobStatus.Completed

export const isRated = (jobDetail: JobDetailsResponse): boolean =>
    jobDetail.job.status === JobStatus.Rated

export const isCancelled = (jobDetail: JobDetailsResponse): boolean =>
    jobDetail.job.status === JobStatus.Cancelled

export const getJobFrequencyOption = (jobDetail: JobDetailsResponse): FrequencyOption => {
    // eslint-disable-next-line functional/no-let
    let frequency = BookingFrequency.OneTime

    const scheduleItems = jobDetail.job.schedule

    if (scheduleItems && scheduleItems.length) {
        const scheduleItem = scheduleItems[0]
        frequency = scheduleItem.fortnightly ? BookingFrequency.Fortnightly : BookingFrequency.Weekly
    }

    return {
        value: frequency,
        label: frequency,
        title: frequency,
        description: frequency,
        list: []
    }
}

export const isApprovedAllCharges = (jobDetail: JobDetailsResponse): boolean => {
    const customerCharges = jobDetail?.job?.customer_charges || []

    return !!customerCharges?.length &&
        customerCharges?.every(charge => charge?.auth_status === CustomerChargeStatus.Approved) as boolean
}

export const shouldHideCancelButton = (jobDetail: JobDetailsResponse): boolean => (
    isCompleted(jobDetail) ||
    isCancelled(jobDetail) ||
    isRated(jobDetail) ||
    isLaundryNotSubmitted(jobDetail) ||
    shouldHideCancelButtonIfJobHasBilling(jobDetail)) ||
    (isApprovedAllCharges(jobDetail) && !(isTasksErrandsSubmitted(jobDetail)))

export const shouldShowRescheduleButton = (jobDetail: JobDetailsResponse): boolean => {
    if(shouldHideCancelButtonIfJobHasBilling(jobDetail)) {
        return false
    }

    if(matchingRescheduleConditionWithNewCancelFlow(jobDetail)) {
        return true
    }

    // legacy condition
    if (
        !isSubmitted(jobDetail) ||
        isLaundry(jobDetail) ||
        isJobRecurring(jobDetail) ||
        (!isPaidPerHour(jobDetail) && !isMoversAndPackers(jobDetail))) {
        return false
    }

    return true
}

export const shouldShowExtendSessionButton = (jobDetail: JobDetailsResponse): boolean => {
    if(matchingExtendSessionConditionWithNewCancelFlow(jobDetail)) {
        return true
    }

    // legacy condition
    if (!isSubmitted(jobDetail) || !isPaidPerHour(jobDetail) || jobDetail.job.schedule?.length > 0) {
        return false
    }

    return true
}

export const getRecurringStartDate = (bookingDate = '', bookingDays = {}, locale) => {
    if (!bookingDate || !bookingDays || !locale) {
        return ''
    }

    const bookingDateDayName = dateHelpers.getWeekDayFullNames(bookingDate, locale)
    const dayBooking = bookingDays[bookingDateDayName]

    if (!dayBooking) {
        return ''
    }

    const dateFormat = dateHelpers.startingDate(bookingDate)
    const timeFormat = dateHelpers.selectTimeLabel(dayBooking)

    return timeFormat ? `${dateFormat} - ${timeFormat}` : dateFormat
}

export const isSessionJob = (jobDetail: JobDetailsResponse): boolean =>
    jobDetail.job.job_type === JobType.Recurring && Boolean(jobDetail.job.recurring_job)

export const shouldCancelJob = (jobDetail: JobDetailsResponse): boolean =>
    isSubmitted(jobDetail) || (isJobOneTime(jobDetail) && !isSessionJob(jobDetail))

export const shouldRescheduleJob = (jobDetail: JobDetailsResponse): boolean =>
    isJobOneTime(jobDetail) && !isSessionJob(jobDetail) && !isAccepted(jobDetail)

export const getRescheduleFormInitialValue = (jobDetail: JobDetailsResponse, bookingDaysInitialValue) => {
    const bookingTimeInitialValue = dateHelpers.convertToCurrentTimeZone(jobDetail?.job?.date_time)
    const frequencyInitialValue = getJobFrequencyOption(jobDetail)
    const startingDateInitialValue = bookingTimeInitialValue ? bookingTimeInitialValue.split('T')[0] : ''
    const schedule = jobDetail.job.schedule
    const beginningDateInitialValue = dateHelpers.convertToCurrentTimeZone(jobDetail.job.beginning_date).split('T')[0]

    Object.keys(bookingDaysInitialValue).forEach(keyDate => {
        const dateShortName = keyDate.substring(0, 3).toLowerCase()
        const activatedDate = schedule?.find(el => el.activated && dateShortName === el.day)

        if (activatedDate) {
            bookingDaysInitialValue[keyDate] = activatedDate.start_time
        }
    })

    return {
        frequency: frequencyInitialValue,
        startingDate: schedule ? beginningDateInitialValue : startingDateInitialValue,
        bookingTime: bookingTimeInitialValue,
        bookingDays: bookingDaysInitialValue
    }
}

export const getLastSessionOfPackage = (jobDetail: JobDetailsResponse) => {
    const schedule: Array<JobSchedule> = jobDetail.job?.schedule || []

    if (!schedule.length) {
        return null
    }

    return schedule.reduce((maxStartTimeElement: JobSchedule | null, element: JobSchedule) => {
        if (element.activated) {
            const startTime = new Date(element.start_time)

            return (!maxStartTimeElement || startTime > new Date(maxStartTimeElement.start_time)) ?
                element : maxStartTimeElement
        }

        return maxStartTimeElement
    }, null)
}

export const shouldShowPausePackageButton = (jobDetail: JobDetailsResponse): boolean =>
    isRecurringAccepted(jobDetail) && !isSessionJob(jobDetail)

export const shouldShowResumePackageButton = (jobDetail: JobDetailsResponse): boolean =>
    isPackage(jobDetail) && isPaused(jobDetail)

export const getTotalSession = (jobQuotation: JobQuotationResponse): number => {
    const totalSession = jobQuotation?.subTotal?.items?.find(el => el.name === 'Sessions')?.value

    return totalSession ? Number(totalSession) : 0
}

export const shouldDisableRescheduleSessionJob = (jobDetail: JobDetailsResponse, hours: number = RescheduleConfig.MiniumHoursToRescheduleSession): boolean => {
    const startDate = jobDetail.job.date_time
    const isWithinLastFewHours = dateHelpers.isWithinLastFewHours(startDate, hours)

    return isAcceptedSessionJobForSubscription(jobDetail) && isWithinLastFewHours
}

export const shouldShowSubscriptionContent = (jobDetail: JobDetailsResponse): boolean => isSubmittedSubscription(jobDetail) || isAcceptedSubscription(jobDetail)

export const getRescheduledBookingRequest = (jobDetail: JobDetailsResponse, rescheduleForm: RescheduleFormShape): RescheduledBookingRequest => {
    if (!isCleaner(jobDetail)) {
        return {} as RescheduledBookingRequest
    }

    const requestType =  isPackage(jobDetail) ? BookingRequestType.Plan : BookingRequestType.Session
    const isPlan = requestType === BookingRequestType.Plan
    const newStartDate = isPlan ? rescheduleForm.startingDate : rescheduleForm.bookingTime
    const result = {
        requestType,
        jobId: jobDetail.job._id,
        newStartDate
    } as RescheduledBookingRequest

    if (isPlan && rescheduleForm.bookingDays) {
        result.newJobSchedule = transformedSchedule(rescheduleForm.bookingDays, rescheduleForm.frequency.value === BookingFrequency.Fortnightly)
    }

    return result
}

const transformedSchedule = (rescheduleDate: Record<string, Nullable<string>>, fortnightly: boolean): Record<string, Nullable<string>> | Array<JobSchedule> => {
    try {
        const defaultStartTime = Object.values(rescheduleDate).find(date => date !== null) || new Date().toISOString()
        const transformedSchedule = Object.keys(rescheduleDate).map(fullDayName => {
            const dayAbbreviation = Object.keys(DayOfWeekMapping).find(
                key => DayOfWeekMapping[key] === fullDayName
            )

            return {
                activated: rescheduleDate[fullDayName] !== null,
                day: dayAbbreviation,
                start_time: rescheduleDate[fullDayName] || defaultStartTime,
                fortnightly
            } as JobSchedule
        })

        return transformedSchedule as Array<JobSchedule>
    } catch {
        return rescheduleDate
    }
}

export const shouldShowPayBySessionForJobDetail = (jobDetail: JobDetailsResponse): boolean => isCleaner(jobDetail) && Boolean(jobDetail?.isPayBySession) && !isSessionJob(jobDetail)

export const shouldShowSubscriptionRescheduleRequestModal = (jobDetail: JobDetailsResponse): boolean => isCleaner(jobDetail) && isJobRecurring(jobDetail) && !isSessionJob(jobDetail)

export const shouldShowAdditionalFeeForCancelSubscription = (jobDetail: JobDetailsResponse): boolean => {
    const totalUpcomingSessions = getUpcomingSessions(jobDetail)?.length || 0
    const totalCompletedSessions = getCompletedSessions(jobDetail)?.length || 0

    return shouldShowSubscriptionRescheduleRequestModal(jobDetail) &&
                isRecurringAccepted(jobDetail) &&
                totalCompletedSessions < CancelConfig.MaximumCompletedSessions &&
                totalUpcomingSessions > CancelConfig.MiniumUpcomingSessions
}

export const getUpcomingSessions = (jobDetail: JobDetailsResponse): Array<SessionJob> => jobDetail?.sessionDetails?.upcomingSessions || []

export const getCompletedSessions = (jobDetail: JobDetailsResponse): Array<SessionJob> => jobDetail?.sessionDetails?.completedSessions || []

export const isSessionStartingWithinHours = (jobDetail: JobDetailsResponse, hours = 0): boolean => {
    const startTime = jobDetail?.job?.date_time

    if (!startTime) {
        return false
    }

    const differenceInMinutesToNow = dateHelpers.differenceInMinutesToNow(startTime)

    return differenceInMinutesToNow < 0 && differenceInMinutesToNow > - (hours * 60)
}

export const getCancellationFee = (jobDetail: JobDetailsResponse): number => jobDetail?.job?.bookingSummary?.cancellationFee || 0

export const getSessionsRemaining = (jobDetail: JobDetailsResponse): number => Number(jobDetail?.cancellationPolicy?.sessionsRemaining?.toString().match(/\d+/)?.[0] ?? 0)

export const getPolicyCancellationFee = (jobDetail: JobDetailsResponse): string => jobDetail?.cancellationPolicy?.cancellationFee || ''

export const isPackage = (jobDetail: JobDetailsResponse): boolean => isJobRecurring(jobDetail) && !isSessionJob(jobDetail)

export const shouldShowSubscriptionPauseModal = (jobDetail: JobDetailsResponse): boolean => isCleaner(jobDetail) && isJobRecurring(jobDetail) && (isAccepted(jobDetail) || isPaused(jobDetail)) && !isSessionJob(jobDetail)

export const shouldShowSubscriptionPauseSuccessModal = (jobDetail: JobDetailsResponse): boolean => isCleaner(jobDetail) && isJobRecurring(jobDetail) && !isSessionJob(jobDetail)

export const shouldShowSubscriptionPausedMessage = (jobDetail: JobDetailsResponse): boolean => isCleaner(jobDetail) && isJobRecurring(jobDetail) && isPaused(jobDetail) && !isSessionJob(jobDetail)

export const getReceiptSummaryItemByName = (receiptSummary: Array<ReceiptSummary>, name: string): ReceiptSummary | undefined => {
    const lowerCaseName = name.toLowerCase()

    return receiptSummary?.find(item => item?.name?.toLowerCase() === lowerCaseName)
}

const getTotalCustomerCharges = (jobDetail: JobDetailsResponse): number =>
    jobDetail?.job?.customer_charges?.length as number || 0

const matchingExtendSessionConditionWithNewCancelFlow = (jobDetail: JobDetailsResponse): boolean =>
    isAcceptedOrSubmitted(jobDetail) &&
    (isJobOneTime(jobDetail) || isSessionJob(jobDetail))

const matchingRescheduleConditionWithNewCancelFlow = (jobDetail: JobDetailsResponse): boolean => {
    const pausedPackage = isPackage(jobDetail) && isPaused(jobDetail)
    const isRecurringOrOneTime = isJobRecurring(jobDetail) || isJobOneTime(jobDetail)

    return pausedPackage || isAcceptedOrSubmitted(jobDetail) && isRecurringOrOneTime
}

const shouldHideCancelButtonIfJobHasBilling = (jobDetail: JobDetailsResponse): boolean => {
    const isJobHasCustomerCharges = getTotalCustomerCharges(jobDetail) !== 0
    const isBillingJobType =
        isHandyman(jobDetail) ||
        isACService(jobDetail) ||
        isTasksErrands(jobDetail) ||
        isMoversAndPackers(jobDetail) ||
        isLaundry(jobDetail)

    // alway show for submitted job
    return !isSubmitted(jobDetail) && isBillingJobType && isJobHasCustomerCharges
}

const isLaundryNotSubmitted = (jobDetail: JobDetailsResponse): boolean => isLaundry(jobDetail) && !isSubmitted(jobDetail)
const isTasksErrandsSubmitted = (jobDetail: JobDetailsResponse): boolean => isTasksErrands(jobDetail) && isSubmitted(jobDetail)
const isAcceptedOrSubmitted = (jobDetail: JobDetailsResponse): boolean => isAccepted(jobDetail) || isSubmitted(jobDetail)
