import React, { useEffect, useRef, useState } from 'react'
import { View } from 'react-native'
import { useForm } from '@codegateinc/react-form-builder-v2'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scrollview'
import { useNavigation } from '@react-navigation/native'
import { AirConditionService, AirConditionServiceOption, BookingFrequency, SupplyID } from 'lib/types'
import { isNative } from 'lib/common'
import { bookingHelpers } from 'lib/utils'
import { Breakpoint, createStyles } from 'lib/styles'
import { MOCKED_NUMBER_OF_AIR_CONDITIONERS } from 'lib/data'
import { useBookingDateCustomValueFormat, useInitializeAddress, useIsWithinBreakpoints, useSegmentSession, useStyles, useTranslations } from 'lib/hooks'
import { NavigationParams, NavigationProps, ScreenNames } from 'lib/routing'
import { Segment, ServiceType } from 'lib/analytics'
import { Adapter, Address, Button, FormComponents, MediaQuery, Sticky } from 'lib/components'
import {
    BookingCancellationFees,
    BookingDateModal,
    AirConditionHelpModalContent,
    AirConditionSummaryColumn,
    MobileSummary,
    PriceComponent,
    Rated,
    ServiceDescription,
    ServiceLayout,
    TotalPriceComponent,
    PriceSummaryComponent
} from '../components'
import { AirConditionFields, AirConditionFormShape, useAirConditionForm } from '../forms'
import { useAirConditionBreadCrumbs, useAirConditionServiceOptions, useFrequencyOptions, useJobQuotation, useResetFormCallback } from '../hooks'
import { getServiceStaticConfig } from '../utils'

type AirConditionScreenProps = {
    route: NavigationParams<ScreenNames.AirCondition>
    navigation: NavigationProps<ScreenNames.AirCondition>
}

const MIN_NO_OF_AC_FOR_CONTRACT = 2

export const AirConditionScreen: React.FunctionComponent<AirConditionScreenProps> = ({
    route
}) => {
    const T = useTranslations()
    const navigation = useNavigation()
    const { styles } = useStyles(stylesheet)
    const scrollRef = useRef<KeyboardAwareScrollView>(null)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const frequencyOptions = useFrequencyOptions()

    const breadcrumbs = useAirConditionBreadCrumbs()
    const { segmentSession } = useSegmentSession()
    const isMobile = useIsWithinBreakpoints(Breakpoint.XS, Breakpoint.MD)
    const [isHelpModalOpen, setHelpModalOpen] = useState(false)
    const airConditionServiceOptions = useAirConditionServiceOptions()
    const { jobQuotation, requestJobQuotation } = useJobQuotation(SupplyID.ACService)
    const [totalPrice, setTotalPrice] = useState(0)

    const config = getServiceStaticConfig(SupplyID.ACService)
    const { form, isFilled, hasError, submit, resetForm, setFieldValue } = useForm<AirConditionFormShape>(useAirConditionForm(), {
        onSuccess: form => {
            const shiftedDays = bookingHelpers.shiftBookingDays(form.bookingDays, config.min_notice_hours, form.startingDate)
            navigation.navigate(ScreenNames.AirConditionSummary, {
                form: {
                    ...form,
                    bookingDays: shiftedDays
                },
                price: totalPrice,
                postalcode: route.params?.postalcode,
                supplyId: SupplyID.ACService
            })
        }
    })
    const dateCustomValueFormat = useBookingDateCustomValueFormat(form.startingDate.value, form.bookingTime.value, form.bookingDays.value)
    const columnWidth = isNative || isMobile
        ? undefined
        : 'calc(55% - 10px)'
    const continueDisabled = !form.bookingTime.value || !isFilled || hasError
    const formSummaryValue = Object.keys(form).reduce((acc, nextValue) => ({
        ...acc,
        [nextValue]: form[nextValue].value
    }), {} as AirConditionFormShape)

    useEffect(() => {
        setFieldValue(AirConditionFields.BookingTime, '')
    }, [form.startingDate.value])

    useResetFormCallback(resetForm)

    useInitializeAddress(true)

    useEffect(() => {
        if (segmentSession.id) {
            Segment.bookingStarted({
                bookingSessionId: segmentSession.id,
                postcode: route.params?.postalcode as string,
                serviceType: ServiceType.AirCondition
            })
        }
    }, [segmentSession])

    useEffect(() => {
        if(!form.numberOfAirConditioners.value || !form.serviceOption.value.value) {
            return
        }

        requestJobQuotation({
            numberOfUnits: form.numberOfAirConditioners.value,
            contractType: form.serviceOption.value.contractType,
            frequency:  form.serviceOption.value.frequency.toLowerCase()
        })

    }, [form.numberOfAirConditioners.value, form.serviceOption.value.value])

    useEffect(() => {
        if (jobQuotation && jobQuotation.estimation) {
            const { value } = jobQuotation.estimation
            setTotalPrice(value)
        }
    }, [jobQuotation])

    const getFrequencyForDateModal = () => frequencyOptions.find(option => option.value === BookingFrequency.OneTime) || frequencyOptions[0]

    const getNoOfACRange = () => {
        if(form.serviceOption.value.frequency === AirConditionService.Contract){
            return MOCKED_NUMBER_OF_AIR_CONDITIONERS.filter(option => Number(option.value) >= MIN_NO_OF_AC_FOR_CONTRACT)
        }

        return MOCKED_NUMBER_OF_AIR_CONDITIONERS
    }

    const getNoOfACValue = () => {
        if(form.serviceOption.value.frequency === AirConditionService.Contract){
            return form.numberOfAirConditioners.value ? Math.max(Number(form.numberOfAirConditioners.value), MIN_NO_OF_AC_FOR_CONTRACT)
                .toString() : MIN_NO_OF_AC_FOR_CONTRACT.toString()
        }

        return form.numberOfAirConditioners.value
    }

    const onServiceOptionChange = (value: AirConditionServiceOption) => {
        if(value?.frequency === AirConditionService.Contract && Number(form.numberOfAirConditioners.value) < MIN_NO_OF_AC_FOR_CONTRACT){
            form.numberOfAirConditioners.onChangeValue(MIN_NO_OF_AC_FOR_CONTRACT.toString())
        }

        form.serviceOption.onChangeValue(value)
    }

    const onNoOfACChange = (value: string) => {
        Segment.bookingNumberOfAirConditionersSelected({
            quantity: Number(value)
        })

        form.numberOfAirConditioners.onChangeValue(value)
    }

    return (
        <React.Fragment>
            <ServiceLayout
                ref={scrollRef}
                onInfoPress={() => setHelpModalOpen(true)}
                title={T.screens.airCondition.title}
                withBackground
                breadcrumbs={breadcrumbs}
                bottomSpacerHeight={
                    isMobile
                        ? 0
                        : 50
                }
                contentColumn={(
                    <View>
                        <MediaQuery.Hidden from={Breakpoint.LG}>
                            <View style={styles.mobileDescriptionContainer}>
                                <ServiceDescription
                                    isHelpModalOpen={isHelpModalOpen}
                                    setHelpModalOpen={setHelpModalOpen}
                                    title={T.screens.airCondition.title}
                                    modalContent={(
                                        <AirConditionHelpModalContent />
                                    )}
                                    description={T.screens.airCondition.serviceDescription}
                                />
                            </View>
                        </MediaQuery.Hidden>
                        <BookingDateModal
                            title={T.screens.airCondition.title}
                            description={T.screens.airCondition.calendarModal.oneTime.description}
                            minHoursNotice={config.min_notice_hours}
                            onContinue={() => setIsModalOpen(false)}
                            isModalOpen={isModalOpen}
                            frequency={getFrequencyForDateModal()}
                            startingDateField={form.startingDate}
                            bookingTimeField={form.bookingTime}
                            bookingDaysField={form.bookingDays}
                            onClose={() => setIsModalOpen(false)}
                        />
                        <FormComponents.FormRow>
                            <Adapter.AirConditionServiceOptions
                                {...form.serviceOption}
                                scrollRef={scrollRef}
                                options={airConditionServiceOptions}
                                onChangeValue={onServiceOptionChange}
                            />
                        </FormComponents.FormRow>
                        <FormComponents.FormRow>
                            <View
                                style={{
                                    width: columnWidth
                                }}
                            >
                                <Adapter.DateAndTime
                                    {...form.startingDate}
                                    customValueFormat={dateCustomValueFormat}
                                    onPress={() => setIsModalOpen(true)}
                                />
                            </View>
                        </FormComponents.FormRow>
                        <FormComponents.FormRow>
                            <Adapter.OptionsRadio
                                {...form.numberOfAirConditioners}
                                options={getNoOfACRange()}
                                value={getNoOfACValue()}
                                onChangeValue={onNoOfACChange}
                            />
                        </FormComponents.FormRow>
                    </View>
                )}
                summaryColumn={(
                    <MediaQuery.Visible from={Breakpoint.LG}>
                        <ServiceDescription
                            isHelpModalOpen={isHelpModalOpen}
                            setHelpModalOpen={setHelpModalOpen}
                            title={T.screens.airCondition.title}
                            modalContent={(
                                <AirConditionHelpModalContent />
                            )}
                            description={T.screens.airCondition.serviceDescription}
                        />
                        <Sticky>
                            <Address />
                            <AirConditionSummaryColumn
                                priceMessage={T.screens.airCondition.estimatedMessage}
                                renderPriceComponent={() => (
                                    <TotalPriceComponent
                                        totalPrice={jobQuotation?.estimation?.value || 0}
                                        customText={T.common.estimatedPrice}
                                    />
                                )}
                                renderPriceSummaryComponent={() => jobQuotation?.priceSummary ? (
                                    <PriceSummaryComponent priceSummary={jobQuotation?.priceSummary} promotion={jobQuotation.discount} />
                                ) : undefined}
                                form={formSummaryValue}
                                jobQuotation={jobQuotation}
                            />
                            <Button
                                onPress={submit}
                                disabled={continueDisabled}
                                text={T.common.continue}
                            />
                            <BookingCancellationFees />
                        </Sticky>
                    </MediaQuery.Visible>
                )}
                footer={(
                    <MediaQuery.Hidden from={Breakpoint.LG}>
                        <MobileSummary
                            submit={submit}
                            price={totalPrice}
                            disabled={continueDisabled}
                            isLoading={false}
                            content={(
                                <React.Fragment>
                                    <Address />
                                    <AirConditionSummaryColumn
                                        priceMessage={T.screens.airCondition.estimatedMessage}
                                        renderPriceComponent={() => (
                                            <PriceComponent
                                                price={totalPrice}
                                                text={T.common.estimatedPrice}
                                            />
                                        )}
                                        renderPriceSummaryComponent={() => jobQuotation?.priceSummary ? (
                                            <PriceSummaryComponent priceSummary={jobQuotation?.priceSummary} promotion={jobQuotation.discount} />
                                        ) : undefined}
                                        form={formSummaryValue}
                                        jobQuotation={jobQuotation}
                                    />
                                    <Rated />
                                    <BookingCancellationFees />
                                </React.Fragment>
                            )}
                        />
                    </MediaQuery.Hidden>
                )}
            />
        </React.Fragment>
    )
}

const stylesheet = createStyles(theme => ({
    row: {
        marginTop: theme.utils.gap(1),
        flexDirection: {
            lg: 'row',
            xs: 'column'
        }
    },
    zIndex: {
        zIndex: theme.zIndex[10]
    },
    spacer: {
        width: theme.utils.gap(1)
    },
    selectWrapper: {
        flex: 1
    },
    mobileDescriptionContainer: {
        backgroundColor: theme.colors.marble,
        marginHorizontal: -theme.utils.gap(2),
        marginBottom: theme.utils.gap(2),
        paddingHorizontal: theme.utils.gap(2),
        paddingTop: theme.utils.gap(4)
    },
    resetMargin: {
        marginTop: 0
    }
}))
