import React from 'react'
import { View } from 'react-native'
import { Children, Nullable } from 'lib/types'
import { dateHelpers } from 'lib/utils'
import { createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { BookingSummary } from './BookingSummary'
import { LaundryFormShape } from '../forms'
import { useDriverInstructionsOptions } from '../hooks'
import { GetPromotionResponse, JobQuotationResponse } from '../types'

type LaundrySummaryColumnProps = {
    form: LaundryFormShape,
    couponText?: string,
    title?: string,
    renderPriceComponent: () => Children,
    renderExtraContent?: () => Children,
    promotionPackage?: Nullable<GetPromotionResponse>,
    jobQuotation?: JobQuotationResponse
    price?: Nullable<number>,
    totalPrice?: Nullable<number>,
    originalPrice?: Nullable<number>
}

export const LaundrySummaryColumn: React.FunctionComponent<LaundrySummaryColumnProps> = ({
    form,
    couponText,
    title,
    renderPriceComponent,
    renderExtraContent,
    promotionPackage,
    jobQuotation,
    totalPrice,
    originalPrice
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const collectionOptions = useDriverInstructionsOptions()
    const bookingDate = form.startingDate
        ? dateHelpers.shortFullDate(form.startingDate)
        : ''
    const collectionPoint = collectionOptions.find(option => option.value === form.driverInstructions)?.key
    const selectedServices = [
        form.washAndIron && T.screens.laundry.formFields.washAndIron.label,
        form.dryCleanAndIron && T.screens.laundry.formFields.dryCleanAndIron.label,
        form.ironOnly && T.screens.laundry.formFields.ironOnly.label,
        form.loadWash && T.screens.laundry.formFields.loadWash.label,
        form.curtainsWash && T.screens.laundry.formFields.curtainsWash.label
    ]
        .filter(Boolean)
        .join('\n')

    return (
        <View style={styles.container}>
            <BookingSummary
                totalPrice={totalPrice}
                originalPrice={originalPrice}
                promotionPackage={promotionPackage}
                title={title}
                couponText={couponText}
                renderPriceComponent={renderPriceComponent}
                numberOfLines={2}
                bookingSummary={{
                    [T.screens.laundry.collectionTime]: `${bookingDate}\n${form?.bookingTime}`,
                    [T.screens.laundry.collectionPoint]: collectionPoint || '',
                    [T.screens.laundry.selectedServices]: selectedServices,
                    ...(jobQuotation?.platformFee
                        ? {
                            [T.components.bookingSummary.platformFee]: jobQuotation.platformFee.value
                        }
                        : {}
                    )
                }}
                platformFeeDescription={jobQuotation?.platformFee?.description}
            />
            {renderExtraContent && renderExtraContent()}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        borderWidth: 1,
        borderRadius: 8,
        borderColor: theme.colors.silver,
        paddingTop: theme.utils.gap(2),
        paddingHorizontal: theme.utils.gap(2),
        marginBottom: theme.utils.gap(2),
        backgroundColor: theme.colors.white,
        ...theme.utils.createShadow(2, 0, 8, 4, 4, theme.colors.webShadow(0.04))
    }
}))
