import React, { useEffect, useState } from 'react'
import { getSharedConfigState } from 'features/bookings/utils'
import { Children, Nullable, SupplyID } from 'lib/types'
import {
    getAirconServiceConfig,
    getCleanerServiceConfig,
    getDeepCleaningServiceConfig,
    getCookingServiceConfig,
    getLaundryServiceConfig,
    getHandymanServiceConfig,
    getPestControlServiceConfig,
    getTaskErrandsServiceConfig,
    getMoversPackersServiceConfig
} from './actions'
import { useServiceConfigAtom, useSharedServiceConfigAtom } from 'lib/atoms'
import { ServiceConfigProvider } from './types'

export const ServiceConfigurationProviderPropsContext = React.createContext<Nullable<ServiceConfigProvider>>(null)

type ServiceConfigurationProviderProps = {
    children: Children
}

export const ServiceConfigurationProvider: React.FunctionComponent<ServiceConfigurationProviderProps> = ({ children }) => {

    const [serviceConfig, setServiceConfig] = useServiceConfigAtom()
    const [, setSharedServiceConfig] = useSharedServiceConfigAtom()
    const [isConfigReady, setIsConfigReady] = useState(false)

    const { mutate: getAirconConfig } = getAirconServiceConfig()
    const { mutate: getCleanerConfig } = getCleanerServiceConfig()
    const { mutate: getDeepCleaningConfig } = getDeepCleaningServiceConfig()
    const { mutate: getCookingConfig } = getCookingServiceConfig()
    const { mutate: getLaundryConfig } = getLaundryServiceConfig()
    const { mutate: getHandymanConfig } = getHandymanServiceConfig()
    const { mutate: getPestControlConfig } = getPestControlServiceConfig()
    const { mutate: getTaskErrandsConfig } = getTaskErrandsServiceConfig()
    const { mutate: getMoversPackersConfig } = getMoversPackersServiceConfig()

    useEffect(() => {
        fetchAndSetServiceConfig(getAirconConfig, SupplyID.ACService)
        fetchAndSetServiceConfig(getCleanerConfig, SupplyID.Cleaner)
        fetchAndSetServiceConfig(getDeepCleaningConfig, SupplyID.DeepCleaning)
        fetchAndSetServiceConfig(getCookingConfig, SupplyID.Cooking)
        fetchAndSetServiceConfig(getLaundryConfig, SupplyID.Laundry)
        fetchAndSetServiceConfig(getHandymanConfig, SupplyID.Handyman)
        fetchAndSetServiceConfig(getPestControlConfig, SupplyID.PestControl)
        fetchAndSetServiceConfig(getTaskErrandsConfig, SupplyID.TasksErrands)
        fetchAndSetServiceConfig(getMoversPackersConfig, SupplyID.MoversPackers)
    }, [setServiceConfig])

    const fetchAndSetServiceConfig = (
        mutateFunction: (variables, options: {
            onSuccess?: (response) => void;
            onError?: (error) => void;
        }) => void,
        supplyID: SupplyID
    ) => {
        mutateFunction({}, {
            onSuccess: response => {
                setServiceConfig(prevConfig => ({ ...prevConfig, [supplyID]: response }))
            },
            onError: error => console.error('error', error)
        })
    }

    const isServiceConfigReady = () => Object.values(serviceConfig).every(element => element._id !== '')

    useEffect(() => {
        setIsConfigReady(isServiceConfigReady())

        if(isServiceConfigReady()) {
            setSharedServiceConfig(getSharedConfigState(serviceConfig))
        }
    },[serviceConfig])

    return (
        <ServiceConfigurationProviderPropsContext.Provider value={null}>
            {isConfigReady ? children : null}
        </ServiceConfigurationProviderPropsContext.Provider>
    )
}
