import { useMutation } from '@tanstack/react-query'
import { useFetcher } from 'lib/api'
import { ErrorResponse, HttpMethod, Response } from 'lib/types'
import { ContactUsRequest, ContactUsResponse } from './types'

export const contactUs = () => {
    const fetcher = useFetcher<ContactUsResponse>(HttpMethod.POST, '/user/feedback')

    return useMutation<Response<ContactUsResponse>, ErrorResponse, ContactUsRequest>(fetcher)
}
