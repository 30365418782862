import React from 'react'
import { ActivityIndicator, View } from 'react-native'
import { Icons } from 'assets'
import { UseFormReturn } from '@codegateinc/react-form-builder-v2'
import { createStyles, theme } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { CustomModal, Button, Typography, DockedModal } from 'lib/components'
import { dateHelpers } from 'lib/utils'
import { isNative } from 'lib/common'
import { BookingFrequency, CustomFonts, DayOfWeekMapping, JobSchedule } from 'lib/types'
import { PauseBookingsFormShape } from 'features/jobs/forms'
import { JobDetailsResponse } from 'features/jobs/types'

type SubscriptionPauseSuccessModalProps = {
    isOpen: boolean,
    isLoading: boolean,
    onClose: VoidFunction,
    formReturn: UseFormReturn<PauseBookingsFormShape>
    job: JobDetailsResponse
}

export const SubscriptionPauseSuccessModal: React.FunctionComponent<SubscriptionPauseSuccessModalProps> = ({
    isOpen,
    job,
    onClose,
    isLoading }) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)

    const Modal = isNative ? DockedModal : CustomModal

    const generateRepeatDays = (schedule: Array<JobSchedule>) => {
        const activeSchedule = schedule?.filter(item => item?.activated)
        const lengthOfDays = activeSchedule.length

        return (
            <Typography.Regular>
                {activeSchedule.map((schedule, i) => {
                    const isLastElement = i + 1 === lengthOfDays
                    const isPrevLastElement = i + 2 === lengthOfDays
                    const separatorChar = isPrevLastElement || isLastElement ? ' ' : ', '
                    const prefixChar = isLastElement ? lengthOfDays > 1 ? 'and ' : '' : ''

                    return (
                        <React.Fragment key={schedule.day}>
                            <Typography.Regular>
                                <Typography.Regular style={styles.frequencyNote}>{prefixChar}</Typography.Regular>
                                <Typography.Regular style={styles.repeatDay}>{DayOfWeekMapping[schedule.day]}</Typography.Regular>{separatorChar}
                            </Typography.Regular>
                        </React.Fragment>
                    )
                })}
            </Typography.Regular>
        )
    }

    return (
        <Modal
            onClose={onClose}
            isOpen={isOpen}
        >
            <View style={styles.modalWrapper}>
                <View style={styles.iconContainer}>
                    <Icons.CheckCircle
                        size={40}
                        forceColor={theme.colors.orange}
                    />
                </View>
                <Typography.SmallSubheading style={styles.title}>
                    {T.screens.jobDetails.subscriptionPauseSuccessModal.packageTitle}
                </Typography.SmallSubheading>
                <Typography.Regular style={styles.messageContainer}>
                    {T.screens.jobDetails.subscriptionPauseSuccessModal.description}
                </Typography.Regular>
                {
                    isLoading ? (
                        <View style={styles.loadingContainer}>
                            <ActivityIndicator color={theme.colors.orange} />
                        </View>
                    ) : (
                        <View style={styles.datetimeWrapper}>
                            <View style={styles.row}>
                                <View style={styles.datetimeContainer}>
                                    <Typography.Regular style={styles.prefixPauseText}>
                                        {T.screens.jobDetails.subscriptionPauseSuccessModal.resumeOn}
                                    </Typography.Regular>
                                    <Typography.Regular style={styles.datetimeText}>
                                        {isLoading ? '-' : job?.job?.pause_end_date && dateHelpers.bookingFullDate(job.job.pause_end_date)}
                                    </Typography.Regular>
                                </View>
                                <View style={styles.datetimeContainer}>
                                    <Icons.Clock size={20} forceColor={theme.colors.orange} viewBox="0 0 25 25" />
                                    <Typography.Regular style={styles.datetimeText}>
                                        {isLoading ? '-' : job?.job?.pause_end_date && dateHelpers.selectTimeLabel(job.job.pause_end_date)}
                                    </Typography.Regular>
                                </View>
                            </View>
                            <View style={styles.row}>
                                <Typography.Regular style={styles.frequencyNote}>
                                    {
                                        job?.job?.frequency === BookingFrequency.Weekly ?
                                            T.screens.jobDetails.rescheduleRequestSend.weeklyRepeat :
                                            T.screens.jobDetails.rescheduleRequestSend.fortnightlyRepeat
                                    } {generateRepeatDays(job?.job?.schedule)}
                                </Typography.Regular>
                            </View>
                        </View>
                    )}

                <View style={styles.actionContainer}>
                    <View style={styles.buttonWrapper}>
                        <Button
                            disabled={isLoading}
                            text={T.screens.jobDetails.pausePackageSuccess.done}
                            onPress={onClose}
                            textWrapperStyles={styles.buttonTextWrapper}
                            textStyles={styles.buttonText}
                        />
                    </View>
                </View>
            </View>
        </Modal>
    )
}

const stylesheet = createStyles(theme => ({
    modalWrapper: {
        alignItems: 'center',
        paddingHorizontal: {
            lg: theme.utils.gap(16),
            md: theme.utils.gap(8),
            sm: theme.utils.gap(0),
            xs: theme.utils.gap(0)
        }
    },
    iconContainer: {
        alignItems: 'center',
        paddingBottom: theme.utils.gap(1)
    },
    messageContainer: {
        textAlign: 'center',
        marginTop: theme.utils.gap(1.5)
    },
    actionContainer: {
        paddingTop: theme.utils.gap(1),
        flexDirection: 'row',
        justifyContent: 'center',
        zIndex: -1,
        width: {
            xs: '100%',
            md: undefined
        }
    },
    buttonWrapper: {
        flex: 1
    },
    buttonTextWrapper: {
        width: '100%',
        wordWrap: 'break-word',
        textAlign: 'center',
        marginHorizontal: theme.utils.gap(7)
    },
    jobIdText: {
        fontWeight: 'bold'
    },
    datetimeWrapper: {
        borderRadius: 8,
        backgroundColor: theme.colors.latte,
        paddingVertical: theme.utils.gap(1.5),
        paddingHorizontal: {
            xs: theme.utils.gap(1),
            md: theme.utils.gap(3)
        },
        marginVertical: theme.utils.gap(1.5),
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: {
            xs: '100%',
            md: undefined
        }
    },
    datetimeContainer: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: theme.utils.gap(1)
    },
    datetimeText: {
        marginTop: theme.utils.gap(1),
        fontSize: isNative ? 12 : 16,
        lineHeight: 20
    },
    prefixPauseText: {
        marginLeft: theme.utils.gap(1),
        fontWeight: '600',
        color: theme.colors.orange,
        height: 20
    },
    title: {
        fontSize: 25,
        lineHeight: 30,
        marginTop: theme.utils.gap(1.5),
        textAlign: 'center'
    },
    buttonText: {
        textAlign: 'center',
        fontFamily: CustomFonts.Roboto500
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    frequencyNote: {
        color: theme.colors.darkGrey,
        marginTop: theme.utils.gap(1),
        fontSize: 13
    },
    repeatDay: {
        fontWeight: 'bold',
        fontSize: 13,
        color: theme.colors.darkGrey
    },
    loadingContainer: {
        padding: theme.utils.gap(2)
    }
}))
