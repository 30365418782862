import React from 'react'
import { View } from 'react-native'
import { createStyles } from 'lib/styles'
import { InfoStack, ListBottomSpacer, Typography } from 'lib/components'
import { useStyles, useTranslations } from 'lib/hooks'

export const DeepCleaningHelpModalContent: React.FunctionComponent = () => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)

    const includedItemsText = [
        {
            bold: T.components.deepCleaningHelp.included.inclusionsBold,
            regular: T.components.deepCleaningHelp.included.inclusionsRegular
        }
    ]

    const howDoesItWorkItemsText = [
        {
            text: T.components.deepCleaningHelp.howDoesItWork.cleaningInstruction
        },
        {
            text: T.components.deepCleaningHelp.howDoesItWork.partnerInfo
        }
    ]

    const includedItems = includedItemsText.map(({ bold, regular }, index) => (
        <View key={index} style={styles.row}>
            <Typography.Regular style={styles.flex} bold>
                {bold}
            </Typography.Regular>
            <Typography.Regular style={styles.flex}>
                {regular}
            </Typography.Regular>
        </View>
    ))

    const howDoesItWorkItems: Array<React.ReactElement> = howDoesItWorkItemsText.map(({ text }, index) => (
        <Typography.Regular key={index}>
            {text}
        </Typography.Regular>
    ))

    return (
        <View style={styles.container}>
            <View style={styles.title}>
                <Typography.Regular style={styles.titleText} bold>
                    {T.components.deepCleaningHelp.title}
                </Typography.Regular>
            </View>
            <Typography.Regular style={styles.descriptionText} forceColor={theme.colors.darkGrey}>
                {T.components.deepCleaningHelp.description}
            </Typography.Regular>

            <InfoStack title={T.components.deepCleaningHelp.included.title} items={includedItems} />

            <ListBottomSpacer />

            <InfoStack title={T.components.deepCleaningHelp.howDoesItWork.title} items={howDoesItWorkItems} />

        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        maxWidth: 800
    },
    title: {
        borderBottomWidth: 1,
        borderColor: theme.colors.silver,
        paddingBottom: theme.utils.gap(2),
        marginBottom: theme.utils.gap(2)
    },
    titleText: {
        fontSize: theme.fontSize[18]
    },
    descriptionText: {
        marginBottom: theme.utils.gap(2)
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: theme.utils.gap(1)
    },
    flex: {
        flex: 1
    }
}))
