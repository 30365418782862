import React from 'react'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import {
    AuthFinishSocialSignUpScreen,
    AuthForgotPasswordScreen,
    AuthNewPasswordScreen,
    AuthSingleSignOnScreen,
    AuthVerificationCodeNativeScreen,
    LogInNativeScreen,
    SignUpNativeScreen
} from 'features/auth'
import { OnboardingNativeScreen } from 'features/onboarding'
import { useTranslations } from 'lib/hooks'
import { ScreenNames } from '../screens'
import { AppStackParams } from '../stackParams'

const Stack = createNativeStackNavigator<AppStackParams>()

export const UnauthorizedNativeStack = (isFirstLaunch: boolean) => {
    const T = useTranslations()

    return (
        <React.Fragment>
            {isFirstLaunch && (
                <Stack.Screen
                    name={ScreenNames.OnboardingNative}
                    component={OnboardingNativeScreen}
                />
            )}
            <Stack.Screen
                name={ScreenNames.SignUpNative}
                component={SignUpNativeScreen}
            />
            <Stack.Screen
                name={ScreenNames.LogInNative}
                component={LogInNativeScreen}
            />
            <Stack.Screen
                name={ScreenNames.AuthVerificationCodeNative}
                component={AuthVerificationCodeNativeScreen}
            />
            <Stack.Screen
                name={ScreenNames.AuthForgotPassword}
                component={AuthForgotPasswordScreen}
                options={{
                    title: T.screens.authOTP.pageTitle
                }}
            />
            <Stack.Screen
                name={ScreenNames.AuthNewPassword}
                component={AuthNewPasswordScreen}
                options={{
                    title: T.screens.authNewPassword.pageTitle
                }}
            />
            <Stack.Screen
                name={ScreenNames.AuthSingleSignOn}
                component={AuthSingleSignOnScreen}
                options={{
                    title: T.screens.authSingleSignOn.pageTitle
                }}
            />
            <Stack.Screen
                name={ScreenNames.AuthFinishSocialSignUp}
                component={AuthFinishSocialSignUpScreen}
                options={{
                    title: T.screens.authFinishSocialSignUp.pageTitle
                }}
            />
        </React.Fragment>
    )
}
