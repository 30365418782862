import React from 'react'
import { View, ViewStyle } from 'react-native'
import { Icons } from 'assets'
import { createStyles } from 'lib/styles'
import { useStyles } from 'lib/hooks'
import { Touchable } from './Touchable'

type BackButtonProps = {
    onPress: VoidFunction,
    customStyles?: ViewStyle
    size?: number
}

export const BackButton: React.FunctionComponent<BackButtonProps> = ({
    onPress,
    customStyles = {},
    size = 10
}) => {
    const { styles } = useStyles(stylesheet)

    return (
        <Touchable
            style={styles.backButtonWrapper}
            onPress={onPress}
        >
            <View
                style={{
                    ...styles.backButton,
                    ...customStyles,
                    transform: [
                        {
                            rotateZ: '90deg'
                        }
                    ]
                }}
            >
                <Icons.Chevron size={size} />
            </View>
        </Touchable>
    )
}

const stylesheet = createStyles(theme => ({
    backButtonWrapper: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    backButton: {
        width: 32,
        height: 32,
        paddingTop: 12,
        marginRight: theme.utils.gap(1),
        alignItems: 'center',
        borderRadius: 4,
        borderWidth: 1,
        borderColor: theme.colors.silver
    }
}))
