import { useState } from 'react'
import { Platform } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { ProviderDetails } from 'lib/models'
import { AuthorizeWithAppleResponse, ErrorCodes, GoogleWebErrorCodes, NotificationType } from 'lib/types'
import { ScreenNames } from 'lib/routing'
import { useSocialSignInTokenAtom, useNewPasswordTokenAtom, useToastAtom } from 'lib/atoms'
import { DeviceInfo, R } from 'lib/utils'
import { useGetErrorMessage, useTranslations, useSessionData } from 'lib/hooks'
import { AnalyticsEvent, AnalyticsEventDescription, AuthSegmentMethod, GoogleAnalytics, Segment } from 'lib/analytics'
import { useSignInWithApple, useSignInWithEmail, useSignInWithFacebook, useSignInWithGoogle } from '../actions'
import { saveAccessToken, saveRefreshToken } from '../utils'
import { useGetProfileData } from './useGetProfileData'
import { authorizeWithApple, authorizeWithFacebook, authorizeWithGoogle, getFacebookUserDetails } from '../social'
import { AuthFlowError, SignInResponse } from '../types'
import { authParams } from 'features/auth'
import { isErrorWithCode, statusCodes } from '@react-native-google-signin/google-signin'
import { getAnonymousID } from 'lib/analytics/segment/utils'

export const useSignIn = (onError?: (error: string) => void) => {
    const T = useTranslations()
    const navigation = useNavigation()
    const [, setToastMessage] = useToastAtom()
    const [, setNewPasswordToken] = useNewPasswordTokenAtom()
    const [, setSocialSignInToken] = useSocialSignInTokenAtom()
    const { mutate: signInWithFacebook } = useSignInWithFacebook()
    const { mutate: signInWithApple } = useSignInWithApple()
    const { mutate: signInWithGoogle } = useSignInWithGoogle()
    const { getMe, isFetchingProfile } = useGetProfileData(
        error => {
            if (onError) {
                onError(error)
            }

            setIsLoading(false)
        })
    const [isLoading, setIsLoading] = useState(false)
    const { getErrorMessage } = useGetErrorMessage()
    const { mutate: signInWithEmail } = useSignInWithEmail()
    const sessionData = useSessionData()
    const onAuthSuccess = (data: SignInResponse, authMethod: AuthSegmentMethod) => {
        saveAccessToken(data.tokens.accessToken)
        saveRefreshToken(data.tokens.refreshToken)
        getMe(data.token)

        const { deviceId, deviceName } = DeviceInfo.get()

        Segment.identify(data.token, {
            device: {
                deviceId,
                deviceName,
                platform: Platform.OS
            }
        })
    }

    const isUserAuthenticated = (authenticatedResponse?: AuthorizeWithAppleResponse) => authenticatedResponse &&
        authenticatedResponse.authorization?.code &&
        authenticatedResponse.authorization?.id_token

    const signInWithAppleAfterAuth = async (response: AuthorizeWithAppleResponse) => {
        signInWithApple({
            ...sessionData,
            provider_details: {
                apple_user_id: response.authorization.code,
                provider: ProviderDetails.Apple,
                provider_access_token: response.authorization.id_token || '',
                anonymous_id: await getAnonymousID()
            }
        }, {
            onSuccess: ({ data }) => {
                GoogleAnalytics.logEvent({
                    eventType: AnalyticsEvent.UserAction,
                    description: AnalyticsEventDescription.AppleSignIn
                })

                onAuthSuccess(data, AuthSegmentMethod.Apple)
                setIsLoading(false)
            },
            onError: errorResponse => {
                setIsLoading(false)

                if (errorResponse.errors.error_code === ErrorCodes.UnhandledException) {
                    setSocialSignInToken(response.authorization?.id_token || '')

                    return navigation.navigate(ScreenNames.AuthFinishSocialSignUp, authParams.getAuthFinishAppleSignUpParams(response))
                }

                setToastMessage({
                    message: getErrorMessage(errorResponse),
                    type: NotificationType.Error
                })
            }
        })
    }

    return {
        isLoading: isLoading || isFetchingProfile,
        signInWithEmail: async (email, password) => {
            setIsLoading(true)

            signInWithEmail({
                ...sessionData,
                email_id: email,
                password,
                anonymous_id: await getAnonymousID(),
                provider_details: {
                    provider: ProviderDetails.Email
                }
            }, {
                onSuccess: ({ data }) => {
                    GoogleAnalytics.logEvent({
                        eventType: AnalyticsEvent.UserAction,
                        description: AnalyticsEventDescription.EmailSignIn
                    })

                    onAuthSuccess(data, AuthSegmentMethod.Email)
                },
                onError: response => {
                    setIsLoading(false)

                    if (response.errors.error_code === ErrorCodes.ForceResetPassword) {
                        setNewPasswordToken(response.token)

                        return navigation.navigate(ScreenNames.AuthNewPassword, {
                            tempPassword: password
                        })
                    }

                    if (onError) {
                        // eslint-disable-next-line nested-if/nested-if-statements
                        if (response.errors.error_code === ErrorCodes.TooManyErrorAttempts) {
                            return onError(T.screens.auth.errors.tooManyLoginAttempts)
                        }

                        onError(getErrorMessage(response))
                    }

                }
            })
        },
        signInWithFacebook: () => {
            setIsLoading(true)
            authorizeWithFacebook(async (accessToken, error) => {
                if (!error && accessToken) {
                    return signInWithFacebook({
                        ...sessionData,
                        provider_details: {
                            provider: ProviderDetails.Facebook,
                            provider_access_token: accessToken,
                            anonymous_id: await getAnonymousID()
                        }
                    }, {
                        onSuccess: ({ data }) => {
                            GoogleAnalytics.logEvent({
                                eventType: AnalyticsEvent.UserAction,
                                description: AnalyticsEventDescription.FacebookSignIn
                            })

                            onAuthSuccess(data, AuthSegmentMethod.Facebook)
                        },
                        onError: async error => {
                            if (error.errors?.error_code !== ErrorCodes.UnhandledException) {
                                setIsLoading(false)

                                return setToastMessage({
                                    message: getErrorMessage(error),
                                    type: NotificationType.Error
                                })
                            }

                            // user has no facebook account, redirect to sign up
                            const userProfile = await getFacebookUserDetails(accessToken)
                                .catch(R.always(null))

                            if (!userProfile) {
                                setIsLoading(false)

                                return setToastMessage({
                                    message: getErrorMessage(error),
                                    type: NotificationType.Error
                                })
                            }

                            setSocialSignInToken(accessToken)
                            setIsLoading(false)

                            navigation.navigate(ScreenNames.AuthFinishSocialSignUp, {
                                email: userProfile.email,
                                name: userProfile.name,
                                providerDetails: ProviderDetails.Facebook
                            })
                        }
                    })
                }

                setIsLoading(false)

                if (error === AuthFlowError.ServerError) {
                    setToastMessage({
                        message: T.screens.auth.errors.facebookError,
                        type: NotificationType.Error
                    })
                }
            })
        },
        signInWithApple: (authenticatedResponse?: AuthorizeWithAppleResponse) => {
            setIsLoading(true)

            // check if the user is already signed up
            if (authenticatedResponse && isUserAuthenticated(authenticatedResponse)) {
                signInWithAppleAfterAuth(authenticatedResponse)

                return
            }

            authorizeWithApple()
                .then(response => {
                    signInWithAppleAfterAuth(response)
                })
                .catch((error: AuthFlowError) => {
                    if (error === AuthFlowError.ServerError) {
                        setToastMessage({
                            message: T.screens.auth.errors.appleError,
                            type: NotificationType.Error
                        })
                    }
                })
                .finally(() => setIsLoading(false))
        },
        signInWithGoogle: () => {
            setIsLoading(true)
            authorizeWithGoogle().then(response => {
                if (!response.access_token || !response.accountId) {
                    return setToastMessage({
                        message: T.screens.auth.googleSignInError,
                        type: NotificationType.Error
                    })
                }

                signInWithGoogle({
                    ...sessionData,
                    email_id: response.email,
                    provider_details: {
                        account_id: response.accountId,
                        provider: ProviderDetails.Google,
                        provider_access_token: response.access_token
                    }
                }, {
                    onSuccess: ({ data }) => {
                        GoogleAnalytics.logEvent({
                            eventType: AnalyticsEvent.UserAction,
                            description: AnalyticsEventDescription.GoogleSignIn
                        })

                        onAuthSuccess(data, AuthSegmentMethod.Google)
                        setIsLoading(false)
                    },
                    onError: async error => {
                        if (error.errors?.error_code !== ErrorCodes.UnhandledException) {
                            setIsLoading(false)

                            return setToastMessage({
                                message: getErrorMessage(error),
                                type: NotificationType.Error
                            })
                        }

                        // user has not signed up, redirect
                        setIsLoading(false)
                        setSocialSignInToken(response.access_token)
                        navigation.navigate(ScreenNames.AuthFinishSocialSignUp, {
                            email: response.email,
                            name: response.name,
                            accountId: response.accountId,
                            providerDetails: ProviderDetails.Google
                        })
                    }
                })
            }).catch(error => {
                setIsLoading(false)

                if (error === AuthFlowError.ServerError) {
                    return setToastMessage({
                        message: T.screens.auth.errors.googleError,
                        type: NotificationType.Error
                    })
                }

                // Error handling for google web sign in
                if (error?.message === GoogleWebErrorCodes.CANCELLED || error === GoogleWebErrorCodes.ACCESS_DENIED) {
                    return
                }

                if (!isErrorWithCode(error)) {
                    return setToastMessage({
                        message: T.screens.auth.googleSignInError,
                        type: NotificationType.Error
                    })
                }

                switch (error.code) {
                    case statusCodes.PLAY_SERVICES_NOT_AVAILABLE:
                        return setToastMessage({
                            message: T.screens.auth.playServicesNotAvailable,
                            type: NotificationType.Error
                        })
                    case statusCodes.SIGN_IN_CANCELLED:
                        break
                    case '-1': // User denied access to their account
                        break
                    default:
                        return setToastMessage({
                            message: T.screens.auth.googleSignInError,
                            type: NotificationType.Error
                        })
                }

            }).finally(() => setIsLoading(false))
        }
    }
}
