import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const Eye: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path d="M40 34.004c-3.307 0-6-2.692-6-6s2.693-6 6-6c3.309 0 6 2.692 6 6s-2.691 6-6 6m0-20c-7.72 0-14 6.28-14 14s6.28 14 14 14 14-6.28 14-14-6.28-14-14-14m.876 33.99c-17.224.4-28.46-14.332-32.188-20.008 4.104-6.42 14.44-19.565 30.432-19.973 17.156-.436 28.456 14.333 32.184 20.009-4.1 6.42-14.44 19.564-30.428 19.972m38.596-21.98C76.92 21.568 62.824-.73 38.92.019 16.806.578 3.947 20.058.53 26.015a3.995 3.995 0 0 0 0 3.98c2.516 4.388 16.116 26.008 39.568 26.008.329 0 .657-.004.985-.012 22.108-.564 34.972-20.04 38.388-25.996a4.014 4.014 0 0 0 0-3.98" />
    </Icon>
)
