import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { useForm } from '@codegateinc/react-form-builder-v2'
import { Adapter, Address, Button, FormComponents, Grid, MediaQuery, Sticky, Typography } from 'lib/components'
import { useInitializeAddress, useIsWithinBreakpoints, useQueryHelpers, useSegmentSession, useStyles, useTranslations } from 'lib/hooks'
import { NavigationParams, NavigationProps, ScreenNames } from 'lib/routing'
import { Breakpoint, createStyles } from 'lib/styles'
import { SupplyID } from 'lib/types'
import { bookingHelpers, dateHelpers } from 'lib/utils'
import { Segment, ServiceType } from 'lib/analytics'
import { getServiceStaticConfig } from 'features/bookings/utils'
import { getLaundryTimeSlots } from '../actions'
import {
    BookingCancellationFees,
    BookingDateModal,
    ChargesComponent,
    LaundryHelpModalContent,
    LaundryServices,
    LaundrySummaryColumn,
    MobileSummary,
    PriceComponent,
    Rated,
    ServiceDescription,
    ServiceLayout
} from '../components'
import { LaundryFields, LaundryFormShape, useLaundryForm } from '../forms'
import { TimeSlot } from '../types'
import {
    useDriverInstructionsOptions,
    useJobQuotation,
    useLaundryAdditionalInfo,
    useLaundryAvailableTimeSlots,
    useLaundryBreadcrumbs,
    useResetFormCallback
} from '../hooks'

type LaundryScreenProps = {
    route: NavigationParams<ScreenNames.Laundry>
    navigation: NavigationProps<ScreenNames.Laundry>
}

export const LaundryScreen: React.FunctionComponent<LaundryScreenProps> = ({
    route
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const navigation = useNavigation()
    const { onRequestError } = useQueryHelpers()
    const { segmentSession } = useSegmentSession()
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [timeSlots, setTimeSlots] = useState<Array<TimeSlot>>()
    const { mutate: getTimeSlots, isLoading } = getLaundryTimeSlots()
    const breadcrumbs = useLaundryBreadcrumbs()
    const driverInstructions = useDriverInstructionsOptions()
    const [isHelpModalOpen, setHelpModalOpen] = useState(false)
    const { getAdditionalInfoSummary } = useLaundryAdditionalInfo()
    const isMobile = useIsWithinBreakpoints(Breakpoint.XS, Breakpoint.MD)
    const { jobQuotation, requestJobQuotation } = useJobQuotation(SupplyID.Laundry)
    const formReturn = useForm<LaundryFormShape>(useLaundryForm(timeSlots || []), {
        onSuccess: form => {
            const shiftedDays = bookingHelpers.shiftBookingDays(form.bookingDays, config.min_notice_hours, form.startingDate)
            const additionalInfo = getAdditionalInfoSummary(form)
            const matchingTimeSlot = timeSlots?.find(timeSlot => timeSlot.display === form.bookingTime)

            navigation.navigate(ScreenNames.LaundrySummary, {
                form: {
                    ...form,
                    bookingDays: shiftedDays
                },
                price: totalCost,
                postalcode: route.params?.postalcode,
                supplyId: SupplyID.Laundry,
                persistentComment: additionalInfo,
                dateTime: matchingTimeSlot
                    ? dateHelpers.getStartDateOfTimeSlot(matchingTimeSlot, form.startingDate)
                    : undefined
            })
        }
    })
    const { form, isFilled, hasError, submit, resetForm, setFieldValue } = formReturn
    const availableTimeSlotsOptions = useLaundryAvailableTimeSlots(form.startingDate.value, timeSlots)
    const config = getServiceStaticConfig(SupplyID.Laundry)
    const hasSelectedServices = [
        form.washAndIron.value && form.washAndIronItems.value > 0,
        form.dryCleanAndIron.value && form.dryCleanAndIronItems.value > 0,
        form.ironOnly.value && form.ironOnlyItems.value > 0,
        form.loadWash.value && form.loadWashItems.value > 0,
        form.curtainsWash.value && form.curtainsWashItems.value > 0
    ]
        .filter(Boolean)
        .length > 0
    const continueDisabled = !hasSelectedServices || !form.bookingTime.value || !isFilled || hasError
    const formSummaryValue = Object.keys(form).reduce((acc, nextValue) => ({
        ...acc,
        [nextValue]: form[nextValue].value
    }), {} as LaundryFormShape)
    // TODO Calc this somehow
    const totalCost = 0

    useEffect(() => {
        setFieldValue(LaundryFields.BookingTime, '')
    }, [form.startingDate.value])

    useEffect(() => {
        if (form.bookingTime.value) {
            form.bookingTime.validate()
        }
    }, [form.bookingTime.value])

    useEffect(() => {
        getTimeSlots({}, {
            onSuccess: ({ data }) => setTimeSlots(data.time_slot_list),
            onError: onRequestError
        })
    }, [])

    useResetFormCallback(resetForm)

    useInitializeAddress(true)

    useEffect(() => {
        if (segmentSession.id) {
            Segment.bookingStarted({
                bookingSessionId: segmentSession.id,
                postcode: route.params?.postalcode as string,
                serviceType: ServiceType.Laundry
            })
        }
    }, [segmentSession])

    useEffect(() => {
        requestJobQuotation(null)
    }, [])

    return (
        <React.Fragment>
            <ServiceLayout
                onInfoPress={() => setHelpModalOpen(true)}
                title={T.screens.laundry.title}
                withBackground
                breadcrumbs={breadcrumbs}
                bottomSpacerHeight={
                    isMobile
                        ? 0
                        : 50
                }
                contentColumn={(
                    <View>
                        <MediaQuery.Hidden from={Breakpoint.LG}>
                            <View style={styles.mobileDescriptionContainer}>
                                <ServiceDescription
                                    isHelpModalOpen={isHelpModalOpen}
                                    setHelpModalOpen={setHelpModalOpen}
                                    title={T.screens.laundry.title}
                                    modalContent={(
                                        <LaundryHelpModalContent />
                                    )}
                                    description={T.screens.laundry.serviceDescription}
                                />
                            </View>
                        </MediaQuery.Hidden>
                        <BookingDateModal
                            title={T.screens.laundry.calendarModal.oneTime.title}
                            timeTitle={T.screens.laundry.formFields.selectTime.label}
                            description={T.screens.laundry.calendarModal.oneTime.description}
                            minHoursNotice={config.min_notice_hours}
                            onContinue={() => setIsModalOpen(false)}
                            isModalOpen={isModalOpen}
                            frequency={form.frequency.value}
                            startingDateField={form.startingDate}
                            bookingTimeField={form.bookingTime}
                            bookingDaysField={form.bookingDays}
                            onClose={() => setIsModalOpen(false)}
                            timeSlots={availableTimeSlotsOptions}
                            isLoading={isLoading}
                        />
                        <FormComponents.FormRow
                            title={T.screens.laundry.collectionTime}
                            style={styles.zIndex}
                        >
                            <View style={styles.row}>
                                <View style={styles.selectWrapper}>
                                    <Adapter.DateAndTime
                                        {...form.startingDate}
                                        value={(form.startingDate.value && form.bookingTime.value)
                                            ? `${dateHelpers.shortFullDate(form.startingDate.value)} - ${form.bookingTime.value}`
                                            : form.startingDate.value
                                        }
                                        onPress={() => setIsModalOpen(true)}
                                    />
                                    <Grid.Gap gapBottom={1} />
                                    {Boolean(config.min_notice_hours) && (
                                        <Typography.Label forceColor={theme.colors.fog}>
                                            {`${T.screens.laundry.minimumNotice.prefix} - ${config.min_notice_hours} ${T.screens.laundry.minimumNotice.suffix}`}
                                        </Typography.Label>
                                    )}
                                </View>
                                <View style={styles.spacer} />
                                <View style={styles.selectWrapper}>
                                    <Adapter.SelectInputColumn
                                        {...form.driverInstructions}
                                        options={driverInstructions}
                                        placeholderContainerStyles={styles.resetMargin}
                                        isClearable={false}
                                        onOptionSelect={value => {
                                            Segment.bookingInstructionForDriverSelected({
                                                instruction: value.label
                                            })
                                        }}
                                    />
                                </View>
                            </View>
                        </FormComponents.FormRow>
                        <LaundryServices formReturn={formReturn} />
                    </View>
                )}
                summaryColumn={(
                    <MediaQuery.Visible from={Breakpoint.LG}>
                        <ServiceDescription
                            isHelpModalOpen={isHelpModalOpen}
                            setHelpModalOpen={setHelpModalOpen}
                            title={T.screens.laundry.title}
                            modalContent={(
                                <LaundryHelpModalContent />
                            )}
                            description={T.screens.laundry.serviceDescription}
                        />
                        <Sticky>
                            <Address />
                            <LaundrySummaryColumn
                                renderPriceComponent={() => (
                                    <ChargesComponent
                                        name={jobQuotation?.minimumCharges?.name}
                                        value={jobQuotation?.minimumCharges?.value}
                                    />
                                )}
                                form={formSummaryValue}
                                jobQuotation={jobQuotation}
                            />
                            <Button
                                onPress={submit}
                                disabled={continueDisabled}
                                text={T.common.continue}
                            />
                            <BookingCancellationFees />
                        </Sticky>
                    </MediaQuery.Visible>
                )}
                footer={(
                    <MediaQuery.Hidden from={Breakpoint.LG}>
                        <MobileSummary
                            submit={submit}
                            price={totalCost}
                            disabled={continueDisabled}
                            isLoading={isLoading}
                            content={(
                                <React.Fragment>
                                    <Address />
                                    <LaundrySummaryColumn
                                        renderPriceComponent={() => (
                                            <PriceComponent
                                                price={totalCost}
                                                text={T.common.totalPrice}
                                            />
                                        )}
                                        form={formSummaryValue}
                                        jobQuotation={jobQuotation}
                                    />
                                    <Rated />
                                    <BookingCancellationFees />
                                </React.Fragment>
                            )}
                        />
                    </MediaQuery.Hidden>
                )}
            />
        </React.Fragment>
    )
}

const stylesheet = createStyles(theme => ({
    row: {
        flexDirection: {
            lg: 'row',
            xs: 'column'
        }
    },
    zIndex: {
        zIndex: theme.zIndex[10]
    },
    spacer: {
        width: theme.utils.gap(2),
        height: theme.utils.gap(2)
    },
    selectWrapper: {
        flex: {
            lg: 1,
            xs: undefined
        },
        zIndex: theme.zIndex[10]
    },
    mobileDescriptionContainer: {
        backgroundColor: theme.colors.marble,
        marginHorizontal: -theme.utils.gap(2),
        marginBottom: theme.utils.gap(2),
        paddingHorizontal: theme.utils.gap(2),
        paddingTop: theme.utils.gap(4)
    },
    resetMargin: {
        marginTop: theme.utils.gap(1)
    }
}))
