import { useCallback } from 'react'
import { useFocusEffect } from '@react-navigation/native'
import { AvoidSoftInput } from 'react-native-avoid-softinput'

export const useAvoidInput = () => {
    const onFocusEffect = useCallback(() => {
        AvoidSoftInput.setShouldMimicIOSBehavior(true)

        return () => {
            AvoidSoftInput.setShouldMimicIOSBehavior(false)
        }
    }, [])

    useFocusEffect(onFocusEffect)
}
