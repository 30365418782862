import { useAtom } from 'jotai'
import { FeatureFlag } from 'lib/types'
import { createAtom } from './atomFactory'
import { MMKVKey } from '../mmkv'

// This is mock data for the feature flag. Will be replaced with the actual data from the backend.
const initialFeatureFlag: FeatureFlag = {
    paymentBySessionFlow: true
}

const { atom, resetAtom } = createAtom<FeatureFlag>(initialFeatureFlag, MMKVKey.FeatureFlag)

export const useFeatureFlagAtom = () => useAtom(atom)
export const useResetFeatureFlagAtom = () => useAtom(resetAtom)
