import React from 'react'
import { ViewStyle } from 'react-native'
import { BlurMask, Canvas, mix, Rect } from '@shopify/react-native-skia'
import Animated, { SharedValue, useAnimatedStyle } from 'react-native-reanimated'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { Measurements } from 'lib/common'
import { VoidFunction } from 'lib/types'

type AnimatedShadowProps = {
    style?: ViewStyle,
    isVisible?: boolean,
    maxHeight?: number,
    animatedHeight: SharedValue<number>,
    maxWidth?: number,
    animatedWidth: SharedValue<number>,
    animatedShadowOpacity: SharedValue<number>
    top?: number,
    bottom?: number,
    left?: number,
    right?: number,
    onPress?: VoidFunction
}

export const AnimatedShadow: React.FunctionComponent<AnimatedShadowProps> = ({
    style,
    isVisible = true,
    maxHeight,
    animatedHeight,
    maxWidth,
    animatedWidth,
    animatedShadowOpacity,
    top = 10,
    bottom = 10,
    left = 10,
    right = 10,
    onPress
}) => {
    const { styles, theme } = useStyles(stylesheet)
    const animatedContainerStyle = useAnimatedStyle(() => ({
        opacity: animatedShadowOpacity.value
    }))

    return !isVisible
        ? null
        : (
            <Animated.View
                pointerEvents={(!onPress || !isVisible) ? 'none' : undefined}
                onTouchStart={() => {
                    if (onPress) {
                        onPress()
                    }
                }}
                style={[
                    {
                        ...styles.shadow,
                        width: Number(maxWidth || Measurements.WindowWidth) + left + right,
                        height: Number(maxHeight || Measurements.WindowHeight) + top + bottom,
                        left: -left,
                        top: -top,
                        display: isVisible ? undefined : 'none',
                        ...style
                    },
                    animatedContainerStyle
                ]}
            >
                <Canvas style={styles.canvas}>
                    <Rect
                        x={left}
                        y={top}
                        width={mix(animatedWidth.value, 0, 1)}
                        height={mix(animatedHeight.value, 0, 1)}
                        color={theme.colors?.mouse}
                    >
                        <BlurMask
                            blur={5}
                            style="normal"
                        />
                    </Rect>
                </Canvas>
            </Animated.View>
        )
}

const stylesheet = createStyles(() => ({
    shadow: {
        position: 'absolute',
        zIndex: 101
    },
    canvas: {
        flex: 1
    }
}))
