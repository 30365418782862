import { Icons } from 'assets'
import { APP_CONFIG } from 'lib/config'
import { useStyles } from './useStyles'

export const useSocialIcons = () => {
    const { theme } = useStyles()

    return [
        {
            url: APP_CONFIG.SOCIAL.FACEBOOK,
            renderIcon: () => (
                <Icons.FacebookIcon
                    size={20}
                    forceColor={theme.colors.orange}
                />
            )
        },
        {
            url: APP_CONFIG.SOCIAL.INSTAGRAM,
            renderIcon: () => (
                <Icons.InstagramIcon
                    size={20}
                    forceColor={theme.colors.orange}
                />
            )
        },
        {
            url: APP_CONFIG.SOCIAL.TWITTER,
            renderIcon: () => (
                <Icons.TwitterIcon
                    size={20}
                    forceColor={theme.colors.orange}
                />
            )
        }
    ]
}
