import { useField } from '@codegateinc/react-form-builder-v2'
import { bookingHelpers, dateHelpers } from 'lib/utils'
import { useMockedHours, useTranslations } from 'lib/hooks'
import { BookingFrequency, FrequencyOption, Nullable, SupplyID, ServicesPlan } from 'lib/types'
import { getServiceStaticConfig } from 'features/bookings/utils'
import { CleaningFields } from './forms'
import { useBookingDays } from '../hooks'
import { ServicePlanOption } from '../types'

export const useCleaningForm = (frequencyOptions: Array<FrequencyOption>) => {
    const T = useTranslations()
    const bookingDaysInitialValue = useBookingDays()
    const [initialHours] = useMockedHours(2.5, 8)
    const config = getServiceStaticConfig(SupplyID.Cleaner)

    // TODO Not supported by API
    // const bedrooms = useField<string>({
    //     key: CleaningFields.Bedrooms,
    //     initialValue: '',
    //     isRequired: true,
    //     validateOnBlur: true,
    //     label: T.screens.cleaning.formFields.bedrooms.label,
    //     validationRules: [
    //         {
    //             errorMessage: T.screens.cleaning.formFields.bedrooms.validation.isRequired,
    //             validate: value => Number(value) > 0
    //         }
    //     ]
    // })

    // const bathrooms = useField<string>({
    //     key: CleaningFields.Bathrooms,
    //     initialValue: '',
    //     isRequired: true,
    //     validateOnBlur: true,
    //     label: T.screens.cleaning.formFields.bathrooms.label,
    //     validationRules: [
    //         {
    //             errorMessage: T.screens.cleaning.formFields.bathrooms.validation.isRequired,
    //             validate: value => Number(value) > 0
    //         }
    //     ]
    // })

    const subscriptionPlan = config?.pricing?.servicePlan?.options?.find(option => option.frequency === ServicesPlan.Recurring)
    const subscriptionPlanTitle = config?.pricing?.servicePlan?.title
    const weeklyFrequency = frequencyOptions?.find(option => option?.value === BookingFrequency.Weekly)

    const propertyType = useField<string>({
        key: CleaningFields.PropertyType,
        initialValue: '',
        isRequired: true,
        validateOnBlur: true,
        label: T.screens.cleaning.formFields.propertyType.label,
        placeholder: T.screens.cleaning.formFields.propertyType.placeholder,
        validationRules: [
            {
                errorMessage: T.screens.cleaning.formFields.propertyType.validation.isRequired,
                validate: Boolean
            }
        ]
    })

    const hours = useField<string>({
        key: CleaningFields.Hours,
        initialValue: initialHours.value,
        isRequired: true,
        validateOnBlur: true,
        label: T.screens.cleaning.formFields.hours.label,
        placeholder: `${T.screens.cleaning.formFields.hours.placeholderPartOne} ${initialHours.label} ${T.screens.cleaning.formFields.hours.placeholderPartTwo}`,
        validationRules: [
            {
                errorMessage: T.screens.cleaning.formFields.hours.validation.isRequired,
                validate: Boolean
            }
        ]
    })

    const frequency = useField<Nullable<FrequencyOption>>({
        key: CleaningFields.Frequency,
        initialValue: weeklyFrequency as FrequencyOption,
        isRequired: true,
        validateOnBlur: true,
        label: T.screens.cleaning.formFields.frequency.label,
        validationRules: [
            {
                errorMessage: T.screens.cleaning.formFields.hours.validation.isRequired,
                validate: Boolean
            }
        ]
    })

    const servicePlan = useField<Nullable<ServicePlanOption>>({
        key: CleaningFields.ServicePlan,
        initialValue: subscriptionPlan,
        isRequired: true,
        validateOnBlur: true,
        label: subscriptionPlanTitle,
        validationRules: [
            {
                errorMessage: T.screens.cleaning.formFields.hours.validation.isRequired,
                validate: Boolean
            }
        ]
    })

    const startingDate = useField<string>({
        key: CleaningFields.StartingDate,
        initialValue: '',
        isRequired: true,
        validateOnBlur: false,
        label: T.screens.cleaning.formFields.startingDate.label,
        placeholder: T.screens.cleaning.formFields.startingDate.placeholder,
        validationRules: [
            {
                errorMessage: T.screens.cleaning.formFields.startingDate.validation.isRequired,
                validate: Boolean
            }
        ]
    })

    const bookingTime = useField<string>({
        key: CleaningFields.BookingTime,
        initialValue: '',
        validateOnBlur: true,
        isRequired: frequency.value?.value === BookingFrequency.OneTime,
        placeholder: T.screens.cleaning.formFields.selectTime.placeHolder,
        validationRules: [
            {
                errorMessage: T.screens.cleaning.formFields.selectTime.validation.isRequired,
                validate: value => {
                    if (frequency.value?.value !== BookingFrequency.OneTime) {
                        return true
                    }

                    return Boolean(value)
                }
            },
            {
                errorMessage: `${config.min_notice_hours} ${T.screens.cleaning.formFields.selectTime.validation.minimumHourNotice}`,
                validate: value => {
                    if (frequency.value?.value !== BookingFrequency.OneTime) {
                        return true
                    }

                    const isPast = dateHelpers.isDatePast(dateHelpers.subHoursFromDate(value, config.min_notice_hours))

                    return !isPast
                }
            }
        ]
    })

    const bookingDays = useField<Record<string, Nullable<string>>>({
        key: CleaningFields.BookingDays,
        initialValue: bookingDaysInitialValue,
        validateOnBlur: true,
        isRequired: frequency.value?.value !== BookingFrequency.OneTime,
        placeholder: T.screens.cleaning.formFields.selectTime.placeHolder,
        validationRules: [
            {
                errorMessage: T.screens.cleaning.formFields.selectTime.validation.isRequired,
                validate: value => {
                    if (frequency.value?.value === BookingFrequency.OneTime) {
                        return true
                    }

                    return Object.values(value).some(Boolean)
                }
            },
            {
                errorMessage: `${config.min_notice_hours} ${T.screens.cleaning.formFields.selectTime.validation.minimumHourNotice}`,
                validate: () => {
                    const shiftedDays = bookingHelpers.shiftBookingDays(bookingDays.value, config.min_notice_hours, startingDate.value)
                    const times = Object.values(shiftedDays).filter(item => Boolean(item)) as Array<string>
                    const isSomePast = times.some(time => dateHelpers.isDatePast(dateHelpers.subHoursFromDate(time, config.min_notice_hours)))

                    return !isSomePast
                }
            }
        ]
    })

    return {
        // bedrooms,
        // bathrooms,
        servicePlan,
        propertyType,
        hours,
        frequency,
        startingDate,
        bookingTime,
        bookingDays
    }
}
