import { useField } from '@codegateinc/react-form-builder-v2'
import { useTranslations } from 'lib/hooks'
import { HandymanAddonsFields } from './forms'

export const useHandymanAddonsForm = () => {
    const T = useTranslations()

    const promoCode = useField<string>({
        key: HandymanAddonsFields.PromoCode,
        initialValue: '',
        isRequired: false,
        placeholder: T.screens.cleaningSummary.formFields.promoCode.placeholder
    })

    return {
        promoCode
    }
}
