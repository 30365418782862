import React from 'react'
import { View } from 'react-native'
import { createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { linkingHelpers } from 'lib/utils'
import { Touchable } from './Touchable'
import { Error } from './typography'

type ReferralWarningProps = {
    withMargin?: boolean
}

export const ReferralWarning: React.FunctionComponent<ReferralWarningProps> = ({ withMargin = true }) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)

    return (
        <View
            style={withMargin
                ? styles.warningMessage
                : styles.warningContainer
            }
        >
            <Error forceColor={theme.colors.green}>
                {`${T.screens.referFriend.bookingMessage} `}
                <Touchable onPress={() => linkingHelpers.openReferralTermsAndConditions()}>
                    <Error
                        style={styles.viewTerms}
                        forceColor={theme.colors.green}
                    >
                        {T.screens.referFriend.footerPartTwo}
                    </Error>
                </Touchable>
            </Error>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    warningMessage: {
        marginTop: theme.utils.gap(1)
    },
    warningContainer: {
        borderRadius: 8,
        justifyContent: 'center',
        textAlign: 'center',
        paddingHorizontal: theme.utils.gap(1),
        paddingVertical: theme.utils.gap(1),
        backgroundColor: theme.colors.mint
    },
    viewTerms: {
        textDecorationLine: 'underline'
    }
}))
