import React from 'react'
import { View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { BottomTabBarProps } from '@react-navigation/bottom-tabs'
import { isAndroid } from 'lib/common'
import { Grid, Typography } from 'lib/components'
import { useStyles, useBottomBarRoutes, useKeyboardVisible } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { IconProps } from 'lib/types'
import { Touchable } from './Touchable'
import { isIOS } from '../common'

export const BottomBar: React.FunctionComponent<BottomTabBarProps> = ({ state, navigation }) => {
    const { styles, theme } = useStyles(stylesheet)
    const routes = useBottomBarRoutes(state)
    const { isKeyboardVisible } = useKeyboardVisible()
    const { bottom } = useSafeAreaInsets()

    return (
        <View style={styles.container(isKeyboardVisible)}>
            {routes.map((link, index) => {
                const isActive = state.index === index
                const Icon = React.createElement<IconProps>(link.icon, {
                    size: 20,
                    forceColor: isActive
                        ? theme.colors?.night
                        : theme.colors?.orange
                })

                return (
                    <Touchable
                        style={styles.link}
                        key={index}
                        onPress={() => navigation.navigate(link.name)}
                    >
                        <View
                            style={{
                                ...styles.linkContainer(bottom),
                                backgroundColor: isActive
                                    ? theme.colors?.sun
                                    : theme.colors?.white
                            }}
                        >
                            {Icon}
                            <Grid.Gap gapBottom={1}/>
                            <Typography.Label
                                style={styles.labelText}
                                forceColor={isActive
                                    ? theme.colors?.night
                                    : theme.colors?.orange
                                }
                            >
                                {link.text}
                            </Typography.Label>
                        </View>
                    </Touchable>
                )
            })}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: (isKeyboardVisible: boolean) => ({
        display: isKeyboardVisible && isAndroid
            ? 'none'
            : 'flex' ,
        width: '100%',
        flexDirection: 'row',
        backgroundColor: theme.colors.white,
        borderTopWidth: 1,
        borderColor: theme.colors.silver
    }),
    link: {
        flex: 1
    },
    linkContainer: (bottomInset: number) => ({
        paddingTop: theme.utils.gap(2),
        paddingBottom: isIOS
            ? Math.max(bottomInset, theme.utils.gap(2))
            : theme.utils.gap(2) + bottomInset,
        alignItems: 'center'
    }),
    labelText: {
        fontSize: 10
    }
}))
