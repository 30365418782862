import { useTranslations } from 'lib/hooks'
import { LaundryService } from '../types'

export const useLaundryServiceOptions = () => {
    const T = useTranslations()

    return [
        {
            label: T.screens.laundry.formFields.washAndIron.label,
            value: LaundryService.WashAndIron
        },
        {
            label: T.screens.laundry.formFields.dryCleanAndIron.label,
            value: LaundryService.DryCleanAndIron
        },
        {
            label: T.screens.laundry.formFields.ironOnly.label,
            value: LaundryService.IronOnly
        },
        {
            label: T.screens.laundry.formFields.loadWash.label,
            value: LaundryService.LoadWash
        },
        {
            label: T.screens.laundry.formFields.curtainsWash.label,
            value: LaundryService.CurtainsWash
        }
    ]
}
