import React from 'react'
import { View } from 'react-native'
import { createStyles } from 'lib/styles'
import { Typography } from 'lib/components'
import { useStyles, useTranslations } from 'lib/hooks'
import { BookingFrequency, FrequencyOption, Nullable } from 'lib/types'

type CleaningBookingTimeFrequencyLabelProps = {
    frequency: FrequencyOption,
    days: Record<string, Nullable<string>>
}

export const CleaningBookingTimeFrequencyLabel: React.FunctionComponent<CleaningBookingTimeFrequencyLabelProps> = ({
    frequency,
    days
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const weekDays = Object.keys(days).filter(key => Boolean(days[key]))

    if (weekDays.length === 0) {
        return null
    }

    return (
        <View style={styles.wrapper}>
            <Typography.Error forceColor={theme.colors.darkGrey}>
                {`${T.components.selectedFrequency.title} `}
            </Typography.Error>
            <Typography.Error bold>
                {frequency.value === BookingFrequency.Weekly
                    ? T.components.selectedFrequency.weekly.toLowerCase()
                    : T.components.selectedFrequency.fortnightly.toLowerCase()
                }
            </Typography.Error>
            {weekDays.map((day, index) => {
                const isLast = index === weekDays.length - 1 && weekDays.length > 1
                const isBeforeLast = index === weekDays.length - 2 || weekDays.length === 1

                return (
                    <React.Fragment key={day}>
                        {isLast && (
                            <Typography.Error forceColor={theme.colors.darkGrey}>
                                {` &`}
                            </Typography.Error>
                        )}
                        <Typography.Error bold>
                            {` ${day}${isLast || isBeforeLast ? '' : ','}`}
                        </Typography.Error>
                    </React.Fragment>
                )
            })}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        flexWrap: 'wrap',
        flexDirection: 'row',
        marginTop: theme.utils.gap(1)
    }
}))
