import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const User: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path d="M40 40c11.05 0 20-8.95 20-20S51.05 0 40 0 20 8.95 20 20s8.95 20 20 20Zm0-30c5.5 0 10 4.5 10 10s-4.5 10-10 10-10-4.5-10-10 4.5-10 10-10Zm0 35C26.65 45 0 51.7 0 65v10c0 2.75 2.25 5 5 5h70c2.75 0 5-2.25 5-5V65c0-13.3-26.65-20-40-20Zm30 25H10v-4.95C11 61.45 26.5 55 40 55s29 6.45 30 10v5Z" />
    </Icon>
)
