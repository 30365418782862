import { Area } from 'lib/models'

// TODO In current version of API this is only hardcoded on frontend. In future this might change.
export const AREAS: Array<Area> = [
    {
        _id: 0,
        area: '500 - 700 sqft',
        title: 'Deep Cleaning',
        cost: 320
    },
    {
        _id: 1,
        area: '700 - 800 sqft',
        title: 'Deep Cleaning',
        cost: 350
    },
    {
        _id: 2,
        area: '800 - 1000 sqft',
        title: 'Deep Cleaning',
        cost: 380
    },
    {
        _id: 3,
        area: '1000 - 1200 sqft',
        title: 'Deep Cleaning',
        cost: 410
    },
    {
        _id: 4,
        area: '1200 - 1400 sqft',
        title: 'Deep Cleaning',
        cost: 450
    },
    {
        _id: 5,
        area: '1400 - 1600 sqft',
        title: 'Deep Cleaning',
        cost: 510
    },
    {
        _id: 6,
        area: '1600 - 1800 sqft',
        title: 'Deep Cleaning',
        cost: 600
    },
    {
        _id: 7,
        area: '1800 - 2000 sqft',
        title: 'Deep Cleaning',
        cost: 710
    }
]
