import React from 'react'
import { ImageBackground, View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scrollview'
import { Images } from 'assets'
import { Children } from 'lib/types'
import { isNative, isWeb } from 'lib/common'
import { useIsWithinBreakpoints, useStyles } from 'lib/hooks'
import { BackButton, Grid, Header } from 'lib/components'
import { Breakpoint, breakpoints, createStyles } from 'lib/styles'
import { WelcomeMessage } from './WelcomeMessage'

type AuthLayoutProps = {
    title?: string,
    message?: string,
    children: Children,
    centerVertical?: boolean,
    scrollEnabled?: boolean,
    renderSubHeader?(): React.ReactElement
}

export const AuthLayout: React.FunctionComponent<AuthLayoutProps> = ({
    title,
    message,
    children,
    centerVertical = false,
    scrollEnabled = true,
    renderSubHeader
}) => {
    const navigation = useNavigation()
    const insets = useSafeAreaInsets()
    const { styles, theme } = useStyles(stylesheet)
    const isMobile = useIsWithinBreakpoints(Breakpoint.XS, Breakpoint.MD)
    const hasTexts = title && message

    return (
        <Grid.Background>
            {isNative ? (
                <View
                    style={{
                        ...styles.backButtonContainer,
                        marginTop: insets.top + theme.utils.gap(1)
                    }}
                >
                    <BackButton onPress={() => navigation.goBack()} />
                </View>
            ) : (
                <Header disableUserMenu />
            )}
            {renderSubHeader && renderSubHeader()}
            <View style={styles.container}>
                {hasTexts && isWeb && (
                    <ImageBackground
                        style={styles.leftColumn}
                        imageStyle={styles.leftColumnImage}
                        source={!isMobile ? Images.AuthBackground : []}
                    >
                        <WelcomeMessage
                            title={title}
                            message={message}
                        />
                    </ImageBackground>
                )}
                <View style={styles.rightColumn}>
                    <KeyboardAwareScrollView
                        scrollEnabled={scrollEnabled}
                        extraScrollHeight={20}
                        showsVerticalScrollIndicator={false}
                        contentContainerStyle={centerVertical
                            ? {
                                ...styles.scrollContainerStyle,
                                ...styles.centerVertical
                            }
                            : styles.scrollContainerStyle
                        }
                    >
                        {hasTexts && isNative && (
                            <Grid.Gap gapTop={2}>
                                <WelcomeMessage
                                    title={title}
                                    message={message}
                                />
                            </Grid.Gap>
                        )}
                        {children}
                    </KeyboardAwareScrollView>
                </View>
            </View>
        </Grid.Background>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        flex: 1,
        flexDirection: {
            lg: 'row',
            xs: 'column'
        },
        justifyContent: 'center',
        alignItems: {
            lg: 'center'
        }
    },
    leftColumn: {
        flex: {
            xl: 3,
            lg: 1,
            xs: 1
        },
        paddingTop: {
            lg: theme.utils.gap(20),
            xs: 0
        },
        minHeight: 100,
        height: '100%',
        paddingHorizontal: {
            lg: theme.utils.gap(9),
            xs: theme.utils.gap(3)
        },
        justifyContent: {
            lg: 'flex-start',
            xs: 'center'
        },
        backgroundColor: theme.colors.white
    },
    leftColumnImage: {
        borderBottomRightRadius: 150
    },
    rightColumn: {
        flex: {
            xl: 2,
            lg: 1,
            xs: 4
        },
        height: '100%',
        justifyContent: {
            lg: 'center',
            xs: 'flex-start'
        },
        maxWidth: breakpoints[Breakpoint.LG],
        backgroundColor: theme.colors.white,
        paddingHorizontal: {
            xl: theme.utils.gap(10),
            lg: theme.utils.gap(5),
            xs: theme.utils.gap(2)
        }
    },
    scrollContainerStyle: {
        paddingTop: {
            lg: theme.utils.gap(8),
            xs: 0
        }
    },
    backButtonContainer: {
        paddingHorizontal: theme.utils.gap(2)
    },
    centerVertical: {
        flex: 1,
        justifyContent: {
            lg: 'center',
            xs: 'flex-start'
        },
        paddingTop: 0
    }
}))
