// TODO Add Segment and Google Analytics for movers & packers
import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import { useForm } from '@codegateinc/react-form-builder-v2'
import { isNative } from 'lib/common'
import { Nullable, SupplyID } from 'lib/types'
import { Breakpoint, createStyles } from 'lib/styles'
import { useBookingDateCustomValueFormat, useInitializeAddress, useIsWithinBreakpoints, useSegmentSession, useStyles, useTranslations } from 'lib/hooks'
import { NavigationParams, NavigationProps, ScreenNames } from 'lib/routing'
import { Segment, ServiceType } from 'lib/analytics'
import { Adapter, Button, FormComponents, Grid, MediaQuery, Sticky, Typography } from 'lib/components'
import { bookingHelpers } from 'lib/utils'
import { useAddressAtom, useMPDropoffLocationAtom, useMPPickupLocationAtom } from 'lib/atoms'
import { getServiceStaticConfig } from 'features/bookings/utils'
import { Address } from 'lib/models'
import {
    BookingCancellationFees,
    BookingDateModal,
    MobileSummary,
    MoverAndPackersPackagePricing,
    MoversAndPackersComponents,
    Rated,
    ServiceDescription,
    ServiceLayout
} from '../components'
import { MoversAndPackersFields, MoversAndPackersFormShape, useMoversAndPackersForm } from '../forms'
import { moversAndPackersHooks, useJobQuotation, useResetFormCallback } from '../hooks'

type MoversAndPackersScreenProps = {
    route: NavigationParams<ScreenNames.MoversAndPackers>
    navigation: NavigationProps<ScreenNames.MoversAndPackers>
}

export const MoversAndPackersScreen: React.FunctionComponent<MoversAndPackersScreenProps> = ({
    navigation,
    route
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const { segmentSession } = useSegmentSession()
    const [address] = useAddressAtom()
    const [ , setMPDropoffLocation] = useMPDropoffLocationAtom()
    const [ , setMPPickupLocation] = useMPPickupLocationAtom()
    const [formErrors, setFormErrors] = useState<Nullable<Record<keyof MoversAndPackersFormShape, string>>>(null)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isHelpModalOpen, setHelpModalOpen] = useState(false)
    const breadcrumbs = moversAndPackersHooks.useMoversAndPackersBreadcrumbs()
    const isMobile = useIsWithinBreakpoints(Breakpoint.XS, Breakpoint.MD)
    const { jobQuotation, requestJobQuotation } = useJobQuotation(SupplyID.MoversPackers)
    const formReturn = useForm<MoversAndPackersFormShape>(useMoversAndPackersForm(), {
        onSuccess: form => {
            const shiftedDays = bookingHelpers.shiftBookingDays(form.bookingDays, config.min_notice_hours, form.startingDate)
            const { pickUpLocation, dropOffLocation, ...formStripInfo } = form
            setMPDropoffLocation(dropOffLocation as Address)
            setMPPickupLocation(pickUpLocation as Address)
            navigation.navigate(ScreenNames.MoversAndPackersSummary, {
                form: {
                    ...formStripInfo,
                    pickUpLocation: null,
                    dropOffLocation: null,
                    bookingDays: shiftedDays
                },
                price: totalCost,
                postalcode: route.params?.postalcode,
                supplyId: SupplyID.MoversPackers
            })

            setFormErrors(null)
        },
        onError: setFormErrors
    })
    const { form, isFilled, hasError, submit, resetForm, setFieldValue } = formReturn
    const dateCustomValueFormat = useBookingDateCustomValueFormat(form.startingDate.value, form.bookingTime.value, form.bookingDays.value)
    const config = getServiceStaticConfig(SupplyID.MoversPackers)
    const columnWidth = isNative || isMobile
        ? undefined
        : 'calc(55% - 10px)'
    const continueDisabled = !form.bookingTime.value || !isFilled || hasError
    const formSummaryValue = Object.keys(form).reduce((acc, nextValue) => ({
        ...acc,
        [nextValue]: form[nextValue].value
    }), {} as MoversAndPackersFormShape)

    // TODO Calc this when ready
    const totalCost = 0

    useResetFormCallback(resetForm)
    useInitializeAddress(true)
    moversAndPackersHooks.useResetMoversAndPackersForm(formReturn)

    useEffect(() => {
        setFieldValue(MoversAndPackersFields.BookingTime, '')
    }, [form.startingDate.value])

    useEffect(() => {
        if (segmentSession.id) {
            Segment.bookingStarted({
                bookingSessionId: segmentSession.id,
                postcode: route.params?.postalcode as string,
                serviceType: ServiceType.MoversAndPackers
            })
        }
    }, [segmentSession])

    useEffect(() => {
        address._id
            ? setFieldValue(MoversAndPackersFields.PickUpLocation, address)
            : resetForm()
    }, [address._id])

    useEffect(() => {
        requestJobQuotation(null)
    }, [])

    return (
        <React.Fragment>
            <ServiceLayout
                onInfoPress={() => setHelpModalOpen(true)}
                title={T.screens.moversAndPackers.title}
                withBackground
                breadcrumbs={breadcrumbs}
                bottomSpacerHeight={
                    isMobile
                        ? 0
                        : 50
                }
                contentColumn={(
                    <View>
                        <MediaQuery.Hidden from={Breakpoint.LG}>
                            <View style={styles.mobileDescriptionContainer}>
                                <ServiceDescription
                                    isHelpModalOpen={isHelpModalOpen}
                                    setHelpModalOpen={setHelpModalOpen}
                                    title={T.screens.moversAndPackers.title}
                                    modalContent={(
                                        <MoversAndPackersComponents.MoversAndPackersHelpModalContent />
                                    )}
                                    description={T.screens.moversAndPackers.serviceDescription}
                                />
                            </View>
                        </MediaQuery.Hidden>
                        <BookingDateModal
                            title={T.screens.moversAndPackers.calendarModal.oneTime.title}
                            description={T.screens.moversAndPackers.calendarModal.oneTime.description}
                            timeTitle={T.screens.moversAndPackers.calendarModal.oneTime.select.title}
                            minHoursNotice={config.min_notice_hours}
                            onContinue={() => setIsModalOpen(false)}
                            isModalOpen={isModalOpen}
                            frequency={form.frequency.value}
                            startingDateField={form.startingDate}
                            bookingTimeField={form.bookingTime}
                            bookingDaysField={form.bookingDays}
                            onClose={() => setIsModalOpen(false)}
                        />
                        <FormComponents.FormRow>
                            <View
                                style={{
                                    width: columnWidth
                                }}
                            >
                                <Adapter.DateAndTime
                                    {...form.startingDate}
                                    customValueFormat={dateCustomValueFormat}
                                    onPress={() => setIsModalOpen(true)}
                                />
                            </View>
                        </FormComponents.FormRow>
                        <Adapter.OptionsRadio
                            {...form.serviceType}
                            options={config.service_type.map(value => ({
                                label: value,
                                value
                            }))}
                            onChangeValue={value => {
                                Segment.bookingMovingTypeSelected({
                                    movingType: value
                                })

                                form.serviceType.onChangeValue(value)
                            }}
                        />
                        <MoverAndPackersPackagePricing />
                        {Boolean(form.serviceType?.value) && (
                            <React.Fragment>
                                <Grid.Gap
                                    style={styles.upperForm}
                                    gapTop={4}
                                    gapBottom={4}
                                >
                                    <MoversAndPackersComponents.MoversAndPackersRequirementsProperty
                                        formErrors={formErrors}
                                        formReturn={formReturn}
                                    />
                                </Grid.Gap>
                                <MoversAndPackersComponents.MoversAndPackersRequirementsProperty
                                    formErrors={formErrors}
                                    formReturn={formReturn}
                                    isDropOffProperty
                                />
                                <Typography.Label forceColor={theme.colors?.mouse}>
                                    {`*${T.screens.moversAndPackers.stairsInfo}`}
                                </Typography.Label>
                                <Grid.Gap gapBottom={4} />
                            </React.Fragment>
                        )}
                    </View>
                )}
                summaryColumn={(
                    <MediaQuery.Visible from={Breakpoint.LG}>
                        <ServiceDescription
                            isHelpModalOpen={isHelpModalOpen}
                            setHelpModalOpen={setHelpModalOpen}
                            title={T.screens.moversAndPackers.title}
                            modalContent={(
                                <MoversAndPackersComponents.MoversAndPackersHelpModalContent />
                            )}
                            description={T.screens.moversAndPackers.serviceDescription}
                        />
                        <Sticky>
                            <MoversAndPackersComponents.MoversAndPackersSummaryColumn
                                renderPriceComponent={() => (
                                    <MoversAndPackersComponents.MoversAndPackersPriceNote price={totalCost} />
                                )}
                                form={formSummaryValue}
                                jobQuotation={jobQuotation}
                            />
                            <Button
                                onPress={submit}
                                disabled={continueDisabled}
                                text={T.common.continue}
                            />
                            <BookingCancellationFees />
                        </Sticky>
                    </MediaQuery.Visible>
                )}
                footer={(
                    <MediaQuery.Hidden from={Breakpoint.LG}>
                        <MobileSummary
                            submit={submit}
                            price={totalCost}
                            disabled={continueDisabled}
                            isLoading={false}
                            content={(
                                <React.Fragment>
                                    <MoversAndPackersComponents.MoversAndPackersSummaryColumn
                                        renderPriceComponent={() => (
                                            <MoversAndPackersComponents.MoversAndPackersPriceNote price={totalCost} />
                                        )}
                                        form={formSummaryValue}
                                        jobQuotation={jobQuotation}
                                    />
                                    <Rated />
                                    <BookingCancellationFees />
                                </React.Fragment>
                            )}
                        />
                    </MediaQuery.Hidden>
                )}
            />
        </React.Fragment>
    )
}

const stylesheet = createStyles(theme => ({
    spacer: {
        width: theme.utils.gap(1)
    },
    mobileDescriptionContainer: {
        backgroundColor: theme.colors.marble,
        marginHorizontal: -theme.utils.gap(2),
        marginBottom: theme.utils.gap(2),
        paddingHorizontal: theme.utils.gap(2),
        paddingTop: theme.utils.gap(4)
    },
    resetMargin: {
        marginTop: 0
    },
    upperForm: {
        zIndex: theme.zIndex[10]
    }
}))
