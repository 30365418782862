import React, { ReactNode } from 'react'
import { View, ViewStyle } from 'react-native'
import { Field } from '@codegateinc/react-form-builder-v2'
import { createStyles } from 'lib/styles'
import { Grid, Touchable, Typography } from 'lib/components'
import { useStyles, useTranslations } from 'lib/hooks'

interface RadioProps extends Field<boolean> {
    disabled?: boolean,
    trueLabel?: string,
    falseLabel?: string,
    customLabel?: ReactNode,
    customTrueLabel?: ReactNode,
    customFalseLabel?: ReactNode,
    optionCustomStyles?: ViewStyle,
    containerCustomStyles?: ViewStyle,
    activeRadioCustomStyles?: ViewStyle,
    inactiveRadioCustomStyles?: ViewStyle,
    customActiveButton?: ReactNode,
    customInactiveButton?: ReactNode
}

export const Radio: React.FunctionComponent<RadioProps> = ({
    onChangeValue,
    value,
    falseLabel,
    trueLabel,
    label,
    customLabel,
    customTrueLabel,
    customFalseLabel,
    disabled,
    optionCustomStyles = {},
    containerCustomStyles = {},
    activeRadioCustomStyles = {},
    inactiveRadioCustomStyles = {},
    customActiveButton,
    customInactiveButton
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)

    const shouldRenderDefaultButton = typeof value !== 'boolean'

    const renderDefaultButton = () => customInactiveButton ?
        customInactiveButton : (
            <View style={{
                ...styles.radio,
                ...inactiveRadioCustomStyles
            }} />
        )

    const renderTrueButton = () => customActiveButton ?
        (value ? customActiveButton : customInactiveButton) :  (
            <View style={{
                ...styles.radio,
                ...(value ? activeRadioCustomStyles : inactiveRadioCustomStyles)
            }}>
                {value && <View style={styles.radioSelected} />}
            </View>
        )

    const renderFalseButton = () => customInactiveButton ?
        (value ? customInactiveButton : customActiveButton) :  (
            <View style={{
                ...styles.radio,
                ...(value ? inactiveRadioCustomStyles : activeRadioCustomStyles)
            }}>
                {!value && <View style={styles.radioSelected} />}
            </View>
        )

    const renderTrueLabel = () => customTrueLabel ? customTrueLabel : (
        <Typography.Label>
            {trueLabel || T.common.yes}
        </Typography.Label>
    )

    const renderFalseLabel = () => customFalseLabel ? customFalseLabel : (
        <Typography.Label>
            {falseLabel || T.common.no}
        </Typography.Label>
    )

    return (
        <View style={{
            ...styles.wrapper,
            ...containerCustomStyles
        }}>
            {
                customLabel ? customLabel : (
                    <Typography.Regular
                        bold
                        style={styles.label}
                    >
                        {label}
                    </Typography.Regular>
                )
            }

            <View>
                <Touchable
                    onPress={() => onChangeValue(true)}
                    disabled={disabled}
                >
                    <View style={{ ...styles.row, ...optionCustomStyles }}>
                        {shouldRenderDefaultButton ? renderDefaultButton() : renderTrueButton()}
                        {renderTrueLabel()}
                    </View>
                </Touchable>
                <Grid.Gap gapRight={4} />
                <Touchable
                    onPress={() => onChangeValue(false)}
                    disabled={disabled}
                >
                    <View style={{ ...styles.row, ...optionCustomStyles }}>
                        {shouldRenderDefaultButton ? renderDefaultButton() : renderFalseButton()}
                        {renderFalseLabel()}
                    </View>
                </Touchable>
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        marginVertical: theme.utils.gap(4)
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: theme.utils.gap(1)
    },
    label: {
        marginBottom: theme.utils.gap(1)
    },
    radio: {
        width: 20,
        height: 20,
        borderRadius: 10,
        borderWidth: 2,
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: theme.utils.gap(1)
    },
    radioSelected: {
        width: 10,
        height: 10,
        borderRadius: 5,
        backgroundColor: theme.colors.sun
    }
}))
