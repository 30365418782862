import React from 'react'
import Animated, {
    cancelAnimation,
    runOnJS,
    useAnimatedStyle,
    useSharedValue,
    withDelay,
    withTiming
} from 'react-native-reanimated'
import {
    gestureHandlerRootHOC,
    GestureDetector,
    Gesture,
    GestureType
} from 'react-native-gesture-handler'
import { KeyboardAvoidingView, Modal, View, ViewStyle } from 'react-native'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { Children, VoidFunction } from '../types'
import { Measurements } from '../common'
import { Touchable } from './Touchable'

type ChildrenProps = {
    onModalClose: (didClose?: VoidFunction) => void
}

type AnimatedModalProps = {
    isVisible: boolean,
    onClose: VoidFunction,
    draggable?: boolean,
    backdropHeight: number,
    enabledKeyboardAvoidingView?: boolean,
    disabled?: boolean,
    children(props: ChildrenProps): React.ReactElement
}

interface ModalContentComponentProps extends JSX.IntrinsicAttributes {
    draggable: boolean,
    dragDown: GestureType,
    handlerContainerStyle: ViewStyle,
    handlerStyles: ViewStyle,
    contentContainerStyles: ViewStyle,
    children: Children
}

const ModalContent = gestureHandlerRootHOC<ModalContentComponentProps>(({
    children,
    draggable,
    handlerStyles,
    handlerContainerStyle,
    dragDown,
    contentContainerStyles
}) => (
    <View style={contentContainerStyles}>
        {draggable
            ? (
                <GestureDetector gesture={dragDown}>
                    <View style={handlerContainerStyle}>
                        <View style={handlerStyles} />
                    </View>
                </GestureDetector>
            ) : (
                <View style={handlerContainerStyle} />
            )}
        {children}
    </View>
))

export const AnimatedModal: React.FunctionComponent<AnimatedModalProps> = ({
    isVisible,
    onClose,
    disabled = false,
    children,
    backdropHeight,
    enabledKeyboardAvoidingView = true,
    draggable = false
}) => {
    const { styles } = useStyles(stylesheet)
    const MODAL_HIDE_THRESHOLD = Measurements.WindowHeight / 4
    const animatedBackgroundOpacity = useSharedValue(0)
    const animatedBackgroundTranslateY = useSharedValue<number>(Measurements.WindowHeight)
    const animatedBackgroundStyles = useAnimatedStyle(() => ({
        opacity: animatedBackgroundOpacity.value
    }))
    const animatedModalBodyStyles = useAnimatedStyle(() => ({
        transform: [
            {
                translateY: animatedBackgroundTranslateY.value
            }
        ]
    }))
    const onModalClose = (didClose?: VoidFunction) => {
        'worklet'
        animatedBackgroundOpacity.value = withTiming(0)
        animatedBackgroundTranslateY.value = withTiming(Measurements.WindowHeight, undefined, isFinished => {
            if (isFinished) {
                runOnJS(onClose)()
                didClose && runOnJS(didClose)()
            }
        })
    }

    const dragDown = Gesture.Pan()
        .onBegin(() => {
            cancelAnimation(animatedBackgroundTranslateY)
        })
        .onChange(({ translationY }) => {
            animatedBackgroundTranslateY.value = animatedBackgroundTranslateY.value + translationY < 0
                ? animatedBackgroundTranslateY.value
                : translationY
        })
        .onFinalize(({ translationY }) => {
            if (translationY >= MODAL_HIDE_THRESHOLD) {
                return onModalClose()
            }

            animatedBackgroundTranslateY.value = withTiming(0)
        })
        .enabled(draggable && !disabled)

    return (
        <Modal
            transparent
            visible={isVisible}
            animationType="none"
            statusBarTranslucent
            onRequestClose={() => onModalClose()}
            onShow={() => {
                animatedBackgroundTranslateY.value = withDelay(250, withTiming(0))
                animatedBackgroundOpacity.value = withDelay(250, withTiming(1))
            }}
        >
            <KeyboardAvoidingView
                behavior="padding"
                style={styles.container}
                enabled={enabledKeyboardAvoidingView}
            >
                <Animated.View
                    style={[
                        animatedBackgroundStyles,
                        styles.background
                    ]}
                >
                    <Touchable
                        disabled={disabled}
                        onPress={() => onModalClose()}
                        style={{
                            height: backdropHeight - 50
                        }}
                    />
                    <Animated.View
                        style={[
                            animatedModalBodyStyles,
                            styles.container
                        ]}
                    >
                        <ModalContent
                            dragDown={dragDown}
                            draggable={draggable}
                            handlerStyles={styles.handler}
                            handlerContainerStyle={styles.handlerContainer}
                            contentContainerStyles={styles.contentContainer}
                        >
                            {children({ onModalClose })}
                        </ModalContent>
                    </Animated.View>
                </Animated.View>
            </KeyboardAvoidingView>
        </Modal>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        flex: 1
    },
    background: {
        flex: 1,
        backgroundColor: theme.components.modal.backdrop.backgroundColor
    },
    contentContainer: {
        flex: 1,
        justifyContent: 'flex-end'
    },
    handlerContainer: {
        height: 50,
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.colors.white,
        borderTopLeftRadius: theme.utils.gap(2),
        borderTopRightRadius: theme.utils.gap(2)
    },
    handler: {
        height: 6,
        borderRadius: 3,
        backgroundColor: theme.colors.marble,
        width: Measurements.WindowWidth / 3
    }
}))
