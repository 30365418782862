import React from 'react'
import { View } from 'react-native'
import { Field } from '@codegateinc/react-form-builder-v2'
import { createStyles } from 'lib/styles'
import { Typography } from 'lib/components'
import { useStyles, useTranslations } from 'lib/hooks'
import { FormComponents } from '../components'

interface RadioCheckboxProps extends Field<boolean> {
    disabled?: boolean
}

export const RadioCheckbox: React.FunctionComponent<RadioCheckboxProps> = ({
    onChangeValue,
    errorMessage,
    value,
    label,
    disabled
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)

    return (
        <View style={styles.row}>
            <FormComponents.Checkbox
                isSelected={!value}
                disabled={disabled}
                errorMessage={errorMessage}
                renderComponent={() => (
                    <Typography.Regular>
                        {T.common.no}
                    </Typography.Regular>
                )}
                onChange={() => onChangeValue(false)}
            />
            <FormComponents.Checkbox
                isSelected={value}
                disabled={disabled}
                errorMessage={errorMessage}
                renderComponent={() => (
                    <Typography.Regular>
                        {T.common.yes}
                    </Typography.Regular>
                )}
                onChange={() => onChangeValue(true)}
            />
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    row: {
        flexDirection: 'row'
    }
}))
