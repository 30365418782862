import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const WhatsApp: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path d="M40.016 0C17.95 0 .008 17.937 0 39.984a39.886 39.886 0 0 0 6.115 21.28l.952 1.514-4.042 14.755 15.14-3.97 1.462.866a39.947 39.947 0 0 0 20.359 5.575H40c22.047 0 39.99-17.94 39.999-39.988a39.738 39.738 0 0 0-11.705-28.29A39.737 39.737 0 0 0 40.015 0Z" />
        <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M27.977 19.867c-.901-2.002-1.85-2.043-2.706-2.077l-2.305-.028c-.802 0-2.105.3-3.206 1.504-1.102 1.204-4.21 4.112-4.21 10.029 0 5.916 4.31 11.632 4.91 12.436.6.803 8.319 13.33 20.542 18.15 10.157 4.006 12.224 3.21 14.43 3.01 2.205-.2 7.114-2.91 8.115-5.717 1.002-2.808 1.003-5.214.702-5.717-.3-.503-1.102-.802-2.305-1.404-1.204-.601-7.115-3.51-8.217-3.912-1.102-.401-1.903-.601-2.706.602-.802 1.204-3.104 3.911-3.806 4.714-.702.802-1.402.903-2.606.302-1.203-.601-5.076-1.871-9.67-5.968-3.574-3.187-5.987-7.123-6.69-8.326-.702-1.203-.074-1.855.529-2.454.54-.539 1.202-1.404 1.804-2.106.603-.702.8-1.203 1.201-2.005.4-.8.201-1.505-.1-2.106-.3-.6-2.637-6.548-3.706-8.926"
            fill="#fff"
        />
    </Icon>
)
