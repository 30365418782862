import React from 'react'
import { View } from 'react-native'
import { Breakpoint } from 'lib/styles'
import { useTranslations } from 'lib/hooks'
import { Field } from '@codegateinc/react-form-builder-v2'
import { Nullable } from 'lib/types'
import { PaymentType } from 'lib/models'
import { useIsAuthorizedAtom, useUserAtom } from 'lib/atoms'
import { Address, Button, MediaQuery, PolicyLinks } from 'lib/components'
import { GetPromotionResponse, JobQuotationResponse, ServiceAddonsFormShape, TaskAndErrandsFormShape } from 'features/bookings'
import { PaymentMethodTile } from '../PaymentMethodTile'
import { PromotionCodeInput } from '../PromotionCodeInput'
import { TotalPriceComponent } from '../TotalPriceComponent'
import { BookingCancellationFees } from '../BookingCancellationFees'
import { TaskAndErrandsSummaryColumn } from './TaskAndErrandsSummaryColumn'
import { PriceSummaryComponent } from '../PriceSummaryComponent'

type TaskAndErrandsSummaryColumnViewProps = {
    disabled: boolean,
    selectedPaymentMethod: PaymentType,
    setPaymentView: (value: boolean) => void,
    form: Record<keyof ServiceAddonsFormShape, Field<boolean & string>>
    formShape: TaskAndErrandsFormShape,
    isLoading: boolean,
    authenticationView: boolean,
    totalPrice: number,
    jobQuotation?: JobQuotationResponse,
    submit: VoidFunction,
    promotionPackage: Nullable<GetPromotionResponse>,
    originalPrice?: Nullable<number>,
    requestPromotion: (code: string) => void,
    onRemovePromoCode: VoidFunction
}

export const TaskAndErrandsSummaryColumnView: React.FunctionComponent<TaskAndErrandsSummaryColumnViewProps> = ({
    form,
    disabled,
    formShape,
    totalPrice,
    originalPrice,
    requestPromotion,
    setPaymentView,
    authenticationView,
    promotionPackage,
    isLoading,
    submit,
    selectedPaymentMethod,
    onRemovePromoCode,
    jobQuotation
}) => {
    const T = useTranslations()
    const [user] = useUserAtom()
    const [isAuthorized] = useIsAuthorizedAtom()
    const notEnoughCredits = selectedPaymentMethod === PaymentType.Credits && user.consumer.balance_credit < totalPrice

    return (
        <MediaQuery.Visible from={Breakpoint.LG}>
            {!(isAuthorized && authenticationView) && (
                <View>
                    <Address disabled={isLoading} />
                </View>
            )}
            <TaskAndErrandsSummaryColumn
                totalPrice={totalPrice}
                originalPrice={originalPrice}
                promotionPackage={promotionPackage}
                title={T.common.bookingSummary}
                renderPriceComponent={() => (
                    <TotalPriceComponent
                        totalPrice={jobQuotation?.total?.value || 0}
                        promotion={promotionPackage?.promotion}
                    />
                )}
                renderPriceSummaryComponent={() => jobQuotation?.priceSummary ? (
                    <PriceSummaryComponent priceSummary={jobQuotation?.priceSummary} promotion={jobQuotation.discount} />
                ) : undefined}
                form={formShape}
                couponText={promotionPackage
                    ? promotionPackage.promotion.message_to_client
                    : undefined
                }
                renderExtraContent={() => (
                    <PromotionCodeInput
                        showBillingMessage
                        promoCode={form.promoCode}
                        isLoading={isLoading}
                        promotionPackage={promotionPackage}
                        onRemovePromoCode={onRemovePromoCode}
                        requestPromotion={requestPromotion}
                    />
                )}
            />
            {!authenticationView && (
                <PaymentMethodTile
                    notEnoughCredits={notEnoughCredits}
                    onPress={() => setPaymentView(true)}
                    selectedPaymentMethod={selectedPaymentMethod}
                />
            )}
            <PolicyLinks />
            {(!authenticationView || isAuthorized) && (
                <Button
                    disabled={notEnoughCredits || disabled}
                    isLoading={isLoading}
                    onPress={submit}
                    text={T.common.confirm}
                    testID="confirm-booking-button"
                />
            )}
            <BookingCancellationFees />
        </MediaQuery.Visible>
    )
}
