import React from 'react'
import { View } from 'react-native'
import { Button, Typography } from 'lib/components'
import { useStyles, useTranslations } from 'lib/hooks'
import { JobDetails } from 'lib/models'
import { createStyles, theme } from 'lib/styles'
import { jobHelpers, R } from 'lib/utils'
import { NoticeOfReschedulingMessage } from './NoticeOfReschedulingMessage'
import { JobDetailsResponse } from '../../types'
import { ExtendSession } from '../ExtendSession'
import { CancelRequest } from '../CancelRequest'
import { RescheduleSession } from '../RescheduleSession'

type NextSessionActionsProps = {
    jobDetails?: JobDetails,
    jobDetailsResponse?: JobDetailsResponse,
}

export const NextSessionActions: React.FunctionComponent<NextSessionActionsProps> = ({ jobDetails, jobDetailsResponse }) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const isAcceptedSessionJobForSubscription = jobDetailsResponse ?
        jobHelpers.isAcceptedSessionJobForSubscription(jobDetailsResponse) : false

    const shouldDisableRescheduleSessionJob = jobDetailsResponse ?
        jobHelpers.shouldDisableRescheduleSessionJob(jobDetailsResponse) : false

    return (
        <React.Fragment>
            <View style={styles.breakRow}>
                {jobDetails ? (
                    <View style={styles.jobActionsContainer}>
                        <RescheduleSession data={{ job: jobDetails } as JobDetailsResponse} fullWidth disabled={shouldDisableRescheduleSessionJob}/>
                        <View style={styles.spacer}/>
                        <View style={styles.extendSessionContainer}>
                            <ExtendSession
                                fullWidth
                                containerStyle={styles.addHoursContainer}
                                data={{ job: jobDetails } as JobDetailsResponse}
                            />
                            <View/>
                            <View style={styles.infoContainer}>
                                <Typography.Label
                                    forceColor={theme.colors.fog}
                                    style={styles.addHoursInfo}
                                >
                                    {T.components.currentJobSession.addHoursInfo}
                                </Typography.Label>
                            </View>
                        </View>
                    </View>
                ) : (
                    <View style={styles.buttonWrapper}>
                        <Button
                            noBackground
                            isLoading
                            text={T.components.currentJobSession.addHours}
                            onPress={R.T}
                        />
                    </View>
                )}
            </View>
            {
                shouldDisableRescheduleSessionJob && <NoticeOfReschedulingMessage/>
            }
            {jobDetails && (
                <CancelRequest
                    jobStatus={jobDetails.status}
                    job={{ job: jobDetails } as JobDetailsResponse}
                    text={T.components.currentJobSession.cancel}
                    hideButton={isAcceptedSessionJobForSubscription}
                />
            )}
        </React.Fragment>
    )
}

const stylesheet = createStyles(() => ({
    spacer: {
        width: {
            xl: theme.utils.gap(2),
            lg: 0
        }
    },
    jobActionsContainer: {
        flexDirection: {
            xl: 'row',
            lg: 'column'
        },
        alignItems: 'flex-start',
        justifyContent: 'center',
        flex: 1
    },
    extendSessionContainer: {
        flexDirection: 'column',
        flex: 1,
        width: '100%'
    },
    breakRow: {
        flexDirection: {
            xl: 'row',
            lg: 'column'
        }
    },
    addHoursContainer: {
        flex: undefined,
        marginBottom: 0
    },
    buttonWrapper: {
        width: {
            ':w[1250, ]': 240,
            ':w[, 1250]': undefined
        }
    },
    infoContainer: {
        flex: 1
    },
    addHoursInfo: {
        fontSize: 12
    },
    cancelWrapper: {
        alignSelf: 'center'
    },
    cancel: {
        textDecorationLine: 'underline'
    }
}))
