export enum ServicesPlan {
    Recurring = 'recurring',
    OneTime = 'onetime'
}

export enum BookingFrequency {
    Weekly = 'weekly',
    Fortnightly = 'fortnightly',
    OneTime = 'oneTime'
}

export type FrequencyOption = {
    value: BookingFrequency,
    label: string,
    title: string,
    description: string,
    price?: number,
    discount?: number,
    list: Array<string>
}

export enum AirConditionService {
    OneTime = 'onetime',
    Contract = 'contract',
    Custom = 'custom'
}

export enum ACServiceContractType {
    quarterly = 'quarterly',
    triyearly = 'triyearly',
}

export type ACServiceDropdownOption = {
    value: string,
    contractType: ACServiceContractType
}

export type AirConditionServiceOption = {
    value: string,
    options?: Array<ACServiceDropdownOption>,
    title: string,
    list: Array<string>,
    price?: number,
    discount?: number,
    contractType?: ACServiceContractType
    frequency?: string
}
