import React, { useMemo } from 'react'
import { View } from 'react-native'
import { Field } from '@codegateinc/react-form-builder-v2'
import { dateHelpers } from 'lib/utils'
import { useStyles, useTranslations } from 'lib/hooks'
import { Adapter, SelectDropdown } from 'lib/components'
import { JobStatus, ViewStyle, ViewStyleOption } from 'lib/types'
import { createStyles } from 'lib/styles'
import { Job, RecurringJob } from 'lib/models'
import { useGroupJobsByDate } from '../hooks'
import { UserBookingsListView } from './UserBookingsListView'
import { UserBookingsCalendarView } from './UserBookingsCalendarView'
import { UserBookingsEmpty } from './emptyBookings'

type UserBookingsProps = {
    jobs?: Array<Job>,
    filteredData: Array<Job>,
    date: Field<string>,
    viewStyle: ViewStyleOption,
    viewStyleOptions: Array<ViewStyleOption>,
    jobStatus: JobStatus,
    emptyTitle: string,
    serviceFilter: string,
    searchFilter?: string,
    setViewStyle: (viewStyle: ViewStyleOption) => void,
    recurringJobs?: Array<RecurringJob>
    emptyBookingListDescription: string
    showEmptyBookingListButton?: boolean
}

export const UserBookings: React.FunctionComponent<UserBookingsProps> = ({
    jobs = [],
    filteredData,
    date,
    viewStyle,
    setViewStyle,
    emptyTitle,
    viewStyleOptions,
    jobStatus,
    serviceFilter,
    searchFilter = '',
    recurringJobs = [],
    emptyBookingListDescription,
    showEmptyBookingListButton = true
}) => {
    const T = useTranslations()
    const isEmpty = jobs.length === 0
    const { styles } = useStyles(stylesheet)
    const isFilteredDataEmpty = filteredData.length === 0
    const groupedByDateJobs = useGroupJobsByDate(filteredData)
    const groupedByDateAllJobs = useGroupJobsByDate(jobs)
    const monthOptions = useMemo(() => [...new Set(Object.keys(groupedByDateAllJobs).map(date => dateHelpers.getMonth(date)))]
        .sort((firstDate, secondDate) => secondDate.localeCompare(firstDate))
        .map(item => ({
            value: item,
            label: dateHelpers.fullMonthAndYear(item)
        })), [])
    const defaultOption = {
        label: T.screens.bookings.viewAllMonths,
        value: T.screens.bookings.viewAllMonths
    }

    if (isEmpty) {
        return (
            <UserBookingsEmpty
                title={emptyTitle}
                serviceFilter={serviceFilter}
                description={emptyBookingListDescription}
                showActionButton={showEmptyBookingListButton}
            />
        )
    }

    const content = viewStyle.value === ViewStyle.Calendar
        ? (
            <UserBookingsCalendarView
                data={groupedByDateJobs}
                jobStatus={jobStatus}
                recurringJobs={recurringJobs}
            />
        ) : (
            <UserBookingsListView
                jobs={groupedByDateJobs}
                recurringJobs={recurringJobs}
                jobStatus={jobStatus}
                filters={[date.value, serviceFilter, searchFilter]}
            />
        )

    return (
        <View style={styles.container}>
            <View style={styles.searchContainer}>
                {viewStyle.value === ViewStyle.List ? (
                    <Adapter.SelectInput
                        {...date}
                        testID={T.accessibility.bookingsScreen.filters.date}
                        dropdownStyles
                        isClearable={false}
                        options={[defaultOption, ...monthOptions]}
                    />
                ) : (
                    <View />
                )}
                <View style={styles.viewSelect}>
                    <SelectDropdown
                        defaultValue={viewStyle}
                        options={viewStyleOptions}
                        testID={T.accessibility.bookingsScreen.filters.view}
                        onChange={value => {
                            if (value) {
                                setViewStyle({
                                    label: value.label,
                                    value: value.value as ViewStyle
                                })
                            }
                        }}

                    />
                </View>
            </View>
            {isFilteredDataEmpty
                ? (
                    <UserBookingsEmpty
                        title={emptyTitle}
                        serviceFilter={serviceFilter}
                        description={emptyBookingListDescription}
                        showActionButton={showEmptyBookingListButton}
                    />
                ) : content
            }
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        flex: 1,
        paddingHorizontal: {
            lg: 0,
            xs: theme.utils.gap(1)
        }
    },
    searchContainer: {
        height: 60,
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: {
            lg: theme.utils.gap(3),
            xs: 0
        },
        zIndex: theme.zIndex[10]
    },
    viewSelect: {
        width: 150
    }
}))
