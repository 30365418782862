import React from 'react'
import { ViewStyle } from 'react-native'
import { Touchable, Typography } from 'lib/components'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'

type PaginationPageTileProps = {
    text: string,
    disabled?: boolean,
    isSelected?: boolean,
    style?: ViewStyle,
    onPress: VoidFunction,
    testID?: string
}

export const PaginationPageTile: React.FunctionComponent<PaginationPageTileProps> = ({
    text,
    disabled,
    isSelected,
    style,
    onPress,
    testID
}) => {
    const { styles, theme } = useStyles(stylesheet)

    return (
        <Touchable
            disabled={disabled}
            style={{
                ...styles.container,
                ...(isSelected ? styles.selectedContainer : {}),
                ...style
            }}
            testID={testID}
            onPress={onPress}
        >
            <Typography.Label
                forceColor={isSelected
                    ? theme.colors.sun
                    : undefined
                }
            >
                {text}
            </Typography.Label>
        </Touchable>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        backgroundColor: theme.colors.white,
        paddingVertical: theme.utils.gap(1),
        paddingHorizontal: {
            lg:  theme.utils.gap(2),
            xs:  theme.utils.gap(1)
        },
        borderWidth: 1,
        borderColor: theme.colors.silver,
        borderRightWidth: 0 // prevent double border
    },
    selectedContainer: {
        backgroundColor: theme.colors.navajo
    }
}))
