import React from 'react'
import { View } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scrollview'
import { Children } from 'lib/types'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { Grid, Header, ListBottomSpacer } from 'lib/components'

type PageLayoutProps = {
    children: Children,
    footer?: Children,
    bottomSpacerHeight?: number
}

export const PageLayout: React.FunctionComponent<PageLayoutProps> = ({
    children,
    footer,
    bottomSpacerHeight = 50
}) => {
    const { styles } = useStyles(stylesheet)

    return (
        <Grid.Background>
            <Header />
            <KeyboardAwareScrollView
                scrollEnabled
                extraScrollHeight={20}
                showsVerticalScrollIndicator={false}
            >
                <View style={styles.wrapper}>
                    <View style={styles.container}>
                        {children}
                        <ListBottomSpacer height={bottomSpacerHeight} />
                    </View>
                </View>
            </KeyboardAwareScrollView>
            {footer}
        </Grid.Background>
    )
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        flex: 1,
        justifyContent: 'center',
        flexDirection: 'row'
    },
    container: {
        flex: 1,
        maxWidth: 1024
    }
}))
