import React from 'react'
import { Breakpoint } from 'lib/styles'
import { Children } from 'lib/types'
import { useIsWithinBreakpoints } from 'lib/hooks'

type HiddenProps = {
    from: Breakpoint,
    to?: Breakpoint,
    children: Children
}

export const Hidden: React.FunctionComponent<HiddenProps> = ({
    to,
    from,
    children
}) => {
    const isWithinBreakpoints = useIsWithinBreakpoints(from, to)

    return !isWithinBreakpoints ? (
        <React.Fragment>
            {children}
        </React.Fragment>
    ) : null
}
