import React, { Fragment, useEffect, useState } from 'react'
import { View, ViewStyle } from 'react-native'
import Animated from 'react-native-reanimated'
import { createStyles, theme } from 'lib/styles'
import { usePrice, useStyles, useTranslations } from 'lib/hooks'
import { Button, Touchable, Typography } from 'lib/components'
import { CustomFonts, Nullable } from 'lib/types'
import { useFeatureFlagAtom, useIsAuthorizedAtom } from 'lib/atoms'
import { Icons } from 'assets'
import { PromotionType } from 'lib/models'
import { firebase } from 'lib/firebase'
import { MINIMUM_DEEP_CLEANING_AMOUNT } from 'lib/components/calendar/consts'
import { CouponMobileButton } from './CouponMobileButton'
import { GetPromotionResponse, JobQuotationResponse, JobQuotationTotal, JobQuotationTotalSubscription } from '../types'

type MobileSummaryButtonProps = {
    disabled: boolean,
    submit: VoidFunction,
    submitText?: string,
    priceLabel?: string,
    price: Nullable<number>,
    priceBeforeDiscount?: Nullable<number>,
    setIsOpen: VoidFunction,
    animatedStyles: ViewStyle,
    onCouponMobileClick?: VoidFunction,
    perHour?: boolean,
    isLoading?: boolean,
    isGuestBookingEnabled?: boolean
    areGuestDetailsValid?: boolean
    authenticationView?: boolean,
    jobQuotation?: JobQuotationResponse,
    promotionPackage?: Nullable<GetPromotionResponse>,
    deepCleanMinAmount?: boolean
}

export const MobileSummaryButton: React.FunctionComponent<MobileSummaryButtonProps> = ({
    disabled,
    submit,
    submitText,
    price,
    setIsOpen,
    animatedStyles,
    perHour,
    isLoading = false,
    authenticationView = false,
    isGuestBookingEnabled = false,
    areGuestDetailsValid = false,
    onCouponMobileClick,
    promotionPackage,
    priceBeforeDiscount,
    jobQuotation,
    deepCleanMinAmount
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const [isAuthorized] = useIsAuthorizedAtom()
    const [featureFlagAtom] = useFeatureFlagAtom()
    const [bookingRequirementsCTA, setBookingRequirementCTA] = useState(T.common.continue)
    const formattedPrice = usePrice(price || 0)
    const buttonHeight = (!authenticationView || (isGuestBookingEnabled && areGuestDetailsValid)) ? 150 : 60
    const discountValue = promotionPackage?.promotion?.package?.price || 0
    const defaultPriceToDisplay = perHour
        ? `${formattedPrice} / ${T.common.hour}`
        : formattedPrice

    const isPromotionInPercent = promotionPackage && promotionPackage.promotion?.percent
    const isPromotionInNumber = promotionPackage?.promotion?.promo_type === PromotionType.Fixed
    const isPromoApplied = promotionPackage && promotionPackage.promotion
    const usePaymentBySessionFlow = featureFlagAtom?.paymentBySessionFlow || false
    const quotationData =
        (
            usePaymentBySessionFlow ?
                jobQuotation?.totalSubscription :
                jobQuotation?.total)
        || ({} as JobQuotationTotalSubscription | JobQuotationTotal)

    const {
        value = 0,
        valueWithoutSavings = undefined,
        valueBeforeDiscount = undefined,
        valueSavings = 0
    } = quotationData
    const shouldShowPriceFromJobQuotation = Object.keys(quotationData).length > 0
    const formattedValue = usePrice(value, true)
    const originValue = valueWithoutSavings || valueBeforeDiscount || priceBeforeDiscount || 0
    const formattedOriginValue = usePrice(originValue, true)
    const savedValue = valueSavings || discountValue || 0
    const formattedSavedValue = usePrice(savedValue, true)
    const deepCleaningRemainingAmount = MINIMUM_DEEP_CLEANING_AMOUNT - (price || 0)

    const getCTAFromRemoteConfig = async () => {
        const { booking_requirements_cta } = await firebase.remoteConfig.fetchRemoteConfig()
        setBookingRequirementCTA(booking_requirements_cta)
    }

    useEffect(() => {
        getCTAFromRemoteConfig()
    }, [])

    const getPromotionContent = () => {
        if (!isPromoApplied) {
            return null
        }

        if (isPromotionInPercent) {
            return (

                <Typography.Regular forceColor={theme.colors.green}>
                    {T.common.youGet}{T.common.space}
                    <Typography.Regular forceColor={theme.colors.green} bold>
                        {promotionPackage.promotion?.percent}{T.common.percent}{T.common.space}{T.common.off}
                    </Typography.Regular>
                    {T.common.space}{T.common.onFinalBilling}
                </Typography.Regular>
            )
        }

        if (isPromotionInNumber) {
            return (
                <View style={styles.discountText}>
                    {/* {priceBeforeDiscount ? (
                        <Typography.Label style={styles.priceBeforeDiscount}>
                            {formattedPriceBeforeDiscount}
                        </Typography.Label>
                    ) : null} */}
                    <Typography.Label forceColor={theme.colors.green} bold>
                        {originValue !== value && formattedOriginValue}
                    </Typography.Label>
                    {
                        savedValue > 0 && (
                            <Fragment>
                                <Typography.Label forceColor={theme.colors.green}>
                                    {T.common.youSaved}
                                </Typography.Label>
                                <Typography.Label forceColor={theme.colors.green} bold>
                                    {formattedSavedValue}
                                </Typography.Label>
                            </Fragment>
                        )
                    }
                </View>
            )
        }
    }

    const getJobQuotationContent = () => (
        <View style={styles.jobQuotationContainer}>
            <View style={styles.discountContainer}>
                {
                    savedValue > 0 && (
                        <Fragment>
                            <Typography.Label style={styles.priceBeforeDiscount}>
                                {formattedOriginValue}
                            </Typography.Label>
                            <Typography.Label forceColor={theme.colors.green}>
                                {T.common.youSaved}
                            </Typography.Label>
                            <Typography.Label forceColor={theme.colors.green} bold>
                                {formattedSavedValue}
                            </Typography.Label>
                        </Fragment>
                    )
                }
            </View>
            <View>
                <Typography.SmallSubheading forceColor={theme.colors.darkSlate}>
                    {formattedValue}
                </Typography.SmallSubheading>
            </View>
        </View>
    )

    return (
        <View
            style={{
                ...styles.bottom,
                height: buttonHeight
            }}
        >
            {deepCleanMinAmount && deepCleaningRemainingAmount > 0 ? (
                <View style={styles.minimumAmountAlertContainer}>
                    <Icons.Info size={16} forceColor={theme.colors.purple} />
                    <Typography.Regular forceColor={theme.colors.purple}>
                        {T.screens.deepCleaning.formFields.services.mobileMessage.add}
                        <Typography.Regular forceColor={theme.colors.purple} bold>
                            {T.common.currency} {deepCleaningRemainingAmount} {T.screens.deepCleaning.formFields.services.mobileMessage.more}
                        </Typography.Regular>
                        {T.screens.deepCleaning.formFields.services.mobileMessage.continue}
                    </Typography.Regular>
                </View>
            ) : null
            }
            <Touchable
                style={styles.buttonContent}
                onPress={setIsOpen}
                testID={T.accessibility.mobileSummaryButton}
            >
                <View>
                    {getPromotionContent()}
                    {
                        shouldShowPriceFromJobQuotation ?
                            getJobQuotationContent() :
                            price ? (
                                <Typography.Title bold>
                                    {defaultPriceToDisplay}
                                </Typography.Title>
                            )
                                : (
                                    <Typography.Regular style={styles.chargesOnQuotation}>
                                        {T.common.chargesOnQuotation}
                                    </Typography.Regular>
                                )
                    }
                </View>
                <View style={styles.summary}>
                    <Typography.Regular bold>
                        {T.common.bookingSummary}
                    </Typography.Regular>
                    <Animated.View style={[styles.chevron, animatedStyles]}>
                        <Icons.Chevron size={10} />
                    </Animated.View>
                </View>
            </Touchable>
            {(!authenticationView || (isGuestBookingEnabled && areGuestDetailsValid)) && (
                <View style={styles.bookingActionButtons}>
                    {submitText && isAuthorized ? (
                        <View style={styles.continueButton}>
                            <CouponMobileButton isLoading={isLoading}
                                promotionPackage={promotionPackage} onClick={onCouponMobileClick} />
                        </View>
                    ) : null}

                    <View style={submitText && isAuthorized ? styles.continueButton : styles.continueButtonFull} >
                        <Button
                            testID={T.accessibility.mobileSummaryButtonContinue}
                            isLoading={isLoading}
                            onPress={submit}
                            text={submitText || bookingRequirementsCTA}
                            disabled={disabled}
                            height={52}
                        />
                    </View>

                </View>
            )}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    bookingActionButtons: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginVertical: theme.utils.gap(2)
    },
    minimumAmountAlertContainer: {
        flexDirection: 'row',
        gap: theme.utils.gap(0.5),
        marginBottom: theme.utils.gap(0.5)
    },
    summary: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    chevron: {
        marginTop: -theme.utils.gap(1) / 2,
        marginLeft: theme.utils.gap(1)
    },
    bottom: {
        paddingHorizontal: theme.utils.gap(2),
        paddingVertical: theme.utils.gap(2),
        backgroundColor: theme.colors.white,
        borderTopLeftRadius: 12,
        borderTopRightRadius: 12,
        ...theme.utils.createShadow(0, 1, 20, 70)
    },
    buttonContent: {
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'row'
    },
    priceBeforeDiscount: {
        textDecorationLine: 'line-through',
        color: theme.colors.grey,
        fontSize: 11,
        marginBottom: theme.utils.gap(0.1)
    },
    discountText: {
        flexDirection: 'row',
        gap: theme.utils.gap(0.5)
    },
    jobQuotationContainer: {
        flexDirection: 'column',
        gap: theme.utils.gap(0.5)
    },
    discountContainer: {
        flexDirection: 'row',
        gap: theme.utils.gap(0.5)
    },
    chargesOnQuotation: {
        paddingTop: theme.utils.gap(1),
        fontSize: {
            xs: 14,
            sm: 16
        },
        fontFamily: CustomFonts.Poppins500
    },
    continueButton: {
        width: '45%'
    },
    continueButtonFull: {
        width: '100%'
    }
}))
