import React, { forwardRef } from 'react'
import { View } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scrollview'
import { Breadcrumb, Children } from 'lib/types'
import { useStyles } from 'lib/hooks'
import { Segment } from 'lib/analytics'
import { Measurements, isAndroid, isIOS, isNative } from 'lib/common'
import { createStyles, theme } from 'lib/styles'
import { Grid, Header, ListBottomSpacer, ServiceHeaderNative } from 'lib/components'
import { ServiceLayoutBreadCrumbs } from './ServiceLayoutBreadCrumbs'
import { ServiceLayoutBackButton } from './ServiceLayoutBackButton'
import { Rated } from './Rated'

type ServiceLayoutProps = {
    contentColumn: Children,
    summaryColumn: Children,
    title?: string,
    onInfoPress?: VoidFunction,
    breadcrumbs?: Array<Breadcrumb>
    footer?: Children,
    withBackground?: boolean,
    bottomSpacerHeight?: number,
    backButtonTitle?: string,
    onBackButtonPress?: VoidFunction,
    authenticationView?: boolean,
    onBackFromDetails?: VoidFunction
}

export const ServiceLayout = forwardRef<KeyboardAwareScrollView, ServiceLayoutProps>(({
    title,
    onInfoPress,
    contentColumn,
    summaryColumn,
    footer,
    breadcrumbs,
    backButtonTitle,
    withBackground = false,
    authenticationView = false,
    onBackButtonPress,
    onBackFromDetails,
    bottomSpacerHeight = 50
}, ref) => {
    const { styles } = useStyles(stylesheet)
    const shouldShowNativeHeader = isNative && title && onInfoPress

    return (
        <Grid.Background style={styles.gridBackground}>
            {shouldShowNativeHeader
                ? (
                    <ServiceHeaderNative
                        title={title}
                        onInfoPress={() => {
                            Segment.bookingWhatsIncludedAndHowItWorksClicked({})

                            onInfoPress()
                        }}
                    />
                )
                : (
                    <Header />
                )
            }
            <KeyboardAwareScrollView
                ref={ref}
                scrollEnabled
                extraScrollHeight={20}
                showsVerticalScrollIndicator={false}
                keyboardShouldPersistTaps="always"
                keyboardDismissMode={isIOS ? 'interactive' : 'on-drag'}
            >
                <View
                    style={{
                        ...styles.wrapper,
                        backgroundColor: withBackground
                            ? theme.colors.marble
                            : undefined
                    }}
                >
                    {withBackground && (
                        <View style={styles.background} />
                    )}
                    <View style={styles.container}>
                        {backButtonTitle && onBackButtonPress && (
                            <ServiceLayoutBackButton
                                title={backButtonTitle}
                                onPress={onBackButtonPress}
                            />
                        )}
                        <View style={styles.content}>
                            <View style={styles.leftColumn}>
                                <View style={styles.leftColumnContainer}>
                                    {breadcrumbs && (
                                        <ServiceLayoutBreadCrumbs
                                            breadcrumbs={breadcrumbs}
                                            onBackFromDetails={onBackFromDetails}
                                            authenticationView={authenticationView}
                                        />
                                    )}
                                    {contentColumn}
                                </View>
                                <View style={styles.spacer}/>
                                {withBackground && (
                                    <Rated />
                                )}
                                <ListBottomSpacer height={bottomSpacerHeight} />
                            </View>
                            <View style={styles.rightColumn}>
                                {summaryColumn}
                            </View>
                        </View>
                    </View>
                </View>
            </KeyboardAwareScrollView>
            {footer}
        </Grid.Background>
    )
})

const stylesheet = createStyles(theme => ({
    gridBackground: {
        minHeight: isAndroid
            ? Measurements.WindowHeight
            : undefined
    },
    wrapper: {
        flex: 1,
        minHeight: Measurements.WindowHeight - 90,
        justifyContent: 'center',
        flexDirection: 'row'
    },
    background: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        width: {
            lg: '50%',
            xs: undefined
        },
        backgroundColor: theme.colors.white
    },
    container: {
        flex: 1,
        maxWidth: 1024
    },
    content: {
        flexDirection: {
            lg: 'row',
            xs: 'column'
        }
    },
    leftColumn: {
        flex: {
            lg: 2,
            xs: undefined
        },
        backgroundColor: theme.colors.white,
        maxWidth: '100%',
        minHeight: {
            lg: Measurements.WindowHeight - Measurements.WideHeaderHeight,
            xs: undefined
        }
    },
    leftColumnContainer: {
        zIndex: theme.zIndex[10],
        paddingLeft: theme.utils.gap(2),
        paddingRight: {
            lg: theme.utils.gap(4),
            xs: theme.utils.gap(2)
        }
    },
    rightColumn: {
        flex: 1,
        maxWidth: {
            lg: 360,
            xs: undefined
        },
        paddingLeft: {
            lg: theme.utils.gap(4),
            xs: theme.utils.gap(2)
        },
        paddingTop: {
            lg: theme.utils.gap(4),
            xs: 0
        },
        paddingRight: theme.utils.gap(2)
    },
    spacer: {
        flex: 1
    }
}))
