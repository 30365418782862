import { useUserAtom } from 'lib/atoms'
import { useTranslations } from 'lib/hooks'

export const useUserProfilePreview = () => {
    const T = useTranslations()
    const [user] = useUserAtom()

    return [
        {
            label: T.screens.user.formFields.name.label,
            value: user.handle
        },
        {
            label: T.screens.user.formFields.email.label,
            value: user.email_id
        },
        {
            label: T.screens.user.formFields.phoneNumber.label,
            value: user.phone
        }
    ]
}
