import React from 'react'
import { View } from 'react-native'
import { Field } from '@codegateinc/react-form-builder-v2'
import { createStyles } from 'lib/styles'
import { Nullable } from 'lib/types'
import { useStyles } from 'lib/hooks'
import { BookingTimeSlot } from './BookingTimeSlot'
import { Icons } from 'assets'

interface BookingTimeSlotsProps extends Field<Record<string, Nullable<string>>> {
    startDate: string,
    disabled: boolean,
    minHoursNotice?: number,
    testID?: string
}

export const BookingTimeSlots: React.FunctionComponent<BookingTimeSlotsProps> = ({
    startDate,
    value,
    onChangeValue,
    placeholder,
    disabled,
    minHoursNotice,
    testID
}) => {
    const { theme, styles } = useStyles(stylesheet)

    return (
        <View style={styles.container}>
            {Object.keys(value).map((dayKey, index) => (
                <View
                    key={index}
                    style={{
                        zIndex: theme.zIndex[10] - index
                    }}
                >
                    <BookingTimeSlot
                        testID={testID}
                        leftIcon={() =>  (
                            <Icons.Clock
                                size={20}
                                forceColor={value[dayKey] ? theme.colors.black : theme.colors.fog}
                                viewBox="0 0 25 25"
                            />
                        )}
                        disabled={disabled}
                        minHoursNotice={minHoursNotice}
                        index={index}
                        dayKey={dayKey}
                        dayValue={value[dayKey]}
                        startDate={startDate}
                        placeholder={placeholder}
                        disabledInput={value[dayKey] === null}
                        isSelected={value[dayKey] !== null}
                        onInputChange={option => {
                            onChangeValue({
                                ...value,
                                [dayKey]: option === null
                                    ? null
                                    : new Date(option.value).toISOString()
                            })
                        }}
                    />
                </View>
            ))}
        </View>
    )

}

const stylesheet = createStyles(theme => ({
    container: {
        paddingBottom: theme.utils.gap(1)
    }
}))
