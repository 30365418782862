import { useField } from '@codegateinc/react-form-builder-v2'
import { dateHelpers } from 'lib/utils'
import { useTranslations } from 'lib/hooks'
import { AirConditionServiceOption, BookingFrequency, FrequencyOption, Nullable, SupplyID } from 'lib/types'
import { AirConditionFields } from './forms'
import { useBookingDays, useFrequencyOptions } from '../hooks'
import { getServiceStaticConfig } from '../utils'

export const useAirConditionForm = () => {
    const T = useTranslations()
    const frequencyOptions = useFrequencyOptions()
    const bookingDaysInitialValue = useBookingDays()

    const config = getServiceStaticConfig(SupplyID.ACService)

    const serviceOption = useField<Nullable<AirConditionServiceOption>>({
        key: AirConditionFields.ServiceOption,
        initialValue: null,
        isRequired: true,
        validateOnBlur: true,
        label: T.screens.airCondition.formFields.serviceOption.label,
        validationRules: [
            {
                errorMessage: T.screens.airCondition.formFields.serviceOption.validation.isRequired,
                validate: Boolean
            }
        ]
    })

    const frequency = useField<Nullable<FrequencyOption>>({
        key: AirConditionFields.Frequency,
        initialValue: frequencyOptions.find(option => option.value === BookingFrequency.OneTime) || null,
        isRequired: false,
        validateOnBlur: false,
        label: T.screens.airCondition.formFields.frequency.label
    })

    const startingDate = useField<string>({
        key: AirConditionFields.StartingDate,
        initialValue: '',
        isRequired: true,
        validateOnBlur: false,
        label: T.screens.airCondition.formFields.startingDate.label,
        placeholder: T.screens.airCondition.formFields.startingDate.placeholder,
        validationRules: [
            {
                errorMessage: T.screens.airCondition.formFields.startingDate.validation.isRequired,
                validate: Boolean
            }
        ]
    })

    const bookingTime = useField<string>({
        key: AirConditionFields.BookingTime,
        initialValue: '',
        validateOnBlur: true,
        isRequired: frequency.value?.value === BookingFrequency.OneTime,
        placeholder: T.screens.airCondition.formFields.selectTime.placeHolder,
        validationRules: [
            {
                errorMessage: T.screens.airCondition.formFields.selectTime.validation.isRequired,
                validate: value => {
                    if (frequency.value?.value !== BookingFrequency.OneTime) {
                        return true
                    }

                    return Boolean(value)
                }
            },
            {
                errorMessage: `${config.min_notice_hours} ${T.screens.airCondition.formFields.selectTime.validation.minimumHourNotice}`,
                validate: value => {
                    if (frequency.value?.value !== BookingFrequency.OneTime) {
                        return true
                    }

                    const isPast = dateHelpers.isDatePast(dateHelpers.subHoursFromDate(value, config.min_notice_hours))

                    return !isPast
                }
            }
        ]
    })

    const bookingDays = useField<Record<string, Nullable<string>>>({
        key: AirConditionFields.BookingDays,
        initialValue: bookingDaysInitialValue,
        validateOnBlur: false,
        isRequired: false,
        placeholder: T.screens.airCondition.formFields.selectTime.placeHolder,
        validationRules: [
            {
                errorMessage: T.screens.airCondition.formFields.selectTime.validation.isRequired,
                validate: value => {
                    if (frequency.value?.value === BookingFrequency.OneTime) {
                        return true
                    }

                    return Object.values(value).some(Boolean)
                }
            },
            {
                errorMessage: `${config.min_notice_hours} ${T.screens.airCondition.formFields.selectTime.validation.minimumHourNotice}`,
                validate: value => {
                    const times = Object.values(value).filter(item => Boolean(item)) as Array<string>
                    const isSomePast = times.some(time => dateHelpers.isDatePast(dateHelpers.subHoursFromDate(time, config.min_notice_hours)))

                    return !isSomePast
                }
            }
        ]
    })

    const numberOfAirConditioners = useField<string>({
        key: AirConditionFields.NumberOfAirConditioners,
        initialValue: '',
        isRequired: true,
        validateOnBlur: true,
        label: T.screens.airCondition.formFields.numberOfAirConditioners.label,
        validationRules: [
            {
                errorMessage: T.screens.airCondition.formFields.numberOfAirConditioners.validation.isRequired,
                validate: Boolean
            }
        ]
    })

    return {
        serviceOption,
        frequency,
        startingDate,
        bookingTime,
        bookingDays,
        numberOfAirConditioners
    }
}
