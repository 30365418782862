import React from 'react'
import { View } from 'react-native'
import { createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { CustomModal, Button, Typography, Touchable } from 'lib/components'
import { jobHelpers, linkingHelpers } from 'lib/utils'
import { ENV } from 'lib/config'
import { JobDetailsResponse } from 'features/jobs/types'
import { CustomFonts } from 'lib/types'
import { SubscriptionRescheduleRequestModal } from '../subscriptionRescheduleRequestModal'

type RescheduleRequestModalProps = {
    job: JobDetailsResponse,
    isOpen: boolean,
    onClose: VoidFunction,
    onProceedToCancel: VoidFunction,
    onRescheduleBooking: VoidFunction,
    onPausePackage: VoidFunction,
    onProceedToCancelPackage: VoidFunction
}

export const RescheduleRequestModal: React.FunctionComponent<RescheduleRequestModalProps> = ({
    isOpen,
    job,
    onClose,
    onProceedToCancel,
    onRescheduleBooking,
    onPausePackage,
    onProceedToCancelPackage
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const shouldShowRescheduleButton = jobHelpers.shouldShowRescheduleButton(job)
    const isPackage = jobHelpers.isRecurringAccepted(job) && !jobHelpers.isSessionJob(job)
    const isPackagePaused = jobHelpers.isPaused(job)
    const isAcceptedSubscription = jobHelpers.isAcceptedSubscription(job)
    const shouldShowSubscriptionRescheduleRequestModal = jobHelpers.shouldShowSubscriptionRescheduleRequestModal(job)
    const shouldUseAcceptedRequestMessage =
        jobHelpers.isRecurringAccepted(job) ||
        jobHelpers.isRecurringSubmitted(job) ||
        jobHelpers.isAdhocAccepted(job)

    const {
        title,
        message,
        submitButtonText
    } = shouldUseAcceptedRequestMessage
        ? {
            title: T.screens.jobDetails.rescheduleRequest.customPackageTitle,
            message: T.screens.jobDetails.rescheduleRequest.customMessage,
            submitButtonText: T.screens.jobDetails.rescheduleRequest.submitRequest
        } : {
            title: T.screens.jobDetails.rescheduleRequest.packageTitle,
            message: T.screens.jobDetails.rescheduleRequest.message,
            submitButtonText: T.screens.jobDetails.rescheduleRequest.rescheduleBooking
        }

    if (shouldShowSubscriptionRescheduleRequestModal) {
        return (
            <SubscriptionRescheduleRequestModal
                job={job}
                isOpen={isOpen}
                onClose={onClose}
                onRescheduleBooking={onRescheduleBooking}
                onPausePackage={onPausePackage}
            />
        )
    }

    return (
        <CustomModal
            onClose={onClose}
            isOpen={isOpen}
        >
            <View style={styles.headerWrapper}>
                <Typography.SmallSubheading style={styles.title}>
                    {isPackage ?
                        T.screens.jobDetails.rescheduleRequest.pausePackageTitle :
                        title
                    }
                </Typography.SmallSubheading>
                <View style={styles.messageContainer}>
                    <Typography.Regular style={styles.message}>
                        {message}
                    </Typography.Regular>
                </View>
            </View>
            <View style={styles.modalWrapper}>
                <View style={styles.actionContainer}>
                    <View style={{
                        ...styles.buttonWrapper,
                        ...styles.firstButtonWrapper
                    }}>
                        <Button
                            noBackground
                            text={T.screens.jobDetails.rescheduleRequest.proceedToCancel}
                            onPress={isPackage ? onProceedToCancelPackage : onProceedToCancel}
                            textWrapperStyles={styles.buttonTextWrapper}
                            textStyles={styles.buttonText}
                        />
                    </View>

                    {
                        isPackage ? (
                            <View style={{
                                ...styles.buttonWrapper,
                                ...styles.lastButtonWrapper
                            }}>
                                <Button
                                    disabled={isPackagePaused}
                                    text={isAcceptedSubscription ? T.screens.jobDetails.rescheduleRequest.pausePlan : T.screens.jobDetails.rescheduleRequest.pausePackage}
                                    onPress={onPausePackage}
                                    textWrapperStyles={styles.buttonTextWrapper}
                                    textStyles={styles.buttonText}
                                />
                            </View>
                        ): shouldShowRescheduleButton ? (
                            <View style={{
                                ...styles.buttonWrapper,
                                ...styles.lastButtonWrapper
                            }}>
                                <Button
                                    text={submitButtonText}
                                    onPress={onRescheduleBooking}
                                    textWrapperStyles={styles.buttonTextWrapper}
                                    textStyles={styles.buttonText}
                                />
                            </View>
                        ): null
                    }
                </View>
            </View>
            <View style={styles.footer}>
                <View style={styles.footerWrapper}>
                    <Typography.Regular style={styles.question}>
                        {T.screens.jobDetails.rescheduleRequest.haveAnIssue}
                    </Typography.Regular>
                    <Touchable onPress={() => linkingHelpers.openUrl(`${ENV.SENDHELPER_MARKETING_SITE_URL}/contacts`)}>
                        <Typography.Regular bold forceColor={theme.colors.sun}>
                            {` ${T.screens.jobDetails.rescheduleRequest.contactUs}`}
                        </Typography.Regular>
                    </Touchable>
                </View>
            </View>
        </CustomModal>
    )
}

const stylesheet = createStyles(theme => ({
    modalWrapper: {
        paddingHorizontal: {
            lg: theme.utils.gap(12),
            md: theme.utils.gap(8),
            sm: theme.utils.gap(6),
            xs: theme.utils.gap(2)
        }
    },
    headerWrapper: {
        alignItems: 'center'
    },
    title: {
        textAlign: 'center',
        fontSize: 25,
        lineHeight: 31,
        fontFamily: CustomFonts.Poppins600
    },
    messageContainer: {
        marginTop: theme.utils.gap(1.5)
    },
    message: {
        textAlign: 'center'
    },
    actionContainer: {
        paddingTop: theme.utils.gap(3.75),
        paddingBottom: theme.utils.gap(2.5),
        flexDirection: {
            md: 'row',
            xs: 'column'
        },
        justifyContent: 'center',
        zIndex: -1
    },
    buttonWrapper: {
        maxWidth: {
            md: 200,
            xs: undefined
        },
        marginHorizontal: {
            md: theme.utils.gap(1),
            xs: undefined
        },
        marginVertical: {
            md: undefined,
            xs: theme.utils.gap(0.5)
        }
    },
    buttonTextWrapper: {
        width: '100%',
        wordWrap: 'break-word',
        textAlign: 'center',
        marginHorizontal: theme.utils.gap(2.25)
    },
    buttonText: {
        textAlign: 'center'
    },
    firstButtonWrapper: {
        marginLeft: 0
    },
    lastButtonWrapper: {
        marginRight: 0
    },
    question: {
        textAlign: 'center',
        color: theme.colors.softBlueGrey
    },
    footer: {
        alignItems: 'center',
        paddingHorizontal: {
            lg: theme.utils.gap(8),
            xs: 0
        }
    },
    footerWrapper: {
        alignItems: 'center',
        flexDirection: 'row'
    }
}))
