import React from 'react'
import { useNavigation } from '@react-navigation/native'
import { View } from 'react-native'
import { R } from 'lib/utils'
import { useAppDrawerLinks, useStyles, useTranslations } from 'lib/hooks'
import { IconProps, VoidFunction } from 'lib/types'
import { createStyles } from 'lib/styles'
import { ScreenNames } from 'lib/routing'
import { Button, Touchable, Typography, UnreadDot } from 'lib/components'
import { Segment } from 'lib/analytics'

type AppDrawerContentProps = {
    routeName?: string,
    onPress?: VoidFunction
}

export const AppDrawerContent: React.FunctionComponent<AppDrawerContentProps> = ({
    routeName,
    onPress
}) => {
    const T = useTranslations()
    const navigation = useNavigation()
    const { styles, theme } = useStyles(stylesheet)
    const userDrawerLinks = useAppDrawerLinks()

    return (
        <View style={styles.container}>
            <View style={styles.bookServiceButton}>
                <Button
                    testID="book-a-service-button"
                    text={T.common.bookService}
                    onPress={() => {
                        Segment.bookServiceClicked({})
                        navigation.navigate(ScreenNames.AllServices)
                        R.ifDefined(onPress, R.call)
                    }}
                />
            </View>
            {userDrawerLinks
                .filter(link => !link.isHidden)
                .map((item, index) => {
                    const isActive = item.screenName
                        ? routeName?.includes(item.screenName)
                        : false
                    const linkColor = isActive
                        ? theme.icon.selected
                        : theme.icon.unselected

                    const Icon = React.createElement<IconProps>(item.icon, {
                        size: 18,
                        forceColor: item.screenName === ScreenNames.Auth
                            ? theme.colors.flame
                            : linkColor
                    })

                    return (
                        <Touchable
                            key={index}
                            onPress={() => {
                                item.onPress()
                                R.ifDefined(onPress, R.call)
                            }}
                        >
                            <View style={styles.linkContainer}>
                                {isActive && (
                                    <View style={styles.activeIndicator} />
                                )}
                                <View style={styles.icon}>
                                    {Icon}
                                    <UnreadDot
                                        top={-1}
                                        right={-3}
                                        isVisible={Boolean(item.hasDot)}
                                    />
                                </View>
                                <Typography.Regular forceColor={linkColor}>
                                    {item.text}
                                </Typography.Regular>
                            </View>
                        </Touchable>
                    )
                })}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        width: '100%'
    },
    linkContainer: {
        marginWidth: 3,
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: theme.utils.gap(2)
    },
    icon: {
        marginRight: theme.utils.gap(2)
    },
    separator: {
        height: 1,
        backgroundColor: theme.colors.shadow,
        marginVertical: theme.utils.gap(2)
    },
    bookServiceButton: {
        flex: 1,
        order: {
            lg: undefined,
            xs: 2
        },
        marginBottom: theme.utils.gap(2)
    },
    activeIndicator: {
        width: 3,
        left: {
            lg: -theme.utils.gap(3),
            xs: -theme.utils.gap(2)
        },
        height: '100%',
        position: 'absolute',
        backgroundColor: theme.colors.orange
    }
}))
