import React from 'react'
import Animated, { Extrapolation, interpolate, useAnimatedStyle, withTiming } from 'react-native-reanimated'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { Touchable } from 'lib/components'
import { Icons } from 'assets'

type ScrollToBottomProps = {
    onPress: VoidFunction,
    scrollY: Animated.SharedValue<number>
}

export const ScrollToBottom: React.FunctionComponent<ScrollToBottomProps> = ({
    onPress,
    scrollY
}) => {
    const { styles, theme } = useStyles(stylesheet)
    const animatedContainerStyles = useAnimatedStyle(() => ({
        right: withTiming(interpolate(
            scrollY.value,
            [0, 99, 100],
            [-64, -50, 0],
            {
                extrapolateLeft: Extrapolation.CLAMP,
                extrapolateRight: Extrapolation.CLAMP
            }
        ))
    }))

    return (
        <Animated.View
            style={[
                styles.container,
                animatedContainerStyles
            ]}
        >
            <Touchable onPress={onPress}>
                <Icons.ArrowDownInCircle
                    size={24}
                    forceColor={theme.colors.night}
                />
            </Touchable>
        </Animated.View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        width: 64,
        height: 48,
        position: 'absolute',
        right: 0,
        bottom: 100,
        borderTopLeftRadius: theme.utils.gap(2),
        borderBottomLeftRadius: theme.utils.gap(2),
        borderColor: theme.colors.silver,
        borderWidth: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.colors.white,
        ...theme.utils.createShadow()
    }
}))
