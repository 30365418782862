export enum AnalyticsEvent {
    UserAction = 'user_action',
    CleaningPayment = 'CLEANER_PAYMENT',
    DeepCleaningPayment = 'DEEP_PAYMENT',
    LaundryPayment = 'LAUNDRY_PAYMENT',
    AirConPayment = 'AIRCON_PAYMENT',
    HandymanPayment = 'HANDYMAN_PAYMENT',
    CookingPayment = 'COOKING_PAYMENT',
    PestPayment = 'PEST_PURCHASE',
    TasksErrandsPayment = 'TASK_AND_ERRANDS_PURCHASE',
    UserPurchase = 'user_purchase',
    Purchase = 'purchase'
}

export type AnalyticsAction = {
    eventType: AnalyticsEvent,
    description: string,
    value?: string
}

export type Analytics = {
    logEvent(event: AnalyticsAction): void,
    logScreenView(screenName: string): void,
    setUserId(userId: string): void,
    logPurchase(amount: number, productName: string, jobId?: string): void
}
