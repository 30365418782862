import React from 'react'
import { View } from 'react-native'
import { Icons } from 'assets'
import { createStyles } from 'lib/styles'
import { useCopyToClipboard, useStyles, useTranslations } from 'lib/hooks'
import { SectionBox, Touchable, Typography } from 'lib/components'
import { useIsAuthorizedAtom } from 'lib/atoms'

type BookingSuccessSectionProps = {
    bookingId: string
}

export const BookingSuccessSection: React.FunctionComponent<BookingSuccessSectionProps> = ({
    bookingId
}) => {
    const T = useTranslations()
    const [isAuthorized] = useIsAuthorizedAtom()
    const { styles, theme } = useStyles(stylesheet)
    const { onCopyPress } = useCopyToClipboard(T.common.copiedBookingIdMessage)

    return (
        <SectionBox>
            <View style={styles.container}>
                <Icons.SuccessBooking
                    size={42}
                    forceColor={theme.colors.orange}
                />
                <View style={styles.message}>
                    <Typography.SmallSubheading>
                        {T.screens.bookingSuccess.pending}
                    </Typography.SmallSubheading>
                    <View style={styles.bookingIdContainer}>
                        <Typography.Regular bold>
                            {T.screens.bookingSuccess.bookingId}
                        </Typography.Regular>
                        <View style={styles.bookingId}>
                            <Typography.Label bold>
                                {bookingId}
                            </Typography.Label>
                        </View>
                        <Touchable
                            onPress={() => onCopyPress(bookingId)}
                            style={styles.copyButton}
                        >
                            <Typography.Label
                                style={styles.copyText}
                                forceColor={theme.colors.orange}
                            >
                                {T.common.copy}
                            </Typography.Label>
                            <Icons.Copy
                                size={20}
                                forceColor={theme.colors.orange}
                            />
                        </Touchable>
                    </View>
                </View>
                <View>
                    <Typography.Regular
                        style={styles.description}
                        forceColor={theme.colors.darkGrey}
                    >

                        {isAuthorized ? T.screens.bookingSuccess.bookingMessage : T.screens.bookingSuccess.bookingMessageGuest}
                    </Typography.Regular>
                </View>
            </View>
        </SectionBox>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        alignItems: 'center',
        paddingVertical: theme.utils.gap(3),
        paddingHorizontal: {
            lg: theme.utils.gap(6),
            xs: 0
        }
    },
    message: {
        alignItems: 'center',
        marginVertical: theme.utils.gap(2)
    },
    description: {
        textAlign: 'center'
    },
    bookingIdContainer: {
        flexDirection: {
            lg: 'row',
            xs: 'column'
        },
        justifyContent: 'center',
        marginTop: theme.utils.gap(1),
        alignItems: 'center'
    },
    bookingId: {
        backgroundColor: theme.colors.silver,
        borderRadius: 4,
        paddingVertical: theme.utils.gap(1) / 2,
        paddingHorizontal: theme.utils.gap(1) / 2,
        marginHorizontal: theme.utils.gap(1),
        marginVertical: {
            lg: undefined,
            xs: theme.utils.gap(1)
        }
    },
    copyButton: {
        flexDirection: 'row'
    },
    copyText: {
        textTransform: 'lowercase',
        marginRight: theme.utils.gap(1) / 2
    }
}))
