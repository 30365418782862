import React from 'react'
import { View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { ENV } from 'lib/config'
import { JobStatus } from 'lib/types'
import { jobHelpers, linkingHelpers } from 'lib/utils'
import { Button, MediaQuery } from 'lib/components'
import { Breakpoint, createStyles } from 'lib/styles'
import { PaymentMethodTile } from 'features/bookings'
import { useStyles, useTranslations } from 'lib/hooks'
import { ConsumerPaymentDetails } from 'lib/models'
import { Segment, segmentUtils } from 'lib/analytics'
import { BookingsScreens, ScreenNames, StackProps } from 'lib/routing'
import { JobDetailsResponse } from 'features/jobs/types'
import { SessionOverview } from 'features/jobs/components/SessionOverview'
import { SessionDetails } from 'features/jobs/components/SessionDetails'
import { JobReceipt } from 'features/jobs/components/JobReceipt'
import { JobContact } from 'features/jobs/components/JobContact'
import { DetailsContactLink } from 'features/jobs/components/DetailsContactLink'
import { JobReceiptWithAdditionalFee } from 'features/jobs/components/JobReceiptWithAdditionalFee'

type ServiceDetailsAcceptedProps = {
    isRecurringAccepted: boolean,
    job: JobDetailsResponse,
    jobStatus?: JobStatus,
    closeModal?: VoidFunction
}

export const ServiceDetailsAccepted: React.FunctionComponent<ServiceDetailsAcceptedProps> = ({
    isRecurringAccepted,
    jobStatus,
    closeModal,
    job
}) => {
    const T = useTranslations()
    const navigation = useNavigation<StackProps<ScreenNames>>()
    const { styles } = useStyles(stylesheet)
    const { screenName } = linkingHelpers.getInternalPathBySupplyId(job.job.supply.id)
    const getConsumerCreditCardToken = () => {
        try {
            const [payment] = job.job.customer_payment

            return JSON.parse(payment.payment_details) as ConsumerPaymentDetails

        } catch {
            return null
        }
    }

    return (
        <View style={styles.wrapper}>
            {isRecurringAccepted && (
                Object.keys(job?.sessionDetails ?? {}).length ?
                    <SessionOverview job={job} />:
                    <SessionDetails job={job} />
            )}

            {
                jobHelpers.getCancellationFee(job) > 0 ?
                    <JobReceiptWithAdditionalFee response={job} /> : (
                        <JobReceipt
                            job={job.job}
                            isSessionJob={jobHelpers.isSessionJob(job)}
                            showPayBySessionForJobDetail={jobHelpers.shouldShowPayBySessionForJobDetail(job)}
                            receiptSummary={job?.receiptSummary || []}
                        />
                    )
            }

            <PaymentMethodTile
                notEnoughCredits={false}
                selectedPaymentMethod={job.job.paymentType}
                creditCardToken={getConsumerCreditCardToken()?.result.creditCard.token}
            />
            {jobStatus === JobStatus.Completed && (
                <Button
                    noBackground
                    text={T.components.jobStatus.bookAgain}
                    onPress={() => {
                        Segment.bookAgainClicked({
                            jobId: job.job.job_id,
                            serviceType: segmentUtils.supplyIdToServiceType(job.job.supply.id),
                            jobStatus: segmentUtils.getReadableJobStatus(job.job.status)
                        })

                        if (closeModal) {
                            closeModal()
                        }

                        screenName
                            ? navigation.navigate(screenName as BookingsScreens, {
                                postalcode: job.job.location.postcode,
                                service: String(job.job.supply.id)
                            })
                            : linkingHelpers.openUrl(ENV.SENDHELPER_MARKETING_SITE_URL, false)
                    }}
                />
            )}
            <MediaQuery.Visible from={Breakpoint.LG}>
                {job.job.status === JobStatus.Accepted && (
                    <JobContact job={job.job} />
                )}
                <DetailsContactLink
                    text={T.screens.jobDetails.needReschedule}
                    onPress={() => Segment.rescheduleBookingClicked({
                        jobId: job.job.job_id,
                        serviceType: segmentUtils.supplyIdToServiceType(job.job.supply.id)
                    })}
                />
            </MediaQuery.Visible>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        width: {
            lg: 290,
            xs: undefined
        },
        marginLeft: {
            lg: theme.utils.gap(2),
            xs: undefined
        }
    }
}))
