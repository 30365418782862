import { Address } from 'lib/models'

export type SetAuthCookieConfig = {
    userName: string,
    userId: string,
    userEmail: string,
    userAddresses: Array<Address>
}

export type SHJWTToken = {
    userId: string,
    iat: number,
    exp: number
}

export enum SHCookieKey {
    UserId = 'sh-user-id',
    UserName = 'sh-customer-name',
    UserEmail = 'sh-customer-email',
    UserAddresses = 'sh-customer-addresses',
    MarketingConsentAccepted = 'sh-marketing-consent-accepted',
    BookingCount = 'sh-booking-count'
}
