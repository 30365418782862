import { useUserTokenAtom } from 'lib/atoms'
import { SupplyID } from 'lib/types'

export const useGetUploadKey = (supplyId: SupplyID) => {
    const [token] = useUserTokenAtom()

    return (index: number) => {
        const jobId = token.concat(String(Date.now() + index))

        switch (supplyId) {
            case SupplyID.Handyman:
                return `handyman/${jobId}`
            case SupplyID.Laundry:
                return `laundry/${jobId}`
            case SupplyID.ACService:
                return `aircon_servicing/${jobId}`
            case SupplyID.MoversPackers:
                return `movers_and_packers/${jobId}`
            default:
                return ''
        }
    }
}
