import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const Cleaning: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path d="M56.187 80.707H8.349A8.359 8.359 0 0 1 0 72.358V65.02a47.007 47.007 0 0 1 4.167-19.418l4.792-10.65a41.476 41.476 0 0 0 3.678-17.132V8.374A8.359 8.359 0 0 1 20.986.02h27.551a7.577 7.577 0 0 1 7.577 7.577v4.453a7.577 7.577 0 0 1-7.577 7.581h-4.671a3.582 3.582 0 0 0-2.852 1.426 9.862 9.862 0 0 0 .83 12.864l16.886 17.33a20.46 20.46 0 0 1 5.807 14.278v6.829a8.359 8.359 0 0 1-8.35 8.349ZM20.986 5.591a2.786 2.786 0 0 0-2.783 2.783v9.446a47.006 47.006 0 0 1-4.166 19.416l-4.794 10.65A41.478 41.478 0 0 0 5.566 65.02v7.341a2.786 2.786 0 0 0 2.783 2.783h47.838a2.786 2.786 0 0 0 2.783-2.783v-6.832a14.894 14.894 0 0 0-4.226-10.393l-16.885-17.33a15.4 15.4 0 0 1-1.3-20.088 9.175 9.175 0 0 1 7.3-3.652h4.678a2.011 2.011 0 0 0 2.011-2.011V7.601a2.011 2.011 0 0 0-2.011-2.01Z" />
        <Path d="M37.066 69.887H15.421a2.783 2.783 0 0 1 0-5.566h21.645a2.783 2.783 0 0 1 0 5.566Z" />
        <Path d="M33.672 30.162H15.42a2.783 2.783 0 0 1 0-5.566h18.252a2.783 2.783 0 1 1 0 5.566Z"/>
        <Path d="M53.337 28.759a2.8 2.8 0 0 1-.549-.055 22.471 22.471 0 0 1-13.123-7.989l-1.144-1.425a2.783 2.783 0 0 1 4.347-3.475l1.144 1.43a16.9 16.9 0 0 0 9.865 6 2.783 2.783 0 0 1-.543 5.513Z" />
        <Path d="M68.774 7.02a2.808 2.808 0 1 1-2.808 2.808 2.808 2.808 0 0 1 2.808-2.808Z" />
        <Path d="M77.196 0a2.808 2.808 0 1 1-2.808 2.808A2.808 2.808 0 0 1 77.196 0Z" />
        <Path d="M77.196 12.638a2.808 2.808 0 1 1-2.808 2.808 2.808 2.808 0 0 1 2.808-2.808Z" />
    </Icon>
)
