import React from 'react'
import { DropZoneProps } from './types'

export const DropZone: React.FunctionComponent<DropZoneProps> = ({
    children,
    rootProps,
    style
}) => (
    <div
        {...rootProps}
        style={style}
    >
        {children}
    </div>
)
