import React from 'react'
import { View } from 'react-native'
import { createStyles, theme } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { CustomModal, Button, Typography } from 'lib/components'
import { JobDetailsResponse } from 'features/jobs/types'
import { Icons } from 'assets'
import { UseFormReturn } from '@codegateinc/react-form-builder-v2'
import { CancelationPackageShape } from 'features/jobs/forms'
import { CANCEL_PACKAGE_OPTIONS } from 'features/jobs/constants/CancelPackageOptions'
import { CustomFonts } from 'lib/types'

type CancelBookingSuccessModalProps = {
    isOpen: boolean,
    cancelationMessage?: string,
    job: JobDetailsResponse,
    onClose: VoidFunction,
    onCloseAndRefetchJob: VoidFunction,
    formReturn: UseFormReturn<CancelationPackageShape>
}

export const CancelPackageSuccessModal: React.FunctionComponent<CancelBookingSuccessModalProps> = ({
    isOpen,
    cancelationMessage,
    onClose,
    onCloseAndRefetchJob,
    formReturn,
    job
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const { form } = formReturn
    const isCancelRequestSend = form.cancellationOption.value === CANCEL_PACKAGE_OPTIONS[0].value
    const onCloseEvent = isCancelRequestSend ? onClose : onCloseAndRefetchJob

    return (
        <CustomModal
            onClose={onCloseEvent}
            isOpen={isOpen}
        >
            <View style={styles.modalWrapper}>
                <View style={styles.iconContainer}>
                    <Icons.CheckCircle
                        size={40}
                        forceColor={theme.colors.orange}
                    />
                </View>
                <View>
                    <Typography.SmallSubheading style={styles.title}>
                        {
                            isCancelRequestSend ?
                                T.screens.jobDetails.cancelPackageRequestSend.packageTitle :
                                T.screens.jobDetails.cancelPackageSuccess.packageTitle
                        }
                    </Typography.SmallSubheading>
                </View>

                {cancelationMessage && (
                    <View style={styles.cancelationMessageWrapper}>
                        <Icons.Info
                            size={24}
                            forceColor={theme.colors.orange}
                        />
                        <Typography.Regular style={styles.cancelationMessage}>
                            {cancelationMessage}
                        </Typography.Regular>
                    </View>
                )}

                {isCancelRequestSend ? (
                    <View style={styles.messageContainer}>
                        <Typography.Regular style={styles.textCenter}>
                            {T.screens.jobDetails.cancelPackageRequestSend.note}
                        </Typography.Regular>
                    </View>
                ) : (
                    <View style={styles.messageContainer}>
                        <Typography.Regular>
                            {T.screens.jobDetails.cancelPackageSuccess.thePackageBooking}
                            <Typography.Regular style={styles.jobIdText}>
                                {` ${job.job.job_id} `}
                            </Typography.Regular>
                            {T.screens.jobDetails.cancelBookingSuccess.hasBeenCancelledSuccessfully}
                        </Typography.Regular>
                    </View>
                )}

                <View style={styles.actionContainer}>
                    <View style={styles.buttonWrapper}>
                        <Button
                            text={T.screens.jobDetails.cancelPackageSuccess.continue}
                            onPress={onCloseEvent}
                            textWrapperStyles={styles.buttonTextWrapper}
                            textStyles={styles.textCenter}
                        />
                    </View>
                </View>
            </View>
        </CustomModal>
    )
}

const stylesheet = createStyles(theme => ({
    modalWrapper: {
        alignItems: 'center',
        paddingHorizontal: {
            lg: theme.utils.gap(10),
            md: theme.utils.gap(8),
            sm: theme.utils.gap(6),
            xs: theme.utils.gap(2)
        }
    },
    iconContainer: {
        alignItems: 'center',
        paddingBottom: theme.utils.gap(1)
    },
    messageContainer: {
        marginTop: theme.utils.gap(1.5),
        textAlign: 'center'
    },
    actionContainer: {
        paddingTop: theme.utils.gap(3),
        flexDirection: 'row',
        justifyContent: 'center',
        zIndex: -1
    },
    buttonWrapper: {
        flex: 1
    },
    buttonTextWrapper: {
        width: '100%',
        wordWrap: 'break-word',
        textAlign: 'center',
        marginHorizontal: theme.utils.gap(7)
    },
    firstButtonWrapper: {
        marginLeft: 0
    },
    lastButtonWrapper: {
        marginRight: 0
    },
    jobIdText: {
        fontWeight: 'bold'
    },
    cancelationMessageWrapper: {
        borderRadius: 8,
        backgroundColor: theme.colors.latte,
        padding: theme.utils.gap(1.5),
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: theme.utils.gap(1.5),
        marginBottom: theme.utils.gap(0)
    },
    cancelationMessage: {
        textAlign: 'center',
        marginLeft: theme.utils.gap(1)
    },
    title: {
        textAlign: 'center',
        fontSize: 25,
        lineHeight: 31,
        fontFamily: CustomFonts.Poppins600
    },
    note: {
        marginTop: theme.utils.gap(0.5)
    },
    textCenter: {
        textAlign: 'center'
    }
}))
