import { useTranslations } from 'lib/hooks'
import { ScreenNames } from 'lib/routing'

export const useCookingBreadcrumbs = () => {
    const T = useTranslations()

    return [
        {
            text: T.screens.cooking.breadCrumbs.requirements,
            screenName: ScreenNames.Cooking
        },
        {
            text: T.screens.cooking.breadCrumbs.confirmation,
            screenName: ScreenNames.CookingSummary
        }
    ]
}
