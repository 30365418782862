import React from 'react'
import { View } from 'react-native'
import { Icons } from 'assets'
import { Segment, segmentUtils } from 'lib/analytics'
import { createStyles } from 'lib/styles'
import { favouriteActions, favouriteHooks } from 'features/favourite'
import { LoadingIndicator, Touchable, Typography } from 'lib/components'
import { useQueryHelpers, useStyles, useTranslations } from 'lib/hooks'
import { JobSupplier, NotificationType, ResetFavouriteExpert } from 'lib/types'
import { useToastAtom, useUserAtom, useUserTokenAtom } from 'lib/atoms'

type SetAsFavouriteProps = {
    supplier: JobSupplier,
    supplyId: number
}

export const SetAsFavourite: React.FunctionComponent<SetAsFavouriteProps> = ({
    supplier,
    supplyId
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const { favourites, isLoading: isLoadingFavourites } = favouriteHooks.useConsumerFavourites()
    const [, setToast] = useToastAtom()
    const [token] = useUserTokenAtom()
    const [, setUser] = useUserAtom()
    const { onRequestError } = useQueryHelpers()
    const { mutate: onSetPreferredHelper, isLoading: iseSettingFavourite } = favouriteActions.setPreferredHelper()
    const isFavourite = favourites.some(expert => expert.supplier._id === supplier._id && expert.supply.id === supplyId)
    const isLoading = isLoadingFavourites || iseSettingFavourite

    const onFavouritePress = () => {
        onSetPreferredHelper(
            {
                token,
                supplier: isFavourite
                    ? ResetFavouriteExpert.None
                    : supplier,
                supply: supplyId
            },
            {
                onSuccess: ({ data }) => {
                    if (!isFavourite) {
                        Segment.favouritePartnerSet({
                            partner: {
                                partnerId: supplier._id,
                                partnerHandle: supplier.handle
                            },
                            serviceType: segmentUtils.supplyIdToServiceType(supplyId)
                        })
                    }

                    setUser(data.user_profile)
                    setToast({
                        message: isFavourite
                            ? T.screens.favourite.removedFromFavourites
                            : T.screens.favourite.setsAsFavourite,
                        type: NotificationType.Success
                    })
                },
                onError: onRequestError
            }
        )
    }

    return (
        <View style={styles.container}>
            <LoadingIndicator isLoading={isLoading} />
            <Touchable
                disabled={isFavourite}
                onPress={onFavouritePress}
                style={styles.favouriteButton}
            >
                <Icons.Heart
                    size={16}
                    fill={
                        isFavourite
                            ? theme.colors.orange
                            : undefined
                    }
                    forceColor={theme.colors.orange}
                />
                <Typography.Label
                    style={{
                        ...styles.favouriteText,
                        borderColor: isFavourite
                            ? theme.colors.night
                            : theme.colors.orange
                    }}
                    forceColor={isFavourite
                        ? theme.colors.night
                        : theme.colors.orange
                    }
                >
                    {
                        isFavourite
                            ? `${supplier.handle} ${T.common.added} ${T.common.asFavourite}`
                            : `${T.common.set} ${supplier.handle} ${T.common.asFavourite}`
                    }
                </Typography.Label>
            </Touchable>
            <Typography.Label forceColor={theme.colors.mouse}>
                {T.components.rating.setAsFavouriteMessage}
            </Typography.Label>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        alignItems: 'center'
    },
    favouriteButton: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: theme.utils.gap(2)
    },
    favouriteText: {
        borderBottomWidth: 1,
        marginLeft: theme.utils.gap(1),
        borderColor: theme.colors.orange
    }
}))
