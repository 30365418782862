import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const Percent: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path fillRule="evenodd" clipRule="evenodd" d="M2.66663 3.16671C2.66663 2.15419 3.48744 1.33337 4.49996 1.33337H11.5C12.5125 1.33337 13.3333 2.15419 13.3333 3.16671V14.1667C13.3333 14.3627 13.2188 14.5406 13.0404 14.6218C12.862 14.703 12.6527 14.6725 12.5049 14.5437L11.1283 13.3447L9.90258 14.5266C9.70889 14.7134 9.40213 14.7134 9.20845 14.5266L7.99996 13.3613L6.79147 14.5266C6.59778 14.7134 6.29102 14.7134 6.09734 14.5266L4.87166 13.3447L3.49502 14.5437C3.34723 14.6725 3.13787 14.703 2.9595 14.6218C2.78112 14.5406 2.66663 14.3627 2.66663 14.1667V3.16671ZM4.49996 2.33337C4.03972 2.33337 3.66663 2.70647 3.66663 3.16671V13.0682L4.56046 12.2897C4.75599 12.1194 5.04926 12.1268 5.23592 12.3068L6.4444 13.4721L7.65289 12.3068C7.84658 12.12 8.15334 12.12 8.34703 12.3068L9.55551 13.4721L10.764 12.3068C10.9507 12.1268 11.2439 12.1194 11.4395 12.2897L12.3333 13.0682V3.16671C12.3333 2.70647 11.9602 2.33337 11.5 2.33337H4.49996ZM10.528 4.81315C10.7233 5.00842 10.7233 5.325 10.528 5.52026L6.19466 9.85359C5.9994 10.0489 5.68281 10.0489 5.48755 9.85359C5.29229 9.65833 5.29229 9.34175 5.48755 9.14649L9.82089 4.81315C10.0161 4.61789 10.3327 4.61789 10.528 4.81315Z" fill="white"/>
        <Path fillRule="evenodd" clipRule="evenodd" d="M5.33337 5.33329C5.33337 5.70148 5.63185 5.99996 6.00004 5.99996C6.36823 5.99996 6.66671 5.70148 6.66671 5.33329C6.66671 4.9651 6.36823 4.66663 6.00004 4.66663C5.63185 4.66663 5.33337 4.9651 5.33337 5.33329Z" fill="white"/>
        <Path fillRule="evenodd" clipRule="evenodd" d="M9.33337 9.33329C9.33337 9.70148 9.63185 9.99996 10 9.99996C10.3682 9.99996 10.6667 9.70148 10.6667 9.33329C10.6667 8.9651 10.3682 8.66663 10 8.66663C9.63185 8.66663 9.33337 8.9651 9.33337 9.33329Z" fill="white"/>
    </Icon>
)
