import { useForm } from '@codegateinc/react-form-builder-v2'
import { Platform } from 'react-native'
import { isNative } from 'lib/common'
import { usePromotionAtom, useSocialSignInTokenAtom, useToastAtom } from 'lib/atoms'
import { NotificationType, SubscribePromotionAction } from 'lib/types'
import { ProviderDetails } from 'lib/models'
import { AuthFinishSocialSignUpScreenParams } from 'lib/routing'
import { Segment, AuthSegmentMethod, GoogleAnalytics, AnalyticsEvent, AnalyticsEventDescription } from 'lib/analytics'
import { useGetErrorMessage, useSessionData } from 'lib/hooks'
import { DeviceInfo } from 'lib/utils'
import { useGetProfileData } from './useGetProfileData'
import { SignUpResponse } from '../types'
import { SignUpFormShape, useSignUpForm } from '../forms'
import { saveAccessToken, saveRefreshToken } from '../utils'
import { useSignUpWithApple, useSignUpWithFacebook, useSignUpWithGoogle } from '../actions'
import { getAnonymousID } from 'lib/analytics/segment/utils'

export const useFinishSocialSignUp = (params: AuthFinishSocialSignUpScreenParams) => {
    const [, setToast] = useToastAtom()
    const [, setPromotionAction] = usePromotionAtom()
    const [ socialSignInToken ] = useSocialSignInTokenAtom()
    const { getErrorMessage } = useGetErrorMessage()
    const sessionData = useSessionData()
    const { getMe, isFetchingProfile } = useGetProfileData(
        error => setToast({
            message: error,
            type: NotificationType.Error
        })
    )

    const handlePromotionAction = (marketingConsentAccepted: boolean) => {
        setPromotionAction(marketingConsentAccepted ?
            SubscribePromotionAction.ShouldShowPromotionModal :
            isNative ?
                SubscribePromotionAction.ShouldShowMarketingConsentModal :
                SubscribePromotionAction.NoAction
        )
    }

    const onAuthSuccess = (data: SignUpResponse, authMethod: AuthSegmentMethod) => {
        saveAccessToken(data.tokens.accessToken)
        saveRefreshToken(data.tokens.refreshToken)
        getMe(data.token)

        const { deviceId, deviceName } = DeviceInfo.get()

        Segment.identify(data.token, {
            email: params.email,
            device: {
                deviceId,
                deviceName,
                platform: Platform.OS
            }
        })
    }

    const { email, name, providerDetails } = params
    const token = socialSignInToken
    const { mutate: signUpWithFacebook, isLoading: isSigningUpWithFacebook } = useSignUpWithFacebook()
    const { mutate: signUpWithApple, isLoading: isSigningInWithApple } = useSignUpWithApple()
    const { mutate: signUpWithGoogle, isLoading: isSigningUpWithGoogle } = useSignUpWithGoogle()
    const isLoading = isSigningUpWithFacebook || isSigningInWithApple || isFetchingProfile || isSigningUpWithGoogle
    const { form, submit, isFilled, hasError } = useForm<SignUpFormShape>(useSignUpForm(
        providerDetails,
        { initialData: { email, name } }
    ), {
        onSuccess: async ({ email: formEmail, password, name, phoneNumber, marketingConsent }) => {
            const marketingConsentAccepted = Boolean(marketingConsent)
            switch (providerDetails) {
                case ProviderDetails.Facebook:
                    signUpWithFacebook({
                        ...sessionData,
                        email_id: providerDetails !== ProviderDetails.Facebook
                            ? formEmail
                            : email,
                        handle: name,
                        phone: phoneNumber,
                        password,
                        provider_details: {
                            provider: providerDetails,
                            provider_access_token: token
                        },
                        anonymous_id: await getAnonymousID(),
                        marketing_consent_accepted: marketingConsentAccepted
                    }, {
                        onSuccess: ({ data }) => {
                            GoogleAnalytics.logEvent({
                                eventType: AnalyticsEvent.UserAction,
                                description: AnalyticsEventDescription.FacebookSignUp
                            })
                            onAuthSuccess(data, AuthSegmentMethod.Facebook)
                            handlePromotionAction(marketingConsentAccepted)
                        },
                        onError: response => setToast({
                            message: getErrorMessage(response),
                            type: NotificationType.Error
                        })
                    })

                    return
                case ProviderDetails.Apple:
                    signUpWithApple({
                        ...sessionData,
                        email_id: email,
                        handle: name,
                        phone: phoneNumber,
                        password,
                        provider_details: {
                            apple_user_id: params.appleUserId || '',
                            provider: providerDetails,
                            provider_access_token: token
                        },
                        anonymous_id: await getAnonymousID(),
                        marketing_consent_accepted: marketingConsentAccepted
                    }, {
                        onSuccess: ({ data }) => {
                            GoogleAnalytics.logEvent({
                                eventType: AnalyticsEvent.UserAction,
                                description: AnalyticsEventDescription.AppleSignUp
                            })
                            onAuthSuccess(data, AuthSegmentMethod.Apple)
                            handlePromotionAction(marketingConsentAccepted)
                        },
                        onError: response => setToast({
                            message: getErrorMessage(response),
                            type: NotificationType.Error
                        })
                    })

                    return
                case ProviderDetails.Google:
                    signUpWithGoogle({
                        ...sessionData,
                        password,
                        email_id: email,
                        handle: name,
                        phone: phoneNumber,
                        provider_details: {
                            provider: providerDetails,
                            provider_access_token: token,
                            account_id: params.accountId!
                        },
                        marketing_consent_accepted: marketingConsentAccepted
                    }, {
                        onSuccess: ({ data }) => {
                            GoogleAnalytics.logEvent({
                                eventType: AnalyticsEvent.UserAction,
                                description: AnalyticsEventDescription.GoogleSignUp
                            })
                            onAuthSuccess(data, AuthSegmentMethod.Google)
                            handlePromotionAction(marketingConsentAccepted)
                        },
                        onError: response => setToast({
                            message: getErrorMessage(response),
                            type: NotificationType.Error
                        })
                    })

                    return
                default:
                    return
            }
        }
    })

    return {
        submit,
        isLoading,
        form,
        isFilled,
        hasError
    }
}
