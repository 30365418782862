import { View } from 'react-native'
import React, { useState } from 'react'
import { useForm } from '@codegateinc/react-form-builder-v2'
import { useNavigation, useRoute } from '@react-navigation/native'
import { Icons } from 'assets'
import { Segment } from 'lib/analytics'
import { createStyles } from 'lib/styles'
import { linkingHelpers, stringHelpers } from 'lib/utils'
import { BookingsScreens, NavigationParams, ScreenNames, StackProps } from 'lib/routing'
import { NotificationType } from 'lib/types'
import { isNative } from 'lib/common'
import { useAddressAtom, useToastAtom, useUserAtom } from 'lib/atoms'
import { useStyles, useTranslations } from 'lib/hooks'
import { Address as AddressModel } from 'lib/models'
import { Adapter } from '../form'
import { BasicModal } from '../BasicModal'
import { Button } from '../Button'
import { Regular, SmallSubheading } from '../typography'
import { ConfirmationModal } from './ConfirmationModal'
import { useSelectPostcodeForm } from './form'

type EditAddressModalProps = {
    isOpen: boolean,
    onClose: VoidFunction,
    onAddNew: VoidFunction,
    modalTitle?: string,
    skipConfirmation?: boolean,
    onSuccess?(address: AddressModel): void
}

export const EditAddressModal: React.FunctionComponent<EditAddressModalProps> = ({
    isOpen,
    onClose,
    onAddNew,
    modalTitle,
    skipConfirmation = false,
    onSuccess
}) => {
    const route = useRoute<NavigationParams<ScreenNames.Summary>>()
    const T = useTranslations()
    const [user] = useUserAtom()
    const navigation = useNavigation<StackProps<ScreenNames>>()
    const [, setAddress] = useAddressAtom()
    const [, setToastMessage] = useToastAtom()
    const { styles, theme } = useStyles(stylesheet)
    const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false)
    const { form, submit, resetForm, formHasChanges } = useForm(useSelectPostcodeForm(), {
        onSuccess: ({ address }) => {
            const getAddressValue = () => {
                try {
                    return JSON.parse(address as string) as AddressModel
                } catch {
                    return null
                }
            }

            const addressValue = getAddressValue()

            if (!addressValue?._id) {
                return
            }

            const selectedSavedAddress = user.locations.find(location => location._id === addressValue._id)

            if (selectedSavedAddress) {
                onSuccess
                    ? onSuccess(selectedSavedAddress)
                    : setAddress(selectedSavedAddress)
            }

            onClose()
            resetForm()
            redirect()
        }
    })

    const onCancel = () => {
        onClose()
        resetForm()
        setConfirmationModalOpen(false)
    }

    const redirect = () => {
        if (onSuccess) {
            return
        }

        setToastMessage({
            message: T.components.addressChangeConfirmation.successMessage,
            type: NotificationType.Success
        })

        if (route.name.toLowerCase().includes(ScreenNames.Summary.toLowerCase())) {
            const canGoBack = navigation.canGoBack()
            const { screenName } = linkingHelpers.getInternalPathBySupplyId(route.params.supplyId)

            canGoBack
                ? navigation.goBack()
                : navigation.replace(screenName as BookingsScreens, {
                    postalcode: route.params.postalcode,
                    service: String(route.params.supplyId)
                })
        }
    }

    const onConfirm = () => {
        submit()
        setConfirmationModalOpen(false)
    }

    return (
        <React.Fragment>
            <ConfirmationModal
                onCancel={onCancel}
                onConfirm={() => {
                    Segment.bookingAddressConfirmClicked({})

                    onConfirm()
                }}
                isLoading={false}
                isOpen={isConfirmationModalOpen}
            />
            <BasicModal
                isOpen={isOpen}
                onClose={() => {
                    Segment.bookingAddressModuleClosed({})

                    onClose()
                    resetForm()
                }}
                scrollViewStyles={styles.modalScrollView}
                scrollViewProps={{
                    scrollEnabled: false
                }}
            >
                <View style={styles.container}>
                    <SmallSubheading>
                        {modalTitle || T.screens.address.select}
                    </SmallSubheading>
                    <View style={styles.formContainer}>
                        <View
                            style={{
                                ...styles.flex,
                                height: isNative
                                    ? theme.components.input.height
                                    : undefined
                            }}
                        >
                            <Adapter.SelectInput
                                {...form.address}
                                label={undefined}
                                isClearable={false}
                                wrapperCustomStyles={{
                                    flex: isNative
                                        ? 1
                                        : undefined
                                }}
                                leftIcon={Icons.Navigation}
                                onChangeValue={value => {
                                    Segment.bookingExistingAddressSelected({})

                                    form.address.onChangeValue(value)
                                }}
                                options={user.locations.map(location => {
                                    const userAddressUnit = stringHelpers.getFormattedAddressUnit(location.unit)

                                    return {
                                        value: JSON.stringify({
                                            _id: location._id,
                                            postcode: location.postcode,
                                            formatted_address: location.address,
                                            district: location.district,
                                            location: location.geo,
                                            unit: location.unit
                                        }),
                                        label: `${userAddressUnit}${location.address}, ${location.postcode}`
                                    }
                                })}
                            />
                        </View>
                        <View style={styles.button}>
                            <Button
                                onPress={() => {
                                    Segment.bookingAddressConfirmClicked({})

                                    if (skipConfirmation) {
                                        return submit()
                                    }

                                    onClose()
                                    setConfirmationModalOpen(true)
                                }}
                                text={T.common.confirm}
                                disabled={!formHasChanges()}
                            />
                        </View>
                    </View>
                    <View style={styles.createNew}>
                        <Regular>
                            <Regular>
                                {`${T.common.or.toLowerCase()} `}
                            </Regular>
                            <Regular
                                onPress={() => {
                                    Segment.bookingNewAddressClicked({})

                                    resetForm()
                                    onAddNew()
                                }}
                                style={styles.underLine}
                                forceColor={theme.colors.orange}
                            >
                                {T.screens.address.createNewOne}
                            </Regular>
                        </Regular>
                    </View>
                </View>
            </BasicModal>
        </React.Fragment>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        paddingHorizontal: {
            lg: theme.utils.gap(3),
            xs: 0
        },
        paddingTop: theme.utils.gap(2)
    },
    modalScrollView: {
        overflow: 'visible'
    },
    formContainer: {
        zIndex: theme.zIndex[10],
        alignItems: {
            lg: 'center',
            xs: undefined
        },
        flexDirection: {
            lg: 'row',
            xs: 'column'
        },
        width: {
            lg: 750,
            xs: undefined
        },
        marginTop: theme.utils.gap(2)
    },
    row: {
        flexDirection: {
            lg: 'row',
            xs: 'column'
        }
    },
    flexLeft: {
        flex: {
            lg: 1,
            xs: undefined
        },
        paddingRight: {
            lg: theme.utils.gap(1),
            xs: 0
        }
    },
    flex: {
        zIndex: theme.zIndex[10],
        flex: {
            lg: 1,
            xs: undefined
        }
    },
    button: {
        flex: {
            lg: undefined,
            xs: 1
        },
        marginLeft: {
            lg: theme.utils.gap(1),
            xs: 0
        },
        marginBottom: theme.utils.gap(2),
        width: {
            lg: 180,
            xs: undefined
        },
        marginTop: isNative
            ? theme.utils.gap(2)
            : undefined
    },
    underLine: {
        borderBottomWidth: 1,
        borderBottomColor: theme.colors.orange
    },
    createNew: {
        marginTop: isNative
            ? theme.utils.gap(4)
            : theme.utils.gap(2),
        marginBottom: theme.utils.gap(2),
        alignItems: 'center'
    }
}))
