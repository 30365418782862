import { openURL } from 'expo-linking'
import { APP_CONFIG } from 'lib/config'
import { useTranslations } from 'lib/hooks'

export const useContactSections = () => {
    const T = useTranslations()

    return [
        {
            title: T.screens.contactUs.contactSections.email.title,
            description: T.screens.contactUs.contactSections.email.description,
            linkText: T.screens.contactUs.contactSections.email.link,
            onPress: () => openURL(`mailto:${APP_CONFIG.CONTACT.MAIL}`)
        },
        {
            title: T.screens.contactUs.contactSections.call.title,
            description: T.screens.contactUs.contactSections.call.description,
            linkText: T.screens.contactUs.contactSections.call.link,
            onPress: () => openURL(`tel:${APP_CONFIG.CONTACT.PHONE}`)
        }
    ]
}
