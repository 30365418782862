import { Icons } from 'assets'
import { Breakpoint } from 'lib/styles'
import { useIsWithinBreakpoints, useTranslations } from 'lib/hooks'

export const useShareSteps = () => {
    const T = useTranslations()
    const isMobile = useIsWithinBreakpoints(Breakpoint.XS, Breakpoint.SM)
    const iconSize = isMobile ? 22 : 32

    return [
        {
            renderIcon: () => (
                <Icons.People size={iconSize} />
            ),
            title: T.screens.referFriend.steps.tiles.share.title,
            description: T.screens.referFriend.steps.tiles.share.description
        },
        {
            renderIcon: () => (
                <Icons.CalendarReferral size={iconSize} />
            ),
            title: T.screens.referFriend.steps.tiles.book.title,
            description: T.screens.referFriend.steps.tiles.book.description
        },
        {
            renderIcon: () => (
                <Icons.Wallet size={iconSize} />
            ),
            title: T.screens.referFriend.steps.tiles.earn.title,
            description: T.screens.referFriend.steps.tiles.earn.description
        }
    ]
}
