import { useField } from '@codegateinc/react-form-builder-v2'
import { useTranslations } from 'lib/hooks'
import { Nullable, SelectInputOption } from 'lib/types'
import { NewAddressFields } from './forms'

export const useNewAddressForm = isInitialAddressSetup => {
    const T = useTranslations()

    const postcode = useField<Nullable<SelectInputOption>>({
        key: NewAddressFields.Postcode,
        initialValue: null,
        isRequired: true,
        validateOnBlur: true,
        placeholder: isInitialAddressSetup
            ? T.screens.addNewAddress.formFields.postCode.label
            : T.screens.addNewAddress.formFields.postCode.placeholder,
        label: isInitialAddressSetup
            ? ''
            : T.screens.addNewAddress.formFields.postCode.label,
        validationRules: [
            {
                errorMessage: T.screens.addNewAddress.formFields.postCode.validation.isRequired,
                validate: Boolean
            },
            {
                errorMessage: T.screens.addNewAddress.formFields.postCode.validation.isTooShort,
                validate: value => {
                    if (!value) {
                        return false
                    }

                    return value.value.length > 5
                }
            }
        ]
    })

    const addressLine = useField<string>({
        key: NewAddressFields.AddressLine,
        initialValue: '',
        isRequired: true,
        validateOnBlur: true,
        placeholder: isInitialAddressSetup
            ? T.screens.addNewAddress.formFields.addressLine.label
            : T.screens.addNewAddress.formFields.addressLine.placeholder,
        label: isInitialAddressSetup
            ? ''
            : T.screens.addNewAddress.formFields.addressLine.label,
        validationRules: [
            {
                errorMessage: T.screens.addNewAddress.formFields.addressLine.validation.isRequired,
                validate: Boolean
            },
            {
                errorMessage: T.screens.addNewAddress.formFields.addressLine.validation.minValue,
                validate: value => value.length >= 10
            },
            {
                errorMessage: T.screens.addNewAddress.formFields.addressLine.validation.maxValue,
                validate: value => value.length <= 150
            }
        ],
        submitParser: value => value
            .split(' ')
            .filter(Boolean)
            .join(' ')
    })

    const block = useField<string>({
        key: NewAddressFields.Block,
        initialValue: '',
        isRequired: false,
        validateOnBlur: true,
        placeholder: T.screens.addNewAddress.formFields.block.placeholder,
        validationRules: [
            {
                errorMessage: T.screens.addNewAddress.formFields.block.validation.maxValue,
                validate: value => value.length <= 20 || value.length === 0
            }
        ]
    })

    const unitFloor = useField<string>({
        key: NewAddressFields.UnitFloor,
        initialValue: '',
        isRequired: true,
        validateOnBlur: true,
        placeholder: T.screens.addNewAddress.formFields.unitFloor.placeholder,
        label: isInitialAddressSetup
            ? ''
            : T.screens.addNewAddress.formFields.unitFloor.label,
        validationRules: [
            {
                errorMessage: T.screens.addNewAddress.formFields.unitFloor.validation.isRequired,
                validate: Boolean
            },
            {
                errorMessage: T.screens.addNewAddress.formFields.unitFloor.validation.maxValue,
                validate: value => value.length <= 10
            }
        ],
        liveParser: value => {
            if (!value) {
                return value
            }

            if (value.charAt(0) === ' ') {
                return value.slice(0, -1)
            }

            return value
        }
    })

    // todo for now not available
    // const propertySize = useField<string>({
    //     key: NewAddressFields.PropertySize,
    //     initialValue: '',
    //     isRequired: false,
    //     validateOnBlur: true,
    //     placeholder: T.screens.addNewAddress.formFields.propertySize.placeholder,
    //     label: T.screens.addNewAddress.formFields.propertySize.label
    // })
    //
    // const addressName = useField<string>({
    //     key: NewAddressFields.AddressName,
    //     initialValue: '',
    //     isRequired: false,
    //     validateOnBlur: true,
    //     placeholder: T.screens.addNewAddress.formFields.nameYourAddress.placeholder,
    //     label: T.screens.addNewAddress.formFields.nameYourAddress.label
    // })

    return {
        postcode,
        addressLine,
        unitFloor,
        block
    }
}
