import React, { useState } from 'react'
import { View } from 'react-native'
import Animated, { interpolateColor, runOnJS, runOnUI, useAnimatedReaction, useAnimatedStyle, useDerivedValue, withTiming } from 'react-native-reanimated'
import { Icons } from 'assets'
import { isNative } from 'lib/common'
import { createStyles } from 'lib/styles'
import { Touchable, Typography } from 'lib/components'
import { useAccordion, useStyles } from 'lib/hooks'

type ShareFaqItemProps = {
    title: string,
    description: string
}

export const ShareFaqItem: React.FunctionComponent<ShareFaqItemProps> = ({
    title,
    description
}) => {
    const [isFullyOpen, setFullyOpen] = useState(false)
    const { styles, theme } = useStyles(stylesheet)
    const { setHeight, animatedHeightStyle, aref, isOpened } = useAccordion()
    const progress = useDerivedValue(() => withTiming(isOpened.value ? 1 : 0))
    const animatedBorderStyles = useAnimatedStyle(() => ({
        borderBottomColor: interpolateColor(progress.value, [0, 1], [theme.colors.silver, theme.colors.orange])
    }))

    useAnimatedReaction(
        () => isOpened.value,
        isOpen => runOnJS(setFullyOpen)(isOpen)
    )

    return (
        <Animated.View style={[animatedBorderStyles, styles.wrapper]}>
            <Touchable
                style={styles.button}
                onPress={() => {
                    runOnUI(setHeight)()
                }}
            >
                <Typography.Title
                    bold
                    style={styles.title}
                >
                    {title}
                </Typography.Title>
                <View>
                    {isFullyOpen
                        ? (
                            <Icons.Minus
                                size={10}
                                forceColor={theme.colors.orange}
                            />
                        ) : (
                            <Icons.Chevron
                                size={10}
                                forceColor={theme.colors.orange}
                            />
                        )
                    }
                </View>
            </Touchable>
            <Animated.View style={animatedHeightStyle}>
                <View
                    ref={aref}
                    collapsable={false}
                    style={styles.accordionWrapper}
                >
                    <Typography.Regular
                        style={styles.description}
                        forceColor={theme.colors.darkGrey}
                    >
                        {description}
                    </Typography.Regular>
                </View>
            </Animated.View>
        </Animated.View>
    )
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        borderBottomWidth: 1,
        overflow: 'hidden'
    },
    title: {
        marginRight: theme.utils.gap(1)
    },
    button: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingVertical: theme.utils.gap(2),
        alignItems: 'center'
    },
    accordionWrapper: {
        left: 0,
        right: 0,
        position: isNative
            ? 'absolute'
            : undefined
    },
    description: {
        marginBottom: theme.utils.gap(2)
    }
}))
