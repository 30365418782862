import React from 'react'
import { Field } from '@codegateinc/react-form-builder-v2'
import { View } from 'react-native'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { Adapter, Button, Typography } from 'lib/components'
import { FrequencyOption, Nullable } from 'lib/types'

type BookingDateCalendarPickerProps = {
    minHoursNotice: number,
    disabled: boolean,
    description: string,
    continueDisabled: boolean,
    frequency: FrequencyOption,
    continueLabel: string,
    selectedDays: Record<string, Nullable<string>>,
    startingDateField: Field<string>,
    liteMode?: boolean,
    fullScreen?: boolean,
    isOneTime?: boolean,
    allowToggle?: boolean,
    maxDate?: string,
    isLoading?: boolean,
    isMultiSelect?: boolean,
    hideContinueButton?: boolean,
    onContinue: VoidFunction,
    onClickDateSelected?: VoidFunction,
    onChangeSelectedDays(days: Record<string, Nullable<string>>): void
}

export const BookingDateCalendarPicker: React.FunctionComponent<BookingDateCalendarPickerProps> = ({
    allowToggle,
    isOneTime,
    isMultiSelect,
    description,
    continueLabel,
    isLoading,
    startingDateField,
    minHoursNotice,
    selectedDays,
    maxDate,
    onChangeSelectedDays,
    disabled,
    onContinue,
    frequency,
    hideContinueButton,
    continueDisabled,
    liteMode,
    fullScreen,
    onClickDateSelected
}) => {
    const { styles } = useStyles(stylesheet)

    const onDateSelected = data => {
        if (onClickDateSelected && !fullScreen) {
            onClickDateSelected()
        }

        startingDateField.onChangeValue(data)
    }

    return (
        <View style={liteMode ? styles.calendarWithLiteMode : styles.calendar}>
            <View style={liteMode ? styles.calendarWrapperWithLiteMode : styles.calendarWrapper}>
                {!liteMode && (
                    <Typography.Regular
                        bold
                        style={styles.description}
                    >
                        {description}
                    </Typography.Regular>
                )}

                <Adapter.DatePicker
                    {...startingDateField}
                    liteMode={liteMode}
                    fullScreen={fullScreen}
                    isMultiSelect={isMultiSelect}
                    allowToggle={allowToggle}
                    maxDate={maxDate}
                    minHoursNotice={minHoursNotice}
                    selectedDays={selectedDays}
                    selectedDaysChangeValue={onChangeSelectedDays}
                    disabled={!isOneTime && continueDisabled}
                    frequency={frequency}
                    onChangeValue={onDateSelected}
                />
            </View>
            {!isOneTime && !hideContinueButton && (
                <Button
                    disabled={disabled}
                    isLoading={isLoading}
                    onPress={onContinue}
                    text={continueLabel}
                />
            )}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    calendar: {
        flex: {
            lg: 1,
            xs: undefined
        },
        minWidth: {
            lg: 300,
            xs: undefined
        },
        maxWidth: {
            lg: 360,
            xs: undefined
        },
        marginBottom: {
            lg: 0,
            xs: theme.utils.gap(4)
        }
    },
    calendarWithLiteMode: {
        flex: 1,
        backgroundColor: theme.colors.white,
        borderRadius: 8
    },
    calendarWrapper: {
        marginTop: theme.utils.gap(2)
    },
    calendarWrapperWithLiteMode: {
        marginTop: theme.utils.gap(0),
        borderWith: 1,
        borderColor: theme.colors.mouse,
        borderRadius: 8
    },
    description: {
        marginBottom: theme.utils.gap(2)
    }
}))
