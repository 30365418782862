import React from 'react'
import { View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { ScreenNames } from 'lib/routing'
import { createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { Button, SectionBox, Typography } from 'lib/components'
import { Segment } from 'lib/analytics'

export const FavouriteEmpty: React.FunctionComponent = () => {
    const T = useTranslations()
    const navigation = useNavigation()
    const { styles } = useStyles(stylesheet)

    return (
        <SectionBox>
            <View style={styles.container}>
                <View style={styles.text}>
                    <Typography.Title bold>
                        {T.screens.favourite.noFavourites}
                    </Typography.Title>
                </View>
                <Button
                    width={180}
                    noBackground
                    onPress={() => {
                        Segment.bookNowClicked({})
                        navigation.navigate(ScreenNames.AllServices)
                    }}
                    text={T.screens.favourite.bookNow}
                />
            </View>
        </SectionBox>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        alignItems: 'center',
        marginVertical: theme.utils.gap(6)
    },
    text: {
        marginBottom: theme.utils.gap(3)
    }
}))
