type InitTokenClientConfig = {
    client_id: string,
    scope: string,
    prompt: string,
    callback: (response: TokenResponse) => void
    error_callback: (response) => void
}

type TokenResponse = {
    access_token: string,
    expires_in: number,
    token_type: string,
    scope: string,
    refresh_token?: string
}

type InitTokenClientResponse = {
    requestAccessToken(): Promise<TokenResponse>
}

type GoogleAccountsOauth2 = {
    initTokenClient(config: InitTokenClientConfig): InitTokenClientResponse
}

export type Google= {
    accounts: {
        oauth2: GoogleAccountsOauth2
    }
}

export type GoogleProfile = {
    access_token: string,
    email: string,
    accountId: string,
    name: string
}

export enum GoogleWebErrorCodes {
    CANCELLED = 'Popup window closed',
    ACCESS_DENIED = 'access_denied',
}
