import React from 'react'
import { View, ViewStyle } from 'react-native'
import Animated from 'react-native-reanimated'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { JobStatus } from 'lib/types'
import { createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { Touchable, Typography } from 'lib/components'
import { Icons } from 'assets'
import { DetailsContactLink } from './DetailsContactLink'
import { JobContact } from './JobContact'
import { JobDetailsResponse } from '../types'

type MobilePaymentButtonProps = {
    setIsOpen: VoidFunction,
    animatedStyles: ViewStyle,
    job: JobDetailsResponse
}

export const MobilePaymentButton: React.FunctionComponent<MobilePaymentButtonProps> = ({
    job,
    setIsOpen,
    animatedStyles
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const insets = useSafeAreaInsets()
    const buttonHeight = job.job.status === JobStatus.Accepted ? 150 : 60 + insets.bottom

    return (
        <View
            style={{
                ...styles.bottom,
                height: buttonHeight
            }}
        >
            <Touchable
                disabled={job.job.status === JobStatus.Submitted}
                style={styles.buttonContent}
                onPress={setIsOpen}
            >
                <View style={styles.summary}>
                    <DetailsContactLink text={T.common.gotQuestion} />
                    {job.job.status !== JobStatus.Submitted && (
                        <View style={styles.row}>
                            <Typography.Regular bold>
                                {T.common.paymentDetails}
                            </Typography.Regular>
                            <Animated.View style={[styles.chevron, animatedStyles ]}>
                                <Icons.Chevron size={10} />
                            </Animated.View>
                        </View>
                    )}
                </View>
                {job.job.status === JobStatus.Accepted && (
                    <JobContact job={job.job} />
                )}
            </Touchable>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    continue: {
        marginVertical: theme.utils.gap(2)
    },
    row: {
        alignItems: 'center',
        flexDirection: 'row'
    },
    summary: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    chevron: {
        marginTop: -theme.utils.gap(1) / 2,
        marginLeft: theme.utils.gap(1)
    },
    bottom: {
        paddingHorizontal: theme.utils.gap(2),
        backgroundColor: theme.colors.white,
        borderTopLeftRadius: 12,
        borderTopRightRadius: 12,
        ...theme.utils.createShadow(0, 1, 20, 70)
    },
    buttonContent: {
        flex: 1,
        paddingVertical: theme.utils.gap(2),
        justifyContent: 'space-between',
        flexDirection: 'column'
    }
}))
