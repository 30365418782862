import React from 'react'
import { View } from 'react-native'
import { Children, Nullable } from 'lib/types'
import { dateHelpers } from 'lib/utils'
import { createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { BookingSummary } from './BookingSummary'
import { DeepCleaningFormShape } from '../forms'
import { GetPromotionResponse, PropertyCleaningValue } from '../types'

type DeepCleaningSummaryColumnProps = {
    price?: number,
    totalPrice?: Nullable<number>,
    originalPrice?: Nullable<number>,
    form: DeepCleaningFormShape,
    title?: string,
    couponText?: string,
    propertySizeLabel?: string,
    renderPriceComponent: () => Children,
    renderPriceSummaryComponent?: () => Children,
    renderExtraContent?: () => Children,
    promotionPackage?: Nullable<GetPromotionResponse>
}

export const DeepCleaningSummaryColumn: React.FunctionComponent<DeepCleaningSummaryColumnProps> = ({
    totalPrice,
    originalPrice,
    form,
    couponText,
    title,
    renderPriceComponent,
    renderPriceSummaryComponent,
    renderExtraContent,
    promotionPackage,
    propertySizeLabel
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const startingDate = form.startingDate
        ? dateHelpers.startingDate(form.startingDate)
        : ''
    const bookingTime = form.bookingTime
        ? ` ${dateHelpers.selectTimeLabel(form.bookingTime)}`
        : ''

    const bookingSummary = {
        [T.components.bookingSummary.serviceDate]: `${startingDate}`,
        [T.components.bookingSummary.serviceTime]: `${bookingTime}`,
        [T.components.bookingSummary.propertyType]: String(form.propertyType),
        [T.components.bookingSummary.propertyDeepClean]: form.propertyCleaning === PropertyCleaningValue.True ? T.common.yes : T.common.no
    }

    delete bookingSummary[T.components.bookingSummary.propertySize]

    if (propertySizeLabel && form.propertyCleaning) {
        Object.assign(bookingSummary, { [T.components.bookingSummary.propertySize]: `${propertySizeLabel}` })
    }

    return (
        <View style={styles.container}>
            <BookingSummary
                totalPrice={totalPrice}
                originalPrice={originalPrice}
                promotionPackage={promotionPackage}
                title={title}
                numberOfLines={2}
                couponText={couponText}
                renderPriceComponent={renderPriceComponent}
                renderPriceSummaryComponent={renderPriceSummaryComponent}
                bookingSummary={bookingSummary}
            />
            {renderExtraContent && renderExtraContent()}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        borderWidth: 1,
        borderRadius: 8,
        borderColor: theme.colors.silver,
        paddingTop: theme.utils.gap(2),
        paddingHorizontal: theme.utils.gap(2),
        marginBottom: theme.utils.gap(2),
        backgroundColor: theme.colors.white,
        ...theme.utils.createShadow(2, 0, 8, 4, 4, theme.colors.webShadow(0.04))
    }
}))
