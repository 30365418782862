import React, { useRef } from 'react'
import { View } from 'react-native'
import Animated, { useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated'
import { useNavigation } from '@react-navigation/native'
import { useClickAway, useStyles } from 'lib/hooks'
import { Icons } from 'assets'
import { useIsAuthorizedAtom, useUserAtom } from 'lib/atoms'
import { ScreenNames } from 'lib/routing'
import { createStyles } from 'lib/styles'
import { Touchable } from '../Touchable'
import { UserAvatar } from './UserAvatar'
import { Regular } from '../typography'
import { useGreetings } from './hooks'
import { UserMenu } from './UserMenu'

type UserDropdownMenuProps = {
    disabled?: boolean
}

export const UserDropdownMenu: React.FunctionComponent<UserDropdownMenuProps> = ({ disabled }) => {
    const { styles } = useStyles(stylesheet)
    const [user] = useUserAtom()
    const userDropdownMenuRef = useRef<View>(null)
    const [isAuthorized] = useIsAuthorizedAtom()
    const navigation = useNavigation()
    const isMenuVisible = useSharedValue<boolean>(false)
    const greetings = useGreetings(isAuthorized, user.handle)
    const animatedChevronStyles = useAnimatedStyle(() => ({
        transform: [
            {
                rotate: withTiming(`${isMenuVisible.value ? 180 : 0}deg`, {
                    duration: 200
                })
            }
        ]
    }))

    useClickAway({
        elementRef: userDropdownMenuRef,
        onClickAway: () => {
            isMenuVisible.value = false
        }
    })

    return (
        <View
            ref={userDropdownMenuRef}
            style={styles.container}
        >
            <Touchable
                disabled={disabled}
                activeOpacity={0.95}
                onPress={() => {
                    if (!isAuthorized) {
                        return navigation.navigate(ScreenNames.Auth)
                    }

                    isMenuVisible.value = !isMenuVisible.value
                }}
                style={styles.button}
            >
                <UserAvatar
                    isAuthorized={isAuthorized}
                    handle={user.handle}
                />
                <Regular
                    style={styles.text}
                    numberOfLines={1}
                >
                    {greetings}
                </Regular>
                <Animated.View
                    style={[
                        styles.chevron,
                        animatedChevronStyles
                    ]}
                >
                    {isAuthorized && !disabled && (
                        <Icons.Chevron size={14} />
                    )}
                </Animated.View>
            </Touchable>
            {isAuthorized && (
                <UserMenu isVisible={isMenuVisible} />
            )}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        flexDirection: 'row',
        width: 235
    },
    button: {
        maxWidth: 235,
        flexDirection: 'row',
        paddingHorizontal: theme.components.userDropDown.padding,
        borderLeftWidth: theme.components.userDropDown.borderWidth,
        borderColor: theme.components.userDropDown.borderColor,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.components.userDropDown.backgroundColor
    },
    text: {
        ...theme.components.header.link
    },
    chevron: {
        marginLeft: theme.utils.gap(2)
    }
}))
