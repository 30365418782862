import { useMemo } from 'react'
import { useForm } from '@codegateinc/react-form-builder-v2'
import { R } from 'lib/utils'
import { useSharedServiceConfigAtom, useUserAtom } from 'lib/atoms'
import { getHelpersList } from '../actions'
import { FavouritesFiltersShape, useServiceFiltersForm } from '../forms'

export const useConsumerFavourites = () => {
    const [user] = useUserAtom()
    const { data: response, isLoading } = getHelpersList()
    const [availableServices] = useSharedServiceConfigAtom()
    const enabledServicesIDs = availableServices.map(service => service.id)
    const { form } = useForm<FavouritesFiltersShape>(useServiceFiltersForm(), {
        onSuccess: R.T
    })
    const supplyFilterValue = form.service?.value ?? null

    const experts = useMemo(() => {
        if (!response?.data) {
            return {
                favourite: [],
                recent: []
            }
        }

        const favouriteExperts = user.consumer.per_service_account
            .filter(item => item.use_preferred_helper)
            .map(item => ({
                _id: item._id,
                date_time: null,
                supply: item.supply,
                supplier: item.preferred_helper,
                isFavourite: true
            }))

        const enabledHelpers = response.data.helper_list.filter(helper => enabledServicesIDs.includes(helper.supply.id))

        if (!supplyFilterValue) {
            return {
                favourite: favouriteExperts,
                recent: enabledHelpers.sort((firstDate, secondDate) => {
                    if (!secondDate.date_time || !firstDate.date_time) {
                        return 0
                    }

                    return secondDate.date_time.localeCompare(firstDate.date_time)
                })
            }
        }

        return {
            favourite: favouriteExperts.filter(supplier => supplier.supply.id === Number(supplyFilterValue)),
            recent: enabledHelpers.filter(supplier => supplier.supply.id === Number(supplyFilterValue))
        }
    }, [response, user, supplyFilterValue])

    return {
        form,
        favourites: experts.favourite,
        recent: experts.recent,
        isLoading,
        hasFavourites: experts.favourite.length > 0,
        hasExperts: experts.recent.length > 0
    }
}
