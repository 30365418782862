import React from 'react'
import { View } from 'react-native'
import { Field } from '@codegateinc/react-form-builder-v2'
import Animated, { SharedValue, useAnimatedReaction, useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated'
import { Icons } from 'assets'
import { useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { FormComponents, Touchable, Typography } from 'lib/components'
import { HandymanService } from 'lib/models'
import { handymanHooks } from '../../hooks'
import { HandymanServicesItemAccordion } from './HandymanServicesItemAccordion'
import { HandyManServicesSearchResults } from './HandyManServicesSearchResults'

interface HandymanServicesDropdownProps extends Field<Array<HandymanService>> {
    isDropDownVisible: SharedValue<boolean>,
    isDropdownOpen: boolean,
    onClose: VoidFunction,
    query: string,
    setQuery: (value: string) => void
}

export const HandymanServicesDropdown: React.FunctionComponent<HandymanServicesDropdownProps> = ({
    isDropDownVisible,
    isDropdownOpen,
    onClose,
    query,
    setQuery,
    ...fieldProps
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const openedIndex = useSharedValue(0)
    const handymanServices = handymanHooks.useHandymanServices()
    const animatedContainerStyles = useAnimatedStyle(() => ({
        opacity: withTiming(isDropDownVisible.value ? 1 : 0),
        display: isDropDownVisible.value ? 'flex' : 'none'
    }))

    useAnimatedReaction(
        () => isDropDownVisible.value,
        isVisible => {
            if (!isVisible) {
                openedIndex.value = 0
            }
        }
    )

    return (
        <Animated.View
            style={[
                styles.wrapper,
                animatedContainerStyles
            ]}
        >
            <View style={styles.container}>
                {isDropdownOpen && (
                    <View style={styles.searchWrapper}>
                        <FormComponents.SearchTextInput
                            value={query}
                            onQuery={setQuery}
                            placeholder={T.components.handymanServiceSelect.search}
                            leftIcon={() => (
                                <Icons.Search size={14}/>
                            )}
                        />
                    </View>
                )}
                {query
                    ? (
                        <HandyManServicesSearchResults
                            query={query}
                            field={fieldProps}
                        />
                    )
                    : handymanServices.map((service, index) => (
                        <HandymanServicesItemAccordion
                            key={index}
                            field={fieldProps}
                            label={service.label}
                            items={service.items}
                            openedIndex={openedIndex}
                            isDropdownOpen={isDropdownOpen}
                            index={index}
                        />
                    ))}
                <Touchable onPress={onClose}>
                    <View style={styles.closeButton}>
                        <Typography.Regular>
                            {`${T.common.close} `}
                            <View
                                style={{
                                    ...styles.closeIcon,
                                    transform: [
                                        {
                                            rotate: '-180deg'
                                        }
                                    ]
                                }}
                            >
                                <Icons.Chevron size={10} />
                            </View>
                        </Typography.Regular>
                    </View>
                </Touchable>
            </View>
        </Animated.View>
    )
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        left: 0,
        right: 0,
        top: 55,
        position: 'absolute',
        zIndex: theme.zIndex[10]
    },
    container: {
        flexDirection: 'column',
        backgroundColor: theme.colors.white,
        borderRadius: 4,
        borderWidth: 1,
        borderTopWidth: 0,
        borderColor: theme.colors.silver,
        marginBottom: theme.utils.gap(2),
        ...theme.utils.createShadow(10)
    },
    closeButton: {
        alignItems: 'center',
        paddingVertical: theme.utils.gap(1)
    },
    closeIcon: {
        marginLeft: theme.utils.gap(1)
    },
    searchWrapper: {
        paddingHorizontal: theme.utils.gap(2),
        paddingTop: theme.utils.gap(2)
    }
}))
