import React, { useState } from 'react'
import { View } from 'react-native'
import { PackageIdentifier, PestControlSession, bookingsHooks } from 'features/bookings'
import { useLocaleAtom } from 'lib/atoms'
import { CallToActionButton, Grid, PhotoGallery } from 'lib/components'
import { useFormatPrice, useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { JobStatus } from 'lib/types'
import { dateHelpers } from 'lib/utils'
import { JobDetailsResponse } from '../../types'
import { AdditionalInformation } from '../AdditionalInformation'
import { DetailsExpert } from '../DetailsExpert'
import { JobDetailsRow } from '../JobDetailsRow'
import { ServiceLocation } from '../ServiceLocation'

type PestControlDetailSectionsProps = {
    data: JobDetailsResponse
}

export const PestControlDetailSections: React.FunctionComponent<PestControlDetailSectionsProps> = ({
    data
}) => {
    const T = useTranslations()
    const [locale] = useLocaleAtom()
    const { styles } = useStyles(stylesheet)
    const formatPrice = useFormatPrice()
    const isSubmitted = data.job.status === JobStatus.Submitted
    const [isGalleryOpen, setIsGalleryOpen] = useState(false)
    const photos = data.job.photos
    const { job: { date_time, beginning_date, service_type_pestcontrol, promotion, bookingSummary } } = data
    const propertyTypes = bookingsHooks.pestControlHooks.useGetPropertyType()
    const matchingPropertyType = propertyTypes.find(propertyType => propertyType.value === service_type_pestcontrol?.at(0)?.area_type)

    return (
        <React.Fragment>
            {!isSubmitted ? (
                <DetailsExpert job={data} />
            ) : (
                <JobDetailsRow
                    leftTitle={T.screens.jobDetails.sections.serviceDate}
                    leftMessage={dateHelpers.getFullBookingDate(date_time || beginning_date, locale)}
                    rightTitle={T.screens.jobDetails.sections.propertyType}
                    rightMessage={matchingPropertyType?.label || service_type_pestcontrol?.at(0)?.area_type || '-'}
                />
            )}
            {(service_type_pestcontrol as Array<PestControlSession>)
                ?.filter(service => service?.title)
                ?.map((item, index) => (
                    <JobDetailsRow
                        key={item.title}
                        leftTitle={index === 0 ? T.screens.jobDetails.sections.services : ''}
                        leftMessage={item.title}
                        rightTitle={index === 0 ? T.screens.jobDetails.sections.sessions : ''}
                        rightMessage={`${item.number_of_treatments}`}
                        style={styles.serviceRow}
                    />
                ))
            }
            <Grid.Gap gapBottom={3}/>
            {(service_type_pestcontrol as Array<PackageIdentifier>)
                ?.filter(service => service?.package_type)
                ?.map((item, index) => (
                    <JobDetailsRow
                        key={item.package_name}
                        leftTitle={index === 0 ? T.screens.jobDetails.sections.packageName : ''}
                        leftMessage={item.package_name}
                        rightTitle={index === 0 ? T.screens.jobDetails.sections.frequency : ''}
                        rightMessage={item.per_annum}
                        style={styles.serviceRow}
                    />
                ))
            }
            <Grid.Gap gapBottom={3}/>
            <JobDetailsRow
                leftTitle={T.screens.jobDetails.sections.promotionCode}
                leftMessage={promotion?.code || '-'}
                rightTitle={T.screens.jobDetails.sections.totalAmount}
                rightMessage={formatPrice(bookingSummary?.total?.value || 0)}
            />
            <AdditionalInformation
                job={data}
                linesLimit={3}
            />
            {photos.length > 0 && (
                <View style={styles.buttonWrapper}>
                    <CallToActionButton
                        onPress={() => setIsGalleryOpen(true)}
                        text={T.common.viewImages}
                    />
                </View>
            )}
            <PhotoGallery
                photos={photos}
                isVisible={isGalleryOpen}
                onClose={() => setIsGalleryOpen(false)}
            />
            <ServiceLocation job={data.job} />
        </React.Fragment>
    )
}

const stylesheet = createStyles(theme => ({
    buttonWrapper: {
        width: {
            lg: 240,
            xs: undefined
        },
        marginBottom: theme.utils.gap(2)
    },
    serviceRow: {
        marginBottom: 0
    }
}))
