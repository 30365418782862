import React from 'react'
import { View } from 'react-native'
import { createStyles } from 'lib/styles'
import { Typography } from 'lib/components'
import { usePrice, useStyles, useTranslations } from 'lib/hooks'
import { CustomFonts, Nullable } from 'lib/types'
import { GetPromotionResponse } from '../types'

type PromotionSummaryProps = {
    promotionPackage?: Nullable<GetPromotionResponse>,
    totalPrice?: Nullable<number>,
    originalPrice?: Nullable<number>
}

export const PromotionSummary: React.FunctionComponent<PromotionSummaryProps> = ({
    promotionPackage,
    totalPrice,
    originalPrice
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    // The promotions with type percent do not have price value. We need to calculate it
    const promotionPrice = promotionPackage?.promotion?.package?.price
    const discountValue = promotionPrice ?
        promotionPrice : (originalPrice || 0) - (totalPrice || 0)
    const formattedDiscountValue = usePrice(discountValue, true)
    const promotionCode = promotionPackage?.promotion?.promo_code

    return promotionCode && discountValue > 0? (
        <View style={styles.promotionContainer}>
            <View style={styles.promotion}>
                <Typography.Label>
                    {T.common.discountCode}:
                </Typography.Label>
                <Typography.Label style={styles.promotionCode}>
                    {promotionCode}
                </Typography.Label>
            </View>
            <Typography.Label style={styles.discountValue}>
                -{formattedDiscountValue}
            </Typography.Label>
        </View>
    ) : null
}

const stylesheet = createStyles(theme => ({
    promotionContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',
        paddingBottom: theme.utils.gap(2)
    },
    promotion: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    promotionCode: {
        fontFamily: CustomFonts.Roboto700,
        marginLeft: theme.utils.gap(0.5),
        textTransform: 'uppercase'
    },
    discountValue: {
        color: theme.colors.flame,
        fontFamily: CustomFonts.Roboto500
    }
}))
