import React from 'react'
import { Path, Rect } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const AnyTime: React.FunctionComponent<IconProps> = props => (
    <Icon viewBox="0 0 57 55" {...props}>
        <Path d="M23.7996 54.3816C36.9437 54.3816 47.5992 43.7262 47.5992 30.5821C47.5992 17.4379 36.9437 6.78247 23.7996 6.78247C10.6554 6.78247 0 17.4379 0 30.5821C0 43.7262 10.6554 54.3816 23.7996 54.3816Z" fill="#ffffff"/>
        <Path d="M25.647 18.6795L7.11849 21.6174C6.81736 21.6652 6.61195 21.948 6.6597 22.2491L9.61694 40.8994C9.66469 41.2005 9.94751 41.4059 10.2486 41.3582L28.7772 38.4202C29.0783 38.3725 29.2837 38.0896 29.236 37.7885L26.2787 19.1383C26.231 18.8371 25.9482 18.6317 25.647 18.6795Z" fill="#ffffff" stroke="#F9B130" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M8.74723 24.8338L8.20996 21.4426" stroke="#F9B130" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M9.16015 27.4462L9.05664 26.7956" stroke="#F9B130" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M29.5718 18.0579L11.0433 20.9958C10.7422 21.0436 10.5368 21.3264 10.5845 21.6275L13.541 40.2729C13.5887 40.574 13.8715 40.7794 14.1727 40.7317L32.7012 37.7937C33.0024 37.746 33.2078 37.4632 33.16 37.162L30.2035 18.5167C30.1558 18.2155 29.873 18.0101 29.5718 18.0579Z" fill="#FCF8EA"/>
        <Path d="M29.5718 18.0579L11.0433 20.9958C10.7422 21.0436 10.5368 21.3264 10.5845 21.6275L13.541 40.2729C13.5887 40.574 13.8715 40.7794 14.1727 40.7317L32.7012 37.7937C33.0024 37.746 33.2078 37.4632 33.16 37.162L30.2035 18.5167C30.1558 18.2155 29.873 18.0101 29.5718 18.0579Z" stroke="#F9B130" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M11.9951 41.0751L10.96 34.5392" stroke="#F9B130" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M31.3811 25.933C32.0712 30.2657 36.1377 33.2231 40.4703 32.533L20.8526 35.6433C16.52 36.3284 12.4486 33.3759 11.7634 29.0433L10.5854 21.6201C10.5361 21.3194 10.7431 21.0384 11.0438 20.9892L29.5721 18.0514C29.8728 18.0021 30.1538 18.2092 30.2031 18.5098L31.3811 25.933Z" fill="#ffffff"/>
        <Path d="M30.9868 23.4438L11.3691 26.5541L10.5854 21.6201C10.5361 21.3194 10.7431 21.0384 11.0438 20.9892L29.5721 18.0514C29.8728 18.0021 30.1538 18.2092 30.2031 18.5098L30.9868 23.4438Z" fill="#FCF8EA"/>
        <Path d="M31.3811 25.933C32.0712 30.2657 36.1377 33.2231 40.4703 32.533L20.8526 35.6433C16.52 36.3284 12.4486 33.3759 11.7634 29.0433L10.5854 21.6201C10.5361 21.3194 10.7431 21.0384 11.0438 20.9892L29.5721 18.0514C29.8728 18.0021 30.1538 18.2092 30.2031 18.5098L31.3811 25.933Z" stroke="#F9B130" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M20.0535 18.6232C20.0979 18.9337 20.1817 19.2344 20.3 19.5203L21.4336 19.3429C21.2858 19.0619 21.1872 18.7612 21.1428 18.4507C20.8816 16.7945 22.0153 15.2419 23.6714 14.9806C25.3276 14.7194 26.8803 15.8531 27.1415 17.5092C27.2696 18.3176 27.0626 19.1408 26.5747 19.7963C26.481 19.9146 26.4366 20.0724 26.4564 20.2252C26.5056 20.5258 26.7915 20.7329 27.0922 20.6836C27.2351 20.6589 27.3633 20.5801 27.4471 20.4667C28.2604 19.3921 28.497 17.9874 28.078 16.7058C27.3584 14.537 25.0171 13.354 22.8483 14.0737C20.9309 14.7095 19.7528 16.6319 20.0535 18.6281V18.6232Z" fill="#F9B130"/>
        <Path d="M14.0593 19.5745C14.1037 19.8851 14.1875 20.1857 14.3058 20.4716L15.4394 20.2942C15.2916 20.0132 15.193 19.7125 15.1486 19.402C14.8874 17.7458 16.0211 16.1932 17.6772 15.9319C19.3334 15.6707 20.8861 16.8044 21.1473 18.4606C21.2755 19.2689 21.0684 20.0921 20.5805 20.7476C20.3882 20.9842 20.4227 21.3293 20.6593 21.5215C20.8959 21.7137 21.241 21.6792 21.4332 21.4426C21.4431 21.4328 21.448 21.4229 21.4578 21.4081C22.2711 20.3336 22.5077 18.9288 22.0888 17.6473C21.3691 15.4785 19.0278 14.2955 16.859 15.0151C14.9416 15.651 13.7636 17.5733 14.0642 19.5696L14.0593 19.5745Z" fill="#F9B130"/>
        <Path d="M8.06615 20.5258C8.11051 20.8364 8.1943 21.137 8.3126 21.4229L9.44628 21.2455C9.29841 20.9645 9.19983 20.6638 9.15547 20.3533C8.89423 18.6971 10.023 17.1445 11.6792 16.8833C13.3353 16.622 14.888 17.7508 15.1492 19.4069C15.2774 20.2153 15.0703 21.0434 14.5824 21.6989C14.3901 21.9355 14.4246 22.2806 14.6612 22.4728C14.8978 22.665 15.2429 22.6305 15.4351 22.3939C15.445 22.3841 15.4499 22.3742 15.4597 22.3594C16.273 21.2849 16.5096 19.8801 16.0956 18.5986C15.376 16.4298 13.0346 15.2468 10.8659 15.9664C8.94845 16.6023 7.7704 18.5246 8.07107 20.5209L8.06615 20.5258Z" fill="#F9B130"/>
        <Path d="M11.3691 26.559L30.9868 23.4438" stroke="#F9B130" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M16.7656 25.7014L17.1452 28.092C17.8204 32.4443 21.8771 35.4363 26.2393 34.7955" stroke="#F9B130" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M21.7197 24.9176L22.0795 27.1949C22.7745 31.5916 26.8657 34.618 31.2723 33.997" stroke="#F9B130" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M26.8652 24.0994L27.2053 26.2436C27.8905 30.5663 31.7499 33.6963 35.9298 33.2576" stroke="#F9B130" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M12.1826 30.6206L31.5538 27.0421" stroke="#F9B130" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M14.5039 33.8885L33.4857 30.1819" stroke="#F9B130" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M21.8479 45.6394C26.6908 45.6394 30.6167 45.2532 30.6167 44.7768C30.6167 44.3004 26.6908 43.9142 21.8479 43.9142C17.005 43.9142 13.0791 44.3004 13.0791 44.7768C13.0791 45.2532 17.005 45.6394 21.8479 45.6394Z" fill="#FCF8EA"/>
        <Path d="M40.6415 5.16193C34.9435 5.6992 30.7636 10.7564 31.3009 16.4544C31.4044 17.5536 31.6853 18.6281 32.129 19.6386L29.6299 25.3514C29.5166 25.6126 29.6349 25.9182 29.8961 26.0316C30.0095 26.0809 30.1327 26.0858 30.251 26.0562L36.2004 24.3064C41.0752 27.3082 47.4633 25.7851 50.4602 20.9103C53.462 16.0355 51.9389 9.64738 47.064 6.65051C45.1417 5.46754 42.8891 4.94505 40.6415 5.16193Z" fill="#ffffff"/>
        <Path d="M40.6415 5.16193C34.9435 5.6992 30.7636 10.7564 31.3009 16.4544C31.4044 17.5536 31.6853 18.6281 32.129 19.6386L29.6299 25.3514C29.5166 25.6126 29.6349 25.9182 29.8961 26.0316C30.0095 26.0809 30.1327 26.0858 30.251 26.0562L36.2004 24.3064C41.0752 27.3082 47.4633 25.7851 50.4602 20.9103C53.462 16.0355 51.9389 9.64738 47.064 6.65051C45.1417 5.46754 42.8891 4.94505 40.6415 5.16193Z" stroke="#F9B130" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M40.6415 5.16193C34.9435 5.6992 30.7636 10.7564 31.3009 16.4544C31.4044 17.5536 31.6853 18.6281 32.129 19.6386L29.6299 25.3514C29.5166 25.6126 29.6349 25.9182 29.8961 26.0316C30.0095 26.0809 30.1327 26.0858 30.251 26.0562L36.2004 24.3064C41.0752 27.3082 47.4633 25.7851 50.4602 20.9103C53.462 16.0355 51.9389 9.64738 47.064 6.65051C45.1417 5.46754 42.8891 4.94505 40.6415 5.16193Z" stroke="#F9B130" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
        <Path d="M40.0883 15.8672C40.4531 15.9898 40.5485 16.4614 40.2599 16.7161L36.3056 20.2059C36.017 20.4606 35.5609 20.3074 35.4847 19.9301L34.4396 14.7607C34.3633 14.3834 34.724 14.0651 35.0889 14.1876L40.0883 15.8672Z" fill="#FCF8EA" stroke="#F9B130" strokeLinejoin="round"/>
        <Rect x="41.6752" y="12.4709" width="2" height="7" transform="rotate(-10.6269 41.6752 12.4709)" fill="#FCF8EA" stroke="#F9B130" strokeLinejoin="round"/>
        <Rect x="45.6068" y="11.7332" width="2" height="7" transform="rotate(-10.6269 45.6068 11.7332)" fill="#FCF8EA" stroke="#F9B130" strokeLinejoin="round"/>
    </Icon>
)

