import { R } from 'lib/utils'
import { JobCancelReason } from 'lib/models'
import { SelectInputOption } from 'lib/types'
import { JobDetailsResponse } from 'features/jobs/types'

export const appendTheReasonOther = (cancellationReasons: Array<JobCancelReason> = []) => {
    const otherReason = { id: 0, reason: 'Other' }

    return R.findIndex(R.propEq('id', otherReason.id), cancellationReasons) !== -1 ?
        cancellationReasons
        : R.append(otherReason, cancellationReasons)
}

export const decodeReasonWithNonZeroId = (encodedReason: string): JobCancelReason | null => {
    try {
        const reason = JSON.parse(encodedReason) as JobCancelReason

        return reason.id ? reason : null
    } catch {
        return null
    }
}

export const getCancelReason = (encodedReason: string, customReason:string = ''): JobCancelReason =>
    decodeReasonWithNonZeroId(encodedReason) || { id: 0, reason: customReason.trim() }

export const getCancelSubscriptionTypes = (jobDetail: JobDetailsResponse): Array<SelectInputOption> => jobDetail?.cancelPackageOptions?.map(item => ({ value: item, label: item })) || []

