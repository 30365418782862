import React, { useState, useEffect } from 'react'
import { useGetErrorMessage } from 'lib/hooks'
import { isNative } from 'lib/common'
import {
    usePromotionAtom,
    useResetPromotionAtom,
    useResetPromotionPopupShownAtAtom,
    useShouldClearPromotionPopupShownAtAtom,
    useRequestEnableNotificationModalShowAtom,
    useUserAtom,
    useUserTokenAtom
} from 'lib/atoms'
import { User } from 'lib/models'
import { cookieUtils } from 'features/auth'
import { SubscribePromotionAction } from 'lib/types/promotion'
import { updatePromotionalNotifications } from 'features/user/actions'
import { MarketingConsentModal } from './MarketingConsentModal'
import { MarketingConsentPopup } from './MarketingConsentPopup'

export type ReminderProps = {
    onPressGetPromotion: VoidFunction,
    onCloseModal: VoidFunction,
    isLoading: boolean,
    isOpen: boolean,
    error: string,
    user?: User
}

export enum ReminderType {
    Popup = 'popup',
    Modal = 'modal'
}

type MarketingConsentReminderProps = {
    type: ReminderType
}

export const MarketingConsentReminder: React.FunctionComponent<MarketingConsentReminderProps> =
    ({ type = ReminderType.Modal }) => {
        const [error, setError] = useState<string>('')
        const [isOpen, setIsOpen] = useState<boolean>(false)
        const [promotionAction, setPromotionAction] = usePromotionAtom()
        const [, resetPromotionPopupShownAtAtom] = useResetPromotionPopupShownAtAtom()
        const [shouldClearPromotionPopupShownAtAtom, setShouldClearPromotionPopupShownAtAtom] = useShouldClearPromotionPopupShownAtAtom()
        const [user, setUser] = useUserAtom()
        const [token] = useUserTokenAtom()
        const [, resetPromotionAction] = useResetPromotionAtom()
        const [requestEnableNotificationModalShow] = useRequestEnableNotificationModalShowAtom()
        const { getErrorMessage } = useGetErrorMessage()
        const { mutate: onMarketingConsent, isLoading } = updatePromotionalNotifications()
        const isModal = type === ReminderType.Modal

        useEffect(() => {
            const shouldShowModal = isModal && promotionAction === SubscribePromotionAction.ShouldShowMarketingConsentModal
            const shouldShowPopup = !isModal && promotionAction === SubscribePromotionAction.ShouldShowMarketingConsentPopup

            if ((shouldShowModal || shouldShowPopup) && !requestEnableNotificationModalShow) {
                setIsOpen(true)
                resetPromotionAction()
            }
        }, [promotionAction, requestEnableNotificationModalShow])

        const onPressGetPromotion = () => {
            onMarketingConsent({
                token,
                accepted: true
            },
            {
                onSuccess: () => {
                    setUser(prevValue => ({
                        ...prevValue,
                        consumer: {
                            ...prevValue?.consumer,
                            marketing_consent: {
                                ...prevValue?.consumer?.marketing_consent,
                                accepted: true
                            }
                        }
                    }))

                    cookieUtils.setMarketingConsentAcceptedCookie(true)
                    setIsOpen(false)
                    setPromotionAction(SubscribePromotionAction.ShouldShowPromotionModal)
                },
                onError: response => setError(getErrorMessage(response))
            })
        }

        const onCloseModal = () => {
            setIsOpen(false)

            if (shouldClearPromotionPopupShownAtAtom) {
                setShouldClearPromotionPopupShownAtAtom(false)
                resetPromotionPopupShownAtAtom()
            }

        }

        const reminderProps: ReminderProps = {
            onPressGetPromotion,
            onCloseModal,
            isLoading,
            isOpen,
            error,
            user
        }

        if (!isNative) {
            return null
        }

        return isModal ? (
            <MarketingConsentModal {...reminderProps}/>
        ) :
            <MarketingConsentPopup {...reminderProps}/>
    }
