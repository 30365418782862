import React, { useState } from 'react'
import { View } from 'react-native'
import { Field } from '@codegateinc/react-form-builder-v2'
import { isNative } from 'lib/common'
import { useNavigation } from '@react-navigation/native'
import { Nullable, SelectInputOption, SupplyID, VoidFunction } from 'lib/types'
import { useAddressAtom, useFilesAtom, useIsAuthorizedAtom, useUserAtom } from 'lib/atoms'
import { PaymentType } from 'lib/models'
import { NewAddressShape } from 'features/user'
import { Segment, segmentUtils } from 'lib/analytics'
import { Breakpoint, createStyles } from 'lib/styles'
import { useIsWithinBreakpoints, useStyles, useTranslations } from 'lib/hooks'
import { Address, Button, CreditCard, MediaQuery, PolicyLinks, SendPay, Typography, UploadedPhoto } from 'lib/components'
import { GetPromotionResponse, JobQuotationResponse } from '../types'
import { ServiceLayout } from './ServiceLayout'
import { BookingCancellationFees } from './BookingCancellationFees'
import { HandymanFormShape, HandymanAddonsShape } from '../forms'
import { useHandymanBreadcrumbs } from '../hooks'
import { PromotionCodeInput } from './PromotionCodeInput'
import { Rated } from './Rated'
import { MobileSummary } from './MobileSummary'
import { SummaryNewAddressForm } from './SummaryNewAddressForm'
import { VisitCharges } from './VisitCharges'
import { PriceComponent } from './PriceComponent'
import { HandymanSummaryColumn } from './HandymanSummaryColumn'
import { HandymanHelpModalContent } from './HandymanHelpModalContent'
import { ServiceDescription } from './ServiceDescription'
import { BookingAuth } from './unauthorized'
import { getServiceStaticConfig } from '../utils'

type HandymanSummaryViewProps = {
    disabled: boolean,
    selectedPaymentMethod: PaymentType,
    form: Record<keyof HandymanAddonsShape, Field<boolean & string>>
    addressForm: Record<keyof NewAddressShape, Field<SelectInputOption & string>>,
    formShape: HandymanFormShape,
    isLoading: boolean,
    totalPrice: number,
    submit: VoidFunction,
    isRecurring?: boolean,
    jobQuotation?: JobQuotationResponse,
    onUploadSuccess: (data: Array<UploadedPhoto>) => void,
    setPaymentMethod: (value: PaymentType) => void,
    promotionPackage: Nullable<GetPromotionResponse>,
    originalPrice?: Nullable<number>,
    requestPromotion: (code: string) => void,
    onRemovePromoCode: VoidFunction,
    fetchPostalCodes(value: string): Promise<Array<SelectInputOption>>
}

export const HandymanSummaryView: React.FunctionComponent<HandymanSummaryViewProps> = ({
    form,
    disabled,
    formShape,
    totalPrice,
    originalPrice,
    requestPromotion,
    promotionPackage,
    isLoading,
    addressForm,
    submit,
    isRecurring,
    setPaymentMethod,
    onUploadSuccess,
    selectedPaymentMethod,
    fetchPostalCodes,
    onRemovePromoCode,
    jobQuotation
}) => {
    const T = useTranslations()
    const [user] = useUserAtom()
    const [files] = useFilesAtom()
    const [address] = useAddressAtom()
    const navigation = useNavigation()
    const { styles } = useStyles(stylesheet)
    const breadcrumbs = useHandymanBreadcrumbs()
    const [isHelpModalOpen, setHelpModalOpen] = useState(false)
    const [isNewCardModalOpen, setIsNewCardModalOpen] = useState(false)
    const [isAuthorized] = useIsAuthorizedAtom()
    const config = getServiceStaticConfig(SupplyID.Handyman)
    const { pricing: { visitCharges: { details: visitChargesDetails } } } = config
    const [authenticationView, setAuthenticationView] = useState(!isAuthorized)
    const notEnoughCredits = selectedPaymentMethod === PaymentType.Credits && user.consumer.balance_credit < totalPrice
    const isMobile = useIsWithinBreakpoints(Breakpoint.XS, Breakpoint.MD)
    const confirmDisabled = isAuthorized
        ? disabled
        : false
    const handleConfirm = () => {
        if (!isAuthorized) {
            return setAuthenticationView(true)
        }

        submit()
    }

    const handleSelectPaymentMethod = (value: PaymentType) => {
        Segment.bookingPaymentSwitched({
            selectedPaymentMethod: segmentUtils.getSelectedPaymentMethod(value)
        })

        setPaymentMethod(value)
    }

    return (
        <ServiceLayout
            onInfoPress={() => setHelpModalOpen(true)}
            title={T.screens.handyman.title}
            withBackground
            authenticationView={authenticationView}
            onBackFromDetails={() => {
                navigation.goBack()
            }}
            breadcrumbs={breadcrumbs}
            contentColumn={authenticationView
                ? (
                    <BookingAuth
                        supplyId={SupplyID.Handyman}
                        onUploadSuccess={onUploadSuccess}
                        notEnoughCredits={notEnoughCredits}
                        selectedPaymentMethod={selectedPaymentMethod}
                        setPaymentMethod={setPaymentMethod}
                    />
                ) : (
                    <View>
                        {isNative && (
                            <ServiceDescription
                                isHelpModalOpen={isHelpModalOpen}
                                setHelpModalOpen={setHelpModalOpen}
                                title={T.screens.handyman.title}
                                modalContent={(
                                    <HandymanHelpModalContent />
                                )}
                                description={T.screens.handyman.serviceDescription}
                            />
                        )}
                        <View style={styles.wrapper}>
                            {address.postcode && (
                                <Address
                                    disabled={isLoading}
                                    title={T.screens.handymanSummary.serviceLocation}
                                />
                            )}
                            {!address.postcode && isAuthorized && (
                                <SummaryNewAddressForm
                                    form={addressForm}
                                    isLoading={isLoading}
                                    fetchPostalCodes={fetchPostalCodes}
                                />
                            )}
                            <View style={styles.paymentsContainer}>
                                <Typography.Title bold>
                                    {T.screens.payment.paymentMethod}
                                </Typography.Title>
                                <View style={styles.description}>
                                    <Typography.Regular>
                                        {T.screens.payment.paymentMethodDescription}
                                    </Typography.Regular>
                                </View>
                                <CreditCard
                                    onSelect={handleSelectPaymentMethod}
                                    isModalOpen={isNewCardModalOpen}
                                    setIsModalOpen={setIsNewCardModalOpen}
                                    selectedPaymentMethod={selectedPaymentMethod}
                                    customStyles={styles.noBorder}
                                />
                                {!isRecurring && (
                                    <SendPay
                                        customStyles={styles.noBorder}
                                        onSelect={handleSelectPaymentMethod}
                                        notEnoughCredits={notEnoughCredits}
                                        selectedPaymentMethod={selectedPaymentMethod}
                                    />
                                )}
                            </View>
                        </View>
                        {isMobile && (
                            <PolicyLinks />
                        )}
                    </View>
                )
            }
            summaryColumn={(
                <MediaQuery.Visible from={Breakpoint.LG}>
                    {(!isAuthorized && authenticationView) && (
                        <View>
                            <Address disabled={isLoading} />
                        </View>
                    )}
                    <HandymanSummaryColumn
                        totalPrice={totalPrice}
                        originalPrice={originalPrice}
                        promotionPackage={promotionPackage}
                        title={T.common.bookingSummary}
                        renderPriceComponent={() => (
                            <PriceComponent price={totalPrice} />
                        )}
                        form={formShape}
                        couponText={promotionPackage
                            ? promotionPackage.promotion.message_to_client
                            : undefined
                        }
                        jobQuotation={jobQuotation}
                        renderExtraContent={() => (
                            <View>
                                <VisitCharges
                                    chargesMessage={jobQuotation?.visitCharges.subtitle}
                                    labelText={jobQuotation?.visitCharges.title}
                                    modalTitle={visitChargesDetails.title}
                                    messageText={visitChargesDetails.description}
                                />
                                <PromotionCodeInput
                                    showBillingMessage
                                    promoCode={form.promoCode}
                                    isLoading={isLoading}
                                    promotionPackage={promotionPackage}
                                    onRemovePromoCode={onRemovePromoCode}
                                    requestPromotion={requestPromotion}
                                />
                                <PolicyLinks />
                            </View>
                        )}
                    />
                    {(!authenticationView || isAuthorized) && (
                        <Button
                            disabled={notEnoughCredits || confirmDisabled}
                            isLoading={isLoading || Boolean(files.length)}
                            onPress={handleConfirm}
                            text={T.common.confirm}
                        />
                    )}
                    <BookingCancellationFees />
                </MediaQuery.Visible>
            )}
            footer={(
                <MediaQuery.Hidden from={Breakpoint.LG}>
                    <MobileSummary
                        authenticationView={authenticationView && !isAuthorized}
                        submit={handleConfirm}
                        submitText={T.common.confirm}
                        priceLabel={T.common.totalPrice}
                        price={totalPrice}
                        isLoading={isLoading || Boolean(files.length)}
                        disabled={notEnoughCredits || confirmDisabled}
                        onRemovePromoCode={onRemovePromoCode}
                        priceBeforeDiscount={originalPrice}
                        promotionPackage={promotionPackage}
                        requestPromotion={requestPromotion}
                        promoCode={form.promoCode}
                        content={(
                            <React.Fragment>
                                <View>
                                    {address.postcode && (
                                        <Address disabled={isLoading} />
                                    )}
                                </View>
                                <HandymanSummaryColumn
                                    totalPrice={totalPrice}
                                    originalPrice={originalPrice}
                                    promotionPackage={promotionPackage}
                                    renderPriceComponent={() => (
                                        <PriceComponent price={totalPrice} />
                                    )}
                                    form={formShape}
                                    couponText={promotionPackage
                                        ? promotionPackage.promotion.message_to_client
                                        : undefined
                                    }
                                    jobQuotation={jobQuotation}
                                    renderExtraContent={() => (
                                        <View>
                                            <VisitCharges
                                                chargesMessage={jobQuotation?.visitCharges.subtitle}
                                                labelText={jobQuotation?.visitCharges.title}
                                                modalTitle={visitChargesDetails.title}
                                                messageText={visitChargesDetails.description}
                                            />
                                            {/* remove this check after guest booking experiment  */}
                                            {!isAuthorized && (
                                                <PromotionCodeInput
                                                    showBillingMessage
                                                    promoCode={form.promoCode}
                                                    isLoading={isLoading}
                                                    promotionPackage={promotionPackage}
                                                    onRemovePromoCode={onRemovePromoCode}
                                                    requestPromotion={requestPromotion}
                                                />
                                            )}
                                            <PolicyLinks />
                                        </View>
                                    )}
                                />
                                <Rated />
                                <BookingCancellationFees />
                            </React.Fragment>
                        )}
                    />
                </MediaQuery.Hidden>
            )}
        />
    )
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        marginTop: {
            xs: 0,
            lg: -theme.utils.gap(4)
        },
        zIndex: theme.zIndex[10]
    },
    selectWrapper: {
        zIndex: theme.zIndex[10],
        marginTop: -theme.utils.gap(2)
    },
    addonsRow: {
        flexDirection: {
            lg: 'row',
            xs: 'column'
        }
    },
    spacer: {
        width: theme.utils.gap(2)
    },
    paymentsContainer: {
        borderRadius: 8,
        borderWidth: 1,
        borderColor: theme.colors.silver,
        paddingVertical: theme.utils.gap(5) / 2,
        paddingHorizontal: {
            lg: theme.utils.gap(3),
            xs: theme.utils.gap(2)
        },
        marginBottom: theme.utils.gap(4),
        backgroundColor: theme.colors.white,
        ...theme.utils.createShadow(2, 0, 8, 4, 4, theme.colors.webShadow(0.04))
    },
    description: {
        marginTop: theme.utils.gap(1),
        marginBottom: theme.utils.gap(2)
    },
    noBorder: {
        borderBottomWidth: 0
    }
}))
