import React from 'react'
import { View } from 'react-native'
import { usePrice, useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { Typography } from 'lib/components'
import { Promotion } from 'lib/models'
import { CustomFonts, Nullable } from 'lib/types'

type TotalPriceComponentProps = {
    promotion?: Promotion,
    perHour?: boolean,
    customText?: string,
    withExtraHours?: boolean,
    totalPrice: Nullable<number>,
    originalPrice?: Nullable<number>
}

export const TotalPriceComponent: React.FunctionComponent<TotalPriceComponentProps> = ({
    totalPrice,
    originalPrice,
    perHour,
    customText
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const formattedPrice = usePrice(totalPrice || 0, true)
    const formattedOriginalPrice = usePrice(originalPrice || 0, true)
    const shouldShowOriginalPrice = originalPrice && totalPrice && totalPrice < originalPrice

    return (
        <React.Fragment>
            <Typography.Regular bold>
                {customText ?? T.common.total}
            </Typography.Regular>

            {
                shouldShowOriginalPrice ? (
                    <View style={styles.row}>
                        <Typography.Regular style={styles.originalPrice}>
                            {perHour
                                ? ` ${formattedOriginalPrice} / ${T.common.hour}`
                                : formattedOriginalPrice
                            }
                        </Typography.Regular>
                    </View>
                ) : null
            }

            <View style={styles.row}>
                <Typography.Regular bold>
                    { totalPrice ? perHour
                        ? ` ${formattedPrice} / ${T.common.hour}`
                        : formattedPrice
                        : '--' }
                </Typography.Regular>
            </View>
        </React.Fragment>
    )
}

const stylesheet = createStyles(theme => ({
    row: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    discount: {
        textDecorationLine: 'line-through',
        marginRight: theme.utils.gap(1)
    },
    originalPrice: {
        color: theme.colors.night,
        fontSize: 10,
        lineHeight: 20,
        textDecorationLine: 'line-through',
        fontFamily: CustomFonts.Poppins500
    }
}))
