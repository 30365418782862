import React from 'react'
import { View } from 'react-native'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { Regular } from './typography'

type SeparatorWithBorderProps = {
    text: string
}

export const SeparatorWithBorder: React.FunctionComponent<SeparatorWithBorderProps> = ({ text }) => {
    const { styles, theme } = useStyles(stylesheet)

    return (
        <View style={styles.container}>
            <View style={styles.border} />
            <View style={styles.textContainer}>
                <Regular
                    style={styles.text}
                    forceColor={theme.colors.darkSilver}
                >
                    {text}
                </Regular>
            </View>
            <View style={styles.border} />
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        height: 35,
        marginVertical: theme.utils.gap(1),
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    border: {
        flex: 1,
        height: 1,
        backgroundColor: theme.colors.darkSilver
    },
    textContainer: {
        marginHorizontal: theme.utils.gap(2)
    },
    text: {
        textTransform: 'uppercase'
    }
}))
