import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { useForm } from '@codegateinc/react-form-builder-v2'
import { isNative } from 'lib/common'
import {
    useAutoSelectFrequencyJobTypeAtom,
    useAutoSelectOneTimeJobTypeAtom,
    useResetAutoSelectFrequencyJobTypeAtom,
    useResetAutoSelectOneTimeJobTypeAtom,
    useToastAtom
} from 'lib/atoms'
import { bookingHelpers } from 'lib/utils'
import { Breakpoint, createStyles } from 'lib/styles'
import {
    BookingFrequency,
    FrequencyOption,
    NotificationType,
    ServicesPlan,
    SupplyID
} from 'lib/types'
import { Segment, ServiceType } from 'lib/analytics'
import {
    useBookingDateCustomValueFormat,
    useAutoSelectFrequency,
    useInitializeAddress,
    useIsWithinBreakpoints,
    useMockedHours,
    useSegmentSession,
    useStyles,
    useTranslations,
    useAutoSelectSubscriptionPlan,
    useAutoSelectOneTimePlan
} from 'lib/hooks'
import { ENV } from 'lib/config'
import { NavigationParams, NavigationProps, ScreenNames } from 'lib/routing'
import {
    Adapter,
    Address,
    Button,
    FormComponents,
    MediaQuery,
    Sticky,
    WebViewInModal
} from 'lib/components'
import { getServiceStaticConfig } from 'features/bookings/utils'
import {
    BookingCancellationFees,
    BookingDateModal,
    CleaningBookingTimeFrequencyLabel,
    CleaningEquipmentDisclaimer,
    CleaningSummaryColumn,
    MobileSummary,
    PriceSummaryComponent,
    Rated,
    ServiceDescription,
    ServiceLayout,
    BookingTotalPrice,
    CleaningWorkingHoursPlaceholder
} from '../components'
import { CleaningFields, CleaningFormShape, useCleaningForm } from '../forms'
import {
    useBookingDays,
    useCleaningBreadcrumbs,
    useFrequencyOptions,
    useResetFormCallback,
    useJobQuotation
} from '../hooks'
import { SubTotalComponent } from '../components/SubTotalComponent'

type CleaningScreenProps = {
    route: NavigationParams<ScreenNames.Cleaning>
    navigation: NavigationProps<ScreenNames.Cleaning>
}

export const CleaningScreen: React.FunctionComponent<CleaningScreenProps> = ({
    route
}) => {
    const config = getServiceStaticConfig(SupplyID.Cleaner)
    const {
        pricing,
        min_notice_hours: minHoursNotice,
        pricing: { servicePlan: { options: servicesPlanOptions } = { options: [] } } = {}
    } = config
    const { equipmentDisclaimer } = pricing
    const propertyTypes = pricing.propertyType.options.map(item => ({
        label: item.label,
        value: item.label
    }))
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const navigation = useNavigation()
    const [, setToastMessage] = useToastAtom()
    const [autoSelectFrequencyJobType] = useAutoSelectFrequencyJobTypeAtom()
    const [, setResetAutoSelectFrequencyJobType] = useResetAutoSelectFrequencyJobTypeAtom()
    const [autoSelectOneTimeJobType] = useAutoSelectOneTimeJobTypeAtom()
    const [, setResetAutoSelectOneTimeJobType] = useResetAutoSelectOneTimeJobTypeAtom()
    const [isModalOpen, setIsModalOpen] = useState(false)
    const { segmentSession } = useSegmentSession()
    const frequencyOptions = useFrequencyOptions({
        pricePerHour: pricing.frequency.options
            .filter(item => item.frequency === 'onetime')[0].price.amount,
        recurringPricePerHour: pricing.frequency.options
            .filter(item => item.frequency === 'recurring')[0].price.amount
    })
    const breadcrumbs = useCleaningBreadcrumbs()
    const mockedHours = useMockedHours(2, 8)
    const [isHelpModalOpen, setHelpModalOpen] = useState(false)
    const bookingDaysInitialValue = useBookingDays()
    const isMobile = useIsWithinBreakpoints(Breakpoint.XS, Breakpoint.MD)
    const { jobQuotation, requestJobQuotation, isLoading } = useJobQuotation(SupplyID.Cleaner)
    const [isRecurring, setIsRecurring] = useState(false)
    const [startingFrom, setStartingFrom] = useState(0)

    const { form, isFilled, hasError, setFieldValue, submit, resetForm } = useForm<CleaningFormShape>(useCleaningForm(frequencyOptions), {
        onSuccess: form => {
            const shiftedDays = bookingHelpers.shiftBookingDays(form.bookingDays, minHoursNotice, form.startingDate)

            navigation.navigate(ScreenNames.CleaningSummary, {
                form: {
                    ...form,
                    bookingDays: shiftedDays
                },
                price: startingFrom,
                postalcode: route.params?.postalcode,
                supplyId: SupplyID.Cleaner
            })
        }
    })

    const dateCustomValueFormat =
        useBookingDateCustomValueFormat(form.startingDate.value, form.bookingTime.value, form.bookingDays.value)
    const formSummaryValue = Object.keys(form).reduce((acc, nextValue) => ({
        ...acc,
        [nextValue]: form[nextValue].value
    }), {} as CleaningFormShape)
    const columnWidth = isNative || isMobile
        ? undefined
        : 'calc(55% - 10px)'
    const continueDisabled = form.frequency.value.value === BookingFrequency.OneTime
        ? !form.bookingTime.value
        : Object.values(form.bookingDays.value).every(value => !value)
    const clearRecurringData = () => {
        setFieldValue(CleaningFields.StartingDate, '')
        setFieldValue(CleaningFields.BookingTime, '')
        setFieldValue(CleaningFields.BookingDays, bookingDaysInitialValue)
    }

    useEffect(() => {
        setStartingFrom(jobQuotation?.total.value || startingFrom)
    }, [jobQuotation])

    useResetFormCallback(resetForm)

    useEffect(() => {
        clearRecurringData()
    }, [form.frequency.value])

    useAutoSelectFrequency(autoSelectFrequencyJobType, route.params?.postalcode, frequencyOptions, form, setResetAutoSelectFrequencyJobType)

    useEffect(() => {
        setFieldValue(CleaningFields.BookingTime, '')
    }, [form.startingDate.value])

    useEffect(() => {
        if (form.bookingDays.errorMessage) {
            setToastMessage({
                message: form.bookingDays.errorMessage,
                type: NotificationType.Error
            })
        }
    }, [form.bookingDays.errorMessage])

    useInitializeAddress(true)

    useEffect(() => {
        if (segmentSession.id) {
            Segment.bookingStarted({
                bookingSessionId: segmentSession.id,
                serviceType: ServiceType.Cleaning,
                postcode: route.params?.postalcode as string
            })
        }
    }, [segmentSession])

    useEffect(() => {

        const { hours, frequency } = form

        if(!frequency.value.value) {
            return
        }

        const isOneTime = frequency?.value?.value === BookingFrequency.OneTime
        const frequencyType = isOneTime ? 'onetime' : 'recurring'
        setIsRecurring(!isOneTime)
        requestJobQuotation({
            frequency: frequencyType,
            numberOfHours: +hours.value
        })

    }, [form.hours.value, form.frequency.value])

    useEffect(() => {
        const oneTimePlan = servicesPlanOptions.find(option => option.frequency === ServicesPlan.OneTime)
        const recurringPlan = servicesPlanOptions.find(option => option.frequency === ServicesPlan.Recurring)

        setFieldValue(CleaningFields.ServicePlan, isRecurring ? recurringPlan : oneTimePlan)
    }, [isRecurring])

    const selectOneTimePlan = () => {
        const oneTimeFrequency = frequencyOptions?.find(opt => opt.value === BookingFrequency.OneTime)
        oneTimeFrequency && setFieldValue(CleaningFields.Frequency, oneTimeFrequency as FrequencyOption)
    }

    const selectWeeklyPlan = () => {
        const weeklyFrequency = frequencyOptions?.find(opt => opt.value === BookingFrequency.Weekly)
        weeklyFrequency && setFieldValue(CleaningFields.Frequency, weeklyFrequency as FrequencyOption)
        Segment.cleaningPlanFrequencySelected({ frequencyType: BookingFrequency.Weekly })
    }

    const selectBiWeeklyPlan = () => {
        const biWeeklyFrequency = frequencyOptions?.find(opt => opt.value === BookingFrequency.Fortnightly)
        biWeeklyFrequency && setFieldValue(CleaningFields.Frequency, biWeeklyFrequency as FrequencyOption)
        Segment.cleaningPlanFrequencySelected({ frequencyType: BookingFrequency.Fortnightly })
    }

    useAutoSelectSubscriptionPlan(autoSelectFrequencyJobType, selectWeeklyPlan, setResetAutoSelectFrequencyJobType)
    useAutoSelectOneTimePlan(autoSelectOneTimeJobType, selectOneTimePlan, setResetAutoSelectOneTimeJobType)

    return (
        <React.Fragment>
            <ServiceLayout
                onInfoPress={() => setHelpModalOpen(true)}
                title={T.screens.cleaning.title}
                withBackground
                breadcrumbs={breadcrumbs}
                bottomSpacerHeight={
                    isMobile
                        ? 0
                        : 50
                }
                contentColumn={(
                    <View>
                        <MediaQuery.Hidden from={Breakpoint.LG}>
                            <View style={styles.mobileDescriptionContainer}>
                                <ServiceDescription
                                    serviceType={ServiceType.Cleaning}
                                    isHelpModalOpen={isHelpModalOpen}
                                    setHelpModalOpen={setHelpModalOpen}
                                    title={T.screens.cleaning.title}
                                    modalContent={(
                                        <WebViewInModal
                                            title={T.screens.cleaning.formFields.hours.whatIncluded}
                                            isOpen={isHelpModalOpen}
                                            closeModal={() => setHelpModalOpen(false)}
                                            uri={ENV.SENDHELPER_CLEANING_WHAT_IS_INCLUDED_URL as string}
                                        />
                                    )}
                                    description={T.screens.cleaning.serviceDescription}
                                />
                            </View>
                        </MediaQuery.Hidden>
                        <BookingDateModal
                            clearData={clearRecurringData}
                            minHoursNotice={minHoursNotice}
                            onContinue={() => setIsModalOpen(false)}
                            disabled={isLoading}
                            isLoading={isLoading}
                            isModalOpen={isModalOpen}
                            frequency={form.frequency.value}
                            startingDateField={form.startingDate}
                            bookingTimeField={form.bookingTime}
                            bookingDaysField={form.bookingDays}
                            onClose={() => setIsModalOpen(false)}
                        />
                        <Adapter.ServicesPlanOptions
                            {...form.servicePlan}
                            options={config?.pricing?.servicePlan?.options || []}
                            disabled={isLoading}
                            frequency={form.frequency.value}
                            selectOneTimePlan={selectOneTimePlan}
                            selectWeeklyPlan={selectWeeklyPlan}
                            selectBiWeeklyPlan={selectBiWeeklyPlan}
                        />
                        <FormComponents.FormRow>
                            <View
                                style={{
                                    width: columnWidth
                                }}
                            >
                                <Adapter.DateAndTime
                                    {...form.startingDate}
                                    customValueFormat={dateCustomValueFormat}
                                    onPress={() => {
                                        Segment.bookingDateAndTimeClicked({})

                                        setIsModalOpen(true)
                                    }}
                                    testID={T.accessibility.cleaningDateTimeSelection}
                                    disabled={isLoading || !form.frequency.value}
                                />
                            </View>
                            <CleaningBookingTimeFrequencyLabel
                                days={form.bookingDays.value}
                                frequency={form.frequency.value}
                            />
                        </FormComponents.FormRow>
                        <View
                            style={{
                                ...styles.selectWrapper,
                                width: columnWidth
                            }}
                        >
                            <FormComponents.FormRow>
                                <Adapter.SelectInputColumn
                                    {...form.propertyType}
                                    disabled={isLoading}
                                    options={propertyTypes}
                                    onOptionSelect={option => {
                                        Segment.bookingPropertyTypeSelected({
                                            propertyType: option.value
                                        })
                                    }}
                                    onMenuOpen={() => {
                                        Segment.bookingPropertyTypeClicked({})
                                    }}
                                    onChangeValue={value => {
                                        if (!value) {
                                            Segment.bookingClearPropertyTypeClicked({})
                                        }

                                        form.propertyType.onChangeValue(value)
                                    }}
                                    testID={T.accessibility.cleaningPropertyTypeSelection}
                                />
                                {/* TODO API do not support this at the moment */}
                                {/* <View style={styles.roomsContainer}>
                                    <Adapter.NumberInput
                                        {...form.bedrooms}
                                        disabled={isLoading}
                                    />
                                    <View style={styles.spacer} />
                                    <Adapter.NumberInput
                                        {...form.bathrooms}
                                        disabled={isLoading}
                                    />
                                </View> */}
                            </FormComponents.FormRow>
                        </View>
                        <FormComponents.FormRow>
                            <Adapter.OptionsRadio
                                {...form.hours}
                                options={mockedHours}
                                disabled={isLoading}
                                onChangeValue={value => {
                                    Segment.bookingHoursSelected({
                                        hours: Number(value)
                                    })

                                    form.hours.onChangeValue(value)
                                }}
                                maxOptionsPerRow={isMobile ? 3 : undefined}
                                initialAmountToShow={isMobile ? 5 : undefined}
                                renderCustomPlaceholder={() => <CleaningWorkingHoursPlaceholder />}
                                testID={T.accessibility.cleaningBookingHoursSelection}
                                testIDReplaceKey="[workingHours]"
                            />
                            <CleaningEquipmentDisclaimer equipmentDisclaimer={equipmentDisclaimer}/>
                        </FormComponents.FormRow>
                    </View>
                )}
                summaryColumn={(
                    <MediaQuery.Visible from={Breakpoint.LG}>
                        <ServiceDescription
                            isHelpModalOpen={isHelpModalOpen}
                            setHelpModalOpen={setHelpModalOpen}
                            title={T.screens.cleaning.title}
                            modalContent={(
                                <WebViewInModal
                                    title={T.screens.cleaning.formFields.hours.whatIncluded}
                                    isOpen={isHelpModalOpen}
                                    closeModal={() => setHelpModalOpen(false)}
                                    uri={ENV.SENDHELPER_CLEANING_WHAT_IS_INCLUDED_URL as string}
                                />
                            )}
                            description={T.screens.cleaning.serviceDescription}
                        />
                        <Sticky>
                            <Address disabled={isLoading} />
                            <CleaningSummaryColumn
                                renderPriceComponent={() => (
                                    <BookingTotalPrice jobQuotation={jobQuotation}/>
                                )}
                                form={formSummaryValue}
                                jobQuotation={jobQuotation}
                                selectWeeklyPlan={selectWeeklyPlan}
                                renderPriceSummaryComponent={() => jobQuotation?.priceSummary ? (
                                    <PriceSummaryComponent
                                        priceSummary={jobQuotation?.priceSummary}
                                        promotion={jobQuotation.discount}
                                        total={jobQuotation?.total}
                                        totalSubscription={jobQuotation?.totalSubscription}
                                    />
                                ) : undefined}
                                renderSubTotalComponent={isRecurring && jobQuotation?.subTotal ? () => (
                                    <SubTotalComponent subTotal={jobQuotation?.subTotal} />
                                ): undefined}
                            />
                            <Button
                                testID="confirm-booking-requirement-button"
                                onPress={submit}
                                disabled={!isFilled || hasError || continueDisabled}
                                text={T.common.continue}
                            />
                            <BookingCancellationFees />
                        </Sticky>
                    </MediaQuery.Visible>
                )}
                footer={(
                    <MediaQuery.Hidden from={Breakpoint.LG}>
                        <MobileSummary
                            submit={submit}
                            price={startingFrom}
                            isLoading={isLoading}
                            disabled={!isFilled || hasError || continueDisabled}
                            content={(
                                <React.Fragment>
                                    <Address disabled={isLoading} />
                                    <CleaningSummaryColumn
                                        renderPriceComponent={() => (
                                            <BookingTotalPrice jobQuotation={jobQuotation}/>
                                        )}
                                        form={formSummaryValue}
                                        jobQuotation={jobQuotation}
                                        selectWeeklyPlan={selectWeeklyPlan}
                                        renderPriceSummaryComponent={() => jobQuotation?.priceSummary ? (
                                            <PriceSummaryComponent
                                                priceSummary={jobQuotation?.priceSummary}
                                                promotion={jobQuotation.discount}
                                                total={jobQuotation?.total}
                                                totalSubscription={jobQuotation?.totalSubscription}
                                            />
                                        ) : undefined}
                                        renderSubTotalComponent={isRecurring && jobQuotation?.subTotal ? () => (
                                            <SubTotalComponent subTotal={jobQuotation?.subTotal} />
                                        ): undefined}
                                    />
                                    <Rated />
                                    <BookingCancellationFees />
                                </React.Fragment>
                            )}
                            jobQuotation={jobQuotation}
                        />
                    </MediaQuery.Hidden>
                )}
            />
        </React.Fragment>
    )
}

const stylesheet = createStyles(theme => ({
    roomsContainer: {
        marginTop: theme.utils.gap(1),
        flexDirection: {
            lg: 'row',
            xs: 'column'
        }
    },
    spacer: {
        width: theme.utils.gap(1)
    },
    selectWrapper: {
        zIndex: theme.zIndex[10]
    },
    mobileDescriptionContainer: {
        backgroundColor: theme.colors.marble,
        marginHorizontal: -theme.utils.gap(2),
        marginBottom: theme.utils.gap(2),
        paddingHorizontal: theme.utils.gap(2),
        paddingTop: theme.utils.gap(4)
    }
}))
