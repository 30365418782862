import React, { useState } from 'react'
import { View } from 'react-native'
import { useNavigation, useRoute } from '@react-navigation/native'
import { isNative } from 'lib/common'
import { createStyles } from 'lib/styles'
import { Segment } from 'lib/analytics'
import { useStyles, useTranslations } from 'lib/hooks'
import { Button, Touchable, Typography } from 'lib/components'
import { NavigationParams, ScreenNames } from 'lib/routing'
import { useAddressAtom, useIsAuthorizedAtom, useUserAtom } from 'lib/atoms'
import { stringHelpers } from 'lib/utils'
import { EditAddressModal } from './EditAddressModal'
import { NewAddressModal } from './NewAddressModal'

type AddressProps = {
    title?: string,
    disabled?: boolean,
    isHidden?: boolean,
}

export const Address: React.FunctionComponent<AddressProps> = ({
    title,
    disabled,
    isHidden
}) => {
    const T = useTranslations()
    const navigation = useNavigation()
    const [isAuthorized] = useIsAuthorizedAtom()
    const { styles } = useStyles(stylesheet)
    const [user] = useUserAtom()
    const [savedAddress] = useAddressAtom()
    const [isEditModalOpen, setIsEditModalOpen] = useState(false)
    const [isNewAddressModalOpen, setIsNewAddressModalOpen] = useState(false)
    const route = useRoute<NavigationParams<ScreenNames.Cleaning>>()

    const onOpenModal = () => {
        if (user.locations.length === 0) {
            return setIsNewAddressModalOpen(true)
        }

        return setIsEditModalOpen(true)
    }

    if ((!route.params?.postalcode && !savedAddress.postcode && isNative) || isHidden) {
        return null
    }

    if (!route.params?.postalcode && !savedAddress.postcode) {
        return isAuthorized ? (
            <View style={styles.buttonContainer}>
                <Button
                    text={T.screens.user.addAddress}
                    onPress={() => navigation.navigate(ScreenNames.UserNewAddress)}
                />
            </View>
        ) : null
    }

    const savedAddressUnit = stringHelpers.getFormattedAddressUnit(savedAddress.unit)

    return (
        <View>
            {isAuthorized && (
                <React.Fragment>
                    <EditAddressModal
                        isOpen={isEditModalOpen}
                        onClose={() => setIsEditModalOpen(false)}
                        onAddNew={() => {
                            setIsEditModalOpen(false)
                            setIsNewAddressModalOpen(true)
                        }}
                    />
                    <NewAddressModal
                        isOpen={isNewAddressModalOpen}
                        onClose={() => setIsNewAddressModalOpen(false)}
                        onEdit={() => {
                            setIsEditModalOpen(true)
                            setIsNewAddressModalOpen(false)
                        }}
                    />
                </React.Fragment>
            )}
            <View style={styles.container}>
                <View style={styles.flex}>
                    {title && (
                        <Typography.Title
                            bold
                            style={styles.title}
                        >
                            {title}
                        </Typography.Title>
                    )}
                    <View>
                        <Typography.Label bold={!title}>
                            {savedAddress.postcode
                                ? `${savedAddressUnit}${savedAddress.address}, ${savedAddress.postcode}`
                                : `${route.params?.postalcode}, ${T.common.singapore}`
                            }
                        </Typography.Label>
                    </View>
                </View>
                {isAuthorized && (
                    <View style={styles.changeButton}>
                        <Touchable
                            disabled={disabled}
                            testID={T.accessibility.summaryColumnEditAddress}
                            onPress={() => {
                                Segment.bookingEditAddressClicked({})

                                onOpenModal()
                            }}
                        >
                            <Typography.Label bold>
                                {T.common.edit}
                            </Typography.Label>
                        </Touchable>
                    </View>
                )}
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        borderRadius: 8,
        borderWidth: 1,
        borderColor: theme.colors.silver,
        paddingVertical: theme.utils.gap(5) / 2,
        paddingHorizontal: {
            lg: theme.utils.gap(3),
            xs: theme.utils.gap(2)
        },
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: theme.utils.gap(4),
        backgroundColor: theme.colors.white,
        ...theme.utils.createShadow(2, 0, 8, 4, 4, theme.colors.webShadow(0.04))
    },
    buttonContainer: {
        marginBottom: theme.utils.gap(2)
    },
    flex: {
        flex: 1
    },
    changeButton: {
        borderBottomWidth: 1,
        marginLeft: theme.utils.gap(1),
        borderColor: theme.colors.night
    },
    title: {
        marginBottom: theme.utils.gap(1) / 2
    }
}))
