import React from 'react'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { Page404Screen } from 'features/auth'
import { AllServicesScreen, BookingSuccessScreen } from 'features/bookings'
import { SendPayScreen } from 'features/sendPay'
import { FavouriteScreen } from 'features/favourite'
import { UserCardsScreen } from 'features/payments'
import { ContactUsScreen } from 'features/contact'
import { BookingsScreen } from 'features/jobs'
import { NotificationsScreen } from 'features/notifications'
import { UserScreen } from 'features/user'
import { useTranslations } from 'lib/hooks'
import { SharedStack } from './SharedStack'
import { AppStackParams } from '../stackParams'
import { ScreenNames } from '../screens'
import { UnauthorizedWebStack } from './UnauthorizedWebStack'
import { ServicesStack } from './ServicesStack'

const Stack = createNativeStackNavigator<AppStackParams>()

export const WebStack = (isAuthorized: boolean) => {
    const T = useTranslations()

    return (
        <Stack.Navigator
            initialRouteName={isAuthorized
                ? ScreenNames.Home
                : undefined
            }
            screenOptions={{
                headerShown: false
            }}
        >
            {isAuthorized
                ? SharedStack()
                : UnauthorizedWebStack()
            }
            {isAuthorized && (
                <React.Fragment>
                    <Stack.Screen
                        name={ScreenNames.AllServices}
                        component={AllServicesScreen}
                        options={{
                            title: T.screens.allServices.pageTitle
                        }}
                    />
                    <Stack.Screen
                        name={ScreenNames.Bookings}
                        component={BookingsScreen}
                        options={{
                            title: T.screens.bookings.pageTitle
                        }}
                    />
                    <Stack.Screen
                        name={ScreenNames.Notifications}
                        component={NotificationsScreen}
                        options={{
                            title: T.screens.notifications.pageTitle
                        }}
                    />
                    <Stack.Screen
                        name={ScreenNames.User}
                        component={UserScreen}
                        options={{
                            title: T.screens.user.pageTitle
                        }}
                    />
                    <Stack.Screen
                        name={ScreenNames.SendPay}
                        component={SendPayScreen}
                        options={{
                            title: T.screens.sendPay.pageTitle
                        }}
                    />
                    <Stack.Screen
                        name={ScreenNames.Favourite}
                        component={FavouriteScreen}
                        options={{
                            title: T.screens.favourite.pageTitle
                        }}
                    />
                    <Stack.Screen
                        name={ScreenNames.UserCards}
                        component={UserCardsScreen}
                        options={{
                            title: T.screens.paymentAndCards.pageTitle
                        }}
                    />
                    <Stack.Screen
                        name={ScreenNames.ContactUs}
                        component={ContactUsScreen}
                        options={{
                            title: T.screens.contactUs.pageTitle
                        }}
                    />
                </React.Fragment>
            )}
            {ServicesStack()}
            <Stack.Screen
                name={ScreenNames.Page404}
                component={Page404Screen}
            />
            <Stack.Screen
                name={ScreenNames.BookingSuccess}
                component={BookingSuccessScreen}
                options={{
                    title: T.screens.bookingSuccess.pageTitle
                }}
            />
        </Stack.Navigator>
    )
}
