import { BookingFrequency, Nullable } from 'lib/types'
import { dateHelpers } from 'lib/utils'

export const useBookingScheduleDates = (frequency: BookingFrequency, dates: Array<Nullable<string>>) => {
    const numberOfWeeksToAdd = frequency === BookingFrequency.Weekly ? 1 : 2

    return dates.reduce<Array<string>>((acc, nextValue) => {
        if (!nextValue) {
            return acc
        }

        const sessions = Array.from(new Array(3)).map((item, index) => dateHelpers.createSessions(nextValue, (index + 1) * numberOfWeeksToAdd))

        return [
            ...acc,
            nextValue,
            ...sessions
        ]
    }, []).sort((firstDate, secondDate) => firstDate.localeCompare(secondDate))
}
