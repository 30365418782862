import React, { useEffect, useState } from 'react'
import { View, StyleSheet, Alert } from 'react-native'
import * as Updates from 'expo-updates'
import { useAppState, useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { R } from 'lib/utils'
import { OTAUpdateIsReady } from './OTAUpdateIsReady'
import { OTAUpdateInProgress } from './OTAUpdateInProgress'

export const OTAUpdateListener: React.FunctionComponent = () => {
    const T = useTranslations()
    const [isUpdateReadyToInstall, setIsUpdateReadyToInstall] = useState(false)
    const [isModalVisible, setModalVisibility] = useState(false)
    const { styles } = useStyles(stylesheet)
    const { isInBackground, willBeActive } = useAppState()
    const checkForUpdates = () => {
        Updates
            .checkForUpdateAsync()
            .then(({ isAvailable }) => {
                if (isAvailable) {
                    setModalVisibility(true)

                    return Updates.fetchUpdateAsync()
                }

                return Promise.reject()
            })
            .then(() => setIsUpdateReadyToInstall(true))
            .catch(R.T)
    }

    useEffect(() => {
        checkForUpdates()
    }, [])

    useEffect(() => {
        if (isInBackground && willBeActive) {
            checkForUpdates()
        }
    }, [isInBackground, willBeActive])

    if (!isModalVisible) {
        return null
    }

    return (
        <View style={styles.container}>
            {isUpdateReadyToInstall
                ? (
                    <OTAUpdateIsReady
                        onInstall={() => {
                            Updates
                                .reloadAsync()
                                .catch(() => {
                                    Alert.alert(T.common.error, T.components.otaUpdateListener.updateErrorMessage)
                                })
                        }}
                    />
                )
                : (
                    <OTAUpdateInProgress />
                )
            }
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        backgroundColor: theme.colors.white,
        justifyContent: 'center',
        alignItems: 'center',
        ...StyleSheet.absoluteFillObject
    }
}))
