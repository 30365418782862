import { useField } from '@codegateinc/react-form-builder-v2'
import { regexes } from 'lib/utils'
import { useTranslations } from 'lib/hooks'
import { LogInFields } from './forms'

export const useLogInForm = () => {
    const T = useTranslations()

    const email = useField<string>({
        key: LogInFields.Email,
        initialValue: '',
        isRequired: true,
        validateOnBlur: true,
        placeholder: T.screens.auth.formFields.email.placeholder,
        label: T.screens.auth.formFields.email.label,
        validationRules: [
            {
                errorMessage: T.screens.auth.formFields.email.validation.isRequired,
                validate: Boolean
            },
            {
                errorMessage: T.screens.auth.formFields.email.validation.isInvalid,
                validate: regexes.isValidEmail
            }
        ]
    })

    const password = useField<string>({
        key: LogInFields.Password,
        initialValue: '',
        isRequired: true,
        validateOnBlur: true,
        placeholder: T.screens.auth.formFields.password.placeholder,
        label: T.screens.auth.formFields.password.label,
        validationRules: [
            {
                errorMessage: T.screens.auth.formFields.password.validation.isRequired,
                validate: Boolean
            }
        ]
    })

    return {
        email,
        password
    }
}
