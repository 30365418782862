import React from 'react'
import { AppLayout, SectionBox } from 'lib/components'
import { useTranslations } from 'lib/hooks'
import { NavigationProps, ScreenNames } from 'lib/routing'
import { PaymentNewForm } from '../components'

type UserCardsNewScreenProps = {
    navigation: NavigationProps<ScreenNames.UserCardsNew>
}

export const UserCardsNewScreen: React.FunctionComponent<UserCardsNewScreenProps> = () => {
    const T = useTranslations()

    return (
        <AppLayout title={T.screens.paymentAndCards.title}>
            <SectionBox title={T.screens.paymentAndCards.addCard}>
                <PaymentNewForm />
            </SectionBox>
        </AppLayout>
    )
}
