import { Typography } from 'lib/components'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import React from 'react'
import { View } from 'react-native'

type CustomServiceInputProps = {
    title: string,
    subtitle: string,
    action: React.ReactElement
}

export const CustomServiceInput = ({ action, subtitle, title }: CustomServiceInputProps) => {
    const { styles } = useStyles(stylesheet)

    return (
        <View style={styles.itemRow}>
            <View style={styles.title}>
                <Typography.Label>
                    {title}
                </Typography.Label>
            </View>
            <View style={styles.subtitle}>
                <Typography.Label style={styles.subtitleText} bold>
                    {subtitle}
                </Typography.Label>
            </View>
            <View style={styles.action}>
                {action}
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    itemRow: {
        height: 60,
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderBottomColor: theme.colors.silver,
        alignItems: 'center',
        justifyContent: {
            xs: 'space-between',
            lg: 'space-evenly'
        }
    },
    title: {
        flex: 2
    },
    price: {
        flex: 1
    },
    subtitle: {
        flex: 1,
        display: {
            lg: 'flex',
            xs: 'none'
        }
    },
    subtitleText: {
        fontSize: 12
    },
    action: {
        width: 200,
        alignItems: 'flex-end'
    }
}))
