import React from 'react'
import { View } from 'react-native'
import { Field } from '@codegateinc/react-form-builder-v2'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { Touchable, Typography } from 'lib/components'
import { Icons } from 'assets'

interface DateAndTimeProps extends Field<string> {
    onPress: VoidFunction,
    disabled?: boolean,
    customValueFormat?: string,
    testID?: string
}

export const DateAndTime: React.FunctionComponent<DateAndTimeProps> = ({
    value,
    label,
    placeholder,
    disabled,
    onPress,
    customValueFormat,
    testID
}) => {
    const { styles } = useStyles(stylesheet)
    const customValue = customValueFormat ?? value

    return (
        <View
            style={disabled
                ? styles.disabled
                : undefined
            }
        >
            <Typography.Regular bold>
                {label}
            </Typography.Regular>
            <Touchable
                onPress={onPress}
                testID={testID}
                disabled={disabled}
            >
                <View style={styles.container}>
                    <Icons.DateAndTime size={24} />
                    <View style={styles.valueText}>
                        <Typography.Label numberOfLines={1}>
                            {customValue ? customValue : placeholder}
                        </Typography.Label>
                    </View>
                    <View
                        style={{
                            ...styles.chevron,
                            transform: [
                                {
                                    rotateZ: '-90deg'
                                }
                            ]
                        }}
                    >
                        <Icons.Chevron size={10} />
                    </View>
                </View>
            </Touchable>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    disabled: {
        opacity: 0.65
    },
    container: {
        borderWidth: 1,
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: theme.utils.gap(2),
        borderRadius: theme.components.input.borderRadius,
        paddingVertical: theme.utils.gap(1),
        paddingHorizontal: theme.utils.gap(1),
        borderColor: theme.components.input.emptyBorderColor
    },
    valueText: {
        flex: 1,
        marginHorizontal: theme.utils.gap(1)
    },
    chevron: {
        marginRight: theme.utils.gap(1)
    }
}))
