import React, { useState } from 'react'
import { Icons } from 'assets'
import { View } from 'react-native'
import { Field } from '@codegateinc/react-form-builder-v2'
import { useStyles, useTranslations } from 'lib/hooks'
import { ENV } from 'lib/config'
import { createStyles } from 'lib/styles'
import { FormComponents, Typography, Touchable, WebViewInModal } from 'lib/components'
import { FrequencyOption, ServicesPlan } from 'lib/types'
import { Segment } from 'lib/analytics'
import { ServicePlanOption } from 'features/bookings'
import { FrequencySelect } from 'lib/components/form/components/FrequencySelect'

interface ServicesPlanOptionsProps extends Field<ServicePlanOption> {
    options: Array<ServicePlanOption>,
    disabled?: boolean,
    frequency?: FrequencyOption,
    selectOneTimePlan(): void,
    selectWeeklyPlan(): void,
    selectBiWeeklyPlan(): void,
}

export const ServicesPlanOptions: React.FunctionComponent<ServicesPlanOptionsProps> = ({
    value,
    options,
    label,
    frequency,
    selectOneTimePlan,
    selectWeeklyPlan,
    selectBiWeeklyPlan
}) => {
    const T = useTranslations()
    const [isCleaningInfoModalOpen, setIsCleaningInfoModalOpen] = useState<boolean>(false)
    const { styles, theme } = useStyles(stylesheet)

    const handleChangeServicesPlan = (option: ServicePlanOption) => {
        option.frequency === ServicesPlan.OneTime ? selectOneTimePlan() : selectWeeklyPlan()
        Segment.cleaningPlanSelected({
            type: option.frequency
        })
    }

    return (
        <View>
            <FormComponents.FormRow>
                <View style={styles.titleContainer}>
                    <Typography.Regular bold>
                        {label}
                    </Typography.Regular>
                    <Touchable
                        onPress={() => setIsCleaningInfoModalOpen(true)}
                        style={styles.cleaningInfoButton}
                        hitSlopBottom={20}
                        hitSlopRight={20}
                        hitSlopLeft={20}
                        hitSlopTop={20}>
                        <Icons.Info
                            size={16}
                            forceColor={theme.colors.orange}
                        />
                    </Touchable>
                    <WebViewInModal
                        title={T.screens.cleaning.planModalTitle}
                        isOpen={isCleaningInfoModalOpen}
                        closeModal={() => setIsCleaningInfoModalOpen(false)}
                        uri={ENV.SENDHELPER_CLEANING_INFO_URL as string}
                    />
                </View>
                <View style={styles.optionsContainer}>
                    {options.map((option, index) => (
                        <FormComponents.ServicesPlanItem
                            key={index}
                            index={index}
                            option={option}
                            isSelected={option.frequency === value.frequency}
                            isLast={index === options.length - 1}
                            onChangeValue={() => {
                                handleChangeServicesPlan(option as ServicePlanOption)
                            }}
                            testID={option.frequency === 'onetime' ? T.accessibility.cleaningOneOffServicePlan : T.accessibility.cleaningSubscriptionServicePlan}
                        />
                    ))}
                </View>
            </FormComponents.FormRow>
            <FrequencySelect
                frequency={frequency}
                selectWeeklyPlan={selectWeeklyPlan}
                selectBiWeeklyPlan={selectBiWeeklyPlan}
            />
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    optionsContainer: {
        marginTop: theme.utils.gap(2),
        flexDirection: {
            md: 'row',
            xs: 'column'
        },
        justifyContent: 'space-between'
    },
    titleContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start'
    },
    cleaningInfoButton: {
        marginLeft: theme.utils.gap(0.5)
    }
}))
