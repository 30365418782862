import React from 'react'
import { View } from 'react-native'
import { createStyles } from 'lib/styles'
import { Typography } from 'lib/components'
import { JobDetails } from 'lib/models'
import { useStyles, useTranslations } from 'lib/hooks'
import { JobStatus } from 'lib/types'

type NoBillingProps = {
    job: JobDetails
}

export const NoBilling: React.FunctionComponent<NoBillingProps> = ({ job }) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const billingsNumber = job.customer_charges?.length || 0

    if (job.status === JobStatus.Cancelled) {
        return null
    }

    return (
        <View style={styles.content}>
            <View style={styles.title}>
                <View style={styles.billingsNumber}>
                    <Typography.Regular forceColor={theme.colors.white}>
                        {billingsNumber}
                    </Typography.Regular>
                </View>
                <Typography.Regular bold>
                    {T.components.noBilling.title}
                </Typography.Regular>
            </View>
            <Typography.Label forceColor={theme.colors.mouse}>
                {T.components.noBilling.message}
            </Typography.Label>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    content: {
        borderRadius: 8,
        borderWidth: 1,
        padding: theme.utils.gap(2),
        marginBottom: theme.utils.gap(2),
        backgroundColor: theme.colors.white,
        borderColor: theme.colors.silver,
        backGroundColor: theme.colors.white,
        ...theme.utils.createShadow(2, 0, 8, 4, 4, theme.colors.webShadow(0.04))
    },
    title: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: theme.utils.gap(2)
    },
    billingsNumber: {
        width: 24,
        height: 24,
        borderRadius: 12,
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: theme.utils.gap(1),
        backgroundColor: theme.colors.night
    }
}))
