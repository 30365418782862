import React from 'react'
import { View } from 'react-native'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { dateHelpers } from 'lib/utils'
import { Typography } from 'lib/components'
import { ChatMessage, GroupChatMessage } from '../types'

export const TextMessage: React.FunctionComponent<ChatMessage> = ({
    payload,
    createdAt
}) => {
    const { styles, theme } = useStyles(stylesheet)
    const { body } = payload as GroupChatMessage

    return (
        <View style={styles.textContainer}>
            <Typography.Regular
                style={styles.message}
                forceColor={theme.colors.tar}
            >
                {body}
            </Typography.Regular>
            <Typography.Label style={styles.date}>
                {dateHelpers.selectTimeLabel(createdAt)}
            </Typography.Label>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    textContainer: {
        flexDirection: 'row'
    },
    message: {
        flex: 1,
        lineHeight: 18,
        alignSelf: 'flex-end'
    },
    date: {
        color: theme.colors.darkGrey,
        lineHeight: 18,
        marginLeft: theme.utils.gap(1) / 2,
        alignSelf: 'flex-end',
        textAlign: 'right'
    }
}))
