import {
    useUserTokenAtom,
    usePromotionPopupAtom
} from 'lib/atoms'
import { getPromotionPopup } from '../actions'
import { PromotionPopupForConsumer, PromotionPopupResponse } from '../types'

export const usePromotionPopup = () => {
    const [token] = useUserTokenAtom()
    const [, setPromotionPopup] = usePromotionPopupAtom()
    const { mutate, isLoading } = getPromotionPopup()

    const requestPromotionPopup = () => {
        mutate(
            {
                token
            },
            {
                onSuccess: response => {
                    const promotionPopupResponse = response.data as PromotionPopupResponse
                    const promotionPopupForConsumer: PromotionPopupForConsumer = promotionPopupResponse?.data?.value?.consumer
                    setPromotionPopup(promotionPopupForConsumer)
                }
            }
        )
    }

    return {
        isLoading,
        requestPromotionPopup
    }
}
