import React from 'react'
import { View, ViewStyle } from 'react-native'
import { useLocalizedDates, useStyles, useTranslations } from 'lib/hooks'
import { dateHelpers } from 'lib/utils'
import { createStyles } from 'lib/styles'
import { ScrollDirection } from 'lib/types'
import { SmallSubheading, Regular } from '../typography'
import { CalendarHeaderButton } from './CalendarHeaderButton'

type CalendarHeaderProps = {
    date: string,
    liteMode?: boolean,
    onChange: (date: string) => void,
    customContainerStyles?: ViewStyle
}

export const CalendarHeader: React.FunctionComponent<CalendarHeaderProps> = ({
    date,
    onChange,
    liteMode,
    customContainerStyles = {}
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const { monthName } = useLocalizedDates()

    return (
        <View
            style={{
                ...(liteMode ? styles.containerWithLiteMode : styles.container),
                ...customContainerStyles
            }}
        >
            <CalendarHeaderButton
                testID={T.accessibility.calendar.header.prev}
                onPress={() => onChange(dateHelpers.prevMonth(date))}
                direction={ScrollDirection.Prev}
            />
            <View style={styles.monthName}>
                {
                    liteMode ?  (
                        <Regular style={styles.monthNameTextWithLiteMode}>
                            {monthName(date)}
                        </Regular>
                    ) :  (
                        <SmallSubheading>
                            {monthName(date)}
                        </SmallSubheading>
                    )
                }

            </View>
            <CalendarHeaderButton
                testID={T.accessibility.calendar.header.next}
                onPress={() => onChange(dateHelpers.nextMonth(date))}
                direction={ScrollDirection.Next}
            />
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingBottom: theme.utils.gap(2),
        paddingHorizontal: theme.utils.gap(1),
        borderBottomColor: theme.colors.silver,
        borderBottomWidth: 1
    },
    containerWithLiteMode: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingBottom: theme.utils.gap(1),
        paddingHorizontal: theme.utils.gap(1),
        borderBottomColor: theme.colors.silver,
        borderBottomWidth: 1
    },
    monthName: {
        marginHorizontal: theme.utils.gap(2)
    },
    monthNameTextWithLiteMode: {
        fontWeight: 'bold'
    }
}))
