import React, { useState } from 'react'
import { View } from 'react-native'
import { Icons } from 'assets'
import { createStyles } from 'lib/styles'
import { VoidFunction } from 'lib/types'
import { OTPVerifyCode } from 'lib/firebase'
import { isWeb } from 'lib/common'
import { useStyles, useTimer, useTranslations } from 'lib/hooks'
import { Button, OTPInput, Touchable, Typography } from 'lib/components'
import { AuthUser } from '../types'
import { useSignUpWithOTP } from '../hooks'
import { RecaptchaNote } from './RecaptchaNote'
import { Recaptcha } from './Recaptcha'

const INPUT_LENGTH = 6

type OTPFormProps = {
    authUser: AuthUser,
    onCancel?: VoidFunction,
    onVerifyCode: OTPVerifyCode,
    onSuccessfulSignUp?: VoidFunction,
    setVerifyCode(verifyCode: OTPVerifyCode): void
}

export const OTPForm: React.FunctionComponent<OTPFormProps> = ({
    authUser,
    onCancel,
    onVerifyCode,
    onSuccessfulSignUp,
    setVerifyCode
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const { startTimer, isTimerInProgress, timer } = useTimer(59)
    const [codeValue, setCodeValue] = useState<Array<string>>(new Array(INPUT_LENGTH).fill(''))
    const isValid = codeValue.every(item => Boolean(item))
    const { isLoading, authError, confirmOTP, resendOTP } = useSignUpWithOTP(
        authUser,
        onVerifyCode,
        setVerifyCode,
        onSuccessfulSignUp
    )

    return (
        <View style={styles.container}>
            <View style={styles.row}>
                <Typography.SmallSubheading style={styles.textCenter}>
                    {T.screens.authOTP.verifyTitle}
                </Typography.SmallSubheading>
            </View>
            <View style={styles.row}>
                <Typography.Regular
                    style={styles.textCenter}
                    forceColor={theme.colors.darkGrey}
                >
                    {`${T.screens.authOTP.verifyMessage} `}
                </Typography.Regular>
            </View>
            <View style={styles.row}>
                <Typography.Title
                    bold
                    style={styles.textCenter}
                >
                    {authUser.phoneNumber}
                </Typography.Title>
            </View>
            {isWeb && (
                <View style={styles.recaptchaContainer}>
                    <RecaptchaNote />
                </View>
            )}
            <View style={styles.otp}>
                <OTPInput
                    value={codeValue}
                    disabled={isLoading}
                    length={INPUT_LENGTH}
                    onChange={setCodeValue}
                    error={Boolean(authError)}
                />
            </View>
            {authError && (
                <View style={styles.row}>
                    <Typography.Error
                        style={styles.textCenter}
                        forceColor={theme.colors.strawberry}
                    >
                        {authError}
                    </Typography.Error>
                </View>
            )}
            <View style={styles.submit}>
                <Button
                    testID="verify-otp-button"
                    isLoading={isLoading}
                    disabled={!isValid}
                    text={T.screens.authOTP.submit}
                    renderIcon={color => (
                        <Icons.Arrow
                            size={14}
                            forceColor={color}
                        />
                    )}
                    onPress={() => confirmOTP(codeValue)}
                />
            </View>
            {isTimerInProgress && (
                <Typography.Regular
                    bold
                    style={styles.textCenter}
                >
                    {`${T.screens.authOTP.wait} ${timer}${T.common.seconds}`}
                </Typography.Regular>
            )}
            {!isTimerInProgress && (
                <View style={styles.resendCodeLink}>
                    <Typography.Regular
                        style={styles.textCenter}
                        forceColor={theme.colors.darkGrey}
                    >
                        {`${T.screens.authOTP.resend} `}
                    </Typography.Regular>
                    <Touchable
                        onPress={() => {
                            resendOTP()
                            startTimer()
                        }}
                        disabled={isTimerInProgress}
                    >
                        <Typography.Regular
                            bold
                            style={styles.textUnderline}
                            forceColor={theme.colors.orange}
                        >
                            {T.screens.authOTP.wait}
                        </Typography.Regular>
                    </Touchable>
                </View>
            )}
            {onCancel && (
                <Touchable
                    onPress={onCancel}
                    style={styles.cancelButton}
                >
                    <Typography.Regular
                        bold
                        style={styles.cancel}
                    >
                        {T.common.cancel}
                    </Typography.Regular>
                </Touchable>
            )}
            <Recaptcha />
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        marginTop: {
            lg: theme.utils.gap(4),
            xs: theme.utils.gap(3)
        }
    },
    textCenter: {
        textAlign: 'center'
    },
    row: {
        marginBottom: theme.utils.gap(1)
    },
    otp: {
        maxWidth: {
            ':w[992]': 340,
            xs: 400
        },
        width: '100%',
        alignSelf: 'center',
        marginBottom: theme.utils.gap(3)
    },
    submit: {
        alignSelf: 'center',
        marginBottom: theme.utils.gap(2),
        maxWidth: {
            lg: 400,
            xs: 300
        },
        width: '100%'
    },
    resendCodeLink: {
        alignSelf: 'center',
        flexDirection: 'row'
    },
    textUnderline: {
        borderBottomWidth: 1,
        borderBottomColor: theme.colors.night
    },
    cancelButton: {
        alignSelf: 'center',
        flexDirection: 'row',
        marginTop: theme.utils.gap(5)
    },
    cancel: {
        textDecorationLine: 'underline',
        color: theme.colors.sun
    },
    recaptchaContainer: {
        marginVertical: theme.utils.gap(3),
        alignSelf: 'center',
        maxWidth: 400
    }
}))
