import { User } from 'lib/models'
import { ScreenNames } from 'lib/routing'

export const getNavigationLinkBySupplyId = (supplyId: number) => {
    const linksMap = {
        0: ScreenNames.Cleaning,
        1: ScreenNames.Cooking,
        2: ScreenNames.Laundry,
        3: ScreenNames.Handyman,
        4: ScreenNames.AirCondition,
        5: ScreenNames.DeepCleaning,
        8: ScreenNames.PestControl,
        9: ScreenNames.TaskAndErrands,
        10: ScreenNames.MoversAndPackers
    }

    return linksMap[supplyId] || null
}

export const redirectToSupply = (supplyId: number, user: User, navigation) => {
    const navigationLink = getNavigationLinkBySupplyId(supplyId)
    const hasSingleAddress = user.locations?.length === 1
    const [firstAddress] = user.locations

    if(hasSingleAddress) {
        navigation.navigate(navigationLink, {
            postalcode: firstAddress.postcode,
            service: supplyId.toString()
        })

        return
    }

    navigation.navigate(ScreenNames.AllServicesPostcode, {
        supplyId
    })
}
