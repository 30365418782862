import React from 'react'
import { Image, StyleSheet, View } from 'react-native'
import { useAppUpdateListener, useStyles, useTranslations } from 'lib/hooks'
import { createStyles, theme } from 'lib/styles'
import { Button, Typography } from 'lib/components'
import { linkingHelpers } from 'lib/utils'
import { isAndroid } from 'lib/common'
import { ENV } from 'lib/config'
import { Icons, Images } from 'assets'

export const ForceUpdateListener: React.FunctionComponent = () => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)

    const { shouldForceUpdate } = useAppUpdateListener()

    if (!shouldForceUpdate) {
        return
    }

    return (
        <View style={styles.container}>
            <View style={styles.bannerContainer}>
                <Typography.Heading style={styles.heading}>
                    {T.components.forceUpdateListener.heading}
                </Typography.Heading>
                <Image
                    source={Images.ForceUpdateBackground}
                    style={styles.backgroundImage}
                />
            </View>

            <View style={styles.updateContent}>
                <View style={styles.subHeaderContainer}>
                    <Typography.Subheading style={styles.subHeadingText}>
                        {T.components.forceUpdateListener.subHeading}
                    </Typography.Subheading>
                </View>
                <View>
                    <Typography.Regular style={styles.message} forceColor={theme.colors.darkGrey}>
                        {T.components.forceUpdateListener.message}
                    </Typography.Regular>
                </View>
                <View style={styles.bulletContainer}>
                    <View style={{ flexDirection: 'row', gap: 8 }}>
                        <Icons.CheckCircle size={20} forceColor={theme.colors.sun} />
                        <Typography.Regular bold forceColor={theme.colors.darkGrey}>{T.components.forceUpdateListener.features.newFeatures}</Typography.Regular>
                    </View>
                    <View style={{ flexDirection: 'row', gap: 8 }}>
                        <Icons.CheckCircle size={20} forceColor={theme.colors.sun} />
                        <Typography.Regular bold forceColor={theme.colors.darkGrey}>{T.components.forceUpdateListener.features.performance}</Typography.Regular>
                    </View>
                    <View style={{ flexDirection: 'row', gap: 8 }}>
                        <Icons.CheckCircle size={20} forceColor={theme.colors.sun} />
                        <Typography.Regular bold forceColor={theme.colors.darkGrey}>{T.components.forceUpdateListener.features.security}</Typography.Regular>
                    </View>
                </View>
            </View>

            <View style={styles.ctaContainer}>
                <Button
                    height={48}
                    textStyles={styles.ctaText}
                    text={T.components.forceUpdateListener.cta}
                    onPress={() => {
                        linkingHelpers.openUrl(isAndroid
                            ? ENV.GOOGLE_PLAY_STORE_URL
                            : ENV.APP_STORE_URL
                        )
                    }}
                />
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        backgroundColor: theme.colors.white,
        ...StyleSheet.absoluteFillObject
    },
    bannerContainer: {
        flex: 4,
        justifyContent: 'flex-end',
        alignItems: 'center',
        gap: theme.utils.gap(3),
        backgroundColor: theme.colors.sun
    },
    subHeaderContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: theme.utils.gap(2)
    },
    subHeadingText: {
        fontSize: theme.fontSize[20]
    },
    heading: {
        textAlign: 'center',
        width: 260,
        fontSize: theme.fontSize[28],
        lineHeight: 35
    },
    message: {
        fontSize: theme.fontSize[18],
        lineHeight: 24,
        marginBottom: theme.utils.gap(1)
    },
    backgroundImage: { width: 400, height: 210, marginBottom: -1 },
    updateContent: {
        padding: 25,
        flex: 2.5
    },
    text: {
        textAlign: 'center'
    },
    ctaContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        paddingHorizontal: theme.utils.gap(2)
    },
    ctaText: {
        fontWeight: 'bold',
        fontSize: theme.fontSize[18]
    },
    bulletContainer: {
        marginTop: theme.utils.gap(2),
        gap: theme.utils.gap(2)
    }
}))
