import React from 'react'
import { View } from 'react-native'
import { Field } from '@codegateinc/react-form-builder-v2'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { Children } from 'lib/types'
import { FormComponents, Touchable, Typography } from 'lib/components'
import { Icons } from 'assets'
import { Segment } from 'lib/analytics'

interface AddonCheckBoxProps extends Field<boolean> {
    disabled?: boolean,
    testID?: string
    renderContent: () => Children,
    extraLabelText?: () => Children,
    onOptionSelected?(option: string): void,
}

export const AddonCheckBox: React.FunctionComponent<AddonCheckBoxProps> = ({
    value,
    label,
    hasError,
    onChangeValue,
    errorMessage,
    renderContent,
    onOptionSelected,
    extraLabelText,
    disabled,
    testID
}) => {
    const { styles, theme } = useStyles(stylesheet)

    return (
        <React.Fragment>
            <Touchable
                disabled={disabled}
                testID={testID}
                onPress={() => {
                    if (!onOptionSelected) {
                        Segment.bookingAdditionalInformationAdded({
                            information: {
                                type: label,
                                value: String(!value)
                            }
                        })
                    }

                    if (onOptionSelected && !value && label) {
                        onOptionSelected(label)
                    }

                    onChangeValue(!value)
                }}
                style={{
                    ...styles.container,
                    backgroundColor: disabled
                        ? theme.colors.silver
                        : value
                            ? theme.colors.yellow
                            : theme.colors.white,
                    borderColor: value
                        ? theme.colors.orange
                        : hasError
                            ? theme.components.input.error.borderColor
                            : theme.components.input.emptyBorderColor
                }}
            >
                <View
                    style={{
                        ...styles.row,
                        opacity: disabled
                            ? 0.5
                            : 1
                    }}
                >
                    <View style={styles.checkboxContainer}>
                        {
                            !value ? (
                                <View
                                    style={styles.checkbox}
                                    focusable
                                />
                            ) : (
                                <View
                                    style={styles.checkbox}
                                    focusable
                                >
                                    <View style={styles.selectedCheckbox}>
                                        <Icons.Check
                                            size={16}
                                            forceColor={theme.components.checkBox.iconColor}
                                        />
                                    </View>
                                </View>
                            )
                        }
                    </View>
                    <View style={styles.column}>
                        <Typography.Regular
                            bold
                            forceColor={
                                value
                                    ? theme.colors.orange
                                    : undefined
                            }
                        >
                            <React.Fragment>
                                {label}
                                {extraLabelText && extraLabelText()}
                            </React.Fragment>
                        </Typography.Regular>
                        <View style={styles.content}>
                            {renderContent()}
                        </View>
                    </View>
                </View>
            </Touchable>
            <FormComponents.ErrorMessage text={errorMessage} />
        </React.Fragment>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        width: {
            lg: '100%',
            xs: undefined
        },
        borderRadius: 8,
        borderWidth: 1,
        paddingHorizontal: theme.utils.gap(2),
        paddingVertical: theme.utils.gap(2),
        ...theme.utils.createShadow(2, 0, 8, 4, 4, theme.colors.webShadow(0.04))
    },
    row: {
        flexDirection: 'row'
    },
    column: {
        flex: 1
    },
    iconContainer: {
        marginRight: theme.utils.gap(1)
    },
    content: {
        marginTop: theme.utils.gap(1)
    },
    checkboxContainer: {
        flexDirection: 'row'
    },
    checkbox: {
        width: theme.components.checkBox.width,
        height: theme.components.checkBox.height,
        padding: {
            lg: theme.utils.gap(1),
            xs: undefined
        },
        borderRadius: theme.components.checkBox.borderRadius,
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: theme.utils.gap(1),
        borderWidth: theme.components.checkBox.borderWidth,
        borderColor: theme.components.checkBox.borderColor
    },
    selectedCheckbox: {
        borderRadius: theme.components.checkBox.borderRadius,
        backgroundColor: theme.components.checkBox.backgroundColor
    }
}))
