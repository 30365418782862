import React from 'react'
import { useTranslations } from 'lib/hooks'
import { Typography, Grid, Button } from 'lib/components'

type OTAUpdateIsReadyProps = {
    onInstall: VoidFunction
}

export const OTAUpdateIsReady: React.FunctionComponent<OTAUpdateIsReadyProps> = ({ onInstall }) => {
    const T = useTranslations()

    return (
        <React.Fragment>
            <Typography.Heading>
                {T.components.otaUpdateListener.readyToInstallTitle}
            </Typography.Heading>
            <Grid.Gap gapTop={4}>
                <Button
                    width={200}
                    onPress={onInstall}
                    text={T.components.otaUpdateListener.cta}
                />
            </Grid.Gap>
        </React.Fragment>
    )
}
