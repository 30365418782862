import { useLocaleAtom } from 'lib/atoms'
import { supportedTimeZones, timeZones } from 'lib/locale'
import { Nullable } from 'lib/types'
import { dateHelpers } from 'lib/utils'

export const useBookingDateCustomValueFormat =
    (bookingDate: string, bookingTime: string, bookingDays: Record<string, Nullable<string>>) => {
        const [locale] = useLocaleAtom()
        const getDateFormat = () => bookingDate ? dateHelpers.startingDate(bookingDate) : ''

        const getTimeFormat = () => {
            if (bookingTime) {
                return dateHelpers.selectTimeLabel(bookingTime)
            }

            const bookingDateDayName = dateHelpers.getWeekDayFullNames(bookingDate || new Date(), locale)
            const selectedDayTime = bookingDays[bookingDateDayName]

            return selectedDayTime ? dateHelpers.selectTimeLabel(selectedDayTime) : ''
        }

        const formattedDate = getDateFormat()
        const formattedTime = getTimeFormat()
        const timeZoneFormat = supportedTimeZones[timeZones.ASIA_SINGAPORE].timeZoneName

        return bookingDate ? `${formattedDate}${formattedTime ? ` - ${timeZoneFormat} ${formattedTime}`  : ''}` : undefined
    }
