import React from 'react'
import { View } from 'react-native'
import { CopyBookingId, Grid, Touchable, Typography } from 'lib/components'
import { useCopyToClipboard, useStyles, useTranslations } from 'lib/hooks'
import { Job, JobDetails } from 'lib/models'
import { createStyles } from 'lib/styles'
import { dateHelpers } from 'lib/utils'

type NextSessionInfoProps = {
    job: Job,
    jobDetails?: JobDetails,
    sessionIndex: number,
    allSessionsCount: number
}

export const NextSessionInfo: React.FunctionComponent<NextSessionInfoProps> = ({
    job,
    jobDetails,
    sessionIndex,
    allSessionsCount
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const { onCopyPress } = useCopyToClipboard(T.common.copiedBookingIdMessage)
    const sessionTime = jobDetails?.num_hours
        ? `${dateHelpers.selectTimeLabel(jobDetails?.date_time || '')} - ${dateHelpers.selectTimeLabel(dateHelpers.addHoursToDate(jobDetails?.date_time || '', jobDetails?.num_hours))}`
        : ''

    return (
        <React.Fragment>
            <View style={styles.breakRow}>
                <View style={styles.row}>
                    <Typography.Regular>
                        {T.components.currentJobSession.title}
                    </Typography.Regular>
                    <Grid.Gap gapRight={3}/>
                    <Touchable
                        onPress={() => onCopyPress(job.job_id)}
                        style={styles.jobId}
                    >
                        <Typography.Label forceColor={theme.colors.white}>
                            {job.job_id}
                        </Typography.Label>
                    </Touchable>
                </View>
                <Grid.Gap
                    gapRight={2}
                    gapBottom={2}
                />
                <View style={styles.flexedRow}>
                    <Typography.Label forceColor={theme.colors.fog}>
                        {`${T.components.currentJobSession.sessionIndexPrefix} ${sessionIndex + 1} ${T.components.currentJobSession.sessionIndexInfix} ${allSessionsCount}`}
                    </Typography.Label>
                    <View style={styles.spacer}/>
                    <CopyBookingId id={job.job_id} />
                </View>
            </View>
            <Grid.Gap gapBottom={2}/>
            <View style={styles.breakRow}>
                <View style={styles.flexedRow}>
                    <Typography.Regular bold>
                        {dateHelpers.shortFullDate(job.date_time)}
                    </Typography.Regular>
                    <Grid.Gap gapRight={2}/>
                    {jobDetails?.date_time && (
                        <Typography.Label>
                            {`${sessionTime} (${jobDetails?.num_hours}${T.components.currentJobSession.hours})`}
                        </Typography.Label>
                    )}
                    <View style={styles.spacer}/>
                </View>
                <Grid.Gap
                    gapRight={2}
                    gapBottom={2}
                />
                {jobDetails && (
                    <Typography.Label forceColor={theme.colors.fog}>
                        {`${T.components.currentJobSession.expert}: ${jobDetails?.supplier?.handle}`}
                    </Typography.Label>
                )}
            </View>
        </React.Fragment>
    )
}

const stylesheet = createStyles(theme => ({
    row: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    flexedRow: {
        flexDirection: 'row',
        alignItems: 'center',
        flex: 1
    },
    breakRow: {
        flexDirection: {
            xl: 'row',
            lg: 'column'
        }
    },
    spacer: {
        flex: 1
    },
    addHoursContainer: {
        flex: undefined,
        marginBottom: 0
    },
    jobId: {
        paddingHorizontal: theme.utils.gap(1),
        paddingVertical: theme.utils.gap(0.5),
        borderRadius: theme.utils.gap(0.5),
        backgroundColor: theme.colors.night
    }
}))
