import React, { useEffect } from 'react'
import { ScrollView, View, ViewStyle, RefreshControl } from 'react-native'
import { useRoute } from '@react-navigation/native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scrollview'
import { isIOS, isWeb, Measurements } from 'lib/common'
import { Children } from 'lib/types'
import { useSegmentSession, useStyles } from 'lib/hooks'
import { ScreenNames } from 'lib/routing'
import { ChatNotification } from 'features/chat'
import { useUnreadJobChatsAtom } from 'lib/atoms'
import { Breakpoint, createStyles } from 'lib/styles'
import { Grid, Header, ListBottomSpacer, MediaQuery, Typography } from 'lib/components'
import { PromotionModal } from 'features/promotion'
import { PromotionPopup } from 'features/promotionPopup'
import { MarketingConsentReminder } from 'features/marketingConsent'
import { ReminderType } from 'features/marketingConsent/components/MarketingConsentReminder'
import { AppDrawerContent } from './AppDrawerContent'

type AppLayoutProps = {
    children: Children,
    title?: string,
    disableUserMenu?: boolean,
    centerVertical?: boolean,
    renderSideMenu?: boolean,
    scrollEnabled?: boolean,
    stickyHeaderIndices?: Array<number>,
    isLoading?: boolean,
    bottomSpacerHeight?: number,
    customContainerStyles?: ViewStyle,
    pullToRefreshEnabled?: boolean,
    isRefreshing?: boolean,
    onRefresh?: VoidFunction,
    renderSubHeader?(): React.ReactElement
    renderNotification?(): React.ReactElement
}

export const AppLayout: React.FunctionComponent<AppLayoutProps> = ({
    children,
    title,
    renderSideMenu = true,
    scrollEnabled = true,
    isLoading = false,
    disableUserMenu = true,
    renderSubHeader,
    stickyHeaderIndices,
    bottomSpacerHeight = 50,
    renderNotification,
    pullToRefreshEnabled = false,
    isRefreshing = false,
    onRefresh,
    customContainerStyles = {}
}) => {
    const { styles } = useStyles(stylesheet)
    const route = useRoute()
    const [unreadChatMessages] = useUnreadJobChatsAtom()
    const { clearSession } = useSegmentSession()

    // To clear segment booking session if user leaves booking flow
    useEffect(() => {
        clearSession()
    }, [])

    return (
        <Grid.Background>
            <Header disableUserMenu={disableUserMenu} />
            {renderSubHeader && renderSubHeader()}
            <View style={styles.container}>
                {renderSideMenu && (
                    <MediaQuery.Visible from={Breakpoint.LG}>
                        <ScrollView
                            style={styles.leftColumn}
                            showsVerticalScrollIndicator={false}
                        >
                            <AppDrawerContent routeName={route.name} />
                            <ListBottomSpacer height={50} />
                        </ScrollView>
                    </MediaQuery.Visible>
                )}
                <View style={styles.rightColumn}>
                    <ChatNotification
                        unreadMessages={unreadChatMessages}
                        isVisible={unreadChatMessages.length > 0 && route.name !== ScreenNames.Bookings}
                    />
                    {renderNotification && renderNotification()}
                    <KeyboardAwareScrollView
                        stickyHeaderIndices={stickyHeaderIndices}
                        scrollEnabled={scrollEnabled}
                        extraScrollHeight={20}
                        showsVerticalScrollIndicator={false}
                        keyboardShouldPersistTaps="always"
                        keyboardDismissMode={isIOS ? 'interactive' : 'on-drag'}
                        contentContainerStyle={{
                            ...styles.scrollContainerStyle,
                            minHeight: isWeb
                                ? Measurements.WindowHeight
                                : undefined,
                            flex: isLoading
                                ? 1
                                : undefined,
                            ...customContainerStyles
                        }}
                        refreshControl={pullToRefreshEnabled
                            ? (
                                <RefreshControl
                                    refreshing={isRefreshing}
                                    onRefresh={onRefresh}
                                />
                            )
                            : null
                        }
                    >
                        {title && (
                            <MediaQuery.Visible from={Breakpoint.LG}>
                                <Typography.Subheading>
                                    {title}
                                </Typography.Subheading>
                            </MediaQuery.Visible>
                        )}
                        {children}
                        <ListBottomSpacer height={bottomSpacerHeight} />
                    </KeyboardAwareScrollView>
                </View>
            </View>
            <PromotionModal/>
            <MarketingConsentReminder type={ReminderType.Modal}/>
            <MarketingConsentReminder type={ReminderType.Popup}/>
            <PromotionPopup/>
        </Grid.Background>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        flex: 1,
        flexDirection: 'row'
    },
    leftColumn: {
        flex: 1,
        maxWidth: 260,
        height: '100%',
        paddingHorizontal: theme.utils.gap(3),
        paddingTop: {
            lg: theme.utils.gap(5),
            xs: 0
        },
        backgroundColor: theme.colors.white,
        ...theme.utils.createShadow(0, 0.8, 8, 8)
    },
    rightColumn: {
        flex: 1,
        maxWidth: '100%'
    },
    scrollContainerStyle: {
        paddingHorizontal: {
            lg: theme.utils.gap(6),
            xs: 0
        },
        paddingTop: {
            lg: theme.utils.gap(5),
            xs: 0
        }
    }
}))
