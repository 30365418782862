import React from 'react'
import { View } from 'react-native'
import { Field } from '@codegateinc/react-form-builder-v2'
import { useStyles } from 'lib/hooks'
import { Segment } from 'lib/analytics'
import { createStyles } from 'lib/styles'
import { HandymanService } from 'lib/models'
import { HandymanService as HandymanServiceEnum } from 'features/bookings'
import { handymanHooks } from '../../hooks'
import { HandymanServiceItem } from './HandymanServiceItem'

type HandyManServicesSearchResultsProps = {
    query: string,
    field: Field<Array<HandymanService>>,
}

export const HandyManServicesSearchResults: React.FunctionComponent<HandyManServicesSearchResultsProps> = ({
    query,
    field
}) => {
    const { styles } = useStyles(stylesheet)
    const handymanServices = handymanHooks.useHandymanServices()
    const serviceTypes = handymanHooks.useHandymanServicesTypes()
    const filteredServices = handymanServices
        .filter(service => service.items.some(item => item.toLowerCase().includes(query.toLowerCase())))
        .map(service => ({
            ...service,
            items: service.items.filter(item => item.toLowerCase().includes(query.toLowerCase()) && item !== HandymanServiceEnum.Others)
        }))

    const handlePress = (item: string, category: string, isSelected: boolean) => {
        const [defaultTypeValue] = serviceTypes[item]
        const newValue = isSelected
            ? field.value.filter(fieldItems => fieldItems.item)
            : [
                ...field.value,
                {
                    item,
                    category,
                    amount: 1,
                    comment: null,
                    othersComment: null,
                    part: [],
                    type: defaultTypeValue || null
                }
            ]

        field.onChangeValue(newValue)
    }

    return (
        <View style={styles.itemsWrapper}>
            {filteredServices.map(service => service.items.map((item, index) => {
                const isSelected = field.value.some(fieldItems => fieldItems.item === item && fieldItems.category === service.label)

                return (
                    <HandymanServiceItem
                        key={index}
                        label={item}
                        isSelected={isSelected}
                        onPress={() =>{
                            if (field.value.length === 0) {
                                Segment.bookingServiceTypeSelected({
                                    category: service.label,
                                    item
                                })
                            }

                            if (field.value.length !== 0) {
                                Segment.bookingAnotherServiceTypeSelected({
                                    category: service.label,
                                    item
                                })
                            }

                            handlePress(item, service.label, isSelected)
                        }}
                    />
                )
            }))}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    itemsWrapper: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        paddingHorizontal: theme.utils.gap(1),
        borderBottomWidth: 1,
        borderBottomColor: theme.colors.darkSilver
    }
}))
