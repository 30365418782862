import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const PestControl: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path d="M12.945 42.353H2.354a2.354 2.354 0 1 1 0-4.707h10.591a2.354 2.354 0 0 1 0 4.707Z" />
        <Path d="M32.943 42.353h-7.061a2.354 2.354 0 1 1 0-4.707h7.061a2.354 2.354 0 1 1 0 4.707Z" />
        <Path d="M54.119 42.353h-7.061a2.354 2.354 0 1 1 0-4.707h7.061a2.354 2.354 0 0 1 0 4.707Z" />
        <Path d="M27.055 57.648a2.354 2.354 0 0 1-2.35-2.353 13.665 13.665 0 0 1 5.825-11.182l1.063-.745a2.354 2.354 0 1 1 2.7 3.856l-1.064.745a8.954 8.954 0 0 0-3.817 7.33 2.354 2.354 0 0 1-2.357 2.349Z" />
        <Path d="M32.941 37.061a2.343 2.343 0 0 1-1.348-.426l-1.064-.745a13.666 13.666 0 0 1-5.824-11.189 2.354 2.354 0 0 1 4.707 0 8.955 8.955 0 0 0 3.817 7.33l1.064.745a2.354 2.354 0 0 1-1.352 4.282Z" />
        <Path d="M47.061 37.059H32.939a2.354 2.354 0 0 1 0-4.707h14.122a2.354 2.354 0 0 1 0 4.707Z" />
        <Path d="M52.942 57.648a2.354 2.354 0 0 1-2.354-2.354 8.954 8.954 0 0 0-3.816-7.33l-1.068-.744a2.354 2.354 0 0 1 2.7-3.856l1.064.745a13.665 13.665 0 0 1 5.824 11.187 2.354 2.354 0 0 1-2.35 2.352Z" />
        <Path d="M47.06 37.061a2.354 2.354 0 0 1-1.356-4.282l1.064-.745a8.955 8.955 0 0 0 3.82-7.333 2.354 2.354 0 1 1 4.707 0 13.666 13.666 0 0 1-5.824 11.19l-1.064.745a2.343 2.343 0 0 1-1.347.425Z" />
        <Path d="M77.651 42.353h-10.6a2.354 2.354 0 0 1 0-4.707h10.6a2.354 2.354 0 0 1 0 4.707Z" />
        <Path d="M40 15.3a2.354 2.354 0 0 1-2.354-2.355V2.354a2.354 2.354 0 1 1 4.707 0v10.591A2.354 2.354 0 0 1 40 15.3Z" />
        <Path d="M40 80a2.354 2.354 0 0 1-2.354-2.349v-10.6a2.354 2.354 0 0 1 4.707 0v10.6A2.354 2.354 0 0 1 40 80Z" />
        <Path d="M40 4.707A35.293 35.293 0 1 1 4.707 40 35.333 35.333 0 0 1 40 4.707Zm0 65.88A30.587 30.587 0 1 0 9.413 40 30.622 30.622 0 0 0 40 70.587Z" />
        <Path d="M40 22.348c2.857 0 5.389 2.077 7.13 5.848a28.908 28.908 0 0 1 2.285 11.8 28.908 28.908 0 0 1-2.285 11.8c-1.745 3.78-4.273 5.852-7.13 5.852s-5.389-2.077-7.13-5.848A28.908 28.908 0 0 1 30.585 40a28.908 28.908 0 0 1 2.285-11.8c1.74-3.775 4.272-5.852 7.13-5.852Zm0 30.6c1.874 0 4.707-5.163 4.707-12.945S41.874 27.055 40 27.055 35.292 32.218 35.292 40 38.126 52.948 40 52.948Z" />
    </Icon>
)
