import React from 'react'
import { Job } from 'lib/models'
import { createStyles } from 'lib/styles'
import { Typography } from 'lib/components'
import { useStyles, useTranslations } from 'lib/hooks'
import { CustomerChargeStatus, JobStatus } from 'lib/types'

type ApproveBillingProps = {
    job: Job
}

export const ApproveBilling: React.FunctionComponent<ApproveBillingProps> = ({ job }) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)

    if (job.status === JobStatus.Cancelled) {
        return null
    }

    if (job.customer_charges?.some(item => item.auth_status === CustomerChargeStatus.Pending) && job.status === JobStatus.Accepted) {
        return (
            <Typography.Regular
                bold
                style={styles.billing}
                forceColor={theme.colors.orange}
            >
                {T.components.billing.approveBilling}
            </Typography.Regular>
        )
    }

    return null
}

const stylesheet = createStyles(theme => ({
    billing: {
        marginRight: theme.utils.gap(4),
        textDecorationLine: 'underline'
    },
    price: {
        flexDirection: 'row',
        alignItems: 'center',
        marginRight: theme.utils.gap(4)
    }
}))
