import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { useForm } from '@codegateinc/react-form-builder-v2'
import { BookingFrequency, SupplyID } from 'lib/types'
import { isNative } from 'lib/common'
import { bookingHelpers } from 'lib/utils'
import { Breakpoint, createStyles, theme } from 'lib/styles'
import { Segment, ServiceType } from 'lib/analytics'
import {
    useIsWithinBreakpoints,
    useStyles,
    useTranslations,
    useInitializeAddress,
    useBookingDateCustomValueFormat,
    useSegmentSession
} from 'lib/hooks'
import { NavigationParams, NavigationProps, ScreenNames } from 'lib/routing'
import { Adapter, Address, Button, FormComponents, MediaQuery, Sticky, Typography } from 'lib/components'
import { Icons } from 'assets'
import { getServiceStaticConfig } from 'features/bookings/utils'
import {
    BookingCancellationFees,
    BookingDateCalendarPickerLite,
    BookingDateModal,
    BookingDateWeeklyService,
    DeepCleaningHelpModalContent,
    DeepCleaningSummaryColumn,
    MobileSummary,
    PriceSummaryComponent,
    Rated,
    ServiceDescription,
    ServiceLayout,
    TotalPriceComponent
} from '../components'
import { DeepCleaningFields, DeepCleaningFormShape, useDeepCleaningForm } from '../forms'
import { useDeepCleaningBreadcrumbs, useJobQuotation, useResetFormCallback } from '../hooks'
import { PropertyCleaningValue } from '../types'

type DeepCleaningScreenProps = {
    route: NavigationParams<ScreenNames.DeepCleaning>
    navigation: NavigationProps<ScreenNames.DeepCleaning>
}

export const DeepCleaningScreen: React.FunctionComponent<DeepCleaningScreenProps> = ({
    route
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const navigation = useNavigation()
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [totalCost, setTotalCost] = useState(0)
    const { segmentSession } = useSegmentSession()
    const breadcrumbs = useDeepCleaningBreadcrumbs()
    const [isHelpModalOpen, setHelpModalOpen] = useState(false)
    const isMobile = useIsWithinBreakpoints(Breakpoint.XS, Breakpoint.MD)
    const { jobQuotation, requestJobQuotation } = useJobQuotation(SupplyID.DeepCleaning)
    const { form, hasError, submit, resetForm, setFieldValue } = useForm<DeepCleaningFormShape>(useDeepCleaningForm(), {
        onSuccess: form => {
            const shiftedDays = bookingHelpers.shiftBookingDays(form.bookingDays, min_notice_hours, form.startingDate)

            navigation.navigate(ScreenNames.DeepCleaningSummary, {
                form: {
                    ...form,
                    bookingDays: shiftedDays
                },
                postalcode: route.params?.postalcode,
                supplyId: SupplyID.DeepCleaning
            })
        }
    })

    useEffect(() => {
        if (jobQuotation) {
            setTotalCost(jobQuotation?.total?.value)
        }
    }, [jobQuotation])

    const dateCustomValueFormat = useBookingDateCustomValueFormat(form.startingDate.value, form.bookingTime.value, form.bookingDays.value)
    const config = getServiceStaticConfig(SupplyID.DeepCleaning)
    const { min_notice_hours, building_type, pricing } = config
    const propertyTypes = building_type.map(item => ({
        label: item,
        value: item
    }))

    const propertyCleaningOptions = pricing?.propertyCleaning.options.map(option => ({
        label: option.label,
        value: String(!option?.isNotPropertyCleaning)
    }))

    const columnWidth = isNative || isMobile
        ? undefined
        : 'calc(55% - 10px)'

    const isFilled = form.propertyCleaning.value === PropertyCleaningValue.False ?
        form.services.value.some(service => service.quantity > 0) : !!form.propertySize.value

    const continueDisabled = !form.bookingTime.value || !isFilled || hasError
    const formSummaryValue = Object.keys(form).reduce((acc, nextValue) => ({
        ...acc,
        [nextValue]: form[nextValue].value
    }), {} as DeepCleaningFormShape)

    useResetFormCallback(resetForm)

    useEffect(() => {
        setFieldValue(DeepCleaningFields.BookingTime, '')
    }, [form.startingDate.value])

    useEffect(() => {
        const selectedServices = form.services.value
            .filter(service => service.quantity > 0)
            .map(service => ({
                id: service.id,
                quantity: service.quantity
            }))

        requestJobQuotation({
            isNotPropertyCleaning: form.propertyCleaning.value === PropertyCleaningValue.False,
            propertySize: form.propertyCleaning.value === PropertyCleaningValue.True ? form.propertySize.value : undefined,
            additionalServices: selectedServices
        })

    }, [form.propertySize.value, form.services.value])

    useEffect(() => {
        if (segmentSession.id) {
            Segment.bookingStarted({
                bookingSessionId: segmentSession.id,
                postcode: route.params?.postalcode as string,
                serviceType: ServiceType.DeepCleaning
            })
        }
    }, [segmentSession])

    useInitializeAddress(true)

    const onFullPropertyDeepCleanChange = (value: string) => {
        if (value === PropertyCleaningValue.False) {
            setFieldValue(DeepCleaningFields.PropertySize, '')
        }

        setFieldValue(DeepCleaningFields.PropertyCleaning, value)
    }

    const propertySizeOptions = pricing.propertySize.options.map(area => ({
        label: `${area.label} (${T.common.currency} ${area.price.toFixed(2)})`,
        value: area.id
    }))

    return (
        <React.Fragment>
            <ServiceLayout
                onInfoPress={() => setHelpModalOpen(true)}
                title={T.screens.deepCleaning.title}
                withBackground
                breadcrumbs={breadcrumbs}
                bottomSpacerHeight={
                    isMobile
                        ? 0
                        : 50
                }
                contentColumn={(
                    <View>
                        <MediaQuery.Hidden from={Breakpoint.LG}>
                            <View style={styles.mobileDescriptionContainer}>
                                <ServiceDescription
                                    isHelpModalOpen={isHelpModalOpen}
                                    setHelpModalOpen={setHelpModalOpen}
                                    title={T.screens.deepCleaning.title}
                                    modalContent={(
                                        <DeepCleaningHelpModalContent />
                                    )}
                                    description={T.screens.deepCleaning.serviceDescription}
                                />
                            </View>
                        </MediaQuery.Hidden>
                        <BookingDateModal
                            minHoursNotice={min_notice_hours}
                            onContinue={() => setIsModalOpen(false)}
                            isModalOpen={isModalOpen}
                            frequency={form.frequency.value}
                            startingDateField={form.startingDate}
                            bookingTimeField={form.bookingTime}
                            bookingDaysField={form.bookingDays}
                            onClose={() => setIsModalOpen(false)}
                        />
                        <View style={styles.dateTimeContainer}>
                            <View
                                style={{
                                    zIndex: 20,
                                    flex: 1
                                }}
                            >

                                <BookingDateCalendarPickerLite
                                    selectedDays={form.bookingDays.value}
                                    onChangeSelectedDays={form.startingDate.onChangeValue}
                                    disabled={false}
                                    description=""
                                    continueLabel={T.common.continue}
                                    continueDisabled={false}
                                    displayDate={dateCustomValueFormat || T.screens.deepCleaning.formFields.startingDate.placeholder}
                                    minHoursNotice={min_notice_hours}
                                    onContinue={() => setIsModalOpen(false)}
                                    frequency={form.frequency.value}
                                    startingDateField={form.startingDate}
                                />

                            </View>

                            <View
                                style={{
                                    width: columnWidth,
                                    flex: 1
                                }}
                            >
                                <BookingDateWeeklyService
                                    liteMode
                                    leftIcon={() =>  (
                                        <Icons.Clock
                                            viewBox="0 0 25 25"
                                            size={20}
                                            forceColor={continueDisabled ? theme.colors.fog : theme.colors.black}
                                        />
                                    )}
                                    timeTitle=""
                                    isOneTime
                                    onContinue={submit}
                                    startingDate={form.startingDate.value}
                                    translationKey={form.frequency.value || BookingFrequency.OneTime}
                                    minHoursNotice={min_notice_hours}
                                    bookingDaysField={form.bookingDays}
                                    continueDisabled={continueDisabled}
                                    bookingTimeField={form.bookingTime}
                                    onChangeSelectedDate={form.startingDate.onChangeValue}
                                    selectedDates={form.startingDate.value}
                                    hideContinueButton
                                    disabled={continueDisabled}
                                />
                            </View>
                        </View>

                        <FormComponents.FormRow style={styles.propertyType}>
                            <Adapter.OptionsRadio
                                {...form.propertyType}
                                options={propertyTypes}
                            />
                        </FormComponents.FormRow>

                        <View style={styles.propertyType}>
                            <FormComponents.FormRow>
                                <View style={styles.fullPropertyContainer}>
                                    <View style={styles.fullPropertyLabelContainer}>
                                        <Typography.Regular bold>
                                            {T.screens.deepCleaning.formFields.propertyCleaning.label}
                                        </Typography.Regular>
                                        <Typography.Regular forceColor={theme.colors.grey}>
                                            {T.screens.deepCleaning.formFields.propertyCleaning.subLabel}
                                        </Typography.Regular>
                                    </View>
                                    <View style={styles.spacer} />
                                    <View style={styles.fullPropertyRadioContainer}>
                                        <Adapter.OptionsRadio
                                            {...form.propertyCleaning}
                                            onChangeValue={onFullPropertyDeepCleanChange}
                                            options={propertyCleaningOptions}
                                        />
                                    </View>
                                </View>
                            </FormComponents.FormRow>
                        </View>

                        <View style={styles.propertySelectContainer}>
                            {form.propertyCleaning.value === 'true' ? (
                                <View style={styles.propertySelectWrapper}>
                                    <View style={styles.propertySelect}>
                                        <Adapter.SelectInputColumn
                                            {...form.propertySize}
                                            fullWidth
                                            isClearable
                                            placeholderContainerStyles={styles.resetMargin}
                                            options={propertySizeOptions}
                                            onOptionSelect={({ label }) => {
                                                Segment.bookingPropertySizeSelected({
                                                    propertySize: label
                                                })
                                            }}
                                        />
                                    </View>
                                </View>

                            ) : <></>}
                        </View>
                        <View style={styles.additionalServicesWrapper}>
                            <FormComponents.FormRow
                                isOptional
                                title={config.pricing.additionalServices.label}
                                style={styles.propertyType}
                            >
                                <View style={styles.row}>
                                    <Typography.Regular forceColor={theme.colors.grey}>
                                        {`${T.screens.deepCleaning.formFields.services.message.minValueOf}`}
                                        <Typography.Regular bold>
                                            {`${T.screens.deepCleaning.formFields.services.message.value}`}
                                        </Typography.Regular>
                                        <Typography.Regular forceColor={theme.colors.grey}>
                                            {`${T.screens.deepCleaning.formFields.services.message.required}`}
                                        </Typography.Regular>
                                    </Typography.Regular>

                                </View>
                                <Adapter.Services
                                    {...form.services}
                                />
                            </FormComponents.FormRow>
                        </View>
                    </View>
                )}
                summaryColumn={(
                    <MediaQuery.Visible from={Breakpoint.LG}>
                        <ServiceDescription
                            isHelpModalOpen={isHelpModalOpen}
                            setHelpModalOpen={setHelpModalOpen}
                            title={T.screens.deepCleaning.title}
                            modalContent={(
                                <DeepCleaningHelpModalContent />
                            )}
                            description={T.screens.deepCleaning.serviceDescription}
                        />
                        <Sticky>
                            <Address />
                            <DeepCleaningSummaryColumn
                                renderPriceComponent={() => (
                                    <TotalPriceComponent
                                        totalPrice={jobQuotation?.total?.value || 0}
                                    />
                                )}
                                renderPriceSummaryComponent={() => jobQuotation?.priceSummary ? (
                                    <PriceSummaryComponent priceSummary={jobQuotation?.priceSummary} promotion={jobQuotation.discount} />
                                ) : undefined}
                                form={formSummaryValue}
                                propertySizeLabel={pricing.propertySize.options.find(area => area.id === form.propertySize.value)?.label || ''}
                            />
                            <Button
                                onPress={submit}
                                disabled={continueDisabled}
                                text={T.common.continue}
                            />
                            <BookingCancellationFees />
                        </Sticky>
                    </MediaQuery.Visible>
                )}
                footer={(
                    <MediaQuery.Hidden from={Breakpoint.LG}>
                        <MobileSummary
                            submit={submit}
                            price={totalCost}
                            disabled={continueDisabled}
                            isLoading={false}
                            content={(
                                <React.Fragment>
                                    <Address />
                                    <DeepCleaningSummaryColumn
                                        renderPriceComponent={() => (
                                            <TotalPriceComponent
                                                totalPrice={jobQuotation?.total?.value || 0}
                                            />
                                        )}
                                        renderPriceSummaryComponent={() => jobQuotation?.priceSummary ? (
                                            <PriceSummaryComponent priceSummary={jobQuotation?.priceSummary} promotion={jobQuotation.discount} />
                                        ) : undefined}
                                        form={formSummaryValue}
                                        propertySizeLabel={pricing.propertySize.options.find(area => area.id === form.propertySize.value)?.label || ''}
                                    />
                                    <Rated />
                                    <BookingCancellationFees />
                                </React.Fragment>
                            )}
                        />
                    </MediaQuery.Hidden>
                )}
            />
        </React.Fragment>
    )
}

const stylesheet = createStyles(theme => ({
    spacer: {
        width: theme.utils.gap(1)
    },
    fullPropertyContainer: {
        flexDirection: {
            lg: 'row',
            xs: 'column'
        }
    },
    fullPropertyLabelContainer: {
        gap: theme.utils.gap(0.5),
        justifyContent: 'center',
        flex: 3
    },
    fullPropertyRadioContainer: {
        flex: 1
    },
    propertySelectWrapper: {
        flexDirection: 'row',
        marginTop: theme.utils.gap(1)
    },
    propertySelect: {
        flex: {
            lg: 0.5,
            xs: 1
        }
    },
    mobileDescriptionContainer: {
        backgroundColor: theme.colors.marble,
        marginHorizontal: -theme.utils.gap(2),
        marginBottom: theme.utils.gap(2),
        paddingHorizontal: theme.utils.gap(2),
        paddingTop: theme.utils.gap(4)
    },
    resetMargin: {
        marginTop: 0
    },
    propertyType: {
        zIndex: -1
    },
    row: {
        flex: 1
    },
    dateTimeContainer: {
        flexDirection: {
            lg: 'row',
            xs: 'column'
        },
        justifyContent: 'center',
        gap: theme.utils.gap(1)
    },
    propertySelectContainer: {
        zIndex: -1
    },
    additionalServicesWrapper: {
        zIndex: -2
    }
}))
