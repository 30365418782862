import React, { useEffect, useState } from 'react'
import { NotificationType, Nullable } from 'lib/types'
import { authTypes } from 'features/auth'
import { OTPVerifyCode } from 'lib/firebase'
import { useInitializeAddress, useTranslations, useUploadPhoto } from 'lib/hooks'
import { useFilesAtom, useIsAuthorizedAtom, useToastAtom } from 'lib/atoms'
import { BookingAuthForms } from './BookingAuthForms'
import { BookingAuthConfirmation } from './BookingAuthConfirmation'
import { BookingAuthConfirmationProps } from './types'

type BookingAuthProps = BookingAuthConfirmationProps

export const BookingAuth: React.FunctionComponent<BookingAuthProps> = props => {
    const T = useTranslations()
    const [files, setFiles] = useFilesAtom()
    const [isAuthorized] = useIsAuthorizedAtom()
    const [, setToastMessage] = useToastAtom()
    const { uploadPhotos } = useUploadPhoto(props.supplyId, {
        onSuccess: data => {
            props.onUploadSuccess && props.onUploadSuccess(data)
            setFiles([])
        },
        onError: () => {
            setFiles([])
        }
    })
    const [initialActiveTabIndex, setInitialActiveTabIndex] = useState(0)
    const [verifyCode, setVerifyCode] = useState<Nullable<OTPVerifyCode>>(null)
    const [authUser, setAuthUser] = useState<Nullable<authTypes.AuthUser>>(null)

    useInitializeAddress(isAuthorized)
    useEffect(() => {
        if (isAuthorized && files.length !== 0) {
            uploadPhotos(files)
        }
    }, [isAuthorized, files])

    return isAuthorized
        ? (
            <BookingAuthConfirmation {...props} />
        ) : (
            <BookingAuthForms
                authUser={authUser}
                initialActiveTabIndex={initialActiveTabIndex}
                verifyCode={verifyCode}
                onAccountConfirmed={(user, verifyCode) => {
                    setAuthUser(user)
                    setVerifyCode(verifyCode)
                }}
                onCancel={() => {
                    setAuthUser(null)
                    setInitialActiveTabIndex(1)
                    setVerifyCode(null)
                }}
                setVerifyCode={setVerifyCode}
                isGuestBookingEnabled={props.isGuestBookingEnabled || false}
                setAreGuestDetailsValid={props.setAreGuestDetailsValid}
                setGuestDetails={props.setGuestDetails}
                showGuestBookingForm={props.showGuestBookingForm}
                setShowGuestBookingForm={props.setShowGuestBookingForm}
                onSuccessfulSignUp={() => {
                    setToastMessage({
                        message: T.components.bookingAuth.accountCreatedMessage,
                        type: NotificationType.Success
                    })
                }}
            />
        )
}

