import React, { useState, useEffect } from 'react'
import { View } from 'react-native'
import { Field } from '@codegateinc/react-form-builder-v2'
import { useLocaleAtom } from 'lib/atoms'
import { useBookingCalendarPickerValueFormat, useStyles, useTranslations } from 'lib/hooks'
import { createStyles, theme } from 'lib/styles'
import { FrequencyOption, Nullable } from 'lib/types'
import { dateHelpers, jobHelpers } from 'lib/utils'
import { BookingDateCalendarPicker } from './BookingDateCalendarPicker'
import { BookingDateWeeklyService } from './BookingDateWeeklyService'
import { TimeZoneMessage, Touchable, Typography } from 'lib/components'
import { Icons } from 'assets'
import { isNative } from 'lib/common'
import { JobDetailsResponse } from 'features/jobs/types'

type RescheduleBookingDateTimeProps = {
    startingDateField: Field<string>,
    bookingDaysField: Field<Record<string, Nullable<string>>>,
    maxDate?: string,
    frequency: FrequencyOption,
    onContinue: VoidFunction,
    disabled?: boolean,
    isLoading?: boolean,
    timeDescription?: string,
    minHoursNotice: number,
    isMultiSelect?: boolean,
    allowToggle?: boolean,
    job: JobDetailsResponse
}

export const RescheduleBookingDateTime: React.FunctionComponent<RescheduleBookingDateTimeProps> = ({
    frequency,
    startingDateField,
    bookingDaysField,
    disabled,
    isLoading,
    onContinue,
    minHoursNotice,
    timeDescription,
    isMultiSelect,
    allowToggle,
    maxDate,
    job
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const [locale] = useLocaleAtom()
    const [isShowBookingCalendar, setIsShowBookingCalendar] = useState(false)
    const startingDate = isMultiSelect
        ? startingDateField.value.split(', ').at(0) || ''
        : startingDateField.value
    const isMultiSelectValueSelected =
        Object.values(bookingDaysField.value).length === 0 ||
        Object.values(bookingDaysField.value).some(value => value === null)
    const isValueSelected =
        Object.values(bookingDaysField.value).every(value => !value) ||
        Object.values(bookingDaysField.value).some(value => value?.length === 0)
    const continueDisabled = isMultiSelect ? isMultiSelectValueSelected : isValueSelected

    const bookingDateDayName = dateHelpers.getWeekDayFullNames(startingDate || new Date(), locale)
    const selectedTime = dateHelpers.getDateTime(bookingDaysField.value[bookingDateDayName] || new Date())
    const selectedTimeDate = startingDate ? `${startingDate}T${selectedTime}` : new Date()
    const isPastMinimumNotice =
        dateHelpers.isDatePast(dateHelpers.subHoursFromDate(selectedTimeDate || new Date(), minHoursNotice))

    useEffect(() => {
        if (isShowBookingCalendar) {
            setIsShowBookingCalendar(false)
        }
    }, [startingDate])

    const dateCustomValueFormat = useBookingCalendarPickerValueFormat(startingDateField.value, '', bookingDaysField.value)
    const shouldShowSubscriptionContent = jobHelpers.shouldShowSubscriptionContent(job)

    return (
        <View style={styles.calendarContainer}>
            <View style={styles.bookingTimeContainer}>
                <BookingDateWeeklyService
                    liteMode
                    showSubscriptionLabel={shouldShowSubscriptionContent}
                    isLoading={isLoading}
                    startingDate={startingDate}
                    timeDescription={timeDescription}
                    bookingDaysField={bookingDaysField}
                />
            </View>
            <View style={styles.datePickerContainer}>
                <View style={styles.label}>
                    <Typography.Regular >
                        {T.screens.jobDetails.rescheduleBooking.startingDate}
                    </Typography.Regular>
                </View>
                <Touchable testID={T.accessibility.bookingDetailsScreen.reschedule.date} style={{
                    ...styles.datePickerToggleButton,
                    ...(isShowBookingCalendar ? styles.toggleButtonBorderWithCalendar : styles.toggleButtonBorder)
                }} onPress={() => setIsShowBookingCalendar(!isShowBookingCalendar)}>
                    <View style={styles.datePickerToggleButtonContainer}>
                        <Icons.DateAndTime
                            size={20}
                            forceColor={theme.colors.black}
                        />
                        <Typography.Regular style={styles.datetimeText}>
                            {dateCustomValueFormat}
                        </Typography.Regular>
                    </View>
                    <View style={isShowBookingCalendar ? styles.chevronUp : styles.chevronDown}>
                        <Icons.Chevron
                            size={10}
                            forceColor={theme.colors.black}
                        />
                    </View>
                    {
                        isShowBookingCalendar && (
                            <View style={styles.bookingCalendarContainer}>
                                <View style={styles.calendarPickerContainer}>
                                    <BookingDateCalendarPicker
                                        liteMode
                                        fullScreen={false}
                                        isOneTime={false}
                                        frequency={frequency}
                                        isLoading={isLoading}
                                        onContinue={onContinue}
                                        allowToggle={allowToggle}
                                        maxDate={maxDate}
                                        isMultiSelect={isMultiSelect}
                                        minHoursNotice={minHoursNotice}
                                        continueLabel={T.common.continue}
                                        continueDisabled={continueDisabled}
                                        startingDateField={startingDateField}
                                        selectedDays={bookingDaysField.value}
                                        onChangeSelectedDays={bookingDaysField.onChangeValue}
                                        description=""
                                        disabled={
                                            disabled ||
                                            !startingDate ||
                                            continueDisabled ||
                                            !bookingDaysField.value[bookingDateDayName] ||
                                            isPastMinimumNotice}
                                        hideContinueButton
                                    />
                                </View>
                            </View>
                        )
                    }
                </Touchable>

            </View>

            {
                // This component appears when the calendar is displayed and applies only to native devices.
                isNative &&
                isShowBookingCalendar &&
                <View style={styles.virtualSpacer} />
            }

            <View style={styles.noteWrapper}>
                <View style={styles.label}/>
                <View style={styles.noteContainer}>
                    <Typography.Regular style={styles.note}>
                        {T.screens.jobDetails.rescheduleBooking.prefixOfNote}
                        <Typography.Regular style={styles.noteSession}>
                            {` 4 ${T.screens.jobDetails.rescheduleBooking.unitOfNote} `}
                        </Typography.Regular>
                        {T.screens.jobDetails.rescheduleBooking.suffixOfNote}
                    </Typography.Regular>
                </View>
            </View>
            <View style={styles.timeZoneMessageContainer}>
                <TimeZoneMessage wideModel />
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    noteWrapper: {
        flexDirection: {
            xs: 'column',
            md: 'row'
        },
        paddingTop: theme.utils.gap(1),
        zIndex: -2
    },
    noteContainer: {
        flex: {
            md: 3,
            xs: 2
        }
    },
    note: {
        color: theme.colors.mouse,
        fontSize: 13
    },
    noteSession: {
        fontWeight: '500',
        fontSize: 13
    },
    timeZoneMessageContainer: {
        paddingTop: theme.utils.gap(2),
        zIndex: -2
    },
    calendarPickerContainer: {
        backgroundColor: theme.colors.fog,
        width: '100%'
    },
    bookingCalendarContainer: {
        zIndex: -1,
        position: 'absolute',
        width: '100%',
        top: isNative ? 46 : '100%',
        left: 0,
        backgroundColor: theme.colors.white,
        borderRadius: 8
    },
    calendarContainer: {
        flexDirection: 'column',
        width: '100%',
        zIndex: 0
    },
    bookingTimeContainer: {
        paddingTop: theme.utils.gap(1)
    },
    datePickerContainer: {
        flexDirection: {
            xs: 'column',
            md: 'row'
        },
        justifyContent: {
            xs: 'center',
            md: 'space-between'
        },
        alignItems: {
            xs: 'flex-start',
            md: 'center'
        },
        paddingTop: theme.utils.gap(0.5),
        zIndex: -1
    },
    label: {
        flex: 1
    },
    datePickerToggleButton: {
        backgroundColor: theme.colors.white,
        flex: {
            md: 3,
            xs: 2
        },
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingLeft: theme.utils.gap(0),
        paddingRight: theme.utils.gap(0),
        paddingVertical: theme.utils.gap(1.25),
        userSelect: 'none',
        position: 'relative',
        boxSizing: 'border-box',
        width: {
            md: undefined,
            xs: '100%'
        },
        marginTop: {
            md: undefined,
            xs: theme.utils.gap(1)
        }
    },
    toggleButtonBorder: {
        borderWidth: 1,
        borderRadius: 8,
        borderColor: theme.colors.black,
        boxSizing: 'border-box'
    },
    toggleButtonBorderWithCalendar: {
        borderRadius: 8,
        borderWidth: 1,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        borderBottomColor: isNative ? undefined : theme.colors.transparent,
        borderColor: theme.colors.fog,
        boxSizing: 'border-box'
    },
    datePickerToggleButtonContainer: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        paddingLeft: theme.utils.gap(1)
    },
    datetimeText: {
        marginLeft: theme.utils.gap(0.5),
        fontSize: 14
    },
    chevronDown: {
        paddingRight: theme.utils.gap(2),
        transform: [
            {
                rotate: '0deg'
            }
        ]
    },
    chevronUp: {
        paddingLeft: theme.utils.gap(2),
        transform: [
            {
                rotate: '180deg'
            }
        ]
    },
    virtualSpacer: {
        width: '100%',
        height: 370,
        backgroundColor: theme.colors.transparent,
        zIndex: -2
    }
}))

export default RescheduleBookingDateTime
