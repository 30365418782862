import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import { useForm } from '@codegateinc/react-form-builder-v2'
import { dateHelpers, jobHelpers, emitter } from 'lib/utils'
import { createStyles } from 'lib/styles'
import { JobStatus, NotificationType } from 'lib/types'
import { useLocaleAtom, useToastAtom, useUserTokenAtom } from 'lib/atoms'
import { Adapter, BasicModal, Button, CallToActionButton, Typography } from 'lib/components'
import { useQueryHelpers, useStyles, useTranslations } from 'lib/hooks'
import { PauseSubscriptionModal } from './pauseSubscriptionModal'
import { JobDetailsResponse } from '../types'
import { PauseBookingsFields, PauseBookingsFormShape, usePauseBookingForm } from '../forms'
import { getConsumerActiveJobs, getJobDetails, pauseJob } from '../actions'
import { usePauseDates } from '../hooks'

type PauseJobProps = {
    job: JobDetailsResponse,
    onRescheduleBooking?: VoidFunction,
    fullWidth?: boolean
}

export const PauseJob: React.FunctionComponent<PauseJobProps> = ({ job, fullWidth, onRescheduleBooking }) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const [locale] = useLocaleAtom()
    const [, setToastMessage] = useToastAtom()
    const { onRequestError } = useQueryHelpers()
    const [token] = useUserTokenAtom()
    const [isModalOpen, setIsModalOpen] = useState(false)
    const { mutate: onPause, isLoading: isMutating } = pauseJob()
    const { refetch: refetchJobDetails, isRefetching: isRefetchingJobDetails } = getJobDetails(job.job._id)
    const { refetch: refetchActiveJobs, isRefetching: isRefetchingActiveJobs } = getConsumerActiveJobs()
    const formReturn = useForm<PauseBookingsFormShape>(usePauseBookingForm(), {
        onSuccess: ({ startDate, endDate }) => {
            onPause(
                {
                    token,
                    id: job.job._id,
                    pause_start_date: startDate,
                    pause_end_date: endDate
                },
                {
                    onSuccess: () => {
                        refetchJobDetails()
                        refetchActiveJobs()
                        setIsModalOpen(false)
                        setToastMessage({
                            type: NotificationType.Success,
                            message: T.components.resumeJob.jobPausedMessage
                        })
                    },
                    onError: onRequestError
                }
            )
        }
    })

    const { submit, form, isFilled, setFieldValue } = formReturn

    const { startOptions, endOptions } = usePauseDates(job.job.schedule, 60, form.startDate.value)

    useEffect(() => {
        if (form.startDate.value) {
            setFieldValue(PauseBookingsFields.EndDate, '')
        }
    }, [form.startDate.value])

    if (job.job.status !== JobStatus.Accepted) {
        return null
    }

    const isLoading = isMutating || isRefetchingJobDetails || isRefetchingActiveJobs
    const isAcceptedSubscription = jobHelpers.isAcceptedSubscription(job)

    const onClose = () => setIsModalOpen(false)

    return (
        <View style={fullWidth ? styles.wrapperFullWidth : styles.wrapper}>
            {
                jobHelpers.shouldShowSubscriptionPauseModal(job) ? (
                    <PauseSubscriptionModal
                        job={job}
                        onSubmit={submit}
                        formReturn={formReturn}
                        isLoading={isLoading}
                        isOpen={isModalOpen}
                        onClose={onClose}
                        onRescheduleBooking={onRescheduleBooking}
                    />
                ) : (
                    <BasicModal
                        isLoading={isLoading}
                        isOpen={isModalOpen}
                        onClose={onClose}
                    >
                        <View style={styles.modalContent}>
                            <Typography.SmallSubheading>
                                {T.components.resumeJob.pauseBooking}
                            </Typography.SmallSubheading>
                            <View style={styles.form}>
                                <View>
                                    <Typography.Regular bold>
                                        {T.components.resumeJob.pauseFrom}
                                    </Typography.Regular>
                                    <Adapter.DateScrollSelect
                                        {...form.startDate}
                                        disabled={isLoading}
                                        options={startOptions.slice(0, 30).map(option => ({
                                            label: dateHelpers.getPauseDates(option, locale),
                                            value: option
                                        }))}
                                    />
                                </View>
                                <View>
                                    <Typography.Regular bold>
                                        {T.components.resumeJob.endOn}
                                    </Typography.Regular>
                                    <Adapter.DateScrollSelect
                                        {...form.endDate}
                                        disabled={isLoading}
                                        options={endOptions.slice(0, 30).map(option => ({
                                            label: dateHelpers.getPauseDates(option, locale),
                                            value: option
                                        }))}
                                    />
                                </View>
                            </View>
                            <Button
                                onPress={submit}
                                disabled={!isFilled}
                                isLoading={isLoading}
                                text={T.common.confirm}
                            />
                        </View>
                    </BasicModal>
                )
            }

            <View style={fullWidth ? styles.buttonWrapperFullWidth : styles.buttonWrapper}>
                <CallToActionButton
                    testID={T.accessibility.jobDetailsScreen.components.pauseJob}
                    text={isAcceptedSubscription ? T.components.resumeJob.pausePlan : T.components.resumeJob.pause}
                    onPress={() => {
                        if (jobHelpers.shouldShowSubscriptionPauseModal(job)) {
                            emitter.emit('openPauseModal', job)

                            return
                        }

                        setIsModalOpen(true)
                    }}
                />
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        marginTop: theme.utils.gap(2)
    },
    wrapperFullWidth: {
        marginBottom: theme.utils.gap(2),
        width: '100%'
    },
    modalContent: {
        paddingHorizontal: {
            lg: theme.utils.gap(2),
            xs: undefined
        }
    },
    form: {
        marginVertical: theme.utils.gap(2),
        flexDirection: {
            lg: 'row',
            xs: 'column'
        }
    },
    buttonWrapper: {
        width: {
            lg: 240,
            xs: undefined
        }
    },
    buttonWrapperFullWidth: {
        width: '100%'
    }
}))
