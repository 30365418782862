import React, { useRef } from 'react'
import Animated, { useSharedValue } from 'react-native-reanimated'
import { ScrollView, TextStyle, View } from 'react-native'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { AnimatedTab, Children } from 'lib/types'
import { Measurements } from 'lib/common'
import { Tabs } from './Tabs'

type AnimatedTabsProps = {
    tabs: Array<AnimatedTab>,
    activeLabelStyles: TextStyle,
    inactiveLabelStyles: TextStyle,
    initialIndex?: number,
    children(
        windowWidth: Animated.SharedValue<number>,
        activeTabIndex: Animated.SharedValue<number>,
        onGoTo: (index: number) => void
    ): Children,
}

export const AnimatedTabs: React.FunctionComponent<AnimatedTabsProps> = ({
    tabs,
    children,
    initialIndex = 0,
    activeLabelStyles,
    inactiveLabelStyles
}) => {
    const { styles } = useStyles(stylesheet)
    const scrollViewRef = useRef<ScrollView>(null)
    const activeTabIndex = useSharedValue<number>(initialIndex)
    const scrollViewWidth = useSharedValue<number>(Measurements.WindowWidth)
    const onGoTo = (index: number) => {
        activeTabIndex.value = index

        scrollViewRef.current?.scrollTo({
            x: index * scrollViewWidth.value,
            y: 0
        })
    }

    return (
        <View>
            <Tabs
                tabs={tabs}
                onPress={onGoTo}
                activeTabIndex={activeTabIndex}
                activeLabelStyles={activeLabelStyles}
                inactiveLabelStyles={inactiveLabelStyles}
            />
            <ScrollView
                horizontal
                ref={scrollViewRef}
                style={styles.scrollView}
                showsHorizontalScrollIndicator={false}
                onLayout={({ nativeEvent }) => {
                    scrollViewWidth.value = nativeEvent.layout.width
                }}
            >
                {children(scrollViewWidth, activeTabIndex, onGoTo)}
            </ScrollView>
        </View>
    )
}

const stylesheet = createStyles(() => ({
    scrollView: {
        flex: 1,
        width: '100%',
        overflowX: 'hidden'
    }
}))
