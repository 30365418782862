import React, { useState } from 'react'
import { View } from 'react-native'
import Animated, { SharedValue, runOnJS, useAnimatedReaction, useAnimatedStyle, useDerivedValue, withTiming } from 'react-native-reanimated'
import { Icons } from 'assets'
import { createStyles } from 'lib/styles'
import { Touchable, Typography } from 'lib/components'
import { useStyles, useTranslations } from 'lib/hooks'

type ViewDetailsProps = {
    onPress: VoidFunction,
    isOpen: SharedValue<boolean>
}

export const ViewDetails: React.FunctionComponent<ViewDetailsProps> = ({
    onPress,
    isOpen
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const [labelText, setLabelText] = useState(isOpen.value
        ? T.common.lessDetails
        : T.common.viewDetails
    )
    const rotateZ = useDerivedValue(() => withTiming(isOpen.value ? -180 : -0))
    const animatedRotateStyle = useAnimatedStyle(() => ({
        transform: [{ rotateZ: `${rotateZ.value}deg` }]
    }))

    useAnimatedReaction(
        () => isOpen.value
            ? T.common.lessDetails
            : T.common.viewDetails,
        result => runOnJS(setLabelText)(result)
    )

    return (
        <Touchable onPress={onPress} testID={
            isOpen.value ?
                T.accessibility.jobDetailsScreen.components.lessDetails :
                T.accessibility.jobDetailsScreen.components.viewDetails}>
            <View style={styles.viewDetails}>
                <Typography.Label>
                    {labelText}
                </Typography.Label>
                <Animated.View style={[styles.chevron, animatedRotateStyle ]}>
                    <Icons.Chevron size={10} />
                </Animated.View>
            </View>
        </Touchable>
    )
}

const stylesheet = createStyles(theme => ({
    chevron: {
        marginLeft: theme.utils.gap(1) / 2
    },
    viewDetails: {
        alignItems: 'center',
        flexDirection: 'row',
        marginTop: theme.utils.gap(2)
    }
}))
