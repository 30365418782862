import React from 'react'
import { View } from 'react-native'
import Animated, { runOnUI } from 'react-native-reanimated'
import { Job } from 'lib/models'
import { JobStatus } from 'lib/types'
import { isNative } from 'lib/common'
import { dateHelpers, jobHelpers } from 'lib/utils'
import { Breakpoint, createStyles } from 'lib/styles'
import { Typography } from 'lib/components'
import { useAccordion, useIsWithinBreakpoints, useStyles, useTranslations } from 'lib/hooks'
import { JobName } from './JobName'
import { JobDetailsResponse } from '../types'
import { CleaningServiceDate } from './CleaningServiceDate'
import { AdditionalInformation } from './AdditionalInformation'
import { ServiceLocation } from './ServiceLocation'
import { CancelRequest } from './CancelRequest'
import { DetailsSession } from './DetailsSession'
import { ViewDetails } from './ViewDetails'
import { RecurringSchedule } from './RecurringSchedule'
import { PausedJobStatus } from './PausedJobStatus'
import { RescheduledMessage } from './RescheduledMessage'
import { RenewalMessage } from './RenewalMessage'
import { NextSessionTile } from './nextSessionTile'
import { CancellationRequestedMessage } from './CancellationRequestedMessage'
import { PausedMessage } from './PausedMessage'

type CleaningRecurringDetailsProps = {
    response: JobDetailsResponse,
    allJobsData: Array<Job>
}

export const CleaningRecurringDetails: React.FunctionComponent<CleaningRecurringDetailsProps> = ({
    response,
    allJobsData
}) => {
    const T = useTranslations()
    const isMobile = useIsWithinBreakpoints(Breakpoint.XS, Breakpoint.MD)
    const { styles, theme } = useStyles(stylesheet)
    const { setHeight, animatedHeightStyle, aref, isOpened } = useAccordion(theme.utils.gap(3))
    const sessions = allJobsData
        .filter(item => response.job._id === item.recurring_job && item.status === JobStatus.Accepted)
        .sort((firstDate, secondDate) => firstDate.date_time.localeCompare(secondDate.date_time))
    const futureSessions = sessions.filter(session => !dateHelpers.isDatePast(session.date_time))
    const [nextSession] = futureSessions || []

    const isAcceptedSubscription = jobHelpers.isAcceptedSubscription(response)

    return (
        <View style={styles.contentWrapper}>
            <View style={styles.content}>
                <JobName
                    job={response}
                    isRecurring
                    recurringJob={response.job}
                    customStyles={styles.resetMargin}
                />
                <Typography.Label>
                    {`${T.components.proposals.expert}: ${response.job.supplier.handle}`}
                </Typography.Label>

                {
                    jobHelpers.shouldShowSubscriptionPausedMessage(response) ?
                        <PausedMessage response={response}/> :
                        <PausedJobStatus job={response} onlyText={!isMobile}/>
                }

                <RenewalMessage response={response} />
                <CancellationRequestedMessage response={response} />

                <View style={styles.viewDetails}>
                    <ViewDetails
                        isOpen={isOpened}
                        onPress={() => runOnUI(setHeight)()}
                    />
                </View>
                <Animated.View style={animatedHeightStyle}>
                    <View
                        ref={aref}
                        collapsable={false}
                        style={styles.accordionWrapper}
                    >
                        <RescheduledMessage response={response} />
                        <RecurringSchedule schedule={response.job.schedule} />
                        <CleaningServiceDate
                            isRecurring
                            isSubmitted={false}
                            job={response}
                        />
                        <AdditionalInformation job={response} />
                        <ServiceLocation job={response.job} />
                        <CancelRequest
                            job={response}
                            jobStatus={JobStatus.Accepted}
                            text={
                                isAcceptedSubscription ?
                                    T.screens.jobDetails.cancelPlan :
                                    T.screens.jobDetails.cancelPackage
                            }
                            forceColor={isAcceptedSubscription ? theme.colors.mouse : undefined}
                        />
                    </View>
                </Animated.View>
            </View>
            {nextSession && (
                <NextSessionTile
                    nextSession={nextSession}
                    sessionIndex={sessions.findIndex(item => item?._id === nextSession?._id) || 0}
                    allSessionsCount={sessions.length}
                />
            )}
            {sessions.length !== 0 && (
                <View style={styles.content}>
                    <Typography.Regular bold>
                        {T.common.upcoming}
                    </Typography.Regular>
                    {sessions.map(session => (
                        <DetailsSession
                            key={session._id}
                            session={session}
                        />
                    ))}
                </View>
            )}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    accordionWrapper: {
        left: 0,
        right: 0,
        position: isNative
            ? 'absolute'
            : undefined
    },
    contentWrapper: {
        flex: 1
    },
    resetMargin: {
        marginBottom: theme.utils.gap(2)
    },
    viewDetails: {
        marginBottom: theme.utils.gap(3)
    },
    content: {
        overflow: 'hidden',
        borderRadius: 8,
        borderWidth: 1,
        paddingHorizontal: theme.utils.gap(2),
        paddingTop: theme.utils.gap(3),
        backgroundColor: theme.colors.white,
        borderColor: theme.colors.silver,
        backGroundColor: theme.colors.white,
        marginBottom: theme.utils.gap(4),
        ...theme.utils.createShadow(2, 0, 8, 4, 4, theme.colors.webShadow(0.04))
    }
}))
