import { useMemo } from 'react'
import { dateHelpers } from 'lib/utils'
import { ChatMessage, GroupedChatMessages } from '../types'

export const useChatMessages = (messages: GroupedChatMessages): Array<ChatMessage | string> =>
    useMemo(() => Object
        .entries(messages)
        .sort(([keyA], [keyB]) => dateHelpers.compareDatesDesc(keyA, keyB))
        .reduce((acc, [key, value]) => acc.concat([
            ...value.sort((messageA, messageB) => dateHelpers.compareDatesDesc(messageA.createdAt, messageB.createdAt)),
            key
        ]), [] as Array<ChatMessage | string>),
    [messages]
    )
