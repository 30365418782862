import React from 'react'
import { Field } from '@codegateinc/react-form-builder-v2'
import { useBackHandler } from 'lib/hooks'
import { AuthFinishSocialSignUpScreenParams } from 'lib/routing'
import { SignUpForm, authForms, authHooks } from 'features/auth'

export const BookingAuthSignUpForm: React.FunctionComponent<AuthFinishSocialSignUpScreenParams> = params => {
    const { isLoading, isFilled, hasError, form, submit } = authHooks.useFinishSocialSignUp(params)

    useBackHandler(() => isLoading)

    return (
        <SignUpForm
            isLoading={isLoading}
            onSubmit={submit}
            providerDetails={params.providerDetails}
            formHasError={!isFilled || hasError}
            form={form as Record<keyof authForms.SignUpFormShape, Field<string & boolean>>}
        />
    )
}
