import { useField } from '@codegateinc/react-form-builder-v2'
import { useTranslations } from 'lib/hooks'
import { BookingFrequency, FrequencyOption, Nullable } from 'lib/types'
import { RescheduleFields } from './forms'
import { JobDetailsResponse } from '../types'
import { jobHelpers } from 'lib/utils'

export const useRescheduleBookingForm = (job: JobDetailsResponse, bookingDaysInitialValue) => {
    const T = useTranslations()
    const initialValue = jobHelpers.getRescheduleFormInitialValue(job, bookingDaysInitialValue)

    const frequency = useField<Nullable<FrequencyOption>>({
        key: RescheduleFields.Frequency,
        initialValue: initialValue.frequency,
        isRequired: false,
        validateOnBlur: false,
        label: T.screens.deepCleaning.formFields.frequency.label
    })

    const startingDate = useField<string>({
        key: RescheduleFields.StartingDate,
        initialValue: initialValue.startingDate,
        isRequired: true,
        validateOnBlur: false,
        label: T.screens.deepCleaning.formFields.startingDate.label,
        placeholder: T.screens.deepCleaning.formFields.startingDate.placeholder,
        validationRules: [
            {
                errorMessage: T.screens.deepCleaning.formFields.startingDate.validation.isRequired,
                validate: Boolean
            }
        ]
    })

    const bookingTime = useField<string>({
        key: RescheduleFields.BookingTime,
        initialValue: initialValue.bookingTime,
        validateOnBlur: true,
        isRequired: frequency.value?.value === BookingFrequency.OneTime,
        placeholder: T.screens.deepCleaning.formFields.selectTime.placeHolder,
        validationRules: [
            {
                errorMessage: T.screens.deepCleaning.formFields.selectTime.validation.isRequired,
                validate: value => {
                    if (frequency.value?.value !== BookingFrequency.OneTime) {
                        return true
                    }

                    return Boolean(value)
                }
            }
        ]
    })

    const bookingDays = useField<Record<string, Nullable<string>>>({
        key: RescheduleFields.BookingDays,
        initialValue: initialValue.bookingDays,
        validateOnBlur: true,
        isRequired: frequency.value?.value !== BookingFrequency.OneTime,
        placeholder: T.screens.deepCleaning.formFields.selectTime.placeHolder,
        validationRules: [
            {
                errorMessage: T.screens.deepCleaning.formFields.selectTime.validation.isRequired,
                validate: value => {
                    if (frequency.value?.value === BookingFrequency.OneTime) {
                        return true
                    }

                    return Object.values(value).some(Boolean)
                }
            }
        ]
    })

    return {
        frequency,
        startingDate,
        bookingTime,
        bookingDays
    }
}
