import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import { Field } from '@codegateinc/react-form-builder-v2'
import { Nullable, SelectInputOption, SupplyID } from 'lib/types'
import { useAddressAtom, useIsAuthorizedAtom, useUserAtom } from 'lib/atoms'
import { PaymentType } from 'lib/models'
import { Segment } from 'lib/analytics'
import { isNative } from 'lib/common'
import { NewAddressShape } from 'features/user'
import { Breakpoint, createStyles } from 'lib/styles'
import { useIsWithinBreakpoints, useStyles, useTranslations } from 'lib/hooks'
import { Adapter, Address, Button, FormComponents, MediaQuery, PolicyLinks, Typography } from 'lib/components'
import { GetPromotionResponse } from '../types'
import { ServiceLayout } from './ServiceLayout'
import { PaymentMethodTile } from './PaymentMethodTile'
import { BookingCancellationFees } from './BookingCancellationFees'
import { ServiceAddonsFormShape, LaundryFormShape, ServiceAddonsFields } from '../forms'
import { useJobQuotation, useLaundryBreadcrumbs } from '../hooks'
import { PromotionCodeInput } from './PromotionCodeInput'
import { Rated } from './Rated'
import { MobileSummary } from './MobileSummary'
import { SummaryNewAddressForm } from './SummaryNewAddressForm'
import { PriceComponent } from './PriceComponent'
import { LaundrySummaryColumn } from './LaundrySummaryColumn'
import { ServiceDescription } from './ServiceDescription'
import { LaundryHelpModalContent } from './LaundryHelpModalContent'
import { BookingAuth } from './unauthorized'

type LaundrySummaryViewProps = {
    disabled: boolean,
    selectedPaymentMethod: PaymentType,
    setPaymentView: (value: boolean) => void,
    form: Record<keyof ServiceAddonsFormShape, Field<boolean & string>>
    addressForm: Record<keyof NewAddressShape, Field<SelectInputOption & string>>,
    formShape: LaundryFormShape,
    isLoading: boolean,
    totalPrice: number,
    submit: VoidFunction,
    promotionPackage: Nullable<GetPromotionResponse>,
    requestPromotion: (code: string) => void,
    onRemovePromoCode: VoidFunction,
    persistentComment: string,
    setPaymentMethod: (value: PaymentType) => void,
    fetchPostalCodes(value: string): Promise<Array<SelectInputOption>>
}

export const LaundrySummaryView: React.FunctionComponent<LaundrySummaryViewProps> = ({
    form,
    disabled,
    formShape,
    totalPrice,
    requestPromotion,
    setPaymentView,
    promotionPackage,
    isLoading,
    addressForm,
    submit,
    selectedPaymentMethod,
    fetchPostalCodes,
    setPaymentMethod,
    persistentComment,
    onRemovePromoCode
}) => {
    const T = useTranslations()
    const [user] = useUserAtom()
    const [address] = useAddressAtom()
    const { styles } = useStyles(stylesheet)
    const breadcrumbs = useLaundryBreadcrumbs()
    const [isHelpModalOpen, setHelpModalOpen] = useState(false)
    const [isAuthorized] = useIsAuthorizedAtom()
    const [authenticationView, setAuthenticationView] = useState(false)
    const notEnoughCredits = selectedPaymentMethod === PaymentType.Credits && user.consumer.balance_credit < totalPrice
    const isMobile = useIsWithinBreakpoints(Breakpoint.XS, Breakpoint.MD)
    const confirmDisabled = isAuthorized
        ? disabled
        : false
    const { jobQuotation, requestJobQuotation } = useJobQuotation(SupplyID.Laundry)
    const [originalPrice, setOriginalPrice] = useState(0)
    const handleConfirm = () => {
        if (!isAuthorized) {
            return setAuthenticationView(true)
        }

        submit()
    }

    useEffect(() => {
        requestJobQuotation(null)
    }, [])

    useEffect(() => {
        if (jobQuotation) {
            setOriginalPrice(jobQuotation?.total?.valueBeforeDiscount ?? 0)
        }
    }, [jobQuotation])

    return (
        <ServiceLayout
            onInfoPress={() => setHelpModalOpen(true)}
            title={T.screens.laundry.title}
            withBackground
            authenticationView={authenticationView}
            onBackFromDetails={() => {
                setAuthenticationView(false)
            }}
            breadcrumbs={breadcrumbs}
            contentColumn={authenticationView
                ? (
                    <BookingAuth
                        supplyId={SupplyID.Laundry}
                        notEnoughCredits={notEnoughCredits}
                        selectedPaymentMethod={selectedPaymentMethod}
                        setPaymentMethod={setPaymentMethod}
                    />
                ) : (
                    <View>
                        {isNative && (
                            <ServiceDescription
                                isHelpModalOpen={isHelpModalOpen}
                                setHelpModalOpen={setHelpModalOpen}
                                title={T.screens.laundry.title}
                                modalContent={(
                                    <LaundryHelpModalContent />
                                )}
                                description={T.screens.laundry.serviceDescription}
                            />
                        )}
                        <View style={styles.wrapper}>
                            <FormComponents.FormRow
                                isOptional
                                title={T.screens.laundrySummary.sections.additionalInformation}
                            >
                                <Adapter.TextInput
                                    {...form.comment}
                                    extraContent={persistentComment}
                                    inputProps={{
                                        multiline: true
                                    }}
                                    onBlur={() => {
                                        if (form.comment.value) {
                                            Segment.bookingAdditionalInformationAdded({
                                                information: {
                                                    type: ServiceAddonsFields.Comment,
                                                    value: form.comment.value
                                                }
                                            })
                                        }
                                    }}
                                />
                                <View>
                                    {!address.postcode && isAuthorized && (
                                        <SummaryNewAddressForm
                                            form={addressForm}
                                            isLoading={isLoading}
                                            fetchPostalCodes={fetchPostalCodes}
                                        />
                                    )}
                                </View>
                            </FormComponents.FormRow>
                            <View style={styles.packagingReminder}>
                                <Typography.Label>
                                    {T.screens.laundrySummary.packingReminder}
                                </Typography.Label>
                            </View>
                            <View style={styles.flexSpacer} />
                        </View>
                        {isMobile && (
                            <PolicyLinks />
                        )}
                    </View>
                )}
            summaryColumn={(
                <MediaQuery.Visible from={Breakpoint.LG}>
                    {!(isAuthorized && authenticationView) && (
                        <View>
                            <Address disabled={isLoading} />
                        </View>
                    )}
                    <LaundrySummaryColumn
                        totalPrice={totalPrice}
                        originalPrice={originalPrice}
                        promotionPackage={promotionPackage}
                        title={T.common.bookingSummary}
                        renderPriceComponent={() => (
                            <PriceComponent
                                price={totalPrice}
                                originalPrice={originalPrice}
                                text={T.common.estimatedPrice}
                            />
                        )}
                        form={formShape}
                        couponText={promotionPackage
                            ? promotionPackage.promotion.message_to_client
                            : undefined
                        }
                        renderExtraContent={() => (
                            <PromotionCodeInput
                                promoCode={form.promoCode}
                                isLoading={isLoading}
                                promotionPackage={promotionPackage}
                                onRemovePromoCode={onRemovePromoCode}
                                requestPromotion={requestPromotion}
                            />
                        )}
                        jobQuotation={jobQuotation}
                    />
                    {!authenticationView && (
                        <PaymentMethodTile
                            notEnoughCredits={notEnoughCredits}
                            onPress={() => setPaymentView(true)}
                            selectedPaymentMethod={selectedPaymentMethod}
                        />
                    )}
                    <PolicyLinks />
                    {(!authenticationView || isAuthorized) && (
                        <Button
                            disabled={notEnoughCredits || confirmDisabled}
                            isLoading={isLoading}
                            onPress={handleConfirm}
                            text={T.common.confirm}
                        />
                    )}
                    <BookingCancellationFees />
                </MediaQuery.Visible>
            )}
            footer={(
                <MediaQuery.Hidden from={Breakpoint.LG}>
                    <MobileSummary
                        authenticationView={authenticationView && !isAuthorized}
                        submit={handleConfirm}
                        submitText={T.common.confirm}
                        priceLabel={T.common.totalPrice}
                        price={totalPrice}
                        onRemovePromoCode={onRemovePromoCode}
                        priceBeforeDiscount={originalPrice}
                        promoCode={form.promoCode}
                        promotionPackage={promotionPackage}
                        requestPromotion={requestPromotion}
                        isLoading={isLoading}
                        disabled={notEnoughCredits || confirmDisabled}
                        content={(
                            <React.Fragment>
                                <View>
                                    <Address disabled={isLoading} />
                                </View>
                                <LaundrySummaryColumn
                                    totalPrice={totalPrice}
                                    originalPrice={originalPrice}
                                    promotionPackage={promotionPackage}
                                    renderPriceComponent={() => (
                                        <PriceComponent
                                            price={totalPrice}
                                            originalPrice={originalPrice}
                                            text={T.common.estimatedPrice}
                                        />
                                    )}
                                    form={formShape}
                                    couponText={promotionPackage
                                        ? promotionPackage.promotion.message_to_client
                                        : undefined
                                    }
                                    // remove this check after guest booking experiment
                                    renderExtraContent={() => !isAuthorized ? (
                                        <PromotionCodeInput
                                            promoCode={form.promoCode}
                                            isLoading={isLoading}
                                            promotionPackage={promotionPackage}
                                            onRemovePromoCode={onRemovePromoCode}
                                            requestPromotion={requestPromotion}
                                        />
                                    ) : null}
                                    jobQuotation={jobQuotation}
                                />
                                {!authenticationView && (
                                    <PaymentMethodTile
                                        notEnoughCredits={notEnoughCredits}
                                        onPress={() => setPaymentView(true)}
                                        selectedPaymentMethod={selectedPaymentMethod}
                                    />
                                )}
                                <PolicyLinks />
                                <Rated />
                                <BookingCancellationFees />
                            </React.Fragment>
                        )}
                    />
                </MediaQuery.Hidden>
            )}
        />
    )
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        marginTop: {
            lg: 0,
            xs: theme.utils.gap(1)
        },
        zIndex: theme.zIndex[10]
    },
    selectWrapper: {
        zIndex: theme.zIndex[10],
        marginTop: -theme.utils.gap(2)
    },
    addonsRow: {
        flexDirection: {
            lg: 'row',
            xs: 'column'
        }
    },
    spacer: {
        width: theme.utils.gap(2)
    },
    flexSpacer: {
        flex: 1
    },
    packagingReminder: {
        width: '100%',
        padding: theme.utils.gap(2),
        borderRadius: theme.utils.gap(1),
        backgroundColor: theme.colors.silver,
        marginBottom: theme.utils.gap(4)
    }
}))
