import { useEffect } from 'react'
import { useForm } from '@codegateinc/react-form-builder-v2'
import { useTranslations } from 'lib/hooks'
import { ViewStyle } from 'lib/types'
import { Job } from 'lib/models'
import { dateHelpers, R } from 'lib/utils'
import { isNative } from 'lib/common'
import { BookingsFiltersFields, BookingsFiltersShape, useBookingsFiltersForm } from '../forms'

export const useBookingsFilters = (jobs: Array<Job> = [], viewStyle: ViewStyle) => {
    const T = useTranslations()
    const viewAllMonths = T.screens.bookings.viewAllMonths
    const { form, setFieldInitialValue, resetForm, setFieldValue } = useForm<BookingsFiltersShape>(useBookingsFiltersForm(), {
        onSuccess: R.T
    })

    useEffect(() => {
        setFieldInitialValue(BookingsFiltersFields.Date, viewAllMonths)
    }, [])

    useEffect(() => {
        if (form.service.value) {
            setFieldValue(BookingsFiltersFields.SearchValue, null)
            setFieldValue(BookingsFiltersFields.Date, isNative ? '' : viewAllMonths)
        }
    }, [form.service.value])

    useEffect(() => {
        if (form.searchValue.value) {
            setFieldValue(BookingsFiltersFields.Service, null)
            setFieldValue(BookingsFiltersFields.Date, isNative ? '' : viewAllMonths)
        }
    }, [form.searchValue.value])

    useEffect(() => {
        if (form.date.value && form.date.value !== viewAllMonths) {
            setFieldValue(BookingsFiltersFields.Service, null)
            setFieldValue(BookingsFiltersFields.SearchValue, null)
        }
    }, [form.date.value])

    const filters = () => {
        try {
            const serviceFilter = form.service.value
                ? Number(form.service.value)
                : null

            const searchFilter = form.searchValue.value
                ? JSON.parse(form.searchValue.value) as Job
                : null

            return {
                serviceFilter,
                searchFilter
            }
        } catch {
            return {
                serviceFilter: null,
                searchFilter: form.searchValue.value
            }
        }
    }

    const { searchFilter, serviceFilter } = filters()

    const filteredJobs = jobs
        .filter(item => {
            if (viewStyle === ViewStyle.Calendar) {
                return true
            }

            if (searchFilter || serviceFilter !== null) {
                return true
            }

            if (form.date.value === viewAllMonths || !form.date.value) {
                return true
            }

            return dateHelpers.isSameMonthAndYear(item.date_time, form.date.value)
        })
        .filter(item => {
            if (!searchFilter || serviceFilter) {
                return true
            }

            return R.hasKeys(searchFilter)
                ? item._id === searchFilter?._id || item.job_id?.toLocaleLowerCase().includes(searchFilter?.job_id.toLocaleLowerCase())
                : item._id === `${searchFilter}` || item.job_id?.toLocaleLowerCase().includes(`${searchFilter}`.toLocaleLowerCase())
        })
        .filter(item => {
            if (serviceFilter !== null) {
                return item.supply.id === serviceFilter
            }

            return true
        })

    return {
        filteredJobs,
        resetSearchForm: resetForm,
        dateField: form.date,
        searchField: form.searchValue,
        serviceField: form.service
    }
}
