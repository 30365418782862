import React, { useEffect, useState } from 'react'
import { StyleSheet } from 'react-native'
import 'react-native-get-random-values'
import 'date-time-format-timezone'
import { useNavigationContainerRef } from '@react-navigation/native'
import { StatusBar } from 'expo-status-bar'
import * as SplashScreen from 'expo-splash-screen'
import { QueryClientProvider } from '@tanstack/react-query'
import { GestureHandlerRootView } from 'react-native-gesture-handler'
import { XMPPChatProvider } from 'features/chat'
import { PushNotificationProvider } from 'features/pushNotifications'
import { ForceUpdateListener, GeneralUpdateListener, OTAUpdateListener } from 'features/updates'
import { initDataDog } from 'lib/datadog'
import { isNative } from 'lib/common'
import { queryClient } from 'lib/api'
import { AppRouter, NavigationScreenParams } from 'lib/routing'
import { R } from 'lib/utils'
import { useCustomFonts, useQueryFocusManager } from 'lib/hooks'
import { AppVersion, Hotjar, SendhelperListener, Toast } from 'lib/components'
import { MaintenanceScreen } from 'features/maintenance'
import { ServiceConfigurationProvider } from 'features/servicesConfig'
import { getAppsFlyerId } from 'lib/analytics/segment/utils'
import { useAppsflyerIdAtom } from 'lib/atoms'

SplashScreen
    .preventAutoHideAsync()
    .catch(R.T)

export const App: React.FunctionComponent = () => {
    const { fontsLoaded, onLayoutRootView } = useCustomFonts()
    const [isNavigationReady, setNavigationIsReady] = useState(false)
    const [, setAppsflyerId] = useAppsflyerIdAtom()
    const navigationRef = useNavigationContainerRef<NavigationScreenParams>()

    useQueryFocusManager()
    useEffect(() => {
        initDataDog()
            .catch(R.T)

        const setAppsflyerIdFn = async () => {
            const id = await getAppsFlyerId
            setAppsflyerId(id)
        }
        setAppsflyerIdFn()
    }, [])

    if (!fontsLoaded) {
        return null
    }

    return (
        <QueryClientProvider client={queryClient}>
            <Hotjar />
            <GestureHandlerRootView
                onLayout={onLayoutRootView}
                style={styles.container}
            >
                <SendhelperListener>
                    <XMPPChatProvider>
                        <ServiceConfigurationProvider>
                            <AppRouter
                                navigationRef={navigationRef}
                                onNavigationReady={() => setNavigationIsReady(true)}
                            />
                        </ServiceConfigurationProvider>
                    </XMPPChatProvider>
                </SendhelperListener>
                <StatusBar style="auto" />
                <AppVersion />
                <Toast />
                {isNative && (
                    <React.Fragment>
                        <OTAUpdateListener />
                        <ForceUpdateListener />
                        <GeneralUpdateListener />
                    </React.Fragment>
                )}
                <MaintenanceScreen />
                {isNavigationReady && (
                    <PushNotificationProvider navigationRef={navigationRef} />
                )}
            </GestureHandlerRootView>
        </QueryClientProvider>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        width: '100%'
    }
})
