import { enUS } from 'date-fns/locale'
import { en_US } from './en_US'

export enum Languages {
    en_US = 'en_US',
}

export const languages = {
    [Languages.en_US]: en_US
}

export const calendarLanguages = {
    [Languages.en_US]: enUS
}

export const timeZones = {
    ASIA_SINGAPORE: 'Asia/Singapore'
}

export const supportedTimeZones = {
    [timeZones.ASIA_SINGAPORE] : {
        label: 'Singapore',
        timeZone: timeZones.ASIA_SINGAPORE,
        timeZoneName: 'SGT',
        timeZoneOffset: 'GMT+8'
    }
}

