import React from 'react'
import Animated, { useAnimatedStyle } from 'react-native-reanimated'
import { Image } from 'react-native'
import { useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { Grid, Typography } from 'lib/components'
import { linkingHelpers } from 'lib/utils'
import { Images } from 'assets'

type BookInMobileAppProps = {
    onPress: VoidFunction,
    animatedScale: Animated.SharedValue<number>,
    animatedOpacity: Animated.SharedValue<number>
}

export const BookInMobileApp: React.FunctionComponent<BookInMobileAppProps> = ({
    onPress,
    animatedScale,
    animatedOpacity
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const animatedOverlayStyles = useAnimatedStyle(() => ({
        opacity: animatedOpacity.value,
        transform: [
            {
                scale: animatedScale.value
            }
        ]
    }))

    return (
        <Animated.View
            style={[
                styles.overlay,
                animatedOverlayStyles
            ]}
        >
            <Typography.Regular style={styles.bookNote}>
                {T.screens.allServices.bookInMobileApp}
            </Typography.Regular>
            <Grid.Gap
                gapTop={3}
                gapBottom={1}
            >
                <Typography.Regular
                    onPress={() => {
                        linkingHelpers.openSendhelperAppStore()
                        onPress()
                    }}
                >
                    <Image
                        source={Images.AppStore}
                        style={styles.store}
                    />
                </Typography.Regular>
            </Grid.Gap>
            <Typography.Regular
                onPress={() => {
                    linkingHelpers.openSendhelperGooglePlayStore()
                    onPress()
                }}
            >
                <Image
                    source={Images.GooglePlayStore}
                    style={styles.store}
                />
            </Typography.Regular>
        </Animated.View>
    )
}

const stylesheet = createStyles(theme => ({
    overlay: {
        borderRadius: 8,
        backgroundColor: theme.colors.sun,
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        justifyContent: 'center',
        alignItems: 'center'
    },
    store: {
        width: 156,
        height: 45
    },
    bookNote: {
        textAlign: 'center',
        color: theme.colors.white
    }
}))
