import { Icons } from 'assets'
import { useStyles } from 'lib/hooks'

export const CustomDropdownIndicator = () => {
    const { theme } = useStyles()

    return (
        <Icons.Select
            size={22}
            forceColor={theme.colors.orange}
        />
    )
}
