import { ENV } from './env'

export const APP_CONFIG = {
    KEYS: {
        STORAGE: 'sendhelper_customer_storage',
        FACEBOOK_SCRIPT_ID: 'facebook_sdk',
        APPLE_SCRIPT_ID: 'apple_sdk',
        GOOGLE_SCRIPT_ID: 'google_sdk'
    },
    API: {
        URL: ENV.API_URL,
        REQUEST_TIMEOUT: 10000
    },
    GA4: {
        TRACKING_ID: ENV.GOOGLE_TRACKING_ID
    },
    DATADOG: {
        ENABLED: Boolean(ENV.DD_ENABLED && ENV.DD_ENABLED === 'true'),
        APP_ID: ENV.DD_RUM_APP_ID,
        CLIENT_TOKEN: ENV.DD_CLIENT_TOKEN,
        SITE: ENV.DD_SITE,
        SERVICE: ENV.DD_SERVICE
    },
    FIREBASE: {
        API_KEY: ENV.FIREBASE_API_KEY,
        AUTH_DOMAIN: ENV.FIREBASE_AUTH_DOMAIN,
        PROJECT_ID: ENV.FIREBASE_PROJECT_ID,
        STORAGE_BUCKET: ENV.FIREBASE_STORAGE_BUCKET,
        MESSAGING_SENDER_ID: ENV.FIREBASE_MESSAGING_SENDER_ID,
        APP_ID: ENV.FIREBASE_APP_ID
    },
    RECAPTCHA: {
        PRIVACY_POLICY: 'https://policies.google.com/privacy',
        TERMS_OF_SERVICE: 'https://policies.google.com/terms'
    },
    APP: {
        VERSION: ENV.DEPRECATED_APP_VERSION,
        WEB_VERSION: ENV.DD_VERSION
    },
    SOCIAL: {
        SHARE_URL: 'https://app.sendhelper.com/refer',
        FACEBOOK: 'https://www.facebook.com/sendhelperbypg',
        TWITTER: 'https://twitter.com/sendhelper',
        INSTAGRAM: 'https://www.instagram.com/sendhelperbypg/'
    },
    CHAT: {
        WEBSOCKET_URL: ENV.WEBSOCKET_URL,
        TIMESTAMP_DEFAULT: ENV.TIMESTAMP_DEFAULT,
        MAX_RECEIVED_MESSAGE: ENV.MAX_RECEIVED_MESSAGE,
        SUFFIX_SERVER_CHAT_NAME: ENV.SUFFIX_SERVER_CHAT_NAME,
        SUFFIX_ROOM_NAME: ENV.SUFFIX_ROOM_NAME
    },
    ONEMAP_API: {
        URL: ENV.ONEMAP_API
    },
    QUOTATION:{
        URL: ENV.QUOTATION_URL
    },
    CONTACT: {
        MAIL: 'Info@Sendhelper.com',
        PHONE: '+659387-5891'
    },
    SERVICES: {
        IS_HANDYMAN_ENABLED: Boolean(ENV.ENABLE_HANDYMAN && ENV.ENABLE_HANDYMAN === 'true'),
        IS_COOKING_ENABLED: Boolean(ENV.ENABLE_COOKING && ENV.ENABLE_COOKING === 'true'),
        IS_LAUNDRY_ENABLED: Boolean(ENV.ENABLE_LAUNDRY && ENV.ENABLE_LAUNDRY === 'true'),
        IS_TASK_ERRANDS_ENABLED: Boolean(ENV.ENABLE_TASK_ERRANDS && ENV.ENABLE_TASK_ERRANDS === 'true'),
        IS_PEST_CONTROL_ENABLED: Boolean(ENV.ENABLE_PEST_CONTROL && ENV.ENABLE_PEST_CONTROL === 'true'),
        IS_MOVERS_AND_PACKERS_ENABLED: Boolean(ENV.ENABLE_MOVERS_AND_PACKERS && ENV.ENABLE_MOVERS_AND_PACKERS === 'true')
    }
}
