import React from 'react'
import { View } from 'react-native'
import { createStyles } from 'lib/styles'
import { Typography } from 'lib/components'
import { useStyles, useTranslations } from 'lib/hooks'
import { PriceComponent } from '../PriceComponent'
import { Nullable } from 'lib/types'

type MoversAndPackersPriceNoteProps = {
    price: number,
    originalPrice?: Nullable<number>
}

export const MoversAndPackersPriceNote: React.FunctionComponent<MoversAndPackersPriceNoteProps> = ({
    price,
    originalPrice
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)

    return (
        <View style={styles.container}>
            <PriceComponent
                price={price}
                originalPrice={originalPrice}
                text={T.common.estimatedPrice}
                quotationText={T.common.priceOnQuotation}
            />
            <View style={styles.note}>
                <Typography.Label forceColor={theme.colors?.mouse}>
                    {`*${T.screens.moversAndPackers.priceNote}`}
                </Typography.Label>
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        flex: 1
    },
    note: {
        marginTop: theme.utils.gap(2)
    }
}))
