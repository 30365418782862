import React from 'react'
import { useNavigation } from '@react-navigation/native'
import { View } from 'react-native'
import { Job, RecurringJob } from 'lib/models'
import { ENV } from 'lib/config'
import { Segment, segmentUtils } from 'lib/analytics'
import { JobStatus, JobType, LaundryStatus } from 'lib/types'
import { linkingHelpers } from 'lib/utils'
import { createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { CopyBookingId, Touchable, Typography } from 'lib/components'
import { JobStatusLabel } from './JobStatusLabel'
import { JobTypeLabel } from './JobTypeLabel'
import { JobContact } from './JobContact'
import { BookingTileButton } from './BookingTileButton'
import { RescheduleLaundry } from './laundry'
import { useJobDetailsRoute } from '../hooks'

type BookingsListViewTileProps = {
    job: Job,
    jobStatus: JobStatus,
    recurringJobs: Array<RecurringJob>
}

export const BookingsListViewTile: React.FunctionComponent<BookingsListViewTileProps> = ({
    job,
    jobStatus,
    recurringJobs
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const navigate = useJobDetailsRoute(job, jobStatus, recurringJobs)
    const isAccepted = job.supplier && job.status === JobStatus.Accepted
    const isRecurring = job.job_type === JobType.Recurring
    const navigation = useNavigation()
    const { screenName } = linkingHelpers.getInternalPathBySupplyId(job?.supply?.id)
    const showRescheduleLaundry = job?.laundry_options?.status === LaundryStatus.Ready && !job?.laundry_options?.delivery_slot?.date
    const showProposalsButton = [
        job.job_proposals?.length !== 0,
        jobStatus === JobStatus.Submitted,
        !isAccepted,
        !isRecurring,
        !showRescheduleLaundry
    ].every(Boolean)

    return (
        <Touchable onPress={navigate} testID={T.accessibility.bookingsScreen.views.listView.item}>
            <View style={styles.container}>
                <View style={styles.icon}>
                    <CopyBookingId id={job.job_id} />
                </View>
                <View style={styles.jobIdWrapper}>
                    <View style={styles.jobContainer}>
                        <Typography.Regular forceColor={theme.colors.mouse}>
                            {job.job_id}
                        </Typography.Regular>
                        <JobStatusLabel job={job} />
                    </View>
                </View>
                <JobTypeLabel
                    job={job}
                    parentJob={recurringJobs.find(item => item?._id === job.recurring_job)}
                    recurringJobs={recurringJobs}
                    isCompletedPage={jobStatus === JobStatus.Completed}
                />
                {showRescheduleLaundry && (
                    // touchable wrapper to fix case when presses passed throughout modal
                    <Touchable onPress={() => {}}>
                        <RescheduleLaundry
                            job={job}
                            customButton={onPress => (
                                <BookingTileButton
                                    text={T.screens.laundryDetails.setDeliveryTime}
                                    onPress={onPress}
                                />
                            )}
                        />
                    </Touchable>
                )}
                {isAccepted && !showRescheduleLaundry && (
                    <JobContact job={job} />
                )}
                {showProposalsButton && (
                    <BookingTileButton
                        text={T.components.jobStatus.proposalReceived}
                        onPress={navigate}
                    />
                )}
                {jobStatus === JobStatus.Completed && !showRescheduleLaundry && (
                    <BookingTileButton
                        text={T.components.jobStatus.bookAgain}
                        onPress={() => {
                            Segment.bookAgainClicked({
                                jobId: job.job_id,
                                serviceType: segmentUtils.supplyIdToServiceType(job.supply.id),
                                jobStatus: segmentUtils.getReadableJobStatus(job.status)
                            })

                            screenName
                            // @ts-ignore
                                ? navigation.navigate(screenName)
                                : linkingHelpers.openUrl(ENV.SENDHELPER_MARKETING_SITE_URL, false)
                        }
                        }
                    />
                )}
            </View>
        </Touchable>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        flexDirection: {
            lg: 'row',
            xs: 'column'
        },
        borderRadius: 8,
        alignItems: {
            lg: 'center',
            xs: undefined
        },
        backgroundColor: theme.colors.white,
        paddingVertical: {
            lg: theme.utils.gap(3),
            xs: theme.utils.gap(1)
        },
        paddingHorizontal: {
            lg: theme.utils.gap(3),
            xs: theme.utils.gap(1)
        },
        marginTop: theme.utils.gap(1),
        ...theme.utils.createShadow(2, 0, 8, 4, 4, theme.colors.webShadow(0.04))
    },
    jobIdWrapper: {
        width: {
            lg: 180,
            xs: undefined
        },
        marginTop: {
            lg: 0,
            xs: theme.utils.gap(1)
        },
        marginLeft: theme.utils.gap(2),
        flexDirection: 'row',
        justifyContent: {
            lg: undefined,
            xs: 'space-between'
        },
        alignItems: {
            lg: 'center',
            xs: undefined
        }
    },
    jobContainer: {
        flex: 1,
        flexDirection: {
            lg: 'column',
            xs: 'row'
        }
    },
    icon: {
        position: {
            lg: undefined,
            xs: 'absolute'
        },
        zIndex: {
            lg: undefined,
            xs: theme.zIndex[10]
        },
        top: {
            lg: undefined,
            xs: theme.utils.gap(2)
        },
        right: {
            lg: undefined,
            xs: theme.utils.gap(1)
        }
    }
}))
