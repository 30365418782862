import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const PlusCircle: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path d="M52 36h-8v-8c0-2.2-1.8-4-4-4s-4 1.8-4 4v8h-8c-2.2 0-4 1.8-4 4s1.8 4 4 4h8v8c0 2.2 1.8 4 4 4s4-1.8 4-4v-8h8c2.2 0 4-1.8 4-4s-1.8-4-4-4ZM40 72C22.356 72 8 57.644 8 40S22.356 8 40 8s32 14.356 32 32-14.356 32-32 32Zm0-72C17.944 0 0 17.944 0 40s17.944 40 40 40 40-17.944 40-40S62.056 0 40 0Z" />
    </Icon>
)
