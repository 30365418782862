import { useField } from '@codegateinc/react-form-builder-v2'
import { ExtendSessionFields } from './forms'

export const useExtendSessionForm = () => {

    const extraHours = useField<string>({
        key: ExtendSessionFields.ExtraHours,
        initialValue: '0',
        isRequired: false,
        validateOnBlur: false
    })

    return {
        extraHours
    }
}
