import React, { useState } from 'react'
import { View } from 'react-native'
import { Field } from '@codegateinc/react-form-builder-v2'
import { Nullable, SelectInputOption, SupplyID } from 'lib/types'
import { isNative } from 'lib/common'
import { useAddressAtom, useIsAuthorizedAtom, useUserAtom } from 'lib/atoms'
import { PaymentType } from 'lib/models'
import { Segment } from 'lib/analytics'
import { NewAddressShape } from 'features/user'
import { Breakpoint, createStyles } from 'lib/styles'
import { useIsWithinBreakpoints, useStyles, useTranslations } from 'lib/hooks'
import { Adapter, Address, Button, FormComponents, MediaQuery, PolicyLinks } from 'lib/components'
import { GetPromotionResponse, JobQuotationResponse } from '../types'
import { ServiceLayout } from './ServiceLayout'
import { PaymentMethodTile } from './PaymentMethodTile'
import { TotalPriceComponent } from './TotalPriceComponent'
import { BookingCancellationFees } from './BookingCancellationFees'
import { ServiceAddonsFormShape, DeepCleaningFormShape, ServiceAddonsFields } from '../forms'
import { useDeepCleaningBreadcrumbs, usePetsOptions } from '../hooks'
import { PromotionCodeInput } from './PromotionCodeInput'
import { Rated } from './Rated'
import { MobileSummary } from './MobileSummary'
import { SummaryNewAddressForm } from './SummaryNewAddressForm'
import { DeepCleaningSummaryColumn } from './DeepCleaningSummaryColumn'
import { ServiceDescription } from './ServiceDescription'
import { DeepCleaningHelpModalContent } from './DeepCleaningHelpModalContent'
import { BookingAuth } from './unauthorized'
import { PriceSummaryComponent } from './PriceSummaryComponent'
import { getServiceStaticConfig } from '../utils'

type DeepCleaningSummaryViewProps = {
    disabled: boolean,
    selectedPaymentMethod: PaymentType,
    setPaymentView: (value: boolean) => void,
    form: Record<keyof ServiceAddonsFormShape, Field<boolean & string>>
    addressForm: Record<keyof NewAddressShape, Field<SelectInputOption & string>>,
    formShape: DeepCleaningFormShape,
    isLoading: boolean,
    totalPrice: number,
    jobQuotation?: JobQuotationResponse,
    submit: VoidFunction,
    setPaymentMethod: (value: PaymentType) => void,
    promotionPackage: Nullable<GetPromotionResponse>,
    originalPrice?: Nullable<number>,
    requestPromotion: (code: string) => void,
    onRemovePromoCode: VoidFunction,
    fetchPostalCodes(value: string): Promise<Array<SelectInputOption>>
}

export const DeepCleaningSummaryView: React.FunctionComponent<DeepCleaningSummaryViewProps> = ({
    form,
    disabled,
    formShape,
    totalPrice,
    originalPrice,
    requestPromotion,
    setPaymentView,
    promotionPackage,
    isLoading,
    addressForm,
    submit,
    onRemovePromoCode,
    setPaymentMethod,
    selectedPaymentMethod,
    fetchPostalCodes,
    jobQuotation
}) => {
    const T = useTranslations()
    const [user] = useUserAtom()
    const [address] = useAddressAtom()
    const { styles } = useStyles(stylesheet)
    const petsOptions = usePetsOptions()
    const breadcrumbs = useDeepCleaningBreadcrumbs()
    const [isHelpModalOpen, setHelpModalOpen] = useState(false)
    const [isAuthorized] = useIsAuthorizedAtom()
    const [authenticationView, setAuthenticationView] = useState(false)
    const notEnoughCredits = selectedPaymentMethod === PaymentType.Credits && user.consumer.balance_credit < totalPrice
    const isMobile = useIsWithinBreakpoints(Breakpoint.XS, Breakpoint.MD)

    const config = getServiceStaticConfig(SupplyID.DeepCleaning)
    const { pricing } = config
    const columnWidth = isNative || isMobile
        ? undefined
        : 'calc(50% - 10px)'
    const confirmDisabled = isAuthorized
        ? disabled
        : false
    const handleConfirm = () => {
        if (!isAuthorized) {
            return setAuthenticationView(true)
        }

        submit()
    }

    return (
        <ServiceLayout
            title={T.screens.deepCleaning.title}
            onInfoPress={() => setHelpModalOpen(true)}
            withBackground
            authenticationView={authenticationView}
            onBackFromDetails={() => {
                setAuthenticationView(false)
            }}
            breadcrumbs={breadcrumbs}
            contentColumn={authenticationView
                ? (
                    <BookingAuth
                        supplyId={SupplyID.DeepCleaning}
                        notEnoughCredits={notEnoughCredits}
                        selectedPaymentMethod={selectedPaymentMethod}
                        setPaymentMethod={setPaymentMethod}
                    />
                ) : (
                    <View>
                        {isNative && (
                            <ServiceDescription
                                isHelpModalOpen={isHelpModalOpen}
                                setHelpModalOpen={setHelpModalOpen}
                                title={T.screens.deepCleaning.title}
                                modalContent={(
                                    <DeepCleaningHelpModalContent />
                                )}
                                description={T.screens.deepCleaning.serviceDescription}
                            />
                        )}
                        <View style={styles.wrapper}>
                            <FormComponents.FormRow
                                isOptional
                                title={T.screens.deepCleaningSummary.sections.additionalInformation}
                            >
                                <View
                                    style={{
                                        ...styles.selectWrapper,
                                        width: columnWidth
                                    }}
                                >
                                    <Adapter.SelectInputColumn
                                        {...form.pets}
                                        disabled={isLoading}
                                        options={petsOptions}
                                        onOptionSelect={({ label }) => {
                                            Segment.bookingAdditionalInformationAdded({
                                                information: {
                                                    type: ServiceAddonsFields.Pets,
                                                    value: label
                                                }
                                            })
                                        }}
                                    />
                                </View>
                                <Adapter.TextInput
                                    {...form.comment}
                                    inputProps={{
                                        multiline: true
                                    }}
                                    onBlur={() => {
                                        if (form.comment.value) {
                                            Segment.bookingAdditionalInformationAdded({
                                                information: {
                                                    type: ServiceAddonsFields.Comment,
                                                    value: form.comment.value
                                                }
                                            })
                                        }
                                    }}
                                />
                                <View>
                                    {!address.postcode && isAuthorized && (
                                        <SummaryNewAddressForm
                                            form={addressForm}
                                            isLoading={isLoading}
                                            fetchPostalCodes={fetchPostalCodes}
                                        />
                                    )}
                                </View>
                            </FormComponents.FormRow>
                        </View>
                        {isMobile && (
                            <PolicyLinks />
                        )}
                    </View>
                )
            }
            summaryColumn={(
                <MediaQuery.Visible from={Breakpoint.LG}>
                    {!(isAuthorized && authenticationView) && (
                        <View>
                            <Address disabled={isLoading} />
                        </View>
                    )}
                    <DeepCleaningSummaryColumn
                        title={T.common.bookingSummary}
                        renderPriceComponent={() => (
                            <TotalPriceComponent
                                totalPrice={jobQuotation?.total?.value || 0}
                                originalPrice={originalPrice}
                                promotion={promotionPackage?.promotion}
                            />
                        )}
                        renderPriceSummaryComponent={() => jobQuotation?.priceSummary ? (
                            <PriceSummaryComponent priceSummary={jobQuotation?.priceSummary} promotion={jobQuotation.discount} />
                        ) : undefined}
                        form={formShape}
                        couponText={promotionPackage
                            ? promotionPackage.promotion.message_to_client
                            : undefined
                        }
                        propertySizeLabel={pricing.propertySize.options.find(area => area.id === +formShape.propertySize)?.label || ''}
                        totalPrice={totalPrice}
                        originalPrice={originalPrice}
                        promotionPackage={promotionPackage}
                        renderExtraContent={() => (
                            <PromotionCodeInput
                                promoCode={form.promoCode}
                                isLoading={isLoading}
                                promotionPackage={promotionPackage}
                                onRemovePromoCode={onRemovePromoCode}
                                requestPromotion={requestPromotion}
                            />
                        )}
                    />
                    {!authenticationView && (
                        <PaymentMethodTile
                            notEnoughCredits={notEnoughCredits}
                            onPress={() => setPaymentView(true)}
                            selectedPaymentMethod={selectedPaymentMethod}
                        />
                    )}
                    <PolicyLinks />
                    {(!authenticationView || isAuthorized) && (
                        <Button
                            disabled={notEnoughCredits || confirmDisabled}
                            isLoading={isLoading}
                            onPress={handleConfirm}
                            text={T.common.confirm}
                        />
                    )}
                    <BookingCancellationFees />
                </MediaQuery.Visible>
            )}
            footer={(
                <MediaQuery.Hidden from={Breakpoint.LG}>
                    <MobileSummary
                        authenticationView={authenticationView && !isAuthorized}
                        submit={handleConfirm}
                        submitText={T.common.confirm}
                        priceLabel={T.common.totalPrice}
                        price={jobQuotation?.total?.value || 0}
                        onRemovePromoCode={onRemovePromoCode}
                        priceBeforeDiscount={originalPrice}
                        promoCode={form.promoCode}
                        promotionPackage={promotionPackage}
                        requestPromotion={requestPromotion}
                        isLoading={isLoading}
                        disabled={notEnoughCredits || confirmDisabled}
                        content={(
                            <React.Fragment>
                                <View>
                                    <Address disabled={isLoading} />
                                </View>
                                <DeepCleaningSummaryColumn
                                    renderPriceComponent={() => (
                                        <TotalPriceComponent
                                            totalPrice={jobQuotation?.total?.value || 0}
                                            originalPrice={originalPrice}
                                            promotion={promotionPackage?.promotion}
                                        />
                                    )}
                                    renderPriceSummaryComponent={() => jobQuotation?.priceSummary ? (
                                        <PriceSummaryComponent priceSummary={jobQuotation?.priceSummary} promotion={jobQuotation.discount} />
                                    ) : undefined}
                                    totalPrice={totalPrice}
                                    originalPrice={originalPrice}
                                    promotionPackage={promotionPackage}
                                    form={formShape}
                                    couponText={promotionPackage
                                        ? promotionPackage.promotion.message_to_client
                                        : undefined
                                    }
                                    propertySizeLabel={pricing.propertySize.options.find(area => area.id === +formShape.propertySize)?.label || ''}
                                    // remove this check after guest booking experiment
                                    renderExtraContent={() => !isAuthorized ? (
                                        <PromotionCodeInput
                                            promoCode={form.promoCode}
                                            isLoading={isLoading}
                                            promotionPackage={promotionPackage}
                                            onRemovePromoCode={onRemovePromoCode}
                                            requestPromotion={requestPromotion}
                                        />
                                    ) : null}
                                />
                                {!authenticationView && (
                                    <PaymentMethodTile
                                        notEnoughCredits={notEnoughCredits}
                                        onPress={() => setPaymentView(true)}
                                        selectedPaymentMethod={selectedPaymentMethod}
                                    />
                                )}
                                <PolicyLinks />
                                <Rated />
                                <BookingCancellationFees />
                            </React.Fragment>
                        )}
                    />
                </MediaQuery.Hidden>
            )}
        />
    )
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        marginTop: {
            lg: 0,
            xs: theme.utils.gap(1)
        },
        zIndex: theme.zIndex[10]
    },
    selectWrapper: {
        zIndex: theme.zIndex[10],
        marginTop: -theme.utils.gap(2)
    },
    addonsRow: {
        flexDirection: {
            lg: 'row',
            xs: 'column'
        }
    },
    spacer: {
        width: theme.utils.gap(2)
    }
}))
