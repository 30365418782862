import { useMutation, useQuery } from '@tanstack/react-query'
import { authActions } from 'features/auth'
import { useQueryHelpers } from 'lib/hooks'
import { QueryKey, useFetcher } from 'lib/api'
import { useCreditsPackageAtom, useUserAtom, useUserTokenAtom } from 'lib/atoms'
import { ErrorResponse, HttpMethod, Response } from 'lib/types'
import { AddSendPayCreditsRequest, AddSendPayCreditsResponse, GetPromotionPackagesResponse } from './types'

export const addSendPayCredits = () => {
    const fetcher = useFetcher<AddSendPayCreditsResponse>(HttpMethod.POST, '/user/add_credit')

    return useMutation<Response<AddSendPayCreditsResponse>, ErrorResponse, AddSendPayCreditsRequest>(fetcher)
}

export const getPromotionPackages = () => {
    const [token] = useUserTokenAtom()
    const [, setUser] = useUserAtom()
    const { onRequestError } = useQueryHelpers()
    const { mutate: getMe } = authActions.useGetMe()
    const [, setCreditPackageAtom] = useCreditsPackageAtom()
    const fetcher = useFetcher<GetPromotionPackagesResponse>(HttpMethod.POST, '/consumer-get-promotion-packages')

    return useQuery<Response<GetPromotionPackagesResponse>, ErrorResponse>(
        [QueryKey.PromotionPackages],
        () => fetcher({ token }),
        {
            onSuccess: ({ data }) => {
                getMe(
                    { token },
                    {
                        onSuccess: ({ data }) => setUser(data.profile),
                        onError: onRequestError
                    }
                )
                setCreditPackageAtom(data.package_list)
            },
            onError: onRequestError
        }
    )
}
