import qs from 'qs'
import { APP_CONFIG } from '../config'

export enum RootStack {
    Root = 'Root'
}

export enum NavigatorId {
    LeftDrawer = 'LeftDrawer',
    RightDrawer = 'RightDrawer',
    BottomTabs = 'BottomTabs'
}

export enum ScreenNames {
    Page404 = '404',
    RootLeftDrawer = 'RootLeftDrawer',
    RootRightDrawer = 'RootRightDrawer',
    RootBottomTabs = 'RootBottomTabs',
    Home = 'Home',
    Auth = 'Auth',
    User = 'User',
    UserDeleteAccount = 'UserDeleteAccount',
    UserAddress = 'UserAddress',
    AddressSetup = 'AddressSetup',
    UserPreferences = 'UserPreferences',
    UserNewAddress = 'UserNewAddress',
    UserEditAddress = 'UserEditAddress',
    AuthForgotPassword = 'AuthForgotPassword',
    AuthNewPassword = 'AuthNewPassword',
    AuthSingleSignOn = 'AuthSingleSignOn',
    AuthFinishSocialSignUp = 'AuthFinishSocialSignUp',
    Favourite = 'Favourite',
    Refer = 'Refer',
    UserCards = 'Cards',
    UserCardsNew = 'CardsNew',
    // todo for now not available
    // UserCardsHistory = 'CardsNewHistory',
    // OffersAndDeals = 'OffersAndDeals',
    Cleaning = 'Cleaning',
    CleaningSummary = 'CleaningSummary',
    Bookings = 'Bookings',
    BookingsCompleted = 'BookingsCompleted',
    BookingsCancelled = 'BookingsCancelled',
    BookingSuccess = 'BookingSuccess',
    Chat = 'BookingsChat',
    SendPay = 'SendPay',
    DeepCleaning = 'DeepCleaning',
    DeepCleaningSummary = 'DeepCleaningSummary',
    CleaningDetails = 'BookingsCleaningDetails',
    Notifications = 'Notifications',
    UnreadNotifications = 'UnreadNotifications',
    DeepCleaningDetails = 'DeepCleaningDetails',
    AirCondition = 'AirCondition',
    AirConditionSummary = 'AirConditionSummary',
    Summary = 'Summary',
    AllServices = 'AllServices',
    AllServicesPostcode = 'AllServicesPostcode',
    AirConditionDetails = 'AirConditionDetails',
    Handyman = 'Handyman',
    HandymanSummary = 'HandymanSummary',
    HandymanDetails = 'HandymanDetails',
    Laundry = 'Laundry',
    LaundrySummary = 'LaundrySummary',
    LaundryDetails = 'LaundryDetails',
    Cooking = 'Cooking',
    CookingSummary = 'CookingSummary',
    CookingDetails = 'CookingDetails',
    PestControl = 'PestControl',
    PestControlSummary = 'PestControlSummary',
    PestControlDetails = 'PestControlDetails',
    TaskAndErrands = 'TaskAndErrands',
    TaskAndErrandsSummary = 'TaskAndErrandsSummary',
    TaskAndErrandsDetails = 'TaskAndErrandsDetails',
    GrabPayWebView = 'GrabPayWebView',
    LogInNative = 'LogInNative',
    SignUpNative = 'SignUpNative',
    AuthVerificationCodeNative = 'AuthVerificationCodeNative',
    OnboardingNative = 'OnboardingNative',
    MoversAndPackers = 'MoversAndPackers',
    MoversAndPackersServiceDetails = 'MoversAndPackersServiceDetails',
    MoversAndPackersSummary = 'MoversAndPackersSummary',
    MoversAndPackersDetails = 'MoversAndPackersDetails',
    ContactUs = 'ContactUs',
    CancelJob = 'CancelJob',
}

export type DetailsScreensNames = ScreenNames.CleaningDetails
| ScreenNames.DeepCleaningDetails
| ScreenNames.AirConditionDetails
| ScreenNames.HandymanDetails
| ScreenNames.LaundryDetails
| ScreenNames.CookingDetails
| ScreenNames.PestControlDetails
| ScreenNames.TaskAndErrandsDetails
| ScreenNames.MoversAndPackersDetails

export const ScreensConfig = {
    [ScreenNames.Home]: {
        path: ''
    },
    [ScreenNames.Page404]: {
        path: '*'
    },
    [ScreenNames.Auth]: {
        path: 'auth',
        parse: {
            fromResetPassword: value => value === 'true'
        }
    },
    [ScreenNames.User]: {
        path: 'user'
    },
    [ScreenNames.UserDeleteAccount]: {
        path: 'user/delete-account'
    },
    [ScreenNames.UserAddress]: {
        path: 'user/address'
    },
    [ScreenNames.UserNewAddress]: {
        path: 'user/address/new'
    },
    [ScreenNames.UserEditAddress]: {
        path: 'user/address/edit',
        stringify: {
            address: qs.stringify
        },
        parse: {
            form: qs.parse
        }
    },
    [ScreenNames.UserPreferences]: {
        path: 'user/preferences'
    },
    [ScreenNames.AuthForgotPassword]: {
        path: 'auth/forgot-password'
    },
    [ScreenNames.AuthNewPassword]: {
        path: 'auth/new-password'
    },
    [ScreenNames.AuthSingleSignOn]: {
        path: 'auth/single-sign-on'
    },
    [ScreenNames.Favourite]: {
        path: 'favourite'
    },
    [ScreenNames.Refer]: {
        path: 'refer'
    },
    [ScreenNames.UserCards]: {
        path: 'cards'
    },
    [ScreenNames.UserCardsNew]: {
        path: 'cards/new'
    },
    // todo for now not available
    // [ScreenNames.UserCardsHistory]: {
    //     path: 'cards/history'
    // },
    // [ScreenNames.OffersAndDeals]: {
    //     path: 'offers'
    // },
    [ScreenNames.Cleaning]: {
        path: 'bookings/cleaning'
    },
    [ScreenNames.CleaningSummary]: {
        path: 'bookings/cleaning/summary',
        stringify: {
            form: qs.stringify,
            price: String,
            supplyId: String
        },
        parse: {
            form: qs.parse,
            price: Number,
            supplyId: Number
        }
    },
    [ScreenNames.Bookings]: {
        path: 'bookings'
    },
    [ScreenNames.BookingsCompleted]: {
        path: 'bookings/completed'
    },
    [ScreenNames.BookingsCancelled]: {
        path: 'bookings/cancelled'
    },
    [ScreenNames.BookingSuccess]: {
        path: 'booking/success'
    },
    [ScreenNames.Chat]: {
        path: 'bookings/chat'
    },
    [ScreenNames.SendPay]: {
        path: 'send-pay'
    },
    [ScreenNames.DeepCleaning]: {
        path: 'bookings/deep-cleaning'
    },
    [ScreenNames.DeepCleaningSummary]: {
        path: 'bookings/deep-cleaning/summary',
        stringify: {
            form: qs.stringify,
            servicePrice: String,
            price: String,
            supplyId: String
        },
        parse: {
            form: qs.parse,
            servicePrice: Number,
            price: Number,
            supplyId: Number
        }
    },
    [ScreenNames.Notifications]: {
        path: 'notifications'
    },
    [ScreenNames.UnreadNotifications]: {
        path: 'notifications/unread'
    },
    [ScreenNames.CleaningDetails]: {
        path: 'bookings/details/cleaning',
        parse: {
            jobStatus: Number,
            recurringJob: qs.parse,
            isSession: value => value === 'true'
        },
        stringify: {
            jobStatus: String,
            recurringJob: qs.stringify
        }
    },
    [ScreenNames.DeepCleaningDetails]: {
        path: 'bookings/details/deep-cleaning',
        parse: {
            jobStatus: Number
        },
        stringify: {
            jobStatus: String
        }
    },
    [ScreenNames.AirCondition]: {
        path: 'bookings/air-condition'
    },
    [ScreenNames.AirConditionSummary]: {
        path: 'bookings/air-condition/summary',
        stringify: {
            form: qs.stringify,
            price: String,
            supplyId: String
        },
        parse: {
            form: qs.parse,
            price: Number,
            supplyId: Number
        }
    },
    [ScreenNames.AirConditionDetails]: {
        path: 'bookings/details/air-condition',
        parse: {
            jobStatus: Number
        },
        stringify: {
            jobStatus: String
        }
    },
    [ScreenNames.AllServices]: {
        path: 'all-services'
    },
    [ScreenNames.AllServicesPostcode]: {
        path: 'all-services/postcode'
    },
    [ScreenNames.ContactUs]: {
        path: 'contact-us'
    },
    [ScreenNames.CancelJob]: {
        path: 'bookings/details/cancel-job',
        parse: {
            jobId: String
        },
        stringify: {
            jobId: String
        }
    },
    ...!APP_CONFIG.SERVICES.IS_HANDYMAN_ENABLED
        ? {}
        : {
            [ScreenNames.Handyman]: {
                path: 'bookings/handyman'
            },
            [ScreenNames.HandymanSummary]: {
                path: 'bookings/handyman/summary',
                stringify: {
                    form: qs.stringify,
                    price: String,
                    supplyId: String
                },
                parse: {
                    form: qs.parse,
                    price: Number,
                    supplyId: Number
                }
            },
            [ScreenNames.HandymanDetails]: {
                path: 'bookings/details/handyman',
                parse: {
                    jobStatus: Number
                },
                stringify: {
                    jobStatus: String
                }
            }
        },
    ...!APP_CONFIG.SERVICES.IS_LAUNDRY_ENABLED
        ? {}
        : {
            [ScreenNames.Laundry]: {
                path: 'bookings/laundry'
            },
            [ScreenNames.LaundrySummary]: {
                path: 'bookings/laundry/summary',
                stringify: {
                    form: qs.stringify,
                    price: String,
                    supplyId: String
                },
                parse: {
                    form: qs.parse,
                    price: Number,
                    supplyId: Number
                }
            },
            [ScreenNames.LaundryDetails]: {
                path: 'bookings/details/laundry',
                parse: {
                    jobStatus: Number
                },
                stringify: {
                    jobStatus: String
                }
            }
        },
    ...!APP_CONFIG.SERVICES.IS_COOKING_ENABLED
        ? {}
        : {
            [ScreenNames.Cooking]: {
                path: 'bookings/cooking'
            },
            [ScreenNames.CookingSummary]: {
                path: 'bookings/cooking/summary',
                stringify: {
                    form: qs.stringify,
                    price: String,
                    supplyId: String
                },
                parse: {
                    form: qs.parse,
                    price: Number,
                    supplyId: Number
                }
            },
            [ScreenNames.CookingDetails]: {
                path: 'bookings/details/cooking',
                parse: {
                    jobStatus: Number,
                    recurringJob: qs.parse,
                    isSession: value => value === 'true'
                },
                stringify: {
                    jobStatus: String,
                    recurringJob: qs.stringify
                }
            }
        },
    ...!APP_CONFIG.SERVICES.IS_PEST_CONTROL_ENABLED
        ? {}
        : {
            [ScreenNames.PestControl]: {
                path: 'bookings/pest-control'
            },
            [ScreenNames.PestControlSummary]: {
                path: 'bookings/pest-control/summary',
                stringify: {
                    form: qs.stringify,
                    price: String,
                    supplyId: String
                },
                parse: {
                    form: qs.parse,
                    price: Number,
                    supplyId: Number
                }
            },
            [ScreenNames.PestControlDetails]: {
                path: 'bookings/details/pest-control',
                parse: {
                    jobStatus: Number
                },
                stringify: {
                    jobStatus: String
                }
            }
        },
    ...!APP_CONFIG.SERVICES.IS_TASK_ERRANDS_ENABLED
        ? {}
        : {
            [ScreenNames.TaskAndErrands]: {
                path: 'bookings/task-and-errands'
            },
            [ScreenNames.TaskAndErrandsSummary]: {
                path: 'bookings/task-and-errands/summary',
                stringify: {
                    form: qs.stringify,
                    price: String,
                    supplyId: String
                },
                parse: {
                    form: qs.parse,
                    price: Number,
                    supplyId: Number
                }
            },
            [ScreenNames.TaskAndErrandsDetails]: {
                path: 'bookings/details/task-and-errands',
                parse: {
                    jobStatus: Number
                },
                stringify: {
                    jobStatus: String
                }
            }
        },
    ...!APP_CONFIG.SERVICES.IS_MOVERS_AND_PACKERS_ENABLED
        ? {}
        : {
            [ScreenNames.MoversAndPackers]: {
                path: 'bookings/movers-and-packers'
            },
            [ScreenNames.MoversAndPackersSummary]: {
                path: 'bookings/movers-and-packers/summary',
                stringify: {
                    form: qs.stringify,
                    price: String,
                    supplyId: String
                },
                parse: {
                    form: qs.parse,
                    price: Number,
                    supplyId: Number
                }
            },
            [ScreenNames.MoversAndPackersDetails]: {
                path: 'bookings/details/movers-and-packers',
                parse: {
                    jobStatus: Number
                },
                stringify: {
                    jobStatus: String
                }
            }
        }
}
