// This client was automatically generated by Segment Typewriter. ** Do Not Edit **
// To update this file, run:
//   npx typewriter

export interface AddAddressClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface AddHoursToSessionClicked {
    /**
     * Short ID of the job eg. SG-D178244
     */
    jobId?: string;
    /**
     * Service name eg. Cleaning
     */
    serviceType?: string;
    /**
     * ID of the supplier
     */
    supplierId?: string;
    [property: string]: any;
}

export interface AddNewCreditCardClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface AddressAdded {
    /**
     * 6-digit postcode eg. 546080
     */
    postcode?: string;
    [property: string]: any;
}

export interface AddressCancelClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface AddressDeleted {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface AddressExpanded {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface AddressLineEntered {
    /**
     * Type of address entry
     */
    entryType?: EntryType;
    [property: string]: any;
}

/**
 * Type of address entry
 *
 * Type of block entry
 *
 * Type of unit and floor entry
 *
 * Type of unit/floor entry
 */
export enum EntryType {
    Manual = "Manual",
    Prefill = "Prefill",
}

export interface AddressSaveClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface AirConPayment {
    /**
     * Payment Method used to complete the booking
     */
    paymentType?: string;
    [property: string]: any;
}

export interface ApplicationBackgrounded {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface ApplicationInstalled {
    /**
     * Build number
     */
    build?: string;
    /**
     * Version number
     */
    version?: string;
    [property: string]: any;
}

export interface ApplicationOpened {
    /**
     * Build number
     */
    build?: string;
    /**
     * Opened from background
     */
    from_background?: boolean;
    /**
     * Version number
     */
    version?: string;
    [property: string]: any;
}

export interface ApplicationUpdated {
    /**
     * Build number
     */
    build?: string;
    /**
     * Previous build number
     */
    previous_build?: string;
    /**
     * Previous version number
     */
    previous_version?: string;
    /**
     * Version number
     */
    version?: string;
    [property: string]: any;
}

export interface BlockLineEntered {
    /**
     * Type of block entry
     */
    entryType?: EntryType;
    [property: string]: any;
}

export interface BookAgainClicked {
    /**
     * Short ID of the job eg. SG-D178244
     */
    jobId?: string;
    /**
     * Status of the job eg. Paused
     */
    jobStatus?: JobStatus;
    /**
     * Service name eg. Cleaning
     */
    serviceType?: string;
    [property: string]: any;
}

/**
 * Status of the job eg. Paused
 */
export enum JobStatus {
    Accepted = "Accepted",
    Cancelled = "Cancelled",
    Completed = "Completed",
    Hold = "Hold",
    Paused = "Paused",
    Rated = "Rated",
    Submitted = "Submitted",
}

export interface BookNowClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface BookServiceClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface BookingAdditionalInformationAdded {
    /**
     * Details about the additional information
     */
    information?: Information;
    [property: string]: any;
}

/**
 * Details about the additional information
 */
export interface Information {
    /**
     * Type of the information eg. Pets
     */
    type?: string;
    /**
     * Value of the additional information
     */
    value?: string;
    [property: string]: any;
}

export interface BookingAddonAdded {
    /**
     * Details about the addon
     */
    addon?: Addon;
    [property: string]: any;
}

/**
 * Details about the addon
 */
export interface Addon {
    /**
     * Name of the addon
     */
    name?: string;
    /**
     * Selected value of the addon
     */
    value?: string;
    [property: string]: any;
}

export interface BookingAddressConfirmClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface BookingAddressModuleClosed {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface BookingAnotherServiceTypeSelected {
    /**
     * Selected category eg. General
     */
    category?: string;
    /**
     * Selected item eg. Door
     */
    item?: string;
    /**
     * Selected type eg. Repair
     */
    type?: string;
    [property: string]: any;
}

export interface BookingBedroomNumberClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface BookingBedroomNumberSelected {
    /**
     * Number of selected bedrooms
     */
    number?: number;
    [property: string]: any;
}

export interface BookingCanceled {
    /**
     * Short ID of the job eg. SG-D178244
     */
    jobId?: string;
    /**
     * Short reason why user canceled the job
     */
    reason?: string;
    /**
     * Service name eg. Cleaning
     */
    serviceType?: string;
    [property: string]: any;
}

export interface BookingCancellationChargesClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface BookingCancellationChargesModuleClosed {
    /**
     * Close type by button or click outside
     */
    closeType?: CloseType;
    [property: string]: any;
}

/**
 * Close type by button or click outside
 */
export enum CloseType {
    Button = "Button",
    Outside = "Outside",
}

export interface BookingClearBedroomsClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface BookingClearPetsClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface BookingClearPropertyFloorClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface BookingClearPropertyTypeClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface BookingCookingStyleEntered {
    /**
     * Cooking style entered by customer
     */
    cookingStyle?: string;
    [property: string]: any;
}

export interface BookingCookingStyleSelected {
    /**
     * Cooking style eg. italian cuisine
     */
    cookingStyle?: string;
    [property: string]: any;
}

export interface BookingDateAndTimeClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface BookingDateAndTimeModuleClosed {
    /**
     * Is date selected
     */
    dateSelected?: boolean;
    /**
     * Is time selected
     */
    timeSelected?: boolean;
    [property: string]: any;
}

export interface BookingDateAndTimeSelected {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface BookingDiscountCodeApplied {
    /**
     * Entered promo code
     */
    promoCode?: string;
    /**
     * Is code valid
     */
    success?: boolean;
    [property: string]: any;
}

export interface BookingDiscountCodeErrorShowed {
    /**
     * Error message
     */
    error?: string;
    /**
     * Entered promo code
     */
    promoCode?: string;
    [property: string]: any;
}

export interface BookingEditAddressClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface BookingEditPaymentClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface BookingElevatorSelected {
    /**
     * If property have elevator
     */
    elevator?: boolean;
    [property: string]: any;
}

export interface BookingExistingAddressSelected {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface BookingFoodAllergiesSelected {
    /**
     * Food allergies
     */
    allergies?: boolean;
    [property: string]: any;
}

export interface BookingFrequencyClicked {
    /**
     * Type of the frequency eg. One Time
     */
    frequencyType?: FrequencyType;
    /**
     * Index of the frequency placed in the view. Starts from 1
     */
    position?: number;
    [property: string]: any;
}

/**
 * Type of the frequency eg. One Time
 */
export enum FrequencyType {
    Fortnightly = "Fortnightly",
    OneTime = "OneTime",
    Weekly = "Weekly",
}

export interface BookingHoursSelected {
    /**
     * Number of hours
     */
    hours?: number;
    [property: string]: any;
}

export interface BookingImageUploaded {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface BookingInspectionDateAndTimeModuleClosed {
    /**
     * Is date selected
     */
    dateSelected?: boolean;
    /**
     * Number of selected dates
     */
    numberOfDatesSelected?: number;
    /**
     * Is time selected
     */
    timeSelected?: boolean;
    [property: string]: any;
}

export interface BookingInspectionDateAndTimeSelected {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface BookingInstructionForDriverSelected {
    /**
     * Instruction for the driver eg. Collect items from reception
     */
    instruction?: string;
    [property: string]: any;
}

export interface BookingLaundryServiceSelected {
    /**
     * Booking laundry service name eg. Iron only
     */
    service?: string;
    [property: string]: any;
}

export interface BookingMoreClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface BookingMovingTypeSelected {
    /**
     * Moving type name
     */
    movingType?: string;
    [property: string]: any;
}

export interface BookingNewAddressClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface BookingNumberOfAirConditionersSelected {
    /**
     * Number of air conditioners
     */
    quantity?: number;
    [property: string]: any;
}

export interface BookingPaymentSwitched {
    /**
     * Selected payment method
     */
    selectedPaymentMethod?: SelectedPaymentMethod;
    [property: string]: any;
}

/**
 * Selected payment method
 */
export enum SelectedPaymentMethod {
    CreditCard = "CreditCard",
    SendPay = "SendPay",
}

export interface BookingPestControlServiceSelected {
    /**
     * Pest control service name eg. Common ants
     */
    service?: string;
    [property: string]: any;
}

export interface BookingPriceReferenceClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface BookingPriceReferenceClosed {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface BookingPriceReferenceTabClicked {
    /**
     * Price reference tab name
     */
    tabName?: string;
    [property: string]: any;
}

export interface BookingPromoCodeEntered {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface BookingPropertyFloorClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface BookingPropertyFloorSelected {
    /**
     * Number of selected floors
     */
    number?: number;
    [property: string]: any;
}

export interface BookingPropertyOptionSelected {
    /**
     * Booking property option eg. I have an oven but no dishwasher
     */
    propertyOption?: string;
    [property: string]: any;
}

export interface BookingPropertySizeSelected {
    /**
     * Size of the property eg. 800 - 1000 sqft
     */
    propertySize?: string;
    [property: string]: any;
}

export interface BookingPropertyTypeClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface BookingPropertyTypeSelected {
    /**
     * Type of the property eg. HDB
     */
    propertyType?: string;
    [property: string]: any;
}

export interface BookingPurchaseSendPayCreditsClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface BookingPurchasingServicesSelected {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface BookingRescheduled {
    /**
     * Job frequency eg. Weekly, Bi-weekly, Monthly
     */
    cancellationFeesCharged?: string;
    /**
     * New date time
     */
    dateTime?: string;
    /**
     * Job frequency eg. Weekly, Bi-weekly, Monthly
     */
    jobFrequency?: string;
    /**
     * Job id of the job
     */
    jobId?: string;
    /**
     * Service name eg. Cleaning
     */
    serviceType?: string;
    [property: string]: any;
}

export interface BookingSelectSavedAddressClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface BookingSelectServiceTypeClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface BookingServiceDescriptionModuleClosed {
    /**
     * Close type by button or click outside
     */
    closeType?: CloseType;
    [property: string]: any;
}

export interface BookingServicePlanClicked {
    /**
     * Index of the service plan placed in the view. Starts from 1
     */
    position?: number;
    /**
     * Type of the service plan eg. One Time
     */
    servicePlan?: ServicePlan;
    [property: string]: any;
}

/**
 * Type of the service plan eg. One Time
 */
export enum ServicePlan {
    Contract = "Contract",
    Custom = "Custom",
    OneTime = "OneTime",
}

export interface BookingServiceTypeSelected {
    /**
     * Selected category eg. General
     */
    category?: string;
    /**
     * Selected item eg. Door
     */
    item?: string;
    /**
     * Selected type eg. Repair
     */
    type?: string;
    [property: string]: any;
}

export interface BookingStarted {
    /**
     * Unique Booking Session ID
     */
    bookingSessionId?: string;
    /**
     * 6-digit postcode eg. 546080
     */
    postcode?: string;
    /**
     * Service name eg. Cleaning
     */
    serviceType?: string;
    [property: string]: any;
}

export interface BookingSummary {
    /**
     * Total amount of the purchase
     */
    amount?: number;
    /**
     * Unique Booking Session ID
     */
    bookingSessionId?: string;
    /**
     * 6-digit postcode eg. 546080
     */
    postcode?: string;
    /**
     * Service name eg. Cleaning
     */
    serviceType?: string;
    [property: string]: any;
}

export interface BookingTaskOrErrandTypeSelected {
    /**
     * Selected task or errand type eg. Grocery Shopping
     */
    type?: string;
    [property: string]: any;
}

export interface BookingUploadedImageRemoveClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface BookingWhatsIncludedAndHowItWorksClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface CallSupplierClicked {
    /**
     * Short ID of the job eg. SG-D178244
     */
    jobId?: string;
    /**
     * Service name eg. Cleaning
     */
    serviceType?: string;
    /**
     * ID of the supplier
     */
    supplierId?: string;
    [property: string]: any;
}

export interface CancelBookingClicked {
    /**
     * Short ID of the job eg. SG-D178244
     */
    jobId?: string;
    /**
     * Service name eg. Cleaning
     */
    serviceType?: string;
    [property: string]: any;
}

export interface ChatWithSupplierClicked {
    /**
     * Short ID of the job eg. SG-D178244
     */
    jobId?: string;
    /**
     * Service name eg. Cleaning
     */
    serviceType?: string;
    /**
     * ID of the supplier
     */
    supplierId?: string;
    [property: string]: any;
}

export interface CleaningPayment {
    /**
     * Payment Method used to complete the booking
     */
    paymentType?: string;
    [property: string]: any;
}

export interface CleaningPlanFrequencySelected {
    /**
     * Called when user clicks on cleaning frequency
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface CleaningPlanSelected {
    /**
     * Called when user clicks on cleaning plan
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface ContinueAsGuestClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface CookingPayment {
    /**
     * Payment Method used to complete the booking
     */
    paymentType?: string;
    [property: string]: any;
}

export interface CreditCardAdded {
    /**
     * Card type eg. Visa
     */
    cardType?: string;
    [property: string]: any;
}

export interface CreditCardCVVEntered {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface CreditCardCancelClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface CreditCardDateEntered {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface CreditCardInvalidNumberEntered {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface CreditCardModuleClosed {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface CreditCardNameEntered {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface CreditCardNumberEntered {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface CrossSaleBookNowClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface CrossaleAirconBookNowClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface CrossaleCleaningBookNowClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface CrossaleCookingBookNowClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface CrossaleDeepCleaningBookNowClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface CrossaleHandymanBookNowClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface CrossaleLaundryBookNowClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface CrossaleMoversAndPackersBookNowClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface CrossaleMyBookingsClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface CrossalePestControlBookNowClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface CrossaleTasksAndErrandsBookNowClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface DeepLinkOpened {
    /**
     * Referring application name
     */
    referring_application?: string;
    /**
     * Url that opened the app
     */
    url?: string;
    [property: string]: any;
}

export interface DeepCleaningPayment {
    /**
     * Payment Method used to complete the booking
     */
    paymentType?: string;
    [property: string]: any;
}

export interface EditProfileClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface ExternalLinkClicked {
    /**
     * External url
     */
    url?: string;
    [property: string]: any;
}

export interface FavouritePartnerSet {
    /**
     * Favourite partner details
     */
    partner?: Partner;
    /**
     * Service name eg. Cleaning
     */
    serviceType?: string;
    [property: string]: any;
}

/**
 * Favourite partner details
 */
export interface Partner {
    /**
     * Handle of the favourite partner (supplier)
     */
    partnerHandle?: string;
    /**
     * Id of the favourite partner (supplier)
     */
    partnerId?: string;
    [property: string]: any;
}

export interface HandymanPayment {
    /**
     * Payment Method used to complete the booking
     */
    paymentType?: string;
    [property: string]: any;
}

export interface InstallAttributed {
    /**
     * Campaign info
     */
    campaign?: Campaign;
    /**
     * Name of the provider
     */
    provider?: string;
    [property: string]: any;
}

/**
 * Campaign info
 */
export interface Campaign {
    /**
     * Name of the campaign
     */
    name?: string;
    /**
     * Source of the campaign
     */
    source?: string;
    [property: string]: any;
}

export interface JobAccepted {
    /**
     * Total amount of the purchase
     */
    amount?: number;
    /**
     * Short ID of the job eg. SG-D178244
     */
    jobId?: string;
    /**
     * Status of the job eg. Paused
     */
    jobStatus?: JobStatus;
    /**
     * 6-digit postcode eg. 546080
     */
    postcode?: string;
    /**
     * Service name eg. Cleaning
     */
    serviceType?: string;
    /**
     * Supplier ID who accepted the job
     */
    supplierId?: string;
    [property: string]: any;
}

export interface JobCancelled {
    /**
     * Total amount of the purchase
     */
    amount?: number;
    /**
     * Amount of the cancellation fees
     */
    cancellationFees?: number;
    /**
     * From where the job cancellation is triggered, e.g - CustomerPreAcceptance,
     * CustomerPostAcceptance, Supplier, Console
     */
    cancellationType?: CancellationType;
    /**
     * The status of the job at cancellation: JobAccepted, JobNotAccepted
     */
    jobAcceptanceStatus?: JobAcceptanceStatus;
    /**
     * Short ID of the job eg. SG-D178244
     */
    jobId?: string;
    /**
     * 6-digit postcode eg. 546080
     */
    postcode?: string;
    /**
     * Job type - adhoc or recurring
     */
    recurring?: boolean;
    /**
     * Service name eg. Cleaning
     */
    serviceType?: string;
    [property: string]: any;
}

/**
 * From where the job cancellation is triggered, e.g - CustomerPreAcceptance,
 * CustomerPostAcceptance, Supplier, Console
 */
export enum CancellationType {
    Console = "Console",
    CustomerPostAcceptance = "CustomerPostAcceptance",
    CustomerPreAcceptance = "CustomerPreAcceptance",
    Supplier = "Supplier",
}

/**
 * The status of the job at cancellation: JobAccepted, JobNotAccepted
 */
export enum JobAcceptanceStatus {
    JobAccepted = "JobAccepted",
    JobNotAccepted = "JobNotAccepted",
}

export interface JobClicked {
    /**
     * Short ID of the job eg. SG-D178244
     */
    jobId?: string;
    /**
     * Status of the job eg. Paused
     */
    jobStatus?: JobStatus;
    /**
     * Service name eg. Cleaning
     */
    serviceType?: string;
    [property: string]: any;
}

export interface JobCompleted {
    /**
     * Total amount of the purchase
     */
    amount?: number;
    /**
     * Short ID of the job eg. SG-D178244
     */
    jobId?: string;
    /**
     * Status of the job eg. Paused
     */
    jobStatus?: JobStatus;
    /**
     * 6-digit postcode eg. 546080
     */
    postcode?: string;
    /**
     * Service name eg. Cleaning
     */
    serviceType?: string;
    /**
     * Supplier ID who completed the job
     */
    supplierId?: string;
    [property: string]: any;
}

export interface JobExpired {
    /**
     * Total amount of the purchase
     */
    amount?: number;
    /**
     * Short ID of the job eg. SG-D178244
     */
    jobId?: string;
    /**
     * 6-digit postcode eg. 546080
     */
    postcode?: string;
    /**
     * Service name eg. Cleaning
     */
    serviceType?: string;
    [property: string]: any;
}

export interface JobSessionExtended {
    /**
     * Number of extended hours
     */
    extraHours?: number;
    /**
     * Short ID of the job eg. SG-D178244
     */
    jobId?: string;
    /**
     * Service name eg. Cleaning
     */
    serviceType?: string;
    /**
     * ID of the supplier
     */
    supplierId?: string;
    [property: string]: any;
}

export interface LaundryPayment {
    /**
     * Payment Method used to complete the booking
     */
    paymentType?: string;
    [property: string]: any;
}

export interface MarketingAffiliateClicked {
    /**
     * Was form completed
     */
    formCompletion?: boolean;
    [property: string]: any;
}

export interface MarketingBecomeAPartnerClicked {
    /**
     * Additional data from the event
     */
    eventData?: MarketingBecomeAPartnerClickedEventData;
    /**
     * Name of the event
     */
    eventName?: string;
    /**
     * Eg: Input or Click event
     */
    eventType?: string;
    [property: string]: any;
}

/**
 * Additional data from the event
 */
export interface MarketingBecomeAPartnerClickedEventData {
    /**
     * Base URL
     */
    baseURI?: string;
    /**
     * Page name eg. HelpCenter
     */
    pageName?: string;
    [property: string]: any;
}

export interface MarketingBlogClicked {
    /**
     * Additional data from the event
     */
    eventData?: MarketingBlogClickedEventData;
    /**
     * Name of the event
     */
    eventName?: string;
    /**
     * Eg: Input or Click event
     */
    eventType?: string;
    [property: string]: any;
}

/**
 * Additional data from the event
 */
export interface MarketingBlogClickedEventData {
    /**
     * Base URL
     */
    baseURI?: string;
    /**
     * Page name eg. HelpCenter
     */
    pageName?: string;
    [property: string]: any;
}

export interface MarketingBookNowClicked {
    /**
     * Additional data from the event
     */
    eventData?: MarketingBookNowClickedEventData;
    /**
     * Name of the event
     */
    eventName?: string;
    /**
     * Eg: Input or Click event
     */
    eventType?: string;
    /**
     * Call to action placement eg. Main banner
     */
    placement?: string;
    /**
     * 6-digit postcode eg. 546080
     */
    postcode?: string;
    /**
     * Service name eg. Cleaning
     */
    serviceType?: string;
    [property: string]: any;
}

/**
 * Additional data from the event
 */
export interface MarketingBookNowClickedEventData {
    /**
     * Base URL
     */
    baseURI?: string;
    /**
     * Page name eg. HelpCenter
     */
    pageName?: string;
    [property: string]: any;
}

export interface MarketingBookServiceClicked {
    /**
     * Base URL
     */
    baseURI?: string;
    /**
     * Page name eg. HelpCenter
     */
    pageName?: string;
    /**
     * 6-digit postcode eg. 546080
     */
    postcode?: string;
    /**
     * Service Id
     */
    serviceId?: string;
    /**
     * Service name eg. Cleaning
     */
    serviceType?: string;
    [property: string]: any;
}

export interface MarketingClickBookNowOnOfferBanner {
    /**
     * Additional data from the event
     */
    eventData?: MarketingClickBookNowOnOfferBannerEventData;
    /**
     * Name of the event
     */
    eventName?: string;
    /**
     * Eg: Input or Click event
     */
    eventType?: string;
    [property: string]: any;
}

/**
 * Additional data from the event
 */
export interface MarketingClickBookNowOnOfferBannerEventData {
    /**
     * Name of the event
     */
    eventName?: string;
    /**
     * Eg: Input or Click event
     */
    eventType?: string;
    [property: string]: any;
}

export interface MarketingContactClicked {
    /**
     * Base URL
     */
    baseURI?: string;
    /**
     * Contact type eg. Email
     */
    contactType?: ContactType;
    /**
     * Form was submitted
     */
    formSubmission?: boolean;
    /**
     * Page name eg. HelpCenter
     */
    pageName?: string;
    [property: string]: any;
}

/**
 * Contact type eg. Email
 */
export enum ContactType {
    Email = "Email",
    Hotline = "Hotline",
    Whatsapp = "Whatsapp",
}

export interface MarketingContactUsClicked {
    /**
     * Additional data from the event
     */
    eventData?: MarketingContactUsClickedEventData;
    /**
     * Name of the event
     */
    eventName?: string;
    /**
     * Eg: Input or Click event
     */
    eventType?: string;
    [property: string]: any;
}

/**
 * Additional data from the event
 */
export interface MarketingContactUsClickedEventData {
    /**
     * Name of the event
     */
    eventName?: string;
    /**
     * Eg: Input or Click event
     */
    eventType?: string;
    [property: string]: any;
}

export interface MarketingDealsClicked {
    /**
     * Base URL
     */
    baseURI?: string;
    /**
     * Page name eg. HelpCenter
     */
    pageName?: string;
    [property: string]: any;
}

export interface MarketingEnterEmailSubscribed {
    /**
     * Newsletter Subscribe Email
     */
    email?: string;
    /**
     * Additional data from the event
     */
    eventData?: MarketingEnterEmailSubscribedEventData;
    /**
     * Name of the event
     */
    eventName?: string;
    /**
     * Eg: Input or Click event
     */
    eventType?: string;
    [property: string]: any;
}

/**
 * Additional data from the event
 */
export interface MarketingEnterEmailSubscribedEventData {
    /**
     * Base URL
     */
    baseURI?: string;
    /**
     * Page name eg. HelpCenter
     */
    pageName?: string;
    [property: string]: any;
}

export interface MarketingEnterPostCode {
    /**
     * Additional data from the event
     */
    eventData?: MarketingEnterPostCodeEventData;
    /**
     * Name of the event
     */
    eventName?: string;
    /**
     * Eg: Input or Click event
     */
    eventType?: string;
    /**
     * 6-digit postcode eg. 546080
     */
    postcode?: string;
    [property: string]: any;
}

/**
 * Additional data from the event
 */
export interface MarketingEnterPostCodeEventData {
    /**
     * Base URL
     */
    baseURI?: string;
    /**
     * Page name eg. HelpCenter
     */
    pageName?: string;
    [property: string]: any;
}

export interface MarketingExternalLinkClicked {
    /**
     * Base URL
     */
    baseURI?: string;
    /**
     * Page name eg. HelpCenter
     */
    pageName?: string;
    /**
     * External url
     */
    url?: string;
    [property: string]: any;
}

export interface MarketingHelpCentreClicked {
    /**
     * Additional data from the event
     */
    eventData?: MarketingHelpCentreClickedEventData;
    /**
     * Name of the event
     */
    eventName?: string;
    /**
     * Eg: Input or Click event
     */
    eventType?: string;
    /**
     * Name of the module
     */
    module?: string;
    [property: string]: any;
}

/**
 * Additional data from the event
 */
export interface MarketingHelpCentreClickedEventData {
    /**
     * Base URL
     */
    baseURI?: string;
    /**
     * Page name eg. HelpCenter
     */
    pageName?: string;
    [property: string]: any;
}

export interface MarketingHelpCentreSearched {
    /**
     * Search keywords
     */
    keywordUsed?: string;
    /**
     * Search results
     */
    searchResults?: any[];
    [property: string]: any;
}

export interface MarketingMobileAppDownload {
    /**
     * Name of the OS
     */
    os?: OS;
    /**
     * Page name from where user clicked on download CTA
     */
    pageName?: string;
    [property: string]: any;
}

/**
 * Name of the OS
 */
export enum OS {
    Android = "Android",
    IOS = "iOS",
}

export interface MarketingMobileAppDownloadClicked {
    /**
     * OS eg. Android
     */
    os?: string;
    /**
     * Page name eg. HelpCenter
     */
    pageName?: string;
    [property: string]: any;
}

export interface MarketingNewsletterSubscribed {
    /**
     * Base URL
     */
    baseURI?: string;
    /**
     * Newsletter Subscribe Email
     */
    email?: string;
    /**
     * Page name eg. HelpCenter
     */
    pageName?: string;
    [property: string]: any;
}

export interface MarketingNotificationClicked {
    /**
     * Additional data from the event
     */
    eventData?: MarketingNotificationClickedEventData;
    /**
     * Name of the event
     */
    eventName?: string;
    /**
     * Eg: Input or Click event
     */
    eventType?: string;
    [property: string]: any;
}

/**
 * Additional data from the event
 */
export interface MarketingNotificationClickedEventData {
    /**
     * Base URL
     */
    baseURI?: string;
    /**
     * Page name eg. HelpCenter
     */
    pageName?: string;
    [property: string]: any;
}

export interface MarketingOurServicesClicked {
    /**
     * Additional data from the event
     */
    eventData?: MarketingOurServicesClickedEventData;
    /**
     * Name of the event
     */
    eventName?: string;
    /**
     * Eg: Input or Click event
     */
    eventType?: string;
    [property: string]: any;
}

/**
 * Additional data from the event
 */
export interface MarketingOurServicesClickedEventData {
    /**
     * Base URL
     */
    baseURI?: string;
    /**
     * Page name eg. HelpCenter
     */
    pageName?: string;
    [property: string]: any;
}

export interface MarketingPageViewed {
    /**
     * Device info
     */
    device?: Device;
    /**
     * Page name eg. HelpCenter
     */
    pageName?: string;
    /**
     * User ID
     */
    userId?: string;
    [property: string]: any;
}

/**
 * Device info
 */
export interface Device {
    /**
     * Device name eg. Samsung Galaxy
     */
    deviceName?: string;
    /**
     * Device OS eg. Windows
     */
    deviceOS?: string;
    /**
     * Device type eg. Tablet
     */
    deviceType?: string;
    [property: string]: any;
}

export interface MarketingPartnerSignedUp {
    /**
     * Base URL
     */
    baseURI?: string;
    /**
     * Page name eg. HelpCenter
     */
    pageName?: string;
    [property: string]: any;
}

export interface MarketingReviewClicked {
    /**
     * Additional data from the event
     */
    eventData?: MarketingReviewClickedEventData;
    /**
     * Name of the event
     */
    eventName?: string;
    /**
     * Eg: Input or Click event
     */
    eventType?: string;
    [property: string]: any;
}

/**
 * Additional data from the event
 */
export interface MarketingReviewClickedEventData {
    /**
     * Base URL
     */
    baseURI?: string;
    /**
     * Page name eg. HelpCenter
     */
    pageName?: string;
    [property: string]: any;
}

export interface MarketingScrollToBottom {
    /**
     * Additional data from the event
     */
    eventData?: MarketingScrollToBottomEventData;
    /**
     * Name of the event
     */
    eventName?: string;
    /**
     * Eg: Input or Click event
     */
    eventType?: string;
    [property: string]: any;
}

/**
 * Additional data from the event
 */
export interface MarketingScrollToBottomEventData {
    /**
     * Base URL
     */
    baseURI?: string;
    /**
     * Page name eg. HelpCenter
     */
    pageName?: string;
    [property: string]: any;
}

export interface MarketingSearchBookNowClicked {
    /**
     * Additional data from the event
     */
    eventData?: MarketingSearchBookNowClickedEventData;
    /**
     * Name of the event
     */
    eventName?: string;
    /**
     * Eg: Input or Click event
     */
    eventType?: string;
    [property: string]: any;
}

/**
 * Additional data from the event
 */
export interface MarketingSearchBookNowClickedEventData {
    /**
     * Base URL
     */
    baseURI?: string;
    /**
     * Page name eg. HelpCenter
     */
    pageName?: string;
    [property: string]: any;
}

export interface MarketingSearchPostCodeClicked {
    /**
     * Additional data from the event
     */
    eventData?: MarketingSearchPostCodeClickedEventData;
    /**
     * Name of the event
     */
    eventName?: string;
    /**
     * Eg: Input or Click event
     */
    eventType?: string;
    [property: string]: any;
}

/**
 * Additional data from the event
 */
export interface MarketingSearchPostCodeClickedEventData {
    /**
     * Base URL
     */
    baseURI?: string;
    /**
     * Page name eg. HelpCenter
     */
    pageName?: string;
    [property: string]: any;
}

export interface MarketingSearchServiceClicked {
    /**
     * Call to action placement eg. Main banner
     */
    placement?: string;
    /**
     * Service name eg. Cleaning
     */
    serviceType?: string;
    [property: string]: any;
}

export interface MarketingSearchServiceTypeClicked {
    /**
     * Additional data from the event
     */
    eventData?: MarketingSearchServiceTypeClickedEventData;
    /**
     * Name of the event
     */
    eventName?: string;
    /**
     * Eg: Input or Click event
     */
    eventType?: string;
    [property: string]: any;
}

/**
 * Additional data from the event
 */
export interface MarketingSearchServiceTypeClickedEventData {
    /**
     * Base URL
     */
    baseURI?: string;
    /**
     * Page name eg. HelpCenter
     */
    pageName?: string;
    [property: string]: any;
}

export interface MarketingServiceClicked {
    /**
     * Base URL
     */
    baseURI?: string;
    /**
     * Page name eg. HelpCenter
     */
    pageName?: string;
    /**
     * Service name eg. Cleaning
     */
    serviceType?: string;
    [property: string]: any;
}

export interface MarketingServiceIconClicked {
    /**
     * Additional data from the event
     */
    eventData?: MarketingServiceIconClickedEventData;
    /**
     * Name of the event
     */
    eventName?: string;
    /**
     * Eg: Input or Click event
     */
    eventType?: string;
    [property: string]: any;
}

/**
 * Additional data from the event
 */
export interface MarketingServiceIconClickedEventData {
    /**
     * Base URL
     */
    baseURI?: string;
    /**
     * Page name eg. HelpCenter
     */
    pageName?: string;
    [property: string]: any;
}

export interface MarketingUserClicked {
    /**
     * Additional data from the event
     */
    eventData?: MarketingUserClickedEventData;
    /**
     * Name of the event
     */
    eventName?: string;
    /**
     * Eg: Input or Click event
     */
    eventType?: string;
    [property: string]: any;
}

/**
 * Additional data from the event
 */
export interface MarketingUserClickedEventData {
    /**
     * Base URL
     */
    baseURI?: string;
    /**
     * Page name eg. HelpCenter
     */
    pageName?: string;
    [property: string]: any;
}

export interface MobileAppDownload {
    /**
     * Name of the OS
     */
    os?: OS;
    /**
     * Page name from where user clicked on download CTA
     */
    pageName?: string;
    [property: string]: any;
}

export interface MoversAndPackersPayment {
    /**
     * Payment Method used to complete the booking
     */
    paymentType?: string;
    [property: string]: any;
}

export interface OrganicInstall {
    /**
     * Name of the provider
     */
    provider?: string;
    [property: string]: any;
}

export interface PaymentCharged {
    /**
     * Total amount of the purchase
     */
    amount?: number;
    /**
     * Short ID of the job eg. SG-D178244
     */
    jobId?: string;
    /**
     * Status of the job eg. Paused
     */
    jobStatus?: JobStatus;
    /**
     * Type of the payment method
     */
    paymentMethod?: PaymentMethod;
    /**
     * Type of the payment
     */
    paymentType?: PaymentType;
    /**
     * 6-digit postcode eg. 546080
     */
    postcode?: string;
    /**
     * Service name eg. Cleaning
     */
    serviceType?: string;
    [property: string]: any;
}

/**
 * Type of the payment method
 */
export enum PaymentMethod {
    Card = "Card",
    Combined = "Combined",
    SendPay = "SendPay",
}

/**
 * Type of the payment
 */
export enum PaymentType {
    Acceptance = "Acceptance",
    Completion = "Completion",
    NewSession = "NewSession",
}

export interface PestPayment {
    /**
     * Payment Method used to complete the booking
     */
    paymentType?: string;
    [property: string]: any;
}

export interface PostcodeEntered {
    /**
     * Entered postcode
     */
    postcode?: string;
    /**
     * Is postcode valid
     */
    success?: boolean;
    [property: string]: any;
}

export interface Purchase {
    /**
     * Currency of the purchase
     */
    currency?: string;
    /**
     * Additional data from the event
     */
    items?: Item[];
    /**
     * Amount of the purchase
     */
    value?: number;
    [property: string]: any;
}

export interface Item {
    /**
     * Currency of the item
     */
    currency?: string;
    /**
     * Job ID
     */
    item_id?: string;
    /**
     * Product Name
     */
    item_name?: string;
    /**
     * Price of the item
     */
    price?: number;
    [property: string]: any;
}

export interface PurchaseSendPayCreditPackClicked {
    /**
     * Credit Pack details
     */
    creditPack?: CreditPack;
    [property: string]: any;
}

/**
 * Credit Pack details
 */
export interface CreditPack {
    /**
     * Credit Pack name eg. Credit Pack 1000
     */
    name?: string;
    /**
     * Credit Pack value
     */
    value?: number;
    [property: string]: any;
}

export interface PurchaseTopUpSendPayCreditsClicked {
    /**
     * Amount of purchase (manual)
     */
    topUpAmount?: number;
    [property: string]: any;
}

export interface PurchasedSendPayCredits {
    /**
     * Total amount of the purchase
     */
    amount?: number;
    /**
     * Type of the payment method eg. Grab
     */
    paymentMethodType?: PaymentMethodType;
    /**
     * Type of the purchase
     */
    purchaseType?: PurchaseType;
    [property: string]: any;
}

/**
 * Type of the payment method eg. Grab
 */
export enum PaymentMethodType {
    Card = "Card",
    Credits = "Credits",
    Grab = "Grab",
}

/**
 * Type of the purchase
 */
export enum PurchaseType {
    Manual = "Manual",
    Package = "Package",
}

export interface ReferFriendClicked {
    /**
     * Referral code
     */
    referralCode?: string;
    /**
     * Name of the platform where user wants to share referral code
     */
    sharePlatform?: SharePlatform;
    [property: string]: any;
}

/**
 * Name of the platform where user wants to share referral code
 */
export enum SharePlatform {
    Clipboard = "Clipboard",
    Email = "Email",
    Instagram = "Instagram",
    Messenger = "Messenger",
    Other = "Other",
    Telegram = "Telegram",
    WhatsApp = "WhatsApp",
}

export interface ReferralCodeCopied {
    /**
     * Referral code
     */
    referralCode?: string;
    [property: string]: any;
}

export interface RescheduleBookingClicked {
    /**
     * Short ID of the job eg. SG-D178244
     */
    jobId?: string;
    /**
     * Service name eg. Cleaning
     */
    serviceType?: string;
    [property: string]: any;
}

export interface RescheduleRequestClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface SelectedServiceFromDropdown {
    /**
     * Service name eg. Cleaning
     */
    serviceType?: string;
    [property: string]: any;
}

export interface ServiceBookedClient {
    /**
     * Total amount of the purchase
     */
    amount?: number;
    /**
     * Unique Booking Session ID
     */
    bookingSessionId?: string;
    /**
     * Short ID of the job eg. SG-D178244
     */
    jobId?: string;
    /**
     * 6-digit postcode eg. 546080
     */
    postcode?: string;
    /**
     * Revenue generated from the service eg. 100
     */
    revenue?: number;
    /**
     * Service name eg. Cleaning
     */
    serviceType?: string;
    [property: string]: any;
}

export interface ServiceClicked {
    /**
     * Service name eg. Cleaning
     */
    serviceType?: string;
    [property: string]: any;
}

export interface ShareReferralCodeClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface SubscriptionBookNowClicked {
    /**
     * Required to generate empty event - do not provide it
     */
    anonymousID?: string;
    [property: string]: any;
}

export interface TasksErrandsPayment {
    /**
     * Payment Method used to complete the booking
     */
    paymentType?: string;
    [property: string]: any;
}

export interface UnitAndFloorEntered {
    /**
     * Type of unit and floor entry
     */
    entryType?: EntryType;
    [property: string]: any;
}

export interface UnitFloorLineEntered {
    /**
     * Type of unit/floor entry
     */
    entryType?: EntryType;
    [property: string]: any;
}

export interface UserSignedIn {
    /**
     * Auth method used to sign up eg. Facebook
     */
    authMethod?: AuthMethod;
    [property: string]: any;
}

/**
 * Auth method used to sign up eg. Facebook
 */
export enum AuthMethod {
    Apple = "Apple",
    Email = "Email",
    Facebook = "Facebook",
    Google = "Google",
}

export interface UserSignedUp {
    /**
     * Auth method used to sign up eg. Facebook
     */
    authMethod?: AuthMethod;
    [property: string]: any;
}

import React, { createContext, useContext } from 'react';
import {
    createClient as createSegmentClient,
    Config as SegmentConfig,
    JsonMap,
} from '@segment/analytics-react-native';
import type { SegmentClient } from '@segment/analytics-react-native/lib/typescript/src/analytics';

export type TypewriterSegmentClient = SegmentClient & {
    addAddressClicked: (message: AddAddressClicked) => void,
    addHoursToSessionClicked: (message: AddHoursToSessionClicked) => void,
    addNewCreditCardClicked: (message: AddNewCreditCardClicked) => void,
    addressAdded: (message: AddressAdded) => void,
    addressCancelClicked: (message: AddressCancelClicked) => void,
    addressDeleted: (message: AddressDeleted) => void,
    addressExpanded: (message: AddressExpanded) => void,
    addressLineEntered: (message: AddressLineEntered) => void,
    addressSaveClicked: (message: AddressSaveClicked) => void,
    airConPayment: (message: AirConPayment) => void,
    applicationBackgrounded: (message: ApplicationBackgrounded) => void,
    applicationInstalled: (message: ApplicationInstalled) => void,
    applicationOpened: (message: ApplicationOpened) => void,
    applicationUpdated: (message: ApplicationUpdated) => void,
    blockLineEntered: (message: BlockLineEntered) => void,
    bookAgainClicked: (message: BookAgainClicked) => void,
    bookNowClicked: (message: BookNowClicked) => void,
    bookServiceClicked: (message: BookServiceClicked) => void,
    bookingAdditionalInformationAdded: (message: BookingAdditionalInformationAdded) => void,
    bookingAddonAdded: (message: BookingAddonAdded) => void,
    bookingAddressConfirmClicked: (message: BookingAddressConfirmClicked) => void,
    bookingAddressModuleClosed: (message: BookingAddressModuleClosed) => void,
    bookingAnotherServiceTypeSelected: (message: BookingAnotherServiceTypeSelected) => void,
    bookingBedroomNumberClicked: (message: BookingBedroomNumberClicked) => void,
    bookingBedroomNumberSelected: (message: BookingBedroomNumberSelected) => void,
    bookingCanceled: (message: BookingCanceled) => void,
    bookingCancellationChargesClicked: (message: BookingCancellationChargesClicked) => void,
    bookingCancellationChargesModuleClosed: (message: BookingCancellationChargesModuleClosed) => void,
    bookingClearBedroomsClicked: (message: BookingClearBedroomsClicked) => void,
    bookingClearPetsClicked: (message: BookingClearPetsClicked) => void,
    bookingClearPropertyFloorClicked: (message: BookingClearPropertyFloorClicked) => void,
    bookingClearPropertyTypeClicked: (message: BookingClearPropertyTypeClicked) => void,
    bookingCookingStyleEntered: (message: BookingCookingStyleEntered) => void,
    bookingCookingStyleSelected: (message: BookingCookingStyleSelected) => void,
    bookingDateAndTimeClicked: (message: BookingDateAndTimeClicked) => void,
    bookingDateAndTimeModuleClosed: (message: BookingDateAndTimeModuleClosed) => void,
    bookingDateAndTimeSelected: (message: BookingDateAndTimeSelected) => void,
    bookingDiscountCodeApplied: (message: BookingDiscountCodeApplied) => void,
    bookingDiscountCodeErrorShowed: (message: BookingDiscountCodeErrorShowed) => void,
    bookingEditAddressClicked: (message: BookingEditAddressClicked) => void,
    bookingEditPaymentClicked: (message: BookingEditPaymentClicked) => void,
    bookingElevatorSelected: (message: BookingElevatorSelected) => void,
    bookingExistingAddressSelected: (message: BookingExistingAddressSelected) => void,
    bookingFoodAllergiesSelected: (message: BookingFoodAllergiesSelected) => void,
    bookingFrequencyClicked: (message: BookingFrequencyClicked) => void,
    bookingHoursSelected: (message: BookingHoursSelected) => void,
    bookingImageUploaded: (message: BookingImageUploaded) => void,
    bookingInspectionDateAndTimeModuleClosed: (message: BookingInspectionDateAndTimeModuleClosed) => void,
    bookingInspectionDateAndTimeSelected: (message: BookingInspectionDateAndTimeSelected) => void,
    bookingInstructionForDriverSelected: (message: BookingInstructionForDriverSelected) => void,
    bookingLaundryServiceSelected: (message: BookingLaundryServiceSelected) => void,
    bookingMoreClicked: (message: BookingMoreClicked) => void,
    bookingMovingTypeSelected: (message: BookingMovingTypeSelected) => void,
    bookingNewAddressClicked: (message: BookingNewAddressClicked) => void,
    bookingNumberOfAirConditionersSelected: (message: BookingNumberOfAirConditionersSelected) => void,
    bookingPaymentSwitched: (message: BookingPaymentSwitched) => void,
    bookingPestControlServiceSelected: (message: BookingPestControlServiceSelected) => void,
    bookingPriceReferenceClicked: (message: BookingPriceReferenceClicked) => void,
    bookingPriceReferenceClosed: (message: BookingPriceReferenceClosed) => void,
    bookingPriceReferenceTabClicked: (message: BookingPriceReferenceTabClicked) => void,
    bookingPromoCodeEntered: (message: BookingPromoCodeEntered) => void,
    bookingPropertyFloorClicked: (message: BookingPropertyFloorClicked) => void,
    bookingPropertyFloorSelected: (message: BookingPropertyFloorSelected) => void,
    bookingPropertyOptionSelected: (message: BookingPropertyOptionSelected) => void,
    bookingPropertySizeSelected: (message: BookingPropertySizeSelected) => void,
    bookingPropertyTypeClicked: (message: BookingPropertyTypeClicked) => void,
    bookingPropertyTypeSelected: (message: BookingPropertyTypeSelected) => void,
    bookingPurchaseSendPayCreditsClicked: (message: BookingPurchaseSendPayCreditsClicked) => void,
    bookingPurchasingServicesSelected: (message: BookingPurchasingServicesSelected) => void,
    bookingRescheduled: (message: BookingRescheduled) => void,
    bookingSelectSavedAddressClicked: (message: BookingSelectSavedAddressClicked) => void,
    bookingSelectServiceTypeClicked: (message: BookingSelectServiceTypeClicked) => void,
    bookingServiceDescriptionModuleClosed: (message: BookingServiceDescriptionModuleClosed) => void,
    bookingServicePlanClicked: (message: BookingServicePlanClicked) => void,
    bookingServiceTypeSelected: (message: BookingServiceTypeSelected) => void,
    bookingStarted: (message: BookingStarted) => void,
    bookingSummary: (message: BookingSummary) => void,
    bookingTaskOrErrandTypeSelected: (message: BookingTaskOrErrandTypeSelected) => void,
    bookingUploadedImageRemoveClicked: (message: BookingUploadedImageRemoveClicked) => void,
    bookingWhatsIncludedAndHowItWorksClicked: (message: BookingWhatsIncludedAndHowItWorksClicked) => void,
    callSupplierClicked: (message: CallSupplierClicked) => void,
    cancelBookingClicked: (message: CancelBookingClicked) => void,
    chatWithSupplierClicked: (message: ChatWithSupplierClicked) => void,
    cleaningPayment: (message: CleaningPayment) => void,
    cleaningPlanFrequencySelected: (message: CleaningPlanFrequencySelected) => void,
    cleaningPlanSelected: (message: CleaningPlanSelected) => void,
    continueAsGuestClicked: (message: ContinueAsGuestClicked) => void,
    cookingPayment: (message: CookingPayment) => void,
    creditCardAdded: (message: CreditCardAdded) => void,
    creditCardCVVEntered: (message: CreditCardCVVEntered) => void,
    creditCardCancelClicked: (message: CreditCardCancelClicked) => void,
    creditCardDateEntered: (message: CreditCardDateEntered) => void,
    creditCardInvalidNumberEntered: (message: CreditCardInvalidNumberEntered) => void,
    creditCardModuleClosed: (message: CreditCardModuleClosed) => void,
    creditCardNameEntered: (message: CreditCardNameEntered) => void,
    creditCardNumberEntered: (message: CreditCardNumberEntered) => void,
    crossSaleBookNowClicked: (message: CrossSaleBookNowClicked) => void,
    crossaleAirconBookNowClicked: (message: CrossaleAirconBookNowClicked) => void,
    crossaleCleaningBookNowClicked: (message: CrossaleCleaningBookNowClicked) => void,
    crossaleCookingBookNowClicked: (message: CrossaleCookingBookNowClicked) => void,
    crossaleDeepCleaningBookNowClicked: (message: CrossaleDeepCleaningBookNowClicked) => void,
    crossaleHandymanBookNowClicked: (message: CrossaleHandymanBookNowClicked) => void,
    crossaleLaundryBookNowClicked: (message: CrossaleLaundryBookNowClicked) => void,
    crossaleMoversAndPackersBookNowClicked: (message: CrossaleMoversAndPackersBookNowClicked) => void,
    crossaleMyBookingsClicked: (message: CrossaleMyBookingsClicked) => void,
    crossalePestControlBookNowClicked: (message: CrossalePestControlBookNowClicked) => void,
    crossaleTasksAndErrandsBookNowClicked: (message: CrossaleTasksAndErrandsBookNowClicked) => void,
    deepLinkOpened: (message: DeepLinkOpened) => void,
    deepCleaningPayment: (message: DeepCleaningPayment) => void,
    editProfileClicked: (message: EditProfileClicked) => void,
    externalLinkClicked: (message: ExternalLinkClicked) => void,
    favouritePartnerSet: (message: FavouritePartnerSet) => void,
    handymanPayment: (message: HandymanPayment) => void,
    installAttributed: (message: InstallAttributed) => void,
    jobAccepted: (message: JobAccepted) => void,
    jobCancelled: (message: JobCancelled) => void,
    jobClicked: (message: JobClicked) => void,
    jobCompleted: (message: JobCompleted) => void,
    jobExpired: (message: JobExpired) => void,
    jobSessionExtended: (message: JobSessionExtended) => void,
    laundryPayment: (message: LaundryPayment) => void,
    marketingAffiliateClicked: (message: MarketingAffiliateClicked) => void,
    marketingBecomeAPartnerClicked: (message: MarketingBecomeAPartnerClicked) => void,
    marketingBlogClicked: (message: MarketingBlogClicked) => void,
    marketingBookNowClicked: (message: MarketingBookNowClicked) => void,
    marketingBookServiceClicked: (message: MarketingBookServiceClicked) => void,
    marketingClickBookNowOnOfferBanner: (message: MarketingClickBookNowOnOfferBanner) => void,
    marketingContactClicked: (message: MarketingContactClicked) => void,
    marketingContactUsClicked: (message: MarketingContactUsClicked) => void,
    marketingDealsClicked: (message: MarketingDealsClicked) => void,
    marketingEnterEmailSubscribed: (message: MarketingEnterEmailSubscribed) => void,
    marketingEnterPostCode: (message: MarketingEnterPostCode) => void,
    marketingExternalLinkClicked: (message: MarketingExternalLinkClicked) => void,
    marketingHelpCentreClicked: (message: MarketingHelpCentreClicked) => void,
    marketingHelpCentreSearched: (message: MarketingHelpCentreSearched) => void,
    marketingMobileAppDownload: (message: MarketingMobileAppDownload) => void,
    marketingMobileAppDownloadClicked: (message: MarketingMobileAppDownloadClicked) => void,
    marketingNewsletterSubscribed: (message: MarketingNewsletterSubscribed) => void,
    marketingNotificationClicked: (message: MarketingNotificationClicked) => void,
    marketingOurServicesClicked: (message: MarketingOurServicesClicked) => void,
    marketingPageViewed: (message: MarketingPageViewed) => void,
    marketingPartnerSignedUp: (message: MarketingPartnerSignedUp) => void,
    marketingReviewClicked: (message: MarketingReviewClicked) => void,
    marketingScrollToBottom: (message: MarketingScrollToBottom) => void,
    marketingSearchBookNowClicked: (message: MarketingSearchBookNowClicked) => void,
    marketingSearchPostCodeClicked: (message: MarketingSearchPostCodeClicked) => void,
    marketingSearchServiceClicked: (message: MarketingSearchServiceClicked) => void,
    marketingSearchServiceTypeClicked: (message: MarketingSearchServiceTypeClicked) => void,
    marketingServiceClicked: (message: MarketingServiceClicked) => void,
    marketingServiceIconClicked: (message: MarketingServiceIconClicked) => void,
    marketingUserClicked: (message: MarketingUserClicked) => void,
    mobileAppDownload: (message: MobileAppDownload) => void,
    moversAndPackersPayment: (message: MoversAndPackersPayment) => void,
    organicInstall: (message: OrganicInstall) => void,
    paymentCharged: (message: PaymentCharged) => void,
    pestPayment: (message: PestPayment) => void,
    postcodeEntered: (message: PostcodeEntered) => void,
    purchase: (message: Purchase) => void,
    purchaseSendPayCreditPackClicked: (message: PurchaseSendPayCreditPackClicked) => void,
    purchaseTopUpSendPayCreditsClicked: (message: PurchaseTopUpSendPayCreditsClicked) => void,
    purchasedSendPayCredits: (message: PurchasedSendPayCredits) => void,
    referFriendClicked: (message: ReferFriendClicked) => void,
    referralCodeCopied: (message: ReferralCodeCopied) => void,
    rescheduleBookingClicked: (message: RescheduleBookingClicked) => void,
    rescheduleRequestClicked: (message: RescheduleRequestClicked) => void,
    selectedServiceFromDropdown: (message: SelectedServiceFromDropdown) => void,
    serviceBookedClient: (message: ServiceBookedClient) => void,
    serviceClicked: (message: ServiceClicked) => void,
    shareReferralCodeClicked: (message: ShareReferralCodeClicked) => void,
    subscriptionBookNowClicked: (message: SubscriptionBookNowClicked) => void,
    tasksErrandsPayment: (message: TasksErrandsPayment) => void,
    unitAndFloorEntered: (message: UnitAndFloorEntered) => void,
    unitFloorLineEntered: (message: UnitFloorLineEntered) => void,
    userSignedIn: (message: UserSignedIn) => void,
    userSignedUp: (message: UserSignedUp) => void,
}

function extendSegmentClient(client: SegmentClient): TypewriterSegmentClient {
    const extendedClient = client as TypewriterSegmentClient;
    extendedClient.addAddressClicked = (message: AddAddressClicked) => {
        client.track('AddAddressClicked', message as unknown as JsonMap);
    };
    extendedClient.addHoursToSessionClicked = (message: AddHoursToSessionClicked) => {
        client.track('AddHoursToSessionClicked', message as unknown as JsonMap);
    };
    extendedClient.addNewCreditCardClicked = (message: AddNewCreditCardClicked) => {
        client.track('AddNewCreditCardClicked', message as unknown as JsonMap);
    };
    extendedClient.addressAdded = (message: AddressAdded) => {
        client.track('AddressAdded', message as unknown as JsonMap);
    };
    extendedClient.addressCancelClicked = (message: AddressCancelClicked) => {
        client.track('AddressCancelClicked', message as unknown as JsonMap);
    };
    extendedClient.addressDeleted = (message: AddressDeleted) => {
        client.track('AddressDeleted', message as unknown as JsonMap);
    };
    extendedClient.addressExpanded = (message: AddressExpanded) => {
        client.track('AddressExpanded', message as unknown as JsonMap);
    };
    extendedClient.addressLineEntered = (message: AddressLineEntered) => {
        client.track('AddressLineEntered', message as unknown as JsonMap);
    };
    extendedClient.addressSaveClicked = (message: AddressSaveClicked) => {
        client.track('AddressSaveClicked', message as unknown as JsonMap);
    };
    extendedClient.airConPayment = (message: AirConPayment) => {
        client.track('AirConPayment', message as unknown as JsonMap);
    };
    extendedClient.applicationBackgrounded = (message: ApplicationBackgrounded) => {
        client.track('Application Backgrounded', message as unknown as JsonMap);
    };
    extendedClient.applicationInstalled = (message: ApplicationInstalled) => {
        client.track('Application Installed', message as unknown as JsonMap);
    };
    extendedClient.applicationOpened = (message: ApplicationOpened) => {
        client.track('Application Opened', message as unknown as JsonMap);
    };
    extendedClient.applicationUpdated = (message: ApplicationUpdated) => {
        client.track('Application Updated', message as unknown as JsonMap);
    };
    extendedClient.blockLineEntered = (message: BlockLineEntered) => {
        client.track('BlockLineEntered', message as unknown as JsonMap);
    };
    extendedClient.bookAgainClicked = (message: BookAgainClicked) => {
        client.track('BookAgainClicked', message as unknown as JsonMap);
    };
    extendedClient.bookNowClicked = (message: BookNowClicked) => {
        client.track('BookNowClicked', message as unknown as JsonMap);
    };
    extendedClient.bookServiceClicked = (message: BookServiceClicked) => {
        client.track('BookServiceClicked', message as unknown as JsonMap);
    };
    extendedClient.bookingAdditionalInformationAdded = (message: BookingAdditionalInformationAdded) => {
        client.track('BookingAdditionalInformationAdded', message as unknown as JsonMap);
    };
    extendedClient.bookingAddonAdded = (message: BookingAddonAdded) => {
        client.track('BookingAddonAdded', message as unknown as JsonMap);
    };
    extendedClient.bookingAddressConfirmClicked = (message: BookingAddressConfirmClicked) => {
        client.track('BookingAddressConfirmClicked', message as unknown as JsonMap);
    };
    extendedClient.bookingAddressModuleClosed = (message: BookingAddressModuleClosed) => {
        client.track('BookingAddressModuleClosed', message as unknown as JsonMap);
    };
    extendedClient.bookingAnotherServiceTypeSelected = (message: BookingAnotherServiceTypeSelected) => {
        client.track('BookingAnotherServiceTypeSelected', message as unknown as JsonMap);
    };
    extendedClient.bookingBedroomNumberClicked = (message: BookingBedroomNumberClicked) => {
        client.track('BookingBedroomNumberClicked', message as unknown as JsonMap);
    };
    extendedClient.bookingBedroomNumberSelected = (message: BookingBedroomNumberSelected) => {
        client.track('BookingBedroomNumberSelected', message as unknown as JsonMap);
    };
    extendedClient.bookingCanceled = (message: BookingCanceled) => {
        client.track('BookingCanceled', message as unknown as JsonMap);
    };
    extendedClient.bookingCancellationChargesClicked = (message: BookingCancellationChargesClicked) => {
        client.track('BookingCancellationChargesClicked', message as unknown as JsonMap);
    };
    extendedClient.bookingCancellationChargesModuleClosed = (message: BookingCancellationChargesModuleClosed) => {
        client.track('BookingCancellationChargesModuleClosed', message as unknown as JsonMap);
    };
    extendedClient.bookingClearBedroomsClicked = (message: BookingClearBedroomsClicked) => {
        client.track('BookingClearBedroomsClicked', message as unknown as JsonMap);
    };
    extendedClient.bookingClearPetsClicked = (message: BookingClearPetsClicked) => {
        client.track('BookingClearPetsClicked', message as unknown as JsonMap);
    };
    extendedClient.bookingClearPropertyFloorClicked = (message: BookingClearPropertyFloorClicked) => {
        client.track('BookingClearPropertyFloorClicked', message as unknown as JsonMap);
    };
    extendedClient.bookingClearPropertyTypeClicked = (message: BookingClearPropertyTypeClicked) => {
        client.track('BookingClearPropertyTypeClicked', message as unknown as JsonMap);
    };
    extendedClient.bookingCookingStyleEntered = (message: BookingCookingStyleEntered) => {
        client.track('BookingCookingStyleEntered', message as unknown as JsonMap);
    };
    extendedClient.bookingCookingStyleSelected = (message: BookingCookingStyleSelected) => {
        client.track('BookingCookingStyleSelected', message as unknown as JsonMap);
    };
    extendedClient.bookingDateAndTimeClicked = (message: BookingDateAndTimeClicked) => {
        client.track('BookingDateAndTimeClicked', message as unknown as JsonMap);
    };
    extendedClient.bookingDateAndTimeModuleClosed = (message: BookingDateAndTimeModuleClosed) => {
        client.track('BookingDateAndTimeModuleClosed', message as unknown as JsonMap);
    };
    extendedClient.bookingDateAndTimeSelected = (message: BookingDateAndTimeSelected) => {
        client.track('BookingDateAndTimeSelected', message as unknown as JsonMap);
    };
    extendedClient.bookingDiscountCodeApplied = (message: BookingDiscountCodeApplied) => {
        client.track('BookingDiscountCodeApplied', message as unknown as JsonMap);
    };
    extendedClient.bookingDiscountCodeErrorShowed = (message: BookingDiscountCodeErrorShowed) => {
        client.track('BookingDiscountCodeErrorShowed', message as unknown as JsonMap);
    };
    extendedClient.bookingEditAddressClicked = (message: BookingEditAddressClicked) => {
        client.track('BookingEditAddressClicked', message as unknown as JsonMap);
    };
    extendedClient.bookingEditPaymentClicked = (message: BookingEditPaymentClicked) => {
        client.track('BookingEditPaymentClicked', message as unknown as JsonMap);
    };
    extendedClient.bookingElevatorSelected = (message: BookingElevatorSelected) => {
        client.track('BookingElevatorSelected', message as unknown as JsonMap);
    };
    extendedClient.bookingExistingAddressSelected = (message: BookingExistingAddressSelected) => {
        client.track('BookingExistingAddressSelected', message as unknown as JsonMap);
    };
    extendedClient.bookingFoodAllergiesSelected = (message: BookingFoodAllergiesSelected) => {
        client.track('BookingFoodAllergiesSelected', message as unknown as JsonMap);
    };
    extendedClient.bookingFrequencyClicked = (message: BookingFrequencyClicked) => {
        client.track('BookingFrequencyClicked', message as unknown as JsonMap);
    };
    extendedClient.bookingHoursSelected = (message: BookingHoursSelected) => {
        client.track('BookingHoursSelected', message as unknown as JsonMap);
    };
    extendedClient.bookingImageUploaded = (message: BookingImageUploaded) => {
        client.track('BookingImageUploaded', message as unknown as JsonMap);
    };
    extendedClient.bookingInspectionDateAndTimeModuleClosed = (message: BookingInspectionDateAndTimeModuleClosed) => {
        client.track('BookingInspectionDateAndTimeModuleClosed', message as unknown as JsonMap);
    };
    extendedClient.bookingInspectionDateAndTimeSelected = (message: BookingInspectionDateAndTimeSelected) => {
        client.track('BookingInspectionDateAndTimeSelected', message as unknown as JsonMap);
    };
    extendedClient.bookingInstructionForDriverSelected = (message: BookingInstructionForDriverSelected) => {
        client.track('BookingInstructionForDriverSelected', message as unknown as JsonMap);
    };
    extendedClient.bookingLaundryServiceSelected = (message: BookingLaundryServiceSelected) => {
        client.track('BookingLaundryServiceSelected', message as unknown as JsonMap);
    };
    extendedClient.bookingMoreClicked = (message: BookingMoreClicked) => {
        client.track('BookingMoreClicked', message as unknown as JsonMap);
    };
    extendedClient.bookingMovingTypeSelected = (message: BookingMovingTypeSelected) => {
        client.track('BookingMovingTypeSelected', message as unknown as JsonMap);
    };
    extendedClient.bookingNewAddressClicked = (message: BookingNewAddressClicked) => {
        client.track('BookingNewAddressClicked', message as unknown as JsonMap);
    };
    extendedClient.bookingNumberOfAirConditionersSelected = (message: BookingNumberOfAirConditionersSelected) => {
        client.track('BookingNumberOfAirConditionersSelected', message as unknown as JsonMap);
    };
    extendedClient.bookingPaymentSwitched = (message: BookingPaymentSwitched) => {
        client.track('BookingPaymentSwitched', message as unknown as JsonMap);
    };
    extendedClient.bookingPestControlServiceSelected = (message: BookingPestControlServiceSelected) => {
        client.track('BookingPestControlServiceSelected', message as unknown as JsonMap);
    };
    extendedClient.bookingPriceReferenceClicked = (message: BookingPriceReferenceClicked) => {
        client.track('BookingPriceReferenceClicked', message as unknown as JsonMap);
    };
    extendedClient.bookingPriceReferenceClosed = (message: BookingPriceReferenceClosed) => {
        client.track('BookingPriceReferenceClosed', message as unknown as JsonMap);
    };
    extendedClient.bookingPriceReferenceTabClicked = (message: BookingPriceReferenceTabClicked) => {
        client.track('BookingPriceReferenceTabClicked', message as unknown as JsonMap);
    };
    extendedClient.bookingPromoCodeEntered = (message: BookingPromoCodeEntered) => {
        client.track('BookingPromoCodeEntered', message as unknown as JsonMap);
    };
    extendedClient.bookingPropertyFloorClicked = (message: BookingPropertyFloorClicked) => {
        client.track('BookingPropertyFloorClicked', message as unknown as JsonMap);
    };
    extendedClient.bookingPropertyFloorSelected = (message: BookingPropertyFloorSelected) => {
        client.track('BookingPropertyFloorSelected', message as unknown as JsonMap);
    };
    extendedClient.bookingPropertyOptionSelected = (message: BookingPropertyOptionSelected) => {
        client.track('BookingPropertyOptionSelected', message as unknown as JsonMap);
    };
    extendedClient.bookingPropertySizeSelected = (message: BookingPropertySizeSelected) => {
        client.track('BookingPropertySizeSelected', message as unknown as JsonMap);
    };
    extendedClient.bookingPropertyTypeClicked = (message: BookingPropertyTypeClicked) => {
        client.track('BookingPropertyTypeClicked', message as unknown as JsonMap);
    };
    extendedClient.bookingPropertyTypeSelected = (message: BookingPropertyTypeSelected) => {
        client.track('BookingPropertyTypeSelected', message as unknown as JsonMap);
    };
    extendedClient.bookingPurchaseSendPayCreditsClicked = (message: BookingPurchaseSendPayCreditsClicked) => {
        client.track('BookingPurchaseSendPayCreditsClicked', message as unknown as JsonMap);
    };
    extendedClient.bookingPurchasingServicesSelected = (message: BookingPurchasingServicesSelected) => {
        client.track('BookingPurchasingServicesSelected', message as unknown as JsonMap);
    };
    extendedClient.bookingRescheduled = (message: BookingRescheduled) => {
        client.track('BookingRescheduled', message as unknown as JsonMap);
    };
    extendedClient.bookingSelectSavedAddressClicked = (message: BookingSelectSavedAddressClicked) => {
        client.track('BookingSelectSavedAddressClicked', message as unknown as JsonMap);
    };
    extendedClient.bookingSelectServiceTypeClicked = (message: BookingSelectServiceTypeClicked) => {
        client.track('BookingSelectServiceTypeClicked', message as unknown as JsonMap);
    };
    extendedClient.bookingServiceDescriptionModuleClosed = (message: BookingServiceDescriptionModuleClosed) => {
        client.track('BookingServiceDescriptionModuleClosed', message as unknown as JsonMap);
    };
    extendedClient.bookingServicePlanClicked = (message: BookingServicePlanClicked) => {
        client.track('BookingServicePlanClicked', message as unknown as JsonMap);
    };
    extendedClient.bookingServiceTypeSelected = (message: BookingServiceTypeSelected) => {
        client.track('BookingServiceTypeSelected', message as unknown as JsonMap);
    };
    extendedClient.bookingStarted = (message: BookingStarted) => {
        client.track('BookingStarted', message as unknown as JsonMap);
    };
    extendedClient.bookingSummary = (message: BookingSummary) => {
        client.track('BookingSummary', message as unknown as JsonMap);
    };
    extendedClient.bookingTaskOrErrandTypeSelected = (message: BookingTaskOrErrandTypeSelected) => {
        client.track('BookingTaskOrErrandTypeSelected', message as unknown as JsonMap);
    };
    extendedClient.bookingUploadedImageRemoveClicked = (message: BookingUploadedImageRemoveClicked) => {
        client.track('BookingUploadedImageRemoveClicked', message as unknown as JsonMap);
    };
    extendedClient.bookingWhatsIncludedAndHowItWorksClicked = (message: BookingWhatsIncludedAndHowItWorksClicked) => {
        client.track('BookingWhatsIncludedAndHowItWorksClicked', message as unknown as JsonMap);
    };
    extendedClient.callSupplierClicked = (message: CallSupplierClicked) => {
        client.track('CallSupplierClicked', message as unknown as JsonMap);
    };
    extendedClient.cancelBookingClicked = (message: CancelBookingClicked) => {
        client.track('CancelBookingClicked', message as unknown as JsonMap);
    };
    extendedClient.chatWithSupplierClicked = (message: ChatWithSupplierClicked) => {
        client.track('ChatWithSupplierClicked', message as unknown as JsonMap);
    };
    extendedClient.cleaningPayment = (message: CleaningPayment) => {
        client.track('CleaningPayment', message as unknown as JsonMap);
    };
    extendedClient.cleaningPlanFrequencySelected = (message: CleaningPlanFrequencySelected) => {
        client.track('CleaningPlanFrequencySelected', message as unknown as JsonMap);
    };
    extendedClient.cleaningPlanSelected = (message: CleaningPlanSelected) => {
        client.track('CleaningPlanSelected', message as unknown as JsonMap);
    };
    extendedClient.continueAsGuestClicked = (message: ContinueAsGuestClicked) => {
        client.track('ContinueAsGuestClicked', message as unknown as JsonMap);
    };
    extendedClient.cookingPayment = (message: CookingPayment) => {
        client.track('CookingPayment', message as unknown as JsonMap);
    };
    extendedClient.creditCardAdded = (message: CreditCardAdded) => {
        client.track('CreditCardAdded', message as unknown as JsonMap);
    };
    extendedClient.creditCardCVVEntered = (message: CreditCardCVVEntered) => {
        client.track('CreditCardCVVEntered', message as unknown as JsonMap);
    };
    extendedClient.creditCardCancelClicked = (message: CreditCardCancelClicked) => {
        client.track('CreditCardCancelClicked', message as unknown as JsonMap);
    };
    extendedClient.creditCardDateEntered = (message: CreditCardDateEntered) => {
        client.track('CreditCardDateEntered', message as unknown as JsonMap);
    };
    extendedClient.creditCardInvalidNumberEntered = (message: CreditCardInvalidNumberEntered) => {
        client.track('CreditCardInvalidNumberEntered', message as unknown as JsonMap);
    };
    extendedClient.creditCardModuleClosed = (message: CreditCardModuleClosed) => {
        client.track('CreditCardModuleClosed', message as unknown as JsonMap);
    };
    extendedClient.creditCardNameEntered = (message: CreditCardNameEntered) => {
        client.track('CreditCardNameEntered', message as unknown as JsonMap);
    };
    extendedClient.creditCardNumberEntered = (message: CreditCardNumberEntered) => {
        client.track('CreditCardNumberEntered', message as unknown as JsonMap);
    };
    extendedClient.crossSaleBookNowClicked = (message: CrossSaleBookNowClicked) => {
        client.track('CrossSaleBookNowClicked', message as unknown as JsonMap);
    };
    extendedClient.crossaleAirconBookNowClicked = (message: CrossaleAirconBookNowClicked) => {
        client.track('CrossaleAirconBookNowClicked', message as unknown as JsonMap);
    };
    extendedClient.crossaleCleaningBookNowClicked = (message: CrossaleCleaningBookNowClicked) => {
        client.track('CrossaleCleaningBookNowClicked', message as unknown as JsonMap);
    };
    extendedClient.crossaleCookingBookNowClicked = (message: CrossaleCookingBookNowClicked) => {
        client.track('CrossaleCookingBookNowClicked', message as unknown as JsonMap);
    };
    extendedClient.crossaleDeepCleaningBookNowClicked = (message: CrossaleDeepCleaningBookNowClicked) => {
        client.track('CrossaleDeepCleaningBookNowClicked', message as unknown as JsonMap);
    };
    extendedClient.crossaleHandymanBookNowClicked = (message: CrossaleHandymanBookNowClicked) => {
        client.track('CrossaleHandymanBookNowClicked', message as unknown as JsonMap);
    };
    extendedClient.crossaleLaundryBookNowClicked = (message: CrossaleLaundryBookNowClicked) => {
        client.track('CrossaleLaundryBookNowClicked', message as unknown as JsonMap);
    };
    extendedClient.crossaleMoversAndPackersBookNowClicked = (message: CrossaleMoversAndPackersBookNowClicked) => {
        client.track('CrossaleMoversAndPackersBookNowClicked', message as unknown as JsonMap);
    };
    extendedClient.crossaleMyBookingsClicked = (message: CrossaleMyBookingsClicked) => {
        client.track('CrossaleMyBookingsClicked', message as unknown as JsonMap);
    };
    extendedClient.crossalePestControlBookNowClicked = (message: CrossalePestControlBookNowClicked) => {
        client.track('CrossalePestControlBookNowClicked', message as unknown as JsonMap);
    };
    extendedClient.crossaleTasksAndErrandsBookNowClicked = (message: CrossaleTasksAndErrandsBookNowClicked) => {
        client.track('CrossaleTasksAndErrandsBookNowClicked', message as unknown as JsonMap);
    };
    extendedClient.deepLinkOpened = (message: DeepLinkOpened) => {
        client.track('Deep Link Opened', message as unknown as JsonMap);
    };
    extendedClient.deepCleaningPayment = (message: DeepCleaningPayment) => {
        client.track('DeepCleaningPayment', message as unknown as JsonMap);
    };
    extendedClient.editProfileClicked = (message: EditProfileClicked) => {
        client.track('EditProfileClicked', message as unknown as JsonMap);
    };
    extendedClient.externalLinkClicked = (message: ExternalLinkClicked) => {
        client.track('ExternalLinkClicked', message as unknown as JsonMap);
    };
    extendedClient.favouritePartnerSet = (message: FavouritePartnerSet) => {
        client.track('FavouritePartnerSet', message as unknown as JsonMap);
    };
    extendedClient.handymanPayment = (message: HandymanPayment) => {
        client.track('HandymanPayment', message as unknown as JsonMap);
    };
    extendedClient.installAttributed = (message: InstallAttributed) => {
        client.track('Install Attributed', message as unknown as JsonMap);
    };
    extendedClient.jobAccepted = (message: JobAccepted) => {
        client.track('JobAccepted', message as unknown as JsonMap);
    };
    extendedClient.jobCancelled = (message: JobCancelled) => {
        client.track('JobCancelled', message as unknown as JsonMap);
    };
    extendedClient.jobClicked = (message: JobClicked) => {
        client.track('JobClicked', message as unknown as JsonMap);
    };
    extendedClient.jobCompleted = (message: JobCompleted) => {
        client.track('JobCompleted', message as unknown as JsonMap);
    };
    extendedClient.jobExpired = (message: JobExpired) => {
        client.track('JobExpired', message as unknown as JsonMap);
    };
    extendedClient.jobSessionExtended = (message: JobSessionExtended) => {
        client.track('JobSessionExtended', message as unknown as JsonMap);
    };
    extendedClient.laundryPayment = (message: LaundryPayment) => {
        client.track('LaundryPayment', message as unknown as JsonMap);
    };
    extendedClient.marketingAffiliateClicked = (message: MarketingAffiliateClicked) => {
        client.track('MarketingAffiliateClicked', message as unknown as JsonMap);
    };
    extendedClient.marketingBecomeAPartnerClicked = (message: MarketingBecomeAPartnerClicked) => {
        client.track('MarketingBecomeAPartnerClicked', message as unknown as JsonMap);
    };
    extendedClient.marketingBlogClicked = (message: MarketingBlogClicked) => {
        client.track('MarketingBlogClicked', message as unknown as JsonMap);
    };
    extendedClient.marketingBookNowClicked = (message: MarketingBookNowClicked) => {
        client.track('MarketingBookNowClicked', message as unknown as JsonMap);
    };
    extendedClient.marketingBookServiceClicked = (message: MarketingBookServiceClicked) => {
        client.track('MarketingBookServiceClicked', message as unknown as JsonMap);
    };
    extendedClient.marketingClickBookNowOnOfferBanner = (message: MarketingClickBookNowOnOfferBanner) => {
        client.track('MarketingClickBookNowOnOfferBanner', message as unknown as JsonMap);
    };
    extendedClient.marketingContactClicked = (message: MarketingContactClicked) => {
        client.track('MarketingContactClicked', message as unknown as JsonMap);
    };
    extendedClient.marketingContactUsClicked = (message: MarketingContactUsClicked) => {
        client.track('MarketingContactUsClicked', message as unknown as JsonMap);
    };
    extendedClient.marketingDealsClicked = (message: MarketingDealsClicked) => {
        client.track('MarketingDealsClicked', message as unknown as JsonMap);
    };
    extendedClient.marketingEnterEmailSubscribed = (message: MarketingEnterEmailSubscribed) => {
        client.track('MarketingEnterEmailSubscribed', message as unknown as JsonMap);
    };
    extendedClient.marketingEnterPostCode = (message: MarketingEnterPostCode) => {
        client.track('MarketingEnterPostCode', message as unknown as JsonMap);
    };
    extendedClient.marketingExternalLinkClicked = (message: MarketingExternalLinkClicked) => {
        client.track('MarketingExternalLinkClicked', message as unknown as JsonMap);
    };
    extendedClient.marketingHelpCentreClicked = (message: MarketingHelpCentreClicked) => {
        client.track('MarketingHelpCentreClicked', message as unknown as JsonMap);
    };
    extendedClient.marketingHelpCentreSearched = (message: MarketingHelpCentreSearched) => {
        client.track('MarketingHelpCentreSearched', message as unknown as JsonMap);
    };
    extendedClient.marketingMobileAppDownload = (message: MarketingMobileAppDownload) => {
        client.track('MarketingMobileAppDownload', message as unknown as JsonMap);
    };
    extendedClient.marketingMobileAppDownloadClicked = (message: MarketingMobileAppDownloadClicked) => {
        client.track('MarketingMobileAppDownloadClicked', message as unknown as JsonMap);
    };
    extendedClient.marketingNewsletterSubscribed = (message: MarketingNewsletterSubscribed) => {
        client.track('MarketingNewsletterSubscribed', message as unknown as JsonMap);
    };
    extendedClient.marketingNotificationClicked = (message: MarketingNotificationClicked) => {
        client.track('MarketingNotificationClicked', message as unknown as JsonMap);
    };
    extendedClient.marketingOurServicesClicked = (message: MarketingOurServicesClicked) => {
        client.track('MarketingOurServicesClicked', message as unknown as JsonMap);
    };
    extendedClient.marketingPageViewed = (message: MarketingPageViewed) => {
        client.track('MarketingPageViewed', message as unknown as JsonMap);
    };
    extendedClient.marketingPartnerSignedUp = (message: MarketingPartnerSignedUp) => {
        client.track('MarketingPartnerSignedUp', message as unknown as JsonMap);
    };
    extendedClient.marketingReviewClicked = (message: MarketingReviewClicked) => {
        client.track('MarketingReviewClicked', message as unknown as JsonMap);
    };
    extendedClient.marketingScrollToBottom = (message: MarketingScrollToBottom) => {
        client.track('MarketingScrollToBottom', message as unknown as JsonMap);
    };
    extendedClient.marketingSearchBookNowClicked = (message: MarketingSearchBookNowClicked) => {
        client.track('MarketingSearchBookNowClicked', message as unknown as JsonMap);
    };
    extendedClient.marketingSearchPostCodeClicked = (message: MarketingSearchPostCodeClicked) => {
        client.track('MarketingSearchPostCodeClicked', message as unknown as JsonMap);
    };
    extendedClient.marketingSearchServiceClicked = (message: MarketingSearchServiceClicked) => {
        client.track('MarketingSearchServiceClicked', message as unknown as JsonMap);
    };
    extendedClient.marketingSearchServiceTypeClicked = (message: MarketingSearchServiceTypeClicked) => {
        client.track('MarketingSearchServiceTypeClicked', message as unknown as JsonMap);
    };
    extendedClient.marketingServiceClicked = (message: MarketingServiceClicked) => {
        client.track('MarketingServiceClicked', message as unknown as JsonMap);
    };
    extendedClient.marketingServiceIconClicked = (message: MarketingServiceIconClicked) => {
        client.track('MarketingServiceIconClicked', message as unknown as JsonMap);
    };
    extendedClient.marketingUserClicked = (message: MarketingUserClicked) => {
        client.track('MarketingUserClicked', message as unknown as JsonMap);
    };
    extendedClient.mobileAppDownload = (message: MobileAppDownload) => {
        client.track('MobileAppDownload', message as unknown as JsonMap);
    };
    extendedClient.moversAndPackersPayment = (message: MoversAndPackersPayment) => {
        client.track('MoversAndPackersPayment', message as unknown as JsonMap);
    };
    extendedClient.organicInstall = (message: OrganicInstall) => {
        client.track('Organic Install', message as unknown as JsonMap);
    };
    extendedClient.paymentCharged = (message: PaymentCharged) => {
        client.track('PaymentCharged', message as unknown as JsonMap);
    };
    extendedClient.pestPayment = (message: PestPayment) => {
        client.track('PestPayment', message as unknown as JsonMap);
    };
    extendedClient.postcodeEntered = (message: PostcodeEntered) => {
        client.track('PostcodeEntered', message as unknown as JsonMap);
    };
    extendedClient.purchase = (message: Purchase) => {
        client.track('Purchase', message as unknown as JsonMap);
    };
    extendedClient.purchaseSendPayCreditPackClicked = (message: PurchaseSendPayCreditPackClicked) => {
        client.track('PurchaseSendPayCreditPackClicked', message as unknown as JsonMap);
    };
    extendedClient.purchaseTopUpSendPayCreditsClicked = (message: PurchaseTopUpSendPayCreditsClicked) => {
        client.track('PurchaseTopUpSendPayCreditsClicked', message as unknown as JsonMap);
    };
    extendedClient.purchasedSendPayCredits = (message: PurchasedSendPayCredits) => {
        client.track('PurchasedSendPayCredits', message as unknown as JsonMap);
    };
    extendedClient.referFriendClicked = (message: ReferFriendClicked) => {
        client.track('ReferFriendClicked', message as unknown as JsonMap);
    };
    extendedClient.referralCodeCopied = (message: ReferralCodeCopied) => {
        client.track('ReferralCodeCopied', message as unknown as JsonMap);
    };
    extendedClient.rescheduleBookingClicked = (message: RescheduleBookingClicked) => {
        client.track('RescheduleBookingClicked', message as unknown as JsonMap);
    };
    extendedClient.rescheduleRequestClicked = (message: RescheduleRequestClicked) => {
        client.track('RescheduleRequestClicked', message as unknown as JsonMap);
    };
    extendedClient.selectedServiceFromDropdown = (message: SelectedServiceFromDropdown) => {
        client.track('SelectedServiceFromDropdown', message as unknown as JsonMap);
    };
    extendedClient.serviceBookedClient = (message: ServiceBookedClient) => {
        client.track('ServiceBookedClient', message as unknown as JsonMap);
    };
    extendedClient.serviceClicked = (message: ServiceClicked) => {
        client.track('ServiceClicked', message as unknown as JsonMap);
    };
    extendedClient.shareReferralCodeClicked = (message: ShareReferralCodeClicked) => {
        client.track('ShareReferralCodeClicked', message as unknown as JsonMap);
    };
    extendedClient.subscriptionBookNowClicked = (message: SubscriptionBookNowClicked) => {
        client.track('SubscriptionBookNowClicked', message as unknown as JsonMap);
    };
    extendedClient.tasksErrandsPayment = (message: TasksErrandsPayment) => {
        client.track('TasksErrandsPayment', message as unknown as JsonMap);
    };
    extendedClient.unitAndFloorEntered = (message: UnitAndFloorEntered) => {
        client.track('UnitAndFloorEntered', message as unknown as JsonMap);
    };
    extendedClient.unitFloorLineEntered = (message: UnitFloorLineEntered) => {
        client.track('UnitFloorLineEntered', message as unknown as JsonMap);
    };
    extendedClient.userSignedIn = (message: UserSignedIn) => {
        client.track('UserSignedIn', message as unknown as JsonMap);
    };
    extendedClient.userSignedUp = (message: UserSignedUp) => {
        client.track('UserSignedUp', message as unknown as JsonMap);
    };
    return extendedClient;
}

export const createClient = (config: SegmentConfig) => {
    const client = createSegmentClient(config);
    return extendSegmentClient(client);
};

const Context = createContext<ReturnType<typeof createClient> | null>(null);

export const AnalyticsProvider = ({
    client,
    children,
}: {
    client?: ReturnType<typeof createClient>;
    children?: any;
}) => {
    if (!client) {
        return null;
    }

    return <Context.Provider value={client}>{children}</Context.Provider>;
};

export const useAnalytics = () => {
    const client = useContext(Context);
    if (!client) {
        console.error(
            'Segment client not configured!',
            'To use the useAnalytics() hook, pass an initialized Segment client into the AnalyticsProvider',
        );

        // @ts-ignore
        return {};
    }
    return {
        ...client,
    };
};

