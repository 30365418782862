import React from 'react'
import Animated, { FadeIn } from 'react-native-reanimated'
import { View } from 'react-native'
import { useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { LinkButton, Typography } from 'lib/components'
import { useUserProfilePreview } from '../hooks'
import { UserDeleteAccountSection } from './UserDeleteAccountSection'
import { isIOS } from 'lib/common'

type UserEditProfileProps = {
    onEdit: VoidFunction
}

export const UserEditProfilePreview: React.FunctionComponent<UserEditProfileProps> = ({ onEdit }) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const userData = useUserProfilePreview()

    return (
        <Animated.View entering={FadeIn}>
            {userData.map((item, index) => (
                <View
                    key={index}
                    style={styles.row}
                >
                    <View style={styles.labelColumn}>
                        <Typography.Regular forceColor={theme.colors.darkGrey}>
                            {item.label}
                        </Typography.Regular>
                    </View>
                    <View>
                        <Typography.Regular bold>
                            {item.value}
                        </Typography.Regular>
                    </View>
                </View>
            ))}
            <View style={styles.button}>
                <LinkButton
                    onPress={onEdit}
                    text={T.screens.user.editProfile}
                />
            </View>
            {isIOS && <UserDeleteAccountSection />}
        </Animated.View>
    )
}

const stylesheet = createStyles(theme => ({
    row: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        paddingVertical: theme.utils.gap(2),
        borderBottomColor: theme.colors.silver
    },
    labelColumn: {
        width: 100
    },
    button: {
        marginTop: theme.utils.gap(2),
        marginBottom: theme.utils.gap(3)
    }
}))
