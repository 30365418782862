import React from 'react'
import { View } from 'react-native'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { UserPhoto } from 'features/user'
import { Icons } from 'assets'

type UserAvatarProps = {
    isAuthorized: boolean,
    handle: string,
    size?: number
}

export const UserAvatar: React.FunctionComponent<UserAvatarProps> = ({
    isAuthorized,
    handle,
    size = 36
}) => {
    const { styles } = useStyles(stylesheet)

    if (!isAuthorized) {
        return (
            <View style={styles.icon}>
                <Icons.User size={16} />
            </View>
        )
    }

    return (
        <View style={styles.photoContainer}>
            <UserPhoto
                size={size}
                name={handle}
            />
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    photoContainer: {
        marginRight: theme.utils.gap(1)
    },
    icon: {
        marginRight: theme.utils.gap(2)
    }
}))
