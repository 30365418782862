import React from 'react'
import { View, ViewStyle } from 'react-native'
import { Children } from 'lib/types'
import { createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { Typography } from 'lib/components'

type FormRowProps = {
    children: Children,
    isOptional?: boolean,
    title?: string,
    description?: string,
    style?: ViewStyle
}

export const FormRow: React.FunctionComponent<FormRowProps> = ({
    children,
    isOptional,
    title,
    description,
    style
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)

    return (
        <View
            style={{
                ...styles.container,
                ...style
            }}
        >
            {title && (
                <View style={styles.title}>
                    <Typography.Regular bold>
                        {title}
                    </Typography.Regular>
                    {isOptional && (
                        <Typography.Label style={styles.optional}>
                            {T.common.optional}
                        </Typography.Label>
                    )}
                </View>
            )}
            {description && (
                <View style={styles.placeholder}>
                    <Typography.Regular forceColor={theme.colors.darkGrey}>
                        {description}
                    </Typography.Regular>
                </View>
            )}
            {children}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        marginBottom: {
            lg: theme.utils.gap(6),
            xs: theme.utils.gap(5)
        }
    },
    title: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: theme.utils.gap(2)
    },
    placeholder: {
        marginBottom: theme.utils.gap(2)
    },
    optional: {
        marginLeft: theme.utils.gap(1)
    }
}))
