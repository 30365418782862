import React from 'react'
import { View } from 'react-native'
import { Icons } from 'assets'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { Touchable, Typography } from 'lib/components'

type BookingTileButtonSmallProps = {
    text: string,
    onPress: VoidFunction
}

export const BookingTileButtonSmall: React.FunctionComponent<BookingTileButtonSmallProps> = ({
    text,
    onPress
}) => {
    const { styles, theme } = useStyles(stylesheet)

    return (
        <Touchable onPress={onPress}>
            <View style={styles.container}>
                <Typography.Regular
                    bold
                    forceColor={theme.colors.orange}
                >
                    {text}
                </Typography.Regular>
                <View
                    style={{
                        ...styles.chevron,
                        transform: [
                            {
                                rotateZ: '-90deg'
                            }
                        ]
                    }}
                >
                    <Icons.Chevron
                        size={10}
                        forceColor={theme.colors.orange}
                    />
                </View>
            </View>
        </Touchable>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        borderRadius: 8,
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'center',
        backgroundColor: theme.colors.yellow,
        paddingVertical: theme.utils.gap(3) / 2,
        paddingHorizontal: theme.utils.gap(4),
        marginHorizontal: -theme.utils.gap(1),
        marginBottom: -theme.utils.gap(1),
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0
    },
    chevron: {
        marginLeft: theme.utils.gap(1)
    }
}))
