import React from 'react'
import { TextStyle } from 'react-native'
import { Children, CustomFonts, VoidFunction } from 'lib/types'
import { createStyles } from 'lib/styles'
import { useStyles } from 'lib/hooks'
import { BaseText } from './BaseText'

type LabelProps = {
    numberOfLines?: number,
    onPress?: VoidFunction,
    style?: TextStyle,
    forceColor?: string,
    testID?: string,
    children?: Children,
    bold?: boolean,
    onNumberOfLines?(numberOfLines: number): void
}

export const Label: React.FunctionComponent<LabelProps> = ({
    children,
    numberOfLines,
    onPress,
    testID,
    style = {},
    forceColor,
    bold,
    onNumberOfLines
}) => {
    const { styles } = useStyles(stylesheet)
    const userStyles = bold
        ? {
            ...styles.bold,
            ...style
        }
        : style

    return (
        <BaseText
            testID={testID}
            onNumberOfLines={onNumberOfLines}
            onPress={onPress}
            style={{
                ...styles.text,
                ...userStyles
            }}
            numberOfLines={numberOfLines}
            forceColor={forceColor}
            children={children}
        />
    )
}

const stylesheet = createStyles(theme => ({
    text: {
        ...theme.components.typography.label,
        fontSize: {
            lg: 14,
            xs: 12
        },
        lineHeight: {
            lg: 17,
            xs: 15
        }
    },
    bold: {
        fontFamily: CustomFonts.Poppins600
    }
}))
