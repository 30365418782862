import braintree from 'braintree-web'
import { CreditCardShape } from 'features/payments'
import { HttpMethod } from 'lib/types'
import { NewCardData } from './types'

export const createNewCard = (clientToken: string, cardData: CreditCardShape) => new Promise<NewCardData>((resolve, reject) => {
    braintree.client.create(
        {
            authorization: clientToken
        },
        (error, client) => {
            if (error) {
                return reject(error)
            }

            client.request(
                {
                    endpoint: 'payment_methods/credit_cards',
                    method: HttpMethod.POST,
                    data: {
                        creditCard: {
                            number: cardData.cardNumber,
                            expirationDate: `${cardData.month}/${cardData.year.slice(-2)}`,
                            cvv: cardData.cvv
                        }
                    }
                },
                (error, response) => {
                    if (error) {
                        return reject(error)
                    }

                    const [card] = response.creditCards

                    return resolve({
                        type: card.type,
                        nonce: card.nonce
                    })
                }
            )
        }
    )
})
