import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const Search: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path d="M8.888 35.553c0-14.706 11.96-26.666 26.667-26.666 14.706 0 26.666 11.96 26.666 26.666 0 14.707-11.96 26.666-26.666 26.666-14.707 0-26.667-11.96-26.667-26.666m69.809 36.86L63.608 57.318c4.68-6.018 7.502-13.56 7.502-21.764C71.11 15.951 55.16 0 35.555 0 15.951 0 0 15.95 0 35.555 0 55.16 15.95 71.11 35.555 71.11c8.204 0 15.746-2.822 21.764-7.502l15.093 15.089a4.432 4.432 0 0 0 3.142 1.302 4.432 4.432 0 0 0 3.143-1.302 4.44 4.44 0 0 0 0-6.285" />
    </Icon>
)
