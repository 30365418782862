import React from 'react'
import { Image, View } from 'react-native'
import { useStyles, useTranslations } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { Grid, Touchable, Typography } from 'lib/components'
import { Images } from 'assets'
import { linkingHelpers } from 'lib/utils'

export const StoresModalBody: React.FunctionComponent = () => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)

    return (
        <View style={styles.container}>
            <Grid.Gap gapTop={3}>
                <Typography.SmallSubheading style={styles.bookNow}>
                    {T.screens.allServices.bookInMobileApp}
                </Typography.SmallSubheading>
            </Grid.Gap>
            <Grid.Gap
                gapTop={3}
                gapBottom={2}
            >
                <Touchable onPress={() => linkingHelpers.openSendhelperAppStore()}>
                    <Image
                        source={Images.AppStore}
                        style={styles.store}
                    />
                </Touchable>
            </Grid.Gap>
            <Touchable onPress={() => linkingHelpers.openSendhelperGooglePlayStore()}>
                <Image
                    source={Images.GooglePlayStore}
                    style={styles.store}
                />
            </Touchable>
        </View>
    )
}

const stylesheet = createStyles(() => ({
    container: {
        height: 200,
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center'
    },
    store: {
        height: 45,
        width: 156
    },
    bookNow: {
        maxWidth: 200,
        textAlign: 'center'
    }
}))
