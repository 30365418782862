import React from 'react'
import { View } from 'react-native'
import { Icons } from 'assets'
import { createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { CopyListener, Touchable, Typography } from 'lib/components'
import { Segment } from 'lib/analytics'

type ReferralCodeProps = {
    code: string,
    onPress: VoidFunction
}

export const ReferralCode: React.FunctionComponent<ReferralCodeProps> = ({
    code,
    onPress
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)

    return (
        <CopyListener
            onCopy={() => {
                Segment.referralCodeCopied({
                    referralCode: code
                })
            }}
        >
            <View style={styles.codeContainer}>
                <Typography.Regular
                    bold
                    forceColor={theme.colors.orange}
                >
                    {code}
                </Typography.Regular>
                <Touchable
                    onPress={() => {
                        Segment.shareReferralCodeClicked({})

                        onPress()
                    }}
                >
                    <View style={styles.shareButton}>
                        <Icons.Share size={30} />
                        <Typography.Regular
                            bold
                            style={styles.shareIcon}
                        >
                            {T.screens.referFriend.shareCode}
                        </Typography.Regular>
                    </View>
                </Touchable>
            </View>
        </CopyListener>
    )
}

const stylesheet = createStyles(theme => ({
    codeContainer: {
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderRadius: 8,
        borderWidth: 1,
        marginVertical: theme.utils.gap(1),
        borderColor: theme.colors.fog,
        maxWidth: {
            lg: 460,
            xs: '100%'
        },
        paddingLeft: theme.utils.gap(1)
    },
    shareButton: {
        margin: -1,
        flexDirection: 'row',
        alignItems: 'center',
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
        paddingVertical: theme.utils.gap(1),
        paddingHorizontal: theme.utils.gap(1),
        backgroundColor: theme.colors.sun
    },
    shareIcon: {
        marginLeft: theme.utils.gap(1)
    }
}))
