import * as Icons from './icons'
import { authBackground } from './platform'

const facebook = require('./images/facebook.png')
const google = require('./images/google.png')
const apple = require('./images/apple.png')
const heroBackground = require('./images/hero.png')
const offerThumb = require('./images/offer_thumb.png')
const logo = require('./images/logo.png')
const cleaning = require('./images/cleaning.png')
const deepCleaning = require('./images/deep_cleaning.png')
const airCondition = require('./images/air_condition.png')
const handyman = require('./images/handyman.png')
const cooking = require('./images/cooking.png')
const laundry = require('./images/laundry.png')
const pestControl = require('./images/pest_control.png')
const taskErrands = require('./images/task_errands.png')
const appStore = require('./images/app_store.png')
const googlePlayStore = require('./images/google_play.png')
const page404 = require('./images/404.png')
const logInHero = require('./images/log_in_hero.png')
const signUpHero = require('./images/sign_up_hero.png')
const onboardingBackground = require('./images/onboarding_background.png')
const onboardingHomeServices = require('./images/onboarding_home_services.png')
const onboardingTopQuality = require('./images/onboarding_top_quality.png')
const onboardingNoCashPayment = require('./images/onboarding_no_cash_payment.png')
const onboardingRatings = require('./images/onboarding_ratings.png')
const nativeAllServicesHeader = require('./images/native_all_services_header.png')
const moversPackers = require('./images/movers_packers.png')
const shareReferral = require('./images/share_referral.png')
const instagram = require('./images/instagram.png')
const messenger = require('./images/messenger.png')
const whatsApp = require('./images/whats_app.png')
const telegram = require('./images/telegram.png')
const tenLorry = require('./images/10ft_lorry.png')
const fourteenLorry = require('./images/14ft_lorry.png')
const twentyFourLorry = require('./images/24ft_lorry.png')
const maintenance = require('./images/maintenance.png')
const marketingConsentBackground = require('./images/marketing_consent_background.png')
const marketingConsentBanner = require('./images/marketing_consent_banner.jpg')
const forceUpdateBackground = require('./images/force_update.png')
const generalUpdateBackground = require('./images/hand_phone.png')
const sadToGo = require('./images/sad_to_go.png')

enum Images {
    Facebook = facebook,
    Google = google,
    Apple = apple,
    AuthBackground = authBackground,
    HeroBackground = heroBackground,
    OfferThumb = offerThumb,
    Logo = logo,
    Cleaning = cleaning,
    DeepCleaning = deepCleaning,
    AirCondition = airCondition,
    Handyman = handyman,
    Cooking = cooking,
    PestControl = pestControl,
    Laundry = laundry,
    TaskErrands = taskErrands,
    AppStore = appStore,
    GooglePlayStore = googlePlayStore,
    Page404 = page404,
    LogInHero = logInHero,
    SignUpHero = signUpHero,
    OnboardingBackground = onboardingBackground,
    OnboardingHomeServices = onboardingHomeServices,
    OnboardingTopQuality = onboardingTopQuality,
    OnboardingNoCashPayment = onboardingNoCashPayment,
    OnboardingRatings = onboardingRatings,
    NativeAllServicesHeader = nativeAllServicesHeader,
    ShareReferral = shareReferral,
    Instagram = instagram,
    Messenger = messenger,
    WhatsApp = whatsApp,
    Telegram = telegram,
    TenLorry = tenLorry,
    FourteenLorry = fourteenLorry,
    TwentyFourLorry = twentyFourLorry,
    MoversPackers = moversPackers,
    Maintenance = maintenance,
    MarketingConsentBackground = marketingConsentBackground,
    MarketingConsentBanner = marketingConsentBanner,
    ForceUpdateBackground = forceUpdateBackground,
    GeneralUpdateBackground = generalUpdateBackground,
    SadToGo = sadToGo
}

export {
    Icons,
    Images
}
