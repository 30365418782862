import React, { useEffect, useState } from 'react'
import { View, ImageBackground, useWindowDimensions } from 'react-native'
import { Breakpoint, createStyles } from 'lib/styles'
import { useUserTokenAtom } from 'lib/atoms'
import { imageActions } from 'features/image'
import { useIsWithinBreakpoints, useQueryHelpers, useStyles } from 'lib/hooks'
import { LoadingIndicator } from 'lib/components'

type PhotoProps = {
    downloadKey: string,
    isPreview?: boolean
}

export const Photo: React.FunctionComponent<PhotoProps> = ({
    downloadKey,
    isPreview
}) => {
    const { styles } = useStyles(stylesheet)
    const [token] = useUserTokenAtom()
    const dimensions = useWindowDimensions()
    const isMobile = useIsWithinBreakpoints(Breakpoint.XS, Breakpoint.MD)
    const [isImageLoading, setIsImageLoading] = useState(true)
    const { onRequestError } = useQueryHelpers()
    const { mutate: getImage, isLoading, data } = imageActions.getS3ImageDownloadURL()

    useEffect(() => {
        if (isPreview) {
            return
        }

        getImage(
            {
                token,
                key: downloadKey
            },
            {
                onError: onRequestError
            }
        )
    }, [downloadKey, isPreview])

    return (
        <View style={styles.wrapper}>
            <LoadingIndicator isLoading={isLoading || isImageLoading} />
            <ImageBackground
                resizeMode="contain"
                style={{
                    ...styles.image,
                    width: dimensions.width - (isMobile ? 50 : 200)
                }}
                onLoadEnd={() => setIsImageLoading(false)}
                source={{
                    uri: isPreview
                        ? downloadKey
                        : data?.data.download_url
                }}
            />
        </View>
    )
}

const stylesheet = createStyles(() => ({
    wrapper: {
        flex: 1
    },
    image: {
        flex: 1
    }
}))
