import React from 'react'
import { Circle, Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const Laundry: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path d="M9.8.5h50.169a8.009 8.009 0 0 1 8 8v64a8.009 8.009 0 0 1-8 8H9.8a8.009 8.009 0 0 1-8-8v-64a8.009 8.009 0 0 1 8-8Zm50.169 74.667a2.67 2.67 0 0 0 2.667-2.667v-64a2.67 2.67 0 0 0-2.667-2.667H9.8A2.67 2.67 0 0 0 7.13 8.5v64a2.67 2.67 0 0 0 2.67 2.667Z" />
        <Path d="M65.243 25.97H4.527v-5.453h60.72Z" />
        <Path d="M20.071 15.848h-7.069a2.727 2.727 0 0 1 0-5.453h7.069a2.727 2.727 0 0 1 0 5.453Z" />
        <Circle
            cx={2.828}
            cy={2.828}
            r={2.828}
            transform="translate(44.473 10.296)"
        />
        <Circle
            cx={2.828}
            cy={2.828}
            r={2.828}
            transform="translate(52.521 10.296)"
        />
        <Path d="M34.888 29.806c11.227 0 20.362 9.468 20.362 21.105s-9.135 21.106-20.362 21.106-20.363-9.468-20.363-21.106 9.135-21.105 20.363-21.105Zm0 36.758c8.327 0 15.1-7.022 15.1-15.652s-6.774-15.653-15.1-15.653-15.1 7.022-15.1 15.652 6.773 15.653 15.1 15.653Z" />
        <Path d="M34.888 56.085a19.216 19.216 0 0 1-6.3-1.059 14.137 14.137 0 0 0-4.647-.786h-6.689v-5.453h6.687a19.167 19.167 0 0 1 6.3 1.066 14.219 14.219 0 0 0 9.295 0 19.168 19.168 0 0 1 6.3-1.066h6.687v5.453h-6.685a14.137 14.137 0 0 0-4.647.786 19.218 19.218 0 0 1-6.301 1.059Z" />
    </Icon>
)
