import React from 'react'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import { AllServicesScreen } from 'features/bookings'
import { ReferScreen } from 'features/refer'
import { BookingsScreen } from 'features/jobs'
import { NotificationsScreen } from 'features/notifications'
import { UserScreen } from 'features/user'
import { BottomBar } from 'lib/components'
import { SendPayScreen } from 'features/sendPay'
import { ContactUsScreen } from 'features/contact'
import { UserCardsScreen } from 'features/payments'
import { FavouriteScreen } from 'features/favourite'
import { NavigatorId, ScreenNames } from '../screens'
import { BottomTabsParams } from '../stackParams'

const BottomTabs = createBottomTabNavigator<BottomTabsParams>()

export const BottomTabsStack = () => (
    <BottomTabs.Navigator
        id={NavigatorId.BottomTabs}
        initialRouteName={ScreenNames.AllServices}
        screenOptions={{
            headerShown: false
        }}
        tabBar={props => (
            <BottomBar {...props}/>
        )}
    >
        <BottomTabs.Screen
            name={ScreenNames.AllServices}
            component={AllServicesScreen}
        />
        <BottomTabs.Screen
            name={ScreenNames.Bookings}
            component={BookingsScreen}
        />
        <BottomTabs.Screen
            name={ScreenNames.Refer}
            component={ReferScreen}
        />
        <BottomTabs.Screen
            name={ScreenNames.Notifications}
            component={NotificationsScreen}
        />
        <BottomTabs.Screen
            name={ScreenNames.User}
            component={UserScreen}
        />
        <BottomTabs.Screen
            name={ScreenNames.SendPay}
            component={SendPayScreen}
        />
        <BottomTabs.Screen
            name={ScreenNames.Favourite}
            component={FavouriteScreen}
        />
        <BottomTabs.Screen
            name={ScreenNames.UserCards}
            component={UserCardsScreen}
        />
        <BottomTabs.Screen
            name={ScreenNames.ContactUs}
            component={ContactUsScreen}
        />
    </BottomTabs.Navigator>
)
