import { useLocaleAtom } from 'lib/atoms'
import { Nullable } from 'lib/types'
import { dateHelpers } from 'lib/utils'

export const useBookingCalendarPickerValueFormat = (
    bookingDate: string,
    bookingTime: string,
    bookingDays: Record<string, Nullable<string>>,
    fullMonthFormat = false
): string => {
    const [locale] = useLocaleAtom()

    const bookingDateDayName = dateHelpers.getWeekDayFullNames(
        bookingDate || new Date(),
        locale
    )

    const getDateFormat = () => {
        if (bookingDate) {
            return bookingTime
                ? dateHelpers.startingDate(bookingDate)
                : fullMonthFormat
                    ? dateHelpers.bookingFullDate(bookingDate)
                    : dateHelpers.calendarPickerDate(bookingDate)
        }

        return ''

    }

    const getTimeFormat = () => {
        if (bookingTime) {
            return dateHelpers.selectTimeLabel(bookingTime)
        }

        if (bookingDays[bookingDateDayName]) {
            return dateHelpers.selectTimeLabel(bookingDays[bookingDateDayName] || new Date())
        }

        return ''

    }

    const formattedDate = getDateFormat()
    const formattedTime = getTimeFormat()

    return bookingDate ? `${formattedDate}${formattedTime ? ` - ${formattedTime}` : ''}` : ''
}
