import React, { useEffect, useState } from 'react'
import { useForm } from '@codegateinc/react-form-builder-v2'
import { UploadedPhoto } from 'lib/components'
import { Segment, ServiceType } from 'lib/analytics'
import { PaymentNewCardModal } from 'features/payments'
import { useSegmentSession, useTranslations } from 'lib/hooks'
import { NavigationParams, NavigationProps, ScreenNames } from 'lib/routing'
import { SupplyID } from 'lib/types'
import { moversAndPackersHooks, useBookingPromoCode, useCreateJob } from '../hooks'
import { BookingPaymentView, MoversAndPackersComponents } from '../components'
import { useMoversAndPackersServiceDetailsForm, MoversAndPackersServiceDetailsShape } from '../forms'
import { getServiceStaticConfig } from '../utils'

type MoversAndPackersServiceDetailsScreenProps = {
    navigation: NavigationProps<ScreenNames.MoversAndPackersSummary>,
    route: NavigationParams<ScreenNames.MoversAndPackersSummary>
}

export const MoversAndPackersServiceDetailsScreen: React.FunctionComponent<MoversAndPackersServiceDetailsScreenProps> = ({ navigation, route }) => {
    const T = useTranslations()
    const { segmentSession } = useSegmentSession()
    const [photos, setUploadedPhotos] = useState<Array<UploadedPhoto>>([])
    const [paymentView, setPaymentView] = useState(false)
    const [isUploadingPhotos, setIsUploadingPhotos] = useState(false)
    const {
        isCreatingJob: isCreatingMoversAndPackers,
        createMoversAndPackers
    } = moversAndPackersHooks.useMoversAndPackersCreateJobs({
        onSuccess: () => {
            resetForm()
            removePromoCode()
        },
        uploadedPhotos: photos,
        routeParams: route.params
    })
    const formReturn = useForm<MoversAndPackersServiceDetailsShape>(useMoversAndPackersServiceDetailsForm(), {
        onSuccess: () => createMoversAndPackers({
            jobDetails,
            onCreateJob,
            paymentMethod
        })
    })
    const { submit, isFilled, hasError, resetForm, form } = formReturn
    const { isLoading: isLoadingPromotion, promotionPackage, removePromoCode, requestPromotion } = useBookingPromoCode()
    const continueDisabled = !isFilled || hasError || isUploadingPhotos
    const config = getServiceStaticConfig(SupplyID.MoversPackers)
    const {
        onCreateJob,
        isLoading: isCreatingJob,
        paymentMethod,
        isDisabled,
        handleSubmit,
        isLoadingAddressForm,
        setPaymentMethod,
        isNewCardModalOpen,
        setIsNewCardModalOpen
    } = useCreateJob(submit, ScreenNames.MoversAndPackersDetails, route.params.postalcode, route.params.price, true)
    const onRequestPromotion = (code: string) => {
        requestPromotion(code, {
            ...jobDetails,
            is_recurring: false,
            date_time: jobDetails.booking.movingDate,
            supply: config._id
        })
    }
    const jobDetails = moversAndPackersHooks.useMoversAndPackersJobDetails({
        formReturn,
        paymentMethod,
        promotionPackage,
        routeParams: route.params
    })

    useEffect(() => {
        if (segmentSession.id) {
            Segment.bookingSummary({
                bookingSessionId: segmentSession.id,
                postcode: route.params?.postalcode as string,
                amount: route.params?.price as number,
                serviceType: ServiceType.MoversAndPackers
            })
        }
    }, [segmentSession])

    const isLoading = isLoadingPromotion || isCreatingJob || isLoadingAddressForm || isCreatingMoversAndPackers

    if (paymentView) {
        return (
            <BookingPaymentView
                disabled={isDisabled}
                submit={handleSubmit}
                isLoading={isLoading}
                totalPrice={promotionPackage?.total_cost || route.params?.price}
                onRemovePromoCode={removePromoCode}
                promotionPackage={promotionPackage}
                setPaymentView={setPaymentView}
                service={T.screens.moversAndPackers.title}
                setPaymentMethod={setPaymentMethod}
                selectedPaymentMethod={paymentMethod}
                discount={promotionPackage?.promotion.percent || promotionPackage?.promotion.package.credit}
                promotionType={promotionPackage?.promotion.promo_type}
                requestPromotion={onRequestPromotion}
                promoCode={form.promoCode}
            />
        )
    }

    return (
        <React.Fragment>
            <PaymentNewCardModal
                isOpen={isNewCardModalOpen}
                onClose={() => setIsNewCardModalOpen(false)}
            />
            <MoversAndPackersComponents.MoversAndPackersServiceDetailsView
                submit={handleSubmit}
                disabled={continueDisabled}
                setPaymentMethod={setPaymentMethod}
                onUploadSuccess={data => {
                    setIsUploadingPhotos(false)
                    setUploadedPhotos(prevState => [...prevState, ...data])
                }}
                setPaymentView={setPaymentView}
                selectedPaymentMethod={paymentMethod}
                onPhotoRemove={uuid => setUploadedPhotos(prevState => prevState.filter(prevFile => uuid !== prevFile.fileUUID))}
                isUploadingPhotos={isUploadingPhotos}
                setIsUploadingPhotos={setIsUploadingPhotos}
                isLoading={isLoading}
                totalPrice={promotionPackage?.total_cost || route.params?.price}
                promotionPackage={promotionPackage}
                onRemovePromoCode={removePromoCode}
                formShape={route.params.form}
                serviceDetailsForm={formReturn}
                requestPromotion={onRequestPromotion}
            />
        </React.Fragment>
    )
}
