import React, { useEffect } from 'react'
import { View } from 'react-native'
import { Segment } from 'lib/analytics'
import { createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { Adapter, BasicModal, Button, Typography } from 'lib/components'
import { useAddNewCreditCard } from '../hooks'

type PaymentNewCardModalProps = {
    isOpen: boolean,
    onClose: VoidFunction,
    setIsLoading?: (value: boolean) => void
}

export const PaymentNewCardModal: React.FunctionComponent<PaymentNewCardModalProps> = ({
    isOpen,
    setIsLoading,
    onClose
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const { submit, isFilled, isLoading, form, hasError } = useAddNewCreditCard(onClose)

    useEffect(() => {
        if (setIsLoading) {
            setIsLoading(isLoading)
        }
    }, [isLoading])

    return (
        <BasicModal
            isOpen={isOpen}
            onClose={() => {
                Segment.creditCardModuleClosed({})

                onClose()
            }}
        >
            <View style={styles.container}>
                <View style={styles.title}>
                    <Typography.SmallSubheading>
                        {T.screens.paymentAndCards.addCard}
                    </Typography.SmallSubheading>
                </View>
                <View style={styles.formContainer}>
                    <Adapter.TextInputWithLabel
                        {...form.nameOnCard}
                        disabled={isLoading}
                        testID="name-on-card"
                        onBlur={() => {
                            if (form.nameOnCard.value) {
                                Segment.creditCardNameEntered({})
                            }
                        }}
                    />
                    <Adapter.TextInputWithLabel
                        {...form.cardNumber}
                        disabled={isLoading}
                        inputProps={{
                            keyboardType: 'number-pad',
                            contextMenuHidden: true
                        }}
                        testID="card-number"
                        onBlur={() => {
                            if (form.cardNumber.value) {
                                Segment.creditCardNumberEntered({})
                            }
                        }}
                    />
                    <View style={styles.row}>
                        <View style={styles.expiry}>
                            <Adapter.TextInputWithLabel
                                {...form.month}
                                disabled={isLoading}
                                inputProps={{
                                    keyboardType: 'number-pad',
                                    contextMenuHidden: true
                                }}
                                testID="exp-month"
                                onBlur={() => {
                                    if (form.month.value) {
                                        Segment.creditCardDateEntered({})
                                    }
                                }}
                            />
                        </View>
                        <View style={styles.flex}>
                            <Adapter.TextInput
                                {...form.year}
                                disabled={isLoading}
                                inputProps={{
                                    keyboardType: 'number-pad',
                                    contextMenuHidden: true
                                }}
                                testID="exp-year"
                                onBlur={() => {
                                    if (form.year.value) {
                                        Segment.creditCardDateEntered({})
                                    }
                                }}
                            />
                        </View>
                        <View style={styles.ccv}>
                            <Adapter.TextInputWithLabel
                                {...form.cvv}
                                smallLabel
                                disabled={isLoading}
                                inputProps={{
                                    keyboardType: 'number-pad',
                                    contextMenuHidden: true,
                                    secureTextEntry: true
                                }}
                                testID="cvv"
                                onBlur={() => {
                                    if (form.cvv.value) {
                                        Segment.creditCardCVVEntered({})
                                    }
                                }}
                            />
                        </View>
                    </View>
                    <View style={styles.buttons}>
                        <View style={styles.button}>
                            <Button
                                noBackground
                                onPress={() => {
                                    Segment.creditCardCancelClicked({})

                                    onClose()
                                }}
                                disabled={isLoading}
                                text={T.common.cancel}
                            />
                        </View>
                        <View style={styles.buttonSeparator} />
                        <View style={styles.button}>
                            <Button
                                onPress={() => {
                                    submit()
                                    onClose()
                                }}
                                text={T.common.continue}
                                isLoading={isLoading}
                                disabled={!isFilled || hasError}
                                testID="save-credit-card-button"
                            />
                        </View>
                    </View>
                </View>
            </View>
        </BasicModal>
    )
}

const stylesheet = createStyles(theme => ({
    flex: {
        flex: {
            lg: 2,
            xs: undefined
        }
    },
    expiry: {
        flex: {
            lg: 4,
            xs: undefined
        },
        marginRight: {
            lg: theme.utils.gap(1),
            xs: 0
        }
    },
    ccv: {
        flex: {
            lg: 3,
            xs: undefined
        },
        marginLeft: {
            lg: theme.utils.gap(1),
            xs: 0
        }
    },
    container: {
        flexDirection: {
            lg: 'row',
            xs: 'column'
        },
        paddingHorizontal: {
            lg: theme.utils.gap(3),
            xs: 0
        },
        paddingTop: theme.utils.gap(2)
    },
    title: {
        width: {
            lg: 200,
            xs: undefined
        }
    },
    formContainer: {
        width: {
            lg: 550,
            xs: undefined
        },
        marginTop: {
            lg: theme.utils.gap(4),
            xs: theme.utils.gap(2)
        }
    },
    row: {
        flexDirection: {
            lg: 'row',
            xs: 'column'
        }
    },
    buttons: {
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginBottom: theme.utils.gap(3)
    },
    buttonSeparator: {
        width: theme.utils.gap(2)
    },
    button: {
        flex: {
            lg: undefined,
            xs: 1
        },
        width: {
            lg: 200,
            xs: undefined
        }
    }
}))
