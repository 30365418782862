import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const Burger: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path d="M0 4.444A4.444 4.444 0 0 1 4.444 0h71.112a4.444 4.444 0 0 1 0 8.889H4.444A4.444 4.444 0 0 1 0 4.444Zm0 26.667a4.444 4.444 0 0 1 4.444-4.444H40a4.444 4.444 0 1 1 0 8.889H4.444A4.444 4.444 0 0 1 0 31.11Zm0 26.667a4.444 4.444 0 0 1 4.444-4.445h71.112a4.444 4.444 0 0 1 0 8.89H4.444A4.444 4.444 0 0 1 0 57.777Z" />
    </Icon>
)
