import { useField } from '@codegateinc/react-form-builder-v2'
import { useTranslations } from 'lib/hooks'
import { Address } from 'lib/models'
import { BookingFrequency, FrequencyOption, Nullable, NewAddressRequestShape, SupplyID } from 'lib/types'
import { dateHelpers } from 'lib/utils'
import { getServiceStaticConfig } from 'features/bookings/utils'
import { useBookingDays, useFrequencyOptions } from '../hooks'
import { CommercialPropertyType, HomePropertyType } from '../types'
import { MoversAndPackersFields } from './forms'

export const useMoversAndPackersForm = () => {
    const T = useTranslations()
    const frequencyOptions = useFrequencyOptions()
    const bookingDaysInitialValue = useBookingDays()
    const config = getServiceStaticConfig(SupplyID.MoversPackers)

    const frequency = useField<Nullable<FrequencyOption>>({
        key: MoversAndPackersFields.Frequency,
        initialValue: frequencyOptions.find(option => option.value === BookingFrequency.OneTime) || null,
        isRequired: false,
        validateOnBlur: false,
        label: T.screens.moversAndPackers.formFields.frequency.label
    })

    const startingDate = useField<string>({
        key: MoversAndPackersFields.StartingDate,
        initialValue: '',
        isRequired: true,
        validateOnBlur: false,
        label: T.screens.moversAndPackers.formFields.startingDate.label,
        placeholder: T.screens.moversAndPackers.formFields.startingDate.placeholder,
        validationRules: [
            {
                errorMessage: T.screens.moversAndPackers.formFields.startingDate.validation.isRequired,
                validate: Boolean
            }
        ]
    })

    const bookingTime = useField<string>({
        key: MoversAndPackersFields.BookingTime,
        initialValue: '',
        validateOnBlur: true,
        isRequired: frequency.value?.value === BookingFrequency.OneTime,
        placeholder: T.screens.moversAndPackers.formFields.bookingTime.placeHolder,
        validationRules: [
            {
                errorMessage: T.screens.moversAndPackers.formFields.bookingTime.validation.isRequired,
                validate: value => {
                    if (frequency.value?.value !== BookingFrequency.OneTime) {
                        return true
                    }

                    return Boolean(value)
                }
            },
            {
                errorMessage: `${config.min_notice_hours} ${T.screens.moversAndPackers.formFields.bookingTime.validation.minimumHourNotice}`,
                validate: value => {
                    if (frequency.value?.value !== BookingFrequency.OneTime) {
                        return true
                    }

                    const isPast = dateHelpers.isDatePast(dateHelpers.subHoursFromDate(value, config.min_notice_hours))

                    return !isPast
                }
            }
        ]
    })

    const bookingDays = useField<Record<string, Nullable<string>>>({
        key: MoversAndPackersFields.BookingDays,
        initialValue: bookingDaysInitialValue,
        validateOnBlur: true,
        isRequired: frequency.value?.value !== BookingFrequency.OneTime,
        placeholder: T.screens.moversAndPackers.formFields.bookingDays.placeHolder,
        validationRules: [
            {
                errorMessage: T.screens.moversAndPackers.formFields.bookingDays.validation.isRequired,
                validate: value => {
                    if (frequency.value?.value === BookingFrequency.OneTime) {
                        return true
                    }

                    return Object.values(value).some(Boolean)
                }
            },
            {
                errorMessage: `${config.min_notice_hours} ${T.screens.moversAndPackers.formFields.bookingDays.validation.minimumHourNotice}`,
                validate: value => {
                    const times = Object.values(value).filter(item => Boolean(item)) as Array<string>
                    const isSomePast = times.some(time => dateHelpers.isDatePast(dateHelpers.subHoursFromDate(time, config.min_notice_hours)))

                    return !isSomePast
                }
            }
        ]
    })

    const serviceType = useField<Nullable<string>>({
        key: MoversAndPackersFields.ServiceType,
        initialValue: null,
        validateOnBlur: false,
        isRequired: true,
        label: T.screens.moversAndPackers.formFields.serviceType.label,
        validationRules: [
            {
                errorMessage: T.components.formsCommon.isRequired,
                validate: Boolean
            }
        ]
    })

    const pickUpLocation = useField<Nullable<Address | NewAddressRequestShape>>({
        key: MoversAndPackersFields.PickUpLocation,
        initialValue: null,
        validateOnBlur: false,
        isRequired: true,
        label: T.screens.moversAndPackers.formFields.pickUpLocation.label,
        placeholder: T.screens.moversAndPackers.formFields.pickUpLocation.placeholder,
        validationRules: [
            {
                errorMessage: T.components.formsCommon.isRequired,
                validate: Boolean
            }
        ]
    })

    const dropOffLocation = useField<Nullable<Address | NewAddressRequestShape>>({
        key: MoversAndPackersFields.DropOffLocation,
        initialValue: null,
        validateOnBlur: false,
        isRequired: true,
        label: T.screens.moversAndPackers.formFields.dropOffLocation.label,
        placeholder: T.screens.moversAndPackers.formFields.dropOffLocation.placeholder,
        validationRules: [
            {
                errorMessage: T.components.formsCommon.isRequired,
                validate: Boolean
            }
        ]
    })

    const pickUpPropertyType = useField<Nullable<HomePropertyType | CommercialPropertyType>>({
        key: MoversAndPackersFields.PickUpPropertyType,
        initialValue: null,
        validateOnBlur: true,
        isRequired: true,
        label: T.screens.moversAndPackers.formFields.propertyType.label,
        placeholder: T.common.dropDownSelect,
        validationRules: [
            {
                errorMessage: T.components.formsCommon.isRequired,
                validate: Boolean
            }
        ]
    })

    const dropOffPropertyType = useField<Nullable<HomePropertyType | CommercialPropertyType>>({
        key: MoversAndPackersFields.DropOffPropertyType,
        initialValue: null,
        validateOnBlur: true,
        isRequired: true,
        label: T.screens.moversAndPackers.formFields.propertyType.label,
        placeholder: T.common.dropDownSelect,
        validationRules: [
            {
                errorMessage: T.components.formsCommon.isRequired,
                validate: Boolean
            }
        ]
    })

    const pickUpNumberOfBedrooms = useField<Nullable<number>>({
        key: MoversAndPackersFields.PickUpNumberOfBedrooms,
        initialValue: null,
        validateOnBlur: true,
        isRequired: false,
        label: T.screens.moversAndPackers.formFields.numberOfBedrooms.label,
        placeholder: T.common.dropDownSelect,
        validationRules: [
            {
                errorMessage: T.components.formsCommon.isRequired,
                validate: value => {
                    if (pickUpPropertyType.value === HomePropertyType.Other) {
                        return true
                    }

                    return Boolean(value) || Boolean(pickUpPropertySize.value)
                }
            }
        ]
    })

    const dropOffNumberOfBedrooms = useField<Nullable<number>>({
        key: MoversAndPackersFields.DropOffNumberOfBedrooms,
        initialValue: null,
        validateOnBlur: true,
        isRequired: false,
        label: T.screens.moversAndPackers.formFields.numberOfBedrooms.label,
        placeholder: T.common.dropDownSelect,
        validationRules: [
            {
                errorMessage: T.components.formsCommon.isRequired,
                validate: value => {
                    if (dropOffPropertyType.value === HomePropertyType.Other) {
                        return true
                    }

                    return Boolean(value) || Boolean(dropOffPropertySize.value)
                }
            }
        ]
    })

    const pickUpPropertySize = useField<Nullable<string>>({
        key: MoversAndPackersFields.PickUpPropertySize,
        initialValue: null,
        validateOnBlur: true,
        isRequired: false,
        label: T.screens.moversAndPackers.formFields.propertySize.label,
        placeholder: T.common.dropDownSelect,
        validationRules: [
            {
                errorMessage: T.components.formsCommon.isRequired,
                validate: value => {
                    if (pickUpPropertyType.value === CommercialPropertyType.Other) {
                        return true
                    }

                    return Boolean(value) || Boolean(pickUpNumberOfBedrooms.value)
                }
            }
        ]
    })

    const dropOffPropertySize = useField<Nullable<string>>({
        key: MoversAndPackersFields.DropOffPropertySize,
        initialValue: null,
        validateOnBlur: true,
        isRequired: false,
        label: T.screens.moversAndPackers.formFields.propertySize.label,
        placeholder: T.common.dropDownSelect,
        validationRules: [
            {
                errorMessage: T.components.formsCommon.isRequired,
                validate: value => {
                    if (dropOffPropertyType.value === CommercialPropertyType.Other) {
                        return true
                    }

                    return Boolean(value) || Boolean(dropOffNumberOfBedrooms.value)
                }
            }
        ]
    })

    const pickUpFloor = useField<Nullable<number>>({
        key: MoversAndPackersFields.PickUpFloor,
        initialValue: null,
        validateOnBlur: true,
        isRequired: false,
        label: T.screens.moversAndPackers.formFields.floor.label,
        placeholder: T.common.dropDownSelect,
        validationRules: [
            {
                errorMessage: T.components.formsCommon.isRequired,
                validate: value => {
                    if (pickUpPropertyType.value === CommercialPropertyType.Other) {
                        return true
                    }

                    return Boolean(value)
                }
            }
        ]
    })

    const dropOffFloor = useField<Nullable<number>>({
        key: MoversAndPackersFields.DropOffFloor,
        initialValue: null,
        validateOnBlur: true,
        isRequired: false,
        label: T.screens.moversAndPackers.formFields.floor.label,
        placeholder: T.common.dropDownSelect,
        validationRules: [
            {
                errorMessage: T.components.formsCommon.isRequired,
                validate: value => {
                    if (dropOffPropertyType.value === CommercialPropertyType.Other) {
                        return true
                    }

                    return Boolean(value)
                }
            }
        ]
    })

    const pickUpHasElevator = useField<boolean>({
        key: MoversAndPackersFields.PickUpHasElevator,
        initialValue: false,
        isRequired: false,
        label: `${T.screens.moversAndPackers.formFields.hasElevator.label}*`
    })

    const dropOffHasElevator = useField<boolean>({
        key: MoversAndPackersFields.DropOffHasElevator,
        initialValue: false,
        isRequired: false,
        label: `${T.screens.moversAndPackers.formFields.hasElevator.label}*`
    })

    const pickupDetails = useField<Nullable<string>>({
        key: MoversAndPackersFields.PickUpDetails,
        initialValue: '',
        validateOnBlur: false,
        isRequired: false,
        placeholder: T.screens.moversAndPackers.formFields.details.placeholder,
        validationRules: [
            {
                errorMessage: T.screens.moversAndPackers.formFields.details.validation.maxLength,
                validate: value => {
                    if (value) {
                        return value.length <= 500
                    }

                    return true
                }
            }
        ]
    })

    const dropOffDetails = useField<Nullable<string>>({
        key: MoversAndPackersFields.DropOffDetails,
        initialValue: '',
        validateOnBlur: false,
        isRequired: false,
        placeholder: T.screens.moversAndPackers.formFields.details.placeholder,
        validationRules: [
            {
                errorMessage: T.screens.moversAndPackers.formFields.details.validation.maxLength,
                validate: value => {
                    if (value) {
                        return value.length <= 500
                    }

                    return true
                }
            }
        ]
    })

    return {
        frequency,
        startingDate,
        bookingTime,
        bookingDays,
        serviceType,
        pickUpLocation,
        dropOffLocation,
        pickUpPropertyType,
        dropOffPropertyType,
        pickUpNumberOfBedrooms,
        dropOffNumberOfBedrooms,
        pickUpPropertySize,
        dropOffPropertySize,
        pickUpFloor,
        dropOffFloor,
        pickUpHasElevator,
        dropOffHasElevator,
        pickupDetails,
        dropOffDetails
    }
}
