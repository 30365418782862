import React from 'react'
import { View } from 'react-native'
import { Icons } from 'assets'
import { createStyles } from 'lib/styles'
import { Typography } from 'lib/components'
import { useStyles } from 'lib/hooks'
import { JobDetailsResponse } from '../types'

type RenewalMessageProps = {
    response: JobDetailsResponse,
}

export const RenewalMessage: React.FunctionComponent<RenewalMessageProps> = ({
    response
}) => {
    const { styles, theme } = useStyles(stylesheet)
    const message = response?.renewalPolicy?.nextRenewal || ''

    return message ?(
        <View style={styles.wrapper}>
            <View style={styles.icon}>
                <Icons.Info size={18} forceColor={theme.colors.strawberry} />
            </View>
            <Typography.Regular forceColor={theme.colors.strawberry} style={styles.text}>
                {message}
            </Typography.Regular>
        </View>
    ) : null
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        marginVertical: theme.utils.gap(1),
        borderWidth: 1,
        borderColor: theme.colors.apricot,
        flexDirection: 'row',
        padding: theme.utils.gap(1),
        borderRadius: 4,
        backgroundColor: theme.colors.linen,
        justifyContent: 'center'
    },
    text: {
        marginLeft: theme.utils.gap(1)
    },
    icon: {
        minWidth: 18,
        minHeight: 18
    }
}))
