import { useField } from '@codegateinc/react-form-builder-v2'
import { MOCKED_COOKING_HOURS } from 'mocks'
import { bookingHelpers, dateHelpers } from 'lib/utils'
import { useTranslations } from 'lib/hooks'
import { BookingFrequency, FrequencyOption, Nullable, SupplyID } from 'lib/types'
import { getServiceStaticConfig } from 'features/bookings/utils'
import { CookingFields } from './forms'
import { useBookingDays } from '../hooks'

export const useCookingForm = () => {
    const T = useTranslations()
    const bookingDaysInitialValue = useBookingDays()
    const [,, initialHours] = MOCKED_COOKING_HOURS
    const config = getServiceStaticConfig(SupplyID.Cooking)

    const frequency = useField<Nullable<FrequencyOption>>({
        key: CookingFields.Frequency,
        initialValue: null,
        isRequired: true,
        validateOnBlur: true,
        label: T.screens.cooking.formFields.frequency.label,
        validationRules: [
            {
                errorMessage: T.screens.cooking.formFields.hours.validation.isRequired,
                validate: Boolean
            }
        ]
    })

    const startingDate = useField<string>({
        key: CookingFields.StartingDate,
        initialValue: '',
        isRequired: true,
        validateOnBlur: false,
        label: T.screens.cooking.formFields.startingDate.label,
        placeholder: T.screens.cooking.formFields.startingDate.placeholder,
        validationRules: [
            {
                errorMessage: T.screens.cooking.formFields.startingDate.validation.isRequired,
                validate: Boolean
            }
        ]
    })

    const bookingTime = useField<string>({
        key: CookingFields.BookingTime,
        initialValue: '',
        validateOnBlur: true,
        isRequired: frequency.value?.value === BookingFrequency.OneTime,
        placeholder: T.screens.cooking.formFields.selectTime.placeHolder,
        validationRules: [
            {
                errorMessage: T.screens.cooking.formFields.selectTime.validation.isRequired,
                validate: value => {
                    if (frequency.value?.value !== BookingFrequency.OneTime) {
                        return true
                    }

                    return Boolean(value)
                }
            },
            {
                errorMessage: `${config.min_notice_hours} ${T.screens.cooking.formFields.selectTime.validation.minimumHourNotice}`,
                validate: value => {
                    if (frequency.value?.value !== BookingFrequency.OneTime) {
                        return true
                    }

                    const isPast = dateHelpers.isDatePast(dateHelpers.subHoursFromDate(value, config.min_notice_hours))

                    return !isPast
                }
            }
        ]
    })

    const bookingDays = useField<Record<string, Nullable<string>>>({
        key: CookingFields.BookingDays,
        initialValue: bookingDaysInitialValue,
        validateOnBlur: true,
        isRequired: frequency.value?.value !== BookingFrequency.OneTime,
        placeholder: T.screens.cooking.formFields.selectTime.placeHolder,
        validationRules: [
            {
                errorMessage: T.screens.cooking.formFields.selectTime.validation.isRequired,
                validate: value => {
                    if (frequency.value?.value === BookingFrequency.OneTime) {
                        return true
                    }

                    return Object.values(value).some(Boolean)
                }
            },
            {
                errorMessage: `${config.min_notice_hours} ${T.screens.cooking.formFields.selectTime.validation.minimumHourNotice}`,
                validate: () => {
                    const shiftedDays = bookingHelpers.shiftBookingDays(bookingDays.value, config.min_notice_hours, startingDate.value)
                    const times = Object.values(shiftedDays).filter(item => Boolean(item)) as Array<string>
                    const isSomePast = times.some(time => dateHelpers.isDatePast(dateHelpers.subHoursFromDate(time, config.min_notice_hours)))

                    return !isSomePast
                }
            }
        ]
    })

    const propertyType = useField<string>({
        key: CookingFields.PropertyType,
        initialValue: '',
        isRequired: true,
        validateOnBlur: true,
        placeholder: T.screens.cooking.formFields.propertyType.placeholder,
        validationRules: [
            {
                errorMessage: T.screens.cooking.formFields.propertyType.validation.isRequired,
                validate: Boolean
            }
        ]
    })

    const appliances = useField<string>({
        key: CookingFields.Appliances,
        initialValue: '',
        isRequired: true,
        validateOnBlur: true,
        placeholder: T.screens.cooking.formFields.appliances.placeholder,
        validationRules: [
            {
                errorMessage: T.screens.cooking.formFields.appliances.validation.isRequired,
                validate: Boolean
            }
        ]
    })

    const hours = useField<string>({
        key: CookingFields.Hours,
        initialValue: initialHours.value,
        isRequired: true,
        validateOnBlur: true,
        label: T.screens.cooking.formFields.hours.label,
        validationRules: [
            {
                errorMessage: T.screens.cooking.formFields.hours.validation.isRequired,
                validate: Boolean
            }
        ]
    })

    const cuisines = useField<string>({
        key: CookingFields.Cuisines,
        initialValue: '',
        isRequired: true,
        validateOnBlur: true,
        placeholder: T.screens.cooking.formFields.cuisines.placeholder,
        label: T.screens.cooking.formFields.cuisines.label,
        validationRules: [
            {
                errorMessage: T.screens.cooking.formFields.cuisines.validation.isRequired,
                validate: Boolean
            }
        ]
    })

    const comment = useField<string>({
        key: CookingFields.Comment,
        initialValue: '',
        isRequired: false,
        validateOnBlur: false,
        placeholder: T.screens.cooking.formFields.comment.placeholder
    })

    return {
        frequency,
        startingDate,
        bookingTime,
        bookingDays,
        propertyType,
        hours,
        cuisines,
        appliances,
        comment
    }
}
