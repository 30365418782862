import { useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { SegmentSession } from 'lib/types'
import { useSegmentSessionAtom } from 'lib/atoms'
import { dateHelpers } from 'lib/utils'

export const useSegmentSession = () => {
    const [segmentSession, setSegmentSession] = useSegmentSessionAtom()

    useEffect(() => {
        if (!segmentSession.id) {
            return setSegmentSession({
                id: uuidv4(),
                timestamp: new Date().toISOString()
            })
        }

        const isStale = dateHelpers.differenceInMinutesToNow(segmentSession.timestamp) > 59

        if (isStale) {
            return setSegmentSession({
                id: uuidv4(),
                timestamp: new Date().toISOString()
            })
        }
    }, [])

    const clearSession = () => {
        setSegmentSession({} as SegmentSession)
    }

    return {
        segmentSession,
        clearSession
    }
}
