import { useField } from '@codegateinc/react-form-builder-v2'
import { v4 as uuidv4 } from 'uuid'
import { regexes } from 'lib/utils'
import { useTranslations } from 'lib/hooks'
import { ProviderDetails } from 'lib/models'
import { Country } from 'lib/types'
import { DEFAULT_COUNTRY } from 'lib/data'
import { SignUpFields } from './forms'

type SignUpFormConfig = {
    initialData?: {
        email?: string,
        name?: string
    }
}

export const useSignUpForm = (providerDetails: ProviderDetails, config: SignUpFormConfig = {}) => {
    const T = useTranslations()
    const randomPassword = `${uuidv4()}Sendhelper${new Date().getTime()}`
    const name = useField<string>({
        key: SignUpFields.Name,
        initialValue: config.initialData?.name?.trim() || '',
        isRequired: true,
        validateOnBlur: true,
        placeholder: T.screens.auth.formFields.name.placeholder,
        label: T.screens.auth.formFields.name.label,
        validationRules: [
            {
                errorMessage: T.screens.auth.formFields.name.validation.isRequired,
                validate: Boolean
            },
            {
                errorMessage: T.screens.auth.formFields.name.validation.minValue,
                validate: value => value.length >= 3
            },
            {
                errorMessage: T.screens.auth.formFields.name.validation.maxValue,
                validate: value => value.length <= 30
            }
        ],
        submitParser: value => value
            .split(' ')
            .filter(Boolean)
            .join(' ')
    })

    const country = useField<Country>({
        key: SignUpFields.Country,
        initialValue: DEFAULT_COUNTRY,
        isRequired: true
    })

    const phoneNumber = useField<string>({
        key: SignUpFields.PhoneNumber,
        initialValue: '',
        isRequired: true,
        validateOnBlur: true,
        placeholder: T.screens.auth.formFields.phoneNumber.placeholder,
        label: T.screens.auth.formFields.phoneNumber.label,
        validationRules: [
            {
                errorMessage: T.screens.auth.formFields.phoneNumber.validation.isRequired,
                validate: Boolean
            },
            {
                errorMessage: T.screens.auth.formFields.phoneNumber.validation.tooShort,
                validate: ({ length }) => length >= 5
            },
            {
                errorMessage: T.screens.auth.formFields.phoneNumber.validation.tooLong,
                validate: ({ length }) => length <= 16
            }
        ],
        liveParser: value => {
            if (!value) {
                return value
            }

            if (!value.slice(-1).match(/\d/) || value.length > 16) {
                return value.slice(0, -1)
            }

            return value
        }
    })

    const email = useField<string>({
        key: SignUpFields.Email,
        initialValue: config.initialData?.email || '',
        isRequired: true,
        validateOnBlur: true,
        placeholder: T.screens.auth.formFields.email.placeholder,
        label: T.screens.auth.formFields.email.label,
        validationRules: [
            {
                errorMessage: T.screens.auth.formFields.email.validation.isRequired,
                validate: Boolean
            },
            {
                errorMessage: T.screens.auth.formFields.email.validation.isInvalid,
                validate: regexes.isValidEmail
            },
            {
                errorMessage: T.screens.auth.formFields.email.validation.minValue,
                validate: value => value.length >= 5
            },
            {
                errorMessage: T.screens.auth.formFields.email.validation.maxValue,
                validate: value => value.length <= 40
            }
        ],
        submitParser: value => value.trim()
    })

    const password = useField<string>({
        key: SignUpFields.Password,
        initialValue: providerDetails !== ProviderDetails.Email ? randomPassword : '',
        isRequired: providerDetails === ProviderDetails.Email,
        validateOnBlur: true,
        placeholder: T.screens.auth.formFields.password.placeholder,
        label: T.screens.auth.formFields.password.label,
        validationRules: [
            {
                errorMessage: T.screens.auth.formFields.password.validation.isTooShort,
                validate: password => !password || password.length >= 10
            },
            {
                errorMessage: T.screens.auth.formFields.password.validation.missingUpperCase,
                validate: password => /[A-Z]/.test(password)
            },
            {
                errorMessage: T.screens.auth.formFields.password.validation.missingLowerCase,
                validate: password => /[a-z]/.test(password)
            },
            {
                errorMessage: T.screens.auth.formFields.password.validation.missingNumber,
                validate: password => /\d/.test(password)
            },
            {
                errorMessage: T.screens.auth.formFields.password.validation.missingSpecialChar,
                validate: password => /[^A-Za-z0-9]/.test(password) // This regex matches any character that is NOT alphanumeric
            }
        ],
        submitParser: value => value.trim()
    })

    const confirmPassword = useField<string>({
        key: SignUpFields.ConfirmPassword,
        initialValue: providerDetails !== ProviderDetails.Email ? randomPassword : '',
        isRequired: providerDetails === ProviderDetails.Email,
        validateOnBlur: true,
        placeholder: T.screens.auth.formFields.confirmPassword.placeholder,
        label: T.screens.auth.formFields.confirmPassword.label,
        validationRules: [
            {
                errorMessage: T.screens.auth.formFields.confirmPassword.validation.doesntMatch,
                validate: confirmPassword => confirmPassword === password.value
            }
        ],
        submitParser: value => value.trim()
    })

    const marketingConsent = useField<undefined | boolean>({
        key: SignUpFields.MarketingConsent,
        initialValue: undefined,
        isRequired: false
    })

    return {
        name,
        country,
        phoneNumber,
        email,
        password,
        confirmPassword,
        marketingConsent
    }
}
