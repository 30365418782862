import React, { useState } from 'react'
import { isNative } from 'lib/common'
import { useTranslations } from 'lib/hooks'
import { Field, UseFormReturn } from '@codegateinc/react-form-builder-v2'
import { NewAddressShape } from 'features/user'
import { PaymentType } from 'lib/models'
import { UploadedPhoto } from 'lib/components'
import { useIsAuthorizedAtom, useUserAtom } from 'lib/atoms'
import { Nullable, SelectInputOption, SupplyID, VoidFunction } from 'lib/types'
import {
    MoversAndPackersFormShape,
    MoversAndPackersAddonsShape,
    MoversAndPackersServiceDetailsShape
} from '../../forms'
import { moversAndPackersHooks } from '../../hooks'
import { GetPromotionResponse } from '../../types'
import { ServiceLayout } from '../ServiceLayout'
import { MoversAndPackersSummaryContent } from './MoversAndPackersSummaryContent'
import { MoversAndPackersSummaryFooter } from './MoversAndPackersSummaryFooter'
import { MoversAndPackersSummaryColumnView } from './MoversAndPackersSummaryColumnView'
import { ServiceDescription } from '../ServiceDescription'
import { MoversAndPackersHelpModalContent } from './MoversAndPackersHelpModalContent'
import { BookingAuth } from '../unauthorized'
import { DoubleAddress } from './DoubleAddress'

type MoversAndPackersSummaryViewProps = {
    disabled: boolean,
    selectedPaymentMethod: PaymentType,
    setPaymentView: (value: boolean) => void,
    setPaymentMethod: (value: PaymentType) => void,
    formReturn: UseFormReturn<MoversAndPackersAddonsShape>,
    onUploadSuccess: (data: Array<UploadedPhoto>) => void,
    addressForm: Record<keyof NewAddressShape, Field<SelectInputOption & string>>,
    formShape: MoversAndPackersFormShape & MoversAndPackersServiceDetailsShape,
    isLoading: boolean,
    totalPrice: number,
    submit: VoidFunction,
    promotionPackage: Nullable<GetPromotionResponse>,
    originalPrice?: Nullable<number>,
    requestPromotion: (code: string) => void,
    onRemovePromoCode: VoidFunction,
    fetchPostalCodes(value: string): Promise<Array<SelectInputOption>>
}

export const MoversAndPackersSummaryView: React.FunctionComponent<MoversAndPackersSummaryViewProps> = props => {
    const T = useTranslations()
    const [isHelpModalOpen, setHelpModalOpen] = useState(false)
    const [user] = useUserAtom()
    const {
        submit,
        disabled,
        selectedPaymentMethod,
        totalPrice,
        setPaymentMethod,
        isLoading,
        onUploadSuccess,
        formShape
    } = props
    const breadcrumbs = moversAndPackersHooks.useMoversAndPackersBreadcrumbs()
    const [isAuthorized] = useIsAuthorizedAtom()
    const [authenticationView, setAuthenticationView] = useState(false)
    const notEnoughCredits = selectedPaymentMethod === PaymentType.Credits && user.consumer.balance_credit < totalPrice
    const confirmDisabled = isAuthorized
        ? disabled
        : false
    const handleConfirm = () => {
        if (!isAuthorized) {
            return setAuthenticationView(true)
        }

        submit()
    }

    return (
        <ServiceLayout
            withBackground
            breadcrumbs={breadcrumbs}
            authenticationView={authenticationView}
            onBackFromDetails={() => {
                setAuthenticationView(false)
            }}
            contentColumn={(authenticationView
                ? (
                    <BookingAuth
                        isMoversAndPackers
                        supplyId={SupplyID.MoversPackers}
                        onUploadSuccess={onUploadSuccess}
                        notEnoughCredits={notEnoughCredits}
                        selectedPaymentMethod={selectedPaymentMethod}
                        setPaymentMethod={setPaymentMethod}
                        renderExtraContent={() => (
                            <DoubleAddress
                                disabled={isLoading}
                                pickUpAddress={formShape.pickUpLocation}
                                dropOffAddress={formShape.dropOffLocation}
                            />
                        )}
                    />
                ) : (
                    <React.Fragment>
                        {isNative && (
                            <ServiceDescription
                                isHelpModalOpen={isHelpModalOpen}
                                setHelpModalOpen={setHelpModalOpen}
                                title={T.screens.pestControl.title}
                                modalContent={(
                                    <MoversAndPackersHelpModalContent />
                                )}
                                description={T.screens.pestControl.serviceDescription}
                            />
                        )}
                        <MoversAndPackersSummaryContent {...props}/>
                    </React.Fragment>
                )
            )}
            summaryColumn={(
                <MoversAndPackersSummaryColumnView
                    {...props}
                    authenticationView={authenticationView}
                    disabled={confirmDisabled}
                    submit={handleConfirm}
                />
            )}
            footer={(
                <MoversAndPackersSummaryFooter
                    {...props}
                    authenticationView={authenticationView}
                    disabled={confirmDisabled}
                    submit={handleConfirm}
                />
            )}
        />
    )
}
