import { useMutation } from '@tanstack/react-query'
import { MutationKey, useFetcher, useExternalFetcher } from 'lib/api'
import { ErrorResponse, HttpMethod, Response } from 'lib/types'
import { APP_CONFIG } from 'lib/config'
import {
    DeleteAccountRequest,
    DeleteAccountResponse,
    EditProfileRequest,
    EditProfileResponse,
    FindAddressOnPostCodeRequest,
    FindAddressOnPostCodeResponse,
    FindAddressByOneMapResponse,
    NewAddressRequest,
    NewAddressResponse,
    RemoveAddressRequest,
    RemoveAddressResponse,
    UpdateNotificationSettingsRequest,
    UpdateNotificationSettingsResponse,
    UpdatePromotionalNotificationsRequest,
    UpdatePromotionalNotificationsResponse
} from './types'

export const deleteAccount = () => {
    const fetcher = useFetcher<DeleteAccountResponse>(HttpMethod.POST, '/user-account-delete')

    return useMutation<Response<DeleteAccountResponse>, ErrorResponse, DeleteAccountRequest>(fetcher)
}

export const updateNotificationSettings = () => {
    const fetcher = useFetcher<UpdateNotificationSettingsResponse>(HttpMethod.POST, '/user/update-notification-settings')

    return useMutation<Response<UpdateNotificationSettingsResponse>, ErrorResponse, UpdateNotificationSettingsRequest>(fetcher, {
        mutationKey: [MutationKey.UpdatingNotificationSettings]
    })
}

export const editProfile = () => {
    const fetcher = useFetcher<EditProfileResponse>(HttpMethod.POST, '/user/edit_profile')

    return useMutation<Response<EditProfileResponse>, ErrorResponse, EditProfileRequest>(fetcher)
}

export const findAddressOnPostCode = () => {
    const fetcher = useFetcher<FindAddressOnPostCodeResponse>(HttpMethod.POST, '/user/address/new_address')

    return useMutation<Response<FindAddressOnPostCodeResponse>, ErrorResponse, FindAddressOnPostCodeRequest>(fetcher)
}

export const findAddressByOneMap = () => {
    const fetcher = useExternalFetcher<FindAddressByOneMapResponse>(HttpMethod.GET, `${APP_CONFIG.ONEMAP_API.URL}/api/common/elastic/search`)

    return useMutation(fetcher)
}

export const addNewAddress = () => {
    const fetcher = useFetcher<NewAddressResponse>(HttpMethod.POST, '/consumer-add-address')

    return useMutation<Response<NewAddressResponse>, ErrorResponse, NewAddressRequest>(fetcher)
}

export const removeAddress = () => {
    const fetcher = useFetcher<RemoveAddressResponse>(HttpMethod.POST, '/consumer-remove-address')

    return useMutation<Response<RemoveAddressResponse>, ErrorResponse, RemoveAddressRequest>(fetcher)
}

export const updatePromotionalNotifications = () => {
    const fetcher = useFetcher<UpdatePromotionalNotificationsResponse>(HttpMethod.POST, '/consumer-update-marketing-consent')

    return useMutation<Response<UpdatePromotionalNotificationsResponse>, ErrorResponse, UpdatePromotionalNotificationsRequest>(fetcher, {
        mutationKey: [MutationKey.UpdatePromotionalNotifications]
    })
}
