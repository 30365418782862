import React, { useState } from 'react'
import { View } from 'react-native'
import Animated, { SharedValue, runOnJS, runOnUI, useAnimatedReaction } from 'react-native-reanimated'
import { Icons } from 'assets'
import { createStyles } from 'lib/styles'
import { isNative } from 'lib/common'
import { Touchable, Typography } from 'lib/components'
import { useAccordion, useStyles } from 'lib/hooks'

type MoversAndPackersPricingAccordionProps = {
    title: string,
    price: string,
    index: number,
    description: string,
    options: Array<string>,
    openedIndex: SharedValue<number>
}

export const MoversAndPackersPricingAccordion: React.FunctionComponent<MoversAndPackersPricingAccordionProps> = ({
    title,
    price,
    index,
    description,
    openedIndex,
    options
}) => {
    const [isFullyOpen, setFullyOpen] = useState(false)
    const { styles, theme } = useStyles(stylesheet)
    const { setHeight, animatedHeightStyle, aref, isOpened } = useAccordion()

    useAnimatedReaction(
        () => isOpened.value,
        isOpen => runOnJS(setFullyOpen)(isOpen)
    )

    useAnimatedReaction(
        () => openedIndex.value,
        openedIndex => {
            if (openedIndex !== index && isOpened.value) {
                setHeight()
            }
        }
    )

    return (
        <Animated.View style={[styles.wrapper]}>
            <Touchable
                onPress={() => {
                    runOnUI(setHeight)()
                    openedIndex.value = index
                }}
                style={styles.tableRow}
            >
                <View style={styles.flex}>
                    <Typography.Regular>
                        {title}
                    </Typography.Regular>
                </View>
                <View style={styles.row}>
                    <Typography.Regular bold>
                        {price}
                    </Typography.Regular>
                    <View style={styles.chevronIcon}>
                        {isFullyOpen
                            ? (
                                <Icons.Minus
                                    size={10}
                                    forceColor={theme.colors.orange}
                                />
                            ) : (
                                <Icons.Chevron
                                    size={10}
                                    forceColor={theme.colors.orange}
                                />
                            )
                        }
                    </View>
                </View>
            </Touchable>
            <Animated.View style={animatedHeightStyle}>
                <View
                    ref={aref}
                    collapsable={false}
                    style={styles.accordionWrapper}
                >
                    <View style={styles.content}>
                        <Typography.Regular forceColor={theme.colors.darkGrey}>
                            {description}
                        </Typography.Regular>
                        {options.map((item, index) => (
                            <View
                                key={index}
                                style={styles.optionItem}
                            >
                                <View style={styles.checkIcon}>
                                    <Icons.Check
                                        size={15}
                                        forceColor={theme.colors.orange}
                                    />
                                </View>
                                <Typography.Regular forceColor={theme.colors.darkGrey}>
                                    {item}
                                </Typography.Regular>
                            </View>
                        ))}
                    </View>
                </View>
            </Animated.View>
        </Animated.View>
    )
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        overflow: 'hidden'
    },
    accordionWrapper: {
        left: 0,
        right: 0,
        position: isNative
            ? 'absolute'
            : undefined
    },
    flex: {
        flex: 1
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    chevronIcon: {
        marginLeft: theme.utils.gap(1)
    },
    tableRow: {
        flexDirection: 'row',
        alignItems: 'center',
        borderBottomWidth: 1,
        paddingTop: theme.utils.gap(2),
        paddingBottom: theme.utils.gap(1),
        borderBottomColor: theme.colors.silver
    },
    content: {
        marginVertical: theme.utils.gap(1)
    },
    optionItem: {
        flexDirection: 'row',
        marginVertical: theme.utils.gap(1)
    },
    checkIcon: {
        marginRight: theme.utils.gap(1)
    }
}))
