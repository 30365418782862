import React from 'react'
import { View } from 'react-native'
import Animated, { useAnimatedStyle, withTiming } from 'react-native-reanimated'
import { CustomFonts } from 'lib/types'
import { useMainDropdownLinks, useStyles, useTranslations } from 'lib/hooks'
import { Measurements } from 'lib/common'
import { createStyles } from 'lib/styles'
import { R } from 'lib/utils'
import { Regular, SmallSubheading } from '../typography'
import { Gap } from '../grid'
import { MainMenuLink } from './MainMenuLink'

type MainDropdownMenuProps = {
    isVisible: Animated.SharedValue<boolean>,
}

export const MainDropdownMenu = React.forwardRef<View, MainDropdownMenuProps>(({ isVisible }, ref) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const animatedContainerStyles = useAnimatedStyle(() => ({
        opacity: withTiming(isVisible.value ? 1 : 0),
        display: isVisible.value ? 'flex' : 'none'
    }))
    const { mostPopular, otherServices } = useMainDropdownLinks()

    return (
        <Animated.View
            // @ts-ignore
            ref={ref}
            style={[
                styles.container,
                animatedContainerStyles
            ]}
        >
            <View style={styles.column}>
                <SmallSubheading style={styles.columnHeader}>
                    {T.components.mainDropdownMenu.leftColumn.sectionName}
                </SmallSubheading>
                <Gap gapTop={1}>
                    <Regular>
                        {T.components.mainDropdownMenu.leftColumn.message}
                    </Regular>
                </Gap>
            </View>
            <View style={styles.column}>
                <SmallSubheading style={styles.sectionName}>
                    {T.components.mainDropdownMenu.mostPopular.sectionName}
                </SmallSubheading>
                <Gap gapTop={1}>
                    {mostPopular.map((link, index) => (
                        <MainMenuLink
                            key={index}
                            withUnderline={index !== mostPopular.length - 1}
                            {...link}
                        />
                    ))}
                </Gap>
            </View>
            <View style={styles.column}>
                <SmallSubheading style={styles.sectionName}>
                    {T.components.mainDropdownMenu.otherServices.sectionName}
                </SmallSubheading>
                <Gap
                    gapTop={1}
                    style={styles.otherServices}
                >
                    {R.splitEvery(3)(otherServices).map((linkGroup, index) => (
                        <View
                            key={index}
                            style={styles.subColumn}
                        >
                            {linkGroup.map((link, linkIndex) => (
                                <View key={linkIndex}>
                                    <MainMenuLink {...link} />
                                </View>
                            ))}
                        </View>
                    ))}
                </Gap>
            </View>
        </Animated.View>
    )
})

const stylesheet = createStyles(theme => ({
    container: {
        left: 0,
        right: 0,
        top: {
            ':w[1440, ]': Measurements.WideHeaderHeight,
            ':w[, 1440]': Measurements.HeaderHeight
        },
        position: 'absolute',
        backgroundColor: theme.colors.white,
        height: 340,
        flexDirection: 'row',
        justifyContent: {
            lg: undefined,
            ':w[1920,]': 'center'
        },
        zIndex: theme.zIndex[100],
        paddingHorizontal: {
            lg: theme.utils.gap(3),
            ':w[1111,]': theme.utils.gap(8)
        },
        paddingTop: theme.utils.gap(4),
        ...theme.utils.createShadow(10)
    },
    column: {
        flex: {
            lg: 1,
            ':w[1920,]': undefined
        },
        minWidth: {
            lg: undefined,
            ':w[1920,]': 400
        },
        marginRight: {
            ':w[1920,]': theme.utils.gap(5),
            lg: theme.utils.gap(1)
        }
    },
    sectionName: {
        fontFamily: CustomFonts.Poppins500,
        fontSize: 20,
        paddingHorizontal: theme.utils.gap(2)
    },
    otherServices: {
        flexDirection: 'row'
    },
    subColumn: {
        flex: 1,
        flexDirection: 'column'
    },
    columnHeader: {
        fontSize: 20,
        fontFamily: CustomFonts.Poppins500
    }
}))
