import React from 'react'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { SelectInputOption } from 'lib/types'
import { Touchable, Typography } from 'lib/components'

type CustomDropdownOptionItemProps = {
    onSelect: VoidFunction,
    item: SelectInputOption
}

export const CustomDropdownOptionItem: React.FunctionComponent<CustomDropdownOptionItemProps> = ({
    onSelect,
    item
}) => {
    const { styles } = useStyles(stylesheet)

    return (
        <Touchable
            onPress={onSelect}
            style={styles.container}
        >
            <Typography.Regular>
                {item.label}
            </Typography.Regular>
        </Touchable>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        paddingHorizontal: theme.utils.gap(2),
        paddingVertical: theme.utils.gap(1)
    }
}))
