import React, { useState } from 'react'
import { TextStyle } from 'react-native'
import Animated, { runOnJS, useAnimatedReaction } from 'react-native-reanimated'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { Touchable, Typography } from 'lib/components'

type TabProps = {
    index: number,
    label: string,
    activeLabelStyles: TextStyle,
    inactiveLabelStyles: TextStyle,
    activeTabIndex: Animated.SharedValue<number>,
    testID?: string,
    onPress(index: number): void
}

export const Tab: React.FunctionComponent<TabProps> = ({
    index,
    label,
    activeTabIndex,
    onPress,
    activeLabelStyles,
    inactiveLabelStyles,
    testID
}) => {
    const { styles } = useStyles(stylesheet)
    const [isActive, setIsActive] = useState(activeTabIndex.value === index)

    useAnimatedReaction(
        () => activeTabIndex.value,
        // even though we have a reanimated value, we need re-render here
        // to fit media queries that lives on js thread
        // otherwise we need to recreate responsive layout and breakpoints in worklet (ui thread)
        activeIndex => runOnJS(setIsActive)(activeIndex === index),
        []
    )

    return (
        <Touchable
            testID={testID}
            key={index}
            onPress={() => onPress(index)}
            style={styles.button}
        >
            <Typography.Regular
                style={{
                    ...styles.tab,
                    ...isActive ? activeLabelStyles : inactiveLabelStyles
                }}
            >
                {label}
            </Typography.Regular>
        </Touchable>
    )
}

const stylesheet = createStyles(theme => ({
    button: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'flex-end',
        paddingVertical: theme.utils.gap(1.5)
    },
    tab: {
        ...theme.components.typography.subheading
    }
}))
