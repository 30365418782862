import React from 'react'
import { Expert } from 'lib/models'
import { useTranslations } from 'lib/hooks'
import { SectionBox } from 'lib/components'
import { ExpertsList } from './ExpertsList'
import { ExpertsTitle } from './ExpertsTitle'

type MostRecentExpertsProps = {
    experts: Array<Expert>
}

export const MostRecentExperts: React.FunctionComponent<MostRecentExpertsProps> = ({ experts }) => {
    const T = useTranslations()

    return (
        <SectionBox>
            <ExpertsTitle title={T.screens.favourite.section.mostRecentExperts} />
            <ExpertsList experts={experts} />
        </SectionBox>
    )
}

