import React from 'react'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { Modal as NativeModal, View, StyleSheet, ScrollView } from 'react-native'
import { Icons } from 'assets'
import { Children } from 'lib/types'
import { Measurements, isAndroid } from 'lib/common'
import { Breakpoint, createStyles } from 'lib/styles'
import { useIsWithinBreakpoints, useStyles } from 'lib/hooks'
import { Touchable } from './Touchable'
import { Toast } from './Toast'

type ModalWithMobileOverlayProps = {
    isOpen: boolean,
    onClose: VoidFunction,
    children: Children
}

export const ModalWithMobileOverlay: React.FunctionComponent<ModalWithMobileOverlayProps> = ({
    isOpen,
    children,
    onClose
}) => {
    const { styles, theme } = useStyles(stylesheet)
    const isMobile = useIsWithinBreakpoints(Breakpoint.XS, Breakpoint.MD)
    const insets = useSafeAreaInsets()
    const extraHeight = isAndroid ? insets.top : 0

    return (
        <NativeModal
            transparent
            visible={isOpen}
            animationType="fade"
            statusBarTranslucent
            onRequestClose={onClose}
        >
            <View
                style={{
                    ...styles.contentWrapper,
                    padding: isMobile
                        ? 0
                        : theme.utils.gap(5)
                }}
            >
                <Touchable
                    onPress={onClose}
                    style={styles.backdrop}
                />
                <View style={styles.spacer} />
                <ScrollView
                    style={{
                        width: isMobile
                            ? '100%'
                            : undefined
                    }}
                    showsVerticalScrollIndicator={false}
                >
                    <View
                        style={{
                            ...styles.content,
                            height: !isMobile
                                ? undefined
                                : Number(Measurements.WindowHeight) + extraHeight
                        }}
                    >
                        {!isMobile && (
                            <Touchable
                                onPress={onClose}
                                style={styles.closeButton}
                                hitSlopBottom={20}
                                hitSlopRight={20}
                                hitSlopLeft={20}
                                hitSlopTop={20}
                            >
                                <Icons.Close size={14} />
                            </Touchable>
                        )}
                        {children}
                    </View>
                </ScrollView>
                <View style={styles.spacer} />
            </View>
            <Toast />
        </NativeModal>
    )
}

const stylesheet = createStyles(theme => ({
    backdrop: {
        ...StyleSheet.absoluteFillObject
    },
    contentWrapper: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.components.modal.backdrop.backgroundColor
    },
    spacer: {
        flex: 100 // should be high enough to suppress the scrollView flex
    },
    content: {
        alignItems: {
            lg: 'center',
            xs: undefined
        },
        justifyContent: {
            lg: 'center',
            xs: undefined
        },
        paddingVertical: {
            lg: theme.utils.gap(3),
            xs: 0
        },
        paddingHorizontal: {
            lg: theme.utils.gap(2),
            xs: 0
        },
        backgroundColor: theme.components.modal.content.backgroundColor,
        borderRadius: {
            lg: theme.components.modal.content.borderRadius,
            xs: 0
        }
    },
    closeButton: {
        zIndex: theme.zIndex[10],
        position: 'absolute',
        right: theme.utils.gap(2),
        top: theme.utils.gap(2)
    }
}))
