import React from 'react'
import { useTranslations } from 'lib/hooks'
import { AppLayout, Tabs } from 'lib/components'
import { NavigationProps, ScreenNames } from 'lib/routing'
import { UserEditProfileSection } from '../components'
import { useUserProfileTabs } from '../hooks'

type UserScreenProps = {
    navigation: NavigationProps<ScreenNames.User>
}

export const UserScreen: React.FunctionComponent<UserScreenProps> = () => {
    const T = useTranslations()
    const userProfileTabs = useUserProfileTabs()

    return (
        <AppLayout title={T.screens.user.title}>
            <Tabs tabs={userProfileTabs} />
            <UserEditProfileSection />
        </AppLayout>
    )
}

