import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const UserOpen: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path d="M 30.4704 12.4704 a 18 18 90 1 1 25.4556 25.4556 a 18 18 90 0 1 -25.452 -25.452 Z M 43.2 14.4 a 10.8 10.8 90 1 0 0 21.6 a 10.8 10.8 90 0 0 0 -21.6 Z"/>
        <Path d="M 43.2 50.4 a 21.6 21.6 90 0 0 -21.6 21.6 a 3.6 3.6 90 1 1 -7.2 0 a 28.8 28.8 90 0 1 57.6 0 a 3.6 3.6 90 1 1 -7.2 0 a 21.6 21.6 90 0 0 -21.6 -21.6 Z"/>
    </Icon>
)
