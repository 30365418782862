import { MutableRefObject, useEffect } from 'react'
import { isNative } from 'lib/common'

type ClickAwayConfig<T> = {
    elementRef: MutableRefObject<T>
    onClickAway: VoidFunction
}

export const useClickAway = <T,>({ onClickAway, elementRef }: ClickAwayConfig<T>) => {
    useEffect(() => {
        const listener = event => {
            const withinBoundaries = event.composedPath().includes(elementRef.current)

            if (!withinBoundaries) {
                onClickAway()
            }
        }

        if (!isNative) {
            document.addEventListener('mouseup', listener)
        }

        return () => {
            if (!isNative) {
                document.removeEventListener('mouseup', listener)
            }
        }
    }, [])
}
