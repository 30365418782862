import React, { useEffect, useState } from 'react'
import { Image, View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { Touchable, Typography } from 'lib/components'
import { createStyles } from 'lib/styles'
import { useStyles } from 'lib/hooks'
import { isNative } from 'lib/common'
import { SubscribePromotionAction } from 'lib/types'
import {
    useAutoApplyPromoAtom,
    usePromotionAtom,
    usePromotionPopupAtom,
    usePromotionPopupShownAtAtom,
    useShouldClearPromotionPopupShownAtAtom
} from 'lib/atoms'
import { ScreenNames } from 'lib/routing'
import { CustomModal } from 'lib/components/CustomModal'

import { usePromotionPopup } from '../hooks'
import { promotionPopupHelpers } from '../utils'
import { TargetActions } from '../types'

export const PromotionPopup: React.FunctionComponent = () => {
    const [, setAutoApplyPromo] = useAutoApplyPromoAtom()
    const [showPopup, setShowPopup] = useState<boolean>(false)
    const { isLoading, requestPromotionPopup } = usePromotionPopup()
    const [promotionAction] = usePromotionAtom()
    const [ promotionPopup ] = usePromotionPopupAtom()
    const [ promotionPopupShownAt, setPromotionPopupShownAt ] = usePromotionPopupShownAtAtom()
    const [, setShouldClearPromotionPopupShownAtAtom] = useShouldClearPromotionPopupShownAtAtom()

    const { styles } = useStyles(stylesheet)
    const navigation = useNavigation()
    const shouldShowPromotionPopup = promotionPopupHelpers.shouldShowPromotionPopup(promotionPopup, promotionPopupShownAt) && !isLoading

    useEffect(() => {
        const shouldRequestPromotionPopup = promotionPopupHelpers.isPromotionPopupRequestNeeded(promotionPopupShownAt) && !isLoading
        shouldRequestPromotionPopup && requestPromotionPopup()
    }, [promotionPopupShownAt])

    useEffect(() => {
        if(shouldShowPromotionPopup) {
            setPromotionPopupShownAt(Date.now())
            setShowPopup(true)
        }
    }, [shouldShowPromotionPopup])

    useEffect(() => {
        if([
            SubscribePromotionAction.ShouldShowPromotionModal as string,
            SubscribePromotionAction.ShouldShowMarketingConsentModal as string,
            SubscribePromotionAction.ShouldShowMarketingConsentPopup as string
        ].includes(promotionAction)) {
            setPromotionPopupShownAt(Date.now())
            setShowPopup(false)
        }
    }, [promotionAction])

    const image = promotionPopup?.image
    const imageSize = promotionPopup?.imageSize || ''
    const [imageWidth, imageHeight] = promotionPopupHelpers.calculateImageSize(imageSize)

    const onClosePopup = () => {
        setShouldClearPromotionPopupShownAtAtom(false)
        setShowPopup(false)
    }

    const onClickTargetButton = () => {
        switch (promotionPopup?.targetLink) {
            case TargetActions.CreateJob:
                onClosePopup()
                setAutoApplyPromo(promotionPopup?.targetPromoCode)
                navigation.navigate(ScreenNames.AllServices)

                return
            default:
                onClosePopup()
        }
    }

    return isNative ? (
        <CustomModal
            isOpen={showPopup}
            onClose={() => onClosePopup()}
            contentStyles={styles.modalContent}
            closeButtonStyles={styles.closeButtonStyles}
            childrenWrapper={styles.modalChildrenWrapper}>
            <View style={styles.container}>
                <View>
                    <Image
                        source={{ uri: image }}
                        resizeMode="contain"
                        style={{
                            ...styles.promotionImage,
                            width: imageWidth,
                            height: imageHeight
                        }}
                    />
                </View>
                <View style={styles.actionContainer}>
                    <Touchable style={{
                        ...styles.button,
                        ...styles.closeButton
                    }} onPress={() => onClosePopup()}>
                        <Typography.Regular style={styles.closeLabel}>{promotionPopup.closeButtonText}</Typography.Regular>
                    </Touchable>
                    <View style={styles.spacer} />
                    <Touchable style={{
                        ...styles.button,
                        ...styles.targetButton
                    }} onPress={() => onClickTargetButton()}>
                        <Typography.Regular style={styles.actionLabel}>{promotionPopup.targetLinkText}</Typography.Regular>
                    </Touchable>
                </View>
            </View>
        </CustomModal>
    ) : null
}

const stylesheet = createStyles(theme => ({
    container: {
        backgroundColor: theme.colors.white,
        padding: theme.utils.gap(0.5),
        borderRadius: 4
    },
    modalContent: {
        backgroundColor: theme.colors.transparent,
        paddingTop: 0,
        paddingBottom: 0,
        paddingHorizontal: 0
    },
    modalChildrenWrapper: {
        backgroundColor: theme.colors.transparent,
        marginTop: 0,
        paddingTop: 0,
        width: 'auto',
        justifyContent: 'center'
    },
    closeButtonStyles: {
        display: 'none',
        opacity: 0
    },
    promotionImage: {
        backgroundColor: theme.colors.white,
        borderRadius: 4
    },
    actionContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingTop: theme.utils.gap(0.75)
    },
    button: {
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
        borderRadius: 4,
        borderWidth: 2,
        borderColor: theme.colors.sun,
        paddingVertical: theme.utils.gap(1),
        paddingHorizontal: theme.utils.gap(2)
    },
    spacer: {
        width: theme.utils.gap(0.5),
        height: theme.utils.gap(0.5)
    },
    closeButton: {
        backgroundColor: theme.colors.white
    },
    closeLabel: {
        color: theme.colors.sun,
        fontSize: 13
    },
    targetButton: {
        backgroundColor: theme.colors.sun
    },
    actionLabel: {
        fontSize: 13
    }
}))
