import React, { useEffect, useRef } from 'react'
import { Children } from 'lib/types'

type CopyListenerProps = {
    children: Children
    onCopy: VoidFunction
}

export const CopyListener: React.FunctionComponent<CopyListenerProps> = ({
    children,
    onCopy
}) => {
    const ref = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        if (!ref.current) {
            return
        }

        ref.current.addEventListener('copy', onCopy)

        return () => {
            ref.current?.removeEventListener('copy', onCopy)
        }
    }, [ref.current])

    return (
        <div ref={ref}>
            {children}
        </div>
    )
}
