// we need a mock as during development
// segment is throwing errors
import { IdentifyTraits } from './types'

export const DevMockedSegment = {
    identify: (userId: string, traits: IdentifyTraits) => {},
    reset: () => {},
    track: () => {},
    bookServiceClicked: () => {},
    page: (screenName: string) => {}
}
