import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const Minus: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path d="M72 48H8c-4.416 0-8-3.576-8-8 0-4.424 3.584-8 8-8h64c4.424 0 8 3.576 8 8 0 4.424-3.576 8-8 8Z" />
    </Icon>
)
