import { View } from 'react-native'
import { measure, useAnimatedRef, useAnimatedStyle, useDerivedValue, useSharedValue, withTiming } from 'react-native-reanimated'

export const useAccordion = (extraHeight?: number) => {
    const aref = useAnimatedRef<View>()
    const isOpened = useSharedValue(false)
    const height = useSharedValue(0)
    const progress = useDerivedValue(() => isOpened.value ? 1 : 0)
    const animatedHeightStyle = useAnimatedStyle(() => ({
        height: withTiming(height.value * progress.value)
    }))

    const setHeight = () => {
        'worklet'

        height.value = height.value === 0
            ? Number(measure(aref)?.height ?? 0) + (extraHeight || 0)
            : 0
        isOpened.value = !isOpened.value
    }

    return {
        aref,
        setHeight,
        isOpened,
        animatedHeightStyle
    }
}
