import React from 'react'
import { View } from 'react-native'
import { Field } from '@codegateinc/react-form-builder-v2'
import { createStyles } from 'lib/styles'
import { SelectInputOption } from 'lib/types'
import { Touchable, Typography } from 'lib/components'
import { useStyles } from 'lib/hooks'

interface MultiRadioSelectProps extends Field<string> {
    disabled?: boolean,
    options: Array<SelectInputOption>
}

export const MultiRadioSelect: React.FunctionComponent<MultiRadioSelectProps> = ({
    onChangeValue,
    value,
    options,
    label,
    disabled
}) => {
    const { styles } = useStyles(stylesheet)

    return (
        <View style={styles.wrapper}>
            <Typography.Regular
                bold
                style={styles.label}
            >
                {label}
            </Typography.Regular>
            <View style={styles.optionsWrapper}>
                {options.map((option, index) => {
                    const isSelected = option.value === value

                    return (
                        <Touchable
                            key={index}
                            onPress={() => onChangeValue(option.value)}
                            disabled={disabled}
                        >
                            <View style={styles.option}>
                                <View style={styles.radio}>
                                    {isSelected && (
                                        <View style={styles.radioSelected} />
                                    )}
                                </View>
                                <Typography.Label>
                                    {option.label}
                                </Typography.Label>
                            </View>
                        </Touchable>
                    )
                })}
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        marginBottom: theme.utils.gap(2)
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: theme.utils.gap(1)
    },
    optionsWrapper: {
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    option: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: theme.utils.gap(1),
        marginRight: theme.utils.gap(4)
    },
    label: {
        marginBottom: theme.utils.gap(1)
    },
    radio: {
        width: 20,
        height: 20,
        borderRadius: 10,
        borderWidth: 2,
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: theme.utils.gap(1 / 2)
    },
    radioSelected: {
        width: 10,
        height: 10,
        borderRadius: 5,
        backgroundColor: theme.colors.sun
    }
}))
