import { useEffect, useState } from 'react'
import { Nullable } from 'lib/types'

export const useInternetConnection = () => {
    const [isOnline, setIsOnline] = useState<Nullable<boolean>>(navigator.onLine)

    useEffect(() => {
        const listener = () => setIsOnline(navigator.onLine)

        window.addEventListener('online', listener)
        window.addEventListener('offline', listener)

        return () => {
            window.removeEventListener('online', listener)
            window.removeEventListener('offline', listener)
        }
    }, [])

    return {
        isOnline
    }
}
