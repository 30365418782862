import { useTranslations } from 'lib/hooks'
import { ViewStyle } from 'lib/types'

export const useViewStyleOptions = () => {
    const T = useTranslations()

    return [
        {
            label: T.components.viewOptions.listView,
            value: ViewStyle.List
        },
        {
            label: T.components.viewOptions.calendar,
            value: ViewStyle.Calendar
        }
    ]
}
