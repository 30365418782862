import React from 'react'
import Animated, { useAnimatedStyle, withTiming } from 'react-native-reanimated'
import { View } from 'react-native'
import { useStyles, useAppDrawerLinks } from 'lib/hooks'
import { Measurements } from 'lib/common'
import { createStyles } from 'lib/styles'
import { IconProps } from 'lib/types'
import { ScreenNames } from 'lib/routing'
import { Touchable } from '../Touchable'
import { Regular } from '../typography'

type UserMenuProps = {
    isVisible: Animated.SharedValue<boolean>
}

export const UserMenu: React.FunctionComponent<UserMenuProps> = ({ isVisible }) => {
    const { styles, theme } = useStyles(stylesheet)
    const links = useAppDrawerLinks()
    const animatedContainerStyles = useAnimatedStyle(() => ({
        opacity: withTiming(isVisible.value ? 1 : 0),
        display: isVisible.value ? 'flex' : 'none'
    }))

    return (
        <Animated.View
            style={[
                styles.container,
                animatedContainerStyles
            ]}
        >
            {links
                .filter(link => !link.isHidden)
                .map((link, index) => {
                    const Icon = React.createElement<IconProps>(link.icon, {
                        size: 16,
                        forceColor: link.screenName === ScreenNames.Auth
                            ? theme.colors.flame
                            : theme.icon.unselected
                    })
                    const isLast = index === links.length - 1

                    return (
                        <Touchable
                            key={index}
                            onPress={() => {
                                isVisible.value = false
                                link.onPress()
                            }}
                            style={isLast
                                ? styles.lastItem
                                : undefined
                            }
                        >
                            <View style={styles.linkContainer}>
                                <View style={styles.icon}>
                                    {Icon}
                                </View>
                                <Regular forceColor={theme.icon.unselected}>
                                    {link.text}
                                </Regular>
                            </View>
                        </Touchable>
                    )
                })}
        </Animated.View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        left: 0,
        right: 0,
        top: {
            ':w[1440, ]': Measurements.WideHeaderHeight,
            ':w[, 1440]': Measurements.HeaderHeight
        },
        position: 'absolute',
        flexDirection: 'column',
        backgroundColor: theme.colors.white,
        borderRadius: 4,
        borderWidth: 1,
        borderTopWidth: 0,
        zIndex: theme.zIndex[100],
        borderColor: theme.colors.silver,
        ...theme.utils.createShadow(10)
    },
    linkContainer: {
        marginWidth: 3,
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: theme.utils.gap(2),
        paddingHorizontal: theme.utils.gap(3)
    },
    icon: {
        marginRight: theme.utils.gap(2)
    },
    lastItem: {
        borderColor: theme.colors.silver,
        borderTopWidth: 1
    }
}))
