import React from 'react'
import { View } from 'react-native'
import { Icons } from 'assets'
import { Modal } from 'lib/components'
import { createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'

type CancelBillingModalProps = {
    isOpen: boolean,
    isLoading: boolean,
    onClose: VoidFunction,
    onCancel: VoidFunction,
    onConfirm: VoidFunction,
    cancelationMessage: string
}

export const CancelBillingModal: React.FunctionComponent<CancelBillingModalProps> = ({
    isOpen,
    isLoading,
    onCancel,
    onClose,
    onConfirm,
    cancelationMessage
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)

    return (
        <Modal
            disabled={isLoading}
            isLoading={isLoading}
            renderIcon={() => (
                <View
                    style={{
                        ...styles.icon,
                        transform: [
                            {
                                rotateZ: '45deg'
                            }
                        ]
                    }}
                >
                    <Icons.PlusCircle
                        size={30}
                        forceColor={theme.colors.orange}
                    />
                </View>
            )}
            isOpen={isOpen}
            confirmText={T.components.cancelJobModal.cancelJob}
            cancelText={T.components.cancelJobModal.contact}
            onConfirm={onConfirm}
            onCancel={onCancel}
            onClose={onClose}
            title={T.components.cancelJobModal.title}
            message={cancelationMessage}
        />
    )
}

const stylesheet = createStyles(theme => ({
    icon: {
        marginBottom: theme.utils.gap(2)
    }
}))
