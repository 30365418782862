import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const Coin: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M40 8C22.327 8 8 22.327 8 40c0 17.673 14.327 32 32 32 17.673 0 32-14.327 32-32C72 22.327 57.673 8 40 8ZM0 40C0 17.909 17.909 0 40 0s40 17.909 40 40-17.909 40-40 40S0 62.091 0 40Zm40-24a4 4 0 0 1 4 4v.37c3.762.707 7.166 2.415 9.416 5.008a4 4 0 1 1-6.043 5.244c-.65-.75-1.809-1.511-3.373-2.019v7.765c2.486.467 4.786 1.368 6.704 2.647C53.591 40.939 56 44.037 56 48c0 3.963-2.41 7.06-5.296 8.985-1.918 1.279-4.218 2.18-6.704 2.647V60a4 4 0 1 1-8 0v-.37c-3.762-.707-7.166-2.416-9.416-5.009a4 4 0 1 1 6.043-5.242c.65.749 1.81 1.51 3.373 2.018v-7.765c-2.486-.467-4.786-1.368-6.704-2.647C26.409 39.061 24 35.963 24 32c0-3.963 2.41-7.06 5.296-8.985 1.918-1.279 4.218-2.18 6.704-2.647V20a4 4 0 0 1 4-4Zm-4 12.604a9.226 9.226 0 0 0-2.267 1.067C32.277 30.642 32 31.544 32 32c0 .456.277 1.358 1.733 2.329.62.412 1.385.781 2.267 1.067v-6.792Zm8 16v6.792a9.226 9.226 0 0 0 2.267-1.067C47.723 49.358 48 48.456 48 48c0-.456-.277-1.358-1.733-2.329A9.22 9.22 0 0 0 44 44.604Z"
        />
    </Icon>
)
