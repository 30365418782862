import React, { useState } from 'react'
import { View } from 'react-native'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { RatingStar } from './RatingStar'

type RatingProps = {
    value: number,
    rated: boolean,
    onRate: (value: number) => void,
    maxValue?: number
}

export const Rating: React.FunctionComponent<RatingProps> = ({
    value,
    rated,
    onRate,
    maxValue = 5
}) => {
    const { styles, theme } = useStyles(stylesheet)
    const [hoveredValue, setHoveredValue] = useState(0)

    return (
        <View style={styles.container}>
            {[...new Array(maxValue)].map((item, index) => {
                const starColor = hoveredValue
                    ? undefined
                    : theme.colors.mouse
                const isFilled = hoveredValue
                    ? index < hoveredValue
                    : index < value

                return (
                    <RatingStar
                        key={index}
                        index={index}
                        rated={rated}
                        value={value}
                        starColor={starColor}
                        isFilled={isFilled}
                        onRate={onRate}
                        hoveredValue={hoveredValue}
                        setHoveredValue={setHoveredValue}
                    />
                )
            })}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        flexDirection: 'row'
    }
}))
