import { UseFormReturn } from '@codegateinc/react-form-builder-v2'
import { useTranslations } from 'lib/hooks'
import { ServiceAddonsFormShape, CookingFormShape } from '../forms'

export const useCookingAdditionalInformation = (cookingFormShape: CookingFormShape, addonsForm: UseFormReturn<ServiceAddonsFormShape>) => {
    const T = useTranslations()
    const { form } = addonsForm

    return `${T.screens.cookingSummary.additionalInformation.propertyInformation.title}
    · ${cookingFormShape.appliances}

${T.screens.cookingSummary.additionalInformation.specialRequest} ${cookingFormShape.comment ? T.common.yes : T.common.no}
${cookingFormShape.comment ? `${cookingFormShape.comment}\n` : ''}
${T.screens.cookingSummary.additionalInformation.allergies} ${form.allergies.value ? T.common.yes : T.common.no}
${form.allergiesComment.value}
`
}
