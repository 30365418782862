import React from 'react'
import { View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { Touchable, Typography } from 'lib/components'
import { ScreenNames } from 'lib/routing'
import { isNative } from 'lib/common'

type CancelButtonProps = {
    isDisabled: boolean
}

export const CancelButton: React.FunctionComponent<CancelButtonProps> = ({
    isDisabled
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const navigation = useNavigation()

    return (
        <View style={styles.container}>
            <Touchable
                style={styles.button}
                disabled={isDisabled}
                onPress={() => {
                    if (isNative) {
                        return navigation.goBack()
                    }

                    navigation.navigate(ScreenNames.Auth)
                }}
            >
                <Typography.Regular forceColor={theme.colors.orange}>
                    {T.common.cancel}
                </Typography.Regular>
            </Touchable>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        alignItems: 'center'
    },
    button: {
        borderBottomWidth: 1,
        borderBottomColor: theme.colors.orange
    }
}))
