import { useTranslations } from 'lib/hooks'
import { LaundryService } from '../types'

export const useLaundryServiceDetails = () => {
    const T = useTranslations()

    const getServiceDetails = (service: LaundryService) => {
        switch (service) {
            case LaundryService.DryCleanAndIron:
                return {
                    ...T.components.laundryPrices.dryCleanAndIron
                }
            case LaundryService.IronOnly:
                return {
                    ...T.components.laundryPrices.ironOnly
                }
            case LaundryService.LoadWash:
                return {
                    ...T.components.laundryPrices.loadWash
                }
            case LaundryService.CurtainsWash:
                return {
                    ...T.components.laundryPrices.curtainsWash
                }
            default:
            case LaundryService.WashAndIron:
                return {
                    ...T.components.laundryPrices.washAndIron
                }
        }
    }

    return (service: LaundryService) => {
        const serviceDetails = getServiceDetails(service)
        const paragraphs = [
            {
                title: `${serviceDetails.name} ${T.components.laundryPrices.overviewTitleSuffix}`,
                description: serviceDetails.overview
            },
            {
                title: T.components.laundryPrices.suitableForTitle,
                description: serviceDetails.suitableFor
            },
            {
                title: T.components.laundryPrices.preparationTitle,
                description: `${T.components.laundryPrices.preparationPrefix} ${serviceDetails.name.toLowerCase()} ${T.components.laundryPrices.preparationSuffix}`
            }
        ]

        return {
            ...serviceDetails,
            paragraphs
        }
    }
}
