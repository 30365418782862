import React from 'react'
import { View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { R } from 'lib/utils'
import { createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { Button, SectionBox, Typography } from 'lib/components'
import { ScreenNames } from 'lib/routing'
import { Segment } from 'lib/analytics'
import { isNative } from 'lib/common'
import { useAddNewCreditCard } from '../hooks'

export const PaymentEmpty: React.FunctionComponent = () => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const navigation = useNavigation()
    const { mobileSubmit, isLoading } = useAddNewCreditCard(R.T)

    return (
        <SectionBox>
            <View style={styles.container}>
                <View style={styles.text}>
                    <Typography.Title bold>
                        {T.screens.paymentAndCards.noCards}
                    </Typography.Title>
                </View>
                <Button
                    width={180}
                    noBackground
                    isLoading={isLoading}
                    onPress={() => {
                        Segment.addNewCreditCardClicked({})

                        if (isNative) {
                            return mobileSubmit()
                        }

                        navigation.navigate(ScreenNames.UserCardsNew)
                    }}
                    text={T.screens.paymentAndCards.addCard}
                />
            </View>
        </SectionBox>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        alignItems: 'center',
        marginVertical: theme.utils.gap(6)
    },
    text: {
        marginBottom: theme.utils.gap(3)
    }
}))
