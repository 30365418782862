import React from 'react'
import { View } from 'react-native'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { Touchable, Typography } from 'lib/components'

type BookingDayButtonProps = {
    index: number,
    dayKey: string,
    disabledCheckbox: boolean,
    isActive: boolean,
    onClick: VoidFunction,
}

export const BookingDayButton: React.FunctionComponent<BookingDayButtonProps> = ({
    index,
    dayKey,
    disabledCheckbox,
    isActive,
    onClick
}) => {
    const { styles } = useStyles(stylesheet)
    const isFirstElement = !index
    const isLastButton = index === 6

    return (
        <View style={styles.container} key={dayKey}>
            <View style={styles.buttonWarper}>
                <Touchable
                    testID={`booking-day-button-${isActive ? 'active' : 'inactive'}`}
                    style={{
                        ...styles.button,
                        ...(isActive ? styles.buttonActive : styles.buttonInactive),
                        ...(isFirstElement ? styles.firstButton : isLastButton ? styles.lastButton : styles.normalButton)
                    }} onPress={onClick} disabled={disabledCheckbox}>
                    <Typography.Regular style={isActive ? styles.buttonTextActive : styles.buttonTextInactive}>
                        {dayKey.substring(0, 3)}
                    </Typography.Regular>
                </Touchable>
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        flexDirection: 'row',
        minWidth: 70
    },
    buttonWarper: {
        flex: 1
    },
    button: {
        padding: theme.utils.gap(1.5),
        borderWidth: 1,
        borderRadius: 4,
        textAlign: 'center',
        userSelect: 'none',
        marginVertical: theme.utils.gap(0.5)
    },
    buttonActive: {
        borderColor: theme.colors.orange,
        backgroundColor: theme.colors.yellow
    },
    buttonInactive: {
        borderColor: theme.colors.fog,
        backgroundColor: theme.colors.white,
        color: theme.colors.black
    },
    buttonTextActive: {
        color: theme.colors.orange,
        fontWeight: 'bold',
        fontSize: 14,
        textAlign: 'center'
    },
    buttonTextInactive: {
        color: theme.colors.black,
        fontSize: 14,
        textAlign: 'center'
    },
    normalButton: {
        marginHorizontal: theme.utils.gap(0.25)
    },
    firstButton: {
        marginLeft: 0,
        marginRight: theme.utils.gap(0.25)
    },
    lastButton : {
        marginRight: 0,
        marginLeft: theme.utils.gap(0.25)
    }
}))
