import React from 'react'
import { View } from 'react-native'
import { createStyles } from 'lib/styles'
import { PromotionType } from 'lib/models'
import { ReferralWarning, Typography } from 'lib/components'
import { usePrice, useStyles, useTranslations } from 'lib/hooks'
import { Children, Nullable } from 'lib/types'
import { GetPromotionResponse } from '../types'

type PaymentSummaryProps = {
    totalPrice: number,
    discount?: number,
    promotionType?: PromotionType,
    service: string,
    couponText?: string,
    perHour?: boolean,
    renderExtraContent?: () => Children,
    promotionPackage: Nullable<GetPromotionResponse>
}

export const PaymentSummary: React.FunctionComponent<PaymentSummaryProps> = ({
    totalPrice,
    couponText,
    perHour,
    renderExtraContent,
    promotionPackage
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const formattedPrice = usePrice(totalPrice)
    const defaultPriceToDisplay = perHour
        ? `${formattedPrice} / ${T.common.hour}`
        : formattedPrice

    return (
        <View style={styles.container}>
            <Typography.SmallSubheading>
                {T.common.paymentSummary}
            </Typography.SmallSubheading>
            <View style={styles.priceContainer}>
                <Typography.Regular bold>
                    {T.common.totalPrice}
                </Typography.Regular>
                {totalPrice === 0 ? (
                    <View style={styles.row}>
                        <Typography.Regular bold>
                            {T.common.chargesOnQuotation}
                        </Typography.Regular>
                    </View>
                ) : (
                    <View style={styles.row}>
                        <Typography.Regular bold>
                            {defaultPriceToDisplay}
                        </Typography.Regular>
                    </View>
                )}
            </View>
            {couponText && (
                <View style={styles.couponContainer}>
                    <Typography.Label
                        bold
                        forceColor={theme.colors.green}
                    >
                        {couponText}
                    </Typography.Label>
                    {promotionPackage?.promotion.warning_message && (
                        <ReferralWarning />
                    )}
                </View>
            )}
            {renderExtraContent && renderExtraContent()}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        borderWidth: 1,
        borderRadius: 8,
        borderColor: theme.colors.silver,
        paddingTop: theme.utils.gap(2),
        paddingBottom: theme.utils.gap(1),
        paddingHorizontal: theme.utils.gap(1),
        marginBottom: theme.utils.gap(2)
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    priceContainer: {
        borderTopWidth: 1,
        borderColor: theme.colors.silver,
        marginVertical: theme.utils.gap(2),
        paddingTop: theme.utils.gap(2),
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    summaryContainer: {
        marginTop: theme.utils.gap(2)
    },
    summaryRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: theme.utils.gap(2)
    },
    labelWrapper: {
        marginRight: theme.utils.gap(2)
    },
    couponContainer: {
        borderRadius: 8,
        justifyContent: 'center',
        textAlign: 'center',
        paddingHorizontal: theme.utils.gap(2),
        paddingVertical: theme.utils.gap(1),
        backgroundColor: theme.colors.mint
    },
    couponIcon: {
        marginRight: theme.utils.gap(1)
    },
    discount: {
        textDecorationLine: 'line-through',
        marginRight: theme.utils.gap(1)
    },
    warningMessage: {
        marginTop: theme.utils.gap(1)
    },
    viewTerms: {
        textDecorationLine: 'underline'
    }
}))
