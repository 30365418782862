import React from 'react'
import { ScrollView, View } from 'react-native'
import { useRoute } from '@react-navigation/native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scrollview'
import { Measurements, isNative } from 'lib/common'
import { Children } from 'lib/types'
import { useStyles } from 'lib/hooks'
import { Breakpoint, createStyles } from 'lib/styles'
import { AppDrawerContent, Grid, Header, ListBottomSpacer, MediaQuery } from 'lib/components'
import { BookingDetailsHeader } from './BookingDetailsHeader'

type BookingDetailsLayoutProps = {
    children: Children,
    footer?: Children,
    title?: string,
    centerVertical?: boolean,
    scrollEnabled?: boolean,
    isLoading?: boolean,
    bottomSpacerHeight?: number,
    renderSubHeader?(): React.ReactElement
    renderNotification?(): React.ReactElement
}

export const BookingDetailsLayout: React.FunctionComponent<BookingDetailsLayoutProps> = ({
    children,
    footer,
    title,
    scrollEnabled = true,
    isLoading = false,
    renderSubHeader,
    bottomSpacerHeight = 50,
    renderNotification
}) => {
    const { styles } = useStyles(stylesheet)
    const route = useRoute()

    return (
        <Grid.Background>
            {!isNative && (
                <Header disableUserMenu />
            )}
            {renderSubHeader && renderSubHeader()}
            <View style={styles.container}>
                <MediaQuery.Visible from={Breakpoint.LG}>
                    <ScrollView
                        style={styles.leftColumn}
                        showsVerticalScrollIndicator={false}
                    >
                        <AppDrawerContent routeName={route.name} />
                        <ListBottomSpacer height={50} />
                    </ScrollView>
                </MediaQuery.Visible>
                <View style={styles.rightColumn}>
                    {renderNotification && renderNotification()}
                    <KeyboardAwareScrollView
                        scrollEnabled={scrollEnabled}
                        extraScrollHeight={20}
                        showsVerticalScrollIndicator={false}
                        contentContainerStyle={{
                            minHeight: Measurements.WindowHeight,
                            flex: isLoading
                                ? 1
                                : undefined
                        }}
                    >
                        {title && (
                            <BookingDetailsHeader title={title} />
                        )}
                        {children}
                        <ListBottomSpacer height={bottomSpacerHeight} />
                    </KeyboardAwareScrollView>
                </View>
            </View>
            {footer}
        </Grid.Background>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        flex: 1,
        flexDirection: 'row'
    },
    leftColumn: {
        flex: 1,
        maxWidth: 260,
        height: '100%',
        paddingHorizontal: theme.utils.gap(3),
        paddingTop: {
            lg: theme.utils.gap(5),
            xs: 0
        },
        backgroundColor: theme.colors.white,
        ...theme.utils.createShadow(0, 0.8, 8, 8)
    },
    rightColumn: {
        flex: 1,
        maxWidth: '100%'
    }
}))
