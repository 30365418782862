import React from 'react'
import { UserAddressesList } from './UserAddressesList'
import { UserAddressEmpty } from './UserAddressEmpty'

type UserAddressesProps = {
    query: string,
    userHaveLocations: boolean,
    setAddAddress?: VoidFunction
}

export const UserAddresses: React.FunctionComponent<UserAddressesProps> = ({
    userHaveLocations,
    query,
    setAddAddress
}) => {
    if (!userHaveLocations) {
        return (
            <UserAddressEmpty setAddAddress={setAddAddress} />
        )
    }

    return (
        <UserAddressesList searchValue={query} />
    )
}
