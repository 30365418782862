import React from 'react'
import { View } from 'react-native'
import { createStyles } from 'lib/styles'
import { useServicePoliciesLinks, useStyles, useTranslations } from 'lib/hooks'
import { Label } from './typography'

export const PolicyLinks: React.FunctionComponent = () => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const [
        cancelationPolicy,
        termsPolicy,
        privacyPolicy
    ] = useServicePoliciesLinks()

    return (
        <View style={styles.container}>
            <Label style={styles.text}>
                <Label forceColor={theme.colors.mouse}>
                    {`${T.components.policies.confirm} `}
                </Label>
                <Label
                    bold
                    style={styles.underline}
                    forceColor={theme.colors.orange}
                    testID={T.accessibility.policyLinksCancelationLabel}
                    onPress={cancelationPolicy.onPress}
                >
                    {`${cancelationPolicy.title}, `}
                </Label>
                <Label
                    bold
                    style={styles.underline}
                    forceColor={theme.colors.orange}
                    onPress={termsPolicy.onPress}
                    testID={T.accessibility.policyLinksTermsPolicyLabel}
                >
                    {termsPolicy.title}
                </Label>
                <Label forceColor={theme.colors.mouse}>
                    {` ${T.common.and} `}
                </Label>
                <Label
                    bold
                    style={styles.underline}
                    forceColor={theme.colors.orange}
                    onPress={privacyPolicy.onPress}
                    testID={T.accessibility.policyLinksPrivacyPolicyLabel}
                >
                    {privacyPolicy.title}
                </Label>
            </Label>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        marginBottom: theme.utils.gap(2)
    },
    text: {
        textAlign: {
            lg: undefined,
            xs: 'center'
        },
        lineHeight: theme.utils.gap(2)
    },
    underline: {
        textDecorationLine: 'underline'
    }
}))
