import React from 'react'
import { View } from 'react-native'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { Typography } from 'lib/components'

type ExpertsTitleProps = {
    title: string
}

export const ExpertsTitle: React.FunctionComponent<ExpertsTitleProps> = ({
    title
}) => {
    const { styles } = useStyles(stylesheet)

    return (
        <View style={styles.container}>
            <Typography.SmallSubheading>
                {title}
            </Typography.SmallSubheading>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        marginVertical: theme.utils.gap(2)
    }
}))
