import { createDrawerNavigator } from '@react-navigation/drawer'
import { CustomLeftDrawer } from 'lib/components'
import { MobileStack } from './MobileStack'
import { DrawerParams } from '../stackParams'
import { NavigatorId, ScreenNames } from '../screens'

const LeftDrawer = createDrawerNavigator<DrawerParams>()

export const NativeStack = (isAuthorized: boolean) => (
    <LeftDrawer.Navigator
        id={NavigatorId.LeftDrawer}
        initialRouteName={ScreenNames.RootLeftDrawer}
        screenOptions={{
            headerShown: false,
            swipeEnabled: isAuthorized,
            drawerPosition: 'left'
        }}
        drawerContent={props => (
            <CustomLeftDrawer {...props} />
        )}
    >
        <LeftDrawer.Screen
            name={ScreenNames.RootLeftDrawer}
            component={MobileStack}
        />
    </LeftDrawer.Navigator>
)
