/* eslint-disable max-lines */
import { Country } from '../types'

export const COUNTRIES: Array<Country> = [
    {
        label: 'Afghanistan',
        code: '+93',
        flagEmoji: '🇦🇫',
        flagCode: 'af'
    },
    {
        label: 'Åland Islands',
        code: '+358',
        flagEmoji: '🇦🇽',
        flagCode: 'ax'
    },
    {
        label: 'Albania',
        code: '+355',
        flagEmoji: '🇦🇱',
        flagCode: 'al'
    },
    {
        label: 'Algeria',
        code: '+213',
        flagEmoji: '🇩🇿',
        flagCode: 'dz'
    },
    {
        label: 'American Samoa',
        code: '+1684',
        flagEmoji: '🇦🇸',
        flagCode: 'as'
    },
    {
        label: 'Andorra',
        code: '+376',
        flagEmoji: '🇦🇩',
        flagCode: 'ad'
    },
    {
        label: 'Angola',
        code: '+244',
        flagEmoji: '🇦🇴',
        flagCode: 'ao'
    },
    {
        label: 'Anguilla',
        code: '+1264',
        flagEmoji: '🇦🇮',
        flagCode: 'ai'
    },
    {
        label: 'Antigua and Barbuda',
        code: '+1268',
        flagEmoji: '🇦🇬',
        flagCode: 'ag'
    },
    {
        label: 'Argentina',
        code: '+54',
        flagEmoji: '🇦🇷',
        flagCode: 'ar'
    },
    {
        label: 'Armenia',
        code: '+374',
        flagEmoji: '🇦🇲',
        flagCode: 'am'
    },
    {
        label: 'Aruba',
        code: '+297',
        flagEmoji: '🇦🇼',
        flagCode: 'aw'
    },
    {
        label: 'Australia',
        code: '+61',
        flagEmoji: '🇦🇺',
        flagCode: 'au'
    },
    {
        label: 'Austria',
        code: '+43',
        flagEmoji: '🇦🇹',
        flagCode: 'at'
    },
    {
        label: 'Azerbaijan',
        code: '+994',
        flagEmoji: '🇦🇿',
        flagCode: 'az'
    },
    {
        label: 'Bahamas',
        code: '+1242',
        flagEmoji: '🇧🇸',
        flagCode: 'bs'
    },
    {
        label: 'Bahrain',
        code: '+973',
        flagEmoji: '🇧🇭',
        flagCode: 'bh'
    },
    {
        label: 'Bangladesh',
        code: '+880',
        flagEmoji: '🇧🇩',
        flagCode: 'bd'
    },
    {
        label: 'Barbados',
        code: '+1246',
        flagEmoji: '🇧🇧',
        flagCode: 'bb'
    },
    {
        label: 'Belarus',
        code: '+375',
        flagEmoji: '🇧🇾',
        flagCode: 'by'
    },
    {
        label: 'Belgium',
        code: '+32',
        flagEmoji: '🇧🇪',
        flagCode: 'be'
    },
    {
        label: 'Belize',
        code: '+501',
        flagEmoji: '🇧🇿',
        flagCode: 'bz'
    },
    {
        label: 'Benin',
        code: '+229',
        flagEmoji: '🇧🇯',
        flagCode: 'bj'
    },
    {
        label: 'Bermuda',
        code: '+1441',
        flagEmoji: '🇧🇲',
        flagCode: 'bm'
    },
    {
        label: 'Bhutan',
        code: '+975',
        flagEmoji: '🇧🇹',
        flagCode: 'bt'
    },
    {
        label: 'Bolivia, Plurinational State of bolivia',
        code: '+591',
        flagEmoji: '🇧🇴',
        flagCode: 'bo'
    },
    {
        label: 'Bosnia and Herzegovina',
        code: '+387',
        flagEmoji: '🇧🇦',
        flagCode: 'ba'
    },
    {
        label: 'Botswana',
        code: '+267',
        flagEmoji: '🇧🇼',
        flagCode: 'bw'
    },
    {
        label: 'Bouvet Island',
        code: '+47',
        flagEmoji: '🇧🇻',
        flagCode: 'bv'
    },
    {
        label: 'Brazil',
        code: '+55',
        flagEmoji: '🇧🇷',
        flagCode: 'br'
    },
    {
        label: 'British Indian Ocean Territory',
        code: '+246',
        flagEmoji: '🇮🇴',
        flagCode: 'io'
    },
    {
        label: 'Brunei Darussalam',
        code: '+673',
        flagEmoji: '🇧🇳',
        flagCode: 'bn'
    },
    {
        label: 'Bulgaria',
        code: '+359',
        flagEmoji: '🇧🇬',
        flagCode: 'bg'
    },
    {
        label: 'Burkina Faso',
        code: '+226',
        flagEmoji: '🇧🇫',
        flagCode: 'bf'
    },
    {
        label: 'Burundi',
        code: '+257',
        flagEmoji: '🇧🇮',
        flagCode: 'bi'
    },
    {
        label: 'Cambodia',
        code: '+855',
        flagEmoji: '🇰🇭',
        flagCode: 'kh'
    },
    {
        label: 'Cameroon',
        code: '+237',
        flagEmoji: '🇨🇲',
        flagCode: 'cm'
    },
    {
        label: 'Canada',
        code: '+1',
        flagEmoji: '🇨🇦',
        flagCode: 'ca'
    },
    {
        label: 'Cabo Verde',
        code: '+238',
        flagEmoji: '🇨🇻',
        flagCode: 'cv'
    },
    {
        label: 'Cayman Islands',
        code: '+345',
        flagEmoji: '🇰🇾',
        flagCode: 'ky'
    },
    {
        label: 'Central African Republic',
        code: '+236',
        flagEmoji: '🇨🇫',
        flagCode: 'cf'
    },
    {
        label: 'Chad',
        code: '+235',
        flagEmoji: '🇹🇩',
        flagCode: 'td'
    },
    {
        label: 'Chile',
        code: '+56',
        flagEmoji: '🇨🇱',
        flagCode: 'cl'
    },
    {
        label: 'China',
        code: '+86',
        flagEmoji: '🇨🇳',
        flagCode: 'cn'
    },
    {
        label: 'Christmas Island',
        code: '+61',
        flagEmoji: '🇨🇽',
        flagCode: 'cx'
    },
    {
        label: 'Cocos (Keeling) Islands',
        code: '+61',
        flagEmoji: '🇨🇨',
        flagCode: 'cc'
    },
    {
        label: 'Colombia',
        code: '+57',
        flagEmoji: '🇨🇴',
        flagCode: 'co'
    },
    {
        label: 'Comoros',
        code: '+269',
        flagEmoji: '🇰🇲',
        flagCode: 'km'
    },
    {
        label: 'Congo',
        code: '+242',
        flagEmoji: '🇨🇬',
        flagCode: 'cg'
    },
    {
        label: 'Congo, The Democratic Republic of the Congo',
        code: '+243',
        flagEmoji: '🇨🇩',
        flagCode: 'cd'
    },
    {
        label: 'Cook Islands',
        code: '+682',
        flagCode: 'ck',
        flagEmoji: '🇨🇰'
    },
    {
        label: 'Costa Rica',
        code: '+506',
        flagEmoji: '🇨🇷',
        flagCode: 'cr'
    },
    {
        label: 'Cote d\'Ivoire\'',
        code: '+225',
        flagEmoji: '🇨🇮',
        flagCode: 'ci'
    },
    {
        label: 'Croatia',
        code: '+385',
        flagEmoji: '🇭🇷',
        flagCode: 'hr'
    },
    {
        label: 'Cuba',
        code: '+53',
        flagEmoji: '🇨🇺',
        flagCode: 'cu'
    },
    {
        label: 'Cyprus',
        code: '+357',
        flagEmoji: '🇨🇾',
        flagCode: 'cy'
    },
    {
        label: 'Czech Republic',
        code: '+420',
        flagEmoji: '🇨🇿',
        flagCode: 'cz'
    },
    {
        label: 'Denmark',
        code: '+45',
        flagEmoji: '🇩🇰',
        flagCode: 'dk'
    },
    {
        label: 'Djibouti',
        code: '+253',
        flagEmoji: '🇩🇯',
        flagCode: 'dj'
    },
    {
        label: 'Dominica',
        code: '+1767',
        flagEmoji: '🇩🇲',
        flagCode: 'dm'
    },
    {
        label: 'Ecuador',
        code: '+593',
        flagEmoji: '🇪🇨',
        flagCode: 'ec'
    },
    {
        label: 'Egypt',
        code: '+20',
        flagEmoji: '🇪🇬',
        flagCode: 'eg'
    },
    {
        label: 'El Salvador',
        code: '+503',
        flagEmoji: '🇸🇻',
        flagCode: 'sv'
    },
    {
        label: 'Equatorial Guinea',
        code: '+240',
        flagEmoji: '🇬🇶',
        flagCode: 'gq'
    },
    {
        label: 'Eritrea',
        code: '+291',
        flagEmoji: '🇪🇷',
        flagCode: 'er'
    },
    {
        label: 'Estonia',
        code: '+372',
        flagEmoji: '🇪🇪',
        flagCode: 'ee'
    },
    {
        label: 'Ethiopia',
        code: '+251',
        flagEmoji: '🇪🇹',
        flagCode: 'et'
    },
    {
        label: 'Falkland Islands (Malvinas)',
        code: '+500',
        flagEmoji: '🇫🇰',
        flagCode: 'fk'
    },
    {
        label: 'Faroe Islands',
        code: '+298',
        flagEmoji: '🇫🇴',
        flagCode: 'fo'
    },
    {
        label: 'Fiji',
        code: '+679',
        flagEmoji: '🇫🇯',
        flagCode: 'fj'
    },
    {
        label: 'Finland',
        code: '+358',
        flagEmoji: '🇫🇮',
        flagCode: 'fi'
    },
    {
        label: 'France',
        code: '+33',
        flagEmoji: '🇫🇷',
        flagCode: 'fr'
    },
    {
        label: 'French Guiana',
        code: '+594',
        flagEmoji: '🇬🇫',
        flagCode: 'gf'
    },
    {
        label: 'French Polynesia',
        code: '+689',
        flagEmoji: '🇵🇫',
        flagCode: 'pf'
    },
    {
        label: 'Gabon',
        code: '+241',
        flagEmoji: '🇬🇦',
        flagCode: 'ga'
    },
    {
        label: 'Gambia',
        code: '+220',
        flagEmoji: '🇬🇲',
        flagCode: 'gm'
    },
    {
        label: 'Georgia',
        code: '+995',
        flagEmoji: '🇬🇪',
        flagCode: 'ge'
    },
    {
        label: 'Germany',
        code: '+49',
        flagEmoji: '🇩🇪',
        flagCode: 'de'
    },
    {
        label: 'Ghana',
        code: '+233',
        flagEmoji: '🇬🇭',
        flagCode: 'gh'
    },
    {
        label: 'Gibraltar',
        code: '+350',
        flagEmoji: '🇬🇮',
        flagCode: 'gi'
    },
    {
        label: 'Greece',
        code: '+30',
        flagEmoji: '🇬🇷',
        flagCode: 'gr'
    },
    {
        label: 'Greenland',
        code: '+299',
        flagEmoji: '🇬🇱',
        flagCode: 'gl'
    },
    {
        label: 'Grenada',
        code: '+1473',
        flagEmoji: '🇬🇩',
        flagCode: 'gd'
    },
    {
        label: 'Guadeloupe',
        code: '+590',
        flagEmoji: '🇬🇵',
        flagCode: 'gp'
    },
    {
        label: 'Guam',
        code: '+1671',
        flagEmoji: '🇬🇺',
        flagCode: 'gu'
    },
    {
        label: 'Guatemala',
        code: '+502',
        flagEmoji: '🇬🇹',
        flagCode: 'gt'
    },
    {
        label: 'Guernsey',
        code: '+44',
        flagEmoji: '🇬🇬',
        flagCode: 'gg'
    },
    {
        label: 'Guinea',
        code: '+224',
        flagEmoji: '🇬🇳',
        flagCode: 'gn'
    },
    {
        label: 'Guinea-Bissau',
        code: '+245',
        flagEmoji: '🇬🇼',
        flagCode: 'gw'
    },
    {
        label: 'Guyana',
        code: '+592',
        flagEmoji: '🇬🇾',
        flagCode: 'gy'
    },
    {
        label: 'Haiti',
        code: '+509',
        flagEmoji: '🇭🇹',
        flagCode: 'ht'
    },
    {
        label: 'Honduras',
        code: '+504',
        flagEmoji: '🇭🇳',
        flagCode: 'hn'
    },
    {
        label: 'Hong Kong',
        code: '+852',
        flagEmoji: '🇭🇰',
        flagCode: 'hk'
    },
    {
        label: 'Hungary',
        code: '+36',
        flagEmoji: '🇭🇺',
        flagCode: 'hu'
    },
    {
        label: 'Iceland',
        code: '+354',
        flagEmoji: '🇮🇸',
        flagCode: 'is'
    },
    {
        label: 'India',
        code: '+91',
        flagEmoji: '🇮🇳',
        flagCode: 'in'
    },
    {
        label: 'Indonesia',
        code: '+62',
        flagEmoji: '🇮🇩',
        flagCode: 'id'
    },
    {
        label: 'Iran, Islamic Republic of Persian Gulf',
        code: '+98',
        flagEmoji: '🇮🇷',
        flagCode: 'ir'
    },
    {
        label: 'Iraq',
        code: '+964',
        flagEmoji: '🇮🇶',
        flagCode: 'iq'
    },
    {
        label: 'Ireland',
        code: '+353',
        flagEmoji: '🇮🇪',
        flagCode: 'ie'
    },
    {
        label: 'Isle of Man',
        code: '+44',
        flagEmoji: '🇮🇲',
        flagCode: 'im'
    },
    {
        label: 'Israel',
        code: '+972',
        flagEmoji: '🇮🇱',
        flagCode: 'il'
    },
    {
        label: 'Italy',
        code: '+39',
        flagEmoji: '🇮🇹',
        flagCode: 'it'
    },
    {
        label: 'Jamaica',
        code: '+1876',
        flagEmoji: '🇯🇲',
        flagCode: 'jm'
    },
    {
        label: 'Japan',
        code: '+81',
        flagEmoji: '🇯🇵',
        flagCode: 'jp'
    },
    {
        label: 'Jersey',
        code: '+44',
        flagEmoji: '🇯🇪',
        flagCode: 'je'
    },
    {
        label: 'Jordan',
        code: '+962',
        flagEmoji: '🇯🇴',
        flagCode: 'jo'
    },
    {
        label: 'Kazakhstan',
        code: '+7',
        flagEmoji: '🇰🇿',
        flagCode: 'kz'
    },
    {
        label: 'Kenya',
        code: '+254',
        flagEmoji: '🇰🇪',
        flagCode: 'ke'
    },
    {
        label: 'Kiribati',
        code: '+686',
        flagEmoji: '🇰🇮',
        flagCode: 'ki'
    },
    {
        label: 'Korea, Democratic People\'s Republic of Korea',
        code: '+850',
        flagEmoji: '🇰🇵',
        flagCode: 'kp'
    },
    {
        label: 'Korea, Republic of South Korea',
        code: '+82',
        flagEmoji: '🇰🇷',
        flagCode: 'kr'
    },
    {
        label: 'Kosovo',
        code: '+383',
        flag: '🇽🇰',
        flagCode: 'xk'
    },
    {
        label: 'Kuwait',
        code: '+965',
        flagEmoji: '🇰🇼',
        flagCode: 'kw'
    },
    {
        label: 'Kyrgyzstan',
        code: '+996',
        flagEmoji: '🇰🇬',
        flagCode: 'kg'
    },
    {
        label: 'Laos',
        code: '+856',
        flagEmoji: '🇱🇦',
        flagCode: 'la'
    },
    {
        label: 'Latvia',
        code: '+371',
        flagEmoji: '🇱🇻',
        flagCode: 'lv'
    },
    {
        label: 'Lebanon',
        code: '+961',
        flagEmoji: '🇱🇧',
        flagCode: 'lb'
    },
    {
        label: 'Lesotho',
        code: '+266',
        flagEmoji: '🇱🇸',
        flagCode: 'ls'
    },
    {
        label: 'Liberia',
        code: '+231',
        flagEmoji: '🇱🇷',
        flagCode: 'lr'
    },
    {
        label: 'Libyan Arab Jamahiriya',
        code: '+218',
        flagEmoji: '🇱🇾',
        flagCode: 'ly'
    },
    {
        label: 'Liechtenstein',
        code: '+423',
        flagEmoji: '🇱🇮',
        flagCode: 'li'
    },
    {
        label: 'Lithuania',
        code: '+370',
        flagEmoji: '🇱🇹',
        flagCode: 'lt'
    },
    {
        label: 'Luxembourg',
        code: '+352',
        flagEmoji: '🇱🇺',
        flagCode: 'lu'
    },
    {
        label: 'Macao',
        code: '+853',
        flagEmoji: '🇲🇴',
        flagCode: 'mo'
    },
    {
        label: 'Macedonia',
        code: '+389',
        flagEmoji: '🇲🇰',
        flagCode: 'mk'
    },
    {
        label: 'Madagascar',
        code: '+261',
        flagEmoji: '🇲🇬',
        flagCode: 'mg'
    },
    {
        label: 'Malawi',
        code: '+265',
        flagEmoji: '🇲🇼',
        flagCode: 'mw'
    },
    {
        label: 'Malaysia',
        code: '+60',
        flagEmoji: '🇲🇾',
        flagCode: 'my'
    },
    {
        label: 'Maldives',
        code: '+960',
        flagEmoji: '🇲🇻',
        flagCode: 'mv'
    },
    {
        label: 'Mali',
        code: '+223',
        flagEmoji: '🇲🇱',
        flagCode: 'ml'
    },
    {
        label: 'Malta',
        code: '+356',
        flagEmoji: '🇲🇹',
        flagCode: 'mt'
    },
    {
        label: 'Marshall Islands',
        code: '+692',
        flagEmoji: '🇲🇭',
        flagCode: 'mh'
    },
    {
        label: 'Martinique',
        code: '+596',
        flagEmoji: '🇲🇶',
        flagCode: 'mq'
    },
    {
        label: 'Mauritania',
        code: '+222',
        flagEmoji: '🇲🇷',
        flagCode: 'mr'
    },
    {
        label: 'Mauritius',
        code: '+230',
        flagEmoji: '🇲🇺',
        flagCode: 'mu'
    },
    {
        label: 'Mayotte',
        code: '+262',
        flagEmoji: '🇾🇹',
        flagCode: 'yt'
    },
    {
        label: 'Mexico',
        code: '+52',
        flagEmoji: '🇲🇽',
        flagCode: 'mx'
    },
    {
        label: 'Micronesia, Federated States of Micronesia',
        code: '+691',
        flagEmoji: '🇫🇲',
        flagCode: 'fm'
    },
    {
        label: 'Moldova',
        code: '+373',
        flagEmoji: '🇲🇩',
        flagCode: 'md'
    },
    {
        label: 'Monaco',
        code: '+377',
        flagEmoji: '🇲🇨',
        flagCode: 'mc'
    },
    {
        label: 'Mongolia',
        code: '+976',
        flagEmoji: '🇲🇳',
        flagCode: 'mn'
    },
    {
        label: 'Montenegro',
        code: '+382',
        flagEmoji: '🇲🇪',
        flagCode: 'me'
    },
    {
        label: 'Montserrat',
        code: '+1664',
        flagEmoji: '🇲🇸',
        flagCode: 'ms'
    },
    {
        label: 'Morocco',
        code: '+212',
        flagEmoji: '🇲🇦',
        flagCode: 'ma'
    },
    {
        label: 'Mozambique',
        code: '+258',
        flagEmoji: '🇲🇿',
        flagCode: 'mz'
    },
    {
        label: 'Myanmar',
        code: '+95',
        flagEmoji: '🇲🇲',
        flagCode: 'mm'
    },
    {
        label: 'Namibia',
        code: '+264',
        flagEmoji: '🇳🇦',
        flagCode: 'na'
    },
    {
        label: 'Nauru',
        code: '+674',
        flagEmoji: '🇳🇷',
        flagCode: 'nr'
    },
    {
        label: 'Nepal',
        code: '+977',
        flagEmoji: '🇳🇵',
        flagCode: 'np'
    },
    {
        label: 'Netherlands',
        code: '+31',
        flagEmoji: '🇳🇱',
        flagCode: 'nl'
    },
    {
        label: 'New Caledonia',
        code: '+687',
        flagEmoji: '🇳🇨',
        flagCode: 'nc'
    },
    {
        label: 'New Zealand',
        code: '+64',
        flagEmoji: '🇳🇿',
        flagCode: 'nz'
    },
    {
        label: 'Nicaragua',
        code: '+505',
        flagEmoji: '🇳🇮',
        flagCode: 'ni'
    },
    {
        label: 'Niger',
        code: '+227',
        flagEmoji: '🇳🇪',
        flagCode: 'ne'
    },
    {
        label: 'Nigeria',
        code: '+234',
        flagEmoji: '🇳🇬',
        flagCode: 'ng'
    },
    {
        label: 'Niue',
        code: '+683',
        flagEmoji: '🇳🇺',
        flagCode: 'nu'
    },
    {
        label: 'Norfolk Island',
        code: '+672',
        flagEmoji: '🇳🇫',
        flagCode: 'nf'
    },
    {
        label: 'Northern Mariana Islands',
        code: '+1670',
        flagEmoji: '🇲🇵',
        flagCode: 'mp'
    },
    {
        label: 'Norway',
        code: '+47',
        flagEmoji: '🇳🇴',
        flagCode: 'no'
    },
    {
        label: 'Oman',
        code: '+968',
        flagEmoji: '🇴🇲',
        flagCode: 'om'
    },
    {
        label: 'Pakistan',
        code: '+92',
        flagEmoji: '🇵🇰',
        flagCode: 'pk'
    },
    {
        label: 'Palau',
        code: '+680',
        flagEmoji: '🇵🇼',
        flagCode: 'pw'
    },
    {
        label: 'Palestinian Territory, Occupied',
        code: '+970',
        flagEmoji: '🇵🇸',
        flagCode: 'ps'
    },
    {
        label: 'Panama',
        code: '+507',
        flagEmoji: '🇵🇦',
        flagCode: 'pa'
    },
    {
        label: 'Papua New Guinea',
        code: '+675',
        flagEmoji: '🇵🇬',
        flagCode: 'pg'
    },
    {
        label: 'Paraguay',
        code: '+595',
        flagEmoji: '🇵🇾',
        flagCode: 'py'
    },
    {
        label: 'Peru',
        code: '+51',
        flagEmoji: '🇵🇪',
        flagCode: 'pe'
    },
    {
        label: 'Philippines',
        code: '+63',
        flagEmoji: '🇵🇭',
        flagCode: 'ph'
    },
    {
        label: 'Poland',
        code: '+48',
        flagEmoji: '🇵🇱',
        flagCode: 'pl'
    },
    {
        label: 'Portugal',
        code: '+351',
        flagEmoji: '🇵🇹',
        flagCode: 'pt'
    },
    {
        label: 'Puerto Rico',
        code: '+1939',
        flagEmoji: '🇵🇷',
        flagCode: 'pr'
    },
    {
        label: 'Qatar',
        code: '+974',
        flagEmoji: '🇶🇦',
        flagCode: 'qa'
    },
    {
        label: 'Romania',
        code: '+40',
        flagEmoji: '🇷🇴',
        flagCode: 'ro'
    },
    {
        label: 'Russia',
        code: '+7',
        flagEmoji: '🇷🇺',
        flagCode: 'ru'
    },
    {
        label: 'Rwanda',
        code: '+250',
        flagEmoji: '🇷🇼',
        flagCode: 'rw'
    },
    {
        label: 'Saint Barthelemy',
        code: '+590',
        flagEmoji: '🇧🇱',
        flagCode: 'bl'
    },
    {
        label: 'Saint Helena, Ascension and Tristan Da Cunha',
        code: '+290',
        flagEmoji: '🇸🇭',
        flagCode: 'sh'
    },
    {
        label: 'Saint Kitts and Nevis',
        code: '+1869',
        flagEmoji: '🇰🇳',
        flagCode: 'kn'
    },
    {
        label: 'Saint Lucia',
        code: '+1758',
        flagEmoji: '🇱🇨',
        flagCode: 'lc'
    },
    {
        label: 'Saint Martin',
        code: '+590',
        flagEmoji: '🇲🇫',
        flagCode: 'mf'
    },
    {
        label: 'Saint Pierre and Miquelon',
        code: '+508',
        flagEmoji: '🇵🇲',
        flagCode: 'pm'
    },
    {
        label: 'Saint Vincent and the Grenadines',
        code: '+1784',
        flagEmoji: '🇻🇨',
        flagCode: 'vc'
    },
    {
        label: 'Samoa',
        code: '+685',
        flagEmoji: '🇼🇸',
        flagCode: 'ws'
    },
    {
        label: 'San Marino',
        code: '+378',
        flagEmoji: '🇸🇲',
        flagCode: 'sm'
    },
    {
        label: 'Sao Tome and Principe',
        code: '+239',
        flagEmoji: '🇸🇹',
        flagCode: 'st'
    },
    {
        label: 'Saudi Arabia',
        code: '+966',
        flagEmoji: '🇸🇦',
        flagCode: 'sa'
    },
    {
        label: 'Senegal',
        code: '+221',
        flagEmoji: '🇸🇳',
        flagCode: 'sn'
    },
    {
        label: 'Serbia',
        code: '+381',
        flagEmoji: '🇷🇸',
        flagCode: 'rs'
    },
    {
        label: 'Seychelles',
        code: '+248',
        flagEmoji: '🇸🇨',
        flagCode: 'sc'
    },
    {
        label: 'Sierra Leone',
        code: '+232',
        flagEmoji: '🇸🇱',
        flagCode: 'sl'
    },
    {
        label: 'Singapore',
        code: '+65',
        flagEmoji: '🇸🇬',
        flagCode: 'sg'
    },
    {
        label: 'Slovakia',
        code: '+421',
        flagEmoji: '🇸🇰',
        flagCode: 'sk'
    },
    {
        label: 'Slovenia',
        code: '+386',
        flagEmoji: '🇸🇮',
        flagCode: 'si'
    },
    {
        label: 'Solomon Islands',
        code: '+677',
        flagEmoji: '🇸🇧',
        flagCode: 'sb'
    },
    {
        label: 'Somalia',
        code: '+252',
        flagEmoji: '🇸🇴',
        flagCode: 'so'
    },
    {
        label: 'South Africa',
        code: '+27',
        flagEmoji: '🇿🇦',
        flagCode: 'za'
    },
    {
        label: 'South Sudan',
        code: '+211',
        flagEmoji: '🇸🇸',
        flagCode: 'ss'
    },
    {
        label: 'Spain',
        code: '+34',
        flagEmoji: '🇪🇸',
        flagCode: 'es'
    },
    {
        label: 'Sri Lanka',
        code: '+94',
        flagEmoji: '🇱🇰',
        flagCode: 'lk'
    },
    {
        label: 'Sudan',
        code: '+249',
        flagEmoji: '🇸🇩',
        flagCode: 'sd'
    },
    {
        label: 'Suriname',
        code: '+597',
        flagEmoji: '🇸🇷',
        flagCode: 'sr'
    },
    {
        label: 'Svalbard and Jan Mayen',
        code: '+47',
        flagEmoji: '🇸🇯',
        flagCode: 'sj'
    },
    {
        label: 'Swaziland',
        code: '+268',
        flagEmoji: '🇸🇿',
        flagCode: 'sz'
    },
    {
        label: 'Sweden',
        code: '+46',
        flagEmoji: '🇸🇪',
        flagCode: 'se'
    },
    {
        label: 'Switzerland',
        code: '+41',
        flagEmoji: '🇨🇭',
        flagCode: 'ch'
    },
    {
        label: 'Syrian Arab Republic',
        code: '+963',
        flagEmoji: '🇸🇾',
        flagCode: 'sy'
    },
    {
        label: 'Taiwan',
        code: '+886',
        flagEmoji: '🇹🇼',
        flagCode: 'tw'
    },
    {
        label: 'Tajikistan',
        code: '+992',
        flagEmoji: '🇹🇯',
        flagCode: 'tj'
    },
    {
        label: 'Tanzania, United Republic of Tanzania',
        code: '+255',
        flagEmoji: '🇹🇿',
        flagCode: 'tz'
    },
    {
        label: 'Thailand',
        code: '+66',
        flagEmoji: '🇹🇭',
        flagCode: 'th'
    },
    {
        label: 'Timor-Leste',
        code: '+670',
        flagEmoji: '🇹🇱',
        flagCode: 'tl'
    },
    {
        label: 'Togo',
        code: '+228',
        flagEmoji: '🇹🇬',
        flagCode: 'tg'
    },
    {
        label: 'Tokelau',
        code: '+690',
        flagEmoji: '🇹🇰',
        flagCode: 'tk'
    },
    {
        label: 'Tonga',
        code: '+676',
        flagEmoji: '🇹🇴',
        flagCode: 'to'
    },
    {
        label: 'Trinidad and Tobago',
        code: '+1868',
        flagEmoji: '🇹🇹',
        flagCode: 'tt'
    },
    {
        label: 'Tunisia',
        code: '+216',
        flagEmoji: '🇹🇳',
        flagCode: 'tn'
    },
    {
        label: 'Turkey',
        code: '+90',
        flagEmoji: '🇹🇷',
        flagCode: 'tr'
    },
    {
        label: 'Turkmenistan',
        code: '+993',
        flagEmoji: '🇹🇲',
        flagCode: 'tm'
    },
    {
        label: 'Turks and Caicos Islands',
        code: '+1649',
        flagEmoji: '🇹🇨',
        flagCode: 'tc'
    },
    {
        label: 'Tuvalu',
        code: '+688',
        flagEmoji: '🇹🇻',
        flagCode: 'tv'
    },
    {
        label: 'Uganda',
        code: '+256',
        flagEmoji: '🇺🇬',
        flagCode: 'ug'
    },
    {
        label: 'Ukraine',
        code: '+380',
        flagEmoji: '🇺🇦',
        flagCode: 'ua'
    },
    {
        label: 'United Arab Emirates',
        code: '+971',
        flagEmoji: '🇦🇪',
        flagCode: 'ae'
    },
    {
        label: 'United Kingdom',
        code: '+44',
        flagEmoji: '🇬🇧',
        flagCode: 'gb'
    },
    {
        label: 'United States',
        code: '+1',
        flagEmoji: '🇺🇸',
        flagCode: 'us'
    },
    {
        label: 'Uruguay',
        code: '+598',
        flagEmoji: '🇺🇾',
        flagCode: 'uy'
    },
    {
        label: 'Uzbekistan',
        code: '+998',
        flagEmoji: '🇺🇿',
        flagCode: 'uz'
    },
    {
        label: 'Vanuatu',
        code: '+678',
        flagEmoji: '🇻🇺',
        flagCode: 'vu'
    },
    {
        label: 'Venezuela, Bolivarian Republic of Venezuela',
        code: '+58',
        flagEmoji: '🇻🇪',
        flagCode: 've'
    },
    {
        label: 'Vietnam',
        code: '+84',
        flagEmoji: '🇻🇳',
        flagCode: 'vn'
    },
    {
        label: 'Wallis and Futuna',
        code: '+681',
        flagEmoji: '🇼🇫',
        flagCode: 'wf'
    },
    {
        label: 'Yemen',
        code: '+967',
        flagEmoji: '🇾🇪',
        flagCode: 'ye'
    },
    {
        label: 'Zambia',
        code: '+260',
        flagEmoji: '🇿🇲',
        flagCode: 'zm'
    },
    {
        label: 'Zimbabwe',
        code: '+263',
        flagEmoji: '🇿🇼',
        flagCode: 'zw'
    }
].sort((a, b) => {
    if (a.code.length !== b.code.length) {
        return a.code.length - b.code.length
    }

    return a.code.localeCompare(b.code)
}) as Array<Country>

export const DEFAULT_COUNTRY = COUNTRIES
    .find(country => country.code === '+65') as Country
