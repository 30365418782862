import React from 'react'
import { View } from 'react-native'
import { createStyles } from 'lib/styles'
import { Touchable, Typography, Grid } from 'lib/components'
import { useStyles, useTimer, useTranslations } from 'lib/hooks'
import { CancelButton } from './CancelButton'

type CheckEmailMessageProps = {
    email: string,
    onResend: VoidFunction
}

export const CheckEmailMessage: React.FunctionComponent<CheckEmailMessageProps> = ({
    email,
    onResend
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const { startTimer, isTimerInProgress, timer } = useTimer(59)

    return (
        <View style={styles.container}>
            <View style={styles.row}>
                <Typography.SmallSubheading style={styles.textCenter}>
                    {T.screens.authForgotPassword.checkEmail}
                </Typography.SmallSubheading>
            </View>
            <View style={styles.row}>
                <Typography.Regular style={styles.message}>
                    {T.screens.authForgotPassword.checkEmailMessage}
                </Typography.Regular>
            </View>
            <View style={styles.row}>
                <Typography.Regular
                    bold
                    style={styles.message}
                >
                    {email}
                </Typography.Regular>
            </View>
            <View style={styles.row}>
                {isTimerInProgress && (
                    <Typography.Label
                        bold
                        style={styles.textCenter}
                    >
                        {`${T.screens.authForgotPassword.wait} ${timer}${T.common.seconds}`}
                    </Typography.Label>
                )}
                {!isTimerInProgress && (
                    <Touchable
                        onPress={() => {
                            onResend()
                            startTimer()
                        }}
                    >
                        <View style={styles.resendCodeLink}>
                            <Typography.Label style={styles.textCenter}>
                                {`${T.screens.authForgotPassword.didntReceiveEmail} `}
                            </Typography.Label>
                            <Typography.Label
                                bold
                                style={styles.textUnderline}
                            >
                                {`${T.screens.authForgotPassword.resendEmail}`}
                            </Typography.Label>
                        </View>
                    </Touchable>
                )}
            </View>
            <Grid.Gap gapTop={3}>
                <CancelButton isDisabled={false} />
            </Grid.Gap>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        marginTop: {
            lg: 0,
            xs: theme.utils.gap(3)
        }
    },
    textCenter: {
        textAlign: 'center'
    },
    row: {
        marginBottom: theme.utils.gap(3)
    },
    resendCodeLink: {
        alignSelf: 'center',
        flexDirection: 'row'
    },
    textUnderline: {
        borderBottomWidth: 1,
        borderBottomColor: theme.colors.night
    },
    message: {
        textAlign: 'center',
        color: theme.colors.darkGrey
    }
}))
