import React, { useState } from 'react'
import { View } from 'react-native'
import { CallToActionButton, MediaQuery, PhotoGallery, Typography } from 'lib/components'
import { Breakpoint, createStyles, theme } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { CustomerChargeStatus, JobStatus } from 'lib/types'
import { dateHelpers, stringHelpers } from 'lib/utils'
import { JobDetailsResponse } from '../types'
import { AirConditionServiceDate } from './AirConditionServiceDate'
import { CancelRequest } from './CancelRequest'
import { BookingsProposals } from './BookingsProposals'
import { JobBillings } from './JobBillings'
import { JobDetailsRow } from './JobDetailsRow'
import { JobName } from './JobName'
import { JobRating } from './JobRating'
import { RescheduleSession } from './RescheduleSession'
import { ServiceDetailsAccepted } from './ServiceDetailsAccepted'
import { SubmittedMessage } from './SubmittedMessage'
import { PropertyInfoContent } from './PropertyInfoContent'

type MoversAndPackersDetailsContentProps = {
    data: JobDetailsResponse,
    jobStatus?: JobStatus
}

export const MoversAndPackersDetailsContent: React.FunctionComponent<MoversAndPackersDetailsContentProps> = ({
    data,
    jobStatus
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const isSubmitted = data.job.status === JobStatus.Submitted
    const [isGalleryOpen, setIsGalleryOpen] = useState(false)
    const photos = data.job.photos
    const {
        service_type,
        location,
        message_to_supplier,
        drop_off_location,
        pick_up_property_info,
        drop_off_property_info,
        inspection_date_availability,
        additional_required_services
    } = data.job
    const pickUpLocationUnit = stringHelpers.getFormattedAddressUnit(location.unit)
    const dropOffLocationUnit = stringHelpers.getFormattedAddressUnit(drop_off_location.unit)

    return (
        <View style={styles.wrapper}>
            <View style={styles.contentWrapper}>
                <View style={styles.content}>
                    <JobName
                        job={data}
                        isRecurring={false}
                    />
                    {isSubmitted && (
                        <SubmittedMessage />
                    )}
                    <AirConditionServiceDate
                        job={data}
                        isSubmitted={isSubmitted}
                    />
                    <RescheduleSession
                        data={data}
                        label={T.screens.moversAndPackersDetails.rescheduleService}
                        description={T.screens.moversAndPackersDetails.rescheduleDescription}
                        buttonWidth={260}
                    />
                    <JobDetailsRow
                        leftTitle={T.screens.moversAndPackersDetails.expertiseType}
                        leftMessage={service_type}
                        rightTitle={T.screens.moversAndPackersDetails.inspectionDate}
                        rightMessage={inspection_date_availability.map(item => `${dateHelpers.shortFullDate(item.date)} - ${item.availability}`).join(', ')}
                    />
                    <PropertyInfoContent
                        serviceType={service_type}
                        title={T.screens.moversAndPackersDetails.pickUpLocation}
                        location={`${pickUpLocationUnit}${location.address}, ${location.postcode}`}
                        propertyInfo={pick_up_property_info}
                    />
                    <PropertyInfoContent
                        serviceType={service_type}
                        title={T.screens.moversAndPackersDetails.dropOffLocation}
                        location={`${dropOffLocationUnit}${drop_off_location.address}, ${drop_off_location.postcode}`}
                        propertyInfo={drop_off_property_info}
                    />
                    {Boolean(message_to_supplier) && (
                        <JobDetailsRow
                            leftTitle={T.screens.moversAndPackersDetails.serviceInformation}
                            leftMessage={message_to_supplier || ''}
                        />
                    )}
                    <JobDetailsRow
                        leftTitle={T.screens.jobDetails.sections.promotionCode}
                        leftMessage={data.job.promotion?.code ?? '-'}
                    />
                    {photos.length > 0 && (
                        <View style={styles.buttonWrapper}>
                            <CallToActionButton
                                onPress={() => setIsGalleryOpen(true)}
                                text={T.common.viewImages}
                            />
                        </View>
                    )}
                    <PhotoGallery
                        photos={photos}
                        isVisible={isGalleryOpen}
                        onClose={() => setIsGalleryOpen(false)}
                    />
                    {additional_required_services.length > 0 && (
                        <View style={styles.contentWrapper}>
                            <View style={styles.title}>
                                <Typography.Label forceColor={theme.colors.mouse}>
                                    {T.screens.moversAndPackersDetails.addonServices}
                                </Typography.Label>
                            </View>
                            {additional_required_services.map((item, index) => (
                                <Typography.Label
                                    key={index}
                                    style={styles.title}
                                >
                                    {`${index + 1}. ${item.type}`}
                                </Typography.Label>
                            ))}
                        </View>
                    )}
                    {data.job.customer_charges?.length === 0 && (
                        <CancelRequest
                            job={data}
                            text={isSubmitted
                                ? T.screens.jobDetails.cancelRequest
                                : undefined
                            }
                            jobStatus={jobStatus}
                        />
                    )}
                </View>
                {jobStatus === JobStatus.Completed && (
                    <JobRating job={data} />
                )}
            </View>
            {isSubmitted
                ? (
                    <BookingsProposals
                        job={data}
                        isRecurring={false}
                    />
                ) : (
                    <React.Fragment>
                        <MediaQuery.Visible from={Breakpoint.LG}>
                            <ServiceDetailsAccepted
                                job={data}
                                jobStatus={jobStatus}
                                isRecurringAccepted={false}
                            />
                        </MediaQuery.Visible>
                        {data.job.customer_charges?.some(item => item.auth_status === CustomerChargeStatus.Pending) && data.job.status === JobStatus.Accepted && (
                            <MediaQuery.Hidden from={Breakpoint.LG}>
                                <View style={styles.billingWrapper}>
                                    <JobBillings job={data.job} />
                                </View>
                            </MediaQuery.Hidden>
                        )}
                    </React.Fragment>
                )
            }
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        flexDirection: {
            lg: 'row',
            xs: 'column'
        },
        alignItems: {
            lg: 'flex-start',
            xs: undefined
        },
        marginTop: -theme.utils.gap(6),
        paddingHorizontal: {
            lg: theme.utils.gap(6),
            xs: theme.utils.gap(2)
        }
    },
    contentWrapper: {
        flex: 1
    },
    content: {
        borderRadius: 8,
        borderWidth: 1,
        paddingHorizontal: theme.utils.gap(2),
        paddingVertical: theme.utils.gap(3),
        backgroundColor: theme.colors.white,
        borderColor: theme.colors.silver,
        backGroundColor: theme.colors.white,
        ...theme.utils.createShadow(2, 0, 8, 4, 4, theme.colors.webShadow(0.04))
    },
    buttonWrapper: {
        width: {
            lg: 240,
            xs: undefined
        },
        marginBottom: theme.utils.gap(2)
    },
    billingWrapper: {
        marginTop: theme.utils.gap(2)
    },
    title: {
        marginBottom: theme.utils.gap(1)
    }
}))
