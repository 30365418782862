import React from 'react'
import { View } from 'react-native'
import { Job } from 'lib/models'
import { JobStatus } from 'lib/types'
import { createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { Typography } from 'lib/components'

type RateExpertProps = {
    job: Job
}

export const RateExpert: React.FunctionComponent<RateExpertProps> = ({
    job
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)

    if (job.status === JobStatus.Rated) {
        // TODO blocked - we do not have rating points here from API
        return null
    }

    return (
        <View style={styles.button}>
            <Typography.Label
                bold
                forceColor={theme.colors.orange}
            >
                {T.components.rateJob.rateExpert}
            </Typography.Label>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    button: {
        borderBottomWidth: 1,
        borderBottomColor: theme.colors.orange
    }
}))
