import React, { useState } from 'react'
import Animated, { FadeIn } from 'react-native-reanimated'
import { View } from 'react-native'
import { useForm } from '@codegateinc/react-form-builder-v2'
import { useUserAtom, useUserTokenAtom } from 'lib/atoms'
import { createStyles } from 'lib/styles'
import { Adapter, Button, FormComponents, Typography } from 'lib/components'
import { useGetErrorMessage, useStyles, useTranslations } from 'lib/hooks'
import { EditProfileFormShape, useEditProfileForm } from '../forms'
import { editProfile } from '../actions'

type UserEditProfileFormProps = {
    onCloseEditMode: VoidFunction
}

export const UserEditProfileForm: React.FunctionComponent<UserEditProfileFormProps> = ({ onCloseEditMode }) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const { getErrorMessage } = useGetErrorMessage()
    const [error, setError] = useState('')
    const [user, setUser] = useUserAtom()
    const [token] = useUserTokenAtom()
    const { mutate: onEditProfile, isLoading } = editProfile()
    const { form, submit, hasError, isFilled } = useForm<EditProfileFormShape>(useEditProfileForm(), {
        onSuccess: ({ name }) => {
            onEditProfile(
                {
                    token,
                    handle: name,
                    phone: user.phone
                },
                {
                    onSuccess: () => {
                        setUser(prevValue => ({
                            ...prevValue,
                            handle: name
                        }))

                        onCloseEditMode()
                    },
                    onError: response => setError(getErrorMessage(response))
                }
            )
        }
    })

    return (
        <Animated.View
            entering={FadeIn}
            style={styles.container}
        >
            <Adapter.TextInputWithLabel
                {...form.name}
                disabled={isLoading}
            />
            <Adapter.TextInputWithLabel
                {...form.email}
                // TODO Edit profile request do not accept email field at the moment
                disabled
                inputProps={{
                    autoComplete: 'email',
                    keyboardType: 'email-address',
                    textContentType: 'emailAddress',
                    autoCapitalize: 'none',
                    autoCorrect: false
                }}
            />
            <View style={styles.phoneNumberRow}>
                <View style={styles.labelContainer}>
                    <Typography.Regular>
                        <Typography.Regular>
                            {form.phoneNumber.label}
                        </Typography.Regular>
                        <Typography.Label forceColor={theme.components.input.typography.error}>
                            {form.phoneNumber.isRequired ? '*' : ''}
                        </Typography.Label>
                    </Typography.Regular>
                </View>
                <View style={styles.phoneInput}>
                    <Adapter.TextInput
                        {...form.phoneNumber}
                        disabled
                        inputProps={{
                            keyboardType: 'phone-pad',
                            contextMenuHidden: true,
                            textContentType: 'telephoneNumber',
                            dataDetectorTypes: 'phoneNumber'
                        }}
                    />
                </View>
            </View>
            <FormComponents.ErrorMessage text={error} />
            <View style={styles.buttons}>
                <View style={styles.button}>
                    <Button
                        noBackground
                        onPress={onCloseEditMode}
                        text={T.common.cancel}
                        disabled={isLoading}
                    />
                </View>
                <View style={styles.buttonSeparator} />
                <View style={styles.button}>
                    <Button
                        onPress={submit}
                        text={T.common.save}
                        isLoading={isLoading}
                        disabled={!isFilled || hasError}
                    />
                </View>
            </View>
        </Animated.View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        marginTop: theme.utils.gap(2)
    },
    buttons: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginBottom: theme.utils.gap(3)
    },
    buttonSeparator: {
        width: theme.utils.gap(2)
    },
    button: {
        flex: {
            xs: 1,
            lg: undefined
        },
        width: 120
    },
    phoneInput: {
        flex: 1
    },
    phoneNumberRow: {
        flexDirection: {
            xs: 'column',
            lg: 'row'
        },
        zIndex: theme.zIndex[10]
    },
    labelContainer: {
        width: {
            xs: '100%',
            lg: 120
        },
        marginRight: {
            lg: theme.utils.gap(1),
            xs: 0
        },
        marginBottom: {
            lg: 0,
            xs: theme.utils.gap(1)
        }
    }
}))

