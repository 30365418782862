import React from 'react'
import { View, Image } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { useForm } from '@codegateinc/react-form-builder-v2'
import { AvoidSoftInputView } from 'react-native-avoid-softinput'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import Animated, { Extrapolate, interpolate, useAnimatedScrollHandler, useAnimatedStyle, useSharedValue } from 'react-native-reanimated'
import { Icons, Images } from 'assets'
import { Measurements } from 'lib/common'
import { NavigationParams, ScreenNames } from 'lib/routing'
import { createStyles } from 'lib/styles'
import { useAvoidInput, useBackHandler, useStyles, useTranslations } from 'lib/hooks'
import { Adapter, Button, Separator, Touchable, Typography } from 'lib/components'
import { useSignIn } from '../hooks'
import { SocialButtons } from '../components'
import { LogInFields, LogInFormShape, useLogInForm } from '../forms'

type LogInNativeScreenProps = {
    route: NavigationParams<ScreenNames.LogInNative>
}

export const LogInNativeScreen: React.FunctionComponent<LogInNativeScreenProps> = ({ route }) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const navigation = useNavigation()
    const insets = useSafeAreaInsets()
    const { signInWithEmail, signInWithFacebook, isLoading, signInWithApple, signInWithGoogle } = useSignIn(error => setError(LogInFields.Password, error))
    const { form, submit, isFilled, setError, hasError } = useForm<LogInFormShape>(useLogInForm(), {
        onSuccess: ({ email, password }) => signInWithEmail(email, password)
    })
    const translationY = useSharedValue(0)
    const scrollHandler = useAnimatedScrollHandler(event => {
        translationY.value = event.contentOffset.y
    })
    const animatedScale = useAnimatedStyle(() => ({
        transform: [
            {
                scale: interpolate(translationY.value, [-100, 0, 150], [1.10, 1, 0.95], Extrapolate.CLAMP)
            }
        ]
    }))

    useAvoidInput()
    useBackHandler(() => isLoading)

    return (
        <View style={styles.wrapper}>
            <Animated.View
                style={[
                    styles.imageWrapper,
                    animatedScale
                ]}
            >
                <Image
                    resizeMode="contain"
                    source={Images.LogInHero}
                    style={styles.image}
                />
            </Animated.View>
            <Animated.ScrollView
                onScroll={scrollHandler}
                scrollEventThrottle={16}
                showsVerticalScrollIndicator={false}
            >
                <View
                    style={{
                        ...styles.contentContainer,
                        paddingBottom: insets.bottom + theme.utils.gap(2)
                    }}
                >
                    <AvoidSoftInputView>
                        <View style={styles.headerText}>
                            <Typography.Subheading>
                                {T.common.logIn}
                            </Typography.Subheading>
                            <Touchable
                                style={styles.subHeaderText}
                                onPress={() => navigation.navigate(ScreenNames.SignUpNative)}
                            >
                                <Typography.Label forceColor={theme.colors.darkGrey}>
                                    {T.screens.auth.noAccountText}
                                </Typography.Label>
                                <Typography.Label
                                    bold
                                    forceColor={theme.colors.orange}
                                >
                                    {` ${T.common.signUp}`}
                                </Typography.Label>
                            </Touchable>
                        </View>
                        <Adapter.TextInput
                            {...form.email}
                            disabled={isLoading}
                            inputProps={{
                                autoComplete: 'email',
                                autoCapitalize: 'none',
                                textContentType: 'emailAddress',
                                keyboardType: 'email-address',
                                autoCorrect: false,
                                onSubmitEditing: submit,
                                testID: 'email'
                            }}
                        />
                        <Adapter.TextInput
                            {...form.password}
                            disabled={isLoading}
                            inputProps={{
                                autoCorrect: false,
                                textContentType: 'password',
                                autoComplete: 'password',
                                secureTextEntry: true,
                                onSubmitEditing: submit,
                                testID: 'password'
                            }}
                        />
                        <View style={styles.forgotPasswordContainer}>
                            <Touchable
                                disabled={isLoading}
                                onPress={() => navigation.navigate(ScreenNames.AuthForgotPassword)}
                                style={styles.forgotButton}
                            >
                                <Typography.Regular bold>
                                    {T.screens.auth.forgotPassword}
                                </Typography.Regular>
                            </Touchable>
                        </View>
                        <View style={styles.submit}>
                            <Button
                                onPress={submit}
                                text={T.common.continue}
                                isLoading={isLoading}
                                disabled={!isFilled || hasError}
                                renderIcon={color => (
                                    <Icons.Arrow
                                        size={14}
                                        forceColor={color}
                                    />
                                )}
                                testID="login-submit-button"
                            />
                        </View>
                        <Separator
                            height={20}
                            text={T.screens.auth.loginSeparatorNative}
                        />
                        <SocialButtons
                            disabled={isLoading}
                            onContinueWithFacebook={signInWithFacebook}
                            onContinueWithApple={signInWithApple}
                            onContinueWithGoogle={signInWithGoogle}
                        />
                        {route.params?.resetPasswordSuccessMessage && (
                            <View style={styles.resetPasswordMessage}>
                                <Typography.Label style={styles.resetPasswordMessageText}>
                                    {T.screens.auth.resetPasswordSuccessMessage}
                                </Typography.Label>
                            </View>
                        )}
                    </AvoidSoftInputView>
                </View>
            </Animated.ScrollView>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        flex: 1,
        backgroundColor: theme.colors.white
    },
    imageWrapper: {
        left: -20,
        right: -20,
        top: -20,
        bottom: -20,
        flex: 1,
        height: Measurements.WindowHeight / 2,
        position: 'absolute'
    },
    image: {
        width: '100%',
        height: '100%'
    },
    contentContainer: {
        marginTop: (Measurements.WindowHeight / 2) - 60,
        borderTopLeftRadius: 15,
        borderTopRightRadius: 15,
        backgroundColor: theme.colors.white,
        paddingHorizontal: theme.utils.gap(2),
        paddingTop: theme.utils.gap(2)
    },
    forgotPasswordContainer: {
        alignItems: 'flex-end',
        marginBottom: {
            lg: theme.utils.gap(3),
            xs: theme.utils.gap(2)
        }
    },
    forgotButton: {
        borderBottomWidth: 1
    },
    submit: {
        alignItems: 'center'
    },
    headerText: {
        alignItems: 'center',
        marginBottom: theme.utils.gap(2)
    },
    subHeaderText: {
        flexDirection: 'row',
        marginTop: theme.utils.gap(1)
    },
    resetPasswordMessage: {
        marginVertical: theme.utils.gap(3)
    },
    resetPasswordMessageText: {
        textAlign: 'center'
    }
}))
