import React from 'react'
import { View } from 'react-native'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { Label } from './typography'

type FormFieldDescriptionProps = {
    title: string,
    message?: string
}

export const FormFieldDescription: React.FunctionComponent<FormFieldDescriptionProps> = ({
    title,
    message
}) => {
    const { styles } = useStyles(stylesheet)

    if (!message) {
        return null
    }

    return (
        <View style={styles.container}>
            <View style={styles.title}>
                <Label bold>
                    {title}
                </Label>
            </View>
            <Label>
                {message}
            </Label>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        borderRadius: 8,
        backgroundColor: theme.colors.marble,
        marginBottom: theme.utils.gap(2),
        padding: theme.utils.gap(2)
    },
    title: {
        marginBottom: theme.utils.gap(2)
    }
}))
