import React from 'react'
import { View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { Button } from 'lib/components'
import { ENV } from 'lib/config'
import { ScreenNames } from 'lib/routing'
import { createStyles } from 'lib/styles'
import { linkingHelpers } from 'lib/utils'
import { useStyles, useTranslations } from 'lib/hooks'
import { useIsAuthorizedAtom } from 'lib/atoms'

type BookingSuccessButtonsProps = {
    hideBackToHomeButton?: boolean,
    hideMyBookingButton?: boolean,
}

export const BookingSuccessButtons: React.FunctionComponent<BookingSuccessButtonsProps> = ({
    hideBackToHomeButton,
    hideMyBookingButton
}) => {
    const T = useTranslations()
    const [isAuthorized] = useIsAuthorizedAtom()
    const { styles } = useStyles(stylesheet)
    const navigation = useNavigation()

    return (
        <View style={styles.container}>
            {!hideBackToHomeButton && (
                <View style={styles.button}>
                    <Button
                        noBackground
                        text={T.screens.bookingSuccess.buttons.home}
                        onPress={() => linkingHelpers.openUrl(`${ENV.SENDHELPER_MARKETING_SITE_URL}`, false)}
                    />
                </View>
            )}

            {!hideMyBookingButton && isAuthorized && (
                <View style={styles.button}>
                    <Button
                        text={T.screens.bookingSuccess.buttons.myBookings}
                        onPress={() => navigation.navigate(ScreenNames.Bookings)}
                    />
                </View>
            )}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        marginTop: theme.utils.gap(2)
    },
    button: {
        width: {
            lg: 240,
            xs: 140
        },
        marginHorizontal: {
            lg: theme.utils.gap(1),
            xs: theme.utils.gap(1) / 2
        }
    }
}))
