import { useField } from '@codegateinc/react-form-builder-v2'
import { useTranslations } from 'lib/hooks'
import { ChatFields } from './forms'

export const useChatForm = (isMessageRequired: boolean = true) => {
    const T = useTranslations()

    const message = useField<string>({
        key: ChatFields.Message,
        initialValue: '',
        isRequired: isMessageRequired,
        validateOnBlur: false,
        placeholder: T.screens.chat.formFields.message.placeholder,
        validationRules: isMessageRequired
            ? [
                {
                    errorMessage: T.screens.chat.formFields.message.validation.isRequired,
                    validate: Boolean
                }
            ]
            : undefined
    })

    return {
        message
    }
}
