import React from 'react'
import { Icons } from 'assets'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { Touchable } from '../Touchable'

type HeaderMenuIconProps = {
    onPress: VoidFunction,
    isOpen: boolean,
    disabled?: boolean
}

export const HeaderMenuIcon: React.FunctionComponent<HeaderMenuIconProps> = ({
    onPress,
    isOpen,
    disabled = false
}) => {
    const { styles } = useStyles(stylesheet)

    return (
        <Touchable
            disabled={disabled}
            onPress={onPress}
            style={styles.container}
        >
            {isOpen
                ? (
                    <Icons.Close size={16} />
                ) : (
                    <Icons.Burger size={16} />
                )
            }
        </Touchable>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        paddingHorizontal: theme.utils.gap(2)
    }
}))
