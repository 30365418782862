export enum FirebaseAuthError {
    InvalidNumber = 'auth/invalid-phone-number',
    MissingPhoneNumber = 'auth/missing-phone-number',
    QuotaExceeded = 'auth/quota-exceeded',
    UserDisabled = 'auth/user-disabled',
    NotAllowed = 'auth/operation-not-allowed',
    InvalidVerificationCode = 'auth/invalid-verification-code',
    MissingVerificationCode = 'auth/missing-verification-code',
    TooManyRequests = 'auth/too-many-requests',
}
