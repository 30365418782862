import React from 'react'
import { View } from 'react-native'
import { createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { CustomFonts } from 'lib/types'
import { Typography } from 'lib/components'
import { useSubscriptionRecommendationListAtom, useSubscriptionSpecialRecommendationAtom } from 'lib/atoms'
import { R } from 'lib/utils'
import { SubscriptionRecommendation } from 'features/bookings/components/SubscriptionRecommendation'
import { SubscriptionSpecialRecommendation } from 'features/bookings/components/SubscriptionSpecialRecommendation'

type SubscriptionsRecommendationProps = object

export const SubscriptionsRecommendation: React.FunctionComponent<SubscriptionsRecommendationProps> = () => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)

    const [subscriptionSpecialRecommendation] = useSubscriptionSpecialRecommendationAtom()
    const [subscriptionRecommendationList] = useSubscriptionRecommendationListAtom()
    const subscriptionRecommendation = subscriptionRecommendationList?.[0]

    const specialRecommendationSupplyId = subscriptionSpecialRecommendation?.supplyId
    const recommendationSupplyId = subscriptionRecommendation?.supplyId

    const isSpecialRecommendationDefined = R.isDefined(specialRecommendationSupplyId)
    const isRecommendationDefined = R.isDefined(recommendationSupplyId)

    const shouldShowRecommendation = isSpecialRecommendationDefined || isRecommendationDefined
    const shouldShowSpacer = isSpecialRecommendationDefined && isRecommendationDefined

    return shouldShowRecommendation ? (
        <View style={styles.wrapper}>
            <View style={styles.titleContainer}>
                <Typography.Regular style={styles.title}>{T.subscriptionsRecommendation.title}</Typography.Regular>
            </View>
            <View style={styles.subscriptionContainer}>
                <SubscriptionSpecialRecommendation/>
                { shouldShowSpacer && <View style={styles.spacer}/> }
                <SubscriptionRecommendation/>
            </View>
        </View>
    ) : null
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        backgroundColor: theme.colors.marble,
        paddingBottom: theme.utils.gap(4),
        paddingTop: theme.utils.gap(0.5),
        marginHorizontal: {
            lg: -theme.utils.gap(6),
            xs: -theme.utils.gap(1)
        },
        paddingHorizontal: {
            lg: theme.utils.gap(6),
            xs: theme.utils.gap(2)
        }
    },
    titleContainer: {
        paddingVertical: theme.utils.gap(1.75)
    },
    title: {
        fontSize: 16,
        fontFamily: CustomFonts.Poppins500
    },
    subscriptionContainer: {
        display: 'flex',
        flexDirection: {
            xl: 'row',
            lg: 'column'
        },
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    spacer: {
        width: {
            xl: theme.utils.gap(5),
            lg: theme.utils.gap(4),
            md: theme.utils.gap(3),
            sm: theme.utils.gap(2),
            xs: theme.utils.gap(2)
        },
        height: theme.utils.gap(2)
    }
}))
