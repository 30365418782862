import { useNavigation } from '@react-navigation/native'
import { useTranslations } from 'lib/hooks'
import { ScreenNames } from 'lib/routing'

export const usePaymentTabs = () => {
    const T = useTranslations()
    const navigation = useNavigation()

    return [
        {
            text: T.components.paymentTabs.cards,
            screenName: ScreenNames.UserCards,
            onPress: () => navigation.navigate(ScreenNames.UserCards)
        }
        // todo for now not available
        // {
        //     text: T.components.paymentTabs.paymentHistory,
        //     screenName: ScreenNames.UserCardsHistory,
        //     onPress: () => navigation.navigate(ScreenNames.UserCardsHistory)
        // }
    ]
}
