import React from 'react'
import { View } from 'react-native'
import { Children } from 'lib/types'
import { createStyles } from 'lib/styles'
import { useStyles } from 'lib/hooks'

type ListBulletProps = {
    children: Children
}

export const ListBullet: React.FunctionComponent<ListBulletProps> = ({ children }) => {
    const { styles } = useStyles(stylesheet)

    return (
        <View style={styles.container}>
            <View style={styles.bullet} />
            {children}
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: theme.utils.gap(1)
    },
    bullet: {
        width: 3,
        height: 3,
        borderRadius: 3 / 2,
        marginHorizontal: theme.utils.gap(1),
        backgroundColor: theme.colors.darkGrey
    }
}))
