import React, { useState } from 'react'
import { isNative } from 'lib/common'
import { useUserAtom } from 'lib/atoms'
import { AppLayout } from 'lib/components'
import { Breakpoint, createStyles } from 'lib/styles'
import { NavigationProps, ScreenNames } from 'lib/routing'
import { useIsWithinBreakpoints, useStyles } from 'lib/hooks'
import { ReferFriendSection, ReferralCodeMobile, ShareFaqSection, ShareStepsSection } from '../components'

type ReferScreenProps = {
    navigation: NavigationProps<ScreenNames.Refer>
}

export const ReferScreen: React.FunctionComponent<ReferScreenProps> = () => {
    const { styles } = useStyles(stylesheet)
    const [isShareModalOpen, setShareModalOpen] = useState(false)
    const isMobile = useIsWithinBreakpoints(Breakpoint.XS, Breakpoint.SM)
    const [user] = useUserAtom()

    return (
        <AppLayout
            stickyHeaderIndices={isNative
                ? [1]
                : undefined
            }
            customContainerStyles={styles.container}
        >
            <ReferFriendSection
                isShareModalOpen={isShareModalOpen}
                setShareModalOpen={setShareModalOpen}
            />
            {isMobile && (
                <ReferralCodeMobile
                    code={user.referral.promo_code}
                    onPress={() => setShareModalOpen(true)}
                />
            )}
            <ShareStepsSection />
            <ShareFaqSection />
        </AppLayout>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        paddingHorizontal: 0
    }
}))
