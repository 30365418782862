import { dateHelpers } from 'lib/utils'
import { TimeSlot } from '../types'
import { useServiceConfigAtom } from 'lib/atoms'
import { SupplyID } from 'lib/types'

type ValidateTimeSlotsProps = {
    timeSlots: Array<TimeSlot>,
    bookingTime: string,
    startingDate: string,
    minHoursNotice: number
}

export const validateTimeSlots = (props: ValidateTimeSlotsProps) => {
    const { timeSlots, bookingTime, startingDate, minHoursNotice } = props
    const matchingTimeSlot = timeSlots?.find(timeSlot => timeSlot.display === bookingTime)

    if (!matchingTimeSlot) {
        return false
    }

    const startDateTime = dateHelpers.getStartDateOfTimeSlot(matchingTimeSlot, startingDate)

    return dateHelpers.validateNoticeHours(startDateTime, minHoursNotice)
}

export const getServiceStaticConfig = (supplyId: SupplyID) => {
    const [serviceConfig] = useServiceConfigAtom()

    return serviceConfig[supplyId]
}

export const pickSharedConfig = (supplyId: SupplyID, configs) => {
    const config = configs[supplyId]
    const { id, name, use_preferred_helper, sort_order, _id, price_per_hour, recurring_price_per_hour } = config

    return {
        id,
        _id,
        name,
        sort_order,
        price_per_hour,
        recurring_price_per_hour,
        use_preferred_helper
    }
}

export const getSharedConfigState = configs => {
    const cleaningSharedConfig = pickSharedConfig(SupplyID.Cleaner, configs)
    const deepCleaningSharedConfig = pickSharedConfig(SupplyID.DeepCleaning, configs)
    const airConditionSharedConfig = pickSharedConfig(SupplyID.ACService, configs)
    const handymanSharedConfig = pickSharedConfig(SupplyID.Handyman, configs)
    const laundrySharedConfig = pickSharedConfig(SupplyID.Laundry, configs)
    const pestControlSharedConfig = pickSharedConfig(SupplyID.PestControl, configs)
    const cookingSharedConfig = pickSharedConfig(SupplyID.Cooking, configs)
    const taskErrandsSharedConfig = pickSharedConfig(SupplyID.TasksErrands, configs)
    const moversAndPackersSharedConfig = pickSharedConfig(SupplyID.MoversPackers, configs)

    return [
        cleaningSharedConfig,
        deepCleaningSharedConfig,
        airConditionSharedConfig,
        handymanSharedConfig,
        laundrySharedConfig,
        pestControlSharedConfig,
        cookingSharedConfig,
        taskErrandsSharedConfig,
        moversAndPackersSharedConfig
    ]
}
