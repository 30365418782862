import React, { useState } from 'react'
import { Keyboard, View } from 'react-native'
import { useIsMutating } from '@tanstack/react-query'
import { useForm } from '@codegateinc/react-form-builder-v2'
import { LinearGradient } from 'expo-linear-gradient'
import { Nullable } from 'lib/types'
import { MutationKey } from 'lib/api'
import { useUserAtom } from 'lib/atoms'
import { CreditPackage } from 'lib/models'
import { Breakpoint, createStyles } from 'lib/styles'
import { NavigationProps, ScreenNames } from 'lib/routing'
import { AnalyticsEvent, AnalyticsEventDescription, GoogleAnalytics } from 'lib/analytics'
import { SendPayCreditsFormShape, useSendPayCredits } from 'features/bookings'
import { useFocusRefetch, useIsWithinBreakpoints, usePrice, useStyles, useTranslations } from 'lib/hooks'
import { Adapter, AppLayout, Button, LoadingIndicator, SeparatorWithBorder, Typography } from 'lib/components'
import { SendPayPaymentErrorModal, SendPayPaymentModal } from '../components'
import { getPromotionPackages } from '../actions'

type SendPayScreenProps = {
    navigation: NavigationProps<ScreenNames.SendPay>
}

export const SendPayScreen: React.FunctionComponent<SendPayScreenProps> = () => {
    const T = useTranslations()
    const [user] = useUserAtom()
    const { styles, theme } = useStyles(stylesheet)
    const isFetchingProfile = useIsMutating([MutationKey.GetMe])
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false)
    const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false)
    const [selectedPackage, setSelectedPackage] = useState<Nullable<CreditPackage>>(null)
    const { isLoading: isFetchingPackages, refetch, isRefetching } = getPromotionPackages()
    const isMobile = useIsWithinBreakpoints(Breakpoint.XS, Breakpoint.MD)
    const { form, submit, hasError, isFilled, resetForm } = useForm<SendPayCreditsFormShape>(useSendPayCredits(), {
        onSuccess: () => {
            GoogleAnalytics.logEvent({
                eventType: AnalyticsEvent.UserAction,
                description: AnalyticsEventDescription.SendPayCredits
            })

            setIsPaymentModalOpen(true)
        }
    })
    const isLoading = isFetchingPackages || isRefetching || Boolean(isFetchingProfile)
    const creditsBalance = usePrice(user.consumer.balance_credit)

    useFocusRefetch(() => {
        refetch()
    })

    const onPaymentError = () => {
        setIsErrorModalOpen(true)
        setIsPaymentModalOpen(false)
    }

    const onTryAgain = () => {
        setIsErrorModalOpen(false)
        setIsPaymentModalOpen(true)
    }

    const onClickPurchase = () => {
        submit()
        Keyboard.dismiss()
    }

    return (
        <AppLayout isLoading={isLoading}>
            <SendPayPaymentErrorModal
                isOpen={isErrorModalOpen}
                onClose={() => setIsErrorModalOpen(false)}
                onConfirm={onTryAgain}
            />
            <SendPayPaymentModal
                onSuccess={resetForm}
                onError={onPaymentError}
                creditPackage={selectedPackage}
                amount={selectedPackage?.price || form.amount.value}
                isOpen={isPaymentModalOpen || Boolean(selectedPackage)}
                onClose={() => {
                    setSelectedPackage(null)
                    setIsPaymentModalOpen(false)
                }}
            />
            <View style={styles.headerContainer}>
                <Typography.Subheading>
                    {T.screens.sendPay.title}
                </Typography.Subheading>
                {!isLoading && (
                    <LinearGradient
                        style={styles.balanceContainer}
                        end={{ x: 1, y: 1 }}
                        colors={[theme.colors.orange, theme.colors.sun]}
                    >
                        <Typography.Label forceColor={theme.colors.white}>
                            {`${T.screens.sendPay.balance} `}
                        </Typography.Label>
                        <Typography.Title
                            bold
                            forceColor={theme.colors.white}
                        >
                            {creditsBalance}
                        </Typography.Title>
                    </LinearGradient>
                )}
            </View>
            {isLoading
                ? (
                    <LoadingIndicator isLoading />
                ) : (
                    <React.Fragment>
                        <View style={styles.container}>
                            <View style={styles.formContainer}>
                                <Typography.SmallSubheading>
                                    {T.components.sendPayModal.topUpAmount}
                                </Typography.SmallSubheading>
                                <View style={styles.messageContainer}>
                                    <Typography.Regular forceColor={theme.colors.darkGrey}>
                                        {T.components.sendPayModal.formFields.amount.label}
                                    </Typography.Regular>
                                </View>
                                <View style={styles.amountInputContainer}>
                                    <View style={styles.amountInput}>
                                        <Adapter.TextInput {...form.amount} />
                                    </View>
                                    <Button
                                        width={isMobile
                                            ? 100
                                            : 200
                                        }
                                        onPress={onClickPurchase}
                                        text={T.common.purchase}
                                        disabled={hasError || !isFilled}
                                    />
                                </View>
                            </View>
                            <View style={styles.textContainer}>
                                <SeparatorWithBorder text={T.common.or} />
                            </View>
                            <View style={styles.creditPackages}>
                                <Typography.SmallSubheading>
                                    {T.components.sendPayModal.purchaseCredits}
                                </Typography.SmallSubheading>
                                <View style={styles.messageContainer}>
                                    <Typography.Regular forceColor={theme.colors.darkGrey}>
                                        {T.components.sendPayModal.message}
                                    </Typography.Regular>
                                </View>
                                <View style={styles.packagesContainer}>
                                    <Adapter.CreditsPackage
                                        {...form.amount}
                                        onPurchase={setSelectedPackage}
                                    />
                                </View>
                            </View>
                            <View style={styles.textContainer}>
                                <Typography.Regular forceColor={theme.colors.mouse}>
                                    {T.components.sendPayModal.expire}
                                </Typography.Regular>
                            </View>
                        </View>
                    </React.Fragment>
                )
            }
        </AppLayout>
    )
}

const stylesheet = createStyles(theme => ({
    balanceContainer: {
        borderRadius: 8,
        minWidth: 250,
        marginTop: {
            lg: 0,
            xs: theme.utils.gap(2)
        },
        paddingVertical: theme.utils.gap(2),
        paddingHorizontal: theme.utils.gap(2)
    },
    container: {
        paddingTop: theme.utils.gap(2)
    },
    messageContainer: {
        marginTop: theme.utils.gap(1)
    },
    amountInputContainer: {
        flexDirection: 'row',
        marginVertical: theme.utils.gap(1)
    },
    amountInput: {
        flex: 1,
        marginRight: theme.utils.gap(2)
    },
    packagesContainer: {
        marginBottom: theme.utils.gap(2)
    },
    headerContainer: {
        justifyContent: 'space-between',
        flexDirection: {
            lg: 'row',
            xs: 'column'
        },
        marginTop: {
            lg: 0,
            xs: theme.utils.gap(2)
        },
        paddingHorizontal: {
            lg: 0,
            xs: theme.utils.gap(1)
        }
    },
    formContainer: {
        maxWidth: 800,
        paddingHorizontal: theme.utils.gap(1)
    },
    creditPackages: {
        paddingVertical: {
            lg: theme.utils.gap(5),
            xs: theme.utils.gap(1)
        },
        paddingHorizontal: theme.utils.gap(1)
    },
    textContainer: {
        paddingHorizontal: {
            lg: 0,
            xs: theme.utils.gap(1)
        }
    }
}))
