import { useMutation } from '@tanstack/react-query'
import { useFetcher } from 'lib/api'
import { ErrorResponse, HttpMethod, Response } from 'lib/types'
import { GetImageUploadURLRequest, GetImageUploadURLResponse, GetImageDownloadURLResponse, GetImageDownloadURLRequest } from './types'

export const getS3ImageUploadURL = () => {
    const fetcher = useFetcher<GetImageUploadURLResponse>(HttpMethod.POST, '/generate-job-image-upload-url')

    return useMutation<Response<GetImageUploadURLResponse>, ErrorResponse, GetImageUploadURLRequest>(fetcher)
}

export const getS3ImageDownloadURL = () => {
    const fetcher = useFetcher<GetImageDownloadURLResponse>(HttpMethod.POST, '/generate-job-image-download-url')

    return useMutation<Response<GetImageDownloadURLResponse>, ErrorResponse, GetImageDownloadURLRequest>(fetcher)
}
