import React from 'react'
import { AppLayout, SectionBox } from 'lib/components'
import { useTranslations } from 'lib/hooks'
import { NavigationProps, ScreenNames } from 'lib/routing'
import { UserNewAddressForm } from '../components'

type UserNewAddressScreenProps = {
    navigation: NavigationProps<ScreenNames.UserNewAddress>
}

export const UserNewAddressScreen: React.FunctionComponent<UserNewAddressScreenProps> = () => {
    const T = useTranslations()

    return (
        <AppLayout title={T.screens.user.title}>
            <SectionBox title={T.screens.addNewAddress.section.addNewAddress}>
                <UserNewAddressForm />
            </SectionBox>
        </AppLayout>
    )
}
