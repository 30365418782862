import React from 'react'
import { View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { Icons } from 'assets'
import { useStyles } from 'lib/hooks'
import { Measurements } from 'lib/common'
import { useUnreadNotificationCounterAtom } from 'lib/atoms'
import { ScreenNames } from 'lib/routing'
import { createStyles } from 'lib/styles'
import { Touchable } from '../Touchable'

export const HeaderNotifications: React.FunctionComponent = () => {
    const navigation = useNavigation()
    const { styles } = useStyles(stylesheet)
    const [unreadNotificationCounter] = useUnreadNotificationCounterAtom()

    return (
        <React.Fragment>
            <Touchable
                style={styles.container}
                onPress={() => navigation.navigate(ScreenNames.Notifications)}
            >
                <View>
                    {unreadNotificationCounter > 0 && (
                        <View style={styles.unreadDot} />
                    )}
                    <Icons.Bell size={20} />
                </View>
            </Touchable>
        </React.Fragment>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        height: '100%',
        backgroundColor: theme.colors.sun,
        alignItems: 'center',
        justifyContent: 'center',
        width: {
            ':w[1440, ]': Measurements.WideHeaderHeight,
            ':w[, 1440]': Measurements.HeaderHeight
        }
    },
    unreadDot: {
        zIndex: theme.zIndex[10],
        width: 8,
        height: 8,
        borderRadius: 4,
        backgroundColor: theme.colors.flame,
        position: 'absolute',
        top: -2,
        right: 3
    }
}))
