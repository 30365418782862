import { useAtom } from 'jotai'
import { createAtom } from './atomFactory'
import { MMKVKey } from '../mmkv'

const { atom, resetAtom } = createAtom<string>('')
const { atom: promotionErrorAtom, resetAtom: resetPromotionErrorAtom } = createAtom<string>('', MMKVKey.PromotionCodeError)

export const useAutoApplyPromoAtom = () => useAtom(atom)
export const useResetAutoApplyPromoAtom = () => useAtom(resetAtom)
export const usePromotionErrorAtom = () => useAtom(promotionErrorAtom)
export const useResetPromotionErrorAtom = () => useAtom(resetPromotionErrorAtom)
