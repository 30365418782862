import { ENV } from 'lib/config'
import { FacebookGetMeResponse, Nullable } from 'lib/types'
import { AuthFlowError, FacebookUserDetailsResponse } from '../types'

const getFBInstance = () => {
    if (!window.FB) {
        throw AuthFlowError.ServerError
    }

    return window.FB
}

export const initFacebookSDK = (id: string, language: string) => new Promise<void>((resolve, reject) => {
    const doesScriptExist = Boolean(document.getElementById(id))

    if (doesScriptExist) {
        return resolve()
    }

    if (!language.includes('_')) {
        return reject()
    }

    const scriptTag = document.createElement('script')

    scriptTag.id = id
    scriptTag.src = `https://connect.facebook.net/${language}/sdk.js`
    scriptTag.onload = () => {
        const FB = getFBInstance()

        FB.init({
            version: 'v15.0',
            cookie: true,
            xfbml: true,
            appId: ENV.FACEBOOK_APP_ID as string
        })

        resolve()
    }
    scriptTag.onerror = () => reject()

    const body = document.querySelector('body')

    if (!body) {
        return reject()
    }

    body.append(scriptTag)
})

export const authorizeWithFacebook = (onResponse: (accessToken: Nullable<string>, error?: AuthFlowError) => void) => {
    const FB = getFBInstance()

    FB.login(response => {
        if (response.authResponse) {
            return onResponse(response.authResponse.accessToken)
        }

        onResponse(null, AuthFlowError.CanceledByUser)
    }, {
        scope: 'public_profile,email'
    })
}

export const getFacebookUserDetails = (accessToken: string) => new Promise<FacebookUserDetailsResponse>(resolve => {
    const FB = getFBInstance()

    FB.api<FacebookGetMeResponse>('/me?fields=id,email,name', response => {
        resolve({
            accessToken,
            id: response.id,
            name: response.name,
            email: response.email
        })
    })
})

export const logoutFromFacebook = (): Promise<void> => {
    const FB = getFBInstance()

    return new Promise(resolve => {
        FB.logout(() => resolve())
    })
}
