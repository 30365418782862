import React from 'react'
import { View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { R } from 'lib/utils'
import { Button } from 'lib/components'
import { isNative } from 'lib/common'
import { ScreenNames } from 'lib/routing'
import { createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { Segment } from 'lib/analytics'
import { useAddNewCreditCard } from '../hooks'

export const AddPaymentButton: React.FunctionComponent = () => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const navigation = useNavigation()
    const { mobileSubmit, isLoading } = useAddNewCreditCard(R.T)

    return (
        <View style={styles.buttonContainer}>
            <Button
                noBackground
                isLoading={isLoading}
                onPress={() => {
                    Segment.addNewCreditCardClicked({})

                    if (isNative) {
                        return mobileSubmit()
                    }

                    navigation.navigate(ScreenNames.UserCardsNew)
                }}
                text={T.screens.paymentAndCards.addCard}
            />
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    buttonContainer: {
        flex: 1,
        maxWidth: {
            lg: 180,
            xs: '100%'
        },
        margin: {
            lg: 0,
            xs: theme.utils.gap(1)
        }
    }
}))
