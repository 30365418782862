import React from 'react'
import { View } from 'react-native'
import { createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { BasicModal, Touchable, Typography } from 'lib/components'
import { Icons } from 'assets'
import { Children } from 'lib/types'
import { Segment, ServiceType } from 'lib/analytics'
import { isNative } from 'lib/common'

type ServiceDescriptionProps = {
    title: string,
    serviceType?: string,
    description: string,
    isHelpModalOpen: boolean,
    setHelpModalOpen: (isOpen: boolean) => void,
    modalContent: Children
}

export const ServiceDescription: React.FunctionComponent<ServiceDescriptionProps> = ({
    title,
    description,
    serviceType,
    modalContent,
    isHelpModalOpen,
    setHelpModalOpen
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)

    if (isNative) {
        return serviceType !== undefined && serviceType === ServiceType.Cleaning ? modalContent : (
            <BasicModal
                isOpen={isHelpModalOpen}
                onClose={byButton => {
                    Segment.bookingServiceDescriptionModuleClosed({
                        closeType: byButton
                            ? Segment.CloseType.Button
                            : Segment.CloseType.Outside
                    })

                    setHelpModalOpen(false)
                }}
            >
                {modalContent}
            </BasicModal>
        )
    }

    return (
        <View style={styles.container}>
            <BasicModal
                scrollViewStyles={styles.scrollViewStyles}
                isOpen={isHelpModalOpen}
                onClose={byButton => {
                    Segment.bookingServiceDescriptionModuleClosed({
                        closeType: byButton
                            ? Segment.CloseType.Button
                            : Segment.CloseType.Outside
                    })

                    setHelpModalOpen(false)
                }}
            >
                {modalContent}
            </BasicModal>
            <Typography.Title bold>
                {title}
            </Typography.Title>
            <View style={styles.description}>
                <Typography.Regular forceColor={theme.colors.darkGrey}>
                    {description}
                </Typography.Regular>
            </View>
            <Touchable
                onPress={() => {
                    Segment.bookingWhatsIncludedAndHowItWorksClicked({})

                    setHelpModalOpen(true)
                }}
                style={styles.seeMoreButton}
            >
                <Typography.Label
                    bold
                    forceColor={theme.colors.orange}
                >
                    {T.common.howItWorks}
                </Typography.Label>
                <View style={styles.seeMoreIcon}>
                    <Icons.Eye
                        size={15}
                        forceColor={theme.colors.orange}
                    />
                </View>
            </Touchable>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    container: {
        borderWidth: 1,
        borderRadius: 8,
        alignItems: 'flex-start',
        borderColor: theme.colors.silver,
        paddingTop: theme.utils.gap(2),
        paddingBottom: theme.utils.gap(3),
        paddingHorizontal: theme.utils.gap(2),
        backgroundColor: theme.colors.white,
        marginBottom: theme.utils.gap(4),
        ...theme.utils.createShadow(2, 0, 8, 4, 4, theme.colors.webShadow(0.04))
    },
    seeMoreButton: {
        flexDirection: 'row',
        alignItems: 'center',
        borderBottomWidth: 1,
        borderColor: theme.colors.orange
    },
    seeMoreIcon: {
        marginLeft: theme.utils.gap(1)
    },
    description: {
        marginTop: theme.utils.gap(1),
        marginBottom: theme.utils.gap(2)
    },
    scrollViewStyles: {
        minWidth: 100
    }
}))
