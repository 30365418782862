import React from 'react'
import { View } from 'react-native'
import Animated from 'react-native-reanimated'
import { createStyles } from 'lib/styles'
import { useStyles } from 'lib/hooks'
import { useIsAuthorizedAtom } from 'lib/atoms'
import { HeaderLinks } from './HeaderLinks'
import { HeaderNotifications } from './HeaderNotifications'
import { UserDropdownMenu } from '../userDropdownMenu'

type HeaderMenuProps = {
    disableUserMenu?: boolean,
    isMainMenuVisible: Animated.SharedValue<boolean>,
    onShowMainMenu: VoidFunction
}

export const HeaderMenu: React.FunctionComponent<HeaderMenuProps> = ({
    isMainMenuVisible,
    onShowMainMenu,
    disableUserMenu
}) => {
    const { styles } = useStyles(stylesheet)
    const [isAuthorized] = useIsAuthorizedAtom()

    return (
        <React.Fragment>
            <HeaderLinks
                onShowMainMenu={onShowMainMenu}
                isMainMenuVisible={isMainMenuVisible}
            />
            <View style={styles.userContent}>
                <UserDropdownMenu disabled={disableUserMenu} />
                {isAuthorized ? (
                    <HeaderNotifications />
                ): (
                    <View style={styles.notificationsPlaceholder} />
                )}
            </View>
        </React.Fragment>
    )
}

const stylesheet = createStyles(theme => ({
    userContent: {
        height: '100%',
        flexDirection: 'row',
        display: {
            ':w[992, ]': 'flex',
            ':w[, 992]': 'none'
        }
    },
    notificationsPlaceholder: {
        paddingLeft: {
            lg: theme.utils.gap(9),
            ':w[992, 1110]': theme.utils.gap(3),
            xs: 0
        }
    }
}))

