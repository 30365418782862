import React from 'react'
import { Field } from '@codegateinc/react-form-builder-v2'
import { View, ViewStyle } from 'react-native'
import { useStyles } from 'lib/hooks'
import { SelectInputOption } from 'lib/types'
import { createStyles } from 'lib/styles'
import { Typography } from 'lib/components'
import { FormComponents } from '../components'

interface SelectInputColumnProps extends Field<string> {
    options: Array<SelectInputOption>,
    disabled?: boolean,
    isClearable?: boolean,
    defaultValue?: SelectInputOption,
    dropdownStyles?: boolean,
    withPlaceholder?: boolean,
    placeholderContainerStyles?: ViewStyle,
    fullWidth?: boolean,
    smallLabel?: boolean,
    testID?: string,
    onMenuOpen?: VoidFunction,
    onOptionSelect?(value: SelectInputOption): void
}

export const SelectInputColumn: React.FunctionComponent<SelectInputColumnProps> = ({
    label,
    options,
    onChangeValue,
    value,
    placeholder,
    errorMessage,
    fullWidth,
    dropdownStyles = false,
    isClearable = true,
    disabled = false,
    onOptionSelect,
    onMenuOpen,
    smallLabel,
    withPlaceholder = true,
    placeholderContainerStyles = {},
    testID
}) => {
    const { styles, theme } = useStyles(stylesheet)

    return (
        <View
            style={
                fullWidth
                    ? styles.fullWidthInput
                    : undefined
            }
        >
            {smallLabel
                ? (
                    <Typography.Label
                        style={styles.smallLabel}
                        forceColor={theme.colors.darkGrey}
                    >
                        {label}
                    </Typography.Label>
                ) : (
                    <Typography.Regular bold>
                        {label}
                    </Typography.Regular>
                )
            }
            {!smallLabel && (
                <View
                    style={{
                        ...styles.placeholder,
                        ...placeholderContainerStyles
                    }}
                >
                    {withPlaceholder && (
                        <Typography.Label forceColor={theme.colors.darkGrey}>
                            {placeholder}
                        </Typography.Label>
                    )}
                </View>
            )}
            <View style={styles.input} testID={`${testID ? testID : 'select-input'}-container`}>
                <FormComponents.SelectInput
                    options={options}
                    disabled={disabled}
                    isClearable={isClearable}
                    errorMessage={errorMessage}
                    placeholder={placeholder}
                    dropdownStyles={dropdownStyles}
                    onMenuOpen={onMenuOpen}
                    value={options.find(item => item.value === value) || null}
                    testID={testID}
                    onChange={option => {
                        if (onOptionSelect && option) {
                            onOptionSelect(option)
                        }

                        onChangeValue(option?.value || '')
                    }}
                />
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    input: {
        flex: 1
    },
    fullWidthInput: {
        width: '100%'
    },
    smallLabel: {
        marginBottom: theme.utils.gap(1)
    },
    placeholder: {
        marginTop: theme.utils.gap(2),
        marginBottom: theme.utils.gap(1)
    }
}))
