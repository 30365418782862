import NetInfo from '@react-native-community/netinfo'
import UAParser from 'ua-parser-js'
import { AppStateStatus, Platform } from 'react-native'
import { onlineManager, QueryClient, focusManager } from '@tanstack/react-query'
import { isWeb } from 'lib/common'
import { APP_CONFIG } from '../config'
import { DeviceInfo } from '../utils'
import { KeyValuePair, Optional, MobileDeviceInfo, AppVersionHeader } from '../types'

onlineManager.setEventListener(setOnline => NetInfo.addEventListener(state => {
    setOnline(Boolean(state.isConnected))
}))

export const handleFocusManager = (status: AppStateStatus) => {
    if (Platform.OS !== 'web') {
        focusManager.setFocused(status === 'active')
    }
}

export const queryClient = new QueryClient()
export const userAgentParser = new UAParser()

export const getRequestMetadataWithDeviceInfo = (fcmToken: Optional<string>, appsFlyerId?: string) => {
    const webAppVersion = APP_CONFIG.APP.WEB_VERSION
    const {
        uniqueId,
        deviceId,
        manufacturer,
        model,
        systemName,
        systemVersion,
        bundleId,
        buildNumber,
        version,
        deviceName,
        languageTag
    } = DeviceInfo.get()
    const appVersion = isWeb ? APP_CONFIG.APP.VERSION : version

    if (isWeb) {
        return {
            device_info: {
                app_version: appVersion,
                web_app_version: webAppVersion,
                ...userAgentParser.getResult()
            },
            device: {
                // Have to be like this - capitalized
                platform: 'Web'
            },
            is_consumer: true,
            is_supplier: false
        }
    }

    const deviceInfo: MobileDeviceInfo = {
        app_version: appVersion,
        uniqueId,
        deviceId,
        manufacturer,
        model,
        systemName,
        systemVersion,
        bundleID: bundleId,
        bundleNumber: buildNumber,
        version,
        deviceName,
        deviceLocale: languageTag
    }

    if (appsFlyerId) {
        deviceInfo.appsFlyerId = appsFlyerId
    }

    return {
        device_info: deviceInfo,
        device: {
            platform: Platform.OS,
            uuid: uniqueId,
            version,
            model,
            app_version: appVersion,
            push_token: fcmToken
        },
        is_consumer: true,
        is_supplier: false
    }
}

export const maskPassword = (params: KeyValuePair) => Object.fromEntries(Object
    .entries(params)
    .map(([key, value]) => key.toLowerCase().includes('password')
        ? [key, '*****']
        : [key, value]
    )
)

export const getAppVersionsFromHeader = (headers: KeyValuePair) => ({
    [AppVersionHeader.IOS_MIN]: headers[AppVersionHeader.IOS_MIN] || '',
    [AppVersionHeader.ANDROID_MIN]: headers[AppVersionHeader.ANDROID_MIN] || '',
    [AppVersionHeader.IOS_CURRENT]: headers[AppVersionHeader.IOS_CURRENT] || '',
    [AppVersionHeader.ANDROID_CURRENT]: headers[AppVersionHeader.ANDROID_CURRENT] || ''
})
