import { useMutation } from '@tanstack/react-query'
import { useFetcher } from 'lib/api'
import { ErrorResponse, HttpMethod, Response } from 'lib/types'
import {
    GetPromotionPopupRequest,
    PromotionPopupResponse
} from './types'

export const getPromotionPopup = () => {
    const fetcher = useFetcher<PromotionPopupResponse>(HttpMethod.POST, '/get-popup')

    return useMutation<Response<PromotionPopupResponse>, ErrorResponse, GetPromotionPopupRequest>(fetcher)
}
