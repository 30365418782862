import React from 'react'
import { View } from 'react-native'
import { Service } from 'lib/models'
import { createStyles } from 'lib/styles'
import { NumberInputActions } from 'lib/types'
import { Touchable, Typography } from 'lib/components'
import { usePrice, useStyles, useTranslations } from 'lib/hooks'
import { Number } from './Number'
import { Icons } from 'assets'

type ServiceInputProps = {
    service: Service,
    disabled?: boolean,
    onAdd(id: number): void,
    onQuantityChange(action: NumberInputActions, id: number): void
}

export const ServiceInput: React.FunctionComponent<ServiceInputProps> = ({
    service,
    onAdd,
    onQuantityChange,
    disabled
}) => {
    const T = useTranslations()
    const { styles, theme } = useStyles(stylesheet)
    const price = usePrice(service.price)
    const serviceButtonInnerColor = service.selected ? theme.colors.black : theme.colors.orange
    const serviceSelected = service.selected || service.quantity > 0
    const serviceRowBg = serviceSelected ? theme.colors.withOpacity(theme.colors.yellow, 0.4) : theme.colors.white

    return (
        <View style={{ ...styles.itemRow, backgroundColor: serviceRowBg }}>
            <View style={styles.title}>
                <Typography.Label>
                    {service.title}
                </Typography.Label>
                <Typography.Label forceColor={theme.colors.darkGrey}>
                    {service.subtitle}
                </Typography.Label>
            </View>
            <View style={styles.row}>
                <View style={styles.price}>
                    <Typography.Label bold>
                        {price}
                    </Typography.Label>
                </View>
                <View style={styles.action}>
                    {service.canChangeQuantity && service.quantity > 0
                        ? (
                            <View style={styles.number}>
                                <Number
                                    quantity={service.quantity}
                                    onRemove={() => onQuantityChange(NumberInputActions.Remove, service.id)}
                                    onAdd={() => onQuantityChange(NumberInputActions.Add, service.id)}
                                />
                            </View>
                        ) : (
                            <View style={styles.addWrapper}>
                                <Touchable
                                    disabled={disabled}
                                    onPress={() => onAdd(service.id)}
                                    style={{
                                        ...styles.button,
                                        ...(service.selected ? styles.added : styles.add)
                                    }}
                                >
                                    {service.selected ? <Icons.Check size={12} forceColor={serviceButtonInnerColor} /> :
                                        <Icons.Plus size={14} forceColor={serviceButtonInnerColor} />}
                                    <Typography.Label
                                        bold
                                        forceColor={serviceButtonInnerColor}
                                    >
                                        {service.selected
                                            ? T.common.added
                                            : T.common.add
                                        }
                                    </Typography.Label>
                                </Touchable>
                            </View>
                        )
                    }
                </View>
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    itemRow: {
        height: 60,
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderBottomColor: theme.colors.silver,
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        flex: {
            xs: 1,
            lg: 0.5
        }
    },
    title: {
        flex: 1
    },
    price: {
        flex: 1
    },
    action: {
        width: 100,
        alignItems: 'flex-end',
        flex: 1
    },
    button: {
        borderRadius: 4,
        borderWidth: 1,
        height: 40,
        width: '100%',
        textAlign: 'center',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        gap: theme.utils.gap(0.5),
        boxSizing: 'border-box'
    },
    add: {
        borderColor: theme.colors.orange
    },
    added: {
        backgroundColor: theme.colors.sun,
        borderWidth: 0
    },
    addWrapper: {
        alignItems: 'center',
        width: 100
    },
    number: {
        width: 100
    }
}))
