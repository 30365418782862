import { useEffect } from 'react'
import { UseFormReturn } from '@codegateinc/react-form-builder-v2'
import { MoversAndPackersFormShape } from '../../forms'

export const useResetMoversAndPackersForm = ({ form }: UseFormReturn<MoversAndPackersFormShape>) => {
    useEffect(() => {
        form.pickUpPropertyType?.onChangeValue(null)
        form.dropOffPropertyType?.onChangeValue(null)
        form.pickUpNumberOfBedrooms?.onChangeValue(null)
        form.dropOffNumberOfBedrooms?.onChangeValue(null)
        form.pickUpPropertySize?.onChangeValue(null)
        form.dropOffPropertySize?.onChangeValue(null)
        form.pickUpFloor?.onChangeValue(null)
        form.dropOffFloor?.onChangeValue(null)
        form.pickUpHasElevator?.onChangeValue(false)
        form.dropOffHasElevator?.onChangeValue(false)
    }, [form.serviceType?.value])

    useEffect(() => {
        form.pickUpNumberOfBedrooms?.onChangeValue(null)
        form.pickUpFloor?.onChangeValue(null)
        form.pickUpHasElevator?.onChangeValue(false)
    }, [form.pickUpPropertyType?.value])

    useEffect(() => {
        form.dropOffNumberOfBedrooms?.onChangeValue(null)
        form.dropOffFloor?.onChangeValue(null)
        form.dropOffHasElevator?.onChangeValue(false)
    }, [form.dropOffPropertyType?.value])
}
