import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const CheckCircle: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path d="M40 0C17.92 0 0 17.92 0 40s17.92 40 40 40 40-17.92 40-40S62.08 0 40 0Zm0 72C22.36 72 8 57.64 8 40S22.36 8 40 8s32 14.36 32 32-14.36 32-32 32Zm15.52-46.84L32 48.68l-7.52-7.52a3.983 3.983 0 0 0-5.64 0 3.983 3.983 0 0 0 0 5.64L29.2 57.16a3.983 3.983 0 0 0 5.64 0L61.2 30.8a3.983 3.983 0 0 0 0-5.64 4.032 4.032 0 0 0-5.68 0Z" />
    </Icon>
)
