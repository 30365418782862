import React from 'react'
import { View } from 'react-native'
import { Icons } from 'assets'
import { createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { BookingFrequency, FrequencyOption, Nullable } from 'lib/types'
import { Typography } from 'lib/components'
import { dateHelpers } from 'lib/utils'
import { useLocaleAtom } from 'lib/atoms'
import { useBookingScheduleDates } from '../hooks'

type RecurringBookingScheduleProps = {
    frequency: FrequencyOption,
    oneTimeDate: string,
    title: string,
    weeklyDays: Record<string, Nullable<string>>
}

export const RecurringBookingSchedule: React.FunctionComponent<RecurringBookingScheduleProps> = ({
    frequency,
    title,
    oneTimeDate,
    weeklyDays
}) => {
    const T = useTranslations()
    const [locale] = useLocaleAtom()
    const { styles, theme } = useStyles(stylesheet)
    const dates = useBookingScheduleDates(frequency.value, Object.values(weeklyDays))

    return (
        <View>
            <Typography.Regular bold testID={T.accessibility.bookingConfirmationScreen.recurringBookingScheduleLabel}>
                {`${frequency.label} ${title}`}
            </Typography.Regular>
            <View style={styles.datesContainer}>
                {frequency.value === BookingFrequency.OneTime
                    ? (
                        <View style={styles.dateContainer}>
                            <Typography.Regular>
                                {dateHelpers.getFullBookingDate(oneTimeDate, locale)}
                            </Typography.Regular>
                        </View>
                    ) : dates.slice(0, 4).map((date, index) => (
                        <View
                            key={index}
                            style={{
                                ...styles.dateContainer,
                                backgroundColor: index % 2 !== 0
                                    ? theme.colors.marble
                                    : theme.colors.white
                            }}
                        >
                            <Typography.Regular>
                                {dateHelpers.getFullBookingDate(date, locale)}
                            </Typography.Regular>
                        </View>
                    ))
                }
            </View>
            <View style={styles.cycleMessage}>
                <View style={styles.icon}>
                    <Icons.Refresh size={18} />
                </View>
                <Typography.Regular forceColor={theme.colors.darkGrey} testID={T.accessibility.bookingConfirmationScreen.recurringAutoRenewLabel}>
                    {T.screens.cleaningSummary.cycleMessage}
                </Typography.Regular>
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    datesContainer: {
        marginTop: theme.utils.gap(2)
    },
    dateContainer: {
        paddingVertical: theme.utils.gap(1),
        paddingHorizontal: theme.utils.gap(1)
    },
    cycleMessage: {
        marginVertical: theme.utils.gap(2),
        flexDirection: 'row',
        alignItems: 'center'
    },
    icon: {
        marginRight: theme.utils.gap(1)
    }
}))
