import { useState } from 'react'
import { Platform } from 'react-native'
import { firebase, FirebaseAuthError, OTPVerifyCode } from 'lib/firebase'
import { AnalyticsEvent, AnalyticsEventDescription, GoogleAnalytics, Segment } from 'lib/analytics'
import { ProviderDetails } from 'lib/models'
import { isNative } from 'lib/common'
import { useFirstSessionAtom, useFirstSignUpAtom, usePromotionAtom } from 'lib/atoms'
import { DeviceInfo } from 'lib/utils'
import { useGetErrorMessage, useTranslations, useSessionData } from 'lib/hooks'
import { SubscribePromotionAction } from 'lib/types/promotion'
import { useGetProfileData } from './useGetProfileData'
import { AuthUser } from '../types'
import { signUpWithEmail } from '../actions'
import { saveAccessToken, saveRefreshToken } from '../utils'
import { getAnonymousID } from 'lib/analytics/segment/utils'

export const useSignUpWithOTP = (
    authUser: AuthUser,
    onVerifyCode: OTPVerifyCode,
    setVerifyCode: (verifyCode: OTPVerifyCode) => void,
    onSuccessfulSignUp?: VoidFunction
) => {
    const T = useTranslations()
    const { getErrorMessage } = useGetErrorMessage()
    const [, setIsFirstSession] = useFirstSessionAtom()
    const [, setPromotionAction] = usePromotionAtom()
    const [isLoading, setIsLoading] = useState(false)
    const [, setFirstSignUp] = useFirstSignUpAtom()
    const [authError, setAuthError] = useState<string>()
    const { mutate: signUpWithEmailAndPassword } = signUpWithEmail()
    const sessionData = useSessionData()
    const { getMe, isFetchingProfile } = useGetProfileData(
        error => {
            setAuthError(error)
            setIsLoading(false)
        }
    )

    const signUp = async () => {
        signUpWithEmailAndPassword({
            ...sessionData,
            email_id: authUser.email,
            handle: authUser.name,
            provider_details: ProviderDetails.Email,
            password: authUser.password,
            phone: authUser.phoneNumber,
            anonymous_id: await getAnonymousID(),
            marketing_consent_accepted: Boolean(authUser.marketingConsent)
        }, {
            onSuccess: ({ data }) => {
                setPromotionAction(authUser.marketingConsent ?
                    SubscribePromotionAction.ShouldShowPromotionModal :
                    isNative ?
                        SubscribePromotionAction.ShouldShowMarketingConsentModal :
                        SubscribePromotionAction.NoAction
                )

                saveAccessToken(data.tokens.accessToken)
                saveRefreshToken(data.tokens.refreshToken)
                getMe(data.token)
                setFirstSignUp(new Date().toISOString())
                setIsFirstSession(true)

                if (onSuccessfulSignUp) {
                    onSuccessfulSignUp()
                }

                GoogleAnalytics.logEvent({
                    eventType: AnalyticsEvent.UserAction,
                    description: AnalyticsEventDescription.EmailSignUp
                })

                const { deviceId, deviceName } = DeviceInfo.get()

                Segment.identify(data.token, {
                    email: authUser.email,
                    device: {
                        deviceId,
                        deviceName,
                        platform: Platform.OS
                    }
                })
            },
            onError: response => {
                setIsLoading(false)
                setAuthError(getErrorMessage(response))
            }
        })
    }

    return {
        isLoading: isLoading || isFetchingProfile,
        authError,
        confirmOTP: (verificationCodeArray: Array<string>) => {
            const verificationCode = verificationCodeArray.join('')

            setIsLoading(true)
            GoogleAnalytics.logEvent({
                eventType: AnalyticsEvent.UserAction,
                description: AnalyticsEventDescription.ConfirmOTP
            })

            onVerifyCode.confirm(verificationCode)
                .then(() => signUp())
                .catch(error => {
                    setIsLoading(false)

                    switch(error.code) {
                        case FirebaseAuthError.InvalidVerificationCode:
                            return setAuthError(T.screens.auth.errors.invalidVerificationCode)
                        default:
                            return setAuthError(T.screens.auth.errors.genericError)
                    }
                })
        },
        resendOTP: () => {
            GoogleAnalytics.logEvent({
                eventType: AnalyticsEvent.UserAction,
                description: AnalyticsEventDescription.ResendOTP
            })

            firebase
                .auth
                .resendPhoneNumberAuthCode(authUser.phoneNumber)
                .then(result => setVerifyCode(result as OTPVerifyCode))
                .catch(error => {
                    switch(error.code) {
                        case FirebaseAuthError.InvalidNumber:
                            return setAuthError(T.screens.auth.errors.invalidPhoneNumber)
                        case FirebaseAuthError.UserDisabled:
                            return setAuthError(T.screens.auth.errors.userDisabled)
                        case FirebaseAuthError.TooManyRequests:
                            return setAuthError(T.screens.auth.errors.tooManyRequests)
                        default:
                            return setAuthError(T.screens.auth.errors.genericError)
                    }
                })
                .finally(() => setIsLoading(false))
        }
    }
}
