import React, { useEffect, useMemo } from 'react'
import { View } from 'react-native'
import { useStyles } from 'lib/hooks'
import { createStyles } from 'lib/styles'
import { bookingHelpers, dateHelpers } from 'lib/utils'
import { Typography } from 'lib/components'
import { IconProps, Nullable, SelectInputOption } from 'lib/types'
import { FormComponents } from '../components'
import { isNative } from 'lib/common'

type BookingTimeSlotProps = {
    index: number,
    dayValue: Nullable<string>,
    startDate: string,
    dayKey: string,
    disabledInput: boolean,
    isSelected: boolean,
    placeholder?: string,
    disabled?: boolean,
    minHoursNotice?: number,
    onInputChange: (value: Nullable<SelectInputOption>) => void,
    leftIcon?: React.FunctionComponent<IconProps>,
    testID?: string
}

export const BookingTimeSlot: React.FunctionComponent<BookingTimeSlotProps> = ({
    index,
    startDate,
    dayKey,
    dayValue,
    disabledInput,
    placeholder,
    isSelected,
    minHoursNotice,
    disabled,
    leftIcon,
    onInputChange,
    testID
}) => {
    const { styles } = useStyles(stylesheet)
    const data = useMemo(() => {
        const bookingTimeOptions =
            bookingHelpers.bookingTimeWithOffsetOptions(startDate || new Date().toISOString(), index)
        const filteredBookingTimeOptions = bookingTimeOptions
            .filter(({ value }) => {
                if (!minHoursNotice) {
                    return true
                }

                return !dateHelpers.isDatePast(dateHelpers.subHoursFromDate(value, minHoursNotice))
            })

        return filteredBookingTimeOptions.length === 0
            ? bookingHelpers.bookingTimeWithOffsetOptions(
                dateHelpers.addDaysToDate(startDate || new Date().toISOString(), 7), index)
            : filteredBookingTimeOptions
    }, [startDate])

    const [selectedValue] = data.filter(option => {
        if (!dayValue) {
            return false
        }

        return option.label === dateHelpers.selectTimeLabel(dayValue)
    })

    useEffect(() => {
        if (!selectedValue) {
            onInputChange(null)
        }
    }, [selectedValue])

    return isSelected ? (
        <View style={styles.container} key={dayKey}>
            <View style={styles.labelContainer}>
                <Typography.Regular style={styles.label}>{dayKey}</Typography.Regular>
            </View>
            <View style={styles.inputContainer}>
                <FormComponents.SelectInput
                    testID={testID}
                    hideErrorMessage
                    leftIcon={leftIcon}
                    options={data}
                    isClearable={false}
                    disabled={disabled || disabledInput}
                    placeholder={placeholder}
                    value={selectedValue || null}
                    onChange={onInputChange}
                />
            </View>
        </View>
    ) : null
}

const stylesheet = createStyles(theme => ({
    container: {
        flexDirection: {
            xs: 'column',
            md: 'row'
        },
        justifyContent: {
            xs: 'center',
            md: 'space-between'
        },
        alignItems: {
            xs: 'flex-start',
            md: 'center'
        },
        marginTop: theme.utils.gap(1.5)
    },
    labelContainer: {
        flex: 1,
        paddingBottom: isNative ? theme.utils.gap(1) : undefined
    },
    label: {
        lineHeight: 17
    },
    inputContainer: {
        flex: {
            md: 3,
            xs: 2
        },
        width: {
            md: undefined,
            xs: '100%'
        }
    }
}))
