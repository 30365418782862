import React from 'react'
import { ActivityIndicator } from 'react-native'
import { useTranslations } from 'lib/hooks'
import { Typography, Grid } from 'lib/components'

export const OTAUpdateInProgress: React.FunctionComponent = () => {
    const T = useTranslations()

    return (
        <React.Fragment>
            <Typography.Heading>
                {T.components.otaUpdateListener.availableTitle}
            </Typography.Heading>
            <Grid.Gap
                gapTop={1}
                gapBottom={2}
            >
                <Typography.Regular>
                    {T.components.otaUpdateListener.message}
                </Typography.Regular>
            </Grid.Gap>
            <ActivityIndicator />
        </React.Fragment>
    )
}
