import React from 'react'
import { View } from 'react-native'
import { Field } from '@codegateinc/react-form-builder-v2'
import { useIsAuthorizedAtom, useUserAtom } from 'lib/atoms'
import { Address, Button, MediaQuery, PolicyLinks } from 'lib/components'
import { useTranslations } from 'lib/hooks'
import { PaymentType } from 'lib/models'
import { Breakpoint } from 'lib/styles'
import { Nullable, VoidFunction } from 'lib/types'
import { PestControlAddonsFormShape, PestControlFormShape } from '../../forms'
import { GetPromotionResponse, JobQuotationResponse } from '../../types'
import { BookingCancellationFees } from '../BookingCancellationFees'
import { PaymentMethodTile } from '../PaymentMethodTile'
import { PromotionCodeInput } from '../PromotionCodeInput'
import { PestControlSummaryColumn } from './PestControlSummaryColumn'
import { TotalPriceComponent } from '../TotalPriceComponent'
import { PriceSummaryComponent } from '../PriceSummaryComponent'

type PestControlSummaryColumnViewProps = {
    disabled: boolean,
    selectedPaymentMethod: PaymentType,
    setPaymentView: (value: boolean) => void,
    form: Record<keyof PestControlAddonsFormShape, Field<boolean & string>>
    formShape: PestControlFormShape,
    isLoading: boolean,
    totalPrice: number,
    originalPrice: Nullable<number>,
    authenticationView: boolean,
    jobQuotation?: JobQuotationResponse,
    submit: VoidFunction,
    promotionPackage: Nullable<GetPromotionResponse>
    requestPromotion: (code: string) => void,
    onRemovePromoCode: VoidFunction
}

export const PestControlSummaryColumnView: React.FunctionComponent<PestControlSummaryColumnViewProps> = ({
    form,
    submit,
    selectedPaymentMethod,
    onRemovePromoCode,
    setPaymentView,
    promotionPackage,
    requestPromotion,
    authenticationView,
    isLoading,
    totalPrice,
    originalPrice,
    disabled,
    formShape,
    jobQuotation
}) => {
    const T = useTranslations()
    const [user] = useUserAtom()
    const [isAuthorized] = useIsAuthorizedAtom()
    const notEnoughCredits = selectedPaymentMethod === PaymentType.Credits && user.consumer.balance_credit < totalPrice

    return (
        <MediaQuery.Visible from={Breakpoint.LG}>
            {!(isAuthorized && authenticationView) && (
                <View>
                    <Address disabled={isLoading} />
                </View>
            )}
            <PestControlSummaryColumn
                promotionPackage={promotionPackage}
                totalPrice={totalPrice}
                originalPrice={originalPrice}
                title={T.common.bookingSummary}
                showPrices
                renderPriceComponent={() => (
                    <TotalPriceComponent
                        totalPrice={jobQuotation?.total?.value || 0}
                    />
                )}
                renderPriceSummaryComponent={() => jobQuotation?.priceSummary ? (
                    <PriceSummaryComponent priceSummary={jobQuotation?.priceSummary} promotion={jobQuotation.discount} />
                ) : undefined}
                form={formShape}
                couponText={promotionPackage
                    ? promotionPackage.promotion.message_to_client
                    : undefined
                }
                renderExtraContent={() => (
                    <View>
                        <PromotionCodeInput
                            promoCode={form.promoCode}
                            isLoading={isLoading}
                            promotionPackage={promotionPackage}
                            onRemovePromoCode={onRemovePromoCode}
                            requestPromotion={requestPromotion}
                        />
                    </View>
                )}
            />
            {!authenticationView && (
                <PaymentMethodTile
                    notEnoughCredits={notEnoughCredits}
                    onPress={() => setPaymentView(true)}
                    selectedPaymentMethod={selectedPaymentMethod}
                />
            )}
            <PolicyLinks />
            {(!authenticationView || isAuthorized) && (
                <Button
                    disabled={notEnoughCredits || disabled}
                    isLoading={isLoading}
                    onPress={submit}
                    text={T.common.confirm}
                />
            )}
            <BookingCancellationFees />
        </MediaQuery.Visible>
    )
}
