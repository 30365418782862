import React, { useState } from 'react'
import { WebView } from 'react-native-webview'
import { AppLayout, LoadingIndicator } from 'lib/components'
import { NavigationParams, NavigationProps, ScreenNames } from 'lib/routing'
import { Dimensions, StyleSheet } from 'react-native'

type GrabPayWebViewScreenProps = {
    navigation: NavigationProps<ScreenNames.GrabPayWebView>,
    route: NavigationParams<ScreenNames.GrabPayWebView>
}

const windowHeight = Dimensions.get('window').height

export const GrabPayWebViewScreen: React.FunctionComponent<GrabPayWebViewScreenProps> = ({ route }) => {
    const [isLoading, setIsLoading] = useState(true)

    return (
        <AppLayout isLoading={isLoading}>
            <LoadingIndicator isLoading={isLoading} />
            <WebView
                onLoadEnd={() => setIsLoading(false)}
                source={{ uri: route.params.url }}
                style={styles.webViewContainer}
            />
        </AppLayout>
    )
}

const styles = StyleSheet.create({
    webViewContainer :{
        flex: 1,
        height: windowHeight - 50
    }
})
