import React, { useEffect } from 'react'
import { View } from 'react-native'
import { Field } from '@codegateinc/react-form-builder-v2'
import Animated, { SharedValue, runOnUI, useAnimatedReaction, useAnimatedStyle, withTiming } from 'react-native-reanimated'
import { Icons } from 'assets'
import { Segment } from 'lib/analytics'
import { isNative } from 'lib/common'
import { createStyles } from 'lib/styles'
import { HandymanService } from 'lib/models'
import { Touchable, Typography } from 'lib/components'
import { useAccordion, useStyles } from 'lib/hooks'
import { HandymanServiceItem } from './HandymanServiceItem'
import { handymanHooks } from '../../hooks'

type HandymanServicesItemAccordionProps = {
    label: string,
    items: Array<string>,
    index: number,
    isDropdownOpen: boolean,
    field: Field<Array<HandymanService>>,
    openedIndex: SharedValue<number>
}

export const HandymanServicesItemAccordion: React.FunctionComponent<HandymanServicesItemAccordionProps> = ({
    label,
    items,
    index,
    openedIndex,
    isDropdownOpen,
    field
}) => {
    const { styles, theme } = useStyles(stylesheet)
    const serviceTypes = handymanHooks.useHandymanServicesTypes()
    const { setHeight, animatedHeightStyle, aref, isOpened } = useAccordion()
    const selectedAmount = field.value.filter(fieldItem => fieldItem.category === label).length
    const animatedChevronStyles = useAnimatedStyle(() => ({
        transform: [
            {
                rotate: withTiming(`${isOpened.value ? 180 : 0}deg`, {
                    duration: 200
                })
            }
        ]
    }))

    useAnimatedReaction(
        () => openedIndex.value,
        openedIndex => {
            if (openedIndex !== index && isOpened.value) {
                setHeight()
            }
        }
    )

    useEffect(() => {
        if (isDropdownOpen && openedIndex.value === index && !isOpened.value) {
            runOnUI(setHeight)()
        }
    }, [isDropdownOpen])

    const handlePress = (item: string, isSelected: boolean) => {
        const [defaultTypeValue] = serviceTypes[item]
        const newValue = isSelected
            ? field.value.filter(fieldItems => fieldItems.item !== item)
            : [
                ...field.value,
                {
                    item,
                    category: label,
                    amount: 1,
                    comment: null,
                    othersComment: null,
                    part: [],
                    type: defaultTypeValue || null
                }
            ]

        field.onChangeValue(newValue)
    }

    return (
        <View style={styles.wrapper}>
            <Touchable
                style={styles.button}
                onPress={() => {
                    runOnUI(setHeight)()
                    openedIndex.value = index
                }}
            >
                <View style={styles.label}>
                    <Typography.Regular bold>
                        {label}
                    </Typography.Regular>
                    {Boolean(selectedAmount) && (
                        <View style={styles.amountNumber}>
                            <Typography.Error forceColor={theme.colors.white}>
                                {selectedAmount}
                            </Typography.Error>
                        </View>
                    )}
                </View>
                <Animated.View style={[animatedChevronStyles]}>
                    <Icons.Chevron size={10} />
                </Animated.View>
            </Touchable>
            <Animated.View style={animatedHeightStyle}>
                <View
                    ref={aref}
                    collapsable={false}
                    style={styles.accordionWrapper}
                >
                    <View style={styles.itemsWrapper}>
                        {items.map((item, index) => {
                            const isSelected = field.value.some(fieldItems => fieldItems.item === item && fieldItems.category === label)

                            return (
                                <HandymanServiceItem
                                    key={index}
                                    label={item}
                                    isSelected={isSelected}
                                    onPress={() => {
                                        if (field.value.length === 0) {
                                            Segment.bookingServiceTypeSelected({
                                                category: label,
                                                item
                                            })
                                        }

                                        if (field.value.length !== 0) {
                                            Segment.bookingAnotherServiceTypeSelected({
                                                category: label,
                                                item
                                            })
                                        }

                                        handlePress(item, isSelected)
                                    }}
                                />
                            )
                        })}
                    </View>
                </View>
            </Animated.View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        borderBottomWidth: 1,
        borderBottomColor: theme.colors.darkSilver,
        overflow: 'hidden'
    },
    label: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    amountNumber: {
        width: 20,
        height: 20,
        borderRadius: 10,
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: theme.utils.gap(1),
        backgroundColor: theme.colors.orange
    },
    button: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingHorizontal: theme.utils.gap(2),
        paddingVertical: theme.utils.gap(2),
        alignItems: 'center'
    },
    accordionWrapper: {
        left: 0,
        right: 0,
        position: isNative
            ? 'absolute'
            : undefined
    },
    itemsWrapper: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        paddingHorizontal: theme.utils.gap(1),
        marginBottom: theme.utils.gap(1)
    }
}))
