import { UploadedPhoto } from 'lib/components'
import { PaymentType } from 'lib/models'
import { Children, SupplyID, Country } from 'lib/types'

export type BookingAuthConfirmationProps = {
    isRecurring?: boolean,
    supplyId: SupplyID,
    notEnoughCredits: boolean,
    selectedPaymentMethod: PaymentType,
    isMoversAndPackers?: boolean,
    isGuestBookingEnabled?: boolean,
    showGuestBookingForm?: boolean,
    setAreGuestDetailsValid?: (value) => void,
    setShowGuestBookingForm?: (value) => void,
    setGuestDetails?: (value) => void,
    setPaymentMethod: (value: PaymentType) => void,
    onUploadSuccess?: (data: Array<UploadedPhoto>) => void,
    renderExtraContent?: () => Children
}

export enum GuestBookingFields {
    Name = 'name',
    Country = 'country',
    PhoneNumber = 'phoneNumber',
    Address = 'address',
}

export type GuestBookingFormShape = {
    [GuestBookingFields.Name]: string,
    [GuestBookingFields.Country]: Country,
    [GuestBookingFields.PhoneNumber]: string,
    [GuestBookingFields.Address]: string,
}
