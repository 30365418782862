import React, { useState } from 'react'
import { View } from 'react-native'
import Animated, { useAnimatedStyle, useDerivedValue, withTiming } from 'react-native-reanimated'
import { Icons } from 'assets'
import { Breakpoint, createStyles, theme } from 'lib/styles'
import { CreditPackage } from 'lib/models'
import { useIsWithinBreakpoints, usePrice, useStyles, useTranslations } from 'lib/hooks'
import { Touchable } from './Touchable'
import { Error, Label, Regular, Title } from './typography'

type PackageTileProps = {
    index: number,
    isLast: boolean,
    isSelected: boolean,
    onPress: VoidFunction,
    creditPackage: CreditPackage,
    onPurchase?: (value: CreditPackage) => void
}

export const PackageTile: React.FunctionComponent<PackageTileProps> = ({
    index,
    isLast,
    isSelected,
    creditPackage,
    onPress,
    onPurchase
}) => {
    const T = useTranslations()
    const { styles } = useStyles(stylesheet)
    const [isHovered, setIsHovered] = useState(false)
    const isMobile = useIsWithinBreakpoints(Breakpoint.XS, Breakpoint.MD)
    const animatedIsSelected = useDerivedValue(() => isSelected)
    const saveAmount = usePrice(creditPackage.credit - creditPackage.price)
    const payAmount = usePrice(creditPackage.price)
    const getAmount = usePrice(creditPackage.credit)
    const animatedRotateStyle = useAnimatedStyle(() => ({
        transform: [{ translateY: withTiming(animatedIsSelected.value ? -10 : 0) }]
    }))

    return (
        <React.Fragment>
            <Animated.View
                style={
                    [
                        animatedRotateStyle,
                        styles.tileContainer
                    ]
                }
            >
                <Touchable
                    style={{
                        ...styles.tile,
                        backgroundColor: isSelected || isHovered
                            ? theme.colors.yellow
                            : theme.colors.white,
                        borderColor: isSelected || isHovered
                            ? theme.colors.sun
                            : theme.colors.silver
                    }}
                    onHoverIn={() => setIsHovered(true)}
                    onHoverOut={() => setIsHovered(false)}
                    key={creditPackage._id}
                    onPress={onPurchase
                        ? () => {
                            onPress()
                            onPurchase(creditPackage)
                        }
                        : onPress
                    }
                >
                    <View style={styles.badgeWrapper}>
                        {index === 1 && (
                            <View style={styles.badge}>
                                <Error forceColor={theme.colors.white}>
                                    {T.components.sendPayModal.mostPopular}
                                </Error>
                            </View>
                        )}
                    </View>
                    <View>
                        {!isMobile && (
                            <View style={styles.title}>
                                <Title bold>
                                    {creditPackage.package_name}
                                </Title>
                            </View>
                        )}
                        <View style={styles.saveBadgeWrapper}>
                            <View
                                style={{
                                    ...styles.saveBadge,
                                    backgroundColor: isSelected || isHovered
                                        ? theme.colors.navajo
                                        : theme.colors.yellow
                                }}
                            >
                                <Label
                                    style={styles.textCenter}
                                    forceColor={theme.colors.orange}
                                >
                                    {`${T.common.save} ${saveAmount}`}
                                </Label>
                            </View>
                        </View>
                        <View
                            style={{
                                ...styles.detailsContainer,
                                backgroundColor: isSelected || isHovered
                                    ? theme.colors.navajo
                                    : theme.colors.marble
                            }}
                        >
                            <View style={styles.getAmountContainer}>
                                <Label forceColor={theme.colors.mouse}>
                                    {T.components.sendPayModal.youPay}
                                </Label>
                                <Regular
                                    bold
                                    style={styles.textCenter}
                                >
                                    {payAmount}
                                </Regular>
                            </View>
                            <View style={styles.separatorIcon}>
                                <Icons.ArrowInCircle
                                    size={16}
                                    forceColor={theme.colors.white}
                                />
                            </View>
                            <View style={styles.getAmountContainer}>
                                <Label forceColor={theme.colors.mouse}>
                                    {T.components.sendPayModal.youGet}
                                </Label>
                                <Regular
                                    bold
                                    style={styles.textCenter}
                                    forceColor={theme.colors.orange}
                                >
                                    {getAmount}
                                </Regular>
                            </View>
                        </View>
                        <View
                            style={{
                                ...styles.selectContainer,
                                borderColor: isSelected || isHovered
                                    ? theme.colors.sun
                                    : theme.colors.darkSilver
                            }}
                        >
                            <Regular
                                forceColor={
                                    isSelected || isHovered
                                        ? theme.colors.orange
                                        : undefined
                                }
                            >
                                {T.common.purchase}
                            </Regular>
                        </View>
                    </View>
                </Touchable>
            </Animated.View>
            {!isLast && (
                <View style={styles.spacer} />
            )}
        </React.Fragment>
    )
}

const stylesheet = createStyles(theme => ({
    tileContainer: {
        flex: 1
    },
    tile: {
        borderWidth: 1,
        alignItems: {
            lg: 'center',
            xs: undefined
        },
        paddingHorizontal: theme.utils.gap(1),
        paddingVertical: theme.utils.gap(2),
        borderRadius: theme.components.button.borderRadius * 2,
        ...theme.utils.createShadow(0, 0.5, 8, 8)
    },
    title: {
        alignItems: 'center',
        paddingBottom: theme.utils.gap(1)
    },
    spacer: {
        width: {
            lg: theme.utils.gap(1),
            xs: theme.utils.gap(1) / 2
        }
    },
    selectContainer: {
        borderWidth: 1,
        alignItems: 'center',
        marginTop: theme.utils.gap(2),
        paddingVertical: theme.utils.gap(1),
        borderRadius: theme.components.button.borderRadius
    },
    detailsContainer: {
        borderRadius: 8,
        flexDirection: {
            lg: 'row',
            ':w[, 1300]': 'column'
        },
        alignItems: 'center',
        paddingHorizontal: {
            lg: theme.utils.gap(1),
            ':w[, 1300]': 0
        },
        paddingVertical: theme.utils.gap(1),
        marginTop: theme.utils.gap(2)
    },
    getAmountContainer: {
        alignItems: 'center'
    },
    saveBadgeWrapper: {
        alignItems: 'center'
    },
    saveBadge: {
        paddingVertical: theme.utils.gap(1) / 2,
        paddingHorizontal: {
            lg: theme.utils.gap(2),
            xs: theme.utils.gap(1)
        },
        borderRadius: theme.components.button.borderRadius
    },
    badgeWrapper: {
        top: -theme.utils.gap(3) / 2,
        left: 0,
        right: 0,
        position: 'absolute',
        alignItems: 'center'
    },
    badge: {
        height: theme.utils.gap(3),
        borderRadius: theme.utils.gap(3) / 2,
        paddingHorizontal: {
            lg: theme.utils.gap(2),
            xs: theme.utils.gap(1)
        },
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.colors.sun
    },
    purchaseContainer: {
        marginTop: theme.utils.gap(2)
    },
    textCenter: {
        textAlign: 'center'
    },
    separatorIcon: {
        backgroundColor: theme.colors.sun,
        alignItems: 'center',
        justifyContent: 'center',
        marginHorizontal: theme.utils.gap(1),
        marginVertical: theme.utils.gap(1),
        width: {
            lg: 32,
            ':w[, 1300]': 20
        },
        height: {
            lg: 32,
            ':w[, 1300]': 20
        },
        borderRadius: {
            lg: 16,
            ':w[, 1300]': 10
        }
    }
}))
