import { Nullable } from 'lib/types'
import React from 'react'

type HTMLContentProps = {
    html: string,
    messageColor: string,
    messageSize?: Nullable<number>
}

// This is component to handle API response messages in html format as well as plain text
export const HTMLContent: React.FunctionComponent<HTMLContentProps> = ({
    html,
    messageColor,
    messageSize
}) => {
    const colorStyle = messageColor ? `color: ${messageColor} !important;` : ''
    const sizeStyle = messageSize ? `font-size: ${messageSize}px !important;` : ''
    const style = colorStyle || sizeStyle ? `style="${colorStyle} ${sizeStyle}"` : ''

    return (
        <div dangerouslySetInnerHTML={{ __html: `<p class="toast-message" ${style}>${html}</p>` }} />
    )
}

