import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const SuccessBooking: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path d="M49.524 41.905c-10.514 0-19.048 8.533-19.048 19.047C30.476 71.467 39.01 80 49.524 80c10.514 0 19.047-8.533 19.047-19.048 0-10.514-8.533-19.047-19.047-19.047Zm6.285 28-8.19-8.19V49.523h3.81v10.628l7.047 7.048-2.666 2.705ZM53.334 7.619H41.22C39.619 3.2 35.429 0 30.476 0c-4.952 0-9.143 3.2-10.743 7.619H7.62C3.429 7.619 0 11.048 0 15.239V72.38C0 76.571 3.429 80 7.619 80h23.276a25.689 25.689 0 0 1-5.41-7.619H7.62V15.238h7.62v11.429h30.475V15.238h7.62V34.59a26.78 26.78 0 0 1 7.618 2.286V15.238c0-4.19-3.428-7.619-7.619-7.619Zm-22.857 7.62a3.82 3.82 0 0 1-3.81-3.81 3.82 3.82 0 0 1 3.81-3.81 3.82 3.82 0 0 1 3.81 3.81 3.82 3.82 0 0 1-3.81 3.81Z" />
    </Icon>
)
