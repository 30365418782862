import React from 'react'
import { useNavigation } from '@react-navigation/native'
import { View } from 'react-native'
import { ENV } from 'lib/config'
import { Supply } from 'lib/models'
import { createStyles } from 'lib/styles'
import { useStyles, useTranslations } from 'lib/hooks'
import { Button, Typography } from 'lib/components'
import { linkingHelpers } from 'lib/utils'
import { SupplyID } from 'lib/types'
import { Segment } from 'lib/analytics'

type UserBookingsEmptyProps = {
    title: string,
    serviceFilter: string
    description: string
    showActionButton?: boolean
}

export const UserBookingsEmpty: React.FunctionComponent<UserBookingsEmptyProps> = ({
    title,
    serviceFilter,
    description,
    showActionButton = true
}) => {
    const T = useTranslations()
    const navigation = useNavigation()
    const { styles } = useStyles(stylesheet)
    const getServiceFilterValue = () => {
        try {
            return JSON.parse(serviceFilter) as Supply
        } catch {
            return null
        }
    }

    const onButtonPress = () => {
        const serviceFilterValue = getServiceFilterValue()

        if (!serviceFilterValue?.id) {
            Segment.externalLinkClicked({
                url: ENV.SENDHELPER_MARKETING_SITE_URL
            })

            return linkingHelpers.openUrl(ENV.SENDHELPER_MARKETING_SITE_URL, false)
        }

        const { screenName } = linkingHelpers.getInternalPathBySupplyId(serviceFilterValue.id as SupplyID)

        Segment.externalLinkClicked({
            url: screenName ?? ENV.SENDHELPER_MARKETING_SITE_URL
        })

        screenName
            // @ts-ignore
            ? navigation.navigate(screenName)
            : linkingHelpers.openUrl(ENV.SENDHELPER_MARKETING_SITE_URL, false)
    }

    return (
        <View style={styles.wrapper}>
            <View style={styles.container}>
                <View style={styles.text}>
                    <Typography.Title bold>
                        {title}
                    </Typography.Title>
                </View>
                <View style={styles.text}>
                    <Typography.Regular>
                        {description}
                    </Typography.Regular>
                </View>
                {showActionButton && (
                    <Button
                        width={180}
                        noBackground
                        onPress={onButtonPress}
                        text={T.screens.bookings.empty.findExpert}
                    />
                )}
            </View>
        </View>
    )
}

const stylesheet = createStyles(theme => ({
    wrapper: {
        flex: 1,
        backgroundColor: theme.colors.marble,
        paddingBottom: theme.utils.gap(4),
        paddingTop: theme.utils.gap(2),
        marginTop: theme.utils.gap(3),
        marginHorizontal: {
            lg: -theme.utils.gap(6)
        },
        paddingHorizontal: {
            lg: theme.utils.gap(6),
            xs: theme.utils.gap(1)
        }
    },
    container: {
        borderRadius: 8,
        alignItems: 'center',
        marginVertical: {
            lg: theme.utils.gap(6),
            xs: 0
        },
        paddingVertical: theme.utils.gap(8),
        backgroundColor: theme.colors.white,
        ...theme.utils.createShadow(2, 0, 8, 4, 4, theme.colors.webShadow(0.04))
    },
    text: {
        marginBottom: theme.utils.gap(3)
    }
}))
