// TODO: refactor and add all params generators for other routes

import { ProviderDetails } from 'lib/models'
import { AuthorizeWithAppleResponse } from 'lib/types'

export const getAuthFinishAppleSignUpParams = (response: AuthorizeWithAppleResponse) => ({
    appleUserId: response.authorization?.code || '',
    providerDetails: ProviderDetails.Apple,
    email: response.user?.email || '',
    name: response.user
        ? `${response.user.name?.firstName || ''} ${response.user.name?.lastName || ''}`
        : ''
})
